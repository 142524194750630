import React from 'react';
import UiModal from '../../ui/modal/UiModal';

import AccountSubscription from '../../account/subscription/AccountSubscription';
import { stores } from '../../../stores';
import UiAnimate from '../../ui/animate/UiAnimate';
import { useStore } from '../../../hooks/useStore';

export default function CrmUserPreferences() {
    const [showSubscriptionModalForm] = useStore(stores.crm.showSubscriptionModalForm);

    return (
        <UiModal open={showSubscriptionModalForm}>
            <UiAnimate animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
                <AccountSubscription />
            </UiAnimate>
        </UiModal>
    );
}
