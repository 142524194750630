import styled from 'styled-components';

export default styled.div`
    display: flex;
    align-items: center;
    .match-sport-icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .match-main-info {
        min-width: 0;
        width: 100%;
    }
    .live-info {
        margin-bottom: var(--spacing-4);
    }

    .match-name {
        display: flex;
        align-items: center;
        gap: 0.4rem;
    }
    .match-extra {
        min-width: 3rem;
        margin-left: auto;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;
