import { Market } from '@staycool/location';
import { getClient } from './environment';
import { ClientName } from './utils/types';

const MarketsByClient = {
    [ClientName.COOLBET]: [
        Market.CANADA_ONTARIO,
        Market.CANADA,
        Market.CHILE,
        Market.ECUADOR,
        Market.ESTONIA,
        Market.FINLAND,
        Market.ICELAND,
        Market.MEXICO,
        Market.NORWAY,
        Market.PERU,
        Market.SWEDEN,
    ],
    [ClientName.STATION]: [Market.USA_NEVADA],
    [ClientName.WYNNBET_NEVADA]: [Market.USA_NEVADA],
};

export const DefaultMarketByClient = {
    [ClientName.STATION]: Market.USA_NEVADA,
    [ClientName.WYNNBET_NEVADA]: Market.USA_NEVADA,
};

export function isMarketEnabled(market: Market) {
    const client = getClient();
    const markets = MarketsByClient[client] || [];
    return markets.includes(market);
}
