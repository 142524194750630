import React from 'react';
import Wrapper from './styles';
import { DATE_FORMAT, TIME_FORMAT, getFormattedDate } from '../../../../services/date';
import UiPlaceholder from '../../../ui/placeholder/UiPlaceholder';

interface Props {
    time: Date | undefined;
    wrap?: boolean;
    vertical?: boolean;
}

export default function SportMatchTime({ time, wrap, vertical }: Props) {
    if (!time) {
        return (
            <Wrapper wrap={wrap} vertical={vertical}>
                <UiPlaceholder width="60px" />
            </Wrapper>
        );
    }
    return (
        <Wrapper wrap={wrap} vertical={vertical}>
            <span>
                {getFormattedDate({
                    date: time,
                    format: DATE_FORMAT,
                    useMonthLetters: true,
                })}
            </span>
            ,
            <div className="time-hour">
                <span>
                    {getFormattedDate({
                        date: time,
                        format: TIME_FORMAT,
                    })}
                </span>
            </div>
        </Wrapper>
    );
}
