import React from 'react';
import { useState } from 'react';
import { translate } from '../../../services/translate';
import UiButton from '../../ui/button/UiButton';
import Zendesk from '../Zendesk';

interface Props {
    $isPhone?: boolean;
}

export default function ZendeskButton({ $isPhone }: Props) {
    const [isVisible, setIsVisible] = useState(false);

    function toggleChat() {
        isVisible
            ? window.zE('webWidget:on', 'close', window.$zopim.livechat.window.hide())
            : window.zE('webWidget:on', 'open', window.$zopim.livechat.window.show());
        setIsVisible(!isVisible);
    }

    return (
        <>
            <Zendesk />
            {$isPhone ? (
                <UiButton color="primary" iconPosition="right" onClick={toggleChat} size="small">
                    {!isVisible ? translate('Open Live Chat', 'ui.common') : translate('Hide Live Chat', 'ui.common')}
                </UiButton>
            ) : (
                <UiButton
                    color="primary"
                    iconPosition="right"
                    onClick={() => window.zE('webWidget:on', 'open', window.$zopim.livechat.window.show())}
                    size="small"
                >
                    {translate('Open Live Chat', 'ui.common')}
                </UiButton>
            )}
        </>
    );
}
