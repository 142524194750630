import { getStoreValue } from '../stores/store/utils';
import { getClient } from './environment';
import { ClientName } from './utils/types';
import { environment } from '../stores/environment/environment';
import { retail } from '../stores/retail/retail';
import { createLoyaltyById } from '../microservices/retail-middleware';
import { EventType } from '../stores/retail/types';
import { logger } from './logger';

const homeRegex = /\[(Home|Player 1)]/;
const awayRegex = /\[(Away|Player 2)]/;

export function getIsDummyLoyalty() {
    return ([ClientName.COOLBET] as ReadonlyArray<string>).includes(getClient());
}

export function getOutcomeDisplayLine(outcomeResultKey: string, displayLine: number) {
    if (Number.isNaN(Number(displayLine))) {
        return '';
    }

    if (
        outcomeResultKey.toLowerCase().includes('over') ||
        outcomeResultKey.toLowerCase().includes('under') ||
        displayLine === 0
    ) {
        return `${displayLine}`;
    }
    if (homeRegex.test(outcomeResultKey) || outcomeResultKey.includes('Draw')) {
        return `${displayLine > 0 ? '+' : '-'}${Math.abs(displayLine)}`;
    }
    if (awayRegex.test(outcomeResultKey)) {
        return `${displayLine > 0 ? '-' : '+'}${Math.abs(displayLine)}`;
    }
    return '';
}

export function getTerminalInitializationUrl(machineId: string) {
    const { TERMINAL_INITIALIZATION_URL } = getStoreValue(environment);
    const url = new URL(TERMINAL_INITIALIZATION_URL);
    url.searchParams.append('hash', machineId);
    return url.href;
}

export async function tryCreateManualLoyalty(loyaltyId: string) {
    try {
        const loyalty = await createLoyaltyById(loyaltyId);
        retail.loyalty.set(loyalty);
    } catch (error) {
        logger.log('retail', 'tryCreateManualLoyalty', error);

        const errorMessage = 'Unable to process a rewards card, please ask for assistance';
        retail.retailModals.isRetailModalLoyalty.set(false);
        retail.retailModals.retailModalEvent.set({
            type: EventType.LoyaltyScanned,
            error: errorMessage,
        });
    }
}
