export enum COUNTRY {
    CANADA = 'CA',
    CHILE = 'CL',
    ECUADOR = 'EC',
    ENGLAND = 'EN',
    ESTONIA = 'EE',
    FINLAND = 'FI',
    ICELAND = 'IS',
    MEXICO = 'MX',
    NORWAY = 'NO',
    PERU = 'PE',
    SWEDEN = 'SE',
    USA = 'US',
}
