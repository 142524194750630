import styled from 'styled-components';

export default styled.div`
    position: sticky;
    top: 150px;
    background: var(--color-body-bg);
    z-index: 1000;
    padding: var(--spacing-12);

    a,
    img {
        user-select: none;
        -webkit-user-select: none;
        -webkit-user-drag: none;
        -webkit-app-region: no-drag;
    }

    .main-sports-menu {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        max-width: 71rem;

        > *:not(:last-child) {
            margin-right: 0.4rem;
        }
    }
    .expanded-sports-menu {
        background-color: var(--color-body-bg);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        max-width: 71rem;

        padding: 0.8rem;
        margin-left: -0.8rem;
        margin-right: -0.8rem;
        > * {
            margin-right: 0.4rem;
        }
    }
`;
