import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--form-input-bg);
    border-radius: ${({ theme }) => theme.layout.gutter};
    box-shadow: var(--ui-shadow);
    width: 100%;
    .progress-bar {
        z-index: 1;
        position: relative;
        height: 20px;
        border-radius: ${({ theme }) => theme.layout.gutter};
        background-color: ${({ theme }) => theme.colors.success};
        width: ${(props) => 10 + props.percentage * 0.9}%;

        box-shadow: var(--ui-shadow);
        transition: width 0.2s;
    }
    .stripes {
        position: absolute;
        top: 1px;
        left: 1px;
        bottom: 1px;
        right: 1px;
        background-image: linear-gradient(
            -45deg,
            rgba(255, 255, 255, 0.2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.2) 50%,
            rgba(255, 255, 255, 0.2) 75%,
            transparent 75%,
            transparent
        );
        z-index: 2;
        background-size: 50px 50px;
        animation: move 2s linear infinite;
        border-radius: 0.8rem;
        overflow: hidden;
    }
    .animated {
        z-index: 3;
        animation: move 2s linear infinite;
    }
    @keyframes move {
        0% {
            background-position: 0 0;
        }
        100% {
            background-position: 50px 50px;
        }
    }
    .progress-text {
        z-index: 4;
        position: absolute;
        text-align: center;
        top: 50%;
        padding: 0 5px;
        line-height: 1;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 0.7rem;
        background-color: var(--form-input-bg);
        border-radius: 20px;
    }
`;
