import React from 'react';
import { translate } from '../../../../services/translate';
import Wrapper from './styles';

interface Props {
    name: string;
    clearSelection: () => void;
}

export default function SportParlayCardHeader({ name, clearSelection }: Props) {
    return (
        <Wrapper>
            <div className="parlay-card-header">
                <div className="parlay-card-title">{name}</div>

                <div className="clear-all" onClick={clearSelection}>
                    {translate('Clear all', 'ui.sportsbook')}
                </div>
            </div>
        </Wrapper>
    );
}
