import React from 'react';
import Wrapper from './styles';
import { stores } from '../../../../../stores';
import classNames from 'classnames';
import { QUIZ_STATUS } from '../../../../../services/quiz';
import { Question, Quiz } from '../../../../../microservices/quiz';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    questions: Question[];
    activeQuiz: Quiz;
}

export default function CmsQuizCollectionQuizQuestionNumber({ questions, activeQuiz }: Props) {
    const [activeQuestionNumber, setActiveQuestionNumber] = useStore(stores.cms.activeQuestionNumber);
    const [userAnswers] = useStore(stores.cms.userAnswers);
    const userAnswersForActiveQuiz = userAnswers && userAnswers[activeQuiz.id];
    const [isAuthenticated] = useStore(stores.isAuthenticated);

    function getQuestionClass(question) {
        if (userAnswersForActiveQuiz) {
            if (activeQuiz.status === QUIZ_STATUS.RESULTED && isAuthenticated) {
                const correctUserAnswers = userAnswersForActiveQuiz.filter(
                    (answer) => answer.was_correct === true && answer.question_id === question.id,
                );

                if (correctUserAnswers.length) {
                    return 'correctAnswer';
                }
                return 'wrongAnswer';
            }
            const userAnsweredQuestion = userAnswersForActiveQuiz.filter(
                (answer) => answer.question_id === question.id,
            ).length;

            if (userAnsweredQuestion) {
                return 'userSelectedAnswer';
            }
        }
    }

    return (
        <Wrapper>
            {questions.map((question, index) => (
                <div
                    key={index}
                    className={classNames('page', getQuestionClass(question), {
                        selected: question.question_number === activeQuestionNumber,
                    })}
                    onClick={() => setActiveQuestionNumber(question.question_number)}
                >
                    {question.question_number}
                </div>
            ))}
        </Wrapper>
    );
}
