import isEmpty from 'lodash/isEmpty';
import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import ScriptLoader from '../../components/script-loader/ScriptLoader';
import { FEATURE, isFeatureAvailable } from '../../services/feature';
import { localeOfLanguage } from '../../services/language';
import { getRoute, isActiveRoute, useRouter } from '../../services/router';
import { isProduction } from '../../services/util';
import { stores } from '../../stores';
import { environment } from '../../stores/environment/environment';
import { getStoreValue } from '../../stores/store/utils';
import { media } from '../../stores/media/media';
import { useStore } from '../../hooks/useStore';

export interface Kustomer {
    stop(cb?: () => void): void;
    start(opts: KustomerStartOptions, cb: (res, err) => void): void;
    open(cb?: () => void): void;
    close(cb?: () => void): void;
    describeConversation(
        data: { conversationId: string; customAttributes: Record<string, string> },
        cb?: (res, err) => void,
    ): void;
    addListener<K extends keyof KustomerEvent>(event: K, cb: (data: KustomerEvent[K]) => void): void;
    removeListener<K extends keyof KustomerEvent>(event: K, cb: (data: KustomerEvent[K]) => void): void;
}

interface KustomerEvent {
    onOpen: undefined;
    onClose: undefined;
    onConversationCreate: KustomerOnConversationCreateParams;
}

interface KustomerOnConversationCreateParams {
    conversationId: string;
    createdAt: string;
    ended: boolean;
    isInAssistantMode: boolean;
}

interface KustomerStartOptions {
    brandId: string;
    lang?: string;
    hideChatIcon?: boolean;
    chatIconPosition?: KustomerChatIconPosition;
}

type KustomerChatIconPosition = 'right' | 'left';

const LiveChatKustomerContext = createContext<{
    toggle: () => void;
    isOpen: boolean;
    isStarted: boolean;
    setIsIconHidden: (value: boolean) => void;
    resetIsIconHidden: () => void;
}>({
    toggle: () => {},
    isOpen: false,
    isStarted: false,
    setIsIconHidden: () => {},
    resetIsIconHidden: () => {},
});

export function LiveChatKustomerContextProvider({ children }: { children: ReactNode }) {
    const { pathname } = useRouter();
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [isFilterModalOpen] = useStore(stores.casino.isFilterModalOpen);
    const [{ receiptById }] = useStore(stores.sports.betSlipPlacingState);
    const [language] = useStore(stores.language);
    const [{ isPhone, isTablet }] = useStore(media);
    const isMobile = isPhone || isTablet;
    const [isStarted, setIsStarted] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isIconHidden, setIsIconHidden] = useState(isMobile);
    const [chatIconPosition, setChatIconPosition] = useState<KustomerChatIconPosition>('right');
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [{ KUSTOMER }] = useStore(environment);
    const { BRAND_ID, API_KEY } = KUSTOMER || {};
    const promiseRef = useRef(Promise.resolve());

    useEffect(() => {
        if (!isScriptLoaded || !BRAND_ID) {
            return;
        }

        promiseRef.current = promiseRef.current.then(
            () =>
                new Promise((resolve) => {
                    window.Kustomer.start(
                        {
                            brandId: BRAND_ID,
                            hideChatIcon: isIconHidden,
                            chatIconPosition,
                            lang: localeOfLanguage[language],
                        },
                        () => {
                            window.Kustomer.addListener('onOpen', onOpen);
                            window.Kustomer.addListener('onClose', onClose);
                            window.Kustomer.addListener('onConversationCreate', onConversationCreate);
                            setIsStarted(true);
                            resolve();
                        },
                    );
                }),
        );

        return () => {
            promiseRef.current = promiseRef.current.then(
                () =>
                    new Promise((resolve) => {
                        window.Kustomer.stop(() => {
                            window.Kustomer.removeListener('onOpen', onOpen);
                            window.Kustomer.removeListener('onClose', onClose);
                            window.Kustomer.removeListener('onConversationCreate', onConversationCreate);
                            setIsStarted(false);
                            resolve();
                        });
                    }),
            );
        };
    }, [isScriptLoaded, isIconHidden, chatIconPosition, language, BRAND_ID]);

    useEffect(resetIsIconHidden, [isMobile, pathname]);

    function resetIsIconHidden() {
        const isHiddenRoute =
            isActiveRoute(`${getRoute('poker')}/:rest`, true) ||
            isActiveRoute(`${getRoute('casino-game')}/:gameSlug`, true) ||
            isActiveRoute(getRoute('share'), false) ||
            isActiveRoute(getRoute('match-promotion-preview'), false);

        setIsIconHidden(isMobile || isHiddenRoute);
    }

    useEffect(() => {
        if (!isEmpty(betSlipMarketIdToOutcomeId) || !isEmpty(receiptById) || isFilterModalOpen) {
            setChatIconPosition('left');
        } else {
            setChatIconPosition('right');
        }
    }, [betSlipMarketIdToOutcomeId, receiptById, isFilterModalOpen]);

    function onConversationCreate({ conversationId }: KustomerOnConversationCreateParams) {
        const BO_URL = isProduction()
            ? 'https://coolbet.staycool.ee/users/'
            : 'https://coolbet.coolbetstage.com/users/';
        const user = getStoreValue(stores.user);

        window.Kustomer.describeConversation({
            conversationId,
            customAttributes: {
                languageStr: language,
                ...(user && { boUrlUrl: BO_URL + user.id }),
            },
        });
    }

    function onOpen() {
        setIsOpen(true);
    }

    function onClose() {
        setIsOpen(false);
    }

    function toggle() {
        return isOpen ? window.Kustomer.close() : window.Kustomer.open();
    }

    return (
        <LiveChatKustomerContext.Provider
            value={{
                toggle,
                isOpen,
                isStarted,
                setIsIconHidden,
                resetIsIconHidden,
            }}
        >
            {isFeatureAvailable(FEATURE.LIVE_CHAT_KUSTOMER) && API_KEY && (
                <ScriptLoader
                    url="https://cdn.kustomerapp.com/chat-web/widget.js"
                    onLoad={() => setIsScriptLoaded(true)}
                    attributes={{ 'data-kustomer-api-key': API_KEY }}
                />
            )}
            {children}
        </LiveChatKustomerContext.Provider>
    );
}

export function useLiveChatKustomerContext() {
    return useContext(LiveChatKustomerContext);
}
