import React from 'react';
import { translate } from '../../../services/translate';
import { retail } from '../../../stores/retail/retail';
import UiButton from '../../ui/button/UiButton';
import { useStore } from '../../../hooks/useStore';

export function RetailDeposit() {
    const [hasRetailSession] = useStore(retail.hasRetailSession);
    const [, setIsRetailModalDeposit] = useStore(retail.retailModals.isRetailModalDeposit);

    if (!hasRetailSession) {
        return null;
    }

    return (
        <UiButton onClick={() => setIsRetailModalDeposit(true)} className="secondary-button">
            {translate('Account Deposit', 'retail.header')}
        </UiButton>
    );
}
