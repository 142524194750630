import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: ${(props) => (props.isCompactView ? '0.5rem 0 0 0' : '0.5rem 1rem 0 1rem')};
    @media ${mediaQuery.isPhone} {
        padding: 0.5rem 0 0 0;
    }

    .bet-box {
        margin: 0.5rem 0 0;
    }

    .bet-totals:nth-child(2) {
        display: flex;
        margin-top: ${(props) => (props.isCompactView ? '' : '0.5rem')};
        width: auto;
        justify-content: ${(props) => (props.isCompactView ? 'center' : 'flex-start')};
    }

    .ma-label {
        text-transform: uppercase;
        font-size: 0.7rem;
        max-width: 65px;
        height: 30px;
        margin-right: 1.6rem;
        padding: ${(props) => (props.isCompactView ? '0' : '0 0 0 1rem')};
    }
    .total-odds {
        order: ${(props) => (props.isCompactView ? '2' : '')};
        &.active {
            order: ${(props) => (props.isCompactView ? '0' : '')};
        }
    }
`;
