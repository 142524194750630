import styled from 'styled-components';

export default styled.div`
    .casino-thumbnail-labels-rtp {
        width: fit-content;
        font-size: 0.75rem;
        font-weight: 900;
        color: var(--font-color-primary);
        font-family: 'Lato', 'Roboto', sans-serif;
        display: flex;
        flex-direction: row;
    }

    .casino-thumbnail-labels-rtp-boosted {
        color: ${({ theme }) => theme.colors.yellow};
        margin-right: var(--spacing-4);
    }

    .casino-thumbnail-labels-rtp-variant {
        color: ${({ theme }) => theme.colors.yellow};
        font-weight: 700;
    }

    .casino-thumbnail-labels-rtp:not(.casino-thumbnail-labels-rtp-payback) {
        border: none;
        background-color: transparent;
    }
`;
