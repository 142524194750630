import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';
import UiGroup from '../../ui/group/UiGroup';

export default styled(UiGroup)`
    position: sticky;
    top: 0;
    z-index: 999;
    overflow: auto;
    padding: 5px;
    background-color: var(--color-header-bg);
    min-height: ${({ theme }) => theme.layout.rgPanelHeight};

    button {
        padding: var(--spacing-4) var(--spacing-8);
    }

    .rg-panel-buttons {
        align-items: center;

        @media ${mediaQuery.isPhone} {
            button {
                padding: 0 var(--spacing-4);
            }
        }
    }

    .clocks-group-wrapper {
        margin-right: auto; // Snaps wrapper to the left side of the reversed flex row
        margin-left: 0;
        overflow: visible; // To not clip the clock visuals during the shake animation

        align-items: center;
        justify-content: center;

        @media ${mediaQuery.isPhone} {
            margin-right: 0;
            margin-left: auto; // Snaps wrapper to the right side of the flex row
        }
    }
`;
