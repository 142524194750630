import React from 'react';
import { useLiveChatKustomerContext } from '../../../contexts/live-chat-kustomer/LiveChatKustomerContext';
import { translate } from '../../../services/translate';
import UiButton from '../../ui/button/UiButton';

export default function LivechatButton() {
    const { toggle, isOpen, isStarted } = useLiveChatKustomerContext();

    return (
        <UiButton color="primary" iconPosition="right" onClick={toggle} isLoading={!isStarted} size="small">
            {isOpen ? translate('Remove Live Chat', 'ui.common') : translate('Open Live Chat', 'ui.common')}
        </UiButton>
    );
}
