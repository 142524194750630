import styled from 'styled-components';

export default styled.div`
    .hot-event-odds {
        margin-bottom: 0.5em;
        .odds {
            display: flex;
            justify-content: space-between;
            gap: 1em;
        }
        .market-name {
            display: flex;
            font-size: 0.7rem;
            text-align: center;
            white-space: wrap;
            justify-content: center;
            font-size: 0.8rem;
            align-items: center;
        }
    }
`;
