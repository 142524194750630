import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

import paybackBoosterLogo from './payback-booster-logo.png';

type StyledProps = {
    isCompact: boolean;
};

export default styled.div<StyledProps>`
    height: 100%;

    overflow: hidden;
    position: relative;

    @media ${mediaQuery.isTablet}, ${mediaQuery.isPhone} {
        overflow: visible;
        padding: 1rem;
    }

    .promo-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        @media ${mediaQuery.isTablet}, ${mediaQuery.isPhone} {
            padding: 0.4rem;
            overflow: visible;
        }

        .booster-logo {
            background-image: url(${paybackBoosterLogo});
            background-repeat: no-repeat;
            background-size: contain;
            height: 90px;
            width: 396px;
            background-position: center;
            position: relative;
            z-index: 1;
            margin: 2rem 0;
            @media ${mediaQuery.isTablet}, ${mediaQuery.isPhone} {
                width: 200px;
                height: 81px;
            }
            @media (max-width: 350px) {
                margin-bottom: 4rem;
            }
        }

        .promo-description {
            color: var(--font-color-primary);
            font-size: 1rem;
            line-height: 1.4;
            text-align: center;
            padding: 20px 30px 10px 10px;
            font-weight: var(--font-weight-medium);

            position: relative;
            ${({ isCompact }) =>
                isCompact &&
                css`
                    width: 350px;
                    font-size: 0.85rem;
                `};

            position: absolute;
            z-index: 10;
            background: #f5c027;
            white-space: break-spaces;
            color: black;
            top: 0;
            width: 100%;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            box-shadow: 0px 20px 20px -15px #111;
        }

        .promo-read-more {
            color: white;
            cursor: pointer;
            position: absolute;
            font-size: var(--font-12);
            top: 15px;
            right: 25px;
            text-align: right;
        }
        .promo-terms-and-conditions {
            color: ${({ theme }) => theme.paybackBooster.primaryColor};
            cursor: pointer;
            position: relative;
        }
    }

    .close-btn {
        z-index: 20;
        top: 10px;
        right: 10px;
    }

    .close-btn > div > svg {
        color: black;
    }
`;
