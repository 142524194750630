import { stores } from '../../../../stores';
import React, { useState } from 'react';
import VirtualSportsSidebar from '../../sidebar/VirtualSportsSidebar';
import Svg from '../../../svg/Svg';
import Wrapper from './styles';
import UiModal from '../../../ui/modal/UiModal';
import VirtualSportsTicketList from '../../ticket/list/VirtualSportsTicketList';
import VirtualSportsBetslip from '../../betslip/VirtualSportsBetslip';
import { SwipeableDrawer } from '@mui/material';
import { virtualSports } from '../../../../stores/virtual-sports/virtual-sports';
import { useStore } from '../../../../hooks/useStore';

function VirtualSportsPhoneBottomNavigation() {
    const [betSlipMarketIdToOutcomeId] = useStore(virtualSports.betslipMarketIdToOutcomeId);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const numberOfBets = Object.keys(betSlipMarketIdToOutcomeId).length;
    const isBetHistoryTabVisible = isAuthenticated && !numberOfBets;
    const [isBetslipOpen, setIsBetslipOpen] = useState(false);
    const [isBetHistoryOpen, setIsBetHistoryOpen] = useState(false);
    return (
        <Wrapper>
            {isBetHistoryTabVisible ? (
                <div onClick={() => setIsBetHistoryOpen(true)} className="safe-area">
                    <div className="back-link">
                        <Svg icon="bethistory-ico" size={1.125} />
                    </div>
                </div>
            ) : (
                <div onClick={() => setIsBetslipOpen(true)} className="safe-area">
                    <div className="betslip-link">
                        <div className="bets-count">{numberOfBets}</div>
                    </div>
                </div>
            )}

            <div className="safe-area" onClick={toggleMenu}>
                <div className="sport-link">
                    <Svg icon="select-sport-ico" size={1.125} />
                </div>
            </div>

            <UiModal
                open={isBetslipOpen}
                onClose={() => setIsBetslipOpen(false)}
                onOpen={() => setIsBetslipOpen(true)}
                mode="drawer"
            >
                <div className="betslip-box">
                    <VirtualSportsBetslip />
                </div>
            </UiModal>

            <UiModal
                open={isBetHistoryOpen}
                onClose={() => setIsBetHistoryOpen(false)}
                onOpen={() => setIsBetHistoryOpen(true)}
                mode="drawer"
            >
                <div className="tickets-box">
                    <VirtualSportsTicketList />
                </div>
            </UiModal>

            <SwipeableDrawer
                disableEnforceFocus
                open={isMenuOpen}
                onOpen={toggleMenu}
                onClose={toggleMenu}
                anchor="right"
                disableDiscovery={true}
            >
                <VirtualSportsSidebar onCloseDrawer={() => setIsMenuOpen(false)} />
            </SwipeableDrawer>
        </Wrapper>
    );
}

export default VirtualSportsPhoneBottomNavigation;
