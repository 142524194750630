import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { getActiveCurrency } from '../currency';
import { initCasino } from './init';
import { loadCasinoJackpots } from '../../microservices/casino';
import { CasinoGame } from './games';
import { formattedAmountWithCurrency } from '../currency';

export interface CasinoJackpot {
    provider: string;
    CAD: number;
    USD: number;
    SEK: number;
    NOK: number;
    EUR: number;
    CLP?: number;
}

export async function setCasinoJackpots() {
    await loadCasinoJackpots();
    await initCasino();

    const games = getStoreValue<CasinoGame[]>(stores.casino.filteredGames);
    const jackpots = getStoreValue<{ [key: string]: CasinoJackpot }>(stores.casino.jackpots);
    const activeCurrency = getActiveCurrency();

    stores.casino.jackpotsDisplayByGameId.set(
        games.reduce((jackpotsDisplayByGameId, game) => {
            const gameJackpotDisplay =
                game.jackpotName && jackpots[game.jackpotName] && jackpots[game.jackpotName][activeCurrency];
            if (gameJackpotDisplay && gameJackpotDisplay > 0) {
                jackpotsDisplayByGameId[game.id] = formattedAmountWithCurrency(gameJackpotDisplay);
            }
            return jackpotsDisplayByGameId;
        }, {}),
    );
}

export function hasCasinoJackpotDisplay(game: CasinoGame) {
    return Boolean(getStoreValue(stores.casino.jackpotsDisplayByGameId)[game.id]);
}
