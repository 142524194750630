import { Licence } from '@staycool/location';
import { stores } from '../stores';
import { environment } from '../stores/environment/environment';
import { getStoreValue } from '../stores/store/utils';
import { COUNTRY } from './country';
import { getClient, isB2B, isProd, isRetail } from './environment';
import { getLicence } from './licence';
import { AppType } from './mobile-app';
import { getUserCountry } from './user';
import { ClientName } from './utils/types';

export enum FEATURE {
    ABOUT = 'ABOUT',
    ACCOUNT_LINKING = 'ACCOUNT_LINKING',
    ACCOUNT_VERIFICATION = 'ACCOUNT_VERIFICATION',
    ADDRESS_AUTOCOMPLETE = 'ADDRESS_AUTOCOMPLETE',
    ANIMATED_BACKGROUND = 'ANIMATED_BACKGROUND',
    AUTH_PROFILE_VALIDATOR = 'AUTH_PROFILE_VALIDATOR',
    BANNER_MESSAGE = 'BANNER_MESSAGE',
    BETBUILDER = 'BETBUILDER',
    BETBUILDER_REGIONS = 'BETBUILDER_REGIONS',
    BETSLIP_BANKERS = 'BETSLIP_BANKERS',
    BETSLIP_CONFIRM = 'BETSLIP_CONFIRM',
    BETSLIP_FULL_MARKET_NAME = 'BETSLIP_FULL_MARKET_NAME',
    BETSLIP_KEEP_SELECTIONS = 'BETSLIP_KEEP_SELECTIONS',
    BETSLIP_NAVIGATION = 'BETSLIP_NAVIGATION',
    BLOCKED_PRODUCTS_CHECK = 'BLOCKED_PRODUCTS_CHECK',
    BLOG = 'BLOG',
    BONUSES = 'BONUSES',
    BONUS_OFFERS = 'BONUS_OFFERS',
    BOOSTED_ODDS = 'BOOSTED_ODDS',
    CAMPAIGNS = 'CAMPAIGNS',
    CAMPAIGN_FEATURED_LINKS = 'CAMPAIGN_FEATURED_LINKS',
    CASINO = 'CASINO',
    CASINO_FILTERS = 'CASINO_FILTERS',
    CASINO_HAPPY_HOUR = 'CASINO_HAPPY_HOUR',
    CASINO_PAYBACK_BOOSTER = 'CASINO_PAYBACK_BOOSTER',
    CASINO_RACE = 'CASINO_RACE',
    CASINO_RULES = 'CASINO_RULES',
    CONTESTS = 'CONTESTS',
    COOKIE_BOT = 'COOKIE_BOT',
    COOKIE_POLICY = 'COOKIE_POLICY',
    COOLBET_LEAGUE = 'COOLBET_LEAGUE',
    DARK_THEME = 'DARK_THEME',
    DEPOSIT = 'DEPOSIT',
    DEPOSIT_LOCK = 'DEPOSIT_LOCK',
    DEVICE_FINGERPRINT_SEON = 'DEVICE_FINGERPRINT_SEON',
    DIGITAL_BOARD = 'DIGITAL_BOARD',
    DISPLAY_DOLLAR_SIGN = 'DISPLAY_DOLLAR_SIGN',
    DOWNLOAD_ALL_MY_DATA = 'DOWNLOAD_ALL_MY_DATA',
    EVENT_TYPE_MAPPING = 'EVENT_TYPE_MAPPING',
    EXTERNAL_WALLET = 'EXTERNAL_WALLET',
    FEATURED_EVENTS = 'FEATURED_EVENTS',
    FOOTER = 'FOOTER',
    FREE_BET_DEDUCTION = 'FREE_BET_DEDUCTION',
    FULL_MY_ACCOUNT_LAYOUT = 'FULL_MY_ACCOUNT_LAYOUT',
    GAMBLING_STATISTICS = 'GAMBLING_STATISTICS',
    GAME_INFORMATION = 'GAME_INFORMATION',
    GENIUS_PLAYER = 'GENIUS_PLAYER',
    GEOCOMPLY = 'GEOCOMPLY',
    HEADER = 'HEADER',
    HEADER_MOBILE = 'HEADER_MOBILE',
    HIGHEST_TURNOVER = 'HIGHEST_TURNOVER',
    HORSE_RACING = 'HORSE_RACING',
    INBOX = 'INBOX',
    INCOMING_BETS = 'INCOMING_BETS',
    IP_CHECK = 'IP_CHECK',
    LANGUAGE_FLAG = 'LANGUAGE_FLAG',
    LANGUAGE_SELECTION = 'LANGUAGE_SELECTION',
    LEADER_BOARD = 'LEADER_BOARD',
    LEAGUE = 'LEAGUE',
    LIVE_CHAT_KUSTOMER = 'LIVE_CHAT_KUSTOMER',
    LIVE_LOBBY_LAUNCHER = 'LIVE_LOBBY_LAUNCHER',
    LOGIN = 'LOGIN',
    LOGIN_GEO_LOCATION = 'LOGIN_GEO_LOCATION',
    MARKETING_INCOME_ACCESS_AFFILIATE = 'MARKETING_INCOME_ACCESS_AFFILIATE',
    MANAGEMENT_PAGE = 'MANAGEMENT_PAGE',
    MATCH_OF_THE_DAY = 'MATCH_OF_THE_DAY',
    MATCH_TURNOVER = 'MATCH_TURNOVER',
    MOBILE_WEB_APP_INSTALL = 'MOBILE_WEB_APP_INSTALL',
    MULTI_CURRENCY_WALLET = 'MULTI_CURRENCY_WALLET',
    MY_ACCOUNT_EDITABLE = 'MY_ACCOUNT_EDITABLE',
    MY_ACCOUNT_GENDER = 'MY_ACCOUNT_GENDER',
    MY_ACCOUNT_INFO_MESSAGE = 'MY_ACCOUNT_INFO_MESSAGE',
    MY_ACCOUNT_LANGUAGE_SELECTION = 'MY_ACCOUNT_LANGUAGE_SELECTION',
    MY_CASINO = 'MY_CASINO',
    OTHER_LOGIN_OPTIONS = 'OTHER_LOGIN_OPTIONS',
    PARLAY_CARD = 'PARLAY_CARD',
    PAY_AND_PLAY = 'PAY_AND_PLAY',
    PLACE_BET = 'PLACE_BET',
    POKER = 'POKER',
    POKER_MARKETING_ONLY = 'POKER_MARKETING_ONLY',
    PREMATCH_STATISTICS = 'PREMATCH_STATISTICS',
    PROCESSES_I_AM_INVOLVED_IN = 'PROCESSES_I_AM_INVOLVED_IN',
    PROFILE_OCCUPATION_VALIDATION = 'PROFILE_OCCUPATION_VALIDATION',
    PROMOTIONS = 'PROMOTIONS',
    PUSH_TO_APP_INSTALL = 'PUSH_TO_APP_INSTALL',
    QUICKLINKS_SINGLE_TOP_LEAGUE = 'QUICKLINKS_SINGLE_TOP_LEAGUE',
    QUICKSTAKE_INCREMENTAL = 'QUICKSTAKE_INCREMENTAL',
    RECENTLY_PLAYED_GAMES = 'RECENTLY_PLAYED_GAMES',
    REFER_A_FRIEND = 'REFER_A_FRIEND',
    REFER_A_FRIEND_SHARE = 'REFER_A_FRIEND_SHARE',
    REGISTRATION = 'REGISTRATION',
    REGISTRATION_B2B = 'REGISTRATION_B2B',
    REGISTRATION_B2B_BASIC = 'REGISTRATION_B2B_BASIC',
    REPORT_FEEDBACK = 'REPORT_FEEDBACK',
    RESPONSIBLE_GAMING = 'RESPONSIBLE_GAMING',
    RESPONSIBLE_GAMING_ADVANCED_TIMEOUTS = 'RESPONSIBLE_GAMING_ADVANCED_TIMEOUTS',
    RESPONSIBLE_GAMING_BETTING_ADVICE = 'RESPONSIBLE_GAMING_BETTING_ADVICE',
    RESPONSIBLE_GAMING_COMMITMENT = 'RESPONSIBLE_GAMING_COMMITMENT',
    RESPONSIBLE_GAMING_SELF_ASSESSMENT = 'RESPONSIBLE_GAMING_SELF_ASSESSMENT',
    RESPONSIBLE_GAMING_UNTIL_FURTHER_NOTICE_EXCLUSION = 'RESPONSIBLE_GAMING_UNTIL_FURTHER_NOTICE_EXCLUSION',
    RETAIL_HEADER = 'RETAIL_HEADER',
    RETAIL_HEADER_HOME_BUTTON = 'RETAIL_HEADER_HOME_BUTTON',
    RTP_CALCULATOR = 'RTP_CALCULATOR',
    RTP_THUMBNAIL = 'RTP_THUMBNAIL',
    SEND_CURRENCY = 'SEND_CURRENCY',
    SEO = 'SEO',
    SEO_INTRO = 'SEO_INTRO',
    SEPARATE_REQUESTS_ON_NORMAL_PLUS_MA_BET = 'SEPARATE_REQUESTS_ON_NORMAL_PLUS_MA_BET',
    SET_YOUR_PASSWORD = 'SET_YOUR_PASSWORD',
    SIMPLE_COMBO = 'SIMPLE_COMBO',
    SPONSORSHIP_BANNER = 'SPONSORSHIP_BANNER',
    SPORTSBOOK = 'SPORTSBOOK',
    SPORTSBOOK_SETTINGS = 'SPORTSBOOK_SETTINGS',
    SPORT_LAYOUT_A = 'SPORT_LAYOUT_A',
    SPORT_PHONE_NAVIGATION_C = 'SPORT_PHONE_NAVIGATION_C',
    SPORT_TICKET_SHARING = 'SPORT_TICKET_SHARING',
    STATE_NOTICE = 'STATE_NOTICE',
    SUBSCRIPTION_SETTINGS_DIRECT_MAIL = 'SUBSCRIPTION_SETTINGS_DIRECT_MAIL',
    SUBSCRIPTION_SETTINGS_PHONE_CALLS = 'SUBSCRIPTION_SETTINGS_PHONE_CALLS',
    SUBSCRIPTION_SETTINGS_REVOKE_CONSENT = 'SUBSCRIPTION_SETTINGS_REVOKE_CONSENT',
    SUPPORT = 'SUPPORT',
    SYSTEM_ANALYTICS = 'SYSTEM_ANALYTICS',
    TEASERS = 'TEASERS',
    THEME_SINGLE_TONE = 'THEME_SINGLE_TONE',
    TICKET_TRACKING = 'TICKET_TRACKING',
    TOGGLE_RETAIL_BALANCE_VISIBILITY = 'TOGGLE_RETAIL_BALANCE_VISIBILITY',
    TWO_FACTOR_AUTHENTICATION = 'TWO_FACTOR_AUTHENTICATION',
    UNIFIED_LOGIN_AND_REGISTER = 'UNIFIED_LOGIN_AND_REGISTER',
    USER_BETTING_PERFORMANCE = 'USER_BETTING_PERFORMANCE',
    USER_FAVORITES = 'USER_FAVORITES',
    VIRTUAL_SPORTS = 'VIRTUAL_SPORTS', // the whole virtual sports product (betradar and highlight games combined)
    VOLATILITY_THUMBNAIL = 'VOLATILITY_THUMBNAIL',
    VS_HIGHLIGHT_GAMES = 'VS_HIGHLIGHT_GAMES',
    VS_HIGHLIGHT_GAMES_IIHF = 'VS_HIGHLIGHT_GAMES_IIHF',
    VS_HIGHLIGHT_GAMES_SOCCERBET = 'VS_HIGHLIGHT_GAMES_SOCCERBET',
    WAGER_LIMIT_LINK = 'WAGER_LIMIT_LINK',
    WELCOME_BONUS_LIST_POKER = 'WELCOME_BONUS_LIST_POKER',
    WELCOME_BONUS_LIST_SPORT = 'WELCOME_BONUS_LIST_SPORT',
    WELCOME_PAGE = 'WELCOME_PAGE',
    WELCOME_PAGE_ROUTE = 'WELCOME_PAGE_ROUTE',
    WINNERS_FEED = 'WINNERS_FEED',
    WINNERS_FEED_TOP_WINNERS = 'WINNERS_FEED_TOP_WINNERS',
    ZE_CHAT_BOT = 'ZE_CHAT_BOT',
    PAYMENT_METHODS_INFO = 'PAYMENT_METHODS_INFO',
    SMART_ID = 'SMART_ID',
    MANUAL_LOYALTY_INPUT = 'MANUAL_LOYALTY_INPUT',
    RETAIL_W2G = 'RETAIL_W2G',
}

const featureInheritance = {
    [FEATURE.CASINO_HAPPY_HOUR]: FEATURE.CASINO,
    [FEATURE.DEPOSIT_LOCK]: FEATURE.DEPOSIT,
};

const disabledCountriesByFeature: Partial<Record<FEATURE, readonly COUNTRY[]>> = {
    [FEATURE.GENIUS_PLAYER]: [COUNTRY.CANADA],
    [FEATURE.ADDRESS_AUTOCOMPLETE]: Object.values(COUNTRY).filter((country) => country !== COUNTRY.CANADA),
    [FEATURE.POKER]: [COUNTRY.MEXICO],
    [FEATURE.REFER_A_FRIEND]: [COUNTRY.NORWAY, COUNTRY.SWEDEN],
    [FEATURE.RTP_CALCULATOR]: [COUNTRY.CHILE, COUNTRY.ECUADOR, COUNTRY.MEXICO, COUNTRY.PERU],
    [FEATURE.WELCOME_BONUS_LIST_POKER]: [COUNTRY.MEXICO],
} as const;

const disabledLicencesByFeature: Partial<Record<FEATURE, Licence[]>> = {
    [FEATURE.BETBUILDER]: [Licence.SWEDEN],
    [FEATURE.CASINO_HAPPY_HOUR]: [Licence.SWEDEN],
    [FEATURE.CASINO_PAYBACK_BOOSTER]: [Licence.SWEDEN],
    [FEATURE.CASINO_RACE]: [Licence.SWEDEN],
    [FEATURE.CASINO_RULES]: getLicencesExcluding([Licence.USA_NEVADA]),
    [FEATURE.COOLBET_LEAGUE]: [Licence.SWEDEN],
    [FEATURE.DEVICE_FINGERPRINT_SEON]: [Licence.SWEDEN],
    [FEATURE.GEOCOMPLY]: getLicencesExcluding([Licence.USA_NEVADA]),
    [FEATURE.LEAGUE]: [Licence.SWEDEN],
    [FEATURE.POKER_MARKETING_ONLY]: getLicencesExcluding([Licence.MEXICO]),
    [FEATURE.REFER_A_FRIEND]: [Licence.SWEDEN],
    [FEATURE.RESPONSIBLE_GAMING_UNTIL_FURTHER_NOTICE_EXCLUSION]: getLicencesExcluding([Licence.MALTA, Licence.SWEDEN]),
    [FEATURE.RTP_CALCULATOR]: [Licence.MEXICO],
    [FEATURE.SIMPLE_COMBO]: [Licence.SWEDEN],
    [FEATURE.WELCOME_BONUS_LIST_POKER]: [Licence.SWEDEN],
};

const disabledAppTypesByFeature: Partial<Record<FEATURE, AppType[]>> = {
    [FEATURE.GEOCOMPLY]: [AppType.ANDROID, AppType.IOS],
};

const disabledFeaturesForRetail: FEATURE[] = [FEATURE.BONUSES, FEATURE.BONUS_OFFERS];

const ENABLE_NEW_FEATURES_SERVICE = 'enable-fo-flags';

export function isFeatureAvailable(feature: FEATURE) {
    const parentFeature = featureInheritance[feature];
    if (parentFeature && !isFeatureAvailable(parentFeature)) {
        return false;
    }
    const clientConfig = getStoreValue(stores.clientConfig) as any;
    const features = getStoreValue(stores.features);
    const appType = getStoreValue(stores.appType);
    const client = getClient();
    const isFeatureDisabled =
        disabledLicencesByFeature[feature]?.includes(getLicence()) ||
        disabledCountriesByFeature[feature]?.includes(getUserCountry()) ||
        (isRetail() && disabledFeaturesForRetail.includes(feature)) ||
        disabledAppTypesByFeature[feature]?.includes(appType) ||
        (isProd() && DisabledProductionFeaturesByClient[client]?.includes(feature));
    if (features.includes(ENABLE_NEW_FEATURES_SERVICE) && !isFeatureDisabled) {
        return features.includes(feature as string);
    }
    if (clientConfig[feature] && clientConfig[feature].disabled) {
        return false;
    }

    if (isFeatureDisabled) {
        return false;
    }
    if (feature === FEATURE.BETBUILDER && !isBetbuilderConfigured()) {
        return false;
    }
    return true;
}

export function isFeatureIPBlocked(feature: FEATURE) {
    return Boolean(getStoreValue(stores.blockedProducts)[feature]);
}

export function isGeniusStreamAvailable() {
    const ipCountry = getStoreValue(stores.ipCountry);
    return !(disabledCountriesByFeature[FEATURE.GENIUS_PLAYER] || []).includes(ipCountry as never);
}

function getLicencesExcluding(licences: Licence[]) {
    return Object.values(Licence).filter((licence) => !licences.includes(licence));
}

function isBetbuilderConfigured() {
    if (!isB2B()) {
        return true;
    }
    const { BETBUILDER_WIDGET_URL, BETBUILDER_WIDGET_KEY } = getStoreValue(environment);
    return Boolean(BETBUILDER_WIDGET_URL && BETBUILDER_WIDGET_KEY);
}

const DISABLED_WYNNBET_COMMON_FEATURES = [
    FEATURE.ABOUT,
    FEATURE.ACCOUNT_LINKING,
    FEATURE.ACCOUNT_VERIFICATION,
    FEATURE.BLOCKED_PRODUCTS_CHECK,
    FEATURE.CASINO,
    FEATURE.CASINO_RACE,
    FEATURE.COOKIE_BOT,
    FEATURE.CONTESTS,
    FEATURE.DOWNLOAD_ALL_MY_DATA,
    FEATURE.INBOX,
    FEATURE.LIVE_CHAT_KUSTOMER,
    FEATURE.MATCH_TURNOVER,
    FEATURE.MULTI_CURRENCY_WALLET,
    FEATURE.PAY_AND_PLAY,
    FEATURE.POKER,
    FEATURE.PREMATCH_STATISTICS,
    FEATURE.PROFILE_OCCUPATION_VALIDATION,
    FEATURE.QUICKLINKS_SINGLE_TOP_LEAGUE,
    FEATURE.QUICKSTAKE_INCREMENTAL,
    FEATURE.REGISTRATION,
    FEATURE.REPORT_FEEDBACK,
    FEATURE.RESPONSIBLE_GAMING_ADVANCED_TIMEOUTS,
    FEATURE.RETAIL_HEADER,
    FEATURE.SEO,
    FEATURE.SEO_INTRO,
    FEATURE.SPORT_LAYOUT_A,
    FEATURE.SPORT_TICKET_SHARING,
    FEATURE.THEME_SINGLE_TONE,
    FEATURE.TWO_FACTOR_AUTHENTICATION,
    FEATURE.USER_BETTING_PERFORMANCE,
    FEATURE.VIRTUAL_SPORTS,
    FEATURE.TICKET_TRACKING,
    FEATURE.IP_CHECK,
    FEATURE.UNIFIED_LOGIN_AND_REGISTER,
    FEATURE.MANAGEMENT_PAGE,
];

export const DisabledFeaturesByClient: Omit<
    Record<typeof ClientName[keyof typeof ClientName], string[]>,
    'coolbet' | 'station'
> = {
    [ClientName.DEMO]: [
        FEATURE.ACCOUNT_LINKING,
        FEATURE.ACCOUNT_VERIFICATION,
        FEATURE.ANIMATED_BACKGROUND,
        FEATURE.BETBUILDER_REGIONS,
        FEATURE.BLOCKED_PRODUCTS_CHECK,
        FEATURE.COOKIE_BOT,
        FEATURE.DEPOSIT_LOCK,
        FEATURE.DISPLAY_DOLLAR_SIGN,
        FEATURE.DOWNLOAD_ALL_MY_DATA,
        FEATURE.EVENT_TYPE_MAPPING,
        FEATURE.EXTERNAL_WALLET,
        FEATURE.FREE_BET_DEDUCTION,
        FEATURE.LIVE_CHAT_KUSTOMER,
        FEATURE.LOGIN_GEO_LOCATION,
        FEATURE.MOBILE_WEB_APP_INSTALL,
        FEATURE.MULTI_CURRENCY_WALLET,
        FEATURE.PUSH_TO_APP_INSTALL,
        FEATURE.REGISTRATION_B2B_BASIC,
        FEATURE.REPORT_FEEDBACK,
        FEATURE.RETAIL_HEADER,
        FEATURE.STATE_NOTICE,
        FEATURE.SPORT_PHONE_NAVIGATION_C,
        FEATURE.SYSTEM_ANALYTICS,
        FEATURE.MARKETING_INCOME_ACCESS_AFFILIATE,
        FEATURE.TWO_FACTOR_AUTHENTICATION,
        FEATURE.WAGER_LIMIT_LINK,
        FEATURE.WELCOME_PAGE_ROUTE,
        FEATURE.ZE_CHAT_BOT,
        FEATURE.UNIFIED_LOGIN_AND_REGISTER,
        FEATURE.SEPARATE_REQUESTS_ON_NORMAL_PLUS_MA_BET,
        FEATURE.RETAIL_W2G,
    ],
    [ClientName.IVC]: [
        FEATURE.ABOUT,
        FEATURE.ACCOUNT_LINKING,
        FEATURE.ACCOUNT_VERIFICATION,
        FEATURE.ANIMATED_BACKGROUND,
        FEATURE.AUTH_PROFILE_VALIDATOR,
        FEATURE.BANNER_MESSAGE,
        FEATURE.BETBUILDER_REGIONS,
        FEATURE.BETSLIP_BANKERS,
        FEATURE.BLOCKED_PRODUCTS_CHECK,
        FEATURE.BLOG,
        FEATURE.BONUS_OFFERS,
        FEATURE.CASINO_RACE,
        FEATURE.CASINO,
        FEATURE.CONTESTS,
        FEATURE.COOKIE_BOT,
        FEATURE.DEPOSIT,
        FEATURE.DOWNLOAD_ALL_MY_DATA,
        FEATURE.EVENT_TYPE_MAPPING,
        FEATURE.EXTERNAL_WALLET,
        FEATURE.FEATURED_EVENTS,
        FEATURE.FOOTER,
        FEATURE.FREE_BET_DEDUCTION,
        FEATURE.HEADER,
        FEATURE.HORSE_RACING,
        FEATURE.INBOX,
        FEATURE.INCOMING_BETS,
        FEATURE.IP_CHECK,
        FEATURE.LANGUAGE_SELECTION,
        FEATURE.LEADER_BOARD,
        FEATURE.LEAGUE,
        FEATURE.LIVE_CHAT_KUSTOMER,
        FEATURE.LOGIN_GEO_LOCATION,
        FEATURE.LOGIN,
        FEATURE.MATCH_OF_THE_DAY,
        FEATURE.MATCH_TURNOVER,
        FEATURE.MOBILE_WEB_APP_INSTALL,
        FEATURE.MULTI_CURRENCY_WALLET,
        FEATURE.PAY_AND_PLAY,
        FEATURE.POKER,
        FEATURE.PREMATCH_STATISTICS,
        FEATURE.PROFILE_OCCUPATION_VALIDATION,
        FEATURE.PROMOTIONS,
        FEATURE.PUSH_TO_APP_INSTALL,
        FEATURE.REGISTRATION_B2B_BASIC,
        FEATURE.REPORT_FEEDBACK,
        FEATURE.RETAIL_HEADER_HOME_BUTTON,
        FEATURE.STATE_NOTICE,
        FEATURE.SEO_INTRO,
        FEATURE.SEO,
        FEATURE.SIMPLE_COMBO,
        FEATURE.SPORT_PHONE_NAVIGATION_C,
        FEATURE.SPORT_TICKET_SHARING,
        FEATURE.SPORTSBOOK_SETTINGS,
        FEATURE.SYSTEM_ANALYTICS,
        FEATURE.MARKETING_INCOME_ACCESS_AFFILIATE,
        FEATURE.TOGGLE_RETAIL_BALANCE_VISIBILITY,
        FEATURE.TWO_FACTOR_AUTHENTICATION,
        FEATURE.USER_BETTING_PERFORMANCE,
        FEATURE.VIRTUAL_SPORTS,
        FEATURE.WAGER_LIMIT_LINK,
        FEATURE.WELCOME_PAGE_ROUTE,
        FEATURE.WINNERS_FEED,
        FEATURE.ZE_CHAT_BOT,
        FEATURE.UNIFIED_LOGIN_AND_REGISTER,
        FEATURE.SEPARATE_REQUESTS_ON_NORMAL_PLUS_MA_BET,
        FEATURE.MANAGEMENT_PAGE,
        FEATURE.RETAIL_W2G,
    ],
    [ClientName.ORCA]: [
        FEATURE.ANIMATED_BACKGROUND,
        FEATURE.BETBUILDER_REGIONS,
        FEATURE.CONTESTS,
        FEATURE.DIGITAL_BOARD,
        FEATURE.DISPLAY_DOLLAR_SIGN,
        FEATURE.EVENT_TYPE_MAPPING,
        FEATURE.EXTERNAL_WALLET,
        FEATURE.FREE_BET_DEDUCTION,
        FEATURE.HORSE_RACING,
        FEATURE.LEADER_BOARD,
        FEATURE.LOGIN_GEO_LOCATION,
        FEATURE.MOBILE_WEB_APP_INSTALL,
        FEATURE.PARLAY_CARD,
        FEATURE.PUSH_TO_APP_INSTALL,
        FEATURE.REGISTRATION_B2B_BASIC,
        FEATURE.REGISTRATION_B2B,
        FEATURE.RETAIL_HEADER,
        FEATURE.STATE_NOTICE,
        FEATURE.SPORT_PHONE_NAVIGATION_C,
        FEATURE.WAGER_LIMIT_LINK,
        FEATURE.ZE_CHAT_BOT,
        FEATURE.UNIFIED_LOGIN_AND_REGISTER,
        FEATURE.SEPARATE_REQUESTS_ON_NORMAL_PLUS_MA_BET,
        FEATURE.MANAGEMENT_PAGE,
        FEATURE.RETAIL_W2G,
    ],
    [ClientName.WYNNBET]: [
        ...DISABLED_WYNNBET_COMMON_FEATURES,
        FEATURE.AUTH_PROFILE_VALIDATOR,
        FEATURE.BETSLIP_BANKERS,
        FEATURE.BETSLIP_CONFIRM,
        FEATURE.BETSLIP_KEEP_SELECTIONS,
        FEATURE.BETSLIP_NAVIGATION,
        FEATURE.BLOG,
        FEATURE.CAMPAIGNS,
        FEATURE.DARK_THEME,
        FEATURE.DEPOSIT,
        FEATURE.FOOTER,
        FEATURE.GEOCOMPLY,
        FEATURE.HEADER,
        FEATURE.HIGHEST_TURNOVER,
        FEATURE.HORSE_RACING,
        FEATURE.LOGIN_GEO_LOCATION,
        FEATURE.LOGIN,
        FEATURE.MATCH_OF_THE_DAY,
        FEATURE.MOBILE_WEB_APP_INSTALL,
        FEATURE.PROMOTIONS,
        FEATURE.PUSH_TO_APP_INSTALL,
        FEATURE.REFER_A_FRIEND,
        FEATURE.REGISTRATION_B2B_BASIC,
        FEATURE.REGISTRATION_B2B,
        FEATURE.RESPONSIBLE_GAMING,
        FEATURE.RETAIL_HEADER_HOME_BUTTON,
        FEATURE.SIMPLE_COMBO,
        FEATURE.SPONSORSHIP_BANNER,
        FEATURE.THEME_SINGLE_TONE,
        FEATURE.TOGGLE_RETAIL_BALANCE_VISIBILITY,
        FEATURE.WELCOME_PAGE_ROUTE,
        FEATURE.ZE_CHAT_BOT,
        FEATURE.SEPARATE_REQUESTS_ON_NORMAL_PLUS_MA_BET,
        FEATURE.RETAIL_W2G,
    ],
    [ClientName.WYNNBET_NEVADA]: [
        ...DISABLED_WYNNBET_COMMON_FEATURES,
        FEATURE.ACCOUNT_VERIFICATION,
        FEATURE.ANIMATED_BACKGROUND,
        FEATURE.AUTH_PROFILE_VALIDATOR,
        FEATURE.BETSLIP_BANKERS,
        FEATURE.BETSLIP_FULL_MARKET_NAME,
        FEATURE.DEPOSIT_LOCK,
        FEATURE.DOWNLOAD_ALL_MY_DATA,
        FEATURE.EVENT_TYPE_MAPPING,
        FEATURE.EXTERNAL_WALLET,
        FEATURE.FREE_BET_DEDUCTION,
        FEATURE.FULL_MY_ACCOUNT_LAYOUT,
        FEATURE.GAMBLING_STATISTICS,
        FEATURE.INCOMING_BETS,
        FEATURE.LANGUAGE_FLAG,
        FEATURE.LEADER_BOARD,
        FEATURE.LEAGUE,
        FEATURE.MOBILE_WEB_APP_INSTALL,
        FEATURE.MY_ACCOUNT_EDITABLE,
        FEATURE.MY_ACCOUNT_INFO_MESSAGE,
        FEATURE.MY_ACCOUNT_LANGUAGE_SELECTION,
        FEATURE.OTHER_LOGIN_OPTIONS,
        FEATURE.PROCESSES_I_AM_INVOLVED_IN,
        FEATURE.PUSH_TO_APP_INSTALL,
        FEATURE.RESPONSIBLE_GAMING_BETTING_ADVICE,
        FEATURE.RESPONSIBLE_GAMING_COMMITMENT,
        FEATURE.RESPONSIBLE_GAMING_SELF_ASSESSMENT,
        FEATURE.SET_YOUR_PASSWORD,
        FEATURE.SPORT_PHONE_NAVIGATION_C,
        FEATURE.SUBSCRIPTION_SETTINGS_DIRECT_MAIL,
        FEATURE.SUBSCRIPTION_SETTINGS_PHONE_CALLS,
        FEATURE.SUBSCRIPTION_SETTINGS_REVOKE_CONSENT,
        FEATURE.WAGER_LIMIT_LINK,
        FEATURE.WELCOME_PAGE,
        FEATURE.WINNERS_FEED,
    ],
};

// TODO: check how to deprecate this
export const DisabledProductionFeaturesByClient: Record<typeof ClientName[keyof typeof ClientName], string[]> = {
    [ClientName.COOLBET]: [FEATURE.PARLAY_CARD],
    [ClientName.DEMO]: [],
    [ClientName.IVC]: [FEATURE.MATCH_TURNOVER],
    [ClientName.ORCA]: [],
    [ClientName.STATION]: [],
    [ClientName.WYNNBET]: [],
    [ClientName.WYNNBET_NEVADA]: [FEATURE.BETBUILDER],
};
