import styled, { css } from 'styled-components';

const width = ({ width }) =>
    width &&
    css`
        height: initial;
        width: ${width}rem;
    `;

export default styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        aspect-ratio: 1;
        height: ${(props) => (props.size ? `${props.size}rem` : '')};
        width: ${(props) => (props.size ? `${props.size}rem` : '100%')}; //fix for ios
        transform: rotate(${(props) => (props.rotate ? props.rotate : '')}deg);
        color: ${(props) => (props.color ? `${props.color}` : '')};
        ${width}
    }
    .cls-1 {
        fill: currentColor;
    }
`;
