import React from 'react';
import Wrapper from './styles';
import UiCountdown from '../../../ui/countdown/UiCountdown';
import moment from 'moment';
import { translate } from '../../../../services/translate';
import UiDuration from '../../../ui/duration/UiDuration';
import capitalize from 'lodash/capitalize';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    endDate?: string;
    minutesWithExtraBoost?: number;
    onExpired?: () => void;
    isSidebarWidget?: boolean;
    isCasinoLocked?: boolean;
}

export default function CasinoPaybackBoosterCountdown({
    endDate,
    minutesWithExtraBoost,
    onExpired,
    isSidebarWidget = false,
    isCasinoLocked = false,
}: Props) {
    const [{ isPhone }] = useStore(media);

    const isSmallWidth = isSidebarWidget || isPhone;
    return (
        <Wrapper isCasinoLocked={isCasinoLocked} isSmallWidth={isSmallWidth}>
            {endDate ? (
                <UiCountdown isLocalized={true} endDate={endDate} onElapsed={onExpired}>
                    {({ days, daysLabel, hours, hoursLabel, minutes, minutesLabel, seconds, secondsLabel }) => (
                        <>
                            {days !== '0' && (
                                <span className="booster-time-unit">
                                    {days} {isSmallWidth ? daysLabel[0] : capitalize(daysLabel)}
                                </span>
                            )}
                            {(hours !== '0' || minutes === '0') && (
                                <span className="booster-time-unit">
                                    {hours} {isSidebarWidget || isPhone ? hoursLabel[0] : capitalize(hoursLabel)}
                                </span>
                            )}
                            {days === '0' && (
                                <span className="booster-time-unit">
                                    {minutes} {isSidebarWidget || isPhone ? minutesLabel[0] : capitalize(minutesLabel)}
                                </span>
                            )}
                            {(hours === '0' || (hours === '1' && minutes === '0')) && (
                                <span className="booster-time-unit">
                                    {seconds} {isSidebarWidget || isPhone ? secondsLabel[0] : capitalize(secondsLabel)}
                                </span>
                            )}
                            <div className="booster-time-left-title">{translate('left', 'casino.booster')}</div>
                        </>
                    )}
                </UiCountdown>
            ) : (
                <>
                    <div className="booster-time-left-title">
                        {translate('Duration with extra boost:', 'casino.booster')}
                    </div>
                    <UiDuration duration={moment.duration({ minutes: minutesWithExtraBoost })} isVerbose={true} />
                </>
            )}
        </Wrapper>
    );
}
