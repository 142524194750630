import styled from 'styled-components';

export default styled.div`
    position: fixed;
    top: 80px;
    bottom: 0;
    right: 0;
    z-index: 4;

    overflow: auto;
    max-height: 100vh;
    width: 300px;

    border-radius: var(--ui-radius);
    background-color: var(--surface-level-1-bg);

    font-family: ${({ theme }) => theme.typography.fontFamily};
    line-height: 1;

    padding: 0.8rem;
`;
