import styled from 'styled-components';

export default styled.div`
    font-size: 0.75rem;
    .pay-and-play-amount input {
        border: 2px solid var(--color-highlight);
        text-align: center;
        //max-width: 100px;
    }
    .pay-and-play-currency {
        min-width: 75px;
    }

    .pay-and-play-currency select,
    .pay-and-play-amount input {
        height: 50px;
    }
    .alternative-option-link {
        margin-top: 1rem;
        a {
            margin-left: 1.5px;
        }
    }

    .pay-and-play-deposit {
        height: 55px;
        font-size: 0.875rem;
        margin-top: 0.8rem;
        padding: 0px 2.5rem;
    }

    .description {
        color: var(--font-color-secondary);
        padding-left: 10px;
    }

    .steps {
        color: var(--font-color-secondary);
        font-size: 0.875rem;
        line-height: 1.2rem;
        padding: 1rem 1.2rem;
    }

    .pay-and-play-terms {
        margin-top: 1rem;
    }

    h3 {
        color: var(--font-color-secondary);
        display: flex;
        font-size: 0.875rem;

        .plus-sign {
            width: 0.625rem;
            margin-right: 12px;
        }
    }

    .bonus-code-input {
        input {
            margin-bottom: 0;
        }
    }
`;
