import React from 'react';
import Wrapper from './styles';
import ComboCardsItemType from '../type/ComboCardsItemType';
import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';

interface Props {
    card: FoComboCardWithOdds;
}

export default function ComboCardsItemHeader({ card }: Props) {
    const numberOfOutcomes = card.matches.reduce((acc, match) => acc + match.markets.length, 0);

    return (
        <Wrapper>
            <ComboCardsItemType type={card.type} />
            <div className="combo-card-name">
                <div className="combo-name">{card.name}</div>
                <div className="card-outcomes-count">{numberOfOutcomes}</div>
            </div>
        </Wrapper>
    );
}
