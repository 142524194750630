import styled from 'styled-components';

export default styled.div`
    .combo-selection {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-left: var(--spacing-16);
        line-height: 120%;

        .selection-market {
            margin: 0.25rem;
            color: var(--match-score-text-color);
            font-family: Roboto;
            font-size: 0.72rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            &:before {
                position: absolute;
                left: -1.1rem;
                content: ' ';
                border: 0.2rem solid var(--color-odds);
                background: var(--color-odds);
                border-radius: 7px;
                margin-top: 2%;
                width: 5px;
                height: 5px;
            }
        }
    }

    .show-all-text {
        margin-left: var(--spacing-16);
        color: var(--font-link-color);
        cursor: pointer;
        letter-spacing: 0.005rem;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-decoration: none;
    }
`;
