import React from 'react';
import Wrapper from './styles';
import { StreamInfo } from '../../../../services/sports/types';
import { isStreamAllowed, streamProvider } from '../../../../services/stream';

interface Props {
    streamInfo: StreamInfo;
    categoryId: number;
}

export default function SportMatchStream({ streamInfo, categoryId }: Props) {
    const { url, provider } = streamInfo;
    if (!isStreamAllowed(categoryId)) {
        return null;
    }

    const baseUrlsBuilder = {
        [streamProvider.TWITCH]: (url) => `https://player.twitch.tv/?channel=${url}&parent=${window.location.hostname}`,
        [streamProvider.YOUTUBE]: (url) => `https://www.youtube.com/embed/${url}?autoplay=1`,
        [streamProvider.VIMEO]: (url) => `https://player.vimeo.com/video/${url}`,
    };

    const matchIframeSrc = url.includes(provider.toLowerCase()) ? url : baseUrlsBuilder[provider](url);

    return (
        <Wrapper>
            <iframe title="sport-match-stream" src={matchIframeSrc} />
        </Wrapper>
    );
}
