import styled from 'styled-components';

export default styled.div`
    .table-cell {
        justify-content: center;
    }

    .table-row.active {
        .table-cell {
            background-color: var(--color-highlight);
        }
    }

    .leaderboard-modal-box {
        max-height: 90vh;
        display: flex;
        flex-direction: column;

        .leaderboard-header {
            height: 100px;
            flex: 0 0 100px;
        }

        .leaderboard-positions {
            width: 100%;
            flex: 1;
            overflow: scroll;
        }
    }
`;
