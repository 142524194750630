import React from 'react';
import Wrapper from './styles';
import CrmMascot from '../mascot/CrmMascot';
import UiButton from '../../ui/button/UiButton';
import UiCloseButton from '../../ui/close-button/UiCloseButton';
import { stores } from '../../../stores';
import * as classnames from 'classnames';
import { storageSet } from '../../../services/storage';
import { OnsiteMessage, confirmMessageDelivery, trackOnSiteMessage } from '../../../microservices/crm';
import { closeCrmMessageAndUpdate } from '../../../services/crm';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../services/analytics';
import CasinoHappyHourCrmMessage from '../../casino/happy-hour/crm-message/CasinoHappyHourCrmMessage';
import { getStoreValue } from '../../../stores/store/utils';
import { filterStyleProps } from '../../../styles/utils';
import { useStore } from '../../../hooks/useStore';

interface Props {
    previewMessage?: OnsiteMessage;
}

export default function CrmMessage({ previewMessage, ...rest }: Props) {
    const [storeIsMessageOpen] = useStore(stores.crm.isMessageOpen);
    const [storeOnsiteMessage] = useStore(stores.crm.onsite);
    const [storeOnsiteMessageOpenedBy] = useStore(stores.crm.openedBy);
    const onsiteMessage = previewMessage || storeOnsiteMessage;
    const isMessageOpen = previewMessage ? true : storeIsMessageOpen;
    const googleTagPayload = {
        on_site_message_name: storeOnsiteMessage?.campaignName,
        on_site_message_id: storeOnsiteMessage?.campaignId,
    };

    function handleCtaClick() {
        if (onsiteMessage && onsiteMessage.status !== 'cta_clicked') {
            trackOnSiteMessage(onsiteMessage.id, 'cta_clicked');
        }
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.CRM_CTA_CLICKED, googleTagPayload);
    }

    if (!onsiteMessage || getStoreValue(stores.applicationType) === 'poker-download') {
        return null;
    }

    const timeNow = new Date();
    storageSet('lastCrmMessageSeenAt', timeNow);

    if (onsiteMessage.id && !onsiteMessage.seenAt) {
        const googleTagMessageShownPayload = {
            ...googleTagPayload,
            on_site_message_open_by: storeOnsiteMessageOpenedBy,
        };
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.CRM_MESSAGE_SHOWN, googleTagMessageShownPayload);
        confirmMessageDelivery(onsiteMessage.id);
    }

    if (onsiteMessage.designTag && onsiteMessage.designTag.startsWith('happy-hour')) {
        return <CasinoHappyHourCrmMessage message={onsiteMessage} isPreview={Boolean(previewMessage)} />;
    }

    return (
        <Wrapper {...filterStyleProps({ ...rest })} hasMascot={onsiteMessage.mascotMessage}>
            <div className={classnames(['crm-message', isMessageOpen ? 'visible' : 'hidden'])}>
                <UiCloseButton
                    className="close-button"
                    onClick={() => closeCrmMessageAndUpdate()}
                    data-test="close-button"
                />

                {onsiteMessage.mascotMessage && (
                    <CrmMascot
                        mascot={onsiteMessage.mascotMessage}
                        type={onsiteMessage.type}
                        avatar={onsiteMessage.mascotAvatar}
                        className={classnames([' animated', isMessageOpen ? 'fadeIn' : 'fadeOutDown'])}
                    />
                )}
                {(onsiteMessage.title || onsiteMessage.description) && (
                    <div
                        className={classnames([
                            'crm-message-content animated',
                            isMessageOpen ? 'fadeIn' : 'fadeOutDown',
                        ])}
                    >
                        {onsiteMessage.image && (
                            <img className="crm-message-image" src={onsiteMessage.image} alt="message_icon" />
                        )}

                        {onsiteMessage.description && (
                            <div
                                className="crm-message-text"
                                dangerouslySetInnerHTML={{ __html: onsiteMessage.description }}
                            />
                        )}

                        {onsiteMessage.ctaLink && (
                            <div className="crm-message-cta" onClick={handleCtaClick}>
                                <a href={onsiteMessage.ctaLink}>
                                    <UiButton color="primary" block size="large">
                                        {onsiteMessage.ctaTitle}
                                    </UiButton>
                                </a>
                            </div>
                        )}
                    </div>
                )}
            </div>

            {isMessageOpen && <div className="message-blur" />}
        </Wrapper>
    );
}
