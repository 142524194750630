import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    $isRetailLayout?: boolean;
};

export default styled.div<StyledProps>`
    display: flex;
    max-width: 360px;
    margin: 0.4rem auto;
    button {
        font-size: 0.8rem;
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            font-size: 1.2rem;
        }
    }

    .first-column {
        flex: 70%;
    }

    .second-column {
        flex: 20%;
    }

    .normal-btn {
        height: 28px;
        display: inline-flex;
        margin: 0 1px 1px 0;
        border-radius: 0;
        width: calc(33.333% - 1px);
        @media ${mediaQuery.isLaptop}, ${mediaQuery.isDesktopSmall} {
            height: ${({ $isRetailLayout }) => ($isRetailLayout ? '50px' : '')};
        }
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            height: 40px;
        }
    }
    .last-row {
        width: 100%;
        display: flex;
    }

    .ok-btn,
    .backspace-btn {
        width: 100%;
        border-radius: 0;
    }

    .ok-btn {
        height: 86px;
        width: 100%;
        font-size: 1rem;
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            height: 122px;
        }
    }
    .zero-button {
        width: 66.66%;
        display: inline-flex;
    }
    .dot-button {
        width: 33.333%;
    }
`;
