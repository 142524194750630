import { TicketToPrintResponse } from '@staycool/retail-types/ticket';

export const retailMiddlewareUrl = 'http://localhost:3000';

export interface TerminalMachineIdResponse {
    machineId: string;
}

export interface TerminalIntegrity {
    passed: boolean;
    report?: { file: string; expected: string; checksum: string }[];
}

export interface RetailUserAuth {
    token: string;
    tickets: TicketToPrintResponse[];
    balance: number;
}

export interface TerminalConfig {
    timezone: string;
    terminalId?: number;
    proxyDomain?: string;
    ip?: string;
    mask?: string;
    gateway?: string;
    dns?: string;
    isDhcp?: boolean;
}

interface Note {
    id: number;
    value: number;
    terminal_id: number;
    status: NoteStatus;
}

enum NoteStatus {
    Accepted = 'Accepted',
    Rejected = 'Rejected',
    Stacked = 'Stacked',
}

interface CashboxReport {
    id: number;
    terminal_id: number;
    amount: number;
    notes: number[];
    created_at: Date;
}

export interface TerminalNotes {
    notes: Record<string, number>;
    amount: number;
}

export interface FullCashboxReport extends Omit<CashboxReport, 'notes'> {
    notes: Note[];
}

export const enum Mask {
    VoucherIdMaskRestricted = '____-...',
    TicketIdMaskRestricted = '________-____-...',
}

export function formatVaucherTicketId(input: string, mask: Mask) {
    input = input.replace(/-/g, '');
    if (!input.length) {
        return '';
    }

    return mask
        .replace(/-/g, ' ')
        .split('')
        .map((char, index) => (char === '_' ? input[index] : char))
        .join('')
        .trim()
        .replace(/\s/g, '-');
}
