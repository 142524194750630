import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { CasinoGame } from './games';
import cloneDeep from 'lodash/cloneDeep';
import keyBy from 'lodash/keyBy';
import { isTestUser } from '../user';

export interface CasinoProvider {
    id: number;
    name: string;
    abbreviation: string;
    totalGames: number;
    type: 'provider' | 'subProvider'; // TODO This logic is flawed. Games should have only provider (and parent provider should be set and used only for reports). This information should not come to FO.
    order?: number;
    url?: string;
}

export interface CasinoCategorySubprovider {
    id: number;
    name: string;
    order: number;
}

export enum CasinoProviders {
    PLAYGO = 1,
    YGGDRASIL = 2,
    MICROGAMING = 3,
    EVOLUTION = 4,
    // NETENT_LIVE = 5,
    // NETENT = 6,
    HUB88 = 7,
    ELK_STUDIOS = 8,
    PRAGMATIC_PLAY = 9,
    ISOFTBET = 10,
    RELAXGAMING = 11,
    PUSHGAMING = 12,
    NOLIMIT_CITY = 13,
    QUICKSPIN = 14,
    SPINOMENAL = 15,
    HACKSAW = 16,
    SUPERRGS = 17,
    STAKELOGIC = 18,
    LIGHTNWONDER = 19,
    PLAYTECH = 20,
}

export const CasinoProviderIntegrationNames = {
    [CasinoProviders.PLAYGO]: 'playgo',
    [CasinoProviders.YGGDRASIL]: 'yggdrasil',
    [CasinoProviders.MICROGAMING]: 'microgaming',
    [CasinoProviders.EVOLUTION]: 'evolution',
    // [CasinoProviders.NETENT_LIVE]: 'netent',
    // [CasinoProviders.NETENT]: 'netent',
    [CasinoProviders.HUB88]: 'hub88',
    [CasinoProviders.ELK_STUDIOS]: 'elk',
    [CasinoProviders.PRAGMATIC_PLAY]: 'pragmaticplay',
    [CasinoProviders.ISOFTBET]: 'isoftbet',
    [CasinoProviders.RELAXGAMING]: 'relaxgaming',
    [CasinoProviders.PUSHGAMING]: 'pushgaming',
    [CasinoProviders.NOLIMIT_CITY]: 'nolimitcity',
    [CasinoProviders.QUICKSPIN]: 'quickspin',
    [CasinoProviders.SPINOMENAL]: 'spinomenal',
    [CasinoProviders.HACKSAW]: 'hacksaw',
    [CasinoProviders.SUPERRGS]: 'superrgs',
    [CasinoProviders.STAKELOGIC]: 'stakelogic',
    [CasinoProviders.LIGHTNWONDER]: 'lightnwonder',
    [CasinoProviders.PLAYTECH]: 'playtech',
};

export enum CasinoSubProvider {
    PLAYSON = 2,
    BOOMING_GAMES = 3,
    PRAGMATIC_PLAY = 4,
    PUSH_GAMING = 6,
    QUICKSPIN = 8,
    ALL41_STUDIOS = 9,
    JUST_FOR_THE_WIN = 11,
    TRIPLE_EDGE_STUDIOS = 14,
    FOXIUM = 17,
    RED_RAKE_GAMING = 18,
    SWITCH_STUDIOS = 19,
    '2BY2' = 21,
    BIG_TIME_GAMING = 27,
    ELECTRIC_ELEPHANT = 28,
    IRON_DOG_STUDIOS = 36,
    ONE_TOUCH = 40,
    WAZDAN = 78,
    NOLIMIT_CITY = 80,
    RED_TIGER = 83,
    RELAX_GAMING = 84,
    BLUEPRINT = 85,
    HACKSAW_GAMING = 88,
    THUNDERKICK = 95,
    SWINTT = 100,
    SPINOMENAL = 104,
    HABANERO = 105,
    SPRIBE = 107,
    EZUGI = 108,
    EVOLUTION = 124,
    NETENT = 126,
    PRAGMATIC_PLAY_LIVE = 132,
    GAMING_CORPS = 151,
    BOMBAY_LIVE = process.env.ENV === 'stage' ? 150 : 149,
    AUTHENTIC_GAMING = 197,
    ON_AIR = 131,
}

export const CasinoSubproviderIntegrationNames = {
    [CasinoSubProvider.AUTHENTIC_GAMING]: 'authentic-gaming',
    [CasinoSubProvider.BOMBAY_LIVE]: 'bombay-live',
};

export const ONAIR_ENTERTAINMENT_ORIGIN_STAGE = 'https://uat1.onairent.com';
export const ONAIR_ENTERTAINMENT_ORIGIN_PROD = 'https://live.onairent.com';

export const ONAIR_ENTERTAINMENT_EVENTS = {
    LAUNCH_GAME: 'oac_launch_game',
};

export const casinoProviderDisplaySettings = {
    minGames: 45,
};

export function mapCasinoProviders(): void {
    let providers = cloneDeep(getStoreValue<CasinoProvider[]>(stores.casino.providers));
    let categoriesSubproviders = cloneDeep(
        getStoreValue<CasinoCategorySubprovider[]>(stores.casino.categoriesSubproviders),
    );
    const games = getStoreValue<CasinoGame[]>(stores.casino.filteredGames).filter(
        (game) => game.isPublic || isTestUser(),
    );
    // TODO: dont loop all games 30 times. It takes only 10ms all together, but still it's a lot.
    providers.forEach((provider) => {
        let totalGames = 0;
        games.forEach((game) => {
            if (provider.type === 'provider' && !game.subProviderId && game.providerId === provider.id) {
                totalGames += 1;
            } else if (provider.type === 'subProvider' && game.subProviderId === provider.id) {
                totalGames += 1;
            }
        });
        provider.totalGames = totalGames;
    });
    providers = providers.filter((provider) => provider.totalGames > 0);
    categoriesSubproviders = categoriesSubproviders.filter((subprovider) =>
        providers.find((provider) => provider.name === subprovider.name),
    );

    stores.casino.providers.set(providers);
    stores.casino.categoriesSubproviders.set(categoriesSubproviders);
    stores.casino.providersById.set(
        keyBy(
            providers.filter((provider) => provider.type === 'provider'),
            'id',
        ),
    );
    stores.casino.subProvidersById.set(
        keyBy(
            providers.filter((provider) => provider.type === 'subProvider'),
            'id',
        ),
    );
    stores.casino.providersBySlug.set(keyBy(providers, (provider) => getCasinoProviderSlug(provider.name)));
}

export function getCasinoProviderSlug(name: string): string {
    return name
        .toLowerCase()
        .replace(/#|_|:|\.|;|,|!|^|\*|'|`|’/g, '')
        .split(' ')
        .join('-');
}

export function getCasinoSubProviderName(subProviderId: number) {
    return CasinoSubProvider[subProviderId]?.toLowerCase();
}
