import React from 'react';
import Wrapper from './styles';
import CrmInboxMessage from './message/CrmInboxMessage';
import { stores } from '../../../stores';
import UiAlert from '../../ui/alert/UiAlert';
import { translate } from '../../../services/translate';
import UiNavLink from '../../ui/nav-link/UiNavLink';
import { getRoute } from '../../../services/router';
import { useStore } from '../../../hooks/useStore';

interface Props {
    showAllMessages?: boolean;
}

export default function CrmInbox({ showAllMessages }: Props) {
    const maxMessages = 3;
    const [allMessages] = useStore(stores.crm.allMessages);
    const previewMessages = showAllMessages ? allMessages : allMessages?.slice(0, maxMessages);

    return (
        <Wrapper>
            <div className="crm-inbox">
                {!allMessages?.length && <UiAlert info>{translate('No new messages', 'crm.info')}</UiAlert>}

                {allMessages?.length > 0 && (
                    <>
                        {previewMessages.map((message, key) => (
                            <CrmInboxMessage message={message} key={key} />
                        ))}

                        {allMessages.length > maxMessages && (
                            <UiNavLink to={getRoute('inbox')} className="show-all-messages link">
                                {translate('Show all', 'crm.inbox')}
                            </UiNavLink>
                        )}
                    </>
                )}
            </div>
        </Wrapper>
    );
}
