import { useEffect } from 'react';

export function useScrollDirectionEvents() {
    useEffect(() => {
        let lastScrollY = 0;
        let scrollDirection: 'up' | 'down' | null = null;

        const scrollDownEvent = new Event('scrollDown');
        const scrollUpEvent = new Event('scrollUp');

        function onScrollHandler() {
            if (lastScrollY < window.scrollY) {
                if (scrollDirection !== 'down') {
                    scrollDirection = 'down';
                    window.dispatchEvent(scrollDownEvent);
                }
            } else {
                if (scrollDirection !== 'up') {
                    scrollDirection = 'up';
                    window.dispatchEvent(scrollUpEvent);
                }
            }

            lastScrollY = window.scrollY;
        }

        window.addEventListener('scroll', onScrollHandler);

        return () => window.removeEventListener('scroll', onScrollHandler);
    }, []);
}
