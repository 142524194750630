import React from 'react';
import PokerPlay from '../play/PokerPlay';
import { getClientType } from '../../../services/poker';
import { getOS, OPERATING_SYSTEM } from '../../../services/device';
import Wrapper from './styles';
import { translate } from '../../../services/translate';
import { getUserCountry } from '../../../services/user';
import { COUNTRY } from '../../../services/country';
import { FEATURE, isFeatureAvailable } from '../../../services/feature';

interface Props {
    showFeatures?: boolean;
}

export default function PokerDownload({ showFeatures }: Props) {
    if (getOS() === OPERATING_SYSTEM.LINUX || !isFeatureAvailable(FEATURE.POKER)) {
        return null;
    }

    function isSwedishMobile() {
        return (
            ![OPERATING_SYSTEM.APPLE_DESKTOP, OPERATING_SYSTEM.WINDOWS_DESKTOP].includes(getOS()) &&
            getUserCountry() === COUNTRY.SWEDEN
        );
    }

    return (
        <Wrapper $showFeatures={showFeatures} className="poker-download-legacy">
            <PokerPlay type={getClientType()} showFeatures={showFeatures} />
            {isSwedishMobile() && (
                <div className="poker-feature-tournament-warning-container">
                    <div className="poker-feature-tournament-warning">
                        <p>{translate('warning-1', 'poker.download')}</p>
                        <p>{translate('warning-2', 'poker.download')}</p>
                    </div>
                </div>
            )}
        </Wrapper>
    );
}
