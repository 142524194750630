import React from 'react';
import Wrapper from './styles';
import CmsQuizCollectionQuizAnswer from '../answer/CmsQuizCollectionQuizAnswer';
import { stores } from '../../../../../stores';
import { Question, Quiz } from '../../../../../microservices/quiz';
import { filterStyleProps } from '../../../../../styles/utils';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    question: Question;
    quiz: Quiz;
}
export default function CmsQuizCollectionQuizQuestion({ question, quiz, ...rest }: Props) {
    const [language] = useStore(stores.language);

    return (
        <Wrapper {...filterStyleProps({ ...rest })}>
            <div className="quiz-question">
                <div className="question-index">{question.question_number}.</div>
                <div className="question-text">{question.title[language] || question.title[quiz.default_language]}</div>
            </div>
            <div className="quiz-answers">
                {question.answers.map((answer) => (
                    <CmsQuizCollectionQuizAnswer key={answer.id} answer={answer} quiz={quiz} question={question} />
                ))}
            </div>
        </Wrapper>
    );
}
