import { translate } from './translate';

export interface BlogPost {
    id?: string;
    title: string;
    slug: string;
    body: string;
    body_raw: string;
    seo_title?: string;
    seo_desc?: string;
    seo_img?: string;
    category: string;
    blog: string;
    created?: Date;
    published: boolean;
    updated?: Date;
    published_from?: Date;
}

function stripTags(html: string, tagName: string): string {
    const div = document.createElement('div');
    div.innerHTML = html;
    Array.from(div.getElementsByTagName(tagName)).forEach((element) => {
        if (element && element.parentNode) {
            element.parentNode.removeChild(element);
        }
    });
    return div.innerHTML;
}

function removeButtons(html: string): string {
    const div = document.createElement('div');
    div.innerHTML = html;
    Array.from(div.getElementsByClassName('read-more-btn')).forEach((button) => {
        if (button && button.parentNode) {
            button.parentNode.removeChild(button);
        }
    });
    return div.innerHTML;
}

function removeTitle(html: string): string {
    const div = document.createElement('div');
    div.innerHTML = html;
    const titleNodes = div.getElementsByTagName('h1');
    if (titleNodes.length) {
        const title = titleNodes[0];
        if (title && title.parentNode) {
            title.parentNode.removeChild(title);
        }
    }
    return div.innerHTML;
}

export function getExcerpt(body: string, excerptLength = 150): string {
    const div = document.createElement('div');
    const excerptDiv = document.createElement('div');
    const imageWrapper = document.createElement('div');
    const contentWrapper = document.createElement('div');
    const previewDiv = document.createElement('div');
    const readMoreButton = document.createElement('a');
    const title = document.createElement('h1');

    previewDiv.className = 'preview';
    imageWrapper.className = 'col-sm-6';
    contentWrapper.className = 'col-sm-6';
    readMoreButton.className = 'read-more-btn';
    readMoreButton.href = '';
    readMoreButton.innerText = translate('Read More', 'ui.blog');
    let featuredImage: HTMLImageElement | undefined;
    const excerpt = document.createElement('p');

    div.innerHTML = body;
    const images = div.getElementsByTagName('img');
    if (images.length) {
        featuredImage = images[0];
    }
    const titles = div.getElementsByTagName('h1');
    if (titles.length) {
        Array.from(titles).forEach((_title: HTMLHeadElement) => {
            if (_title.innerText.length && !title.innerText) {
                title.innerText = _title.innerText.trim();
                if (title.innerText.length > 35) {
                    const nextSpace = title.innerText.indexOf(' ', 35);
                    title.innerText = `${title.innerText.slice(0, nextSpace !== -1 ? nextSpace : undefined)}...`;
                }
            }
        });
    }

    const strippedContent = removeTitle(removeButtons(stripTags(stripTags(body, 'script'), 'style')));
    const content = new DOMParser().parseFromString(strippedContent, 'text/html').documentElement.innerText;
    if (content && excerptLength) {
        const cutOffIndex = content.length > excerptLength ? content.indexOf(' ', excerptLength) : content.length;
        let excerptText = content.slice(0, cutOffIndex);
        if (!excerptText.endsWith('.') && !excerptText.endsWith('!') && !excerptText.endsWith('?')) {
            excerptText += '...';
        }
        excerpt.insertAdjacentHTML('afterbegin', excerptText);
    }

    if (title && featuredImage && excerpt) {
        previewDiv.insertAdjacentElement('afterbegin', title);
        previewDiv.insertAdjacentElement('beforeend', excerpt);
        previewDiv.insertAdjacentElement('beforeend', readMoreButton);
        contentWrapper.insertAdjacentElement('afterbegin', previewDiv);
        imageWrapper.insertAdjacentElement('afterbegin', featuredImage);
        excerptDiv.insertAdjacentElement('afterbegin', imageWrapper);
        excerptDiv.insertAdjacentElement('beforeend', contentWrapper);
    }
    return excerptDiv ? excerptDiv.innerHTML : '';
}
