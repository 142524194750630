import React from 'react';
import { BetslipMode } from '../../../../services/sports/betslip';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import Wrapper from './styles';
import UiButton from '../../../ui/button/UiButton';
import { initialBetSlipPlacingState } from '../../../../services/sports/constants';
import isEmpty from 'lodash/isEmpty';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    onBetslipModeChanged: (mode: BetslipMode) => void;
    betslipMode: BetslipMode;
}

export default function SportBetslipMode({ onBetslipModeChanged, betslipMode }: Props) {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [{ isPhone }] = useStore(media);

    if (!isAuthenticated || isPhone || !isEmpty(betSlipMarketIdToOutcomeId)) {
        return null;
    }

    function toggleBetSlipMode(mode: 'History' | 'Cashout') {
        if (betslipMode === BetslipMode[mode]) {
            onBetslipModeChanged(BetslipMode.Betslip);
            return;
        }

        onBetslipModeChanged(BetslipMode[mode]);
        if (BetslipMode.History) {
            stores.sports.betSlipPlacingState.set(initialBetSlipPlacingState);
        }
    }

    return (
        <Wrapper>
            <UiButton
                type="tab"
                size="small"
                selected={betslipMode === BetslipMode.History}
                onClick={() => toggleBetSlipMode('History')}
            >
                {translate('Bet History', 'ui.sportsbook')}
            </UiButton>

            <UiButton
                type="tab"
                size="small"
                selected={betslipMode === BetslipMode.Cashout}
                onClick={() => toggleBetSlipMode('Cashout')}
            >
                {translate('Cash Out', 'ui.sportsbook')}
            </UiButton>
        </Wrapper>
    );
}
