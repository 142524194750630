import React, { PropsWithChildren } from 'react';
import { isMobile } from '../../../../services/browser';
import Svg from '../../../svg/Svg';
import Wrapper from './styles';

export default function AccountVerificationBox({
    children,
    icon,
    description,
    title,
    dataTest,
    badgeColor = 'success',
    badgeText,
    isCompactView,
    ...rest
}: PropsWithChildren<
    {
        icon?: string;
        badgeColor?: string;
        description?: string;
        title?: string;
        badgeText?: string;
        dataTest?: string;
        isCompactView?: boolean;
    } & typeof Wrapper.defaultProps
>) {
    const isHeaderVisible = !!(icon || description);
    return (
        <Wrapper
            isBadgeVisible={!!badgeText}
            badgeColor={badgeColor}
            isCompactView={isCompactView || isMobile()}
            isHeaderVisible={isHeaderVisible}
            {...rest}
        >
            {isHeaderVisible && (
                <header className="header">
                    {icon && <Svg className="icon" icon={icon} />}
                    {description && <span className="description">{description}</span>}
                </header>
            )}

            <div className="content-container">
                {!!title && <span className="title">{title}</span>}
                {badgeText && (
                    <div className="badge-container" data-test={dataTest}>
                        <span className="badge">{badgeText}</span>
                    </div>
                )}
                {children}
            </div>
        </Wrapper>
    );
}
