import React from 'react';
import AuthProfileValidatorLicenceRequirementEstonia from './estonia/AuthProfileValidatorLicenceRequirementEstonia';
import AuthProfileValidatorLicenceRequirementMalta from './malta/AuthProfileValidatorLicenceRequirementMalta';
import Wrapper from './styles';
import { stores } from '../../../../stores';
import UiBox from '../../../../components/ui/box/UiBox';
import { getRoute, isActiveRoute } from '../../../../services/router';
import { getLicence } from '../../../../services/licence';
import { loadAskLimit } from '../../../../services/responsible-gaming';
import AuthProfileValidatorLicenceRequirementSweden from './sweden/AuthProfileValidatorLicenceRequirementSweden';
import { loadProfile } from '../../../../services/user';
import AuthProfileValidatorLicenceRequirementMexico from './mexico/AuthProfileValidatorLicenceRequirementMexico';
import { Licence } from '@staycool/location';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    isModal?: boolean;
    onExit?: () => void;
}

export default function AuthProfileValidatorLicenceRequirement({ isModal = false, onExit = () => {} }: Props) {
    const licence = getLicence();
    const [isRequiredProfileInformationInquiryModalOpen] = useStore(
        stores.modals.isRequiredProfileInformationInquiryModalOpen,
    );
    const [isEssentialUserInformationInquiryModalOpen] = useStore(
        stores.modals.isEssentialUserInformationInquiryModalOpen,
    );
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [user] = useStore(stores.user);

    if (
        !isAuthenticated ||
        !user ||
        !licence ||
        isEssentialUserInformationInquiryModalOpen ||
        (isModal && isActiveRoute(getRoute('registration')))
    ) {
        return null;
    }

    const validators = {
        [Licence.ESTONIA]: AuthProfileValidatorLicenceRequirementEstonia,
        [Licence.MALTA]: AuthProfileValidatorLicenceRequirementMalta,
        [Licence.SWEDEN]: AuthProfileValidatorLicenceRequirementSweden,
        [Licence.MEXICO]: AuthProfileValidatorLicenceRequirementMexico,
    };

    const Validator = validators[licence];

    if (!Validator) {
        return null;
    }

    const ValidatorElement = <Validator isModal={isModal} onAfterSubmit={onAfterSubmit} />;

    async function onAfterSubmit() {
        await Promise.all([loadProfile(), loadAskLimit()]);
        stores.modals.isRequiredProfileInformationInquiryModalOpen.set(false);
        onExit();
    }

    if (isModal && isRequiredProfileInformationInquiryModalOpen) {
        return (
            <Wrapper>
                <UiBox>{ValidatorElement}</UiBox>
            </Wrapper>
        );
    }

    return ValidatorElement;
}
