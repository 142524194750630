import styled from 'styled-components';

export default styled.div`
    .crm-message {
        max-height: calc(100vh - 25px);
        bottom: auto;
        .crm-message-content {
            .crm-message-text {
                max-height: none;
            }
        }
    }

    .custom-frame {
        width: 100vw;
        height: 100vh;
        background-color: #292b35;
    }
`;
