import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 3;
    width: 100%;
    background-image: url(${(props) =>
        props.sportId ? `/assets/images/scoreboard-backgrounds/scoreboard-${props.sportSlug}.jpg` : ''});
    background-size: cover;
    background-position: center;
    border-radius: var(--ui-radius);
    margin-top: var(--spacing-16);
    @media ${mediaQuery.isPhone} {
        background-position: right;
    }
    > * {
        flex: 1;
    }

    @media ${mediaQuery.isPhone} {
        padding: 0;
    }

    iframe {
        width: 100%;
        height: 302px;
        border: none;
    }
`;
