import React, { FocusEvent, InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import Wrapper from './styles';
import { translate } from '../../../../../services/translate';
import SportBetslipQuickStake from '../../../betslip/quick-stake/SportBetslipQuickStake';
import { stores } from '../../../../../stores';
import SportBetslipStakeKeyboard from '../../../betslip/stake-keyboard/SportBetslipStakeKeyboard';
import Svg from '../../../../svg/Svg';
import { showVirtualKeyboard } from '../../../../../services/virtual-keyboard/virtual-keyboard';
import { formattedAmountWithCurrency } from '../../../../../services/currency';
import { proccessRetailInput } from '../../../../../services/util';
import { getBetslipQuickStakes } from '../../../../../services/sports/betslip';
import { getFixedStake } from '../../../../../services/sports/betslip-formatting';
import { FEATURE, isFeatureAvailable } from '../../../../../services/feature';
import { isRetail } from '../../../../../services/environment';
import { retail } from '../../../../../stores/retail/retail';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    onChange: (value: string) => void;
    value: number;
    label?: string;
    isDisabled: boolean;
}

function SportParlayCardBetslipStakeInput({ onChange, isDisabled, value, label }: Props) {
    const [{ isPhone, isLaptop }] = useStore(media);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [stakeValue, setStakeValue] = useState<number | string>(0);

    const limitAmountOfOption = isLaptop ? 4 : 5;
    const quickStakes = getBetslipQuickStakes(limitAmountOfOption);

    const [isKeyboardOpened, setIsKeyboardOpened] = useState(false);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [wallet] = useStore(stores.wallet);
    const isRetailLayout = isRetail();
    const [isRetailKeyboardVisible] = useStore(retail.retailKeyboard.isVisible);
    const isShowRemoveStakeButton =
        value > 0 &&
        !isDisabled &&
        ((!isKeyboardOpened && !isRetailLayout) || (!isRetailKeyboardVisible && isRetailLayout));

    const stakeInputContainerReference = useRef<HTMLDivElement>(null);
    const stakeInputReference = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isInputFocused) {
            setIsKeyboardOpened(true);
            if (!isRetailLayout) {
                clearStake();
            }

            if (isPhone) {
                const stakeElement = stakeInputReference.current;
                if (stakeElement) {
                    stakeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }
        }
    }, [isInputFocused]);

    useEffect(() => {
        if (stakeValue !== value) {
            setStakeValue(value);
        }
    }, [value]);

    function updateStake(value, newValue: number) {
        const isQuickStakeIncremental = isFeatureAvailable(FEATURE.QUICKSTAKE_INCREMENTAL);
        const currentStake = parseInt(value || 0);

        const updatedValue = isQuickStakeIncremental ? currentStake + newValue : newValue;
        onChange(String(updatedValue));
    }

    function clearStake() {
        handleStakeInput('');
    }

    function handleInputFocus({ target }: FocusEvent<HTMLInputElement>) {
        setIsInputFocused(true);
        stores.sports.isBottomNavigationVisible.set(false);
        if (isRetailLayout && target.parentElement) {
            showVirtualKeyboard({
                targetEl: target,
                anchorEl: target.parentElement.parentElement as HTMLElement,
                callback: (value) => onChange(value),
                value: value.toString(),
                layout: 'betslip',
                offsetCenterY: 15,
                ignoreLossFocus: false,
            });
        }
    }

    function handleInputBlur() {
        setIsInputFocused(false);
        stores.sports.isBottomNavigationVisible.set(true);
    }

    function handleStakeInput(inputValue: string) {
        const fixedStakeValue = getFixedStake(inputValue);
        setStakeValue(fixedStakeValue);
        const fixedStakeValueNum = Number(fixedStakeValue);
        if (fixedStakeValueNum === value || isNaN(Number(fixedStakeValueNum))) {
            return;
        }
        if (isRetailLayout) {
            proccessRetailInput(onChange, String(fixedStakeValue));
        } else {
            onChange(String(fixedStakeValue));
        }
    }

    const inputProps: InputHTMLAttributes<HTMLInputElement> = {
        type: 'text',
        min: 0,
        onChange: (e) => handleStakeInput(e.target.value),
        onFocus: handleInputFocus,
        onBlur: handleInputBlur,
        disabled: isDisabled,
    } as const;

    return (
        <Wrapper>
            <div className="stake-input" ref={stakeInputContainerReference}>
                {isPhone && isAuthenticated && (
                    <div className="available-balance">
                        <Svg icon="user-icon" />
                        <div>{formattedAmountWithCurrency(wallet!.balance_uc)}</div>
                    </div>
                )}
                <label htmlFor="betStakeInput" className="stake-input-label">
                    {translate(label || 'Wager', 'ui.sportsbook')}
                </label>

                <input
                    id="betStakeInput"
                    ref={stakeInputReference}
                    value={stakeValue}
                    readOnly={isRetailLayout}
                    className={isRetailLayout ? 'retail-stake-input' : ''}
                    {...inputProps}
                />
                {isShowRemoveStakeButton && (
                    <div className="remove-stake-button" onClick={clearStake}>
                        <Svg icon="backspace" />
                    </div>
                )}
            </div>

            {isKeyboardOpened && !isRetailLayout ? (
                <SportBetslipStakeKeyboard
                    inputRef={stakeInputReference}
                    value={value}
                    onChange={handleStakeInput}
                    onClose={() => setIsKeyboardOpened(false)}
                />
            ) : (
                <div className="quick-stake-buttons">
                    {quickStakes.map((stake) => {
                        return (
                            <SportBetslipQuickStake
                                key={stake}
                                stake={stake}
                                onStakeUpdate={() => updateStake(value, stake)}
                                disabled={isRetailKeyboardVisible}
                            />
                        );
                    })}
                </div>
            )}
        </Wrapper>
    );
}

export default React.memo(SportParlayCardBetslipStakeInput);
