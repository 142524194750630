import React, { useEffect } from 'react';
import Wrapper from './styles';
import Blog from '../../components/blog/Blog';
import { getRoute, useRoutes } from '../../services/router';
import Navigator from '../../components/navigator/Navigator';
import { withErrorBoundary } from '../../higher-order-components/error-boundary';
import BlogMenu from '../../components/blog/menu/BlogMenu';
import { loadBlogCategories } from '../../microservices/l10n';
import { stores } from '../../stores';
import PromotionSlider from '../../components/promotion/slider/PromotionSlider';
import { storageSet } from '../../services/storage';
import BlogArticle from '../../components/blog/article/BlogArticle';
import { getUserCountry } from '../../services/user';
import { COUNTRY } from '../../services/country';
import UiBox from '../../components/ui/box/UiBox';
import { translate } from '../../services/translate';
import BlogPreviewPage from './preview/BlogPreviewPage';
import { PRODUCT } from '../../types/common';
import { useStore } from '../../hooks/useStore';

function BlogPage() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const isUserFromFinlandAndLoggedOut = getUserCountry() === COUNTRY.FINLAND && !isAuthenticated;

    function updateNewBlogVisitDateAndPostsCount() {
        const today = new Date().toISOString();
        storageSet('blogVisitDate', today);
        stores.blog.blogVisitDate.set(today);
        stores.blog.newBlogPostsCount.set(undefined);
    }

    useEffect(() => {
        loadBlogCategories();
        updateNewBlogVisitDateAndPostsCount();
        return () => stores.blog.categories.set([]);
    }, []);

    useEffect(() => {
        if (isUserFromFinlandAndLoggedOut) {
            stores.modals.isLoginModalOpen.set(true);
        }
    }, [isUserFromFinlandAndLoggedOut]);

    const sliderSettings = {
        autoplay: false,
    };
    const routes = useRoutes([
        {
            route: getRoute('blog'),
            component: Blog,
            exact: true,
        },
        {
            route: `${getRoute('blog.category')}`,
            component: Blog,
            exact: true,
        },
        {
            route: `${getRoute('blog.preview')}`,
            component: BlogPreviewPage,
            exact: true,
        },
        {
            route: `${getRoute('blog')}/:slug`,
            component: BlogArticle,
            exact: true,
        },
        {
            route: `${getRoute('blog.category')}/:blogCategory`,
            component: Blog,
            exact: true,
        },
        {
            route: `${getRoute('blog.preview')}/:draftId`,
            component: BlogPreviewPage,
            exact: true,
        },
    ]);

    return (
        <Wrapper isUserFromFinlandAndLoggedOut={isUserFromFinlandAndLoggedOut}>
            {isUserFromFinlandAndLoggedOut && (
                <UiBox className="logged-out-finnish-users-message">
                    <div>{translate('ui.common.login-to-see-blog')}</div>
                </UiBox>
            )}
            <div className="blog-page-content">
                <BlogMenu />
                <Navigator routes={routes} />
                <div className="blog-promotions">
                    <PromotionSlider product={PRODUCT.ALL} sliderSettings={sliderSettings} />
                </div>
            </div>
        </Wrapper>
    );
}

export default withErrorBoundary(BlogPage);
