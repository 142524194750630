import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    position: relative;

    background-color: var(--surface-level-2-bg);
    border: var(--surface-level-2-border);
    border-radius: var(--ui-radius);
    box-shadow: var(--ui-shadow);

    color: var(--font-color-primary);

    padding: var(--spacing-24);

    @media ${mediaQuery.isPhone} {
        padding: 1rem;
    }

    @media (max-width: 375px) {
        padding: var(--spacing-8);
    }

    .card-header {
        color: var(--font-color-primary);
        font-size: 1rem;
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering}

        margin-bottom: var(--spacing-16);
    }

    .close-button {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
    }

    p {
        color: var(--font-color-subtle);
        font-size: 0.875rem;
        margin: 0 0 1rem 0;

        a {
            border-bottom: 1px dotted;
            color: var(--font-link-color);
            text-decoration: none;
        }
    }
    .category {
        position: sticky;
        top: 55px;
        z-index: 2;

        display: flex;

        color: var(--color-highlight);

        background-color: var(--surface-level-1-bg);
        padding: 0.4rem 0;
        margin-top: 0.8rem;
        @media ${mediaQuery.isPhone} {
            top: 0;
            .category-title {
                display: flex;
                align-items: center;

                width: ${(props) => (props.hasLiveMatches ? '455' : '445')}px;

                font-size: 1rem;
                font-weight: var(--font-weight-bold);
                cursor: pointer;

                ${({ theme }) => theme.optimizeFontRendering}
            }
            .category-name {
                padding: 0 0.4rem;
                @media ${mediaQuery.isPhone} {
                    padding: 0 0.8em;
                }
            }
        }
`;
