import { stores } from '../../stores';
import { environment } from '../../stores/environment/environment';
import { getStoreValue } from '../../stores/store/utils';
import { getDecodedToken } from '../token';
import {
    attemptWithRetry,
    GEOCOMPLY_ENVIRONMENT,
    GEOCOMPLY_REASON,
    geocomplyClientErrorHandler,
    handleEncryptedGeopacket,
    logMessage,
    startDurationTimer,
} from './geocomply';

export async function geoComplyDesktopPlatformGeoVerification(reason: GEOCOMPLY_REASON): Promise<void> {
    const geocomplyDesktopSettings = getGeocomplyDesktopSettings(reason);
    const GeoComplyDesktopLibrary = (window as any).GeoComply as GeoComplyDesktopLibrary;

    (window as any).CoolbetGeoComplyDesktopLibrary = GeoComplyDesktopLibrary;

    const knownErrorCodes = Object.entries(GeoComplyDesktopLibrary.Client)
        .filter(([key]) => key.startsWith('CLNT_ERROR_'))
        .map(([, value]) => value) as Array<number>;

    stores.geocomply.client.set((geocomplyClient) => {
        geocomplyClient.libraryVersion = GeoComplyDesktopLibrary.Client.getJSLibraryVersion();
        geocomplyClient.clientError = null;
    });

    const attemptConnection = (attemptIndex: number, totalAttempts: number) =>
        new Promise<void>((resolve, reject) => {
            logMessage(`Connection attempt ${attemptIndex} out of ${totalAttempts}`, 'INFO');

            const getDuration = startDurationTimer();

            GeoComplyDesktopLibrary.Client.on('connect', () => {
                logMessage(`Connecting succeeded after ${getDuration().humanized}`, 'INFO');
                logMessage('GeoComply client connected', 'SUCCESS');

                stores.geocomply.client.set((geocomplyClient) => {
                    geocomplyClient.clientError = null;
                    geocomplyClient.clientVersion = GeoComplyDesktopLibrary.Client.getVersion();
                    geocomplyClient.isConnected = true;
                    geocomplyClient.isConnecting = false;
                    geocomplyClient.isGeolocating = false;
                });

                resolve();
            });

            GeoComplyDesktopLibrary.Client.on('error', (code, message) => {
                logMessage(`Connecting failed after ${getDuration().humanized}`, 'INFO');

                geocomplyClientErrorHandler(code, message);
                reject({ code, message });
            });

            GeoComplyDesktopLibrary.Client.on('log', (message) => logMessage(message, 'DEBUG'));

            GeoComplyDesktopLibrary.Client.connect(
                geocomplyDesktopSettings.installerID,
                geocomplyDesktopSettings.envId,
            );

            stores.geocomply.client.set((geocomplyClient) => {
                geocomplyClient.isConnecting = true;
            });
        }).finally(() => {
            GeoComplyDesktopLibrary.Client.off('connect');
            GeoComplyDesktopLibrary.Client.off('error');
            GeoComplyDesktopLibrary.Client.off('log');
        });

    const attemptConnectionWithRetry = () =>
        attemptWithRetry(
            [GeoComplyDesktopLibrary.Client.CLNT_ERROR_LOCAL_SERVICE_COMMUNICATION],
            knownErrorCodes,
            (attemptIndex: number, totalAttempts: number) => attemptConnection(attemptIndex, totalAttempts),
        );

    const attemptGeoLocation = (attemptIndex: number, totalAttempts: number) =>
        new Promise<string>((resolve, reject) => {
            logMessage(`Geolocation attempt ${attemptIndex} out of ${totalAttempts}`, 'INFO');

            const getDuration = startDurationTimer();

            GeoComplyDesktopLibrary.Client.on('geolocation', (encryptedGeopacket: string) => {
                logMessage(`GeoLocating succeeded after ${getDuration().humanized}`, 'INFO');
                logMessage(`GeoPacket created: ${encryptedGeopacket}`, 'SUCCESS');

                stores.geocomply.client.set((geocomplyClient) => {
                    geocomplyClient.clientError = null;
                    geocomplyClient.clientVersion = GeoComplyDesktopLibrary.Client.getVersion();
                    geocomplyClient.isConnected = true;
                    geocomplyClient.isConnecting = false;
                    geocomplyClient.isGeolocating = false;
                });

                resolve(encryptedGeopacket);
            });

            GeoComplyDesktopLibrary.Client.on('error', (code, message) => {
                logMessage(`GeoLocating failed after ${getDuration().humanized}`, 'INFO');

                geocomplyClientErrorHandler(code, message);
                reject({ code, message });
            });

            GeoComplyDesktopLibrary.Client.on('log', (message) => logMessage(message, 'DEBUG'));

            const { license, reason, userId, customFields } = geocomplyDesktopSettings;
            GeoComplyDesktopLibrary.Client.setLicense(license as string);
            GeoComplyDesktopLibrary.Client.setGeolocationReason(reason as string);
            GeoComplyDesktopLibrary.Client.setUserId(userId as string);
            GeoComplyDesktopLibrary.Client.customFields.set('session_key', customFields.sessionKey as string);

            GeoComplyDesktopLibrary.Client.requestGeolocation();

            stores.geocomply.client.set((geocomplyClient) => {
                geocomplyClient.isGeolocating = true;
            });
        }).finally(() => {
            GeoComplyDesktopLibrary.Client.off('geolocation');
            GeoComplyDesktopLibrary.Client.off('error');
            GeoComplyDesktopLibrary.Client.off('log');
        });

    const attemptGeolocationWithRetry = () =>
        attemptWithRetry(
            [GeoComplyDesktopLibrary.Client.CLNT_ERROR_LOCAL_SERVICE_COMMUNICATION],
            knownErrorCodes,
            (attemptIndex: number, totalAttempts: number) => attemptGeoLocation(attemptIndex, totalAttempts),
        );

    try {
        stores.geocomply.client.set((geocomplyClient) => {
            geocomplyClient.isAttemptingConnection = true;
        });

        await attemptConnectionWithRetry();

        stores.geocomply.client.set((geocomplyClient) => {
            geocomplyClient.isAttemptingConnection = false;
            geocomplyClient.isAttemptingGeolocation = true;
        });

        const encryptedGeopacket = await attemptGeolocationWithRetry();
        await handleEncryptedGeopacket(encryptedGeopacket, reason);
    } finally {
        GeoComplyDesktopLibrary.Client.disconnect();

        stores.geocomply.client.set((geocomplyClient) => {
            geocomplyClient.clientVersion = null;
            geocomplyClient.isConnected = false;
            geocomplyClient.isConnecting = false;
            geocomplyClient.isAttemptingConnection = false;
            geocomplyClient.isGeolocating = false;
            geocomplyClient.isAttemptingGeolocation = false;
        });
    }
}

export function getGeocomplyDesktopSettings(reason: GEOCOMPLY_REASON): GeocomplyDesktopSettings {
    const user = getStoreValue(stores.user);
    const token = getStoreValue(stores.token);
    const license = getStoreValue(stores.geocomply.license);
    const installerID = getStoreValue(environment).GEOCOMPLY?.DESKTOP_INSTALLER_KEY || '';
    const envId = (getStoreValue(environment).GEOCOMPLY?.ENVIRONMENT || '') as GEOCOMPLY_ENVIRONMENT;

    return {
        installerID,
        envId,
        license: license.license,
        userId: user?.id ?? null,
        reason,
        customFields: {
            sessionKey: token ? getDecodedToken(token).login_session_id : null,
        },
    };
}

interface GeocomplyDesktopSettings {
    license: string | null;
    userId: string | null;
    reason: GEOCOMPLY_REASON;
    customFields: {
        sessionKey: string | null;
    };
    envId: GEOCOMPLY_ENVIRONMENT;
    installerID: string;
}

export interface GeoComplyDesktopLibrary {
    createClient: () => GeoComplyDesktopLibrary['Client']; // The use of this mehtod is deprecated
    Client: {
        CLNT_OK: 0; // No errors
        CLNT_ERROR_UNEXPECTED: 600; // Unexpected error
        CLNT_ERROR_NOT_CERTIFIED_BINARIES: 601; // Core libraries are not certified
        CLNT_ERROR_NETWORK_CONNECTION: 602; // Network connection error
        CLNT_ERROR_SERVER_COMMUNICATION: 603; //  Server communication error
        CLNT_ERROR_CLIENT_SUSPENDED: 604; // Client is suspended
        CLNT_ERROR_DISABLED_SOLUTION: 605; // Solution is disabled
        CLNT_ERROR_INVALID_LICENSE_FORMAT: 606; // Invalid license format
        CLNT_ERROR_CLIENT_LICENSE_UNAUTHORIZED: 607; // Client's license is unauthorized
        CLNT_ERROR_LICENSE_EXPIRED: 608; // Client's license is expired
        CLNT_ERROR_INVALID_CUSTOM_FIELDS: 609; // Invalid custom fields
        CLNT_ERROR_LOCAL_SERVICE_UNAVAILABLE: 612; // Local service is unavailable
        CLNT_ERROR_LOCAL_SERVICE_COMMUNICATION: 613; // Local service communication error
        CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS: 614; // Geolocation request in progress
        CLNT_ERROR_LOCAL_SERVICE_UNSUP_VER: 615; // Installed version of PLC is not supported
        CLNT_ERROR_TRANSACTION_TIMEOUT: 620; // The create transaction procedure took more time than it was configured
        CLNT_ERROR_WRONG_OR_MISSING_PARAMETER: 699; //  Wrong or missing required Client.connect() parameter

        connect: (installerID: string, environment: GEOCOMPLY_ENVIRONMENT) => void;
        customFields: {
            set: (fieldName: string, fieldValue: string | number) => unknown;
            get: () => unknown;
            remove: () => unknown;
            clear: () => unknown;
        };
        detectInstall: () => unknown;
        disconnect: () => void;
        getConnectionMethod: () => string;
        getFallbackVersion: () => string;
        getGeolocationReason: () => string;
        getJSLibraryVersion: () => string;
        getLastErrorCode: () => string;
        getLicense: () => string;
        getUserId: () => string;
        getUserPhoneNumber: () => string;
        getVersion: () => string;
        isConnected: () => boolean;
        off: (eventName: string) => void;
        on: (eventName: string, eventHandler: (...args: any[]) => void) => void;
        requestGeolocation: () => void;
        setGeolocationReason: (reason: string) => GeoComplyDesktopLibrary['Client'];
        setLicense: (license: string) => GeoComplyDesktopLibrary['Client'];
        setUserId: (userId: string) => GeoComplyDesktopLibrary['Client'];
        setUserPhoneNumber: (phoneNumber: string) => GeoComplyDesktopLibrary['Client'];
    };
    Events: {
        add: (a, b, c) => void;
        ready: (a, b) => void;
        remove: (a, b, c) => void;
        trigger: (a, b) => void;
    };
    events: {
        add: (a, b, c) => void;
        ready: (a, b) => void;
        remove: (a, b, c) => void;
        trigger: (a, b) => void;
    };
}
