import React from 'react';
import { stores } from '../../../stores';
import { checkLoginRequired, checkMaintenanceSportsbook } from '../../../services/navigator';
import MainLayout from '../../../layouts/main/MainLayout';
import { isRetail } from '../../../services/environment';
import { useStore } from '../../../hooks/useStore';

interface Props {
    loginRequired?: boolean;
    root?: boolean;
    render: (props) => JSX.Element;
    component: any;
    layout: any;
}

export default function NavigatorRender(props: Props) {
    const { loginRequired, root, render, component: Component, layout: Layout } = props;
    const isRetailLayout = isRetail();
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [sportsbookMaintenanceState] = useStore(stores.maintenance.sportsbookMaintenanceState);

    const navigatorRenderMiddleware = [
        () => checkLoginRequired(loginRequired, root, isAuthenticated),
        () => checkMaintenanceSportsbook(sportsbookMaintenanceState),
    ];

    for (const middleware of navigatorRenderMiddleware) {
        const restriction = middleware();
        if (restriction && Layout) {
            return isRetailLayout ? <>{restriction}</> : <MainLayout>{restriction}</MainLayout>;
        }
        if (restriction) {
            return restriction;
        }
    }

    if (Layout) {
        return (
            <Layout>
                <Component {...props} />
            </Layout>
        );
    }
    if (Component) {
        return <Component {...props} />;
    }
    if (render) {
        return render(props);
    }
    return null;
}
