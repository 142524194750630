import styled from 'styled-components';

export default styled.div`
    position: fixed;
    top: 5px;
    right: 5px;
    z-index: 9999;

    .mikk {
        background-color: ${({ theme }) => theme.colors.headerBackground};
        border-radius: 2px;
        cursor: pointer;
        font-size: 1rem;
        margin: 0 0 0 0.5rem;
        position: relative;
        z-index: 0;

        &:after {
            animation: animatedBorder 3500ms ease-in-out alternate infinite;
            background: linear-gradient(90deg, #6fc529, #549520, #17beff, #118fbf, #ff8e03, #bf6b03, #e11d00, #a91600);
            background-size: 300% 300%;
            border-radius: 4px;
            content: '';
            height: calc(100% + 4px);
            left: -2px;
            position: absolute;
            top: -2px;
            width: calc(100% + 4px);
            z-index: -1;
        }
    }

    .badge {
        color: ${({ theme }) => theme.colors.alert};
        font-size: 0.7em;
    }

    @keyframes animatedBorder {
        0% {
            background-position: 0 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0 50%;
        }
    }
`;
