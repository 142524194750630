import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    z-index: 10;
    position: relative;
    transition: background-color 0.2s, border-radius 0.2s;
    height: 45px;
    background-color: #2f2f38;
    border-radius: var(--ui-radius);
    margin: 10px 5px 5px 0;

    @media ${mediaQuery.isPhone} {
        margin-right: 0;
    }

    .poker-leaderboard-search-icon {
        height: 45px;
        width: 45px;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        padding: 10px;
        margin-left: 5px;
        color: var(--color-highlight);
        transition: color 0.2s;
    }

    .poker-leaderboard-search-input {
        flex: 1 1 250px;
        width: 100%;
        min-width: 0;
        background-color: transparent;
        border: unset;
        outline: unset;
        color: var(--font-color-secondary);
        height: 100%;
        padding-right: 40px;
        transition: color 0.2s, padding-left 0.15s;
        padding-left: 55px;
        z-index: 2;
        font-size: 16px;

        &::placeholder {
            color: var(--font-color-secondary);
            font-weight: 700;
            opacity: 1;
        }

        &::-ms-clear {
            display: none;
        }
    }
`;
