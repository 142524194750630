import React, { useMemo } from 'react';
import Wrapper from './styles';
import { stores } from '../../../../stores';
import { translate } from '../../../../services/translate';
import {
    CasinoGameLauncher,
    isCasinoGameBlockedByBonus,
    isCasinoGameUnderMaintenance,
} from '../../../../services/casino/games';
import { DATE_YEAR_FORMAT, getFormattedDate } from '../../../../services/date';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    gameLauncher: CasinoGameLauncher;
    border?: 'rounded' | 'flat';
    isRecentlyPlayed?: boolean;
}

export default function CasinoThumbnailDisabled({ border = 'rounded', gameLauncher, isRecentlyPlayed }: Props) {
    const [blockedProviders] = useStore(stores.casino.maintenance);
    const [hasActiveBonus] = useStore(stores.casino.hasActiveBonus);
    const [closedGames] = useStore(stores.casino.closedGames);

    const closedBlock = useMemo(() => gameLauncher.id && Boolean(closedGames[gameLauncher.id]), [closedGames]);
    const maintenanceBlock = useMemo(
        () => isCasinoGameUnderMaintenance(gameLauncher.providerId, gameLauncher.subProviderId),
        [blockedProviders],
    );
    const bonusBlock = useMemo(
        () => gameLauncher.disabledWithBonus && isCasinoGameBlockedByBonus(gameLauncher.disabledWithBonus),
        [hasActiveBonus],
    );
    return (
        <Wrapper border={border} className="casino-thumbnail-disabled" $isRecentlyPlayed={isRecentlyPlayed}>
            {maintenanceBlock && (
                <div className="casino-thumbnail-disabled-message-box" data-test="maintenance">
                    <div className="casino-thumbnail-disabled-message-box-text">
                        <div>{translate('maintenance-info', 'ui.casino')}</div>
                        <div>
                            <span>
                                {getFormattedDate({
                                    date: blockedProviders[gameLauncher.providerId].endDate,
                                    format: DATE_YEAR_FORMAT,
                                    useDots: true,
                                })}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {closedBlock && !maintenanceBlock && (
                <div className="casino-thumbnail-disabled-message-box">
                    <div className="casino-thumbnail-disabled-message-box-text">
                        <div>{translate(['closed-info', 'Table is closed'], 'ui.casino')}</div>
                    </div>
                </div>
            )}
            {bonusBlock && (
                <div className="casino-thumbnail-disabled-message-box">
                    <div className="casino-thumbnail-disabled-message-box-text">
                        {translate('disabled-with-bonus-short-info', 'ui.casino')}
                    </div>
                </div>
            )}
        </Wrapper>
    );
}
