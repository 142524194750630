import styled, { css } from 'styled-components';

type StyledProps = {
    $showFeatures?: boolean;
};

export default styled.div<StyledProps>`
    display: flex;
    flex-grow: 1;
    flex-basis: 50%;

    ${({ $showFeatures }) =>
        !$showFeatures &&
        css`
            .poker-play,
            button {
                display: flex;
                flex-grow: 1;
                flex-basis: 100%;
            }
        `}

    ${({ $showFeatures }) =>
        $showFeatures &&
        css`
            align-items: center;
        `}
`;
