import styled from 'styled-components';
import { mediaQuery } from '../../styles/utils';

export default styled.div`
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: var(--spacing-8);

    &::-webkit-scrollbar {
        display: flex;
    }

    &::-webkit-scrollbar-thumb {
        visibility: hidden;
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            visibility: visible;
        }
        @media ${mediaQuery.isPhone} {
            &::-webkit-scrollbar-thumb {
                visibility: hidden;
            }
        }
    }

    &.wrap {
        flex-wrap: wrap;
        > * {
            margin-bottom: 0.4rem;
        }
    }
    > div {
        margin-right: 0.3rem;
    }
`;
