import { stores } from '../../stores';
import { logger } from '../logger';
import { TranslateKey, translate } from '../translate';

export const racebookBetslipErrorPrefix = 'ui.racebook.betslip';

export const translationKeysByCode = {
    1014: 'TICKET_TOTAL_COST_MIN_STAKE_ERROR',
    1015: 'MIN_STAKE_ONLY_ERROR',
    1016: 'STAKE_RANGE_ERROR',
    1017: 'STAKE_MULTIPLE_OF_MIN_ERROR',
    1018: 'STAKE_MULTIPLE_OF_50CENTS_ERROR',
    1019: 'STAKE_WHOLE_NUMBER_ONLY_ERROR',
    1020: 'MISSING_DATA_IN_BET',
};

export interface BetslipError {
    code: number;
    description: string;
    placeholders: {
        minStake?: number;
        maxStake?: number;
    };
}

export const clearRacebookBetslipErrors = () => stores.horseRacing.betslipErrors.set([]);

export function addRacebookBetslipError(error: BetslipError | string, logError = false) {
    logger.error('HorseRacingBetslipErrorsService', 'addRacebookBetslipError', JSON.stringify(error, null, 2));
    if (typeof error !== 'string') {
        logError && logger.error('HorseRacingBetslipErrorsService', 'addRacebookBetslipError', error);
        const translationKey = translationKeysByCode[error.code]
            ? ([translationKeysByCode[error.code], error.description] as TranslateKey)
            : 'GENERIC_ERROR';
        const errorMessage = translate(translationKey, racebookBetslipErrorPrefix, error.placeholders ?? {});
        stores.horseRacing.betslipErrors.set((state) => [...state, errorMessage]);
    } else {
        stores.horseRacing.betslipErrors.set((state) => [...state, error]);
    }
}
