import React, { useCallback, useEffect, useState } from 'react';
import Wrapper from './styles';
import { stores } from '../../../../stores';
import { getStoreValue } from '../../../../stores/store/utils';
import { translate } from '../../../../services/translate';
import UiAlert from '../../../ui/alert/UiAlert';
import SportParlayCardBetslipOutcome from './outcome/SportParlayCardBetslipOutcome';
import { storageGet, storageSet } from '../../../../services/storage';
import SportParlayCardBetslipStakeInput from './stake-input/SportParlayCardBetslipStakeInput';
import {
    BetslipMode,
    deleteBetSlip,
    getErrorMessage,
    navigateToBetslip,
    storeAndClearSelection,
} from '../../../../services/sports/betslip';
import round from 'lodash/round';
import { convertOdds } from '../../../../services/odds-format';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Svg from '../../../svg/Svg';
import UiDottedArrow from '../../../ui/dotted-arrow/UiDottedArrow';
import SportParlayCardBetslipReceipt from './receipt/SportParlayCardBetslipReceipt';
import uniq from 'lodash/uniq';
import { isMobile } from '../../../../services/browser';

import {
    INCORRECT_AMOUNT_OF_SELECTIONS,
    INVALID_MAIN_LINE,
    INVALID_STAKE_ERROR,
    PARLAY_CARD_MARKET_STATUS,
    PARLAY_CARD_MATCH_STATUS,
    PARLAY_CARD_MAX_WIN,
    PARLAY_CARD_OUTCOME_STATUS,
    PARLAY_CARD_SELECTION_NOT_AVAILABLE,
    SAME_MARKET_IN_BETSLIP_ERROR,
} from '../../../../services/sports/constants';
import SportParlayCardBetslipButtons from './buttons/SportParlayCardBetslipButtons';
import {
    isMAErrorForParlayCard,
    isMAStakeAllowedForParlayCard,
} from '../../../../services/sports/manual-acceptance-helpers';
import { getActiveCurrency } from '../../../../services/currency';
import {
    areOddsValidForOnDemandBet,
    getParlayCardMaxSelections,
    loadParlayCardData,
    ParlayCardBetslipOutcome,
} from '../../../../services/parlay-card/parlay-card';
import UiButton from '../../../ui/button/UiButton';
import RetailBringYourDevice from '../../../retail/bring-your-device/RetailBringYourDevice';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import sortBy from 'lodash/sortBy';
import { withErrorBoundary } from '../../../../higher-order-components/error-boundary';
import SportParlayCardHeader from '../header/SportParlayCardHeader';
import { isBYOD, isRetail } from '../../../../services/environment';
import UiAnimate from '../../../ui/animate/UiAnimate';
import { getMaxWin } from '../../../../services/sports/limits';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import { useStore } from '../../../../hooks/useStore';

enum ValidationMessages {
    MIN_SELECTION = 'MIN_SELECTION',
    MAX_SELECTION = 'MAX_SELECTION',
    MIN_STAKE = 'MIN_STAKE',
    MAX_STAKE = 'MAX_STAKE',
    MAX_WIN = 'MAX_WIN',
    INCORRECT_AMOUNT_OF_SELECTIONS = 'INCORRECT_AMOUNT_OF_SELECTIONS',
    SAME_MATCH_IN_BETSLIP_ERROR = 'SAME_MATCH_IN_BETSLIP_ERROR',
    SAME_MARKET_IN_BETSLIP_ERROR = 'SAME_MARKET_IN_BETSLIP_ERROR',
    PARLAY_CARD_SELECTION_NOT_AVAILABLE = 'PARLAY_CARD_SELECTION_NOT_AVAILABLE',
    INSUFFICIENT_FUNDS_ERROR = 'INSUFFICIENT_FUNDS_ERROR',
}

function SportParlayCardBetslip() {
    const [betSlipMarketIdToOutcomeIds, setBetSlipMarketIdToOutcomeIds] = useStore(
        stores.sports.parlayCard.betSlipMarketIdToOutcomeIds,
    );
    const [stakeValue, setStakeValue] = useStore(stores.sports.parlayCard.stake);
    const [parlayCard] = useStore(stores.sports.parlayCard.parlayCard);
    const [oddsByOutcomeId] = useStore(stores.sports.parlayCard.oddsByOutcomeId);
    const [marketInfo] = useStore(stores.sports.parlayCard.marketInfo);
    const [betSlipErrors, setBetSlipErrors] = useStore(stores.sports.parlayCard.betSlipErrors);
    const [manualAcceptanceStake, setManualAcceptanceStake] = useStore(stores.sports.parlayCard.manualAcceptanceStake);
    const [betSlipPlacingState] = useStore(stores.sports.parlayCard.betSlipPlacingState);
    const isRetailLayout = isRetail();
    const [wallet] = useStore(stores.wallet);
    const { balance_uc: userBalance } = wallet || ({} as any);
    const [validations, setValidations] = useState<Record<string, string>>({});
    const [betSlipOutcomes, setBetSlipOutcomes] = useState<ParlayCardBetslipOutcome[]>([]);
    const [sameMatchIdsOnBetslip, setSameMatchIdsOnBetslip] = useState<number[]>([]);
    const [sameMarketIdsOnBetslip, setSameMarketIdsOnBetslip] = useState<number[]>([]);
    const [totalOdds, setTotalOdds] = useState(0);
    const [maxWin, setMaxWin] = useState(0);
    const [isStakeChanging, setIsStakeChanging] = useState(false);
    const [disabledOutcomeIds, setDisabledOutcomeIds] = useState<number[]>([]);
    const [lineChangedMarketIds, setLineChangedMarketIds] = useState<number[]>([]);
    const {
        min_selections,
        min_stake,
        max_stake,
        max_win,
        handicap_teaser_points,
        totals_teaser_points,
        same_match_selections_allowed,
        same_market_selections_allowed,
        should_check_master_market,
        status: parlayCardStatus,
    } = parlayCard;
    const { receiptById, isLoading } = betSlipPlacingState;
    const ticketIds = Object.values(receiptById);
    const betslipMarketIds = Object.keys(betSlipMarketIdToOutcomeIds).map(Number);
    const betslipOutcomeIds = Object.values(betSlipMarketIdToOutcomeIds).flat();
    const maxSelections = getParlayCardMaxSelections(parlayCard);
    const isPlaceBetButtonDisabled = parlayCardStatus !== 'OPEN' || !stakeValue || !isEmpty(validations);
    const stakeInputValue = isMAStakeAllowedForParlayCard() ? stakeValue - manualAcceptanceStake : stakeValue;
    const [betslipCollection] = useStore(stores.sports.parlayCard.parlayBetslipCollection);
    const [betSlipPosition] = useStore(stores.sports.parlayCard.betslipPosition);
    const isPrevEnabled = Boolean(betslipCollection.length && betSlipPosition !== 0);
    const isNextEnabled = Boolean(betslipCollection.length && betSlipPosition < betslipCollection.length - 1);
    const availableMaxWin = max_win ? Math.min(max_win, getMaxWin()) : getMaxWin();

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        calculateMaxWin();
    }, [stakeValue, parlayCard]);

    useEffect(setValidationsToBetslip, [maxWin, betSlipErrors, oddsByOutcomeId]);

    useEffect(() => {
        calculateMaxWin();
        setSortedMarketIds();
        clearBetslipErrorsAndMAStake();
        clearLineChangedMarketIds();
    }, [betSlipMarketIdToOutcomeIds]);

    function clearLineChangedMarketIds() {
        const marketIdsInBetslip = Object.keys(betSlipMarketIdToOutcomeIds).map(Number);
        if (lineChangedMarketIds.some((marketId) => !marketIdsInBetslip.includes(marketId))) {
            setLineChangedMarketIds((state) => state.filter((marketId) => marketIdsInBetslip.includes(marketId)));
        }
    }

    function clearBetslipErrorsAndMAStake() {
        setManualAcceptanceStake(0);
        setBetSlipErrors([]);
    }

    async function init() {
        const parlayCardId = Number(storageGet('parlayCardId'));
        if (!parlayCardId) {
            return;
        }

        if (isEmpty(parlayCard)) {
            await loadParlayCardData(parlayCardId);
        }

        const { betSlipMarketIdToOutcomeIds }: any = storageGet('parlayCardSettings') || {};
        if (betSlipMarketIdToOutcomeIds) {
            setBetSlipMarketIdToOutcomeIds(betSlipMarketIdToOutcomeIds);
        }
    }

    function setSortedMarketIds() {
        if (!isEmpty(parlayCard)) {
            const outcomes = Object.values(marketInfo).flatMap((market) =>
                market.outcomes.map((outcome) => ({ ...outcome, marketId: market.id, matchId: market.match_id })),
            );
            const sortedOutcomes = sortBy(outcomes, 'position');
            const outcomesOnBetslip = sortedOutcomes.filter((outcome) =>
                betslipOutcomeIds.find((outcomeId) => outcomeId === outcome.id),
            );
            const matchIds = outcomesOnBetslip.map((o) => o.matchId);
            const sameMatchIds = matchIds.filter((matchId, index) => matchIds.indexOf(matchId) !== index);
            setSameMatchIdsOnBetslip(sameMatchIds);
            const betslipMarketIds = outcomesOnBetslip.map((o) => o.marketId);
            const sameMarketIds = betslipMarketIds.filter(
                (marketId, index) => betslipMarketIds.indexOf(marketId) !== index,
            );
            setSameMarketIdsOnBetslip(sameMarketIds);
            setBetSlipOutcomes(outcomesOnBetslip);
        }
    }

    function calculateMaxWin() {
        const totalOdds = calculateTotalOdds();
        setTotalOdds(totalOdds);

        if (!stakeValue) {
            return;
        }
        setMaxWin(round(totalOdds * stakeValue, 2));
    }

    function calculateTotalOdds() {
        const selectionsCount = betslipOutcomeIds.length;
        const payout = parlayCard?.payouts?.find((p) => p.selections_amount === selectionsCount);
        return payout?.odds || 0;
    }

    function getDisabledOutcomeIds(betslipOutcomeIds: number[]) {
        const marketsWithDisabledMatch = betslipMarketIds.filter(
            (marketId) => marketInfo[marketId]?.match_status !== 'OPEN',
        );
        return betslipOutcomeIds.filter((outcomeId) => {
            const outcome = parlayCard.markets
                .flatMap((mr) => mr.outcomes.map((o) => ({ ...o, market_id: mr.id })))
                .find((o) => o.id === outcomeId);
            if (!outcome || !outcome.is_open || marketsWithDisabledMatch.includes(outcome.market_id)) {
                return true;
            }

            if (!should_check_master_market) {
                return false;
            }

            const marketOutcomeIds =
                parlayCard.markets.find((mr) => mr.id === outcome?.market_id)?.outcomes.map((o) => o.id) || [];
            if (!marketOutcomeIds?.length) {
                return true;
            }
            const odds = marketOutcomeIds.map((outcomeId) => oddsByOutcomeId[outcomeId]).filter(Boolean);
            if (odds.length !== marketOutcomeIds.length) {
                return true;
            }
            return !areOddsValidForOnDemandBet(odds);
        });
    }

    function getLineChangedMarketIds() {
        return betslipMarketIds.filter((marketId) => {
            const sameMarketOnParlayCard = parlayCard.markets.find((mr) => mr.id === marketId);
            if (marketInfo[marketId] && !sameMarketOnParlayCard) {
                return true;
            }
            if (marketInfo[marketId] && sameMarketOnParlayCard) {
                return marketInfo[marketId].line !== sameMarketOnParlayCard.line;
            }
            return false;
        });
    }

    function substituteMatchingMarketsInBetslip(marketIdsMissingFromParlayCard: number[]) {
        const betSlipMarketIdToOutcomeIds = getStoreValue(stores.sports.parlayCard.betSlipMarketIdToOutcomeIds);
        const marketsToSubstitute = marketIdsMissingFromParlayCard
            .map((oldMarketId) => {
                const oldMarketInfo = marketInfo[oldMarketId];
                if (oldMarketInfo.view_type !== 'line') {
                    return null;
                }

                const newMatchingMarket = parlayCard.markets.find(
                    (mr) =>
                        mr.match_id === oldMarketInfo.match_id && mr.market_type_id === oldMarketInfo.market_type_id,
                );
                if (!newMatchingMarket) {
                    return null;
                }
                const oldOutcomeIds = betSlipMarketIdToOutcomeIds[oldMarketId];
                const oldOutcomes = oldMarketInfo.outcomes.filter((o) => oldOutcomeIds?.includes(o.id));
                if (!oldOutcomes.length) {
                    return null;
                }
                const oldPositions = oldOutcomes.map((o) => o.position);
                const newOutcomes = newMatchingMarket.outcomes.filter((o) => oldPositions.includes(o.position));
                if (!newOutcomes.length) {
                    return null;
                }

                return {
                    old_market_id: oldMarketInfo.id,
                    new_market_id: newMatchingMarket.id,
                    new_outcome_ids: newOutcomes.map((o) => o.id),
                };
            })
            .filter(Boolean) as { old_market_id: number; new_market_id: number; new_outcome_ids: number[] }[];

        if (marketsToSubstitute.length) {
            marketsToSubstitute.forEach(({ old_market_id, new_market_id, new_outcome_ids }) => {
                stores.sports.parlayCard.betSlipMarketIdToOutcomeIds.set((state) => {
                    delete state[old_market_id];
                    state[new_market_id] = new_outcome_ids;
                });
            });
        }

        const newBetslipOutcomeIds = Object.entries(betSlipMarketIdToOutcomeIds).flatMap(([marketId, outcomeIds]) => {
            const substitutedMarket = marketsToSubstitute.find((mr) => mr.old_market_id === Number(marketId));
            if (!substitutedMarket) {
                return outcomeIds;
            }
            return substitutedMarket.new_outcome_ids;
        });

        return { newBetslipOutcomeIds, newLineChangedMarketIds: marketsToSubstitute.map((mr) => mr.new_market_id) };
    }

    function hasErrorMessagesAlreadyFromBackend(errors: any[]) {
        return betSlipErrors?.some(
            (betslipError) => errors.includes(betslipError?.code) || errors.includes(betslipError),
        );
    }

    function setValidationsToBetslip() {
        const validationMessages = {} as Record<string, string>;

        if (!betslipOutcomeIds.length) {
            setValidations({} as Record<string, string>);
            return;
        }

        const activeCurrency = getActiveCurrency();

        const lineChangedOldMarketIds = getLineChangedMarketIds();
        const { newLineChangedMarketIds, newBetslipOutcomeIds } =
            substituteMatchingMarketsInBetslip(lineChangedOldMarketIds);
        setLineChangedMarketIds((state) => uniq([...state, ...newLineChangedMarketIds]));

        const isMinSelectionError = betslipOutcomeIds.length < min_selections;
        const isMaxSelectionError = maxSelections && betslipOutcomeIds.length > maxSelections;

        const _disabledOutcomeIds = getDisabledOutcomeIds(newBetslipOutcomeIds);
        setDisabledOutcomeIds(_disabledOutcomeIds);

        if (
            _disabledOutcomeIds.length &&
            !hasErrorMessagesAlreadyFromBackend([
                PARLAY_CARD_MATCH_STATUS,
                PARLAY_CARD_MARKET_STATUS,
                PARLAY_CARD_OUTCOME_STATUS,
                INVALID_MAIN_LINE,
            ])
        ) {
            validationMessages[ValidationMessages.PARLAY_CARD_SELECTION_NOT_AVAILABLE] = translate(
                PARLAY_CARD_SELECTION_NOT_AVAILABLE,
                'ui.betslip',
            );
        } else {
            delete validationMessages[ValidationMessages.PARLAY_CARD_SELECTION_NOT_AVAILABLE];
        }

        if (isMinSelectionError) {
            validationMessages[ValidationMessages.MIN_SELECTION] = translate(
                'Minimum amount of selections is %1',
                'ui.sportsbook',
                [min_selections],
            );
        } else {
            delete validationMessages[ValidationMessages.MIN_SELECTION];
        }

        if (isMaxSelectionError) {
            validationMessages[ValidationMessages.MAX_SELECTION] = translate(
                'Maximum amount of selections is %1',
                'ui.sportsbook',
                [maxSelections],
            );
        } else {
            delete validationMessages[ValidationMessages.MAX_SELECTION];
        }

        if (stakeValue && stakeValue < min_stake && !hasErrorMessagesAlreadyFromBackend([INVALID_STAKE_ERROR])) {
            validationMessages[ValidationMessages.MIN_STAKE] = translate('MIN_STAKE', 'ui.sportsbook', [
                min_stake,
                activeCurrency,
            ]);
        } else {
            delete validationMessages[ValidationMessages.MIN_STAKE];
        }

        if (max_stake && stakeValue > max_stake && !hasErrorMessagesAlreadyFromBackend([INVALID_STAKE_ERROR])) {
            validationMessages[ValidationMessages.MAX_STAKE] = translate('MAX_STAKE', 'ui.sportsbook', [
                max_stake,
                activeCurrency,
            ]);
        } else {
            delete validationMessages[ValidationMessages.MAX_STAKE];
        }

        if (availableMaxWin && maxWin > availableMaxWin && !hasErrorMessagesAlreadyFromBackend([PARLAY_CARD_MAX_WIN])) {
            validationMessages[ValidationMessages.MAX_WIN] = translate(
                'Maximum win can not exceed %1 %2',
                'ui.sportsbook',
                [availableMaxWin, activeCurrency],
            );
        } else {
            delete validationMessages[ValidationMessages.MAX_WIN];
        }

        if (!isMaxSelectionError && !isMinSelectionError && totalOdds <= 1) {
            validationMessages[ValidationMessages.INCORRECT_AMOUNT_OF_SELECTIONS] = translate(
                INCORRECT_AMOUNT_OF_SELECTIONS,
                'ui.betslip',
            );
        } else {
            delete validationMessages[ValidationMessages.INCORRECT_AMOUNT_OF_SELECTIONS];
        }

        if (!same_match_selections_allowed && sameMatchIdsOnBetslip.length) {
            validationMessages[ValidationMessages.SAME_MATCH_IN_BETSLIP_ERROR] = translate(
                'SAME_MATCH_IN_BETSLIP',
                'ui.sportsbook',
            );
        } else {
            delete validationMessages[ValidationMessages.SAME_MATCH_IN_BETSLIP_ERROR];
        }

        if (
            !same_market_selections_allowed &&
            sameMarketIdsOnBetslip.length &&
            !hasErrorMessagesAlreadyFromBackend([SAME_MARKET_IN_BETSLIP_ERROR])
        ) {
            validationMessages[ValidationMessages.SAME_MARKET_IN_BETSLIP_ERROR] = translate(
                'SAME_MARKET_IN_BETSLIP',
                'ui.sportsbook',
            );
        } else {
            delete validationMessages[ValidationMessages.SAME_MARKET_IN_BETSLIP_ERROR];
        }

        if (userBalance !== undefined && stakeValue > userBalance && !isLoading) {
            validationMessages[ValidationMessages.INSUFFICIENT_FUNDS_ERROR] = translate(
                'INSUFFICIENT_FUNDS',
                'ui.sportsbook',
            );
        } else {
            delete validationMessages[ValidationMessages.INSUFFICIENT_FUNDS_ERROR];
        }
        setValidations(validationMessages);
    }

    const stakeChange = useCallback((stakeValue: string) => {
        setStakeValue(+stakeValue);
        handleStakeChange();
    }, []);

    function handleStakeChange() {
        setIsStakeChanging(true);
        const timeout = setTimeout(() => setIsStakeChanging(false), 500);
        return () => clearTimeout(timeout);
    }

    const clearSelection = useCallback(() => {
        if (isLoading) {
            return;
        }
        storageSet('parlayCardSettings', {
            betSlipMarketIdToOutcomeIds: {},
            updatedAt: new Date().getTime(),
        });
        setBetSlipMarketIdToOutcomeIds({});
        stores.sports.parlayCard.betSlipErrors.set([]);
        setStakeValue(0);
        if (isMobile()) {
            stores.isParlayBetslipOpen.set(false);
        }
    }, [isLoading]);

    function isMarketDisabled(marketId: number) {
        return (
            parlayCardStatus !== 'OPEN' ||
            betSlipMarketIdToOutcomeIds[marketId]?.some((outcomeId) => disabledOutcomeIds.includes(outcomeId))
        );
    }

    return (
        <Wrapper $isRetailLayout={isRetailLayout}>
            {isEmpty(betSlipMarketIdToOutcomeIds) && isEmpty(receiptById) && (
                <>
                    <div className="pick-bets">
                        <div className="pick-bets-ico animated bounceInDown">
                            <Svg icon="betslip-ico" size={2.6} />
                        </div>
                        <div className="pick-bets-text animated fadeIn">
                            {translate('Pick your bets', 'ui.sportsbook')}
                        </div>
                    </div>
                    <div className="pick-bets-arrows">
                        <UiDottedArrow size={8} rotate={180} />
                        <UiDottedArrow size={8} rotate={180} />
                        <UiDottedArrow size={8} rotate={180} />
                    </div>
                </>
            )}

            {!isEmpty(betSlipMarketIdToOutcomeIds) && (
                <>
                    {isRetailLayout && <SportParlayCardHeader name={parlayCard.name} clearSelection={clearSelection} />}
                    <div className="selection-count">
                        <b>{betSlipOutcomes.length} </b>/{maxSelections}
                    </div>
                    <div className="selections">
                        {betSlipOutcomes.map((betslipOutcome) => {
                            return (
                                <div key={betslipOutcome.id}>
                                    <SportParlayCardBetslipOutcome
                                        outcome={betslipOutcome}
                                        handicapTeaserPoints={handicap_teaser_points}
                                        totalsTeaserPoints={totals_teaser_points}
                                        isDisabled={isMarketDisabled(betslipOutcome.marketId)}
                                        hasLineChanged={lineChangedMarketIds.includes(betslipOutcome.marketId)}
                                    />
                                    <div className="divider" />
                                </div>
                            );
                        })}

                        <SportParlayCardBetslipStakeInput
                            value={stakeInputValue}
                            onChange={stakeChange}
                            isDisabled={isMAErrorForParlayCard()}
                        />

                        {isMAErrorForParlayCard() && isMAStakeAllowedForParlayCard() && (
                            <UiAnimate animationIn="bounceIn" animationOut="bounceOut" isVisible>
                                <SportParlayCardBetslipStakeInput
                                    value={manualAcceptanceStake}
                                    onChange={stakeChange}
                                    label={translate('Apply extra stake', 'ui.sportsbook')}
                                    isDisabled={false}
                                />
                            </UiAnimate>
                        )}

                        <div className="total-odds">
                            <span className="stake-input-label">{translate('Total odds', 'ui.betslip')} →</span>
                            <span className="stake-input-value">{convertOdds(totalOdds)}</span>
                        </div>

                        {maxWin > 0 && stakeValue > 0 && (
                            <div className="potential-return">
                                <span className="stake-input-label">
                                    {translate('Potential return', 'ui.betslip')} →
                                </span>
                                <span
                                    className={classNames('stake-input-value', { 'animated-value': isStakeChanging })}
                                >
                                    {formattedAmountWithCurrency(maxWin)}
                                </span>
                            </div>
                        )}
                    </div>
                    {betSlipErrors?.map((error) => (
                        <UiAlert failure className="error-alert" key={error}>
                            {getErrorMessage(error) || translate('Something went wrong', 'ui.common')}
                        </UiAlert>
                    ))}
                    {Object.values(validations).map((validationMessage) => (
                        <UiAlert failure className="warning-alert" key={validationMessage}>
                            {validationMessage}
                        </UiAlert>
                    ))}
                    <SportParlayCardBetslipButtons
                        isDisabled={isPlaceBetButtonDisabled}
                        clearSelection={clearSelection}
                    />
                    <div className="alerts-container" />

                    {isFeatureAvailable(FEATURE.PARLAY_CARD) && !isRetailLayout && (
                        <>
                            {Boolean(parlayCard) && (
                                <UiButton
                                    className="clear-selections"
                                    size="large"
                                    block
                                    onClick={clearSelection}
                                    disabled={isLoading}
                                >
                                    {translate('Clear selections', 'ui.sportsbook')}
                                </UiButton>
                            )}
                        </>
                    )}
                </>
            )}

            {isBYOD() && !isRetailLayout && (
                <>
                    {!isEmpty(betSlipMarketIdToOutcomeIds) && isEmpty(receiptById) && (
                        <RetailBringYourDevice betslipMode={BetslipMode.ParlayCard} />
                    )}

                    <div className="qr-code-nav">
                        {isPrevEnabled ? (
                            <Svg
                                icon="drawer"
                                size={1}
                                onClick={() => navigateToBetslip(betSlipPosition - 1, BetslipMode.ParlayCard)}
                                className="drawer-icon"
                            />
                        ) : (
                            <div className="drawer-icon" />
                        )}
                        {Boolean(betslipCollection.length) && (
                            <div className="pagination-counter">
                                {betSlipPosition + 1} / {betslipCollection.length}
                            </div>
                        )}
                        {isNextEnabled ? (
                            <Svg
                                icon="drawer"
                                size={1}
                                rotate={180}
                                onClick={() => navigateToBetslip(betSlipPosition + 1, BetslipMode.ParlayCard)}
                                className="drawer-icon"
                            />
                        ) : (
                            <div className="drawer-icon" />
                        )}
                    </div>
                    {Boolean(betslipMarketIds.length) && (
                        <UiButton
                            className="action-btn"
                            color="primary"
                            size="large"
                            block
                            onClick={() => storeAndClearSelection(BetslipMode.ParlayCard)}
                            disabled={isLoading}
                        >
                            {translate('Add New', 'ui.sportsbook')}
                        </UiButton>
                    )}
                    {Boolean(betslipCollection.length) && (
                        <UiButton
                            size="large"
                            className="action-btn"
                            block
                            onClick={() => deleteBetSlip(BetslipMode.ParlayCard)}
                            disabled={isLoading}
                        >
                            {translate('Delete', 'ui.sportsbook')}
                        </UiButton>
                    )}
                </>
            )}
            {uniq(ticketIds).map((ticketId) => (
                <SportParlayCardBetslipReceipt key={ticketId} ticketId={ticketId} />
            ))}
        </Wrapper>
    );
}

export default withErrorBoundary(React.memo(SportParlayCardBetslip));
