import styled from 'styled-components';

export default styled.div`
    .casino-thumbnail-label-provider {
        margin-top: -1px;
        padding: var(--spacing-8) 0;
        width: 100%;
        text-align: center;
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering};
        font-size: var(--font-14);
        color: var(--font-color-primary);
        transition: opacity 0.2s;
        border-radius: var(--ui-radius) var(--ui-radius) 0 0;
        background-color: rgba(0, 0, 0, 0.6);
    }
`;
