import styled from 'styled-components';

export default styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;

    .container {
        box-sizing: border-box;
        padding: var(--spacing-8) var(--spacing-4);
        background: rgba(0, 0, 0, 0.45);
        border-radius: 0 0 var(--ui-radius) var(--ui-radius);
        column-gap: var(--spacing-4);
        row-gap: var(--spacing-32);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        height: 2rem;
        max-height: 2rem;
        overflow: hidden;
    }

    .double-ball-roulette {
        height: 3rem;
        max-height: 3rem;
    }

    .item {
        font-family: var(--font-family);
        font-weight: var(--font-weight-heavy);
        font-size: var(--font-10);
        line-height: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: calc(var(--ui-radius) / 4);
        width: 18px;
        height: 18px;
        flex: 0 0 auto;
    }

    .item-double-ball-roulette {
        height: 36px;
        border: 1px solid #ffffff;
    }
`;
