import React from 'react';
import Wrapper from './styles';
import Snippet from '../../snippet/Snippet';
import { translate } from '../../../services/translate';
import { FacebookIcon, FacebookShareButton, TwitterShareButton } from 'react-share';
import WhatsappShareButton from 'react-share/es/WhatsappShareButton';
import WhatsappIcon from 'react-share/es/WhatsappIcon';
import EmailShareButton from 'react-share/es/EmailShareButton';
import EmailIcon from 'react-share/es/EmailIcon';
import Seo from '../../seo/Seo';
import { getRoute, useRouter } from '../../../services/router';
import upperCase from 'lodash/upperCase';
import UiNavLink from '../../ui/nav-link/UiNavLink';
import { useCmsEvents } from '../../../services/cms';
import { LANGUAGE } from '../../../services/language';
import UiButton from '../../ui/button/UiButton';
import Icon from '../../icon/Icon';

interface Props {
    careerPosition: string;
    formId: string;
    color: 'brandColor3' | 'primary' | 'success' | 'brandColor4';
}

export default function CmsCareerOffer({ careerPosition, formId, color = 'primary' }: Props) {
    const { location, fullUrl } = useRouter();
    const paths = location.pathname.split('/');
    const isContentVisible = paths[paths.length - 1] === careerPosition;
    const [openTypeform] = useCmsEvents('openTypeform');

    const jobOfferTitleKey = `career-offer.${careerPosition}.title`;
    const jobOfferTitleTranslation = translate(jobOfferTitleKey);

    const jobOfferTitle =
        jobOfferTitleTranslation === jobOfferTitleKey
            ? upperCase(careerPosition)
            : jobOfferTitleTranslation.replace(/\w+/gm, upperCase);

    return (
        <Wrapper color={color}>
            <UiNavLink to={`${getRoute('about.career')}/${careerPosition}`} className="offer-title">
                <h2>{jobOfferTitle}</h2>
            </UiNavLink>

            {isContentVisible && (
                <div className="content">
                    <Seo
                        title={translate(`seo.career-offer.${careerPosition}.title`)}
                        description={translate(`seo.career-offer.${careerPosition}.description`)}
                    />

                    <div className="share-with-friends">
                        <FacebookShareButton url={fullUrl}>
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>

                        <TwitterShareButton url={fullUrl}>
                            <Icon icon="twitter" medium />
                        </TwitterShareButton>

                        <WhatsappShareButton url={fullUrl}>
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>

                        <EmailShareButton url={fullUrl}>
                            <EmailIcon size={32} round />
                        </EmailShareButton>
                    </div>

                    <Snippet snippetKey={`ui.about.career.${careerPosition}`} language={LANGUAGE.ENGLISH} />

                    {formId && (
                        <UiButton onClick={() => openTypeform.send({ formId })} icon="user-icon">
                            {translate('Apply', 'ui.about.career')}
                        </UiButton>
                    )}
                </div>
            )}
        </Wrapper>
    );
}
