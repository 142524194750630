import React from 'react';
import UiModal from '../../../ui/modal/UiModal';
import Ui2Form from '../../../ui-2/form/Ui2Form';
import { useForm } from 'react-hook-form';
import { stores } from '../../../../stores';
import Ui2FormRadio from '../../../ui-2/form/radio/Ui2FormRadio';
import UiButton from '../../../ui/button/UiButton';
import { translate } from '../../../../services/translate';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}
export default function AccountMenuCurrencySelect({ isOpen, onClose }: Props) {
    const [, setWallet] = useStore(stores.wallet);
    const [wallets] = useStore(stores.wallets);
    const useFormMethods = useForm({
        defaultValues: {
            currency: undefined,
        },
    });

    function changeCurrency() {
        const { currency } = useFormMethods.getValues();
        const newWallet = wallets.find((wallet) => wallet.currency === currency);
        setWallet(newWallet);
        onClose();
    }

    if (!isFeatureAvailable(FEATURE.MULTI_CURRENCY_WALLET)) {
        return null;
    }

    return (
        <UiModal className="new-modal" open={isOpen} onClose={onClose}>
            <Ui2Form onSubmit={changeCurrency} useFormMethods={useFormMethods}>
                <Ui2FormRadio
                    name="currency"
                    options={wallets.map((wallet) => ({
                        label: `${wallet.balance_uc} ${wallet.currency}`,
                        value: wallet.currency,
                    }))}
                />
                <UiButton isFormSubmitButton color="primary" block>
                    {translate('Confirm', 'ui.common')}
                </UiButton>
            </Ui2Form>
        </UiModal>
    );
}
