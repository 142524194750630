import React, { useEffect, useState } from 'react';
import { stores } from '../../../../../stores';
import { useSportsUserSettings } from '../../../../../services/sports/user-settings';
import { logger } from '../../../../../services/logger';
import { getRoute, useRouter } from '../../../../../services/router';
import UiAlert from '../../../../ui/alert/UiAlert';
import { translate } from '../../../../../services/translate';
import { getBetbuilderCulture } from '../../../../../services/language';
import { FEATURE, isFeatureAvailable } from '../../../../../services/feature';
import { getClient, isB2B, isRetail } from '../../../../../services/environment';
import { getLicence } from '../../../../../services/licence';
import { Licence } from '@staycool/location';
import { getBetbuilderClientMarketInfo } from '../../../../../microservices/sports';
import { resetComboCardBetSlip } from '../../../../../services/sports/combo-cards';
import { getStoreValue } from '../../../../../stores/store/utils';
import { retail } from '../../../../../stores/retail/retail';
import { environment } from '../../../../../stores/environment/environment';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    matchBetbuilderId?: number;
}

export default function SportMatchBetbuilderSportcast({ matchBetbuilderId }: Props) {
    const [{ BETBUILDER_WIDGET_URL, BETBUILDER_WIDGET_KEY, IS_CORE_BETBUILDER_INTEGRATION, STATE_SHORT_NAME: state }] =
        useStore(environment);
    const [sportcastIframeId] = useState<string>(`sportcast-iframe-${new Date().getTime().toString()}`);
    const { isAmericanOddsFormat } = useSportsUserSettings();
    const [language] = useStore(stores.language);
    const { navigateTo } = useRouter();
    const [{ isPhone }] = useStore(media);
    const [retailExpirationRefresh, setRetailExpirationRefresh] = useStore(retail.retailExpirationRefresh);
    const isRetailLayout = isRetail();

    useEffect(() => {
        const handleMessage = (ev: MessageEvent) => {
            console.log('SportMatchBetbuilderSportcast', 'handleMessage', `message: ${ev.data}`);
            if (ev?.origin !== BETBUILDER_WIDGET_URL || typeof ev?.data !== 'string') {
                return;
            }

            const { command, marketId, sportsbookMarketContext, betslipUid } = JSON.parse(ev.data) || {};
            if (command !== 'addToBetslip') {
                return;
            }

            const outcomeId = parseInt(sportsbookMarketContext);
            if (!marketId || !outcomeId) {
                logger.error(
                    'SportMatchBetbuilderSportcast',
                    'SportMatchBetbuilderSportcast',
                    `Missing data in addToBetslipMessage: ${ev.data}`,
                );
                return;
            }
            resetComboCardBetSlip(false);
            if (IS_CORE_BETBUILDER_INTEGRATION === 'true') {
                addClientMarketToBetslipByCoreId(marketId, outcomeId, betslipUid);
            } else {
                addToBetslip(marketId, outcomeId, betslipUid);
            }
        };
        window.addEventListener('message', handleMessage);

        return () => window.removeEventListener('message', handleMessage);
    }, []);

    useEffect(() => {
        if (!isRetailLayout) {
            return;
        }
        const interval = setIframeInteraction();
        return () => clearInterval(interval);
    }, [retailExpirationRefresh]);

    function setIframeInteraction() {
        const interval = setInterval(function () {
            const elem = document.activeElement;
            if (elem && elem instanceof HTMLElement && elem.id === sportcastIframeId) {
                setRetailExpirationRefresh(!retailExpirationRefresh);
                elem.blur();
            }
        }, 5000);
        return interval;
    }

    async function addClientMarketToBetslipByCoreId(coreMarketId: number, coreOutcomeId: number, betslipUid: string) {
        console.log('SportMatchBetbuilderSportcast', 'addClientMarketToBetslipByCoreId', {
            coreMarketId,
            coreOutcomeId,
            betslipUid,
        });

        stores.sports.betSlipPlacingState.set((state) => ({ ...state, isLoading: true }));
        const { clientMarketId, clientOutcomeId } =
            (await getBetbuilderClientMarketInfo(coreMarketId, coreOutcomeId)) || {};
        stores.sports.betSlipPlacingState.set((state) => ({ ...state, isLoading: false }));

        if (!clientMarketId || !clientOutcomeId) {
            logger.error(
                'SportMatchBetbuilderSportcast',
                'addClientMarketToBetslipByCoreId',
                'Could not find client market info',
            );
            return;
        }

        addToBetslip(clientMarketId, clientOutcomeId, betslipUid);
    }

    function addToBetslip(marketId: number, outcomeId: number, betslipUid: string) {
        console.log(
            'SportMatchBetbuilderSportcast',
            'addToBetslip',
            `params: ${marketId}, ${outcomeId}, ${betslipUid}`,
        );
        const { isLoading, needsConfirm } = getStoreValue(stores.sports.betSlipPlacingState);
        if (isLoading || needsConfirm) {
            console.log('SportMatchBetbuilderSportcast', 'addToBetslip', `info:${isLoading}, ${needsConfirm}`);
            return;
        }

        stores.sports.betSlipMarketIdToOutcomeId.set((state) => {
            state[marketId] = outcomeId;
        });

        stores.sports.betbuilderBetslipIdByMarketId.set((state) => {
            state[marketId] = betslipUid;
        });

        if (isPhone && isFeatureAvailable(FEATURE.BETSLIP_NAVIGATION)) {
            navigateTo(getRoute('sport.betslip'));
        }
    }

    function getIframeUrl(betbuilderId: number) {
        const params = [
            `key=${BETBUILDER_WIDGET_KEY}`,
            `ConsumerFixtureId=${betbuilderId}`,
            `odds=${isAmericanOddsFormat ? 'AmericanPrice' : 'DecimalPrice'}`,
            `culture=${getBetbuilderCulture(language)}`,
            `brand=${getClient()}`,
        ];

        const region = isFeatureAvailable(FEATURE.BETBUILDER_REGIONS) && getRegion();
        if (region) {
            params.push(`region=${region}`);
        }

        return `${BETBUILDER_WIDGET_URL}?${params.join('&')}`;
    }

    function getRegion() {
        if (isB2B()) {
            if (!state) {
                return;
            }
            return state;
        }
        const licenceNameMap = {
            [Licence.ESTONIA]: 'Estonia',
            [Licence.MALTA]: 'Malta',
            [Licence.MEXICO]: 'Mexico',
            [Licence.SWEDEN]: 'Sweden',
        };
        return licenceNameMap[getLicence()];
    }

    return (
        <>
            {matchBetbuilderId && (
                <div>
                    <iframe
                        id={sportcastIframeId}
                        title="iframe"
                        className="iframe"
                        src={getIframeUrl(matchBetbuilderId)}
                    />
                </div>
            )}
            {!matchBetbuilderId && (
                <UiAlert warning className="alert">
                    {translate('sb.betbuilder-not-available')}
                </UiAlert>
            )}
        </>
    );
}
