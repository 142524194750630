import * as classnames from 'classnames';
import React from 'react';
import { BG_GAME_SPORTS, SPORT_ID } from '../../../../../services/sports/constants';
import { stores, useStoreWithSelector } from '../../../../../stores';
import Wrapper from './styles';
import SportCategoryIcon from '../../../category-icon/SportCategoryIcon';
import { FoCategoryMatch } from '@staycool/sbgate-types';

interface Props {
    match: FoCategoryMatch;
}

export default function SportMatchScoreboardBgRounds({ match }: Props) {
    const isBadminton = SPORT_ID.BADMINTON === match.sport;
    const isTableTennis = SPORT_ID.TABLE_TENNIS === match.sport;
    const isBeachVolleyball = SPORT_ID.BEACH_VOLLEYBALL === match.sport;
    let roundType = 'Set';
    if (BG_GAME_SPORTS.includes(match.sport)) {
        roundType = 'Game';
    }
    const [inplayData] = useStoreWithSelector(stores.sports.inplayMatchData, (state) => state[match.id], [match.id]);

    const { current_server, home_team_games, away_team_games, scoreboard = {} } = inplayData || {};
    const currentRound = (home_team_games as number) + (away_team_games as number) + 1;
    const setsArray = isBadminton || isBeachVolleyball || isTableTennis ? [1, 2, 3] : [1, 2, 3, 4, 5];
    if (isTableTennis && currentRound > 3) {
        setsArray.push(...[4, 5]);
    }

    const getScoreboardDisplayValue = (value: number | null | false) => {
        // value is number when we have all the data
        // value is null when we don't have data for past set(s)
        //   -- this will happen if live-match was booked after kick-off, or if BG failed to deliver data
        // value is false when scoreboard does not contain a set
        //   - this will happen when new set has just started and no point was scored yet
        if (value === null) {
            return 'N/A';
        }
        return value || 0;
    };
    return (
        <Wrapper>
            <div className="background">
                <div className="scoreboard">
                    <div className="scoreboard-box">
                        <div className="team">
                            <div className="team-name">{match.home_team_name}</div>
                            {current_server === 'home' && (
                                <div className="possession">
                                    <SportCategoryIcon tiny icon={match.sport_icon} />
                                </div>
                            )}
                            {setsArray.map((set, index) => {
                                const roundNumber = index + 1;
                                return (
                                    <div
                                        key={index}
                                        className={classnames('set-points', {
                                            active: currentRound === roundNumber,
                                        })}
                                    >
                                        <div className="set-name">{`${roundType} ${set}`}</div>
                                        {getScoreboardDisplayValue(
                                            scoreboard[roundNumber] && scoreboard[roundNumber].home,
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="team">
                            <div className="team-name">{match.away_team_name}</div>
                            {current_server === 'away' && (
                                <div className="possession">
                                    <SportCategoryIcon small icon={match.sport_icon} />
                                </div>
                            )}
                            {setsArray.map((set, index) => {
                                const roundNumber = index + 1;
                                return (
                                    <div
                                        key={index}
                                        className={classnames('set-points', {
                                            active: currentRound === roundNumber,
                                        })}
                                    >
                                        {getScoreboardDisplayValue(
                                            scoreboard[roundNumber] && scoreboard[roundNumber].away,
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
