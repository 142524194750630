import styled from 'styled-components';

export default styled.div`
    padding: 0.8rem 0;
    .update-buttons {
        margin-top: 1.6rem;
        display: flex;
        flex-direction: column;
        > *:not(:last-of-type) {
            margin-bottom: 0.4rem;
        }
    }
`;
