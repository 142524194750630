export const breakpoints = {
    phoneMax: 800,
    phoneMin: 380,
    phoneMedium: 600,
    tabletMin: 800,
    tabletMedium: 1000,
    tabletMax: 1200,
    laptopMin: 1440,
    desktopMin: 1440,
    desktopSmall: 1600,
    desktopMedium: 1800,
    desktop4K: 3800,
    desktop8k: 7600,
};

export const mediaQuery = {
    isPhone: `(max-width: ${breakpoints.phoneMax}px)`,
    isTablet: `(max-width: ${breakpoints.tabletMax}px) and (min-width: ${breakpoints.phoneMax + 1}px)`,
    isLaptop: `(max-width: ${breakpoints.desktopMin}px) and (min-width: ${breakpoints.tabletMax + 1}px)`,
    isDesktop: `(min-width: ${breakpoints.desktopMin + 1}px)`,
    isDesktopMedium: `(max-width: ${breakpoints.desktopMedium}px) and (min-width: ${breakpoints.desktopSmall + 1}px)`,
    isLaptopMin: `(max-width: ${breakpoints.laptopMin}px) and (min-width: ${breakpoints.tabletMax + 1}px)`,
    isTabletMax: `(max-width: ${breakpoints.tabletMax}px) and (min-width: ${breakpoints.tabletMin + 1}px)`,
    isTabletMediumLarge: `(max-width: ${breakpoints.tabletMax}px) and (min-width: ${breakpoints.tabletMedium + 1}px) `,
    isTabletMediumSmall: `(max-width: ${breakpoints.tabletMedium}px) and (min-width: ${breakpoints.tabletMin + 1}px) `,
    isTabletMin: `(max-width: ${breakpoints.tabletMin}px) and (min-width: ${breakpoints.phoneMedium + 1}px)`,
    isPhoneMedium: `(max-width: ${breakpoints.phoneMedium}px) and (min-width: ${breakpoints.phoneMin + 1}px)`,
    isPhoneMin: `(max-width: ${breakpoints.phoneMin}px)`,
    isDesktopSmall: `(max-width: ${breakpoints.desktopSmall}px) and (min-width: ${breakpoints.laptopMin + 1}px)`,
    isSmallerThanLaptop: `(max-width: ${breakpoints.tabletMax}px)`,
    isDigitalBoardSmallScreen: `(max-width: ${breakpoints.desktopSmall}px)`,
    is4kDesktop: `(min-width: ${breakpoints.desktop4K}px) and (max-width: ${breakpoints.desktop8k}px)`,
    is8kDesktop: `(min-width: ${breakpoints.desktop8k}px)`,
    isLargerThanPhone: `(min-width: ${breakpoints.phoneMax}px)`,
    isMobileLandscapeMode: `(orientation: landscape) and (max-height: ${breakpoints.phoneMax}px)`,
};

const globalEventHandlers = [
    'onAbort',
    'onAnimationEnd',
    'onAnimationIteration',
    'onAuxClick',
    'onBlur',
    'onCancel',
    'onCanPlay',
    'onCanPlayThrough',
    'onChange',
    'onClick',
    'onClose',
    'onContextMenu',
    'onDoubleClick',
    'onDurationChange',
    'onEnded',
    'onError',
    'onFocus',
    'onGotPointerCapture',
    'onInput',
    'onInvalid',
    'onKeyDown',
    'onKeyPress',
    'onKeyUp',
    'onLoad',
    'onLoadedData',
    'onLoadedMetadata',
    'onLoadStart',
    'onLostPointerCapture',
    'onMouseDown',
    'onMouseEnter',
    'onMouseLeave',
    'onMouseMove',
    'onMouseOut',
    'onMouseOver',
    'onMouseUp',
    'onPause',
    'onPlay',
    'onPointerCancel',
    'onPointerDown',
    'onPointerEnter',
    'onPointerLeave',
    'onPointerMove',
    'onPointerOut',
    'onPointerOver',
    'onPointerUp',
    'onReset',
    'onScroll',
    'onSelect',
    'onSubmit',
    'onTouchCancel',
    'onTouchStart',
    'onTransitionEnd',
    'onWheel',
];

export function filterStyleProps(props) {
    const onEventRegex = /^on[A-Z].*$/;
    const styleProps = {};

    for (const propName of Object.keys(props)) {
        const isInexistentNativeEventHandler = propName.match(onEventRegex) && !globalEventHandlers.includes(propName);
        const isInvalidDomProp = ['forwardedRef', 'isLoading', 'fullStoryBlock'].includes(propName);

        if (isInexistentNativeEventHandler || isInvalidDomProp) {
            // eslint-disable-next-line no-continue
            continue;
        }

        styleProps[propName] = props[propName];
    }

    return styleProps;
}
