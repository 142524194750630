import styled, { css } from 'styled-components';

type StyledProps = {
    $showFeatures?: boolean;
};

export default styled.div<StyledProps>`
    display: flex;
    margin-right: 10px;
    flex-grow: 1;
    flex-basis: 50%;

    .poker-coming-soon-button {
        border: unset;
        font-size: 14px;
        font-weight: 700;
        min-width: 240px;

        @media (max-width: 1200px) {
            min-width: unset;
        }

        .button-text {
            margin-left: 7px;
            white-space: normal;
            text-align: center;
        }

        .button-icon {
            margin-bottom: 5px;
        }
    }

    .poker-feature-tournament-warning-container {
        padding: 0 10px;
        flex-grow: 1;
        flex-basis: 200px;
        display: flex;
        justify-content: center;
    }

    .poker-feature-tournament-warning {
        border-radius: var(--ui-radius);
        background-color: rgba(251, 0, 27, 0.6);
        padding: 10px;
        font-size: 11px;
        max-width: 260px;
    }

    ${({ $showFeatures }) =>
        !$showFeatures &&
        css`
            .poker-play,
            button {
                display: flex;
                flex-grow: 1;
                flex-basis: 100%;
            }

            flex-wrap: wrap;
            align-items: center;

            .poker-feature-tournament-warning-container {
                padding: 5px 5px;
            }

            .poker-feature-tournament-warning {
                max-width: unset;

                p {
                    margin: 3px 0;
                    font-weight: 700;
                }
            }
        `}

    ${({ $showFeatures }) =>
        !$showFeatures &&
        css`
            .poker-play,
            button {
                display: flex;
                flex-grow: 1;
            }
        `}
    
    ${({ $showFeatures }) =>
        $showFeatures &&
        css`
            align-items: center;
        `}
`;
