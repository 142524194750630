import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { logger } from '../services/logger';
import { BONUS_STATUS, getUnifiedBonusResponse } from '../services/bonus';
import orderBy from 'lodash/orderBy';
import { getMexicanMarketPromotionsLanguage, handleDynamicPromotionsCount } from '../services/promotion';
import { checkCasinoActiveBonus } from '../services/casino/init';
import { getProvinceOntarioOrNull, getUserCountry } from '../services/user';
import { getSportsLayout } from '../services/sports/user-settings';
import { WithLanguageLayout } from '@staycool/sbgate-types';
import { FoMatch } from '@staycool/sports-types';
import { setSingleCategoryMarketTypesToStore } from '../services/sports/sport-category';
import { SportCategoryWithMatches } from '../services/sports/types';
import { FEATURE, isFeatureAvailable } from '../services/feature';
import { BonusType } from '../services/sports/constants';

const getUrl = (url) => getServiceUrl('bonuses', url);

export async function getBonusOffers({ reload }) {
    if (!isFeatureAvailable(FEATURE.BONUS_OFFERS)) {
        return;
    }

    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const language = getStoreValue(stores.language);
    const promotions = getStoreValue(stores.promotions);

    const requestLanguage = getMexicanMarketPromotionsLanguage(language);

    if (promotions && promotions.length && !reload) {
        return promotions;
    }

    const urlString = `campaigns/available/${isAuthenticated ? '' : 'public/'}${requestLanguage}`;
    const url = getUrl(urlString);

    try {
        const response = await httpGet<Campaign[]>(url);
        stores.promotions.set(orderBy(response, (promotion) => promotion.display && promotion.display.order));
        handleDynamicPromotionsCount();
    } catch (error) {
        logger.error('BonusesMicroservice', 'getBonusOffers', error);
    }
}

export async function submitBonusCode(bonusCode, isDepositing = false) {
    try {
        const url = getUrl('code/');
        const response = await httpPost(url, { code: bonusCode, depositing: isDepositing });
        checkCasinoActiveBonus();
        return getUnifiedBonusResponse(response, false);
    } catch (error) {
        logger.dev('BonusesMicroservice', 'submitBonusCode', error);
        return getUnifiedBonusResponse(BONUS_STATUS.SUBMISSION_FAILED, false);
    }
}

export async function validateBonusCode(bonusCode, selectedProvider) {
    try {
        const url = getUrl('code/validate');
        const response = await httpPost(url, {
            code: bonusCode,
            provider: selectedProvider,
        });
        return getUnifiedBonusResponse(response, false);
    } catch (error) {
        logger.dev('BonusesMicroservice', 'validateBonusCode', error);
        return getUnifiedBonusResponse(BONUS_STATUS.SUBMISSION_FAILED, false);
    }
}

export async function validateBonusCodePublic(bonusCode) {
    try {
        const url = getUrl('public/code/validate');
        const response = await httpPost(url, {
            code: bonusCode,
        });
        return getUnifiedBonusResponse(response, false);
    } catch (error) {
        logger.dev('BonusesMicroservice', 'validateBonusCodePublic', error);
        return getUnifiedBonusResponse(BONUS_STATUS.SUBMISSION_FAILED, false);
    }
}

export function cancelPokerPendingBonus(bonus) {
    const url = getUrl('user/cancel/');
    return httpPost(url, { user_bonus_id: bonus.id });
}

export function getUserTakenBonuses(lang) {
    const url = getUrl('user/view/');
    return httpGet<UserViewBonus[]>(url, { lang });
}
enum BonusBetType {
    SINGLE = 'single',
    COMBO = 'combo',
}

enum BonusBetProduct {
    LIVEBET = 'LIVE',
    PREMATCH = 'PREMATCH',
}
export interface BonusBet {
    wagering_ratio?: number;
    wagering_min_odds?: number;
    wagering_duration?: number;
    amount?: number;
    code: string;
    id: string;
    matches: number[];
    categories: number[] | null;
    odds_value: number;
    sb_product: BonusBetProduct;
    minAmount: number;
    bet_type: BonusBetType | null;
    multiplierSettings?: { maxNetWin: number; multiplier: number };
    article?: string;
    bonusType?: BonusType;
    quantity?: number;
    expires_at?: Date;
    userBonusId?: string;
}

export interface BonusBetsResponse {
    free_bet: BonusBet[];
    free_bet_v2: BonusBet[];
    risk_free_bet: BonusBet[];
    multiplier: BonusBet[];
}

export async function getOfferedBonuses(params: { bonusType?: BonusType; currency: string }) {
    const url = getUrl('bonus-bet/available');
    return httpGet<BonusBetsResponse>(url, params);
}

type RequestMatchList = Pick<
    WithLanguageLayout<FoMatch.FoMatchListFilteredCampaigns>,
    'offset' | 'limit' | 'country' | 'locale' | 'layout' | 'language' | 'province'
>;

export async function getBonusMatchesList(bonusId: string, params: { offset: number; limit: number }) {
    const language = getStoreValue(stores.language);
    const country = getUserCountry();
    const province = getProvinceOntarioOrNull();
    const layout = getSportsLayout();
    const url = getUrl(`bonus-bet/matches/${bonusId}`);
    const query: RequestMatchList = {
        ...params,
        language,
        locale: language,
        country,
        province: province || undefined,
        layout,
    };
    const categoryWithMatches = await httpGet<SportCategoryWithMatches>(url, query);
    setSingleCategoryMarketTypesToStore(categoryWithMatches);
    return categoryWithMatches?.matches;
}

export async function getBonusBetWageringProgress() {
    const url = getUrl('bonus-bet/wagering-progress');
    return httpGet<{ wageringProgress?: number }>(url);
}

export function getBonusCodeCounter(bonusId) {
    const url = getUrl(`code/counter-id/${bonusId}`);
    return httpGet<number>(url);
}

export async function checkAndValidateUserCode(bonusCode: string) {
    const url = getUrl(`code/check-validate`);
    return httpPost<{
        status?: string;
        min_bonus?: Record<string, number>;
        name?: string;
        validation_message?: Record<string, string>;
    }>(url, { code: bonusCode });
}

export type UserViewBonus = {
    bonus_type: string;
    bonus_name?: string;
    article?: any;
    description?: any;
    bonus_amount: number;
    created: string;
    deposit: string;
    product: 'ALL' | 'CASINO' | 'SPORTSBOOK' | 'POKER' | 'VIRTUAL_SPORTS';
    status: string;
    user_id: string;
    poker_details?: any;
    id?: string;
    lock?: any;
    cancelAmount?: number;
    bonus_internal_name: string;
    bonus_id: string;
    wagering_ratio: string;
    wagering_min_odds: string;
};

type DepositProviderPolicy = 'whitelist' | 'blacklist' | null;
export interface Campaign {
    id: string;
    name_external: string;
    description: Record<string, string>;
    terms_and_conditions: string;
    images: Record<string, string>;
    segment: string | null;
    display: {
        order: number;
        logged: string;
        placement: string[];
        show_in_featured_section: boolean;
    };
    article_id: string;
    code: string;
    bonus_type: BonusType;
    products: {
        sportsbook: boolean;
        casino: boolean;
        poker: boolean;
        virtual_sports: boolean;
    };
    status?: {
        active: boolean;
        public: boolean;
    };
    start_time: string;
    end_time: string;
    article?: {
        id: string;
        category: string;
        published: string;
        mobile: boolean;
        slug: Record<string, string>;
    };
    deposit: {
        ratio: number;
        first_deposit: Date;
        forbiddenProviders: string[];
        depositProviderPolicy: DepositProviderPolicy;
    };
    is_taken_by_user?: boolean;
    participants?: number;
}

export interface CampaignGroups {
    all: string;
    newAndFeatured: string;
    [key: string]: string;
}
