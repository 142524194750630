import { useLayoutEffect, useEffect } from 'react';
import { stores } from '../../../stores';
import { getCasinoFilter } from '../../../services/casino/filter';
import { setCasinoPaybackBoosterCompactViewState } from '../../../services/casino/payback-booster';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

export default function SystemCasino() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [{ isDesktop, isLaptop }] = useStore(media);
    const [isPaybackBoosterCompactView] = useStore(stores.casino.isPaybackBoosterCompactView);

    const isDesktopActual = isDesktop || isLaptop;

    function handleCasinoReloading(isAuthenticated) {
        stores.casino.playForReal.set(isAuthenticated);
        stores.casino.shouldReload.set(true);
    }

    useLayoutEffect(() => {
        handleCasinoReloading(isAuthenticated);
    }, [isAuthenticated, isDesktopActual]);

    useLayoutEffect(() => {
        stores.casino.filter.set(getCasinoFilter());
    }, [isAuthenticated]);

    useEffect(() => {
        setCasinoPaybackBoosterCompactViewState(isPaybackBoosterCompactView);
    }, [isPaybackBoosterCompactView]);

    return null;
}
