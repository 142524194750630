import styled from 'styled-components';

export default styled.div`
    .content {
        background: #333;
        padding: 20px;
    }

    .offer-title {
        text-decoration: none;

        h2 {
            color: ${(props) => props.color && props.theme.colors[props.color]};
        }
    }

    .share-with-friends {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 0.6rem;

        > * {
            &:not(:last-of-type) {
                margin-right: 0.5rem;
            }
        }
    }
`;
