import React, { useEffect } from 'react';
import Wrapper from './styles';
import CasinoGameInfo from '../CasinoGameInfo';
import { CasinoGame } from '../../../../../services/casino/games';
import { useLiveChatKustomerContext } from '../../../../../contexts/live-chat-kustomer/LiveChatKustomerContext';

interface Props {
    game: CasinoGame;
    isOpen: boolean;
    onClose: () => void;
    onGameClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, game: CasinoGame) => void;
}
export default function CasinoGameInfoModal({ game, isOpen, onClose, onGameClick }: Props) {
    const { setIsIconHidden, resetIsIconHidden } = useLiveChatKustomerContext();

    useEffect(() => {
        if (isOpen) {
            setIsIconHidden(true);
            return resetIsIconHidden;
        }
    }, [isOpen]);

    return (
        <Wrapper open={isOpen} onClose={onClose} scroll="body" maxWidth="sm">
            <CasinoGameInfo
                game={game}
                onClose={onClose}
                onGameClick={(event) => {
                    onClose();
                    if (onGameClick) {
                        onGameClick(event, game);
                    }
                }}
            />
        </Wrapper>
    );
}
