import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    .amount {
        &.success {
            color: var(--color-success);
        }

        &.info {
            color: var(--color-info);
        }

        &.error {
            color: var(--color-error);
        }
    }
    .table-cell-actions {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        .action-buttons {
            display: flex;
        }
    }
    .cancel-payout-button,
    .lock-payout-button {
        color: white;
        .button-text {
            min-width: auto;
            display: flex;
            align-items: center;
            .svg-icon {
                display: inline-block;
                svg {
                    height: 15px;
                    width: 15px;
                }
            }
            span.text {
                margin-left: 3px;
                @media ${mediaQuery.isPhone} {
                    display: none;
                }
            }
        }
    }
    .cancel-payout-button {
        background-color: ${({ theme }) => theme.colors.error};
        .svg-icon svg {
            background: white;
            border-radius: 100%;
            path {
                fill: ${({ theme }) => theme.colors.error};
            }
        }
    }
    .lock-payout-button {
        background-color: ${({ theme }) => theme.colors.info};
        .svg-icon svg path {
            fill: white;
        }
    }
`;
