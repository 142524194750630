import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import classNames from 'classnames';
import Svg from '../../svg/Svg';
import RetailPrint from '../print/RetailPrint';
import UiNavLink from '../../ui/nav-link/UiNavLink';
import UiButton from '../../ui/button/UiButton';
import UiReel from '../../ui/reel/UiReel';
import Logo from '../../logo/Logo';
import round from 'lodash/round';
import isEmpty from 'lodash/isEmpty';
import { translate } from '../../../services/translate';
import { stores } from '../../../stores';
import { getRoute, useRouter } from '../../../services/router';
import { getRulePage } from '../../../microservices/cms';
import { clearActiveElementFocus } from '../../../services/util';
import { FEATURE, isFeatureAvailable } from '../../../services/feature';
import { ClientName } from '../../../services/utils/types';
import { RetailDeposit } from '../deposit/RetailDeposit';
import { getClient, isRetail } from '../../../services/environment';
import { retail } from '../../../stores/retail/retail';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

interface Props {
    simplifyHeader?: boolean;
}

export default function RetailHeader({ simplifyHeader = false }: Props) {
    const { pathname } = window.location;
    const [wallet] = useStore(stores.wallet);
    const [balanceDifference, setBalanceDifference] = useState(0);
    const [isBalanceVisible, setIsBalanceVisible] = useStore(retail.isRetailBalanceVisible);
    const [isPositiveBalanceUpdate, setIsPositiveBalanceUpdate] = useState(true);
    const [currentBalance, setCurrentBalance] = useState(wallet?.balance_uc || 0);
    const [isBalanceChangeActivated, setIsBalanceChangeActivated] = useState(false);
    const [ticketsToPrint] = useStore(stores.sports.ticketsToPrint);
    const [isRetailModalLoyalty, setIsRetailModalLoyalty] = useStore(retail.retailModals.isRetailModalLoyalty);
    const [loyalty] = useStore(retail.loyalty);
    const [retailTerminal] = useStore(retail.retailTerminal);
    const [previousPath, setPreviousPath] = useStore(stores.rulesPagePreviousPath);
    const isRetailLayout = isRetail();
    const [rulesPageAvailable, setRulesPageAvailable] = useStore(stores.rulesPageAvailable);
    const [isDisplayRulesPage, setIsDisplayRulesPage] = useState(Boolean(isRetailLayout || rulesPageAvailable));
    const defaultAnimation = { duration: 700, delay: 85 };
    const [balanceAnimation, setBalanceAnimation] = useState(defaultAnimation);
    const { navigateTo } = useRouter();
    const isTwoToneTheme = !isFeatureAvailable(FEATURE.THEME_SINGLE_TONE);
    const [{ isPhone }] = useStore(media);
    const [hasRetailSession] = useStore(retail.hasRetailSession);

    const difference = function (a, b) {
        return Math.abs(a - b);
    };

    const isRulesPage = pathname.includes(getRoute('retail.rules'));
    const homeRoute = isRetailLayout ? getRoute('retail') : getRoute('sport');

    useEffect(() => {
        loadRulesPage();
    }, []);

    useEffect(() => {
        if (wallet && currentBalance !== wallet?.balance_uc) {
            setBalanceAnimation(defaultAnimation);
            setBalanceDifference(difference(currentBalance, wallet?.balance_uc));
            currentBalance < wallet?.balance_uc ? setIsPositiveBalanceUpdate(true) : setIsPositiveBalanceUpdate(false);
            setCurrentBalance(wallet?.balance_uc);
            setIsBalanceChangeActivated(true);
            balanceUpdated();
        }
    }, [wallet]);

    async function loadRulesPage() {
        if (!simplifyHeader || rulesPageAvailable !== null) {
            return;
        }
        const rulesPage = await getRulePage();
        if (rulesPage) {
            setIsDisplayRulesPage(true);
            setRulesPageAvailable(true);
        }
    }

    function balanceUpdated() {
        setIsBalanceChangeActivated(true);
        const balanceChangeAnimation = setTimeout(() => setIsBalanceChangeActivated(false), 800);
        return () => {
            clearTimeout(balanceChangeAnimation);
        };
    }

    function openRulesPage() {
        setPreviousPath(pathname);
        navigateTo(getRoute('retail.rules'));
    }

    return (
        <Wrapper isTwoToneTheme={isTwoToneTheme} simplifyHeader={simplifyHeader} isRulesPage={isRulesPage}>
            <div className="header-info">
                <div className="header-logo" onClick={clearActiveElementFocus}>
                    <UiNavLink to={homeRoute}>
                        <Logo size={simplifyHeader ? 30 : 55} />
                    </UiNavLink>
                    {(retailTerminal.alias || retailTerminal.id) && (
                        <div className="kiosk-id">{retailTerminal.alias || `Kiosk id: ${retailTerminal.id}`}</div>
                    )}
                </div>

                {!simplifyHeader && (
                    <>
                        {isFeatureAvailable(FEATURE.RETAIL_HEADER_HOME_BUTTON) && (
                            <UiButton
                                icon={<Svg icon="home-ico" />}
                                onClick={clearActiveElementFocus}
                                url={homeRoute}
                            />
                        )}
                        <div className="balance">
                            {wallet && hasRetailSession && !isRulesPage && (
                                <>
                                    {isFeatureAvailable(FEATURE.TOGGLE_RETAIL_BALANCE_VISIBILITY) && (
                                        <div
                                            className="toggle-balance"
                                            onClick={() => {
                                                setBalanceAnimation({ duration: 0, delay: 0 });
                                                setIsBalanceVisible(!isBalanceVisible);
                                            }}
                                        >
                                            <Svg icon={isBalanceVisible ? 'eye-invisible' : 'eye-visible'} size={3} />
                                        </div>
                                    )}

                                    <div
                                        className={classNames('header-balance', {
                                            'animated bounceIn': isBalanceChangeActivated,
                                        })}
                                    >
                                        <div className="balance-label">
                                            {isBalanceVisible ? 'balance' : 'balance hidden'}
                                        </div>
                                        <div className="balance-amount">
                                            {isBalanceVisible ? (
                                                <>
                                                    <div className="currency">$</div>
                                                    <div className="amount">
                                                        <UiReel
                                                            text={round(currentBalance, 2).toFixed(2)}
                                                            {...balanceAnimation}
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="amount">*****</div>
                                            )}
                                        </div>
                                        <div
                                            className={classNames('balance-update-bubble', {
                                                active: isBalanceChangeActivated,
                                                positive: isPositiveBalanceUpdate,
                                            })}
                                        >
                                            {isPositiveBalanceUpdate ? '+' : '-'}
                                            {round(balanceDifference, 2).toFixed(2)}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="header-loyalty">
                            {!isRulesPage && loyalty.loyalty_id && (
                                <>
                                    <div className="loyalty-label">Loyalty id</div>
                                    <div className="loyalty-amount">{loyalty.loyalty_id}</div>
                                </>
                            )}
                            {isEmpty(loyalty) && !isRulesPage && (
                                <div className="loyalty-id">
                                    <UiButton
                                        onClick={() => setIsRetailModalLoyalty(!isRetailModalLoyalty)}
                                        className="secondary-button"
                                    >
                                        {translate('Loyalty ID', 'retail.header')}
                                    </UiButton>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>

            <div className="header-controls">
                <div className="tickets-icon">
                    {ticketsToPrint.length > 0 && !isRulesPage && !simplifyHeader && (
                        <div className="header-tickets-link">
                            <UiNavLink to={getRoute('sport.retail-tickets')}>
                                <Svg icon="betslip-ico" />
                                <div className="badge">{ticketsToPrint.length}</div>
                            </UiNavLink>
                        </div>
                    )}
                </div>

                <div className="header-print">
                    {!simplifyHeader && ([ClientName.STATION] as ReadonlyArray<string>).includes(getClient()) && (
                        <div className="deposit">
                            {!isRulesPage && currentBalance > 0 && loyalty.loyalty_id && <RetailDeposit />}
                        </div>
                    )}
                    {!simplifyHeader && <div className="print-tickets">{!isRulesPage && <RetailPrint />}</div>}
                    {isDisplayRulesPage && (
                        <div className="rules">
                            {isRulesPage ? (
                                <UiButton
                                    onClick={() => (previousPath ? navigateTo(previousPath) : navigateTo(homeRoute))}
                                    icon={<Svg icon="crossmark" />}
                                />
                            ) : (
                                <UiButton
                                    onClick={openRulesPage}
                                    className="secondary-button"
                                    size={isPhone ? 'small' : 'default'}
                                >
                                    {translate('Rules', 'retail.header')}
                                </UiButton>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Wrapper>
    );
}
