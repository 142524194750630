import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    position: sticky;
    left: 0;

    .sport-select {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        min-width: 5.5rem;
        max-width: 5.5rem;
        height: 5rem;

        background: var(--sport-menu-select-bg);
        border: var(--sport-menu-select-border);
        border-radius: var(--ui-radius);

        color: var(--sport-menu-select-font-color);
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            min-width: ${(props) => (props.isLayoutB ? '4rem' : 'initial')};
            max-width: ${(props) => (props.isLayoutB ? '4rem' : 'initial')};
            height: ${(props) => (props.isLayoutB ? '4.5rem' : 'auto')};
        }
        .select-sport-icon {
            margin-bottom: var(--spacing-8);
        }
        .select-sport-title {
            font-size: 0.625rem;
            text-transform: var(--sport-menu-select-font-style);
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering}
            @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
                font-size: var(--sport-menu-select-font-size);
            }
        }
    }
`;
