import React from 'react';
import { translate } from '../../../../services/translate';
import Wrapper from './styles';
import { AnnualReport } from '../../../../stores/responsible-gaming';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import { PRODUCT } from '../../../../types/common';

interface Props {
    product: typeof PRODUCT[keyof typeof PRODUCT];
    report: null | AnnualReport;
}

export default function ResponsibleGamingAnnualReportProduct({ product, report }: Props) {
    const isBetProduct = (
        [PRODUCT.SPORTSBOOK, PRODUCT.VIRTUAL_SPORTS] as Partial<typeof PRODUCT[keyof typeof PRODUCT][]>
    ).includes(product);
    const balanceChangeMessage = isBetProduct ? 'Resolved bets' : 'Balance Change';
    return (
        <Wrapper>
            <h3>{translate(product, 'ui.common')}</h3>

            <div className="statistic">
                <div className="stats-box">
                    <p>{translate(balanceChangeMessage, 'ui.common')}</p>
                    <div className="balance-amount">
                        <em>{report && formattedAmountWithCurrency(report.balance[product])}</em>
                    </div>
                </div>

                {isBetProduct && (
                    <div className="stats-box">
                        <p>{translate('Pending bets', 'ui.common')}</p>
                        <div className="balance-amount">
                            <em>{report && formattedAmountWithCurrency(report.pending_bets[product])}</em>
                        </div>
                    </div>
                )}
            </div>
        </Wrapper>
    );
}
