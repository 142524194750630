import styled, { css } from 'styled-components';

export default styled.div`
    color: var(--match-secondary-color);
    font-weight: var(--match-name-weight);
    ${({ theme }) => theme.optimizeFontRendering};
    font-size: 0.85rem;
    cursor: pointer;
    user-select: none;
    ${({ isOpen }) =>
        isOpen &&
        css`
            color: var(--color-highlight);
        `};
    .more-wagers {
        display: flex;
        flex-direction: column;
        align-items: center;

        text-align: center;
        font-size: 0.5rem;
        text-transform: uppercase;
        > *:not(:last-child) {
            margin-bottom: 0.4rem;
        }
    }
    .betbuilder-ico {
        margin-bottom: var(--spacing-12);
    }
`;
