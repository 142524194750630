import { createGlobalStore } from '../store/utils';
import { Environment } from './types';
import { ClientName } from '../../services/utils/types';

const initialStore = {
    AVAILABLE_LICENSES: [] as string[],
    SENTRY_DSN: '',
    CASINO_GAMES_PICS: '',
    CMS_IMAGES: '',
    IMAGES_HOST: '',
    DIGITAL_SPORTS: '',
    ENVIRONMENT: '' as 'dev' | 'stage' | 'qa' | 'prod',
    LOQATE_API_KEY: '',
    ROOT_URL: '',
    CLIENT_NAME: '' as typeof ClientName[keyof typeof ClientName],
    THEME: '',
    LVDC_ASSOCIATION_MAPPING: {} as Record<string, number>,
    TERMINAL_INITIALIZATION_URL: '',
};

export const environment = createGlobalStore({ ...initialStore } as Environment, 'environment');
