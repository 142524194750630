import styled, { css } from 'styled-components';

type StyledProps = {
    $showFeatures?: boolean;
    $primary?: boolean;
};

export default styled.div<StyledProps>`
    ${({ $showFeatures, $primary }) => {
        if ($showFeatures) {
            return $primary
                ? css`
                      background-color: rgba(25, 89, 0, 0.6);
                      border-radius: var(--ui-radius);
                  `
                : css`
                      background-color: rgba(59, 60, 70, 0.6);
                      border-radius: var(--ui-radius);
                  `;
        }
    }}
    .poker-download-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: var(--spacing-12);

        font-size: 0.875rem;
        font-weight: 700;
        color: rgb(205, 205, 204);
        line-height: 1;
        cursor: pointer;
        user-select: none;

        min-width: 240px;
        text-transform: uppercase;
        transition: background-color 0.2s ease 0s, color 0.2s ease 0s, border-color 0.2s ease 0s;
        background-color: rgb(96, 96, 111);
        height: 55px;
        padding: 0 1.5rem;
        border-radius: var(--ui-radius);
        &:hover {
            background-color: rgb(107, 107, 125);
            color: rgb(255, 255, 255);
        }
        @media (max-width: 1200px) {
            min-width: unset;
        }
        .apple-icon {
            display: none;
        }
    }
    .poker-apple-button {
        background-color: rgba(249, 246, 239, 1);
        color: rgb(36, 68, 10);
        .apple-icon {
            display: block;
        }
        &:hover {
            background-color: rgb(140, 233, 64);
            color: rgb(36, 68, 10);
        }
    }

    .poker-download-info {
        width: 20px;
        min-width: 20px;
        height: 20px;
        margin-left: 5px;
    }
`;
