import React, { useEffect } from 'react';
import Wrapper from './styles';
import { CategoryMatch } from '../../../../services/sports/types';
import SportMatchBetbuilderSportcast from './sportcast/SportMatchBetbuilderSportcast';
import SportMatchBetbuilderBetgenius from './betgenius/SportMatchBetbuilderBetgenius';
import { useSportsMatchContext } from '../../../../services/sports/match-helpers';
import { isRetail } from '../../../../services/environment';
import { environment } from '../../../../stores/environment/environment';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    match: CategoryMatch;
}

export default function SportMatchBetbuilder({ match }: Props) {
    const [{ BETBUILDER_WIDGET_URL, BETBUILDER_WIDGET_KEY }] = useStore(environment);
    const isSportcastConfigured = Boolean(BETBUILDER_WIDGET_URL && BETBUILDER_WIDGET_KEY);
    const { setContext } = useSportsMatchContext();
    const isRetailLayout = isRetail();
    useEffect(() => {
        setContext?.({ isBetbuilderFilter: true });
    }, []);

    return (
        <Wrapper $isRetailLayout={isRetailLayout}>
            {isSportcastConfigured ? (
                <SportMatchBetbuilderSportcast matchBetbuilderId={Number(match.betbuilder_id)} />
            ) : (
                <SportMatchBetbuilderBetgenius match={match} />
            )}
        </Wrapper>
    );
}
