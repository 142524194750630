import React, { useMemo } from 'react';
import Wrapper from './styles';
import { Duration } from 'moment';

interface Props {
    isVerbose?: boolean; //Full words
    isPrecise?: boolean; //All values
    duration: Duration;
    children?: any;
}

export default function UiDuration({ isVerbose = false, isPrecise = false, duration, children }: Props) {
    const displayValues = useMemo(() => {
        const localizedDuration = (duration as any).format('d __ h __ m __', { trim: false });
        const localizedDurationPieces = localizedDuration.split(' ');

        return {
            days: parseInt(localizedDurationPieces[0]),
            hours: parseInt(localizedDurationPieces[2]),
            minutes: parseInt(localizedDurationPieces[4]),
            daysLabel: isVerbose ? localizedDurationPieces[1] : localizedDurationPieces[1][0],
            hoursLabel: isVerbose ? localizedDurationPieces[3] : localizedDurationPieces[3][0],
            minutesLabel: isVerbose ? localizedDurationPieces[5] : localizedDurationPieces[5][0],
        };
    }, [isVerbose, isPrecise, duration]);

    if (children) {
        return <Wrapper>{children(displayValues)}</Wrapper>;
    }

    const showDays = Boolean(displayValues.days);
    const showHours = Boolean((displayValues.days && isPrecise) || displayValues.hours);
    const showMinutes = Boolean((!displayValues.days && displayValues.minutes) || isPrecise);

    return (
        <Wrapper className="duration">
            {showDays && (
                <span className="duration-element days">{`${displayValues.days} ${displayValues.daysLabel}`}</span>
            )}
            {showHours && (
                <span className="duration-element hours">{`${displayValues.hours} ${displayValues.hoursLabel}`}</span>
            )}
            {showMinutes && (
                <span className="duration-element minutes">
                    {`${displayValues.minutes} ${displayValues.minutesLabel}`}
                </span>
            )}
        </Wrapper>
    );
}
