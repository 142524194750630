import React from 'react';
import Wrapper from './styles';
import { getRoute } from '../../../../services/router';
import UiNavLink from '../../nav-link/UiNavLink';

interface Props {
    route?: string;
    url?: string;
    selected?: boolean;
    isDisabled?: boolean;
    onClick?: (event?) => void;
    className?: any;
    children?: any;
    icon?: any;
    size?: 'small' | 'default';
    dataTest?: string;
}
export default function UiTabsItem({
    route,
    url,
    children,
    selected,
    isDisabled,
    onClick,
    icon,
    size = 'default',
    dataTest = 'tabItem',
}: Props) {
    const onClickHandler = onClick && !isDisabled ? onClick : () => {};

    function getTab() {
        return (
            <Wrapper
                onClick={onClickHandler}
                selected={selected}
                size={size}
                isDisabled={isDisabled}
                data-test={dataTest}
            >
                {icon && <div className="tab-icon">{icon}</div>}
                <div className="tab-text">{children}</div>
            </Wrapper>
        );
    }

    if ((route || url) && !isDisabled) {
        return (
            <UiNavLink exact to={route ? getRoute(route) : url}>
                {getTab()}
            </UiNavLink>
        );
    }

    return getTab();
}
