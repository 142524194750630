import styled from 'styled-components';

export default styled.div`
    .provider-picture {
        padding-bottom: ${({ theme }) => theme.layout.gutter};
    }
    .bank-id-next-button {
        margin-top: 1rem;
    }
`;
