import React from 'react';
import Wrapper from './styles';
import { QUIZ_TITLE_TYPE, SPORT_TYPE } from '../../../../../services/quiz';
import { stores } from '../../../../../stores';
import { Quiz } from '../../../../../microservices/quiz';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    quiz: Quiz;
}

export default function CmsQuizCollectionQuizHeader({ quiz }: Props) {
    const [language] = useStore(stores.language);

    return (
        <Wrapper sportType={quiz.sport_type}>
            {quiz.title_type === QUIZ_TITLE_TYPE.VS && (
                <>
                    <div className="quiz-team-name">{quiz.variables?.home[language]}</div>
                    <div className="quiz-sport">
                        {quiz.sport_type === SPORT_TYPE.FOOTBALL && <div className="football" />}
                        {quiz.sport_type === SPORT_TYPE.HOCKEY && <div className="hockey" />}
                        {quiz.sport_type === SPORT_TYPE.BASKETBALL && <div className="basketball" />}
                    </div>
                    <div className="quiz-team-name">{quiz.variables?.away[language]}</div>
                </>
            )}
            {quiz.title_type === QUIZ_TITLE_TYPE.CUSTOM && (
                <div className="quiz-custom-title">{quiz.title[language] || quiz.title[quiz.default_language]}</div>
            )}
            {quiz.image && <img src={quiz.image} alt="" className="quiz-header-image" />}
        </Wrapper>
    );
}
