import React from 'react';
import { FEATURE, isFeatureAvailable } from '../../services/feature';
import LivechatButton from '../livechat/button/LivechatButton';
import ZendeskButton from '../zendesk/button/ZendeskButton';

interface Props {
    isPhone?: boolean;
}

export default function ContactUsButton({ isPhone }: Props) {
    if (isFeatureAvailable(FEATURE.ZE_CHAT_BOT)) {
        return <ZendeskButton $isPhone={isPhone} />;
    }

    if (isFeatureAvailable(FEATURE.LIVE_CHAT_KUSTOMER)) {
        return <LivechatButton />;
    }

    return null;
}
