import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import {
    disableMinBetReminder,
    getMyCasinoRaces,
    getPublicCasinoRaces,
    resetPoints,
} from '../../microservices/casino-race';
import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { getCmsImageUrl } from '../cms-image';
import { getActiveCurrency, getAmountByStaticRate } from '../currency';
import { Language } from '../language';
import { logger } from '../logger';
import { Toast, showToast } from '../toast';
import { translate } from '../translate';
import { formattedAmountWithCurrency } from '../currency';
import { Currency } from '../wallet/types';
import { getGameImageUrlForImage } from './games';

export enum RACE_TYPE {
    STANDARD = 'STANDARD',
    HIGHEST_WIN_MULTIPLIER = 'HIGHEST_WIN_MULTIPLIER',
}

export interface CasinoRace {
    id: number;
    startDate: string;
    endDate: string;
    name: string;
    cmsTemplate: string;
    maxSpins: number;
    minBetAmount: number;
    prizePoolDisplay: string;
    isMainRace: boolean;
    scoreSettings: CasinoRaceScoreSettings;
    status: string;
    awards: CasinoRaceAward[];
    games: string[];
    score?: CasinoRaceScore;
    leaderboard?: CasinoRaceScore[];
    backgroundImageName?: string;
    imageName?: string;
    skip?: string;
    timestamp?: string;
    joined?: boolean;
    totalFreeMoneyAmount: number;
    totalFreeSpinsAmount: number;
    type: RACE_TYPE;
    raceSettings?: { minBet?: { [keys: string]: number } };
}

export interface CasinoRaceHistory extends CasinoRace {
    leaderboard: CasinoRaceScore[];
}

export interface CasinoRaceScore {
    country?: string;
    device?: string;
    gameId?: string;
    isBigWin?: boolean;
    lastBetType?: string;
    points: number;
    position?: number;
    spins: number;
    spinsCounterAmount?: number;
    updated?: Date;
    userAlias: string;
    winMultiplier?: number;
    wins: number;
    maxSpins?: number; // TODO: remove this field and move it to Race entity
    active: boolean;
    resets: number;
    highestWinMultipliers: number[];
    multiplierPoints?: number;
    multiplierPointsTotal?: number;
}

export interface CasinoRaceFlyingPointsAnimations {
    color: string;
    animation: string;
    duration: string;
    condition: boolean;
    points: number;
}

export interface CasinoRaceAward {
    displayName: any;
    prizeMessage: any;
    worth?: number;
    bonusCode?: string;
    type: string;
    amount: number;
    position: number;
    raceId?: number;
}

export interface CasinoRaceScoreSettings {
    allSpinsCompleted: number;
    bigWin: number;
    bigWinMultiplier: number;
    maxAllowedForReset: number;
    minAllowedForReset: number;
    resets: number;
    singleSpin: number;
    singleWin: number;
    spinsCounter: number;
    spinsCounterAmount: number;
    tripleWin: number;
}

export const AWARD_TYPE = {
    CUSTOM: 'custom',
    FREE_SPINS: 'free_spins',
    FREE_MONEY: 'free_money',
};

const RACE_MIN_BET_MULTIPLIERS = {
    [Currency.CAD]: 1,
    [Currency.CLP]: 500,
    [Currency.EUR]: 1,
    [Currency.NOK]: 10,
    [Currency.PEN]: 4,
    [Currency.SEK]: 10,
    [Currency.USD]: 1,
    [Currency.MXN]: 20,
};

export async function loadActiveCasinoRace() {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    try {
        const promise = isAuthenticated ? getMyCasinoRaces() : getPublicCasinoRaces();
        return await promise;
    } catch (error) {
        logger.error('CasinoCasinoRaceService', 'loadActiveCasinoRace', error);
    }
}

export async function initializeCasinoRace() {
    stores.casinoRace.active.race.set({} as CasinoRace);
    stores.casinoRace.active.score.set({} as CasinoRaceScore);
    stores.casinoRace.active.leaderboard.set([]);
    const activeRace = await loadActiveCasinoRace();
    if (!activeRace) {
        return;
    }
    const { leaderboard, score } = activeRace;
    raceStartInitialization({ activeRace, score, leaderboard });
}

export function updateActiveRace(updated = {}) {
    const activeRace = getStoreValue(stores.casinoRace.active.race);
    const copyActiveRace = cloneDeep(activeRace);
    Object.assign(copyActiveRace, updated);
    stores.casinoRace.active.race.set(copyActiveRace);
}

export function getRacePrize(userPosition: number, awards: CasinoRaceAward[], language: Language): string {
    if (userPosition - 1 >= awards.length) {
        return '0';
    }
    const prize = awards[userPosition - 1];
    switch (prize.type) {
        case AWARD_TYPE.FREE_SPINS:
            return `${formattedAmountWithCurrency(prize.amount)} ${translate('FS', 'casino.race')}`;
        case AWARD_TYPE.FREE_MONEY:
            return formattedAmountWithCurrency(prize.amount);
        case AWARD_TYPE.CUSTOM:
            return prize.displayName[language] || prize.displayName.en;
        default:
            return '';
    }
}

export function getGamesFromRace(race: CasinoRace) {
    const casinoGames = getStoreValue(stores.casino.filteredGames);
    return casinoGames.filter((game) => game.serverGameId && race.games?.includes(game.serverGameId));
}

export function hasAvailableGamesInRace(race) {
    if (!race.games || race.games.length === 0) {
        return true;
    }
    const gamesByServerId = getStoreValue(stores.casino.gamesByServerId);
    return race.games.some((game) => gamesByServerId[game]);
}

export function getDurationDisplay(race) {
    const startDate = moment(race.startDate);
    const endDate = moment(race.endDate);
    return startDate.from(endDate, true);
}

export function raceStartEvent(raceStart) {
    logger.dev('CasinoCasinoRaceService', 'raceStartEvent', `Race start: ${JSON.stringify(raceStart)}`);
    stores.casinoRace.clientServerTimeDifference.set(moment().valueOf() - moment(raceStart.startDate).valueOf());
    raceStartInitialization(raceStart);
}

function raceStartInitialization(raceStart) {
    stores.casinoRace.active.race.set(raceStart.activeRace);
    stores.casinoRace.active.score.set(
        raceStart.score
            ? { ...raceStart.score, userAlias: getStoreValue(stores.user)?.alias || '' }
            : getDefaultScore(),
    );
    stores.casinoRace.raceEnd.set({} as CasinoRace);
    stores.casinoRace.isRaceSidebarVisible.set(true);

    setLeaderboardData(raceStart.leaderboard);
}

export function setLeaderboardData(leaderboard): CasinoRaceScore[] {
    const personalizedLeaderboard = getPersonalizedLeaderboard(leaderboard);
    const user = getStoreValue(stores.user);
    const userScore = personalizedLeaderboard.find((player) => player.userAlias === user?.alias);
    const activeRace = getStoreValue(stores.casinoRace.active.race);
    stores.casinoRace.active.position.set(userScore && userScore.spins ? userScore.position : undefined);
    const updatedLeaderboard = activeRace?.joined ? personalizedLeaderboard : leaderboard;
    stores.casinoRace.active.leaderboard.set(updatedLeaderboard);
    return updatedLeaderboard;
}

function getPersonalizedLeaderboard(leaderboard) {
    let personalizedLeaderboard = cloneDeep(leaderboard);
    let activePlayer = personalizedLeaderboard.find((player) => player.isActive);
    const user = getStoreValue(stores.user);
    if (!activePlayer) {
        activePlayer = personalizedLeaderboard.find((player) => player.userAlias === user?.alias);
    }
    if (!activePlayer) {
        personalizedLeaderboard = leaderboard.concat([getDefaultScore()]);
    } else {
        activePlayer.isActive = true;
    }
    return personalizedLeaderboard;
}

export function getDefaultScore(): CasinoRaceScore {
    const user = getStoreValue(stores.user);
    return {
        userAlias: user?.alias || '',
        spins: 0,
        wins: 0,
        points: 0,
        active: true,
        spinsCounterAmount: 0,
        winMultiplier: 0,
        resets: 0,
        highestWinMultipliers: [],
        multiplierPoints: 0,
        multiplierPointsTotal: 0,
    };
}

export function raceEndEvent(raceEnd) {
    logger.dev('CasinoCasinoRaceService', 'raceEndEvent', `Race end: ${JSON.stringify(raceEnd)}`);
    const { race } = raceEnd;
    race.leaderboard = getPersonalizedLeaderboard(raceEnd.leaderboard);
    race.score = raceEnd.score;
    stores.casinoRace.active.score.set({} as CasinoRaceScore);
    stores.casinoRace.active.leaderboard.set([]);
    stores.casinoRace.active.race.set({} as CasinoRace);
    stores.casinoRace.raceEnd.set(race);
    initializeCasinoRace();
}

export function racePointsEvent(score) {
    stores.casinoRace.active.score.set({
        ...score,
        userAlias: getStoreValue(stores.user)?.alias || '',
    } as CasinoRaceScore);
}

export function raceMessageEvent(event) {
    if (event.key === 'casino-race-min-bet-message') {
        showBelowMinBetToast(event);
    }
}

function showBelowMinBetToast(message) {
    const { currency } = getStoreValue(stores.wallet) as any;
    const raceSettingsMinBet = currency && message?.raceSettings?.minBet?.[currency];
    const toast: Toast = {
        title: translate('Low bet', 'ui.casino.race'),
        size: 'size-medium',
        text: `${translate('Minimum stake for this casino race is {{ amount }}', 'casino.race', {
            amount: raceSettingsMinBet ?? RACE_MIN_BET_MULTIPLIERS[currency] * message.amountEur,
        })} ${currency}`,
        iconName: 'casino-race',
        type: 'warning',
        isImportant: true,
        closeOnLogout: true,
        lifespan: 3000,
        actions: [
            {
                default: true,
                name: translate('remind-next-time', 'casino.race'),
            },
            {
                name: translate('Got it', 'ui.common.toast'),
                action: disableMinBetReminder,
            },
        ],
    };

    showToast(toast);
}

export function getRaceMinBetDisplay(race: CasinoRace) {
    const currency = getActiveCurrency();
    const raceSettingsMinBet = race.raceSettings?.minBet?.[currency];
    return raceSettingsMinBet
        ? formattedAmountWithCurrency(raceSettingsMinBet)
        : getRaceCashDisplay(race.minBetAmount, RACE_MIN_BET_MULTIPLIERS);
}

export function getRaceCashDisplay(amount, rateMultipliers?) {
    const currency = getActiveCurrency();
    let amountForUc;
    if (typeof amount === 'object') {
        amountForUc = amount[currency];
    } else {
        amountForUc = getAmountByStaticRate(Number(amount), currency, rateMultipliers);
    }
    return formattedAmountWithCurrency(amountForUc);
}

export function isSpinAmountEnoughForReset(race) {
    const storeScore = getStoreValue(stores.casinoRace.active.score);
    const score = storeScore && Object.keys(storeScore).length ? storeScore : getDefaultScore();

    const minSpinsNeeded = Math.round(race.scoreSettings.minAllowedForReset * 0.01 * race.maxSpins);
    const maxSpinsNeeded = Math.round(race.scoreSettings.maxAllowedForReset * 0.01 * race.maxSpins);
    return maxSpinsNeeded >= score.spins && score.spins >= minSpinsNeeded;
}

export function getRaceBackgroundUrl(race, params) {
    if (race.cmsTemplate) {
        return getCmsImageUrl(race.cmsTemplate, params);
    }
    const gamesByServerId = getStoreValue(stores.casino.gamesByServerId);
    const game = gamesByServerId[race.games?.[0]];
    return getGameImageUrlForImage(race.backgroundImageName || game?.backgroundImageName, params);
}

export function setMobileRaceGameMenuVisible(isVisible) {
    stores.casinoRace.isMobileRaceGameMenuVisible.set(isVisible);
}

export async function submitReset() {
    const race = getStoreValue(stores.casinoRace.active.race);
    const score = getStoreValue(stores.casinoRace.active.score);
    const userAlias = getStoreValue(stores.user)?.alias || '';

    const response = await resetPoints();
    if (response.status === 'error') {
        const toast: Toast = {
            title: translate('failure', 'casino.race.reset-points'),
            text: response.message,
            size: 'size-medium',
            iconName: 'casino-race',
            type: 'warning',
            closeOnLogout: true,
            lifespan: 3000,
            actions: [{ name: translate('Got it', 'ui.common.toast'), default: true }],
        };
        showToast(toast);
        return false;
    }

    stores.casinoRace.active.score.set({ ...response, userAlias });

    const toast: Toast = {
        title: translate('success', 'casino.race.reset-points'),
        text: translate('message', 'casino.race.reset-points.success').replace(
            '|amount|',
            (race.scoreSettings.resets - ((score && score.resets) || 0) - 1).toString(),
        ), //  TODO: make it properly? Hack because of data race probably
        size: 'size-medium',
        iconName: 'casino-race',
        type: 'success',
        closeOnLogout: true,
        lifespan: 5000,
        actions: [{ name: translate('Got it', 'ui.common.toast'), default: true }],
    };
    showToast(toast);

    return true;
}
