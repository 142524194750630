import { useEffect, useState } from 'react';
import { getActiveCurrency } from '../currency';
import { showToast } from '../toast';
import { translate } from '../translate';
import { logger } from '../logger';
import { useSocketSubscribeUnsubscribe, useSocketTopicEvents } from '../../microservices/pusher';
import {
    getCasinoGameOfTheDayPaybackBooster,
    getPaybackBoosterProgramGames,
} from '../../microservices/casino-payback-booster';
import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { storageGet, storageSet } from '../storage';
import isEmpty from 'lodash/isEmpty';
import { formattedAmountWithCurrency } from '../currency';
import { Currency } from '../wallet/types';
import { useStore } from '../../hooks/useStore';

export interface PaybackBoosterProgramGames {
    periodFrom: string;
    periodTo: string;
    cashbackSettings: {
        promotedGames: string[];
        extraPayback?: {
            [game: string]: number;
        };
    };
    turnoverSettings?: {
        games?: string[];
    };
}

export type PaybackBooster = {
    id: string;
    name: string;
    createdAt: string;
    expiryDate: string;
    amountTotal: number;
    programId: number;
    paybackBoosterSettings: {
        minutesWithExtraRtp: number;
        endProgramImmediately?: boolean;
        extraPayback: {
            [game: string]: number;
        };
        promotedGames: string[];
        maxReturnAmount: Record<Currency, number>;
        minBet: { [key: string]: number };
        maxBet: { [key: string]: number };
    };
    status: PaybackBoosterStatus;
    casinoLocked: boolean;
};

export enum PaybackBoosterWrapperState {
    empty = 'casino-payback-booster-wrapper-empty',
    fadeIn = 'casino-payback-booster-wrapper-fade-in',
    wrapper = 'casino-payback-booster-wrapper',
}

export enum PaybackBoosterStatus {
    active = 'active',
    available = 'available',
    used = 'used',
}

export enum TransactionNotificationsTypes {
    BET_OUT_OF_RANGE = 'bet_out_of_range',
    MAX_RETURN_AMOUNT_REACHED = 'max_return_amount_reached',
    NOT_RIGHT_GAME = 'not_right_game',
    HAS_ACTIVE_CASINO_BONUS = 'has_active_casino_bonus',
    BALANCE_TRANSFERRED = 'balance_transferred',
    PAYBACK_PROGRAM_HAS_ENDED = 'payback_program_has_ended',
}

const sentNotifications: string[] = [];

export function usePaybackBoosterTransactionNotifications() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    useSocketSubscribeUnsubscribe('casino-payback-booster-transaction-notification', {
        guardFunction: () => isAuthenticated,
        params: [],
        watchParams: [isAuthenticated],
    });
    useSocketTopicEvents('casino-payback-booster-transaction-notification', onMessage);
}

export async function loadPaybackBoosterProgramGames(): Promise<void> {
    try {
        const isAuthenticated = getStoreValue(stores.isAuthenticated);
        let paybackBooster = getStoreValue(stores.paybackBooster.paybackBooster);

        if (isAuthenticated) {
            if (isEmpty(paybackBooster)) {
                paybackBooster = await getCasinoGameOfTheDayPaybackBooster();
            }

            const isPaybackBooster = paybackBooster.amountTotal !== undefined;
            const isExpired = Boolean(new Date(paybackBooster.expiryDate) < new Date());
            if (isPaybackBooster) {
                stores.paybackBooster.paybackBooster.set(isExpired ? undefined : paybackBooster);
            }
            if (!isEmpty(paybackBooster) && paybackBooster.programId) {
                const program = await getPaybackBoosterProgramGames([paybackBooster.programId]);

                if (program) {
                    stores.paybackBooster.programGames.set(program);
                }
            }
        }
    } catch (error) {
        logger.error('CasinoPaybackBoosterService', 'loadPaybackBoosterProgramGames', error);
    }
}

export function usePaybackBoosterProgram(serverGameId?: string) {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [paybackBooster, setPaybackBooster] = useStore(stores.paybackBooster.paybackBooster);
    const [isSecondaryDataInitialized] = useStore(stores.casino.isSecondaryDataInitialized);

    useSocketSubscribeUnsubscribe('casino-payback-booster-payback-booster-update', {
        guardFunction: () => isAuthenticated,
        params: [],
        watchParams: [isAuthenticated],
    });
    useSocketSubscribeUnsubscribe('casino-payback-booster-turnover-update', {
        guardFunction: () => isAuthenticated,
        params: [],
        watchParams: [isAuthenticated],
    });

    useEffect(() => {
        if (isAuthenticated && (isEmpty(paybackBooster) || serverGameId) && isSecondaryDataInitialized) {
            loadPaybackBoosterProgramGames();
        }
        setIsLoading(false);
    }, [isAuthenticated, serverGameId]);

    useSocketTopicEvents(
        'casino-payback-booster-payback-booster-update',
        (message: { userId: string; paybackBooster: PaybackBooster }) => {
            const { paybackBooster } = message;
            if (paybackBooster.status === PaybackBoosterStatus.active) {
                return setPaybackBooster(paybackBooster);
            }
        },
    );

    return {
        isLoading,
        paybackBooster,
        setPaybackBooster,
        showMessage,
    };
}

function onMessage({ type, data }: { type: TransactionNotificationsTypes; data?: any }) {
    const currency = getActiveCurrency();
    if (type === TransactionNotificationsTypes.BET_OUT_OF_RANGE && data) {
        const { minBet, maxBet } = data.turnoverSettings || data.cashbackSettings;
        showMessage(
            {
                text: `The bet range for this Payback Booster program is between %1 and %2`,
                params: [formattedAmountWithCurrency(minBet[currency]), formattedAmountWithCurrency(maxBet[currency])],
            },
            type,
        );
    } else if (type === TransactionNotificationsTypes.MAX_RETURN_AMOUNT_REACHED) {
        showMessage(
            {
                text: `You have reached the maximum payback amount set for this Payback Booster program`,
            },
            type,
        );
    } else if (type === TransactionNotificationsTypes.NOT_RIGHT_GAME) {
        showMessage({ text: `This game is not eligible for the current Payback Booster program` }, type);
    } else if (type === TransactionNotificationsTypes.HAS_ACTIVE_CASINO_BONUS) {
        showMessage(
            {
                text: `Your bets made with an active Casino Bonus will not count towards the Payback Booster program`,
            },
            type,
        );
    } else if (type === TransactionNotificationsTypes.BALANCE_TRANSFERRED && data) {
        const { amount } = data;
        showMessage(
            {
                title: 'Congratulations!',
                text: `You have transferred %1 from your Booster Balance to your main Balance`,
                type: 'success',
                params: [formattedAmountWithCurrency(amount)],
            },
            type,
        );
    }
}

function showMessage(
    {
        text,
        title = `Your bet is not applicable`,
        type,
        params,
    }: {
        text: string;
        title?: string;
        type?: string;
        params?: string[];
    },
    transactionNotificationType: TransactionNotificationsTypes,
) {
    if (!sentNotifications.includes(transactionNotificationType)) {
        showToast({
            title: translate(title, 'ui.casino'),
            text: translate(text, 'casino.payback-booster', params),
            size: 'size-small',
            iconName: 'payback-booster',
            type: type || 'warning',
            closeOnLogout: true,
            lifespan: 6000,
            actions: [{ name: translate('Got it', 'ui.casino'), default: true }],
        });
        sentNotifications.push(transactionNotificationType);
    }
}

const COMPACT_VIEW_STATE_KEY = 'casino.booster.isCompact';

export function getCasinoPaybackBoosterCompactViewState(): boolean {
    return storageGet(COMPACT_VIEW_STATE_KEY) === 'true';
}

export function setCasinoPaybackBoosterCompactViewState(isCompact: boolean): void {
    storageSet(COMPACT_VIEW_STATE_KEY, isCompact ? 'true' : 'false');
}
