import React from 'react';
import { SportMatchLayoutHeadParams as Props } from '../../../../../services/sports/types';
import { useSportsUserSettings } from '../../../../../services/sports/user-settings';
import { stores } from '../../../../../stores';
import SportMatchLayoutAmericanPhone from '../american/phone/SportMatchLayoutAmericanPhone';
import SportMatchLayoutAmerican from '../american/SportMatchLayoutAmerican';
import SportMatchLayoutCompact from '../compact/SportMatchLayoutCompact';
import SportMatchLayoutDesktop from '../desktop/SportMatchLayoutDesktop';
import SportMatchLayoutPhone from '../phone/SportMatchLayoutPhone';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';

export default function SportMatchLayoutHeader({ match, matchHeader }: Props) {
    const [{ deviceType }] = useStore(media);
    const [isMobileCompactView] = useStore(stores.sports.isMobileCompactView);
    const { isAmericanLayout } = useSportsUserSettings({ sportCategoryId: match.sport_category });

    const componentPerDeviceType = {
        isPhone: isAmericanLayout ? SportMatchLayoutAmericanPhone : SportMatchLayoutPhone,
        isTablet: isAmericanLayout ? SportMatchLayoutAmerican : SportMatchLayoutDesktop,
        isLaptop: isAmericanLayout ? SportMatchLayoutAmerican : SportMatchLayoutDesktop,
        isDesktop: isAmericanLayout ? SportMatchLayoutAmerican : SportMatchLayoutDesktop,
    };

    const ComponentForDevice = isMobileCompactView ? SportMatchLayoutCompact : componentPerDeviceType[deviceType];

    return (
        <>
            <div className="match-layout-container" ref={matchHeader}>
                <ComponentForDevice match={match} />
            </div>
        </>
    );
}
