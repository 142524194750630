import React, { useEffect, useState } from 'react';
import { CategoryMatch, MarketTypeCategory } from '../../../../../../services/sports/types';
import { useSportsUserSettings } from '../../../../../../services/sports/user-settings';
import { stores } from '../../../../../../stores';
import Wrapper from './styles';
import {
    useTopMarketBestLine,
    useTopMarketSocketUpdatesInView,
} from '../../../../../../services/sports/top-markets-hooks';
import SportMatchMarketsTopMarketsMarketNoOdds from './no-odds/SportMatchMarketsTopMarketsMarketNoOdds';
import SportMatchMarketsTopMarketsMarketOdds from './odds/SportMatchMarketsTopMarketsMarketOdds';
import SportMatchMarketsTopMarketsMarketLineBtn from './line-btn/SportMatchMarketsTopMarketsMarketLineBtn';
import { isRetail } from '../../../../../../services/environment';
import { filterStyleProps } from '../../../../../../styles/utils';
import { getFakeMarket } from '../../../../../../services/sports/skeleton-mocks';
import { useStore } from '../../../../../../hooks/useStore';

interface Props {
    className?: string;
    marketType: MarketTypeCategory;
    match: CategoryMatch;
    onMarketIdChanged?: (marketId: number | undefined) => void;
    isEuropeanLayoutForced?: boolean;
}

function SportMatchMarketsTopMarketsMarket({
    marketType,
    match,
    onMarketIdChanged,
    isEuropeanLayoutForced,
    ...rest
}: Props) {
    const [isMobileCompactView] = useStore(stores.sports.isMobileCompactView);
    const { isAmericanLayout, layout } = useSportsUserSettings({ sportCategoryId: match.sport_category });
    const isRetailLayout = isRetail();
    const [bestMarketLineId, setBestMarketLineId] = useState<number>(-1);

    const { markets, setMarkets, refWrapper } = useTopMarketSocketUpdatesInView(
        match.id,
        marketType.id,
        !!match.isFake,
    );

    useEffect(() => {
        const marketTypeMarkets = match.markets?.filter?.((market) => market.market_type_id === marketType.id);
        setMarkets(marketTypeMarkets);
    }, [match, marketType.id, layout]);

    const { market, setMarket } = useTopMarketBestLine(
        markets,
        marketType.view_type,
        !!match.isFake,
        match.prefer_half_lines,
        getFakeMarket(isAmericanLayout),
    );

    useEffect(() => {
        if (market && onMarketIdChanged) {
            onMarketIdChanged(market.id);
        }
        if (market && bestMarketLineId !== market?.id) {
            setBestMarketLineId(market?.id);
        }
    }, [market]);

    const showLineSelector = market?.view_type === 'line';

    return (
        <Wrapper
            {...filterStyleProps({ ...rest })}
            ref={refWrapper}
            $isAmericanLayout={!isEuropeanLayoutForced && isAmericanLayout}
            $isMobileCompactView={isMobileCompactView}
            $isRetailLayout={isRetailLayout}
        >
            {market ? (
                <>
                    <SportMatchMarketsTopMarketsMarketOdds
                        market={market}
                        match={match}
                        marketType={marketType}
                        isEuropeanLayoutForced={isEuropeanLayoutForced}
                    />
                    {showLineSelector && (
                        <SportMatchMarketsTopMarketsMarketLineBtn
                            markets={markets}
                            market={market}
                            isAmericanLayout={!isEuropeanLayoutForced && isAmericanLayout}
                            setMarket={setMarket}
                            disabled={(markets || []).length < 2}
                            bestMarketLineId={bestMarketLineId}
                        />
                    )}
                </>
            ) : (
                <SportMatchMarketsTopMarketsMarketNoOdds match={match} />
            )}
        </Wrapper>
    );
}

export default React.memo(SportMatchMarketsTopMarketsMarket);
