import React from 'react';
import { isB2B } from '../../../services/environment';
import UiFlag from '../../ui/flag/UiFlag';
import Svg from '../../svg/Svg';
import Icon from '../../icon/Icon';
import UiPlaceholderIcon from '../../ui/placeholder/icon/UiPlaceholderIcon';

interface Props {
    icon: string | null | undefined;
    isRegion?: boolean;
    fallbackIcon?: string | null;
    size?: number;
    small?: boolean;
    tiny?: boolean;
    large?: boolean;
    className?: string;
    shouldForceRenderFlag?: boolean;
}

export default function SportCategoryIcon({
    icon,
    isRegion,
    fallbackIcon,
    size,
    small,
    tiny,
    large,
    className,
    shouldForceRenderFlag = false,
}: Props) {
    const shouldUseSvg = isB2B();
    const iconName = icon || fallbackIcon;

    if (!iconName) {
        if (icon === undefined) {
            return <UiPlaceholderIcon />;
        }
        return null;
    }

    if (isRegion && icon) {
        return <UiFlag flag={iconName} shouldForceRenderFlag={shouldForceRenderFlag} />;
    }

    return shouldUseSvg ? (
        <Svg icon={`${iconName}-ico`} size={size ? size : 1.125} />
    ) : (
        <Icon
            icon={`${iconName}-ico`}
            small={Boolean(small)}
            tiny={Boolean(tiny)}
            large={Boolean(large)}
            className={className}
        />
    );
}
