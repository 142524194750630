import styled from 'styled-components';

export default styled.div`
    padding: 12px;
    .poker-feature-container {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: 2px 0;
    }

    .poker-feature-icon {
        width: 16px;
        height: 16px;
        margin: 0 8px 0 3px;
        color: ${({ theme }) => theme.colors.secondary};

        &.cross {
            color: #fb3b5e;
            padding: 1px;
        }
    }

    .poker-feature-text {
        font-size: 12px;
        line-height: 14px;
        padding-top: 1px;
        font-weight: 700;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }
`;
