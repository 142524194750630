import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    &.promotion-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 0.75rem;
        letter-spacing: 0.2px;
        border-radius: var(--ui-radius);
        width: 100%;
        color: var(--font-color-secondary);
        margin-top: var(--spacing-4);

        @media ${mediaQuery.isPhone} {
            flex-wrap: wrap;
            width: auto;
            line-height: 1.5;
        }

        .promotion-highlighted-text {
            color: var(--color-highlight);
        }

        .promotion-marged-action {
            @media ${mediaQuery.isPhone} {
                margin-left: 4px;
            }
        }

        .promotion-marged-action.with-left-margin {
            margin-left: 4px;
        }

        .promotion-sm-margin-sides {
            margin-left: 0.4rem;
        }

        .promotion-cta {
            border-bottom: 1px dotted;
            font-weight: var(--font-weight-bold);
        }

        .promotion-text-wrapper {
            @media ${mediaQuery.isPhone} {
                margin: 0.4rem 0;
            }
        }
        button {
            min-width: auto;
            height: auto;
            font-size: 0.8rem;
            padding: var(--spacing-4) var(--spacing-8);
        }
    }
`;
