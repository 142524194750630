import React from 'react';
import Wrapper from './styles';
import { TICKET_TYPE } from '../../../../services/bet-history';
import { translate } from '../../../../services/translate';
import SportBadge from '../../badge/SportBadge';
import { Ticket } from '../../../../services/sports/types';
import { filterStyleProps } from '../../../../styles/utils';

interface Props {
    ticket: Ticket;
    isLeaderboard?: boolean;
    isCompactView?: boolean;
    className?: string;
}

export default function SportTicketType({ ticket, isLeaderboard, isCompactView, ...rest }: Props) {
    return (
        <Wrapper {...filterStyleProps({ ...rest })}>
            <>
                <SportBadge type={ticket.ticket_type} extraInfo={ticket.parlay_card_name} />

                {ticket.product === TICKET_TYPE.LIVE && <SportBadge type={TICKET_TYPE.LIVE} />}

                {ticket.ticket_type === TICKET_TYPE.COMBO && !isLeaderboard && (
                    <span className="combo">[ {ticket?.total_matches} ]</span>
                )}

                {ticket?.extra?.freeBet && (
                    <div className="campaign-badge">{translate('free bet', 'ui.sportsbook')}</div>
                )}

                {ticket?.extra?.multiplierBet && (
                    <div className="campaign-badge">{translate('Multiplier bet', 'ui.sportsbook')}</div>
                )}

                {ticket?.extra?.riskFreeBet && (
                    <div className="campaign-badge">{translate('risk free bet', 'ui.sportsbook')}</div>
                )}

                {ticket?.extra?.campaignId && (
                    <div className="campaign-badge">{translate(`${ticket.extra.campaignId}`, 'ui.campaign.name')}</div>
                )}
            </>
        </Wrapper>
    );
}
