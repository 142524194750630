import React from 'react';
import Wrapper from './styles';
import { stores, useStoreWithSelector } from '../../../../../stores';
import has from 'lodash/has';
import SportMatchTimeLive from '../../time/live/SportMatchTimeLive';
import { FoCategoryMatch } from '@staycool/sbgate-types';

interface Props {
    match: FoCategoryMatch;
}

export default function SportMatchScoreboardBgTennis({ match }: Props) {
    const tennisSets = [1, 2, 3];
    const [inplayData] = useStoreWithSelector(stores.sports.inplayMatchData, (state) => state[match.id], [match.id]);
    const { home_team_games, away_team_games } = inplayData || {};
    const { scoreboard, current_server } = inplayData || {};
    const homeTeam = match.home_team_name;
    const awayTeam = match.away_team_name;
    const backgroundPath = `/assets/images/scoreboard-backgrounds/scoreboard-${match.sport_slug}.jpg`;
    const tennisIconPath = '/assets/images/sport-icons/tennis-ico.png';

    if (!inplayData) {
        return null;
    }

    const scoreBoardSets = Object.keys(scoreboard);
    if (scoreBoardSets.length > 3) {
        tennisSets.push(...[4, 5]);
    }
    const latestSet = tennisSets[scoreBoardSets.length - 1];

    return (
        <Wrapper backgroundUrl={backgroundPath}>
            <div className="score-board">
                <div className="scores">
                    <div className="score">
                        <h1 className="score-title">{homeTeam}</h1>
                        <span className="score-number"> {home_team_games || 0}</span>
                        {current_server === 'home' && (
                            <img className="current-server" src={tennisIconPath} alt="tennis_icon" />
                        )}
                    </div>
                    <div className="timer">
                        <SportMatchTimeLive matchId={match.id} sportId={match.sport} isOurLivebet={match.livebet_our} />
                    </div>
                    <div className="score">
                        <h1 className="score-title">{awayTeam}</h1>
                        <span className="score-number"> {away_team_games || 0}</span>
                        {current_server === 'away' && (
                            <img className="current-server" src={tennisIconPath} alt="tennis_icon" />
                        )}
                    </div>
                </div>
                {Boolean(Object.values(scoreboard).length) && (
                    <div className="set-scores-container">
                        <div className="set-scores">
                            <div className="team-name">{match.home_team_name}</div>
                            {tennisSets.map((set) => (
                                <span className={`set-score ${set === latestSet ? 'active' : ''}`} key={set}>
                                    <div className="set-label">{`${set}`}</div>
                                    {has(scoreboard, `${set}.home`) ? scoreboard[set].home : 0}
                                </span>
                            ))}
                        </div>
                        <div className="set-scores">
                            <div className="team-name">{match.away_team_name}</div>
                            {tennisSets.map((set) => (
                                <span className={`set-score ${set === latestSet ? 'active' : ''}`} key={set}>
                                    {has(scoreboard, `${set}.away`) ? scoreboard[set].away : 0}
                                </span>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </Wrapper>
    );
}
