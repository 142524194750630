import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

type StyledProps = {
    $size?: 'small' | 'large' | 'default';
};

const sizes = {
    default: {
        css: css`
            padding: var(--button-size-default);
            @media ${mediaQuery.isPhone} {
                font-size: 0.85rem;
                min-height: 50px;
            }
        `,
    },
    small: {
        css: css`
            padding: var(--button-size-small);
            font-size: var(--button-font-size-small);
            letter-spacing: 0.03em;
            @media ${mediaQuery.isPhone} {
                font-size: 0.675rem;
            }
        `,
    },
    large: {
        css: css`
            padding: var(--button-size-large);
            font-size: var(--button-font-size-large);
            @media ${mediaQuery.isPhone} {
                font-size: 0.95rem;
            }
        `,
    },
};

export default styled.div<StyledProps>`
    background-color: var(--button-bg);
    color: var(--button-text-color);
    border: var(--button-border);
    border-radius: 100px;
    display: flex;
    width: 100%;
    > * {
        flex: 1;
    }

    .toggle-option {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 2rem;
        border-radius: 100px;

        font-size: 0.75rem;
        white-space: nowrap;
        font-weight: var(--font-weight-medium);
        text-transform: capitalize;
        cursor: pointer;
        outline: none;
        user-select: none;
        border: 2px solid transparent;

        ${({ $size }) => $size && sizes[$size].css};

        &.selected {
            border: var(--button-active-border);
            color: var(--button-active-text-color);
            background-color: var(--button-active-bg);
        }
    }
`;
