import React, { useEffect, useState, useMemo, useRef } from 'react';
import Wrapper from './styles';
import uniq from 'lodash/uniq';
import { CasinoGame, isCasinoGameDisabled, navigateToCasinoGamePage } from '../../../../../services/casino/games';
import UiButton from '../../../../ui/button/UiButton';
import CasinoPaybackBoosterGamesModalView from './view/CasinoPaybackBoosterGamesModalView';
import UiSearch from '../../../../ui/search/UiSearch';
import { stores } from '../../../../../stores';
import orderBy from 'lodash/orderBy';
import { getCasinoCategoryName } from '../../../../../services/casino/categories';
import Svg from '../../../../svg/Svg';
import { translate } from '../../../../../services/translate';
import UiSliderSlick from '../../../../ui/slider/slick/UiSliderSlick';
import { usePreventClicksOnSwipe } from '../../../../../hooks/usePreventClicksOnSwipe';
import { Dialog, Drawer } from '@mui/material';
import { GameLaunchSource } from '../../../../../services/casino/types';
import { useIsTestUser } from '../../../../../services/user';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';
interface Props {
    onClose: () => void;
    isOpen: boolean;
}

export default function CasinoPaybackBoosterGamesModal({ isOpen, onClose }: Props) {
    const [search, setSearch] = useState('');
    const [isFocusedInput, setIsFocusedInput] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState<string>();
    const [availableFilters, setAvailableFilters] = useState<string[]>([]);
    const [filteredGames, setFilteredGames] = useState<CasinoGame[]>([]);
    const [gamesByServerGameId] = useStore<{ [key: string]: CasinoGame }>(stores.casino.gamesByServerId);
    const [{ isPhone, isDesktop, isLaptop }] = useStore(media);
    const [[program]] = useStore(stores.paybackBooster.programGames);
    const isTestUser = useIsTestUser();

    const [sliderSettings] = useState({
        variableWidth: true,
        arrows: true,
        slidesToShow: 8,
        slidesToScroll: 3,
    });
    const sliderController = useRef<any>();

    const preventClicksOnSwipe = usePreventClicksOnSwipe(0, sliderController);

    const filtersByName = {
        slots: 2,
        'table-games': 4,
        'live-casino': 5,
        'video-poker': 7,
    };

    const menu = useMemo(
        () =>
            availableFilters.map((filter) => {
                return {
                    title: getCasinoCategoryName(filtersByName[filter]),
                    filter,
                };
            }),
        [availableFilters, filtersByName],
    );

    const games = useMemo(() => {
        if (!program) {
            return [];
        }

        let serverGameIds: string[] = [];
        if (program.cashbackSettings.extraPayback) {
            serverGameIds = Object.keys(program.cashbackSettings.extraPayback);
        }

        return orderBy(
            serverGameIds.reduce((prevGameIds: (CasinoGame & { filters: string[] })[], gameId) => {
                const game = gamesByServerGameId[gameId];
                if (game) {
                    prevGameIds.push({ ...game, filters: getFilters(game) });
                }
                return prevGameIds;
            }, []),
            (game) => game.releaseDate || '',
            'desc',
        );
    }, [program, gamesByServerGameId]);

    useEffect(() => {
        if (games.length) {
            setAvailableFilters(uniq(games.map((game) => game.filters).flat()));
        }
    }, [games]);

    useEffect(() => {
        if (search || selectedFilter) {
            setFilteredGames(
                games.filter(
                    (game) =>
                        (selectedFilter ? game.filters.includes(selectedFilter) : true) &&
                        (search ? game.name.toLowerCase().includes(search.toLowerCase()) : true),
                ),
            );
        } else {
            setFilteredGames(games);
        }
    }, [search, selectedFilter, games]);

    function getFilters(game: CasinoGame) {
        const type = game.type || '';
        const filters: string[] = [];
        if (game.gameType === 'casino') {
            if (type === 'Slots') {
                filters.push('slots');
            } else if (['Baccarat', 'Blackjack', 'Poker', 'Roulette', 'Bingo', 'Puntobanco', 'Dice'].includes(type)) {
                filters.push('table-games');
            } else if (type === 'Video Poker') {
                filters.push('video-poker');
            }
        } else if (game.gameType === 'live') {
            filters.push('live-casino');
        }
        return filters;
    }

    function handleFilter(filter: string) {
        setSelectedFilter(selectedFilter === filter ? undefined : filter);
    }

    function openGame(event, game: CasinoGame) {
        event.preventDefault();
        // TODO: move setting source below game disabled check after removing test user check
        const launchSource: GameLaunchSource = 'PAYBACK_BOOSTER_MORE_GAMES';
        stores.casino.latestGameLaunchPosition.set({ launchSource });
        if (isCasinoGameDisabled(game) && !isTestUser) {
            return;
        }
        navigateToCasinoGamePage(navigator, game);
        onClose();
    }

    function renderModalBody() {
        return (
            <Wrapper>
                <div className="casino-payback-booster-games-modal-title-container">
                    <div className="casino-payback-booster-games-modal-title-adornment">
                        <Svg icon="boosted-icon" />
                    </div>
                    <div className="casino-payback-booster-games-modal-title-label">
                        <span className="primary-color">{translate('payback', 'casino.payback-booster')}</span>{' '}
                        {translate('booster', 'casino.payback-booster')}
                    </div>
                    <UiButton
                        className="casino-payback-booster-games-modal-title-close"
                        icon={<Svg icon="cross" size={2} />}
                        onClick={onClose}
                    />
                </div>
                <div className="casino-payback-booster-games-modal-body-container">
                    <UiSearch
                        onFocusedInput={setIsFocusedInput}
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                        onClear={() => setSearch('')}
                    />
                    {menu.length > 1 && (
                        <div className="casino-search-slider-body">
                            {isPhone ? (
                                <div className="casino-slider-mobile">
                                    {menu.map((menuElement) => (
                                        <div key={menuElement.title} {...preventClicksOnSwipe}>
                                            <UiButton
                                                selected={selectedFilter === menuElement.filter}
                                                onClick={() => handleFilter(menuElement.filter)}
                                                size="small"
                                            >
                                                {menuElement.title}
                                            </UiButton>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <UiSliderSlick customSettings={sliderSettings} ref={sliderController}>
                                    {menu.map((menuElement) => (
                                        <div key={menuElement.title} {...preventClicksOnSwipe}>
                                            <UiButton
                                                selected={selectedFilter === menuElement.filter}
                                                onClick={() => handleFilter(menuElement.filter)}
                                            >
                                                {menuElement.title}
                                            </UiButton>
                                        </div>
                                    ))}
                                </UiSliderSlick>
                            )}
                        </div>
                    )}
                </div>
                <CasinoPaybackBoosterGamesModalView games={filteredGames} onGameClick={openGame} />
            </Wrapper>
        );
    }

    return (
        <Wrapper focused={isFocusedInput}>
            {(isDesktop || isLaptop) && (
                <Dialog scroll="body" maxWidth="lg" open={isOpen} onClose={onClose}>
                    {renderModalBody()}
                </Dialog>
            )}

            {!(isDesktop || isLaptop) && (
                <Drawer open={isOpen} onClose={onClose} anchor="right">
                    {renderModalBody()}
                </Drawer>
            )}
        </Wrapper>
    );
}
