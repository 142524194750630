import React, { useEffect, useState } from 'react';
import Wrapper from './styles';

interface Props {
    delay?: number;
    values: Array<number | string>;
    duration?: number;
    number?: number;
}
export default function UiReelNumbers({ delay, values, number = 0, duration = 700 }: Props) {
    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
        setHasLoaded(true);
    }, []);

    const display = hasLoaded ? number : 0;

    return (
        <Wrapper duration={duration} delay={delay} display={display}>
            {values.map((v) => (
                <div key={v} className="number">
                    {v}
                </div>
            ))}
        </Wrapper>
    );
}
