import React from 'react';
import Wrapper from './styles';
import SportTreeLivebet from '../../tree/livebet/SportTreeLivebet';

export default function SportSidebarLive() {
    return (
        <Wrapper>
            <SportTreeLivebet />
        </Wrapper>
    );
}
