import styled from 'styled-components';
import { adjustHue, lighten } from 'polished';
import { mediaQuery } from '../../../styles/utils';

type StyledProps = {
    isCompact?: boolean;
};
export default styled.div<StyledProps>`
    display: ${(props) => (props.isCompact ? 'block' : 'flex')};
    justify-content: center;
    width: 100%;
    color: white;
    min-width: ${(props) => (props.isCompact ? '100%' : '500px')};

    @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
        min-width: auto;
        display: block;
    }

    .be-online {
        position: absolute;
        top: -15px;
        left: 50%;
        transform: translate(-50%, 0);

        display: flex;
        align-items: center;
        justify-content: center;

        text-transform: uppercase;
        font-size: 0.65rem;
        letter-spacing: 2px;
        color: ${({ theme }) => theme.happyHour.secondaryColor};

        background-color: ${({ theme }) => theme.happyHour.primaryDarkColor};
        border: 2px solid ${({ theme }) => theme.happyHour.secondaryColor};
        border-radius: 100px;
        padding: 0.4rem 0.8rem;

        svg {
            margin-right: 0.4rem;
        }
    }

    .happy-hour-login {
        position: relative;

        background-color: ${({ theme }) => theme.happyHour.primaryColor};
        box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.3),
            inset 0 0 8px 5px ${({ theme }) => adjustHue(10, lighten(0.15, theme.happyHour.primaryColor))};
        border-radius: 10px;
        padding: 4rem;
    }

    .widget-no-event {
        position: relative;

        background-color: ${({ theme }) => theme.happyHour.primaryColor};
        box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.3),
            inset 0 0 8px 5px ${({ theme }) => adjustHue(10, lighten(0.15, theme.happyHour.primaryColor))};
        border-radius: 10px;
        padding: 2rem 4rem;

        width: 100%;
        max-width: 250px;

        h2 {
            text-transform: uppercase;
            font-size: 1.5rem;
            color: ${({ theme }) => theme.happyHour.secondaryColor};
        }

        p {
            font-size: 1.2rem;
            color: #fff;
        }

        .event-header {
            position: absolute;
            top: -30px;
            left: 50%;
            transform: translate(-50%, 0);

            width: 203px;
            height: 71px;
            background: url('https://coolbet-cms.imgix.net/blog/calendar-holders_1588622163997.png?fm=png');
            background-size: cover;
        }
    }

    .widget-next-event {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        position: relative;

        background-color: ${({ theme }) => theme.happyHour.primaryColor};
        box-shadow: 0 20px 40px -10px rgba(0, 0, 0, 0.35),
            inset 0 0 8px 5px ${({ theme }) => adjustHue(10, lighten(0.15, theme.happyHour.primaryColor))};
        border-radius: 10px;

        width: 100%;
        max-width: 280px;
        margin: 0 auto;

        .event-header {
            position: absolute;
            top: -30px;
            left: 50%;
            transform: translate(-50%, 0);

            width: 203px;
            height: 71px;
            background: url('https://coolbet-cms.imgix.net/blog/calendar-holders_1588622163997.png?fm=png');
            background-size: cover;
        }
        .event-time {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            padding: 3rem 0 2rem 0;
            width: 100%;

            text-align: center;
            text-transform: uppercase;
            letter-spacing: 1px;
            text-shadow: 0 1px 1px #5a1474;
            .time-day {
                font-size: 1.1rem;
            }
            .time-time {
                display: flex;

                font-family: 'Roboto', sans-serif;
                font-size: 2.5rem;
                font-weight: 800;

                padding: 0.2rem 0;

                .minutes {
                    font-size: 0.8rem;
                    font-weight: 300;

                    margin-left: 3px;
                    margin-top: 6px;
                }
                .time-separator {
                    margin: 0 5px;
                }
            }
            .time-note {
                font-size: 0.7rem;
            }
        }

        .hh-opted-out {
            margin: 0 0 1rem 0;
        }

        .event-prizes {
            background-color: ${({ theme }) => theme.happyHour.primaryDarkColor};
            border: 2px solid ${({ theme }) => theme.happyHour.secondaryColor};
            border-radius: 10px;
            padding: 1rem 2rem;

            text-align: center;
            color: ${({ theme }) => theme.happyHour.secondaryColor};

            .each-wins {
                position: relative;
                display: inline-block;

                text-transform: uppercase;
                font-size: 0.7rem;
                letter-spacing: 2px;
                color: #fff;
                font-weight: 800;

                background-color: ${({ theme }) => theme.happyHour.primaryDarkColor};
                border: 1px solid ${({ theme }) => theme.happyHour.secondaryColor};
                border-radius: 100px;
                padding: 0.4rem 0.8rem;
                margin: 1rem 0;

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    transform: translate(0, -50%);

                    width: 30px;
                    height: 2px;
                    background-color: ${({ theme }) => theme.happyHour.secondaryColor};
                }
                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 100%;
                    transform: translate(0, -50%);

                    width: 30px;
                    height: 2px;
                    background-color: ${({ theme }) => theme.happyHour.secondaryColor};
                }
            }

            .number-of-winners {
                display: flex;
                align-items: center;
                justify-content: center;

                .winners-count {
                    font-family: 'Roboto', sans-serif;
                    font-size: 2.5rem;
                    font-weight: 800;

                    margin-right: 0.8rem;
                }

                .winners-label {
                    display: flex;
                    align-items: center;

                    font-family: 'Roboto', sans-serif;
                    font-weight: 800;
                    font-size: 0.65rem;
                    text-transform: uppercase;
                    text-align: left;
                    svg {
                        margin-right: 0.4rem;
                    }
                }
            }

            .game-image {
                margin-right: 0.8rem;
                img {
                    border-radius: 10px;
                    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.35), inset 1px 1px 4px -2px rgba(255, 255, 255, 0.22);
                    border: 2px solid transparent;
                    transition: border 200ms ease-in-out;
                    &:hover {
                        border: 2px solid ${({ theme }) => theme.happyHour.secondaryColor};
                    }
                }
            }
        }
    }

    .time-ended {
        padding: 1rem;
        border-radius: 10px;
        border: 1px solid ${({ theme }) => theme.happyHour.secondaryColor};
        box-shadow: 0 20px 40px -10px rgba(0, 0, 0, 0.35);
        background: ${({ theme }) => theme.happyHour.secondaryColor};
        color: ${({ theme }) => theme.happyHour.primaryDarkColor};
        width: 80%;
        font-size: 0.8rem;
        margin-top: 1rem;
        text-shadow: none;
        font-weight: bold;
    }

    .widget-schedule {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 100%;
        max-width: 280px;
        margin: 0 auto;
        padding: 0 1rem;

        text-transform: uppercase;
        letter-spacing: 1px;

        .schedule-label {
            font-size: 0.75rem;

            margin: 1rem 0;
        }

        .schedule-item {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;

            width: 100%;
            margin-bottom: 0.8rem;

            color: #fff;

            &:hover {
                color: ${({ theme }) => theme.happyHour.secondaryColor};
            }
        }

        .schedule-day {
            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 100%;

            font-size: 0.75rem;

            margin-bottom: 0.4rem;
            padding: 0 0.8rem;

            .day {
                font-weight: 800;
                font-size: 1rem;
                letter-spacing: normal;
            }

            .time {
                display: flex;
                align-items: flex-start;

                font-family: 'Roboto', sans-serif;
                font-weight: 800;
                font-size: 0.7rem;

                margin-left: 0.8rem;

                .minutes {
                    font-size: 0.5rem;
                    font-weight: 300;

                    margin: 0 0 0 3px;
                }
                .time-separator {
                    margin: 0 5px;
                }
            }
        }
        .schedule-day-prize {
            display: flex;
            align-items: center;

            width: 100%;
            background-color: ${({ theme }) => theme.happyHour.primaryDarkColor};
            border: 1px solid ${({ theme }) => theme.happyHour.primaryColor};
            border-radius: 10px;
            padding: 0.2rem 0.8rem;

            .schedule-day-game {
                width: 55px;
                img {
                    border-radius: 10px;
                    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.35), inset 1px 1px 4px -2px rgba(255, 255, 255, 0.22);
                    border: 2px solid transparent;
                    transition: border 200ms ease-in-out;
                    vertical-align: middle;
                    &:hover {
                        border: 2px solid ${({ theme }) => theme.happyHour.secondaryColor};
                    }
                }
            }

            .schedule-day-winners {
                padding: 0 0.8rem;
            }

            .winners-count,
            .winners-prize {
                display: flex;
                align-items: center;

                font-family: 'Roboto', sans-serif;
                font-size: 1.2rem;
                font-weight: 800;
                color: ${({ theme }) => theme.happyHour.secondaryColor};

                margin-right: 0.8rem;
                .winners-label {
                    font-size: 40%;
                    color: #fff;
                    margin-left: 0.4rem;
                }
            }
        }
    }
`;
