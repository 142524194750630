import styled from 'styled-components';
import { lighten, darken } from 'polished';

const toastBorderRadius = '5px';
const sizeNormal = '100px';
const sizeMedium = '80px';
const sizeSmall = '60px';

export default styled.div`
    display: block;
    position: fixed;

    bottom: 10px;
    left: 10px;
    z-index: 1200;

    transform: translateX(-110%);

    transition: ${(props) => `transform ${props.effectDuration}ms, opacity ${props.effectDuration}ms`};
    transition-timing-function: ease-in-out;
    &.open {
        transform: translateX(0);
    }

    .coolbet-toast {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        min-width: 320px;
        max-width: 500px;
        position: relative;
        align-items: stretch;
        min-height: ${sizeNormal};

        border-radius: ${toastBorderRadius};
        box-shadow: 0 0 5px 3px rgba(#000000, 0.35);

        @media (max-width: 520px) {
            max-width: calc(100vw - 20px);
        }

        &.no-icon {
            .coolbet-toast-content-container {
                border-radius: ${toastBorderRadius};
                box-shadow: inset 1px 1px 4px -2px rgba(white, 0.22);
            }

            min-width: 280px;
        }

        .coolbet-toast-icon-container {
            flex: 0 0 ${sizeNormal};
            min-height: ${sizeNormal};
            width: ${sizeNormal};
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-shadow: inset 1px 1px 4px -2px rgba(255, 255, 255, 0.22);
            border-radius: ${toastBorderRadius} 0 0 ${toastBorderRadius};
            background-color: ${({ theme }) => lighten(0.05, theme.buttons.background)};
            background-size: 200%;

            .coolbet-toast-icon {
                max-width: ${sizeNormal} - 40px;
                max-height: ${sizeNormal} - 40px;
                width: 100%;
                height: 100%;
                color: white;
            }

            .coolbet-toast-icon-subtext {
                height: 13px;
                font-size: 13px;
                line-height: 13px;
                white-space: nowrap;
            }
        }

        .coolbet-toast-content-container {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            padding-bottom: 10px;
            box-shadow: inset 0 9px 4px -10px rgba(255, 255, 255, 0.22);

            border-radius: 0 ${toastBorderRadius} ${toastBorderRadius} 0;
            background-color: ${({ theme }) => lighten(0.02, theme.buttons.background)};

            .coolbet-toast-content-text-container {
                width: 100%;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                padding: 15px 15px 10px 15px;

                .coolbet-toast-content-text-container-title {
                    font-size: 14px;
                    line-height: 14px;
                    padding-bottom: 6px;
                    flex-basis: 20px;
                    font-weight: bold;
                    letter-spacing: 0.5px;
                    position: relative;
                    color: #ffffff;
                }

                .coolbet-toast-content-text-container-message {
                    flex-grow: 1;
                    font-size: 13px;
                    white-space: pre-wrap;
                    color: darken(#ffffff, 10%);
                }

                &.no-title {
                    .coolbet-toast-content-text-container-message {
                        font-size: 14px;
                    }
                }
            }

            .coolbet-toast-content-actions-container {
                height: 25px;
                flex-basis: 25px;
                width: 100%;
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                justify-content: flex-end;
                padding: 0 10px;

                .coolbet-toast-content-action-button {
                    height: 25px;
                    background-color: ${({ theme }) => lighten(0.07, theme.buttons.background)};
                    border-radius: ${toastBorderRadius};
                    min-width: 60px;
                    padding: 0 10px 0 10px;
                    text-transform: uppercase;
                    font-size: 13px;
                    letter-spacing: 1px;
                    line-height: 25px;
                    white-space: nowrap;
                    margin-left: 10px;
                    text-align: center;
                    cursor: pointer;
                    color: darken(#ffffff, 10%);
                    transition: background-color 0.2s, color 0.2s;
                    position: relative;
                    overflow: hidden;

                    &:nth-of-type(1) {
                        margin-left: unset;
                    }

                    &:hover {
                        background-color: ${({ theme }) => lighten(0.1, theme.buttons.background)};
                        color: #ffffff;
                    }

                    .coolbet-toast-content-action-button-bar {
                        background-color: ${({ theme }) => darken(0.05, theme.buttons.background)};
                        opacity: 0.7;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        transform: scaleX(0);
                        transition: transform 0.2s;
                        transform-origin: left;
                    }
                }
            }
        }

        &.size-medium {
            min-width: 250px;
            min-height: ${sizeMedium};
            .coolbet-toast-icon-container {
                flex: 0 0 ${sizeMedium};
                min-height: ${sizeMedium};
                width: ${sizeMedium};
                padding: 12px;

                .coolbet-toast-icon {
                    max-width: ${sizeMedium} - 32px;
                    max-height: ${sizeMedium} - 32px;
                }

                .coolbet-toast-icon-subtext {
                    height: 12px;
                    font-size: 12px;
                    line-height: 12px;
                }
            }

            .coolbet-toast-content-container {
                .coolbet-toast-content-text-container {
                    padding: 10px 10px 0 10px;

                    .coolbet-toast-content-text-container-title {
                        font-size: 13.5px;
                        line-height: 13.5px;
                        letter-spacing: 0.5px;
                        margin-bottom: 3px;
                        flex-basis: 14px;
                        height: 14px;
                    }

                    .coolbet-toast-content-text-container-message {
                        font-size: 13px;
                        line-height: 13px;
                        color: #fff;
                        letter-spacing: 0.5px;
                    }

                    &.no-title {
                        .coolbet-toast-content-text-container-message {
                            font-size: 14px;
                        }
                    }
                }

                .coolbet-toast-content-actions-container {
                    width: unset;
                    padding: 0 10px;
                    margin-top: 7px;

                    .coolbet-toast-content-action-button {
                        font-size: 12px;
                        height: 25px;
                        line-height: 25px;
                        margin-left: 7px;
                    }
                }
            }
        }

        &.size-small {
            min-width: 200px;
            min-height: ${sizeSmall};
            .coolbet-toast-icon-container {
                flex: 0 0 ${sizeSmall};
                min-height: ${sizeSmall};
                width: ${sizeSmall};
                padding: 12px;

                .coolbet-toast-icon {
                    max-width: ${sizeSmall} - 24px;
                    max-height: ${sizeSmall} - 24px;
                    svg {
                        height: 100%;
                        width: 100%;
                    }
                }

                .coolbet-toast-icon-subtext {
                    height: 11px;
                    font-size: 11px;
                    line-height: 11px;
                }
            }

            .coolbet-toast-content-container {
                flex-direction: row;
                padding: unset;

                .coolbet-toast-content-text-container {
                    padding: 15px 15px;
                    height: 100%;
                    flex-direction: row;
                    position: relative;

                    .coolbet-toast-content-text-container-title {
                        font-size: 13px;
                        line-height: 13px;
                        padding: unset;
                        position: absolute;
                        top: 10px;
                        left: 15px;
                        letter-spacing: 0.5px;
                    }

                    .coolbet-toast-content-text-container-message {
                        font-size: 13px;
                        line-height: 13px;
                        color: #fff;
                        display: flex;
                        align-items: flex-start;
                        padding-top: 15px;
                        letter-spacing: 0.5px;
                    }

                    &.no-title {
                        .coolbet-toast-content-text-container-message {
                            padding: unset;
                            align-items: center;
                            font-size: 14px;
                        }
                    }
                }

                .coolbet-toast-content-actions-container {
                    height: 100%;
                    width: unset;
                    flex-basis: unset;
                    justify-content: center;
                    align-items: center;
                    padding: 0 15px 0 0;

                    .coolbet-toast-content-action-button {
                        font-size: 12px;
                        height: 30px;
                        line-height: 30px;
                    }
                }
            }
        }

        &.default {
            color: #ffffff;
        }

        &.custom-theme {
            .coolbet-toast-icon-container {
                background-color: ${(props) => props.theme.colors[props.toastType]};
            }

            .coolbet-toast-content-actions-container {
                .coolbet-toast-content-action-button {
                    background-color: ${(props) => props.theme.colors[props.toastType]};

                    &:hover {
                        background-color: ${(props) => lighten(0.05, props.theme.colors[props.toastType])};
                    }

                    .coolbet-toast-content-action-button-bar {
                        background-color: ${(props) => darken(0.1, props.theme.colors[props.toastType])};
                    }
                }
            }
        }
    }
`;
