import React, { FocusEvent, useRef, useState } from 'react';
import Wrapper from './styles';
import UiGroup from '../../../ui/group/UiGroup';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import classNames from 'classnames';
import { translate } from '../../../../services/translate';
import { isRetail } from '../../../../services/environment';
import { stores } from '../../../../stores';
import { showVirtualKeyboard } from '../../../../services/virtual-keyboard/virtual-keyboard';
import { isMobile } from '../../../../services/browser';
import Svg from '../../../svg/Svg';
import SportBetslipStakeKeyboard from '../../../sport/betslip/stake-keyboard/SportBetslipStakeKeyboard';
import SportBetslipQuickStake from '../../../sport/betslip/quick-stake/SportBetslipQuickStake';
import { getBetslipQuickStakes } from '../../../../services/sports/betslip';
import { getFixedStake } from '../../../../services/sports/betslip-formatting';
import { convertOdds } from '../../../../services/odds-format';
import { retail } from '../../../../stores/retail/retail';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import round from 'lodash/round';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    cardId: number;
    totalOdds: number;
}

export default function ComboCardsBetslipStakeInput({ cardId, totalOdds }: Props) {
    const inputRef = useRef<HTMLInputElement>(null);
    const elementRef = useRef<HTMLDivElement>(null);
    const betStakeInputId = `betStakeInputId-betslip-combo-card-${cardId}`;
    const [cardsInBetslip] = useStore(stores.sports.comboCard.cardsInBetslip);
    const [stakeByCardId, setStakeByCardId] = useStore(stores.sports.comboCard.stakeByCardId);
    const [isStakeChanging, setIsStakeChanging] = useState(false);
    const [{ isPhone }] = useStore(media);
    const isRetailLayout = isRetail();
    const [isRetailKeyboardVisible] = useStore(retail.retailKeyboard.isVisible);
    const [isKeyboardOpened, setIsKeyboardOpened] = useState(false);
    const quickStakes = getBetslipQuickStakes(isPhone ? 5 : 4);

    const inputProps = {
        type: 'text',
        min: 0,
        onFocus: handleInputFocus,
        onBlur: () => stores.sports.isBottomNavigationVisible.set(true),
        onChange: (e) => handleChange(e.target.value),
        autoComplete: 'off',
        autoCorrect: 'off',
        autoCapitalize: 'off',
        spellCheck: 'false',
        'data-gramm_editor': 'false',
        'data-gramm': 'false',
    } as const;

    function handleChange(newStake: number = 0) {
        if (newStake < 0 || isNaN(newStake)) {
            return;
        }
        setIsStakeChanging(true);
        setStakeByCardId((state) => ({ ...state, [cardId]: getFixedStake(newStake) }));
        const timeout = setTimeout(() => setIsStakeChanging(false), 500);
        return () => clearTimeout(timeout);
    }

    function handleInputFocus({ target }: FocusEvent<HTMLInputElement>) {
        if (!isRetailLayout) {
            setIsKeyboardOpened(true);
        }

        if (isPhone) {
            const stakeElement = elementRef.current;
            if (stakeElement) {
                stakeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
        stores.sports.isBottomNavigationVisible.set(false);
        if (isRetailLayout && target.parentElement) {
            showVirtualKeyboard({
                targetEl: target.parentElement,
                anchorEl: target.parentElement.parentElement as HTMLElement,
                shouldTakeAnchorWidth: true,
                callback: (value) => handleChange(parseInt(value)),
                value: stakeByCardId[cardId].toString() ?? '',
                layout: 'betslip',
                ignoreLossFocus: false,
            });
        }
    }

    function handleManualInputFocus() {
        if (isRetailLayout && inputRef?.current) {
            const ref = inputRef.current as HTMLElement;
            ref.focus();
        }
    }

    function applyStakeForAll() {
        const allCardIds = cardsInBetslip.map((card) => card.id);
        setStakeByCardId(Object.assign({}, ...allCardIds.map((id) => ({ [id]: stakeByCardId[cardId] }))));
    }

    function handleQuickStakesClick(value: number | string, newValue: number) {
        const isQuickStakeIncremental = isFeatureAvailable(FEATURE.QUICKSTAKE_INCREMENTAL);
        const currentStake = parseInt(`${value}`) || 0;
        const updatedValue = isQuickStakeIncremental ? currentStake + newValue : newValue;
        handleChange(updatedValue);
    }

    return (
        <Wrapper>
            <UiGroup>
                <div className="stake-input" ref={elementRef}>
                    {isFeatureAvailable(FEATURE.DISPLAY_DOLLAR_SIGN) && <div className="stake-currency">$</div>}
                    <label
                        htmlFor={betStakeInputId}
                        className={classNames('stake-input-label', {
                            'single-label': true,
                        })}
                    >
                        {translate('Your stake', 'ui.sportsbook')}
                    </label>
                    <div onClick={handleManualInputFocus}>
                        <input
                            id={betStakeInputId}
                            value={stakeByCardId[cardId] || ''}
                            ref={inputRef}
                            readOnly={isRetailLayout || isMobile()}
                            className={isRetailLayout ? 'retail-stake-input' : ''}
                            {...inputProps}
                        />
                    </div>

                    {stakeByCardId[cardId] > 0 &&
                        ((!isKeyboardOpened && !isRetailLayout) || (!isRetailKeyboardVisible && isRetailLayout)) && (
                            <div className="remove-stake-button" onClick={() => handleChange()}>
                                <Svg icon="backspace" />
                            </div>
                        )}
                </div>
            </UiGroup>
            {isKeyboardOpened && !isRetailLayout ? (
                <SportBetslipStakeKeyboard
                    inputRef={inputRef}
                    value={stakeByCardId[cardId]}
                    onChange={handleChange}
                    onClose={() => setIsKeyboardOpened(false)}
                />
            ) : (
                <div className="quick-stake-buttons">
                    {quickStakes.map((stake) => {
                        return (
                            <SportBetslipQuickStake
                                key={stake}
                                stake={stake}
                                onStakeUpdate={() => handleQuickStakesClick(stakeByCardId[cardId], stake)}
                                disabled={isRetailKeyboardVisible}
                            />
                        );
                    })}
                </div>
            )}

            <div className="total-odds">
                <span className="stake-input-label">{translate('Total odds', 'ui.betslip')} →</span>
                <span className="stake-input-value">{convertOdds(totalOdds)}</span>
            </div>
            {Boolean(stakeByCardId[cardId]) && (
                <div className="potential-return">
                    <span className="stake-input-label">{translate('Potential return', 'ui.betslip')} → </span>
                    <span className={classNames('stake-input-value', { 'animated-value': isStakeChanging })}>
                        {formattedAmountWithCurrency(round(stakeByCardId[cardId] * totalOdds, 2))}
                    </span>
                </div>
            )}

            {Boolean(stakeByCardId[cardId]) && cardsInBetslip.length > 1 && (
                <a className="apply-to-all" onClick={applyStakeForAll}>
                    <span>{translate('Apply to all ?', 'ui.sportsbook')}</span>
                </a>
            )}
        </Wrapper>
    );
}
