import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

const isOutright = ({ isOutright }) =>
    isOutright &&
    css`
        min-width: 220px;
        max-width: 220px;
        width: 100%;
    `;

export default styled.div`
    .match-market-types-header {
        display: flex;
        font-size: 0.7rem;
        font-weight: bold;

        .category-market-types {
            width: 100%;
            display: flex;
            text-align: center;

            .market-type {
                flex: 1;
                margin-bottom: var(--spacing-4);
                color: var(--match-secondary-color);
                font-weight: var(--font-weight-regular);
            }
        }
    }
    .compact-match-container {
        display: flex;
        align-items: ${(props) => (props.isAmericanLayout && !props.isOutright ? 'flex-start' : 'center')};
    }
    .match-name-container {
        display: flex;
        align-items: center;
        gap: var(--spacing-4);
        min-width: 350px;
        max-width: 350px;
        padding: 0 var(--spacing-8) 0 0;

        white-space: nowrap;
        margin-top: ${(props) => (props.isAmericanLayout || props.isOutright ? '' : props.theme.odds.outcomeHeight)};

        @media ${mediaQuery.isTablet} {
            min-width: 460px;
            max-width: 460px;
        }

        @media ${mediaQuery.isPhone} {
            min-width: 130px;
            max-width: 130px;
            ${isOutright};
        }
    }

    .match-markets {
        position: ${(props) => (props.isAmericanLayout ? 'relative' : '')};
        min-width: 0;
        flex: 1;
    }
    .match-market {
        min-height: 65px;
        min-width: 0;

        max-width: 300px;
        margin-left: auto;

        @media ${mediaQuery.isPhone} {
            max-width: initial;
        }
    }
    .match-compact-info {
        margin-top: ${(props) => (props.isAmericanLayout ? 'var(--spacing-12)' : 'var(--spacing-8)')};
        margin-bottom: var(--spacing-8);
    }
    .match-compact-info-main {
        display: flex;
        align-items: center;
        min-width: 0;

        color: var(--match-secondary-color);
        font-size: 0.7rem;
        .match-time {
            flex-shrink: 0;
            width: 100%;
        }
        .match-extra {
            display: flex;
            align-items: center;
            margin-left: auto;
            padding: 0 0.8rem;
            > *:not(:last-child) {
                margin-right: 1.2rem;
            }
        }
        .close-button {
            position: relative;
            margin-left: auto;
        }
    }
    .close-button {
        top: -0.4rem;
        right: -0.4rem;
    }
    .match-sport-icon {
        display: ${(props) => (props.isMatchPage ? 'none' : '')};
    }
    .active svg {
        color: var(--color-highlight);
    }
`;
