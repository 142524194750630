import React, { useState } from 'react';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import SportTreePrematchAdvanced from '../prematch/advanced/SportTreePrematchAdvanced';
import UiButton from '../../../ui/button/UiButton';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import { useStore } from '../../../../hooks/useStore';

export default function SportTreeMenu() {
    const [tree] = useStore(stores.sports.prematchTree);
    const [isDisplayingAll, setIsDisplayingAll] = useState(false);
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);
    const INITIAL_VISIBLE_CATEGORY_COUNT = isLayoutB ? 65 : 5;

    return (
        <>
            <div className="menu-label" title={translate('Switch menu', 'ui.sportsbook')}>
                {translate('Top Sports', 'ui.sportsbook')}
            </div>

            <SportTreePrematchAdvanced
                tree={tree}
                isDisplayingAll={isDisplayingAll}
                initialVisibleCategoryCount={INITIAL_VISIBLE_CATEGORY_COUNT}
            />

            {!isDisplayingAll && !isLayoutB && (
                <UiButton block onClick={() => setIsDisplayingAll(true)} className="show-all-sports">
                    {translate('show all', 'ui.sportsbook')}
                </UiButton>
            )}
        </>
    );
}
