import React, { ReactNode } from 'react';
import { stores } from '../../stores';
import { getRoute, isActiveRoute } from '../../services/router';
import { isDepositPageBlocked } from '../../services/responsible-gaming';
import ResponsibleGamingSessionReminderAlert from './session-reminder/alert/ResponsibleGamingSessionReminderAlert';
import ResponsibleGamingProductBlockingAlert from './product-blocking/alert/ResponsibleGamingProductBlockingAlert';
import ResponsibleGamingLoginDurationLimitExceededAlert from './login-duration-limit-exceeded-alert/ResponsibleGamingLoginDurationLimitExceededAlert';
import ResponsibleGamingAnnualReport from './annual-report/ResponsibleGamingAnnualReport';
import UiProductClosed from '../ui/product-closed/UiProductClosed';
import { FEATURE, isFeatureAvailable } from '../../services/feature';
import { getUserProductBlockReason } from '../../services/responsible-gaming';
import { PRODUCT } from '../../types/common';
import { useStore } from '../../hooks/useStore';

interface Props {
    children: ReactNode;
}

export default function ResponsibleGaming({ children }: Props) {
    const [blockedProducts] = useStore(stores.blockedProducts);
    const [isAuthenticated] = useStore(stores.isAuthenticated);

    const isVirtualSportsRouteActive = isActiveRoute(getRoute('virtual-sports'), false);
    const isSportsbookRouteActive = !isVirtualSportsRouteActive && isActiveRoute(getRoute('sport'), false);
    const isHorseRacingRouteActive =
        isFeatureAvailable(FEATURE.HORSE_RACING) && isActiveRoute(getRoute('horse-racing'), false);
    const isCasinoRouteActive =
        isActiveRoute(getRoute('casino'), false) || isActiveRoute(getRoute('casino-game'), false);
    const isPokerRouteActive = isActiveRoute(getRoute('poker'), false);
    const isDepositRouteActive = isActiveRoute(getRoute('deposit'), false);

    const closedProductByCountry =
        (isSportsbookRouteActive && blockedProducts[PRODUCT.SPORTSBOOK] && PRODUCT.SPORTSBOOK) ||
        (isVirtualSportsRouteActive && blockedProducts[PRODUCT.VS_HIGHLIGHT_GAMES] && PRODUCT.VS_HIGHLIGHT_GAMES);

    if (closedProductByCountry) {
        return <UiProductClosed product={closedProductByCountry} />;
    }

    const shouldDisplayProductClosedAlert =
        (isSportsbookRouteActive && Boolean(getUserProductBlockReason(PRODUCT.SPORTSBOOK))) ||
        (isHorseRacingRouteActive && Boolean(getUserProductBlockReason(PRODUCT.RACEBOOK))) ||
        (isCasinoRouteActive && Boolean(getUserProductBlockReason(PRODUCT.CASINO))) ||
        (isPokerRouteActive && Boolean(getUserProductBlockReason(PRODUCT.POKER))) ||
        (isVirtualSportsRouteActive && Boolean(getUserProductBlockReason(PRODUCT.VIRTUAL_SPORTS))) ||
        (isDepositRouteActive && isDepositPageBlocked());

    const productClosedAlert = (
        <>
            {isSportsbookRouteActive && <ResponsibleGamingProductBlockingAlert product={PRODUCT.SPORTSBOOK} />}
            {isHorseRacingRouteActive && <ResponsibleGamingProductBlockingAlert product={PRODUCT.RACEBOOK} />}
            {isCasinoRouteActive && <ResponsibleGamingProductBlockingAlert product={PRODUCT.CASINO} />}
            {isPokerRouteActive && <ResponsibleGamingProductBlockingAlert product={PRODUCT.POKER} />}
            {isVirtualSportsRouteActive && <ResponsibleGamingProductBlockingAlert product={PRODUCT.VIRTUAL_SPORTS} />}
            {isDepositRouteActive && <ResponsibleGamingProductBlockingAlert product={PRODUCT.DEPOSIT} />}
        </>
    );

    return (
        <>
            {isAuthenticated && (
                <>
                    <ResponsibleGamingSessionReminderAlert />
                    <ResponsibleGamingLoginDurationLimitExceededAlert />
                    <ResponsibleGamingAnnualReport />
                </>
            )}

            {shouldDisplayProductClosedAlert ? productClosedAlert : children}
        </>
    );
}
