import { getServiceUrl, httpGet, httpPost, httpPut } from '../services/api';
import { LIMIT_PERIOD, LIMIT_TYPE, processReminder } from '../services/responsible-gaming';
import { SelfExclusionUntilFurtherNotice } from '../services/responsible-gaming/types';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { PRODUCT } from '../types/common';

const getUrl = (url) => getServiceUrl('responsible-gaming', url);

export async function getProductsTimeoutStatuses() {
    const url = getUrl('timeout/');
    return httpGet<{
        casino: TimeoutItem;
        sportsbook: TimeoutItem;
        poker: TimeoutItem;
        horse_racing: TimeoutItem;
        source: TimeoutItemSource;
        firstEndDate: string;
        selfExclusionUntilFurtherNotice: SelfExclusionUntilFurtherNotice | undefined;
    }>(url);
}

export async function getUserReminderSettings(): Promise<{ interval_mins: number; is_active: boolean }> {
    const url = getUrl('reminder-settings/');
    return httpGet(url);
}

export async function setPanicTimeout() {
    const url = getUrl('timeout/panic/');
    return httpPost<void>(url);
}

export async function setSelfExclusionTimeout(period: number, isUntilFurtherNotice?: boolean) {
    const url = getUrl('timeout/self-exclusion/');
    return httpPost<void>(url, { period, isUntilFurtherNotice });
}

export async function setTimeoutForProduct(product, periodInDays) {
    const url = getUrl('timeout/');
    return httpPost<void>(url, { product, period: periodInDays });
}

export async function setSessionReminder(
    reminderIntervalInMinutes,
    isSessionReminderEnabled,
): Promise<{ interval_mins: number; is_active: boolean }> {
    const url = getUrl('reminder-settings/');

    const body = isSessionReminderEnabled
        ? { is_active: isSessionReminderEnabled, interval_mins: reminderIntervalInMinutes }
        : { is_active: isSessionReminderEnabled };

    return httpPost(url, body);
}

export async function loadUnapprovedReminders() {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);

    if (!isAuthenticated) {
        return;
    }

    const url = getUrl('unapproved-reminders/');
    const responsibleGamingReminders = await httpGet<Reminder[]>(url);

    processReminder(responsibleGamingReminders.length > 0 ? responsibleGamingReminders[0] : {});
}

export function prolongSessionReminder(unApprovedSessionReminder) {
    const url = getUrl('prolong-reminder/');
    return httpPost(url, { reminder_id: unApprovedSessionReminder.id });
}

export async function getLimitsByType(type) {
    const url = getUrl('limits/');
    return httpGet<Record<string, { current_limit: any; next_limit: any }>>(url, { type });
}

export async function setLimitByType(payload: { type: LIMIT_TYPE; period: LIMIT_PERIOD; amount: number }) {
    const url = getUrl('limits/');
    return httpPost(url, payload);
}

export async function scheduleRemovalOfActiveLimitByTypeAndPeriod(type, period) {
    const url = getUrl('limits/');
    return httpPost(url, { type, period, amount: 0 });
}

export async function scheduleCancellationOfUpcomingLimitByTypeAndPeriod(type, period) {
    const url = getUrl('limits/');
    return httpPut<Record<string, never>>(url, { type, period });
}

export async function saveSwedishLicenseFields(updateData: {
    depositLimitAmountDay?: number;
    depositLimitAmountMonth?: number;
    depositLimitAmountWeek?: number;
    isOverRecommendedLimitConfirmed: boolean;
}) {
    const url = getUrl('sweden-license/');
    return httpPost(url, updateData);
}

export async function getUserAskResponsibleGaming() {
    const url = getUrl('user/');
    return httpGet<RGUser>(url);
}

export async function skipLimits(limitType: LIMIT_TYPE) {
    const url = getUrl('user/skip-limits/');
    return httpPost(url, { limitType });
}

export async function cancelSelfExclusionUntilFurtherNotice() {
    const url = getUrl('timeout/self-exclusion/until-further-notice/cancel/');
    return httpPost(url);
}

export enum TimeoutItemSource {
    PANIC_BUTTON = 'panic button',
    PRODUCT_BLOCKING = 'product blocking',
    SELF_EXCLUSION = 'self exclusion',
}

type TimeoutItem = {
    end_date: string;
    product: PRODUCT;
    source: TimeoutItemSource;
};

type Reminder = {
    action: string;
    id: string;
    sessionStart: string;
    userId: string;
    wallet_data: any[];
    wallet_total: number;
};

type RGUser = {
    askDepositLimit: boolean;
    askLoginDurationLimit: boolean;
    askLossLimit: boolean;
};
