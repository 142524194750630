import React, { useState } from 'react';
import Wrapper from '../../styles-designed';
import { BonusType } from '../../../../../services/sports/constants';
import UiAnimate from '../../../../ui/animate/UiAnimate';
import Svg from '../../../../svg/Svg';
import { translate } from '../../../../../services/translate';
import { getActiveCurrency } from '../../../../../services/currency';
import UiButton from '../../../../ui/button/UiButton';
import { BonusBet } from '../../../../../microservices/bonuses';
import classNames from 'classnames';
import SportBonusBetDescription from '../../description/SportBonusBetDescription';
import {
    bonusLabelByType,
    getRemainingTimeText,
    isBonusEligibleAndSelected,
} from '../../../../../services/sports/bonus-bets';

interface Props {
    bonus: BonusBet;
    onBonusToggle: () => void;
}

export default function SportBonusBetFreeBetsDesignedLayout({ bonus, onBonusToggle }: Props) {
    const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

    return (
        <Wrapper>
            <UiAnimate animationIn="flipInX" animationOut="flipOutX" isVisible={true}>
                <div
                    className={classNames('campaign-bet animated fadeIn', {
                        selected: isBonusEligibleAndSelected(bonus),
                    })}
                >
                    <div className="campaign-icon">
                        <img
                            src={`${window.coolb2b?.baseUrl || ''}/assets/images/general/${
                                [BonusType.FreeBet, BonusType.FreeBetV2].includes(bonus.bonusType!)
                                    ? 'bet-credit-ico.png'
                                    : 'bet-protection-ico.png'
                            }`}
                            alt=""
                        />
                        {(bonus.quantity ?? 1) > 1 && <div className="campaign-quantity">{`X${bonus.quantity}`}</div>}
                    </div>

                    <div className="campaing-description">
                        <div className="description-text">
                            {translate(`${bonusLabelByType[bonus.bonusType as string]}`, 'ui.bonus', {
                                amount: bonus.amount || '',
                                minAmount: bonus.minAmount || '',
                                currency: getActiveCurrency(),
                            })}
                        </div>
                        {bonus.expires_at && (
                            <div className="description-remaining flex gap-8">
                                {getRemainingTimeText(bonus.expires_at)}
                            </div>
                        )}
                        <div
                            className="description-more flex gap-8"
                            onClick={() => setIsDescriptionVisible(!isDescriptionVisible)}
                        >
                            <Svg icon="info" size={0.75} /> Learn more
                        </div>
                    </div>
                    {!isBonusEligibleAndSelected(bonus) ? (
                        <UiButton color="primary" size="small" onClick={onBonusToggle}>
                            {translate('Apply?', 'ui.bonus')}
                        </UiButton>
                    ) : (
                        <UiButton size="small" onClick={onBonusToggle}>
                            {translate('Remove', 'ui.bonus')}
                        </UiButton>
                    )}
                </div>
            </UiAnimate>
            {isDescriptionVisible && <SportBonusBetDescription bonus={bonus} />}
        </Wrapper>
    );
}
