import styled from 'styled-components';
import { lighten, transparentize } from 'polished';
import { mediaQuery } from '../../../../../styles/utils';

export default styled.div`
    background: url(${(props) => props.backgroundUrl || ''});
    background-size: cover;
    background-position: center;
    @media ${mediaQuery.isPhone} {
        margin: 0;
        padding: 1rem 0;
        background-position: left;
    }

    .score-board {
        align-items: center;
        background: ${({ theme }) => transparentize(0.2, lighten(0.06, theme.box.background))};
        background-position: center;
        background-size: cover;
        border-radius: var(--ui-radius);
        box-shadow: var(--ui-shadow);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0.8rem auto;
        max-width: 700px;
        padding: 1rem;
        @media ${mediaQuery.isPhone} {
            max-width: 360px;
        }
    }

    .scores {
        align-items: stretch;
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        &.less-margin {
            margin-bottom: 10px;
        }
        .score {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            position: relative;
            width: 30%;
        }
        .score-title {
            font-size: 1rem;
            letter-spacing: 1px;
            text-shadow: 1px 1px #000;
            text-align: center;
        }
        .score-number {
            align-items: center;
            background-color: var(--surface-level-1-bg);
            border-radius: 10px;
            display: flex;
            font-size: 1.5rem;
            font-weight: bold;
            height: 50px;
            justify-content: center;
            width: 40px;
        }
    }

    .timer {
        align-items: center;
        display: flex;
        justify-content: center;
        min-width: 100px;
        padding-top: 50px;
    }
`;
