import React, { useEffect } from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import Wrapper from './styles';
import { getAnnualReport } from '../../../microservices/users';
import { logger } from '../../../services/logger';
import { translate } from '../../../services/translate';
import ResponsibleGamingAnnualReportProduct from './product/ResponsibleGamingAnnualReportProduct';
import UiModal from '../../ui/modal/UiModal';
import UiBox from '../../ui/box/UiBox';
import UiGroup from '../../ui/group/UiGroup';
import UiCloseButton from '../../ui/close-button/UiCloseButton';
import { stores } from '../../../stores';
import UiSubHeading from '../../ui/sub-heading/UiSubHeading';
import { isUserValidForAnnualReport, SHOW_ANNUAL_REPORT } from '../../../services/responsible-gaming';
import { storageGet, storageSet } from '../../../services/storage';
import UiButton from '../../ui/button/UiButton';
import Logo from '../../logo/Logo';
import { PRODUCT } from '../../../types/common';
import { useStore } from '../../../hooks/useStore';

export default function ResponsibleGamingAnnualReport() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [isResponsibleGamingAnnualReportOpen, setIsResponsibleGamingAnnualReportOpen] = useStore(
        stores.modals.isResponsibleGamingAnnualReportOpen,
    );
    const [annualReport, setAnnualReport] = useStore(stores.responsibleGaming.annualReport);
    const [, setIsAnnualReportLoaded] = useStore(stores.responsibleGaming.isAnnualReportLoaded);

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        if (!annualReport && storageGet(SHOW_ANNUAL_REPORT) && isUserValidForAnnualReport()) {
            loadAnnualReport();
        }
    }, [isAuthenticated]);

    async function loadAnnualReport() {
        try {
            const report = await getAnnualReport();
            setIsAnnualReportLoaded(true);
            setAnnualReport(report);
            setIsResponsibleGamingAnnualReportOpen(!isEmpty(report));
        } catch (error) {
            logger.error('ResponsibleGamingAnnualReport', 'loadAnnualReport', error);
            setIsResponsibleGamingAnnualReportOpen(false);
        }
    }

    function onClose() {
        storageSet(SHOW_ANNUAL_REPORT, false);
        setIsResponsibleGamingAnnualReportOpen(false);
    }

    return (
        <UiModal open={isResponsibleGamingAnnualReportOpen} onClose={onClose}>
            <Wrapper>
                <UiBox light>
                    <UiCloseButton onClick={onClose} className="close-button" data-test="close-button" />
                    <div className="annual-report-header">
                        <Logo size={35} />
                        <div>
                            <UiSubHeading className="prompt-title">
                                {translate('Your Gambling Statistics', 'ui.annual-report')}
                            </UiSubHeading>
                            <p>{translate('Last 12 Months', 'ui.annual-report')}</p>
                        </div>
                    </div>
                    <UiGroup expand className="responsible-gaming-options">
                        <UiButton block onClick={onClose} route="responsible-gaming">
                            {translate('Responsible Gaming', 'ui.common')}
                        </UiButton>

                        <UiButton block onClick={onClose} route="responsible-gaming.set-limits">
                            {translate('Set Limits', 'ui.account')}
                        </UiButton>
                    </UiGroup>
                    <ResponsibleGamingAnnualReportProduct report={annualReport} product={PRODUCT.SPORTSBOOK} />
                    <ResponsibleGamingAnnualReportProduct report={annualReport} product={PRODUCT.VIRTUAL_SPORTS} />
                    <ResponsibleGamingAnnualReportProduct report={annualReport} product={PRODUCT.CASINO} />
                    <ResponsibleGamingAnnualReportProduct report={annualReport} product={PRODUCT.POKER} />

                    <UiButton block onClick={onClose}>
                        {translate('ui.common.close')}
                    </UiButton>
                    <br />
                    <p className="center-text">
                        {translate('Last login', 'ui.common')}{' '}
                        {annualReport && moment(annualReport.last_login_time).format('HH:mm DD.MM.YYYY')}
                    </p>
                </UiBox>
            </Wrapper>
        </UiModal>
    );
}
