import React, { useEffect, useState } from 'react';
import {
    onVerifiedUsersStatsUpdate,
    subscribeToVerifiedUsersStats,
    unsubscribeFromVerifiedUsersStats,
} from '../../../microservices/pusher';
import { useSubscription } from '../../../services/subscription';

interface Props {
    countries: string;
}

export default function CmsUserCount({ countries: countriesInput }: Props) {
    const [userCount, setUserCount] = useState<number>();

    const countries = countriesInput ? countriesInput.split(',') : [];

    useEffect(() => {
        subscribeToVerifiedUsersStats();
        return () => {
            unsubscribeFromVerifiedUsersStats();
        };
    }, []);

    useSubscription(onVerifiedUsersStatsUpdate, (userCount) => {
        if (!userCount) {
            return;
        }
        const userCountJson = typeof userCount === 'string' ? JSON.parse(userCount) : userCount;
        if (!countries.length || countries[0] === 'ALL') {
            const country = userCountJson.find((country) => country.country === 'ALL');
            setUserCount(country.users);
        } else {
            let totalUsers = 0;
            userCountJson.forEach((country) => {
                if (countries.includes(country.country)) {
                    totalUsers += country.users;
                }
            });
            setUserCount(totalUsers);
        }
    });

    const formatNumber = (number) => String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return <span>{formatNumber(userCount)}</span>;
}
