import trim from 'lodash/trim';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Development from '../../components/development/Development';
import DevelopmentSettings from '../../components/development/settings/DevelopmentSettings';
import GlobalModal from '../../components/global-modal/GlobalModal';
import MobileApp from '../../components/mobile-app/MobileApp';
import Navigator from '../../components/navigator/Navigator';
import Retail from '../../components/retail/Retail';
import Seo from '../../components/seo/Seo';
import { LiveChatKustomerContextProvider } from '../../contexts/live-chat-kustomer/LiveChatKustomerContext';
import { getRootRoutes } from '../../pages';
import { useMediaQueryHooks } from '../../services/browser';
import { isRetail } from '../../services/environment';
import { getLastActiveProductRoute, useRouter, useRoutes } from '../../services/router';
import { translate } from '../../services/translate';
import { GlobalStyles } from '../../theme/global';
import Wrapper from './styles';
import { isMobileApp } from '../../services/mobile-app';
import { getLicence } from '../../services/licence';
import { stores } from '../../stores';
import { COUNTRY } from '../../services/country';
import AppNotAccessible from '../../components/app-not-accessible/AppNotAccessible';
import { Licence } from '@staycool/location';
import { useStore } from '../../hooks/useStore';

export default function AppPage() {
    const preloader = document.getElementById('preloader');
    if (preloader) {
        preloader.style.display = 'none';
    }
    const { location } = useRouter();
    const [ipCountry] = useStore(stores.ipCountry);
    const [isOfficeIp] = useStore(stores.isOfficeIp);
    const routes = useRoutes(getRootRoutes(false));
    const checkForCmsContent = location.pathname.split('/').length > 2;
    useMediaQueryHooks();

    if (!isOfficeIp && isMobileApp() && getLicence() === Licence.MEXICO && ipCountry !== COUNTRY.MEXICO) {
        return <AppNotAccessible />;
    }

    if (!isRetail()) {
        const trimmedPathName = trim(window.location.pathname, '/');
        if (trimmedPathName.split('/').length <= 1) {
            return <Redirect to={getLastActiveProductRoute()} />;
        }

        return (
            <Wrapper>
                <LiveChatKustomerContextProvider>
                    <GlobalModal>
                        <GlobalStyles />
                        {process.env.NODE_ENV === 'development' && <Development />}
                        {process.env.NODE_ENV === 'development' && <DevelopmentSettings />}
                        <Seo title={translate('seo.home.title')} description={translate('seo.home.desc')} />
                        <Navigator routes={routes} checkForCmsContent={checkForCmsContent} root />
                        <ToastContainer />
                        <MobileApp />
                    </GlobalModal>
                </LiveChatKustomerContextProvider>
            </Wrapper>
        );
    }

    return <Retail />;
}
