import { Autocomplete, TextField } from '@mui/material';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';
import React, { ReactElement, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useUiFormContext } from '../../../../contexts/ui-form/UiFormContext';
import { withForwardedRef } from '../../../../higher-order-components/ref/WithForwardedRef';
import { usePrevious } from '../../../../services/hooks';
import { useInputInteractions } from '../../../../hooks/useInputInteractions';
import { stores } from '../../../../stores';
import UiAlert from '../../alert/UiAlert';
import UiFormGroup from '../group/UiFormGroup';
import UiFormLabel from '../label/UiFormLabel';
import Wrapper from './styles';
import WrapperAutocomplete from './styles-autocomplete';
import WrapperCheckbox from './styles-checkbox';
import WrapperRadio from './styles-radio';
import WrapperSelect from './styles-select';
import WrapperTextarea from './styles-textarea';
import WrapperToggle from './styles-toggle';
import { filterStyleProps } from '../../../../styles/utils';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    autoCompleteOptions?: { id: string; title: string }[];
    onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
    onValueChange?: (value: any) => void;
    onBlur?: (event: Event) => void;
    onFocus?: (event: Event) => void;
    validator?: (value: any) => void;
    round?: number;
    typeNumeric?: boolean;
    typeNumericString?: boolean;
    typeAlphabetical?: boolean;
    typeAlphabeticalOrNumericString?: boolean;
    numberFrom?: number;
    numberTo?: number;
    toggle?: boolean;
    checkbox?: boolean;
    autocompleteSelect?: boolean;
    select?: boolean;
    radio?: boolean;
    textarea?: boolean;
    success?: boolean;
    failure?: boolean;
    warning?: boolean;
    alert?: string;
    label?: string;
    value?: any;
    type?: string;
    id?: string;
    name?: string;
    placeholder?: string;
    autoFocus?: boolean;
    minLength?: number;
    maxLength?: number;
    radioOptions?: any;
    requiresInteraction?: boolean;
    disabled?: boolean;
    exclude?: boolean;
    disableValidation?: boolean;
    disableLiveValidationMessage?: boolean;
    validate?: number;
    nowrap?: boolean;
    min?: number;
    max?: number;
    rows?: number;
    forwardedRef?: any;
    optional?: boolean;
    noAutocomplete?: boolean;
    hideMobileTabOnFocus?: boolean;
    startAdornment?: ReactElement;
    endAdornment?: ReactElement;
    className?: string;
    children?: any;
    defaultNoWrap?: boolean;
    row?: boolean;
    validationDependencies?: any[];
    justify?: 'spaced' | 'center';
    displayDollarSign?: boolean;
    inputmode?: 'numeric' | 'tel' | 'url' | 'decimal' | 'email' | 'search';
}

/**
 * @deprecated The component should be replaced with Ui2FormTextInput, Ui2FormCheckbox, Ui2FormToggle, Ui2FormRadio, Ui2FormSelect
 */
function UiFormInput(props: Props) {
    const {
        autoCompleteOptions = [],
        onChange = () => {},
        onValueChange = () => {},
        onBlur = () => {},
        onFocus = () => {},
        validator,
        round = 0,
        typeNumeric = false,
        typeNumericString = false,
        typeAlphabetical = false,
        typeAlphabeticalOrNumericString = false,
        numberFrom,
        numberTo,
        toggle,
        checkbox,
        autocompleteSelect,
        select,
        radio,
        textarea,
        success,
        failure,
        warning,
        alert = '',
        label,
        value,
        type,
        id,
        name = '',
        placeholder,
        autoFocus = false,
        minLength,
        maxLength,
        radioOptions,
        requiresInteraction = true,
        disabled,
        exclude = false,
        disableValidation = false,
        disableLiveValidationMessage = false,
        validate: externalValidate = 0,
        children,
        className,
        nowrap = false,
        min,
        max,
        rows,
        forwardedRef,
        optional = false,
        noAutocomplete = false,
        hideMobileTabOnFocus = false,
        startAdornment = null,
        endAdornment = null,
        defaultNoWrap = true,
        row = true,
        validationDependencies = [],
        justify,
        displayDollarSign = false,
        inputmode,
    } = props;
    const validatorMethod = useMemo(() => validator || (() => {}), [validator]);
    // validate = externalValidate is for cross-compatibility with old validation functionality.
    // It will be removed once Robert migrates all the UiForm and UiFormInput usages over to new validation functionality.
    const {
        validate = externalValidate,
        isFormLoading,
        announceInput = () => {},
        deRegisterInput = () => {},
        announceInputIsValidatingByName = () => {},
        announceInputErrorStateByName = () => {},
        announceInputValueByName = () => {},
        announceInputInteractedWithByName = () => {},
    } = useUiFormContext() || {};

    const [validationSuccess, setValidationSuccess] = useState('');
    const [validationWarning, setValidationWarning] = useState('');
    const [validationError, setValidationError] = useState('');

    useImperativeHandle(
        forwardedRef,
        () => ({
            setValidationMessage(validationMessage) {
                const { success, warning, error } = validationMessage;

                if (error) {
                    announceInputErrorStateByName(name, Boolean(error));
                }

                setValidationSuccess(success);
                setValidationWarning(warning);
                setValidationError(error);
            },
        }),
        [],
    );

    const [isAutoCompleteOptionsOpen, setIsAutoCompleteOptionsOpen] = useState(false);
    const [hasInputBeenInteractedWith, setHasInputBeenInteractedWith] = useState(false);
    const [isUserInitiatedInteraction, setIsUserInitiatedInteraction] = useState(false);
    const [, setIsInputFocused] = useStore(stores.isInputFocused);
    const { onBlurEvent, onFocusEvent } = useInputInteractions(hideMobileTabOnFocus, onBlur, onFocus);
    const previousValidate = usePrevious(validate);

    useEffect(() => () => deRegisterInput(name), []);

    useEffect(() => {
        const isFormInitiatedInteraction = previousValidate !== undefined && validate !== previousValidate;
        setIsUserInitiatedInteraction(!Boolean(isFormInitiatedInteraction));
    }, [value, validate]);

    useEffect(() => {
        if (exclude) {
            return;
        }

        announceInput(name, value);
    }, []);

    useEffect(() => {
        if (optional) {
            registerInteraction();
        }
    }, [optional, type]);

    useEffect(() => {
        if (!requiresInteraction) {
            registerInteraction();
        }
    }, [requiresInteraction]);

    useEffect(() => {
        setValidationSuccess('');
        setValidationWarning('');
        setValidationError('');

        if (exclude) {
            return;
        }

        if (!isEmpty(value) || value === true) {
            registerInteraction();
        }

        announceInputValueByName(name, value);

        if (autoCompleteOptions.length) {
            if (autoCompleteOptions.map((option) => option.title).includes(value)) {
                setIsAutoCompleteOptionsOpen(false);
            } else {
                setIsAutoCompleteOptionsOpen(!isEmpty(value));
            }
        }
    }, [value]);

    useEffect(() => {
        if ((optional && isEmpty(value)) || exclude || disableValidation) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (validatorMethod && (validate || hasInputBeenInteractedWith)) {
            const timeout = setTimeout(() => validateValue(value), 500);
            return () => clearTimeout(timeout);
        }
    }, [
        exclude,
        disableValidation,
        optional,
        validatorMethod,
        validate,
        hasInputBeenInteractedWith,
        value,
        ...validationDependencies,
    ]);

    const inputRef = useRef();

    useEffect(() => {
        if (inputRef && inputRef.current && noAutocomplete && !isNil(value) && value !== '') {
            // Detect if input was autofilled, then reset the input value
            setImmediate(() => {
                const isAutofilled = (inputRef.current as any).matches(':-webkit-autofill');

                if (isAutofilled) {
                    onValueChange('');
                }
            });
        }
    }, [inputRef, value]);

    const e2eSelector = 'data-test';
    const wrapperProps = { success, failure, warning, className };
    const isInputDisabled = disabled || isFormLoading;

    const propsShared: any = {
        onChange: handleValueChanged,
        onBlur: (event: Event) => {
            onBlurEvent(event);
            setIsInputFocused(false);
        },
        onFocus: (event: Event) => {
            onFocusEvent(event);
            setIsInputFocused(true);
        },
        name,
        placeholder,
        [e2eSelector]: props[e2eSelector],
        autoFocus,
        disabled: isInputDisabled,
        ...(inputmode && { inputmode }),
    };

    if (noAutocomplete) {
        // Browsers usually don't autofill inputs which are meant for new password
        propsShared.autoComplete = 'new-password';
    }

    const alerts =
        disableLiveValidationMessage && isUserInitiatedInteraction ? null : (
            <>
                {success && (
                    <UiAlert success>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: alert,
                            }}
                        />
                    </UiAlert>
                )}
                {failure && (
                    <UiAlert failure>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: alert,
                            }}
                        />
                    </UiAlert>
                )}
                {warning && (
                    <UiAlert warning>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: alert,
                            }}
                        />
                    </UiAlert>
                )}
                {validationSuccess && (
                    <UiAlert success>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: validationSuccess,
                            }}
                        />
                    </UiAlert>
                )}
                {validationWarning && (
                    <UiAlert warning>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: validationWarning,
                            }}
                        />
                    </UiAlert>
                )}
                {validationError && (
                    <UiAlert failure>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: validationError,
                            }}
                        />
                    </UiAlert>
                )}
            </>
        );

    function registerInteraction() {
        if (exclude) {
            return;
        }

        if (!hasInputBeenInteractedWith) {
            setHasInputBeenInteractedWith(true);
            announceInputInteractedWithByName(name);
        }
    }

    function handleValueChanged(event) {
        event.stopPropagation(); // Prevent onChange event from being caught by parent component.
        onChange(event);

        if (select || radio) {
            registerInteraction();
        }

        if (checkbox || toggle) {
            onValueChange(event.target.checked);
        } else {
            onValueChange(handleValueCasting(event.target.value));
        }
    }

    async function validateValue(value) {
        announceInputIsValidatingByName(name, true);

        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/await-thenable
            const validationResponse = (await validatorMethod(value)) || '';

            if (!isPlainObject(validationResponse)) {
                announceInputIsValidatingByName(name, false);
                announceInputErrorStateByName(name, false);
                return;
            }

            const { success, warning, error } = validationResponse as any;

            if (error) {
                announceInputErrorStateByName(name, true);
            } else if (success || warning) {
                announceInputErrorStateByName(name, false);
            }

            setValidationSuccess(success);
            setValidationWarning(warning);
            setValidationError(error);
        } catch (error: any) {
            setValidationError(error.message);
            announceInputErrorStateByName(name, true);
        }

        announceInputIsValidatingByName(name, false);
    }

    function handleValueCasting(value) {
        let castedValue;
        castedValue = typeNumeric ? getNumericValue(value) : value;
        castedValue = typeNumericString ? getNumericStringValue(value) : castedValue;
        castedValue = typeAlphabetical ? getAlphabeticalValue(value) : castedValue;
        castedValue = typeAlphabeticalOrNumericString ? getAlphabeticOrNumericStringValue(value) : castedValue;
        return castedValue;
    }

    function getNumericValue(value) {
        const regex = round ? /[^0-9.]/g : /[^0-9]/g;
        const numericString = String(value).replace(regex, '');

        if (numericString !== '') {
            let number = Number(numericString);

            if (numberFrom && number < numberFrom) {
                number = numberFrom;
            }

            if (numberTo && number > numberTo) {
                number = numberTo;
            }

            if (Number(value) === Number(number.toFixed(round))) {
                return value;
            }
            return number.toFixed(round);
        }

        return '';
    }

    function getNumericStringValue(value) {
        return String(value).replace(/[^0-9]/g, '');
    }

    function getAlphabeticalValue(value) {
        return String(value).replace(/[^A-Za-z -]/g, '');
    }

    function getAlphabeticOrNumericStringValue(value) {
        return String(value).replace(/[^0-9A-Za-z]/g, '');
    }

    if (toggle) {
        const propsToggle = { ...propsShared, checked: Boolean(value) };

        return (
            <WrapperToggle {...filterStyleProps(wrapperProps)} justify={justify}>
                {label && <UiFormLabel>{label}</UiFormLabel>}

                <label className="pseudo-toggle-container">
                    <input {...propsToggle} type="checkbox" />
                    <span className="pseudo-toggle" />
                </label>

                {!isInputDisabled && alerts}
            </WrapperToggle>
        );
    }

    if (checkbox) {
        const propsCheckbox = { ...propsShared, checked: Boolean(value) };

        return (
            <WrapperCheckbox {...filterStyleProps(wrapperProps)}>
                <UiFormLabel>
                    <input {...propsCheckbox} type="checkbox" />
                    <span className="pseudo-checkbox" />
                    {label && <span className="pseudo-label" dangerouslySetInnerHTML={{ __html: label }} />}
                </UiFormLabel>

                {alerts}
            </WrapperCheckbox>
        );
    }
    if (autocompleteSelect) {
        return (
            <WrapperAutocomplete>
                {label && <UiFormLabel>{label}</UiFormLabel>}
                <div>
                    <Autocomplete
                        id={`grouped-${label}`}
                        options={autoCompleteOptions}
                        onChange={(_, value) => onValueChange(value?.title)}
                        classes={{ root: 'autocomplete' }}
                        getOptionLabel={(item) => item.title}
                        renderInput={(params) => <TextField {...params} placeholder={placeholder} variant="outlined" />}
                        ListboxProps={{
                            sx: {
                                backgroundColor: 'var(--surface-level-2-bg)',
                            },
                        }}
                    />
                </div>

                {alerts}
            </WrapperAutocomplete>
        );
    }

    if (radio) {
        return (
            <WrapperRadio>
                <UiFormGroup row={row} nowrap={nowrap}>
                    {label && <UiFormLabel>{label}</UiFormLabel>}

                    {radioOptions.map((option) => (
                        <label key={option.value}>
                            <input
                                {...propsShared}
                                onChange={handleValueChanged}
                                type="radio"
                                value={option.value}
                                checked={option.value === value}
                            />

                            <span className="pseudo-radio" />
                            <span className="pseudo-label">{option.label}</span>
                        </label>
                    ))}
                </UiFormGroup>

                {alerts}
            </WrapperRadio>
        );
    }

    if (select) {
        const propsSelect = { ...propsShared, value };

        return (
            <WrapperSelect {...filterStyleProps(wrapperProps)}>
                {label && <UiFormLabel noWrap={defaultNoWrap}>{label}</UiFormLabel>}
                <div className="select-container">
                    {displayDollarSign && <span className="stake-currency">$</span>}
                    <select className="dropdown" {...propsSelect}>
                        {children}
                    </select>
                </div>

                {alerts}
            </WrapperSelect>
        );
    }

    if (textarea) {
        const propsTextarea = { ...propsShared, value, rows };

        return (
            <WrapperTextarea {...filterStyleProps(wrapperProps)}>
                {label && <UiFormLabel noWrap={defaultNoWrap}>{label}</UiFormLabel>}
                <textarea {...propsTextarea} />
                {alerts}
            </WrapperTextarea>
        );
    }

    const inputProps = { ...propsShared, value, type, minLength, maxLength, min, max, id };

    return (
        <Wrapper {...filterStyleProps(wrapperProps)}>
            {label && <UiFormLabel>{label}</UiFormLabel>}
            {startAdornment || endAdornment ? (
                <div className={classNames('input-wrapper', { disabled: propsShared.disabled })}>
                    {startAdornment && (
                        <div className="input-adornment" onClick={() => (inputRef.current as any).focus()}>
                            {startAdornment}
                        </div>
                    )}
                    <input {...inputProps} ref={inputRef} autoComplete="off" />
                    {endAdornment && (
                        <div className="input-adornment" onClick={() => (inputRef.current as any).focus()}>
                            {endAdornment}
                        </div>
                    )}
                </div>
            ) : (
                <input {...inputProps} ref={inputRef} autoComplete="off" />
            )}
            {isAutoCompleteOptionsOpen && (
                <ul className="autocomplete-options">
                    {autoCompleteOptions
                        .filter((option) => option.title.toLowerCase().includes(value.toLowerCase()))
                        .slice(0, 10)
                        .map((option) => (
                            <li key={option.id} onClick={() => onValueChange(option.title)}>
                                {option.title}
                            </li>
                        ))}
                </ul>
            )}
            {alerts}
        </Wrapper>
    );
}

export default withForwardedRef<Props>(UiFormInput);
