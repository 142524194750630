import styled from 'styled-components';

import { lighten } from 'polished';

export default styled.div`
    border-radius: 20px;
    background-color: var(--surface-level-2-bg);
    margin: 0 10px;
    position: relative;

    .casino-game-info-content {
        padding: 20px;
        position: relative;

        @media (max-width: 500px) {
            padding: 20px 10px;
        }
    }
    .casino-game-info-image {
        width: 200px;
        height: 200px;
        background-size: cover;
        border-radius: 10px;
        animation: popIn 0.4s;
    }

    .casino-game-info-image-container {
        margin-top: -40px;
        display: flex;
        justify-content: center;
        position: relative;
    }

    .casino-game-info-image-logo-promotion {
        position: absolute;
        max-width: 35%;
        max-height: 35%;
        top: 0;
        left: 0;
    }

    .casino-game-info-image-logo-provider {
        position: absolute;
        max-width: 40%;
        max-height: 40%;
        top: 0;
        right: 0;
    }

    .casino-game-info-content-main {
        display: flex;
        flex-wrap: nowrap;
        z-index: 2;
        position: relative;

        @media (max-width: 600px) {
            flex-direction: column;

            .casino-game-info-buttons-container {
                padding: 20px 0 0 0;
            }
        }
    }

    .casino-game-info-description {
        padding: 20px 20px;
        background-color: #292b38;
        border-radius: 0 0 18px 18px;
        display: flex;
        justify-content: center;
        color: #c5c5c5;
        font-weight: 700;
        font-size: 13px;
        line-height: 1.5;
    }

    .casino-game-info-buttons-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 20px;
    }

    .casino-game-info-buttons-play {
        border-radius: 30px;
        height: 60px;
        font-size: 24px;
        font-weight: 700;
        background-color: hsl(158, 95%, 35%);
        color: white;
        min-width: 220px;
        margin-bottom: 6px;
        padding: 0 40px;
        animation: popIn 0.2s;

        &:hover {
            background-color: hsl(158, 100%, 40%);
        }

        .button-text {
            text-transform: unset;
        }
    }

    .casino-game-info-buttons-demo {
        min-width: 100px;
        height: 30px;
        border-radius: 15px;
        padding: 0 20px;
        font-size: 12px;
        animation: popIn 0.3s;
        color: white;

        .button-text {
            text-transform: unset;
        }
    }

    .casino-game-info-content-background-container {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        border-radius: 20px 20px 0 0;
        overflow: hidden;
    }

    .casino-game-info-content-background {
        background-size: cover;
        background-position: center;
        filter: blur(5px);
    }

    .casino-game-info-content-stats {
        display: flex;
        flex-wrap: wrap;
        z-index: 2;
        position: relative;
        padding: 30px 10px 10px 10px;
        justify-content: center;
    }

    .casino-game-info-content-stats-container {
        flex: 1 0 150px;
        padding: 10px;
        animation: popIn 0.2s;
        animation-fill-mode: both;

        &:nth-child(2) {
            animation-delay: 0.1s;
        }
        &:nth-child(3) {
            animation-delay: 0.15s;
        }
        &:nth-child(4) {
            animation-delay: 0.2s;
        }
        &:nth-child(5) {
            animation-delay: 0.25s;
        }
        &:nth-child(6) {
            animation-delay: 0.3s;
        }
        &:nth-child(7) {
            animation-delay: 0.1s;
        }
        &:nth-child(8) {
            animation-delay: 0.15s;
        }
        &:nth-child(9) {
            animation-delay: 0.2s;
        }
    }

    .casino-game-info-content-stats-element {
        background-color: #292b38;
        border-radius: 20px;
        padding: 6px 20px;
        text-align: center;
        position: relative;
    }

    .casino-game-info-content-stats-name {
        color: #c5c5c5;
        font-size: 11px;
        font-weight: 700;
    }

    .casino-game-info-rtp-comparison-list-element {
        display: flex;
    }

    .casino-game-info-rtp-comparison-flag {
        padding: 0px 5px;
    }

    .casino-game-info-rtp-comparison-list {
        margin: 10px auto 10px;
    }
    .casino-game-info-rtp-comparison-text {
        text-align: center;
    }

    .casino-game-info-rtp-comparison {
        font-weight: normal;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        color: var(--color-highlight);
    }

    .casino-game-info-content-stats-data {
        color: white;
        font-size: 15px;
        font-weight: 700;
    }

    .casino-game-info-content-more {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: center;
    }

    .casino-game-info-content-more-icon {
        width: 40px;
        height: 40px;
        padding: 10px;
        color: #c5c5c5;
        background-color: #292b3899;
        cursor: pointer;
        border-radius: 10px 10px 22px 22px;
        transition: background-color 0.2s, color 0.2s, transform 0.2s;

        &.open {
            transform: rotate(180deg);
        }

        &:hover {
            background-color: #292b38;
            color: white;
        }
    }

    .casino-game-info-content-stats-special {
        width: 41px;
        height: 41px;
        padding: 10px;
        position: absolute;
        top: 0;
        right: 0;
        color: #c5c5c5;
        transition: color 0.2s;

        &:hover {
            color: white;
        }
    }

    .casino-game-info-close {
        width: 50px;
        height: 50px;
        padding: 10px;
        background-color: #292b38;
        border-radius: 0 18px 0 20px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
        color: #c5c5c5;
        transition: background-color 0.2s, color 0.2s;
        cursor: pointer;

        &:hover {
            color: white;
            background-color: ${lighten(0.05, '#292b38')};
        }
    }

    @keyframes popIn {
        from {
            transform: scale(0);
        }
        to {
            transform: scale(1);
        }
    }
`;
