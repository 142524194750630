import styled from 'styled-components';

export default styled.div`
    .login-content {
        margin-top: 85px;
        padding-top: var(--spacing-16);
    }

    .login-header {
        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        top: 0;
        width: 100%;
        left: 0;

        padding: 2rem;
        background-color: var(--color-header-bg);
    }

    .register {
        display: block;
    }

    .logo-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1.6rem 0 0.8rem 0;
    }

    .welcome-back {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        color: var(--font-color-subtle);
        font-size: 0.8rem;
        font-weight: bold;
        line-height: 1;
        text-transform: uppercase;
        text-align: right;
    }

    .welcome-user {
        color: var(--color-highlight);
        font-size: 0.8rem;
        text-transform: none;
        margin-bottom: 0.4rem;
    }

    .login-pnp-header,
    .login-pnp-footer {
        color: var(--font-color-secondary);
    }

    .login-pnp-footer {
        font-size: 0.8rem;
        font-style: italic;
        margin-top: 1rem;
        text-align: center;
    }
    .bg {
        content: '';
        background: var(--surface-level-1-bg) url(/assets/images/turnover/coolbet-logo-45deg-dark.png) repeat 0 -40px;
        background-size: 35%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        animation: bg-slide 55s linear infinite;
        height: 200%;
        width: 100%;
    }
    @keyframes bg-slide {
        from {
            transform: translateY(-50%);
        }
        to {
            transform: translateY(0%);
        }
    }
`;
