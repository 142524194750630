import styled, { css } from 'styled-components';

type StyledProps = {
    $isRetailLayout?: boolean;
    $isCompact?: boolean;
    $isAmericanCompactLayout?: boolean;
};

export default styled.div<StyledProps>`
    .match-score {
        position: relative;

        display: flex;
        align-items: center;
        gap: var(--spacing-4);
        justify-content: center;

        text-align: center;

        margin: ${({ $isRetailLayout }) => ($isRetailLayout ? '0 var(--spacing-12)' : '')};

        ${({ $isCompact }) =>
            $isCompact &&
            css`
                flex-direction: column;
            `};

        &.sets {
            white-space: nowrap;
            .score {
                min-width: 1.2rem;
            }
        }
        .home-scores,
        .away-scores {
            position: relative;
        }
        .score-box {
            display: inline-block;
            ${({ $isAmericanCompactLayout }) =>
                $isAmericanCompactLayout &&
                css`
                    display: flex;
                    align-items: center;
                `};
        }
        .score {
            display: flex;
            align-items: center;
            justify-content: center;

            height: 1.25rem;
            min-width: 1.25rem;

            padding: 0 0.2rem;
            margin: 0 0.1rem;

            background-color: var(--match-score-bg);
            border: var(--match-score-border);
            border-radius: var(--match-score-radius);

            font-size: 0.75rem;
            color: var(--match-score-text-color);
            line-height: 1;
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};
        }
    }
    .score-divider {
        margin: 0 0.4rem;
    }
    .score-description {
        position: absolute;
        bottom: 100%;

        font-size: 0.55rem;
        text-transform: uppercase;
        text-align: center;

        padding: 0 0.25rem;

        display: flex;
        align-items: center;
        width: 100%;
        height: 0.8rem;
        > * {
            flex: 1;
        }
    }
`;
