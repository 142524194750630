import styled from 'styled-components';

export default styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--spacing-8);
    .cards-left-text {
        margin-top: var(--spacing-4);
        color: var(--match-score-text-color);
        font-size: 0.625rem;
    }
    .selection-btn,
    .selection-btn-active {
        width: 9.7rem;
        height: 2.5rem;
        border: var(--odds-border);
        border-radius: var(--odds-radius);
        font-weight: var(--font-weight-bold);
        .btn-text {
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            .original-odds {
                color: var(--odds-line-text);
                font-size: 0.875rem;
                text-decoration: line-through;
            }
            .arrow {
                margin: 0rem 0.5rem 0.2rem;
                color: var(--odds-line-text);
                font-size: 1.5rem;
            }
            .boosted-odds {
                font-size: 1.25rem;
            }
        }
    }
    .selection-btn {
        background-color: var(--odds-bg);
        .boosted-odds {
            color: var(--color-odds);
        }
    }
    .selection-btn-active {
        background-color: var(--odds-bg-active);
        .boosted-odds {
            color: var(--odds-text-active);
        }
        cursor: default;
    }
`;
