import React from 'react';
import Wrapper from './styles';
import { CasinoGameVolatility } from '../../../../../services/casino/games';
import classNames from 'classnames';
import { translate } from '../../../../../services/translate';
import Tooltip from '@mui/material/Tooltip';

interface Props {
    volatility: CasinoGameVolatility;
}

export default function CasinoThumbnailLabelsVolatility({ volatility }: Props) {
    if (!volatility) {
        return null;
    }

    return (
        <Wrapper>
            <Tooltip
                title={
                    <div style={{ whiteSpace: 'pre-line', fontSize: '11px' }}>{`${translate(
                        'Volatility',
                        'casino.thumbnail',
                    )} - ${translate(volatility, 'casino.volatility')}`}</div>
                }
                enterDelay={200}
                enterTouchDelay={0}
            >
                <div className={classNames('dot-container', volatility.toLowerCase())}>
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                </div>
            </Tooltip>
        </Wrapper>
    );
}
