import orderBy from 'lodash/orderBy';
import React, { useEffect, useState } from 'react';
import { fetchRegistrationCountries } from '../../microservices/auth';
import { Contact, ContactField, createContact, Event, getEventById } from '../../microservices/contact-form';
import { getArticleById } from '../../microservices/cms';
import { logger } from '../../services/logger';
import { COUNTRY } from '../../services/country';
import { translate } from '../../services/translate';
import { stores } from '../../stores';
import UiBox from '../ui/box/UiBox';
import UiButton from '../ui/button/UiButton';
import UiDotsLoader from '../ui/dots-loader/UiDotsLoader';
import UiFormInputRaw from '../ui/form/input/UiFormInput';
import UiFormLabel from '../ui/form/label/UiFormLabel';
import UiGroup from '../ui/group/UiGroup';
import { environment } from '../../stores/environment/environment';
import { media } from '../../stores/media/media';
import { useStore } from '../../hooks/useStore';

interface Props {
    eventId: number;
}

export default function ContactForm({ eventId }: Props) {
    const [{ ROOT_URL }] = useStore(environment);
    const [language] = useStore(stores.language);
    const [ipCountry] = useStore<COUNTRY>(stores.ipCountry);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errors, setErrors] = useState<{ [key in ContactField]?: string }>({});
    const [event, setEvent] = useState<Event>();
    const [article, setArticle] = useState<any>();
    const [contact, setContact] = useState<Partial<Contact>>({ eventId });
    const [selectedPhonePrefix, setSelectedPhonePrefix] = useState<string>();
    const [phonePrefixes, setPhonePrefixes] = useState<string[]>([]);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [{ isPhone }] = useStore(media);

    useEffect(() => {
        loadPhonePrefixes();
    }, []);

    useEffect(() => {
        loadEvent(eventId);
    }, [eventId]);

    useEffect(() => {
        if (event) {
            loadArticle(event.termsArticleId);
        }
    }, [event]);

    async function loadPhonePrefixes() {
        let registrationCountries;
        try {
            const response = await fetchRegistrationCountries();
            registrationCountries = orderBy(response.registrationCountries, (country) => country.code !== ipCountry);
        } catch (error) {
            logger.error('ContactForm', 'loadPhonePrefixes', error);
            return;
        }

        const phonePrefixes = registrationCountries.map((country) => country.phonePrefix);
        setPhonePrefixes(phonePrefixes);
        setSelectedPhonePrefix(phonePrefixes[0]);
    }

    async function loadEvent(id: number) {
        setIsLoading(true);
        try {
            const event = await getEventById(id);
            setEvent(event);
        } catch (error) {
            logger.error('ContactForm', 'loadEvent', error);
        }
    }

    async function loadArticle(id: string) {
        try {
            const article = await getArticleById(id);
            setArticle(article);
        } catch (error) {
            logger.error('ContactForm', 'loadArticle', error);
        }
        setIsLoading(false);
    }

    async function submitContact() {
        setErrors({});
        try {
            await createContact({ ...contact, phone: contact.phone && `${selectedPhonePrefix}${contact.phone}` });
            setIsSubmitted(true);
        } catch (error: any) {
            if (error.details) {
                setErrors(error.details);
            } else {
                logger.error('ContactForm', 'submitContact', error);
            }
        }
    }

    let content;
    if (isLoading) {
        content = <UiDotsLoader />;
    } else if (!event || !article) {
        content = <span>{translate('Event not found', 'ui.contact-form')}</span>;
    } else if (!isSubmitted) {
        const slug = article.slug[language];

        const hasField = event.fields.reduce(
            (map, field) => ({ ...map, [field]: true }),
            {} as { [key in ContactField]: boolean },
        );

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const UiFormInput = UiFormInputRaw as any;

        content = (
            <>
                {(hasField.firstName || hasField.lastName) && (
                    <UiGroup expand>
                        {hasField.firstName && (
                            <UiFormInput
                                name="firstName"
                                label={translate('First name', 'ui.account')}
                                placeholder={translate('First name', 'ui.account')}
                                value={contact.firstName}
                                onValueChange={(firstName) => setContact({ ...contact, firstName })}
                                failure={errors.firstName}
                                disableLiveValidationMessage
                            />
                        )}

                        {hasField.lastName && (
                            <UiFormInput
                                name="lastName"
                                label={translate('Last name', 'ui.account')}
                                placeholder={translate('Last name', 'ui.account')}
                                value={contact.lastName || ''}
                                onValueChange={(lastName) => setContact({ ...contact, lastName })}
                                failure={errors.lastName}
                                disableLiveValidationMessage
                            />
                        )}
                    </UiGroup>
                )}
                {hasField.email && (
                    <UiFormInput
                        name="email"
                        label={translate('Email', 'ui.account')}
                        placeholder={translate('Email', 'ui.account')}
                        value={contact.email || ''}
                        onValueChange={(email) => setContact({ ...contact, email })}
                        failure={errors.email}
                        disableLiveValidationMessage
                    />
                )}
                {hasField.phone && (
                    <>
                        <UiFormLabel>{translate('Mobile number', 'ui.account')}</UiFormLabel>

                        <UiGroup expand>
                            <UiFormInput
                                select
                                value={selectedPhonePrefix}
                                onValueChange={(prefix) => setSelectedPhonePrefix(prefix)}
                            >
                                {phonePrefixes.map((prefix) => (
                                    <option key={prefix} value={prefix}>
                                        {prefix}
                                    </option>
                                ))}
                            </UiFormInput>

                            <UiFormInput
                                name="phone"
                                type="tel"
                                typeNumeric
                                placeholder={translate('Mobile number', 'ui.account')}
                                value={contact.phone}
                                onValueChange={(phone) => setContact({ ...contact, phone })}
                                failure={errors.phone}
                                disableLiveValidationMessage
                            />
                        </UiGroup>
                    </>
                )}
                {hasField.answer && (
                    <UiFormInput
                        textarea
                        rows={3}
                        name="answer"
                        label={translate('Answer', 'ui.account')}
                        value={contact.answer}
                        onValueChange={(answer) => setContact({ ...contact, answer })}
                        failure={errors.answer}
                        disableLiveValidationMessage
                    />
                )}
                {hasField.answer2 && (
                    <UiFormInput
                        textarea
                        rows={3}
                        name="answer2"
                        label={translate('Answer 2', 'ui.account')}
                        value={contact.answer2}
                        onValueChange={(answer2) => setContact({ ...contact, answer2 })}
                        failure={errors.answer2}
                        disableLiveValidationMessage
                        defaultNoWrap={!isPhone}
                    />
                )}
                {hasField.acceptTerms && (
                    <UiFormInput
                        checkbox
                        label={translate(
                            'I agree to the <a target="_blank" href="{{ link }}">Terms</a>',
                            'ui.contact-form',
                            {
                                link: `${ROOT_URL}/${language}/${slug}`,
                            },
                        )}
                        value={contact.acceptTerms}
                        onValueChange={(acceptTerms) => setContact({ ...contact, acceptTerms })}
                        failure={errors.acceptTerms}
                        disableLiveValidationMessage
                    />
                )}
                <UiButton color="primary" block onClick={submitContact}>
                    {translate('Submit', 'ui.account')}
                </UiButton>
            </>
        );
    } else {
        content = <div dangerouslySetInnerHTML={{ __html: translate('Thank you', 'ui.contact-form') }} />;
    }

    return <UiBox isCompactView>{content}</UiBox>;
}
