import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { COUNTRY } from './country';
import { getUserCountry } from './user';

export function isStreamAllowed(matchCategory) {
    // TODO: Implement proper match stream country restrictions that can be set up in backoffice
    const PREMIUM_LEAGUE_CATEGORY = 18925;
    const ipCountry = getStoreValue(stores.ipCountry);
    const userCountry = getUserCountry();

    return (
        matchCategory !== PREMIUM_LEAGUE_CATEGORY || ipCountry === COUNTRY.ESTONIA || userCountry === COUNTRY.ESTONIA
    );
}

export const streamProvider = {
    TWITCH: 'TWITCH',
    YOUTUBE: 'YOUTUBE',
    VIMEO: 'VIMEO',
};
