import React from 'react';
import Wrapper from './styles';
import UiNavLink from '../../ui/nav-link/UiNavLink';
import { getRoute } from '../../../services/router';
import { translate } from '../../../services/translate';
import readingTime from 'reading-time';
import { BlogPost, getExcerpt } from '../../../services/cms-blog';
import { DATE_YEAR_FORMAT, getFormattedDate } from '../../../services/date';

interface Props {
    article: BlogPost;
    excerptLength?: number;
    isHighlightedArticle?: boolean;
}

export default function BlogPreview({ article, isHighlightedArticle, excerptLength }: Props) {
    function getReadingTime() {
        const stats = readingTime(article.body);
        return Math.ceil(stats.minutes);
    }

    return (
        <Wrapper isHighlightedArticle={isHighlightedArticle}>
            <UiNavLink to={`${getRoute('blog')}/${article.slug}`}>
                <div
                    className="article-preview"
                    dangerouslySetInnerHTML={{ __html: getExcerpt(article.body, excerptLength) }}
                />
                <div className="reading-time">
                    {translate('{{ readingDuration }} min read', 'ui.blog.categories', {
                        readingDuration: getReadingTime(),
                    })}
                </div>
                <div className="post-creation-date">
                    <span>
                        {getFormattedDate({
                            date: article.created,
                            format: DATE_YEAR_FORMAT,
                            useDots: true,
                        })}
                    </span>
                </div>
            </UiNavLink>
        </Wrapper>
    );
}
