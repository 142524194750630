import React, { useEffect, useMemo, useRef, useState } from 'react';
import Wrapper from './styles';
import classNames from 'classnames';
import { stores } from '../../../stores';
import { getClient, isB2B, isBYOD } from '../../../services/environment';
import { translate } from '../../../services/translate';
import { getRoute } from '../../../services/router';
import UiBottomNavigationTab from './tab/UiBottomNavigationTab';
import { AppType } from '../../../services/mobile-app';
import { isProduction } from '../../../services/util';
import { FEATURE, isFeatureAvailable } from '../../../services/feature';
import UiModal from '../modal/UiModal';
import SportProductLauncher from '../../sport/product-launcher/SportProductLauncher';
import compact from 'lodash/compact';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../services/analytics';
import { ClientName } from '../../../services/utils/types';
import { useScrollDirectionEvents } from '../../../hooks/useScrollDirectionEvents';
import { useStore } from '../../../hooks/useStore';

function UiBottomNavigation() {
    const [appType] = useStore(stores.appType);
    const [isGameLauncherOpen, setIsGameLauncherOpen] = useState(false);
    const navigationWrapperRef = useRef<HTMLDivElement>(null);

    let isCasinoLiveAvailable = true;
    if (isProduction() && appType === AppType.IOS) {
        isCasinoLiveAvailable = false;
    }

    function scrollUpHandler() {
        navigationWrapperRef.current?.classList.remove('fadeOutDown');
    }
    function scrollDownHandler() {
        if (!isB2B()) {
            navigationWrapperRef.current?.classList.add('fadeOutDown');
        }
    }

    useScrollDirectionEvents();

    useEffect(() => {
        window.addEventListener('scrollUp', scrollUpHandler);
        window.addEventListener('scrollDown', scrollDownHandler);

        return () => {
            window.removeEventListener('scrollUp', scrollUpHandler);
            window.removeEventListener('scrollDown', scrollDownHandler);
        };
    }, []);

    function openGameLauncher() {
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.GAME_LAUNCHER_OPENED);
        setIsGameLauncherOpen(true);
    }

    const productLinks = useMemo(
        () =>
            compact(
                [
                    {
                        label: translate('Sports', 'ui.header'),
                        route: getRoute('sport'),
                        icon: 'sports-ico',
                        isAvailable: true,
                    },
                    {
                        label: translate('Horse Racing', 'ui.header'),
                        route: getRoute('horse-racing'),
                        icon: 'horse-racing-ico',
                        isAvailable: isFeatureAvailable(FEATURE.HORSE_RACING),
                    },
                    {
                        label: translate('Casino', 'casino.ui'),
                        route: getRoute('casino.slots'),
                        icon: 'casino-lobby',
                        isAvailable: isFeatureAvailable(FEATURE.CASINO),
                    },
                    {
                        label: translate('Live Casino', 'casino.ui'),
                        route: appType !== AppType.IOS ? getRoute('casino.live') : '',
                        icon: 'casino-live',
                        isAvailable: isCasinoLiveAvailable && isFeatureAvailable(FEATURE.CASINO),
                    },
                    {
                        label: translate('Poker', 'casino.ui'),
                        route: getRoute('poker'),
                        icon: 'poker-ico',
                        isAvailable: isFeatureAvailable(FEATURE.POKER),
                    },
                ].filter((link) => link.isAvailable),
            ),
        [],
    );

    const mixedLinks = useMemo(
        () =>
            compact(
                [
                    {
                        label: translate('Sports', 'ui.header'),
                        route: getRoute('sport'),
                        icon: 'sports-ico',
                        isAvailable: true,
                    },
                    {
                        label: translate('Horse Racing', 'ui.header'),
                        route: getRoute('horse-racing'),
                        icon: 'horse-racing-ico',
                        isAvailable: isFeatureAvailable(FEATURE.HORSE_RACING),
                    },
                    {
                        label: translate('Bet history', 'ui.account'),
                        route: getRoute('bet-history'),
                        icon: 'bethistory-ico',
                        isAvailable: !isBYOD(),
                    },
                    {
                        label: translate('Promotions', 'ui.header'),
                        route: getRoute('promotions'),
                        icon: 'promotions-ico',
                        isAvailable: !isBYOD(),
                    },
                    {
                        label: translate('Deposit', 'ui.common'),
                        route: getRoute('deposit'),
                        icon: 'deposit-ico',
                        isAvailable: !isBYOD(),
                    },
                ].filter((link) => link.isAvailable),
            ),
        [],
    );

    const client = getClient();

    const linksByClient = {
        [ClientName.COOLBET]: productLinks,
        [ClientName.STATION]: mixedLinks,
        [ClientName.WYNNBET_NEVADA]: mixedLinks,
    };

    function getLayout(clientName) {
        if (clientName in linksByClient) {
            return linksByClient[clientName];
        } else {
            return productLinks;
        }
    }

    if (productLinks.length < 2) {
        return null;
    }

    return (
        <Wrapper ref={navigationWrapperRef} className={classNames('animated fadeInUp')}>
            <div className="mobile-tabs">
                {getLayout(client).map((link) => (
                    <UiBottomNavigationTab key={link.route} to={link.route} icon={link.icon} label={link.label} />
                ))}

                {productLinks.length > 3 && (
                    <UiBottomNavigationTab
                        label={translate('More', 'ui.header')}
                        icon="more-ico"
                        onClick={openGameLauncher}
                    />
                )}
            </div>

            <UiModal
                open={isGameLauncherOpen}
                onClose={() => setIsGameLauncherOpen(false)}
                onOpen={() => setIsGameLauncherOpen(true)}
                mode="drawer"
            >
                <div className="game-launcher-content">
                    <SportProductLauncher onClose={() => setIsGameLauncherOpen(false)} />
                </div>
            </UiModal>
        </Wrapper>
    );
}

export default UiBottomNavigation;
