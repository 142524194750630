import React, { useState, useMemo, useEffect } from 'react';
import Wrapper from './styles';
import { stores } from '../../../../stores';
import {
    CasinoGame,
    isCasinoGameDisabled,
    getCasinoGameRoute,
    getGameImageUrl,
    navigateToCasinoGamePage,
} from '../../../../services/casino/games';
import { useIsTestUser } from '../../../../services/user';
import UiNavLink from '../../../ui/nav-link/UiNavLink';
import UiImageLoaderBackground from '../../../ui/image-loader/background/UiImageLoaderBackground';
import { translate } from '../../../../services/translate';
import CasinoPaybackBoosterGamesModal from './modal/CasinoPaybackBoosterGamesModal';
import { initCasino } from '../../../../services/casino/init';
import { useRouter } from '../../../../services/router';
import { GameLaunchSource } from '../../../../services/casino/types';
import { PaybackBoosterProgramGames } from '../../../../services/casino/payback-booster';
import orderBy from 'lodash/orderBy';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    serverGameIds: string[];
    extraPayback?: {
        [game: string]: number;
    };
    maxGames: number;
    isSideBar?: boolean;
    isCasinoLocked?: boolean;
}

export default function CasinoPaybackBoosterGames({
    serverGameIds,
    extraPayback,
    maxGames,
    isSideBar = false,
    isCasinoLocked = false,
}: Props) {
    const isTestUser = useIsTestUser();
    const [isOpen, setIsOpen] = useState(false);
    const { navigateTo } = useRouter();
    const [gamesByServerGameId] = useStore<{ [key: string]: CasinoGame }>(stores.casino.gamesByServerId);
    const [isPaybackBoosterCmsView] = useStore(stores.casino.isPaybackBoosterCmsView);
    const [[program]] = useStore<PaybackBoosterProgramGames[]>(stores.paybackBooster.programGames);

    const games = useMemo(() => getLatestReleasedProgramServerGameIds(), [serverGameIds, gamesByServerGameId]);

    useEffect(() => {
        if (isPaybackBoosterCmsView && !Object.keys(gamesByServerGameId).length) {
            initCasino();
        }
    }, []);

    function openGame(event, game: CasinoGame) {
        event.preventDefault();
        // TODO: move setting source below game disabled check after removing test user check
        const launchSource: GameLaunchSource = `PAYBACK_BOOSTER_${isSideBar ? 'INGAME' : 'LOBBY'}` as const;
        stores.casino.latestGameLaunchPosition.set({ launchSource });
        if (isCasinoGameDisabled(game) && !isTestUser) {
            return;
        }
        navigateToCasinoGamePage(navigateTo, game);
        setIsOpen(false);
    }

    function getLatestReleasedProgramServerGameIds() {
        const promotedGames = serverGameIds
            .filter((gameId) => gamesByServerGameId[gameId])
            .map((gameId) => gamesByServerGameId[gameId]);

        let programServerGameIds: string[] = [];
        if (program?.cashbackSettings?.extraPayback) {
            programServerGameIds = Object.keys(program.cashbackSettings.extraPayback);
        }
        const programGames: CasinoGame[] = orderBy(
            programServerGameIds.reduce((prevGameIds: CasinoGame[], gameId) => {
                const game = gamesByServerGameId[gameId];
                if (game) {
                    prevGameIds.push({ ...game });
                }
                return prevGameIds;
            }, []),
            (game) => game.releaseDate || '',
            'desc',
        )
            .filter((game) => {
                const isExistInPromotedGames = promotedGames.some((promotedGame) => {
                    return promotedGame.serverGameId === game.serverGameId;
                });
                return !isExistInPromotedGames;
            })
            .slice(0, maxGames);

        return [...promotedGames, ...programGames];
    }

    return (
        <Wrapper isCasinoLocked={isCasinoLocked}>
            <div className="games-container">
                {games.slice(0, maxGames).map((game) => (
                    <UiNavLink
                        key={`games-thumbnail-${game.id}`}
                        to={`${getCasinoGameRoute(game)}`}
                        onClick={(event) => openGame(event, game)}
                    >
                        <div className="game-image-container">
                            <UiImageLoaderBackground
                                className="game-image"
                                imageUrl={getGameImageUrl(game.imageName, 120)}
                            />
                            {extraPayback && game.serverGameId && !isCasinoLocked && (
                                <div className="extra-payback-footer">
                                    {translate('+%1% / spin', 'casino.payback-booster', [
                                        extraPayback[game.serverGameId]?.toFixed(2) || 0,
                                    ])}
                                </div>
                            )}
                        </div>
                    </UiNavLink>
                ))}
            </div>
            {games.length > maxGames && (
                <p
                    className="show-more-button"
                    onClick={() => {
                        setIsOpen(true);
                    }}
                >
                    {translate('More games', 'ui.casino')}
                </p>
            )}
            <CasinoPaybackBoosterGamesModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
        </Wrapper>
    );
}
