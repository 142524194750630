import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../../../../styles/utils';

type StyledProps = {
    isCasinoLocked: boolean;
};
export default styled.div<StyledProps>`
    align-items: center;
    border-radius: var(--ui-radius);
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 15px;
    width: 400px;

    @media ${mediaQuery.isTablet}, ${mediaQuery.isPhone} {
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        width: 100%;
    }

    @media (max-width: 350px) {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
    }
    ${({ isCasinoLocked }) =>
        !isCasinoLocked &&
        css`
            .activate-button > button {
                color: ${({ theme }) => theme.paybackBooster.primaryColor};
                border: ${({ theme }) => theme.paybackBooster.primaryColor} 2px solid;
                background: #fcbe0024;
            }
        `}
    .activate-button > button {
        border-radius: var(--ui-radius);
        margin-left: 0.5rem;
        font-weight: var(--font-weight-bold);
        box-shadow: none;
    }
`;
