import React from 'react';
import PokerPlay from '../play/PokerPlay';
import { POKER_CLIENT_TYPE } from '../../../services/poker';
import Wrapper from './styles';
import { FEATURE, isFeatureAvailable } from '../../../services/feature';

interface Props {
    showFeatures?: boolean;
}

export default function PokerInstantPlay({ showFeatures }: Props) {
    if (!isFeatureAvailable(FEATURE.POKER)) {
        return null;
    }

    return (
        <Wrapper $showFeatures={showFeatures} className="poker-instant-legacy">
            <PokerPlay type={POKER_CLIENT_TYPE.HTML5} showFeatures={showFeatures} />
        </Wrapper>
    );
}
