import React from 'react';
import { GeoComplyMobileTrueLocationBrowserHint } from '../../../../../../services/geocomply/mobile';
import { translate } from '../../../../../../services/translate';
import UiButton from '../../../../../ui/button/UiButton';
import UiGroup from '../../../../../ui/group/UiGroup';

interface Props {
    hint: GeoComplyMobileTrueLocationBrowserHint;
    onDismiss: () => void;
}

export default function GeocomplyMobileHintHandlerTrueLocationBrowser({ hint, onDismiss }: Props) {
    function openTrueLocationBrowser() {
        window.open(hint.link, '_self');
        onDismiss();
    }

    return (
        <UiGroup vertical>
            <UiButton onClick={openTrueLocationBrowser} color="primary">
                {translate('Open True Location Browser', 'ui.geocomply')}
            </UiButton>
        </UiGroup>
    );
}
