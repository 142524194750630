import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: column;
    height: 4.8rem;
    padding: var(--general-ui-spacing-radius-spacing-12, 0.75rem) var(--general-ui-spacing-radius-spacing-16, 1rem);
    border-radius: var(--ui-radius) var(--ui-radius) 0 0;
    align-self: stretch;
    justify-content: center;
    gap: var(--spacing-4);

    .combo-card-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .combo-name {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            font-size: var(--font-14);
            font-weight: var(--match-name-weight);
            ${({ theme }) => theme.optimizeFontRendering};
        }
        .card-outcomes-count {
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            min-width: 1.4rem;
            min-height: 1.4rem;
            border-radius: 6.25rem;
            border: 1px solid white;
            font-size: var(--font-12);
            font-weight: 700;
        }
    }
`;
