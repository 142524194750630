import { storageGet } from '../services/storage';
import { COUNTRY } from '../services/country';
import { User } from '../services/types';
import { Language } from '../services/language';
import { createStores } from './store/utils';
import { Currency } from '../services/wallet/types';

export const registration = createStores({
    errors: undefined as Record<string, { error: string }> | undefined,
    form: {
        acceptTermsAndConditions: Boolean(storageGet('terms')),
        address: storageGet<string>('address', ''),
        address2: storageGet<string>('address2', ''),
        ageConfirm: false,
        alias: storageGet<string>('alias', ''),
        birthDate: storageGet<{ day: number; month: number; year: number }>('birthDate') || undefined,
        city: storageGet<string>('city', ''),
        country: COUNTRY.ESTONIA,
        currency: Currency.EUR,
        depositLimitAmount: storageGet('depositLimitAmount', ''),
        depositLimitPeriod: storageGet('depositLimitPeriod', ''),
        dlNV: storageGet<string>('dlnv', ''),
        email: storageGet<User['email']>('email', ''),
        eVerificationMethod: undefined,
        fathersName: storageGet<string>('fathersName', ''),
        firstName: storageGet<string>('firstName', ''),
        gender: storageGet<string>('gender'),
        hasAcceptedAgeAndPlayingOwnBehalf: Boolean(storageGet('hasAcceptedAgeAndPlayingOwnBehalf', '')),
        language: undefined as unknown as Language,
        lastName: storageGet<string>('lastName', ''),
        loqateLookupAddressId: undefined,
        loyalty: storageGet<string>('loyalty', ''),
        mothersName: storageGet<string>('mothersName', ''),
        otp: storageGet<string>('otp', ''),
        password: storageGet<string>('password', ''),
        passwordConfirm: storageGet<string>('passwordConfirm', ''),
        personalId: storageGet<string>('personalId', ''),
        personalIdType: 'dni',
        phoneNumber: storageGet<string>('phone', ''),
        phonePrefix: '+372',
        pin: storageGet<string>('pin', ''),
        province: '',
        sessionId: undefined,
        socialMediaRegistrationToken: undefined,
        ssn9: storageGet<string>('ssn9', ''),
        subscribeDirectMail: undefined,
        subscribeMailList: undefined,
        subscribePhoneCalls: undefined,
        subscribeSms: undefined,
        temporaryToken: undefined,
        zip: storageGet<string>('zip', ''),
    },
});
