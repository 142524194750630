import React, { useEffect } from 'react';
import Wrapper from './styles';
import { stores } from '../../../../stores';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import { BonusType } from '../../../../services/sports/constants';
import SportBonusBetLinksMultiplier from '../links/multiplier/SportBonusBetLinksMultiplier';
import SportBonusBetLinks from '../links/SportBonusBetLinks';
import { translate } from '../../../../services/translate';
import { filterBonusesThatAreNotMatchOfTheDay } from '../../../../services/bonus';
import { loadOfferedBonuses } from '../../../../services/sports/bonus-bets';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    isList?: boolean;
}

export default function SportBonusBetList({ isList }: Props) {
    const [bonusBetsStore, setBonusBetsStore] = useStore(stores.sports.bonusBets);
    const [isAuthenticated] = useStore(stores.isAuthenticated);

    useEffect(() => {
        if (isFeatureAvailable(FEATURE.BONUSES) && isAuthenticated) {
            loadOfferedBonuses();
        } else {
            setBonusBetsStore({
                [BonusType.RiskFreeBet]: [],
                [BonusType.FreeBet]: [],
                [BonusType.FreeBetV2]: [],
                [BonusType.Multiplier]: [],
            });
        }
    }, [isAuthenticated]);

    function prepareLinkItemsForBonuses(): JSX.Element[] {
        const result: JSX.Element[] = [];
        if (isFeatureAvailable(FEATURE.BONUSES)) {
            Object.entries(bonusBetsStore).forEach(([bonusType, bonusList]) => {
                if (bonusType === BonusType.Multiplier) {
                    result.push(
                        ...filterBonusesThatAreNotMatchOfTheDay(bonusList).map((bonus) => (
                            <SportBonusBetLinksMultiplier key={bonus.id} bonus={bonus} isList={isList} />
                        )),
                    );
                } else {
                    result.push(
                        ...bonusList.map((bonus) => (
                            <SportBonusBetLinks
                                key={bonus.id}
                                bonusType={bonusType as BonusType}
                                bonus={bonus}
                                isList={isList}
                            />
                        )),
                    );
                }
            });
        }
        return result;
    }

    const bonusLinks = prepareLinkItemsForBonuses();
    if (!bonusLinks.length) {
        return null;
    }

    return (
        <Wrapper $isList={isList}>
            <div className="campaigns-list">
                <div className="menu-label">
                    <div>
                        {translate('Active Bonuses', 'ui.sportsbook')} {isList && ':'}
                    </div>
                    {isList && <div className="menu-disclaimer">* Terms Apply</div>}
                </div>
                {bonusLinks}
            </div>
        </Wrapper>
    );
}
