import React, { useEffect } from 'react';
import Wrapper from './styles';
import Svg from '../../../svg/Svg';
import { stores } from '../../../../stores';
import CrmInbox from '../CrmInbox';
import { useComponentFocused } from '../../../../services/hooks';
import UiButton from '../../../ui/button/UiButton';
import { useStore } from '../../../../hooks/useStore';

export default function CrmInboxIcon() {
    const [unreadMessagesTotal] = useStore(stores.crm.unreadMessagesTotal);
    const [isInboxOpen, setIsInboxOpen] = useStore(stores.crm.isInboxOpen);
    const [inboxRef, isFocused] = useComponentFocused(false);

    useEffect(() => {
        if (!isFocused) {
            setIsInboxOpen(false);
        }
    }, [isFocused]);

    return (
        <Wrapper>
            <UiButton
                size="small"
                onClick={() => setIsInboxOpen(!isInboxOpen)}
                badge={unreadMessagesTotal}
                icon={<Svg icon="envelope-ico" className="icon" size={1.125} />}
            />
            {isInboxOpen && (
                <div ref={inboxRef}>
                    <CrmInbox />
                </div>
            )}
        </Wrapper>
    );
}
