import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    .slick-list {
        padding-top: ${({ isDefaultArrows }) => (isDefaultArrows ? '0.225rem' : '0.625rem')};

        @media ${mediaQuery.isPhone} {
            overflow-x: scroll;
            overflow-y: hidden;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .slick-track {
        display: flex;
        margin-left: 0;
    }

    .slick-initialized {
        overflow: hidden;
    }
`;
