import React, { useLayoutEffect, useRef, useState } from 'react';
import Wrapper from './styles';
import { logger } from '../../../services/logger';

interface Props {
    children: any;
    hideOverflow?: boolean;
    box?: 'border-box' | 'content-box' | 'device-pixel-content-box';
}

export default function UiResize({ box = 'border-box', children, hideOverflow = true }: Props) {
    const ref: any = useRef();
    const [height, setHeight] = useState(-1);
    useLayoutEffect(() => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            new ResizeObserver(onResize).observe(ref.current, { box });
        } catch (e: any) {
            logger.info('UiResize', 'UiResize', e.error);
        }
    }, []);

    function onResize() {
        if (ref.current) {
            setHeight(ref.current.offsetHeight);
        }
    }

    return (
        <Wrapper style={{ height: height >= 0 ? `${height}px` : 'unset', overflow: hideOverflow ? 'hidden' : 'unset' }}>
            <div ref={ref}>{children}</div>
        </Wrapper>
    );
}
