import styled from 'styled-components';

import UiModal from '../../../ui/modal/UiModal';
import { darken } from 'polished';

export default styled(UiModal)`
    position: relative;
    width: 400px;
    max-width: 90%;
    max-height: 100%;
    overflow: auto;
    margin-top: 15px;

    .currency-badge {
        user-select: none;
        font-weight: bold;
        color: ${({ theme }) => darken(0.4, theme.typography.fontColorPrimary)};
        padding: 0 ${({ theme }) => theme.layout.gutter} 0 2px;
    }

    .close-button {
        position: relative;
        top: 0;
        right: 0;
        margin: 0 0 5px 5px;
        float: right;
    }
    .sow-field {
        width: 100%;
    }
    .fill-form {
        margin: auto;
    }
    .truncated-info-text {
        margin-bottom: 20px;
        > div {
            display: inline;
            p {
                display: none;
                &:first-of-type {
                    display: inline;
                }
            }
        }
        p {
            display: inline;
            button {
                border: none;
                margin-left: 5px;
                cursor: pointer;
                background: none;
                color: #00bfff;
                padding: 0;
                outline: none;
            }
        }
    }
    button[type='submit'] {
        margin-left: auto;
    }
`;
