import React from 'react';
import Wrapper from './styles';
import { stores } from '../../../../../stores';
import { CasinoGame } from '../../../../../services/casino/games';
import { getStoreValue } from '../../../../../stores/store/utils';

interface Props {
    game: CasinoGame;
}

export default function CasinoThumbnailLabelsProvider({ game }: Props) {
    return (
        <Wrapper>
            <div className="casino-thumbnail-label-provider">
                {getStoreValue(stores.casino.studiosById)[game.studioId]?.name}
            </div>
        </Wrapper>
    );
}
