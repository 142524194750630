import React from 'react';
import { getBadgeProps, KycDocumentType, SourceOfIncomeStatus, useDocumentUpload } from '../../../../services/kyc';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import AccountVerificationBox from '../box/AccountVerificationBox';
import AccountVerificationDocumentSelectionOneSided from '../document/selection/one-sided/AccountVerificationDocumentSelectionOneSided';
import AccountVerificationDocumentUploadButton from '../document/upload-button/AccountVerificationDocumentUploadButton';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    isCompactView?: boolean;
    onDocumentUploadSuccess?: () => void;
}

export default function AccountVerificationSourceOfFunds({ onDocumentUploadSuccess = () => void 0, ...rest }: Props) {
    const [kycSettings] = useStore(stores.kyc.settings);
    const documentUpload = useDocumentUpload({
        documentType: KycDocumentType.INCOME,
        uploadSuccessMessage: translate(
            "We have received your document copies. We'll email you once they have been processed.",
            'ui.account.kyc',
        ),
        status: kycSettings.sourceOfIncomeStatus as SourceOfIncomeStatus,
        declineReason: kycSettings.kycIncomeDeclineReason,
    });

    async function handleUploadingDocuments() {
        await documentUpload.uploadDocuments();
        documentUpload.setDocuments([]);
        onDocumentUploadSuccess();
    }

    return (
        <AccountVerificationBox
            icon="wallet"
            description={translate('Payslip, bank statement, employment contract, etc.', 'ui.account')}
            title={translate('proof-of-income-method', 'ui.account')}
            {...rest}
            {...getBadgeProps(documentUpload.status)}
        >
            {documentUpload.statusAlert}
            {documentUpload.isEnabled && (
                <AccountVerificationDocumentSelectionOneSided documentUpload={documentUpload} />
            )}
            {documentUpload.isDocumentSelected && (
                <AccountVerificationDocumentUploadButton
                    onClick={handleUploadingDocuments}
                    isLoading={documentUpload.isUploading}
                />
            )}
            {documentUpload.uploadResultAlert}
        </AccountVerificationBox>
    );
}
