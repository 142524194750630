import { useEffect } from 'react';
import { getLimitsByCurrency } from '../../microservices/bets';
import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { getActiveCurrency } from '../currency';
import { isRetail } from '../environment';
import { BET_TYPE } from './constants';
import { retail } from '../../stores/retail/retail';
import { useStore } from '../../hooks/useStore';

export function useLimits() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [language] = useStore(stores.language);

    useEffect(() => {
        loadSportsLimits();
    }, [isAuthenticated, language]);
}

async function loadSportsLimits() {
    const activeCurrency = getActiveCurrency();
    const limits = await getLimitsByCurrency(activeCurrency);
    stores.sports.limits.set(limits);
}

export function getMinStake() {
    const limits = getStoreValue(stores.sports.limits);
    let minStake: number | undefined;

    minStake = limits.min_stake;
    const { betType } = getStoreValue(stores.sports.betSlipUserState);
    if (isRetail()) {
        minStake = limits.retail_min_stake || minStake;
        if (betType === BET_TYPE.SYSTEM) {
            minStake = limits.retail_system_ticket_min_stake || minStake;
        }
    } else if (betType === BET_TYPE.SYSTEM) {
        minStake = limits.system_ticket_min_stake || minStake;
    }
    return minStake || Number.MAX_SAFE_INTEGER;
}

export function getMaxWin() {
    const limits = getStoreValue(stores.sports.limits);

    let maxWin = limits.ticket_max_win;
    if (isRetail()) {
        maxWin = limits.retail_ticket_max_win || maxWin;
        const loyalty = getStoreValue(retail.loyalty);
        if (loyalty.loyalty_id) {
            maxWin = limits.retail_loyalty_ticket_max_win || maxWin;
        }
    }
    return maxWin || 0;
}

export function getMaxWinDaily() {
    const limits = getStoreValue(stores.sports.limits);

    return limits.daily_max_win || 0;
}

export function getMaxWinWeekly() {
    const limits = getStoreValue(stores.sports.limits);

    return limits.weekly_max_win || 0;
}
