import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    .autocomplete .MuiOutlinedInput-root {
        appearance: none;
        background-color: var(--form-input-bg);
        border: var(--form-input-border);
        border-radius: var(--ui-radius);
        cursor: pointer;
        color: var(--form-input-text);
        height: 50px;
        padding: 0.5rem 0 1.5rem 0.3rem;

        margin-bottom: 0.8rem;
        @media ${mediaQuery.isPhone} {
            height: 40px;
        }
    }
    .autocomplete .MuiOutlinedInput-root.Mui-focused fieldset {
        border: var(--form-input-border);
        box-shadow: none;
    }
`;
