import React from 'react';
import classNames from 'classnames';
import { getPromotionAnalyticsInfo } from '../../../../microservices/cms';
import { getArticleById } from '../../../../microservices/cms';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../../services/analytics';
import { LANGUAGE } from '../../../../services/language';
import { logger } from '../../../../services/logger';
import { useRouter } from '../../../../services/router';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import { CtaPromotionComponentProps as Props } from '../../../../services/cms-promotion';
import { useStore } from '../../../../hooks/useStore';

export default function MarketingMatchPromotionCta({ promotion }: Props) {
    const { id, link, use_highlight_text, use_content_text } = promotion;
    const [language] = useStore(stores.language);
    const { navigateTo } = useRouter();

    let translatedLinkText = translate(`ui.promotion.link-text.${id}`);
    if (translatedLinkText === `ui.promotion.link-text.${id}`) {
        translatedLinkText = translate('ui.promotion.link-text');
    }

    async function handleCTALinkClick() {
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.MARKETING_PROMOTION_CLICK, getPromotionAnalyticsInfo(promotion));
        try {
            const article = await getArticleById(link);
            if (article.slug[language]) {
                navigateTo(`/${language}/${article.slug[language]}`);
            } else if (article.slug[LANGUAGE.ENGLISH]) {
                // Default to english article/page
                navigateTo(`/${language}/${article.slug[LANGUAGE.ENGLISH]}`);
            }
        } catch (error) {
            logger.error('MarketingMatchPromotionCta', 'handleCTALinkClick', error);
            return null;
        }
    }

    return (
        <a
            className={classNames(
                'promotion-cta',
                'promotion-marged-action',
                (use_highlight_text || use_content_text) && 'with-left-margin',
            )}
            onClick={handleCTALinkClick}
        >
            {`${translatedLinkText}!`}
        </a>
    );
}
