import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import SportSettingsMenu from '../SportSettingsMenu';
import { translate } from '../../../../services/translate';
import { SportSetting, useSportsUserSettings } from '../../../../services/sports/user-settings';
import { SportsOddsFormat } from '../../../../microservices/sbgate';
import Svg from '../../../svg/Svg';
import { useComponentFocused, useHover } from '../../../../services/hooks';
import { isMobile } from '../../../../services/browser';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import { isBYOD } from '../../../../services/environment';

interface Props {
    settings?: SportSetting[];
    highlighted?: number;
    isCompact?: boolean;
}

export default function SportSettingsMenuButton({
    settings = ['ODDS_TYPE', 'TIME_FORMAT', 'AMERICAN_LAYOUT', 'COMPACT_VIEW'],
    highlighted = 2,
    isCompact,
}: Props) {
    const AUTO_CLOSE_TIME = 3000;
    const [isHidden, setIsHidden] = useState(true);
    const { oddsFormat } = useSportsUserSettings();
    const { isHovering, hoverProps } = useHover(isMobile(), AUTO_CLOSE_TIME);
    const [menuRef, isFocused] = useComponentFocused(false);

    useEffect(() => {
        if (!isFocused && !isHidden) {
            setIsHidden(true);
        }
    }, [isFocused]);

    useEffect(() => {
        if (!isHovering && !isHidden) {
            setIsHidden(true);
        }
    }, [isHovering]);

    if (!isFeatureAvailable(FEATURE.SPORTSBOOK_SETTINGS)) {
        return null;
    }

    return (
        <Wrapper isCompact={isCompact}>
            {!isBYOD() && (
                <div className="sports-settings" onClick={() => setIsHidden(false)}>
                    {!isCompact && (
                        <div className="settings-state">
                            <span className="title">
                                {translate(['oddsformat.type', 'Odds type'], 'ui.sportsbook')}:
                            </span>
                            <span className="info">
                                {oddsFormat === SportsOddsFormat.DECIMAL
                                    ? translate(['oddsformat.decimal', 'Decimal odds'], 'ui.sportsbook')
                                    : translate(['oddsformat.american', 'American odds'], 'ui.sportsbook')}
                            </span>
                        </div>
                    )}
                    <Svg
                        {...hoverProps}
                        icon="menu-dots"
                        onClick={() => setIsHidden(false)}
                        size={1.1}
                        className="animated fadeInRight"
                    />
                </div>
            )}
            <SportSettingsMenu
                settings={settings}
                highlighted={highlighted}
                isHidden={isHidden}
                onClose={setIsHidden}
                hoverProps={hoverProps}
                menuRef={menuRef}
            />
        </Wrapper>
    );
}
