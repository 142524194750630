import styled from 'styled-components';

export default styled.div`
    background-color: var(--surface-level-1-bg);
    border-radius: var(--ui-radius);
    padding: 15px;

    .poker-android-instructions-title {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 1px;
        color: var(--font-color-secondary);
        margin-bottom: 10px;
    }

    .poker-android-instructions-google-play {
        font-weight: 700;
        color: var(--font-color-subtle);
        font-size: 13px;
        margin-bottom: 30px;
    }

    .poker-android-instructions-step-container {
        font-size: 14px;
        padding: 10px 0;
        letter-spacing: 0.5px;
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .poker-android-instructions-step-icon {
        width: 36px;
        height: 36px;
    }

    .poker-android-instructions-step-text {
        margin-top: 10px;
        text-align: center;
        max-width: 200px;
    }

    .poker-android-instructions-download {
        border: none;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.5px;
        margin: 30px 0 20px 0;

        .button-text {
            margin-right: 36px;
        }
    }

    .poker-android-instructions-after-install {
        font-size: 12px;
        color: var(--font-color-subtle);
        padding: 0 5px;
        text-align: center;
    }

    .bank-id-warning {
        margin-top: -10px;
        p {
            text-align: center;
            margin: 0;
            padding: 3px 0;
            font-size: 11px;
            font-weight: 700;
        }
    }
`;
