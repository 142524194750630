import React from 'react';
import Wrapper from './styles';
import { filterStyleProps } from '../../../../styles/utils';

interface Props {
    className?: string;
    expand?: boolean;
    nowrap?: boolean;
    row?: boolean;
    children: any;
}

export default function UiFormGroup({ children, ...rest }: Props) {
    return <Wrapper {...filterStyleProps({ ...rest })}>{children}</Wrapper>;
}
