import styled from 'styled-components';

type StyledProps = {
    size?: number;
    $isRetailLayout?: boolean;
};

export default styled.div<StyledProps>`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;

    cursor: pointer;
    svg {
        width: ${({ size, $isRetailLayout }) => (size ? size : $isRetailLayout ? '36' : '24')}px;
        color: var(--font-color-subtle);
    }
`;
