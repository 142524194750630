import React, { useState } from 'react';
import Wrapper from './styles';
import PokerPlayFeatures from './features/PokerPlayFeatures';
import { translate } from '../../../services/translate';
import { getOS, OPERATING_SYSTEM } from '../../../services/device';
import {
    openAppleAppStoreLink,
    openPokerWeb,
    POKER_CLIENT_TYPE,
    startPokerClientDownload,
    startPokerMacClientDownload,
} from '../../../services/poker';
import UiModal from '../../ui/modal/UiModal';
import PokerPlayAndroidInstructions from './android-instructions/PokerPlayAndroidInstructions';
import { getUserCountry } from '../../../services/user';
import { COUNTRY } from '../../../services/country';
import classNames from 'classnames';
import Svg from '../../svg/Svg';

interface Props {
    showFeatures?: boolean;
    type: POKER_CLIENT_TYPE;
}

type PokerFeatureProperties = {
    primary: boolean;
    features: {
        hasCashGames: boolean;
        hasSitAndGoes: boolean;
        hasTwisterSitAndGoes: boolean;
        hasTournaments: boolean;
    };
    display?: {
        icon: string;
        className: string;
    };
    title: string;
    onClick;
};

export default function PokerPlay({ showFeatures, type }: Props) {
    const [isAndroidModalVisible, setIsAndroidModalVisible] = useState(false);
    const allFeatures = {
        hasCashGames: true,
        hasSitAndGoes: true,
        hasTwisterSitAndGoes: true,
        hasTournaments: true,
    };
    const propertiesByType: { [key: string]: PokerFeatureProperties } = {
        [POKER_CLIENT_TYPE.DOWNLOAD]: {
            primary: true,
            features: allFeatures,
            title: translate('Download Poker Client', 'poker'),
            onClick: startPokerClientDownload,
        },
        [POKER_CLIENT_TYPE.HTML5]: {
            primary: getOS() === OPERATING_SYSTEM.LINUX,
            features: {
                hasCashGames: true,
                hasSitAndGoes: false,
                hasTwisterSitAndGoes: true,
                hasTournaments: true,
            },
            title: translate('Play Poker In Browser', 'poker'),
            onClick: () => openPokerWeb(),
        },
        [POKER_CLIENT_TYPE.ANDROID]: {
            primary: true,
            features: allFeatures,
            title: translate('Download Poker App', 'poker'),
            onClick: () => setIsAndroidModalVisible(true),
        },
        [POKER_CLIENT_TYPE.IOS]: {
            primary: true,
            features: allFeatures,
            display: {
                icon: 'apple',
                className: 'poker-apple-button',
            },
            title: translate('Download Poker App', 'poker'),
            onClick: openAppleAppStoreLink,
        },
        [POKER_CLIENT_TYPE.MAC_DOWNLOAD]: {
            primary: true,
            features: allFeatures,
            display: {
                icon: 'apple',
                className: 'poker-apple-button',
            },
            title: translate('Download Poker Client', 'poker'),
            onClick: startPokerMacClientDownload,
        },
    };

    const properties = propertiesByType[type];
    return (
        <Wrapper $showFeatures={showFeatures} $primary={properties.primary} className={classNames('poker-play', type)}>
            {showFeatures && <PokerPlayFeatures {...properties.features} />}
            <div
                className={classNames('poker-download-button', properties.display?.className || '')}
                onClick={properties.onClick}
            >
                <Svg icon="apple" size={1.5} className="apple-icon" />
                {properties.title}
                {type === POKER_CLIENT_TYPE.ANDROID && getUserCountry() === COUNTRY.SWEDEN && (
                    <Svg icon="info" className="poker-download-info" />
                )}
            </div>
            <UiModal onClose={() => setIsAndroidModalVisible(false)} open={isAndroidModalVisible}>
                <PokerPlayAndroidInstructions />
            </UiModal>
        </Wrapper>
    );
}
