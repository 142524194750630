import React, { useEffect, useState } from 'react';
import { CasinoJackpot } from '../../../services/casino/jackpots';
import { getActiveCurrency } from '../../../services/currency';
import { stores } from '../../../stores';
import { formattedAmountWithCurrency } from '../../../services/currency';
import { useStore } from '../../../hooks/useStore';

interface Props {
    'jackpot-name': string;
}

export default function CmsJackpotFeed({ 'jackpot-name': jackpotName }: Props) {
    const activeCurrency = getActiveCurrency();
    const [jackpots] = useStore<{ [key: string]: CasinoJackpot }>(stores.casino.jackpots);
    const [jackpotValueWithCurrency, setJackpotValueWithCurrency] = useState<string>('');

    useEffect(() => {
        const jackpotValue = jackpots[jackpotName] && jackpots[jackpotName][activeCurrency];
        if (jackpotValue) {
            setJackpotValueWithCurrency(formattedAmountWithCurrency(jackpotValue));
        }
    }, [jackpots]);

    return <div>{jackpotValueWithCurrency}</div>;
}
