import styled from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

export default styled.div`
    .sport-menu-item {
        display: flex;
        align-items: center;

        background-color: var(--sport-menu-item-bg);

        border: var(--sport-menu-item-border);
        border-radius: var(--ui-radius);

        margin-bottom: var(--spacing-8);
        &:hover {
            background-color: var(--sport-menu-item-bg-hover);
        }
        &.active {
            background-color: var(--sport-menu-item-bg-active);
            border: var(--sport-menu-item-border-active);
        }
        &.expanded {
            //background-color: var(--sport-menu-item-bg-expanded);
            .sport-menu-expand {
                color: var(--sport-menu-icon-color-active);
            }
        }
        .sport-menu-item-link {
            flex: 1;
            display: flex;
            align-items: center;

            font-size: 1rem;
            color: var(--sport-menu-text-color);
            font-weight: var(--tabs-text-weight);
            ${({ theme }) => theme.optimizeFontRendering};
            text-decoration: none;

            padding: 0.5rem 0.75rem 0.5rem 0;
            @media ${mediaQuery.isPhone} {
                font-size: 1rem;
            }
            &.active {
                color: var(--sport-menu-text-active);
            }
            .item-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 3rem;
                margin-right: 0.5rem;
            }
        }

        .sport-menu-expand {
            display: flex;
            justify-content: center;
            align-items: center;

            min-width: 2.5rem;

            cursor: pointer;
            color: var(--sport-menu-icon-color);
            &:hover {
                color: var(--sport-menu-icon-color-hover);
            }

            &.rotate {
                svg {
                    transform: rotate(90deg);
                }
            }
            svg,
            img {
                max-width: 1rem;
                min-width: 1rem;
            }
            svg {
                transition: 200ms ease-in-out;
            }
        }

        + .sport-menu-expanded {
            background-color: var(--sport-menu-expanded-bg);
            border-radius: var(--ui-radius);
            border: var(--surface-level-2-border);
            .sport-menu-item {
                &:hover {
                    background-color: var(--sport-menu-item-bg-expanded-hover);
                }
                &.active {
                    background-color: var(--sport-menu-item-bg-active);
                    border: var(--sport-menu-item-border-active);
                }
            }
            .sport-menu-item-link {
                font-size: 0.8rem;
                @media ${mediaQuery.isPhone} {
                    font-size: 1rem;
                }
                .item-icon {
                    svg,
                    img {
                        max-width: 1rem;
                        min-width: 1rem;
                        height: 1rem;
                    }
                }
            }
            .sport-menu-expanded {
                border: none;
            }
        }

        .favorite-icon {
            display: flex;
            justify-content: center;
            align-items: center;

            color: var(--sport-menu-icon-color);
            user-select: none;
            cursor: pointer;

            width: 2.5rem;
            &:hover {
                color: var(--sport-menu-icon-color-hover);
            }
            &.selected {
                color: var(--sport-menu-icon-color-active);
            }
        }
    }
`;
