import styled from 'styled-components';

export default styled.div`
    min-height: calc(100vh);

    display: flex;
    align-items: center;
    justify-content: center;

    .brand-logo {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 200px;
        svg {
            width: 100px;
        }
        .loader {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
        }
        .motto {
            height: 30px;
            color: #fff;
            font-size: 0.625rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-family: Roboto, sans-serif;
            font-weight: bold;
            ${({ theme }) => theme.optimizeFontRendering}
        }
    }
`;
