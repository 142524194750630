import React, { useState } from 'react';
import { translate } from '../../../services/translate';
import { stores } from '../../../stores';
import UiButton from '../../ui/button/UiButton';
import { logger } from '../../../services/logger';
import { printTicketAndVoucher } from '../../../microservices/retail-middleware';
import { retail } from '../../../stores/retail/retail';
import { useStore } from '../../../hooks/useStore';

interface Props {
    printButtonType?: 'primary' | 'default';
}

export default function RetailPrint({ printButtonType }: Props) {
    const [hasRetailSession] = useStore(retail.hasRetailSession);
    const [ticketsToPrint] = useStore(stores.sports.ticketsToPrint);
    const [retailPrintingModal] = useStore(retail.retailModals.retailPrintingModal);
    const [betSlipPlacingState] = useStore(stores.sports.betSlipPlacingState);
    const [isPrintButtonLocked, setIsPrintButtonLocked] = useState(false);
    const { isLoading } = betSlipPlacingState;

    async function printAndCashOut() {
        try {
            setIsPrintButtonLocked(true);
            await printTicketAndVoucher();
        } catch (error) {
            logger.error('RetailPrint', 'printAndCashOut', error);
        } finally {
            setIsPrintButtonLocked(false);
        }
    }

    if (!hasRetailSession) {
        return <></>;
    }

    return (
        <UiButton
            onClick={() => printAndCashOut()}
            disabled={retailPrintingModal?.isPrinting || isLoading || isPrintButtonLocked}
            color={printButtonType ? printButtonType : ticketsToPrint.length > 0 ? 'primary' : 'default'}
            className="secondary-button"
        >
            {translate('Print & Cashout', 'retail.header')}
        </UiButton>
    );
}
