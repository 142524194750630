import React, { ComponentType, ReactPropTypes, Suspense } from 'react';
import { ErrorBoundary } from '../../higher-order-components/error-boundary';
import Loader from '../loader/Loader';

export default function AsyncComponent(loader: () => Promise<{ default: ComponentType<any> }>) {
    const Component = React.lazy(loader);
    return (props: ReactPropTypes) => (
        <ErrorBoundary>
            <Suspense fallback={<Loader />}>
                <Component {...props} />
            </Suspense>
        </ErrorBoundary>
    );
}
