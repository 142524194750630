import styled, { css } from 'styled-components';

import { darken } from 'polished';
import { SPORT_TYPE } from '../../../../../services/quiz';
import football from './quiz-football.png';
import basketball from './quiz-basketball.png';
import hockey from './quiz-hockey.png';

type StyledProps = {
    sportType?: string;
};
export default styled.div<StyledProps>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => darken(0.2, theme.colors.secondary)};
    border: 2px solid ${({ theme }) => theme.colors.secondary};
    box-shadow: 0 10px 25px -3px #000;
    padding: ${({ theme }) => theme.layout.gutter};
    border-radius: var(--ui-radius);
    min-height: 100px;
    overflow: hidden;
    z-index: 1;
    .quiz-team-name {
        position: relative;
        z-index: 1;

        flex: 1;
        text-transform: uppercase;
        font-size: 0.85rem;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: right;
        &:last-of-type {
            text-align: left;
        }
    }
    .quiz-custom-title {
        position: relative;
        z-index: 1;
        text-transform: uppercase;
        font-size: 0.85rem;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: center;
    }
    .quiz-sport {
        position: relative;
        z-index: 1;
        width: 75px;
        height: 65px;
        margin: 0 ${({ theme }) => theme.layout.gutterHalf};
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .football {
        width: 76px;
        height: 76px;
        animation: spin-axis-x 1s infinite steps(15), spin-axis-y 20s infinite steps(20);
        background-image: url(${football});
        transform: scale(0.6);
    }
    .basketball {
        width: 76px;
        height: 76px;
        animation: run-x 1s infinite steps(10), run-y 20s infinite steps(20);
        background-image: url(${basketball});
        transform: scale(0.6);
    }

    .hockey {
        width: 76px;
        height: 76px;
        animation: run-x 1s infinite steps(15), run-y 20s infinite steps(20);
        background-image: url(${hockey});
        transform: scale(0.6);
    }

    @keyframes run-y {
        from {
            background-position-y: 0;
        }
        to {
            background-position-y: -1500px;
        }
    }

    @keyframes spin-axis-x {
        from {
            background-position-x: 0;
        }
        to {
            background-position-x: -1125px;
        }
    }

    @keyframes spin-axis-y {
        from {
            background-position-y: 0;
        }
        to {
            background-position-y: -1500px;
        }
    }

    .quiz-header-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    ${({ sportType }) =>
        sportType === SPORT_TYPE.FOOTBALL &&
        css`
            background-color: ${({ theme }) => darken(0.2, theme.colors.secondary)};
            border-color: ${({ theme }) => theme.colors.secondary};
        `}
    ${({ sportType }) =>
        sportType === SPORT_TYPE.HOCKEY &&
        css`
            background-color: ${darken(0.2, '#00bfff')};
            border-color: #00bfff;
            @keyframes run-x {
                from {
                    background-position-x: 0;
                }
                to {
                    background-position-x: -1125px;
                }
            }
        `}
    ${({ sportType }) =>
        sportType === SPORT_TYPE.BASKETBALL &&
        css`
            background-color: #cc5c0c;
            border-color: #ff8033;
            @keyframes run-x {
                from {
                    background-position-x: 0;
                }
                to {
                    background-position-x: -750px;
                }
            }
        `}
`;
