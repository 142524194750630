import { getServiceUrl, httpGet, httpPost } from '../services/api';

export interface Event {
    id: number;
    name: string;
    termsArticleId: string;
    fields: ContactField[];
    createdBy: number;
    updatedBy: number;
    createdAt: Date;
    updatedAt: Date;
}

export enum ContactField {
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    EMAIL = 'email',
    PHONE = 'phone',
    ANSWER = 'answer',
    ANSWER_2 = 'answer2',
    ACCEPT_TERMS = 'acceptTerms',
}

export type Contact = {
    id: number;
    eventId: number;
} & { [key in ContactField]: string };

const getUrl = (url) => getServiceUrl('contact-form', url);

export function getEventById(id: number): Promise<Event> {
    const url = getUrl(`public/events/${id}`);
    return httpGet(url);
}

export function createContact(contact: Partial<Contact>): Promise<Contact> {
    const url = getUrl('public/contacts');
    return httpPost(url, contact);
}
