import styled from 'styled-components';

export default styled.div`
    display: flex;
    align-items: center;
    .match-sport-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-right: var(--spacing-8);
    }
    .match-main-info {
        min-width: 0;
        width: 100%;
    }
    .live-info {
        margin-bottom: var(--spacing-8);
    }
    .match-name {
        display: flex;
        align-items: center;

        margin-bottom: 0.4rem;
    }
    .match-extra {
        min-width: 3rem;
        margin-left: auto;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        color: var(--match-secondary-color);

        > * {
            padding: 0.4rem 0;
            &:not(:last-child) {
                margin-bottom: 0.4rem;
            }
        }
    }
    .active svg {
        color: var(--color-highlight);
    }
`;
