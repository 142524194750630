import React, { useEffect, useRef, useState } from 'react';
import { checkAndValidateUserCode, submitBonusCode } from '../../../microservices/bonuses';
import UiDotsLoader from '../../../components/ui/dots-loader/UiDotsLoader';
import { BONUS_STATUS } from '../../../services/bonus';
import { stores } from '../../../stores';
import { logger } from '../../../services/logger';
import { useStore } from '../../../hooks/useStore';

const CODE_STATUS = {
    CODE_REGISTERED: 'code-registered',
    CODE_NOT_REGISTERED: 'code-not-registered',
    CODE_NOT_ELIGIBLE: 'code-not-eligible',
};

export const withCheckCode = (Component, bonusCode) => (props) => {
    const { children, onClick = () => {} } = props;

    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [codeStatus, setCodeStatus] = useState('');
    const codeStatusReference = useRef<string>('');

    useEffect(() => {
        if (isAuthenticated) {
            validateCode();
        }
    }, [isAuthenticated]);

    async function validateCode() {
        try {
            const response = await checkAndValidateUserCode(bonusCode);
            codeStatusReference.current = mapResponseToCodeStatus(response.status);
            setCodeStatus(codeStatusReference.current);
        } catch (error) {
            logger.error('withCheckCode', 'validateCode', error);
        }
    }

    async function submitCode() {
        try {
            const response = await submitBonusCode(bonusCode);
            const bonusCodeResponse = response.status;
            setCodeStatus(mapResponseToCodeStatus(bonusCodeResponse));
        } catch (error) {
            logger.error('withCheckCode', 'submitCode', error);
        }
    }

    function mapResponseToCodeStatus(response) {
        if (response === BONUS_STATUS.ELIGIBLE) {
            return CODE_STATUS.CODE_NOT_REGISTERED;
        }
        if (response === BONUS_STATUS.ALREADY_GOT_THIS_BONUS) {
            return CODE_STATUS.CODE_REGISTERED;
        }
        return CODE_STATUS.CODE_NOT_ELIGIBLE;
    }

    function updatedOnClick(event) {
        onClick(event);

        if (codeStatus === CODE_STATUS.CODE_NOT_REGISTERED) {
            submitCode();
        }
    }

    if (!isAuthenticated) {
        return null;
    }
    return (
        <>
            {codeStatus === null && <UiDotsLoader />}
            {codeStatus !== null && (
                <Component {...props} onClick={updatedOnClick}>
                    {children.filter((child) => child.props && codeStatusReference.current in child.props)}
                </Component>
            )}
        </>
    );
};
