import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import Svg from '../../../svg/Svg';
import { stores } from '../../../../stores';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import {
    loadActiveHappyHour,
    loadAvailableHappyHour,
    optInToHappyHourCrm,
    optInToHappyHourCrmCheck,
} from '../../../../microservices/happy-hour';
import moment from 'moment';
import UiCountdown from '../../../ui/countdown/UiCountdown';
import { translate } from '../../../../services/translate';
import UiAlert from '../../../ui/alert/UiAlert';
import UiButton from '../../../ui/button/UiButton';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../../services/analytics';
import { dateToFromNowDaily } from '../../../../services/marketing/happy-hour';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    isCompact?: boolean;
}

export default function CasinoHappyHourWidget({ isCompact }: Props) {
    const [{ isPhone, isTablet }] = useStore(media);
    const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
    const [isHappyHourActive, setIsHappyHourActive] = useState(false);
    const [happyHour, setHappyHour] = useState<any>();
    const [activeHappyHour] = useStore(stores.happyHour.active);
    const [availableHappyHours] = useStore(stores.happyHour.available);
    const [optIn] = useStore(stores.happyHour.optIn);
    const [isOptedIn, setIsOptedIn] = useState(true);
    const [language] = useStore(stores.language);
    const happyHourPageLink = `/${language}/happy-hour`;

    useEffect(() => {
        updateHappyHours();
    }, []);

    useEffect(() => {
        if (activeHappyHour) {
            setHappyHour(activeHappyHour);
        } else if (availableHappyHours && (availableHappyHours as any).length) {
            setHappyHour((availableHappyHours as any)[0]);
        }
    }, [activeHappyHour, availableHappyHours]);

    useEffect(() => {
        if (happyHour) {
            getStartTime();
        }
    }, [happyHour]);

    useEffect(() => {
        setIsOptedIn(Boolean(optIn));
    }, [optIn]);

    useEffect(() => {
        if (isDescriptionVisible) {
            trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.CASINO_HAPPY_HOUR_WIDGET_OPENED);
        }
    }, [isDescriptionVisible]);

    function updateHappyHours() {
        loadActiveHappyHour();
        loadAvailableHappyHour();
        optInToHappyHourCrmCheck();
    }

    function getStartTime() {
        const timeNow = moment();
        if (happyHour?.schedule?.length) {
            const schedule = happyHour.schedule[0];
            const startTime = moment.utc(schedule.start).local();
            const endTime = moment.utc(schedule.end).local();
            setIsHappyHourActive(timeNow.isAfter(startTime) && timeNow.isBefore(endTime));
        } else {
            setIsHappyHourActive(false);
        }
    }

    if (!happyHour || happyHour.hasEnded || !happyHour.schedule || !isFeatureAvailable(FEATURE.CASINO_HAPPY_HOUR)) {
        return null;
    }

    const startTime = moment.utc(happyHour.schedule[0].start).local().format('H:mm');
    const endTime = moment.utc(happyHour.schedule[0].end).local().format('H:mm');

    return (
        <Wrapper className="animated fadeIn" isCompact={isCompact} isOptedIn={isOptedIn}>
            <div
                className="happy-hour-widget"
                onClick={() => (isCompact ? '' : setIsDescriptionVisible(!isDescriptionVisible))}
            >
                <div className="happy-hour-logo">
                    <div className="happy">Happy</div>
                    <div className="hour">Hour</div>
                    {isHappyHourActive && <div className="active">{translate('Active', 'casino-happy-hour')}</div>}
                </div>
                {isHappyHourActive && happyHour && (
                    <>
                        <div className="happy-our-countdown">
                            <UiCountdown
                                className="countdown-clock"
                                endDate={moment.utc(happyHour.schedule[0].end).local().toISOString()}
                                onElapsed={updateHappyHours}
                            />
                            {!isOptedIn && (
                                <div className="opted-out"> {translate('Opt back in?', 'casino-happy-hour')}</div>
                            )}
                        </div>
                    </>
                )}
                {!isHappyHourActive && happyHour && happyHour.schedule && (
                    <div className="happy-our-time">
                        <div className="day">{dateToFromNowDaily(happyHour.schedule[0].start, language)}</div>
                        <div className="time-time">
                            <div className="time">
                                <div className="hours">
                                    {moment.utc(happyHour.schedule[0].start).local().format('H')}
                                </div>
                                <div className="minutes">
                                    {moment.utc(happyHour.schedule[0].start).local().format('mm')}
                                </div>
                            </div>
                            <div className="time-separator">-</div>
                            <div className="time">
                                <div className="hours">{moment.utc(happyHour.schedule[0].end).local().format('H')}</div>
                                <div className="minutes">
                                    {moment.utc(happyHour.schedule[0].end).local().format('mm')}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {!isCompact && (
                    <div className="happy-hour-info">
                        {!isDescriptionVisible && <Svg icon="info" size={isPhone || isTablet ? 1.6 : 1.1} />}
                        {isDescriptionVisible && <Svg icon="crossmark" size={isPhone || isTablet ? 1.6 : 1.1} />}
                    </div>
                )}
            </div>

            {isDescriptionVisible && (
                <div className="happy-hour-description">
                    {!isOptedIn && (
                        <>
                            <UiAlert warning>
                                {translate('You have opted out from this promotion.', 'casino-happy-hour')}
                            </UiAlert>
                            <UiButton block color="primary" onClick={optInToHappyHourCrm}>
                                {translate('Opt Back In', 'casino-happy-hour')}
                            </UiButton>
                        </>
                    )}

                    <p className="text">
                        {translate(
                            'Stay logged on between %1 - %2 to win. We will randomly pick %3 winners who will each get %4 free spins on %5. Good luck!',
                            `casino-happy-hour`,
                            [startTime, endTime, happyHour.no_of_winners, happyHour.no_of_spins, happyHour.game_name],
                        )}
                    </p>

                    <a href={happyHourPageLink} className="link read-more">
                        {translate('Read more', 'ui.account')} ...
                    </a>
                </div>
            )}
        </Wrapper>
    );
}
