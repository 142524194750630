import React from 'react';
import Wrapper from './styles';
import classNames from 'classnames';
import { CasinoGameType, CasinoInternalBaccaratResults } from '../../../../../services/casino/games';

interface Props {
    item: string;
    latestWinnings: string[];
    gameType?: typeof CasinoGameType[keyof typeof CasinoGameType];
}

export default function CasinoThumbnailLatestWinningsResults({ item, gameType, latestWinnings }: Props) {
    const blackResults = [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35];
    const greenResults = [0];
    const isDoubleBallRoulette = latestWinnings.length ? latestWinnings[0].includes('|') : false;

    function getRouletteColor(result: string) {
        if (blackResults.includes(parseInt(result))) {
            return isDoubleBallRoulette ? 'black' : 'black-bordered';
        }
        if (greenResults.includes(parseInt(result))) {
            return 'green';
        }
        return 'red';
    }

    function getBaccaratColor(result: string) {
        if (
            (
                [
                    CasinoInternalBaccaratResults.PLAYER,
                    CasinoInternalBaccaratResults.AWAY,
                    CasinoInternalBaccaratResults.RIGHT,
                ] as string[]
            ).includes(result)
        ) {
            return 'green';
        }
        if (
            (
                [
                    CasinoInternalBaccaratResults.DEALER,
                    CasinoInternalBaccaratResults.DRAGON,
                    CasinoInternalBaccaratResults.HOME,
                    CasinoInternalBaccaratResults.LEFT,
                ] as string[]
            ).includes(result)
        ) {
            return 'red';
        }
        if (result === CasinoInternalBaccaratResults.TIGER) {
            return 'yellow';
        }
        return 'black';
    }

    const getColor = (result: string) => {
        switch (gameType) {
            case CasinoGameType.ROULETTE:
                return getRouletteColor(result);
            case CasinoGameType.BACCARAT:
                return getBaccaratColor(result);
            case CasinoGameType.GAME_SHOW:
                return 'black-bordered';
        }
    };

    return (
        <>
            {item
                .split('|')
                .reverse()
                .map((winningNumber, index) => {
                    return (
                        <Wrapper key={index} className={classNames(getColor(winningNumber))}>
                            {winningNumber}
                        </Wrapper>
                    );
                })}
        </>
    );
}
