import { OddsStatus } from '@staycool/odds-types';
import { RefObject } from 'react';
import { COUNTRY } from '../country';
import { Language } from '../language';
import { SportsLayout } from '../types';
import { BET_TYPE } from './constants';
import { DatabaseStreamInfo, MarketStatus, MarketViewType, OutcomeStatus } from '@staycool/sports-types';
import {
    BetSlipMinimalMarket,
    FoCategoryMarketType,
    FoCategoryMatch,
    FoSportCategoryWithMatches,
    SportSearchCategory,
    SportSearchMatch,
} from '@staycool/sbgate-types';

export type Odds = {
    odds_id: string;
    value: number;
    outcome_id: number;
    market_id: number;
    status: OddsStatus;
};

export type OddsByOutcomeIdStore = { [outcomeId: number]: Odds | undefined };

export interface CategoryMatchMarketOutcome {
    id: number;
    status: 'OPEN';
    result_key: string;
    name: string;
    has_trading_position: boolean;
}

export type CategoryMatchMarket = FoCategoryMatch['markets'][number];

export interface CmsGeneralSetting {
    id: number;
    key: string;
    value: string;
    created_at?: Date;
    updated_at?: Date;
    updated_by: string;
    type: string;
}

export type GroupedMarketType = {
    id: number;
    market_types: FoCategoryMarketType[];
};

export type MarketTypeCategory = {
    id: number;
    view_type: MarketViewType;
    sport_id: number;
    name: string;
    shortName?: string;
};

export type SportCategoryWithMatches = FoSportCategoryWithMatches;

export interface FavouriteCategoryWithMatches {
    matches: CategoryMatch[];
    top_market_types_by_league: GroupedMarketType[];
    has_more: boolean;
    open_match_id?: number[];
}

export type CategoryMatch = FoCategoryMatch & { isFake?: boolean; category?: number };

export type DatabaseSequence =
    | {
          sequence: number;
      }
    | {
          set: number;
          game: number;
          point?: number;
      };

export interface FoSidebetsMarketGroupMarket {
    translatedName: string;
    id: number;
    name: string;
    line: string;
    raw_line: number;
    status: 'OPEN' | 'SUSPENDED';
    singles_only: boolean;
    market_type_id: number;
    team_names: string[];
    player_names: string[];
    sequence: DatabaseSequence;
    outcomes_order_by: 'odds' | 'outcomeName' | 'outcomeCreated' | null;
    provider: number;
    outcomes: CategoryMatchMarketOutcome[];
}

export interface FoSidebetsMarketGroup {
    market_type_id: number;
    translatedName: string;
    sequence: DatabaseSequence;
    view_type: MarketViewType;
    result_keys: string[];
    fo_groups: number[];
    placeholder_count: number;
    outcome_names?: string[];
    uniqueSequences: { key: string; translation: string }[];
    markets: FoSidebetsMarketGroupMarket[];
}

interface SidebetMatch {
    sport_category_id: number;
    home_team_name?: string;
    away_team_name?: string;
    match_start: Date;
}

export interface SidebetMarketsResponse {
    markets: FoSidebetsMarketGroup[];
    match: SidebetMatch;
    foGroups: FoGroup[];
}

export type StreamInfo = Pick<DatabaseStreamInfo, 'provider' | 'url'>;

export type BetSlipUserState = {
    acceptAnyOddsChanges?: boolean;
    bankers: string[];
    betType: BET_TYPE;
    copiedFrom: string | null;
    disableInputs?: boolean;
    disableSystemIndividualUpdate?: boolean;
    disableSystemSingles?: boolean;
    isBankerSystem: boolean;
    MAStakeByMarketId: Record<string, string> | Readonly<Record<string, unknown>>;
    MASystemStakes: Record<string, string> | Readonly<Record<string, unknown>>;
    parentSystemStakes: { withSingles: string; noSingles: string };
    stakeByMarketId: Record<string, string> | Readonly<Record<string, unknown>>;
    systemStakes: Record<string, string> | Readonly<Record<string, unknown>>;
    userBetTypeSelection: BET_TYPE | undefined;
};

export type BetSlipPlacingState = {
    isConfirmed: boolean;
    isLoading: boolean;
    needsConfirm: boolean;
    receiptById: Record<string, string>;
    needsConfirmDuplicate?: boolean;
};

type Bet = {
    created_at?: Date;
    id: string;
    initial_stake_bc: number;
    initial_stake: number;
    is_ma: boolean;
    selections?: Selection[];
    stake_bc: number;
    stake: number;
    status: BetStatusEnum;
    ticket_id: string;
    transaction_id?: string;
    updated_at?: Date;
};

enum BetStatusEnum {
    WON = 'WON',
    LOST = 'LOST',
    PENDING = 'PENDING',
    CANCELLED = 'CANCELLED',
    MANUALLY_CANCELLED = 'MANUALLY_CANCELLED',
    MANUAL_ACCEPTANCE = 'MANUAL_ACCEPTANCE',
    MANUAL_ACCEPTANCE_DENIED = 'MANUAL_ACCEPTANCE_DENIED',
}

type BetWithOutcomeIds = Bet & { outcome_ids: number[] };

type ExtraInfo = {
    campaignId?: number;
    freeBet?: string;
    riskFreeBet?: string;
    multiplierBet?: string;
    brand?: string;
};

type SelectionStatus =
    | SelectionStatusesEnum.CANCELLED
    | SelectionStatusesEnum.LOST
    | SelectionStatusesEnum.MANUALLY_CANCELLED
    | SelectionStatusesEnum.PENDING
    | SelectionStatusesEnum.MANUAL_ACCEPTANCE
    | SelectionStatusesEnum.WON
    | SelectionStatusesEnum.MANUAL_ACCEPTANCE_DENIED;

enum SelectionStatusesEnum {
    MANUAL_ACCEPTANCE = 'MANUAL_ACCEPTANCE',
    MANUAL_ACCEPTANCE_DENIED = 'MANUAL_ACCEPTANCE_DENIED',
    CANCELLED = 'CANCELLED',
    WON = 'WON',
    LOST = 'LOST',
    PENDING = 'PENDING',
    MANUALLY_CANCELLED = 'MANUALLY_CANCELLED',
}

export enum TicketType {
    SINGLE = 'single',
    COMBO = 'combo',
    SYSTEM = 'system',
    TEASER = 'teaser',
    PARLAY_CARD = 'parlayCard',
    COMBO_CARD = 'comboCard',
}

export type UniqueSelection = {
    away_team: string;
    cashout_status?: string;
    category_id: number;
    display_line: string;
    display_odds: number;
    extra?: ExtraInfo;
    home_team: string;
    league_name: string;
    leagueName?: string;
    line?: number;
    market_id: number;
    market_name: string;
    market_sequence?: { [key: string]: number };
    market_type_id: number;
    marketTypeName: string;
    match_id: number;
    match_name: string;
    match_start: Date;
    odds: string;
    outcome_id: number;
    outcome_name: string;
    player_names: string[];
    product: ProductTypeEnum;
    region_name: string;
    score_away: null | number;
    score_home?: null | number;
    sport_category_id: number;
    sport_name: string;
    sportId?: number;
    status: SelectionStatus;
    team_names?: string[];
    view_type: string;
    teaser_points: number;
    expected_result_date?: Date;
    sport_icon: string | null;
};

enum ProductTypeEnum {
    LIVE = 'LIVE',
    PREMATCH = 'PREMATCH',
}

export interface TicketDetails {
    bets: BetWithOutcomeIds[];
    selectionByOutcomeId: {
        [key: number]: UniqueSelection;
    };
    systemsMeta?: SystemsMeta;
    ticket: Ticket;
    uniqueSelections: UniqueSelection[];
    userRiskSettings?: UserRiskSettings;
}

type BetWithMaBet = BetWithOutcomeIds & { maBet: BetWithOutcomeIds };

export type SystemsMeta = {
    [k in SystemBetType]: {
        bets: BetWithMaBet[];
        totalStake: number;
    };
};

export type SystemBet = {
    bets: BetWithMaBet[];
    totalStake: number;
    systemType: SystemBetType;
};

enum SystemBetType {
    DOUBLE = 'DOUBLE',
    EIGHTFOLD = 'EIGHTFOLD',
    FIVEFOLD = 'FIVEFOLD',
    FOURFOLD = 'FOURFOLD',
    NINEFOLD = 'NINEFOLD',
    SEVENFOLD = 'SEVENFOLD',
    SINGLE = 'SINGLE',
    SIXFOLD = 'SIXFOLD',
    TENFOLD = 'TENFOLD',
    TREBLE = 'TREBLE',
}

type UserRiskSettings = {
    bo_user_id?: number;
    cashout_disabled?: boolean;
    country_code: string;
    disabled_manual_acceptance?: boolean;
    display_cashout_disabled?: boolean;
    display_disabled_manual_acceptance?: boolean;
    display_limit_ratio?: number;
    id: number;
    limit_ratio?: number;
    livebet_grace_time?: number;
    risk_group_id: number;
    risk_group_name?: string;
    updated_at?: Date;
    user_id: string;
    user_limit_ratio?: number;
};

type MatchType = 'OUTRIGHT' | null;

export type FakeMarket = { id: number; outcomes: number[] };

export type MarketInfo = {
    away_team_name?: string;
    betting_end?: Date;
    category_id: number;
    home_team_name?: string;
    id: number;
    in_play: boolean;
    market_type_id: number;
    name: string; // added in sbgate
    match_id: number;
    match_name: string;
    match_status: 'OPEN' | 'LIVE';
    match_type: MatchType;
    player_names: any;
    outcomes: {
        id: number;
        status: OutcomeStatus;
        name: string;
        result_key: string;
        market_id: number;
    }[];
    raw_line: number;
    singles_only: boolean;
    sport_category_id: number;
    sport_id: number;
    status: MarketStatus;
    view_type: MarketViewType;
    same_match_combo_allowed: boolean;
    provider: number;
    expected_result_time?: Date;
    teasers_enabled?: boolean;
    sameMatchTeaserAllowed?: boolean;
    marketTypeName?: string;
};

export type Ticket = {
    sportName: string;
    marketTypeName: string;
    matchName: string;
    marketName: string;
    outcomeName: string;
    total_max_win: number;
    banker_outcome_ids: number[];
    bets: Bet[];
    bonusAmount: number;
    cashout_amount?: number;
    cashout_enabled: boolean;
    cashout_status?: string;
    cashoutAmount: number;
    cashoutEnabled: boolean;
    created_at: Date;
    currency: string;
    display_id: number;
    extra?: ExtraInfo;
    first_bet_odds: number;
    first_match: FirstMatch;
    id: string;
    is_match_live: boolean;
    ma_bets: Bet[];
    ma_bo_user_id?: number;
    ma_decided_at?: string;
    ma_stake_requested: number;
    ma_status?: string;
    max_win: number;
    num_bets_lost: number;
    num_bets_pending: number;
    num_bets_total: number;
    num_bets_won: number;
    potential_return: number;
    risk_group_name?: string;
    sport_id: number;
    sportId: number;
    status: SelectionStatus;
    ticket_type: TicketType;
    total_matches: number;
    total_stake: number;
    transaction_id: string;
    updated_at: Date;
    user_id: number;
    user_limit_ratio: number;
    product?: string;
    odds_format?: string;
    expected_result_date?: Date;
    parlay_card_name?: string;
    w2g_events?: TicketW2gEvent[] | null;
};

interface TicketW2gEvent {
    bet_id: string;
    threshold_reached: string;
    status: string;
}

export type FirstMatch = {
    categoryName?: string;
    marketTypeName?: string;
    league_name: string;
    market_id: number;
    market_name: string;
    match_name: string;
    outcome_name: string;
    region_name: string;
    sport_category_id: number;
    sport_name: string;
    sport_icon: string | null;
};

export type Turnover = {
    id: string;
    bets: number;
    share: number;
    stake: number;
    sum: number;
};

export type LeaderBoardSports = {
    amount: number;
    city: string;
    country: string;
    latitude: number;
    longitude: number;
    regionName: string;
    sportsbook: {
        awayTeamName: string;
        categoryId: number;
        categoryNames: Record<string, string>;
        fixtureName: string;
        homeTeamName: string;
        marketName: string;
        marketTypeNames: Record<string, string>;
        outcomeIds: number[];
        outcomeName: string;
        potentialReturn: number;
        product: string;
        sportId: number;
        sportNames: Record<string, string>;
        ticketId: string;
        ticketType: string;
        totalOdds: number;
    };
};

export type SearchResult = SportSearchCategory | SportSearchMatch;

export type SportMatchLayoutComponentParams = {
    match: CategoryMatch;
    matchStatus?: string;
};

export interface SportMatchLayoutHeadParams extends SportMatchLayoutComponentParams {
    matchHeader: RefObject<HTMLDivElement> | null;
}

type MinimalExtraMatchInfoForOdds = {
    betting_end: Date;
    status?: string;
    sport_category?: number;
};
export type SportMatchSidebetMarketProps = {
    marketType: FoSidebetsMarketGroup;
    match: MinimalExtraMatchInfoForOdds & {
        home_team_name?: string;
        away_team_name?: string;
        prefer_half_lines: boolean;
    };
    collapsed?: boolean;
};

export type FoGroup = { id: number; name: string; position?: number };

export type BetbuilderInfo = {
    allBetBuilderSportCategoryIds: number[];
    category: {
        market_types: MarketTypeCategory[];
        matches: CategoryMatch[];
    };
};

export type LightweightCategoryData = {
    id: number;
    name: string;
    fullSlug: string;
    sportCategoryId: number;
    icon: string | null;
    depth: number;
    sportIcon: string | null;
};

export interface ComboGeneratorMarketInfo extends BetSlipMinimalMarket {
    outcome: BetSlipMinimalMarket['outcomes'][number];
}

export type BoostedEventsParams = {
    language: Language;
    country: COUNTRY;
    province: string | null;
    layout: SportsLayout;
    limit: number;
    offset: number;
};

export interface FeaturedMatchesResponse {
    matches: CategoryMatch[];
    top_market_types_by_league: GroupedMarketType[];
    has_more: boolean;
    sportCategoryIds: number[];
}

export interface TeaserValidationMatchMarketResponse {
    matches: CategoryMatch[];
}

export interface BetSlipReplayState {
    betSlipMarketIdToOutcomeId: { [marketId: number]: number };
    betType: BET_TYPE;
}

export enum ScoreboardType {
    bgRounds = 'bgRounds',
    bgIcehockey = 'bgIcehockey',
    bgAmericanFootball = 'bgAmericanFootball',
    betgenius = 'betgenius',
    inhouse = 'inhouse',
    bgFootball = 'bgFootball',
    bgDarts = 'bgDarts',
    bgBaseball = 'bgBaseball',
    bgTennis = 'bgTennis',
    bgBasketball = 'bgBasketball',
}
