import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { translate } from '../../../../../services/translate';
import UiButton from '../../../../ui/button/UiButton';
import { MarketTypeGroupIdBetbuilder, MarketTypeGroupIdPopular } from '../../../../../services/sports/sidebets';
import SportMatchMarketsSearch from '../search/SportMatchMarketsSearch';
import { FoGroup } from '../../../../../services/sports/types';
import { useSportsMatchContext } from '../../../../../services/sports/match-helpers';
import { isRetail } from '../../../../../services/environment';
import { storageGet } from '../../../../../services/storage';

interface Props {
    currentMarketTypeGroup: number;
    onSetCurrentMarketTypeGroup: Dispatch<SetStateAction<number>>;
    allMarketTypeGroupFilters?: FoGroup[];
    showSearch: boolean;
}

export default function SportMatchMarketsMarketGroupFilter({
    currentMarketTypeGroup,
    onSetCurrentMarketTypeGroup,
    allMarketTypeGroupFilters,
    showSearch,
}: Props) {
    const [isSearching, setIsSearching] = useState(false);
    const { isBetbuilderActive, isBetbuilderIconClicked, setContext: setMatchContext } = useSportsMatchContext();
    const isRetailLayout = isRetail();
    const betBuilderInfoStatus = Boolean(storageGet('betBuilderInfoDismissed'));

    function handleFilterChange(marketTypeGroup) {
        if (marketTypeGroup.id !== MarketTypeGroupIdBetbuilder) {
            setMatchContext?.({ isBetbuilderActive: false });
        }
        onSetCurrentMarketTypeGroup(marketTypeGroup.id);
    }

    useEffect(() => {
        if (isBetbuilderIconClicked) {
            setMatchContext?.({ isBetbuilderIconClicked: false });
            onSetCurrentMarketTypeGroup(isBetbuilderActive ? MarketTypeGroupIdBetbuilder : MarketTypeGroupIdPopular);
        }
    }, [isBetbuilderIconClicked]);

    const showMarketTypeFilters = !isSearching && allMarketTypeGroupFilters && allMarketTypeGroupFilters.length > 1;

    return (
        <div className="market-filters">
            <div className="filters">
                {showSearch && !isRetailLayout && (
                    <SportMatchMarketsSearch isSearching={isSearching} onIsSearching={setIsSearching} />
                )}
                {showMarketTypeFilters &&
                    allMarketTypeGroupFilters?.map((marketTypeGroup) => (
                        <UiButton
                            size={isRetailLayout ? 'default' : 'small'}
                            key={marketTypeGroup.id}
                            selected={currentMarketTypeGroup === marketTypeGroup.id}
                            onClick={() => handleFilterChange(marketTypeGroup)}
                        >
                            {translate(marketTypeGroup.name, 'ui.sportsbook.fo-groups')}
                            {!isRetailLayout && !betBuilderInfoStatus && marketTypeGroup.name === 'Betbuilder' && (
                                <div className="arrow" />
                            )}
                        </UiButton>
                    ))}
            </div>
        </div>
    );
}
