import styled from 'styled-components';

export default styled.div`
    height: 1em;
    display: flex;
    align-items: flex-end;
    overflow-y: hidden;
    font-size: 1em;
    line-height: 0.95em;
`;
