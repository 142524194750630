import React, { useState } from 'react';
import Keyboard from 'react-simple-keyboard';
import Wrapper from './styles';
import 'react-simple-keyboard/build/css/index.css';

type Layout = 'default' | 'shift';

interface Props {
    setValue: any;
    width?: number | string;
    layout?: any;
    display?: any;
    onEnter?: () => void;
    onKeyPress?: (button: string) => void;
}

export function UiKeyboard({
    setValue,
    layout,
    width = 800,
    onEnter,
    display = {
        '{clear}': 'clear',
        '{shift}': 'shift',
        '{space}': 'space',
        '{bksp}': 'backspace',
    },
    onKeyPress,
}: Props) {
    const [layoutName, setLayoutName] = useState<Layout>('default');

    function handleKeyPress(button: string) {
        if (button === '{shift}' || button === '{lock}') {
            setLayoutName(layoutName === 'default' ? 'shift' : 'default');
        } else if (button === '{bksp}') {
            setValue((value) => value.slice(0, -1));
        } else if (button === '{space}') {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            setValue((value) => value + ' ');
        } else if (button === '{enter}') {
        } else if (button === '{clear}') {
            setValue('');
        } else {
            setValue((value) => `${value}${button}`);
        }
        if (onKeyPress) {
            onKeyPress(button);
        }
    }

    function handleKeyReleased(button: string) {
        if (onEnter && button === '{enter}') {
            setTimeout(() => {
                onEnter();
            }, 50);
        }
    }

    return (
        <Wrapper width={width}>
            <Keyboard
                onKeyPress={handleKeyPress}
                onKeyReleased={handleKeyReleased}
                layoutName={layoutName}
                layout={layout}
                display={display}
            />
        </Wrapper>
    );
}
