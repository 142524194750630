import styled, { css } from 'styled-components';

const medium = ({ medium }) =>
    medium &&
    css`
        max-width: 32px;
        min-width: 32px;
        height: 32px;
    `;

const small = ({ small }) =>
    small &&
    css`
        max-width: 21px;
        min-width: 21px;
        height: 21px;
    `;

const tiny = ({ tiny }) =>
    tiny &&
    css`
        max-width: 14px;
        min-width: 14px;
        height: 14px;
    `;

export default styled.img`
    animation: fadeIn 200ms linear;
    max-width: 26px;
    min-width: 26px ${tiny} ${small} ${medium};
    display: block;
`;
