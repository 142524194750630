import React, { useLayoutEffect, useState } from 'react';
import { getClient } from '../../services/environment';
import { isMobile } from '../../services/browser';
import { OPERATING_SYSTEM, getOS } from '../../services/device';
import { translate } from '../../services/translate';
import Logo from '../logo/Logo';
import UiButton from '../ui/button/UiButton';
import Wrapper from './styles';
import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { AppType } from '../../services/mobile-app';
import DangerousHtml from '../dangerous-html/DangerousHtml';

export default function MobileWebCheckBanner() {
    const client = getClient();
    const [isBanner, setIsBanner] = useState(false);
    const appType = getStoreValue(stores.appType);

    useLayoutEffect(() => {
        if ([AppType.ANDROID, AppType.IOS].includes(appType) || !isMobile()) {
            setIsBanner(false);
        } else {
            setIsBanner(true);
        }
    }, [appType]);

    if (isBanner) {
        return (
            <Wrapper>
                <div className="container">
                    <div className="content">
                        <div className="image-wrapper">
                            <Logo size={24} />
                        </div>
                        <p>{translate(['please-download-app', `Please download the APP`], 'ui.common')}</p>
                        <UiButton color="primary">
                            <DangerousHtml
                                content={translate(
                                    `${client}.${
                                        getOS() === OPERATING_SYSTEM.ANDROID ? 'android' : 'ios'
                                    }.app.download`,
                                )}
                            />
                        </UiButton>
                    </div>
                </div>
            </Wrapper>
        );
    }
    return null;
}
