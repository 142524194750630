import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { storageGet, storageSet } from '../storage';
import {
    BET_TYPE,
    DUPLICATE_TICKET_ERROR,
    MARKET_SPECIFIC_ERRORS,
    MA_DISABLED_ERROR,
    MA_ENABLED_ERROR,
    ODDS_CLOSED_ERRORS_BACKEND,
} from '../sports/constants';
import { SportsLayout } from '../../microservices/sbgate';
import { placeBetRequest } from '../../microservices/bets';
import { logger } from '../logger';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { getOddsForFoLine } from '../../microservices/sb-odds';
import { FOTranslationsByOutcomeId } from '../sport';
import { getOddsFormat, getSportsLayout } from '../sports/user-settings';
import uniq from 'lodash/uniq';
import { loadParlayCardData } from './parlay-card';
import { Language } from '../language';
import { verifyGeoLocation } from '../geocomply/geocomply';
import { FEATURE, isFeatureAvailable } from '../feature';
import { isRetail } from '../environment';
import { placeRetailTicket } from '../../microservices/retail-middleware';
import { requestLogin } from '../auth';
import { retail } from '../../stores/retail/retail';
import { getActiveCurrency } from '../currency';
import { Currency } from '@staycool/currency-helper';

export async function placeBet({ isManualAcceptance = false, allowErrorForMarket = false, isForceDuplicate = false }) {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const betSlipMarketIdToOutcomeIds = getStoreValue(stores.sports.parlayCard.betSlipMarketIdToOutcomeIds);
    const marketIds = Object.keys(betSlipMarketIdToOutcomeIds);
    const betSlipPlacingState = getStoreValue(stores.sports.parlayCard.betSlipPlacingState);
    const wallet = getStoreValue(stores.wallet);
    const isRetailLayout = isRetail();

    if (!isAuthenticated) {
        if (isRetailLayout) {
            if (!wallet) {
                return retail.retailModals.isNoBalanceModalVisible.set(true);
            }
        } else {
            requestLogin();
            return;
        }
    }
    if (
        !isRetailLayout &&
        !betSlipPlacingState.isConfirmed &&
        !isManualAcceptance &&
        !allowErrorForMarket &&
        isFeatureAvailable(FEATURE.BETSLIP_CONFIRM)
    ) {
        return stores.sports.parlayCard.betSlipPlacingState.set({ ...betSlipPlacingState, needsConfirm: true });
    }

    await verifyGeoLocation('BET');

    stores.sports.parlayCard.betSlipPlacingState.set({
        ...betSlipPlacingState,
        isLoading: true,
        needsConfirm: false,
        needsConfirmDuplicate: false,
    });
    stores.sports.parlayCard.betSlipErrors.set([]);

    let data = await createBetPlaceDataObjectFromStoreByMarketId(isManualAcceptance, isForceDuplicate);

    try {
        const betAction = isRetailLayout ? placeRetailTicket : placeBetRequest;
        // TODO: remove after proper fix will be deployed in b2b envs
        if (
            isFeatureAvailable(FEATURE.SEPARATE_REQUESTS_ON_NORMAL_PLUS_MA_BET) &&
            data.bets &&
            data.bets[0].stake > 0 &&
            data.bets[0].stakeRequest &&
            data.bets[0].stakeRequest > 0
        ) {
            const bet = data.bets[0];
            const normalBetData = { ...data, bets: [omit(bet, 'stakeRequest')], isForceDuplicate: true };
            const manualAcceptanceBetData = { ...data, bets: [{ ...bet, stake: 0 }] };
            const maTicketId = await betAction(manualAcceptanceBetData);
            marketIds.forEach((marketId) => {
                stores.sports.parlayCard.betSlipPlacingState.set((state) => {
                    state.receiptById[String(marketId) + '-ma'] = maTicketId;
                });
            });
            data = normalBetData;
        }
        const ticketId = await betAction(data);
        marketIds.forEach((marketId) => {
            stores.sports.parlayCard.betSlipPlacingState.set((state) => {
                state.receiptById[String(marketId)] = ticketId;
            });
        });
        setInitialParlayCardValues();
    } catch (error: any) {
        if (isRetailLayout && error.code === MA_ENABLED_ERROR) {
            error.code = MA_DISABLED_ERROR;
        }
        if (error.meta && MARKET_SPECIFIC_ERRORS === error.code) {
            const errors: any[] = [];
            Object.keys(error.meta).forEach((outcomeId) => {
                let errorCode = error.meta[outcomeId].code;
                if (ODDS_CLOSED_ERRORS_BACKEND.includes(errorCode)) {
                    errorCode = ODDS_CLOSED_ERRORS_BACKEND[0];
                }
                errors.push(errorCode);
            });
            uniq(errors).forEach((e) => addBetslipError(e));
        } else {
            if (error.code === DUPLICATE_TICKET_ERROR) {
                stores.sports.parlayCard.betSlipPlacingState.set({
                    ...betSlipPlacingState,
                    needsConfirmDuplicate: true,
                });
            }
            addBetslipError(error);
            handleManualAcceptance(error);
        }
        await loadParlayCardData(data.parlayCardId);

        logger.info('ParlayCardBetslipService', 'placeBet', `marketIds: ${marketIds}`);
        logger.info('ParlayCardBetslipService', 'placeBet', error);
    }

    const { receiptById } = getStoreValue(stores.sports.parlayCard.betSlipPlacingState);
    const placedMarketIds = Object.keys(receiptById);
    if (placedMarketIds.length) {
        stores.sports.parlayCard.betSlipMarketIdToOutcomeIds.set((state) => omit(state, placedMarketIds));
        storageSet('parlayCardSettings', {
            betSlipMarketIdToOutcomeId: {},
            updatedAt: new Date().getTime(),
        });
    }
    stores.sports.parlayCard.betSlipPlacingState.set((state) => ({ ...state, isLoading: false, isConfirmed: false }));
}

function addBetslipError(error) {
    stores.sports.parlayCard.betSlipErrors.set((state) => [...state, error]);
}

async function createBetPlaceDataObjectFromStoreByMarketId(isManualAcceptance = false, isForceDuplicate = false) {
    const oddsIdByOutcomeId = {} as Record<number, string>;
    const foTranslationsByOutcomeId = {} as Record<number, FOTranslationsByOutcomeId>;

    const manualAcceptanceStake = getStoreValue(stores.sports.parlayCard.manualAcceptanceStake);
    const betSlipMarketIdToOutcomeIds = getStoreValue(stores.sports.parlayCard.betSlipMarketIdToOutcomeIds);
    const stake = getStoreValue(stores.sports.parlayCard.stake);
    const deviceId = storageGet('uuid');
    const { markets, id } = getStoreValue(stores.sports.parlayCard.parlayCard);
    const language = getStoreValue(stores.language);
    let oddsByOutcomeId = getStoreValue(stores.sports.parlayCard.oddsByOutcomeId);

    if (isEmpty(oddsByOutcomeId)) {
        const marketIds = markets.map((market) => [market.id]);
        oddsByOutcomeId = await getOddsForFoLine(marketIds);
        stores.sports.parlayCard.oddsByOutcomeId.set((state) => ({ ...state, ...oddsByOutcomeId }));
    }

    const marketIds = Object.keys(betSlipMarketIdToOutcomeIds).map(Number);
    marketIds.forEach((marketId) => {
        const market = markets.find((market) => market.id === marketId);

        const outcomeIds = betSlipMarketIdToOutcomeIds[marketId];
        const outcomes = market?.outcomes.filter((outcome) => outcomeIds.includes(outcome.id));
        outcomes?.forEach((outcome) => {
            foTranslationsByOutcomeId[outcome.id] = {
                outcomeName: outcome ? outcome.name : '',
                marketName: market?.market_type_name,
                matchName: market?.match_name,
            } as FOTranslationsByOutcomeId;
            oddsIdByOutcomeId[outcome.id] = oddsByOutcomeId[outcome.id].odds_id;
        });
    });

    const data = {
        ticketType: 'parlayCard',
        timestamp: new Date(),
        foTranslationsByOutcomeId,
        oddsFormat: getOddsFormat(),
        layout: getSportsLayout(),
        isForceDuplicate: isRetail() || isForceDuplicate,
        deviceId,
        parlayCardId: id,
        language,
    };

    if (isFeatureAvailable(FEATURE.SEND_CURRENCY)) {
        data['currency'] = getActiveCurrency();
    }

    const bet = {
        stake: stake - manualAcceptanceStake,
        oddsIdByOutcomeId,
    };

    if (isManualAcceptance) {
        bet['stakeRequest'] = manualAcceptanceStake;
    }
    data['bets'] = [bet];

    return data as {
        ticketType: BET_TYPE;
        timestamp: Date;
        acceptAnyOddsChanges: boolean;
        copiedFrom: string | null;
        oddsFormat: string;
        layout: SportsLayout;
        foTranslationsByOutcomeId: Record<string, unknown>;
        oddsIdByOutcomeId: string;
        bets?: { stake: number; oddsIdByOutcomeId: Record<string, number>; stakeRequest?: number }[];
        forceDuplicate?: boolean;
        isForceDuplicate: boolean;
        deviceId: string | null;
        parlayCardId: number;
        language: Language;
        currency?: Currency;
    };
}

function handleManualAcceptance(manualAcceptanceError) {
    if (![MA_DISABLED_ERROR, MA_ENABLED_ERROR].includes(manualAcceptanceError.code)) {
        return;
    }

    const stake = getStoreValue(stores.sports.parlayCard.stake);
    const meta = Object.values(manualAcceptanceError.meta)[0] as number;
    const allowedStake = Math.floor(meta * stake);
    stores.sports.parlayCard.manualAcceptanceStake.set(stake - allowedStake);
}

function setInitialParlayCardValues() {
    stores.sports.parlayCard.betSlipErrors.set([]);
    stores.sports.parlayCard.betSlipMarketIdToOutcomeIds.set({} as Record<number, number[]>);
    stores.sports.parlayCard.stake.set(0);
    stores.sports.parlayCard.manualAcceptanceStake.set(0);
}
