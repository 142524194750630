import styled, { css } from 'styled-components';

export default styled.div`
    text-align: center;
    font-size: 0.7rem;
    color: var(--match-secondary-color);
    white-space: ${(props) => (props.wrap ? 'normal' : 'nowrap')};
    line-height: 0.8rem;

    display: flex;
    align-items: center;

    ${({ vertical }) =>
        vertical &&
        css`
            display: block;
        `};
    .time-hour {
        margin: ${(props) => (props.vertical ? '0.2rem 0 0 0' : '0 0 0 0.4rem')};
    }
`;
