import React from 'react';
import Wrapper from './styles';
import { filterStyleProps } from '../../../styles/utils';

interface Props {
    badge?;
    to?: string;
    exact?: boolean;
    style?;
    onClick?;
    children?: any;
    className?: any;
    $openNewTab?: boolean;
}

export default function UiNavLink({ $openNewTab, badge, children, ...rest }: Props) {
    return (
        <Wrapper {...filterStyleProps(rest)} target={$openNewTab ? '_blank' : ''}>
            {children}
        </Wrapper>
    );
}
