import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    $isRetailLayout?: boolean;
    $isLiveNow?: boolean;
};

export default styled.div<StyledProps>`
    display: flex;
    align-items: center;
    min-width: 100px;

    color: var(--match-secondary-color);
    font-size: 0.7rem;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    > *:not(:last-child) {
        margin-right: var(--spacing-8);
    }
    .match-live-data {
        display: flex;
        align-items: center;
        > *:not(:first-child) {
            margin-left: var(--spacing-8);
        }
    }
    .match-time {
        display: flex;
        align-items: center;
        order: ${({ $isRetailLayout, $isLiveNow }) => ($isRetailLayout && $isLiveNow ? '2' : '')};
        @media ${mediaQuery.isPhone} {
            display: block;
        }
    }
    .match-sport-icon {
        margin-right: var(--spacing-12);
    }
    .match-category {
        display: flex;
        align-items: center;
        min-width: 0;
        margin-left: 0.4rem;
        @media ${mediaQuery.isPhone} {
            margin-top: 0.2rem;
            margin-left: 0;
        }

        .category-name {
            padding: 0.1rem 0;
            max-width: 110px;

            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            border-bottom: 1px dotted;
            a {
                color: var(--match-secondary-color);
            }
        }
        @media ${mediaQuery.isPhone} {
            padding: 0.2rem 0;
        }
        &:hover a {
            color: var(--font-link-color);
        }
        .devider {
            margin: 0 0.4rem;
        }
    }

    .extra-info {
        display: flex;
        align-items: center;
        > *:not(:last-of-type) {
            margin-right: 0.4rem;
        }
        .category-name {
            a {
                color: var(--match-secondary-color);
            }
            @media ${mediaQuery.isPhone} {
                margin-left: 0.4rem;
            }
        }
    }
`;
