import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Wrapper from './styles';
import classNames from 'classnames';
import Svg from '../../../svg/Svg';

interface Props {
    seatsTaken?: number[];
    seatedPlayers?: number;
    availableSeats?: number;
}

export default function CasinoThumbnailSeats({ seatsTaken, seatedPlayers, availableSeats }: Props) {
    const BLACKJACK_DEFAULT_TABLE_SIZE = 7;
    const [tableSize, setTableSize] = useState(BLACKJACK_DEFAULT_TABLE_SIZE);

    useEffect(() => {
        if (seatedPlayers !== undefined && availableSeats != undefined) {
            const totalCounted = seatedPlayers + availableSeats;
            if (totalCounted) {
                return setTableSize(totalCounted);
            }
        }
        setTableSize(BLACKJACK_DEFAULT_TABLE_SIZE);
    }, [seatsTaken, seatedPlayers, availableSeats]);
    return (
        <Wrapper>
            {seatsTaken && (
                <Grid container className="casino-thumbnail-seats-container">
                    {Array.from(Array(tableSize).keys())
                        .reverse()
                        .map((seatNumber) => (
                            <Grid
                                item
                                key={seatNumber}
                                className={classNames('casino-thumbnail-seats-seat', {
                                    'casino-thumbnail-seats-seat-taken': seatsTaken?.includes(seatNumber),
                                })}
                            >
                                <div>
                                    <Svg icon="profile-icon" size={0.875} />
                                </div>
                            </Grid>
                        ))}
                </Grid>
            )}
        </Wrapper>
    );
}
