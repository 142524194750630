import React, { useEffect, useState } from 'react';
import UiDotsLoader from '../../ui/dots-loader/UiDotsLoader';
import { stores } from '../../../stores';
import ScriptLoader from '../../script-loader/ScriptLoader';
import { getStoreValue } from '../../../stores/store/utils';
import { environment } from '../../../stores/environment/environment';
import { useStore } from '../../../hooks/useStore';

interface Props {
    'campaign-id': number;
}

export default function MarketingAdactGame({ 'campaign-id': campaignId }: Props) {
    const [{ ADACT_API_KEY }] = useStore(environment);
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const user = getStoreValue(stores.user);

    useEffect(() => {
        if (isAuthenticated) {
            initWindow();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (isScriptLoaded) {
            window.renderAdact({ userId: user?.id, integrationUniqueId: user?.id });
        }
    }, [isScriptLoaded]);
    function initWindow() {
        window.ADACT_API_KEY = ADACT_API_KEY;
        window.ADACT_CAMPAIGN_ID = campaignId;
        window.ADACT_PLAYER_DATA = { userId: user?.id };

        setIsLoading(false);
    }
    if (!isAuthenticated) {
        return;
    }
    if (isLoading) {
        return <UiDotsLoader />;
    }

    return (
        <>
            <ScriptLoader
                url="https://d3c1rbfz6sx1n3.cloudfront.net/embedded-script.js"
                onLoad={() => setIsScriptLoaded(true)}
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <div id={`adact-${campaignId}`} adact-id={campaignId} adact-width="500" adact-height="400" />
        </>
    );
}
