import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    isCasinoLocked: boolean;
};
export default styled.div<StyledProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .show-more-button {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        color: ${({ theme }) => theme.paybackBooster.primaryColor};
        padding: 0 var(--spacing-16) var(--spacing-16) 0;
        width: 100%;
    }

    .game-image {
        width: 80px;
        height: 80px;
        background-position: center;
        background-size: cover;
        border-radius: 10px;
        z-index: 1;
        position: relative;
        @media ${mediaQuery.isSmallerThanLaptop} {
            width: 100px;
            height: 100px;
        }
    }

    .game-image-container {
        background-color: var(--surface-level-1-bg);
        border-radius: 10px;
        position: relative;
    }

    .game-disabled {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .games-container {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 0 var(--spacing-12);
        row-gap: var(--spacing-32);
        column-gap: var(--spacing-4);
        padding-bottom: var(--spacing-32);
    }

    .extra-payback-footer {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: ${({ theme }) => theme.paybackBooster.primaryColor};

        width: 100%;
        font-size: var(--font-12);

        padding: var(--spacing-12) var(--spacing-4) var(--spacing-4);
        color: #000000;

        position: absolute;
        text-align: center;
        top: 72px;

        @media ${mediaQuery.isSmallerThanLaptop} {
            top: 92px;
        }
    }
`;
