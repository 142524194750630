import { TerminalStatus } from '@staycool/retail-types/terminal';
import { Loyalty, LoyaltyCardResponse } from '@staycool/retail-types/loyalty';

export enum EventType {
    VoucherScanned = 'voucher-scanned',
    LoyaltyScanned = 'loyalty-scanned',
    TicketScanned = 'ticket-scanned',
    DepositAdded = 'deposit-added',
    DeviceQrScanned = 'device-qr-scanned',
    LimitExceeded = 'limit-exceeded',
    Success = 'success',
}

export enum Type {
    Warning = 'warning',
    SoftError = 'softError',
    Error = 'error',
    Info = 'info',
}

export interface Notification {
    id: number;
    name: string;
    source: string;
    description?: string;
    type: Type;
    terminal_id: number;
    created_at: Date;
    updated_at: Date;
}

export interface Terminal {
    id: number;
    bet_shop_id: number;
    status: TerminalStatus;
    hash: string;
    alias: string | null;
    address: string | null;
    notifications: Notification[];
}

export interface RetailEvent {
    type: EventType;
    data?: any;
    error?: string;
}

export interface PrintEvent {
    type: 'status';
    data?: {
        type: 'print-started' | 'ticket-in-output' | 'print-finished';
        task: PrintTask;
    };
    error?: string;
}

interface PrintTask {
    type: 'ticket' | 'voucher';
    current: number;
    total: number;
}

export interface RetailTerminalConfig {
    timezone: string;
    terminalId?: number;
    proxyDomain?: string;
    networkSettings: NetworkSettings;
}

interface NetworkSettings {
    ip?: string;
    mask?: string;
    gateway?: string;
    dns?: string;
    isDhcp?: boolean;
}

export interface LoyaltyResponseKiosk extends Loyalty {
    meta?: LoyaltyCardResponse['meta'];
}
