import React from 'react';
import Wrapper from './styles';
import Svg from '../../../svg/Svg';
import { translate } from '../../../../services/translate';
import { getLicence } from '../../../../services/licence';
import { Licence } from '@staycool/location';

export default function CoolbetFooterLicense() {
    function getFooterText(textType) {
        const textByLicenceCountry = {
            default: { legal: 'ui.footer.legaltext', responsible: 'ui.footer.responsible-mt-login' },
            [Licence.MALTA]: { legal: 'ui.footer.legaltext-mga', responsible: 'ui.footer.responsible-mt-login' },
            [Licence.SWEDEN]: { legal: 'ui.footer.legaltext-sweden', responsible: 'ui.footer.responsible-se' },
            [Licence.ESTONIA]: { legal: 'ui.footer.legaltext-estonia', responsible: 'ui.footer.responsible-ee' },
            [Licence.MEXICO]: {
                legal: 'ui.footer.legaltext-mexico',
                responsible: 'ui.footer.responsible-mx',
            },
        };

        const textForTheLicence = textByLicenceCountry[getLicence()] || textByLicenceCountry.default;
        return textForTheLicence[textType];
    }

    return (
        <Wrapper>
            <div className="coolbet-footer-license-content">
                <div className="coolbet-footer-license-content-box">
                    <div className="coolbet-footer-license-content-box-icon-container">
                        <div className="coolbet-footer-license-content-box-icon">
                            <Svg icon="anchor" />
                        </div>
                    </div>

                    <div className="coolbet-footer-license-content-box-text-container">
                        <div className="coolbet-footer-license-content-box-text-title">
                            {translate('Responsible Gaming', 'ui.footer')}
                        </div>
                        <div
                            className="coolbet-footer-license-content-box-text"
                            dangerouslySetInnerHTML={{
                                __html: translate(getFooterText('responsible')),
                            }}
                        />
                    </div>
                </div>
                <div className="coolbet-footer-license-content-box">
                    <div className="coolbet-footer-license-content-box-icon-container">
                        <div className="coolbet-footer-license-content-box-icon">
                            <Svg icon="license" />
                        </div>
                    </div>

                    <div className="coolbet-footer-license-content-box-text-container">
                        <div className="coolbet-footer-license-content-box-text-title">
                            {translate('License', 'ui.footer')}
                        </div>
                        <div
                            className="coolbet-footer-license-content-box-text"
                            dangerouslySetInnerHTML={{ __html: translate(getFooterText('legal')) }}
                        />
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
