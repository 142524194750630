import { useEffect, useState } from 'react';
import { bankIdAuthorize, bankIdPoll } from '../microservices/auth';
import { stores } from '../stores';
import { REGISTRATION_FLOW, setTokenAndAuthenticate } from './auth';
import { useForm } from './form';
import { openKycModal } from './kyc';
import { getLastActiveProductRoute, useRouter } from './router';
import { translate } from './translate';
import { DATE_YEAR_TIME_FORMAT, getFormattedDate } from './date';
import { getStoreValue } from '../stores/store/utils';
import { useStore } from '../hooks/useStore';

export const BANK_ID_AUTH_TYPE = {
    KYC: 'kyc',
    LOGIN: 'login',
    REGISTRATION: 'registration',
} as const;

export function useBankIdAuth() {
    const { navigateTo } = useRouter();
    const form = useForm(stores.registration.form);
    const ERROR = {
        INVALID_PERSONAL_ID: translate("Didn't find user with this personal id", 'ui.account'),
        NETWORK_CONNECTION_ERROR: 'The network connection was lost.',
        NO_CLIENT: translate('Start your bank ID app', 'ui.account'),
        NO_PERSONAL_ID: translate("Didn't find user with this personal id", 'ui.account'),
        NOT_VERIFIED: 'NOT_VERIFIED',
        OUTSTANDING_TRANSACTION: translate('Start your bank ID app', 'ui.account'),
        REGISTRATION_FAILED: translate('Registration failed, please contact customer support', 'ui.account'),
        TECHNICAL_ERROR: translate('Technical Error', 'ui.common'),
        USER_CANCEL: translate('You cancelled the verification process', 'ui.account'),
        LOAD_FAILED: 'Load failed',
    };
    const [pokerLoginInProgress] = useStore(stores.poker.loginInProgress);
    const [errorMessage, setErrorMessage] = useState('');
    const [sessionId, setSessionId] = useState('');
    const [isAuthenticationSuccessful, setIsAuthenticationSuccessful] = useState(false);
    const [loginProgressMessage, setLoginProgressMessage] = useState('');

    let interval;

    useEffect(() => {
        if (sessionId) {
            interval = setInterval(async () => {
                await tryLoginOrRegisterWithBankId();
            }, 2000);
            return () => clearInterval(interval);
        }
    }, [sessionId]);

    async function startAuth(authType) {
        setErrorMessage('');
        await startSession(authType);
    }

    async function startSession(authType) {
        try {
            setLoginProgressMessage('Verification process started');
            const { sessionId, redirectUrl } = await bankIdAuthorize(authType, form.personalId.value);

            if (sessionId) {
                setSessionId(sessionId);
                setLoginProgressMessage('Please check your BankID app');
            }
            if (redirectUrl) {
                window.location.assign(redirectUrl);
            }
        } catch (error: any) {
            setErrorMessage(ERROR[error.message] || ERROR.TECHNICAL_ERROR);
        }
    }

    async function tryLoginOrRegisterWithBankId() {
        try {
            const { askSmsVerification, country, email, id, phoneNumber, phonePrefix, token, type, userInfo } =
                await bankIdPoll(sessionId);
            setLoginProgressMessage('Processing BankID verification');
            clearInterval(interval);

            const isLoginAttempt = !type || ![BANK_ID_AUTH_TYPE.KYC, BANK_ID_AUTH_TYPE.REGISTRATION].includes(type);

            if (askSmsVerification && isLoginAttempt) {
                stores.modals.isLoginFormOpen.set(false);
                stores.verification.isVerificationActive.set(true);
                stores.verification.data.set({
                    country,
                    email,
                    phoneNumber,
                    phonePrefix,
                    temporaryToken: token,
                    userId: id,
                });
                return;
            }

            if (type === BANK_ID_AUTH_TYPE.KYC) {
                stores.modals.isBankIdModalOpen.set(false);
                return;
            }

            if (userInfo) {
                form.setInputValue({
                    ...userInfo,
                    eVerificationMethod: REGISTRATION_FLOW.BANK_ID,
                    sessionId: sessionId,
                });
            }

            if (token) {
                await setTokenAndAuthenticate(token);
                if (!pokerLoginInProgress) {
                    stores.modals.isLoginModalOpen.set(false);
                    if (form.sessionId) {
                        stores.isRegistrationComplete.set(true);
                        navigateTo(getLastActiveProductRoute(getStoreValue(stores.language)));
                    }
                }
            }

            setIsAuthenticationSuccessful(true);
        } catch (error: any) {
            if (error.code === 3006) {
                setErrorMessage(
                    translate('login-blocked-until', 'ui.responsible-gaming', {
                        loginBlockExpiryTime: getFormattedDate({
                            date: error?.details?.loginBlockedUntil,
                            format: DATE_YEAR_TIME_FORMAT,
                            useDots: true,
                        }),
                    }),
                );
                return;
            }
            if (![ERROR.NETWORK_CONNECTION_ERROR, ERROR.NOT_VERIFIED, ERROR.LOAD_FAILED].includes(error.message)) {
                clearInterval(interval);
                if (error.kycToken) {
                    openKycModal(error.kycToken, error.kycTokenExpiry);
                    stores.modals.isLoginModalOpen.set(false);
                    return;
                }
                setErrorMessage(ERROR[error.message] || ERROR.TECHNICAL_ERROR);
            }
        }
    }

    return {
        errorMessage,
        isAuthenticationSuccessful,
        loginProgressMessage,
        sessionId,
        startAuth,
    };
}
