import React from 'react';
import { translate } from '../../../../../services/translate';
import { stores, useStoreWithSelector } from '../../../../../stores';
import UiDotsLoader from '../../../../ui/dots-loader/UiDotsLoader';
import SportMatchTurnoverChart from '../../turnover/chart/SportMatchTurnoverChart';
import Wrapper from './styles';
import SportMatchTimeLive from '../../time/live/SportMatchTimeLive';
import { FoCategoryMatch } from '@staycool/sbgate-types';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    match: FoCategoryMatch;
}

export default function SportMatchScoreboardBgIcehockey({ match }: Props) {
    const [{ isPhone }] = useStore(media);
    const [inplayData] = useStoreWithSelector(stores.sports.inplayMatchData, (state) => state[match.id], [match.id]);
    const { home_team_points, away_team_points, custom } = inplayData || {};
    const { players_on_ice_count, in_progress_penalty_count, statistics } = custom || {};
    const { total_attacks, shots_on_target, total_penalties } = statistics || {};
    if (!total_attacks) {
        return <UiDotsLoader />;
    }
    const totalAttacks = Number(total_attacks.home) + Number(total_attacks.away);
    const totalShots = Number(shots_on_target.home) + Number(shots_on_target.away);
    const homeAttacksPercentage = (Number(total_attacks.home) / totalAttacks) * 100 || 50;
    const homeShotsPercentage = (Number(shots_on_target.home) / totalShots) * 100 || 50;
    const awayAttacksPercentage = (Number(total_attacks.away) / totalAttacks) * 100 || 50;
    const awayShotsPercentage = (Number(shots_on_target.away) / totalShots) * 100 || 50;
    return (
        <Wrapper $isPhone={isPhone} sportSlug={match.sport_slug} className="animated fadeIn">
            <div className="scores">
                <div className="score">
                    <h1 className="score-title">{match.home_team_name}</h1>
                    <span className="score-number"> {home_team_points || 0}</span>
                    {players_on_ice_count.home > players_on_ice_count.away && (
                        <div className="powerplay">{translate('Powerplay', 'ui.scoreboard.hockey')}</div>
                    )}
                </div>
                <div className="timer">
                    <SportMatchTimeLive matchId={match.id} sportId={match.sport} isOurLivebet={match.livebet_our} />
                </div>
                <div className="score">
                    <h1 className="score-title">{match.away_team_name}</h1>
                    <span className="score-number"> {away_team_points || 0}</span>
                    {players_on_ice_count.away > players_on_ice_count.home && (
                        <div className="powerplay">{translate('Powerplay', 'ui.scoreboard.hockey')}</div>
                    )}
                </div>
            </div>
            <div className="stats">
                <div>
                    <h3 className="stats-title">{translate('Total Penalties', 'ui.scoreboard.hockey')}</h3>
                    <div className="stats-number red-card">{total_penalties.home || 0}</div>
                </div>

                <div>
                    <h3 className="stats-title">{translate('Penalty', 'ui.scoreboard.hockey')}</h3>
                    <div className="stats-number">{in_progress_penalty_count.home || 0}</div>
                </div>

                <div className="stats-divider" />

                <div>
                    <h3 className="stats-title">{translate('Total Penalties', 'ui.scoreboard.hockey')}</h3>
                    <div className="stats-number red-card">{total_penalties.away || 0}</div>
                </div>

                <div>
                    <h3 className="stats-title">{translate('Penalty', 'ui.scoreboard.hockey')}</h3>
                    <div className="stats-number">{in_progress_penalty_count.away || 0}</div>
                </div>
            </div>

            <div className="stats">
                <div>
                    <h3 className="stats-title">{translate('Total Attacks', 'ui.scoreboard.hockey')}</h3>
                    <div className="stats-number">{total_attacks.home}</div>
                    <div className="stats-chart">
                        <SportMatchTurnoverChart percentage={homeAttacksPercentage} />
                    </div>
                </div>

                <div>
                    <h3 className="stats-title">{translate('Shots on goal', 'ui.scoreboard.hockey')}</h3>
                    <div className="stats-number">{shots_on_target.home}</div>

                    <div className="stats-chart">
                        <SportMatchTurnoverChart percentage={homeShotsPercentage} />
                    </div>
                </div>

                <div className="stats-divider" />

                <div>
                    <h3 className="stats-title">{translate('Total Attacks', 'ui.scoreboard.hockey')}</h3>
                    <div className="stats-number">{total_attacks.away}</div>

                    <div className="stats-chart">
                        <SportMatchTurnoverChart percentage={awayAttacksPercentage} />
                    </div>
                </div>

                <div>
                    <h3 className="stats-title">{translate('Shots on goal', 'ui.scoreboard.hockey')}</h3>
                    <div className="stats-number">{shots_on_target.away}</div>

                    <div className="stats-chart">
                        <SportMatchTurnoverChart percentage={awayShotsPercentage} />
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
