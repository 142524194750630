import {
    SmartIdProfile,
    SmartIdSessionResult,
    pollSmartIdSession,
    startSmartIdAuthentication,
} from '../microservices/auth';
import { useEffect, useState } from 'react';
import { translate } from './translate';
import { LOGIN_METHOD, handleAuthenticationFailure, handleAuthenticationSuccess } from './auth';

export enum SMART_ID_FLOW {
    LOGIN = 'login',
    REGISTRATION = 'registration',
}

const DEFAULT_ERROR_MESSAGE = translate('Failed to authenticate with Smart ID, please try again', 'ui.account');
const ERROR_MESSAGES_BY_SESSION_RESULT: Partial<Record<SmartIdSessionResult, string>> = {
    [SmartIdSessionResult.TIMEOUT]: translate('Session timeout', 'ui.account'),
    [SmartIdSessionResult.USER_REFUSED_CERT_CHOICE]: translate('Session cancelled by user', 'ui.account'),
    [SmartIdSessionResult.USER_REFUSED_CONFIRMATIONMESSAGE_WITH_VC_CHOICE]: translate(
        'Session cancelled by user',
        'ui.account',
    ),
    [SmartIdSessionResult.USER_REFUSED_CONFIRMATIONMESSAGE]: translate('Session cancelled by user', 'ui.account'),
    [SmartIdSessionResult.USER_REFUSED_DISPLAYTEXTANDPIN]: translate('Session cancelled by user', 'ui.account'),
    [SmartIdSessionResult.USER_REFUSED_VC_CHOICE]: translate('Session cancelled by user', 'ui.account'),
    [SmartIdSessionResult.USER_REFUSED]: translate('Session cancelled by user', 'ui.account'),
};

export function useSmartIdAuth(authType: SMART_ID_FLOW) {
    const [verificationCode, setVerificationCode] = useState<string>();
    const [isAuthenticationSuccessful, setIsAuthenticationSuccessful] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [profile, setProfile] = useState<SmartIdProfile>();
    const [redirectUrl, setRedirectUrl] = useState<string>();
    let interval;

    useEffect(() => {
        return () => {
            clearInterval(interval);
        };
    }, []);

    async function startAuthentication(personalId: string) {
        setErrorMessage(undefined);
        clearSession();
        try {
            const { sessionId, verificationCode: _verificationCode } = await startSmartIdAuthentication(personalId);
            setVerificationCode(_verificationCode);
            startPolling(sessionId);
        } catch (error) {
            setErrorMessage(DEFAULT_ERROR_MESSAGE);
            clearSession();
        }
    }

    function startPolling(sessionId: string) {
        interval = setInterval(async () => {
            try {
                const response = await pollSmartIdSession({
                    authType,
                    sessionId,
                });

                if (response.token) {
                    try {
                        const successResponse = await handleAuthenticationSuccess(response, LOGIN_METHOD.SMART_ID);
                        if (successResponse?.navigateTo && window.location.pathname !== successResponse.navigateTo) {
                            setRedirectUrl(successResponse.navigateTo);
                        }
                        setIsAuthenticationSuccessful(true);
                    } catch (response: any) {
                        handleAuthenticationFailure(response, LOGIN_METHOD.SMART_ID);
                        clearSession();
                    } finally {
                        clearInterval(interval);
                    }
                    return;
                }

                const { result, profile } = response;

                if (!result) {
                    return;
                }

                clearInterval(interval);

                if (result !== SmartIdSessionResult.OK) {
                    setErrorMessage(ERROR_MESSAGES_BY_SESSION_RESULT[result] || DEFAULT_ERROR_MESSAGE);
                    return;
                }

                setProfile(profile);
                setIsAuthenticationSuccessful(true);
            } catch (error) {
                setErrorMessage(DEFAULT_ERROR_MESSAGE);
                clearInterval(interval);
                clearSession();
            }
        }, 5_000);
    }

    function clearSession() {
        setVerificationCode(undefined);
        setProfile(undefined);
        setRedirectUrl(undefined);
        setIsAuthenticationSuccessful(false);
    }

    return {
        startAuthentication,
        verificationCode,
        errorMessage,
        isAuthenticationSuccessful,
        profile,
        redirectUrl,
    };
}
