import memoize from 'lodash/memoize';
import { SportsOddsFormat } from '../microservices/sbgate';
import { getOddsFormat } from './sports/user-settings';

export const convertOdds = (odds) => {
    const format = getOddsFormat();
    return converters[format](odds);
};

const converters = {
    [SportsOddsFormat.DECIMAL]: (x) => parseFloat(x || 1).toFixed(2),
    [SportsOddsFormat.AMERICAN]: memoize((odds) => convertDecimalToAmerican(odds)),
};

export const getPreciseOdds = (odds) => {
    const format = getOddsFormat();
    const preciseOdds = {
        [SportsOddsFormat.DECIMAL]: odds,
        [SportsOddsFormat.AMERICAN]: convertAmericanToDecimal(odds),
    };
    return preciseOdds[format];
};

const convertAmericanToDecimal = (americanOdds: string): number => {
    if (americanOdds === '-') {
        return 1;
    }
    const parsedAmericanOdds = parseInt(americanOdds, 10);
    const preciseOdds = parsedAmericanOdds > 0 ? parsedAmericanOdds / 100 + 1 : 100 / Math.abs(parsedAmericanOdds) + 1;
    return parseFloat(preciseOdds.toFixed(8));
};

export const convertDecimalToAmerican = (odds: string): string => {
    const parsedOdds = parseFloat(odds) || 1;
    if (parsedOdds === 1) {
        return '-';
    }
    if (parsedOdds >= 2) {
        return `+${Math.round((parsedOdds - 1) * 100)}`;
    }
    return `${Math.round(-100 / (parsedOdds - 1))}`;
};
