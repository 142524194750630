import { getFilteredTransactions } from '../../microservices/wallet';
import { getWalletTransactionsByProduct } from '../../microservices/wallet-proxy';
import { translate } from '../translate';
import { TransactionProduct } from './types';

export async function getTransactionsByProduct(filter) {
    const { data, has_next_page } = await getWalletTransactionsByProduct(filter);
    return {
        transactions: data.map((transaction) => {
            const { description, ticket_display_id } = transaction;
            transaction.description = translate(description, 'ui.account', [ticket_display_id]);
            return transaction;
        }),
        hasNextPage: has_next_page,
    };
}

export async function getTransactions(filter) {
    if (filter.product === TransactionProduct.BONUS) {
        filter.product = '';
        filter.type = 'BONUS';
    }
    const response = await getFilteredTransactions(filter);
    return {
        transactions: response.data.map((transaction) => {
            const { product, description, ticket_display_id, type } = transaction;
            if (product === TransactionProduct.CASINO && description.startsWith('casino.')) {
                transaction.description = translate(description);
            } else if (product === TransactionProduct.SPORTSBOOK && type !== TRANSACTION_TYPE.BONUS) {
                transaction.description = translate(description, 'ui.account', [ticket_display_id]);
            } else if (product === TransactionProduct.RACEBOOK) {
                const racebookDescriptions = ['TICKET_PLACED_DESC', 'TICKET_WON_DESC', 'TICKET_VOIDED_DESC'];
                if (racebookDescriptions.includes(description)) {
                    transaction.description = translate(description, 'ui.racebook', [ticket_display_id]);
                }
            } else if (product === TransactionProduct.VIRTUAL_SPORTS) {
                transaction.description = translate(transaction.description, 'ui.account.vs', [ticket_display_id]);
            } else if (
                product === TransactionProduct.POKER &&
                ![TRANSACTION_TYPE.POKER_CASHBACK, TRANSACTION_TYPE.BONUS].includes(type) &&
                !Object.values(POKER_PLAYTECH_TRANSACTION_TYPE).includes(
                    type as keyof typeof POKER_PLAYTECH_TRANSACTION_TYPE,
                )
            ) {
                const descriptionSplit = description.split(' #');
                if (descriptionSplit.length > 1) {
                    transaction.description = `${translate(descriptionSplit[0], 'ui.account.poker')} #${
                        descriptionSplit[1]
                    }`;
                } else if (descriptionSplit[0] === TRANSACTION_DESCRIPTION.GENERIC_CANCELLED) {
                    transaction.description = `${translate(descriptionSplit[0], 'ui.account')}`;
                } else {
                    transaction.description = `${translate(descriptionSplit[0], 'ui.account.poker')}`;
                }
            } else if (product === TransactionProduct.CASINO_IN_POKER) {
                const descriptionSplit = transaction.description.split(' #');
                transaction.description = `${translate(descriptionSplit[0], 'ui.account')} #${descriptionSplit[1]}`;
            } else if (
                product === TransactionProduct.POKER &&
                Object.values(POKER_PLAYTECH_TRANSACTION_TYPE).includes(
                    type as keyof typeof POKER_PLAYTECH_TRANSACTION_TYPE,
                )
            ) {
                let action = description.match(/^[\S]+/g);
                const tableNumber = description.match(/[0-9]{8,}/g);
                const tournament = description.match(/".+"/g);
                const content = tableNumber
                    ? `#${tableNumber[tableNumber.length - 1]}`
                    : tournament
                    ? `${tournament[tournament.length - 1]}`
                    : '';

                if (type === POKER_PLAYTECH_TRANSACTION_TYPE.POKER_TOURN_REG) {
                    action = ['register'];
                }
                if (action) {
                    transaction.description = `${translate(action[0].toLowerCase(), 'ui.account.poker')} ${content} `;
                }
            }
            return transaction;
        }),
        hasNextPage: response.has_next_page,
    };
}

export const TRANSACTION_TYPE = {
    BONUS: 'BONUS',
    CANCELLATION: 'CANCELLATION',
    DEVCODE_MUCHBETTER_WITHDRAWAL: 'DEVCODE_MUCHBETTER_WITHDRAWAL',
    POKER_CASHBACK: 'POKER_CASHBACK',
    TRUSTLY_DEPOSIT: 'TRUSTLY_DEPOSIT',
    TRUSTLY_WITHDRAWAL: 'TRUSTLY_WITHDRAWAL',
};

export const TRANSACTION_DESCRIPTION = {
    BANK: 'Bank',
    CASH: 'Cash',
    GENERIC_CANCELLED: 'GENERIC_CANCELLED_DESC',
    WEBPAY: 'WebPay',
    WITHDRAWAL_DECLINED: 'WITHDRAWAL_DECLINED_DESC',
};

const POKER_PLAYTECH_TRANSACTION_TYPE = {
    POKER_BUYING_CHIPS: 'POKER_BUYING_CHIPS',
    POKER_REFUND: 'POKER_REFUND',
    POKER_SELLING_CHIPS: 'POKER_SELLING_CHIPS',
    POKER_TOURN_ADDON: 'POKER_TOURN_ADDON',
    POKER_TOURN_REBUY: 'POKER_TOURN_REBUY',
    POKER_TOURN_REG: 'POKER_TOURN_REG',
    POKER_TOURN_WIN: 'POKER_TOURN_WIN',
    POKER_TOURN_REG_TOKEN: 'POKER_TOURN_REG_TOKEN',
    POKER_TOURN_REG_WON_TOKEN: 'POKER_TOURN_REG_WON_TOKEN',
    POKER_TOURN_UNREG: 'POKER_TOURN_UNREG',
    POKER_TOURN_WIN_TOKEN: 'POKER_TOURN_WIN_TOKEN',
    POKER_TOURN_UNREG_TOKEN: 'POKER_TOURN_UNREG_TOKEN',
    POKER_TOURN_UNREG_WON_TOKEN: 'POKER_TOURN_UNREG_WON_TOKEN',
    EXTERNAL_BONUS: 'EXTERNAL_BONUS',
} as const;
