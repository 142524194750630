import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    .crm-inbox {
        width: 400px;
        padding: 1rem;
        border-radius: 10px;
        background-color: var(--surface-level-2-bg);
        border: var(--surface-level-2-border);
        box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
        z-index: 110;

        position: absolute;
        top: 100%;
        right: -30px;
        margin-top: 0.8rem;
        &:after,
        &:before {
            bottom: 100%;
            right: 36px;
            border: solid transparent;
            content: ' ';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &:after {
            border-color: rgba(136, 183, 213, 0);
            border-bottom-color: var(--surface-level-2-bg);
            border-width: 10px;
            margin-left: -10px;
        }
        &:before {
            border-color: rgba(194, 225, 245, 0);
            border-bottom-color: var(--surface-level-2-bg);
            border-width: 5px;
            margin-left: -5px;
        }
        .show-all-messages {
            margin-top: ${({ theme }) => theme.layout.gutter};
            text-align: center;
            display: block;
            font-size: 0.875rem;
        }

        @media ${mediaQuery.isPhone} {
            width: 100%;
            right: initial;
        }
    }
`;
