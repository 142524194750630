import moment from 'moment';
import { getServiceUrl, httpGet, httpPost, httpPut } from '../services/api';
import {
    AuthenticationResponse,
    COUNTRIES_WITHOUT_PERSONAL_DATA_IN_PERSONAL_ID,
    COUNTRIES_WITH_PERSONAL_ID,
    LOGIN_METHOD,
    handleAuthenticationFailure,
    handleAuthenticationSuccess,
    logout,
} from '../services/auth';
import { COUNTRY } from '../services/country';
import { getOS } from '../services/device';
import { isRetail } from '../services/environment';
import { logger } from '../services/logger';
import { storageSet } from '../services/storage';
import { getToken, isTokenExpired, isTokenRenewalRequired, setToken } from '../services/token';
import { User } from '../services/types';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { SMART_ID_FLOW } from '../services/smart-id';
import { Currency } from '../services/wallet/types';
import { environment } from '../stores/environment/environment';

const getUrl = (url) => getServiceUrl('auth', url);
const renewTokenUrl = getUrl('renew-token');

let renewTokenPromise;

export function changeUserPassword(params: { newPassword: string; oldPassword: string }) {
    const url = getUrl('password/change');
    return httpPost<void>(url, params);
}

export function renewUserPassword(params: { newPassword: string; oldPassword: string; email: string }) {
    const url = getUrl('password/renew');
    return httpPost<void>(url, params);
}

export async function sendTwoFactorEmailPin(email) {
    const url = getUrl(`send-2fa-code-with-email/`);

    return httpPost(url, {
        email,
    });
}

export function emailLogin(params: { email: string; password: string }) {
    const url = getUrl('login');
    return httpPost<AuthenticationResponse>(url, params);
}

export function facebookLogin(fbAccessToken: string) {
    const url = getUrl('login/facebook');
    return httpPost<AuthenticationResponse>(url, {}, { headers: { 'fb-access-token': fbAccessToken } });
}

export async function getHasPassword() {
    const url = getUrl('has-password');
    return httpGet<boolean>(url);
}

export function googleLogin(params: { clientId: string; code: string; redirectUri: string }) {
    const url = getUrl('login/google');
    return httpPost<AuthenticationResponse>(url, params);
}

export function tokenLogin(params: { token: string }) {
    const url = getUrl('login/token');
    return httpPost<AuthenticationResponse>(url, params);
}

export async function linkWithGoogle(code) {
    try {
        storageSet('preferLogin', LOGIN_METHOD.GOOGLE);
        const { GOOGLE_CLIENT_ID } = getStoreValue(environment);

        const url = getUrl('link/google');
        const clientId = GOOGLE_CLIENT_ID;
        const redirectUri = window.location.origin;

        await httpPost<AuthenticationResponse>(url, {
            clientId,
            code,
            redirectUri,
        });
    } catch (response: any) {
        handleAuthenticationFailure(response, LOGIN_METHOD.GOOGLE);
    }
}

export async function impersonateWithGoogle(code, impersonationToken) {
    try {
        storageSet('preferLogin', LOGIN_METHOD.GOOGLE);
        const { GOOGLE_CLIENT_ID } = getStoreValue(environment);

        const url = getUrl('login/impersonate');
        const clientId = GOOGLE_CLIENT_ID;
        const redirectUri = window.location.origin;

        const authenticationResponse = await httpPost<AuthenticationResponse>(url, {
            clientId,
            code,
            redirectUri,
            impersonationToken,
        });

        return await handleAuthenticationSuccess(authenticationResponse, LOGIN_METHOD.GOOGLE);
    } catch (error: any) {
        error.message = error.code;
        handleAuthenticationFailure(error, LOGIN_METHOD.GOOGLE);
    }
}

export async function linkWithFacebook(accessToken) {
    try {
        storageSet('preferLogin', LOGIN_METHOD.FACEBOOK);
        const url = getUrl('link/facebook');
        const headers = {
            'fb-access-token': accessToken,
        };
        await httpPost<AuthenticationResponse>(url, {}, { headers });
    } catch (response: any) {
        handleAuthenticationFailure(response, LOGIN_METHOD.FACEBOOK);
    }
}

export async function update2faStatus(updateData) {
    return httpPut(getUrl('is-2fa-enabled/'), updateData);
}

export async function renewToken(isRetry = false) {
    const isRetailLayout = isRetail();
    if (isRetailLayout) {
        return;
    }
    try {
        renewTokenPromise = httpPost(renewTokenUrl);
        const requestStartTime = Date.now();
        const { token } = await renewTokenPromise;
        const requestTime = (requestStartTime + Date.now()) / 2;
        setToken(token, requestTime);
    } catch (error: any) {
        if (![401, 403].includes(error.status) && !isRetry) {
            await renewToken(true);
        } else {
            logger.error('AuthMicroservice', 'renewToken', error);
            logout();
            if (isRetry) {
                throw error;
            }
        }
    } finally {
        renewTokenPromise = undefined;
    }
}

export async function requestResetPassword(email: string) {
    return httpPost(getUrl('password/request-reset'), { email });
}

export async function verifyResetPasswordToken({ token }) {
    return httpPost(getUrl('password/reset-token-verify'), { token });
}

export async function bankIdAuthorize(authType, personalId) {
    const url = getUrl('bank-id/authorize/');
    return httpPost<{ sessionId: string; redirectUrl?: string }>(url, { personalId, type: authType });
}

export async function bankIdPoll(sessionId) {
    const url = getUrl('bank-id/poll/');
    return httpPost<BankIdPollResponse>(url, { sessionId });
}

export function getUserSignupProperty() {
    const url = getUrl('signup-property');
    return httpGet<{ signupProperty: string }>(url);
}

export async function resetPassword({ token, password }) {
    return httpPost(getUrl('password/reset'), { token, password });
}

export async function tryUpdateToken(url = '') {
    const token = getToken();

    if (url.includes(renewTokenUrl) || !token) {
        return;
    }

    if (renewTokenPromise) {
        return renewTokenPromise;
    }

    if (isTokenExpired()) {
        await renewToken();
        if (isTokenExpired()) {
            return logout();
        }
    }

    if (isTokenRenewalRequired()) {
        return renewToken();
    }
}

export async function signup({
    acceptTermsAndConditions,
    address,
    alias,
    birthDate,
    city,
    country,
    currency,
    email,
    eVerificationMethod,
    fathersName,
    firstName,
    gender,
    hasAcceptedAgeAndPlayingOwnBehalf,
    language,
    lastName,
    mothersName,
    password,
    personalId,
    personalIdType,
    phoneNumber,
    province,
    sessionId,
    socialMediaRegistrationToken,
    subscribeDirectMail,
    subscribeMailList,
    subscribePhoneCalls,
    subscribeSms,
    zip,
    loqateLookupAddressId,
}: {
    acceptTermsAndConditions: boolean;
    address: string;
    alias: string;
    birthDate: { day: number; month: number; year: number };
    city: string;
    country: COUNTRY;
    currency: Currency;
    email: string;
    eVerificationMethod?: string;
    fathersName?: string;
    firstName: string;
    gender: string;
    hasAcceptedAgeAndPlayingOwnBehalf: boolean;
    language: string;
    lastName: string;
    mothersName?: string;
    password: string;
    personalId?: string;
    personalIdType?: string;
    phoneNumber: string;
    province?: string;
    sessionId?: string;
    socialMediaRegistrationToken?: string;
    subscribeDirectMail?: boolean;
    subscribeMailList?: boolean;
    subscribePhoneCalls?: boolean;
    subscribeSms?: boolean;
    zip: string;
    loqateLookupAddressId?: string;
}) {
    if (fathersName && mothersName) {
        lastName = `${fathersName} ${mothersName}`;
    }

    const signupData = {
        acceptTermsAndConditions,
        address,
        alias,
        birthDate: '',
        city,
        country,
        currency,
        email,
        eVerificationMethod,
        firstName,
        gender,
        hasAcceptedAgeAndPlayingOwnBehalf,
        language,
        lastName,
        password,
        personalId,
        phoneNumber,
        province,
        sessionId,
        socialMediaRegistrationToken,
        subscribeDirectMail,
        subscribeMailList,
        subscribePhoneCalls,
        subscribeSms,
        zip,
        personalIdType: country === COUNTRY.PERU ? personalIdType : undefined,
        loqateLookupAddressId,
    };

    if (COUNTRIES_WITH_PERSONAL_ID.includes(country)) {
        signupData.personalId = String(personalId);
    }
    if (
        !COUNTRIES_WITH_PERSONAL_ID.includes(country) ||
        COUNTRIES_WITHOUT_PERSONAL_DATA_IN_PERSONAL_ID.includes(country)
    ) {
        signupData.birthDate = moment()
            .year(birthDate.year)
            .month(birthDate.month)
            .date(birthDate.day)
            .format('YYYY-MM-DD');
        signupData.gender = gender;
    }

    const url = getUrl('signup/');
    return httpPost<{ email: User['email']; id: User['id']; token: string; verified: User['verified'] }>(
        url,
        signupData,
    );
}

export async function tryLogout(logoutReason) {
    const isRetailLayout = isRetail();
    if (isRetailLayout || logoutReason === 'Multiple sessions') {
        return;
    }
    try {
        const url = getUrl('logout');
        const queryParameters = logoutReason ? { reason: logoutReason } : {};
        await httpGet(url, queryParameters);
    } catch (error) {
        logger.error('AuthMicroservice', 'tryLogout', error);
    }
}

export function fetchRegistrationCountries() {
    return httpGet<{
        countryCurrencies: Record<COUNTRY, Currency[]>;
        registrationCountries: { code: COUNTRY; currency: Currency; name: string; phonePrefix: string }[];
    }>(getUrl('registration-countries/'));
}

export async function getAuthData() {
    return httpGet<{
        displayName: string;
        facebook: string;
        google: string;
        lastActivity?: string;
        is_2faEnabled: boolean;
        is_2faEnabledOnce: boolean;
    }>(getUrl('auth-data'));
}

export async function emailCheck(email: string) {
    const url = getUrl('email-check');
    return httpGet(url, { email });
}

export async function signupBtob({
    acceptTermsAndConditions,
    email,
    password,
}: {
    acceptTermsAndConditions: boolean;
    email: string;
    password: string;
}) {
    const url = getUrl('signup/basic');
    return httpPost<SignupBtobResponse>(url, {
        acceptTermsAndConditions,
        email,
        password,
    });
}

export async function sendTwoFactorCode(email: string) {
    try {
        storageSet('preferLogin', LOGIN_METHOD.EMAIL);
        const url = getUrl('login/send-two-factor-code/');
        const authenticationResponse = await httpPost<AuthenticationResponse>(url, { email });
        return await handleAuthenticationSuccess(authenticationResponse, LOGIN_METHOD.EMAIL);
    } catch (response: any) {
        stores.twoFactor.email.set(email);
        stores.twoFactor.country.set(response?.twoFactor?.country);
        handleAuthenticationFailure(response, LOGIN_METHOD.EMAIL);
        return;
    }
}

export function verifyTwoFactorPin(body: { pin: string; pinId?: string; email?: string }) {
    const url = getUrl('verify-2fa/');
    return httpPost<{ token: string }>(url, body, { device: getOS() } as any);
}

export function startSmartIdAuthentication(personalId: string) {
    const url = getUrl('smart-id/authentication');
    return httpPost<{ sessionId: string; verificationCode: string }>(url, { personalId });
}

export function pollSmartIdSession(body: { authType: SMART_ID_FLOW; sessionId: string }) {
    const url = getUrl('smart-id/poll');
    return httpPost<Partial<SmartIdPollResponse>>(url, body);
}

type BankIdPollResponse = {
    // successful login
    token?: string;
    askSmsVerification?: boolean;
    country?: COUNTRY;
    phonePrefix?: string;
    phoneNumber?: string;
    message?: string;
    statusCode?: number;
    id?: string;
    email?: string;
    alias?: string;
    firstName?: string;
    displayName?: string;
    closed?: boolean;

    // unsuccessful login - user closed
    kycToken?: string; // kyc waiting or security
    kycTokenExpiry?: string;
    gdprDownloadToken?: string; // gdpr download request

    // kyc or registration flow
    type?: 'kyc' | 'registration';
    verified?: boolean; // also in login
    userInfo?: UserInfo;
};

type UserInfo = {
    personalId: string;
    firstName?: string;
    lastName?: string;
    address?: string;
    city?: string;
    email?: string;
    gender?: string;
    zip?: string;
};

type SignupBtobResponse = {
    id: User['id'];
    emailId: User['email'];
    verified: User['verified'];
    closed: User['closed'];
    inPerson: boolean;
    token: string;
};

export enum SmartIdSessionResult {
    DOCUMENT_UNUSABLE = 'DOCUMENT_UNUSABLE',
    OK = 'OK',
    REQUIRED_INTERACTION_NOT_SUPPORTED_BY_APP = 'REQUIRED_INTERACTION_NOT_SUPPORTED_BY_APP',
    TIMEOUT = 'TIMEOUT',
    USER_REFUSED = 'USER_REFUSED',
    USER_REFUSED_CERT_CHOICE = 'USER_REFUSED_CERT_CHOICE',
    USER_REFUSED_CONFIRMATIONMESSAGE = 'USER_REFUSED_CONFIRMATIONMESSAGE',
    USER_REFUSED_CONFIRMATIONMESSAGE_WITH_VC_CHOICE = 'USER_REFUSED_CONFIRMATIONMESSAGE_WITH_VC_CHOICE',
    USER_REFUSED_DISPLAYTEXTANDPIN = 'USER_REFUSED_DISPLAYTEXTANDPIN',
    USER_REFUSED_VC_CHOICE = 'USER_REFUSED_VC_CHOICE',
    WRONG_VC = 'WRONG_VC',
}

export type SmartIdProfile = {
    country: COUNTRY;
    firstName: string;
    lastName: string;
    personalId: string;
};

type SmartIdPollResponse = {
    // KYC / registration / failed authentication
    result: SmartIdSessionResult;
    profile: SmartIdProfile;

    // successful login
    alias: string;
    askSmsVerification: boolean;
    closed?: boolean;
    country: COUNTRY;
    displayName?: string;
    email: string;
    firstName: string;
    lastName: string;
    id: string;
    phoneNumber: string;
    phonePrefix: string;
    token: string;
    registrationToken: string;
};
