import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../../services/analytics';
import Svg from '../../../svg/Svg';
import { stores } from '../../../../stores';
import { toggleMatchOrCategoryInFavorites } from '../../../../services/user-favorites';
import classNames from 'classnames';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    matchId: number;
}

export default function SportFavoriteStar({ matchId }: Props) {
    const [userFavorites] = useStore(stores.sports.userFavorites);
    const [isMatchInFavorites, setIsMatchInFavorites] = useState(false);

    useEffect(() => {
        setIsMatchInFavorites((userFavorites.matches || []).includes(matchId));
    }, [userFavorites]);

    function toggleMatchInFavorites() {
        toggleMatchOrCategoryInFavorites({ match: matchId }, null);
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.SPORT_USER_FAVORITES_ADDED);
    }

    return (
        <Wrapper>
            <span className={classNames({ selected: isMatchInFavorites })} onClick={toggleMatchInFavorites}>
                <Svg icon={isMatchInFavorites ? 'star' : 'star-outline'} size={1} />
            </span>
        </Wrapper>
    );
}
