import React from 'react';
import Wrapper from './styles';
import { translate } from '../../../../services/translate';
import { convertDecimalToAmerican } from '../../../../services/odds-format';
import { useStorage } from '../../../../services/storage';
import { stores } from '../../../../stores';
import { SPORTS_USER_SETTINGS_KEY } from '../../../../services/sports/user-settings';
import { SportsOddsFormat } from '../../../../microservices/sbgate';
import { BonusType } from '../../../../services/sports/constants';
import { BonusBet } from '../../../../microservices/bonuses';

interface Props {
    bonus: BonusBet;
}
export default function SportBonusBetDescription({ bonus }: Props) {
    const [userSettings] = useStorage(stores.sports.userSettings, SPORTS_USER_SETTINGS_KEY);
    const isAmericanOddsFormat = userSettings?.odds_format === SportsOddsFormat.AMERICAN;
    const isMultiplierBonus = bonus.bonusType === BonusType.Multiplier;

    return (
        <Wrapper>
            <div className="bonus-bet-description">
                <div className="description-row">
                    {translate('Bet Type', 'ui.bonus')}:{' '}
                    <div className="row-value">
                        {bonus.bet_type ? translate(`${bonus.bet_type}`, 'ui.sportsbook') : 'All'}
                    </div>
                </div>
                <div className="description-row">
                    {translate('Min. odds', 'ui.bonus')}:{' '}
                    <div className="row-value">
                        {bonus.odds_value
                            ? isAmericanOddsFormat
                                ? convertDecimalToAmerican(bonus.odds_value.toString())
                                : bonus.odds_value
                            : '-'}
                    </div>
                </div>
                {isMultiplierBonus && (
                    <>
                        <div className="description-row">
                            {translate('Min. stake', 'ui.bonus')}:{' '}
                            <div className="row-value">${bonus.minAmount ? bonus.minAmount : '-'}</div>
                        </div>
                        <div className="description-row">
                            {translate('Max. win', 'ui.bonus')}:{' '}
                            <div className="row-value">${bonus.multiplierSettings?.maxNetWin || '-'}</div>
                        </div>
                    </>
                )}
            </div>
        </Wrapper>
    );
}
