import React from 'react';
import Wrapper from './styles';
import UiButton from '../../../ui/button/UiButton';
import Svg from '../../../svg/Svg';
import { isRetail } from '../../../../services/environment';

interface Props {
    inputRef: any;
    value: number;
    onChange: (value) => void;
    onClose: () => void;
}

export default function SportBetslipStakeKeyboard({ inputRef, value, onChange, onClose }: Props) {
    const firstRow = ['1', '2', '3'];
    const secondRow = ['4', '5', '6'];
    const thirdRow = ['7', '8', '9'];
    const isRetailLayout = isRetail();

    function onBackspace() {
        const currentValue = inputRef.current.value.toString();
        if (value) {
            onChange(Number(currentValue.slice(0, -1)));
        }
    }

    function onStakeChange(inputValue) {
        const currentValue = inputRef.current.value.toString();
        if (currentValue === '0' && inputValue?.toString().match(/[0-9]/)) {
            onChange(inputValue);
        } else {
            onChange(`${currentValue}${inputValue}`);
        }
    }

    function stakeButton(stake) {
        return (
            <UiButton className="normal-btn" key={stake} size="small" onClick={() => onStakeChange(stake)}>
                {stake}
            </UiButton>
        );
    }

    return (
        <Wrapper $isRetailLayout={isRetailLayout}>
            <div className="first-column">
                {firstRow.map((stake) => stakeButton(stake))}
                {secondRow.map((stake) => stakeButton(stake))}
                {thirdRow.map((stake) => stakeButton(stake))}
                <div className="last-row">
                    <UiButton className="normal-btn zero-button" size="small" onClick={() => onStakeChange(0)}>
                        0
                    </UiButton>
                    <UiButton className="normal-btn dot-button" size="small" onClick={() => onStakeChange('.')}>
                        .
                    </UiButton>
                </div>
            </div>
            <div className="second-column">
                <UiButton className="normal-btn backspace-btn" size="small" onClick={onBackspace}>
                    <Svg icon="backspace" size={1.1} />
                </UiButton>
                <UiButton className="ok-btn" size="small" onClick={onClose}>
                    OK
                </UiButton>
            </div>
        </Wrapper>
    );
}
