import React from 'react';
import UiButton from '../../../ui/button/UiButton';
import { stores } from '../../../../stores';
import { translate } from '../../../../services/translate';
import {
    useCampaignOrNormalBetSlipFunctions,
    isWarning,
    getErrorMessage,
    isInfo,
} from '../../../../services/sports/betslip';
import Wrapper from '../../../sport/betslip/button-and-errors/styles';
import UiProgressBar from '../../../ui/progress-bar/UiProgressBar';
import SportBetslipButtonAndErrorsConfirm from '../../../sport/betslip/button-and-errors/confirm/SportBetslipButtonAndErrorsConfirm';
import UiAlert from '../../../ui/alert/UiAlert';
import { COMBO_MARKET_ID } from '../../../../services/sports/constants';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    clearSelections: (id?: number) => void;
}

export default function ComboCardsBetslipFooter({ clearSelections }: Props) {
    const [cardsInBetslip] = useStore(stores.sports.comboCard.cardsInBetslip);
    const [stakeByCardId] = useStore(stores.sports.comboCard.stakeByCardId);
    const doAllCardsHaveStake = cardsInBetslip.every((card) => stakeByCardId[card.id] > 0);
    const { placeBet } = useCampaignOrNormalBetSlipFunctions();
    const [betSlipPlacingState, setBetSlipPlacingState] = useStore(stores.sports.betSlipPlacingState);
    const [betSlipErrorByMarketId] = useStore(stores.sports.betSlipErrorByMarketId);
    const { isLoading, needsConfirm, needsConfirmDuplicate } = betSlipPlacingState;
    const genericErrors = betSlipErrorByMarketId[String(COMBO_MARKET_ID)] || [];
    const [firstMarketError] = Object.entries(betSlipErrorByMarketId)
        .map(([key, value]) => {
            if (key === 'null') {
                return;
            }
            return value;
        })
        .filter(Boolean);

    const isBetPlacementButtonDisabled = isLoading || !doAllCardsHaveStake || genericErrors.length || firstMarketError;

    if (needsConfirm || needsConfirmDuplicate) {
        return (
            <Wrapper>
                {needsConfirmDuplicate && isLoading && (
                    <UiProgressBar className="progress" fakeProgressDuration={8500} showAfterPercentage={20} />
                )}
                <SportBetslipButtonAndErrorsConfirm
                    onPlaceBet={placeBet}
                    isForceDuplicate={needsConfirmDuplicate}
                    clearSelection={clearSelections}
                    betSlipPlacingState={betSlipPlacingState}
                    setBetSlipPlacingState={setBetSlipPlacingState}
                />
            </Wrapper>
        );
    }

    return (
        <>
            {firstMarketError && (
                <UiAlert
                    className="market-error"
                    info={isInfo(firstMarketError)}
                    warning={isWarning(firstMarketError)}
                    failure={!isWarning(firstMarketError) && !isInfo(firstMarketError)}
                >
                    {getErrorMessage(firstMarketError)}
                </UiAlert>
            )}
            <UiButton
                color="primary"
                size="large"
                block
                onClick={placeBet}
                disabled={isBetPlacementButtonDisabled}
                data-test="button-place-bet"
                isLoading={isLoading}
            >
                {translate(`Place bet${cardsInBetslip.length > 1 ? 's' : ''}`, 'ui.sportsbook')}
            </UiButton>
        </>
    );
}
