import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { translate } from '../../../../../services/translate';
import { getRoute } from '../../../../../services/router';
import { logger } from '../../../../../services/logger';
import UiProgressBar from '../../../../ui/progress-bar/UiProgressBar';
import { stores } from '../../../../../stores';
import { BonusBet, getBonusBetWageringProgress } from '../../../../../microservices/bonuses';
import UiButton from '../../../../ui/button/UiButton';
import Svg from '../../../../svg/Svg';
import UiNavLink from '../../../../ui/nav-link/UiNavLink';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    bonus: BonusBet;
    isList?: boolean;
}

export default function SportBonusBetLinksMultiplier({ bonus, isList }: Props) {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [wageringProgress, setWageringProgress] = useState<number>();

    useEffect(() => {
        if (isAuthenticated) {
            loadBonusBetWageringProgress();
        } else {
            setWageringProgress(undefined);
        }
    }, [isAuthenticated]);

    async function loadBonusBetWageringProgress() {
        try {
            const { wageringProgress } = await getBonusBetWageringProgress();
            if (wageringProgress !== undefined) {
                setWageringProgress(wageringProgress);
            }
        } catch (error) {
            logger.error('SportBonusBetLinksMultiplier', 'loadBonusBetWageringProgress', error);
        }
    }

    if (!bonus && wageringProgress === undefined) {
        return null;
    }

    const multiplierPercantage = bonus.multiplierSettings?.multiplier
        ? `${(bonus.multiplierSettings.multiplier * 100).toString()}%`
        : '';

    return (
        <Wrapper>
            {bonus &&
                (isList ? (
                    <UiNavLink
                        to={`${getRoute('sport.multiplier')}/${bonus.id}`}
                        onClick={() => stores.sports.isSportSideMenuOpen.set(false)}
                    >
                        <span>
                            {translate('Multiplier', 'ui.bonus')} {multiplierPercantage}
                        </span>
                    </UiNavLink>
                ) : (
                    <UiButton
                        type="menu"
                        icon={<Svg icon="multiplier-bonus-ico" />}
                        url={`${getRoute('sport.multiplier')}/${bonus.id}`}
                        onClick={() => stores.sports.isSportSideMenuOpen.set(false)}
                    >
                        <span>
                            {translate('Multiplier', 'ui.bonus')} {multiplierPercantage}
                        </span>
                    </UiButton>
                ))}
            {wageringProgress !== undefined && (
                <UiProgressBar percentage={wageringProgress} className="multiplier-bonus-progress" />
            )}
        </Wrapper>
    );
}
