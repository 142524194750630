import { useSocketSubscribeUnsubscribe, useSocketTopicEvents } from '../microservices/pusher';

export function useCmsSettingSync(cmsKey: string, setRecord: (setting?: SettingResponse) => void) {
    function onCmsUpdate({ setting }) {
        setRecord(setting);
    }

    useSocketSubscribeUnsubscribe('public', {
        params: {
            service: 'cms',
            channel: cmsKey,
        },
    });

    useSocketTopicEvents(`publish-setting-${cmsKey}`, onCmsUpdate, []);
}

interface SettingResponse {
    key: string;
    value: string;
    createdAt: Date;
    updatedAt: Date;
}
