import React, { useEffect } from 'react';
import Wrapper from './styles';
import UiButton from '../../ui/button/UiButton';
import HorseRacingBetslipSelection from './selection/HorseRacingBetslipSelection';
import { stores } from '../../../stores';
import Svg from '../../svg/Svg';
import { translate } from '../../../services/translate';
import UiDottedArrow from '../../ui/dotted-arrow/UiDottedArrow';
import { placeBet, validateBetslipStake } from '../../../services/horse-racing/betslip';
import UiAlert from '../../ui/alert/UiAlert';
import { getErrorMessage } from '../../../services/sports/betslip';
import { getFixedStake } from '../../../services/sports/betslip-formatting';
import HorseRacingBetslipReceipt from './receipt/HorseRacingBetslipReceipt';
import isEmpty from 'lodash/isEmpty';
import { clearBetslip, getBetTypeMinMaxStake, getPredefinedStakes } from '../../../services/lvdc/lvdc';
import { getLivestreamDetails } from '../../../microservices/lvdc-web';
import HorseRacingRaceStake from '../race/stake/HorseRacingRaceStake';
import { FEATURE, isFeatureAvailable } from '../../../services/feature';
import { useStore } from '../../../hooks/useStore';

export default function HorseRacingBetslip() {
    const [betslip] = useStore(stores.horseRacing.betslip);
    const [, setLivestreamDetails] = useStore(stores.horseRacing.livestreamDetails);
    const [betslipErrors] = useStore(stores.horseRacing.betslipErrors);
    const [betslipStakeErrors] = useStore(stores.horseRacing.betslipStakeErrors);
    const [betslipPlacingState] = useStore(stores.horseRacing.betslipPlacingState);
    const { betType, betStake, nevadaTrackId, raceId, runnerMaps, totalStake, totalCombinations } = betslip;
    const { isLoading, receipt } = betslipPlacingState;
    const [, setIsBetslipOpen] = useStore(stores.horseRacing.isBetslipOpen);
    const [, setBetslipStakeErrors] = useStore(stores.horseRacing.betslipStakeErrors);
    const [wallet] = useStore(stores.wallet);
    const [, setMinMaxStakes] = useStore(stores.horseRacing.minMaxStakes);
    const [, setStakeOptions] = useStore(stores.horseRacing.predefinedBetStakes);

    const isPlaceBetButtonDisabled =
        Boolean(betslipErrors.length) || Boolean(betslipStakeErrors.length) || totalStake <= 0 || isLoading;

    useEffect(() => {
        validateStakeValues(betStake, totalStake);
    }, [betType, betStake, totalStake]);

    function validateStakeValues(newStake: number, totalStake: number) {
        const minMaxStakes = getBetTypeMinMaxStake(betType, totalCombinations);
        setMinMaxStakes(minMaxStakes);
        setStakeOptions(getPredefinedStakes(minMaxStakes.minStake, minMaxStakes.maxStake, betType));

        const { balance_uc: userBalance } = wallet || {};
        const errors = validateBetslipStake({
            stake: newStake,
            minValue: minMaxStakes.minStake,
            maxValue: minMaxStakes.maxStake,
            betType,
            userBalance,
            totalStake,
        });

        setBetslipStakeErrors(errors);
    }

    if (!isEmpty(receipt)) {
        return <HorseRacingBetslipReceipt receipt={receipt} />;
    }

    if (runnerMaps.every((runners) => !runners?.length)) {
        return (
            <Wrapper>
                <div className="pick-bets">
                    <div className="pick-bets-ico animated bounceInDown">
                        <Svg icon="betslip-ico" size={2.6} />
                    </div>
                    <div className="pick-bets-text animated fadeIn">{translate('Pick your bets', 'ui.sportsbook')}</div>
                </div>
                <div className="pick-bets-arrows">
                    <UiDottedArrow size={8} rotate={180} />
                    <UiDottedArrow size={8} rotate={180} />
                    <UiDottedArrow size={8} rotate={180} />
                </div>
            </Wrapper>
        );
    }

    async function confirmBetPlace() {
        const isBetSuccessfullyPlaced = await placeBet();
        if (!isBetSuccessfullyPlaced) {
            return;
        }
        clearBetslip();
        const livestream = await getLivestreamDetails(nevadaTrackId, raceId);
        setLivestreamDetails(livestream);
    }

    function handleClearAll() {
        clearBetslip();
        setIsBetslipOpen(false);
    }

    return (
        <Wrapper>
            <div className="betslip-header">
                <div className="header-title">
                    <div className="betslip-title">{translate('Betslip', 'ui.sportsbook')}</div>
                </div>
                <div className="clear-all" onClick={handleClearAll}>
                    {translate('Clear all', 'ui.sportsbook')}
                </div>
            </div>
            <HorseRacingBetslipSelection />
            <HorseRacingRaceStake isInBetslip />

            <div className="ticket-cost">
                <div className="cost">
                    <div className="cost-label">Cost per bet</div>
                    <div className="cost-amount">
                        {isFeatureAvailable(FEATURE.DISPLAY_DOLLAR_SIGN) ? '$ ' : ''}
                        {getFixedStake(betStake)}
                    </div>
                </div>
                <div className="cost">
                    <div className="cost-label">Total cost</div>
                    <div className="cost-amount">
                        {isFeatureAvailable(FEATURE.DISPLAY_DOLLAR_SIGN) ? '$ ' : ''}
                        {getFixedStake(totalStake)}
                    </div>
                </div>
            </div>

            {[...betslipErrors.map((error) => error.description || error), ...betslipStakeErrors]?.map((error) => (
                <UiAlert failure className="error-alert" key={error}>
                    {getErrorMessage(error) || error || translate('Something went wrong', 'ui.common')}
                </UiAlert>
            ))}
            <div className="betslip-buttons">
                <UiButton
                    disabled={isPlaceBetButtonDisabled}
                    isLoading={isLoading}
                    color="primary"
                    size="large"
                    block
                    onClick={confirmBetPlace}
                >
                    {translate(`Place bet`, 'ui.sportsbook')}
                </UiButton>
            </div>
        </Wrapper>
    );
}
