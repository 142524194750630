import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { getRoute } from '../../../services/router';
import RetailHotEventsItem from './item/RetailHotEventsItem';
import UiNavLink from '../../ui/nav-link/UiNavLink';
import { clearActiveElementFocus } from '../../../services/util';
import { getRetailHotEvents, HotCardType, HotEvent, SettingResponse } from '../../../microservices/cms';
import { logger } from '../../../services/logger';
import { getMatchesByFilter } from '../../../microservices/sbgate';
import { loadOddsByMarketIds } from '../../../microservices/sb-odds';
import { stores } from '../../../stores';
import { useCmsSettingSync } from '../../../hooks/useCmsSettingSync';
import { useInterval } from '../../../services/hooks';
import { CategoryMatch } from '../../../services/sports/types';
import isEqual from 'lodash/isEqual';
import { retail } from '../../../stores/retail/retail';
import { getStoreValue } from '../../../stores/store/utils';
import { useStore } from '../../../hooks/useStore';

export default function RetailHotEvents() {
    const fetchTimeMilliseconds = 1000 * 10;
    const [hotEvents, setHotEvents] = useState<HotEvent[]>(getEmptyEventPlaceholders([]));
    const [isHotCardWithOdds, setIsHotCardWithOdds] = useStore(stores.sports.isHotCardWithOdds);
    const [matchIds, setMatchIds] = useState<number[]>([]);
    const [marketIds, setMarketIds] = useState<number[]>([]);
    const { bet_shop_id } = getStoreValue(retail.retailTerminal);

    useEffect(() => {
        init();
        return () => setIsHotCardWithOdds(false);
    }, []);

    useCmsSettingSync(`retail-hot-event-cards-${bet_shop_id}`, handleSetHotCard);
    useInterval(() => filterResultedMatches(matchIds, hotEvents), fetchTimeMilliseconds);

    async function init() {
        try {
            const { value } = await getRetailHotEvents();
            await parseHotCard(value);
        } catch (error) {
            logger.error('RetailHotEvents', 'init', error);
            setHotEvents(getEmptyEventPlaceholders([]));
        }
    }

    async function parseHotCard(value) {
        const events = JSON.parse(value);
        const filteredHotcards = events.hotCards.filter((hotCard) => hotCard.event);
        const formattedHotCards = filteredHotcards.map((hotCard) => {
            return { ...hotCard, matchId: hotCard.event.id, image: hotCard.media?.image } as HotEvent;
        });
        const matchIds = formattedHotCards
            .filter((hotCard) => hotCard.event?.hotCardType === HotCardType.MatchHotCard)
            .map((hc) => hc.matchId);
        setMatchIds(matchIds);
        await filterResultedMatches(matchIds, formattedHotCards);
    }

    async function filterResultedMatches(matchIds: number[], hotCards: HotEvent[]) {
        if (!matchIds.length) {
            setHotEvents(getEmptyEventPlaceholders(hotCards));
            return;
        }
        const newMarketIds: number[] = [];
        const matchesData = await getMatchesByFilter({ matchIds });
        const availableMatchIds: number[] = matchesData.map((match) => match.id);

        const filteredEvents = hotCards.filter((hotCard) => {
            if (hotCard.event.hotCardType !== HotCardType.MatchHotCard) {
                return true;
            }
            const isActiveMatch = hotCard.matchId && availableMatchIds.includes(hotCard.matchId);
            if (isActiveMatch && isOddsHotCard(hotCard)) {
                const match = matchesData.find((match) => match.id === hotCard.matchId) as CategoryMatch;
                const filteredMarkets =
                    match?.markets?.filter((market) => market.market_type_id === hotCard.marketTypeId) || [];
                newMarketIds.push(...filteredMarkets.map((market) => market.id));
                if (!isHotCardWithOdds && filteredMarkets.length) {
                    setIsHotCardWithOdds(true);
                }
                hotCard.matchMarkets = { ...match, markets: filteredMarkets };
            }
            return isActiveMatch;
        });
        if (!isEqual(newMarketIds, marketIds)) {
            await loadOddsByMarketIds(newMarketIds);
            setMarketIds(newMarketIds);
        }
        setHotEvents(getEmptyEventPlaceholders(filteredEvents));
    }

    async function handleSetHotCard(record?: SettingResponse) {
        try {
            record ? await parseHotCard(record.value) : setHotEvents(getEmptyEventPlaceholders([]));
        } catch (err) {
            logger.error('RetailHotEvents', 'handleSetHotCard', err);
            setHotEvents(getEmptyEventPlaceholders([]));
        }
    }

    function isOddsHotCard(hotCard: HotEvent) {
        return hotCard.event?.hotCardType === HotCardType.MatchHotCard && hotCard.isShowOdds && hotCard.marketTypeId;
    }

    function generateCategoryPath(hotCard: HotEvent) {
        return `${getRoute('sport.category')}/${hotCard.event.id}`;
    }

    function getEmptyEventPlaceholders(events: HotEvent[]) {
        if (events.length >= 5) {
            return events;
        }
        const emptyEventPlaceHolderCount = 5 - events.length;
        const emptyEventPlaceHolds = [...Array(emptyEventPlaceHolderCount)].map(() => {
            return {
                content: '',
                matchId: undefined,
                image: '',
                title: '',
                event: {
                    hotCardType: HotCardType.EmptyHotCard,
                },
            };
        });
        return [...events, ...emptyEventPlaceHolds];
    }

    return (
        <Wrapper>
            <div className="hot-events-container">
                {hotEvents.map((event, key) =>
                    [HotCardType.MatchHotCard, HotCardType.LeagueHotCard].includes(event.event.hotCardType) ? (
                        <UiNavLink
                            to={
                                event.event.hotCardType === HotCardType.MatchHotCard
                                    ? `${getRoute('sport')}/match/${event.matchId}`
                                    : generateCategoryPath(event)
                            }
                            key={`event.title=${key}`}
                            onClick={clearActiveElementFocus}
                        >
                            <RetailHotEventsItem event={event} />
                        </UiNavLink>
                    ) : (
                        <RetailHotEventsItem key={`event.title=${key}`} event={event} />
                    ),
                )}
            </div>
        </Wrapper>
    );
}
