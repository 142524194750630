import React from 'react';
import { translate } from '../../../../../../services/translate';
import { PaybackBooster, PaybackBoosterStatus } from '../../../../../../services/casino/payback-booster';
import { DATE_YEAR_TIME_FORMAT, getFormattedDate } from '../../../../../../services/date';

interface Props {
    paybackBooster?: PaybackBooster;
}

export default function CasinoPaybackBoosterThumbnailPromoDescription({ paybackBooster }: Props) {
    const promoDescription = getPromoDescription();

    function getPromoDescription() {
        if (paybackBooster && paybackBooster.status === PaybackBoosterStatus.available) {
            return translate(
                'Congratulations! You can now activate the Payback Booster and receive extra cash on each spin (no matter if you win or lose) on the eligible game(s). Activate it before %1',
                'casino.payback-booster',
                [getFormattedDate({ date: paybackBooster.expiryDate, format: DATE_YEAR_TIME_FORMAT, useDots: true })],
            );
        }

        if (
            paybackBooster &&
            paybackBooster.status === PaybackBoosterStatus.active &&
            new Date(paybackBooster.expiryDate) < new Date()
        ) {
            return translate(
                `This program has now expired. Don't forget to transfer any remaining Payback Booster money to your Main Balance!`,
                'casino.payback-booster',
            );
        }

        return translate(
            'You have activated your Payback Booster! Play now on the eligible game(s) and receive extra cash on each spin! No matter if you win or lose!',
            'casino.payback-booster',
        );
    }

    return <>{promoDescription}</>;
}
