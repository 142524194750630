import React, { useEffect } from 'react';
import { isMobileApp } from '../../../../services/mobile-app';
import { getRoute, useRouter } from '../../../../services/router';
import { stores } from '../../../../stores';
import AuthLogin from '../AuthLogin';
import Wrapper from './styles';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

export default function AuthLoginModal() {
    const [isLoginModalOpen, setIsLoginModalOpen] = useStore(stores.modals.isLoginModalOpen);
    const [{ isPhone }] = useStore(media);
    const { navigateTo } = useRouter();

    function onClose() {
        setIsLoginModalOpen(false);
    }

    useEffect(() => {
        if (isLoginModalOpen) {
            if (isFeatureAvailable(FEATURE.UNIFIED_LOGIN_AND_REGISTER) && !isMobileApp()) {
                navigateTo(getRoute('registration-btob'));
            }
        }
    }, [isLoginModalOpen]);

    if (isMobileApp()) {
        return null;
    }

    return (
        <Wrapper
            open={isLoginModalOpen}
            onClose={onClose}
            className="new-modal"
            maxWidth={310}
            minWidth={310}
            mode={isPhone ? 'drawer' : undefined}
        >
            <AuthLogin onClose={onClose} />
        </Wrapper>
    );
}
