import React from 'react';
import Wrapper from './styles';
import { translate } from '../../../services/translate';
import moment from 'moment';

interface Props {
    endTime: string;
}

export default function PromotionTimeRemainingBadge({ endTime }: Props) {
    const endDate = moment(endTime);

    if (endDate.diff(moment(), 'days') >= 2) {
        return null;
    }

    return (
        <Wrapper>
            <div className="badge">
                {translate('hurry-up', 'ui.bonuses', { timePeriod: endDate.fromNow(true).toString() }).toUpperCase()}
            </div>
        </Wrapper>
    );
}
