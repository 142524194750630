import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import UiFormInput from '../../../ui/form/input/UiFormInput';
import { translate } from '../../../../services/translate';
import UiGroup from '../../../ui/group/UiGroup';
import { validators } from '../../../../services/validators';
import { BANK_ID_AUTH_TYPE, useBankIdAuth } from '../../../../services/bank-id';
import UiDotsLoader from '../../../ui/dots-loader/UiDotsLoader';
import { useForm } from '../../../../services/form';
import UiForm from '../../../ui/form/UiForm';
import { storageSet } from '../../../../services/storage';
import { AUTH_IMAGE, LOGIN_METHOD } from '../../../../services/auth';
import RegistrationFieldsAcceptTermsAndConditions from '../../../registration/fields/accept-terms-and-conditions/RegistrationFieldsAcceptTermsAndConditions';
import RegistrationFieldsHasAcceptedAgeAndPlayingOwnBehalf from '../../../registration/fields/has-accepted-age-and-playing-own-behalf/RegistrationFieldsHasAcceptedAgeAndPlayingOwnBehalf';
import { stores } from '../../../../stores';
import UiButton from '../../../ui/button/UiButton';

interface Props {
    authType: typeof BANK_ID_AUTH_TYPE[keyof typeof BANK_ID_AUTH_TYPE];
    onClose?: () => void;
    onAuthenticationSuccess?: () => void;
}

export default function AuthLoginBankId({
    authType,
    onAuthenticationSuccess = () => undefined,
    onClose = () => undefined,
}: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoginStarted, setIsLoginStarted] = useState(false);
    const form = useForm(stores.registration.form);

    const { startAuth, errorMessage, sessionId, isAuthenticationSuccessful, loginProgressMessage } = useBankIdAuth();

    useEffect(() => {
        if (isLoginStarted) {
            return;
        }
        if (sessionId) {
            setIsLoginStarted(true);
        }
    }, [sessionId]);

    useEffect(() => {
        if (isAuthenticationSuccessful) {
            onAuthenticationSuccess();
        }
    }, [isAuthenticationSuccessful]);

    async function login(isFormValid) {
        if (!isFormValid) {
            return;
        }
        setIsLoading(true);
        storageSet('preferLogin', LOGIN_METHOD.BANK_ID);
        await startAuth(authType);
        setIsLoading(false);
    }

    return (
        <Wrapper>
            {authType === BANK_ID_AUTH_TYPE.REGISTRATION && (
                <img className="provider-picture" src={AUTH_IMAGE.BANK_ID} alt="Bank-ID" />
            )}
            {isLoginStarted && !errorMessage ? (
                <>
                    <p>{translate(loginProgressMessage, 'ui.account')}</p>
                    <UiDotsLoader />
                </>
            ) : (
                <UiForm onSubmit={login} isLoading={isLoading}>
                    <UiFormInput
                        {...form.personalId}
                        alert={errorMessage}
                        failure={Boolean(errorMessage)}
                        validator={validators.personalIdSwedish}
                        placeholder={translate('Personal identification', 'ui.account')}
                    />

                    {authType === BANK_ID_AUTH_TYPE.REGISTRATION && (
                        <>
                            <RegistrationFieldsHasAcceptedAgeAndPlayingOwnBehalf />
                            <RegistrationFieldsAcceptTermsAndConditions />
                        </>
                    )}

                    <UiGroup vertical>
                        <UiButton
                            color="primary"
                            className="bank-id-next-button"
                            isFormSubmitButton
                            isLoading={isLoading}
                        >
                            {translate('next', 'ui.common')}
                        </UiButton>

                        {authType !== BANK_ID_AUTH_TYPE.REGISTRATION && (
                            <UiButton onClick={onClose} block>
                                {translate('Back', 'ui.common')}
                            </UiButton>
                        )}
                    </UiGroup>
                </UiForm>
            )}
        </Wrapper>
    );
}
