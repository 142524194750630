import React, { useEffect } from 'react';
import AppPage from '../app/AppPage';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import 'moment/locale/nb';
import 'moment/locale/sv';
import 'moment/locale/fi';
import 'moment/locale/et';
import 'moment/locale/ru';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/is';
import 'moment/locale/da';
import 'moment-duration-format/lib/moment-duration-format';
import { stores } from '../../stores';
import { getToken, hasValidToken } from '../../services/token';
import { setTokenAndAuthenticate } from '../../services/auth';
import System from '../../components/system/System';
import { initSentry } from '../../services/sentry';
import { findLanguage, initLanguage } from '../../services/language';
import { getRoute, isActiveRoute, useRouter } from '../../services/router';
import { loadIpDetails } from '../../services/ip';
import { dispatchEvent, EventType, loadClientConfig } from '../../services/utils/b2b';
import { ThemeList } from '../../ui/config/themeConfig';
import { loadEnvironment, saveAvailableLicenes } from '../../services/environment';
import { loadFeatures } from '../../microservices/features';
import { useTheme } from '../../hooks/useTheme';
import { useStore } from '../../hooks/useStore';

export default function BootstrapPage() {
    const { history } = useRouter();
    const [isBootstrapped, setIsBootstrapped] = useStore(stores.isBootstrapped);
    // TODO: hide this for production bundle and use theme directly
    const theme = useTheme();

    useEffect(() => {
        bootstrap();
    }, []);

    async function bootstrap() {
        await loadEnvironment();
        initSentry();
        loadClientConfig();
        await loadFeatures();

        await loadIpDetails();
        saveAvailableLicenes();

        findLanguage();

        const pokerAuthRouteSuffixes = ['/auth-se', '/auth', '/auth-ee', '/auth-mx', '/auth-mt'];
        const isPokerAuthRoute = pokerAuthRouteSuffixes.some((suffix) =>
            isActiveRoute(`${getRoute('poker')}${suffix}`),
        );

        if (hasValidToken() && !isPokerAuthRoute) {
            await setTokenAndAuthenticate(getToken());
        }

        await initLanguage(history);

        setIsBootstrapped(true);
        dispatchEvent(EventType.Bootstrap);
    }

    return (
        <>
            <MuiThemeProvider theme={ThemeList.dark}>
                {theme && <ThemeProvider theme={theme}>{isBootstrapped && <AppPage />}</ThemeProvider>}
                {isBootstrapped && <System />}
            </MuiThemeProvider>
        </>
    );
}
