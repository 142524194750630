import styled from 'styled-components';
import { lighten, saturate } from 'polished';

export default styled.div`
    margin-bottom: ${(props) => props.bottomSpacing || 'var(--spacing-12)'};
    .pseudo-label {
        color: var(--font-color-subtle);
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 0.6rem;
        margin-right: 0.4rem;
    }

    label {
        align-items: center;
        cursor: pointer;
        display: flex;
        margin: 0.35rem 0;
        user-select: none;

        &:hover input ~ .pseudo-radio {
            background-color: var(--form-input-bg);
            box-shadow: var(--ui-shadow);
            transition: 150ms linear background-color, 150ms linear box-shadow;
        }

        &:hover input:checked ~ .pseudo-radio {
            box-shadow: none;
        }

        input:checked ~ .pseudo-radio::after {
            display: block;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    input {
        appearance: none;
        height: 0;
        opacity: 0;
        width: 0;

        &:disabled ~ .pseudo-radio {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    .pseudo-radio {
        background-color: var(--form-input-bg);
        border-radius: 50%;
        height: 24px;
        margin: 0 0.35rem 0 0;
        position: relative;
        min-width: 24px;
        width: 24px;

        &:after {
            background-color: ${({ theme }) => theme.buttons.primaryBackground};
            box-shadow: var(--ui-shadow),
                inset 0 6px 20px 0 ${({ theme }) => saturate(0.5, lighten(0.2, theme.buttons.primaryBackground))};
            border-radius: 50%;
            content: '';
            display: none;
            height: 8px;
            width: 8px;
            position: absolute;
            top: 8px;
            left: 8px;
        }
    }
    .input-error {
        margin-top: var(--spacing-4);
    }
`;
