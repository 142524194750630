import React, { useEffect, useState } from 'react';
import UiPlaceholder from '../../../../../../ui/placeholder/UiPlaceholder';
import SportOdds from '../../../../../odds/SportOdds';
import { MATCH_STATUS } from '../../../../../../../services/sports/constants';
import { useSportsVisualSettings } from '../../../../../../../services/sports/sport-match-visual-settings';
import classnames from 'classnames';
import {
    CategoryMatch,
    CategoryMatchMarket,
    MarketInfo,
    MarketTypeCategory,
} from '../../../../../../../services/sports/types';
import { MarketsWithOutcomesMinimal } from '../../../../../../../microservices/sbgate';
import { useSportsUserSettings } from '../../../../../../../services/sports/user-settings';
import { marketTypeNameWrapper } from '../../../../../../../services/sports/market';

interface Props {
    market: CategoryMatchMarket | MarketsWithOutcomesMinimal | MarketInfo;
    marketType: MarketTypeCategory;
    match: CategoryMatch;
    isEuropeanLayoutForced: boolean | undefined;
}

function SportMatchMarketsTopMarketsMarketOdds({ market, marketType, match, isEuropeanLayoutForced }: Props) {
    const marketTypeName = marketTypeNameWrapper(marketType.name);
    const { isAmericanLayout } = useSportsUserSettings({ sportCategoryId: match.sport_category });
    const { isMarketTitleVisible } = useSportsVisualSettings();
    const [previousMarket, setPreviousMarket] = useState<
        CategoryMatchMarket | MarketsWithOutcomesMinimal | MarketInfo
    >();

    // for animation of line change
    useEffect(() => {
        if (!market || market?.id === previousMarket?.id) {
            return;
        }
        const timeout = setTimeout(() => {
            setPreviousMarket(market);
        }, 1000);
        return () => clearTimeout(timeout);
    }, [market]);

    return (
        <>
            {isMarketTitleVisible && (
                <div
                    className={classnames('match-market-name', {
                        'line-market-name': Boolean((market as CategoryMatchMarket).line),
                    })}
                    title={marketType.name}
                >
                    {marketTypeName || <UiPlaceholder />}
                </div>
            )}

            <div
                className={classnames('match-market-outcome animated', {
                    'line-market': market.view_type === 'line',
                    fadeIn: !previousMarket && match.isFake,
                })}
            >
                {market.outcomes.map((outcome, index) => (
                    <SportOdds
                        matchId={match.id}
                        key={outcome?.id || index}
                        outcomeId={outcome?.id}
                        market={{
                            in_play: match.status === MATCH_STATUS.LIVE,
                            betting_end: match.betting_end,
                            ...market,
                        }}
                        isOutcomeNameVisible={!isAmericanLayout || isEuropeanLayoutForced}
                        isAmericanLayoutTopMarkets={isAmericanLayout}
                        isAmericanLayout={isAmericanLayout}
                    />
                ))}
            </div>
        </>
    );
}

export default React.memo(SportMatchMarketsTopMarketsMarketOdds);
