import { getServiceUrl, httpGet } from '../services/api';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { logger } from '../services/logger';
import {
    CasinoDateFilter,
    GamePlayedCount,
    RecentlyPlayed,
    storeRecentlyPlayedGames,
    UserStatistics,
    UserActivity,
    UserHighestWin,
    HighestDailyWins,
} from '../services/casino/casino-statistics';
import { storageGet } from '../services/storage';
import keyBy from 'lodash/keyBy';
import moment from 'moment';
import { Currency } from '../services/wallet/types';
import { COUNTRY } from '../services/country';

const getUrl = (url) => getServiceUrl('casino-statistics', url);

export async function loadRecentlyPlayedGames(): Promise<void> {
    try {
        const isAuthenticated = getStoreValue(stores.isAuthenticated);
        const url = getUrl('fo/recently-played');
        const recentlyPlayed: RecentlyPlayed[] = isAuthenticated ? await httpGet(url) : storageGet('recentlyPlayed');
        storeRecentlyPlayedGames(recentlyPlayed);
    } catch (error) {
        logger.error('CasinoStatisticsMicroservice', 'loadRecentlyPlayedGames', error);
    }
}

export async function loadGamesPlayedCounts(): Promise<void> {
    try {
        const url = getUrl('fo/game-played-count');
        const gamesPlayedCounts: GamePlayedCount[] = await httpGet(url);

        stores.casino.gamesPlayedCountsByGameId.set(keyBy(gamesPlayedCounts, 'gameId'));
    } catch (error) {
        logger.error('CasinoStatisticsMicroservice', 'loadGamesPlayedCounts', error);
    }
}

export async function loadUserStatistics(dateFilter: CasinoDateFilter): Promise<void> {
    const properFilter = {
        period: dateFilter.period,
        startDate: (dateFilter.startDate as any) ? moment(dateFilter.startDate as any).format('YYYY-MM-DD') : undefined,
        endDate: (dateFilter.endDate as any) ? moment(dateFilter.endDate as any).format('YYYY-MM-DD') : undefined,
    };
    try {
        const url = getUrl('fo/statistics');
        const userStatistics: UserStatistics = await httpGet(url, properFilter);

        stores.casino.userStatistics.set(userStatistics);
    } catch (error) {
        logger.error('CasinoStatisticsMicroservice', 'loadUserStatistics', error);
    }
}

export async function loadUserActivity(dateFilter: CasinoDateFilter): Promise<void> {
    const properFilter = {
        period: dateFilter.period,
        startDate: (dateFilter.startDate as any) ? moment(dateFilter.startDate as any).format('YYYY-MM-DD') : undefined,
        endDate: (dateFilter.endDate as any) ? moment(dateFilter.endDate as any).format('YYYY-MM-DD') : undefined,
    };
    try {
        const url = getUrl('fo/activity');
        const userActivity: UserActivity = await httpGet(url, properFilter);

        stores.casino.userActivity.set(userActivity);
    } catch (error) {
        logger.error('CasinoStatisticsMicroservice', 'loadUserActivity', error);
    }
}

export async function loadUserBiggestWins(dateFilter: CasinoDateFilter) {
    const properFilter = {
        period: dateFilter.period,
        startDate: (dateFilter.startDate as any) ? moment(dateFilter.startDate as any).format('YYYY-MM-DD') : undefined,
        endDate: (dateFilter.endDate as any) ? moment(dateFilter.endDate as any).format('YYYY-MM-DD') : undefined,
    };
    try {
        const url = getUrl('fo/biggest-wins');
        const userBiggestWins: UserHighestWin[] = (await httpGet(url, properFilter)) || [];
        stores.casino.highestWins.set(userBiggestWins);
    } catch (error) {
        logger.error('CasinoStatisticsMicroservice', 'loadUserBiggestWins', error);
    }
}

export async function loadCasinoHighestWins(country: COUNTRY, currency: Currency) {
    try {
        const url = getUrl('fo/highest-daily-wins');
        return httpGet<HighestDailyWins[]>(url, { country, currency });
    } catch (error) {
        logger.error('CasinoStatisticsMicroservice', 'loadCasinoHighestWins', error);
        return [];
    }
}
