import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';
import moment from 'moment';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import {
    getFoTranslations,
    getIncrementalTranslationUpdates,
    reportMissingTranslations,
    TranslationUpdate,
} from '../microservices/cms';
import {
    Language,
    LANGUAGE,
    localeOfLanguage,
    momentJsLanguageByApplicationLanguage,
    saveLanguageToStorage,
} from './language';
import { logger } from './logger';
import { storageGet, storageSet } from './storage';
import stackTrace from 'stack-trace';

let TRANSLATIONS = {};
const isMissingTranslationReportedByUniqueKey =
    storageGet<Record<string, boolean>>('reportedMissingTranslations') || {};
let missingTranslationReports: MissingTranslationReport[] = [];
let isReportingMissingTranslations = false;

setInterval(() => sendMissingTranslationsReport(), 30000);

async function mergeIncrementalTranslationUpdate(language: Language, fallbackLanguage: Language, lastUpdated: string) {
    const lastUpdatedUnixTime = new Date(lastUpdated).getTime();
    const incrementalTranslationUpdates = lastUpdatedUnixTime
        ? await getIncrementalTranslationUpdates(lastUpdatedUnixTime.toString())
        : [];
    const formattedIncrementalTranslationUpdates = formatIncrementalUpdatesForLanguage(
        incrementalTranslationUpdates,
        language,
        fallbackLanguage,
    );
    return merge({}, TRANSLATIONS, formattedIncrementalTranslationUpdates);
}

export async function setLanguage(language) {
    const locales = {
        ca: 'en-CA',
        us: 'en-US',
        en: 'en-GB',
        cl: 'es-CL',
        ec: 'es-EC',
        mx: 'es-MX',
        pe: 'es-PE',
        et: 'et',
        ru: 'ru',
        fi: 'fi-FI',
        sv: 'sv-SE',
        no: 'no-NO',
        is: 'is',
    };
    let fallbackLanguage;
    switch (language) {
        case LANGUAGE.PERUVIAN:
        case LANGUAGE.ECUADORIAN:
        case LANGUAGE.MEXICAN:
            fallbackLanguage = LANGUAGE.DEFAULT_LATAM;
            break;
        default:
            break;
    }

    (document as any).querySelector('html').setAttribute('lang', locales[language] || locales['en']);
    let lastUpdated;

    ({ translationsByKey: TRANSLATIONS, lastUpdated } = await getFoTranslations(language, fallbackLanguage));
    TRANSLATIONS = await mergeIncrementalTranslationUpdate(language, fallbackLanguage, lastUpdated);
    saveLanguageToStorage(language);

    moment.locale(localeOfLanguage[language]); // Changes moment.js global language

    moment.updateLocale(momentJsLanguageByApplicationLanguage[language], {
        durationLabelsStandard: {
            s: translate('second', 'ui.duration'),
            ss: translate('seconds', 'ui.duration'),
            m: translate('minute', 'ui.duration'),
            mm: translate('minutes', 'ui.duration'),
            h: translate('hour', 'ui.duration'),
            hh: translate('hours', 'ui.duration'),
            d: translate('day', 'ui.duration'),
            dd: translate('days', 'ui.duration'),
        } as any,
    });
}

export type TranslateKey = string | [key: string, fallBack: string];

export function translate(
    key: TranslateKey,
    context?: string,
    replacements?: any[] | Record<string, string | number | Date>,
) {
    if (!key) {
        logger.error('TranslateService', 'translate', `Translation key missing ${context}`);
        return '';
    }
    let fallback = '';
    let text = '';

    if (isString(key)) {
        key = key.trim();
        fallback = key;
    } else if (Array.isArray(key)) {
        fallback = key[1];
        key = key[0];
    } else {
        return '';
    }

    try {
        if (context) {
            key = kebabCaseify(key);
            key = `${context}.${key}`;
        }

        key = key.slice(0, 126); // Max translation key length in l10n service is 127 characters
        text = TRANSLATIONS[key];

        if (!text && text !== '') {
            // TODO temporary workaround because "stack-trace" doesn't work properly with FF
            try {
                reportMissingTranslation(key, fallback);
            } catch (error) {
                logger.error('TranslateService', 'translate', `Error translating ${key} ${context}`);
                logger.error('TranslateService', 'translate', error);
            }
        }

        const textOrFallback = text || fallback;

        if (textOrFallback && replacements) {
            text = interpolate(textOrFallback, replacements);
        }
    } catch (error) {
        logger.error('TranslateService', 'translate', `Error translating ${key} ${context}`);
        logger.error('TranslateService', 'translate', error);
    }

    return text || fallback || '???';
}

export function interpolate(text: string, replacements) {
    /*
        EXAMPLES of "interpolate" usages

        Old format:
        text = 'My name is %1, I am %2 years old and I work at %3';
        replacements = ['CoolBear', 2, 'Coolbet'];

        New format:
        text = 'My name is {{ name }}, I am {{ age }} years old and I work at {{ workplace }}';
        replacements = {
            name: 'CoolBear',
            age: 2,
            workplace: 'Coolbet',
        };

        Note:
        The new format matches interpolations within the string in any of the following form:
        '{{ name }}', '{{name }}', '{{ name}}', '{{name}}'
    */

    if (Array.isArray(replacements)) {
        replacements.forEach((replacementValue, replacementIndex) => {
            text = text.replace(`%${replacementIndex + 1}`, replacementValue);
        });
    }

    if (isPlainObject(replacements)) {
        const reg = /{{\s*([^ ]+)\s*}}/g;
        text = text.replace(reg, (match, p1) => get(replacements, p1, '') as string);
    }

    return text;
}

function kebabCaseify(text: string) {
    return text.toLowerCase().replace(/[ .,!?&^%$#'"/<>()+@]/gi, '-');
}

function formatIncrementalUpdatesForLanguage(
    incrementalTranslationUpdates: TranslationUpdate[],
    language: Language,
    fallbackLanguage: Language,
) {
    const translationsByKey = keyBy(incrementalTranslationUpdates, (translation) => translation.key);
    return mapValues(translationsByKey, (translation) => {
        if (translation.text) {
            return (
                translation.text[language] || translation.text[fallbackLanguage] || translation.text[LANGUAGE.ENGLISH]
            );
        }
    });
}

function reportMissingTranslation(key: string, fallback: string) {
    const isSportsBookCategoryKey = key.startsWith('sb.category') && key.endsWith('slug');
    const isSeoKey = key.startsWith('seo.') && (!fallback || key === fallback);
    const isSportsMarketConcreteKey = key.startsWith('sb.market-concrete');
    const isCbOutcomeKey = key.startsWith('cb.outcome');
    const isSbOutcomeKey = key.startsWith('sb.outcome');
    const isSbMarketTypeKey = key.startsWith('sb.market-type.');
    const isSbLivebetKey = key.startsWith('ui.sportsbook.lb.');
    const isCasinoGameKey = key.startsWith('casino.game.');

    if (
        !key ||
        isSportsBookCategoryKey ||
        isSeoKey ||
        isSportsMarketConcreteKey ||
        isCbOutcomeKey ||
        isSbOutcomeKey ||
        isSbMarketTypeKey ||
        isSbLivebetKey ||
        isCasinoGameKey ||
        key.includes('undefined')
    ) {
        return;
    }

    const trace = stackTrace.get();
    key = key.substr(0, 126);

    const translateIndex: number = trace.findIndex((callSite) => callSite.getFunctionName() === 'translate');
    const location = trace[translateIndex + 1].getFunctionName();

    const missingTranslationReport: MissingTranslationReport = {
        key,
        path: window.location.pathname,
        lang: getStoreValue(stores.language) || LANGUAGE.ENGLISH,
        location: String(location) || '',
    };

    if (fallback && key !== fallback) {
        missingTranslationReport.fallback = fallback;
    }

    const uniqueReportKey = `${missingTranslationReport.lang}${missingTranslationReport.key}${missingTranslationReport.ng_url}`;

    if (!isMissingTranslationReportedByUniqueKey[uniqueReportKey]) {
        isMissingTranslationReportedByUniqueKey[uniqueReportKey] = true;
        storageSet('reportedMissingTranslations', isMissingTranslationReportedByUniqueKey);
        missingTranslationReports.push(missingTranslationReport);
    }
}

async function sendMissingTranslationsReport() {
    if (!missingTranslationReports.length || isReportingMissingTranslations) {
        return;
    }

    isReportingMissingTranslations = true;
    const missingTranslationReportsToSend = [...missingTranslationReports];
    missingTranslationReports = [];

    try {
        await reportMissingTranslations(missingTranslationReportsToSend);
    } catch (error) {
        logger.error('TranslateService', 'sendMissingTranslationsReport', error);
        missingTranslationReports = [...missingTranslationReports, ...missingTranslationReportsToSend];
    }

    isReportingMissingTranslations = false;
}

type MissingTranslationReport = {
    key: string;
    path: string;
    lang: Language;
    location: string;
    ng_url?: string;
    fallback?: string;
};
