import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

type StyledProps = {
    type: string;
};

export default styled.div<StyledProps>`
    display: flex;
    align-items: center;
    margin-top: ${(props) => (props.type === 'MASCOT_CTA' ? '0.5rem' : '0')};
    max-width: 400px;
    padding: 0.8rem;

    @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
        bottom: ${({ theme }) => theme.mobileTabs.height};
    }

    .crm-mascot {
        animation-delay: 3000ms;
        height: 50px;
        width: 50px;
        min-width: 50px;
        border-radius: 100px;
        margin-right: 0.5rem;
    }
    .crm-text {
        animation-delay: 3500ms;
        padding: 0.8rem 0.4rem;
        font-size: 0.875rem;
        border-radius: 100px;
        color: var(--font-color-primary);
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            font-size: 1rem;
        }
    }
`;
