// TODO (Robert): Hooks should not be used conditionally or in loops

import { useEffect, useRef, useState } from 'react';
import { isValidatorsValid } from './auth';
import isPlainObject from 'lodash/isPlainObject';
import { useStore } from '../hooks/useStore';

export function useForm(formState) {
    const isFormInStore = formState._store;
    const [currentFormState, setCurrentFormState] = isFormInStore ? useStore(formState) : [formState];

    const form: any = {};

    for (const formInputName of Object.keys(currentFormState)) {
        // TODO (Robert): Find the root cause of the race condition and the succeeding endless loop in the useFormInputWithStore (previously useFormInput) implementation when used without store.
        form[formInputName] = isFormInStore
            ? useFormInputWithStore(currentFormState[formInputName], formInputName, setCurrentFormState as any)
            : useFormInput(currentFormState[formInputName], formInputName);
    }

    form.getValues = () => {
        const values = {};

        for (const formInputName of Object.keys(form)) {
            values[formInputName] = form[formInputName].value;
        }

        return values;
    };

    form.getInputValue = (formInputName) => form[formInputName].value;

    form.setInputValue = (valueByInputName) => {
        if (!isPlainObject(valueByInputName)) {
            throw new Error('valueByInputName has to be a dictionary with values by input names!');
        }

        for (const formInputName of Object.keys(valueByInputName)) {
            form[formInputName].setValue(valueByInputName[formInputName]);
        }
    };

    form.setInputValidationMessage = (validationMessageByInputName) => {
        if (!isPlainObject(validationMessageByInputName)) {
            throw new Error(
                'validationMessageByInputName has to be a dictionary with validation message by input names!',
            );
        }

        for (const formInputName of Object.keys(validationMessageByInputName)) {
            if (!isPlainObject(validationMessageByInputName[formInputName])) {
                throw new Error(
                    'Input validation message has to be an object with either of those properties present - error, warning, success!',
                );
            }

            if (Boolean(form[formInputName])) {
                form[formInputName].setValidationMessage(validationMessageByInputName[formInputName]);
            }
        }
    };

    return form;
}

function useFormInput(externalValue, formInputName) {
    const [value, setValue] = useState(externalValue);
    const formInputRef = useRef();

    return {
        name: formInputName,
        value,
        ref: formInputRef,
        onValueChange: setValue,
        setValue,
        setValidationMessage(validationMessage) {
            if (formInputRef.current) {
                (formInputRef.current as any).setValidationMessage(validationMessage);
            }
        },
    };
}

function useFormInputWithStore(externalValue, formInputName, storeSetter = () => {}) {
    const [value, setValue] = useState(externalValue);
    const formInputRef = useRef();

    useEffect(() => {
        setValue(externalValue);
    }, [externalValue]);

    function setInputValue(value) {
        setValue(value);

        (storeSetter as any)((formState) => {
            formState[formInputName] = value;
        });
    }

    return {
        name: formInputName,
        value,
        ref: formInputRef,
        onValueChange: setInputValue,
        setValue: setInputValue,
        setValidationMessage(validationMessage) {
            if (formInputRef.current) {
                (formInputRef.current as any).setValidationMessage(validationMessage);
            }
        },
    };
}

export function useValidator() {
    const [validate, setValidate] = useState(0);

    return {
        validate,
        runValidation: async (validators: any[] = []) => {
            setValidate(validate + 1);
            return isValidatorsValid(validators);
        },
    };
}
