import React from 'react';
import Wrapper from './styles';
import { filterStyleProps } from '../../styles/utils';

interface Props {
    alt?: string;
    className?: string;
    icon: string;
    color?: string;
    onClick?: (event?) => void;
    size?: number;
    width?: number;
    title?: string;
    rotate?: number;
}

export default function Svg({ icon, size, rotate, width, ...rest }: Props) {
    if (!icon) {
        return null;
    }

    return (
        <Wrapper {...filterStyleProps(rest)} size={size} rotate={rotate} data-name={icon} width={width}>
            <svg>
                <use href={`#${icon}`} />
            </svg>
        </Wrapper>
    );
}
