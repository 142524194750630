import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

type StyledProps = {
    isMobileDevice?: boolean;
    isFeatured?: boolean;
    isPaybackBooster?: boolean;
    isPaybackBoosterExpired?: boolean;
};
export default styled.div<StyledProps>`
    flex-wrap: nowrap;
    cursor: pointer;

    transform: translate(0, 0);
    transition: transform 100ms linear;
    &.outsideView {
        pointer-events: none;
    }

    &.isTestUser.disabled {
        pointer-events: unset;
    }
    &:hover {
        @media (hover: hover) and (pointer: fine) {
            transform: translate(0, -5px);
            .casino-thumbnail-game-image {
                filter: brightness(115%);
            }
        }
        .casino-thumbnail-game-info-icon {
            display: block;
        }

        .casino-thumbnail-live-game-image {
            position: absolute;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            border-radius: var(--ui-radius);
        }
    }
    height: 100%;
    .casino-thumbnail-game-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        ${({ isFeatured, isMobileDevice }) =>
            isFeatured &&
            isMobileDevice &&
            css`
                width: calc(100vw - 2.05rem);
            `}
    }

    .casino-thumbnail-game-body {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background: ${({ isPaybackBooster, isPaybackBoosterExpired }) =>
            isPaybackBooster && !isPaybackBoosterExpired
                ? `${({ theme }) => theme.paybackBooster.primaryColor}`
                : 'var(--surface-level-2-bg)'};
        border-radius: var(--ui-radius);
        box-sizing: content-box;
        height: 100%;
        width: 100%;

        ${({ isPaybackBooster }) =>
            isPaybackBooster &&
            css`
                border: 0;
                background-color: ${({ theme }) => theme.paybackBooster.primaryColor};
            `}
    }

    .casino-thumbnail-game-image-container {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: var(--ui-radius);
        display: flex;
        align-items: flex-start;
        justify-content: center;
        background-size: 100% 100%;
    }

    .casino-thumbnail-game-image-logo-promotion {
        position: absolute;
        max-width: 35%;
        max-height: 35%;
        top: 0;
        left: 0;

        &.disabled {
            opacity: 0.3;
        }
    }

    .casino-thumbnail-game-image-logo-provider {
        position: absolute;
        max-width: 40%;
        max-height: 40%;
        top: 0;
        right: 0;
    }

    .small-game-container {
        width: fit-content;
    }

    .casino-thumbnail-game-image {
        border-radius: var(--ui-radius);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        transition: opacity 0.3s, filter 0.3s;
        height: 100%;
        width: 100%;
        opacity: 1;
        &.disabled {
            opacity: 0.3;
        }
    }

    .casino-thumbnail-game-title {
        color: var(--font-color-secondary);
        font-weight: var(--font-weight-medium);
        font-size: var(--font-12);
        text-align: center;
        text-transform: capitalize;

        width: 100%;
        max-width: 190px;
        padding: var(--spacing-16) var(--spacing-8);
        height: 1.25rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media ${mediaQuery.isLaptopMin} {
            max-width: 150px;
        }
    }

    .casino-thumbnail-game-dealer {
        color: var(--font-color-primary);
        font-weight: var(--font-weight-heavy);
        font-size: var(--font-12);
        font-family: var(--font-family);
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .casino-thumbnail-bet-behind {
        font-weight: var(--font-weight-medium);
        background: #ec2127;
        border-radius: calc(var(--ui-radius) / 2);
        padding: var(--spacing-4) var(--spacing-8);
        color: var(--font-color-primary);
        font-size: var(--font-10);
        font-family: var(--font-family);
    }

    .casino-thumbnail-game-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: calc(var(--spacing-4) / 2) var(--spacing-8);
        width: 100%;
        line-height: 1;
        box-sizing: border-box;
        z-index: 2;
        min-height: 1.75rem;

        ${({ isPaybackBooster }) =>
            isPaybackBooster &&
            css`
                padding: 0;
            `}
    }

    .booster {
        color: ${({ isPaybackBoosterExpired }) =>
            isPaybackBoosterExpired ? ` ${({ theme }) => theme.typography.fontColorPrimary}` : '#000000'};
        font-size: var(--font-14);
        justify-content: center;

        ${({ isMobileDevice }) =>
            isMobileDevice &&
            css`
                font-size: var(--font-12);
                min-height: 1.875rem;
            `}
    }

    .casino-thumbnail-game-users-played {
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        white-space: nowrap;
        color: var(--font-color-secondary);
        svg {
            margin-right: var(--spacing-4);
        }
    }

    .casino-thumbnail-game-info-icon {
        display: none;
        z-index: 5;
        color: var(--font-color-secondary);

        &:hover {
            color: var(--font-color-primary);
        }
    }

    .casino-thumbnail-game-info-icon {
        position: absolute;
        top: 0.3rem;
        right: 0.5rem;
        margin-left: var(--spacing-4);
    }

    .hide {
        opacity: 0;
    }

    .remove {
        display: none;
    }
`;
