import React from 'react';
import Wrapper from './styles';
import { isB2B } from '../../../services/environment';

interface Props {
    vertical?: boolean;
    center?: boolean;
    fullWidth?: boolean;
    heightScale?: number;
}

export default function UiBrandLine({ vertical, heightScale = 1, center, fullWidth }: Props) {
    return (
        <Wrapper vertical={vertical} heightScale={heightScale} center={center} fullWidth={fullWidth}>
            {isB2B() ? (
                <div className="brand-line">
                    <div className="brand" />
                </div>
            ) : (
                <div className="brand-line">
                    <div className="green" />
                    <div className="blue" />
                    <div className="orange" />
                    <div className="red" />
                </div>
            )}
        </Wrapper>
    );
}
