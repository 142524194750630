import React from 'react';
import Wrapper from './styles';
import { CategoryMatch } from '../../../../services/sports/types';
import { amountOfMarketsByDevice, useHiddenMarketsCount } from '../../../../services/sports/top-markets';
import { useSportsMatchContext } from '../../../../services/sports/match-helpers';
import Svg from '../../../svg/Svg';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import { getRoute, useRouter } from '../../../../services/router';
import { MATCH_STATUS } from '../../../../services/sports/constants';
import { isRetail } from '../../../../services/environment';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    match: CategoryMatch;
}

export default function SportMatchSidebetsCount({ match }: Props) {
    const [{ deviceType }] = useStore(media);
    const numMarketsToShow = amountOfMarketsByDevice[deviceType];
    const { hiddenMarketsCount } = useHiddenMarketsCount(match, numMarketsToShow);
    const { setContext: setMatchContext, isOpen } = useSportsMatchContext();
    const isRetailLayout = isRetail();
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);
    const isLiveNow = match.status === MATCH_STATUS.LIVE;
    const matchRoute = isLiveNow ? `${getRoute('sport.live')}/${match.id}` : `${getRoute('sport.match')}/${match.id}`;
    const { navigateTo } = useRouter();

    if (!Boolean(hiddenMarketsCount)) {
        return null;
    }

    function openSidebets() {
        isLayoutB || isRetailLayout
            ? !isOpen && navigateTo(matchRoute)
            : setMatchContext?.({ isOpen: !isOpen, currentVisualization: isRetailLayout ? 'scoreboard' : '' });
    }

    return (
        <Wrapper onClick={() => openSidebets()} isOpen={isOpen}>
            {Boolean(hiddenMarketsCount) && (
                <>
                    {isRetailLayout ? (
                        <div className="more-wagers">
                            <div className="icon">
                                <Svg icon="plus-sign" size={1.5} />
                            </div>
                            <div className="text">
                                More <br /> wagers
                            </div>
                        </div>
                    ) : (
                        <div>+{hiddenMarketsCount}</div>
                    )}
                </>
            )}
        </Wrapper>
    );
}
