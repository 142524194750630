import { stores } from '../../stores';
import { getClient } from '../environment';
import { DisabledFeaturesByClient } from '../feature';
import { ScoreboardType } from '../sports/types';
import { ClientName } from './types';

export enum EventType {
    Bootstrap = 'bootstrap',
    Login = 'login',
    LoginRequired = 'loginRequired',
    PlaceBetClicked = 'placeBetClicked',
    BetPlaced = 'betPlaced',
    Cashout = 'cashout',
}

export function loadClientConfig() {
    const client = getClient();
    const clientConfig = {};
    DisabledFeaturesByClient[client]?.forEach((feature) => {
        clientConfig[feature] = { disabled: true };
    });
    stores.clientConfig.set(clientConfig);
}

export function dispatchEvent(event: EventType, params?) {
    if (window.coolb2b?.events) {
        const customEvent = new CustomEvent(event, { detail: params });
        window.coolb2b.events.dispatchEvent(customEvent);
        console.log('[B2B] event:', event, params);
    }
}

export function isScoreboardEnabled(scoreboardType: ScoreboardType, requiredScoreboardType?: ScoreboardType) {
    const client = getClient();
    const enabledScoreboardsByClient = {
        [ClientName.IVC]: [ScoreboardType.betgenius],
        [ClientName.WYNNBET]: [ScoreboardType.betgenius],
    };

    return (
        (requiredScoreboardType ? scoreboardType === requiredScoreboardType : true) &&
        (enabledScoreboardsByClient[client] ? enabledScoreboardsByClient[client].includes(scoreboardType) : true)
    );
}
