import { createStores } from './store/utils';

export const modals = createStores({
    isMessageModalOpen: false,
    isLoginModalOpen: false,
    isLoginFormOpen: true,
    isNemIdModalOpen: false,
    isBankIdModalOpen: false,
    isTermsAndConditionsAcceptanceModalOpen: false,
    isRequiredProfileInformationInquiryModalOpen: false,
    isEssentialUserInformationInquiryModalOpen: false,
    isUnapprovedSessionReminderOpen: false,
    isResponsibleGamingAnnualReportOpen: false,
    isTwoMinuteWarningModalVisible: false,
    isInactivityModalVisible: false,
    isAccountDataDownloadModalOpen: false,
    isPayAndPlayModalOpen: false,
    isBetRequirementsModalOpen: false,
    isGeoComplyModalOpen: false,
    isMultipleSessionModalOpen: false,
});
