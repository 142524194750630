import React from 'react';
import CmsPage from '../cms/CmsPage';
import MainLayout from '../../layouts/main/MainLayout';

export default function ArticlePreviewPage() {
    return (
        <MainLayout>
            <CmsPage isPreviewRequest={true} />
        </MainLayout>
    );
}
