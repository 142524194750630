import React from 'react';
import Wrapper from './styles';
import { CasinoGame, getGameImageUrl } from '../../../../../services/casino/games';
import UiImageLoaderBackground from '../../../../ui/image-loader/background/UiImageLoaderBackground';
import classNames from 'classnames';

interface Props {
    className?: string;
    game: CasinoGame;
    onGameRtpInfoSelect?: (selectedGame: CasinoGame) => void;
    isSelectedGame?: boolean;
}

export default function RtpCalculatorGameSelectionSearchGame({
    game,
    onGameRtpInfoSelect,
    className,
    isSelectedGame = false,
}: Props) {
    return (
        <Wrapper
            className={classNames(className)}
            onMouseDown={() => (onGameRtpInfoSelect ? onGameRtpInfoSelect(game) : '')}
        >
            <div className="rtp-calculator-game-selection-search-game-image-container">
                <UiImageLoaderBackground
                    imageUrl={getGameImageUrl(game.imageName, 80)}
                    className={
                        isSelectedGame
                            ? 'rtp-calculator-game-selection-search-game-image-selected'
                            : 'rtp-calculator-game-selection-search-game-image'
                    }
                />
            </div>
            <div className="rtp-calculator-game-selection-search-game-body">
                <div className="rtp-calculator-game-selection-search-game-title">{game.name}</div>
            </div>
        </Wrapper>
    );
}
