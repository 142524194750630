import React, { useMemo } from 'react';
import { translate } from '../../../services/translate';
import { getRoute, isActiveRoute } from '../../../services/router';
import { FEATURE, isFeatureAvailable } from '../../../services/feature';
import { stores } from '../../../stores';
import compact from 'lodash/compact';
import Wrapper from './styles';
import Svg from '../../svg/Svg';
import UiButton from '../../ui/button/UiButton';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

export default function SportLobbyCategories() {
    const [liveMatchesCount] = useStore(stores.sports.liveMatchesCount);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [{ isPhone, isTablet }] = useStore(media);

    const links = useMemo(
        () =>
            compact([
                {
                    label:
                        isPhone || isTablet
                            ? translate('sports.navigation.sports-lobby.short')
                            : translate('sports.navigation.sports-lobby'),
                    route: getRoute('sport.recommendations'),
                    icon: 'sports-ico',
                },
                {
                    label:
                        isPhone || isTablet
                            ? translate('sports.navigation.livebet-lobby.short')
                            : translate('sports.navigation.livebet-lobby'),
                    route: getRoute('sport.live'),
                    icon: 'live-stream',
                },
                isFeatureAvailable(FEATURE.PARLAY_CARD) && {
                    route: getRoute('sport.parlay-cards'),
                    icon: 'parley-card',
                    label: translate('Parlay Cards', 'ui.sportsbook'),
                },
                isFeatureAvailable(FEATURE.CONTESTS) && {
                    route: getRoute('sport.contests'),
                    icon: 'casino-race',
                    label: translate('Contests', 'ui.sportsbook'),
                },
                isFeatureAvailable(FEATURE.INCOMING_BETS) && {
                    label:
                        isPhone || isTablet
                            ? translate('sports.navigation.incoming-bets-button.short')
                            : translate('sports.navigation.incoming-bets-button'),
                    route: getRoute('sport.incoming-bets'),
                    icon: 'incoming-bets',
                },
                isFeatureAvailable(FEATURE.VIRTUAL_SPORTS) && {
                    label:
                        isPhone || isTablet
                            ? translate('sports.navigation.virtual-sport-button.short')
                            : translate('sports.navigation.virtual-sport-button'),
                    route: getRoute('virtual-sports'),
                    icon: 'virtual-sports',
                },
                isFeatureAvailable(FEATURE.LEAGUE) && {
                    label:
                        isPhone || isTablet
                            ? translate('sports.navigation.league-button.short')
                            : translate('sports.navigation.league-button'),
                    route: getRoute('league'),
                    icon: 'coolbet-league',
                },
                isFeatureAvailable(FEATURE.WINNERS_FEED) && {
                    label:
                        isPhone || isTablet
                            ? translate('sports.navigation.winners-feed-button.short')
                            : translate('sports.navigation.winners-feed-button'),
                    route: getRoute('sport.winners-feed'),
                    icon: 'cash',
                },
            ]),
        [isAuthenticated],
    );

    return (
        <Wrapper>
            {links.map(({ route, icon, label }) => {
                const isLiveCard = route.includes(`${getRoute('sport.live')}`);
                return (
                    <UiButton
                        url={route}
                        key={route}
                        type="card"
                        icon={<Svg icon={icon} size={1.4} />}
                        badge={isLiveCard ? liveMatchesCount : 0}
                        size={isPhone ? 'small' : 'default'}
                        selected={isActiveRoute(route, false)}
                    >
                        {label}
                    </UiButton>
                );
            })}
        </Wrapper>
    );
}
