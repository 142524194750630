import styled from 'styled-components';
import { mediaQuery } from '../../styles/utils';

export default styled.div`
    .load-more-button {
        margin: 1rem;
        @media ${mediaQuery.isPhone} {
            margin: 0 0 ${({ theme }) => theme.layout.gutter} 0;
        }
    }

    .blog-highlighted {
        margin: 0 ${({ theme }) => theme.layout.gutter} ${({ theme }) => theme.layout.gutter} 0;
        display: flex;
        @media ${mediaQuery.isPhone} {
            margin: 0;
        }

        .blog-highlighted-preview {
            display: flex;
            margin: 0;
            font-size: 1rem;

            @media ${mediaQuery.isTablet} {
                margin-right: ${({ theme }) => theme.layout.gutter};
            }
        }
    }

    .blog-articles {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: stretch;
        @media ${mediaQuery.isPhone} {
            display: block;
        }
        @media ${mediaQuery.isTablet} {
            padding-right: ${({ theme }) => theme.layout.gutter};
        }

        .blog-articles-preview {
            display: flex;
            flex: 0 0 25%;
            padding: 0 1rem 1rem 0;
            h1 {
                font-size: 1.6rem;
            }

            @media (max-width: 1600px) {
                flex: 1 1 33.333%;
            }

            @media (max-width: 1400px) {
                flex: 1 1 50%;
            }

            @media (max-width: 850px) {
                flex: 1 1 100%;
            }
            @media ${mediaQuery.isPhone} {
                display: block;
                padding: 0;
                margin-bottom: var(--spacing-12);
            }
        }
    }
`;
