import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withErrorBoundary } from '../../../../higher-order-components/error-boundary';
import { BetHistoryRequest, getBetHistory } from '../../../../microservices/sbgate';
import { TICKET_STATUS, TICKET_TYPE, TICKET_STATUS_SETTLED, TIME_FILTER } from '../../../../services/bet-history';
import { logger } from '../../../../services/logger';
import { useCashoutListeners } from '../../../../services/sports/cashout';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import BetHistoryFiltersDate from '../../../bet-history/filters/date/BetHistoryFiltersDate';
import BetHistoryFiltersStatus from '../../../bet-history/filters/status/BetHistoryFiltersStatus';
import UiAlert from '../../../ui/alert/UiAlert';
import UiDotsLoader from '../../../ui/dots-loader/UiDotsLoader';
import SportTotals from '../../totals/SportTotals';
import SportTicket from '../SportTicket';
import Wrapper from './styles';
import UiButton from '../../../ui/button/UiButton';
import { getRoute } from '../../../../services/router';
import { Ticket } from '../../../../services/sports/types';
import { useSportsUserSettings } from '../../../../services/sports/user-settings';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import SportTicketDetails from '../details/SportTicketDetails';
import { isB2B } from '../../../../services/environment';
import { PRODUCT } from '../../../../types/common';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    isCompactView?: boolean;
    isBetHistory?: boolean;
}

function SportTicketList({ isCompactView, isBetHistory = false }: Props) {
    const { layout } = useSportsUserSettings();
    const [pageSize] = useStore(stores.betHistory.pageSize);
    const [language] = useStore(stores.language);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [page, setPage] = useState(1);
    const [tickets, setTickets] = useState([] as Ticket[]);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTicketStatusFilter, setSelectedTicketStatusFilter] = useState<string>(TICKET_STATUS.PENDING);
    const [betHistoryDateFilter, setBetHistoryDateFilter] = useState<string>(TICKET_STATUS.ALL);
    const [isLoadingErrorVisible, setIsLoadingErrorVisible] = useState(false);
    const defaultQueryParams: BetHistoryRequest = {
        isCashout: [TICKET_TYPE.CASHOUT, TICKET_STATUS_SETTLED].includes(selectedTicketStatusFilter),
        isCampaign: selectedTicketStatusFilter === TICKET_TYPE.CAMPAIGN,
        pageNumber: page,
        pageSize,
    };
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    if (![TICKET_TYPE.CASHOUT, TICKET_TYPE.CAMPAIGN].includes(selectedTicketStatusFilter)) {
        defaultQueryParams.ticketStatus = selectedTicketStatusFilter;
    }

    if (betHistoryDateFilter !== TIME_FILTER.ALL) {
        defaultQueryParams.timestampTo = moment().unix();
        defaultQueryParams.timestampFrom =
            betHistoryDateFilter === TIME_FILTER.THREE_MONTHS
                ? moment()
                      .subtract(3, TIME_FILTER.MONTH as any)
                      .unix()
                : moment()
                      .subtract(1, betHistoryDateFilter as any)
                      .unix();
    }

    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);

    useEffect(() => {
        if (isAuthenticated) {
            loadTickets();
        }
    }, [betHistoryDateFilter, pageSize, selectedTicketStatusFilter, language, layout, isAuthenticated]);

    useCashoutListeners({ cashoutTickets: tickets, setTickets });

    async function loadTickets() {
        setIsLoading(true);
        setIsLoadingErrorVisible(false);

        try {
            setTickets([]);
            setHasNextPage(false);
            setPage(1);
            const { tickets, hasNextPage } = await getBetHistory({
                ...defaultQueryParams,
                pageNumber: 1,
            });
            setTickets(tickets);
            setHasNextPage(Boolean(hasNextPage));

            if (isFirstLoad && !tickets.length) {
                setSelectedTicketStatusFilter(TICKET_STATUS_SETTLED);
                setIsFirstLoad(false);
            }
        } catch (error) {
            logger.error('SportTicketList', 'loadTickets', error);
            setIsLoadingErrorVisible(true);
        }
        setIsLoading(false);
    }

    async function loadNextPage() {
        const nextPage = page + 1;
        setPage(nextPage);
        setIsLoading(true);
        try {
            const betHistoryTickets = await getBetHistory({ ...defaultQueryParams, pageNumber: nextPage });
            setTickets([...tickets, ...betHistoryTickets.tickets]);
            setHasNextPage(Boolean(betHistoryTickets.hasNextPage));
        } catch (error) {
            logger.error('SportTicketList', 'loadNextPage', error);
        }
        setIsLoading(false);
    }

    return (
        <Wrapper isCompactView={isCompactView}>
            <div className="ticket-filters">
                {!isCompactView && (
                    <>
                        <BetHistoryFiltersDate
                            className="date-filters"
                            selectedFilter={betHistoryDateFilter}
                            onFilterSelected={setBetHistoryDateFilter}
                        />
                    </>
                )}
                <BetHistoryFiltersStatus
                    className="status-filters"
                    selectedFilter={selectedTicketStatusFilter}
                    onFilterSelected={setSelectedTicketStatusFilter}
                    isCompact={Boolean(isCompactView)}
                />
            </div>
            <div className="tickets-container">
                {isLoading && <UiDotsLoader className="loader" />}

                {!isLoading && !tickets.length && (
                    <UiAlert warning>{translate('No tickets found', 'ui.account')}</UiAlert>
                )}

                {tickets.map((ticket) =>
                    isLayoutB ? (
                        <SportTicketDetails
                            key={ticket.id}
                            ticket={ticket}
                            isCompactView={isCompactView}
                            isBetHistory={isBetHistory}
                        />
                    ) : (
                        <SportTicket
                            key={ticket.id}
                            ticket={ticket}
                            isCompactView={isCompactView}
                            isBetHistory={isBetHistory}
                        />
                    ),
                )}
            </div>

            {isLoadingErrorVisible && <UiAlert failure>{translate('Could not load tickets', 'ui.account')}</UiAlert>}

            <div className="tickets-buttons">
                {hasNextPage && !isLoading && (
                    <UiButton onClick={loadNextPage} block isLoading={isLoading}>
                        {translate('Load more', 'ui.common')}
                    </UiButton>
                )}

                {!isLoading && isFeatureAvailable(FEATURE.USER_BETTING_PERFORMANCE) && (
                    <UiButton url={getRoute('sport.performance')} block>
                        {translate('sport.ratings.your-performance')}
                    </UiButton>
                )}

                {!isCompactView && !isLoading && !isB2B() && (
                    <SportTotals product={PRODUCT.SPORTSBOOK} selectedDateFilter={betHistoryDateFilter} />
                )}
            </div>
        </Wrapper>
    );
}

export default withErrorBoundary(SportTicketList);
