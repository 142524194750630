import React from 'react';
import * as Sentry from '@sentry/browser';
import bearImg from './404bear.png';
import { translate } from '../../services/translate';
import UiButton from '../../components/ui/button/UiButton';
import { FEATURE, isFeatureAvailable } from '../../services/feature';

export class ErrorBoundary extends React.PureComponent<any, { error: any }> {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error });
        Sentry.configureScope((scope) => {
            Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key]);
            });
        });
        Sentry.captureException(error);
    }

    render() {
        if (this.state.error) {
            return (
                <div style={{ textAlign: 'center', width: '100%', maxWidth: '100px' }}>
                    <p>{translate(['components.errorBoundary.componentBlewUp', 'Component not working'])}</p>
                    {isFeatureAvailable(FEATURE.REPORT_FEEDBACK) && (
                        <>
                            <img style={{ width: '100%' }} src={bearImg} alt="error happened" />
                            <UiButton color="primary" onClick={() => Sentry.showReportDialog()}>
                                Report feedback
                            </UiButton>
                        </>
                    )}
                </div>
            );
        }

        return this.props.children;
    }
}

export const withErrorBoundary = (ComponentThatMightFail) => (props) =>
    (
        <ErrorBoundary>
            <ComponentThatMightFail {...props} />
        </ErrorBoundary>
    );
