import React from 'react';
import Svg from '../../../svg/Svg';
import { stores } from '../../../../stores';
import { LANGUAGE } from '../../../../services/language';
import Wrapper from './styles';
import { getDocumentHost } from '../../../../services/util';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import { getStoreValue } from '../../../../stores/store/utils';

interface Props {
    externalId?: number | null;
}

export default function SportMatchStatistics({ externalId }: Props) {
    if (!isFeatureAvailable(FEATURE.PREMATCH_STATISTICS) || !externalId) {
        return null;
    }

    function openPopup(url, title, w: number, h: number) {
        // Fixes dual-screen position                         Most browsers      Firefox
        const dualScreenLeft: number =
            window.screenLeft !== undefined ? window.screenLeft : (window.screen as any).left;
        const dualScreenTop: number = window.screenTop !== undefined ? window.screenTop : (window.screen as any).top;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight;

        const left = width / 2 - w / 2 + dualScreenLeft;
        const top = height / 2 - h / 2 + dualScreenTop;
        const newWindow = window.open(url, title, `scrollbars=yes,width=${w},height=${h},top=${top},left=${left}`);

        // Puts focus on the newWindow
        if (newWindow) {
            newWindow.focus();
        }
    }

    const language = getStoreValue(stores.language);
    const langParam = language === LANGUAGE.CHILEAN ? 'esp' : 'eng';
    const statisticsUrl = `https://statistics.${getDocumentHost()}/statistics3/team-statistics.jsp?partner=coolbet&gameId=${externalId}&lang=${langParam}`;
    return (
        <Wrapper onClick={() => openPopup(statisticsUrl, 'Stats', 800, 800)}>
            <Svg icon="stats-ico" size={1.125} />
        </Wrapper>
    );
}
