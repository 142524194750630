import styled from 'styled-components';

import { lighten } from 'polished';
import { mediaQuery } from '../../../../../styles/utils';

export default styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: auto;

    margin-top: ${({ theme }) => theme.layout.gutterDouble};
    .page {
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--surface-level-1-bg);
        border: 1px solid ${({ theme }) => lighten(0.15, theme.box.background)};
        border-radius: var(--ui-radius);
        cursor: pointer;
        font-size: 0.85rem;
        color: var(--font-color-secondary);
        margin-bottom: ${({ theme }) => theme.layout.gutterHalf};
        @media ${mediaQuery.isPhone} {
            height: 45px;
            width: 45px;
        }
        &:not(:last-of-type) {
            margin-right: ${({ theme }) => theme.layout.gutterHalf};
        }
        &:hover {
            background-color: ${({ theme }) => lighten(0.15, theme.box.background)};
            border: 1px solid ${({ theme }) => lighten(0.2, theme.box.background)};
        }
        &.selected {
            background-color: ${({ theme }) => lighten(0.15, theme.box.background)};
            border-color: ${({ theme }) => lighten(0.3, theme.box.background)};
            color: var(--font-color-primary);
            border-width: 3px;
        }
        &.userSelectedAnswer {
            border-color: ${({ theme }) => theme.colors.secondary};
            color: ${({ theme }) => theme.colors.secondary};
        }
        &.correctAnswer {
            border-color: ${({ theme }) => theme.colors.success};
            color: ${({ theme }) => theme.colors.success};
        }
        &.wrongAnswer {
            border-color: ${({ theme }) => theme.colors.red};
            color: ${({ theme }) => theme.colors.red};
        }
    }
`;
