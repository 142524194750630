import React from 'react';
import Wrapper from './styles';
import { FieldError, RegisterOptions, useFormContext } from 'react-hook-form';
import UiAlert from '../../../ui/alert/UiAlert';
import { translate } from '../../../../services/translate';
import UiFormLabel from '../../../ui/form/label/UiFormLabel';
import classNames from 'classnames';

interface Props extends Omit<React.HTMLProps<HTMLInputElement>, 'pattern'> {
    error?: FieldError;
    name: string;
    label?: string;
    type?: 'text' | 'email' | 'password' | 'number' | 'tel' | 'search';
    pattern?: RegExp;
    validator?: {
        validate: Record<string, (value: any, form?: any) => true | string>;
    };
    dataTest?: string;
}
export default function Ui2FormTextInput({
    name,
    error,
    label,
    dataTest,
    minLength,
    maxLength,
    pattern,
    validator,
    required = true,
    type = 'text',
    ...args
}: Props) {
    const { register } = useFormContext();

    const options: RegisterOptions = {
        required: { value: required, message: translate('Oops! You forgot this field', 'ui.registration') },
        validate: validator?.validate,
    };

    if (minLength) {
        options.minLength = {
            value: minLength,
            message: translate(`Oops! Field ${name} should be more then ${minLength} characters.`, 'ui.registration'),
        };
    }

    if (maxLength) {
        options.maxLength = {
            value: maxLength,
            message: translate(`Oops! Field ${name} should be less then ${maxLength} characters.`, 'ui.registration'),
        };
    }

    if (pattern) {
        options.pattern = {
            value: pattern,
            message: translate('Oops! You are using invalid characters.', 'ui.common'),
        };
    }

    return (
        <Wrapper>
            {label && <UiFormLabel>{label}</UiFormLabel>}
            <input
                {...register(name, options)}
                type={type}
                {...args}
                data-test={dataTest}
                className={classNames({ error })}
            />
            {error && (
                <UiAlert className="input-error" failure>
                    {error.message}
                </UiAlert>
            )}
        </Wrapper>
    );
}
