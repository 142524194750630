import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { storageGet } from '../services/storage';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { translate } from '../services/translate';
import { getCodeProvider, getCodeWithoutProvider, suffixMap } from '../services/refer-a-friend';
import { isLocalDevelopment } from '../services/environment';
import { getDocumentHost } from '../services/util';

export type Invitation = {
    id: number;
    rewardAmount?: number;
    status: string;
    expired: boolean;
    name: string;
    created: Date;
    source: string;
    expiresIn: number;
};
type Setting = {
    header: string;
    rules: string;
    crossSell?: string;
};

export type RAFSettings = {
    CA: Setting;
    IS: Setting;
    FI: Setting;
    NO: Setting;
    CL: Setting;
    US: Setting;
    rest: Setting;
};

type Provider = {
    url?: string;
    subject?: string;
    text?: string;
    body?: string;
};

export type Providers = {
    email: Provider & { url: string };
    facebook: Provider & { url: string };
    facebookMessenger: Provider;
    link: Provider;
    skype: Provider;
    sms: Provider;
    twitter: Provider;
    viber: Provider;
    whatsApp: Provider;
};

const getUrl = (url) => getServiceUrl('refer-a-friend', url);

export async function loadParticipant(userId: string) {
    const url = getUrl('api/participants/load');
    const response = await httpGet<any>(url, { userId });

    return {
        ...response,
        invitations: response.invitations || [],
    };
}

export async function getParticipantProviders(code, user): Promise<Providers> {
    const language = getStoreValue(stores.language);

    const referUrl = `https://${getDocumentHost()}/${language}/refer/?code=${code}`;
    const prerenderUrl = '?_escaped_fragment_=';

    if (!isLocalDevelopment()) {
        await Promise.all([
            httpGet<any>(`/${language}/refer/?code=${code}-F${prerenderUrl}`),
            httpGet<any>(`/${language}/refer/?code=${code}-C${prerenderUrl}`),
        ]);
    }

    const urlTo = (provider) => `${referUrl}-${provider}`;
    const emailUrl = `${referUrl}-${suffixMap.email}`;
    return {
        facebook: {
            url: urlTo(suffixMap.facebook),
            text: translate('raf.short-invite'),
        },
        facebookMessenger: {
            url: `fb-messenger://share/?link=${urlTo(suffixMap.facebookMessenger)}`,
        },
        twitter: {
            url: urlTo(suffixMap.twitter),
            text: translate('raf.short-invite-twitter'),
        },
        email: {
            url: urlTo(suffixMap.email),
            subject: translate('Subject', 'raf.email', [user.firstName]),
            body: translate('Body', 'raf.email', [user.firstName, emailUrl, emailUrl]).replace(/\\n/g, '\n'),
        },
        link: {
            text: urlTo(suffixMap.link),
        },
        whatsApp: {
            url: urlTo(suffixMap.whatsapp),
        },
        viber: {
            url: urlTo(suffixMap.viber),
        },
        skype: {
            url: `https://web.skype.com/share?url=${urlTo(suffixMap.skype)}&text=${translate('raf.short-invite')}`,
        },
        sms: {
            url: `sms:?&body=${translate('raf.short-invite')}: ${urlTo(suffixMap.sms)}`,
        },
    };
}

export async function findByCode(code) {
    const url = getUrl('api/participants/find-by-code');
    return httpGet<{ firstName: string }>(url, { code: getCodeWithoutProvider(code) });
}

export async function trackAction(action) {
    const code = storageGet('raf_code');

    if (code) {
        const language = getStoreValue(stores.language);

        return httpPost(getUrl('api/participants/track'), {
            action,
            code: getCodeWithoutProvider(code),
            source: getCodeProvider(code),
            language,
        });
    }
}

export async function linkInvitation(userId, code) {
    return httpPost<any>(getUrl('api/participants/link'), {
        userId,
        code: getCodeWithoutProvider(code),
        source: getCodeProvider(code),
    });
}

export async function remindParticipant(invitationId) {
    const url = getUrl('api/participants/remind');
    const response = await httpPost<any>(url, { invitationId });
    const isReminded = response.status === 'reminded';

    return { isReminded };
}

export async function getRafSettingsByKey(settingsKey) {
    const url = getUrl('api/settings/find-by-key');
    return httpGet<{ id: number; key: string; value: string }>(url, { key: settingsKey });
}
