import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import Svg from '../../../svg/Svg';
import { Collapse } from '@mui/material';
import { useComponentFocused } from '../../../../services/hooks';
import RtpCalculatorGameSelectionSearchGame from './game/RtpCalculatorGameSelectionSearchGame';
import classnames from 'classnames';
import { translate } from '../../../../services/translate';
import { CasinoGame } from '../../../../services/casino/games';
import uniqBy from 'lodash/uniqBy';

interface Props {
    gamesWithRtpVariant: CasinoGame[];
    preSelectedGameIds?: string[];
    onGameRtpInfoSelect?: (gameRtpInfoId: number) => void;
}

export default function RtpCalculatorGameSelectionSearch({
    gamesWithRtpVariant,
    preSelectedGameIds,
    onGameRtpInfoSelect,
}: Props) {
    const [elementRefInput, isFocusedInput, setIsFocusedInput] = useComponentFocused(false);
    const [isGameRtpInfoVisible, setIsGameRtpInfoVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [foundGames, setFoundGames] = useState<CasinoGame[]>([]);
    const [selectedGame, setSelectedGame] = useState<CasinoGame>({} as CasinoGame);

    useEffect(() => setFoundGames(searchRtpCalculatorGames(searchTerm, 50)), [gamesWithRtpVariant, searchTerm]);

    function searchRtpCalculatorGames(searchWord: string, limit): CasinoGame[] {
        if (!searchWord) {
            if (preSelectedGameIds) {
                const preSelectedArray = gamesWithRtpVariant.filter((game) =>
                    preSelectedGameIds.includes(game.id.toString()),
                );
                return preSelectedArray
                    .sort(function (a, b) {
                        return (
                            preSelectedGameIds.indexOf(a.id.toString()) - preSelectedGameIds.indexOf(b.id.toString())
                        );
                    })
                    .slice(0, limit);
            }
            return uniqBy([...gamesWithRtpVariant], 'id').slice(0, limit);
        }

        return uniqBy(
            [
                ...gamesWithRtpVariant.filter((game) => game.name.toLowerCase().startsWith(searchWord.toLowerCase())),
                ...gamesWithRtpVariant.filter((game) => game.name.toLowerCase().includes(searchWord.toLowerCase())),
            ],
            'id',
        ).slice(0, limit);
    }

    function updateSelectedGame(newSelectedGame: CasinoGame) {
        setSearchTerm('');
        setSelectedGame(newSelectedGame);
        setIsGameRtpInfoVisible(false);
        if (onGameRtpInfoSelect) {
            onGameRtpInfoSelect(newSelectedGame.id);
        }
    }

    function clearSearch() {
        if (Object.keys(selectedGame).length) {
            setSelectedGame({} as CasinoGame);
            if (onGameRtpInfoSelect) {
                onGameRtpInfoSelect(0);
            }
        } else {
            setSearchTerm('');
        }
        setIsGameRtpInfoVisible(true);
    }

    function handleInputChange(event): void {
        setSearchTerm(event.target.value);
    }

    function clearGameSelection() {
        clearSearch();
        setIsFocusedInput(true);
        elementRefInput.current?.focus();
        setIsGameRtpInfoVisible(true);
    }

    return (
        <Wrapper
            className={classnames({ focused: isFocusedInput }, { 'game-selected': Object.keys(selectedGame).length })}
        >
            <div className="rtp-calculator-game-selection-search-container">
                <div className="rtp-calculator-game-selection-search-container-wrapper" onClick={clearGameSelection}>
                    {Object.keys(selectedGame).length !== 0 && (
                        <RtpCalculatorGameSelectionSearchGame
                            className="rtp-calculator-game-selection-search-input-selected"
                            key={selectedGame.id}
                            game={selectedGame}
                            isSelectedGame={true}
                        />
                    )}
                    <div className="rtp-calculator-game-selection-search-input-container">
                        <Svg className="rtp-calculator-game-selection-search-icon" icon="search-ico" />
                        <input
                            ref={elementRefInput}
                            onChange={handleInputChange}
                            value={searchTerm}
                            autoComplete="off"
                            onFocus={() => setIsGameRtpInfoVisible(true)}
                            onBlur={() => setIsGameRtpInfoVisible(false)}
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            data-gramm_editor="false"
                            data-gramm="false"
                            className="rtp-calculator-game-selection-search-input"
                            placeholder={translate('Search...', 'casino.search')}
                        />
                    </div>
                </div>

                <div className="rtp-calculator-game-selection-search-cross-wrapper">
                    <div
                        className="rtp-calculator-game-selection-search-cross-container"
                        onClick={() => {
                            if (isGameRtpInfoVisible) {
                                setIsGameRtpInfoVisible(false);
                            } else {
                                clearGameSelection();
                            }
                        }}
                    >
                        <Svg icon="cross" />
                    </div>
                </div>
            </div>
            <Collapse in={isGameRtpInfoVisible} className="rtp-calculator-game-selection-search-result-wrapper">
                <div className="rtp-calculator-game-selection-search-result-container">
                    {!foundGames.length && searchTerm !== '' ? (
                        <div className="rtp-calculator-game-selection-search-result-container-no-games">
                            <div className="rtp-calculator-game-selection-search-result-container-no-games-text">
                                {translate('No games found', 'casino.search')}
                            </div>
                        </div>
                    ) : (
                        <div className="rtp-calculator-game-selection-search-result-container-games invisible-scrollbar">
                            {foundGames.map((game) => (
                                <RtpCalculatorGameSelectionSearchGame
                                    key={game.id}
                                    game={game}
                                    onGameRtpInfoSelect={updateSelectedGame}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </Collapse>
        </Wrapper>
    );
}
