import React from 'react';
import { translate } from '../../../services/translate';
import Logo from '../../logo/Logo';
import Snippet from '../../snippet/Snippet';
import UiButton from '../../ui/button/UiButton';
import UiBox from '../box/UiBox';
import UiGroup from '../group/UiGroup';
import UiModal from '../modal/UiModal';
import UiSubHeading from '../sub-heading/UiSubHeading';
import Wrapper from './styles';

interface Props {
    open?: boolean;
    title?: string;
    message?: string;
    snippetKey?: string;
    dismissPhrase?: string;
    acceptPhrase?: string;
    onAccept?: () => void;
    onDismiss?: (...args: unknown[]) => void;
    isCoolbearVisible?: boolean;
    dataTestAccept?: string;
    dataTestCancel?: string;
    maxWidth?: number;
    buttonsVertical?: boolean;
}

export default function UiPrompt({
    open,
    title,
    message,
    snippetKey,
    dismissPhrase,
    acceptPhrase,
    onAccept = () => {},
    onDismiss = () => {},
    isCoolbearVisible = true,
    dataTestAccept = '',
    dataTestCancel = '',
    maxWidth = 350,
    buttonsVertical,
}: Props) {
    // TODO: This could be done with material-ui dialogs but not sure how to style these as needed.

    const buttonGroupProps = buttonsVertical ? { vertical: true } : { horizontal: true };
    return (
        <UiModal open={open} maxWidth={maxWidth} centered onClose={onDismiss}>
            <UiBox>
                <Wrapper>
                    <UiGroup>
                        {isCoolbearVisible && <Logo size={35} />}
                        {title && <UiSubHeading className="prompt-title">{title}</UiSubHeading>}
                        {message && <p className="prompt-message">{message}</p>}
                        {snippetKey && <Snippet snippetKey={snippetKey} />}
                    </UiGroup>

                    <UiGroup expand {...buttonGroupProps}>
                        <UiButton
                            data-test={dataTestCancel}
                            onClick={(ev) => onDismiss(ev, 'dismissButtonClick')}
                            block
                        >
                            {dismissPhrase || translate('No', 'ui.common')}
                        </UiButton>

                        <UiButton data-test={dataTestAccept} color="primary" onClick={onAccept} block>
                            {acceptPhrase || translate('Yes', 'ui.common')}
                        </UiButton>
                    </UiGroup>
                </Wrapper>
            </UiBox>
        </UiModal>
    );
}
