import React from 'react';
import { HotEvent } from '../../../../../microservices/cms';
import { CategoryMatchMarket, CategoryMatchMarketOutcome } from '../../../../../services/sports/types';
import SportOdds from '../../../../sport/odds/SportOdds';
import Wrapper from './styles';

interface Props {
    outcome: CategoryMatchMarketOutcome;
    market: CategoryMatchMarket;
    event: HotEvent;
}

export default function RetailHotEventsItemSingleOdd({ outcome, market, event }: Props) {
    if (!event.matchMarkets) {
        return null;
    }
    return (
        <Wrapper key={outcome.id}>
            <div className="sidebet-outcome-name">{outcome.name}</div>
            <div className="exact-odds">
                <SportOdds
                    light={true}
                    outcomeId={outcome.id}
                    market={{
                        ...market,
                        status: market.status,
                        view_type: market.view_type,
                        in_play: false,
                        betting_end: event.matchMarkets.betting_end,
                    }}
                />
            </div>
        </Wrapper>
    );
}
