import store from 'store';
import { Store } from '../stores/store/types';
import { useStore } from '../hooks/useStore';

export const LocalStorage = {
    AVAILABLE_LICENCES: 'app-licences',
};

export function storageGet<T>(key: string, defaultValue?: T): T {
    return store.get(key, defaultValue);
}

export function storageSet(key: string, value: unknown) {
    store.set(key, value);
}

export function storageRemove(key: string) {
    store.remove(key);
}

export function useStorage<T>(store: Store<T>, key: string): [T, (value: T) => void] {
    const [get, set] = useStore(store);

    function setValue(value: T) {
        if (value) {
            storageSet(key, value);
        } else {
            storageRemove(key);
        }

        set(value);
    }

    return [get, setValue];
}
