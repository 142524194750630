import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { getStreamDRM } from '../../../../microservices/betgenius-streams-web';
import { stores } from '../../../../stores';
import { isProduction } from '../../../../services/util';
import UiAlert from '../../../ui/alert/UiAlert';
import { logger } from '../../../../services/logger';
import UiBlobLoader from '../../../ui/blob-loader/UiBlobLoader';
import { translate } from '../../../../services/translate';
import Wrapper from './styles';
import { getWidthToFitInContainer } from '../../../../services/browser';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../../services/analytics';
import { FoCategoryMatch } from '@staycool/sbgate-types';
import { media as mediaStore } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    betgeniusId: number;
    match: FoCategoryMatch;
}

interface GeniusSportsEvent {
    detail: {
        type: string;
        body: {
            streamId: string;
            deliveryType: 'HLS' | 'DASH';
            deliveryId: string;
            geniusSportsFixtureId: number;
        };
    };
}

export default function SportMatchGeniusPlayer({ betgeniusId, match }: Props) {
    const [urlToCall, setUrlToCall] = useState<string>();
    const [isScriptError, setIsScriptError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [{ isPhone, isTablet }] = useStore(mediaStore);
    const [liveTree] = useStore(stores.sports.liveTree);
    const [prematchTree] = useStore(stores.sports.prematchTree);
    const [ipCountry] = useStore(stores.ipCountry);
    const media = isPhone ? 'MOBILE' : isTablet ? 'TABLET' : 'DESKTOP';
    const playerId = 'geniusLive';

    const sportName =
        liveTree?.find((section) => section.sport_id === match.sport)?.sportName ||
        prematchTree.children?.find((sportCategory) => sportCategory.id === match.sport_category)?.name;

    useEffect(() => {
        init();
    }, [betgeniusId]);

    useEffect(() => {
        const startStreamingTime = new Date();
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.BETGENIUS_STREAM_ON, {
            betgenius_stream_match_id: match.id,
            betgenius_stream_match_name: match.name,
            betgenius_stream_league_id: match.category_id,
            betgenius_stream_league_name: match.categoryName,
            betgenius_stream_sport_id: match.sport,
            betgenius_stream_sport_name: sportName,
        });
        return () => {
            const streamDuration = (new Date().getTime() - startStreamingTime.getTime()) / 1000;
            trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.BETGENIUS_STREAM_OFF, { stream_duration: streamDuration });
        };
    }, []);

    function init() {
        setIsLoading(true);
        const { height, width } = getStreamResolution();
        const resolution = `&width=${width}px&height=${height}px`;
        const domain = `genius-live-player-${isProduction() ? 'production' : 'uat'}.betstream.betgenius.com`;
        const customerId = '6718';
        try {
            const url = `https://${domain}/widgetLoader?customerId=${customerId}&fixtureId=${betgeniusId}&containerId=${playerId}${resolution}&autoplayEnabled=true&allowFullScreen=false`;
            setUrlToCall(url);
        } catch (e) {
            handleScriptError(e);
        }
    }

    function getStreamResolution() {
        const containerWidth = getWidthToFitInContainer('.match-layout-container');
        if (containerWidth) {
            const width = Math.floor(containerWidth);
            const height = Math.floor((width * 9) / 16);
            return { width, height };
        } else {
            return { width: isPhone ? 426 : 1024, height: isPhone ? 240 : 576 };
        }
    }

    useEffect(() => {
        window.addEventListener('geniussportsmessagebus', processStreamDrm);
        return () => {
            window.removeEventListener('geniussportsmessagebus', processStreamDrm);
            if (window['GeniusLivePlayer']) {
                GeniusLivePlayer.player?.close();
            }
        };
    }, []);

    async function processStreamDrm(event) {
        const typedEvent = event as unknown as GeniusSportsEvent;
        if (!typedEvent?.detail?.body) {
            return;
        }
        const {
            detail: {
                type,
                body: { deliveryId, deliveryType, geniusSportsFixtureId, streamId },
            },
        } = typedEvent;
        if (type === 'player_ready') {
            const getUuid = /\b[\da-f]{8}\b(?:-[\da-f]{4}){3}-\b[\da-f]{12}\b/;
            const uuid = document.cookie.match(getUuid) || [];
            const dataToPost = {
                deliveryType,
                streamId,
                deliveryId,
                geniusSportsFixtureId,
                endUserSessionId: uuid[0], //session id
                region: ipCountry, //region
                device: media, //device
            };
            try {
                const drmData = await getStreamDRM(dataToPost as any);
                GeniusLivePlayer?.player?.start(drmData);
                setIsLoading(false);
            } catch (error) {
                handleScriptError(error);
            }
        }
    }

    function handleScriptError(error) {
        logger.error('SportMatchGeniusPlayer', 'handleScriptError', error);
        setIsScriptError(true);
        setIsLoading(false);
    }

    return (
        <Wrapper>
            {urlToCall && (
                <span>
                    <Helmet>
                        <script src={urlToCall} async />
                    </Helmet>
                    <div id={playerId} />
                </span>
            )}
            {isScriptError && <UiAlert failure>{translate('technical_error', 'ui.sportsbook')}</UiAlert>}
            {isLoading && <UiBlobLoader />}
        </Wrapper>
    );
}

declare let GeniusLivePlayer: any;
