import React from 'react';
import { getClient } from '../../../../../services/environment';
import { CrazyResults } from '../../../../../services/casino/games';
import { environment } from '../../../../../stores/environment/environment';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    item: string;
}

export default function CasinoThumbnailLatestWinningsCrazyTimeResults({ item }: Props) {
    const getCrazyResultImageName = (result: string) => {
        switch (result) {
            case CrazyResults.NUM_1:
                return '01';
            case CrazyResults.NUM_2:
                return '02';
            case CrazyResults.NUM_5:
                return '05';
            case CrazyResults.NUM_10:
                return '10';
            case CrazyResults.PA:
                return 'plinko';
            case CrazyResults.BO:
                return 'crazy';
            case CrazyResults.CA:
                return 'shooting';
            case CrazyResults.FL_B:
            case CrazyResults.FL_R:
            case CrazyResults.FL:
                return 'coin';
            default:
                return '';
        }
    };

    const imageName = getCrazyResultImageName(item);
    const [{ IMAGES_HOST }] = useStore(environment);
    const client = getClient();

    if (!IMAGES_HOST || !client || !imageName) {
        return null;
    }

    const imagePath = `${IMAGES_HOST}${client}/casino/games/${imageName}.png?w=18`;

    return (
        <img
            srcSet={`${imagePath}&dpr=1 1x,
              ${imagePath}&dpr=2 2x,
              ${imagePath}&dpr=3 3x`}
            src={`${imagePath}&auto=compress`}
            width={18}
            alt={item}
        />
    );
}
