import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { stores } from '../../../../stores';
import { getStoreValue } from '../../../../stores/store/utils';
import { updateSiteLanguage } from '../../../../services/router';
import UiLanguageSelectMini from '../../../ui/language-select/mini/UiLanguageSelectMini';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    size?: number;
}

export default function CoolbetHeaderLanguages(
    props: Props & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
) {
    const [language] = useStore(stores.language);
    const isAuthenticated = getStoreValue(stores.isAuthenticated);

    return (
        <UiLanguageSelectMini
            {...props}
            currentLanguage={language}
            shift={isAuthenticated ? 'left' : 'right'}
            onValueChange={updateSiteLanguage}
        />
    );
}
