import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

type StyledProps = {
    $isOutright?: boolean;
    $isMobileCompactView?: boolean;
    $isNormalMatch?: boolean;
    $isRetailLayout?: boolean;
};

export default styled.div<StyledProps>`
    &.match-container {
        display: flex;
        align-items: center;
        &:hover {
            .sidebets {
                color: ${({ theme }) => theme.colors.highlight};
            }
            .match-market-name {
                opacity: 1;
            }
            .outcome-name {
                color: var(--match-primary-color);
            }
            .market-line-select {
                color: var(--match-primary-color);
            }
            .stream-tooltip {
                display: block;
            }
        }
        .match-header {
            width: var(--match-name-width-desktop);
            @media (max-width: 1600px) {
                width: var(--match-name-width-desktop-small);
            }
            @media ${mediaQuery.isLaptop} {
                width: ${({ $isOutright }) => ($isOutright ? '100%' : 'var(--match-name-width-laptop)')};
            }
            @media (max-width: 1300px) {
                width: ${({ $isMobileCompactView }) =>
                    $isMobileCompactView ? '' : 'var(--match-name-width-laptop-small)'};
                min-width: ${({ $isMobileCompactView }) =>
                    $isMobileCompactView ? '' : 'var(--match-name-width-laptop-small)'};
            }
            @media ${mediaQuery.isTablet} {
                width: var(--match-name-width-tablet);
                min-width: ${({ $isNormalMatch }) => (!$isNormalMatch ? 'var(--match-name-width-tablet)' : '')};
            }
            @media ${mediaQuery.isPhone} {
                padding: 0 0.4rem;
                width: initial;
                min-width: auto;
            }
            ${({ $isRetailLayout }) =>
                $isRetailLayout &&
                css<{
                    $isOutright?: boolean;
                }>`
                    width: ${({ $isOutright }) => ($isOutright ? '100%' : '250px !important')};
                    min-width: ${({ $isOutright }) => ($isOutright ? '' : '250px !important')};
                `}
            .match-name {
                display: flex;
                align-items: center;
                margin-bottom: 0.2rem;
                margin-top: ${({ $isRetailLayout }) => ($isRetailLayout ? '1.5rem' : '')};

                > *:not(:last-of-type) {
                    margin-right: 0.4rem;
                }
            }
        }
    }
    .close-button {
        position: relative;
        margin-bottom: 1.2rem;
    }

    .match-extra {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 3.5rem;
    }
`;
