import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { translate } from '../../../services/translate';
import { useClock } from '../../../services/hooks';
import { getSessionStartTime } from '../../../microservices/users';
import moment from 'moment';
import { logger } from '../../../services/logger';
import { getLimitsByType } from '../../../microservices/responsible-gaming';
import * as classnames from 'classnames';
import { stores } from '../../../stores';
import orderBy from 'lodash/orderBy';
import { LIMIT_TYPE } from '../../../services/responsible-gaming';
import { filterStyleProps } from '../../../styles/utils';
import { useStore } from '../../../hooks/useStore';

interface Props {
    className?: string;
    isGameTopBar?: boolean;
}

export default function ResponsibleGamingTimers(props: Props) {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [formattedCurrentTime, setFormattedCurrentTime] = useState('');

    const [sessionStartTime, setSessionStartTime] = useState<any>();
    const [formattedSessionDuration, setFormattedSessionDuration] = useState<any>();

    const [sessionEndTime, setSessionEndTime] = useState<any>();
    const [sessionLimitPeriodEndTime, setSessionLimitPeriodEndTime] = useState<any>();
    const [formattedDurationUntilSessionExpiry, setFormattedDurationUntilSessionExpiry] = useState('');

    const [secondsSinceClockStart, { pauseClock, resumeClock, resetClock }] = useClock();

    useEffect(() => {
        if (isAuthenticated) {
            setupPanelClocks();
        }

        return () => cleanupPanelClocks();
    }, [isAuthenticated]);

    useEffect(() => {
        updatePanelClocks();
    }, [sessionStartTime, sessionEndTime, secondsSinceClockStart]);

    function setupPanelClocks() {
        loadSessionStartTime();
        loadSessionDurationLimits();
        resumeClock();
    }

    function cleanupPanelClocks() {
        setFormattedCurrentTime('');
        setSessionStartTime(undefined);
        setFormattedSessionDuration(undefined);
        setSessionEndTime(undefined);
        setSessionLimitPeriodEndTime(undefined);
        setFormattedDurationUntilSessionExpiry('');
        pauseClock();
        resetClock();
    }

    async function loadSessionStartTime() {
        try {
            const { sessionStart } = await getSessionStartTime();
            setSessionStartTime(moment(sessionStart));
        } catch (error) {
            logger.error('ResponsibleGamingTimers', 'loadSessionStartTime', error);
        }
    }

    function getLoginDurationLimitWithLeastRemainingAmount(
        loginDurationLimitsByPeriod: Record<string, { current_limit: any; next_limit: any }> = {},
    ) {
        const loginDurationLimits = Object.values(loginDurationLimitsByPeriod);

        return loginDurationLimits.length > 0
            ? orderBy(
                  loginDurationLimits,
                  (loginDurationLimit) => moment().add(loginDurationLimit.current_limit.remaining_amount, 'minutes'),
                  'asc',
              )[0]
            : {};
    }

    async function loadSessionDurationLimits() {
        setSessionEndTime(undefined);
        setSessionLimitPeriodEndTime(undefined);

        try {
            const { current_limit } = getLoginDurationLimitWithLeastRemainingAmount(
                await getLimitsByType(LIMIT_TYPE.LOGIN_DURATION),
            ) as { current_limit: any; next_limit: any };

            if (current_limit && current_limit.remaining_amount) {
                setSessionEndTime(moment().add(current_limit.remaining_amount, 'minutes'));
            }

            if (current_limit && current_limit.end_date) {
                setSessionLimitPeriodEndTime(moment(current_limit.end_date));
            }
        } catch (error) {
            logger.error('ResponsibleGamingTimers', 'loadSessionDurationLimits', error);
        }
    }

    function updatePanelClocks() {
        updateCurrentTime();
        updateSessionDuration();
        updateDurationUntilSessionExpiry();
    }

    function updateCurrentTime() {
        const currentTime = moment();
        setFormattedCurrentTime(currentTime.format('HH:mm'));
    }

    function updateSessionDuration() {
        if (sessionStartTime) {
            const currentTime = moment();
            const sessionDuration = moment.duration(currentTime.diff(sessionStartTime));
            setFormattedSessionDuration((sessionDuration as any).format('hh:mm', { trim: false })); //fix type here
        }
    }

    function updateDurationUntilSessionExpiry() {
        if (sessionEndTime) {
            const currentTime = moment();
            const durationUntilSessionExpiry = moment.duration(sessionEndTime.diff(currentTime));
            const isCountdownCompleted = durationUntilSessionExpiry.asSeconds() <= 0;

            if (isCountdownCompleted) {
                setFormattedDurationUntilSessionExpiry('00:00');
            } else {
                const isLessThanMinuteLeft = durationUntilSessionExpiry.asSeconds() < 60;
                const durationFormat = isLessThanMinuteLeft ? 'mm:ss' : 'hh:mm';

                setFormattedDurationUntilSessionExpiry(
                    (durationUntilSessionExpiry as any).format(durationFormat, { trim: false }), //fix type here
                );
            }

            if (sessionLimitPeriodEndTime && sessionLimitPeriodEndTime.isBefore(currentTime)) {
                loadSessionDurationLimits();
            }
        }
    }

    function getSessionExpiryDurationClasses() {
        const currentTime = moment();
        const durationUntilSessionExpiry = moment.duration(sessionEndTime.diff(currentTime));

        return classnames('session-expiry-countdown', {
            'five-minutes-left': durationUntilSessionExpiry.asMinutes() < 5,
            'ten-minutes-left':
                durationUntilSessionExpiry.asMinutes() >= 5 && durationUntilSessionExpiry.asMinutes() < 10,
        });
    }
    return (
        <Wrapper {...filterStyleProps(props)} isGameTopBar={props.isGameTopBar}>
            <div className="clocks-group">
                <div className="current-time" title={translate('Current time', 'ui.common')}>
                    {formattedCurrentTime}
                </div>

                {formattedSessionDuration && (
                    <div className="session-duration" title={translate('Session duration', 'ui.common')}>
                        {formattedSessionDuration}
                    </div>
                )}

                {formattedDurationUntilSessionExpiry && (
                    <div
                        className={getSessionExpiryDurationClasses()}
                        title={translate('Duration until session expiry', 'ui.common')}
                    >
                        {formattedDurationUntilSessionExpiry}
                    </div>
                )}
            </div>
        </Wrapper>
    );
}
