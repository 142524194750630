import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import SportBetslip from '../../betslip/SportBetslip';
import SportSponsorshipBanner from '../../sponsorship-banner/SportSponsorshipBanner';
import { stores, useStoreWithSelector } from '../../../../stores';
import isEmpty from 'lodash/isEmpty';
import PromotionSlider from '../../../promotion/slider/PromotionSlider';

import CasinoHappyHourWidget from '../../../casino/happy-hour/widget/CasinoHappyHourWidget';
import { optInToHappyHourCrmCheck } from '../../../../microservices/happy-hour';
import { BetslipMode } from '../../../../services/sports/betslip';
import { getRoute, isActiveRoute } from '../../../../services/router';
import { translate } from '../../../../services/translate';
import UiButton from '../../../ui/button/UiButton';
import SportCashoutList from '../../cashout/list/SportCashoutList';
import SportTicketList from '../../ticket/list/SportTicketList';
import SportBetslipMode from '../../betslip/mode/SportBetslipMode';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import SportParlayCardBetslip from '../../parlay-card/betslip/SportParlayCardBetslip';
import SportHotEvents from '../../hot-events/SportHotEvents';
import UiAnimate from '../../../ui/animate/UiAnimate';
import { isRetail } from '../../../../services/environment';
import ComboCardsBetslip from '../../../combo-cards/betslip/ComboCardsBetslip';
import { PRODUCT } from '../../../../types/common';
import { useStore } from '../../../../hooks/useStore';

export default function SportSidebarRight() {
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [betSlipMode, setBetSlipMode] = useStore(stores.sports.betslipMode);
    const [receiptById] = useStoreWithSelector(stores.sports.betSlipPlacingState, (state) => state.receiptById);
    const [optIn] = useStore(stores.happyHour.optIn);
    const [isOptedIn, setIsOptedIn] = useState(true);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [cardsInBetslip] = useStore(stores.sports.comboCard.cardsInBetslip);
    const isBetslipEmpty = isEmpty(betSlipMarketIdToOutcomeId) && isEmpty(receiptById);
    const isRetailLayout = isRetail();
    const isParlayCardRoute = isActiveRoute(getRoute('sport.parlay-cards'), false);
    const shouldDisplayNormalBetslip = isEmpty(cardsInBetslip) && !isParlayCardRoute;

    const isTwoToneTheme = !isFeatureAvailable(FEATURE.THEME_SINGLE_TONE);

    useEffect(() => {
        if (isFeatureAvailable(FEATURE.CASINO_HAPPY_HOUR) && isAuthenticated) {
            optInToHappyHourCrmCheck();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        setIsOptedIn(Boolean(optIn));
    }, [optIn]);

    useEffect(() => {
        setBetSlipMode(BetslipMode.Betslip);
    }, [betSlipMarketIdToOutcomeId]);

    return (
        <Wrapper>
            {isBetslipEmpty && isFeatureAvailable(FEATURE.PROMOTIONS) && !isRetailLayout && (
                <>
                    {isFeatureAvailable(FEATURE.CASINO_HAPPY_HOUR) && isAuthenticated && isOptedIn && (
                        <CasinoHappyHourWidget />
                    )}
                    <div className="promo-banner">
                        <UiAnimate animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
                            <PromotionSlider product={PRODUCT.SPORTSBOOK} />
                        </UiAnimate>
                    </div>
                </>
            )}

            {shouldDisplayNormalBetslip ? (
                <div className="betslip-box">
                    <SportBetslip />
                    {isBetslipEmpty && isAuthenticated && !isRetailLayout && (
                        <>
                            <SportBetslipMode betslipMode={betSlipMode} onBetslipModeChanged={setBetSlipMode} />
                            {betSlipMode === BetslipMode.History && isAuthenticated && (
                                <div className="tickets-list">
                                    <SportTicketList isCompactView isBetHistory />
                                    <UiButton url={getRoute('sport.bet-history')} block className="show-all-button">
                                        {translate('Show All Tickets', 'ui.sportsbook')}
                                    </UiButton>
                                </div>
                            )}

                            {betSlipMode === BetslipMode.Cashout && isAuthenticated && (
                                <div className="tickets-list">
                                    <SportCashoutList />
                                </div>
                            )}
                        </>
                    )}
                </div>
            ) : (
                isParlayCardRoute && (
                    <div className="betslip-box">
                        <SportParlayCardBetslip />
                    </div>
                )
            )}

            {Boolean(cardsInBetslip.length) && (
                <div className="betslip-box">
                    <ComboCardsBetslip />
                </div>
            )}

            {isRetailLayout && <SportHotEvents type="menu" isTwoToneTheme={isTwoToneTheme} />}

            {!isRetailLayout && isBetslipEmpty && isFeatureAvailable(FEATURE.SPONSORSHIP_BANNER) && (
                <div className="sponsorship-banner-box">
                    <SportSponsorshipBanner />
                </div>
            )}
        </Wrapper>
    );
}
