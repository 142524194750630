import React from 'react';
import { fakeTopMarketTypesWithMarket } from '../../../../../services/sports/skeleton-mocks';
import { CategoryMatch } from '../../../../../services/sports/types';
import { stores, useStoreWithSelector } from '../../../../../stores';
import UiPlaceholder from '../../../../ui/placeholder/UiPlaceholder';
import Wrapper from './styles';
import { MATCH_STATUS, MATCH_TYPE } from '../../../../../services/sports/constants';
import SportMatchMarketsTopMarketsMarket from './market/SportMatchMarketsTopMarketsMarket';
import { translate } from '../../../../../services/translate';
import UiButton from '../../../../ui/button/UiButton';
import { useSportsMatchContext } from '../../../../../services/sports/match-helpers';
import { useTopMarketTypes } from '../../../../../services/sports/top-markets';
import { FEATURE, isFeatureAvailable } from '../../../../../services/feature';
import { getRoute, useRouter } from '../../../../../services/router';
import { useSportsUserSettings } from '../../../../../services/sports/user-settings';
import { isRetail } from '../../../../../services/environment';
import { useSportsVisualSettings } from '../../../../../services/sports/sport-match-visual-settings';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';
interface Props {
    match: CategoryMatch;
    marketsToShow?: number;
}

export default function SportMatchMarketsTopMarkets({ match, marketsToShow = 3 }: Props) {
    const leagueCategoryId = match.category || match.category_id;
    const [topMarketTypesByCategory] = useTopMarketTypes(leagueCategoryId);

    const topMarketTypes = topMarketTypesByCategory || fakeTopMarketTypesWithMarket;

    const [marketTypeSelected] = useStoreWithSelector(
        stores.sports.marketTypeSelectedByCategoryId,
        (state) => state[match.category_id],
        [match.category_id],
        (state) => !!state[match.category_id],
    );
    const isOutright = match.match_type === MATCH_TYPE.OUTRIGHT;
    const { isAmericanLayout } = useSportsUserSettings({ sportCategoryId: match.sport_category });
    const { isOpen, setContext: setMatchContext } = useSportsMatchContext();
    const [{ isPhone }] = useStore(media);
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);
    const isLiveNow = match.status === MATCH_STATUS.LIVE;
    const matchRoute = isLiveNow ? `${getRoute('sport.live')}/${match.id}` : `${getRoute('sport.match')}/${match.id}`;
    const { navigateTo } = useRouter();
    const isRetailLayout = isRetail();
    function openSidebets() {
        isLayoutB || isRetailLayout ? !isOpen && navigateTo(matchRoute) : setMatchContext?.({ isOpen: !isOpen });
    }
    const { isMarketTitleVisible } = useSportsVisualSettings();

    if (isOutright) {
        return (
            <Wrapper>
                {!isOpen && !isPhone && (
                    <UiButton size="small" onClick={openSidebets} className="show-more-odds" color="outline">
                        {translate('Show odds', 'ui.sportsbook')}
                    </UiButton>
                )}
            </Wrapper>
        );
    }
    return (
        <Wrapper
            className="match-markets"
            $isAmericanLayout={isAmericanLayout}
            $isMarketTitleVisible={isMarketTitleVisible}
            $isRetailLayout={isRetailLayout}
        >
            {topMarketTypes
                .filter((x) => (marketTypeSelected ? x.id === marketTypeSelected : true))
                .slice(0, marketsToShow)
                .map((marketType) => (
                    <SportMatchMarketsTopMarketsMarket
                        key={marketType.id}
                        match={match}
                        marketType={marketType}
                        className="match-market"
                    />
                ))}
            {!topMarketTypes.length && <UiPlaceholder />}
        </Wrapper>
    );
}
