import styled from 'styled-components';
import { lighten } from 'polished';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    width: 320px;
    .status-container {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: center;
    }
    .status {
        max-width: 220px;
        margin: 0 auto -10px auto;
        background-color: ${({ theme }) => lighten(0.05, theme.box.background)};
        border: 1px solid ${({ theme }) => lighten(0.05, theme.box.background)};
        box-shadow: 0 3px 15px -3px #000;
        padding: ${({ theme }) => theme.layout.gutterHalf};
        border-radius: 100px;
        font-size: 0.6rem;
        color: var(--font-color-secondary);
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .quiz-content {
        min-height: 550px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .register-and-login-buttons {
        display: flex;
        justify-content: center;
        margin-top: ${({ theme }) => theme.layout.gutter};
        button {
            margin-right: ${({ theme }) => theme.layout.gutterHalf};
            flex: 1;
        }
    }
    .quiz-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: ${({ theme }) => lighten(0.05, theme.box.background)};
        border-bottom-left-radius: var(--ui-radius);
        border-bottom-right-radius: var(--ui-radius);
        box-shadow: var(--ui-shadow), inset 1px 1px 4px -2px ${({ theme }) => lighten(0.2, theme.box.background)};
        margin-bottom: ${({ theme }) => theme.layout.gutter};
        padding: ${({ theme }) => theme.layout.gutterDouble};
    }
    .submit-answers {
        height: 45px;
        width: 90%;
        margin: ${({ theme }) => theme.layout.gutterDouble} auto 0 auto;
        @media ${mediaQuery.isPhone} {
            min-height: 65px;
            font-size: 0.875rem;
        }
    }
    .login {
        display: flex;
        align-items: center;
        flex-direction: column;

        h3 {
            white-space: nowrap;
            text-transform: uppercase;
        }
    }
    .no-active-quiz-message {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        white-space: nowrap;
    }
`;
