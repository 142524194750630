import { getRoute } from './services/router';
import { withLayout } from './higher-order-components/layout';
import AccountLayout from './layouts/account/AccountLayout';
import SportLayout from './layouts/sport/SportLayout';
import CasinoGameLayout from './layouts/casino-game/CasinoGameLayout';
import VirtualSportLayout from './layouts/virtual-sport/VirtualSportLayout';
import PokerLayout from './layouts/poker/PokerLayout';
import { FEATURE, isFeatureAvailable } from './services/feature';
import HorseRacingLayout from './layouts/horse-racing/HorseRacingLayout';
import EmptyLayout from './layouts/empty/EmptyLayout';
import { isBYOD } from './services/environment';
import ArticlePreviewPage from './pages/article-preview/ArticlePreviewPage';
import OnsiteMessagePreviewPage from './pages/onsite-message-preview/OnsiteMessagePreviewPage';

import asyncComponent from './components/async-component/AsyncComponent';

const RetailPage = asyncComponent(() => import('./pages/retail/RetailPage' /* webpackChunkName: 'RetailPage'*/));
const RetailRulesPage = asyncComponent(
    () => import('./pages/retail/rules/RetailRulesPage' /* webpackChunkName: 'RetailRulesPage'*/),
);
const DigitalBoardPage = asyncComponent(
    () => import('./pages/digital-board/DigitalBoardPage' /* webpackChunkName: 'DigitalBoardPage'*/),
);
const SportPage = asyncComponent(() => import('./pages/sport/SportPage' /* webpackChunkName: 'SportPage'*/));
const VirtualSportsPage = asyncComponent(
    () => import('./pages/virtual-sports/VirtualSportsPage' /* webpackChunkName: 'VirtualSportsPage'*/),
);

const CasinoWrapperPage = asyncComponent(
    () => import('./pages/casino-wrapper/CasinoWrapperPage' /* webpackChunkName: 'CasinoWrapperPage'*/),
);

const HorseRacingPage = asyncComponent(
    () => import('./pages/horse-racing/HorseRacingPage' /* webpackChunkName: 'HorseRacingPage'*/),
);
const CasinoGamePage = asyncComponent(
    () => import('./pages/casino-game/CasinoGamePage' /* webpackChunkName: 'CasinoGamePage'*/),
);
const LiveLobbyPage = asyncComponent(
    () => import('./pages/live-lobby/LiveLobbyPage' /* webpackChunkName: 'LiveLobbyPage'*/),
);
const PokerPage = asyncComponent(() => import('./pages/poker/PokerPage' /* webpackChunkName: 'PokerPage'*/));
const AboutPage = asyncComponent(() => import('./pages/about/AboutPage' /* webpackChunkName: 'AboutPage'*/));
const SupportPage = asyncComponent(() => import('./pages/support/SupportPage' /* webpackChunkName: 'SupportPage'*/));
const PromotionsPage = asyncComponent(
    () => import('./pages/promotions/PromotionsPage' /* webpackChunkName: 'PromotionsPage'*/),
);
const AccountPage = asyncComponent(() => import('./pages/account/AccountPage' /* webpackChunkName: 'AccountPage'*/));
const DepositPage = asyncComponent(() => import('./pages/deposit/DepositPage' /* webpackChunkName: 'DepositPage'*/));
const DepositReturnPage = asyncComponent(
    () => import('./pages/deposit-return/DepositReturnPage' /* webpackChunkName: 'DepositReturnPage'*/),
);
const PayoutPage = asyncComponent(() => import('./pages/payout/PayoutPage' /* webpackChunkName: 'PayoutPage'*/));
const TransactionsPage = asyncComponent(
    () => import('./pages/transactions/TransactionsPage' /* webpackChunkName: 'TransactionsPage'*/),
);
const BetHistoryPage = asyncComponent(
    () => import('./pages/bet-history/BetHistoryPage' /* webpackChunkName: 'BetHistoryPage'*/),
);
const ReferAFriendPage = asyncComponent(
    () => import('./pages/refer-a-friend/ReferAFriendPage' /* webpackChunkName: 'ReferAFriendPage'*/),
);
const WelcomePage = asyncComponent(() => import('./pages/welcome/WelcomePage' /* webpackChunkName: 'WelcomePage'*/));
const BlogPage = asyncComponent(() => import('./pages/blog/BlogPage' /* webpackChunkName: 'BlogPage'*/));
const RegistrationPage = asyncComponent(
    () => import('./pages/registration/RegistrationPage' /* webpackChunkName: 'RegistrationPage'*/),
);
const ResponsibleGamingPage = asyncComponent(
    () => import('./pages/responsible-gaming/ResponsibleGamingPage' /* webpackChunkName: 'ResponsibleGamingPage'*/),
);
const LoginPage = asyncComponent(() => import('./pages/login/LoginPage' /* webpackChunkName: 'LoginPage'*/));
const MobileLandingPage = asyncComponent(
    () => import('./pages/mobile-landing/MobileLandingPage' /* webpackChunkName: 'MobileLandingPage'*/),
);
const LeaguePage = asyncComponent(() => import('./pages/league/LeaguePage' /* webpackChunkName: 'LeaguePage'*/));
const LeagueDetailPage = asyncComponent(
    () => import('./pages/league/detail/LeagueDetailPage' /* webpackChunkName: 'LeagueDetailPage'*/),
);
const ReferPage = asyncComponent(() => import('./pages/refer/ReferPage' /* webpackChunkName: 'ReferPage'*/));
const ResetPasswordPage = asyncComponent(
    () => import('./pages/reset-password/ResetPasswordPage' /* webpackChunkName: 'ResetPasswordPage'*/),
);
const SharePage = asyncComponent(() => import('./pages/share/SharePage' /* webpackChunkName: 'SharePage'*/));
const HowToPwaPage = asyncComponent(
    () => import('./pages/how-to-pwa/HowToPwaPage' /* webpackChunkName: 'HowToPwaPage'*/),
);
const StopSessionPage = asyncComponent(
    () => import('./pages/stop-session/StopSessionPage' /* webpackChunkName: 'StopSessionPage'*/),
);
const PreLaunchPage = asyncComponent(
    () => import('./pages/pre-launch/PreLaunchPage' /* webpackChunkName: 'PreLaunchPage'*/),
);
const InboxPage = asyncComponent(() => import('./pages/inbox/InboxPage' /* webpackChunkName: 'InboxPage'*/));
const CrmMessagePreviewPage = asyncComponent(
    () => import('./pages/crm-message-preview/CrmMessagePreviewPage' /* webpackChunkName: 'CrmMessagePreviewPage'*/),
);
const MatchPromotionPreviewPage = asyncComponent(
    () =>
        import(
            './pages/match-promotion-preview/MatchPromotionPreviewPage' /* webpackChunkName: 'MatchPromotionPreviewPage'*/
        ),
);
const BlogPreviewPage = asyncComponent(
    () => import('./pages/blog-preview/BlogPreviewPage' /* webpackChunkName: 'BlogPreviewPage'*/),
);
const PayAndPlayLoginPage = asyncComponent(
    () => import('./pages/pay-and-play-login/PayAndPlayLoginPage' /* webpackChunkName: 'PayAndPlayLoginPage'*/),
);
const GeocomplyPage = asyncComponent(
    () => import('./pages/geocomply/GeocomplyPage' /* webpackChunkName: 'GeocomplyPage'*/),
);
const GamblingStatisticsPage = asyncComponent(
    () => import('./pages/gambling-statistics/GamblingStatisticsPage' /* webpackChunkName: 'GamblingStatisticsPage'*/),
);
const RegistrationBtobPage = asyncComponent(
    () => import('./pages/registration-btob/RegistrationBtobPage' /* webpackChunkName: 'RegistrationBtobPage'*/),
);
const RegistrationBtobErrorPage = asyncComponent(
    () =>
        import(
            './pages/registration-btob/error/RegistrationBtobErrorPage' /* webpackChunkName: 'RegistrationBtobErrorPage'*/
        ),
);
const PayoutReturnPage = asyncComponent(
    () => import('./pages/payout-return/PayoutReturnPage' /* webpackChunkName: 'PayoutReturnPage'*/),
);
const EmptyPage = asyncComponent(() => import('./pages/empty/EmptyPage' /* webpackChunkName: 'EmptyPage'*/));
const RegistrationBtobCamsPage = asyncComponent(
    () =>
        import(
            './pages/registration-btob/cams/RegistrationBtobCamsPage' /* webpackChunkName: 'RegistrationBtobCamsPage'*/
        ),
);
const RegistrationBtobCamsContinuePage = asyncComponent(
    () =>
        import(
            './pages/registration-btob/cams/continue/RegistrationBtobCamsContinuePage' /* webpackChunkName: 'RegistrationBtobCamsContinuePage'*/
        ),
);

export function getRootRoutes(isRetail: boolean) {
    const pages = [
        isFeatureAvailable(FEATURE.SPORTSBOOK) && {
            route: getRoute('sport'),
            layout: SportLayout,
            component: SportPage,
        },
        isFeatureAvailable(FEATURE.CASINO) && {
            route: getRoute('casino'),
            component: CasinoWrapperPage,
        },
        isFeatureAvailable(FEATURE.VIRTUAL_SPORTS) && {
            route: getRoute('virtual-sports'),
            layout: VirtualSportLayout,
            component: VirtualSportsPage,
        },
        isFeatureAvailable(FEATURE.CASINO) && {
            route: `${getRoute('casino-game')}/:gameSlug`,
            layout: CasinoGameLayout,
            component: CasinoGamePage,
        },
        isFeatureAvailable(FEATURE.CASINO) && {
            route: `${getRoute('live-lobby')}/:providerName/:gameType`,
            layout: CasinoGameLayout,
            component: LiveLobbyPage,
        },
        (isFeatureAvailable(FEATURE.POKER) || isFeatureAvailable(FEATURE.POKER_MARKETING_ONLY)) && {
            route: getRoute('poker'),
            layout: PokerLayout,
            component: PokerPage,
        },
        isFeatureAvailable(FEATURE.HORSE_RACING) && {
            route: getRoute('horse-racing'),
            layout: HorseRacingLayout,
            component: HorseRacingPage,
        },
        isFeatureAvailable(FEATURE.ABOUT) && {
            route: getRoute('about'),
            component: withLayout(AboutPage),
        },
        isFeatureAvailable(FEATURE.RESPONSIBLE_GAMING) && {
            route: getRoute('responsible-gaming'),
            component: withLayout(ResponsibleGamingPage, AccountLayout),
        },
        isFeatureAvailable(FEATURE.PROMOTIONS) && {
            route: getRoute('promotions'),
            component: withLayout(PromotionsPage, AccountLayout),
        },
        isFeatureAvailable(FEATURE.SUPPORT) && {
            route: getRoute('support'),
            component: withLayout(SupportPage, AccountLayout),
        },
        isFeatureAvailable(FEATURE.REFER_A_FRIEND) && {
            route: getRoute('refer-a-friend'),
            layout: AccountLayout,
            component: ReferAFriendPage,
        },
        {
            route: getRoute('welcome'),
            component: withLayout(WelcomePage),
        },
        isFeatureAvailable(FEATURE.BLOG) && {
            route: getRoute('blog'),
            component: withLayout(BlogPage),
        },
        isFeatureAvailable(FEATURE.BLOG) && {
            route: getRoute('blog-preview'),
            component: BlogPreviewPage,
            loginRequired: false,
        },
        isFeatureAvailable(FEATURE.PROMOTIONS) && {
            route: getRoute('match-promotion-preview'),
            component: MatchPromotionPreviewPage,
            loginRequired: false,
        },
        {
            route: getRoute('onsite-message-preview'),
            component: OnsiteMessagePreviewPage,
            loginRequired: false,
        },
        {
            route: getRoute('crm-message-preview'),
            component: CrmMessagePreviewPage,
        },
        isFeatureAvailable(FEATURE.REGISTRATION) && {
            route: getRoute('registration'),
            component: withLayout(RegistrationPage),
        },
        isFeatureAvailable(FEATURE.REGISTRATION_B2B) && {
            route: getRoute('registration-btob.cams.continue'),
            component: withLayout(RegistrationBtobCamsContinuePage),
        },
        isFeatureAvailable(FEATURE.REGISTRATION_B2B) && {
            route: getRoute('registration-btob.cams'),
            component: withLayout(RegistrationBtobCamsPage, EmptyLayout),
        },
        isFeatureAvailable(FEATURE.REGISTRATION_B2B) && {
            route: getRoute('registration-btob.error'),
            component: withLayout(RegistrationBtobErrorPage),
        },
        isFeatureAvailable(FEATURE.REGISTRATION_B2B) && {
            route: `${getRoute('registration-btob')}/:step`,
            component: withLayout(RegistrationBtobPage),
        },
        isFeatureAvailable(FEATURE.REGISTRATION_B2B) && {
            route: getRoute('registration-btob'),
            component: withLayout(RegistrationBtobPage),
        },
        {
            route: getRoute('account'),
            layout: AccountLayout,
            component: AccountPage,
            loginRequired: true,
        },
        isFeatureAvailable(FEATURE.DEPOSIT) && {
            route: getRoute('deposit'),
            layout: AccountLayout,
            component: DepositPage,
            loginRequired: true,
        },
        isFeatureAvailable(FEATURE.DEPOSIT) && {
            route: getRoute('deposit-return'),
            layout: AccountLayout,
            component: DepositReturnPage,
            loginRequired: true,
        },
        {
            route: getRoute('payout-return'),
            layout: AccountLayout,
            component: PayoutReturnPage,
            loginRequired: true,
        },
        {
            route: `${getRoute('pay-and-play-login')}/:token`,
            component: PayAndPlayLoginPage,
            loginRequired: false,
        },
        {
            route: getRoute('payout'),
            layout: AccountLayout,
            component: PayoutPage,
            loginRequired: true,
        },
        {
            route: getRoute('transactions'),
            layout: AccountLayout,
            component: TransactionsPage,
            loginRequired: true,
        },
        isFeatureAvailable(FEATURE.SPORTSBOOK) && {
            route: getRoute('bet-history'),
            layout: AccountLayout,
            component: BetHistoryPage,
            loginRequired: true,
        },
        {
            route: getRoute('reset-password'),
            component: withLayout(ResetPasswordPage),
        },
        isFeatureAvailable(FEATURE.REFER_A_FRIEND) && {
            route: `${getRoute('refer')}/:rafCode`,
            component: withLayout(ReferPage),
        },
        isFeatureAvailable(FEATURE.REFER_A_FRIEND) && {
            route: `${getRoute('refer')}`,
            component: withLayout(ReferPage),
        },
        isFeatureAvailable(FEATURE.LEAGUE) && {
            route: `${getRoute('league')}/:tournamentId`,
            component: withLayout(LeagueDetailPage),
        },
        isFeatureAvailable(FEATURE.LEAGUE) && {
            route: `${getRoute('league')}`,
            component: withLayout(LeaguePage),
        },
        {
            route: `${getRoute('share')}`,
            component: SharePage,
        },
        {
            route: `${getRoute('mobile-landing')}`,
            component: withLayout(MobileLandingPage),
        },
        {
            route: getRoute('login'),
            component: withLayout(LoginPage),
        },
        {
            route: getRoute('how-to-pwa'),
            component: withLayout(HowToPwaPage),
        },
        isFeatureAvailable(FEATURE.INBOX) && {
            route: getRoute('inbox'),
            layout: AccountLayout,
            component: InboxPage,
            loginRequired: true,
        },
        {
            route: getRoute('stop-session'),
            component: withLayout(StopSessionPage),
            loginRequired: true,
        },
        {
            route: getRoute('pre-launch'),
            component: PreLaunchPage,
        },
        {
            layout: AccountLayout,
            route: getRoute('gambling-statistics'),
            component: GamblingStatisticsPage,
            loginRequired: true,
        },
        {
            route: getRoute('geocomply'),
            component: withLayout(GeocomplyPage),
        },
        isFeatureAvailable(FEATURE.DIGITAL_BOARD) && {
            route: `${getRoute('digital-board')}/:boardId`,
            layout: EmptyLayout,
            component: DigitalBoardPage,
        },
        {
            route: `${getRoute('article-preview')}/:slug`,
            component: ArticlePreviewPage,
        },
        {
            route: getRoute('empty'),
            layout: EmptyLayout,
            component: EmptyPage,
        },
    ];

    if (isBYOD() || (isFeatureAvailable(FEATURE.RETAIL_HEADER) && !isRetail)) {
        pages.unshift({
            route: getRoute('retail.rules'),
            component: withLayout(RetailRulesPage),
        });
    }

    if (isRetail) {
        pages.unshift({
            route: getRoute('retail'),
            component: RetailPage,
        });
    }
    return pages;
}
