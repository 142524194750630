import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { logger } from '../../services/logger';
import { fetchAllComboCards } from '../../microservices/sbgate';
import ComboCardsItem from './item/ComboCardsItem';
import { stores } from '../../stores';
import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';
import { useSocketSubscribeUnsubscribe, useSocketTopicEvents } from '../../microservices/pusher';
import { getUserCountry } from '../../services/user';
import { useStore } from '../../hooks/useStore';

export default function ComboCards() {
    const [comboCards, setComboCards] = useState<FoComboCardWithOdds[]>([]);
    const [language] = useStore(stores.language);

    useSocketSubscribeUnsubscribe('public', {
        params: {
            service: 'sports',
            channel: `combo-card-updater-${getUserCountry()}`,
        },
        resubscribeOnReconnect: true,
    });

    useSocketTopicEvents('combo-card-update', loadAllComboCards);

    useEffect(() => {
        loadAllComboCards();
    }, []);

    async function loadAllComboCards() {
        try {
            const fetchedComboCards = await fetchAllComboCards(language);
            setComboCards(fetchedComboCards);
        } catch (error) {
            logger.error('ComboCards', 'loadAllComboCards', error);
        }
    }

    if (!comboCards.length) {
        return null;
    }

    return (
        <Wrapper>
            {comboCards.map((card) => (
                <ComboCardsItem card={card} key={card.id} />
            ))}
        </Wrapper>
    );
}
