import React from 'react';
import Wrapper from './styles';
import UiCountdown from '../../ui/countdown/UiCountdown';

interface Props {
    deadline: string;
    pattern: string;
    children: React.ReactNode;
}

export default function CmsCountdown({ deadline, pattern, children }: Props) {
    return (
        <Wrapper>
            <UiCountdown endDate={deadline} localizedPattern={pattern} isLocalized /> {children}
        </Wrapper>
    );
}
