import 'core-js/actual/set-immediate';
import 'core-js/actual/array/is-array';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { initHistory } from './services/browser';
import BootstrapPage from './pages/bootstrap/BootstrapPage';
import Redirects from './components/redirects/Redirects';
import 'animate.css/animate.min.css';

console.info(`[FO] version: ${process.env.REACT_APP_VERSION || 'development'}`);
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

const history = initHistory();

const root = ReactDOM.createRoot(document.getElementById(window?.coolb2b?.rootElementId || 'root')!);

const basePath = window?.coolb2b?.basePath || '/';

root.render(
    <Router basename={basePath} history={history}>
        <div>
            <Redirects />
            <BootstrapPage />
        </div>
    </Router>,
);
