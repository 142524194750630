import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import Wrapper from './styles';
import classNames from 'classnames';
import Svg from '../svg/Svg';
import { stores } from '../../stores';
import { useRouter } from '../../services/router';
import { closeToast, closeToastsOnLogout } from '../../services/toast';
import { getStoreValue } from '../../stores/store/utils';
import { useStore } from '../../hooks/useStore';

export default function Toast() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [toasts] = useStore(stores.toasts);
    const [isOpen, setIsOpen] = useState(false);
    const [toastTimeoutId, setToastTimeoutId] = useState<NodeJS.Timeout>();
    const [loadingBarAnimation, setLoadingBarAnimation] = useState<Animation>();
    const [toastIdCounter, setToastIdCounter] = useState(0);

    const { navigateTo } = useRouter();

    const loadingBar = useRef<HTMLDivElement>();

    const effectDuration = 300;

    useEffect(() => {
        displayToast();
    }, [toasts]);

    useEffect(() => {
        if (!isAuthenticated && toasts.length) {
            closeToastsOnLogout();
            if (toasts[0].closeOnLogout) {
                close();
            }
        }
    }, [isAuthenticated]);

    function displayToast() {
        const toast = getStoreValue(stores.toasts)[0];
        if (toast && !isOpen) {
            toast.id = toastIdCounter;
            setToastIdCounter(toastIdCounter + 1);
            setIsOpen(true);
            if (toast.lifespan) {
                startTimer();
            }
        }
    }

    function close() {
        if (toastTimeoutId) {
            clearTimeout(toastTimeoutId);
            setToastTimeoutId(undefined);
        }
        setIsOpen(false);
        setTimeout(() => {
            if (toasts.length) {
                closeToast(toasts[0]);
            }
            displayToast();
        }, effectDuration);
    }

    function startTimer() {
        const { lifespan } = getStoreValue(stores.toasts)[0];
        setToastTimeoutId(setTimeout(close, lifespan));
        startLoadingBar(lifespan);
    }

    function startLoadingBar(lifespan: number | undefined) {
        if (!lifespan || lifespan < 0) {
            lifespan = 2000;
        }
        if (loadingBar.current && loadingBar.current.animate) {
            setLoadingBarAnimation(
                loadingBar.current.animate([{ transform: 'scaleX(0)' }, { transform: 'scaleX(1)' }], {
                    duration: lifespan,
                    fill: 'forwards',
                    easing: 'linear',
                }),
            );
        }
    }

    function stopTimer() {
        clearTimeout(toastTimeoutId);
        stopLoadingBar();
    }

    function stopLoadingBar() {
        if (loadingBarAnimation) {
            loadingBarAnimation.playbackRate = -Math.sqrt(Math.abs(loadingBarAnimation.currentTime || 0)); // loadingBarAnimation.currentTime is negative sometimes, IDK why
        }
    }

    function onActionClicked(action) {
        if (action.action) {
            action.action(navigateTo);
        }
        close();
    }

    const toast = toasts.length && toasts[0];

    if (!toast) {
        return null;
    }

    const toastType = toast.type || 'secondary';

    return (
        <Wrapper
            toastType={toastType}
            effectDuration={effectDuration}
            className={classNames({ open: isOpen })}
            onMouseOver={stopTimer}
            onMouseLeave={startTimer}
        >
            <div
                className={classNames('coolbet-toast', toast.size, {
                    'no-icon': !toast.iconName,
                    'custom-theme': toast.type,
                })}
            >
                {toast.iconName && (
                    <div className="coolbet-toast-icon-container grid-background">
                        <div className="coolbet-toast-icon">
                            <Svg icon={toast.iconName} />
                        </div>
                        {toast.iconSubText && <div className="coolbet-toast-icon-subtext">{toast.iconSubText}</div>}
                    </div>
                )}

                <div className="coolbet-toast-content-container">
                    <div className={classNames('coolbet-toast-content-text-container', { 'no-title': !toast.title })}>
                        {toast.title && <div className="coolbet-toast-content-text-container-title">{toast.title}</div>}
                        <div
                            className="coolbet-toast-content-text-container-message"
                            dangerouslySetInnerHTML={{ __html: toast.text }}
                        />
                    </div>

                    {toast.actions && (
                        <div className="coolbet-toast-content-actions-container">
                            {toast.actions.map((action, index) => (
                                <div
                                    className="coolbet-toast-content-action-button"
                                    onClick={() => onActionClicked(action)}
                                    key={index}
                                >
                                    {action.name}
                                    {action.default && (
                                        <div
                                            className="coolbet-toast-content-action-button-bar"
                                            ref={loadingBar as MutableRefObject<HTMLDivElement>}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </Wrapper>
    );
}
