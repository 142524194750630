import React from 'react';
import Wrapper from './styles';
import isEmpty from 'lodash/isEmpty';
import { stores, useStoreWithSelector } from '../../../../../stores';
import has from 'lodash/has';
import SportMatchTimeLive from '../../time/live/SportMatchTimeLive';
import { FoCategoryMatch } from '@staycool/sbgate-types';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    match: FoCategoryMatch;
}

export default function SportMatchScoreboardBgBaseball({ match }: Props) {
    const [{ isPhone }] = useStore(media);
    const baseballPlays = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const mobileViewPlays = [1, 2, 3, 4, 5];
    const [inplayData] = useStoreWithSelector(stores.sports.inplayMatchData, (state) => state[match.id], [match.id]);
    const { home_team_points, away_team_points } = inplayData || {};
    const { scoreboard, custom } = inplayData || {};
    const isOutright = match.match_type === 'OUTRIGHT' || !match.home_team_name;
    const homeTeam = isOutright ? '' : match.home_team_name;
    const awayTeam = isOutright ? '' : match.away_team_name;
    const backgroundPath = `/assets/images/scoreboard-backgrounds/scoreboard-${match.sport_slug}.jpg`;
    const battingIcon = `/assets/images/icons/baseball-bat.png`;

    if (!inplayData) {
        return null;
    }

    function showOnlyLastPlays(scoreboardPlays, defaultPlays, playsAmountToShow) {
        let plays = defaultPlays;
        if (scoreboardPlays.length >= playsAmountToShow) {
            plays = scoreboardPlays;
            const numberOfPlaysToDelete = scoreboardPlays.length - playsAmountToShow;
            plays.splice(0, numberOfPlaysToDelete);
        }
        return plays;
    }

    const scoreBoardPlays = Object.keys(scoreboard);
    const numberOfPlays = showOnlyLastPlays(
        scoreBoardPlays,
        isPhone ? mobileViewPlays : baseballPlays,
        isPhone ? 5 : 10,
    );
    const latestPlay = numberOfPlays[scoreBoardPlays.length - 1];

    return (
        <Wrapper backgroundUrl={backgroundPath}>
            <div className="score-board">
                <div className="scores">
                    <div className="score">
                        <h1 className="score-title">{homeTeam}</h1>
                        <span className="score-number"> {home_team_points || 0}</span>
                        {inplayData.current_server === 'home' && (
                            <img className="current-batter" src={battingIcon} alt="baseball_bat" />
                        )}
                    </div>
                    <div className="timer">
                        <SportMatchTimeLive matchId={match.id} sportId={match.sport} isOurLivebet={match.livebet_our} />
                    </div>
                    <div className="score">
                        <h1 className="score-title">{awayTeam}</h1>
                        <span className="score-number"> {away_team_points || 0}</span>
                        {inplayData.current_server === 'away' && (
                            <img className="current-batter" src={battingIcon} alt="baseball_bat" />
                        )}
                    </div>
                </div>
                {Boolean(Object.values(scoreboard).length) && (
                    <div className="inning-scores-container">
                        {inplayData.current_server && (
                            <div className="batting-scores">
                                <span className="batting-score">
                                    <div className="batting-label">Hits</div>
                                    {custom.current_batting_hits}
                                </span>
                                <span className="batting-score">
                                    <div className="batting-label">Errors</div>
                                    {custom.current_batting_errors}
                                </span>
                                <span className="batting-score">
                                    <div className="batting-label">Outs</div>
                                    {custom.current_batting_outs}
                                </span>
                            </div>
                        )}
                        <div className="inning-scores">
                            <div className="team-name">{match.away_team_name}</div>
                            {numberOfPlays.map((play) => (
                                <span className={`inning-score ${play === latestPlay ? 'active' : ''}`} key={play}>
                                    <div className="inning-label">{`${play}`}</div>
                                    {has(scoreboard, `${play}.away`) ? scoreboard[play].away : 0}
                                </span>
                            ))}
                            {!isPhone && !isEmpty(custom) && (
                                <div className="inning-scores custom">
                                    <span className="inning-score">
                                        <div className="inning-label">Runs</div>
                                        {custom.total_runs.away}
                                    </span>
                                    <span className="inning-score">
                                        <div className="inning-label">Hits</div>
                                        {custom.total_hits.away}
                                    </span>
                                    <span className="inning-score">
                                        <div className="inning-label">Errors</div>
                                        {custom.total_errors.away}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="inning-scores">
                            <div className="team-name">{match.home_team_name}</div>
                            {numberOfPlays.map((play) => (
                                <span className={`inning-score ${play === latestPlay ? 'active' : ''}`} key={play}>
                                    {has(scoreboard, `${play}.home`) ? scoreboard[play].home : 0}
                                </span>
                            ))}
                            {!isPhone && !isEmpty(custom) && (
                                <div className="inning-scores custom">
                                    <span className="inning-score">{custom.total_runs.home}</span>
                                    <span className="inning-score">{custom.total_hits.home}</span>
                                    <span className="inning-score">{custom.total_errors.home}</span>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </Wrapper>
    );
}
