import styled from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

export default styled.div`
    .quiz-question {
        display: flex;
        align-items: center;
        margin-bottom: ${({ theme }) => theme.layout.gutterDouble};
        min-height: 50px;
        .question-text {
            display: flex;
            align-items: center;
            text-align: left;
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: bold;
            color: white;
            padding: 0 ${({ theme }) => theme.layout.gutter};
            .question-index {
                max-width: 20px;
                margin-right: ${({ theme }) => theme.layout.gutter};
            }
        }
    }
    .quiz-answers {
        min-height: 150px;
        @media ${mediaQuery.isPhone} {
            min-height: 185px;
        }
    }
`;
