import React from 'react';
import classNames from 'classnames';
import { SportsOddsFormat } from '../../../../microservices/sbgate';
import { TICKET_STATUS } from '../../../../services/bet-history';
import { convertDecimalToAmerican, convertOdds } from '../../../../services/odds-format';
import { translate } from '../../../../services/translate';
import UiButton from '../../../ui/button/UiButton';
import SportCashoutButton from '../../cashout/button/SportCashoutButton';
import SportTicketOdds from '../odds/SportTicketOdds';
import Wrapper from './styles';
import SportBadgeTicketStatus from '../../badge/ticket-status/SportBadgeTicketStatus';
import SportTicketType from '../type/SportTicketType';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import SportTicketW2GAlert from '../w-2-g-alert/SportTicketW2GAlert';
import { isRetail } from '../../../../services/environment';
import { findW2gStatusToDisplay } from '../../../../services/w2g';
import SportTicketTotalsMaInfo from './ma-info/SportTicketTotalsMaInfo';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    ticket: any;
    isLeaderboard?: boolean;
    isPublic: boolean;
    isScreenShotPage?: boolean;
    isCompactView?: boolean;
    onTicketCopy?: () => void;
    isCopyingTicket?: boolean;
    extraInfo?: boolean;
    w2gEventStatus?: string;
}
export default function SportTicketTotals({
    ticket,
    isLeaderboard = false,
    isPublic,
    isScreenShotPage = false,
    isCompactView = false,
    onTicketCopy,
    isCopyingTicket,
    extraInfo = false,
    w2gEventStatus,
}: Props) {
    const [{ isPhone, isTablet, isLaptop, isDesktop }] = useStore(media);
    const isCompactViewEnabled = isCompactView || isPhone;
    const oddsValue = ['CANCELLED', 'PUSHED', 'MANUAL_ACCEPTANCE_DENIED'].includes(ticket.status)
        ? 1
        : ticket.first_bet_odds || ticket.total_odds || 1;
    const isOddsTooBig = ticket ? oddsValue.length > 4 : false;
    const oddsFormatOnBetPlace = !ticket.odds_format
        ? convertOdds(oddsValue)
        : ticket.odds_format === SportsOddsFormat.AMERICAN
        ? convertDecimalToAmerican(oddsValue)
        : oddsValue.toFixed(2);
    const isFreeBet = ticket.extra?.hasOwnProperty('freeBet');
    const ticketMaxWin =
        ticket.status === 'MANUAL_ACCEPTANCE_DENIED'
            ? ticket.stake_requested
            : ticket.potential_return || ticket.max_win;
    const totalStake = ticket.total_stake || ticket.stake || ticket.ma_stake_requested || ticket.stake_requested;
    const isRetailLayout = isRetail();
    const isFreeBetDeduction = isFeatureAvailable(FEATURE.FREE_BET_DEDUCTION);
    const useExtraAmounts = ticket.status === TICKET_STATUS.WON || ticket.status === TICKET_STATUS.PENDING;
    // NOTE: This gets ugly, but I got promised that we will optimise FreeBet logic across the platform in the future, so we don't need such complex checks
    const displayMaxWin =
        useExtraAmounts && isFreeBet && isFreeBetDeduction
            ? ticketMaxWin - totalStake
            : isFreeBet && isFreeBetDeduction
            ? '0.00'
            : ticketMaxWin;

    function isCashoutEligible() {
        if (ticket.extra && (ticket.extra.multiplierBet || ticket.extra.riskFreeBet || ticket.extra.freeBet)) {
            return false;
        }
        return Boolean(ticket.cashoutAmount && !isPublic && !isScreenShotPage && !isLeaderboard);
    }

    return (
        <Wrapper
            $isCompactViewEnabled={isCompactViewEnabled}
            $isCompactView={isCompactView}
            $extraInfo={extraInfo}
            $isLeaderboard={isLeaderboard}
            $oddsTooBig={isOddsTooBig}
            $isScreenShotPage={isScreenShotPage}
        >
            {extraInfo && (
                <>
                    <div className="ticket-status-container">
                        <SportTicketType ticket={ticket} isLeaderboard={isLeaderboard} isCompactView={isCompactView} />
                        {!isPublic && <SportBadgeTicketStatus ticket={ticket} />}
                    </div>
                    <div className="totals-label">
                        {!isLeaderboard && ticket.display_id && (
                            <span className="ticket-id">#{ticket.display_id} / </span>
                        )}

                        <span>{translate('Total-odds', 'ui.betslip')}</span>
                        {!isLeaderboard && (
                            <>
                                <span> / {translate('Stake', 'ui.betslip')}</span>
                                <span>
                                    {' '}
                                    /{' '}
                                    {ticket.status !== TICKET_STATUS.PENDING
                                        ? translate('Total payout', 'ui.betslip')
                                        : translate('potential-return', 'ui.betslip')}
                                </span>
                            </>
                        )}
                    </div>
                </>
            )}

            <div className="ticket-totals">
                <div
                    className={classNames('bet-totals', {
                        denied: ticket.status === TICKET_STATUS.MANUAL_ACCEPTANCE_DENIED,
                    })}
                >
                    <div className="ticket-total-odds">
                        <SportTicketOdds active={extraInfo} oddsTooBig={isOddsTooBig}>
                            {oddsFormatOnBetPlace}
                        </SportTicketOdds>
                    </div>
                    {!isLeaderboard && (
                        <div className="ticket-total-stake">{formattedAmountWithCurrency(totalStake)}</div>
                    )}
                    {!isLeaderboard && <span>&rsaquo;</span>}
                    {!isLeaderboard && !ticket.cashout_status && (
                        <div className={classNames('ticket-possible-return', ticket.status)}>
                            {displayMaxWin
                                ? formattedAmountWithCurrency(displayMaxWin)
                                : formattedAmountWithCurrency(0)}
                            {ticket.bonusAmount && useExtraAmounts
                                ? ` + ${formattedAmountWithCurrency(ticket.bonusAmount)}`
                                : null}
                        </div>
                    )}
                    {!isLeaderboard && ticket.cashout_status && (
                        <div className="cashout-amount">{formattedAmountWithCurrency(ticket.cashout_amount)}</div>
                    )}
                    {isCashoutEligible() && !(isPhone || isCompactView) && (
                        <div className="cashout-buttons">
                            <SportCashoutButton ticket={ticket} />
                        </div>
                    )}
                </div>

                {(isDesktop || isLaptop || isTablet) && isPublic && !isScreenShotPage && extraInfo && !isRetailLayout && (
                    <div className="copy-ticket">
                        <UiButton
                            color="primary"
                            className="copy-button"
                            onClick={onTicketCopy}
                            isLoading={isCopyingTicket}
                        >
                            {translate('ui.sportsbook.betsfeed.copy-ticket')}
                        </UiButton>
                    </div>
                )}
            </div>
            <SportTicketTotalsMaInfo
                ma_status={ticket.ma_status}
                ma_stake={ticket.ma_stake}
                stake_requested={ticket.stake_requested || ticket.ma_stake_requested}
                total_stake={ticket.total_stake}
            />
            <SportTicketW2GAlert status={findW2gStatusToDisplay(ticket.w2g_events, w2gEventStatus)} />
            {isCashoutEligible() && (isPhone || isCompactView) && (
                <div className="cashout-buttons">
                    <SportCashoutButton ticket={ticket} />
                </div>
            )}
        </Wrapper>
    );
}
