import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: var(--ui-radius);
    border-width: 2px;
    border-style: solid;
    border-color: transparent;

    width: 6.68rem;
    min-width: 6.68rem;
    height: 6.68rem;

    background-color: var(--card-button-bg);
    border: var(--card-button-border);

    color: var(--card-button-text-color);
    cursor: pointer;
    user-select: none;
    &.selected {
        background-color: var(--card-button-active-bg);
        border: var(--card-button-active-border);
        color: var(--card-button-active-text-color);
        .menu-button-text {
            color: var(--card-button-active-text-color);
        }
    }
    margin-bottom: 0.4rem;

    .menu-button-icon {
        width: 2.5rem;
        height: 2.5rem;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 2.5rem;
        @media ${({ theme }) => theme.mediaQuery.isTablet} {
            width: 2.5rem;
            height: 2.5rem;
        }

        svg {
            width: 2.5rem;
            height: 2.5rem;
            @media ${({ theme }) => theme.mediaQuery.isTablet} {
                width: 2.5rem;
                height: 2.5rem;
            }
        }

        img {
            width: 2.5rem;
            height: 2.5rem;
            @media ${({ theme }) => theme.mediaQuery.isTablet} {
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }
    .menu-button-text {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 1.8rem;
        padding: 0 0.4rem;

        font-size: 0.6rem;
        color: var(--card-button-text-color);
        text-transform: uppercase;
        letter-spacing: 0.5px;
        text-align: center;
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering}
        &:hover {
            color: var(--card-button-text-color-hover);
        }
    }
`;
