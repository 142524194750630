import { createGlobalStore } from '../store/utils';
import { MediaTypes } from '../../services/browser';
import { mediaQuery } from '../../styles/utils';

type GetMedia = Record<MediaTypes, boolean> & { deviceType: MediaTypes };

export const initialMediaStore = function () {
    const media = Object.keys(mediaQuery).reduce((media, key) => {
        media[key] = window.matchMedia(mediaQuery[key]).matches;
        return media;
    }, {}) as GetMedia;

    media.deviceType = Object.keys(media).filter((key) => media[key])[0] as MediaTypes;

    return media;
};

export const media = createGlobalStore({ ...initialMediaStore() } as GetMedia, 'media');
