import { createStores } from './store/utils';
import { ResponsibleGamingProduct, SelfExclusionUntilFurtherNotice } from '../services/responsible-gaming/types';
import { Transaction } from '../services/wallet/types';

export type AnnualReport = {
    last_login_time: Date;
    balance: Record<string, any>;
    currency: string;
    pending_bets: Record<string, any>;
};

type ResponsibleGamingStore = {
    annualReport: null | AnnualReport;
    askDepositLimit: undefined | boolean;
    askLoginDurationLimit: undefined | boolean;
    askLossLimit: undefined | boolean;
    isAnnualReportLoaded: null | boolean;
    isMaltaPanelVisible: boolean;
    isPanicTimeoutActive: boolean;
    isResponsibleGamingPanelVisible: boolean;
    isSelfExclusionTimeoutActive: boolean;
    isUnapprovedSessionReminderLoaded: boolean;
    loginDurationLimitReminder: null | { limit_end: Date };
    panicTimeoutEndDate: Date;
    selfExclusionTimeoutEndDate: Date;
    selfExclusionUntilFurtherNotice: SelfExclusionUntilFurtherNotice | undefined;
    timeout: Record<ResponsibleGamingProduct, Date | undefined>;
    unApprovedSessionReminder: null | {
        sessionStart: Date;
        wallet_data: Transaction[];
    };
};

export const responsibleGaming = createStores<ResponsibleGamingStore>({
    annualReport: null,
    askDepositLimit: undefined,
    askLoginDurationLimit: undefined,
    askLossLimit: undefined,
    isAnnualReportLoaded: null,
    isMaltaPanelVisible: false,
    isPanicTimeoutActive: false,
    isResponsibleGamingPanelVisible: false,
    isSelfExclusionTimeoutActive: false,
    isUnapprovedSessionReminderLoaded: false,
    loginDurationLimitReminder: null,
    panicTimeoutEndDate: new Date(),
    selfExclusionTimeoutEndDate: new Date(),
    selfExclusionUntilFurtherNotice: undefined,
    timeout: {
        [ResponsibleGamingProduct.CASINO]: undefined,
        [ResponsibleGamingProduct.HORSE_RACING]: undefined,
        [ResponsibleGamingProduct.POKER]: undefined,
        [ResponsibleGamingProduct.SPORTSBOOK]: undefined,
    },
    unApprovedSessionReminder: null,
});
