import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    align-self: flex-start;
    flex: 0 0 250px;

    @media ${mediaQuery.isPhone} {
        flex: 0 0 auto;
        width: 100%;
        overflow: auto;
    }
`;
