import React, { useState } from 'react';
import { claimCasinoPaybackBooster } from '../../../../microservices/casino-payback-booster';
import { logger } from '../../../../services/logger';
import { translate } from '../../../../services/translate';
import { PaybackBooster } from '../../../../services/casino/payback-booster';
import UiButton from '../../../ui/button/UiButton';

interface Props {
    paybackBooster: PaybackBooster;
}

export default function CasinoPaybackBoosterActivateBoosterButton({ paybackBooster }: Props) {
    const [isActivated, setIsActivated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    async function activatePaybackBooster() {
        if (isActivated) {
            return;
        }
        setIsLoading(true);
        try {
            await claimCasinoPaybackBooster(paybackBooster.id);
            setIsActivated(true);
        } catch (error) {
            logger.error('CasinoPaybackBoosterActivateBoosterButton', 'activatePaybackBooster', error);
        }
        setIsLoading(false);
    }

    return (
        <UiButton
            color="default"
            size="small"
            isLoading={isLoading}
            onClick={activatePaybackBooster}
            disabled={paybackBooster.casinoLocked}
        >
            {isActivated ? translate('Success', 'ui.account') : translate('Activate booster', 'casino.payback-booster')}
        </UiButton>
    );
}
