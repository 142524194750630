import React from 'react';
import Wrapper from './styles';
import Svg from '../../../svg/Svg';
import { translate } from '../../../../services/translate';
import classNames from 'classnames';

interface Props {
    hasCashGames: boolean;
    hasSitAndGoes: boolean;
    hasTournaments: boolean;
    hasTwisterSitAndGoes: boolean;
}

export default function PokerPlayFeatures({
    hasCashGames,
    hasSitAndGoes,
    hasTwisterSitAndGoes,
    hasTournaments,
}: Props) {
    return (
        <Wrapper>
            <div className="poker-feature-container">
                <Svg
                    icon={hasCashGames ? 'check' : 'cross'}
                    className={classNames('poker-feature-icon', { cross: !hasCashGames })}
                />
                <div className="poker-feature-text">{translate('Cash Games', 'poker')}</div>
            </div>

            {(hasSitAndGoes || (!hasSitAndGoes && !hasTwisterSitAndGoes)) && (
                <div className="poker-feature-container">
                    <Svg
                        icon={hasSitAndGoes ? 'check' : 'cross'}
                        className={classNames('poker-feature-icon', { cross: !hasSitAndGoes })}
                    />
                    <div className="poker-feature-text">{translate('Sit & Goes', 'poker')}</div>
                </div>
            )}

            {!hasSitAndGoes && hasTwisterSitAndGoes && (
                <div className="poker-feature-container">
                    <Svg
                        icon={hasTwisterSitAndGoes ? 'check' : 'cross'}
                        className={classNames('poker-feature-icon', { cross: !hasTwisterSitAndGoes })}
                    />
                    <div className="poker-feature-text">{translate('Twister Sit & Goes', 'poker')}</div>
                </div>
            )}

            <div className="poker-feature-container tournaments">
                <Svg
                    icon={hasTournaments ? 'check' : 'cross'}
                    className={classNames('poker-feature-icon', { cross: !hasTournaments })}
                />
                <div className="poker-feature-text">{translate('Tournaments', 'poker')}</div>
            </div>
        </Wrapper>
    );
}
