import styled, { css } from 'styled-components';

type PropTypes = {
    columnsAmount: number;
    isPhone: boolean;
    isDesktop: boolean;
    isTablet: boolean;
    isLaptop: boolean;
};

const singleColumnStyles = css`
    flex-direction: column;

    .column {
        display: flex;
        flex-direction: column;
        margin-bottom: ${({ theme }) => theme.layout.gutter};

        > * {
            margin: var(--spacing-4) 0;
        }

        &:nth-child(odd) {
            margin-bottom: ${({ theme }) => theme.layout.gutterHalf};
        }
    }
`;
const multiColumnStyles = css<PropTypes>`
    .column {
        flex: 1;
        margin-right: ${({ theme }) => theme.layout.gutter};
        max-width: ${({ columnsAmount }) => 100 / columnsAmount}%;
        &:last-child {
            margin-right: 0;
        }
    }
`;

export default styled.div<PropTypes>`
    display: flex;

    .column > * {
        margin-bottom: var(--spacing-4);

        &:last-child {
            margin-bottom: 0;
        }
    }

    ${({ isPhone }) => isPhone && singleColumnStyles}
    ${({ isDesktop, isTablet, isLaptop }) => (isDesktop || isLaptop || isTablet) && multiColumnStyles}
`;
