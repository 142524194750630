import React, { useState } from 'react';
import Wrapper from './styles';
import UiModal from '../../ui/modal/UiModal';
import UiCloseButton from '../../ui/close-button/UiCloseButton';
import UiBox from '../../ui/box/UiBox';
import ScriptLoader from '../../script-loader/ScriptLoader';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

interface Props {
    formid: string;
    onClose: () => void;
}

export default function CmsTypeform({ formid, onClose = () => {} }: Props) {
    const [{ deviceType }] = useStore(media);
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);

    if (!formid) {
        return null;
    }

    const minWidthByDevice = {
        isPhone: '400px',
        isTablet: '800px',
        isLaptop: '1000px',
        isDesktop: '1000px',
    };

    const maxWidthByDevice = {
        isPhone: '760px',
        isTablet: '1000px',
        isLaptop: '1000px',
        isDesktop: '1000px',
    };

    return (
        <>
            <ScriptLoader onLoad={() => setIsScriptLoaded(true)} url="https://embed.typeform.com/embed.js" />

            <UiModal
                open={Boolean(formid)}
                maxWidth={maxWidthByDevice[deviceType]}
                minWidth={minWidthByDevice[deviceType]}
                onClose={() => onClose()}
            >
                <Wrapper>
                    <UiBox className="container" isCompactView>
                        <UiCloseButton onClick={() => onClose()} className="close-button" />

                        {isScriptLoaded && (
                            <iframe
                                id="typeform-full"
                                title="typeform-full"
                                width="100%"
                                height="100%"
                                frameBorder="0"
                                src={`https://coolbet.typeform.com/to/${formid}`}
                            />
                        )}
                    </UiBox>
                </Wrapper>
            </UiModal>
        </>
    );
}
