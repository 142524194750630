import { getServiceUrl, httpGet } from '../services/api';
import { isRetail } from '../services/environment';
import { Currency, Transaction } from '../services/wallet/types';
import { stores } from '../stores';

const getUrl = (url) => getServiceUrl('wallet', url);

export function getFilteredTransactions(filter) {
    const url = getUrl('transactions');
    return httpGet<{ data: Transaction[]; has_next_page: boolean }>(url, filter);
}

export async function loadWallet() {
    if (isRetail()) {
        return;
    }
    const url = getUrl('users/me');
    const wallet = await httpGet<WalletBalance[]>(url, { walletType: 'multi-currency' });
    stores.wallets.set(wallet);
    stores.wallet.set(wallet[0]);
}

export function getCurrencyRates() {
    const url = getUrl('currency-rates/');
    return httpGet<Record<string, number>>(url);
}

export type WalletBalance = {
    id: string;
    created: string;
    updated: string;
    balance_uc: number;
    balance: number;
    currency: Currency;
    has_deposited: boolean;
};
