import React, { useLayoutEffect, useState } from 'react';
import Wrapper from './styles';
import classNames from 'classnames';

interface Props {
    imageUrl: string;
    onClick?: () => void;
    children?: any;
    className?;
    backgroundImageStyle?: string;
}

export default function UiImageLoaderBackground({
    imageUrl,
    onClick = () => {},
    children,
    className,
    backgroundImageStyle = '',
}: Props) {
    const [imageState, setImageState] = useState('');

    useLayoutEffect(() => {
        const image = new Image();
        image.src = imageUrl;
        if (imageState) {
            setImageState('');
        }

        if (image.complete) {
            setImageState('pre-loaded');
        } else {
            image.onload = () => {
                setImageState('loaded');
            };
        }
        return () => {
            image.onload = null;
        };
    }, [imageUrl]);

    return (
        <Wrapper
            className={classNames(imageState, className)}
            style={{ backgroundImage: imageUrl ? `${backgroundImageStyle}url(${imageUrl})` : 'none' }}
            onClick={onClick}
        >
            {children}
        </Wrapper>
    );
}
