import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import CmsQuizCollectionQuizHeader from './quiz/header/CmsQuizCollectionQuizHeader';
import CmsQuizCollectionQuizTimer from './quiz/timer/CmsQuizCollectionQuizTimer';
import CmsQuizCollectionQuizQuestion from './quiz/question/CmsQuizCollectionQuizQuestion';
import { translate } from '../../../services/translate';
import { stores } from '../../../stores';
import {
    checkIsUserInSegment,
    getCollectionQuizzes,
    getQuiz,
    getUserAnswers,
    getUserParticipatedQuizzes,
    Question,
    Quiz,
    submitAnswers,
    UserAnswers,
} from '../../../microservices/quiz';
import { logger } from '../../../services/logger';
import UiAlert from '../../ui/alert/UiAlert';
import CmsQuizCollectionQuizSubmission from './quiz/submission/CmsQuizCollectionQuizSubmission';
import CmsQuizCollectionFooter from './footer/CmsQuizCollectionFooter';
import UiDotsLoader from '../../ui/dots-loader/UiDotsLoader';
import AuthLoginButton from '../../auth/login/button/AuthLoginButton';
import AuthRegisterButton from '../../auth/register/button/AuthRegisterButton';
import { getQuizError, QUIZ_STATUS, translateQuizStatus } from '../../../services/quiz';
import moment from 'moment';
import CmsQuizCollectionQuizUserScore from './quiz/user-score/CmsQuizCollectionQuizUserScore';
import CmsQuizCollectionQuizQuestionNumber from './quiz/question-number/CmsQuizCollectionQuizQuestionNumber';
import UiBox from '../../ui/box/UiBox';
import UiButton from '../../ui/button/UiButton';
import { useStore } from '../../../hooks/useStore';

interface Props {
    'collection-id': number;
    'quiz-id'?: number;
}

export default function CmsQuizCollection({ 'quiz-id': quizId, 'collection-id': collectionId }: Props) {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [activeQuizId, setActiveQuizId] = useStore(stores.cms.activeQuizId);
    const [activeQuestionNumber, setActiveQuestionNumber] = useStore(stores.cms.activeQuestionNumber);
    const [isQuizChangedFromFooter] = useStore(stores.cms.isQuizChangedFromFooter);
    const [activeQuiz, setActiveQuiz] = useState<Quiz>();
    const [activeQuestion, setActiveQuestion] = useState<Question>();
    const [collectionQuizzes, setCollectionQuizzes] = useState<Quiz[]>([]);
    const [userAnswers, setUserAnswers] = useStore(stores.cms.userAnswers);
    const [isSubmittingAnswers, setIsSubmittingAnswers] = useState(false);
    const [isAnswersSubmitted, setIsAnswersSubmitted] = useState(false);
    const [isUpdatingAnswers, setIsUpdatingAnswers] = useState(false);
    const [userParticipatedQuizzes, setUserParticipatedQuizzes] = useState<Quiz[]>([]);
    const [error, setError] = useState('');
    const [isCollectionLoading, setIsCollectionLoading] = useState(false);
    const [isQuizLoading, setIsQuizLoading] = useState(false);
    const [isUserInSegment, setIsUserInSegment] = useState(false);
    const isQuizGoLiveTimeInFuture = activeQuiz && moment(activeQuiz.go_live_time) > moment();

    const cantSubmitAnswers =
        activeQuiz?.id &&
        (!userAnswers[activeQuiz.id] ||
            userAnswers[activeQuiz.id].length !== activeQuiz.questions?.length ||
            isSubmittingAnswers ||
            moment(activeQuiz.submission_start_time) > moment() ||
            moment(activeQuiz.submission_end_time) < moment() ||
            Boolean(error) ||
            !isUserInSegment);

    useEffect(() => {
        initCollection();
    }, []);

    useEffect(() => {
        if (activeQuizId) {
            initQuiz();
        }
    }, [activeQuizId]);

    useEffect(() => () => setActiveQuizId(0), []);

    useEffect(() => {
        if (activeQuiz?.questions) {
            const question = activeQuiz.questions.find((question) => question.question_number === activeQuestionNumber);
            setActiveQuestion(question);
        }
    }, [activeQuestionNumber]);

    async function initCollection() {
        setIsCollectionLoading(true);
        setError('');
        try {
            const collectionQuizzes = await getCollectionQuizzes(collectionId);
            setCollectionQuizzes(collectionQuizzes);

            if (isAuthenticated) {
                setUserParticipatedQuizzes(await getUserParticipatedQuizzes(collectionId));
            }

            const activeQuizId = quizId || collectionQuizzes[0]?.id || userParticipatedQuizzes[0]?.id;

            if (!activeQuizId) {
                return null;
            }

            setActiveQuizId(activeQuizId);
            setActiveQuestionNumber(1);

            const quizUserAnswers = {} as UserAnswers;

            collectionQuizzes.forEach((quiz) => {
                quizUserAnswers[quiz.id] = [];
            });
            setUserAnswers(quizUserAnswers);
        } catch (error) {
            logger.error('CmsQuizCollection', 'initCollection', error);
        }
        setIsCollectionLoading(false);
    }

    async function initQuiz() {
        setIsQuizLoading(true);
        setIsAnswersSubmitted(false);
        setError('');
        try {
            const quiz = await getQuiz(activeQuizId);

            if (quiz.status === QUIZ_STATUS.ACTIVE && moment(quiz.submission_end_time) < moment()) {
                quiz.status = QUIZ_STATUS.UNRESOLVED;
            }

            setActiveQuiz(quiz);
            setActiveQuestion(quiz.questions?.[0]);

            if (isAuthenticated) {
                if (quiz.status === QUIZ_STATUS.ACTIVE) {
                    const checkResponse = await checkIsUserInSegment(activeQuizId);
                    if (checkResponse.isUserInQuizSegment) {
                        setIsUserInSegment(true);
                    } else {
                        setIsUserInSegment(false);
                        setError(getQuizError(2002));
                    }
                }
                const userAnswersForQuiz = await getUserAnswers(activeQuizId);
                if (userAnswersForQuiz) {
                    setIsAnswersSubmitted(true);
                    setIsUpdatingAnswers(true);
                    userAnswers[quiz.id] = userAnswersForQuiz.answers || [];
                }
                setUserAnswers({ ...userAnswers });
            }
        } catch (error: any) {
            setError(getQuizError(error.code) || translate('Something went wrong', 'ui.common'));
            logger.error('CmsQuizCollection', 'initQuiz', error);
        }
        setIsQuizLoading(false);
    }

    async function handleSubmitUserAnswers() {
        setIsSubmittingAnswers(true);
        setError('');

        try {
            if (!activeQuiz) {
                return null;
            }

            const userAnswerObject = {
                quiz_id: activeQuiz.id,
                answers: userAnswers[activeQuiz.id],
            };

            await submitAnswers(userAnswerObject);
            setUserParticipatedQuizzes(await getUserParticipatedQuizzes(collectionId));
            setIsSubmittingAnswers(false);
            setIsAnswersSubmitted(true);
        } catch (error: any) {
            setError(getQuizError(error.code) || translate('Something went wrong', 'ui.common'));
            logger.error('CmsQuizCollection', 'handleSubmitUserAnswers', error);
        }
        setIsSubmittingAnswers(false);
    }

    function viewSubmittedAnswers() {
        setIsAnswersSubmitted(false);
    }

    function handleQuizEnding() {
        initQuiz();
    }

    if (isCollectionLoading || (activeQuizId && isQuizLoading && !isQuizChangedFromFooter)) {
        return (
            <UiBox className="quiz-loading">
                <UiAlert info>{translate('Quiz iz loading ...', 'ui.quiz')}</UiAlert>
                <UiDotsLoader />
            </UiBox>
        );
    }

    return (
        <Wrapper>
            <div className="quiz-content animated fadeIn">
                {isQuizLoading && <UiDotsLoader />}
                {!isQuizLoading && (
                    <>
                        {activeQuiz && (
                            <div className="animated fadeIn">
                                {activeQuiz.status === QUIZ_STATUS.ACTIVE && (
                                    <CmsQuizCollectionQuizTimer
                                        endTime={
                                            isQuizGoLiveTimeInFuture
                                                ? (activeQuiz.go_live_time as string)
                                                : (activeQuiz.submission_end_time as string)
                                        }
                                        onTimerEnd={handleQuizEnding}
                                    />
                                )}
                                <div className="status-container">
                                    <div className="status">
                                        {isQuizGoLiveTimeInFuture
                                            ? translate('Starting Soon', 'ui.sportsbook.lb')
                                            : translateQuizStatus(activeQuiz.status)}
                                    </div>
                                </div>
                                <CmsQuizCollectionQuizHeader quiz={activeQuiz} />

                                {activeQuestion && !isAnswersSubmitted && (
                                    <div className="quiz-body animated fadeIn">
                                        {(activeQuiz.status === QUIZ_STATUS.RESULTED ||
                                            activeQuiz.status === QUIZ_STATUS.UNRESOLVED) &&
                                            isAuthenticated && <CmsQuizCollectionQuizUserScore quiz={activeQuiz} />}

                                        <CmsQuizCollectionQuizQuestion quiz={activeQuiz} question={activeQuestion} />

                                        {Boolean(error) && <UiAlert failure>{error}</UiAlert>}

                                        {!isAuthenticated && (
                                            <div className="register-and-login-buttons">
                                                <AuthLoginButton />
                                                <AuthRegisterButton openInNewTab />
                                            </div>
                                        )}

                                        {activeQuiz.status === QUIZ_STATUS.ACTIVE &&
                                            isAuthenticated &&
                                            !isUpdatingAnswers && (
                                                <UiButton
                                                    color="primary"
                                                    block
                                                    className="submit-answers"
                                                    disabled={Boolean(cantSubmitAnswers)}
                                                    isLoading={isSubmittingAnswers}
                                                    onClick={handleSubmitUserAnswers}
                                                >
                                                    {translate('Submit your answers', 'ui.quiz')}
                                                </UiButton>
                                            )}

                                        {activeQuiz.status === QUIZ_STATUS.ACTIVE && isUpdatingAnswers && (
                                            <UiButton
                                                color="primary"
                                                block
                                                className="submit-answers"
                                                disabled={Boolean(cantSubmitAnswers)}
                                                isLoading={isSubmittingAnswers}
                                                onClick={handleSubmitUserAnswers}
                                            >
                                                {translate('Update your answers', 'ui.quiz')}
                                            </UiButton>
                                        )}

                                        <CmsQuizCollectionQuizQuestionNumber
                                            questions={activeQuiz.questions}
                                            activeQuiz={activeQuiz}
                                        />
                                    </div>
                                )}
                                {isAnswersSubmitted && (
                                    <CmsQuizCollectionQuizSubmission
                                        quiz={activeQuiz}
                                        onViewingAnswers={viewSubmittedAnswers}
                                    />
                                )}

                                <CmsQuizCollectionFooter
                                    allQuizzes={collectionQuizzes}
                                    userParticipatedQuizzes={userParticipatedQuizzes}
                                    isQuizLoading={isQuizLoading}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </Wrapper>
    );
}
