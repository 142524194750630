import { translate } from '../translate';
import { sportPrefix } from './betslip';
import { DatabaseSequence } from './types';
import { MarketViewType } from '@staycool/sports-types';

export function getLine(
    market: {
        outcomes: {
            id: number;
        }[];
        team_names?: string[];
        raw_line: number;
        view_type: MarketViewType | string;
    },
    outcome: { id: number; name: string; result_key: string },
    addTotalsPrefix = true,
    teaserPoints?: number,
) {
    if (market.view_type !== 'line' || !outcome) {
        return;
    }

    if (['Over', 'Under'].includes(outcome.result_key)) {
        let line = market.raw_line;
        if (teaserPoints) {
            const points = outcome.result_key === 'Over' ? teaserPoints * -1 : teaserPoints;
            line = line + points;
        }
        return addTotalsPrefix ? `${outcome.name.slice(0, 1)} ${formatLine(line)}` : formatLine(line);
    }

    const firstTeamName = market.team_names?.length === 2 ? market.team_names[0] : undefined;
    const lastTeamName = market.team_names?.length === 2 ? market.team_names[1] : undefined;

    const firstRawLine = teaserPoints ? market.raw_line + teaserPoints : market.raw_line;
    const secondRawLine = teaserPoints ? market.raw_line * -1 + teaserPoints : market.raw_line * -1;
    const firstLine = firstRawLine > 0 ? `+${formatLine(firstRawLine)}` : formatLine(firstRawLine);
    const secondLine = secondRawLine > 0 ? `+${formatLine(secondRawLine)}` : formatLine(secondRawLine);

    if (['[Home]', '[Player 1]', firstTeamName].includes(outcome.result_key)) {
        return firstLine;
    }
    if (['[Away]', '[Player 2]', lastTeamName].includes(outcome.result_key)) {
        return secondLine;
    }

    if (market.outcomes.length === 2) {
        if (market.outcomes[0].id === outcome.id) {
            return firstLine;
        }
        if (market.outcomes[1].id === outcome.id) {
            return secondLine;
        }
    }
}

export function formatLine(rawLine: number) {
    return rawLine % 1 !== 0 ? rawLine : `${rawLine}.0`;
}

export const replaceSequencesInName = (name: string, sequence: DatabaseSequence) => {
    if (!sequence) {
        return name;
    }

    Object.entries(sequence || {}).forEach(([key, value]) => {
        name = name.replace(`[${key}]`, value.toString());
    });

    return name;
};

/**
 * Replaces pitcher placeholders with generic names
 * For top marketType names we don't have actual sequences.
 */
export const marketTypeNameWrapper = (marketTypeName: string) => {
    if (!marketTypeName) {
        return;
    }

    const pitchersTranslation = translate(['MARKET_TYPE_NAME_PITCHERS', 'Pitchers'], sportPrefix);
    const translations = {
        '[pitcherHome] and [pitcherAway]': pitchersTranslation,
        '[pitcherAway] and [pitcherHome]': pitchersTranslation,
        '[pitcherHome]': translate(['MARKET_TYPE_NAME_HOME_PITCHER', 'Home Pitcher'], sportPrefix),
        '[pitcherAway]': translate(['MARKET_TYPE_NAME_AWAY_PITCHER', 'Away Pitcher'], sportPrefix),
    };

    Object.keys(translations).forEach((key) => (marketTypeName = marketTypeName.replace(key, translations[key])));
    return marketTypeName;
};
