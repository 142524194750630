import { ProofOfPaymentVerificationOption } from '../contexts/proof-of-payment-verification/ProofOfPaymentVerificationContext';
import { getServiceUrl, httpGet, httpPost, httpPostFile } from '../services/api';
import { KycDocumentType } from '../services/kyc';
import { logger } from '../services/logger';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';

const getUrl = (url) => getServiceUrl('kyc', url);

export async function uploadFiles(
    files: File[],
    uploadType: KycDocumentType,
    extraData?: Record<string, string | Blob>,
) {
    const kycToken = getStoreValue(stores.kyc.kycToken);
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const url = isAuthenticated ? getUrl('upload') : getUrl('anon_upload');
    const formData = new FormData();
    files.forEach((file) => formData.append('file', file));
    formData.append('type', uploadType);
    if (!isAuthenticated && kycToken) {
        formData.append('hash', kycToken);
    }
    if (extraData) {
        Object.entries(extraData).forEach(([key, value]) => formData.append(key, value));
    }
    return httpPostFile(url, formData);
}

export async function loadKycUserSettings() {
    const kycToken = getStoreValue(stores.kyc.kycToken);
    const headers = {};
    if (kycToken) {
        headers['kyc-token'] = kycToken;
    }
    const url = getUrl('user-settings');
    try {
        const result = await httpGet<KycSettings>(url, undefined, {
            headers,
        });
        stores.kyc.settings.set(result);
    } catch (error) {
        logger.error('KycMicroservice', 'loadKycUserSettings', error);
    }
}

export function getRequestedPaymentDocuments() {
    const kycToken = getStoreValue(stores.kyc.kycToken);
    const headers = {};
    if (kycToken) {
        headers['kyc-token'] = kycToken;
    }
    return httpGet<RequestedDocument[]>(getUrl('requested-documents/payment'), undefined, { headers });
}

export async function loadGbgParams(): Promise<GbgParams> {
    const kycToken = getStoreValue(stores.kyc.kycToken);
    const headers = {};
    if (kycToken) {
        headers['kyc-token'] = kycToken;
    }
    const url = getUrl('docs/capture');
    return await httpGet(url, undefined, {
        headers,
    });
}

export function verifyMyId() {
    const url = getUrl('verify-my-id');
    return httpPost<void>(url);
}

type HasReachedKycUploadLimit = { [key in KycDocumentType]: boolean };
type KycUploadLimits = { [key in KycDocumentType]: number };
export type KycSettings = {
    hasReachedLimits: HasReachedKycUploadLimit;
    isEVerificationEnabled: boolean;
    isKycBannerEnabled: boolean;
    isKycCaptureEnabled: boolean;
    isKycDocsEnabled: boolean;
    isProofOfIdEnabled: false;
    kycAddressDeclineReason: string;
    kycIdDeclineReason: string;
    kycIncomeDeclineReason: string;
    kycPaymentDeclineReason: string;
    kycStatus: string;
    isClosed: boolean;
    limits: KycUploadLimits;
    proofOfAddress: string;
    proofOfId: string;
    proofOfPaymentStatus: string;
    sourceOfIncomeStatus: string;
};

export interface RequestedDocument {
    amount?: number;
    backSideDeclineReason?: string;
    currency?: string;
    date?: string;
    declineReason?: string;
    documentType: 'E_WALLET' | 'BANK_ACCOUNT' | 'CREDIT_CARD' | 'CRYPTO_WALLET' | 'DEPOSIT';
    expirationDate?: string;
    frontSideDeclineReason?: string;
    id: string;
    number?: string;
    previouslySelectedOption?: ProofOfPaymentVerificationOption;
    selectedOption?: ProofOfPaymentVerificationOption;
    status: 'Pending' | 'Requested' | 'Declined';
    type: string;
}

export type GbgParams = {
    backendUrl: string;
    journeyDefinitionId: string;
    token: string;
};
