import React from 'react';
import {
    ProofOfPaymentVerificationOption,
    useProofOfPaymentVerificationContext,
} from '../../../../../../contexts/proof-of-payment-verification/ProofOfPaymentVerificationContext';
import { translate } from '../../../../../../services/translate';
import AccountVerificationOptionsList from '../../../options-list/AccountVerificationOptionsList';
import AccountVerificationProofOfPaymentFooter from '../../footer/AccountVerificationProofOfPaymentFooter';
import AccountVerificationProofOfPaymentHeader from '../../header/AccountVerificationProofOfPaymentHeader';

export default function AccountVerificationProofOfPaymentSteps1() {
    const { selectedDocument, selectedVerificationOption, setSelectedVerificationOption } =
        useProofOfPaymentVerificationContext();
    if (!selectedDocument) {
        return null;
    }
    const verificationOptionsByDocumentType = {
        CREDIT_CARD: [
            {
                value: ProofOfPaymentVerificationOption.CARD_FRONT_AND_BACK,
                label: translate('Front side and back side of card', 'ui.account.kyc.pop'),
            },
            {
                value: ProofOfPaymentVerificationOption.CARD_LOST,
                label: translate('I have lost the card', 'ui.account.kyc.pop'),
            },
            {
                value: ProofOfPaymentVerificationOption.CARD_NO_NAME_OR_NUMBER,
                label: translate('The card does not have number or my name on it', 'ui.account.kyc.pop'),
            },
        ].filter((option) => option.value !== selectedDocument.previouslySelectedOption),
        BANK_ACCOUNT: [
            {
                value: ProofOfPaymentVerificationOption.BANK_SCREENSHOT,
                label: translate('Screenshot from your internet bank', 'ui.account.kyc.pop'),
            },
            {
                value: ProofOfPaymentVerificationOption.BANK_STATEMENT,
                label: translate('Bank statement', 'ui.account.kyc.pop'),
            },
        ],
    };
    return (
        <>
            <AccountVerificationProofOfPaymentHeader>
                <span>{translate('Please select one of the options from below', 'ui.account.kyc.pop')}</span>
            </AccountVerificationProofOfPaymentHeader>
            <AccountVerificationOptionsList
                options={verificationOptionsByDocumentType[selectedDocument.documentType]}
                selectedOption={selectedVerificationOption}
                onOptionSelected={(option) => setSelectedVerificationOption(option as ProofOfPaymentVerificationOption)}
            />
            <AccountVerificationProofOfPaymentFooter />
        </>
    );
}
