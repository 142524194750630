import debounce from 'lodash/debounce';
import { loadLivebetTree } from '../microservices/sbgate';
import { getActiveCurrency } from './currency';

const ALL_FILTER = 'All';
const TOP_13_FILTER = 'Top 13';
const BETBUILDER_FILTER = 'Betbuilder';

export const MARKET_TYPE_FILTERS = { ALL_FILTER, TOP_13_FILTER, BETBUILDER_FILTER };

export const MARKET_TYPE_VIEW_TYPES = {
    EXACT_SCORE: 'exact_score',
    HALF_TIME_FULL_TIME: 'ht_ft',
};

export const FOOTBALL_CATEGORY_ID = 62;

export const ratingsSportsToCategoryIdMap = {
    ATHLETICS: '26472',
    AUSTRALIAN_RULES: '66',
    BADMINTON: '67',
    BANDY: '19231',
    BASEBALL: '96',
    BASKETBALL: '77',
    BEACH_SOCCER: '43004',
    BEACH_VOLLEYBALL: '97',
    BIATHLON: '29649',
    BOXING: '99',
    CHESS: '30190',
    CRICKET: '100',
    CROSSFIT: '48008',
    CURLING: '30244',
    CYCLING: '101',
    DARTS: '102',
    DISCGOLF: '42910',
    ENTERTAINMENT: '29550',
    ESPORTS: '103',
    FINANCE: '42413',
    FLOORBALL: '19136',
    FOOTBALL: '62',
    FORMULA_1: '26450',
    FUTSAL: '104',
    GOLF: '106',
    HANDBALL: '68',
    HOCKEY: '107',
    ICE_HOCKEY: '85',
    MMA: '20491',
    PESÄPALLO: '19135',
    POKER: '26656',
    POLITICS: '26328',
    RALLY: '34421',
    RUGBY_LEAGUE: '7323',
    RUGBY_UNION: '110',
    SNOOKER: '79',
    SPEEDWAY: '25232',
    SWIMMING: '44749',
    TABLE_TENNIS: '112',
    TENNIS: '72',
    TROTTING: '25313',
    VOLLEYBALL: '92',
    WATERPOLO: '113',
    NORDIC_COMBINED: '30256',
};

export const ratingsSportToCategoryMap = {
    62: 'FOOTBALL',
    85: 'ICE_HOCKEY',
    77: 'BASKETBALL',
    72: 'TENNIS',
    96: 'BASEBALL',
    103: 'ESPORTS',
    68: 'HANDBALL',
    // 333:'AMERICAN_FOOTBALL',
    92: 'VOLLEYBALL',
    19136: 'FLOORBALL',
    29550: 'ENTERTAINMENT',
    19135: 'PESÄPALLO',
    // 333:'CROSS_COUNTRY_SKIING',
    20491: 'MMA',
    79: 'SNOOKER',
    29649: 'BIATHLON',
    106: 'GOLF',
    26450: 'FORMULA_1',
    26472: 'ATHLETICS',
    97: 'BEACH_VOLLEYBALL',
    102: 'DARTS',
    26328: 'POLITICS',
    99: 'BOXING',
    19231: 'BANDY',
    101: 'CYCLING',
    // 333:'ALPINE_SKIING',
    110: 'RUGBY_UNION',
    30244: 'CURLING',
    67: 'BADMINTON',
    34421: 'RALLY',
    // 333:'SKI_JUMPING',
    104: 'FUTSAL',
    100: 'CRICKET',
    26656: 'POKER',
    42910: 'DISCGOLF',
    66: 'AUSTRALIAN_RULES',
    112: 'TABLE_TENNIS',
    7323: 'RUGBY_LEAGUE',
    // 333:'NORDIC_COMBINED',
    25232: 'SPEEDWAY',
    // 333:'RIO_2016',
    25313: 'TROTTING',
    // 333:'3X3_BASKETBALL',
    30190: 'CHESS',
    // 333:'SPEED_SKATING',
    107: 'HOCKEY',
    113: 'WATERPOLO',
    // 333:'FREESTYLE_SKIING',
    // 333:'SNOWBOARDING',
    44749: 'SWIMMING',
    // 333:'ORIENTEERING',
    // 333:'OLYMPICS',
    42413: 'FINANCE',
    // 333:'SHORT_TRACK_SPEED_SKATING',
    // 333:'BOBSLEIGH',
    43004: 'BEACH_SOCCER',
    // 333:'LUGE',
    // 333:'SKELETON',
    48008: 'CROSSFIT',
    // 333:'FIGURE_SKATING',
    // 333:'X_GAMES',
    // 333:'BRIDGE',
    // 333:'REINDEER_RACING',
    // 333:'MOTOR_SPORT',
    // 333:'RALLYCROSS',
    // 333:'FISHING',
    // 333:'HORSE_RACING'
};

export function mapIncomingTicketToTicket(ticket) {
    return {
        amount: ticket.amount.toFixed(2),
        total_stake: Math.abs(ticket.amount).toFixed(2),
        categoryId: ticket.sportsbook.categoryId,
        outcomeIds: ticket.sportsbook.outcomeIds,
        sportId: ticket.sportsbook.sportId,
        first_bet_odds: ticket.sportsbook.totalOdds,
        max_win: ticket.sportsbook.potentialReturn.toFixed(2),
        city: ticket.city,
        product: ticket.sportsbook.product,
        country: ticket.country ? ticket.country.toLowerCase() : '',
        time: Math.floor(Date.now() / 1000),
        latitude: ticket.latitude,
        longitude: ticket.longitude,
        ticket_type: ticket.sportsbook.ticketType,
        id: ticket.sportsbook.ticketId,
        total_matches: ticket.sportsbook.outcomeIds.length,
        currency: getActiveCurrency(),
        first_match: {
            category_id: ticket.sportsbook.categoryId,
            sport_category_id: ticket.sportsbook.sportId,
            outcome_name: ticket.sportsbook.outcomeName,
            market_name: ticket.sportsbook.marketName,
            marketTypeName: ticket.sportsbook.marketTypeName,
            categoryName: ticket.sportsbook.categoryName,
            sportName: ticket.sportsbook.sportName,
            sport_icon: ticket.sportsbook.sportIcon,
            home_team: ticket.sportsbook.homeTeamName,
            away_team: ticket.sportsbook.awayTeamName,
            match_name: ticket.sportsbook.fixtureName,
        },
    };
}

export const loadLivebetTreeDebounced = debounce(loadLivebetTree, 1000, { maxWait: 2000 });

export interface FOTranslationsByOutcomeId {
    [outcomeId: number]: FOTranslations;
}

interface FOTranslations {
    outcomeName: string;
    marketName: string;
    matchName: string;
}
