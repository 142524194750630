import React from 'react';
import Wrapper from './styles';
import { stores } from '../../../../../stores';
import { translate } from '../../../../../services/translate';
import UiAlert from '../../../../ui/alert/UiAlert';
import { Quiz } from '../../../../../microservices/quiz';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    quiz: Quiz;
}

export default function CmsQuizCollectionQuizUserScore({ quiz }: Props) {
    const [userAnswers] = useStore(stores.cms.userAnswers);
    const userAnswersForQuiz = quiz.id && userAnswers[quiz.id];
    const correctAnswers =
        userAnswersForQuiz && userAnswersForQuiz.filter((answer) => answer.was_correct === true).length;
    const questionsCount = userAnswersForQuiz && userAnswersForQuiz.length;
    const isUserWinner = quiz.min_correct_answers <= correctAnswers;

    if (!userAnswersForQuiz) {
        return <UiAlert info>{translate('You did not participate in this round', 'ui.quiz')}</UiAlert>;
    }

    return (
        <Wrapper isUserWinner={isUserWinner} isUserNotWinner={!isUserWinner}>
            <div className="score-text">{translate('your score', 'ui.quiz')}</div>
            <div className="score-count">
                {correctAnswers} / {questionsCount}
            </div>
        </Wrapper>
    );
}
