import { Licence, Market, UsaProvince } from '@staycool/location';
import camelCase from 'lodash/camelCase';
import mapValues from 'lodash/mapValues';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getMe, validateAlias } from '../microservices/users';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { COUNTRY } from './country';
import { getClient, isB2B, isRetail } from './environment';
import { getLicence } from './licence';
import { logger } from './logger';
import { DefaultMarketByClient, isMarketEnabled } from './markets';
import { getArticleRoute } from './router';
import { storageGet, storageSet } from './storage';
import { translate } from './translate';
import { User, UserQueryParameters } from './types';
import { useStore } from '../hooks/useStore';

const STORAGE_ALIAS_KEY = 'userAlias';

export async function loadProfile(options?: { isThrowingOnError: boolean }) {
    try {
        const user = await getMe();
        stores.user.set(user);
        storageSet(STORAGE_ALIAS_KEY, user.alias);
        return user;
    } catch (error) {
        logger.error('UserService', 'loadProfile', error);
        if (options?.isThrowingOnError) {
            throw error;
        }
    }
}

export async function getUserWithExtra(query: UserQueryParameters): Promise<User | undefined> {
    try {
        const user = await getMe(query);
        return user;
    } catch (error) {
        logger.error('UserService', 'getUserWithExtra', error);
        return undefined;
    }
}

export function getUnderageError(country: COUNTRY) {
    return translate('Sorry! You must be at least {{ minAges }} years old in order to sign up.', 'ui.registration', {
        minAges: getSignupAgeLimit(country),
    });
}

export function setPromotionsPageLastVisit() {
    storageSet('promotionsPageLastVisit', new Date().getTime().toString());
}

export function getPromotionsPageLastVisit() {
    return parseInt(storageGet('promotionsPageLastVisit')) || 0;
}

export function isTestUser() {
    const user = getStoreValue(stores.user);
    return Boolean(user && user.isTest);
}

export function isExistingUser() {
    return storageGet(STORAGE_ALIAS_KEY);
}

export function isUserFromCountry(country: COUNTRY) {
    return getUserCountry() === country;
}

export function getUserMarketCode() {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const user = getStoreValue(stores.user);
    if (isAuthenticated && user) {
        return user.market;
    }
    const country = getUserCountry();
    const province = getUserProvince();

    if (country === COUNTRY.USA && province === UsaProvince.NEVADA && isMarketEnabled(Market.USA_NEVADA)) {
        return Market.USA_NEVADA;
    }

    const marketCountry = country as unknown as Market;
    if (Object.values(Market).includes(marketCountry) && isMarketEnabled(marketCountry)) {
        return marketCountry;
    }
    return DefaultMarketByClient[getClient()] || Market.REST_OF_THE_WORLD;
}

export function getUserProvince() {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const user = getStoreValue(stores.user);
    if (isAuthenticated && user) {
        return user.province;
    }
    const ipProvince = getStoreValue(stores.ipProvince);
    return ipProvince;
}

export function getUserCountry() {
    if (isB2B()) {
        return COUNTRY.USA;
    }

    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const user = getStoreValue(stores.user);
    if (isAuthenticated && user) {
        const isRetailLayout = isRetail();
        return isRetailLayout ? COUNTRY.USA : user.country;
    }
    return getStoreValue(stores.ipCountry);
}

export function isMalteseRegulatedUser() {
    return !isUserFromCountry(COUNTRY.ESTONIA) && !isUserFromCountry(COUNTRY.SWEDEN);
}

export function getFullName() {
    const user = getStoreValue(stores.user);
    return `${user?.firstName} ${user?.lastName}`;
}

export function getPhoneNumber() {
    const user = getStoreValue(stores.user);
    return `${user?.phonePrefix}${user?.phoneNumber}`;
}

export function isValidUser() {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const user = getStoreValue(stores.user);
    const askDepositLimit = getStoreValue(stores.responsibleGaming.askDepositLimit);
    const askLoginDurationLimit = getStoreValue(stores.responsibleGaming.askLoginDurationLimit);
    const askLossLimit = getStoreValue(stores.responsibleGaming.askLossLimit);

    if (!user || !isAuthenticated) {
        return false;
    }

    const hasAcceptedTerms = user.acceptTermsAndConditions;
    const isValidProfile =
        !user.askPersonalId &&
        ![askDepositLimit, askLoginDurationLimit, askLossLimit].includes(undefined) &&
        !askDepositLimit &&
        !askLoginDurationLimit;

    return hasAcceptedTerms && isValidProfile;
}

export async function isFieldsValid({ alias }) {
    return (await validateAlias(alias)).result;
}

export function getSignupAgeLimit(country: COUNTRY) {
    const signupLegalAgeByCountry = {
        [COUNTRY.CANADA]: 19,
    };
    return (signupLegalAgeByCountry[country] as number) || 18;
}

export function getCountryBasedTermsAndConditions() {
    const articleMarketKey = getLicence().toLowerCase();
    return getArticleRoute(`support-terms-and-conditions-${articleMarketKey}`, 'support.terms-and-conditions');
}

export function captureUserActivity() {
    stores.lastActivityTime.set(moment().toISOString());
}

export function getUpdateUserErrors(updateError: Record<string, string[]>) {
    const errorByFieldName = {};
    Object.entries(updateError).forEach(([field, [message]]) => {
        if (field === 'non_field_errors' && message === 'invalid phone number') {
            errorByFieldName['phoneNumber'] = { error: translate(message, 'ui.registration') };
        } else if (
            field === 'non_field_errors' &&
            message ===
                'Oops! "Poste restante" is not acceptable address by compliance reasons. Please provide real address'
        ) {
            errorByFieldName['address'] = { error: translate(message, 'ui.registration') };
        } else {
            errorByFieldName[camelCase(field)] = { error: translate(message, 'ui.registration') };
        }
    });
    return errorByFieldName as {
        address?: { error: string };
        birthDate?: { error: string };
    };
}

export function getTranslatedBackendErrors(
    errors: Record<string, [message: string, replacements?: Record<string, string | number>, context?: string]>,
) {
    return mapValues(errors, ([message, replacements, context = 'ui.registration']) => ({
        error: translate(message, context, replacements),
    }));
}

export const getProvinceOntarioOrNull = () => {
    // caching optimization: only Ontario province affects sportsbook content
    return getUserProvince() === 'Ontario' ? 'Ontario' : null;
};

export function useIsTestUser() {
    const [isTest, setIsTest] = useState(false);
    const [user] = useStore(stores.user);
    useEffect(() => {
        setIsTest(isTestUser());
    }, [user]);

    return isTest;
}

export function isFieldVisible(field: string) {
    const licence = getLicence();
    const fieldByLicence = {
        nationality: [Licence.ESTONIA, Licence.MALTA, Licence.SWEDEN],
        birthPlace: [Licence.ESTONIA, Licence.MALTA, Licence.SWEDEN],
    };

    return Boolean(fieldByLicence[field]?.includes(licence));
}
