import styled, { css } from 'styled-components';

const borders = {
    rounded: css`
        border-radius: var(--ui-radius);
    `,
    flat: css`
        border-radius: 0;
    `,
};

type StyledProps = {
    border: 'flat' | 'rounded';
    $isRecentlyPlayed?: boolean;
};
export default styled.div<StyledProps>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 3;
    ${({ border }) => borders[border]};

    .casino-thumbnail-disabled-message-box {
        background-color: var(--surface-level-1-bg);
        padding: 10px 20px;
        border-radius: var(--ui-radius);
        font-size: var(--font-10);
        font-weight: 600;
        color: var(--font-color-secondary);
        text-transform: uppercase;
        margin: 5px 0;
        text-align: center;
        max-width: 150px;
        white-space: pre-wrap;
        letter-spacing: 1px;
    }

    ${({ $isRecentlyPlayed }) =>
        $isRecentlyPlayed &&
        css`
            font-size: 8px;
            width: 50%;
        `}
`;
