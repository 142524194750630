import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import moment from 'moment';
import classNames from 'classnames';
import { stores } from '../../../../stores';
import { translate } from '../../../../services/translate';
import UiButton from '../../../ui/button/UiButton';
import UiDotsLoader from '../../../ui/dots-loader/UiDotsLoader';
import UiAlert from '../../../ui/alert/UiAlert';
import UiBox from '../../../ui/box/UiBox';
import Participated from './quiz-participated.png';
import UiAnimate from '../../../ui/animate/UiAnimate';
import { Quiz } from '../../../../microservices/quiz';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    allQuizzes: Quiz[];
    userParticipatedQuizzes: Quiz[];
    isQuizLoading: boolean;
}

export default function CmsQuizCollectionFooter({ allQuizzes, userParticipatedQuizzes, isQuizLoading = true }: Props) {
    const [activeQuizId, setActiveQuizId] = useStore(stores.cms.activeQuizId);
    const [activeQuizTab, setActiveQuizTab] = useStore(stores.cms.activeQuizTab);
    const [filteredQuizzes, setFilteredQuizzes] = useState<Quiz[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const firstActiveQuiz = allQuizzes && allQuizzes[0];
    const firstUserParticipatedQuiz = userParticipatedQuizzes && userParticipatedQuizzes[0];

    const QUIZ_FILTERING = {
        ACTIVE_QUIZZES: translate('Active quizzes', 'ui.quiz'),
        PARTICIPATED: translate('Participated', 'ui.quiz'),
    };
    const [activeTab, setActiveTab] = useState(!activeQuizTab ? QUIZ_FILTERING.ACTIVE_QUIZZES : activeQuizTab);
    const isUserNotParticipatedInQuizzes = Boolean(
        !userParticipatedQuizzes.length &&
            !isQuizLoading &&
            isAuthenticated &&
            (activeTab !== QUIZ_FILTERING.ACTIVE_QUIZZES || !allQuizzes.length),
    );

    useEffect(() => {
        initQuizFiltering();
    }, [activeQuizTab, allQuizzes, userParticipatedQuizzes, isAuthenticated]);

    function initQuizFiltering() {
        setIsLoading(true);

        if (!isAuthenticated) {
            setActiveQuizTab(QUIZ_FILTERING.ACTIVE_QUIZZES);
        }

        if (activeTab === QUIZ_FILTERING.PARTICIPATED || (allQuizzes.length === 0 && isAuthenticated)) {
            setFilteredQuizzes(userParticipatedQuizzes);
        } else {
            setFilteredQuizzes(allQuizzes);
        }
        setIsLoading(false);
    }

    const filterOptions = [QUIZ_FILTERING.ACTIVE_QUIZZES, QUIZ_FILTERING.PARTICIPATED];

    const [language] = useStore(stores.language);

    function goToQuiz(quizId) {
        stores.cms.isQuizChangedFromFooter.set(true);
        setActiveQuizId(quizId);
        stores.cms.activeQuestionNumber.set(1);
    }

    function handleActiveTabSelection(filter) {
        setActiveTab(filter);
        setActiveQuizTab(filter);
        if (filter === QUIZ_FILTERING.PARTICIPATED && userParticipatedQuizzes.length > 0) {
            goToQuiz(firstUserParticipatedQuiz.id);
        } else {
            goToQuiz(firstActiveQuiz.id);
        }
    }

    function showParticipatedIcon(quiz) {
        if (activeTab === QUIZ_FILTERING.PARTICIPATED) {
            return true;
        }

        const activeQuizzesUserHasParticipatedIn = allQuizzes.filter((quiz) =>
            userParticipatedQuizzes.find((participatedQuiz) => participatedQuiz.id === quiz.id),
        );
        const findParticipatedQuizzesFromActiveQuizzes = activeQuizzesUserHasParticipatedIn.find(
            (oneQuiz) => oneQuiz.id === quiz.id,
        );
        return Boolean(findParticipatedQuizzesFromActiveQuizzes);
    }

    return (
        <Wrapper>
            {allQuizzes.length > 0 && isAuthenticated && (
                <div className="filter-buttons">
                    {filterOptions.map((filter, index) => (
                        <UiButton
                            size="small"
                            selected={activeTab === filter}
                            key={index}
                            onClick={() => handleActiveTabSelection(filter)}
                        >
                            {filter}
                        </UiButton>
                    ))}
                </div>
            )}

            {isLoading ? (
                <UiDotsLoader />
            ) : (
                <>
                    {isUserNotParticipatedInQuizzes ? (
                        <UiBox className="no-participated-quizzes-message">
                            <UiAlert info>{translate('There are no participated quizzes', 'ui.quiz')}</UiAlert>
                        </UiBox>
                    ) : (
                        <div className="quiz-history-item-container">
                            {filteredQuizzes &&
                                filteredQuizzes.map((quiz, index) => (
                                    <UiAnimate animationIn="fadeIn" animationOut="fadeOut" key={index}>
                                        <div
                                            key={index}
                                            className={classNames('quiz-history-item', {
                                                selected: quiz.id === activeQuizId,
                                            })}
                                            onClick={() => goToQuiz(quiz.id)}
                                        >
                                            <div className="history-item-date">
                                                {moment(quiz.submission_end_time).format('DD MMM')}
                                            </div>
                                            <div className="history-item-title">
                                                {quiz.title[language] || quiz.title[quiz.default_language]}
                                            </div>
                                            {showParticipatedIcon(quiz) && (
                                                <div className="participated-icon">
                                                    <img src={Participated} alt="" />
                                                </div>
                                            )}
                                        </div>
                                    </UiAnimate>
                                ))}
                        </div>
                    )}
                </>
            )}
        </Wrapper>
    );
}
