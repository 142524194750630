import { useSocketSubscribeUnsubscribe, useSocketTopicEvents } from '../../microservices/pusher';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInViewport } from 'react-in-viewport';
import { CategoryMatchMarket } from './types';
import { MarketsWithOutcomesMinimal } from '../../microservices/sbgate';
import { fetchTopMarketsByMarketTypeAndMatchDebounced, findBestLine } from './top-markets';
import { logger } from '../logger';
import { stores, useStoreWithSelector } from '../../stores';
import pick from 'lodash/pick';
import {
    loadOddsForFoLineQueue,
    oddsUsageByMarketId,
    queuedSubscribeOddsByMarketId,
    queuedUnsubscribeOddsByMarketId,
} from './odds';
import { fakeMarket } from './skeleton-mocks';
import isEmpty from 'lodash/isEmpty';
import { useMatchStatusUpdate } from './hooks';
import type { MarketViewType } from '@staycool/sports-types';

export function useTopMarketBestLine(
    markets: (CategoryMatchMarket | MarketsWithOutcomesMinimal)[] | undefined,
    viewType: MarketViewType,
    isFake: boolean,
    preferHalfLine = false,
    initialMarket = fakeMarket,
) {
    const [market, setMarket] = useState<CategoryMatchMarket | MarketsWithOutcomesMinimal | undefined>(
        initialMarket as any,
    );

    const [isLoadedOdds, setIsLoadedOdds] = useState(false);

    const marketIds = useMemo(
        () =>
            markets
                ?.map((m) => m.id)
                .sort()
                .join(),
        [markets],
    );

    const outcomeIds = useMemo(
        () => markets?.map((lineMarket) => lineMarket.outcomes?.map((outcome) => outcome.id)).flat() || [],
        [marketIds],
    );

    const [marketOddsByOutcomeId] = useStoreWithSelector(
        stores.sports.oddsByOutcomeId,
        (state) => pick(state, outcomeIds as any),
        [outcomeIds as never],
    );

    useEffect(
        // subscribe to all line markets, as some of them are not visible so won't use the SportOddsLoad auto-subscribe
        () => {
            if (!markets) {
                return;
            }
            const marketIds = markets.map((market) => market.id);
            const newMarkets = markets.filter((market) => (oddsUsageByMarketId[market.id] || 0) <= 0);
            const newMarketIds = newMarkets.map((market) => market.id);
            marketIds.forEach(queuedSubscribeOddsByMarketId);
            if (newMarketIds?.length) {
                setIsLoadedOdds(false);
                loadOddsForFoLineQueue([newMarketIds]).then(() => isLoadedOdds || setIsLoadedOdds(true));
            } else if (!isLoadedOdds) {
                setIsLoadedOdds(true);
            }
            return () => {
                setTimeout(() => marketIds.forEach(queuedUnsubscribeOddsByMarketId), 10);
            };
        },
        [markets],
    );

    useEffect(() => {
        if (isFake || !markets || !isLoadedOdds) {
            return;
        } else if (isEmpty(marketOddsByOutcomeId) && isLoadedOdds) {
            setMarket(undefined);
            return;
        }
        const bestLineMarket = findBestLine(
            markets as CategoryMatchMarket[],
            viewType,
            marketOddsByOutcomeId,
            preferHalfLine,
        );
        setMarket(bestLineMarket);
    }, [isLoadedOdds && marketOddsByOutcomeId, markets, isFake]);

    return { market, setMarket, isLoadedOdds };
}

export function useTopMarketSocketUpdatesInView(matchId: number, marketTypeId: number, isFake: boolean) {
    const [markets, setMarkets] = useState<(CategoryMatchMarket | MarketsWithOutcomesMinimal)[]>();
    const refWrapper: any = useRef();
    const [latestUpdate, setLatestUpdate] = useState<any>();
    const { inViewport } = useInViewport(refWrapper);

    useSocketSubscribeUnsubscribe('public', {
        params: {
            service: 'sports',
            channel: `market-update-${matchId}-${marketTypeId}`,
        },
        watchParams: [matchId, marketTypeId],
        guardFunction: () => !isFake,
        resubscribeOnReconnect: true,
    });

    const refreshMarketsIf = useMemo(
        () =>
            async (timestamp: Date, force = false) => {
                if (markets?.[0]?.view_type !== 'line' && markets?.length && !force) {
                    return;
                }
                if (!inViewport) {
                    setLatestUpdate(timestamp);
                    return;
                }
                setLatestUpdate(null);
                await refreshMarkets(timestamp);
            },
        [inViewport, markets],
    );

    useSocketTopicEvents(`market-update-${matchId}-${marketTypeId}`, ({ timestamp }) => refreshMarketsIf(timestamp), [
        matchId,
        marketTypeId,
        inViewport,
    ]);

    async function refreshMarkets(timestamp: Date) {
        try {
            const markets = await fetchTopMarketsByMarketTypeAndMatchDebounced(matchId, marketTypeId, timestamp);
            if (!markets) {
                return;
            }
            setMarkets(markets);
        } catch (e) {
            logger.error('SportsTopMarketsHooksService', 'refreshMarkets', e);
        }
    }

    useEffect(() => {
        if (!inViewport || !latestUpdate) {
            return;
        }
        setLatestUpdate(null);
        refreshMarkets(latestUpdate);
    }, [inViewport, latestUpdate]);

    useMatchStatusUpdate({ id: matchId }, ({ updated }) => refreshMarketsIf(updated, true));

    return { markets, setMarkets, refWrapper };
}
