import React, { useState } from 'react';
import Wrapper from './styles';
import { translate } from '../../../services/translate';
import UiAlert from '../../ui/alert/UiAlert';
import UiFormGroup from '../../ui/form/group/UiFormGroup';
import { stores } from '../../../stores';
import UiButton from '../../ui/button/UiButton';
import { useForm } from 'react-hook-form';
import Ui2Form from '../../ui-2/form/Ui2Form';
import { loadProfile } from '../../../services/user';
import { UserCrmPreferences } from '../../../microservices/crm';
import { insertUserCrmPreferences, updateUserCrmPreferences } from '../../../services/crm';
import { FEATURE, isFeatureAvailable } from '../../../services/feature';
import UiBox from '../../ui/box/UiBox';
import Ui2FormToggle from '../../ui-2/form/toggle/Ui2FormToggle';
import UiDotsLoader from '../../ui/dots-loader/UiDotsLoader';
import { useStore } from '../../../hooks/useStore';

interface Props {
    className?: string;
}

export default function AccountSubscription({ className }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [userPreferences] = useStore(stores.crm.userPreferences);
    const useFormMethods = useForm();

    async function submit() {
        setIsLoading(true);
        setIsSuccess(false);
        setIsError(false);
        try {
            await updateCrmPreferences();
            await loadProfile({ isThrowingOnError: true });
            setIsSuccess(true);
        } catch (error) {
            setIsError(true);
        }
        setIsLoading(false);
    }

    async function updateCrmPreferences() {
        const updateBody = useFormMethods.getValues() as Omit<UserCrmPreferences, 'user_id'>;
        if (userPreferences) {
            await updateUserCrmPreferences(updateBody);
        } else {
            await insertUserCrmPreferences(updateBody);
        }
        stores.crm.showSubscriptionModalForm.set(false);
    }

    if (!userPreferences) {
        return <UiDotsLoader />;
    }

    return (
        <Wrapper className={className}>
            <UiBox header={translate('Subscription Settings', 'ui.account')}>
                <Ui2Form onSubmit={submit} useFormMethods={useFormMethods}>
                    <p>{translate('Send me bonus offers and news by', 'ui.account')}</p>

                    <UiFormGroup>
                        <Ui2FormToggle
                            name="subscribeMailList"
                            label={translate('Email', 'ui.account')}
                            defaultValue={Number(userPreferences.subscribeMailList)}
                        />
                    </UiFormGroup>

                    <UiFormGroup>
                        <Ui2FormToggle
                            name="subscribeSms"
                            label={translate('SMS', 'ui.account')}
                            defaultValue={Number(userPreferences.subscribeSms)}
                        />
                    </UiFormGroup>

                    {isFeatureAvailable(FEATURE.SUBSCRIPTION_SETTINGS_DIRECT_MAIL) && (
                        <UiFormGroup>
                            <Ui2FormToggle
                                name="subscribeDirectMail"
                                label={translate('Direct mail', 'ui.account')}
                                defaultValue={Number(userPreferences.subscribeDirectMail)}
                            />
                        </UiFormGroup>
                    )}
                    {isFeatureAvailable(FEATURE.SUBSCRIPTION_SETTINGS_PHONE_CALLS) && (
                        <UiFormGroup>
                            <Ui2FormToggle
                                name="subscribePhoneCalls"
                                label={translate('Phone calls', 'ui.account')}
                                defaultValue={Number(userPreferences.subscribePhoneCalls)}
                            />
                        </UiFormGroup>
                    )}

                    <UiFormGroup>
                        {isSuccess && <UiAlert success>{translate('Success!', 'ui.account')}</UiAlert>}
                        {isError && <UiAlert failure>{translate('Oops!', 'ui.account')}</UiAlert>}
                    </UiFormGroup>

                    <div className="update-buttons">
                        <UiButton block color="primary" isLoading={isLoading} isFormSubmitButton>
                            {translate('Update preferences', 'ui.account')}
                        </UiButton>
                        {isFeatureAvailable(FEATURE.COOKIE_BOT) && (
                            <UiButton block onClick={() => window.OneTrust?.ToggleInfoDisplay()}>
                                {translate('Change Cookie Policy preferences', 'cookie')}
                            </UiButton>
                        )}
                        {isFeatureAvailable(FEATURE.SUBSCRIPTION_SETTINGS_REVOKE_CONSENT) && (
                            <UiButton block onClick={() => window.OneTrust?.RejectAll()}>
                                {translate('Revoke your consent', 'cookie')}
                            </UiButton>
                        )}
                    </div>
                </Ui2Form>
            </UiBox>
        </Wrapper>
    );
}
