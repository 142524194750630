import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    .products-filter,
    .time-filter {
        margin: 0.8rem 0;
    }

    .transactions-header {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;

        @media ${mediaQuery.isPhone} {
            align-items: unset;
            flex-flow: column nowrap;
        }
        .UiGroup {
            margin-top: 0.8rem;
        }
    }

    .minor-filters-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media ${mediaQuery.isPhone} {
        .products-filter {
            white-space: nowrap;
            overflow: auto;
        }

        .minor-filters-container {
            flex-direction: column;
            align-items: stretch;
        }
    }
`;
