import { translate } from './translate';
import { CasinoGame } from './casino/games';
import { getLicence } from './licence';
import { Licence } from '@staycool/location';

export function gameHasRtpVariant(game: CasinoGame): boolean {
    if (!game) {
        return false;
    }
    switch (getLicence()) {
        case Licence.ESTONIA:
            return Boolean(game.minRtpEmta);
        case Licence.MALTA:
            return Boolean(game.minRtpMga);
        case Licence.SWEDEN:
            return Boolean(game.minRtpSga);
        default:
            return Boolean(game.minRtpCur);
    }
}

export function getRtpByLicenseCountryCode(game: CasinoGame) {
    switch (getLicence()) {
        case Licence.ESTONIA:
            return game.minRtpEmta ? parseFloat(game.minRtpEmta) : 0;
        case Licence.SWEDEN:
            return game.minRtpSga ? parseFloat(game.minRtpSga) : 0;
        case Licence.MALTA:
            return game.minRtpMga ? parseFloat(game.minRtpMga) : 0;
        case Licence.MEXICO:
            return game.minRtpMex ? parseFloat(game.minRtpMex) : 0;
    }

    return 0;
}

export function getCuracaoNameLabel() {
    let label = translate('Foreign casinos', 'casino.rtp-calculator');
    if (getLicence() === Licence.MALTA) {
        label = translate('Casinos outside eu', 'casino.rtp-calculator');
    }
    return `${label} (${translate('curacao', 'casino.rtp-calculator')})`;
}
