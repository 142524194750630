import isNaN from 'lodash/isNaN';
import React from 'react';
import ContactForm from '../../contact-form/ContactForm';

interface Props {
    id: string;
}

export default function CmsContactForm({ id }: Props) {
    if (isNaN(id)) {
        return null;
    }
    return <ContactForm eventId={parseInt(id)} />;
}
