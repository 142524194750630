import styled from 'styled-components';

export default styled.div`
    .match-comment {
        display: flex;
        align-items: center;

        max-width: 300px;
        margin-top: 0.25rem;
        padding: 0.25rem 0;

        color: var(--match-secondary-color);
        font-size: 0.7rem;
    }
`;
