import moment from 'moment';
import { translate } from '../translate';

export function dateToFromNowDaily(date, locale) {
    const fromNow = moment(date).fromNow();
    // ensure the date is displayed with today and yesterday
    return moment(date)
        .locale(locale.toLowerCase())
        .calendar(undefined, {
            sameDay: `[${translate('ui.common.today')}]`,
            nextDay: `[${translate('ui.common.tomorrow')}]`,
            nextWeek: 'dddd',
            // when the date is further away, use from-now functionality
            sameElse: () => {
                return `[${fromNow}]`;
            },
        });
}
