import React from 'react';
import { useProofOfPaymentVerificationContext } from '../../../../../../contexts/proof-of-payment-verification/ProofOfPaymentVerificationContext';
import { translate } from '../../../../../../services/translate';
import UiFormInput from '../../../../../ui/form/input/UiFormInput';
import AccountVerificationDocumentPreview from '../../../document/preview/AccountVerificationDocumentPreview';
import AccountVerificationProofOfPaymentFooter from '../../footer/AccountVerificationProofOfPaymentFooter';
import AccountVerificationProofOfPaymentHeader from '../../header/AccountVerificationProofOfPaymentHeader';

export default function AccountVerificationProofOfPaymentSteps3() {
    const { documentUpload, requiredSubmissionFields, submissionState, updateSubmissionState } =
        useProofOfPaymentVerificationContext();
    const checkboxLabelByField: Record<keyof typeof submissionState, string> = {
        areBothSidesSelected: 'Both sides of the card are selected',
        areFirst6AndLast4DigitsVisible: 'First six and last 4 digits of the card number are visible',
        areImagesReadable: 'Selected images are readable',
        areMiddleDigitsHidden: 'Middle digits are hidden',
        isBankAccountNumberVisible: 'Bank account number is visible',
        isCardHolderNameVisible: 'Cardholder name is visible',
        isCardNumberOrRecentDepositVisible: 'Card number or a recent deposit to Coolbet is visible',
        isCryptoWalletAddressVisible: 'Crypto wallet address is visible',
        isCvcCovered: 'CVC code (3 digit code on the back of the card) is covered',
        isDepositAmountVisible: 'Deposit amount is visible',
        isDepositDateVisible: 'Deposit date is visible',
        isEWalletAccountNumberOrEmailVisible: 'E-Wallet account number or email is visible',
        isExpiryDateVisible: 'Expiry date is visible',
        isFullNameVisible: 'Full name is visible',
    };
    return (
        <>
            <AccountVerificationProofOfPaymentHeader>
                <span>{translate('Review your document', 'ui.account.kyc')}</span>
            </AccountVerificationProofOfPaymentHeader>
            <AccountVerificationDocumentPreview documents={documentUpload.documents} itemHeight={512} isColumnLayout />
            {requiredSubmissionFields.map((field) => (
                <UiFormInput
                    checkbox
                    key={field}
                    label={translate(checkboxLabelByField[field], 'ui.account.kyc.pop')}
                    onValueChange={(newValue: boolean) => updateSubmissionState({ [field]: newValue })}
                    value={submissionState[field]}
                />
            ))}
            <AccountVerificationProofOfPaymentFooter nextButtonText={translate('Submit', 'ui.account')} />
        </>
    );
}
