import React from 'react';
import { HotEvent } from '../../../../../microservices/cms';
import { CategoryMatchMarket } from '../../../../../services/sports/types';
import RetailHotEventsItemSingleOdd from '../single-odd/RetailHotEventsItemSingleOdd';
import Wrapper from './styles';

interface Props {
    market: CategoryMatchMarket;
    event: HotEvent;
}

export default function RetailHotEventsItemMarketOutcomes({ market, event }: Props) {
    const [outcome1, outcome2, outcome3] = market?.outcomes || [];

    if (!outcome1 || !outcome2) {
        return null;
    }

    return (
        <Wrapper>
            <div className="hot-event-odds">
                <div className="odds">
                    <RetailHotEventsItemSingleOdd outcome={outcome1} market={market} event={event} />
                    <div className="market-name">{market.name}</div>
                    <RetailHotEventsItemSingleOdd outcome={outcome3 || outcome2} market={market} event={event} />
                </div>
            </div>
        </Wrapper>
    );
}
