import styled from 'styled-components';

import { lighten } from 'polished';

export default styled.div`
    padding: ${({ theme }) => theme.layout.gutterDouble};
    background-color: ${({ theme }) => lighten(0.05, theme.box.background)};
    box-shadow: 0 3px 15px -3px #000;
    border-bottom-left-radius: var(--ui-radius);
    border-bottom-right-radius: var(--ui-radius);
    .submitted-answers-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        strong {
            color: white;
        }
    }
    .submitted-answers-title {
        color: ${({ theme }) => theme.colors.secondary};
        font-weight: bold;
        font-size: 1.1rem;
    }
    .submitted-icon {
        color: ${({ theme }) => theme.colors.secondary};
        width: 50px;
        height: 50px;
        margin: ${({ theme }) => theme.layout.gutterDouble};
    }
    .view-answers-button {
        margin-top: ${({ theme }) => theme.layout.gutterDouble};
    }
    .quiz-resolving-message {
        color: ${({ theme }) => theme.colors.success};
        padding: ${({ theme }) => theme.layout.gutter};
        margin-bottom: ${({ theme }) => theme.layout.gutter};
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1rem;
    }
`;
