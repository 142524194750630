import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    width: 100%;

    .rtp-calculator-game-selection-comparison {
        padding-bottom: 20px;
        @media ${mediaQuery.isTablet}, ${mediaQuery.isLaptop}, ${mediaQuery.isDesktop} {
            padding-top: 20px;
        }

        &-wrapper {
            margin: auto;
            @media ${mediaQuery.isPhone} {
                width: 100%;
            }
        }
        &-content {
            display: flex;
            width: 100%;
            justify-content: center;
            &-info {
                display: inline-block;
                @media ${mediaQuery.isPhone} {
                    font-size: 0.7rem;
                    width: 33%;
                }
                &:first-child {
                    @media ${mediaQuery.isTablet}, ${mediaQuery.isLaptop}, ${mediaQuery.isDesktop} {
                        margin-right: 20px;
                    }
                }
            }
        }
    }
`;
