import React, { forwardRef, ReactNode, Ref, useImperativeHandle, useRef } from 'react';
import Wrapper from './styles';
import Slider from 'react-slick';
import { SliderSettings, SliderSlickRef } from '../../../../services/casino/slider-settings';
import { filterStyleProps } from '../../../../styles/utils';

interface Props {
    customSettings?: SliderSettings;
    children?: ReactNode;
}

function UiSliderSlick({ customSettings, children }: Props, ref: Ref<SliderSlickRef | undefined>) {
    const defaultSettings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        swipeToSlide: true,
        slidesToScroll: 1,
        slidesToShow: 9,
        cssEase: 'ease',
        variableWidth: true,
    };
    const settings = { ...defaultSettings, ...customSettings };

    const sliderRef = useRef(Slider);

    useImperativeHandle(ref, () => ({
        slickNext() {
            sliderRef.current.slickNext();
        },
        slickPrev() {
            sliderRef.current.slickPrev();
        },
        slickGoTo(index) {
            sliderRef.current.slickGoTo(index);
        },
        getListContainer(): HTMLDivElement {
            return sliderRef.current.innerSlider.list;
        },
    }));

    return (
        <Wrapper {...filterStyleProps({ isDefaultArrows: settings.arrows })}>
            <Slider {...settings} ref={sliderRef}>
                {children}
            </Slider>
        </Wrapper>
    );
}

export default forwardRef(UiSliderSlick);
