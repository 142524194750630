import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { loadHotEvents } from '../../../microservices/sbgate';
import { getRoute, isActiveRoute } from '../../../services/router';
import { stores } from '../../../stores';
import UiButton from '../../ui/button/UiButton';
import UiNavLink from '../../ui/nav-link/UiNavLink';
import classNames from 'classnames';
import { translate } from '../../../services/translate';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../services/analytics';
import UiTag from '../../ui/tag/UiTag';
import { isRetail } from '../../../services/environment';
import { FoHotEvent } from '@staycool/sbgate-types';
import SportCategoryIcon from '../category-icon/SportCategoryIcon';
import { retail } from '../../../stores/retail/retail';
import isEmpty from 'lodash/isEmpty';
import { useStore } from '../../../hooks/useStore';

interface Props {
    type?: string;
    wrap?: boolean;
    isTwoToneTheme?: boolean;
}

export default function SportHotEvents({ type, wrap, isTwoToneTheme }: Props) {
    const [hotEvents] = useStore(stores.sports.hotEvents);
    const isRetailLayout = isRetail();
    const [isRetailKeyboardVisible] = useStore(retail.retailKeyboard.isVisible);
    const hotEventsOrPlaceholders = hotEvents || ([{}, {}, {}, {}, {}] as FoHotEvent[]);
    const [isHotEventsLinksDisabled, setIsHotEventsLinksDisabled] = useState(false);

    useEffect(() => {
        loadHotEvents();
    }, []);

    useEffect(() => {
        if (isRetailKeyboardVisible) {
            setIsHotEventsLinksDisabled(true);
        } else {
            setTimeout(() => {
                setIsHotEventsLinksDisabled(false);
            }, 300);
        }
    }, [isRetailKeyboardVisible]);

    function hotEventClicked() {
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.SPORT_HOT_EVENT_CLICKED);
        stores.sports.isSportSideMenuOpen.set(false);
    }

    function getHotEvents() {
        if (isRetailLayout) {
            const hotEventsWithoutMatches = hotEventsOrPlaceholders.filter((event) => !event.match_id);
            const retailPlaceholders: FoHotEvent[] = new Array(15 - hotEventsWithoutMatches.length).fill({});
            return [...hotEventsWithoutMatches, ...retailPlaceholders];
        }
        return hotEventsOrPlaceholders;
    }

    function getUrl(event: FoHotEvent) {
        if (isRetailLayout && !event.slug && !event.match_id) {
            return '';
        }
        if (event.match_id) {
            return `${getRoute('sport')}/match/${event.match_id}`;
        }
        return `${getRoute('sport')}/${event.slug}`;
    }

    if (hotEvents && !hotEvents.length) {
        return null;
    }
    if (type === 'menu') {
        return (
            <Wrapper $type={type}>
                <div className="menu-label">{translate('Hot Events', 'ui.sportsbook')}</div>
                <div className="hot-events-list">
                    {getHotEvents().map((event, index) => (
                        <UiButton
                            className={classNames({ disabled: isHotEventsLinksDisabled, placeholder: isEmpty(event) })}
                            url={getUrl(event)}
                            type="menu"
                            isTwoToneTheme={isTwoToneTheme}
                            size={isRetailLayout ? 'large' : 'default'}
                            key={event.slug || index}
                            onClick={hotEventClicked}
                            icon={<SportCategoryIcon icon={event.sport_icon} fallbackIcon={event.league_icon} small />}
                        >
                            {event.display_name}
                        </UiButton>
                    ))}
                </div>
            </Wrapper>
        );
    } else {
        return (
            <Wrapper>
                {wrap && <div className="menu-label">{translate('Hot Events', 'ui.sportsbook')}</div>}

                <div
                    className={classNames('tabs-list', {
                        wrap: wrap,
                    })}
                >
                    {hotEventsOrPlaceholders.map((event, index) => (
                        <UiNavLink to={getUrl(event)} key={event.slug || index} onClick={hotEventClicked}>
                            <UiTag
                                selected={isActiveRoute(`${getRoute('sport')}/${event.slug}`, false)}
                                icon={<SportCategoryIcon icon={event.sport_icon} fallbackIcon={event.league_icon} />}
                            >
                                {event.display_name}
                            </UiTag>
                        </UiNavLink>
                    ))}
                </div>
            </Wrapper>
        );
    }
}
