import React, { ReactNode, useEffect, useState } from 'react';
import CoolbetHeader from '../../components/coolbet/header/CoolbetHeader';
import CoolbetFooter from '../../components/coolbet/footer/CoolbetFooter';
import CoolbetHeaderMobile from '../../components/coolbet/header/mobile/CoolbetHeaderMobile';
import GeocomplyFeedback from '../../components/geocomply/feedback/GeocomplyFeedback';
import ResponsibleGaming from '../../components/responsible-gaming/ResponsibleGaming';
import { stores } from '../../stores';
import AuthLoginModal from '../../components/auth/login/modal/AuthLoginModal';
import AuthProfileValidator from '../../components/auth/profile-validator/AuthProfileValidator';
import AccountVerificationKyc from '../../components/account/verification/kyc/AccountVerificationKyc';
import ResponsibleGamingPanel from '../../components/responsible-gaming/panel/ResponsibleGamingPanel';
import AuthInactivityModal from '../../components/auth/inactivity-modal/AuthInactivityModal';
import Wrapper from './styles';
import ScrollToTop from '../../components/scroll-to-top/ScrollToTop';
import Toast from '../../components/toast/Toast';
import CrmMessage from '../../components/crm/message/CrmMessage';
import { getRoute, isActiveRoute } from '../../services/router';
import BannerMessage from '../../components/banner-message/BannerMessage';
import AccountDataForClosedAccount from '../../components/account/data/for-closed-account/AccountDataForClosedAccount';
import AuthPayAndPlayModal from '../../components/auth/pay-and-play/modal/AuthPayAndPlayModal';
import { isMalteseRegulatedUser } from '../../services/user';
import { FEATURE, isFeatureAvailable } from '../../services/feature';
import CrmUserPreferences from '../../components/crm/user-preferences/CrmUserPreferences';
import { checkSourceType } from '../../services/mobile-app';
import RetailHeader from '../../components/retail/header/RetailHeader';
import { isBYOD, isRetail } from '../../services/environment';
import CmsMessageModal from '../../components/cms/message-modal/CmsMessageModal';
import MobileWebCheckBanner from '../../components/mobile-web-check-banner/MobileWebCheckBanner';
import UiBottomNavigation from '../../components/ui/bottom-navigation/UiBottomNavigation';
import ResponsibleGamingMultipleSessionModal from '../../components/responsible-gaming/multiple-session-modal/ResponsibleGamingMultipleSessionModal';
import { storageGet, storageSet } from '../../services/storage';
import { media } from '../../stores/media/media';
import { useStore } from '../../hooks/useStore';

interface Props {
    children: ReactNode;
}

export default function MainLayout({ children }: Props) {
    const [{ isDesktop, isLaptop, isTablet, isPhone }] = useStore(media);
    const isFooterVisibleOnMobile = isActiveRoute(getRoute('mobile-landing')) || isMalteseRegulatedUser();
    const [applicationType] = useStore(stores.applicationType);
    const [isPokerClient, setIsPokerClient] = useState(false);
    const isRetailLayout = isRetail();

    const forceHideMobileNav = window.location.search.includes('hideMobileNav') || storageGet<string>('hideMobileNav');

    if (forceHideMobileNav) {
        storageSet('hideMobileNav', true);
    }

    const displayRetailHeader = isBYOD() || (isFeatureAvailable(FEATURE.RETAIL_HEADER) && !isRetailLayout);
    const displayWebHeader = isFeatureAvailable(FEATURE.HEADER) && checkSourceType() !== 'IOS' && !forceHideMobileNav;

    useEffect(() => {
        setIsPokerClient(applicationType === 'poker-download');
    }, [applicationType]);

    return (
        <Wrapper className="main" id="main-layout">
            <ResponsibleGamingPanel />
            {displayRetailHeader ? (
                <RetailHeader simplifyHeader />
            ) : (
                displayWebHeader && (
                    <div className="header">
                        {(isDesktop || isLaptop) && <CoolbetHeader />}
                        {!isDesktop && !isLaptop && isFeatureAvailable(FEATURE.HEADER_MOBILE) && (
                            <>
                                <CoolbetHeaderMobile />
                            </>
                        )}
                    </div>
                )
            )}
            <BannerMessage />
            <ScrollToTop>
                <div className="main-container">
                    {isFeatureAvailable(FEATURE.MOBILE_WEB_APP_INSTALL) && <MobileWebCheckBanner />}
                    <ResponsibleGaming>{children}</ResponsibleGaming>
                    <CrmMessage />
                    <CrmUserPreferences />
                    <CmsMessageModal />
                </div>
            </ScrollToTop>
            {applicationType !== 'poker-download' && (isPhone || isTablet) && !forceHideMobileNav && (
                <div className="main-phone-navigation">
                    <UiBottomNavigation />
                </div>
            )}
            {isFeatureAvailable(FEATURE.LOGIN) && <AuthLoginModal />}
            <GeocomplyFeedback />
            <AuthPayAndPlayModal />
            {isFeatureAvailable(FEATURE.LOGIN) && <AuthInactivityModal />}
            {isFeatureAvailable(FEATURE.RESPONSIBLE_GAMING) && <ResponsibleGamingMultipleSessionModal />}
            {!isPokerClient && <AuthProfileValidator />}
            <AccountVerificationKyc />
            <AccountDataForClosedAccount />
            <Toast />
            {(isFooterVisibleOnMobile || !isPhone) && isFeatureAvailable(FEATURE.FOOTER) && <CoolbetFooter />}
        </Wrapper>
    );
}
