import React, { createContext, MutableRefObject, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { KycDocumentType, ProofOfAddressStatus, useDocumentUpload } from '../../services/kyc';
import { stores } from '../../stores';
import { useStore } from '../../hooks/useStore';

const submissionState = {
    documentType: '',
    isAddressEqualToAccountAddress: false,
    isAddressVisible: false,
    isDocumentNotOlderThanThreeMonths: false,
    isNameVisible: false,
};

interface InitialState {
    activeStep: number;
    documentUpload: ReturnType<typeof useDocumentUpload>;
    formContainerRef: MutableRefObject<HTMLDivElement | null>;
    reset: () => void;
    setActiveStep: (step: number) => void;
    stepCompletionState: Record<number, boolean>;
    updateSubmissionState: (state: Partial<typeof submissionState>) => void;
    submissionState;
}

const initialState = {
    activeStep: 0,
    documentUpload: {} as ReturnType<typeof useDocumentUpload>,
    formContainerRef: { current: null },
    reset: () => {},
    setActiveStep: () => {},
    stepCompletionState: {},
    updateSubmissionState: () => {},
    submissionState,
};

const ProofOfAddressVerificationContext = createContext<InitialState>(initialState);

interface Props {
    children: ReactNode;
}

export function ProofOfAddressVerificationContextProvider({ children }: Props) {
    const [activeStep, setActiveStep] = useState(initialState.activeStep);
    const [submissionState, setSubmissionState] = useState(initialState.submissionState);
    const [user] = useStore(stores.user);
    const [kycSettings] = useStore(stores.kyc.settings);
    const formContainerRef = useRef<HTMLDivElement>(null);
    const documentUpload = useDocumentUpload({
        documentType: KycDocumentType.ADDRESS,
        status: kycSettings.proofOfAddress as ProofOfAddressStatus,
        declineReason: kycSettings.kycAddressDeclineReason,
        documentLimit: 1,
    });
    const stepCompletionState = {
        1: !!submissionState.documentType,
        2: documentUpload.isDocumentSelected,
        3: [
            submissionState.isNameVisible,
            submissionState.isAddressVisible,
            !user || submissionState.isAddressEqualToAccountAddress,
            submissionState.isDocumentNotOlderThanThreeMonths,
        ].every(Boolean),
        4: !!documentUpload.uploadResultAlert,
    };

    useEffect(
        () => setSubmissionState({ ...initialState.submissionState, documentType: submissionState.documentType }),
        [documentUpload.documents],
    );

    useEffect(() => {
        if (!activeStep || !formContainerRef.current) {
            return;
        }
        formContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [activeStep]);

    return (
        <ProofOfAddressVerificationContext.Provider
            value={{
                activeStep,
                documentUpload,
                formContainerRef,
                reset() {
                    setSubmissionState(initialState.submissionState);
                    setActiveStep(initialState.activeStep);
                    documentUpload.reset();
                },
                setActiveStep,
                stepCompletionState,
                submissionState,
                updateSubmissionState(updates) {
                    setSubmissionState({ ...submissionState, ...updates });
                },
            }}
        >
            {children}
        </ProofOfAddressVerificationContext.Provider>
    );
}

export const ProofOfAddressVerificationContextConsumer = ProofOfAddressVerificationContext.Consumer;

export function useProofOfAddressVerificationContext() {
    return useContext<InitialState>(ProofOfAddressVerificationContext);
}
