import React, { useEffect } from 'react';
import Wrapper from './styles';
import UiBox from '../../ui/box/UiBox';
import { translate } from '../../../services/translate';
import moment from 'moment';
import { stores } from '../../../stores';
import { logout } from '../../../services/auth';
import UiCloseButton from '../../ui/close-button/UiCloseButton';
import { useStore } from '../../../hooks/useStore';

export default function ResponsibleGamingLoginDurationLimitExceededAlert() {
    const [loginDurationLimitReminder, setLoginDurationLimitReminder] = useStore(
        stores.responsibleGaming.loginDurationLimitReminder,
    );

    useEffect(() => {
        if (loginDurationLimitReminder) {
            logout();
        }
    }, [loginDurationLimitReminder]);

    function handleClose() {
        setLoginDurationLimitReminder(null);
    }

    if (!loginDurationLimitReminder) {
        return null;
    }

    return (
        <Wrapper open onClose={handleClose}>
            <UiBox light>
                <UiCloseButton onClick={handleClose} className="close-button" />

                {translate('Login duration has been exceeded', 'ui.responsible-gaming', {
                    durationLimitExpiryTime: moment(loginDurationLimitReminder?.limit_end).format('DD.MM.YYYY HH:mm'),
                })}
            </UiBox>
        </Wrapper>
    );
}
