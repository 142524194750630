import React, { useEffect, useState } from 'react';
import { stores } from '../../../../../stores';
import {
    getInhouseLivebetMatchStats,
    getLiveBetgeniusMatchStats,
    getMatchStats,
} from '../../../../../services/sports/inplay-helpers';
import Wrapper from './styles';
import { SPORT_ID } from '../../../../../services/sports/constants';
import isEmpty from 'lodash/isEmpty';
import { getStoreValue } from '../../../../../stores/store/utils';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    sportId: number;
    matchId: number;
    isOurLivebet?: boolean;
    isCompact?: boolean;
}

type MatchesStats = {
    matchPhaseName?: string;
    cumulativeMatchRunTimeInMinutes?: string;
    currentSavedTimeSeconds?: number;
    lastUpdatedTime?: number;
};

export default function SportMatchTimeLive({ sportId, matchId, isOurLivebet, isCompact }: Props) {
    const [matchStats, setMatchStats] = useState<MatchesStats>({});
    const [secondsPassedSinceApplicationStart] = useStore(stores.secondsPassedSinceApplicationStart);
    const { matchPhaseName, cumulativeMatchRunTimeInMinutes, currentSavedTimeSeconds, lastUpdatedTime } = matchStats;

    useEffect(() => {
        setMatchStats({});
    }, [matchId]);

    useEffect(() => {
        updateMatchLiveData(cumulativeMatchRunTimeInMinutes, currentSavedTimeSeconds, lastUpdatedTime);
    }, [secondsPassedSinceApplicationStart, matchId]);

    function updateMatchLiveData(cumulativeMatchRunTimeInMinutes, currentSavedTimeSeconds, lastUpdatedTime) {
        const matchInplayData = getStoreValue(stores.sports.inplayMatchData)[matchId];
        if (!matchInplayData) {
            return;
        }
        if (isOurLivebet) {
            setMatchStats(getInhouseLivebetMatchStats(matchInplayData, sportId));
        } else if (
            sportId === SPORT_ID.ICEHOCKEY ||
            (sportId === SPORT_ID.AMERICAN_FOOTBALL && !isEmpty(matchInplayData.custom))
        ) {
            setMatchStats(
                getMatchStats(
                    matchInplayData,
                    cumulativeMatchRunTimeInMinutes,
                    currentSavedTimeSeconds,
                    lastUpdatedTime,
                ),
            );
        } else {
            setMatchStats(getLiveBetgeniusMatchStats(matchInplayData));
        }
    }

    return (
        <Wrapper isCompact={isCompact}>
            {cumulativeMatchRunTimeInMinutes && <div className="live-time">{cumulativeMatchRunTimeInMinutes}</div>}
            {matchPhaseName && <div className="live-period">{matchPhaseName}</div>}
        </Wrapper>
    );
}
