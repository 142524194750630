import React, { useEffect, useState } from 'react';
import UiFormInput from '../../ui/form/input/UiFormInput';
import Wrapper from './styles';
import { stores } from '../../../stores';
import UiDotsLoader from '../../ui/dots-loader/UiDotsLoader';
import { getActiveCurrency } from '../../../services/currency';
import RtpCalculatorGameSelectionComparison from './comparison/RtpCalculatorGameSelectionComparison';
import { loadCasinoGames, loadCasinoProviders } from '../../../microservices/casino';
import RtpCalculatorGameSelectionSearch from './search/RtpCalculatorGameSelectionSearch';
import { CasinoGame, mapCasinoGames } from '../../../services/casino/games';
import { mapCasinoProviders } from '../../../services/casino/providers';
import { translate } from '../../../services/translate';
import { gameHasRtpVariant } from '../../../services/rtp-calculator';
import { getLicence } from '../../../services/licence';
import { FEATURE, isFeatureAvailable } from '../../../services/feature';
import { formattedAmountWithCurrency } from '../../../services/currency';
import { Licence } from '@staycool/location';
import { Currency } from '../../../services/wallet/types';
import { useStore } from '../../../hooks/useStore';

interface Props {
    preSelectedGameIds?: string[];
}

interface CalculatorValues {
    betSizes: number[];
    spinsPerMinute: number;
    startingBalances: number[];
}

export default function RtpCalculatorGameSelection({ preSelectedGameIds }: Props) {
    const [games] = useStore(stores.casino.allGames);

    const [calculatorValues, setCalculatorValues] = useState<CalculatorValues>({
        betSizes: [1, 2, 3],
        startingBalances: [100, 200, 300],
        spinsPerMinute: 30,
    });

    const valuesMultiplierByCurrency = {
        [Currency.NOK]: 10,
        [Currency.SEK]: 10,
        [Currency.CLP]: 1000,
        [Currency.PEN]: 4,
        [Currency.PYG]: 5000,
    };

    const [selectedGameId, setSelectedGameId] = useState(207);
    const [gamesWithRtpVariant, setGamesWithRtpVariant] = useState<CasinoGame[]>([]);
    const [selectedBetSize, setSelectedBetSize] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedStartingBalance, setSelectedStartingBalance] = useState(0);

    useEffect(() => {
        setCalculatorValuesByCurrency(getActiveCurrency());
        setSelectedStartingBalance(calculatorValues.startingBalances[0]);
        setSelectedBetSize(calculatorValues.betSizes[0]);

        Promise.all([loadCasinoGames(), loadCasinoProviders()])
            .then(mapCasinoGames)
            .then(mapCasinoProviders)
            .then(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        if (games) {
            setGamesWithRtpVariant(
                games.filter((game) => {
                    return gameHasRtpVariant(game);
                }),
            );
        }
    }, [games]);

    function setCalculatorValuesByCurrency(currency: string) {
        const newCalculatorValues = { ...calculatorValues } as CalculatorValues;
        const valuesMultiplier = valuesMultiplierByCurrency[currency];

        if (getLicence() === Licence.SWEDEN) {
            newCalculatorValues.spinsPerMinute = 20;
        }

        if (valuesMultiplier) {
            newCalculatorValues.betSizes.forEach((betSize, index) => {
                newCalculatorValues.betSizes[index] = betSize * valuesMultiplier;
            });
            newCalculatorValues.startingBalances.forEach((startingBalances, index) => {
                newCalculatorValues.startingBalances[index] = startingBalances * valuesMultiplier;
            });
        }

        setCalculatorValues(newCalculatorValues);
    }

    if (!isFeatureAvailable(FEATURE.RTP_CALCULATOR)) {
        return null;
    }

    if (isLoading) {
        return <UiDotsLoader />;
    }

    return (
        <Wrapper>
            <div className="rtp-calculator-game-selection-label">
                <div className="rtp-calculator-game-selection-label-text">
                    {translate('Best rtp casino', 'casino.rtp-calculator')}
                </div>
                <p>{translate('Compare rtp with other casinos', 'casino.rtp-calculator')}</p>
            </div>
            <div className="rtp-calculator-game-selection-wrapper">
                <div className="rtp-calculator-game-selection-content">
                    <div className="rtp-calculator-game-selection-comparison">
                        <div className="rtp-calculator-game-selection-element">
                            <div className="rtp-calculator-game-selection-element-label">
                                {translate('Game Selection', 'casino.rtp-calculator')}:
                            </div>
                            <RtpCalculatorGameSelectionSearch
                                preSelectedGameIds={preSelectedGameIds}
                                onGameRtpInfoSelect={(value) => setSelectedGameId(value)}
                                gamesWithRtpVariant={gamesWithRtpVariant}
                            />
                        </div>
                        <RtpCalculatorGameSelectionComparison
                            gameId={selectedGameId}
                            gamesWithRtpVariant={gamesWithRtpVariant}
                            startingBalance={selectedStartingBalance}
                            betSize={selectedBetSize}
                            spinsPerMinute={calculatorValues.spinsPerMinute}
                        />
                        <div className="rtp-calculator-game-selection-play-options">
                            <div className="rtp-calculator-game-selection-element">
                                <UiFormInput
                                    name="bet-amount"
                                    label={`${translate('Bet Amount', 'casino.rtp-calculator')}:`}
                                    className="rtp-calculator-game-selection-select"
                                    select
                                    onValueChange={setSelectedBetSize}
                                >
                                    {calculatorValues.betSizes.map((betSize) => (
                                        <option key={betSize} value={betSize}>
                                            {formattedAmountWithCurrency(betSize)}
                                        </option>
                                    ))}
                                </UiFormInput>
                            </div>
                            <div className="rtp-calculator-game-selection-element">
                                <UiFormInput
                                    name="starting-balance"
                                    label={`${translate('Starting Balance', 'casino.rtp-calculator')}:`}
                                    className="rtp-calculator-game-selection-select"
                                    select
                                    onValueChange={setSelectedStartingBalance}
                                >
                                    {calculatorValues.startingBalances.map((startingBalance) => (
                                        <option key={startingBalance} value={startingBalance}>
                                            {formattedAmountWithCurrency(startingBalance)}
                                        </option>
                                    ))}
                                </UiFormInput>
                            </div>
                        </div>
                    </div>
                    <div className="rtp-calculator-game-selection-info">
                        <p>{translate('This calculator', 'casino.rtp-calculator')}</p>
                        <p>{translate('coolbet rtp number', 'casino.rtp-calculator')}</p>
                        <p>{translate('The result in the RTP Calculator', 'casino.rtp-calculator')}</p>
                        <p>{translate('no responsibility', 'casino.rtp-calculator')}</p>
                        <p>
                            {translate('one spin takes %1 sec', 'casino.rtp-calculator', [
                                60 / calculatorValues.spinsPerMinute,
                            ])}
                        </p>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
