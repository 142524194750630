import { SyntheticEvent } from 'react';
import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { breakpoints } from '../../styles/utils';
import { COUNTRY } from '../country';
import { getActiveCurrency } from '../currency';
import { getDeviceCategory } from '../device';
import { getLicence, LICENCE_NAME_BY_LICENCE_CODE } from '../licence';
import { logger } from '../logger';
import { translate } from '../translate';

// Please don't add here any casino object specific code. We have separate typescript file for every type of casino object (game, category, feature, provider etc).

export function getDefaultQueryParams() {
    let country = getStoreValue(stores.ipCountry);
    const user = getStoreValue(stores.user);

    const APP_STORE_TEST_USERS = ['CndWLHdq', 'HKYhQVWJ'];
    if (user && APP_STORE_TEST_USERS.includes(user.id)) {
        country = COUNTRY.MEXICO;
    }

    return {
        country,
        userCountry: user?.country || country,
        province: undefined,
        licence: getLicence(),
        device: getDeviceCategory().toLowerCase(),
        currency: getActiveCurrency(),
        language: getStoreValue(stores.language),
    };
}

document.addEventListener('webkitfullscreenchange', fullScreenChange, false);
document.addEventListener('mozfullscreenchange', fullScreenChange, false);
document.addEventListener('fullscreenchange', fullScreenChange, false);
document.addEventListener('MSFullscreenChange', fullScreenChange, false);

export const descriptionTranslations = {
    live: {
        Blackjack: (name) => translate('blackjack.live', 'casino.meta.description', { name }),
        Roulette: (name) => translate('roulette.live', 'casino.meta.description', { name }),
        Baccarat: (name) => translate('baccarat.live', 'casino.meta.description', { name }),
        Poker: (name) => translate('poker.live', 'casino.meta.description', { name }),
        Dice: (name) => translate('dice.live', 'casino.meta.description', { name }),
        Gameshow: (name) => translate('gameshow.live', 'casino.meta.description', { name }),
    },
    common: {
        Slots: (name) => translate('slots', 'casino.meta.description', { name }),
        Blackjack: (name) => translate('blackjack', 'casino.meta.description', { name }),
        Roulette: (name) => translate('roulette', 'casino.meta.description', { name }),
        Baccarat: (name) => translate('baccarat', 'casino.meta.description', { name }),
        Poker: (name) => translate('poker', 'casino.meta.description', { name }),
        'Video Poker': (name) => translate('video-poker', 'casino.meta.description', { name }),
        'Video Bingo': (name) => translate('video-bingo', 'casino.meta.description', { name }),
        Other: (name) => translate('other', 'casino.meta.description', { name }),
    },
};

export function goFullScreen(element?: HTMLElement | SyntheticEvent) {
    let gameLayout: HTMLElement | null;

    if (element instanceof HTMLElement) {
        gameLayout = element;
    } else {
        gameLayout = document.getElementById('casino-game-desktop-layout-game');
    }

    if (getStoreValue(stores.responsibleGaming.isResponsibleGamingPanelVisible)) {
        gameLayout = document.documentElement;
    }

    if (!gameLayout) {
        return;
    }
    const options = { navigationUI: 'hide' } as FullscreenOptions;

    try {
        gameLayout.requestFullscreen(options).catch(() => {});
    } catch (error) {
        logger.error('CasinoCasinoService', 'goFullScreen', error);
    }
}

export function goSemiFullScreen() {
    try {
        goFullScreen(document.documentElement);
    } catch (error) {
        logger.error('CasinoCasinoService', 'goSemiFullScreen', error);
    }
}

export function exitFullScreen() {
    if (getStoreValue(stores.casino.isFullScreen)) {
        try {
            document?.exitFullscreen().catch(() => {});
        } catch (error) {
            logger.error('CasinoCasinoService', 'exitFullScreen', error);
        }
    }
}

function fullScreenChange() {
    if (
        !document.webkitIsFullScreen &&
        !document.mozFullScreenElement &&
        !document.msFullscreenElement &&
        !document.fullscreenElement
    ) {
        stores.casino.isFullScreen.set(false);
    } else {
        stores.casino.isFullScreen.set(true);
    }
}

export function resizeGameContainer({ minRatio, maxRatio }) {
    releaseGameContainerSize();
    const gameSize = getGameObjectSize();

    if (!gameSize) {
        return;
    }

    const currentRatio = gameSize.height / gameSize.width;

    let width;
    let height;

    if (currentRatio >= minRatio && currentRatio <= maxRatio) {
        ({ width, height } = { width: gameSize.width, height: gameSize.height });
    } else if (currentRatio < minRatio) {
        ({ width, height } = { width: gameSize.height / minRatio, height: gameSize.height });
    } else {
        ({ width, height } = { width: gameSize.width, height: gameSize.width * maxRatio });
    }
    stores.casino.gameContainerSize.set({ height: parseInt(height), width: parseInt(width) });
    setGameContainerSize(parseInt(width), parseInt(height));
}

export function getIFrameHeight(): number | null {
    const isRGPanelVisible = getStoreValue(stores.responsibleGaming.isResponsibleGamingPanelVisible);

    const responsibleGamingPanelHeight = isRGPanelVisible ? 40 : 0;
    const topBarHeight = 30 + responsibleGamingPanelHeight;
    const bottomBarHeight = 64;

    if (breakpoints.tabletMedium > window.innerWidth) {
        const isLandscapeMode = window.innerWidth > window.innerHeight;
        const height = window.innerHeight - topBarHeight;
        return isLandscapeMode ? height : height - bottomBarHeight;
    }

    if (breakpoints.tabletMax >= window.innerWidth && breakpoints.phoneMax + 1 <= window.innerWidth) {
        const height = window.innerHeight - bottomBarHeight - topBarHeight;

        return height;
    }
    return null;
}

export function releaseGameContainerSize() {
    const elem = document.getElementById('casino-game-desktop-layout-game');

    if (!elem) {
        return;
    }

    elem.style.width = '100%';
    elem.style.height = '100%';
    elem.style.flex = '1 0 0';
}

function setGameContainerSize(width, height) {
    const elem = document.getElementById('casino-game-desktop-layout-game');

    if (!elem) {
        return;
    }

    elem.style.width = `${width}px`;
    elem.style.height = `${height}px`;
    elem.style.flex = `unset`;
}

function getGameObjectSize() {
    return document.getElementById('gameObject')?.getBoundingClientRect();
}

export function getGeneralErrorMessage() {
    return `${translate('Could not start casino game.', 'ui.casino')}${translate(
        'Please try again later.',
        'ui.common',
    )}`;
}

export function getFromConfigByLicenseCountry(configRoot) {
    return configRoot[LICENCE_NAME_BY_LICENCE_CODE[getLicence()]] || configRoot.MALTA;
}
