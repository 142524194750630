import styled from 'styled-components';

export default styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    .badge {
        position: relative;
        background-color: ${({ theme }) => theme.colors.info};
        padding: 5px 15px;
        border-radius: 30px;
        font-size: 0.8rem;
    }

    .badge::before {
        position: absolute;
        content: '';
        top: calc(100% - 4px);
        left: 30%;
        right: 0;
        margin: auto;
        transform: rotate(45deg);
        z-index: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 5px 0 0;
        border-color: ${({ theme }) => theme.colors.info} transparent transparent transparent;
    }
`;
