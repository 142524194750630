import React, { ReactElement, ReactNode } from 'react';
import { produce } from 'immer';

export function ChangableContextProvider<T>({
    Context,
    initialValues,
    children,
}: {
    Context: React.Context<T>;
    initialValues?: any;
    children: React.ReactNode;
}) {
    const [value, setContext] = React.useState({ ...(initialValues || {}) });
    const newValue = (x) => produce(value, x instanceof Function ? x : () => ({ ...value, ...x }));
    return (
        <Context.Provider
            value={{
                ...value,
                setContext: (x) => setContext(newValue(x)),
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function withContextProvider<T>(
    Component: any,
    context: React.Context<T & { setContext?: (newValues: Partial<T> | ((x: Partial<T>) => void)) => void }>,
    initialValues?: Partial<T>,
) {
    return (props) => (
        <ChangableContextProvider Context={context} initialValues={initialValues}>
            <Component {...props} />
        </ChangableContextProvider>
    );
}

type ProviderType = ({ children }: { children: ReactNode | ReactNode[] }) => ReactElement;

export function withProvider<T>(Provider: ProviderType, Component: any) {
    return (props: T) => {
        return (
            <Provider>
                <Component {...props} />
            </Provider>
        );
    };
}
