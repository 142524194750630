import styled from 'styled-components';

export default styled.div`
    .share-bet {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
    }

    .share-modal {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .generated-image {
            margin-bottom: 0.8rem;
        }
    }

    .share-button {
        position: relative;
        display: flex;
        align-items: center;
        gap: var(--spacing-8);
        justify-content: center;

        font-size: 0.75rem;
        white-space: nowrap;
        font-weight: var(--font-weight-medium);
        cursor: pointer;
        outline: none;
        user-select: none;

        background-clip: padding-box;
        background-color: rgb(46, 100, 147);
        color: var(--font-color-primary);
        height: 40px;
        padding: 0 0.8rem;
        border-radius: var(--ui-radius);
        width: 100%;
        &:hover {
            background-color: rgb(47, 102, 150);
        }
    }

    .alert {
        text-align: center;
        margin-top: 1rem;
    }
`;
