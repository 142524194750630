import React from 'react';
import {
    getBetslipStakeComboOrSingles,
    isBetslipButtonDisabled,
    PlaceBetHandle,
} from '../../../../../services/sports/betslip';
import { BET_TYPE } from '../../../../../services/sports/constants';
import { hasBetslipManualAcceptanceError } from '../../../../../services/sports/manual-acceptance-helpers';
import { calculateSystemExtraTotalStake, SystemBets } from '../../../../../services/sports/system-bet-helpers';
import { translate } from '../../../../../services/translate';
import { formattedAmountWithCurrency } from '../../../../../services/currency';
import { stores } from '../../../../../stores';
import UiButton from '../../../../ui/button/UiButton';
import Wrapper from './styles';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    onPlaceBet: PlaceBetHandle;
    systemBets?: SystemBets;
    totalStake: number;
}

export default function SportBetslipButtonAndErrorsManualAcceptance({ onPlaceBet, systemBets, totalStake }: Props) {
    const [userState] = useStore(stores.sports.betSlipUserState);
    const [betSlipPlacingState] = useStore(stores.sports.betSlipPlacingState);
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);

    const { betType, MAStakeByMarketId } = userState;
    const { isLoading } = betSlipPlacingState;
    const manualAcceptanceStake =
        betType === BET_TYPE.SYSTEM
            ? calculateSystemExtraTotalStake(systemBets)
            : getBetslipStakeComboOrSingles(betType, MAStakeByMarketId, betSlipMarketIdToOutcomeId);

    const isManualAcceptanceError = hasBetslipManualAcceptanceError();
    const normalStakeAllowed = totalStake > 0 && isManualAcceptanceError;

    return (
        <Wrapper>
            <UiButton
                color="primary"
                block
                size="large"
                isLoading={isLoading}
                disabled={isLoading || isBetslipButtonDisabled() || manualAcceptanceStake <= 0}
                onClick={() => onPlaceBet(true)}
            >
                <div>{translate('Manual Acceptance', 'ui.betslip')}</div>
                <div className="extra-text">
                    {normalStakeAllowed
                        ? translate('Place the bet of %1 and apply for the remaining %2.', 'ui.sportsbook', [
                              `${formattedAmountWithCurrency(totalStake)}`,
                              `${formattedAmountWithCurrency(manualAcceptanceStake)}`,
                          ])
                        : translate('Request to place a stake of %1.', 'ui.sportsbook', [
                              `${formattedAmountWithCurrency(manualAcceptanceStake)}`,
                          ])}
                </div>
            </UiButton>
        </Wrapper>
    );
}
