import React from 'react';
import Wrapper from './styles';
import { openMessageByUser } from '../../../../services/crm';
import TimeAgo from 'react-timeago/lib/index';
import ru from 'react-timeago/lib/language-strings/ru';
import et from 'react-timeago/lib/language-strings/et';
import fi from 'react-timeago/lib/language-strings/fi';
import da from 'react-timeago/lib/language-strings/da';
import is from 'react-timeago/lib/language-strings/is';
import sv from 'react-timeago/lib/language-strings/sv';
import es from 'react-timeago/lib/language-strings/es';
import en from 'react-timeago/lib/language-strings/en-short';
import ca from 'react-timeago/lib/language-strings/en';
import on from 'react-timeago/lib/language-strings/en';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import { stores } from '../../../../stores';
import { translate } from '../../../../services/translate';
import moment from 'moment';
import { OnsiteMessage } from '../../../../microservices/crm';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    message: OnsiteMessage;
    className?: string;
}

export default function CrmInboxMessage({ message, className }: Props) {
    const [language] = useStore(stores.language);

    const languageDict = {
        et,
        ru,
        ca,
        eu: en,
        fi,
        is,
        cl: es,
        en,
        sv,
        da,
        pe: es,
        ec: es,
        py: es,
        on,
        mx: es,
    };

    const formatter = buildFormatter(languageDict[language]);

    const messageDescription = message.description;
    const noImagesDescription = messageDescription
        ? messageDescription.replace(/<img[^>]*>/g, '')
        : message.mascotMessage;
    const isExpired = message.expiresAt && new Date(message.expiresAt) < new Date();
    const timeNow = moment();
    const timeToTest = moment(message.createdAt);
    const moreThenTwoDaysPassed = timeNow.diff(timeToTest, 'days') > 2;

    return (
        <Wrapper onClick={() => openMessageByUser(message)} className={className}>
            {isExpired && <div className="crm-status expired">{translate('Expired', 'ui.common')}</div>}
            {!isExpired && !message.seenAt && <div className="crm-status">{translate('New', 'ui.common')}</div>}

            <div className="crm-inbox-message-text">
                <div className="message-time">
                    {!moreThenTwoDaysPassed && <TimeAgo date={message.createdAt} formatter={formatter} />}
                    {moreThenTwoDaysPassed && moment(message.createdAt).format('DD.MM.YYYY')}
                </div>
                <h1>{message.title}</h1>
                <p dangerouslySetInnerHTML={{ __html: noImagesDescription }} />
            </div>
        </Wrapper>
    );
}
