import { useState } from 'react';

export function usePreventClicksOnSwipe(slideIndex, sliderRef) {
    const SWIPE_DELTA = 3;
    const [mousePosition, setMousePosition] = useState({ clientX: 0, clientY: 0 });

    function handleSmallSlide(deltaX: number) {
        if (deltaX > 60 && deltaX < 300) {
            sliderRef.current.slickGoTo((slideIndex as number) + 1);
        }
        if (deltaX < -60) {
            sliderRef.current.slickGoTo(slideIndex - 1);
        }
    }

    function onMouseDownCapture(e) {
        setMousePosition({ clientX: e.clientX, clientY: e.clientY });
        const deltaX = Math.abs(mousePosition.clientX - e.clientX);
        const deltaY = Math.abs(mousePosition.clientY - e.clientY);

        if (e.target.tagName !== 'path' && e.target.tagName !== 'svg' && deltaX > SWIPE_DELTA && deltaY > SWIPE_DELTA) {
            e.preventDefault();
        }
    }

    function onClickCapture(e) {
        const deltaX = mousePosition.clientX - e.clientX;
        const deltaY = Math.abs(mousePosition.clientY - e.clientY);

        if (Math.abs(deltaX) > SWIPE_DELTA || deltaY > SWIPE_DELTA) {
            e.stopPropagation();
            e.preventDefault();
        }

        handleSmallSlide(deltaX);
    }

    return {
        onMouseDownCapture,
        onClickCapture,
    };
}
