import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    max-width: ${(props) => (props.isRetailLayout ? '' : '340px')};
    margin: 0 auto;
    @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
        padding-bottom: 80px;
    }
`;
