import React, { useEffect } from 'react';
import Wrapper from './styles';
import isBoolean from 'lodash/isBoolean';
import { PaybackBoosterStatus, PaybackBoosterWrapperState } from '../../../../services/casino/payback-booster';
import { stores } from '../../../../stores';
import { translate } from '../../../../services/translate';
import { isFeatureAvailable, FEATURE } from '../../../../services/feature';
import CasinoPaybackBoosterThumbnailPaybackBooster from './payback-booster/CasinoPaybackBoosterThumbnailPaybackBooster';
import UiAlert from '../../../ui/alert/UiAlert';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

export default function CasinoPaybackBoosterThumbnail() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [isPaybackBoosterCompactView, setIsPaybackBoosterCompactView] = useStore(
        stores.casino.isPaybackBoosterCompactView,
    );
    const [{ isPhone }] = useStore(media);
    const [isPaybackBoosterWidgetVisible, setIsPaybackBoosterWidgetVisible] = useStore(
        stores.casino.isPaybackBoosterWidgetVisible,
    );
    const [isPaybackBoosterWrapperVisible, setIsPaybackBoosterWrapperVisible] = useStore(
        stores.casino.isPaybackBoosterWrapperVisible,
    );

    const [paybackBooster] = useStore(stores.paybackBooster.paybackBooster);

    useEffect(() => {
        if (!isBoolean(isPaybackBoosterCompactView)) {
            setIsPaybackBoosterCompactView(isPhone);
        }
    }, [isPhone]);

    useEffect(() => {
        if (
            (!paybackBooster ||
                ![PaybackBoosterStatus.active, PaybackBoosterStatus.available].includes(paybackBooster.status)) &&
            isPaybackBoosterWidgetVisible
        ) {
            setIsPaybackBoosterWidgetVisible(false);
        } else if (paybackBooster && !isPaybackBoosterWidgetVisible) {
            setIsPaybackBoosterWidgetVisible(true);

            if (
                paybackBooster &&
                isPaybackBoosterWrapperVisible !== PaybackBoosterWrapperState.wrapper &&
                isFeatureAvailable(FEATURE.CASINO_PAYBACK_BOOSTER)
            ) {
                setIsPaybackBoosterWrapperVisible(PaybackBoosterWrapperState.fadeIn);
                setTimeout(() => {
                    setIsPaybackBoosterWrapperVisible(PaybackBoosterWrapperState.wrapper);
                }, 3000);
            }
        }
    }, [paybackBooster]);

    if (!(isAuthenticated && isFeatureAvailable(FEATURE.CASINO_PAYBACK_BOOSTER) && isPaybackBoosterWidgetVisible)) {
        return null;
    }

    return (
        <Wrapper
            isMobileDevice={isPhone}
            paybackBooster={paybackBooster}
            isCompact={isPaybackBoosterCompactView}
            className="casino-payback-booster-thumbnail"
        >
            <div className="booster-label">{translate('booster available', 'casino.lobby')}</div>
            {paybackBooster && (
                <CasinoPaybackBoosterThumbnailPaybackBooster
                    paybackBooster={paybackBooster}
                    forceFullView={false}
                    isCompact={isPaybackBoosterCompactView}
                />
            )}
            {paybackBooster && paybackBooster.casinoLocked && (
                <div className="booster-alert">
                    <UiAlert failure={true}>
                        {translate(
                            'Your bets made with an active Casino Bonus will not count towards the Payback Booster program',
                            'casino.payback-booster',
                        )}
                    </UiAlert>
                </div>
            )}
        </Wrapper>
    );
}
