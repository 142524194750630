import styled from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

export default styled.div`
    .side-buttons {
        display: flex;
        align-items: center;
        > *:not(:last-of-type) {
            margin-right: ${({ theme }) => theme.layout.gutterHalf};
        }

        > * {
            @media ${mediaQuery.isPhone} {
                flex: 1;
            }
        }
    }
    .scoreboards {
        display: flex;
        align-items: stretch;
        justify-content: center;

        margin-top: 0.4rem;
        @media ${mediaQuery.isPhone} {
            flex-direction: column;
        }
    }
`;
