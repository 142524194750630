import React from 'react';
import Wrapper from './styles';
import RtpCalculatorGameSelection from './game-selection/RtpCalculatorGameSelection';

interface Props {
    preSelectedGameIds?: string[];
}

export default function RtpCalculator({ preSelectedGameIds }: Props) {
    return (
        <Wrapper>
            <RtpCalculatorGameSelection preSelectedGameIds={preSelectedGameIds} />
        </Wrapper>
    );
}
