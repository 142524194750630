import React from 'react';
import Wrapper from './styles';
import { stores } from '../../../../stores';
import classNames from 'classnames';
import { translate } from '../../../../services/translate';
import Svg from '../../../svg/Svg';
import { CasinoGame, CasinoGameMetaData } from '../../../../services/casino/games';
import { hasCasinoJackpotDisplay } from '../../../../services/casino/jackpots';
import { Tooltip } from '@mui/material';
import CasinoThumbnailLabelsAttributes from './attributes/CasinoThumbnailLabelsAttributes';
import CasinoThumbnailLabelsProvider from './provider/CasinoThumbnailLabelsProvider';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    game: CasinoGame;
    hiddenGameLabels?: number[];
    isSearchResult?: boolean;
    showProvider?: boolean;
    showAttributes?: boolean;
    hideAllLabels?: boolean;
}

export default function CasinoThumbnailLabels({
    game,
    hiddenGameLabels = [],
    isSearchResult,
    showProvider = true,
    showAttributes = true,
    hideAllLabels = false,
}: Props) {
    const [jackpotsDisplayByGameId] = useStore(stores.casino.jackpotsDisplayByGameId);
    const [gamesMetaDataById] = useStore(stores.casino.gamesMetaDataById);
    const [lobbySettings] = useStore(stores.casino.lobbySettings);
    const isJackpotActive = hasCasinoJackpotDisplay(game);
    const isAttributesActive = Boolean(game.labels.length);
    const showBetRange = game.gameType === 'live' && game.minBet && game.maxBet;

    function getCasinoGameBetRangeDisplay(game: CasinoGame): string {
        let minBet = game.minBet;
        let maxBet = game.maxBet;
        const metaData: CasinoGameMetaData = gamesMetaDataById[game.id];
        if (metaData) {
            minBet = metaData.minBet;
            maxBet = metaData.maxBet;
        }

        return (
            (minBet && maxBet && `${formattedAmountWithCurrency(minBet)} - ${formattedAmountWithCurrency(maxBet)}`) ||
            ''
        );
    }

    return (
        <Wrapper>
            {!hideAllLabels && showProvider && !isSearchResult && (
                <div className="casino-thumbnail-top-container">
                    <CasinoThumbnailLabelsProvider game={game} />
                </div>
            )}
            <div
                className={classNames('casino-thumbnail-labels-top', {
                    stakes: showBetRange,
                    hidden: !game.isPublic,
                })}
            >
                {!game.isPublic && (
                    <div className="casino-thumbnail-hidden">
                        <Svg className="casino-thumbnail-hidden-icon" icon="visibility-off" /> Unpublished
                    </div>
                )}
                <div className="casino-thumbnail-labels-bottom-container">
                    {showBetRange && !hideAllLabels && (
                        <Tooltip
                            title={
                                <div style={{ whiteSpace: 'pre-line', fontSize: '11px' }}>
                                    {translate('Bet size range', 'casino.thumbnail')}
                                </div>
                            }
                            enterDelay={200}
                            enterTouchDelay={0}
                        >
                            <div className="casino-thumbnail-label casino-thumbnail-labels-stakes">
                                {getCasinoGameBetRangeDisplay(game)}
                            </div>
                        </Tooltip>
                    )}
                </div>

                {!hideAllLabels && (
                    <div className="casino-thumbnail-labels-top-container">
                        <div className="casino-thumbnail-labels">
                            {showAttributes && lobbySettings.labels && isAttributesActive && !isJackpotActive && (
                                <CasinoThumbnailLabelsAttributes
                                    attributes={game.labels}
                                    hiddenGameLabels={hiddenGameLabels}
                                />
                            )}

                            {isJackpotActive && (
                                <div className={classNames('casino-thumbnail-labels-jackpot')}>
                                    {jackpotsDisplayByGameId[game.id]}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </Wrapper>
    );
}
