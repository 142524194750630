import { CasinoGame } from '../services/casino/games';
import { PaybackBooster, PaybackBoosterProgramGames } from '../services/casino/payback-booster';
import { createStores } from './store/utils';

export const paybackBooster = createStores({
    paybackBooster: {} as PaybackBooster | undefined,
    games: [] as CasinoGame[],
    programGames: [] as PaybackBoosterProgramGames[],
    isExpired: false,
});
