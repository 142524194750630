import pick from 'lodash/pick';
import {
    getSportsUserSettings,
    SportsBetslipInputFormat,
    SportsLayout,
    SportsOddsFormat,
    SportsUserSettings,
    updateSportsUserSettings,
} from '../../microservices/sbgate';
import { stores, useStoreWithSelector } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { COUNTRY } from '../country';
import { isB2B } from '../environment';
import { LANGUAGE } from '../language';
import { logger } from '../logger';
import { storageGet, storageSet, useStorage } from '../storage';
import { getUserCountry } from '../user';
import { useStore } from '../../hooks/useStore';

export const SPORTS_USER_SETTINGS_KEY = 'sportsUserSettings';

export type LocalSportsUserSettings = Omit<SportsUserSettings, 'user_id'>;

interface UseSportsUserSettingsProps {
    sportCategoryId?: number;
}

export type SportSetting = 'AMERICAN_LAYOUT' | 'COMPACT_VIEW' | 'ODDS_TYPE' | 'TIME_FORMAT' | 'WAGER_TO_WIN';

export const useSportsUserSettings = ({ sportCategoryId }: UseSportsUserSettingsProps = {}) => {
    const [userSettings, setUserSettings] = useStorage(stores.sports.userSettings, SPORTS_USER_SETTINGS_KEY);
    const [americanSportsCategories] = useStore(stores.sports.americanSportCategoryIds);
    const layout = userSettings?.layout || SportsLayout.EUROPEAN;

    const betslipInputFormat = userSettings?.betslip_input_format || SportsBetslipInputFormat.EUROPEAN;
    const isAmericanBetslipInputFormat = userSettings?.betslip_input_format === SportsBetslipInputFormat.AMERICAN;
    const isAmericanOddsFormat = userSettings?.odds_format === SportsOddsFormat.AMERICAN;

    let isAmericanLayout = layout === SportsLayout.AMERICAN;
    const isPreferringShortNames = layout === SportsLayout.AMERICAN;
    if (sportCategoryId && americanSportsCategories) {
        isAmericanLayout = isAmericanLayout && americanSportsCategories.includes(sportCategoryId);
    }
    const updateUserSettings = async (settings: Partial<LocalSportsUserSettings>) => {
        const newSettings = { ...(userSettings as SportsUserSettings), ...settings };

        setUserSettings(newSettings);
        if (getStoreValue(stores.isAuthenticated)) {
            await updateRemoteSportsUserSettings(newSettings);
        }
    };

    const toggleLayout = async () => {
        await updateUserSettings({
            layout: layout === SportsLayout.AMERICAN ? SportsLayout.EUROPEAN : SportsLayout.AMERICAN,
        });
    };

    const toggleBetslipInputFormat = async () => {
        await updateUserSettings({
            betslip_input_format:
                betslipInputFormat === SportsBetslipInputFormat.AMERICAN
                    ? SportsBetslipInputFormat.EUROPEAN
                    : SportsBetslipInputFormat.AMERICAN,
        });
    };

    return {
        isAmericanBetslipInputFormat,
        isAmericanOddsFormat,
        isAmericanLayout,
        updateUserSettings,
        toggleLayout,
        toggleBetslipInputFormat,
        betslipInputFormat,
        layout,
        oddsFormat: userSettings?.odds_format || SportsOddsFormat.DECIMAL,
        isAmericanTimeFormat: userSettings?.is_american_time_format,
        isPreferringShortNames,
    };
};

export const useOddsFormat = () =>
    useStoreWithSelector(stores.sports.userSettings, (settings) => settings?.odds_format);

export const getOddsFormat = () => getStoreValue(stores.sports.userSettings)?.odds_format || SportsOddsFormat.DECIMAL;

export const getSportsLayout = () => getStoreValue(stores.sports.userSettings)?.layout || SportsLayout.EUROPEAN;

const isUserFromNorthAmerica = () => {
    const ipCountry = getStoreValue(stores.ipCountry);
    const userCountry = getUserCountry();
    const language = getStoreValue(stores.language);
    return (
        ipCountry === COUNTRY.CANADA ||
        ipCountry === COUNTRY.USA ||
        userCountry === COUNTRY.CANADA ||
        language === LANGUAGE.CANADIAN
    );
};

const updateRemoteSportsUserSettings = async (settings: LocalSportsUserSettings) => {
    try {
        await updateSportsUserSettings(settings);
    } catch (error) {
        logger.error('SportsUserSettingsService', 'updateSportsUserSettings', error);
    }
};

export const loadRemoteSportsUserSettings = async () => {
    try {
        const settings = await getSportsUserSettings();
        setLocalUserSettings(
            pick(settings, 'is_american_time_format', 'layout', 'odds_format', 'betslip_input_format'),
        );
    } catch (error) {}

    return loadLocalSportsUserSettings();
};

export const loadLocalSportsUserSettings = () => {
    const isAmericanTimeFormat = isB2B()
        ? true
        : getStoreValue(stores.sports.userSettings)?.is_american_time_format || false;
    const layout = isB2B() || isUserFromNorthAmerica() ? SportsLayout.AMERICAN : SportsLayout.EUROPEAN;
    const oddsFormat = isB2B() || isUserFromNorthAmerica() ? SportsOddsFormat.AMERICAN : SportsOddsFormat.DECIMAL;
    const betslipInputFormat =
        isB2B() || isUserFromNorthAmerica() ? SportsBetslipInputFormat.AMERICAN : SportsBetslipInputFormat.EUROPEAN;
    const settings: LocalSportsUserSettings = {
        layout,
        is_american_time_format: isAmericanTimeFormat,
        odds_format: oddsFormat,
        betslip_input_format: betslipInputFormat,
        ...(getLocalUserSettings() as Partial<LocalSportsUserSettings>),
    };
    stores.sports.userSettings.set(settings);
};

const getLocalUserSettings = () => storageGet<LocalSportsUserSettings>(SPORTS_USER_SETTINGS_KEY);
const setLocalUserSettings = (settings: LocalSportsUserSettings) => storageSet(SPORTS_USER_SETTINGS_KEY, settings);
