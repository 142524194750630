import React, { useEffect, useState } from 'react';
import { getSnippet } from '../../microservices/l10n';
import { translate } from '../../services/translate';
import UiAlert from '../ui/alert/UiAlert';
import { logger } from '../../services/logger';
import { useHeaderElementsByContent, useImmediate } from '../../services/hooks';
import { getHeaderElementsFromHtml } from '../../services/cms';
import CmsDomToReact from '../cms/dom-to-react/CmsDomToReact';
import ScriptLoader from '../script-loader/ScriptLoader';

interface Props {
    snippetKey: string;
    className?: string;
    failSilently?: boolean;
    language?: string;
    parseHtml?: boolean;
}

export default function Snippet({ className, failSilently, language, parseHtml = false, snippetKey }: Props) {
    const [snippetContent, setSnippetContent] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [headerElements, setHeaderElements] = useState<any[]>([]);
    const [isJqueryRequired, setIsJqueryRequired] = useState(false);
    const [isJqueryLoaded, setIsJqueryLoaded] = useState(false);

    useEffect(() => {
        loadSnippet();
    }, [snippetKey]);

    useImmediate(() => {
        if (snippetContent && isJqueryLoaded) {
            setHeaderElements(getHeaderElementsFromHtml(snippetContent));
        }
    }, [snippetContent, isJqueryLoaded]);

    useHeaderElementsByContent(headerElements);

    async function loadSnippet() {
        try {
            const snippet = await getSnippet(snippetKey, language);
            setIsJqueryRequired(snippet.includes('$.') || snippet.includes('$('));
            setSnippetContent(snippet);
        } catch (error) {
            logger.error('Snippet', 'loadSnippet', error);
        }

        setIsLoading(false);
    }

    if (isLoading || !snippetContent) {
        return null;
    }

    const evaluatedContent = parseHtml ? (
        <div className={className}>
            <CmsDomToReact html={snippetContent} />
        </div>
    ) : (
        <div className={className} dangerouslySetInnerHTML={{ __html: snippetContent }} />
    );

    return (
        <>
            {isJqueryRequired && <ScriptLoader onLoad={() => setIsJqueryLoaded(true)} url="/lib/jquery-2.2.4.min.js" />}

            {snippetContent
                ? evaluatedContent
                : !failSilently && <UiAlert failure>{translate('Snippet loading failed!', 'ui.common')}</UiAlert>}
        </>
    );
}
