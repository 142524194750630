import React, { useEffect, useState } from 'react';
import { updateTrackingIDs } from '../../../services/analytics';
import { FEATURE, isFeatureAvailable } from '../../../services/feature';
import { useForm } from '../../../services/form';
import { getRoute } from '../../../services/router';
import { storageGet } from '../../../services/storage';
import { translate } from '../../../services/translate';
import { validators } from '../../../services/validators';
import UiButton from '../../ui/button/UiButton';
import UiAlert from '../../ui/alert/UiAlert';
import UiFormInput from '../../ui/form/input/UiFormInput';
import UiGroup from '../../ui/group/UiGroup';
import UiNavLink from '../../ui/nav-link/UiNavLink';
import Wrapper from './styles';
import DangerousHtml from '../../dangerous-html/DangerousHtml';
import PaymentDepositProviderPayAndPlay from '../../payment/deposit/pay-and-play/PaymentDepositPayAndPlay';
import { stores } from '../../../stores';
import { PROVIDERS } from '../../../services/payments/types';
import { Country } from '@staycool/location';
import { Currency } from '../../../services/wallet/types';
import { useStore } from '../../../hooks/useStore';

interface Props {
    onClose: () => void;
    setShouldDisableBackdropClick: (value: boolean) => void;
}

export default function AuthPayAndPlay({ onClose, setShouldDisableBackdropClick }: Props) {
    const [currencyOptions, setCurrencyOptions] = useState<Currency[]>([]);
    const [isRegisteredUser, setIsRegisteredUser] = useState(false);
    const [depositError, setDepositError] = useState<string>();
    const [shouldOpenPnpDeposit, setShouldOpenPnpDeposit] = useState(false);
    const [pnpDepositParams, setPnpDepositParams] = useState({
        currency: '',
        depositAmount: '',
        bonusCode: '',
    });
    const [payAndPlaySettings] = useStore(stores.payAndPlaySettings);

    const form = useForm({
        currency: '',
        depositAmount: null,
        bonusCode: '',
    });

    useEffect(() => {
        setIsRegisteredUser(storageGet('userAlias'));
        updateTrackingIDs();
    }, []);

    useEffect(() => {
        if (payAndPlaySettings) {
            const options = Object.keys(payAndPlaySettings.minAmount) as Currency[];
            const [firstOption] = options;
            setCurrencyOptions(options);
            form.setInputValue({ currency: firstOption });
        }
    }, [payAndPlaySettings]);

    function confirmDeposit() {
        if (!isValidDeposit()) {
            return;
        }
        const { currency, depositAmount, bonusCode } = form.getValues();
        setPnpDepositParams({ currency, depositAmount, bonusCode });
        setShouldOpenPnpDeposit(true);
        setShouldDisableBackdropClick(true);
    }

    function isValidDeposit() {
        setDepositError('');

        const minAmount = payAndPlaySettings?.minAmount[form.currency.value] || 0;
        const maxAmount = payAndPlaySettings?.maxAmount[form.currency.value] || 0;
        const isUnderMinLimit = form.depositAmount.value < minAmount;
        const isOverMaxLimit = form.depositAmount.value > maxAmount;
        const isEmptyDepositAmount = form.depositAmount.value <= 0;

        if (isEmptyDepositAmount) {
            setDepositError(translate('Oops! Seems you forgot to enter amount', 'ui.account'));
            return false;
        }
        if (isUnderMinLimit) {
            setDepositError(
                translate('Minimum deposit amount is {{ amount }} {{ currency }}.', 'ui.account', {
                    amount: minAmount,
                    currency: form.currency.value,
                }),
            );
            return false;
        }
        if (isOverMaxLimit) {
            setDepositError(
                translate('Maximum deposit amount is {{ amount }} {{ currency }}.', 'ui.account', {
                    amount: maxAmount,
                    currency: form.currency.value,
                }),
            );
            return false;
        }
        return true;
    }

    return (
        <Wrapper>
            {shouldOpenPnpDeposit ? (
                <PaymentDepositProviderPayAndPlay
                    provider={payAndPlaySettings?.provider as PROVIDERS}
                    country={payAndPlaySettings?.country as Country}
                    currency={pnpDepositParams.currency}
                    amount={pnpDepositParams.depositAmount}
                    bonusCode={pnpDepositParams.bonusCode}
                    onClose={onClose}
                />
            ) : (
                <>
                    <UiGroup>
                        <UiFormInput
                            {...form.currency}
                            select
                            className="pay-and-play-currency"
                            data-test="pay-and-play-currency"
                        >
                            {currencyOptions.map((currency) => (
                                <option key={currency} value={currency}>
                                    {currency}
                                </option>
                            ))}
                        </UiFormInput>
                        <UiFormInput
                            {...form.depositAmount}
                            onFocus={() => setDepositError('')}
                            placeholder={translate('Amount', 'ui.account')}
                            typeNumeric
                            round={2}
                            className="pay-and-play-amount"
                            data-test="pay-and-play-amount"
                        />
                    </UiGroup>

                    {payAndPlaySettings?.country &&
                        [Country.ESTONIA, Country.FINLAND].includes(payAndPlaySettings?.country) && (
                            <>
                                <h3>{translate('Wait, I have a bonus code', 'ui.bonus')}</h3>

                                <UiGroup>
                                    <UiFormInput
                                        name="bonusCode"
                                        {...form.bonusCode}
                                        className="bonus-code-input"
                                        placeholder={translate('Bonus code', 'ui.account')}
                                        validator={validators.bonusCodePublic}
                                    />
                                </UiGroup>
                            </>
                        )}

                    {depositError && <UiAlert failure>{depositError}</UiAlert>}

                    <DangerousHtml
                        className="pay-and-play-terms"
                        elementType="p"
                        content={translate('agree to terms', 'ui.pay-and-play')}
                    />

                    <UiButton
                        className="pay-and-play-deposit"
                        data-test="pay-and-play-deposit"
                        onClick={confirmDeposit}
                        color="primary"
                        block
                    >
                        {translate('Deposit & Play', 'ui.pay-and-play')}
                    </UiButton>

                    {!isRegisteredUser && isFeatureAvailable(FEATURE.REGISTRATION) && (
                        <p className="alternative-option-link">
                            {translate('For more deposit options use our', 'ui.pay-and-play')}
                            <UiNavLink onClick={onClose} to={`${getRoute('registration')}`}>
                                {translate('regular signup form', 'ui.pay-and-play')}
                            </UiNavLink>
                        </p>
                    )}
                    {isRegisteredUser && (
                        <p className="alternative-option-link">
                            {translate('To login without deposit use our', 'ui.pay-and-play')}
                            <UiNavLink onClick={onClose} to={`${getRoute('login')}`}>
                                {translate('regular login form', 'ui.pay-and-play')}
                            </UiNavLink>
                        </p>
                    )}
                    <DangerousHtml
                        className="alternative-option-link"
                        elementType="p"
                        content={translate('more info', 'ui.pay-and-play')}
                    />
                </>
            )}
        </Wrapper>
    );
}
