import React, { useEffect, useRef, useState } from 'react';
import Wrapper from './styles';
import SportMatchOpen from '../open/SportMatchOpen';
import { useSportsMatchContext } from '../../../../services/sports/match-helpers';
import SportMatchLayoutHeader from './header/SportMatchLayoutHeader';
import { SportMatchLayoutComponentParams as Props } from '../../../../services/sports/types';
import { stores } from '../../../../stores';
import SportMatchOpenTopVisualization from '../open/top-visualization/SportMatchOpenTopVisualization';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import UiButton from '../../../ui/button/UiButton';
import Svg from '../../../svg/Svg';
import { translate } from '../../../../services/translate';
import { isRetail } from '../../../../services/environment';
import { useSportsUserSettings } from '../../../../services/sports/user-settings';
import { FOOTBALL_CATEGORY_ID } from '../../../../services/sport';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

export default function SportMatchLayout({ match, matchStatus }: Props) {
    const [matchHeight, setMatchHeight] = useState(0);
    const [isClosing, setIsClosing] = useState(false);
    const { isOpen, isBetbuilderFilter } = useSportsMatchContext();
    const matchHeader = useRef<HTMLDivElement>(null);
    const matchElement = useRef<HTMLDivElement>(null);
    const [originalScrollOffset, setOriginalScrollOffset] = useState(0);
    const [, setIsSidebetsOpen] = useStore(stores.sports.isSidebetsOpen);
    const [{ isPhone }] = useStore(media);
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);
    const isRetailLayout = isRetail();
    const sportCategoryId = match ? match.sport_category : FOOTBALL_CATEGORY_ID;
    const { isAmericanLayout } = useSportsUserSettings({ sportCategoryId });

    useEffect(() => {
        if (isOpen) {
            const node = matchHeader.current as any;
            setMatchHeight(node.clientHeight);
            setIsClosing(true);
            setOriginalScrollOffset(window.pageYOffset);
            setIsSidebetsOpen(true);
        }

        if (isClosing && !isOpen && isPhone) {
            window.scrollTo({
                top: originalScrollOffset,
                behavior: 'smooth',
            });
            setIsSidebetsOpen(false);
        }
    }, [isOpen]);

    return (
        <Wrapper
            $isOpen={isOpen}
            ref={matchElement}
            $isRetailLayout={isRetailLayout}
            $isAmericanLayout={isAmericanLayout}
        >
            {(isLayoutB || isRetailLayout) && isOpen && (
                <div className="go-back-btn">
                    <UiButton
                        size={isRetailLayout ? 'default' : 'small'}
                        color="outline"
                        icon={<Svg icon="drawer" size={0.875} />}
                        iconPosition="left"
                        onClick={() => window.history.go(isBetbuilderFilter ? -2 : -1)}
                    >
                        {translate('Back', 'ui.common')}
                    </UiButton>
                </div>
            )}
            <SportMatchLayoutHeader match={match} matchHeader={matchHeader} />

            <SportMatchOpenTopVisualization match={match} />

            {isOpen && !!matchHeight && (
                <SportMatchOpen match={match} matchStatus={matchStatus || match.status} stickyTop={matchHeight} />
            )}
        </Wrapper>
    );
}
