import React, { useState } from 'react';
import { getLeaderboard, LeaderBoard } from '../../../microservices/sb-campaigns';
import { logger } from '../../../services/logger';
import UiModal from '../../ui/modal/UiModal';
import Wrapper from './total/styles';
import UiBox from '../../ui/box/UiBox';
import UiHeading from '../../ui/heading/UiHeading';
import UiCloseButton from '../../ui/close-button/UiCloseButton';
import UiAlert from '../../ui/alert/UiAlert';
import { translate } from '../../../services/translate';
import UiDotsLoader from '../../ui/dots-loader/UiDotsLoader';
import CmsLeaderboardTotal from './total/CmsLeaderboardTotal';
import CmsLeaderboardDaily from './daily/CmsLeaderboardDaily';
import UiButton from '../../ui/button/UiButton';

interface Props {
    campaignId: string;
    'campaign-id': string;
    day: string;
    linkText: string;
    'link-text': string;
}

export default function CmsLeaderboard({ 'campaign-id': campaignId, day, 'link-text': linkText }: Props) {
    const [leaderboard, setLeaderboard] = useState<LeaderBoard[]>([]);
    const [isLeaderBoardModalOpen, setIsLeaderBoardModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    async function loadLeaderBoard() {
        setIsLeaderBoardModalOpen(true);
        setIsLoading(true);
        try {
            const response = await getLeaderboard(campaignId, day);
            setLeaderboard(response);
        } catch (error) {
            logger.error('CmsLeaderboard', 'loadLeaderBoard', error);
        }
        setIsLoading(false);
    }

    return (
        <>
            <UiButton color="primary" onClick={loadLeaderBoard}>
                {linkText}
            </UiButton>
            <UiModal open={isLeaderBoardModalOpen} onClose={() => setIsLeaderBoardModalOpen(false)} maxWidth="100%">
                <Wrapper>
                    <UiBox>
                        <UiHeading title={linkText} />
                        <UiCloseButton onClick={() => setIsLeaderBoardModalOpen(false)} className="close-button" />
                        {day && <CmsLeaderboardTotal leaderboard={leaderboard} />}
                        {!day && <CmsLeaderboardDaily leaderboard={leaderboard} />}
                        {leaderboard && !leaderboard.length && (
                            <UiAlert warning>{translate('nothing-found', 'raf.ui')}</UiAlert>
                        )}
                        {isLoading && <UiDotsLoader />}
                    </UiBox>
                </Wrapper>
            </UiModal>
        </>
    );
}
