import { storageGet } from '../storage';
import { stores } from '../../stores';
import { BetType } from '@staycool/lvdc-types/bets';
import { initialHorseRacingBetslipPlacingState } from '../horse-racing/horse-racing';
import { ticketHandlerByBetType } from './handlers';
import { isMobile } from '../browser';
import { loadCarryovers, loadTodaysTracks, loadTrackRaceDetails } from '../../microservices/lvdc-web';
import round from 'lodash/round';
import clone from 'lodash/clone';
import { isProd } from '../environment';
import { RaceBetType } from '../../microservices/lvdc-web';
import moment from 'moment';
import { clearRacebookBetslipErrors } from '../horse-racing/betslip-errors';
import { getStoreValue } from '../../stores/store/utils';
import { logger } from '../logger';
import { environment } from '../../stores/environment/environment';

export const MAX_DISPLAYED_RACES = isMobile() ? 3 : 5;

export function isHorseRacingDevMode() {
    const isHorseRacingDev = getStoreValue(stores.horseRacingDevMode.isEnabled);
    return Boolean(!isProd() && isHorseRacingDev);
}

export const getCurrentDateString = () => {
    // returns array of date parts:
    // [
    //     {type: 'month', value: '04'},
    //     {type: 'literal', value: '/'},
    //     {type: 'day', value: '18'},
    //     {type: 'literal', value: '/'},
    //     {type: 'year', value: '2023'}
    // ]
    const dateParts = new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'America/Los_Angeles',
    }).formatToParts();

    const mockedDate = getStoreValue(stores.horseRacingDevMode.mockedDate);
    return isHorseRacingDevMode()
        ? mockedDate.toString()
        : `${dateParts[4].value}-${dateParts[0].value}-${dateParts[2].value}`;
};
export const lvdcBettingOffChannel = `${getCurrentDateString()}-betting-off`;
export const lvdcRaceEndingChannel = `${getCurrentDateString()}-race-ending`;

interface BetTypeSettings {
    selectionsCount: number;
    isMultiRace?: boolean;
    swappable?: boolean;
    bet_pools_count?: number;
    isKeyType?: boolean;
}

export const settingsByBetType: Partial<Record<BetType, BetTypeSettings>> = {
    WIN: { selectionsCount: 1 },
    PLC: { selectionsCount: 1 },
    SHW: { selectionsCount: 1 },
    WP: { selectionsCount: 1, bet_pools_count: 2 },
    WPS: { selectionsCount: 1, bet_pools_count: 3 },
    WS: { selectionsCount: 1, bet_pools_count: 2 },
    PS: { selectionsCount: 1, bet_pools_count: 2 },
    EXA: { selectionsCount: 2, swappable: true },
    EBX: { selectionsCount: 1 },
    QNL: { selectionsCount: 2 },
    QBX: { selectionsCount: 1 },
    TRI: { selectionsCount: 3, swappable: true },
    TBX: { selectionsCount: 1 },
    DBL: { selectionsCount: 2, isMultiRace: true },
    PK3: { selectionsCount: 3, isMultiRace: true },
    PK4: { selectionsCount: 4, isMultiRace: true },
    PK5: { selectionsCount: 5, isMultiRace: true },
    PK6: { selectionsCount: 6, isMultiRace: true },
    PK7: { selectionsCount: 7, isMultiRace: true },
    PK8: { selectionsCount: 8, isMultiRace: true },
    PK9: { selectionsCount: 9, isMultiRace: true },
    P10: { selectionsCount: 10, isMultiRace: true },
    SFC: { selectionsCount: 4, swappable: true },
    SFX: { selectionsCount: 1 },
    TRK: { selectionsCount: 2, swappable: true, isKeyType: true },
    SFK: { selectionsCount: 2, swappable: true, isKeyType: true },
    E5K: { selectionsCount: 2, isKeyType: true },
    GSL: { selectionsCount: 4, isMultiRace: true },
    E05: { selectionsCount: 5, swappable: true },
    E5X: { selectionsCount: 1 },
};

const allBetStakesVariants = [
    0.1, 0.2, 0.3, 0.4, 0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 25, 50, 100, 200, 250, 500, 1000, 2500,
];

export const startingNowUpperBound = 16;
export const startingNowLowerBound = -10;
export const startingSoonUpperBound = 45;

export function setFavouriteTracksFromStore() {
    const favoriteTracks = storageGet('favoriteTracks', []);
    stores.horseRacing.favouriteTrackIds.set(favoriteTracks);
}

export const getTicketBetsCombinations = (betType: string, runnerMaps: number[][]) => {
    if (runnerMaps.length === 0) {
        return 0;
    }
    const getBetsCountHandler = ticketHandlerByBetType[betType];
    if (getBetsCountHandler) {
        const runnerMapsNormalized: number[][] = [];
        for (let i = 0; i < runnerMaps.length; i++) {
            runnerMapsNormalized.push(runnerMaps[i] ?? []);
        }

        return getBetsCountHandler(runnerMapsNormalized, betType);
    }

    return 0;
};

export function clearBetslip() {
    stores.horseRacing.betslip.set((prevState) => ({
        ...prevState,
        totalStake: 0,
        card: 0,
        totalCombinations: 0,
        runnerMaps: [],
    }));
    clearRacebookBetslipErrors();
}

export function clearReceipt() {
    stores.horseRacing.betslipPlacingState.set(initialHorseRacingBetslipPlacingState);
}

export function clearBetslipAndReceipt() {
    clearBetslip();
    clearReceipt();
}

export async function getTodaysTracks() {
    const tracks = await loadTodaysTracks();
    setStoreMinutesToPost(tracks.filter(Boolean).map((track) => parseInt(`${track.post_time}`, 10)));
    return tracks;
}

export async function getCarryovers() {
    const carryovers = await loadCarryovers();
    setStoreMinutesToPost(carryovers.map((carryover) => carryover.post_time));
    return carryovers;
}

export async function getTrackRaceDetails(nevadaTrackId: number, raceId: number) {
    const trackDetails = await loadTrackRaceDetails(nevadaTrackId, raceId);
    setStoreMinutesToPost(
        trackDetails.raceList.filter((race) => !!race.post_time).map((race) => Number(race.post_time)),
    );
    return trackDetails;
}

function setStoreMinutesToPost(postTimes: number[]) {
    const minutesToPostMap = clone(stores.horseRacing.minutesToPostByTimestamp.state);
    postTimes
        .filter((time) => !isNaN(time))
        .forEach((postTime) => {
            if (postTime === undefined) {
                return;
            }

            if (!minutesToPostMap.has(postTime)) {
                const millisecondsToPost = moment(postTime).endOf('minute').diff(moment());
                const minutesToPost = round(moment.duration(millisecondsToPost).asMinutes());
                minutesToPostMap.set(postTime, minutesToPost);
            }
        });
    stores.horseRacing.minutesToPostByTimestamp.set(minutesToPostMap);
}

export function getBetTypeMinMaxStake(betType: RaceBetType, totalCombinations: number) {
    if (!betType) {
        return { minStake: 0, maxStake: 0 };
    }
    const {
        special_min_combos,
        special_min_amount,
        standard_min_amount,
        special_max_combos,
        standard_max_amount,
        special_max_amount,
    } = betType;

    const minStake = totalCombinations >= special_min_combos ? special_min_amount : standard_min_amount;
    const maxStake = totalCombinations >= special_max_combos ? special_max_amount : standard_max_amount;
    return { minStake, maxStake };
}

export function getPredefinedStakes(minStake: number, maxStake: number, betType?: RaceBetType): number[] {
    if (!betType) {
        return [0];
    }

    const { is_min_amount_only, is_min_on_bet_cost, is_pennies_allowed } = betType;

    if (is_min_amount_only || is_min_on_bet_cost) {
        return [minStake];
    }

    let stakes: number[] = allBetStakesVariants.filter((stake) => stake >= minStake && stake <= maxStake);
    if (!is_pennies_allowed) {
        return stakes.filter((stake) => stake >= 1);
    }

    if (minStake === 0.5) {
        stakes = [0.5, 1, 1.5, 2, 2.5, ...stakes.filter((stake) => stake >= 3)];
    } else if (minStake === 0.2) {
        stakes = [0.2, 0.4, 0.6, 0.8, ...stakes.filter((stake) => stake >= 1)];
    }
    return stakes;
}

export function getAssociationMapping(signupProperty: string) {
    try {
        const signupPropertyUpper = signupProperty.toUpperCase();
        const { LVDC_ASSOCIATION_MAPPING = {} } = getStoreValue(environment);
        if (!Object.keys(LVDC_ASSOCIATION_MAPPING).length) {
            logger.error('LvdcLvdcService', 'getAssociationMapping', 'Failed to get associationId');
            return;
        }

        return LVDC_ASSOCIATION_MAPPING[signupPropertyUpper];
    } catch (e) {
        logger.error('LvdcLvdcService', 'getAssociationMapping', `Failed to get associationId , ${e}`);
        return;
    }
}
