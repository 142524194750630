import React from 'react';
import SportTransactionsPage from '../../../pages/sport/transactions/SportTransactionsPage';

export default function PageTransactions() {
    return (
        <>
            <SportTransactionsPage />
        </>
    );
}
