import React from 'react';
import Wrapper from './styles';
import { translate } from '../../../../services/translate';
import { LeaderBoard } from '../../../../microservices/sb-campaigns';
import { formattedAmountWithCurrency } from '../../../../services/currency';

interface Props {
    leaderboard: LeaderBoard[];
}

export default function CmsLeaderboardDaily({ leaderboard }: Props) {
    return (
        <Wrapper>
            <div className="leaderboard-header">
                <div className="leaderboard-cell">{translate('number', 'ui.sports.campaigns.leaderboard.daily')}</div>
                <div className="leaderboard-cell">{translate('alias', 'ui.account')}</div>
                <div className="leaderboard-cell">
                    {translate(
                        'bet-amount-for-next-round-bet--eur--nok--sek-',
                        'ui.sports.campaigns.leaderboard.daily',
                    )}
                </div>
            </div>
            <div className="leaderboard">
                {leaderboard &&
                    leaderboard.map((user, index) => (
                        <div key={index} className="leaderboard-results">
                            <div className="leaderboard-cell">{index + 1}</div>
                            <div className="leaderboard-cell">{user.user_alias}</div>
                            <div className="leaderboard-cell">
                                {formattedAmountWithCurrency(user.won_amount, { currency: user.currency })}
                            </div>
                        </div>
                    ))}
            </div>
        </Wrapper>
    );
}
