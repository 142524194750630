import { getServiceUrl, httpPost } from '../services/api';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { getUserCountry, getUserProvince } from '../services/user';
import { CasinoGameLauncher } from '../services/casino/games';
import { getRoute } from '../services/router';
import { CasinoProviderIntegrationNames, CasinoProviders } from '../services/casino/providers';
import { getLicence } from '../services/licence';
import { getDeviceCategory } from '../services/device';
import { storageGet } from '../services/storage';
import { GameLaunchPosition } from '../services/casino/types';
import { LauncherConfig } from '../services/casino/provider-live-lobby-types';
import { Currency } from '../services/wallet/types';

function getUrl(url: string, provider: string) {
    return getServiceUrl(`casino-integrations-${provider}`, url);
}

export enum Platform {
    DESKTOP = 'DESKTOP',
    MOBILE = 'MOBILE',
    IOS = 'IOS',
}

function getGameLaunchSource(): GameLaunchPosition {
    // TODO: handle rendered links in blog and promotions page
    return getStoreValue(stores.casino.latestGameLaunchPosition);
}

export async function getGameLauncherConfig(gameLauncher: CasinoGameLauncher, config?: any): Promise<LauncherConfig> {
    const gameLaunchPosition: GameLaunchPosition = getGameLaunchSource();
    const isPlayForReal = getStoreValue(stores.casino.playForReal);
    const lobbyUrl = `${window.location.origin}${getRoute(getStoreValue(stores.casino.lastOpenedProductRoute))}`;
    const language = getStoreValue(stores.language);
    const gameParams = {
        lobbyUrl,
        gameVariantId: gameLauncher?.gameVariantId,
        platform: getDeviceCategory(),
        country: getUserCountry(),
        province: getUserProvince(),
        licence: getLicence(),
        deviceUuid: storageGet('uuid'),
        language,
        gameLaunchPosition,
        userCurrency: getStoreValue(stores.wallet)?.currency,
        //TODO be taken from a future dropdown
        gamePlayCurrency: gameLauncher?.gamePlayCurrency || Currency.USD,
        ...config,
    };

    const gameUrl = isPlayForReal ? 'fo/init-game-real' : 'fo/init-game-fun';

    const gameUrlResponse = await httpPost<any>(
        getUrl(gameUrl, CasinoProviderIntegrationNames[gameLauncher.providerId]),
        gameParams,
    );
    return { ...gameUrlResponse };
}

interface LaunchParams {
    origin: string;
}

interface EvolutionLobbyUrl {
    launchParams: LaunchParams;
}

export async function getEvolutionLobbyUrl(): Promise<EvolutionLobbyUrl> {
    const lobbyUrl = `${window.location.origin}${getRoute(getStoreValue(stores.casino.lastOpenedProductRoute))}`;
    const language = getStoreValue(stores.language);
    const gameParams = {
        lobbyUrl,
        platform: getDeviceCategory(),
        country: getUserCountry(),
        licence: getLicence(),
        deviceUuid: storageGet('uuid'),
        playMode: getStoreValue(stores.casino.playForReal),
        province: getUserProvince(),
        language,
        userCurrency: getStoreValue(stores.wallet)?.currency,
        gamePlayCurrency: Currency.USD,
    };

    const lobbyUrlResponse = await httpPost<EvolutionLobbyUrl>(
        getUrl('fo/init-game-lobby', CasinoProviderIntegrationNames[CasinoProviders.EVOLUTION]),
        gameParams,
    );
    return { ...lobbyUrlResponse };
}

export async function getProviderLiveLobbyLauncherConfig(
    gameLauncher: CasinoGameLauncher,
): Promise<LauncherConfig | null> {
    const lobbyUrl = `${window.location.origin}${getRoute(getStoreValue(stores.casino.lastOpenedProductRoute))}`;

    if (!gameLauncher.type) {
        return null;
    }

    const requestPayload = {
        country: getUserCountry(),
        language: getStoreValue(stores.language),
        platform: getDeviceCategory(),
        licence: getLicence(),
        deviceUuid: storageGet('uuid'),
        playMode: getStoreValue(stores.casino.playForReal),
        province: getUserProvince(),
        lobbyUrl,
        liveLobbyData: { lobbyType: gameLauncher.type.toUpperCase(), subproviderId: gameLauncher.subProviderId },
        userCurrency: getStoreValue(stores.wallet)?.currency,
        gamePlayCurrency: Currency.USD,
    };

    const launcherConfig = await httpPost<LauncherConfig>(
        getUrl('fo/init-live-lobby', CasinoProviderIntegrationNames[gameLauncher.providerId]),
        requestPayload,
    );
    return launcherConfig;
}
