import { lighten } from 'polished';
import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

const generalArticle = ({ isHighlightedArticle }: StyledProps) =>
    !isHighlightedArticle &&
    css`
        .article-preview {
            word-break: break-word;
            padding-bottom: 1rem;
            p {
                font-size: 1rem;
            }
            @media ${mediaQuery.isTablet} {
                display: block;
            }

            .preview {
                padding: 1.5rem;
            }
        }
    `;

type StyledProps = {
    isHighlightedArticle?: boolean;
};

export default styled.div<StyledProps>`
    border-radius: 10px;
    overflow: hidden;
    background-color: #efefef;
    position: relative;
    white-space: normal;
    margin: 0;

    h1,
    h2,
    h3,
    h4,
    u {
        color: ${({ theme }) => theme.colors.black};
    }

    h1 {
        font-size: 1.6rem;
        font-weight: 500;
        margin-top: 0;
    }

    p {
        font-size: 0.875rem;
        color: #4c4c4c;
    }

    img {
        border-radius: 10px;
        display: block;
    }

    .category {
        position: absolute;
        top: 11px;
        left: 14px;
        background-color: var(--color-highlight);
        color: ${({ theme }) => theme.buttons.primaryTextColor};

        text-transform: uppercase;
        font-weight: normal;
        border-radius: 100px;
        letter-spacing: 1px;
        font-size: 0.7rem;
        padding: 0.2rem 0.5rem;

        @media ${mediaQuery.isPhone} {
            top: 5px;
            left: 5px;
        }
    }

    ${generalArticle}
    ${({ isHighlightedArticle }) =>
        isHighlightedArticle &&
        css`
            margin-bottom: 1rem;
            .category {
                top: 18px;
                font-size: 1rem;
            }
            .article-preview {
                word-break: break-word;
                display: flex;
                align-items: center;
                .col-sm-6 {
                    // comes from BO
                    flex: 1;
                }
                .preview {
                    // comes from BO
                    flex: 1;
                    padding: 1.5rem;
                    p {
                        font-size: 1rem;
                    }
                }

                @media (max-width: 1300px) {
                    padding-bottom: 1rem;
                    display: block;
                }
                @media ${mediaQuery.isPhone} {
                    padding-bottom: 1rem;
                    display: block;
                }

                @media ${mediaQuery.isTablet} {
                    padding-bottom: 1rem;
                    display: block;
                }
            }
        `}

    .read-more-btn {
        // class read-more-btn comes directly from BO.
        bottom: 11px;
        position: absolute;
        text-decoration: none;
        background-color: #b1b1b1;
        color: #ffffff;
        border-radius: var(--ui-radius);
        border: 1px solid transparent;
        min-height: 35px;
        font-size: 0.8rem;
        letter-spacing: 1px;
        line-height: 1;
        padding: 0 1.5rem;
        background-clip: padding-box;
        transition: background-color 0.2s, border-color 0.2s, color 0.2s;
        display: flex;
        justify-content: center;
        align-items: center;

        &:empty {
            display: none;
        }
    }
    .read-more-btn:hover {
        background-color: ${({ theme }) => lighten(0.05, theme.buttons.background)};
    }

    .read-more {
        // class read-more comes directly from BO.
        display: none;
    }
    .reading-time {
        position: absolute;
        bottom: 11px;
        right: 14px;
        padding: 0.2rem 0.5rem;
        font-size: 0.6rem;
        text-transform: uppercase;
        font-weight: normal;
        letter-spacing: 1px;
        border-radius: 100px;
        background-color: #b1b1b1;
        color: #ffffff;
    }
    .post-creation-date {
        position: absolute;
        top: 5px;
        right: 5px;
        letter-spacing: 1px;
        border-radius: 100px;
        background-color: #b1b1b1;
        color: #ffffff;
        padding: 0.2rem 0.5rem;
        font-size: 0.6rem;
        text-transform: uppercase;
        @media ${mediaQuery.isPhone} {
            top: 3px;
            right: 15px;
            font-size: 0.7rem;
        }
    }
    .preview > p {
        line-height: 1.5;
    }
`;
