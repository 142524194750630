import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { RequestedDocument } from '../../../../../../microservices/kyc';
import { translate } from '../../../../../../services/translate';
import UiAlert from '../../../../../ui/alert/UiAlert';
import AccountVerificationDeclineReason from '../../../decline-reason/AccountVerificationDeclineReason';
import Wrapper from './styles';

interface Props {
    className?: string;
    displayDeclineReasonOnlyForSide?: 'front' | 'back';
    document: RequestedDocument;
    isCompact?: boolean;
}

export default function AccountVerificationProofOfPaymentDocumentDeclineReason({
    document,
    isCompact,
    displayDeclineReasonOnlyForSide,
    className,
}: Props) {
    function StatusIndicator(props: { heading: string; isDeclined: boolean }) {
        return (
            <div className={classNames('status-indicator', { declined: props.isDeclined })}>
                <span className="heading">{props.heading}</span>
                <span className="status">
                    {props.isDeclined
                        ? translate('declined', 'ui.account.kyc.pop.status')
                        : translate('ok', 'ui.account.kyc.pop.status')}
                </span>
            </div>
        );
    }

    function DeclineReasonContainer(props: { title: string; declineReason: string }) {
        return (
            <div>
                <div className="decline-reason-title">{props.title}</div>
                <UiAlert failure>
                    <AccountVerificationDeclineReason declineReason={props.declineReason} />
                </UiAlert>
            </div>
        );
    }

    if (isCompact) {
        if (document.backSideDeclineReason || document.frontSideDeclineReason) {
            return (
                <Wrapper className={classNames(className, 'compact')}>
                    <div className="status-indicators">
                        <StatusIndicator
                            heading={translate('front side', 'ui.account.kyc')}
                            isDeclined={!!document.frontSideDeclineReason}
                        />
                        <StatusIndicator
                            heading={translate('back side', 'ui.account.kyc')}
                            isDeclined={!!document.backSideDeclineReason}
                        />
                    </div>
                </Wrapper>
            );
        }
        return (
            <Wrapper className={classNames(className, 'compact')}>
                <Tooltip title={<AccountVerificationDeclineReason declineReason={document.declineReason} />}>
                    <AccountVerificationDeclineReason isCompact declineReason={document.declineReason} />
                </Tooltip>
            </Wrapper>
        );
    }
    return (
        <Wrapper className={className}>
            {(!displayDeclineReasonOnlyForSide || displayDeclineReasonOnlyForSide === 'front') &&
                document.frontSideDeclineReason && (
                    <DeclineReasonContainer
                        title={translate('Previously uploaded document front side decline reason', 'ui.account.kyc')}
                        declineReason={document.frontSideDeclineReason}
                    />
                )}
            {(!displayDeclineReasonOnlyForSide || displayDeclineReasonOnlyForSide === 'back') &&
                document.backSideDeclineReason && (
                    <DeclineReasonContainer
                        title={translate('Previously uploaded document back side decline reason', 'ui.account.kyc')}
                        declineReason={document.backSideDeclineReason}
                    />
                )}
            {document.declineReason && (
                <DeclineReasonContainer
                    title={translate('Previously uploaded document decline reason', 'ui.account.kyc')}
                    declineReason={document.declineReason}
                />
            )}
        </Wrapper>
    );
}
