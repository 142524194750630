import React, { useEffect, useRef, useState } from 'react';
import { stores } from '../../../../../stores';
import { getBetbuilderMarket } from '../../../../../microservices/sports';
import { useSportsUserSettings } from '../../../../../services/sports/user-settings';
import { isProduction } from '../../../../../services/util';
import { getRoute, useRouter } from '../../../../../services/router';
import { translate } from '../../../../../services/translate';
import UiAlert from '../../../../ui/alert/UiAlert';
import { COOLBET_PROVIDER_MAP } from '../../../../../services/sports/constants';
import { CategoryMatch } from '../../../../../services/sports/types';
import { getBetbuilderCulture } from '../../../../../services/language';
import { FEATURE, isFeatureAvailable } from '../../../../../services/feature';
import ScriptLoader from '../../../../script-loader/ScriptLoader';
import { getStoreValue } from '../../../../../stores/store/utils';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    match: CategoryMatch;
}

export default function SportMatchBetbuilderBetgenius({ match }: Props) {
    const [isBgApiLoaded, setIsBgApiLoaded] = useState<boolean>(false);
    const [iframeId] = useState<string>(`betgenius-iframe-${new Date().getTime().toString()}`);
    const iframeRef = useRef();
    const { isAmericanOddsFormat } = useSportsUserSettings();
    const [language] = useStore(stores.language);
    const { navigateTo } = useRouter();
    const [{ isPhone }] = useStore(media);
    const [heartbeats] = useStore(stores.sports.heartbeat);
    const isBetgeniusUp = heartbeats[COOLBET_PROVIDER_MAP.BETGENIUS];

    useEffect(() => {
        const iframe = iframeRef.current;
        if (iframe && isBgApiLoaded) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const iframeApi = new (window as any).IFrameApi(iframeId);
            iframeApi.onMessage = (message) => {
                if (message.command === 'addToBetslip') {
                    addToBetslip(message.selection);
                }
            };
        }
    }, [isBgApiLoaded]);

    async function addToBetslip(betbuilderSelectionId) {
        const {
            id: marketId,
            outcomes: [{ id: outcomeId }],
        } = await getBetbuilderMarket(betbuilderSelectionId);

        const { isLoading, needsConfirm } = getStoreValue(stores.sports.betSlipPlacingState);

        if (isLoading || needsConfirm) {
            return;
        }

        stores.sports.betSlipMarketIdToOutcomeId.set((state) => {
            state[marketId] = outcomeId;
        });

        if (isPhone && isFeatureAvailable(FEATURE.BETSLIP_NAVIGATION)) {
            navigateTo(getRoute('sport.betslip'));
        }
    }

    function getIframeUrl(betbuilderId: number) {
        const baseURL = isProduction()
            ? 'https://coolbet-prod-gen2.sportcastlive.com'
            : 'https://coolbet-uat-gen2.sportcastlive.com';
        const params = [
            'key=9dc3973b-d184-42e5-ab5d-0f912792a201',
            `ConsumerFixtureId=${betbuilderId}`,
            `odds=${isAmericanOddsFormat ? 'AmericanPrice' : 'DecimalPrice'}`,
            `culture=${getBetbuilderCulture(language)}`,
            'brand=coolbet',
        ];
        return `${baseURL}/markets?${params.join('&')}`;
    }

    return (
        <>
            {isBetgeniusUp && (
                <div>
                    <ScriptLoader
                        onLoad={() => setIsBgApiLoaded(true)}
                        url={
                            isProduction()
                                ? 'https://cdn.betstream.betgenius.com/betstream-view/public/bg_api.js'
                                : 'https://bsdemo.betstream.betgenius.com/betstream-view/public/bg_api.js'
                        }
                    />
                    {isBgApiLoaded && (
                        <iframe
                            id={iframeId}
                            title="iframe"
                            className="iframe"
                            src={getIframeUrl(Number(match.betbuilder_id))}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            ref={iframeRef as any}
                        />
                    )}
                </div>
            )}
            {!isBetgeniusUp && (
                <UiAlert warning className="alert">
                    {translate('sb.betbuilder-not-available')}
                </UiAlert>
            )}
        </>
    );
}
