import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import UiButton from '../../../ui/button/UiButton';
import classNames from 'classnames';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../../services/analytics';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import { isRetail } from '../../../../services/environment';
import { formattedAmountWithCurrency } from '../../../../services/currency';

interface Props {
    onStakeUpdate: () => void;
    stake: number;
    disabled?: boolean;
}

export default function SportBetslipQuickStake({ onStakeUpdate, stake, disabled = false }: Props) {
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
    const [isQuickStakeActivated, setIsQuickStakeActivated] = useState(false);
    const isRetailLayout = isRetail();
    useEffect(() => {
        return () => clearTimeout(timeoutId as NodeJS.Timeout);
    }, []);

    function quickStakeActivated() {
        onStakeUpdate();
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.BETSLIP_QUICK_STAKE_CLICKED);

        setIsQuickStakeActivated(true);
        setTimeoutId(
            setTimeout(() => {
                setIsQuickStakeActivated(false);
            }, 500),
        );
    }

    const isQuickStakeIncremental = isFeatureAvailable(FEATURE.QUICKSTAKE_INCREMENTAL);
    const stakeToDisplay = `${isQuickStakeIncremental ? '+ ' : ''}${formattedAmountWithCurrency(stake, {
        maximumFractionDigits: 0,
    })}`;

    return (
        <Wrapper $isRetailLayout={isRetailLayout}>
            <UiButton
                className="quick-stake"
                key={stake}
                size={isRetailLayout ? 'default' : 'small'}
                onClick={quickStakeActivated}
                disabled={disabled}
            >
                {stakeToDisplay}
                <div
                    className={classNames('quick-stake-bubble', {
                        active: isQuickStakeActivated,
                    })}
                >
                    {stakeToDisplay}
                </div>
            </UiButton>
        </Wrapper>
    );
}
