import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { translate } from '../../../services/translate';
import UiCloseButton from '../../ui/close-button/UiCloseButton';
import Svg from '../../svg/Svg';
import { getMyStats } from '../../../microservices/warehouse-api';
import { TIME_FILTER } from '../../../services/bet-history';
import { logger } from '../../../services/logger';
import UiDotsLoader from '../../ui/dots-loader/UiDotsLoader';
import UiButton from '../../ui/button/UiButton';
import UiBox from '../../ui/box/UiBox';
import { formattedAmountWithCurrency } from '../../../services/currency';
import { PRODUCT } from '../../../types/common';

interface Props {
    product: PRODUCT;
    selectedDateFilter: string;
}

export default function SportTotals({ product, selectedDateFilter }: Props) {
    const [isTotalsLoading, setIsTotalsLoading] = useState(false);
    const [isTotalsVisible, setIsTotalsVisible] = useState(false);
    const [totals, setTotals] = useState<string>();

    useEffect(() => {
        if (isTotalsVisible) {
            loadTotals();
        }
    }, [isTotalsVisible, product, selectedDateFilter]);

    async function loadTotals() {
        setIsTotalsLoading(true);
        const timeUtc__ge =
            selectedDateFilter !== TIME_FILTER.ALL
                ? moment()
                      .subtract(1, selectedDateFilter as any)
                      .utc()
                      .toISOString()
                : undefined;
        try {
            const { sportsbookBetsUc, sportsbookWinsUc, virtualSportsBetsUc, virtualSportsWinsUc } = await getMyStats({
                timeUtc__ge,
            });
            let productNetBalance = 0;
            if (product === PRODUCT.SPORTSBOOK) {
                productNetBalance = sportsbookWinsUc - sportsbookBetsUc;
            } else if (product === PRODUCT.VIRTUAL_SPORTS) {
                productNetBalance = virtualSportsWinsUc - virtualSportsBetsUc;
            }
            setTotals(formattedAmountWithCurrency(productNetBalance || 0));
        } catch (error) {
            logger.error('SportTotals', 'loadTotals', error);
        }
        setIsTotalsLoading(false);
    }

    function toggleTotalsView() {
        setIsTotalsVisible(!isTotalsVisible);
    }

    return (
        <Wrapper>
            {!isTotalsVisible && (
                <UiButton
                    onClick={toggleTotalsView}
                    icon={<Svg icon="arrow-circle" size={1.1} />}
                    block
                    className="balance-status-button"
                >
                    {translate('Show my turnover stats', 'ui.account')}
                </UiButton>
            )}

            {isTotalsVisible && (
                <UiBox className="balance-status-container">
                    <UiCloseButton onClick={toggleTotalsView} />
                    {isTotalsLoading && <UiDotsLoader />}
                    {!isTotalsLoading && (
                        <>
                            <div className="balance-status">
                                <Svg className="blue" icon="arrow-circle" />

                                <div className="balance-status-totals">
                                    {translate('Total Net Balance Change', 'ui.account')}
                                    <br />
                                    <span className="balance-status-amount">{totals}</span>
                                </div>
                            </div>
                        </>
                    )}
                </UiBox>
            )}
        </Wrapper>
    );
}
