import { useEffect } from 'react';
import { NativeMessageEventType, isMobileApp, sendNativeEvent } from '../../../services/mobile-app';
import { getRoute } from '../../../services/router';
import { storageGet, storageSet } from '../../../services/storage';
import { stores } from '../../../stores';
import { router as routerStore } from '../../../stores/router/router';
import { getStoreValue } from '../../../stores/store/utils';
import { useStore } from '../../../hooks/useStore';

export default function SystemRouteListener() {
    // TODO: check if store can be replaced with router hook
    const [router] = useStore(routerStore);

    function isCasinoGameRoute(route: string | undefined): boolean {
        return route?.startsWith(getRoute('casino-game')) || false;
    }

    useEffect(() => {
        const activeRoute = router.location.pathname;
        const lastActiveRoute = storageGet<string | undefined>('lastActiveRoute');

        const reloadRequired = getStoreValue(stores.apiVersion.reloadRequired);
        if (lastActiveRoute && activeRoute !== lastActiveRoute && reloadRequired) {
            window.location.reload();
        }

        if (isMobileApp() && isCasinoGameRoute(lastActiveRoute) && !isCasinoGameRoute(activeRoute)) {
            sendNativeEvent({ type: NativeMessageEventType.GAME_CLOSED });
        }

        storageSet('lastActiveRoute', activeRoute);
    }, [router]);

    return null;
}
