import styled from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

export default styled.div`
    width: 100%;

    .rtp-calculator-game-selection-comparison-label-info {
        width: 100%;
        min-height: 36px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .rtp-calculator-game-selection-comparison-info-primary {
        font-weight: bold;
        font-size: 1.2rem;
        color: var(--color-highlight);

        @media ${mediaQuery.isPhone} {
            font-size: 0.85rem;
        }

        .rtp-calculator-game-selection-comparison-image {
            height: 180px;
            width: 180px;
        }

        @media ${mediaQuery.isPhone} {
            .rtp-calculator-game-selection-comparison-image {
                height: 80px;
                width: 80px;
                background-size: 80px 80px;
            }
        }
    }

    .rtp-calculator-game-selection-comparison-info-secondary {
        .rtp-calculator-game-selection-comparison-image {
            opacity: 0.8;
        }
        font-size: 1rem;
        .rtp-calculator-game-selection-comparison-image {
            height: 150px;
            width: 150px;
            background-size: 150px 150px;
        }

        @media ${mediaQuery.isPhone} {
            font-size: 0.75rem;
            .rtp-calculator-game-selection-comparison-image {
                height: 70px;
                width: 70px;
                background-size: 70px 70px;
            }
        }
    }

    .rtp-calculator-game-selection-comparison-info-tertiary {
        .rtp-calculator-game-comparison-image {
            opacity: 0.6;
        }
        font-size: 0.875rem;
        .rtp-calculator-game-selection-comparison-image {
            height: 120px;
            width: 120px;
            background-size: 120px 120px;
        }
        @media ${mediaQuery.isPhone} {
            font-size: 0.7rem;
            .rtp-calculator-game-selection-comparison-image {
                height: 60px;
                width: 60px;
                background-size: 60px 60px;
            }
        }
    }
    .rtp-calculator-game-selection-comparison-image-wrapper {
        height: 200px;
        width: 180px;
        display: flex;
        margin: auto;
        @media ${mediaQuery.isTablet}, ${mediaQuery.isLaptop}, ${mediaQuery.isDesktop} {
            margin-bottom: 10px;
        }
        @media ${mediaQuery.isPhone} {
            height: 100px;
            width: 100%;
        }
        .rtp-calculator-game-selection-comparison-image {
            margin: auto;
            border-radius: 8px;
        }
    }

    .rtp-calculator-game-selection-comparison-label {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 44px;
        max-width: 150px;
        margin: auto;
        @media ${mediaQuery.isPhone} {
            min-height: 45px;
            flex-direction: column;
            justify-content: flex-end;
        }
        &-text {
            label {
                display: block;
            }
        }
        &-flag {
            @media ${mediaQuery.isTablet}, ${mediaQuery.isLaptop}, ${mediaQuery.isDesktop} {
                padding-left: 5px;
            }
        }
    }

    .rtp-calculator-game-selection-comparison-label-percent {
        margin: 10px;
        min-height: 23px;
        @media ${mediaQuery.isPhone} {
            margin: 5px;
            min-height: 18px;
        }
    }

    .rtp-calculator-game-selection-comparison-label-percent,
    .rtp-calculator-game-selection-comparison-label-spins,
    .rtp-calculator-game-selection-comparison-label-minutes {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
