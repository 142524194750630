import styled from 'styled-components';

export default styled.header`
    background-color: var(--color-header-bg);
    box-shadow: var(--ui-shadow);
    padding: 0 0.8rem;

    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: ${({ theme }) => theme.header.height};
        max-width: 1920px;
        margin: 0 auto;
    }

    .header-logo {
        margin: 0 0.8rem;
    }

    .header-products-nav {
        display: flex;
        align-items: center;
        flex-grow: 1;

        margin-left: 0.8rem;
        > *:not(:last-child) {
            margin-right: 2rem;
        }
    }

    .header-user-controls {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: var(--spacing-12);
    }
`;
