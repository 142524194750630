import styled from 'styled-components';

export default styled.header`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 0.8rem;

    height: 60px;
    z-index: 2;
    background: var(--color-header-bg);
    box-shadow: var(--ui-shadow);
    padding: var(--spacing-12);

    .mobile-profile-wrapper {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        gap: var(--spacing-8);
    }
`;
