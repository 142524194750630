import remove from 'lodash/remove';
import React, { useEffect, useState } from 'react';
import { withErrorBoundary } from '../../../../higher-order-components/error-boundary';
import { onLiveTreeChange } from '../../../../microservices/pusher';
import { loadLivebetTree } from '../../../../microservices/sbgate';
import { loadLivebetTreeDebounced } from '../../../../services/sport';
import {
    subscribeToInplayMatchChanges,
    unsubscribeFromInplayMatchChanges,
} from '../../../../services/sports/inplay-helpers';
import { fakeLiveTree } from '../../../../services/sports/skeleton-mocks';
import { useSubscription } from '../../../../services/subscription';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import Svg from '../../../svg/Svg';
import UiAlert from '../../../ui/alert/UiAlert';
import UiPlaceholder from '../../../ui/placeholder/UiPlaceholder';
import SportTreeLivebetCategory from './category/SportTreeLivebetCategory';
import Wrapper from './styles';
import UiPlaceholderIcon from '../../../ui/placeholder/icon/UiPlaceholderIcon';
import SportSettingsMenuButton from '../../settings-menu/button/SportSettingsMenuButton';
import { LiveTreeCategoryFo } from '@staycool/sbgate-types/dist/categoryTree/types';
import SportCategoryIcon from '../../category-icon/SportCategoryIcon';
import { useStore } from '../../../../hooks/useStore';

function SportTreeLivebet() {
    const [tree] = useStore(stores.sports.liveTree);
    const [liveTreeSelectedCategoryId, setLiveTreeSelectedCategoryId] = useStore(
        stores.sports.liveTreeSelectedCategoryId,
    );
    const [language] = useStore(stores.language);
    const [liveBetTree, setLiveBetTree] = useState<LiveTreeCategoryFo[]>();

    useEffect(() => {
        loadLivebetTree();
        subscribeToInplayMatchChanges(1);
        return () => unsubscribeFromInplayMatchChanges(1);
    }, [language]);

    useEffect(() => {
        if (!tree) {
            return;
        }
        const existingCategory = tree.find((category) => category.sport_category_id === liveTreeSelectedCategoryId);
        if (existingCategory) {
            reorderCategory(liveTreeSelectedCategoryId);
            return;
        }
        setLiveTreeSelectedCategoryId(tree[0]?.sport_category_id);
        setLiveBetTree(tree);
    }, [tree]);

    useSubscription(onLiveTreeChange, loadLivebetTreeDebounced);

    function reorderCategory(categoryId: number | undefined) {
        if (categoryId) {
            setLiveBetTree(reOrderTree(categoryId, tree));
        }
        return setLiveTreeSelectedCategoryId(categoryId);
    }

    function isOpen(category, index) {
        const categoryId = category.sport_category_id;
        return categoryId ? liveTreeSelectedCategoryId === categoryId : index === 0;
    }

    function reOrderTree(categoryId: number, liveTree: LiveTreeCategoryFo[]) {
        const sports = [...liveTree];
        const removedCategories = remove(sports, (category) => category.sport_category_id === categoryId);
        if (removedCategories.length) {
            sports.unshift(removedCategories[0]);
        }
        return sports;
    }

    if (liveBetTree && !liveBetTree.length) {
        return (
            <Wrapper>
                <UiAlert info>{translate('No matches', 'ui.sportsbook')}</UiAlert>
            </Wrapper>
        );
    }

    const treeOrSkeleton = liveBetTree || fakeLiveTree;

    function toggleCategory(category: LiveTreeCategoryFo) {
        return () =>
            setLiveTreeSelectedCategoryId(
                category.sport_category_id === liveTreeSelectedCategoryId ? 0 : category.sport_category_id,
            );
    }

    return (
        <Wrapper>
            <SportSettingsMenuButton />
            {treeOrSkeleton.map((category, index) => (
                <div key={category.sport_category_id || index} className="sport-category-list">
                    <button onClick={toggleCategory(category)} className="sport-category-tab">
                        {category.sport_category_id ? (
                            <SportCategoryIcon small icon={category.sport_icon} />
                        ) : (
                            <UiPlaceholderIcon />
                        )}
                        <span className="sport-name">{category.sportName || <UiPlaceholder />}</span>

                        {isOpen(category, index) ? <Svg icon="arrow-round" rotate={90} /> : <Svg icon="arrow-round" />}
                    </button>

                    {isOpen(category, index) && <SportTreeLivebetCategory category={category} />}
                </div>
            ))}
        </Wrapper>
    );
}

export default withErrorBoundary(SportTreeLivebet);
