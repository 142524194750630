import styled from 'styled-components';
import { lighten, darken, desaturate } from 'polished';
import { mediaQuery } from '../../../../../styles/utils';

export default styled.div`
    padding: 2rem;
    overflow: auto;

    .scoreboard-box {
        display: flex;
        flex-direction: column;
    }
    .scoreboard {
        display: flex;
        width: fit-content;
        max-width: 450px;
        margin: 0 auto;
        border-radius: var(--ui-radius);
        background: ${({ theme }) => lighten(0.06, theme.box.background)};
        box-shadow: var(--ui-shadow), inset 1px 1px 4px -2px ${({ theme }) => lighten(0.4, theme.box.background)};
        padding: 1.5rem 1rem;
        @media ${mediaQuery.isPhone} {
            padding: 0.5rem;
        }
    }
    .set-name {
        display: flex;
    }
    .team {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0.1rem 0.1rem 0.1rem 1.8rem;

        .possession {
            position: absolute;
            left: 0;
            margin-right: 0.5rem;
        }
        .team-name {
            text-align: left;
            max-width: 140px;
            min-width: 140px;
            margin: 0 1rem 0 0;
            padding: 0.5rem 0;
            font-size: 0.8rem;
            display: flex;
            align-items: center;
            @media ${mediaQuery.isPhone} {
                max-width: 110px;
                min-width: 110px;
            }
        }
    }
    .set-points {
        position: relative;
        min-width: 40px;
        padding: 5px 0;
        text-align: center;
        border: 1px solid #313131;
        background: var(--color-body-bg);
        font-size: 0.8rem;
        border-radius: var(--ui-radius);
        &.active {
            background-color: ${({ theme }) => desaturate(0.8, darken(0.35, theme.colors.primary))};
            color: ${({ theme }) => theme.colors.primary};
            border-color: ${({ theme }) => theme.colors.primary};
        }
    }
    .set-name {
        position: absolute;
        bottom: 100%;
        margin-bottom: 5px;
        border-radius: var(--ui-radius);
        height: 20px;
        background: ${({ theme }) => lighten(0.06, theme.box.background)};

        color: var(--font-color-secondary);
        font-size: 0.7rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
