import styled, { css } from 'styled-components';

type StyledProps = {
    size?: 'small' | 'default';
    selected?: boolean;
    isDisabled?: boolean;
};

export default styled.div<StyledProps>`
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-12);

    color: var(--tabs-text-color);
    text-transform: capitalize;
    cursor: pointer;

    ${({ size }) => size && sizes[size].css};

    &:after {
        position: absolute;
        content: '';
        height: 3px;
        bottom: 0px;
        left: 0px;
        right: 0px;

        transition: all 0.3s cubic-bezier(1, 0, 0, 1);
        will-change: transform, opacity;

        border-radius: 10px;
        opacity: 0;
        transform: scale(0, 1);
    }
    &:hover {
        color: var(--tabs-text-color-active);
    }

    ${({ selected }) => {
        if (selected) {
            return states.active;
        }
        if (selected === undefined) {
            return css`
                .active > & {
                    ${states.active}
                }
            `;
        }
    }};

    .tab-icon {
        display: flex;
        justify-content: center;
        margin-right: var(--spacing-12);
    }
    .tab-text {
        white-space: nowrap;
    }

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            pointer-events: none;
            cursor: not-allowed;
            filter: contrast(0.8);
            opacity: 0.6;
            box-shadow: none;
        `};
`;
const states = {
    active: css`
        color: var(--tabs-text-color-active);
        &:after {
            opacity: 1;
            transform: scale(1, 1);
            background-color: var(--tabs-text-color-active);
        }
        &:hover {
            color: var(--tabs-text-color-active);
        }
    `,
};

const sizes = {
    default: {
        css: css`
            font-size: 0.875rem;
        `,
    },
    small: {
        css: css`
            font-size: 0.75rem;
        `,
    },
};
