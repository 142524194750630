import React, { useLayoutEffect, useRef, useState } from 'react';
import Wrapper from './styles';
import CasinoThumbnail from '../../../../thumbnail/CasinoThumbnail';
import { translate } from '../../../../../../services/translate';
import { CasinoGame } from '../../../../../../services/casino/games';
import UiLazyLoaderInterval from '../../../../../ui/lazy-loader/interval/UiLazyLoaderInterval';
import { CasinoCategoryCode } from '../../../../../../services/casino/categories';
import { media } from '../../../../../../stores/media/media';
import { useStore } from '../../../../../../hooks/useStore';

interface Props {
    games: CasinoGame[];
    onGameClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, game: CasinoGame) => void;
}

export default function CasinoPaybackBoosterGamesModalView({ games, onGameClick }: Props) {
    const lazyLoadAmount = 50;
    const [{ isDesktop, isLaptop, isSmallerThanLaptop }] = useStore(media);
    const [filteredGames, setFilteredGames] = useState<CasinoGame[]>([]);
    const [visibleGamesAmount, setVisibleGamesAmount] = useState(lazyLoadAmount);
    const lazyLoaderRef = useRef();

    useLayoutEffect(() => {
        if (lazyLoaderRef && lazyLoaderRef.current) {
            (lazyLoaderRef.current as any).loadingFinished();
        }
    }, [visibleGamesAmount]);

    useLayoutEffect(() => {
        setFilteredGames(games);
        setVisibleGamesAmount(lazyLoadAmount);
    }, [games]);

    function showMoreGames() {
        setVisibleGamesAmount(
            visibleGamesAmount + lazyLoadAmount > games.length ? games.length : visibleGamesAmount + lazyLoadAmount,
        );
    }

    return (
        <Wrapper mobile={!(isDesktop || isLaptop)}>
            <div className="casino-payback-booster-games-modal-view-results-container">
                {Boolean(filteredGames.length) && (
                    <div className="casino-payback-booster-games-modal-view-results-header">
                        <div className="casino-payback-booster-games-modal-view-results-games-found-amount">
                            {translate('Games Found', 'casino.filter')}: {filteredGames.length}
                        </div>
                    </div>
                )}

                {Boolean(filteredGames.length) && (
                    <UiLazyLoaderInterval
                        ref={lazyLoaderRef as any}
                        onLoadMore={showMoreGames}
                        reserveFromBottom={1000}
                    >
                        <div className="casino-payback-booster-games-modal-view-results-body">
                            {filteredGames.slice(0, visibleGamesAmount).map((game) => (
                                <div key={game.id} className="casino-payback-booster-games-modal-view-game-container">
                                    <CasinoThumbnail
                                        game={game}
                                        onGameClick={(event) => onGameClick(event, game)}
                                        thumbnailLocation="PAYBACK_BOOSTER_MORE_GAMES"
                                        categoryCode={CasinoCategoryCode.PAYBACK_BOOSTER}
                                        isCategoryView={isSmallerThanLaptop}
                                    />
                                </div>
                            ))}
                        </div>
                    </UiLazyLoaderInterval>
                )}
            </div>
        </Wrapper>
    );
}
