import React from 'react';
import Wrapper from './styles';
import { getRoute } from '../../../../../services/router';
import UiNavLink from '../../../../ui/nav-link/UiNavLink';
import classNames from 'classnames';
import { stores } from '../../../../../stores';
import { filterStyleProps } from '../../../../../styles/utils';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    children?: any;
    icon: any;
    selected?: boolean;
    route?: string;
    url?: string;
    onClick?: (event?) => void;
}
export default function SportTreeRetailMenuButton({ children, selected, icon, route, url, ...rest }: Props) {
    const [liveMatchesCount] = useStore(stores.sports.liveMatchesCount);

    function getButton() {
        const isLiveCard = url?.includes(`${getRoute('sport.live')}`);
        return (
            <Wrapper className={classNames({ selected: selected })} {...filterStyleProps({ ...rest })}>
                <div className="menu-button-icon">{isLiveCard ? liveMatchesCount : icon}</div>
                <div className="menu-button-text">{children}</div>
            </Wrapper>
        );
    }

    if (route || url) {
        return (
            <UiNavLink {...filterStyleProps({ ...rest })} exact to={route ? getRoute(route) : url}>
                {getButton()}
            </UiNavLink>
        );
    }

    return getButton();
}
