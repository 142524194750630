import styled from 'styled-components';
import { transparentize, lighten } from 'polished';
import { mediaQuery } from '../../../../../styles/utils';

export default styled.div`
    align-items: center;
    background: ${({ theme }) => transparentize(0.2, lighten(0.06, theme.box.background))};
    background-position: center;
    background-size: cover;
    border-radius: var(--ui-radius);
    box-shadow: var(--ui-shadow);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.8rem auto;
    max-width: 550px;
    padding: 1rem;

    @media ${mediaQuery.isPhone} {
        margin: 0;
        padding: 1rem 0;
    }
    .scores {
        align-items: stretch;
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        &.less-margin {
            margin-bottom: 10px;
        }
        .score {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            width: 30%;

            .current-possession {
                position: absolute;
                bottom: -25px;
                width: 20px;
                height: 20px;
                @media ${mediaQuery.isPhone} {
                    bottom: -25px;
                }
            }
        }
        .score-title {
            font-size: 1rem;
            letter-spacing: 1px;
            text-shadow: 1px 1px #000;
            text-align: center;
        }
        .score-number {
            align-items: center;
            background-color: var(--surface-level-1-bg);
            border-radius: 10px;
            display: flex;
            font-size: 1.5rem;
            font-weight: bold;
            height: 50px;
            justify-content: center;
            width: 40px;
        }
        .timeouts-left {
            color: var(--font-color-secondary);
            font-size: 0.7rem;
            margin-top: 3px;
        }
    }

    .quarter-scores-container {
        background-color: var(--surface-level-1-bg);
        border-radius: var(--ui-radius);
        box-shadow: var(--ui-shadow), inset 1px 1px 4px -2px ${({ theme }) => lighten(0.4, theme.box.background)};
        letter-spacing: 1px;
        padding: 1.5rem;
        text-shadow: 1px 1px #000;
        .quarter-scores {
            align-items: center;
            display: flex;
            padding: 0.2rem;
            .quarter-score {
                background-color: var(--surface-level-2-bg);
                border-radius: 10px;
                margin-right: 5px;
                min-width: 28px;
                padding: 5px;
                position: relative;
                text-align: center;
                .quarter-label {
                    bottom: 30px;
                    color: var(--font-color-secondary);
                    font-size: 0.7rem;
                    left: 6px;
                    position: absolute;
                }
                &.active {
                    color: ${({ theme }) => theme.colors.primary};
                    .quarter-label {
                        color: ${({ theme }) => theme.colors.primary};
                    }
                }
            }
            .team-name {
                align-items: center;
                display: flex;
                font-size: 0.8rem;
                margin: 0 1rem 0 0;
                max-width: 140px;
                min-width: 140px;
                padding: 0.5rem 0;
                text-align: left;
                @media ${mediaQuery.isPhone} {
                    max-width: 110px;
                    min-width: 110px;
                }
            }
        }
    }
    .timer {
        align-items: center;
        display: flex;
        justify-content: center;
        min-width: 100px;
        padding-top: 50px;
    }
`;
