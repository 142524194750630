import React, { useEffect, useMemo, useState } from 'react';
import Wrapper from './styles';
import {
    CasinoGame,
    CasinoGameFull,
    CasinoGameProperties,
    getCasinoGameBackgroundUrl,
    getGameImageUrl,
    hasCasinoGamePlayForFun,
    isCasinoGameDisabled,
    setCasinoGameMode,
    navigateToCasinoGamePage,
    GameLogoType,
    CasinoGameMetaData,
} from '../../../../services/casino/games';
import UiResize from '../../../ui/resize/UiResize';
import UiImageLoaderBackground from '../../../ui/image-loader/background/UiImageLoaderBackground';
import UiButton from '../../../ui/button/UiButton';
import { stores } from '../../../../stores';
import { getCasinoGameFull } from '../../../../microservices/casino';
import UiBlobLoader from '../../../ui/blob-loader/UiBlobLoader';
import { translate } from '../../../../services/translate';
import Svg from '../../../svg/Svg';
import classNames from 'classnames';
import { Tooltip } from '@mui/material';
import { useIsTestUser } from '../../../../services/user';
import { useRouter } from '../../../../services/router';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../../services/analytics';
import {
    gameHasRtpVariant,
    getCuracaoNameLabel,
    getRtpByLicenseCountryCode,
} from '../../../../services/rtp-calculator';
import UiFlag from '../../../ui/flag/UiFlag';
import { getLicence, getLicenceName, LICENCE_FLAG_COUNTRY } from '../../../../services/licence';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import { DATE_YEAR_FORMAT, getFormattedDate } from '../../../../services/date';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import { getStoreValue } from '../../../../stores/store/utils';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    game: CasinoGame;
    onClose: () => void;
    onGameClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, game: CasinoGame) => void;
}

export default function CasinoGameInfo({ game, onClose, onGameClick }: Props) {
    const [{ isDesktop, isLaptop }] = useStore(media);
    const [fullGame, setFullGame] = useState<CasinoGameFull>();
    const licence = getLicence();
    const [isFullView, setIsFullView] = useState(false);

    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [blockedProviders] = useStore(stores.casino.maintenance);
    const [hasActiveBonus] = useStore(stores.casino.hasActiveBonus);
    const [gamesMetaDataById] = useStore(stores.casino.gamesMetaDataById);
    const isTestUser = useIsTestUser();
    const isDisabled = useMemo(() => isCasinoGameDisabled(game), [game, blockedProviders, hasActiveBonus]);
    const [isLoginInProgress, setIsLoginInProgress] = useState(false);
    const { navigateTo } = useRouter();
    const [providerLogoUrl, setProviderLogoUrl] = useState<string>();
    const [promotionLogoUrl, setPromotionLogoUrl] = useState<string>();

    useEffect(() => {
        getCasinoGameFull(game.id).then((fullGame) => setFullGame(fullGame));
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.CASINO_GAME_INFO_OPENED);
    }, []);

    useEffect(() => {
        const providerLogo = fullGame?.gameLogos?.find((gameLogo) => gameLogo.type === GameLogoType.PROVIDER);
        if (providerLogo) {
            setProviderLogoUrl(getGameImageUrl(providerLogo.imageName));
        }

        const promotionLogo = fullGame?.gameLogos?.find((gameLogo) => gameLogo.type === GameLogoType.PROMOTION);
        if (promotionLogo) {
            setPromotionLogoUrl(getGameImageUrl(promotionLogo.imageName));
        }
    }, [fullGame]);

    if (isLoginInProgress && isAuthenticated) {
        navigateToCasinoGamePage(navigateTo, game);
    }

    function getRtpDescription() {
        if (fullGame) {
            return fullGame.rtpComment || translate('RTP', 'casino.thumbnail');
        }
        return translate('Loading...', 'ui.common');
    }

    function getLinesDescription() {
        if (fullGame) {
            return fullGame.linesComment;
        }
        return translate('Loading...', 'ui.common');
    }

    function getXWinDescription() {
        if (fullGame) {
            return fullGame.maxWinMultiplierComment;
        }
        return translate('Loading...', 'ui.common');
    }

    function getMinBet() {
        const metaData: CasinoGameMetaData = gamesMetaDataById[game.id];
        if (metaData?.minBet) {
            return formattedAmountWithCurrency(metaData.minBet);
        }

        return game.minBet ? `${formattedAmountWithCurrency(game.minBet)}` : '-';
    }

    function getMaxBet() {
        const metaData: CasinoGameMetaData = gamesMetaDataById[game.id];
        if (metaData?.maxBet) {
            return formattedAmountWithCurrency(metaData.maxBet);
        }

        return game.maxBet ? `${formattedAmountWithCurrency(game.maxBet)}` : '-';
    }

    function openGame(event, isReal) {
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.CASINO_GAME_INFO_PLAY_CLICKED);

        if (isDisabled && !isTestUser) {
            event.preventDefault();
            return;
        }

        setCasinoGameMode(isReal);

        if (isReal) {
            if (isAuthenticated) {
                onGameClick(event, game);
                navigateToCasinoGamePage(navigateTo, game);
            } else {
                event.preventDefault();
                setIsLoginInProgress(true);
                stores.modals.isLoginModalOpen.set(true);
                stores.casino.latestGamePromisedToNavigate.set(game);
            }
        } else {
            onGameClick(event, game);
            navigateToCasinoGamePage(navigateTo, game);
        }
    }

    function getStatElement(stat) {
        return (
            <div key={stat.title} className="casino-game-info-content-stats-container">
                <div className="casino-game-info-content-stats-element">
                    {stat.special && (
                        <Tooltip
                            title={
                                <div style={{ whiteSpace: 'pre-line', fontSize: '11px', fontWeight: 700 }}>
                                    {stat.special}
                                </div>
                            }
                        >
                            <div>
                                <Svg className="casino-game-info-content-stats-special" icon="info-2" />
                            </div>
                        </Tooltip>
                    )}
                    <div className="casino-game-info-content-stats-name">{translate(stat.title, 'casino.stats')}</div>
                    <div className="casino-game-info-content-stats-data">{stat.value}</div>
                </div>
            </div>
        );
    }

    const viewType: 'full' | 'limited' =
        game.gameType === 'live' ||
        (game.type &&
            ['Video Poker', 'Roulette', 'Blackjack', 'Poker', 'Gameshow', 'Dice', 'Bacaratt', 'Bingo'].includes(
                game.type,
            ))
            ? 'limited'
            : 'full';

    return (
        <div style={{ minHeight: 'calc(100vh - 96px)', display: 'flex', alignItems: 'center' }}>
            <Wrapper>
                <Svg className="casino-game-info-close" icon="cross" onClick={onClose} />

                <div className="casino-game-info-content">
                    {game.backgroundImageName && (
                        <div className="casino-game-info-content-background-container">
                            <UiImageLoaderBackground
                                className="casino-game-info-content-background"
                                imageUrl={getCasinoGameBackgroundUrl(game.backgroundImageName, { w: 600 })}
                            />
                        </div>
                    )}

                    <div className="casino-game-info-content-main">
                        <div className="casino-game-info-image-container">
                            <UiImageLoaderBackground
                                className="casino-game-info-image"
                                imageUrl={getGameImageUrl(game.imageName, isDesktop || isLaptop ? 300 : 200)}
                            />

                            {promotionLogoUrl && (
                                <img className="casino-game-info-image-logo-promotion" src={promotionLogoUrl} alt="" />
                            )}
                            {providerLogoUrl && (
                                <img className="casino-game-info-image-logo-provider" src={providerLogoUrl} alt="" />
                            )}
                        </div>
                        <div className="casino-game-info-buttons-container">
                            <UiButton
                                className="casino-game-info-buttons-play"
                                size="large"
                                color="primary"
                                onClick={(event) => openGame(event, true)}
                            >
                                {translate('Play', 'casino.stats')}
                            </UiButton>
                            {hasCasinoGamePlayForFun(game) && !isAuthenticated && (
                                <UiButton
                                    className="casino-game-info-buttons-demo"
                                    size="small"
                                    onClick={(event) => openGame(event, false)}
                                >
                                    {translate('Demo', 'casino.stats')}
                                </UiButton>
                            )}
                        </div>
                    </div>

                    <UiResize hideOverflow={false}>
                        {viewType === 'full' && (
                            <div className="casino-game-info-content-stats">
                                {[
                                    {
                                        title: 'RTP',
                                        value: game.rtp ? `${game.rtp}%` : '-',
                                        special:
                                            game.properties.includes(CasinoGameProperties.RTP_SPECIAL) &&
                                            getRtpDescription(),
                                    },
                                    {
                                        title: 'Hit Rate',
                                        value: game.hitRate ? `${game.hitRate}%` : '-',
                                        comment: translate('comment', 'casino.stats.hit-rate'),
                                    },
                                    {
                                        title: 'Volatility',
                                        value: game.volatility ? translate(game.volatility, 'casino.volatility') : '-',
                                        comment: translate('comment', 'casino.stats.volatility'),
                                    },
                                    {
                                        title: 'Release Date',
                                        value: game.releaseDate ? (
                                            <span>
                                                {getFormattedDate({
                                                    date: game.releaseDate,
                                                    format: DATE_YEAR_FORMAT,
                                                    useDots: true,
                                                })}
                                            </span>
                                        ) : (
                                            '-'
                                        ),
                                    },
                                    {
                                        title: 'Provider',
                                        value: getStoreValue(stores.casino.studiosById)[game.studioId]?.name,
                                    },
                                    {
                                        title: 'Max. Win Multiplier',
                                        value: game.xWin || '-',
                                        special:
                                            game.properties.includes(CasinoGameProperties.X_WIN_SPECIAL) &&
                                            getXWinDescription(),
                                    },
                                ].map((stat) =>
                                    stat.comment ? (
                                        <Tooltip
                                            key={stat.value}
                                            title={
                                                <div
                                                    style={{
                                                        whiteSpace: 'pre-line',
                                                        fontSize: '11px',
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    {stat.comment}
                                                </div>
                                            }
                                        >
                                            {getStatElement(stat)}
                                        </Tooltip>
                                    ) : (
                                        getStatElement(stat)
                                    ),
                                )}
                                {isFullView &&
                                    [
                                        {
                                            title: 'Min. Bet',
                                            value: getMinBet(),
                                        },
                                        {
                                            title: 'Max. Bet',
                                            value: getMaxBet(),
                                        },
                                        {
                                            title: 'Lines',
                                            value: game.lines || '-',
                                            special:
                                                game.properties.includes(CasinoGameProperties.LINES_SPECIAL) &&
                                                getLinesDescription(),
                                        },
                                        // {
                                        //     title: 'Reels',
                                        //     value: game.reels || '-',
                                        // },
                                    ].map((stat) => getStatElement(stat))}
                            </div>
                        )}

                        {viewType === 'limited' && (
                            <div className="casino-game-info-content-stats">
                                {[
                                    {
                                        title: 'RTP',
                                        value: game.rtp ? `${game.rtp}%` : '-',
                                        special:
                                            game.properties.includes(CasinoGameProperties.RTP_SPECIAL) &&
                                            getRtpDescription(),
                                    },
                                    {
                                        title: 'Release Date',
                                        value: game.releaseDate ? (
                                            <span>
                                                {getFormattedDate({
                                                    date: game.releaseDate,
                                                    format: DATE_YEAR_FORMAT,
                                                    useDots: true,
                                                })}
                                            </span>
                                        ) : (
                                            '-'
                                        ),
                                    },
                                    {
                                        title: 'Provider',
                                        value: getStoreValue(stores.casino.studiosById)[game.studioId]?.name,
                                    },
                                    {
                                        title: 'Min. Bet',
                                        value: getMinBet(),
                                    },
                                    {
                                        title: 'Max. Bet',
                                        value: getMaxBet(),
                                    },
                                ].map((stat) => getStatElement(stat))}
                            </div>
                        )}
                    </UiResize>

                    {viewType === 'full' && (
                        <div className="casino-game-info-content-more">
                            <Svg
                                className={classNames('casino-game-info-content-more-icon', { open: isFullView })}
                                icon="drawer"
                                rotate={-90}
                                onClick={() => setIsFullView(!isFullView)}
                            />
                        </div>
                    )}
                </div>
                <div className="casino-game-info-description">
                    <UiResize>
                        {!fullGame && <UiBlobLoader />}
                        {fullGame && fullGame.description}

                        {gameHasRtpVariant(game) && isFeatureAvailable(FEATURE.RTP_CALCULATOR) && (
                            <div className="casino-game-info-rtp-comparison">
                                <div className="casino-game-info-rtp-comparison-text">
                                    {translate('If you play this game', 'casino.rtp-calculator')}
                                </div>
                                <ul className="casino-game-info-rtp-comparison-list">
                                    <li>
                                        <div className="casino-game-info-rtp-comparison-list-element">
                                            Coolbet RTP:
                                            {LICENCE_FLAG_COUNTRY[licence] ? (
                                                <div className="casino-game-info-rtp-comparison-flag">
                                                    <UiFlag flag={LICENCE_FLAG_COUNTRY[licence]} />
                                                </div>
                                            ) : (
                                                ` (${translate(getLicenceName(), 'casino.rtp-calculator')}) `
                                            )}
                                            {game.rtp}%
                                        </div>
                                    </li>
                                    <li>
                                        <div className="casino-game-info-rtp-comparison-list-element">
                                            {translate('Other casinos', 'casino.rtp-calculator')}:{' '}
                                            {LICENCE_FLAG_COUNTRY[licence] ? (
                                                <div className="casino-game-info-rtp-comparison-flag">
                                                    <UiFlag flag={LICENCE_FLAG_COUNTRY[licence]} />
                                                </div>
                                            ) : (
                                                ` (${translate(getLicenceName(), 'casino.rtp-calculator')}) `
                                            )}
                                            {getRtpByLicenseCountryCode(game)}%
                                        </div>
                                    </li>
                                    <li>
                                        <div className="casino-game-info-rtp-comparison-list-element">
                                            {getCuracaoNameLabel()}: {game.minRtpCur}%
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </UiResize>
                </div>
            </Wrapper>
        </div>
    );
}
