import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import UiGroup from '../../../ui/group/UiGroup';
import UiPointsLoader from '../../../ui/points-loader/UiPointsLoader';
import { useStore } from '../../../../hooks/useStore';

export default function GeocomplyFeedbackMinimized() {
    const [geocomplyClient] = useStore(stores.geocomply.client);
    const [serviceError] = useStore(stores.geocomply.serviceError);
    const [unexpectedError] = useStore(stores.geocomply.unexpectedError);

    const isProcessing = geocomplyClient.isAttemptingConnection || geocomplyClient.isAttemptingGeolocation;
    const hasErrors = geocomplyClient.clientError || geocomplyClient.hint || serviceError || unexpectedError;

    const [toastId, setToastId] = useState<number>();

    function getToastContent() {
        const styles: Record<string, React.CSSProperties> = {
            title: {
                fontWeight: 'bold',
            },
            statusConnecting: {
                fontSize: '1rem',
            },
            statusGeolocating: {
                fontSize: '1rem',
            },
            statusDone: {
                fontSize: '1rem',
            },
        };

        if (geocomplyClient.isAttemptingConnection) {
            return (
                <UiGroup vertical>
                    <span style={styles.title}>GeoComply</span>
                    <UiGroup>
                        <span style={styles.statusConnecting}>
                            {translate('Establishing connection', 'ui.geocomply')}
                            <UiPointsLoader />
                        </span>
                    </UiGroup>
                </UiGroup>
            );
        }

        if (geocomplyClient.isAttemptingGeolocation) {
            return (
                <UiGroup vertical>
                    <span style={styles.title}>GeoComply</span>
                    <UiGroup>
                        <span style={styles.statusGeolocating}>
                            {translate('Geolocating', 'ui.geocomply')}
                            <UiPointsLoader />
                        </span>
                    </UiGroup>
                </UiGroup>
            );
        }

        if (!isProcessing && !hasErrors) {
            return (
                <UiGroup vertical>
                    <span style={styles.title}>GeoComply</span>
                    <UiGroup>
                        <span style={styles.statusDone}>{translate('Successfully geoverified', 'ui.geocomply')}</span>
                    </UiGroup>
                </UiGroup>
            );
        }
    }

    useEffect(() => {
        return () => {
            if (toastId) {
                toast.dismiss(toastId);
            }
        };
    }, [toastId]);

    useEffect(() => {
        const toastContent = getToastContent();

        if (toastId) {
            toast.update(toastId, {
                render: toastContent,
            });
        } else {
            const toastParameters = {
                hideProgressBar: true,
                closeButton: false,
                autoClose: false,
                closeOnClick: false,
                position: 'bottom-left',
            };

            const newToastId = toast.info(toastContent, toastParameters as any);
            setToastId(newToastId);
        }
    }, [geocomplyClient.isAttemptingConnection, geocomplyClient.isAttemptingGeolocation, hasErrors, toastId]);

    return null;
}
