import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { PaybackBooster, PaybackBoosterProgramGames } from '../services/casino/payback-booster';

const getUrl = (url) => getServiceUrl('casino-cashback', url);

export async function claimCasinoPaybackBooster(paybackBoosterId: string): Promise<void> {
    return httpPost(getUrl('cashback/claim'), { paybackBoosterId });
}

export async function collectPaybackBoosterBalance(): Promise<{ balance: number; currency: string }> {
    return httpPost(getUrl('balance/collect'));
}

export async function getCasinoGameOfTheDayPaybackBooster(serverGameId?: string): Promise<PaybackBooster> {
    return httpGet(getUrl('cashback/game-of-the-day'), { serverGameId });
}

export async function getUserPaybackBoosterBalance(): Promise<{ balance: number; currency: string }> {
    return httpGet(getUrl('balance/user'));
}

export async function getPaybackBoosterProgramGames(ids: number[]): Promise<PaybackBoosterProgramGames[]> {
    return httpGet(getUrl('program/games'), { ids });
}
