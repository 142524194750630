import { Market } from '@staycool/location';
import { CategoryMatch, CmsGeneralSetting } from '../services/sports/types';
import { logger } from '../services/logger';
import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import moment from 'moment';
import { OddPromotion, PromotionTypes } from '../services/cms-promotion';
import { getUserCountry } from '../services/user';
import { Language } from '../services/language';
import { loadOddsForFoLineQueue } from '../services/sports/odds';
import keyBy from 'lodash/keyBy';
import { storageGet } from '../services/storage';
import { isRetail } from '../services/environment';
import { retail } from '../stores/retail/retail';

const getUrl = (url) => getServiceUrl('cms', url);

export async function getBannerMessages(market: Market) {
    const bannerMessages = getStoreValue(stores.cms.bannerMessages);
    const lastRequest = getStoreValue(stores.cms.bannerMessageLastRequest);
    const isLessThan10MinutesAgo = lastRequest && moment().isBefore(moment(lastRequest).add(10, 'minutes'));
    if (isLessThan10MinutesAgo) {
        return bannerMessages;
    }
    const url = getUrl('banner-message/user');
    const response = await httpGet<CmsBannerMessage[]>(url, { market });
    response.forEach((message) => {
        const hiddenEndTime = storageGet(`banner-${message.id}`);
        message.isHidden = Number(hiddenEndTime) > new Date().getTime();
    });
    stores.cms.bannerMessages.set(response);
    stores.cms.bannerMessageLastRequest.set(new Date().toISOString());
    return response;
}

export async function getFoTranslations(language: string, fallbackLanguage: string) {
    const url = getUrl(`translations/fo/${language}`);
    try {
        return await httpGet<{ translationsByKey: Record<string, string>; lastUpdated: string }>(url, {
            fallbackLanguage,
            version: process.env.REACT_APP_VERSION || 'development',
        });
    } catch (error) {
        logger.error('CmsMicroservice', 'getFoTranslations', error);
        return { translationsByKey: {}, lastUpdated: null };
    }
}

export async function getIncrementalTranslationUpdates(lastUpdatedUnixTime: string) {
    const url = getUrl(
        `translations/incremental/${lastUpdatedUnixTime}?v=${process.env.REACT_APP_VERSION || 'development'}`,
    );

    try {
        return await httpGet<TranslationUpdate[]>(url);
    } catch (error) {
        logger.error('CmsMicroservice', 'getIncrementalTranslationUpdates', error);
        return [];
    }
}

export async function reportMissingTranslations(missingTranslationReports) {
    logger.dev('CmsMicroservice', 'reportMissingTranslations', missingTranslationReports);
    const url = getUrl('translations/missing/report');
    return httpPost(url, missingTranslationReports);
}

export async function getRetailHotEvents() {
    const { bet_shop_id } = getStoreValue(retail.retailTerminal);
    const key = `retail-hot-event-cards-${bet_shop_id}`;
    const url = getUrl('settings');
    return (await httpGet<Record<string, SettingResponse>>(url, { keys: key }))?.[0];
}

export async function getRetailTopBanner() {
    const { bet_shop_id } = getStoreValue(retail.retailTerminal);
    const key = `retail-top-banner-${bet_shop_id}`;
    const url = getUrl('settings');
    return (await httpGet<Record<string, SettingResponse>>(url, { keys: key }))?.[0];
}

export async function getRetailBottomBanners() {
    const { bet_shop_id } = getStoreValue(retail.retailTerminal);
    const key = `retail-bottom-banner-${bet_shop_id}`;
    const url = getUrl('settings');
    return (await httpGet<Record<string, SettingResponse>>(url, { keys: key }))?.[0];
}

export async function getRulePage() {
    const { bet_shop_id } = getStoreValue(retail.retailTerminal);
    const isRetaiLayout = isRetail();
    const key = `retail-rules-page-${isRetaiLayout ? bet_shop_id : 'web'}`;
    const url = getUrl('settings');
    return (await httpGet<Record<string, SettingResponse>>(url, { keys: key }))?.[0];
}

export async function getCmsSettings() {
    const url = getUrl('settings');
    return await httpGet<CmsGeneralSetting[]>(url);
}

async function getMatchPromotions(matches): Promise<PromotionTypes[]> {
    const url = getUrl(`match-promotion`);
    const getMatchIds = (matches: CategoryMatch[]) => matches.flatMap((match) => match.id);
    try {
        const query = {
            matchIds: getMatchIds(matches).join(','),
            country: getUserCountry(),
        };
        matches = await httpGet(url, query);
    } catch (error) {
        logger.error('CmsMicroservice', 'getMatchPromotions', error);
    }
    return matches;
}

export async function populateMatchPromotionsByMatches(matches: CategoryMatch[]) {
    if (!matches.length) {
        return;
    }
    const matchPromotions = await getMatchPromotions(matches);
    if (!matchPromotions) {
        return;
    }
    const newPromotions = matchPromotions
        .map((promotion) => {
            const match = matches.find((match) => match.id === promotion?.match_id);
            return {
                ...promotion,
                sport_id: match?.sport,
                match_betting_end: match?.betting_end,
            };
        })
        .filter(Boolean);
    const newPromotionsByMatchId = keyBy(newPromotions, (x) => x.match_id);
    stores.marketing.matchPromotionsByMatchIds.set((state) => ({ ...state, ...newPromotionsByMatchId }));
    newPromotions
        .filter((promotion): promotion is OddPromotion => promotion.type === 'odd')
        .map(({ market }) => loadOddsForFoLineQueue([[market.id]]));
}

export async function getArticleBySlug(pageSlug: string, languageOverride?: Language) {
    const language = languageOverride || getStoreValue(stores.language);
    const url = getUrl(`articles/public/by-slug/${pageSlug}/${language}`);
    return httpGet<ArticleResponse>(url);
}

export async function getArticleForPreviewBySlug(pageSlug: string) {
    const language = getStoreValue(stores.language);
    const url = getUrl(`articles/preview/${pageSlug}/${language}`);
    return httpGet<ArticleResponse>(url);
}

export function getArticleById(id: string | number) {
    const url = getUrl(`articles/public/${id}`);
    return httpGet<ArticleResponse>(url);
}

export function checkIsUserEligibleForContentBySegment(segmentId: string) {
    const url = getUrl(`articles/user-segment-check/${segmentId}`);
    return httpGet<{ isUserInSegment: boolean }>(url);
}

export function getPromotionAnalyticsInfo(promotion: PromotionTypes) {
    return {
        sports: promotion.sport_id,
        country: getUserCountry(),
        type: promotion.type,
        name: promotion.name,
    };
}

export type CmsBannerMessage = {
    displayPage: BannerMessageDisplayPage;
    endDate: Date;
    id: number;
    isHidden?: boolean;
    market: string;
    severityLevel: BannerMessageSeverityLevel;
    startDate: Date;
    translationKey: string;
};

export enum BannerMessageDisplayPage {
    deposit = 'deposit',
    header = 'header',
    payout = 'payout',
}

export enum BannerMessageSeverityLevel {
    info = 'info',
    failure = 'failure',
    warning = 'warning',
}

export enum CmsSettings {
    REGISTRATION_PAGE_IMAGE = 'registration-page-image',
    POKER_PAGE_BANNER_IMAGE = 'poker-page-banner-image',
    POKER_PAGE_WELCOME_BANNER = 'poker-page-welcome-banner',
    MARKETING_BADGE_IMAGE = 'marketing-badge-image',
    SPONSORSHIP_BANNER = 'sponsorship-banner',
    LICENSE_BANNER = 'license-banner',
}

export interface SponsorshipBanner {
    imageURL: string;
    imageRedirectLink: string;
}

export type TranslationUpdate = {
    id: string;
    key: string;
    redirect_key: string;
    text: Record<Language, string>;
};

export interface LicenseBannerSetting {
    translationKey: string;
    image?: string;
    screens: FoScreensForBanners[];
    imageRedirectLink?: string;
}

export enum FoScreensForBanners {
    MAIN_PAGE = 'main_page',
    RESPONSIBLE_GAMING = 'responsible_gaming',
    SELF_EXCLUSION = 'self_exclusion',
    FAQ = 'faq',
    TERMS_AND_CONDITIONS = 'terms_and_conditions',
    PLAYER_AGREEMENT = 'player_agreement',
    CUSTOMER_CARE = 'customer_care',
}

export interface SettingResponse {
    key: string;
    value: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface TopBanner {
    image: string;
    title: string;
    link?: string;
    timeInterval?: number;
}

export interface RulesPage {
    rulesPage: {
        htmlString: string;
    };
}

export interface HotEvent {
    content: string;
    event: {
        id?: number;
        name?: string;
        sportId?: number;
        categoryId?: number;
        hotCardType: HotCardType;
        parentName?: string;
    };
    matchId?: number;
    image: string;
    title: string;
    categoryId?: number;
    categoryName?: string;
    marketTypeId?: number;
    matchMarkets?: CategoryMatch;
    isShowOdds?: boolean;
}

export enum HotCardType {
    HotCardOdds = 'HotCardOdds',
    LeagueHotCard = 'LeagueHotCard',
    MatchHotCard = 'MatchHotCard',
    EmptyHotCard = 'EmptyHotCard',
}

interface ArticleResponse {
    body: string;
    category: string;
    created: Date;
    id: string | number;
    mobile: boolean;
    published: boolean;
    seo_desc: Record<string, string>;
    seo_img: Record<string, string>;
    seo_title: Record<string, string>;
    site: string;
    slug: Record<string, string>;
    title: string;
    updated: Date;
    updated_by: string | number;
}
