import styled, { css } from 'styled-components';
import { mediaQuery } from '../../styles/utils';

type StyledProps = {
    isUserFromFinlandAndLoggedOut: boolean;
};
export default styled.div<StyledProps>`
    position: relative;

    .blog-page-content {
        display: flex;
        gap: var(--spacing-12);
        margin-top: 0.8rem;
        ${({ isUserFromFinlandAndLoggedOut }) =>
            isUserFromFinlandAndLoggedOut &&
            css`
                filter: blur(10px);
                z-index: 1;
            `};
        @media ${mediaQuery.isPhone} {
            flex-flow: column nowrap;
        }
    }

    .logged-out-finnish-users-message {
        position: absolute;
        z-index: 2;
        top: 100px;
        left: 50%;
        transform: translate(-50%, -50%);
        @media ${mediaQuery.isTablet} {
            top: 75px;
        }
        @media ${mediaQuery.isPhone} {
            top: 75px;
        }
    }

    @media ${mediaQuery.isPhone} {
        flex-flow: column nowrap;
    }

    .blog-promotions {
        align-self: flex-start;
        flex: 0 0 250px;
    }

    .blog-promotions {
        position: sticky;
        top: ${({ theme }) => theme.layout.gutter};
        max-width: 250px;

        @media ${mediaQuery.isPhone} {
            display: none;
        }
        @media ${mediaQuery.isTablet} {
            display: none;
        }
    }
`;
