import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Wrapper from './styles';
import SportLobbyCategories from '../../components/sport/lobby-categories/SportLobbyCategories';
import { getRoute } from '../../services/router';
import SportSidebarLive from '../../components/sport/sidebar/live/SportSidebarLive';
import SportSidebarSoon from '../../components/sport/sidebar/soon/SportSidebarSoon';
import Navigator from '../../components/navigator/Navigator';
import { stores } from '../../stores';
import VirtualSportsBetslip from '../../components/virtual-sports/betslip/VirtualSportsBetslip';
import VirtualSportsSidebar from '../../components/virtual-sports/sidebar/VirtualSportsSidebar';
import SportSidebarRight from '../../components/sport/sidebar/right/SportSidebarRight';
import MainLayout from '../main/MainLayout';
import { FEATURE, isFeatureAvailable } from '../../services/feature';
import { loadBlockedProductsByPlayerCountry } from '../../microservices/users';
import UiBlobLoader from '../../components/ui/blob-loader/UiBlobLoader';
import UiAppLoader from '../../components/ui/app-loader/UiAppLoader';
import RetailHotEvents from '../../components/retail/hot-events/RetailHotEvents';
import SportTreeRetail from '../../components/sport/tree/retail/SportTreeRetail';
import SportNavigationB from '../../components/sport/navigation-b/SportNavigationB';
import SportPhoneNavigationB from '../../components/sport/phone/navigation-b/SportPhoneNavigationB';
import SportPhoneNavigationC from '../../components/sport/phone/navigation-c/SportPhoneNavigationC';
import { isDevelopment, isRetail, isStage } from '../../services/environment';
import ComboCards from '../../components/combo-cards/ComboCards';
import { retail } from '../../stores/retail/retail';
import { media } from '../../stores/media/media';
import { useStore } from '../../hooks/useStore';

interface Props {
    children: any;
}

export default function SportLayout({ children }: Props) {
    const isRetailLayout = isRetail();
    const [{ isDesktop, isLaptop, isTablet, isPhone }] = useStore(media);
    const [, setIsRetailKeyboardVisible] = useStore(retail.retailKeyboard.isVisible);
    const leftSidebarComponents = useMemo(() => getLeftSidebarComponents(), []);
    const rightSidebarComponents = useMemo(() => getRightSidebarComponents(), []);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [isNavigationHidden] = useStore(stores.sports.isNavigationHidden);
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);
    const betSlipRef = useRef<HTMLDivElement>(null);
    const [retailModalEvent] = useStore(retail.retailModals.retailModalEvent);
    const [isRetailModalExpires] = useStore(retail.retailModals.isRetailModalExpires);
    const [isNoBalanceModalVisible] = useStore(retail.retailModals.isNoBalanceModalVisible);
    const [retailPrintingModal] = useStore(retail.retailModals.retailPrintingModal);
    const [isRetailModalLoyalty] = useStore(retail.retailModals.isRetailModalLoyalty);
    const hasActiveModal = Boolean(
        isRetailModalLoyalty ||
            isNoBalanceModalVisible ||
            retailModalEvent ||
            isRetailModalExpires ||
            retailPrintingModal?.isPrinting,
    );

    const handleScroll = useCallback(() => {
        setIsRetailKeyboardVisible(false);
    }, []);

    useEffect(() => {
        const div = betSlipRef.current;
        div?.addEventListener('scroll', handleScroll);
    }, [handleScroll]);

    useEffect(() => {
        if (!isAuthenticated) {
            loadBlockedProductsByPlayerCountry();
        }
    }, [isAuthenticated]);

    function getLeftSidebarComponents() {
        return [
            { route: getRoute('sport.live'), component: SportSidebarLive },
            !isRetailLayout &&
                isFeatureAvailable(FEATURE.VIRTUAL_SPORTS) && {
                    route: getRoute('virtual-sports'),
                    component: VirtualSportsSidebar,
                },
            {
                route: getRoute('sport'),
                component: SportSidebarSoon,
            },
        ];
    }

    function getRightSidebarComponents() {
        return [
            { route: getRoute('sport.recommendations'), component: SportSidebarRight, exact: true },
            { route: getRoute('sport.live'), component: SportSidebarRight, exact: true },
            { route: getRoute('sport.incoming-bets'), component: SportSidebarRight, exact: true },
            !isRetailLayout &&
                isFeatureAvailable(FEATURE.VIRTUAL_SPORTS) && {
                    route: getRoute('virtual-sports'),
                    component: VirtualSportsBetslip,
                },
            { route: `${getRoute('sport')}/:sportId`, component: SportSidebarRight },
        ];
    }

    const [userSettings] = useStore(stores.sports.userSettings);
    const [limits] = useStore(stores.sports.limits);
    const dependenciesLoaded = userSettings && limits !== null;

    function SportLayoutComponent() {
        return (
            <Wrapper $isRetailLayout={isRetailLayout} $hasActiveModal={hasActiveModal} $isLayoutB={isLayoutB}>
                {isRetailLayout && (
                    <div className="hot-event-card">
                        <RetailHotEvents />
                    </div>
                )}

                {isRetailLayout && <SportTreeRetail />}

                <div className="sport-content">
                    {!isRetailLayout && (isDesktop || isLaptop) && !isLayoutB && (
                        <div className="sport-menu">
                            <Navigator routes={leftSidebarComponents} noRedirect />
                        </div>
                    )}

                    <div className="main-content">
                        {!isRetailLayout && !isLayoutB && <SportLobbyCategories />}
                        {!isRetailLayout && isLayoutB && !isNavigationHidden && <SportNavigationB />}
                        {(isStage() || isDevelopment()) && <ComboCards />}
                        <div className="sport-matches">{dependenciesLoaded ? children : <UiAppLoader />}</div>
                    </div>

                    {(isDesktop || isLaptop) && (
                        <div className="sport-sidebar" id="sport-sidebar" ref={betSlipRef}>
                            {dependenciesLoaded ? (
                                <Navigator routes={rightSidebarComponents} noRedirect />
                            ) : (
                                <UiBlobLoader />
                            )}
                        </div>
                    )}
                </div>
                {isFeatureAvailable(FEATURE.SPORT_PHONE_NAVIGATION_C)
                    ? (isPhone || isTablet) && <SportPhoneNavigationC />
                    : (isPhone || isTablet) && <SportPhoneNavigationB />}
            </Wrapper>
        );
    }

    return !isRetailLayout ? <MainLayout>{SportLayoutComponent()}</MainLayout> : SportLayoutComponent();
}
