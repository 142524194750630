import { useEffect } from 'react';
import { stores } from '../../../stores';
import { getRoute, isActiveRoute } from '../../../services/router';
import {
    initializeResponsibleGaming,
    resetResponsibleGamingTimeoutStatuses,
} from '../../../services/responsible-gaming';
import { getLicence } from '../../../services/licence';
import { loadProfile } from '../../../services/user';
import { Licence } from '@staycool/location';
import { useStore } from '../../../hooks/useStore';

export default function SystemResponsibleGaming() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [user] = useStore(stores.user);
    const isResponsibleGamingLimitsRouteActive = isActiveRoute(getRoute('responsible-gaming.set-limits'));

    useEffect(() => {
        if (isAuthenticated) {
            if (isResponsibleGamingLimitsRouteActive) {
                loadProfile({ isThrowingOnError: true });
            }
            initializeResponsibleGaming();
        } else {
            resetResponsibleGamingTimeoutStatuses();
        }
    }, [isResponsibleGamingLimitsRouteActive, isAuthenticated]);

    useEffect(() => {
        const licence = getLicence();
        const isResponsibleGamingPanelVisible = Boolean(user && [Licence.SWEDEN].includes(licence));

        stores.responsibleGaming.isResponsibleGamingPanelVisible.set(isResponsibleGamingPanelVisible);

        stores.responsibleGaming.isMaltaPanelVisible.set(getLicence() === Licence.MALTA);
    }, [user, isAuthenticated]);

    return null;
}
