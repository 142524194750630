import React, { useEffect } from 'react';
import Wrapper from './styles';
import UiCountdown from '../../../../ui/countdown/UiCountdown';
import moment from 'moment';

interface Props {
    endTime: string;
    onTimerEnd: () => void;
}

export default function CmsQuizCollectionQuizTimer({ endTime, onTimerEnd }: Props) {
    useEffect(() => {
        if (moment(endTime) > moment()) {
            initializeInterval();
        }
    }, []);

    function initializeInterval() {
        const timerInterval = setInterval(() => {
            if (moment(endTime) < moment()) {
                onTimerEnd();
                clearInterval(timerInterval);
            }
        }, 1000);
    }

    return (
        <Wrapper>
            <UiCountdown endDate={endTime} className="submit-timer">
                {({ days, daysLabel, hours, hoursLabel, minutes, minutesLabel, seconds, secondsLabel }) => (
                    <>
                        <span className="time">
                            <span className="time-label">{daysLabel}</span>
                            <span className="time-number">{days} </span>
                        </span>
                        <span className="time">
                            <span className="time-label">{hoursLabel}</span>
                            <span className="time-number">{hours} </span>
                        </span>
                        <span className="time">
                            <span className="time-label">{minutesLabel}</span>
                            <span className="time-number">{minutes} </span>
                        </span>
                        <span className="time">
                            <span className="time-label">{secondsLabel}</span>
                            <span className="time-number">{seconds} </span>
                        </span>
                    </>
                )}
            </UiCountdown>
        </Wrapper>
    );
}
