import React from 'react';
import Wrapper from './styles';
import { colors } from '@mui/material';
import { ComboCardType } from '@staycool/sports-types/combo-card';

interface Props {
    type: ComboCardType;
}
type DisplayStyleByType = Record<ComboCardType, { bgColor: string; textColor: string }>;

export default function ComboCardsItemType({ type }: Props) {
    if (!type) {
        return null;
    }

    const displayStyleByType: DisplayStyleByType = {
        'BALANCED ODDS': {
            bgColor: colors.yellow[600],
            textColor: 'black',
        },
        'HIGH RISK / HIGH WIN': {
            bgColor: colors.red['A700'],
            textColor: 'white',
        },
        'HOT PICK': {
            bgColor: colors.red['A700'],
            textColor: 'white',
        },
        'BOOKMAKERS CHOICE': {
            bgColor: colors.cyan['A200'],
            textColor: 'black',
        },
        'PERFECT COMBO': {
            bgColor: colors.green['A400'],
            textColor: 'black',
        },
        'COMBO CARD': {
            bgColor: colors.blue['A400'],
            textColor: 'white',
        },
        'BOOSTED PRICE': {
            bgColor: colors.purple['A400'],
            textColor: 'white',
        },
    };

    const { bgColor, textColor } = displayStyleByType[type];
    return (
        <Wrapper bgColor={bgColor} textColor={textColor}>
            <span className="combo-card-type">{type}</span>
        </Wrapper>
    );
}
