import React from 'react';
import Wrapper from './styles';
import { getClient } from '../../services/environment';

interface Props {
    size?: number;
    compact?: boolean;
    isDigitalBoard?: boolean;
}

export default function Logo({ size, compact, isDigitalBoard }: Props) {
    // TODO: use theme to pick the logo?
    const client = getClient();

    return (
        <Wrapper size={size} isDigitalBoard={isDigitalBoard} className="brand-logotype">
            {compact ? (
                <img src={`${window.coolb2b?.baseUrl || ''}/assets/images/logo/${client}-compact.svg`} alt={client} />
            ) : (
                <img src={`${window.coolb2b?.baseUrl || ''}/assets/images/logo/${client}.svg`} alt={client} />
            )}
        </Wrapper>
    );
}
