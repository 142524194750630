import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { logger } from '../services/logger';
import { camelCaseKeys } from '../services/util';

const getUrl = (url) => getServiceUrl('tournaments', url);

export async function getTournamentList() {
    try {
        return camelCaseKeys(await httpGet<any>(getUrl('tournament/list')));
    } catch (error) {
        logger.error('TournamentsMicroservice', 'getTournamentList', error);
    }
    return [];
}

export async function getTournament(tournamentId) {
    try {
        return camelCaseKeys(await httpGet<any>(getUrl(`tournament/${tournamentId}`)));
    } catch (error) {
        logger.error('TournamentsMicroservice', 'getTournament', error);
    }
    return null;
}

export async function getRound(roundId) {
    try {
        return camelCaseKeys(await httpGet<any>(getUrl(`round/${roundId}`)));
    } catch (error) {
        logger.error('TournamentsMicroservice', 'getRound', error);
    }
    return null;
}

export async function postPrediction(roundId, predictionsByMatchId) {
    const requestBody: any = {
        round_id: roundId,
        match_predictions: [],
    };
    for (const matchId of Object.keys(predictionsByMatchId)) {
        requestBody.match_predictions.push({
            match_id: parseInt(matchId),
            home_score: parseInt(predictionsByMatchId[matchId].homeScore),
            away_score: parseInt(predictionsByMatchId[matchId].awayScore),
        });
    }
    const url = getUrl(`prediction/`);
    return camelCaseKeys(await httpPost(url, requestBody));
}

export async function getRoundPrediction(roundId, userAlias) {
    let url = getUrl(`prediction/?round_id=${roundId}`);
    if (userAlias) {
        url = getUrl(`prediction/compare/?round_id=${roundId}&user_alias=${userAlias}`);
    }

    try {
        return camelCaseKeys(await httpGet<any>(url));
    } catch (error) {
        logger.error('TournamentsMicroservice', 'getRoundPrediction', error);
    }
    return null;
}

export async function getTournamentLeaderboard(tournamentId: number, page: number, userAlias?: string) {
    let url = `leaderboard/${tournamentId}/?page=${page}&limit=10`;
    if (userAlias) {
        url += `&user_alias=${userAlias}`;
    }
    url = getUrl(url);
    try {
        return camelCaseKeys(await httpGet<any>(url));
    } catch (error) {
        logger.error('TournamentsMicroservice', 'getTournamentLeaderboard', error);
    }
}

export async function getRoundLeaderboard(roundId, page, userAlias?: string) {
    let url = `round/leaderboard/${roundId}/?page=${page}&limit=10`;
    if (userAlias) {
        url += `&user_alias=${userAlias}`;
    }
    url = getUrl(url);
    try {
        return camelCaseKeys(await httpGet<any>(url));
    } catch (error) {
        logger.error('TournamentsMicroservice', 'getRoundLeaderboard', error);
    }
}
