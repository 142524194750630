import { useEffect } from 'react';
import { environment } from '../../stores/environment/environment';
import { media } from '../../stores/media/media';
import { useStore } from '../../hooks/useStore';

export default function Zendesk() {
    const [{ ZENDESK }] = useStore(environment);
    const [{ isPhone }] = useStore(media);

    useEffect(() => {
        if (!window.zE) {
            const script = document.createElement('script');
            script.id = 'ze-snippet';
            script.src = `${ZENDESK?.URL}?key=${ZENDESK?.KEY}`;
            script.onload = function () {
                if (isPhone) {
                    window.zE(function () {
                        window.zE.hide();
                    });
                }
            };

            document.body.appendChild(script);
            window.zESettings = {
                webWidget: {
                    offset: {
                        mobile: {
                            horizontal: '15px',
                            vertical: '55px',
                        },
                    },
                    chat: {
                        departments: {
                            enabled: [],
                        },
                    },
                },
            };
        }

        return () => window.$zopim?.livechat.window.hide();
    }, []);

    return null;
}
