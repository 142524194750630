import capitalize from 'lodash/capitalize';
import invert from 'lodash/invert';
import { COUNTRY } from './country';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { isStage } from './environment';
import { Province } from './types';
import { Licence } from '@staycool/location';
import { environment } from '../stores/environment/environment';
import { storageGet } from './storage';

export enum LicenceRequirementSteps {
    OCCUPATION,
    DEPOSIT_LIMIT,
    LOSS_LIMIT,
    PERSONAL_ID,
}

const PRODUCTION_LICENCES = [Licence.ESTONIA, Licence.MALTA, Licence.MEXICO, Licence.SWEDEN, Licence.USA_NEVADA];
const STAGE_ONLY_LICENCES = [];
const PROVINCE_WITH_LICENCE = [Province.USA_NEVADA];

export const LICENCE_NAME_BY_LICENCE_CODE = invert(Licence);
const LICENCE_NAME_BY_PROVINCE = invert(Province);

export function getLicenceName() {
    return capitalize(LICENCE_NAME_BY_LICENCE_CODE[getLicence()].toLowerCase());
}

export function getFormattedLicenceName() {
    return LICENCE_NAME_BY_LICENCE_CODE[getLicence()].toLowerCase().replace('_', '-');
}

function getIpBasedLicence(ipCountry: COUNTRY, provinceName?: Province): Licence {
    if (provinceName && PROVINCE_WITH_LICENCE.includes(provinceName)) {
        const province = Licence[LICENCE_NAME_BY_PROVINCE[provinceName]];
        return Licence[LICENCE_NAME_BY_LICENCE_CODE[province]];
    }
    return Licence[LICENCE_NAME_BY_LICENCE_CODE[ipCountry]] || Licence.MALTA;
}

function isLicenceEnabledInEnvironment(ipCountryBasedLicence: Licence) {
    if (isStage()) {
        return Boolean(PRODUCTION_LICENCES.concat(STAGE_ONLY_LICENCES).includes(ipCountryBasedLicence));
    }
    return Boolean(PRODUCTION_LICENCES.includes(ipCountryBasedLicence));
}

function getDefaultLicence(availableLicences: string[]) {
    const allLicences = Object.values(Licence);
    const firstAvailableLicence = availableLicences.find(
        (licence) => allLicences.includes(licence as Licence) && isLicenceEnabledInEnvironment(licence as Licence),
    ) as Licence | undefined;
    return firstAvailableLicence || Licence.MALTA;
}

export function isWithLicence(licence: Licence) {
    return getLicence() === licence;
}

export function getLicence(): Licence {
    const user = getStoreValue(stores.user);
    if (user?.licence) {
        return user.licence;
    }
    const availableLicences = getStoreValue(environment).AVAILABLE_LICENSES;
    const appLicence = storageGet<Licence>('app-licences');
    if (appLicence && availableLicences.includes(appLicence) && isLicenceEnabledInEnvironment(appLicence)) {
        return appLicence;
    }

    const provinceName = getStoreValue(stores.ipProvince);
    const ipCountry = getStoreValue(stores.ipCountry);

    const ipLicence = getIpBasedLicence(ipCountry, provinceName);

    if (availableLicences.includes(ipLicence) && isLicenceEnabledInEnvironment(ipLicence)) {
        return ipLicence;
    }

    return getDefaultLicence(availableLicences);
}

export const LICENCE_FLAG_COUNTRY = {
    [Licence.ESTONIA]: COUNTRY.ESTONIA,
    [Licence.MEXICO]: COUNTRY.MEXICO,
    [Licence.SWEDEN]: COUNTRY.SWEDEN,
    [Licence.USA_NEVADA]: COUNTRY.USA,
};
