import React, { useState, useEffect, SyntheticEvent } from 'react';
import Wrapper from './styles';
import { getActiveCurrency } from '../../../../services/currency';
import CasinoPaybackBoosterBalanceTransfer from './transfer/CasinoPaybackBoosterBalanceTransfer';
import { translate } from '../../../../services/translate';
import classNames from 'classnames';
import { stores } from '../../../../stores';
import { getUserPaybackBoosterBalance } from '../../../../microservices/casino-payback-booster';
import { logger } from '../../../../services/logger';
import { useSocketSubscribeUnsubscribe, useSocketTopicEvents } from '../../../../microservices/pusher';
import { Tooltip } from '@mui/material';
import Svg from '../../../svg/Svg';
import isNumber from 'lodash/isNumber';
import UiButton from '../../../ui/button/UiButton';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    withoutSubscribers?: boolean;
    isCompact?: boolean;
    isTransferConfirming?: any;
    isMobileMenu?: boolean;
    setIsPaybackBoosterMenuVisible?: (value) => void;
}

export default function CasinoPaybackBoosterBalance({
    isCompact,
    isTransferConfirming = () => {},
    withoutSubscribers = false,
    isMobileMenu = false,
    setIsPaybackBoosterMenuVisible = () => {},
}: Props) {
    const currency = getActiveCurrency();

    const [isConfirming, setIsConfirming] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [displayedBalance, setDisplayedBalance] = useState(0);
    const [balance, setBalance] = useState(0);
    const [balanceChange, setBalanceChange] = useState(0);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    async function loadBalance() {
        try {
            setIsLoading(true);
            const balanceResponse = await getUserPaybackBoosterBalance();
            setBalance(balanceResponse.balance);
            setIsLoading(false);
        } catch (error) {
            logger.error('CasinoPaybackBoosterBalance', 'loadBalance', error);
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            loadBalance();
        }
    }, [isAuthenticated, withoutSubscribers]);

    useEffect(() => {
        setBalanceChange(balance - displayedBalance);
        setDisplayedBalance(balance);
    }, [balance]);

    useSocketSubscribeUnsubscribe('casino-payback-booster-balance-update', {
        params: [],
        guardFunction: () => !withoutSubscribers,
        watchParams: [isAuthenticated],
    });
    useSocketTopicEvents('casino-payback-booster-balance-update', (message) => onBalanceUpdate(message.balance));

    function startTransfer() {
        if (!isThereEnoughPaybackToAddToBalance) {
            return setIsTooltipOpen(!isTooltipOpen);
        }
        setIsConfirming(true);
        isTransferConfirming();
    }

    function onConfirmed(newBalance?: number | SyntheticEvent) {
        if (newBalance !== undefined && isNumber(newBalance)) {
            setBalance(newBalance);
        }
        setBalanceChange(0);

        setIsConfirming(false);
        isTransferConfirming();
    }

    function onBalanceUpdate(newBalance) {
        setBalanceChange(0);
        setBalance(newBalance);
    }

    function getAmount(amount: number) {
        return formattedAmountWithCurrency(amount);
    }

    function checkIfThereIsEnoughPaybackToAddToBalance(amount: number): boolean {
        const minimumAmount = 0.01;
        return amount >= minimumAmount;
    }

    const isThereEnoughPaybackToAddToBalance = checkIfThereIsEnoughPaybackToAddToBalance(balance);

    if (isMobileMenu) {
        return (
            <Wrapper isMobileMenu={isMobileMenu}>
                <div className="balance-text" onClick={() => setIsPaybackBoosterMenuVisible(true)}>
                    {Boolean(balanceChange) && (
                        <div
                            className={classNames('balance-change', {
                                fadeOutDown: balanceChange > 0,
                                fadeOutUp: balanceChange <= 0,
                            })}
                        >
                            {balanceChange > 0 ? '+' : ''}
                            {getAmount(balanceChange)}
                        </div>
                    )}
                    <Svg className="icon" icon="payback-booster" />
                    <span className="amount">{getAmount(displayedBalance)}</span>
                </div>
            </Wrapper>
        );
    }

    return (
        <Wrapper
            isCompact={isCompact}
            onMouseLeave={() => {
                setIsTooltipOpen(false);
            }}
        >
            {!isConfirming && (
                <UiButton
                    isLoading={isLoading}
                    onClick={startTransfer}
                    className={classNames({
                        'can-add-to-balance': isThereEnoughPaybackToAddToBalance,
                    })}
                    color={isThereEnoughPaybackToAddToBalance ? 'primary' : 'default'}
                >
                    <div className="balance-text">
                        {Boolean(balanceChange) && (
                            <div
                                className={classNames('balance-change', {
                                    fadeOutDown: balanceChange > 0,
                                    fadeOutUp: balanceChange <= 0,
                                })}
                            >
                                {balanceChange > 0 ? '+' : ''}
                                {getAmount(balanceChange)}
                            </div>
                        )}
                        <span
                            className="balance-text-title"
                            dangerouslySetInnerHTML={{
                                __html: translate('Booster<br>balance', 'casino.payback-booster'),
                            }}
                        />
                        <span className="amount">{getAmount(displayedBalance)}</span>
                    </div>
                </UiButton>
            )}
            <Tooltip
                title={`${translate(
                    'There is not enough payback amount to transfer to the balance. The minimum required amount is 0.01',
                    'casino.payback-booster',
                )} ${currency}`}
                enterDelay={200}
                enterTouchDelay={0}
                open={isTooltipOpen}
            >
                <div>{isConfirming && <CasinoPaybackBoosterBalanceTransfer onConfirmed={onConfirmed} />}</div>
            </Tooltip>
        </Wrapper>
    );
}
