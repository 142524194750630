import React from 'react';
import Wrapper from './styles';
import UiModal from '../../../ui/modal/UiModal';
import Snippet from '../../../snippet/Snippet';
import UiBox from '../../../ui/box/UiBox';
import UiCloseButton from '../../../ui/close-button/UiCloseButton';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export default function CasinoPaybackBoosterTermsAndConditions({ isOpen, onClose }: Props) {
    const snippetKey = {
        paybackBooster: 'ui.casino.payback-booster-terms-and-conditions',
    };

    const fitToScreenSizeHack = {
        overflow: 'auto',
        maxHeight: '100vh',
    };

    return (
        <Wrapper open={isOpen} onClose={onClose}>
            <UiModal open={isOpen} onClose={onClose}>
                <UiBox style={fitToScreenSizeHack}>
                    <UiCloseButton onClick={onClose} className="close-button" />
                    <Snippet snippetKey={snippetKey['paybackBooster']} />
                </UiBox>
            </UiModal>
        </Wrapper>
    );
}
