import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

type StyledProps = {
    focused?: boolean;
};
export default styled.div<StyledProps>`
    ${({ focused }) =>
        focused &&
        css`
            .search-cross-container .search-cross {
                opacity: 1;
            }
        `}
    .casino-payback-booster-games-modal-body-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 20px 10px 15px;
        overflow: hidden;
        .search {
            display: flex;
            justify-content: flex-start;
            width: 100%;
        }
        .ui-slider-simple {
            display: flex;
            flex-grow: 1;
        }
        @media ${mediaQuery.isPhone} {
            flex-direction: column;
            padding: 10px 12px 0px 12px;
        }
        @media (max-width: 330px) {
            padding: 0px;
        }
    }
    .casino-payback-booster-games-modal-title-container {
        position: relative;
        margin: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        @media ${mediaQuery.isPhone} {
            margin: 20px 20px 10px 20px;
        }
        .casino-payback-booster-games-modal-title-adornment {
            width: 35px;
            height: 35px;
            color: var(--color-highlight);
            margin-right: 10px;
        }
        .casino-payback-booster-games-modal-title-label {
            font-size: 1.5rem;
            text-transform: uppercase;
            font-weight: 700;
            @media ${mediaQuery.isPhone} {
                font-size: 1rem;
            }
        }
        .primary-color {
            color: var(--color-highlight);
        }
        .casino-payback-booster-games-modal-title-close {
            background-color: unset;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
        }
    }
    .casino-payback-booster-games-modal-body-container > div {
        @media ${mediaQuery.isDesktop} {
            margin-right: 40px;
        }
        @media ${mediaQuery.isTablet} {
            margin-right: var(--spacing-4);
        }
        @media ${mediaQuery.isPhone} {
            margin-bottom: var(--spacing-4);
        }
    }

    .casino-search-slider-body {
        width: 70%;
        height: 100%;
        white-space: nowrap;
    }

    .casino-slider-mobile {
        display: flex;
        width: 95vw;
        overflow-x: scroll;
    }
`;
