import { useEffect } from 'react';
import { getPayAndPlaySettingsByCountry } from '../../../microservices/payments';
import { getBackofficeLoginToken, logout } from '../../../services/auth';
import { FEATURE, isFeatureAvailable } from '../../../services/feature';
import { logger } from '../../../services/logger';
import { stores } from '../../../stores';
import { Country } from '@staycool/location';
import { getStoreValue } from '../../../stores/store/utils';
import { useStore } from '../../../hooks/useStore';

export default function SystemLogin() {
    const [ipCountry] = useStore(stores.ipCountry);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [isOfficeIp] = useStore(stores.isOfficeIp);

    useEffect(() => {
        const isLoginFromBackoffice = Boolean(getBackofficeLoginToken());
        const isLoginModalOpen = getStoreValue(stores.modals.isLoginModalOpen);
        if (isLoginFromBackoffice && !isLoginModalOpen) {
            stores.modals.isLoginModalOpen.set(true);
        }
        if (isLoginFromBackoffice && isAuthenticated) {
            logout();
            stores.isAuthenticated.set(false);
        }
    }, []);

    useEffect(() => {
        if (isFeatureAvailable(FEATURE.PAY_AND_PLAY)) {
            loadPayAndPlayStatus();
        }
    }, [isAuthenticated, ipCountry]);

    async function loadPayAndPlayStatus() {
        try {
            const payAndPlaySettings = await getPayAndPlaySettingsByCountry(ipCountry as unknown as Country);
            if (payAndPlaySettings) {
                stores.payAndPlaySettings.set(payAndPlaySettings);

                const isPnpEnabledInClientsIpCountry = payAndPlaySettings.status === 'enabled';
                const isClientFromCoolbetOffice = payAndPlaySettings.status === 'office' && isOfficeIp;
                stores.isPayAndPlayEnabled.set(isPnpEnabledInClientsIpCountry || isClientFromCoolbetOffice);
            }
        } catch (error) {
            logger.error('SystemLogin', 'loadPayAndPlayStatus', error);
        }
    }

    return null;
}
