import styled, { css } from 'styled-components';
import PolarBearBullet from './polar-bear-bullet.png';
import { mediaQuery } from '../../../styles/utils';

const newFormatRoot = css`
    h1,
    h2,
    h3,
    h4,
    h5,
    table,
    h6 {
        font-weight: 500;
        margin-top: 0;
        padding: 0.2rem 2rem;
    }
    h2,
    h3,
    h4,
    h5 {
        padding-top: 1rem;
    }
    h1 {
        font-size: 1.6rem;
        padding-top: 2rem;
    }
    img {
        width: 100%;
    }
    p {
        padding: 0.2rem 2rem;
        font-size: 1rem;
        color: #4c4c4c;
        line-height: 1.5;
    }
`;

const oldFormatRoot = css`
    h1 {
        font-size: 1.6rem;
        font-weight: 500;
        margin-top: 0;
    }
    table {
        font-weight: 500;
        margin-top: 0;
        padding: 2rem;
    }
    p {
        font-size: 1rem;
        color: #4c4c4c;
        text-align: justify;
    }
`;

type StyledProps = {
    isNewFormat: boolean;
};

export default styled.div<StyledProps>`
    position: relative;
    display: flex;
    color: #4c4c4c;
    h1,
    h2,
    h3,
    h4,
    ul,
    u {
        color: ${({ theme }) => theme.colors.black};
    }
    li {
        padding: 0.5rem 0;
        list-style-position: inside;
    }
    ul {
        list-style-image: url('${PolarBearBullet}');
    }
    ${(props) => (props.isNewFormat ? newFormatRoot : oldFormatRoot)}

    .blog-article-content {
        min-width: 0;
        display: flex;
        @media ${mediaQuery.isTablet} {
            padding: 0 ${({ theme }) => theme.layout.gutter} 0 0;
            display: block;
        }
        @media ${mediaQuery.isPhone} {
            display: block;
        }
        .active-article {
            flex: 2;
            white-space: normal;
            margin-bottom: ${({ theme }) => theme.layout.gutter};
            max-width: 550px;
            @media ${mediaQuery.isPhone} {
                padding: 0 ${({ theme }) => theme.layout.gutter};
            }
            @media ${mediaQuery.isTablet} {
                padding: ${({ theme }) => theme.layout.gutter} 0 0 ${({ theme }) => theme.layout.gutter};
            }
            .ui-box-margin {
                position: relative;
                margin: 0;
                border-radius: 10px;
                overflow: hidden;
                background-color: #efefef;
                @media ${mediaQuery.isDesktop} {
                    min-width: 550px;
                }
                @media ${mediaQuery.isPhone} {
                    min-width: 320px;
                }
                .article-category {
                    position: absolute;
                    top: 14px;
                    left: 14px;
                    background-color: var(--color-highlight);
                    color: ${({ theme }) => theme.buttons.primaryTextColor};
                    text-transform: uppercase;
                    font-weight: normal;
                    border-radius: 100px;
                    letter-spacing: 1px;
                    font-size: 0.7rem;
                    padding: 0.2rem 0.5rem;
                    @media ${mediaQuery.isPhone} {
                        top: 5px;
                        left: 5px;
                    }
                }

                .post-creation-date {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    letter-spacing: 1px;
                    border-radius: 100px;
                    background-color: #b1b1b1;
                    color: #ffffff;
                    padding: 0.2rem 0.5rem;
                    font-size: 0.6rem;
                    text-transform: uppercase;
                    @media ${mediaQuery.isPhone} {
                        top: 9px;
                        right: 16px;
                        font-size: 0.875rem;
                    }
                }
                .preview {
                    padding: 2rem 2rem 0 2rem;
                }
                .read-more-btn {
                    display: none;
                }
            }
            .read-more {
                p {
                    padding: 0.2rem 2rem;
                }

                h1,
                h2,
                h3,
                h4,
                table,
                li,
                h5 {
                    list-style-position: inside;
                    padding: 0.5rem 2rem;
                    margin: 0;
                    font-weight: 500;
                }
            }
        }
        .side-articles {
            ${(props) =>
                props.isNewFormat &&
                css`
                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6 {
                        padding: 0;
                    }
                    p {
                        padding: 0;
                    }
                `}
            max-width: 300px;
            flex: 1;
            padding-right: ${({ theme }) => theme.layout.gutter};
            @media ${mediaQuery.isPhone} {
                max-width: initial;
            }
        }
    }
`;
