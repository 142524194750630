import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

type StyledProps = {
    $isPhone?: boolean;
    lowercase?: boolean;
    $isTwoToneTheme?: boolean;
    size: 'default' | 'large' | 'small' | 'tiny';
    type: 'menu' | 'tab';
    selected?: boolean;
};

export default styled.div<StyledProps>`
    position: relative;

    font-size: 0.75rem;
    color: ${({ $isTwoToneTheme }) => ($isTwoToneTheme ? 'var(--tabs-text-color-inverse)' : 'var(--tabs-text-color)')};
    text-transform: ${({ lowercase }) => (lowercase ? '' : 'uppercase')};
    cursor: pointer;
    font-weight: var(--tabs-text-weight);
    ${({ theme }) => theme.optimizeFontRendering};

    display: flex;
    align-items: center;
    flex-shrink: 0;

    &[disabled] {
        pointer-events: none;
        cursor: not-allowed;
        filter: contrast(0.8);
        opacity: 0.6;
        box-shadow: none;
    }

    ${({ size }) => sizes[size].css};
    ${({ $isPhone }) =>
        !$isPhone &&
        css`
            &:hover {
                .icon {
                    color: var(--tabs-text-color-active);
                }
                .text {
                    color: var(--tabs-text-color-active);
                    &:after {
                        width: 35%;
                        left: 0;
                    }
                }
            }
        `};

    .text {
        position: relative;

        display: flex;
        align-items: center;
        width: 100%;

        cursor: pointer;
        transform: translate(0, 0);
        transition: transform 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s, font-size 0.1s linear, color 0.1s linear;
        letter-spacing: 0.2px;

        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: 0;
        > * {
            margin-right: 0.4rem;
        }
        .content {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:after {
            display: block;
            content: '';

            position: absolute;
            left: 0;
            bottom: -0.5rem;

            height: 3px;
            border-radius: 100px;
            background: var(--tabs-text-color-active);
            transition: width 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s,
                font-size 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s, left 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s,
                transform 0.5s cubic-bezier(0.22, 0.61, 0.36, 1) 0s;
            width: 0;
            overflow: hidden;
            max-width: 30px;
        }
        > span {
            width: 100%;
        }
    }

    .icon {
        transition: color 0.3s cubic-bezier(0.22, 0.61, 0.36, 1), transform 0.6s cubic-bezier(0.22, 0.61, 0.36, 1);
        transform: translate3d(0, 0, 0);
        margin-right: 0.8rem;
        display: flex;
        align-items: center;
        svg {
            width: 1.25rem;
        }
    }

    img {
        margin-right: 0.8rem;
    }

    ${({ type }) =>
        type === 'menu' &&
        css`
            text-transform: initial;
            font-weight: var(--tabs-text-weight);
            ${({ theme }) => theme.optimizeFontRendering};
            .icon {
                margin-right: 0.8rem;
                width: 3rem;
                height: 1.25rem;
            }
        `};

    ${({ type }) =>
        type !== 'menu' &&
        css`
            ${({ theme }) => theme.optimizeFontRendering}
            font-size: 0.75rem;
        `};

    ${({ selected }) => {
        if (selected) {
            return states.active;
        }
        if (selected === undefined) {
            return css`
                .active > & {
                    ${states.active}
                }
            `;
        }
    }};

    .badge {
        position: absolute;
        right: -0.8rem;
        bottom: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 17px;
        height: 17px;

        background-color: var(--color-info);
        border-radius: 50%;

        font-size: 0.65rem;
        color: #000;
        text-align: center;
        font-weight: var(--font-weight-medium);
        line-height: 1;

        transform: translate(0, 0);
        transition: transform 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s;
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            right: 0;
        }
    }

    .new,
    .updated {
        position: absolute;
        right: -0.8rem;
        bottom: 100%;

        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 10px;
        padding: 0.3rem;

        color: white;
        text-transform: uppercase;
        font-size: 0.6rem;
        font-weight: var(--font-weight-medium);

        transform: translate(0, 0);
        transition: transform 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s;
    }
    .new {
        background-color: ${({ theme }) => theme.colors.red};
    }
    .updated {
        background-color: #00bfff;
        color: #000;
    }
`;

const sizes = {
    large: {
        css: css`
            font-size: 1rem;
            @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
                font-size: 1.1rem;
            }
            .icon {
                width: 26px;
                margin-right: 0.8rem;
                svg {
                    width: 26px;
                }
            }
        `,
    },
    default: {
        css: css`
            font-size: 0.875rem;
            .icon {
                width: 21px;
                svg {
                    width: 21px;
                }
            }
        `,
    },
    small: {
        css: css`
            font-size: 0.75rem;
            .icon {
                width: 16px;

                svg {
                    width: 16px;
                }
            }
        `,
    },
};

const states = {
    active: css`
        color: var(--tabs-text-color-active);
        .text {
            &:after {
                background: var(--color-highlight);
                width: 35%;
                left: 0;
                max-width: 30px;
            }
        }
    `,
};
