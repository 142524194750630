import styled from 'styled-components';

export default styled.div`
    position: relative;
    padding-top: 52%;
    overflow: hidden;
    margin-top: var(--spacing-16);
    iframe {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        border: none;
    }
`;
