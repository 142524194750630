import styled, { css } from 'styled-components';

export default styled.div`
    position: relative;
    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .tickets-container {
        position: relative;
        > *:not(:last-child) {
            margin-bottom: var(--spacing-8);
        }
    }
    .tickets-buttons {
        > *:not(:last-child) {
            margin-bottom: var(--spacing-4);
        }
    }
    .ticket-filters {
        padding: 0 var(--spacing-12) var(--spacing-12) 0;
        ${({ isCompactView }) =>
            isCompactView &&
            css`
                display: flex;
                justify-content: center;
            `};

        > *:not(:last-child) {
            margin-bottom: var(--spacing-12);
        }
    }
`;
