import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { getBlogArticle, getBlogPreview } from '../../../microservices/l10n';
import BlogArticleSideArticles from './side-articles/BlogArticleSideArticles';
import Loader from '../../loader/Loader';
import { translate } from '../../../services/translate';
import { stores as store } from '../../../stores';
import { useRouter } from '../../../services/router';
import { logger } from '../../../services/logger';
import Seo from '../../seo/Seo';
import FourOFour from '../../four-o-four/FourOFour';
import { BlogPost, getExcerpt } from '../../../services/cms-blog';
import { DATE_YEAR_FORMAT, getFormattedDate } from '../../../services/date';

export default function BlogArticle() {
    const { slug, draftId } = useRouter().params;
    const [isLoadingFailed, setIsLoadingFailed] = useState(false);
    const [article, setArticle] = useState<BlogPost>();

    useEffect(() => {
        if (draftId) {
            loadPreviewArticle();
        } else {
            loadBlogArticleBySlug();
            return () => store.blog.activeArticle.set(undefined);
        }
    }, [slug, draftId]);

    async function loadBlogArticleBySlug() {
        try {
            const articleResponse = await getBlogArticle(slug);
            setArticle(articleResponse);
            store.blog.activeArticle.set(articleResponse);
        } catch (error) {
            logger.error('BlogArticle', 'loadBlogArticleBySlug', error);
            setIsLoadingFailed(true);
        }
    }

    async function loadPreviewArticle() {
        try {
            const previewArticle = await getBlogPreview(draftId);
            setArticle(previewArticle.doc);
        } catch (error) {
            logger.error('BlogArticle', 'loadPreviewArticle', error);
            setIsLoadingFailed(true);
        }
    }

    return (
        <Wrapper isNewFormat={Boolean(article?.body_raw)}>
            {isLoadingFailed && <FourOFour />}
            {article && (
                <>
                    <Seo
                        title={article.seo_title || article.title}
                        description={article.seo_desc || getExcerpt(article.body, 200)}
                        image={article.seo_img || ''}
                    />

                    <div className="blog-article-content">
                        <div className="active-article">
                            {!article && !isLoadingFailed && <Loader />}
                            {article && (
                                <div className="ui-box-margin">
                                    <div className="article-category">
                                        {translate(article.category, 'ui.blog.categories')}
                                    </div>
                                    {
                                        <div className="post-creation-date">
                                            <span>
                                                {getFormattedDate({
                                                    date: article.created,
                                                    format: DATE_YEAR_FORMAT,
                                                    useDots: true,
                                                })}
                                            </span>
                                        </div>
                                    }
                                    <div
                                        className="active-article-content"
                                        dangerouslySetInnerHTML={{ __html: article.body }}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="side-articles">
                            <BlogArticleSideArticles forArticle={article} />
                        </div>
                    </div>
                </>
            )}
        </Wrapper>
    );
}
