import { COUNTRY } from '../services/country';
import { createStores } from './store/utils';

export const verification = createStores({
    isRegistrationPhoneVerification: false,
    isVerificationActive: false,
    data: {
        email: undefined as string | undefined,
        phoneNumber: undefined as string | undefined,
        phonePrefix: undefined as string | undefined,
        country: undefined as COUNTRY | undefined,
        temporaryToken: undefined as string | undefined,
        userId: undefined as string | undefined,
    },
});
