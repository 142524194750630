import React, { useEffect, useState } from 'react';
import { GEOCOMPLY_REASON, verifyGeoLocation } from '../../../services/geocomply/geocomply';
import { translate } from '../../../services/translate';
import { stores } from '../../../stores';
import { getStoreValue } from '../../../stores/store/utils';
import UiButton from '../../ui/button/UiButton';

export default function GeocomplyRetryGeolocationButton() {
    const [isButtonVisible, setIsButtonVisible] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsButtonVisible(true);
        }, 2000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    function retryGeoLocation() {
        const lastReason = getStoreValue(stores.geocomply.lastReason) as GEOCOMPLY_REASON;
        verifyGeoLocation(lastReason)?.catch();
    }

    if (!isButtonVisible) {
        return null;
    }

    return (
        <UiButton onClick={retryGeoLocation} color="default">
            {translate('Retry GeoLocation', 'ui.geocomply')}
        </UiButton>
    );
}
