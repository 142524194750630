import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

type StyledProps = {
    isCompact: boolean;
    isStatusActive: boolean;
};
export default styled.div<StyledProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100%;
    border: 2px solid ${({ theme }) => theme.paybackBooster.primaryColor};
    border-radius: 10px;
    transition: all 200ms ease-in-out;
    ${({ isCompact }) =>
        isCompact &&
        css`
            cursor: pointer;
        `};

    @media ${mediaQuery.isTablet}, ${mediaQuery.isPhone} {
        flex-direction: column;
        overflow: visible;
    }

    .booster-games {
        padding: 0 10px;
        flex: 1;
        .games-container {
            display: flex;
            flex-direction: row;
        }
    }

    .close-button {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 3;
        border-radius: 100px;
    }

    .controls {
        position: absolute;
        bottom: 0;
        @media ${mediaQuery.isTablet}, ${mediaQuery.isPhone} {
            bottom: -10px;
            width: 100%;
        }
    }

    .promo {
        border-right: 2px solid ${({ theme }) => theme.paybackBooster.primaryColor};
        border-radius: var(--ui-radius);
        min-width: 500px;
        max-width: 500px;
        margin-right: -10px;
        z-index: 2;
        background: #2a113a;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        ${({ isCompact }) =>
            isCompact &&
            css`
                border-right: none;
            `};
        @media ${mediaQuery.isLaptop} {
            min-width: 400px;
            max-width: 400px;
            ${({ isCompact }) =>
                isCompact &&
                css`
                    min-width: 100%;
                    max-width: 100%;
                `};
        }

        @media ${mediaQuery.isTablet}, ${mediaQuery.isPhone} {
            min-width: 100%;
            max-width: 100%;
            border-radius: 10px;
            margin: 0;
            border: none;
        }
        ${({ isCompact }) =>
            isCompact &&
            css`
                min-width: 100%;
                max-width: 100%;
            `};
    }
    .info {
        position: relative;
        background-color: ${({ theme }) => theme.paybackBooster.primaryColor};
        border-radius: 5px;
        overflow: hidden;
        flex: 1;

        display: flex;
        flex-direction: column;

        @media ${mediaQuery.isTablet}, ${mediaQuery.isPhone} {
            border: none;
            border-radius: 0;
        }
    }
`;
