import { Country } from '@staycool/location';
import { getServiceUrl, httpGet, httpPost, httpPut } from '../services/api';
import { COUNTRY } from '../services/country';
import { isRetail } from '../services/environment';
import { FEATURE, isFeatureAvailable } from '../services/feature';
import { logger } from '../services/logger';
import { User, UserQueryParameters } from '../services/types';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { AnnualReport } from '../stores/responsible-gaming';
import { PRODUCT } from '../types/common';

const getUrl = (url) => getServiceUrl('users', url);

export function getMe(query?: UserQueryParameters) {
    const url = getUrl('me');
    return isRetail() ? ({} as any) : httpGet<User>(url, query);
}

export async function getGeneratedUserData(country: COUNTRY) {
    return httpGet<{
        alias: string;
        phonePrefix: string;
        phoneNumber: string;
        password: string;
        email: string;
        personalId: string;
        gender: string;
        firstName: string;
        lastName: string;
        fathersName?: string;
        mothersName?: string;
        address: string;
        zip: string;
        city: string;
        province?: string;
        birthDate: Record<string, string>;
    }>(getUrl('fake-user'), { country });
}

export function postZignsecBankVerification(bankIdentifier: string) {
    const url = getUrl(`user-bank-verification/?targetUrl=${window.location.href}&bankIdentifier=${bankIdentifier}`);
    return httpPost<{ redirectUrl: string }>(url);
}

export function saveChileanPersonalId(personalId: string) {
    return httpPut(getUrl('required-fields/chile/'), { personalId });
}

export async function getIndustries() {
    return httpGet<Industry[]>(getUrl('industries'));
}

export async function getSessionStartTime() {
    const url = getUrl('user-login-history/session-start');
    return httpGet<{ sessionStart: string }>(url);
}

export async function getSourceOfWealthForm() {
    return httpGet<{ fields: { fieldId: string; label: string }[] }>(getUrl('source-of-wealth'));
}

export async function saveSourceOfWealthForm(data: { fieldId: string; label: string }[]) {
    return httpPost<void>(getUrl('source-of-wealth'), data);
}

export async function saveLoqateAddress(addressLookupId: string) {
    return httpPut<{
        id: string;
        country: string;
        province: string;
        zip: string;
        city: string;
        address: string;
    }>(getUrl('me/loqate'), { addressLookupId });
}

export async function validateEmail(email: string) {
    return httpPost<{ isDisposableEmail: boolean }>(getUrl('validators/public/email'), { email });
}

export async function validateName(data: {
    externalFirstOrFullName: string;
    externalLastName?: string;
    shouldCloseAccountOnMismatch?: boolean;
}) {
    return httpPost<{ areNamesSimilar: boolean; comment: string }>(getUrl('validators/name'), data);
}

export function validateAlias(alias?: string) {
    return httpPost<{ result: boolean }>(getUrl('validators/public/alias'), {
        alias,
    });
}

export function validatePhoneNumber(country: Country, phonePrefix: string, phoneNumber: string) {
    return httpPost<{ result: boolean }>(getUrl('validators/public/phoneNumber'), {
        country,
        phoneNumber,
        phonePrefix,
    });
}

export async function loadBlockedProductsByPlayerCountry() {
    if (isRetail() || !isFeatureAvailable(FEATURE.BLOCKED_PRODUCTS_CHECK)) {
        return;
    }
    const getCasinoUrl = (url) => getServiceUrl('casino/fo', url);
    const blockedProductsUrl = getUrl('get-blocked-products/');
    const casinoUrl = getCasinoUrl('virtual-sports-providers');
    const blockedProducts = {};
    try {
        const sportsbookBlocked: { sportsbook: boolean } = await httpGet(blockedProductsUrl);
        blockedProducts[PRODUCT.SPORTSBOOK] = sportsbookBlocked.sportsbook;
    } catch (error) {
        const sportsbookMaintenanceState = { endDate: null, liveBet: true, preMatch: true };
        stores.maintenance.sportsbookMaintenanceState.set(sportsbookMaintenanceState);
        logger.error('UsersMicroservice', 'loadBlockedProductsByPlayerCountry', error);
    }

    try {
        const country = getStoreValue(stores.ipCountry);
        const blockedVirtualSportsProviders: { name: string }[] = await httpGet(casinoUrl, { country });
        blockedVirtualSportsProviders.forEach((provider) => {
            const formattedName = 'VS_' + provider.name.toUpperCase().replace(' ', '_');
            blockedProducts[formattedName] = true;
        });
    } catch (error) {
        stores.maintenance.isVirtualSportsHighlightMaintenance.set(true);
        logger.error('UsersMicroservice', 'loadBlockedProductsByPlayerCountry', error);
    }
    stores.blockedProducts.set(blockedProducts);
}

export async function getCountries() {
    return httpGet<UserCountry[]>(getUrl('countries'));
}

export function getAnnualReport(): Promise<AnnualReport> {
    return httpGet(getUrl('get-annual-report/'));
}

export function getAccessHistory() {
    return httpGet<LoginHistory[]>(getUrl('user-login-history'));
}

export function acceptTermsAndConditions() {
    return httpPost<{ message: string }>(getUrl('terms-and-conditions/accept/'));
}

export function getUserProductStatus(userId: string) {
    return httpGet<UserProductStatus>(getUrl(`/user-product-status/${userId}`));
}

interface UserProductStatus {
    userId: string;
    racebookClosed: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export type Industry = {
    id: number;
    title: string;
};

export type UserCountry = {
    iso2: string;
    title: string;
};

export type LoginHistory = {
    created: string;
    loginTime: string;
    ipAddress: string;
    device: string;
    os: string;
    browser: string;
    cookie: string;
    ipCountry: string;
    socialLoginService: string;
    appId: string;
    deviceId: string;
    loginSessionId: string;
    message: string;
    reason: string;
};
