import React, { useState } from 'react';
import Wrapper from './styles';
import { translate } from '../../../services/translate';
import { getRoute } from '../../../services/router';
import { stores } from '../../../stores';
import PromotionBonusEntry from '../../promotion/bonus-entry/PromotionBonusEntry';
import { logout } from '../../../services/auth';
import { FEATURE, isFeatureAvailable } from '../../../services/feature';
import UiButton from '../../ui/button/UiButton';
import Svg from '../../svg/Svg';
import ContactUsButton from '../../contact-us-button/ContactUsButton';
import { withLayout } from '../../../higher-order-components/layout';
import BlogPage from '../../../pages/blog/BlogPage';
import Logo from '../../logo/Logo';
import UiBrandLine from '../../ui/brand-line/UiBrandLine';
import CoolbetHeaderLanguages from '../../coolbet/header/languages/CoolbetHeaderLanguages';
import AuthPayAndPlayButton from '../../auth/pay-and-play/button/AuthPayAndPlayButton';
import AuthRegisterButton from '../../auth/register/button/AuthRegisterButton';
import AuthLoginButton from '../../auth/login/button/AuthLoginButton';
import { isExistingUser } from '../../../services/user';
import { formattedAmountWithCurrency } from '../../../services/currency';
import AccountMenuCurrencySelect from './currency-select/AccountMenuCurrencySelect';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

export default function AccountMenu() {
    const [wallet] = useStore(stores.wallet);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [{ isPhone }] = useStore(media);
    const [newBlogPostsCount] = useStore(stores.blog.newBlogPostsCount);
    const [unreadMessagesTotal] = useStore(stores.crm.unreadMessagesTotal);
    const [isChangingCurrency, setIsChangingCurrency] = useState(false);
    const isUserExists = isExistingUser();

    const primaryLinks = [
        {
            label: translate('My Bonuses', 'ui.account'),
            route: getRoute('promotions.bonuses'),
            dataTest: 'menu-my-bonuses',
            icon: 'promotions-ico',
            isAvailable: isFeatureAvailable(FEATURE.PROMOTIONS),
        },
        {
            label: translate('Payout', 'ui.common'),
            route: getRoute('payout'),
            dataTest: 'menu-payout',
            icon: 'withdrawal-ico',
            isAvailable: true,
        },
        {
            label: translate('Deposit', 'ui.common'),
            route: getRoute('deposit'),
            dataTest: 'menu-deposit',
            icon: 'plus-sign',
            isAvailable: true,
        },
    ].filter((route) => route.isAvailable);
    const firstLinks = [
        {
            label: translate('My Account', 'ui.header'),
            route: getRoute('account.profile'),
            dataTest: 'menu-my-account',
            icon: 'user-icon',
            isAvailable: isAuthenticated,
        },

        {
            label: translate('Promotions', 'ui.account'),
            route: getRoute('promotions'),
            dataTest: 'menu-my-bonuses',
            icon: 'promotions-ico',
            isAvailable: isFeatureAvailable(FEATURE.PROMOTIONS),
        },

        {
            label: translate('Inbox', 'ui.account'),
            route: getRoute('inbox'),
            icon: 'sms-icon',
            isAvailable: isFeatureAvailable(FEATURE.INBOX) && isAuthenticated,
            badge: unreadMessagesTotal,
        },
    ].filter((route) => route.isAvailable);

    const secondLinks = [
        {
            label: translate('Bet History', 'ui.account'),
            route: getRoute('bet-history.sports'),
            dataTest: 'menu-bet-history',
            icon: 'bethistory-ico',
            isAvailable: isFeatureAvailable(FEATURE.SPORTSBOOK) && isAuthenticated,
        },

        {
            label: translate('Transactions', 'ui.account'),
            route: getRoute('transactions'),
            dataTest: 'menu-transactions',
            icon: 'transactions-ico',
            isAvailable: isAuthenticated,
        },
    ].filter((route) => route.isAvailable);
    const fourthLinks = [
        {
            label: translate('Responsible gaming', 'ui.common'),
            route: getRoute('responsible-gaming.set-limits'),
            dataTest: 'menu-responsible-gaming',
            icon: 'anchor',
            isAvailable: isFeatureAvailable(FEATURE.RESPONSIBLE_GAMING),
        },
        {
            label: translate('Gambling Statistics', 'ui.account'),
            route: getRoute('gambling-statistics'),
            dataTest: 'menu-gambling-statistics',
            icon: 'betting-performance',
            isAvailable: isFeatureAvailable(FEATURE.GAMBLING_STATISTICS) && isAuthenticated,
        },
    ].filter((route) => route.isAvailable);

    const fifthLinks = [
        {
            label: translate('Refer a friend', 'ui.account'),
            route: getRoute('refer-a-friend'),
            dataTest: 'menu-refer-a-friend',
            icon: 'refer-a-friend-ico',
            isAvailable: isFeatureAvailable(FEATURE.REFER_A_FRIEND),
        },
        {
            label: translate('Blog', 'ui.account'),
            route: getRoute('blog'),
            component: withLayout(BlogPage),
            icon: 'blog-ico',
            badge: newBlogPostsCount,
            isAvailable: isFeatureAvailable(FEATURE.BLOG),
        },
        {
            route: getRoute('about.about-us'),
            label: translate('About us', 'ui.about'),
            icon: 'info',
            isAvailable: isFeatureAvailable(FEATURE.ABOUT),
        },
    ].filter((route) => route.isAvailable);

    function checkSelectedRoute(link) {
        const linkRoute = link.split('/')[2];
        const currentRoute = window.location.pathname.split('/')[2];
        return linkRoute === currentRoute;
    }

    return (
        <Wrapper>
            <div className="account-menu-branding">
                <div className="brand-logo">
                    <Logo size={30} />
                    {isFeatureAvailable(FEATURE.LANGUAGE_SELECTION) && (
                        <div className="info-box">
                            <div className="info-box-content">
                                <CoolbetHeaderLanguages />
                            </div>
                            <div className="info-box-label">{translate('Language', 'ui.account')}</div>
                        </div>
                    )}
                </div>
                <UiBrandLine fullWidth />
            </div>

            {isAuthenticated && (
                <div className="account-info" onClick={() => setIsChangingCurrency(true)}>
                    <div className="info-box">
                        <div className="info-box-content balance">
                            {formattedAmountWithCurrency(wallet?.balance_uc || 0)}
                        </div>
                    </div>
                </div>
            )}

            <div className="account-menu-list">
                {isAuthenticated && (
                    <div className="account-links-primary">
                        {(primaryLinks as { route: string; label: string; icon: string; dataTest: string }[])
                            .filter(Boolean)
                            .map((link) => (
                                <UiButton
                                    url={link.route}
                                    key={link.route}
                                    type="card"
                                    size="small"
                                    isOnSurface
                                    block
                                    data-test={link.dataTest}
                                    icon={<Svg icon={link.icon} />}
                                    selected={checkSelectedRoute(link.route)}
                                >
                                    {link.label}
                                </UiButton>
                            ))}
                    </div>
                )}

                {isAuthenticated && (
                    <div className="account-links">
                        <PromotionBonusEntry hasValidation />
                    </div>
                )}
                <div className="account-links">
                    {(firstLinks as { route: string; label: string; icon: string; dataTest: string }[])
                        .filter(Boolean)
                        .map((link) => (
                            <UiButton
                                url={link.route}
                                key={link.route}
                                type="menu"
                                data-test={link.dataTest}
                                icon={<Svg icon={link.icon} />}
                                selected={checkSelectedRoute(link.route)}
                            >
                                {link.label}
                            </UiButton>
                        ))}
                </div>

                {isAuthenticated && (
                    <div className="account-links">
                        {(secondLinks as { route: string; label: string; icon: string; dataTest: string }[])
                            .filter(Boolean)
                            .map((link) => (
                                <UiButton
                                    url={link.route}
                                    key={link.route}
                                    type="menu"
                                    data-test={link.dataTest}
                                    icon={<Svg icon={link.icon} />}
                                    selected={checkSelectedRoute(link.route)}
                                >
                                    {link.label}
                                </UiButton>
                            ))}
                    </div>
                )}

                <div className="account-links">
                    <UiButton url={getRoute('support')} type="menu" icon={<Svg icon="support-ico" />}>
                        {translate('Help', 'ui.common')}
                    </UiButton>
                    <ContactUsButton isPhone={isPhone} />
                </div>

                <div className="account-links">
                    {(fourthLinks as { route: string; label: string; icon: string; dataTest: string }[])
                        .filter(Boolean)
                        .map((link) => (
                            <UiButton
                                url={link.route}
                                key={link.route}
                                type="menu"
                                data-test={link.dataTest}
                                icon={<Svg icon={link.icon} />}
                                selected={checkSelectedRoute(link.route)}
                            >
                                {link.label}
                            </UiButton>
                        ))}
                </div>

                <div className="account-links">
                    {(fifthLinks as { route: string; label: string; icon: string; dataTest: string }[])
                        .filter(Boolean)
                        .map((link) => (
                            <UiButton
                                url={link.route}
                                key={link.route}
                                type="menu"
                                data-test={link.dataTest}
                                icon={<Svg icon={link.icon} />}
                                selected={checkSelectedRoute(link.route)}
                            >
                                {link.label}
                            </UiButton>
                        ))}
                </div>
            </div>
            {isAuthenticated && (
                <UiButton block onClick={() => logout(undefined, false, true)}>
                    {translate('Logout', 'ui.header')}
                </UiButton>
            )}
            {!isAuthenticated && (
                <div className="auth-buttons">
                    {isUserExists ? <AuthPayAndPlayButton /> : <AuthRegisterButton />}
                    <AuthLoginButton block />
                </div>
            )}
            <AccountMenuCurrencySelect isOpen={isChangingCurrency} onClose={() => setIsChangingCurrency(false)} />
        </Wrapper>
    );
}
