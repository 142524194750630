import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { PokerLeaderboardParticipant } from '../../../../microservices/poker-playtech-gameslink';
import Svg from '../../../svg/Svg';
import { translate } from '../../../../services/translate';

interface Props {
    participants: PokerLeaderboardParticipant[];
    onSearch: (participants: PokerLeaderboardParticipant[]) => void;
}

export default function CmsPokerLeaderboardSearch({ participants, onSearch }: Props) {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredParticipants, setFilteredParticipants] = useState<PokerLeaderboardParticipant[]>(participants);

    useEffect(() => {
        filterParticipants();
    }, [searchTerm]);

    useEffect(() => {
        onSearch(filteredParticipants);
    }, [filteredParticipants]);

    function handleInputChange(event): void {
        setSearchTerm(event.target.value);
    }

    function filterParticipants() {
        const filtered = participants.filter((participant) =>
            participant.alias.toLowerCase().includes(searchTerm.toLowerCase()),
        );
        setFilteredParticipants(filtered);
    }

    return (
        <Wrapper>
            <Svg className="poker-leaderboard-search-icon" icon="search-ico" />

            <input
                onChange={handleInputChange}
                value={searchTerm}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                data-gramm_editor="false"
                data-gramm="false"
                className="poker-leaderboard-search-input"
                placeholder={translate('Search...', 'casino.search')}
            />
        </Wrapper>
    );
}
