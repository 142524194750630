import React from 'react';
import Wrapper from './styles';
import moment from 'moment';
import { translate } from '../../../../services/translate';
import { LeaderBoard } from '../../../../microservices/sb-campaigns';
import { formattedAmountWithCurrency } from '../../../../services/currency';

interface Props {
    leaderboard: LeaderBoard[];
}

export default function CmsLeaderboardTotal({ leaderboard }: Props) {
    return (
        <Wrapper>
            <div className="leaderboard-header">
                <div className="leaderboard-cell">{translate('alias', 'ui.account')}</div>
                <div className="leaderboard-cell">{translate('winning', 'ui.sportsbook')}</div>
                <div className="leaderboard-cell">
                    {translate('start-bet-amount', 'ui.sports.campaigns.leaderboard.total')}
                </div>
            </div>
            <div className="leaderboard">
                {leaderboard &&
                    leaderboard
                        .slice(0)
                        .reverse()
                        .map((user, index) => (
                            <div key={index} className="leaderboard-results">
                                <div className="leaderboard-cell">
                                    <div className="user-alias">{user.user_alias}</div>
                                    <div className="date">{moment(user.created_at).format('D MMM YYYY')}</div>
                                </div>
                                <div className="leaderboard-cell winning-bet-amount">
                                    {formattedAmountWithCurrency(user.won_amount, { currency: user.currency })}
                                </div>
                                <div className="leaderboard-cell start-bet-amount">
                                    {formattedAmountWithCurrency(user.start_bet_amount, { currency: user.currency })}
                                </div>
                            </div>
                        ))}
            </div>
        </Wrapper>
    );
}
