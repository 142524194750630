import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import Svg from '../../svg/Svg';
import { stores } from '../../../stores';
import {
    loadAvailableHappyHour,
    optInToHappyHourCrm,
    optInToHappyHourCrmCheck,
} from '../../../microservices/happy-hour';
import { FEATURE, isFeatureAvailable } from '../../../services/feature';
import moment from 'moment';
import { translate } from '../../../services/translate';
import { getRoute } from '../../../services/router';
import { getCasinoGameSlug } from '../../../services/casino/games';
import UiNavLink from '../../ui/nav-link/UiNavLink';
import { dateToFromNowDaily } from '../../../services/marketing/happy-hour';
import UiAlert from '../../ui/alert/UiAlert';
import UiButton from '../../ui/button/UiButton';
import { useStore } from '../../../hooks/useStore';

interface Props {
    isCompact?: boolean;
}

export default function CmsHappyHour({ isCompact }: Props) {
    const [happyHour, setHappyHour] = useState<any>();
    const [happyHourSchedule, setHappyHourSchedule] = useState<any>();
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [availableHappyHours] = useStore(stores.happyHour.available);
    const [language] = useStore(stores.language);
    const [optIn] = useStore(stores.happyHour.optIn);
    const [isOptedIn, setIsOptedIn] = useState(true);

    useEffect(() => {
        if (isAuthenticated) {
            loadAvailableHappyHour();
            optInToHappyHourCrmCheck();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (availableHappyHours && (availableHappyHours as any).length) {
            setHappyHour((availableHappyHours as any)[0]);
            generateHappyHourSchedule();
        }
    }, [availableHappyHours]);

    useEffect(() => {
        setIsOptedIn(Boolean(optIn));
    }, [optIn]);

    function generateHappyHourSchedule() {
        if (!availableHappyHours) {
            return;
        }
        const schedule = (availableHappyHours as any).filter((happyHour) => {
            return !happyHour.hasEnded;
        });
        setHappyHourSchedule(schedule);
    }

    if (!happyHour || !isFeatureAvailable(FEATURE.CASINO_HAPPY_HOUR)) {
        return (
            <Wrapper>
                <div className="widget-no-event">
                    <div className="event-header" />

                    <span
                        dangerouslySetInnerHTML={{
                            __html: translate('casino-happy-hour.no-active'),
                        }}
                    />
                </div>
            </Wrapper>
        );
    }
    return (
        <Wrapper isCompact={isCompact}>
            <div className="widget-next-event">
                <div className="be-online">
                    <Svg icon="wifi-ico" size={1.3} />
                    <span
                        dangerouslySetInnerHTML={{
                            __html: translate('Stay&nbsp;online', 'casino-happy-hour'),
                        }}
                    />
                </div>
                <div className="event-header" />
                <div className="event-time">
                    {!happyHour.hasEnded && (
                        <>
                            <div className="time-day">{dateToFromNowDaily(happyHour.schedule[0].start, language)}</div>
                            <div className="time-time">
                                <div className="hours">
                                    {moment.utc(happyHour.schedule[0].start).local().format('H')}
                                </div>
                                <div className="minutes">
                                    {moment.utc(happyHour.schedule[0].start).local().format('mm')}
                                </div>{' '}
                                <div className="time-separator">-</div>
                                <div className="hours">{moment.utc(happyHour.schedule[0].end).local().format('H')}</div>
                                <div className="minutes">
                                    {moment.utc(happyHour.schedule[0].end).local().format('mm')}
                                </div>
                            </div>
                        </>
                    )}
                    {happyHour.hasEnded && (
                        <div className="time-ended animated bounceIn">
                            {translate('This Happy Hour has already ended', 'casino-happy-hour')}
                        </div>
                    )}
                </div>

                <div className="event-prizes">
                    {!isOptedIn && (
                        <div className="hh-opted-out">
                            <UiAlert warning>
                                {translate('You have opted out from this promotion.', 'casino-happy-hour')}
                            </UiAlert>
                            <UiButton block color="primary" onClick={() => optInToHappyHourCrm()}>
                                {translate('Opt Back In', 'casino-happy-hour')}
                            </UiButton>
                        </div>
                    )}
                    <div className="number-of-winners">
                        <div className="winners-count">{happyHour.no_of_winners}</div>
                        <div className="winners-label">
                            <Svg icon="user-group" size={1.3} />
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: translate('random<br/>users', 'casino-happy-hour'),
                                }}
                            />
                        </div>
                    </div>

                    <div className="each-wins">{translate('Each wins', 'casino-happy-hour')}</div>

                    <div className="number-of-winners">
                        <div className="winners-count">{happyHour.no_of_spins}</div>
                        <div className="game-image">
                            <UiNavLink
                                to={`${getRoute('casino-game')}/${getCasinoGameSlug(
                                    happyHour.game_name,
                                    happyHour.game_id,
                                )}`}
                            >
                                <img src={`${happyHour.game_image}?dpr=1&w=60`} alt="" />
                            </UiNavLink>
                        </div>
                        <div className="winners-label">
                            <Svg icon="casino-lobby" size={1.3} />
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: translate('free<br/>spins', 'casino-happy-hour'),
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {happyHourSchedule && happyHourSchedule.length > 1 && (
                <div className="widget-schedule">
                    <div className="schedule-label">{translate('Schedule', 'casino-happy-hour')}</div>
                    {happyHourSchedule.slice(1, 4).map((day) => (
                        <div className="schedule-item" key={day}>
                            <div className="schedule-day">
                                <div className="day">
                                    {moment(day.schedule[0].start).locale(language.toLowerCase()).format('D MMM')}
                                </div>
                                <div className="time">
                                    <div className="hours">{moment.utc(day.schedule[0].start).local().format('H')}</div>
                                    <div className="minutes">
                                        {moment.utc(day.schedule[0].start).local().format('mm')}
                                    </div>{' '}
                                    <div className="time-separator">-</div>
                                    <div className="hours">{moment.utc(day.schedule[0].end).local().format('H')}</div>
                                    <div className="minutes">
                                        {moment.utc(day.schedule[0].end).local().format('mm')}
                                    </div>
                                </div>
                            </div>
                            <div className="schedule-day-prize">
                                <div className="schedule-day-game">
                                    <UiNavLink
                                        to={`${getRoute('casino-game')}/${getCasinoGameSlug(
                                            day.game_name,
                                            day.game_id,
                                        )}`}
                                    >
                                        <img src={`${day.game_image}?dpr=1&w=60`} alt="" />
                                    </UiNavLink>
                                </div>
                                <div className="schedule-day-winners">
                                    <div className="winners-count">
                                        {happyHour.no_of_winners}{' '}
                                        <span className="winners-label">
                                            - {translate('winners', 'casino-happy-hour')}
                                        </span>{' '}
                                    </div>
                                    <div className="winners-prize">
                                        {happyHour.no_of_spins}{' '}
                                        <span className="winners-label">
                                            - {translate('free spins', 'casino-happy-hour')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </Wrapper>
    );
}
