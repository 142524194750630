import styled from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

export default styled.div`
    .casino-thumbnail-labels-attribute {
        font-size: 0.75rem;
        line-height: 12px;
        font-weight: 600;
        height: auto;
        text-transform: lowercase;
        font-family: Lato, Roboto, sans-serif;
        border-radius: calc(2 * var(--ui-radius));
        background-color: var(--color-body-bg);
        padding: 3px 8px;
        width: auto;
        margin-right: 4px;

        @media ${mediaQuery.isPhone} {
            margin-right: 5px;
        }

        &.hot {
            color: ${({ theme }) => theme.colors.red};
            border: 1px solid ${({ theme }) => theme.colors.red};
        }

        &.new {
            color: ${({ theme }) => theme.colors.green};
            border: 1px solid ${({ theme }) => theme.colors.green};
        }

        &.exclusive {
            color: ${({ theme }) => theme.colors.yellow};
            border: 1px solid ${({ theme }) => theme.colors.yellow};
        }
    }
`;
