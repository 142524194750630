import keyBy from 'lodash/keyBy';
import React, { useEffect, useMemo, useState } from 'react';
import {
    getPublicTicketDetailsByTickedId,
    getTicketDetailsByTickedId,
    SportsOddsFormat,
} from '../../../../microservices/sbgate';
import { TICKET_STATUS, TICKET_TYPE } from '../../../../services/bet-history';
import { logger } from '../../../../services/logger';
import { convertDecimalToAmerican, convertOdds } from '../../../../services/odds-format';
import { getRoute } from '../../../../services/router';
import { SHARE_TYPE } from '../../../../services/share';
import { useCashoutDoneRefreshTicket } from '../../../../services/sports/cashout';
import { MATCH_STATUS, systemBetTypeBySystemKey } from '../../../../services/sports/constants';
import {
    SystemBet,
    SystemsMeta,
    Ticket,
    TicketDetails,
    TicketType,
    UniqueSelection,
} from '../../../../services/sports/types';
import { useOddsFormat } from '../../../../services/sports/user-settings';
import { translate } from '../../../../services/translate';
import Logo from '../../../logo/Logo';
import Share from '../../../share/Share';
import Svg from '../../../svg/Svg';
import UiAlert from '../../../ui/alert/UiAlert';
import UiCloseButton from '../../../ui/close-button/UiCloseButton';
import UiDotsLoader from '../../../ui/dots-loader/UiDotsLoader';
import UiNavLink from '../../../ui/nav-link/UiNavLink';
import SportBadgeTicketPlatform from '../../badge/ticket-platform/SportBadgeTicketPlatform';
import SportBadgeTicketStatus from '../../badge/ticket-status/SportBadgeTicketStatus';
import SportTicketMatchScore from '../match-score/SportTicketMatchScore';
import SportTicketOdds from '../odds/SportTicketOdds';
import SportTicketSelections from '../selections/SportTicketSelections';
import SportTicketTotals from '../totals/SportTicketTotals';
import Wrapper from './styles';
import { checkInplayDataAndSubscribe } from '../../../../services/sports/inplay-helpers';
import SportMatchScore from '../../match/score/SportMatchScore';
import SportMatchTimeLive from '../../match/time/live/SportMatchTimeLive';
import { fetchInPlayData } from '../../../../microservices/sports';
import SportTicketDetailsMatchContainer from './match-container/SportTicketDetailsMatchContainer';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import UiButton from '../../../ui/button/UiButton';
import { getOutcomeDisplayLine } from '../../../../services/retail';
import { isB2B, isRetail } from '../../../../services/environment';
import { getTicketsToPrint } from '../../../../microservices/retail-middleware';
import SportCategoryIcon from '../../category-icon/SportCategoryIcon';
import { DATE_TIME_FORMAT, getFormattedDate } from '../../../../services/date';
import { filterStyleProps } from '../../../../styles/utils';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

interface LiveMatches {
    [matchId: number]: boolean;
}

interface Props {
    ticket?: any;
    ticketId?: string;
    onTicketClosed?: () => void;
    isCompactView?: boolean;
    isPublic?: boolean;
    isScreenShotPage?: boolean;
    isCopyingTicket?: boolean;
    onTicketCopy?: () => void;
    isLeaderboard?: boolean;
    onLoadingAccomplished?: () => void;
    isBetHistory?: boolean;
}

export default function SportTicketDetails({
    ticket: propsTicket,
    ticketId,
    onTicketClosed,
    isPublic = false,
    isCompactView,
    isScreenShotPage = false,
    isCopyingTicket = false,
    onLoadingAccomplished = () => {},
    onTicketCopy = () => {},
    isLeaderboard = false,
    isBetHistory = false,
    ...rest
}: Props) {
    const [{ isPhone }] = useStore(media);
    const isRetailLayout = isRetail();
    const [ticketFromResponse, setTicketFromResponse] = useState({} as TicketDetails);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingFailed, setIsLoadingFailed] = useState(false);
    const { systemsMeta, uniqueSelections } = ticketFromResponse;
    const isCompactViewEnabled = isCompactView || isPhone;
    const isBetSharingVisible = !isLeaderboard && !isPublic && !isScreenShotPage && !isLoadingFailed && !isLoading;
    const isCopyTicketVisible = isPhone && isPublic && !isScreenShotPage && !isRetailLayout;
    const [liveMatches, setLiveMatches] = useState<LiveMatches>({});
    const cashoutAmount = propsTicket?.cashoutAmount || propsTicket?.ticket?.cashoutAmount;
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);

    useEffect(() => {
        loadTicketDetails();
    }, []);

    useEffect(() => {
        if (!cashoutAmount) {
            return;
        }
        setTicketFromResponse((ticketFromResponse) => ({
            ...ticketFromResponse,
            ticket: {
                ...ticketFromResponse.ticket,
                cashoutAmount,
            },
        }));
    }, [cashoutAmount]);

    useOddsFormat();

    const localTicket: Ticket | undefined = useCashoutDoneRefreshTicket(propsTicket);
    const ticket: any = localTicket || propsTicket;
    const ticketType = ticket?.ticket?.ticket_type || ticket?.ticket_type;

    async function loadTicketDetails() {
        if (isLeaderboard) {
            setIsLoading(false);
            return setTicketFromResponse(ticket.details);
        }

        setIsLoading(true);
        setIsLoadingFailed(false);
        try {
            const bareTicket: Ticket = ticketId ? null : ticket.ticket || ticket;
            let ticketResponse = {} as TicketDetails;

            if (propsTicket && propsTicket.ticket) {
                ticketResponse = propsTicket;
            } else {
                const ticketID = ticketId || bareTicket.id;
                if (isRetailLayout) {
                    const tickets = await getTicketsToPrint();
                    if (tickets.length) {
                        const ticket = tickets.find((ticket) => ticket.betsTicket?.ticket.id === ticketId);
                        ticketResponse = ticket?.betsTicket as any;
                    }
                } else {
                    ticketResponse = isPublic
                        ? await getPublicTicketDetailsByTickedId(ticketID)
                        : await getTicketDetailsByTickedId(ticketID);
                }
            }
            ticketResponse.ticket = { ...ticketResponse.ticket, ...bareTicket };
            const ticketFromResponse = {
                ...ticketResponse,
                selectionByOutcomeId: keyBy(ticketResponse.uniqueSelections, 'outcome_id'),
            };
            setTicketFromResponse(ticketFromResponse);
            getLiveScoreData(ticketResponse.uniqueSelections);
        } catch (error) {
            logger.error('SportTicketDetails', 'loadTicketDetails', error);
            setIsLoadingFailed(true);
        }
        setIsLoading(false);
        onLoadingAccomplished();
    }

    async function getLiveScoreData(selections) {
        const tmpLiveMatches = {};
        const liveMatchIds = selections
            .filter(
                (selection) => selection.status === TICKET_STATUS.PENDING && selection.product === MATCH_STATUS.LIVE,
            )
            .map((selection) => selection.match_id);
        if (liveMatchIds.length) {
            (await fetchInPlayData(liveMatchIds)).forEach(
                (inplayData) => (tmpLiveMatches[inplayData.match_id] = Boolean(inplayData.custom?.messages)),
            );
            liveMatchIds.forEach((id) => checkInplayDataAndSubscribe(id));
        }
        setLiveMatches(tmpLiveMatches);
    }

    function orderSystemBetTypes(systemsMeta: SystemsMeta) {
        return Object.values(systemBetTypeBySystemKey).map((systemType) => ({
            ...systemsMeta[systemType],
            systemType,
        })) as SystemBet[];
    }

    const ticketDetails = localTicket || ticketFromResponse.ticket;
    const groupedSystemBets = useMemo(
        () => systemsMeta && ticketDetails.ticket_type === TicketType.SYSTEM && orderSystemBetTypes(systemsMeta),
        [systemsMeta, ticketDetails],
    );

    if (!ticketDetails) {
        return null;
    }

    function formatOddValue(oddsValue) {
        if (!ticketDetails?.odds_format) {
            return convertOdds(oddsValue);
        } else if (ticketDetails?.odds_format === SportsOddsFormat.AMERICAN) {
            return convertDecimalToAmerican(oddsValue);
        }
        return oddsValue.toFixed(2);
    }

    function getBetbuilderMarketName(marketName) {
        const markets = marketName.split(' And ').map((market) => market.split(' - '));
        return (
            <div>
                {markets.map((market, index) => (
                    <div key={index} className="betbuilder-market">
                        <span>{market[0]}</span> → <span className="outcome">{market[1]}</span>
                    </div>
                ))}
            </div>
        );
    }

    function isLiveNow(matchId) {
        return Object.keys(liveMatches).map(Number).includes(matchId);
    }

    function shouldForceAmericanLayout(uniqueSelection: UniqueSelection) {
        return uniqueSelection.match_name.includes('@') && isBetHistory;
    }

    function displayLine(uniqueSelection?: UniqueSelection) {
        if (!uniqueSelection || !Number(uniqueSelection.line)) {
            return '';
        }
        return getOutcomeDisplayLine(uniqueSelection['result_key'], Number(uniqueSelection.line));
    }

    return (
        <Wrapper
            {...filterStyleProps({ ...rest })}
            $isCompactViewEnabled={isCompactViewEnabled}
            $isScreenShotPage={isScreenShotPage}
        >
            {(!isPublic || !isScreenShotPage) && !isRetailLayout && !isLayoutB && (
                <UiCloseButton onClick={() => onTicketClosed?.()} className="close-button" />
            )}

            {!isLoading && !isLoadingFailed && (
                <>
                    <SportTicketTotals
                        extraInfo
                        ticket={ticketDetails}
                        isLeaderboard={isLeaderboard}
                        isPublic={isPublic}
                        onTicketCopy={onTicketCopy}
                        isCopyingTicket={isCopyingTicket}
                        isScreenShotPage={isScreenShotPage}
                        isCompactView={isCompactView}
                    />
                </>
            )}
            {isLoading && !isLoadingFailed && <UiDotsLoader />}

            {isLoadingFailed && <UiAlert failure>{translate('Could not load tickets', 'ui.account')}</UiAlert>}

            {uniqueSelections &&
                uniqueSelections.map((uniqueSelection) => (
                    <div className="ticket-details" key={uniqueSelection.outcome_id}>
                        {isLiveNow(uniqueSelection.match_id) && isCompactViewEnabled && (
                            <div className="match-live-score">
                                <SportMatchScore
                                    matchId={uniqueSelection.match_id}
                                    sportId={ticketDetails.sport_id}
                                    sportCategoryId={uniqueSelection.sport_category_id}
                                    isLivebetOur={liveMatches[uniqueSelection.match_id]}
                                    forceIsAmericanLayout={shouldForceAmericanLayout(uniqueSelection)}
                                />
                                <SportMatchTimeLive
                                    matchId={uniqueSelection.match_id}
                                    sportId={ticketDetails.sport_id}
                                    isOurLivebet={liveMatches[uniqueSelection.match_id]}
                                />
                            </div>
                        )}
                        <div className="selection-sport">
                            <SportCategoryIcon className="sport-icon" small icon={uniqueSelection.sport_icon} />
                        </div>

                        <div className="selection-match">
                            <div className="match-info">
                                <div className="match-dates">
                                    <span>
                                        {getFormattedDate({
                                            date: `${uniqueSelection.match_start}`,
                                            format: DATE_TIME_FORMAT,
                                            useMonthLetters: true,
                                        })}
                                    </span>
                                </div>
                                <span> / </span>
                                <span className="match-league">{uniqueSelection.league_name}</span>
                                {isCompactViewEnabled && (
                                    <span className="match-sport">
                                        <SportCategoryIcon
                                            className="sport-icon"
                                            tiny
                                            icon={uniqueSelection.sport_icon}
                                        />
                                    </span>
                                )}
                            </div>
                            {uniqueSelection.expected_result_date &&
                            new Date(uniqueSelection.expected_result_date) > new Date() ? (
                                <UiNavLink
                                    to={`${getRoute(
                                        uniqueSelection.product === TICKET_TYPE.LIVE ? 'sport.live' : 'sport.match',
                                    )}/${uniqueSelection.match_id}`}
                                >
                                    <SportTicketDetailsMatchContainer
                                        uniqueSelection={uniqueSelection}
                                        ticketDetails={ticketDetails}
                                        isPublic={isPublic}
                                        isCompactViewEnabled={isCompactViewEnabled}
                                    />
                                </UiNavLink>
                            ) : (
                                <SportTicketDetailsMatchContainer
                                    uniqueSelection={uniqueSelection}
                                    ticketDetails={ticketDetails}
                                    isPublic={isPublic}
                                    isCompactViewEnabled={isCompactViewEnabled}
                                />
                            )}

                            {ticket?.ticket_type === TICKET_TYPE.BETBUILDER ||
                            ticket?.ticket?.ticket_type === TICKET_TYPE.BETBUILDER ||
                            ticketFromResponse?.ticket?.ticket_type === TICKET_TYPE.BETBUILDER ? (
                                <div className="selected-market">
                                    {getBetbuilderMarketName(
                                        uniqueSelection.marketTypeName || uniqueSelection.market_name,
                                    )}
                                </div>
                            ) : (
                                <div className="selected-market">
                                    {ticketType === TicketType.COMBO_CARD ? (
                                        <div className="market-outcome">{uniqueSelection.outcome_name}</div>
                                    ) : (
                                        <>
                                            <div className="market-name">
                                                {isRetailLayout
                                                    ? `${
                                                          uniqueSelection?.['marketTypeName'] ||
                                                          uniqueSelection?.['market_name']
                                                      } ${displayLine(uniqueSelection)}`
                                                    : uniqueSelection.marketTypeName || uniqueSelection.market_name}
                                            </div>
                                            <span className="market-outcome">
                                                {![TicketType.TEASER, TicketType.PARLAY_CARD].includes(ticketType) && (
                                                    <SportTicketOdds compact>
                                                        {formatOddValue(uniqueSelection.odds)}
                                                    </SportTicketOdds>
                                                )}
                                                <span>»</span>
                                                {uniqueSelection.outcome_name}{' '}
                                                {[TicketType.TEASER].includes(ticketType) &&
                                                    `( ${uniqueSelection.teaser_points}${translate(
                                                        'pts Teaser',
                                                        'ui.sportsbook',
                                                    )} )`}
                                            </span>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="odds-and-type">
                            {isLiveNow(uniqueSelection.match_id) && !isCompactViewEnabled && (
                                <div className="match-live-score">
                                    <SportMatchScore
                                        matchId={uniqueSelection.match_id}
                                        sportId={ticketDetails.sport_id}
                                        sportCategoryId={uniqueSelection.sport_category_id}
                                        isLivebetOur={liveMatches[uniqueSelection.match_id]}
                                        forceIsAmericanLayout={shouldForceAmericanLayout(uniqueSelection)}
                                    />
                                    <SportMatchTimeLive
                                        matchId={uniqueSelection.match_id}
                                        sportId={ticketDetails.sport_id}
                                        isOurLivebet={liveMatches[uniqueSelection.match_id]}
                                    />
                                </div>
                            )}
                            {isCompactViewEnabled && (
                                <>
                                    {uniqueSelection.score_home !== null && (
                                        <SportTicketMatchScore matchInfo={uniqueSelection} />
                                    )}

                                    <SportBadgeTicketPlatform
                                        ticket={ticketDetails.cashout_status ? ticketDetails : uniqueSelection}
                                    />
                                    <SportBadgeTicketStatus
                                        ticket={ticketDetails.cashout_status ? ticketDetails : uniqueSelection}
                                    />
                                </>
                            )}

                            {ticketDetails.banker_outcome_ids &&
                                ticketDetails.banker_outcome_ids.includes(uniqueSelection.outcome_id) && (
                                    <div className="banker-bet">
                                        <Svg icon="bank" />
                                        <span>{translate('banker', 'ui.sportsbook')}</span>
                                    </div>
                                )}

                            {!isB2B() && (
                                <div className="coolbet-ticket-logo">
                                    <Logo size={16} />
                                </div>
                            )}
                        </div>
                    </div>
                ))}

            {isCopyTicketVisible && (
                <div className="copy-ticket">
                    <UiButton
                        color="primary"
                        className="copy-button"
                        onClick={onTicketCopy}
                        isLoading={isCopyingTicket}
                        block={isCompactViewEnabled}
                    >
                        {translate('ui.sportsbook.betsfeed.copy-ticket')}
                    </UiButton>
                </div>
            )}

            {isBetSharingVisible && isFeatureAvailable(FEATURE.SPORT_TICKET_SHARING) && (
                <div className="bet-sharing">
                    <Share ticket={ticketDetails} type={SHARE_TYPE.TICKET} />
                </div>
            )}
            {groupedSystemBets &&
                groupedSystemBets.map((systemInfo) => (
                    <SportTicketSelections
                        key={systemInfo.systemType}
                        systemInfo={systemInfo}
                        ticketFromResponse={ticketFromResponse}
                        isCompactViewEnabled={isCompactViewEnabled}
                        isPublic={isPublic}
                    />
                ))}
        </Wrapper>
    );
}
