import React, { useEffect, useState } from 'react';
import OnsiteMessagePreview from '../../components/onsite-message-preview/OnsiteMessagePreview';
import { useBrowserCommunication } from '../../hooks/useBrowserCommunication';
import { OnsiteMessage } from '../../microservices/crm';
import { logger } from '@sentry/utils';

export default function OnsiteMessagePreviewPage() {
    const [onsiteMessage, setOnsiteMessage] = useState<OnsiteMessage>();
    const { channelState } = useBrowserCommunication({ channelName: 'onsiteMessagePreviewChannel' });

    useEffect(() => {
        if (channelState?.lastMessage) {
            try {
                const onsiteMessage: OnsiteMessage = JSON.parse(channelState.lastMessage);
                setOnsiteMessage(onsiteMessage);
            } catch (err) {
                logger.info('OnsiteMessagePreviewPage', 'OnsiteMessagePreviewPage', channelState.lastMessage);
            }
        }
    }, [channelState]);

    return <OnsiteMessagePreview onsiteMessage={onsiteMessage} />;
}
