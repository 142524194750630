import React from 'react';
import { translate } from '../../services/translate';
import { Transaction, TransactionProduct } from '../../services/wallet/types';
import FullstoryBlock from '../fullstory-block/FullstoryBlock';
import UiButton from '../ui/button/UiButton';
import UiTable from '../ui/table/UiTable';
import TransactionsRow from './row/TransactionsRow';
import Wrapper from './styles';

interface Props {
    transactions: Transaction[];
    hasNextPage: boolean;
    onNextPageRequested: (...args: unknown[]) => void;
    isLoadingNextPage: boolean;
    activeProduct: string;
    onReloadTransactions: (...args: unknown[]) => void;
}

export default function Transactions({
    transactions = [],
    onReloadTransactions,
    hasNextPage,
    onNextPageRequested = () => {},
    isLoadingNextPage,
    activeProduct,
}: Props) {
    return (
        <FullstoryBlock>
            <Wrapper>
                <UiTable>
                    <div className="table-row heading">
                        <div className="table-cell">
                            {translate('Created', 'ui.common')} / {translate('Type', 'ui.common')}
                        </div>

                        <div className="table-cell ">
                            {translate('Description', 'ui.common')} / {translate('Product', 'ui.common')}
                        </div>

                        <div className="table-cell">
                            {translate('Amount', 'ui.common')}
                            {activeProduct === TransactionProduct.WITHDRAWAL && ` / ${translate('State', 'ui.common')}`}
                            {!activeProduct && ` / ${translate('Balance', 'ui.common')}`}
                        </div>
                    </div>

                    {transactions.map((transaction) => (
                        <TransactionsRow
                            key={transaction.id}
                            transaction={transaction}
                            onReloadTransactions={onReloadTransactions}
                            isBalanceVisible={!activeProduct}
                        />
                    ))}
                </UiTable>

                {hasNextPage && (
                    <UiButton onClick={onNextPageRequested} block isLoading={isLoadingNextPage}>
                        {translate('Load more', 'ui.common')}
                    </UiButton>
                )}
            </Wrapper>
        </FullstoryBlock>
    );
}
