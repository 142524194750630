import styled, { css } from 'styled-components';

const isUserWinner = ({ isUserWinner }: StyledProps) =>
    isUserWinner &&
    css`
        color: ${({ theme }) => theme.colors.success};
    `;

const isUserNotWinner = ({ isUserNotWinner }: StyledProps) =>
    isUserNotWinner &&
    css`
        color: ${({ theme }) => theme.colors.red};
    `;

type StyledProps = {
    isUserWinner?: boolean;
    isUserNotWinner?: boolean;
};
export default styled.div<StyledProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: ${({ theme }) => theme.layout.gutter} 0;
    .score-text {
        text-transform: uppercase;
        font-size: 0.75rem;
    }
    .score-count {
        color: ${({ theme }) => theme.colors.success};
        font-weight: var(--font-weight-bold);

        font-size: 45px;
        ${isUserWinner}
        ${isUserNotWinner}
    }
`;
