import { CasinoRace, CasinoRaceHistory, CasinoRaceScore } from '../services/casino/casino-race';
import { createStores } from './store/utils';

export const casinoRace = createStores({
    isMobileRaceGameMenuVisible: false,
    raceGamesModal: createStores({
        isVisible: false,
        race: {} as CasinoRace,
    }),
    active: createStores({
        race: {} as CasinoRace,
        score: {} as CasinoRaceScore,
        leaderboard: [] as CasinoRaceScore[],
        position: undefined as number | undefined,
    }),
    clientServerTimeDifference: 0,
    raceEnd: {} as CasinoRace,
    isRaceSidebarVisible: false,
    historyCardClicked: {} as CasinoRaceHistory,
});
