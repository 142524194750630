import React from 'react';
import Wrapper from './styles';
import SportBetslip from '../../sport/betslip/SportBetslip';
import { stores } from '../../../stores';
import isEmpty from 'lodash/isEmpty';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

export default function CmsBetslip() {
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const isBetslipNotEmpty = !isEmpty(betSlipMarketIdToOutcomeId);
    const [{ isPhone, isTablet }] = useStore(media);

    const isMobileDevice = isTablet || isPhone;

    return (
        isBetslipNotEmpty &&
        !isMobileDevice && (
            <Wrapper className="cms-betslip animated fadeInRight">
                <SportBetslip />
            </Wrapper>
        )
    );
}
