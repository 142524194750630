import React from 'react';
import { translate } from '../../../../../services/translate';
import { stores, useStoreWithSelector } from '../../../../../stores';
import Wrapper from './styles';
import SportMatchTimeLive from '../../time/live/SportMatchTimeLive';
import { FoCategoryMatch } from '@staycool/sbgate-types';

interface Props {
    match: FoCategoryMatch;
}

export default function SportMatchScoreboardBgFootball({ match }: Props) {
    const [inplayData] = useStoreWithSelector(stores.sports.inplayMatchData, (state) => state[match.id], [match.id]);
    const { home_team_points, away_team_points, custom } = inplayData || {};
    const { red_cards_home, red_cards_away, yellow_cards_home, yellow_cards_away, corners_home, corners_away } =
        custom || {};
    return (
        <Wrapper sportId={match.sport} sportSlug={match.sport_slug} className="animated fadeIn">
            <div className="scores">
                <div className="score">
                    <h1 className="score-title">{match.home_team_name}</h1>
                    <span className="score-number"> {home_team_points || 0}</span>
                </div>
                <div className="timer">
                    <SportMatchTimeLive matchId={match.id} sportId={match.sport} isOurLivebet={match.livebet_our} />
                </div>
                <div className="score">
                    <h1 className="score-title">{match.away_team_name}</h1>
                    <span className="score-number"> {away_team_points || 0}</span>
                </div>
            </div>

            <div className="stats">
                <div>
                    <h3 className="stats-title">{translate('Red cards', 'ui.scoreboard.football')}</h3>
                    <div className="stats-number red-card">{red_cards_home || 0}</div>
                </div>
                <div>
                    <h3 className="stats-title">{translate('Yellow cards', 'ui.scoreboard.football')}</h3>
                    <div className="stats-number yellow-card">{yellow_cards_home || 0}</div>
                </div>
                <div>
                    <h3 className="stats-title">{translate('Corners', 'ui.scoreboard.football')}</h3>
                    <div className="stats-number">{corners_home || 0}</div>
                </div>
                <div className="stats-divider" />
                <div>
                    <h3 className="stats-title">{translate('Corners', 'ui.scoreboard.football')}</h3>
                    <div className="stats-number">{corners_away || 0}</div>
                </div>
                <div>
                    <h3 className="stats-title">{translate('Yellow cards', 'ui.scoreboard.football')}</h3>
                    <div className="stats-number yellow-card">{yellow_cards_away || 0}</div>
                </div>
                <div>
                    <h3 className="stats-title">{translate('Red cards', 'ui.scoreboard.football')}</h3>
                    <div className="stats-number red-card">{red_cards_away || 0}</div>
                </div>
            </div>
        </Wrapper>
    );
}
