import React from 'react';
import Wrapper from './styles';
import { MATCH_STATUS, MATCH_TYPE } from '../../../../services/sports/constants';
import { useSportsVisualSettings } from '../../../../services/sports/sport-match-visual-settings';
import SportMatchTime from '../time/SportMatchTime';
import SportMatchTimeLive from '../time/live/SportMatchTimeLive';
import { getRoute } from '../../../../services/router';
import UiNavLink from '../../../ui/nav-link/UiNavLink';
import Svg from '../../../svg/Svg';
import { translate } from '../../../../services/translate';
import { CategoryMatch } from '../../../../services/sports/types';
import SportBadge from '../../badge/SportBadge';
import { useMatchStatusUpdate } from '../../../../services/sports/hooks';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import { isB2B, isRetail } from '../../../../services/environment';
import SportCategoryIcon from '../../category-icon/SportCategoryIcon';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    showTime?: boolean;
    match: CategoryMatch;
}

export default function SportMatchInfo({ match, showTime }: Props) {
    const useSportsVisualSettings1 = useSportsVisualSettings();
    const { isShowSportCategoryIcon } = useSportsVisualSettings1;
    const [{ isPhone }] = useStore(media);
    const isFeatured = match.featured && match.match_type !== MATCH_TYPE.OUTRIGHT;
    const shouldHaveGeniusStream = match.has_genius_stream;
    const matchStatus = useMatchStatusUpdate(match);
    const isPrematch = matchStatus === MATCH_STATUS.OPEN;
    const isLiveNow = matchStatus === MATCH_STATUS.LIVE;
    const isLivebetMatch = !isLiveNow && match.inplay;
    const isRetailLayout = isRetail();
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);

    return (
        <Wrapper $isRetailLayout={isRetailLayout} $isLiveNow={isLiveNow}>
            {showTime && (
                <div className="match-time">
                    {!isLiveNow && isShowSportCategoryIcon && !isPhone && (
                        <div className="match-sport-icon">
                            <SportCategoryIcon tiny icon={match.sport_icon} />
                        </div>
                    )}
                    {isPrematch && (
                        <SportMatchTime
                            time={match.match_type === MATCH_TYPE.OUTRIGHT ? match.betting_end : match.match_start}
                        />
                    )}
                    {isLiveNow && (
                        <div className="match-live-data">
                            <SportBadge type={MATCH_STATUS.LIVE} />
                            <SportMatchTimeLive
                                matchId={match.id}
                                sportId={match.sport}
                                isOurLivebet={match.livebet_our}
                            />
                        </div>
                    )}
                    {!isLiveNow && isShowSportCategoryIcon && (
                        <div className="match-category">
                            <>
                                {isPhone && (
                                    <div className="match-sport-icon">
                                        <SportCategoryIcon tiny icon={match.sport_icon} />
                                    </div>
                                )}
                                <span className="category-name">
                                    <UiNavLink to={`${getRoute('sport')}/${match.fullSlug}`}>
                                        {match.categoryName}
                                    </UiNavLink>
                                </span>
                            </>
                        </div>
                    )}
                </div>
            )}

            <div className="extra-info">
                {isFeatured && !isRetailLayout && !isB2B() && (
                    <div className="special-icons" title={translate('promoted', 'ui.sportsbook')}>
                        <Svg icon="boosted-icon" size={1} />
                    </div>
                )}

                {isShowSportCategoryIcon && isPhone && (
                    <>
                        <span className="category-name">
                            <UiNavLink to={`${getRoute('sport')}/${match.fullSlug}`}>{match.categoryName}</UiNavLink>
                        </span>
                    </>
                )}

                {!isPhone &&
                    isLivebetMatch &&
                    !(match.stream_info || shouldHaveGeniusStream) &&
                    !isLayoutB &&
                    !isRetailLayout && <SportBadge type={MATCH_TYPE.LIVEBET} />}
            </div>
        </Wrapper>
    );
}
