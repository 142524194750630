import styled from 'styled-components';
import { darken, desaturate, lighten } from 'polished';
import background from './background.jpg';
import { mediaQuery } from '../../../../../styles/utils';

export default styled.div`
    .background {
        padding: 2rem;
        width: 100%;
        background: url(${(props) => props.backgroundUrl || ''});
        background-size: cover;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .scoreboard-box {
        display: flex;
        flex-direction: column;
        padding-top: 1rem;
    }
    .messages-and-winners {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        align-items: flex-start;
        > *:not(:last-of-type) {
            margin-right: 1rem;
            @media ${mediaQuery.isPhone} {
                margin-bottom: 1.5rem;
                margin-right: 0;
            }
        }
        @media ${mediaQuery.isPhone} {
            flex-direction: column;
        }
    }
    .messages,
    .leaderboard {
        flex: 1;
        max-width: 450px;
    }
    .scoreboard {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        flex: 1;
    }
    .scoreboard,
    .messages,
    .leaderboard {
        position: relative;
        display: flex;
        justify-content: space-evenly;
        border-radius: var(--ui-radius);
        background-image: url(${background});
        background-size: 50%;
        box-shadow: var(--ui-shadow), inset 1px 1px 4px -2px ${({ theme }) => lighten(0.4, theme.box.background)};
        padding: 1.5rem 1rem;
        @media ${mediaQuery.isPhone} {
            padding: 0.5rem;
            width: 100%;
            max-width: 350px;
        }
        .box-label {
            position: absolute;
            bottom: 100%;
            left: 0;
            z-index: 2;
            transform: translate(0, 50%);
            padding: 0.4rem 0.8rem;
            border-top-right-radius: var(--ui-radius);
            border-top-left-radius: var(--ui-radius);
            background-image: url(${background});
            background-size: 50%;
            box-shadow: inset 1px 1px 4px -2px ${({ theme }) => lighten(0.4, theme.box.background)};
            color: var(--color-highlight);
            font-size: 0.6rem;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
    .set-name {
        display: flex;
    }
    .team,
    .round {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0.1rem 0.1rem 0.1rem 1.8rem;

        .possession {
            position: absolute;
            left: 0;
            margin-right: 0.5rem;
        }
        .team-name {
            text-align: left;
            max-width: 140px;
            min-width: 140px;
            margin: 0 1rem 0 0;
            padding: 0.5rem 0;
            font-size: 0.8rem;
            display: flex;
            align-items: center;
            @media ${mediaQuery.isPhone} {
                max-width: 110px;
                min-width: 110px;
            }
        }
    }
    .round {
        color: var(--font-color-secondary);
        padding-left: 0;
        margin-left: 1.8rem;
        margin-top: -1.5rem;
        .round-name {
            text-align: right;
            max-width: 140px;
            min-width: 140px;
            margin: 0 1rem 0 0;
            padding: 0.5rem 0;
            font-size: 0.8rem;
            @media ${mediaQuery.isPhone} {
                max-width: 110px;
                min-width: 110px;
            }
        }
        .round-total {
            padding: 0.4rem;
            font-size: 0.8rem;
        }
    }
    .set-points,
    .round-number {
        position: relative;
        min-width: 40px;
        padding: 5px 0;
        text-align: center;
        border: 1px solid #313131;
        background: var(--color-body-bg);
        font-size: 0.8rem;
        border-radius: var(--ui-radius);
        margin-right: 0.4rem;
        &.active {
            background-color: ${({ theme }) => desaturate(0.8, darken(0.35, theme.colors.primary))};
            color: ${({ theme }) => theme.colors.primary};
            border-color: ${({ theme }) => theme.colors.primary};
        }
    }

    .points-total {
        color: ${({ theme }) => theme.colors.secondary};
        border-color: ${({ theme }) => theme.colors.secondary};
    }

    .set-name {
        position: absolute;
        bottom: 100%;
        margin-bottom: 5px;
        border-radius: var(--ui-radius);
        height: 20px;
        background: ${({ theme }) => lighten(0.06, theme.box.background)};

        color: var(--font-color-secondary);
        font-size: 0.7rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .messages {
        .messages-list {
            display: flex;
            flex-direction: column;
            max-height: 100px;
            overflow-y: scroll;
            width: 100%;
            .message {
                padding: 0.4rem 0.8rem;
                border-radius: var(--ui-radius);
                font-size: 0.7rem;
                &:nth-of-type(odd) {
                    background: ${({ theme }) => lighten(0.04, theme.box.background)};
                }
            }
        }
    }
    .leaderboard {
        display: flex;
        flex-direction: column;
        width: 100%;
        .leaderboard-position {
            padding: 0.4rem;
            border-radius: var(--ui-radius);
            background: ${({ theme }) => lighten(0.04, theme.box.background)};
            margin-bottom: 0.2rem;
            display: flex;
            align-items: center;
            .position-number {
                max-width: 100px;
                border-radius: var(--ui-radius);
                font-size: 1rem;
                font-weight: bold;
                width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                background: ${({ theme }) => lighten(0.1, theme.box.background)};
                margin-right: 0.8rem;
            }
            .player-name {
            }
        }
    }

    .penalty-scoreboard {
        display: flex;
        margin-top: 1.5rem;
        color: var(--font-color-secondary);
        .team {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 0 0 1.8rem;
            &.scores {
                padding: 0;
            }
            .set-points {
                margin: 0.25rem 0.4rem 0.25rem 0;
            }
            .team-name {
                padding: 0.6rem 0;
                &.round {
                    display: block;
                    text-align: right;
                }
            }
            .round-total {
                padding: 0.4rem;
                font-size: 0.8rem;
                margin: 0.2rem 0.4rem 0.25rem 0;
            }
        }
    }
`;
