import { CasinoCategoryCode } from '../services/casino/categories';
import { getServiceUrl, httpGet } from '../services/api';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { logger } from '../services/logger';
import keyBy from 'lodash/keyBy';
import snakeCase from 'lodash/snakeCase';
import { CasinoGame, CasinoGameFull, loadFilteredCasinoGames } from '../services/casino/games';
import { CasinoCategory } from '../services/casino/categories';
import { CasinoJackpot } from '../services/casino/jackpots';
import { CasinoCategorySubprovider, CasinoProvider } from '../services/casino/providers';
import { CasinoFeature } from '../services/casino/features';
import { isTestUser } from '../services/user';
import { CasinoMaintenance } from '../services/casino/maintenance';
import { getDefaultQueryParams } from '../services/casino/casino';
import { CasinoGameLabel } from '../services/casino/game-label';
import { CasinoGameOperatingLanguage } from '../services/casino/operating-language';
import { getDeviceCategory } from '../services/device';
import { CasinoLobbySettings } from '../services/casino/lobby-setting';
import { COUNTRY } from '../services/country';
import { getLicence } from '../services/licence';
import { CasinoStudio } from '../services/casino/studios';
import { LiveLobbyLauncherByGameType } from '../services/casino/provider-live-lobby-types';
import { FEATURE, isFeatureAvailable } from '../services/feature';

const getUrl = (url) => getServiceUrl('casino', url);

export async function loadIsCasinoBlocked() {
    let countryCode = getStoreValue(stores.ipCountry);
    const user = getStoreValue(stores.user);
    if (user?.id && ['CndWLHdq', 'HKYhQVWJ'].includes(user.id)) {
        // Google Play and App Store test accounts
        countryCode = COUNTRY.MEXICO;
    }
    const url = getUrl('fo/is-casino-blocked');
    try {
        const { isCasinoBlocked } = await httpGet<{ isCasinoBlocked: boolean }>(url, { countryCode });
        stores.casino.isCasinoBlocked.set(isCasinoBlocked);
    } catch (error) {
        stores.casino.isCasinoBlocked.set(false);
        logger.error('CasinoMicroservice', 'loadIsCasinoBlocked', error);
    }
}

export async function loadCasinoGames(): Promise<void> {
    try {
        const url = getUrl('fo/games/');
        const device = getDeviceCategory();
        const defaultParams = getDefaultQueryParams();
        const params = isTestUser() ? { ...defaultParams, isTestUser: true } : defaultParams;
        const games: CasinoGame[] = (await httpGet<any>(url, params)).filter((game) => game.isPublic || isTestUser());
        games.forEach((game) => {
            game.mode = device;
        });
        stores.casino.allGames.set(games);
        loadFilteredCasinoGames();
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoGames', error);
    }
}

export async function getCasinoGameFull(gameId: number): Promise<CasinoGameFull | undefined> {
    try {
        const url = getUrl(`fo/games/${gameId}`);
        return await httpGet<CasinoGameFull>(url, getDefaultQueryParams());
    } catch (error) {
        logger.error('CasinoMicroservice', 'getCasinoGameFull', error);
    }
}

export async function loadCasinoCategories(): Promise<void> {
    try {
        const url = getUrl('fo/categories/');
        const categories: CasinoCategory[] = await httpGet(url, { device: getDeviceCategory().toLowerCase() });
        stores.casino.categories.set(
            categories.map((category) => {
                return { ...category, code: snakeCase(category.name).toUpperCase() as CasinoCategoryCode };
            }),
        );
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoCategories', error);
    }
}

export async function loadSubproviderPositions(): Promise<void> {
    try {
        const orderedSubprovidersUrl = getUrl('fo/subprovider-positions');
        const country = getStoreValue(stores.isAuthenticated)
            ? (getStoreValue(stores.user) as { country: string }).country
            : getStoreValue(stores.ipCountry);
        const subproviders: CasinoCategorySubprovider[] = await httpGet(orderedSubprovidersUrl, {
            country,
        });
        stores.casino.categoriesSubproviders.set(subproviders);
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadSubproviderPositions', error);
    }
}

export async function loadCasinoActiveBonus() {
    try {
        const url = getServiceUrl('bonuses', 'user/has-active-casino-bonus');
        const hasActiveCasinoBonus: boolean = await httpGet(url);
        stores.casino.hasActiveBonus.set(hasActiveCasinoBonus);
    } catch (error) {
        stores.casino.hasActiveBonus.set(false);
        logger.error('CasinoMicroservice', 'loadCasinoActiveBonus', error);
    }
}

export async function loadCasinoJackpots() {
    if (!isFeatureAvailable(FEATURE.CASINO)) {
        return;
    }

    try {
        const url = getUrl('fo/jackpots');
        const jackpots: { [key: string]: CasinoJackpot } = await httpGet(url, getDefaultQueryParams());
        stores.casino.jackpots.set(jackpots);
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoJackpots', error);
    }
}

export async function loadCasinoDisabledProviders() {
    const licence = getLicence();
    const url = getUrl('fo/maintenance');
    try {
        return await httpGet<CasinoMaintenance[]>(url, { licence });
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoDisabledProviders', error);
        return [] as CasinoMaintenance[];
    }
}

export async function loadCasinoGameForSeoById(
    gameId: string,
): Promise<Pick<CasinoGame, 'name' | 'imageName' | 'gameType' | 'type'>> {
    const url = getUrl(`fo/games/seo/${gameId}`);
    return httpGet<any>(url).catch((error) => logger.error('CasinoMicroservice', 'loadCasinoGameForSeoById', error));
}

export async function loadCasinoProviders() {
    try {
        const [providers, subproviders] = await Promise.all([
            httpGet<CasinoProvider[]>(getUrl('fo/providers/'), getDefaultQueryParams()),
            httpGet<CasinoProvider[]>(getUrl('fo/subproviders/')),
        ]);

        providers.forEach((provider) => {
            provider.type = 'provider';
        });
        subproviders.forEach((subprovider) => {
            subprovider.type = 'subProvider';
        });
        stores.casino.providers.set([...providers, ...subproviders]);
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoProviders', error);
    }
}

export async function loadCasinoFeatures() {
    try {
        const url = getUrl('fo/features/');
        const features: CasinoFeature[] = await httpGet(url);
        stores.casino.features.set(features);
        stores.casino.featuresById.set(keyBy(features, 'id'));
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoFeatures', error);
    }
}

export async function loadCasinoGameLabels(): Promise<void> {
    try {
        const url = getUrl('fo/labels/');
        const gameLabels: CasinoGameLabel[] = await httpGet(url);
        stores.casino.gameLabels.set(gameLabels);
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoGameLabels', error);
    }
}

export async function loadCasinoGameOperatingLanguages(): Promise<void> {
    try {
        const url = getUrl('fo/operating-languages/');
        const operatingLanguages: CasinoGameOperatingLanguage[] = await httpGet(url);
        stores.casino.operatingLanguages.set(operatingLanguages);
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoGameOperatingLanguages', error);
    }
}

export async function loadCasinoLobbySettings(): Promise<void> {
    try {
        const url = getUrl('fo/lobby-settings/');
        const casinoLobbySettings: CasinoLobbySettings = await httpGet(url);
        stores.casino.lobbySettings.set(casinoLobbySettings);
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoLobbySettings', error);
    }
}

export async function loadCasinoStudios(): Promise<void> {
    try {
        const url = getUrl('fo/studios/');
        const studios: CasinoStudio[] = await httpGet(url);
        stores.casino.studios.set(studios);
        stores.casino.studiosById.set(keyBy(studios, 'id'));
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoStudios', error);
    }
}

export async function loadCasinoGamesThemes(): Promise<void> {
    try {
        const url = getUrl('fo/themes/');
        const gameThemes: { name: string; id: number }[] = await httpGet(url);
        stores.casino.themes.set(gameThemes);
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoGamesThemes', error);
    }
}

export async function loadCasinoProviderLiveLobbyTypes(): Promise<void> {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    if (!isAuthenticated) {
        return;
    }
    try {
        const url = getUrl('fo/providers/live-lobby-types');
        const lobbyTypes: LiveLobbyLauncherByGameType = await httpGet(url, getDefaultQueryParams());
        stores.casino.liveLobbyLauncherByGameType.set(lobbyTypes);
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoProviderLiveLobbyTypes', error);
    }
}

export async function loadCasinoLobbyType(): Promise<void> {
    try {
        const url = getUrl('fo/lobby-type');
        const { lobby } = await httpGet<{ lobby: 'v1' | 'v2' }>(url);
        stores.casino.lobbyType.set(lobby);
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCasinoLobbyType', error);
    }
}
