import React, { useEffect } from 'react';
import Wrapper from './styles';
import Svg from '../../svg/Svg';
import { translate } from '../../../services/translate';
import { useComponentFocused } from '../../../services/hooks';

interface Props {
    value?: string;
    onChange: (event) => void;
    onClear: () => void;
    onFocusedInput?: (boolean) => void;
    children?: any;
}

export default function UiSearch({ onFocusedInput, value = '', onChange, onClear, children }: Props) {
    const [elementRefInput, isFocusedInput] = useComponentFocused(false);

    useEffect(() => onFocusedInput && onFocusedInput(isFocusedInput), [isFocusedInput]);

    return (
        <Wrapper className="search">
            <Svg className="search-icon" icon="search-ico" />
            <input
                ref={elementRefInput}
                onChange={onChange}
                value={value}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                data-gramm_editor="false"
                data-gramm="false"
                className="search-input"
                placeholder={translate('Search...', 'casino.search')}
            />
            {onClear && (
                <div className="search-cross-container">
                    <div className="search-cross" onMouseDown={onClear}>
                        <Svg icon="cross" />
                    </div>
                </div>
            )}
            {children}
        </Wrapper>
    );
}
