import styled, { css } from 'styled-components';

export default styled.div`
    display: inline-block;
    position: relative;
    ${({ block }) =>
        block &&
        css`
            width: 100%;
        `};
`;
