import React, { PropsWithChildren } from 'react';
import Wrapper from './styles';
import ResponsibleGamingTimers from '../../../../responsible-gaming/timers/ResponsibleGamingTimers';
import { filterStyleProps } from '../../../../../styles/utils';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';

export default function CoolbetHeaderAuthenticatedClock(props: PropsWithChildren<Record<string, unknown>>) {
    const [{ isDesktop, isLaptop }] = useStore(media);
    const isMobile = !isDesktop && !isLaptop;

    return (
        <Wrapper {...filterStyleProps({ ...props, isMobile })}>
            <ResponsibleGamingTimers className="clock" />
        </Wrapper>
    );
}
