import React, { useEffect } from 'react';
import {
    loadOddsForOutcomeId,
    queuedSubscribeOddsByMarketId,
    queuedUnsubscribeOddsByMarketId,
    SportOddsProps as Props,
} from '../../../../services/sports/odds';
import SportOdds from '../SportOdds';

export default function SportOddsLoad(props: Props) {
    const { outcomeId, market } = props;

    useEffect(() => {
        if (market.id < 0) {
            return;
        }
        loadOddsForOutcomeId(outcomeId, market.id);
        queuedSubscribeOddsByMarketId(market.id);
        return () => queuedUnsubscribeOddsByMarketId(market.id);
    }, [market.id, outcomeId]);
    return <SportOdds {...props} />;
}
