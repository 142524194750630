import moment from 'moment';
import {
    setScheduledTaskStatusExecuting,
    setScheduledTaskStatusIdle,
    purgeExpiredScheduledTaskStorageKeys,
    getIsScheduledTaskPending,
} from '../../hooks/useScheduler';
import {
    getGeocomplyLicense,
    getGeoVerificationStatus,
    postGeopacketForProcessing,
} from '../../microservices/geocomply';
import { fetchIpDetails } from '../../microservices/ip';
import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { getOS, OPERATING_SYSTEM } from '../device';
import { FEATURE, isFeatureAvailable } from '../feature';
import { setDeviceUuid } from '../fingerprint';
import { logger } from '../logger';
import { geoComplyDesktopPlatformGeoVerification } from './desktop';
import { GeoComplyMobileHint, geoComplyMobilePlatformGeoVerification } from './mobile';
import { isRetail } from '../environment';

export const GEOCOMPLY_VERIFICATION_TASK_NAME = 'GEOCOMPLY_VERIFICATION_TASK';
export const GEOCOMPLY_IP_CHECK_TASK_NAME = 'GEOCOMPLY_IP_CHECK_TASK';

export async function loadGeocomplyLicenseStatus(isLoadByError = false): Promise<void> {
    stores.geocomply.isLoadingLicense.set(true);

    try {
        const geocomplyLicense = await getGeocomplyLicense(isLoadByError);
        stores.geocomply.license.set(geocomplyLicense);
    } catch (error) {
        logger.error('GeocomplyGeocomplyService', 'loadGeocomplyLicenseStatus', error);
        stores.geocomply.license.set(geocomplyInitialLicense);
    } finally {
        stores.geocomply.isLoadingLicense.set(false);
    }
}

export async function loadGeocomplyVerificationStatus(): Promise<void> {
    stores.geocomply.isLoadingVerification.set(true);

    try {
        const { deviceUuid, isGeoVerified, expiresAt, retryAt, ipAddress } = await getGeoVerificationStatus();

        stores.geocomply.verification.set({
            deviceUuid,
            isGeoVerified,
            expiresAt,
            retryAt,
            ipAddress,
        });
    } catch (error) {
        logger.error('GeocomplyGeocomplyService', 'loadGeocomplyVerificationStatus', error);
        stores.geocomply.verification.set(geocomplyInitialVerification);
    } finally {
        stores.geocomply.isLoadingVerification.set(false);
    }
}

export async function getClientIp(): Promise<string> {
    const ipDetails = await fetchIpDetails();
    return ipDetails.query;
}

export function isDesktopPlatform() {
    const os = getOS();
    return [OPERATING_SYSTEM.APPLE_DESKTOP, OPERATING_SYSTEM.WINDOWS_DESKTOP].includes(os);
}

export function isMobilePlatform() {
    const os = getOS();
    return [OPERATING_SYSTEM.APPLE_MOBILE, OPERATING_SYSTEM.ANDROID].includes(os);
}

let geoComplyVerificationPromise: Promise<void> | undefined;

function getGeoComplyVerificationPromise(reason: GEOCOMPLY_REASON, isSilentMode = false, forceVerification = false) {
    if (geoComplyVerificationPromise) {
        return geoComplyVerificationPromise;
    }

    // We build one promise which we return to every verifyGeoLocation call while the processing is taking place
    // eslint-disable-next-line @typescript-eslint/require-await
    geoComplyVerificationPromise = (async () => {
        try {
            setScheduledTaskStatusExecuting(GEOCOMPLY_VERIFICATION_TASK_NAME);

            stores.geocomply.serviceError.set(geocomplyInitialServiceError);
            stores.geocomply.unexpectedError.set(null);
            stores.geocomply.isSilentMode.set(isSilentMode);
            stores.geocomply.lastReason.set(reason);

            if (!forceVerification) {
                await loadGeocomplyVerificationStatus();
            }

            if (!forceVerification && getStoreValue(stores.geocomply.verification).isGeoVerified) {
                return;
            }

            if (isMobilePlatform()) {
                return await geoComplyMobilePlatformGeoVerification(reason);
            }

            if (isDesktopPlatform()) {
                return await geoComplyDesktopPlatformGeoVerification(reason);
            }

            throw new Error(`verifyGeoLocation: Geo-verification handler not implemented for platform ${getOS()}`);
        } catch (error: any) {
            logger.error('GeocomplyGeocomplyService', 'getGeoComplyVerificationPromise', error);
            const hasLicenceExpired = error.code === 608;

            if (hasLicenceExpired) {
                return await recallVerifyGeolocation(reason, isSilentMode, forceVerification);
            } else {
                throw new GeocomplyError(error.message);
            }
        } finally {
            geoComplyVerificationPromise = undefined;
            setScheduledTaskStatusIdle(GEOCOMPLY_VERIFICATION_TASK_NAME);
        }
    })();

    return geoComplyVerificationPromise;
}

export async function renewGeoLocation() {
    try {
        if (!isFeatureAvailable(FEATURE.GEOCOMPLY) || isRetail()) {
            return;
        }

        if (geoComplyVerificationPromise) {
            return geoComplyVerificationPromise;
        }

        await getGeoComplyVerificationPromise('RENEW', true, true);
    } catch (error) {
        logger.error('GeocomplyGeocomplyService', 'renewGeoLocation', error);
    }
}

export async function verifyGeoLocation(reason: GEOCOMPLY_REASON, isSilentMode = false, forceVerification = false) {
    if (!isFeatureAvailable(FEATURE.GEOCOMPLY) || isRetail()) {
        return;
    }

    purgeExpiredScheduledTaskStorageKeys(GEOCOMPLY_VERIFICATION_TASK_NAME);

    if (geoComplyVerificationPromise) {
        return geoComplyVerificationPromise;
    }

    if (getIsScheduledTaskPending(GEOCOMPLY_VERIFICATION_TASK_NAME)) {
        throw new GeocomplyError(
            `Task "${GEOCOMPLY_VERIFICATION_TASK_NAME}" is most likely already running in another tab. Not running it in this tab.`,
        );
    }

    return await getGeoComplyVerificationPromise(reason, isSilentMode, forceVerification);
}

async function recallVerifyGeolocation(reason: GEOCOMPLY_REASON, isSilentMode = false, forceVerification = false) {
    const isLoadingLicense = getStoreValue(stores.geocomply.isLoadingLicense);

    geoComplyVerificationPromise = undefined;
    setScheduledTaskStatusIdle(GEOCOMPLY_VERIFICATION_TASK_NAME);

    if (!isLoadingLicense) {
        return await verifyGeoLocation(reason, isSilentMode, forceVerification);
    } else {
        setTimeout(() => {
            recallVerifyGeolocation(reason, isSilentMode, forceVerification);
        }, 250);
    }
}

export async function handleEncryptedGeopacket(encryptedGeopacket: string, reason: GEOCOMPLY_REASON): Promise<void> {
    try {
        const geoVerificationStatus = await postGeopacketForProcessing(encryptedGeopacket);

        const { deviceUuid, isGeoVerified, expiresAt, retryAt, ipAddress } = geoVerificationStatus;
        setDeviceUuid(deviceUuid);

        stores.geocomply.verification.set({
            deviceUuid,
            isGeoVerified,
            expiresAt,
            retryAt,
            ipAddress,
        });
    } catch (error: any) {
        const isKnownErrorCode = error.code >= 1000 && error.code < 4000;
        const hasLicenceExpired = error.code === 608;

        if (hasLicenceExpired) {
            return await recallVerifyGeolocation(reason);
        } else if (isKnownErrorCode) {
            stores.geocomply.serviceError.set({
                code: error.code,
                name: error.name,
                troubleshooter: error.troubleshooter ?? null,
            });
        } else {
            stores.geocomply.unexpectedError.set(error);
        }

        logger.error('GeocomplyGeocomplyService', 'handleEncryptedGeopacket', error);

        throw error;
    }
}

export async function attemptWithRetry<T>(
    retryErrorCodes: Array<number>,
    knownErrorCodes: Array<number>,
    promiseFactory: (attemptIndex: number, totalAttempts: number) => Promise<T>,
    totalAttempts = 20,
    retryDelayMs = 2000,
) {
    let currentError;
    let attemptsLeft = totalAttempts;

    while (attemptsLeft > 0) {
        const attemptIndex = totalAttempts - attemptsLeft + 1;

        try {
            return await promiseFactory(attemptIndex, totalAttempts);
        } catch (error: any) {
            if (!knownErrorCodes.includes(parseInt(error.code))) {
                stores.geocomply.unexpectedError.set(error);
                throw error;
            }

            const isLastAttempt = attemptIndex === totalAttempts;

            if (!retryErrorCodes.includes(error.code) || isLastAttempt) {
                stores.geocomply.client.set((geocomplyClient) => {
                    geocomplyClient.clientError = { code: error.code, message: error.message };
                });

                throw error;
            }

            currentError = error;
            await new Promise((resolve) => setTimeout(resolve, retryDelayMs));
            attemptsLeft--;
        }
    }

    throw currentError;
}

export const geocomplyInitiaLastReason: GEOCOMPLY_REASON | null = null;

export const geocomplyInitialLicense: GeocomplyLicenseStore = { license: null, expiresAt: null };

export const geocomplyInitialVerification: GeocomplyGeoverificationStatusStore = {
    deviceUuid: null,
    isGeoVerified: null,
    expiresAt: null,
    retryAt: null,
    ipAddress: null,
};

export const geocomplyInitialServiceError: GeocomplyServiceError = null;

export const geocomplyClientInitialStatus: GeocomplyClientInitialStatusStore = {
    logs: [],
    hint: null,
    clientError: null,
    libraryVersion: null,
    clientVersion: null,
    isConnected: false,
    isConnecting: false,
    isAttemptingConnection: false,
    isGeolocating: false,
    isAttemptingGeolocation: false,
    isLibraryLoaded: false,
};

export function softResetGeocomplyStore() {
    stores.geocomply.isSilentMode.set(false);
    stores.geocomply.serviceError.set(geocomplyInitialServiceError);
    stores.geocomply.unexpectedError.set(null);
    stores.geocomply.client.set({
        ...geocomplyClientInitialStatus,
        logs: getStoreValue(stores.geocomply.persistLogs) ? getStoreValue(stores.geocomply.client).logs : [],
        isLibraryLoaded: getStoreValue(stores.geocomply.client).isLibraryLoaded,
    });
}

export function hardResetGeocomplyStore() {
    stores.geocomply.isDevModeVisibility.set(false);
    stores.geocomply.isSilentMode.set(false);
    stores.geocomply.isLoadingVerification.set(false);
    stores.geocomply.isLoadingLicense.set(false);
    stores.geocomply.persistLogs.set(false);
    stores.geocomply.lastReason.set(geocomplyInitiaLastReason);
    stores.geocomply.license.set(geocomplyInitialLicense);
    stores.geocomply.verification.set(geocomplyInitialVerification);
    stores.geocomply.serviceError.set(geocomplyInitialServiceError);
    stores.geocomply.unexpectedError.set(null);
    stores.geocomply.client.set(geocomplyClientInitialStatus);
}

export function logMessage(message: string, level: LOG_LEVEL) {
    return stores.geocomply.client.set((geocomplyClient) => {
        const newLogMessage: GeocomplyLogMessage = {
            time: moment().toISOString(),
            message,
            level,
        };

        geocomplyClient.logs = [...geocomplyClient.logs, newLogMessage];
    });
}

export function geocomplyClientErrorHandler(errorCode, errorMessage) {
    logger.error('GeocomplyGeocomplyService', 'geocomplyClientErrorHandler', `${errorCode} | ${errorMessage}`);
    logMessage(`${errorCode} | ${errorMessage}`, 'ERROR');

    stores.geocomply.client.set((geocomplyClient) => {
        geocomplyClient.clientError = null;
        geocomplyClient.clientVersion = null;
        geocomplyClient.isConnected = false;
        geocomplyClient.isConnecting = false;
        geocomplyClient.isGeolocating = false;
    });

    if (errorCode === 608) {
        stores.geocomply.serviceError.set(geocomplyInitialServiceError);
        stores.geocomply.license.set(geocomplyInitialLicense);
    }
}

export function getCountdownState(countdownEndTimeISO: string, currentTimeISO?: string) {
    const currentTime = currentTimeISO ? moment(currentTimeISO) : moment();
    const countdownEndTime = moment(countdownEndTimeISO);
    const durationUntilEndTime = moment.duration(countdownEndTime.diff(currentTime));
    const isCountdownCompleted = durationUntilEndTime.asSeconds() <= 0;

    return {
        formattedCountdown: isCountdownCompleted
            ? '00:00'
            : (durationUntilEndTime as any).format('mm:ss', { trim: false }),
        totalMinutesLeft: Math.max(durationUntilEndTime.asMinutes(), 0),
        totalSecondsLeft: Math.max(durationUntilEndTime.asSeconds(), 0),
        totalMillisecondsLeft: Math.max(durationUntilEndTime.asMilliseconds(), 0),
    };
}

export const startDurationTimer = () => {
    const unixStartTime = new Date().getTime();

    const getHumanizedDuration = (durationInMs: number): string => {
        if (durationInMs < 1000) {
            return `${durationInMs}ms`;
        }

        if (durationInMs < 60000) {
            const seconds = Math.floor(durationInMs / 1000);
            const milliseconds = durationInMs - seconds * 1000;

            return `${seconds}sec ${milliseconds}ms`;
        }

        const minutes = Math.floor(durationInMs / 60000);
        const seconds = (durationInMs - minutes * 60000) / 1000;

        return `${minutes}min  ${seconds}sec`;
    };

    return () => {
        const durationInMs = new Date().getTime() - unixStartTime;

        return {
            milliseconds: durationInMs,
            humanized: getHumanizedDuration(durationInMs),
        };
    };
};

class GeocomplyError extends Error {
    constructor(message = 'GeoComply Error') {
        super(message);
    }
}

interface GeocomplyLicenseStore {
    license: string | null;
    expiresAt: string | null;
}

interface GeocomplyGeopacketTroubleshooterMessage {
    help: string;
    message: string;
    optin: string;
    retry: boolean;
    rule: string;
}

type GeocomplyGeopacketErrors = Array<GeocomplyGeopacketTroubleshooterMessage> | null;

export type GeocomplyServiceError = {
    code: number;
    name: string;
    troubleshooter: GeocomplyGeopacketErrors;
} | null;

export interface GeocomplyClientError {
    code: number;
    message: string;
}

interface GeocomplyClientInitialStatusStore {
    logs: Array<GeocomplyLogMessage>;
    clientError: GeocomplyClientError | null;
    hint: GeoComplyMobileHint | null;
    libraryVersion: string | null;
    clientVersion: string | null;
    isConnected: boolean;
    isConnecting: boolean;
    isAttemptingConnection: boolean;
    isGeolocating: boolean;
    isAttemptingGeolocation: boolean;
    isLibraryLoaded: boolean;
}

interface GeocomplyGeoverificationStatusStore {
    deviceUuid: string | null;
    isGeoVerified: boolean | null;
    expiresAt: string | null;
    retryAt: string | null;
    ipAddress: string | null;
}

type LOG_LEVEL = 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR' | 'DEBUG';

interface GeocomplyLogMessage {
    time: string;
    message: string;
    level: LOG_LEVEL;
}

export interface GeocomplyLicense {
    license: string;
    expiresAt: string;
}

export interface GeocomplyGeoverificationStatus {
    deviceUuid: string;
    isGeoVerified: boolean;
    expiresAt: string | null;
    retryAt: string | null;
    ipAddress: string | null;
}

export type GeocomplyGeoverification = GeocomplyGeoverificationStatusStore | GeocomplyGeoverificationStatus;

export type GEOCOMPLY_ENVIRONMENT = 'production' | 'staging';
export type GEOCOMPLY_REASON = 'TEST' | 'BET' | 'CASINO' | 'DEPOSIT' | 'WITHDRAW' | 'RENEW' | 'IP_CHANGE' | 'LOGIN';

export enum GEOCOMPLY_SERVICE_ERROR {
    GEOPACKET_IP_ADDRESS_MISMATCH_ERROR = 3006,
}
