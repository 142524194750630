import React, { useState } from 'react';
import { stores } from '../../../../stores';
import { generateUserData } from '../../../../services/auto-fill/auto-fill';
import { translate } from '../../../../services/translate';
import { useForm } from '../../../../services/form';
import { isProduction } from '../../../../services/util';
import { REGISTRATION_FLOW } from '../../../../services/auth';
import { getUserCountry } from '../../../../services/user';
import UiButton from '../../../ui/button/UiButton';

interface Props {
    flow: REGISTRATION_FLOW;
    onDataGenerated?: () => void;
}

export default function AuthRegisterAutoFill({ flow, onDataGenerated = () => {} }: Props) {
    const [isLoading, setIsLoading] = useState(false);

    const form = useForm(stores.registration.form);

    if (isProduction()) {
        return null;
    }

    async function generateData() {
        setIsLoading(true);
        const userCountry = flow === REGISTRATION_FLOW.EMAIL ? form.country.value : getUserCountry();
        const generatedData = await generateUserData(flow, userCountry);
        form.setInputValue({
            ...generatedData,
            acceptTermsAndConditions: true,
            hasAcceptedAgeAndPlayingOwnBehalf: true,
        });
        onDataGenerated();
        setIsLoading(false);
    }

    return (
        <UiButton block data-test="autofill" onClick={generateData} isLoading={isLoading}>
            {translate('Auto-fill', 'ui.registration')}
        </UiButton>
    );
}
