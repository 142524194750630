import { Store, StoreSetter } from '../stores/store/types';
import { useEffect, useState } from 'react';

export function useStore<T>(store: Store<T>): [T, StoreSetter<T>] {
    const [state, setState] = useState<T>(store.state);
    store.subscribe(setState);

    useEffect(() => () => store.unsubscribe(setState), []);

    return [state, store.set];
}
