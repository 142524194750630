import React from 'react';
import classNames from 'classnames';
import { MATCH_STATUS } from '../../../../services/sports/constants';
import { translate } from '../../../../services/translate';
import SportOddsLoad from '../../../sport/odds/load/SportOddsLoad';
import { OddPromotionComponentProps as Props } from '../../../../services/cms-promotion';

export default function MarketingMatchPromotionOdd({ promotion }: Props) {
    const { id, use_highlight_text: useHighlightText, use_content_text: useContentText } = promotion;
    const sportOddsProprs = {
        outcomeId: promotion.outcome_id,
        market: {
            ...promotion.market,
            in_play: promotion.status === MATCH_STATUS.LIVE,
            betting_end: promotion.match_betting_end,
        },
        promotion,
    };

    return (
        <>
            <span
                className={classNames(
                    'promotion-highlighted-text',
                    'promotion-marged-action',
                    (useHighlightText || useContentText) && 'with-left-margin',
                )}
            >
                {translate(`ui.promotion.odd-text.${id}`)}
                <span className="promotion-sm-margin-sides"> → </span>
            </span>
            <div className="promotion-sm-margin-sides">
                <SportOddsLoad {...sportOddsProprs} />
            </div>
        </>
    );
}
