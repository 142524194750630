import React, { useEffect, useState } from 'react';
import { COUNTRY } from '../../../../../services/country';
import { LicenceRequirementSteps as Steps } from '../../../../../services/licence';
import { AuthProfileValidatorLimitsProps as Props, LIMIT_TYPE } from '../../../../../services/responsible-gaming';
import { translate } from '../../../../../services/translate';
import { isUserFromCountry } from '../../../../../services/user';
import { stores } from '../../../../../stores';
import AuthProfileValidatorLimits from '../../limits/AuthProfileValidatorLimits';
import AuthProfileValidatorOccupation from '../../occupation/AuthProfileValidatorOccupation';
import { useStore } from '../../../../../hooks/useStore';

export default function AuthProfileValidatorLicenceRequirementMalta(props: Props) {
    const { onAfterSubmit, isModal } = props;
    const [step, setStep] = useState<Steps>(Steps.DEPOSIT_LIMIT);
    const [user] = useStore(stores.user);
    const [askDepositLimit] = useStore(stores.responsibleGaming.askDepositLimit);

    useEffect(() => {
        if (user?.isProfileReviewRequired) {
            setStep(Steps.OCCUPATION);
        }
    }, [user?.isProfileReviewRequired]);

    const modals = {
        [Steps.DEPOSIT_LIMIT]: (
            <AuthProfileValidatorLimits
                {...props}
                key="deposit-limit"
                heading={translate('Deposit limits', 'ui.account')}
                onAfterSubmit={goToNextStep}
                isOfferOnly={!isUserFromCountry(COUNTRY.NORWAY)}
                limitType={LIMIT_TYPE.DEPOSIT}
                snippetKey={`auth.update-${
                    isUserFromCountry(COUNTRY.NORWAY) ? 'norway' : 'malta'
                }-license-fields-deposit`}
            />
        ),
        [Steps.OCCUPATION]: (
            <AuthProfileValidatorOccupation isModal={isModal} onAfterSubmit={goToNextStep} onExit={goToNextStep} />
        ),
    };

    function goToNextStep() {
        if (step === Steps.DEPOSIT_LIMIT && user?.isProfileReviewRequired) {
            setStep(Steps.OCCUPATION);
        } else if (step === Steps.OCCUPATION && askDepositLimit) {
            setStep(Steps.DEPOSIT_LIMIT);
        } else {
            onAfterSubmit();
        }
    }

    return modals[step];
}
