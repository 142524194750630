import styled from 'styled-components';
import { darken } from 'polished';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    padding: 0.5em;
    margin-bottom: 2em;
    width: 100%;
    max-width: 900px;
    text-align: center;
    border-radius: var(--ui-radius);
    background-color: ${({ theme }) => darken(0.05, theme.pokerLeaderboard.primaryBackground)};
    @media ${mediaQuery.isPhone} {
        margin-bottom: 100px;
    }

    .participants-header {
        margin: 0 auto 5px;
        max-height: inherit;
        overflow: hidden;
        position: relative;
        background-color: ${({ theme }) => darken(0.2, theme.pokerLeaderboard.primaryBackground)};
        border-radius: 5px;
        display: flex;
        justify-content: stretch;
        align-items: center;
        border: 1px solid transparent;
        z-index: 1;
        height: 41px;
        text-align: left;
        @media ${mediaQuery.isPhone} {
            justify-content: center;
        }

        div {
            margin: 0 5px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            letter-spacing: 1px;
            color: var(--font-color-subtle);
            font-weight: bold;
            font-size: 13px;
            overflow: hidden;
            text-align: right;
        }

        .position {
            display: block;
            text-align: center;
            width: 50px;
            line-height: 30px;
            font-weight: 700;
            text-align: center;
        }

        .alias {
            width: 200px;

            @media ${mediaQuery.isPhone} {
                flex: 1;
            }
        }

        .score {
            text-align: center;
            width: 100px;

            @media ${mediaQuery.isPhone} {
                justify-content: flex-end;
                padding-right: 5px;
            }
        }

        .eventCount {
            width: 80px;

            @media ${mediaQuery.isPhone} {
                display: none;
            }
        }

        .prize {
            flex: 1;
            justify-content: center;

            @media ${mediaQuery.isPhone} {
                display: none;
            }
        }
    }

    .participants-container {
        max-height: 500px;
        overflow: auto;
    }
`;
