import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    .side-component-content {
        padding: 0 0 0 ${({ theme }) => theme.layout.gutter};
        margin-bottom: ${({ theme }) => theme.layout.gutter};
        opacity: 0.6;
        transition: opacity 200ms linear;
        @media ${mediaQuery.isPhone} {
            opacity: 1;
        }
        &:hover {
            opacity: 1;
        }
        .preview {
            padding: 1rem;
            line-height: 1.5;
        }
    }
`;
