import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { logger } from '../services/logger';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { getDeviceCategory } from '../services/device';

const getUrl = (url) => getServiceUrl('poker-playtech-gameslink', url);

export async function updatePokerMaintenanceStatus() {
    const url = getUrl('fo/maintenance-status');

    try {
        const maintenance = await httpGet<{ isMaintenance: boolean; endDate?: string }>(url);
        stores.maintenance.isPokerMaintenance.set(maintenance.isMaintenance);
        stores.maintenance.pokerMaintenanceEndDate.set(maintenance.endDate);
    } catch (error) {
        logger.error('PokerPlaytechGameslinkMicroservice', 'updatePokerMaintenanceStatus', error);
    }
}

export async function initGame(
    isWebClient: boolean,
    tournamentCode?: number,
): Promise<{ token: string; gameLaunchUrl?: string }> {
    const platform = getDeviceCategory();
    const url = getUrl('fo/init-game');
    return httpPost(url, { isWebClient, tournamentCode, platform });
}

interface CachbackInfo {
    previousWeekStart: string;
    previousWeekEnd: string;
    currentWeekStart: string;
    currentWeekEnd: string;
    previousRake: number;
    previousCashback: number;
    currentRake: number;
    potentialCashback: number;
}

export async function getCashbackInfo(): Promise<CachbackInfo> {
    return httpGet(getUrl('fo/cashback'));
}

export async function getCoolbetTokenFromPoker(loginSessionId: string, token: string): Promise<string> {
    return httpPost(getUrl('fo/poker-session'), { loginSessionId, token });
}

export enum PromotionSource {
    NETWORK = 'network',
    CARDROOM = 'cardRoom',
}

export interface UpcomingTournament {
    code: number;
    name: string;
    startDate: string;
    gameType: string;
    registeredPlayers: number;
    buyIn: number;
    promotionSource: PromotionSource;
    fee: number;
}

export async function getUpcomingTournaments(ipCountry: string, userCountry: string): Promise<UpcomingTournament[]> {
    return httpGet(getUrl('fo/upcoming-tournaments'), { country: ipCountry, userCountry });
}

export async function loadIsPokerBlocked(queryCountryCode: string | undefined): Promise<void> {
    stores.poker.isLoadingPokerBlocked.set(true);
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const path = isAuthenticated ? 'fo/is-poker-blocked-auth' : 'fo/is-poker-blocked';
    const url = getUrl(path);
    try {
        const { isPokerBlocked } = (await httpGet(url, { countryCode: queryCountryCode })) as any;
        stores.poker.isPokerBlocked.set(isPokerBlocked);
    } catch (err) {
        stores.poker.isPokerBlocked.set(false);
        logger.error('PokerPlaytechGameslinkMicroservice', 'loadIsPokerBlocked', err);
    } finally {
        stores.poker.isFirstRenderLoading.set(false);
        stores.poker.isLoadingPokerBlocked.set(false);
    }
}

export interface PokerLeaderboardParticipant {
    rank: number;
    alias: string;
    eventCount?: number;
    score?: number;
    resets?: number;
    type?: string;
    amount?: number;
    value?: number;
    currencyCode?: string;
    name?: string;
    displayName?: string;
}

export interface PokerLeaderboard {
    code: number;
    startTime: string;
    endTime: string;
    registrationStartTime: string;
    registrationEndTime: string;
    publicationTime: string;
    status: string;
    leaderboardEventCount: number;
    minEvents: number;
    maxResets: number;
    createdAt: Date;
    updatedAt: Date;
    name: string;
    displayName: string;
    language: string;
    description: string;
    tnc: string;
    participants: PokerLeaderboardParticipant[];
}
export async function getLeaderboard(code: string, languageCode: string): Promise<PokerLeaderboard> {
    return httpGet(getUrl(`fo/leaderboard/${code}`), { languageCode });
}
