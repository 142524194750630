import React, { useEffect } from 'react';
import Wrapper from './styles';
import SportHotEvents from '../../hot-events/SportHotEvents';
import SportTreeMenu from '../../tree/menu/SportTreeMenu';
import SportSidebarSoonCampaigns from '../../sidebar/soon/campaigns/SportSidebarSoonCampaigns';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../../services/analytics';
import SportSettingsMenuButton from '../../settings-menu/button/SportSettingsMenuButton';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import SportBonusBetList from '../../bonus-bet/list/SportBonusBetList';

export default function SportPhoneSelectSportSidebar() {
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);

    useEffect(() => {
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.SPORT_MOBILE_MENU_OPENED);
    }, []);
    return (
        <Wrapper>
            <SportSettingsMenuButton />

            <div className="sport-menu">
                {!isLayoutB && <SportBonusBetList />}
                <SportSidebarSoonCampaigns />
                {!isLayoutB && (
                    <>
                        <SportHotEvents wrap type="menu" />
                    </>
                )}
                <SportTreeMenu />
            </div>
        </Wrapper>
    );
}
