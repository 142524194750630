import React, { useEffect } from 'react';
import Wrapper from './styles';
import { stores } from '../../stores';
import MainLayout from '../main/MainLayout';
import { loadBlockedProductsByPlayerCountry } from '../../microservices/users';
import UiAppLoader from '../../components/ui/app-loader/UiAppLoader';
import HorseRacingSidebar from '../../components/horse-racing/sidebar/HorseRacingSidebar';
import RetailHotEvents from '../../components/retail/hot-events/RetailHotEvents';
import SportTreeRetail from '../../components/sport/tree/retail/SportTreeRetail';
import HorseRacingPhoneNavigation from '../../components/horse-racing/phone/navigation/HorseRacingPhoneNavigation';
import { isRetail } from '../../services/environment';
import { media } from '../../stores/media/media';
import { useStore } from '../../hooks/useStore';

interface Props {
    children: any;
}

export default function HorseRacingLayout({ children }: Props) {
    const [{ isDesktop, isLaptop }] = useStore(media);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const isRetailLayout = isRetail();

    useEffect(() => {
        if (!isAuthenticated) {
            loadBlockedProductsByPlayerCountry();
        }
    }, [isAuthenticated]);

    const [userSettings] = useStore(stores.sports.userSettings);
    const dependenciesLoaded = userSettings;

    function HorseRacingLayoutComponent() {
        return (
            <Wrapper $isRetailLayout={isRetailLayout}>
                {isRetailLayout && (
                    <div className="hot-event-card">
                        <RetailHotEvents />
                    </div>
                )}

                {isRetailLayout && <SportTreeRetail />}

                <div className="sport-content">
                    {!isRetailLayout && <div className="sport-menu" />}

                    <div className="sport-matches">{dependenciesLoaded ? children : <UiAppLoader />}</div>

                    {isDesktop || isLaptop ? (
                        <div className="sport-sidebar">
                            <HorseRacingSidebar />
                        </div>
                    ) : (
                        <HorseRacingPhoneNavigation />
                    )}
                </div>
            </Wrapper>
        );
    }
    return !isRetailLayout ? <MainLayout>{HorseRacingLayoutComponent()}</MainLayout> : HorseRacingLayoutComponent();
}
