import React from 'react';
import Wrapper from './styles';
import ComboCardsBetslipHeader from './header/ComboCardsBetslipHeader';
import { stores } from '../../../stores';
import ComboCardsBetslipSelection from './selection/ComboCardsBetslipSelection';
import ComboCardsBetslipFooter from './footer/ComboCardsBetslipFooter';
import omit from 'lodash/omit';
import { resetComboCardBetSlip } from '../../../services/sports/combo-cards';
import ComboCardsBetslipReceipt from './receipt/ComboCardsBetslipReceipt';
import UiButton from '../../ui/button/UiButton';
import { translate } from '../../../services/translate';
import { initialBetSlipPlacingState } from '../../../services/sports/constants';
import { isMobile } from '../../../services/browser';
import { useStore } from '../../../hooks/useStore';

export default function ComboCardsBetslip() {
    const [cardsInBetslip, setCardsInBetslip] = useStore(stores.sports.comboCard.cardsInBetslip);
    const [, setStakeByCardId] = useStore(stores.sports.comboCard.stakeByCardId);
    const [{ receiptById }] = useStore(stores.sports.betSlipPlacingState);
    const cardIdsWithReceipt = Object.keys(receiptById);

    function clearSelections(cardId?: number) {
        if (!cardId) {
            resetComboCardBetSlip();
            return;
        }

        const updatedCards = cardsInBetslip.filter(({ id }) => id !== cardId);
        if (updatedCards.length) {
            setCardsInBetslip(updatedCards);
            setStakeByCardId((prevState) => omit(prevState, cardId));
            return;
        }
        resetComboCardBetSlip();
    }

    function removeReceipt() {
        resetComboCardBetSlip();
        stores.sports.betSlipPlacingState.set(initialBetSlipPlacingState);
        if (isMobile()) {
            stores.isBetslipOpen.set(false);
        }
    }

    return (
        <Wrapper>
            {Boolean(cardIdsWithReceipt.length) ? (
                <>
                    <UiButton block onClick={removeReceipt} className="remove-receipt">
                        {translate('Close', 'ui.common')}
                    </UiButton>
                    {cardIdsWithReceipt.map((cardId) => (
                        <ComboCardsBetslipReceipt key={cardId} cardId={cardId} removeReceipt={removeReceipt} />
                    ))}
                </>
            ) : (
                <>
                    <ComboCardsBetslipHeader numberOfCards={cardsInBetslip.length} clearSelections={clearSelections} />
                    {cardsInBetslip.map((card) => (
                        <ComboCardsBetslipSelection key={card.id} card={card} clearSelections={clearSelections} />
                    ))}
                    <ComboCardsBetslipFooter clearSelections={clearSelections} />
                </>
            )}
        </Wrapper>
    );
}
