import styled from 'styled-components';

export default styled.span`
    position: absolute;
    display: block;
    z-index: 2;
    animation: comeInOut 700ms forwards;
    svg {
        display: block;
        animation: spin 1000ms linear;
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(180deg);
            }
        }
    }

    @keyframes comeInOut {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
`;
