import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { isB2B } from './environment';
import { COUNTRY } from './country';
import { Currency } from './wallet/types';

export const CURRENCY_SYMBOL = {
    [Currency.CAD]: '$',
    [Currency.CLP]: '$',
    [Currency.EUR]: '€',
    [Currency.MXN]: '$',
    [Currency.NOK]: 'kr',
    [Currency.PEN]: 'S/.',
    [Currency.SEK]: 'kr',
    [Currency.USD]: '$',
    [Currency.USDT]: '$',
};

const currencyFormatLocales = {
    [COUNTRY.CANADA]: 'en-CA',
    [COUNTRY.USA]: 'en-US',
    [COUNTRY.CHILE]: 'es-CL',
    [COUNTRY.ECUADOR]: 'es-EC',
    [COUNTRY.MEXICO]: 'es-MX',
    [COUNTRY.PERU]: 'es-PE',
    [COUNTRY.ESTONIA]: 'fi-FI',
    [COUNTRY.FINLAND]: 'fi-FI',
    [COUNTRY.SWEDEN]: 'sv-SE',
    [COUNTRY.NORWAY]: 'no-NO',
    [COUNTRY.ICELAND]: 'nl-BE', // closest format to Iceland
};

const STATIC_RATES = {
    [Currency.CAD]: 1.5,
    [Currency.CLP]: 750,
    [Currency.EUR]: 1,
    [Currency.MXN]: 20,
    [Currency.NOK]: 10,
    [Currency.PEN]: 4,
    [Currency.SEK]: 10,
    [Currency.USD]: 1,
    [Currency.USDT]: 1,
};

const ipCurrency = {
    [COUNTRY.CANADA]: Currency.CAD,
    [COUNTRY.USA]: Currency.USD,
    [COUNTRY.CHILE]: Currency.CLP,
    [COUNTRY.ECUADOR]: Currency.USD,
    [COUNTRY.MEXICO]: Currency.MXN,
    [COUNTRY.PERU]: Currency.PEN,
    [COUNTRY.ESTONIA]: Currency.EUR,
    [COUNTRY.FINLAND]: Currency.EUR,
    [COUNTRY.SWEDEN]: Currency.SEK,
    [COUNTRY.NORWAY]: Currency.NOK,
    [COUNTRY.ICELAND]: Currency.EUR,
};

export function formattedAmountWithCurrency(
    amount: number,
    options?: { currency?: Currency; maximumFractionDigits?: number },
) {
    let activeCurrency = options?.currency || getActiveCurrency();
    if (activeCurrency === Currency.USDT) {
        activeCurrency = Currency.USD;
    }
    const country = getStoreValue(stores.user)?.country || getStoreValue(stores.ipCountry);

    try {
        const formattedAmount = amountWithCurrency(amount, activeCurrency, country, options?.maximumFractionDigits);
        return formattedAmount;
    } catch (error) {
        return amountWithCurrency(amount, Currency.USD, COUNTRY.USA);
    }
}

export function amountWithCurrency(amount: number, currency: Currency, country: COUNTRY, maximumFractionDigits = 2) {
    const locale = currencyFormatLocales[country];
    const style = {
        style: 'currency',
        currency,
        maximumFractionDigits,
        minimumFractionDigits: maximumFractionDigits,
    };

    return new Intl.NumberFormat(locale, style).format(amount);
}

export function getAmountByStaticRate(amountInEUR: number, currency: Currency, rateMultipliers = STATIC_RATES) {
    return parseFloat((amountInEUR * rateMultipliers[currency]).toFixed(2));
}

export function getActiveCurrency() {
    const wallet = getStoreValue(stores.wallet);
    if (wallet) {
        return wallet.currency;
    }

    if (isB2B()) {
        return Currency.USD;
    }

    const country = getStoreValue(stores.ipCountry);
    return (ipCurrency[country] as Currency) || Currency.EUR;
}
