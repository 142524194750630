import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

const isCompact = ({ isCompact }) =>
    isCompact &&
    css`
        flex-direction: column;
        justify-content: center;
        margin-right: 0;
        > *:not(:last-of-type) {
            margin-bottom: 0.2rem;
        }
    `;

export default styled.div`
    display: flex;
    align-items: center;

    text-align: center;
    font-size: 0.7rem;

    ${isCompact}

    > *:not(:last-child) {
        margin-right: ${(props) => (props.isCompact ? '0' : '0.4rem')};
    }
    .live-time {
        color: var(--match-live-text-color);
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering};
        font-size: 0.875rem;
    }
    .live-period {
        white-space: nowrap;
        color: var(--match-secondary-color);
        @media ${mediaQuery.isPhone} {
            white-space: ${(props) => (props.isCompact ? 'normal' : '')};
        }
    }
`;
