import React, { Fragment, useState } from 'react';
import Wrapper from './styles';
import UiReelsNumbers from './numbers/UiReelNumbers';

interface Props {
    text: string;
    duration?: number;
    delay?: number;
}
export default function UiReel({ text, duration = 700, delay = 85 }: Props) {
    enum DataTypes {
        STRING,
        INT,
        FRACTION,
    }

    const [reelState, setReelState] = useState<{ text: string; delayArray: number[] }>({
        text: '',
        delayArray: [],
    });
    function getNumbers(number) {
        return number
            .toString()
            .split('')
            .map((n) => parseInt(n, 10));
    }
    const stripNonNumbers = (str) => str && (str.match(/\d/g) || []).join('');

    if (reelState.text !== text) {
        const strippedPrev = stripNonNumbers(reelState.text);
        const strippedNext = stripNonNumbers(text);

        if (strippedPrev === strippedNext) {
            return null;
        }

        const prevNum = getNumbers(strippedPrev);
        const nextNum = getNumbers(strippedNext);
        const delayArray: number[] = [];

        for (let i = 0; i < nextNum.length; i++) {
            if (nextNum[i] !== prevNum[i]) {
                delayArray.push(i);
            }
        }

        setReelState({
            text: text,
            delayArray,
        });
    }

    function calcDelay(index) {
        const { delayArray } = reelState;

        if (!delayArray) {
            return 0;
        }

        const indexDelay = delayArray.indexOf(index);

        return (indexDelay > -1 ? indexDelay + 1 : 0) * delay;
    }

    function renderReels(parts) {
        const digits = [9, 8, 7, 6, 5, 4, 3, 2, 1, 0];

        return parts.map(({ type, value }, partIndex) => {
            switch (type) {
                case DataTypes.INT:
                case DataTypes.FRACTION:
                    return (
                        <Fragment key={`${type}_${partIndex}`}>
                            {getNumbers(value).map((number, index) => {
                                return (
                                    <UiReelsNumbers
                                        duration={duration}
                                        key={`${type}:${index}`}
                                        delay={calcDelay(index)}
                                        number={number}
                                        values={digits}
                                    />
                                );
                            })}
                        </Fragment>
                    );
                default:
                    return <UiReelsNumbers key={`${type}`} values={[value]} />;
            }
        });
    }

    function getParts(text) {
        const parts: { type: DataTypes; value: string }[] = [];

        let lastType;

        for (let i = 0; i < text.length; i++) {
            const isInt = !isNaN(parseInt(text[i], 10));
            const type = isInt ? DataTypes.INT : DataTypes.STRING;
            const isSame = (lastType === DataTypes.INT && isInt) || (lastType === DataTypes.STRING && !isInt);

            if (isSame) {
                parts[parts.length - 1].value += text[i];
            } else {
                parts.push({ type, value: text[i] });
            }

            lastType = type;
        }

        return parts;
    }

    const parts = getParts(text);

    return <Wrapper>{renderReels(parts)}</Wrapper>;
}
