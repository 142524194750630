import React, { ButtonHTMLAttributes, PropsWithChildren, ReactElement, useState } from 'react';
import Wrapper from './styles';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { authenticateWithFacebook } from '../../../../../services/auth';
import { logger } from '../../../../../services/logger';
import { translate } from '../../../../../services/translate';
import { LOGIN_METHOD } from '../../../../../services/auth';
import { useRouter } from '../../../../../services/router';
import { linkWithFacebook } from '../../../../../microservices/auth';
import { filterStyleProps } from '../../../../../styles/utils';
import { environment } from '../../../../../stores/environment/environment';
import { useStore } from '../../../../../hooks/useStore';

interface Props extends PropsWithChildren, ButtonHTMLAttributes<HTMLButtonElement> {
    isLinking?: boolean;
    onAuthenticationStart: () => void;
    onAuthenticationSuccess?: () => void;
    onErrorHandler?: (message: string) => void;
    buttonComponent: (props: any) => ReactElement<any, any>;
}

export default function AuthLoginButtonFacebook({
    children,
    buttonComponent: ButtonComponent,
    isLinking = false,
    onAuthenticationStart = () => {},
    onAuthenticationSuccess = () => {},
    onErrorHandler = () => {},
    ...rest
}: Props) {
    const { navigateTo } = useRouter();
    const [{ FACEBOOK_CLIENT_ID }] = useStore(environment);
    const [isLoading, setIsLoading] = useState(false);

    async function onSuccess({ accessToken }) {
        try {
            let response;
            if (isLinking) {
                await linkWithFacebook(accessToken);
            } else {
                response = await authenticateWithFacebook(accessToken);
            }
            setIsLoading(false);
            onAuthenticationSuccess();

            if (!isLinking && response && response.navigateTo && window.location.pathname !== response.navigateTo) {
                navigateTo(response.navigateTo);
            }
        } catch (error: any) {
            setIsLoading(false);
            onErrorHandler(translate(error.message, 'ui.account'));
        }
    }

    function onFailure({ status }) {
        logger.error('AuthLoginButtonFacebook', 'onFailure', status);

        setIsLoading(false);

        if (!status) {
            return;
        }

        const errorMessage = translate(`Failed to authenticate with {{ loginMethod }}`, 'auth.login', {
            loginMethod: LOGIN_METHOD.FACEBOOK,
        });

        const errorMessageWithErrorCode = `${errorMessage} "${status}"`;

        onErrorHandler(errorMessageWithErrorCode);
    }

    return (
        <Wrapper>
            <FacebookLogin
                render={({ onClick }) => (
                    <ButtonComponent
                        onClick={() => {
                            onAuthenticationStart();
                            setIsLoading(true);
                            if (onClick) {
                                onClick();
                            }
                        }}
                        isLoading={isLoading}
                        {...filterStyleProps(rest)}
                    >
                        {children || 'Facebook'}
                    </ButtonComponent>
                )}
                appId={FACEBOOK_CLIENT_ID}
                onSuccess={onSuccess}
                onFail={onFailure}
            />
        </Wrapper>
    );
}
