import React from 'react';
import Wrapper from './styles';
import Snippet from '../../snippet/Snippet';
import { PRODUCT } from '../../../types/common';

interface Props {
    product: PRODUCT;
}

export default function UiProductClosed({ product }: Props) {
    const snippedKeyByProduct = {
        [PRODUCT.CASINO]: 'casino.disabled.games.country-block',
        [PRODUCT.CASINO_RACE]: 'casino.disabled.casino-race.country-block',
        [PRODUCT.POKER]: 'casino.disabled.poker.country-block',
        [PRODUCT.VIRTUAL_SPORTS]: 'casino.disabled.virtual-sport.country-block',
        [PRODUCT.VS_HIGHLIGHT_GAMES]: 'casino.disabled.virtual-sport.country-block',
        [PRODUCT.SPORTSBOOK]: 'sportsbook.disabled.country-block',
    };

    return (
        <Wrapper className={product}>
            <div className="noize" />
            <div className="message-box">
                <div className="message-box-text">
                    <Snippet snippetKey={snippedKeyByProduct[product]} />
                </div>
            </div>
        </Wrapper>
    );
}
