import styled from 'styled-components';

type StyledProps = {
    bgColor: string;
    textColor: string;
};
export default styled.div<StyledProps>`
    display: flex;
    padding: 0.125rem var(--general-ui-spacing-radius-spacing-8, 0.5rem);
    align-items: flex-start;
    gap: 0.625rem;
    background-color: ${(props) => props.bgColor};
    border-radius: var(--general-ui-spacing-radius-ui-radius, 8px);
    width: fit-content;

    .combo-card-type {
        color: ${(props) => props.textColor};
        text-align: center;
        font-family: Roboto;
        font-size: 0.6rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.005rem;
        text-transform: uppercase;
    }
`;
