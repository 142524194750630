import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import keyBy from 'lodash/keyBy';
import { translate } from '../translate';
import { getRoute } from '../router';
import { GameLabels } from './game-label';
import { CasinoGame } from './games';
import isEmpty from 'lodash/isEmpty';
import { CasinoRace, getGamesFromRace } from './casino-race';
import { FEATURE, isFeatureAvailable } from '../feature';

export enum CasinoCategoryCode {
    FEATURED_GAMES = 'FEATURED_GAMES',
    NEW_GAMES = 'NEW_GAMES',
    RECENTLY_PLAYED = 'RECENTLY_PLAYED',
    PAYBACK_BOOSTER = 'PAYBACK_BOOSTER',
    TOP_20 = 'TOP_20',
    ALL = 'ALL',
    RACE = 'RACE',
    POPULAR_GAMES = 'POPULAR_GAMES',
    LIVE_CASINO = 'LIVE_CASINO',
    REAL_DEALER = 'REAL_DEALER',
}

export interface CasinoCategory {
    id: number;
    name: string;
    code: CasinoCategoryCode;
    order: number;
    type: CasinoCategoryTypes;
    games: number[];
    isCustom?: boolean;
}

export enum CasinoCategoryTypes {
    CASINO = 'casino',
    LIVE = 'live',
    MINI = 'mini',
}

enum CasinoCustomCategoryIds {
    RECENTLY_PLAYED = -1,
    PAYBACK_BOOSTER = -2,
    CASINO_RACE = -3,
}

export enum CollectionType {
    SLIDER = 'slider',
    THEME = 'theme',
}

export enum ThumbnailSize {
    SMALL = 60,
    MEDIUM = 95,
    LARGE = 190,
    MOBILE = 130,
    FEATURED = 460,
}

export function mapCasinoCategories(): void {
    let categories = getStoreValue(stores.casino.categories);
    insertCustomCategories(categories);
    const gamesById = getStoreValue(stores.casino.gamesById);

    categories.forEach((category) => {
        category.games = category.games.filter((gameId) => gamesById[gameId]);
    });

    categories = categories.filter((category) => category.games.length);
    stores.casino.categories.set(categories);
    stores.casino.categoriesById.set(keyBy(categories, 'id'));
    stores.casino.categoriesByCode.set(keyBy(categories, 'code'));
    stores.casino.categoriesBySlug.set(keyBy(categories, (category) => getCasinoCategorySlug(category.id)));
}

function insertCustomCategories(categories: CasinoCategory[]) {
    if (isFeatureAvailable(FEATURE.RECENTLY_PLAYED_GAMES)) {
        const recentlyPlayedCategory: CasinoCategory = getRecentlyPlayedCategory();
        const shouldInsertRecentlyPlayed = !categories.find(
            (category) => category.code === CasinoCategoryCode.RECENTLY_PLAYED,
        );

        if (shouldInsertRecentlyPlayed) {
            categories.push(recentlyPlayedCategory);
        }
    }

    const paybackBoosterCategory = getPaybackBoosterCategory();
    const shouldInsertPaybackBooster =
        paybackBoosterCategory && !categories.find((category) => category.code === CasinoCategoryCode.PAYBACK_BOOSTER);
    if (paybackBoosterCategory && shouldInsertPaybackBooster) {
        categories.push(paybackBoosterCategory);
    }

    const race = getStoreValue(stores.casinoRace.active.race);
    const raceCategory = getCasinoRaceCategory(race);
    const shouldInsertRaceCategory =
        raceCategory && !categories.find((category) => category.code === CasinoCategoryCode.RACE);
    if (shouldInsertRaceCategory) {
        categories.push(raceCategory);
    }
}

function getRecentlyPlayedCategory() {
    const recentlyPlayedGames = getStoreValue(stores.casino.recentlyPlayedGames);
    const recentlyPlayedCategory: CasinoCategory = {
        id: CasinoCustomCategoryIds.RECENTLY_PLAYED,
        name: 'Recently played games',
        code: CasinoCategoryCode.RECENTLY_PLAYED,
        order: 98,
        type: CasinoCategoryTypes.CASINO,
        games: recentlyPlayedGames.map((recentlyPlayedGame) => recentlyPlayedGame.id),
        isCustom: true,
    };
    return recentlyPlayedCategory;
}

function getCasinoRaceCategory(race: CasinoRace): CasinoCategory | null {
    if (!race) {
        return null;
    }
    const raceGames = getGamesFromRace(race);
    const raceCategory: CasinoCategory = {
        id: CasinoCustomCategoryIds.CASINO_RACE,
        name: 'Casino race games',
        code: CasinoCategoryCode.RACE,
        order: 99,
        type: CasinoCategoryTypes.CASINO,
        games: raceGames.map((raceGame) => raceGame.id),
        isCustom: true,
    };
    return raceCategory;
}

function getPaybackBoosterCategory(): CasinoCategory | null {
    const [program] = getStoreValue(stores.paybackBooster.programGames);
    const paybackBooster = getStoreValue(stores.paybackBooster.paybackBooster);
    if (!paybackBooster) {
        return null;
    }
    const filteredGames = getStoreValue<CasinoGame[]>(stores.casino.filteredGames);
    const extraPayback = program?.cashbackSettings?.extraPayback;

    if (!extraPayback || (program && Boolean(new Date(program?.periodTo) < new Date())) || isEmpty(paybackBooster)) {
        return null;
    }
    const serverGameIds = Object.keys(extraPayback);

    const promotedGames = program.cashbackSettings.promotedGames;
    if (promotedGames) {
        promotedGames.forEach((promotedGame) => {
            const index = serverGameIds.indexOf(promotedGame);
            if (index > -1) {
                serverGameIds.splice(index, 1);
            }
        });
        serverGameIds.unshift(...promotedGames);
    }
    const paybackBoosterGames = serverGameIds.reduce((prevGameIds: CasinoGame[], gameId) => {
        const game = keyBy(filteredGames, 'serverGameId')[gameId];
        if (game) {
            prevGameIds.push(game);
        }
        return prevGameIds;
    }, []);

    stores.paybackBooster.games.set(paybackBoosterGames);
    const paybackBoosterCategory: CasinoCategory = {
        id: CasinoCustomCategoryIds.PAYBACK_BOOSTER,
        name: 'Payback booster',
        code: CasinoCategoryCode.PAYBACK_BOOSTER,
        order: 100,
        type: CasinoCategoryTypes.CASINO,
        games: paybackBoosterGames.map((paybackBoosterGame) => paybackBoosterGame.id),
        isCustom: true,
    };
    return paybackBoosterCategory;
}

export function getCasinoCategoryRoute(category: CasinoCategory): string {
    return `${getRoute(
        { casino: 'casino.slots', live: 'casino.live', mini: 'casino.mini' }[category.type],
    )}/${translate(`casino.category.${category.id}.slug`)}`;
}

export function getCasinoCategorySubprovidersRoute(categoryType?): string {
    return `${getRoute({ casino: 'casino.slots', live: 'casino.live' }[categoryType || 'casino'])}/providers`;
}

export function getHiddenGameLabelsByCategory(category: CasinoCategory) {
    return (
        (category &&
            ((category.code === CasinoCategoryCode.NEW_GAMES && [GameLabels.NEW]) ||
                (category.code === CasinoCategoryCode.TOP_20 && [GameLabels.HOT]))) ||
        []
    );
}

export function getCasinoCategoryName(id): string {
    return translate(`${id === -2 ? 'payback booster' : 'name'}`, `casino.category.${id}`);
}
const getCasinoCategorySlug = (id): string => translate('slug', `casino.category.${id}`);

export function getCategoryTitle(categoryCode: string): string {
    const categoriesByCode = getStoreValue(stores.casino.categoriesByCode);
    const category = categoriesByCode[categoryCode];

    const isRecentlyPlayed = categoryCode === CasinoCategoryCode.RECENTLY_PLAYED;

    const context = category?.isCustom ? 'ui.casino' : `casino.category.${category?.id}`;
    let key = isRecentlyPlayed ? 'recently-played' : 'name';

    if (category?.isCustom && !isRecentlyPlayed) {
        key = category?.name;
    }

    return translate(key, context);
}
