import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    $isRetailLayout?: boolean;
    $isAmericanLayout?: boolean;
    $isOpen?: boolean;
};

export default styled.div<StyledProps>`
    position: relative;
    background: var(--match-background);
    border-width: var(--match-border-width);
    border-style: var(--match-border-style);
    border-color: var(--match-border-color);
    border-radius: var(--ui-radius);
    padding: ${({ $isRetailLayout }) =>
        $isRetailLayout
            ? 'var(--spacing-4) var(--spacing-12) var(--spacing-12) var(--spacing-12)'
            : 'var(--spacing-12) var(--spacing-4) var(--spacing-12) var(--spacing-16)'};
    margin-bottom: 0.25rem;
    transition: border-color 200ms linear;
    &:hover {
        border-color: var(--match-border-color-hover);
    }
    @media ${mediaQuery.isPhone} {
        padding: ${({ $isAmericanLayout }) =>
            $isAmericanLayout
                ? 'var(--spacing-8) var(--spacing-12)'
                : 'var(--spacing-4) var(--spacing-12) var(--spacing-8) var(--spacing-12)'};
    }
    ${({ $isOpen }) =>
        $isOpen &&
        css`
            z-index: 4;
            border-color: var(--match-open-border-color);
            &:hover {
                border-color: var(--match-open-border-color);
            }
        `};
    .match-layout-container {
        ${({ $isOpen }) =>
            $isOpen &&
            css`
                position: sticky;
                top: 0;
                z-index: 4;
                background: var(--match-open-background);
                @media ${mediaQuery.isPhone} {
                    position: initial;
                }
            `};
    }
    .match-markets {
        min-width: 0;
    }
    .special-icons {
        svg {
            color: var(--color-highlight);
            @media ${mediaQuery.isPhone} {
                display: none;
            }
        }
    }
    .go-back-btn {
        padding: var(--spacing-8) 0;
    }
`;
