import styled from 'styled-components';

export default styled.div`
    position: fixed;
    bottom: ${(props) => (props.fixedToBottom ? '0' : '70px')};
    left: 0;
    right: 0;
    z-index: 100;
    background: var(--surface-level-1-bg);
    padding: var(--spacing-12) var(--spacing-16);
    box-shadow: 0 -5px 4px rgb(9 22 51 / 17%);
    .mobile-betslip {
        display: flex;
        align-items: center;
        gap: var(--spacing-8);
        width: 100%;

        color: var(--font-color-primary);
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering};

        .bets-count {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 100px;
            border: 2px solid var(--odds-text-color);
            font-size: var(--font-14);
        }
        .betslip-odds {
            font-size: var(--font-14);
            font-weight: var(--font-weight-regular);
            margin-left: auto;
            .odds-value {
                font-weight: var(--font-weight-bold);
                ${({ theme }) => theme.optimizeFontRendering};
            }
        }
    }
`;
