import styled from 'styled-components';
import { Dialog } from '@mui/material';

export default styled(Dialog)`
    z-index: 1150 !important;

    & > div > div {
        background-color: unset;
        box-shadow: unset;
        overflow-y: unset;
        margin: 48px auto !important;
    }
`;
