import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { getJobs } from '../../../microservices/career';
import { translate } from '../../../services/translate';
import CmsCareerApply from '../apply/CareerApply';
import { logger } from '../../../services/logger';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../services/analytics';
import { useLocation } from 'react-router';
import { useRouter } from '../../../services/router';
import kebabCase from 'lodash/kebabCase';
import { Job } from '../../../services/career';
import UiButton from '../../ui/button/UiButton';
import Svg from '../../svg/Svg';

export default function CareerPositions() {
    const [jobGroups, setJobs] = useState<Record<string, Job[]>>();
    const [selectedJob, setSelectedJob] = useState<Job>();
    const [isOpen, setIsOpen] = useState(false);
    const { history } = useRouter();
    const jobFromUrl = new URLSearchParams(useLocation().search).get('position');

    useEffect(() => {
        initPositions();
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.MARKETING_CAREER_PRINT);
    }, []);

    useEffect(() => {
        initSelectedJob();
    }, [jobGroups]);

    useEffect(() => {
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.MARKETING_CAREER_JOB_OPEN, {
            jobTitle: selectedJob?.attributes.title,
        });
    }, [selectedJob]);

    async function initPositions() {
        try {
            const jobGroups = await getJobs();
            setJobs(jobGroups);
        } catch (e) {
            logger.error('CareerPositions', 'initPositions', e);
        }
    }

    function initSelectedJob() {
        if (!jobGroups) {
            return;
        }

        if (jobFromUrl) {
            for (const group of Object.values(jobGroups)) {
                const target = group.find((job) => kebabCase(job.attributes.title) === jobFromUrl);
                if (target) {
                    setSelectedJob(target);
                    break;
                }
            }
            (document as any).getElementById('positions').scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            // select first job in the first non empty group.
            setSelectedJob((Object.values(jobGroups).find((group) => group.length) || [])[0]);
        }
    }

    function selectJob(job: Job) {
        setSelectedJob(job);
        history.push(`?position=${kebabCase(job.attributes.title)}`);
    }

    function openApplyModal() {
        setIsOpen(true);
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.MARKETING_CAREER_JOB_APPLY, {
            jobTitle: selectedJob?.attributes.title,
        });
    }

    if (!jobGroups || !selectedJob) {
        return null;
    }

    return (
        <>
            <Wrapper>
                <div className="column job-list">
                    <h3>
                        We are <span className="green">hiring</span>
                    </h3>
                    {Object.entries(jobGroups).map(([groupName, jobs]) => (
                        <>
                            {jobs.length ? <h3>{groupName}</h3> : null}
                            {jobs.map((job) => (
                                <h5
                                    key={job.id}
                                    className={selectedJob.id === job.id ? 'active' : ''}
                                    onClick={() => selectJob(job)}
                                >
                                    <div className="tab-l job-title">{job.attributes.title}</div>
                                </h5>
                            ))}
                        </>
                    ))}
                </div>
                <div className="column">
                    <div className="job-offer-card background-grey-5 border-mrk-cb-top">
                        <div className="car-t-c active" id="s1">
                            <div className="medium-txt">{selectedJob.attributes.title}</div>
                            <div dangerouslySetInnerHTML={{ __html: selectedJob.attributes.body }} />
                        </div>
                        <UiButton
                            onClick={openApplyModal}
                            icon={<Svg icon="user-icon" size={0.5} />}
                            iconPosition="left"
                        >
                            {translate('Apply', 'ui.about.career')}
                        </UiButton>
                    </div>
                </div>
            </Wrapper>

            <CmsCareerApply
                positionId={selectedJob.id}
                positionTitle={selectedJob.attributes.title}
                applicationURL={selectedJob.links['careersite-job-apply-iframe-url']}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
            />
        </>
    );
}
