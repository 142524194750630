import styled from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

type StyledProps = {
    $isRetailLayout?: boolean;
    $isAmericanLayout?: boolean;
    $isMarketTitleVisible?: boolean;
};

export default styled.div<StyledProps>`
    display: flex;
    flex: 1;
    gap: var(--spacing-24);
    animation: 'fadeIn' 200ms ease-in;
    @media ${mediaQuery.isLaptop} {
        gap: var(--spacing-16);
    }
    @media ${mediaQuery.isPhone} {
        gap: ${({ $isAmericanLayout }) => ($isAmericanLayout ? 'var(--spacing-4)' : 'var(--spacing-8)')};
    }
    .match-market {
        display: flex;
        width: 33.3333%;
        align-items: center;
        min-height: 58px;
        min-width: 0;
        margin-top: ${({ $isRetailLayout, $isMarketTitleVisible }) =>
            $isRetailLayout && $isMarketTitleVisible ? '1rem' : ''};
        @media ${mediaQuery.isPhone} {
            min-height: 71px;
            flex-direction: ${({ $isAmericanLayout }) => ($isAmericanLayout ? 'column' : '')};
        }
        @media ${mediaQuery.isLaptop} {
            width: 50%;
        }
        @media ${mediaQuery.isTablet} {
            width: 50%;
            margin: 0.4rem 0;
        }
        @media ${mediaQuery.isPhone} {
            align-items: flex-end;
            width: 100%;
        }
    }
    .show-more-odds {
        @media ${mediaQuery.isPhone} {
            margin-bottom: 0.4rem;
        }
    }
`;
