import React, { useState } from 'react';
import Wrapper from './styles';
import { PaybackBooster, PaybackBoosterStatus } from '../../../../../services/casino/payback-booster';
import { stores } from '../../../../../stores';
import CasinoPaybackBoosterThumbnailPromo from '../promo/CasinoPaybackBoosterThumbnailPromo';
import CasinoPaybackBoosterThumbnailPaybackBoosterAvailable from './available/CasinoPaybackBoosterThumbnailPaybackBoosterAvailable';
import CasinoPaybackBoosterThumbnailPaybackBoosterActive from './active/CasinoPaybackBoosterThumbnailPaybackBoosterActive';
import CasinoPaybackBoosterGamesBooster from '../../games/booster/CasinoPaybackBoosterGamesBooster';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    paybackBooster: PaybackBooster;
    forceFullView?: boolean;
    isCompact: boolean;
}

export default function CasinoPaybackBoosterThumbnailPaybackBooster({
    paybackBooster,
    forceFullView,
    isCompact,
}: Props) {
    const [isPaybackBoosterCompactView, setIsPaybackBoosterCompactView] = useStore(
        stores.casino.isPaybackBoosterCompactView,
    );
    const [isExpired, setIsExpired] = useState(
        paybackBooster.status === PaybackBoosterStatus.active && new Date(paybackBooster.expiryDate) <= new Date(),
    );
    const isStatusActive = paybackBooster.status === PaybackBoosterStatus.active;

    return (
        <Wrapper
            isCompact={isCompact}
            isStatusActive={isStatusActive}
            onClick={() => (isPaybackBoosterCompactView ? setIsPaybackBoosterCompactView(false) : false)}
        >
            <div className="promo">
                <CasinoPaybackBoosterThumbnailPromo
                    forceFullView={forceFullView}
                    paybackBooster={paybackBooster}
                    isCompact={isCompact}
                />
                {isCompact && (
                    <div className="controls">
                        {paybackBooster.status === PaybackBoosterStatus.available && (
                            <CasinoPaybackBoosterThumbnailPaybackBoosterAvailable
                                paybackBooster={paybackBooster}
                                onExpired={() => setIsExpired(true)}
                            />
                        )}
                        {paybackBooster.status === PaybackBoosterStatus.active && (
                            <CasinoPaybackBoosterThumbnailPaybackBoosterActive
                                paybackBooster={paybackBooster}
                                onExpired={() => setIsExpired(true)}
                                isCompact={isCompact}
                            />
                        )}
                    </div>
                )}
            </div>
            {!isCompact && (
                <div className="info">
                    {paybackBooster.status === PaybackBoosterStatus.active && (
                        <CasinoPaybackBoosterThumbnailPaybackBoosterActive
                            paybackBooster={paybackBooster}
                            onExpired={() => setIsExpired(true)}
                            isCompact={isCompact}
                        />
                    )}
                    <div className="booster-games">
                        <CasinoPaybackBoosterGamesBooster
                            paybackBoosterSettings={paybackBooster.paybackBoosterSettings}
                            isExpired={isExpired}
                            isShowStepNeeded={false}
                        />
                    </div>
                </div>
            )}
        </Wrapper>
    );
}
