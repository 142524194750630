import styled, { css } from 'styled-components';

export default styled.div`
    color: ${(props) => props.color};
    display: flex;
    flex-direction: ${(props) => (props.horizontal ? 'row' : 'column')};
    align-items: center;
    ${[1, 2, 3, 4, 5].map(
        (i) => css`
            > div:nth-child(${i}) {
                animation-delay: ${i * 200}ms;
            }
        `,
    )}
    div {
        animation: zoomAndFade 3000ms alternate infinite;
        &:not(:last-of-type) {
            margin: ${(props) => (props.horizontal ? '0 1rem 0 0' : '0 0 1rem 0')};
        }
    }
    svg {
        width: ${(props) => props.size}px;
        transform: rotate(${(props) => (props.rotate ? props.rotate : '0')}deg);
    }
    @keyframes zoomAndFade {
        0% {
            transform: scale(1.05);
            opacity: 0.1;
        }
        50% {
            transform: scale(1.2);
            opacity: 1;
        }
        100% {
            transform: scale(1.05);
            opacity: 0.1;
        }
    }
`;
