import React, { ReactNode, useState } from 'react';
import UiModal from '../ui/modal/UiModal';
import { GlobalModalContextProvider } from '../../contexts/global-modal/GlobalModalContext';
import { media } from '../../stores/media/media';
import { useStore } from '../../hooks/useStore';

interface Props {
    children?: ReactNode;
}

export default function GlobalModal({ children }: Props) {
    const [store, setStore] = useState<any>();
    const [{ isPhone }] = useStore(media);
    const { Component, params, onOpen, onClose, open } = store || {};

    function showModal(component, params, onOpen?, onClose?) {
        setStore({
            ...store,
            Component: component,
            params,
            onOpen,
            onClose,
            open: true,
        });
    }

    function hideModal() {
        setStore({
            ...store,
            open: false,
        });
        setTimeout(() => {
            setStore({
                ...store,
                Component: null,
                params: null,
                onOpen: null,
                onClose: null,
            });
        }, 500);
    }

    function renderComponent() {
        if (!Component) {
            return null;
        }

        return <Component {...params} />;
    }

    return (
        <GlobalModalContextProvider value={{ store, showModal, hideModal }}>
            <UiModal open={Boolean(open)} onClose={onClose} onOpen={onOpen} mode={isPhone ? 'drawer' : 'default'}>
                {renderComponent()}
            </UiModal>
            {children}
        </GlobalModalContextProvider>
    );
}
