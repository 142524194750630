// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from 'react';

export default function SystemKioskScanner() {
    useEffect(() => {
        window.addEventListener('click', clearActiveElementFocus);

        return () => {
            window.removeEventListener('click', clearActiveElementFocus);
        };
    }, [clearActiveElementFocus]);

    function clearActiveElementFocus() {
        if (document.activeElement instanceof HTMLElement && document.activeElement.tagName === 'BUTTON') {
            document.activeElement.blur();
        }
    }

    return null;
}
