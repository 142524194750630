import React, { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { GeoComplyMobileClient } from '../../../../services/geocomply/mobile';
import { translate } from '../../../../services/translate';
import UiAlert from '../../../ui/alert/UiAlert';
import UiDotsLoader from '../../../ui/dots-loader/UiDotsLoader';

interface Props {
    onAppOpenSuccess: () => void;
    onAppOpenFailure: () => void;
    totalPingAttempts?: number;
    retryDelayMs?: number;
}

interface GeocomplyMobileAppPingerRef {
    startPinger: () => void;
}

function GeocomplyMobileAppPinger(
    { onAppOpenSuccess, onAppOpenFailure, totalPingAttempts = 100, retryDelayMs = 2000 }: Props,
    ref: Ref<GeocomplyMobileAppPingerRef>,
) {
    const [currentAttemptIndex, setCurrentAttemptIndex] = useState(1);
    const [isRunning, setIsRunning] = useState(false);
    const [isAppOpened, setIsAppOpened] = useState(false);
    const [isAppOpenFailed, setIsAppOpenFailed] = useState(false);
    const stopAppPingerRef = useRef<{ stopAppPinger: () => void } | null>(null);

    const [isVisible, setIsVisible] = useState(false);

    useImperativeHandle(ref, () => ({
        startPinger: () => {
            reset();
            const stopAppPinger = startAppPinger();
            stopAppPingerRef.current = { stopAppPinger };
            setIsVisible(true);
        },
    }));

    useEffect(() => {
        return () => {
            stopAppPingerRef.current?.stopAppPinger();
        };
    }, []);

    useEffect(() => {
        let timeoutRef;

        if (!isRunning && isAppOpened) {
            timeoutRef = setTimeout(() => {
                reset();
            }, 5000);
        }

        return () => {
            if (timeoutRef) {
                clearTimeout(timeoutRef);
            }
        };
    }, [isRunning, isAppOpened]);

    function reset() {
        stopAppPingerRef.current?.stopAppPinger();
        setCurrentAttemptIndex(1);
        setIsAppOpened(false);
        setIsAppOpenFailed(false);
        setIsVisible(false);
    }

    function startAppPinger() {
        let shouldStopWhileLoop = false;

        (async () => {
            setIsRunning(true);
            let attemptsLeft = totalPingAttempts;

            while (attemptsLeft > 0) {
                setCurrentAttemptIndex(totalPingAttempts - attemptsLeft + 1);

                try {
                    const GeoComplyMobileClient = (window as any).CoolbetGeoComplyMobileClient as GeoComplyMobileClient;

                    await new Promise<void>((resolve, reject) => {
                        GeoComplyMobileClient.ping(
                            () => {
                                resolve();
                            },
                            () => {
                                reject();
                            },
                        );
                    });

                    if (shouldStopWhileLoop) {
                        return;
                    }

                    setIsAppOpened(true);
                    onAppOpenSuccess();
                    return;
                } catch (error) {
                    await new Promise((resolve) => setTimeout(resolve, retryDelayMs));
                    attemptsLeft--;
                }

                if (shouldStopWhileLoop) {
                    return;
                }
            }

            setIsAppOpenFailed(true);
            onAppOpenFailure();
            setIsRunning(false);
        })();

        return () => {
            shouldStopWhileLoop = true;
        };
    }

    if (!isVisible) {
        return null;
    }

    return (
        <>
            {isAppOpened && (
                <UiAlert success center>
                    {translate('App is now opened', 'ui.geocomply')}
                </UiAlert>
            )}

            {isAppOpenFailed && (
                <UiAlert failure center>
                    {translate('App opening failed. Please retry.', 'ui.geocomply')}
                </UiAlert>
            )}

            {!isAppOpened && (
                <UiAlert warning center>
                    <UiDotsLoader /> {translate('Checking if app is opened yet', 'ui.geocomply')} (
                    {translate('Attempt {{ currentAttemptIndex }} out of {{ totalPingAttempts }}', 'ui.geocomply', {
                        currentAttemptIndex,
                        totalPingAttempts,
                    })}
                    )
                </UiAlert>
            )}
        </>
    );
}

export default forwardRef(GeocomplyMobileAppPinger);
