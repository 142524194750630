import React, { ReactElement, useMemo, useEffect, useState } from 'react';
import Wrapper from './styles';
import Navigator from '../../components/navigator/Navigator';
import SportLobbyCategories from '../../components/sport/lobby-categories/SportLobbyCategories';
import MainLayout from '../main/MainLayout';
import { stores } from '../../stores';
import { getRoute } from '../../services/router';
import VirtualSportsSidebar from '../../components/virtual-sports/sidebar/VirtualSportsSidebar';
import VirtualSportsBetslip from '../../components/virtual-sports/betslip/VirtualSportsBetslip';
import VirtualSportsPhoneBottomNavigation from '../../components/virtual-sports/phone/bottom-navigation/VirtualSportsPhoneBottomNavigation';
import { loadBlockedProductsByPlayerCountry } from '../../microservices/users';
import UiProductClosed from '../../components/ui/product-closed/UiProductClosed';
import UiPointsLoader from '../../components/ui/points-loader/UiPointsLoader';
import { virtualSports } from '../../stores/virtual-sports/virtual-sports';
import { PRODUCT } from '../../types/common';
import { media } from '../../stores/media/media';
import { useStore } from '../../hooks/useStore';

interface Props {
    children: ReactElement | ReactElement[];
}

export default function VirtualSportLayout({ children }: Props) {
    const [{ isDesktop, isLaptop, isTablet, isPhone }] = useStore(media);
    const [blockedProducts] = useStore(stores.blockedProducts);
    const leftSidebarComponents = useMemo(() => getLeftSidebarComponents(), []);
    const rightSidebarComponents = useMemo(() => getRightSidebarComponents(), []);
    const [isBetslipEmpty] = useStore(virtualSports.isBetslipEmpty);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        initBlockedProducts();
    }, []);

    async function initBlockedProducts() {
        await loadBlockedProductsByPlayerCountry();
        setIsLoading(false);
    }

    const [limits] = useStore(stores.sports.limits);
    const dependenciesLoaded = limits !== null;

    if (isLoading && dependenciesLoaded) {
        return (
            <MainLayout>
                <Wrapper>
                    <UiPointsLoader />
                </Wrapper>
            </MainLayout>
        );
    }

    if (blockedProducts[PRODUCT.VIRTUAL_SPORTS]) {
        return (
            <MainLayout>
                <Wrapper>
                    <UiProductClosed product={PRODUCT.VIRTUAL_SPORTS} />
                </Wrapper>
            </MainLayout>
        );
    }
    function getLeftSidebarComponents() {
        return [
            {
                route: getRoute('virtual-sports'),
                component: VirtualSportsSidebar,
            },
        ];
    }

    function getRightSidebarComponents() {
        return [{ route: getRoute('virtual-sports'), component: VirtualSportsBetslip }];
    }

    return (
        <MainLayout>
            <Wrapper isBetslipEmpty={isBetslipEmpty}>
                <SportLobbyCategories />
                <div className="sport-content">
                    {(isDesktop || isLaptop) && (
                        <div className="sport-menu">
                            <Navigator routes={leftSidebarComponents} noRedirect />
                        </div>
                    )}
                    <div className="sport-matches">{children}</div>
                    {(isDesktop || isLaptop) && (
                        <div className="sport-sidebar">
                            <Navigator routes={rightSidebarComponents} noRedirect />
                        </div>
                    )}
                    {(isPhone || isTablet) && <VirtualSportsPhoneBottomNavigation />}
                </div>
            </Wrapper>
        </MainLayout>
    );
}
