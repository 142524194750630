import React, { useEffect, useMemo, useState } from 'react';
import Wrapper from './styles';
import CasinoHappyHourWidget from '../../casino/happy-hour/widget/CasinoHappyHourWidget';
import UiHeading from '../../ui/heading/UiHeading';
import { translate } from '../../../services/translate';
import Promotion from '../../promotion/Promotion';
import UiButton from '../../ui/button/UiButton';
import Svg from '../../svg/Svg';
import { getRoute, isActiveRoute } from '../../../services/router';
import { FEATURE, isFeatureAvailable } from '../../../services/feature';
import CasinoThumbnail from '../../casino/thumbnail/CasinoThumbnail';
import { stores } from '../../../stores';
import { CasinoGame } from '../../../services/casino/games';
import { getFilteredPromotions } from '../../../services/promotion';
import { CasinoCategoryCode } from '../../../services/casino/categories';
import { initCasino } from '../../../services/casino/init';
import { useSportsMaintenanceSocketUpdates } from '../../../services/sports/maintenance';
import { optInToHappyHourCrmCheck } from '../../../microservices/happy-hour';
import compact from 'lodash/compact';
import UiNavLink from '../../ui/nav-link/UiNavLink';
import { isTestUser } from '../../../services/user';
import ContactUsButton from '../../contact-us-button/ContactUsButton';
import { PRODUCT } from '../../../types/common';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

interface Props {
    onClose?: () => void;
}

export default function SportProductLauncher({ onClose }: Props) {
    const [gamesByCategoryId] = useStore(stores.casino.gamesByCategoryId);
    const [categoriesByCode] = useStore(stores.casino.categoriesByCode);
    const [promotions] = useStore(stores.promotions);
    const [games, setGames] = useState<CasinoGame[]>([]);
    const filteredPromotions = getFilteredPromotions(PRODUCT.ALL, promotions);
    const [{ isPhoneMedium, isPhoneMin }] = useStore(media);

    useEffect(() => {
        initCasino();
    }, []);

    useEffect(() => {
        const newGames = gamesByCategoryId[categoriesByCode[CasinoCategoryCode.NEW_GAMES]?.id];
        if (newGames?.length) {
            const firstNewGames = newGames.slice(0, 9);
            setGames(firstNewGames);
        }
    }, [gamesByCategoryId, categoriesByCode, isPhoneMedium, isPhoneMin]);

    const [optIn] = useStore(stores.happyHour.optIn);
    const [isOptedIn, setIsOptedIn] = useState(true);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [{ preMatch, liveBet }] = useStore(stores.maintenance.sportsbookMaintenanceState);
    const [{ isPhone }] = useStore(media);

    useSportsMaintenanceSocketUpdates();

    useEffect(() => {
        if (isAuthenticated) {
            optInToHappyHourCrmCheck();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        setIsOptedIn(Boolean(optIn));
    }, [optIn]);

    const sportLinks = useMemo(
        () =>
            compact([
                {
                    label: translate('sports.navigation.sports-lobby.short'),
                    route: getRoute('sport.recommendations'),
                    icon: 'sports-ico',
                },
                {
                    label: translate('sports.navigation.livebet-lobby.short'),
                    route: getRoute('sport.live'),
                    icon: 'live-stream',
                },
                isFeatureAvailable(FEATURE.PARLAY_CARD) && {
                    route: getRoute('sport.parlay-cards'),
                    icon: 'parley-card',
                    label: translate('Parlay Cards', 'ui.sportsbook'),
                },
                isFeatureAvailable(FEATURE.CONTESTS) && {
                    route: getRoute('sport.contests'),
                    icon: 'casino-race',
                    label: translate('Contests', 'ui.sportsbook'),
                },
                isFeatureAvailable(FEATURE.INCOMING_BETS) && {
                    label: translate('sports.navigation.incoming-bets-button.short'),
                    route: getRoute('sport.incoming-bets'),
                    icon: 'incoming-bets',
                },
                isFeatureAvailable(FEATURE.VIRTUAL_SPORTS) && {
                    label: translate('sports.navigation.virtual-sport-button.short'),
                    route: getRoute('virtual-sports'),
                    icon: 'virtual-sports',
                },
                isFeatureAvailable(FEATURE.LEAGUE) && {
                    label: translate('sports.navigation.league-button.short'),
                    route: getRoute('league'),
                    icon: 'coolbet-league',
                },
                isFeatureAvailable(FEATURE.WINNERS_FEED) && {
                    label: translate('sports.navigation.winners-feed-button.short'),
                    route: getRoute('sport.winners-feed'),
                    icon: 'cash',
                },
            ]),
        [isAuthenticated],
    );

    const casinoLinks = useMemo(
        () =>
            compact([
                {
                    label: translate('Casino', 'casino.ui'),
                    route: getRoute('casino.slots'),
                    icon: 'casino-lobby',
                    isPublic: true,
                    isVisible: true,
                },
                {
                    label: translate('Live Casino', 'casino.ui'),
                    route: getRoute('casino.live'),
                    icon: 'casino-live',
                    isPublic: true,
                    isVisible: true,
                },
                {
                    label: translate('Casino Race', 'casino.ui'),
                    route: getRoute('casino.race.schedule'),
                    icon: 'casino-race',
                    isPublic: true,
                    isVisible: isAuthenticated,
                },
                {
                    label: translate('casino.nav.my-casino'),
                    route: getRoute('casino.my-casino'),
                    icon: 'sport-leaderboard-ico',
                    isPublic: false,
                    isVisible: isAuthenticated && isFeatureAvailable(FEATURE.MY_CASINO),
                },
            ]),
        [isAuthenticated],
    );
    return (
        <Wrapper>
            {isFeatureAvailable(FEATURE.CASINO_HAPPY_HOUR) && isAuthenticated && isOptedIn && <CasinoHappyHourWidget />}

            <div className="products-section">
                <div className="section-title">
                    <UiHeading title={translate('Promotions', 'ui.header')}>
                        <UiNavLink onClick={onClose} to={`${getRoute('promotions')}`}>
                            {translate('Show all', 'ui.common')}
                        </UiNavLink>
                    </UiHeading>
                </div>
                <div className="promotions-box">
                    {promotions.length > 0 &&
                        filteredPromotions
                            .slice(0, 5)
                            .map((filteredPromotion) => (
                                <Promotion
                                    isMobileLandingPage
                                    key={filteredPromotion.id}
                                    promotion={filteredPromotion}
                                />
                            ))}
                </div>
            </div>

            <div className="products-section">
                {!liveBet && !preMatch && (
                    <>
                        <div className="section-title">
                            <UiHeading title={translate('Sports', 'ui.header')} />
                        </div>

                        <div className="quick-links">
                            {sportLinks.map(({ route, icon, label }) => {
                                return (
                                    <UiButton
                                        url={route}
                                        key={route}
                                        type="card"
                                        isOnSurface
                                        icon={<Svg icon={icon} size={1.4} />}
                                        size="small"
                                        selected={isActiveRoute(route, false)}
                                        onClick={onClose}
                                    >
                                        {label}
                                    </UiButton>
                                );
                            })}
                        </div>
                    </>
                )}
            </div>

            {isFeatureAvailable(FEATURE.CASINO) && (
                <div className="products-section">
                    <div className="section-title">
                        <UiHeading title={translate('Casino', 'ui.header')} />
                    </div>

                    <div className="quick-links">
                        {casinoLinks
                            .filter(({ isPublic, isVisible }) => isVisible && (isTestUser() || isPublic))
                            .map(({ route, icon, label }) => {
                                return (
                                    <UiButton
                                        url={route}
                                        key={route}
                                        type="card"
                                        isOnSurface
                                        icon={<Svg icon={icon} size={1.4} />}
                                        size="small"
                                        selected={isActiveRoute(route, false)}
                                        onClick={onClose}
                                    >
                                        {label}
                                    </UiButton>
                                );
                            })}
                    </div>

                    <div className="games-list">
                        {games &&
                            games.map((game, index) => (
                                <CasinoThumbnail
                                    key={index}
                                    size={80}
                                    isFooterVisible={false}
                                    isHeaderVisible={false}
                                    game={game}
                                    isCategoryView
                                    position={index}
                                    thumbnailLocation="MOBILE_LANDING"
                                />
                            ))}
                    </div>
                </div>
            )}

            <div className="products-section">
                <div className="section-title">
                    <UiHeading title={translate('Help', 'ui.common')} />
                </div>
                <div className="support-section">
                    <ContactUsButton isPhone={isPhone} />
                    <span>{translate('or', 'ui.common')}</span>
                    <a href="mailto:support@coolbet.com" className="link">
                        {translate('use email', 'ui.common')}
                    </a>
                </div>
            </div>
        </Wrapper>
    );
}
