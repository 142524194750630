import { io } from 'socket.io-client';
import { fromEvent, Observable } from 'rxjs';
import {
    AcceptorInfo,
    CardReaderInfo,
    DiagnosticStatus,
    PrinterInfo,
    ScannerInfo,
    SensorsInfo,
} from '../microservices/retail-diagnostic';
import { retailMiddlewareUrl, RetailUserAuth } from './retail-middleware';
import { WalletBalance } from '../microservices/wallet';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { logger } from './logger';
import { getBalance, getTicketsToPrint } from '../microservices/retail-middleware';
import { resetSession } from './retail-session';
import { TerminalStatus } from '@staycool/retail-types/terminal';
import { isLocalDevelopment } from './environment';
import { Settings } from '@staycool/retail-types/settings';
import { BetShopWithSettings } from '@staycool/retail-types/bet-shop-settings';
import { retail } from '../stores/retail/retail';
import {
    EventType,
    PrintEvent,
    RetailEvent,
    Terminal,
    Type,
    Notification,
    LoyaltyResponseKiosk,
} from '../stores/retail/types';
import { Currency } from './wallet/types';
import { FEATURE, isFeatureAvailable } from './feature';
import { tryCreateManualLoyalty } from './retail';

let socket: any;

export let onPrinterInfo: Observable<PrinterInfo>;
export let onAcceptorInfo: Observable<AcceptorInfo>;
export let onScannerData: Observable<string>;
export let onScannerInfo: Observable<ScannerInfo>;
export let onCardReaderInfo: Observable<CardReaderInfo>;
export let onCardReaderData: Observable<string[]>;
export let onDoorState: Observable<SensorsInfo>;

export function initRetailMiddlewareSocket() {
    socket = io(retailMiddlewareUrl, {
        path: '/socket.io/',
        reconnectionDelayMax: 10000,
        transports: ['websocket'],
    });
    const onConnect = fromEvent(socket, 'connect');
    const onDisconnect = fromEvent(socket, 'disconnect');
    const onConnectError = fromEvent(socket, 'connect_error');

    const onUserAuth = fromEvent<RetailUserAuth>(socket, 'browser-user-auth');
    const onUserAuthEnd = fromEvent(socket, 'browser-user-auth-end');
    const onUserBalance = fromEvent<number>(socket, 'browser-user-balance');
    const onTerminalSettings = fromEvent<Partial<Settings['settings']>>(socket, 'browser-terminal-settings');
    const onKioskPrint = fromEvent<Partial<{ status: boolean; voucherAmount?: number }>>(
        socket,
        'browser-kiosk-printing',
    );
    const onTerminalWithNotificationsUpdate = fromEvent<Terminal & { loyaltyId: string }>(
        socket,
        'terminal-with-notifications-update',
    );
    const onTerminalEvent = fromEvent<RetailEvent>(socket, 'terminal-event');
    const onPrinterEvent = fromEvent<PrintEvent>(socket, 'printer-event');
    const onBetShopSettingsChange = fromEvent<BetShopWithSettings>(socket, 'browser-bet-shop-settings-change');
    onPrinterInfo = fromEvent<PrinterInfo>(socket, 'printer-info');
    onAcceptorInfo = fromEvent<AcceptorInfo>(socket, 'acceptor-info');
    onScannerData = fromEvent<string>(socket, 'scanner-data');
    onScannerInfo = fromEvent<ScannerInfo>(socket, 'scanner-info');
    onCardReaderInfo = fromEvent<CardReaderInfo>(socket, 'card-reader-info');
    onCardReaderData = fromEvent<string[]>(socket, 'card-reader-data');
    onDoorState = fromEvent<SensorsInfo>(socket, 'door-state');

    onConnect.subscribe(async () => {
        const [tickets, balance] = await Promise.all([getTicketsToPrint(), getBalance()]);
        if (tickets.length) {
            stores.sports.ticketsToPrint.set(tickets);
        }
        if (balance > 0) {
            const wallet = getStoreValue(stores.wallet) as WalletBalance;
            stores.wallet.set({ ...wallet, currency: Currency.USD, balance_uc: balance });
            stores.wallets.set([{ ...wallet, currency: Currency.USD, balance_uc: balance }]);
        }
    });

    onPrinterEvent.subscribe((event) => {
        const printerEvent = getStoreValue(retail.retailPrinterEvent);
        if (JSON.stringify(printerEvent) !== JSON.stringify(event)) {
            retail.retailPrinterEvent.set(event);
        }
    });

    onConnectError.subscribe(() => handleDisconnect());

    onDisconnect.subscribe(() => handleDisconnect());

    onUserAuth.subscribe((data) => {
        const { tickets, balance } = data;
        if (tickets.length) {
            stores.sports.ticketsToPrint.set(tickets);
        }
        if (balance > 0) {
            const wallet: WalletBalance = {
                ...getStoreValue(stores.wallet),
                currency: Currency.USD,
                balance_uc: balance,
            } as WalletBalance;
            stores.wallet.set(wallet);
            stores.wallets.set([wallet]);
        }
        logger.dev('RetailMiddlewareSocketService', 'onUserAuth');
    });

    onUserAuthEnd.subscribe(() => {
        resetSession();
        logger.dev('RetailMiddlewareSocketService', 'onUserAuthEnd');
    });

    onUserBalance.subscribe((balance) => {
        const wallet = getStoreValue(stores.wallet) as WalletBalance;
        const walletBalance = wallet?.balance_uc;
        const deposit = walletBalance > 0 ? balance - walletBalance : balance;
        if (deposit > 0 && deposit !== walletBalance) {
            retail.retailModals.retailModalEvent.set({ type: EventType.DepositAdded, data: deposit });
        }
        stores.wallet.set({ ...wallet, currency: Currency.USD, balance_uc: balance });
        stores.wallets.set([{ ...wallet, currency: Currency.USD, balance_uc: balance }]);
    });

    onTerminalSettings.subscribe((data) => {
        const { sessionExpiration, kioskIdleTimeInSeconds, authorizationTimeInSeconds } = data;
        const settings = getStoreValue(retail.retailSettings);
        retail.retailSettings.set({
            retailExpirationTime: sessionExpiration ? sessionExpiration * 1000 : settings.retailExpirationTime,
            retailKioskIdleTime: kioskIdleTimeInSeconds ? kioskIdleTimeInSeconds * 1000 : settings.retailKioskIdleTime,
            retailAuthorizationIdleTime: authorizationTimeInSeconds
                ? authorizationTimeInSeconds * 1000
                : settings.retailAuthorizationIdleTime,
        });
    });

    onKioskPrint.subscribe(({ status, voucherAmount }) => {
        retail.retailModals.retailPrintingModal.set({
            isPrinting: status || false,
            data: { voucherAmount },
        });
    });

    onTerminalWithNotificationsUpdate.subscribe((data) => {
        const { loyaltyId, ...terminalData } = data;
        if (loyaltyId) {
            retail.loyalty.set((loyalty) => {
                return { ...loyalty, loyalty_id: loyaltyId };
            });
        }
        retail.retailTerminal.set(terminalData);
    });

    onTerminalEvent.subscribe(async (event) => {
        const { type, data, error } = event;

        if (type === EventType.DeviceQrScanned) {
            retail.retailModals.retailModalBringYourDevice.set(event);
        } else if (type === EventType.LoyaltyScanned && !error) {
            await trySetLoyalty(data);
        } else {
            retail.retailModals.retailModalEvent.set(event);
        }
    });

    onBetShopSettingsChange.subscribe((updatedBetShopWithSettings) => {
        if (updatedBetShopWithSettings.id === getStoreValue(retail.shopId)) {
            stores.isRacingAvailable.set(updatedBetShopWithSettings.settings.isRacingAvailable || false);
        }
    });
}

async function trySetLoyalty(loyalty: unknown) {
    if (isFeatureAvailable(FEATURE.MANUAL_LOYALTY_INPUT) && typeof loyalty === 'string' && loyalty.length < 10) {
        await tryCreateManualLoyalty(loyalty);
    } else if (loyalty && typeof loyalty === 'object') {
        retail.loyalty.set(loyalty as LoyaltyResponseKiosk);
    }
}

function handleDisconnect() {
    const retailTerminal = getStoreValue(retail.retailTerminal);
    if (!isLocalDevelopment()) {
        retail.retailTerminal.set({
            ...retailTerminal,
            status: TerminalStatus.HasError,
            notifications: [
                {
                    id: 1,
                    name: 'Middleware socket failure',
                    source: 'Middleware socket',
                    description: 'Middleware not running',
                    type: Type.Error,
                    terminal_id: 0,
                },
            ] as Notification[],
        });
    }
    resetSession();
}

export function emitDiagnosticStatus(status: DiagnosticStatus) {
    socket.emit('diagnostic-update', status);
}
