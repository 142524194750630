import styled, { css } from 'styled-components';
import { mediaQuery } from '../../styles/utils';

type StyledProps = {
    $isRetailLayout?: boolean;
};

export default styled.div<StyledProps>`
    .sport-content {
        display: grid;
        grid-template-columns: 280px minmax(0, 1fr) 300px;
        grid-column-gap: var(--spacing-8);
        width: 100%;
        margin-top: var(--spacing-8);

        @media (max-width: 1600px) {
            grid-template-columns: 230px minmax(0, 1fr) 280px;
        }
        @media (max-width: 1300px) {
            grid-template-columns: 200px minmax(0, 1fr) 280px;
        }
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            display: block;
        }
        ${({ $isRetailLayout }) =>
            $isRetailLayout &&
            css`
                grid-template-columns: minmax(0, 1fr) 400px !important;
                padding: 0 var(--spacing-12);
                margin-top: 0;
            `};

        .sport-matches {
            position: relative;
            ${({ theme }) => theme.contentBoxStyle};
            padding: var(--spacing-12);
            width: 100%;
            min-height: 100vh;
            @media ${mediaQuery.isPhone} {
                padding: var(--spacing-12) var(--spacing-8);
            }
        }

        .sport-sidebar {
            position: sticky;
            top: ${({ $isRetailLayout }) => ($isRetailLayout ? '316px' : '10px')};
            overflow-y: scroll;
            height: ${({ $isRetailLayout }) => ($isRetailLayout ? '87vh' : '100vh')};
            .happy-hour-info {
                display: none;
            }
        }
    }

    .hot-event-card {
        padding: var(--spacing-12);
    }
`;
