import React, { useEffect, useState } from 'react';
import { getRoute, isActiveRoute } from '../../../services/router';
import UiGroup from '../../ui/group/UiGroup';
import { translate } from '../../../services/translate';
import { stores } from '../../../stores';
import Wrapper from './styles';
import UiButton from '../../ui/button/UiButton';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

export default function BlogMenu() {
    const [{ isPhone }] = useStore(media);
    const [categories] = useStore(stores.blog.categories);
    const [activeArticle] = useStore(stores.blog.activeArticle);
    const [activeCategoryArticles] = useStore(stores.blog.activeCategoryArticles);
    const [activeCategory, setActiveCategory] = useState();

    const categoryLinks = categories.map(({ category }) => ({
        label: translate(category, 'ui.blog.categories'),
        route: `${getRoute('blog.category')}/${category}`,
        category,
    }));

    const links = [
        {
            label: translate('All', 'ui.blog.categories'),
            route: getRoute('blog'),
            exact: true,
        },
        ...categoryLinks,
    ];

    useEffect(() => {
        const isBloghomePage = isActiveRoute(getRoute('blog'));
        const isBlogCategoryPage = isActiveRoute(`${getRoute('blog.category')}/:blogCategory`);
        const isBlogArticlePage = isActiveRoute(`${getRoute('blog')}/:slug`);

        if (isBloghomePage) {
            setActiveCategory(translate('All', 'ui.blog.categories') as any);
        }

        if (isBlogArticlePage || isBlogCategoryPage) {
            const category = isBlogArticlePage
                ? activeArticle && (activeArticle as any).category
                : activeCategoryArticles.length > 0 && activeCategoryArticles[0].category;

            if (category) {
                setActiveCategory(translate(category, 'ui.blog.categories') as any);
            }
        }
    }, [activeArticle, activeCategoryArticles]);

    return (
        <Wrapper>
            <UiGroup vertical={!isPhone}>
                {links.map((link) => (
                    <UiButton
                        block
                        key={link.label}
                        url={link.route}
                        selected={activeCategory && activeCategory === link.label}
                    >
                        {link.label}
                    </UiButton>
                ))}
            </UiGroup>
        </Wrapper>
    );
}
