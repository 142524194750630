import React, { useEffect, useState } from 'react';
import { translate } from '../../../services/translate';
import UiFormInput from '../form/input/UiFormInput';

interface Props {
    onChange: (pageSize: number) => void;
}

export default function UiPageSize({ onChange }: Props) {
    const pageSizes = [10, 25, 50, 100];
    const [pageSize, setPageSize] = useState(pageSizes[0]);

    useEffect(() => {
        changePageSize(pageSizes[0]);
    }, []);

    function changePageSize(pageSize) {
        setPageSize(pageSize);
        onChange(pageSize);
    }

    return (
        <UiFormInput select value={pageSize} onValueChange={changePageSize} name="pageSize">
            {pageSizes.map((size) => (
                <option key={size} value={size}>
                    {translate('show %1 per page', 'ui.account', [size])}
                </option>
            ))}
        </UiFormInput>
    );
}
