import React, { PropsWithChildren } from 'react';
import Wrapper from './styles';

interface Props extends PropsWithChildren {
    boxHorizontalSide?: number;
    boxVerticalSide?: number;
    deactivate?: boolean;
}

export default function UiRatioBox({ boxHorizontalSide = 1, boxVerticalSide = 1.35, deactivate, children }: Props) {
    const paddingTop = (boxVerticalSide / boxHorizontalSide) * 100;

    return deactivate ? (
        <>{children}</>
    ) : (
        <Wrapper $paddingTop={paddingTop}>
            <div className="ratio-box-content">{children}</div>
        </Wrapper>
    );
}
