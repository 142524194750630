import React from 'react';
import Wrapper from './styles';
import { stores } from '../../stores';
import {
    getMexicanMarketPromotionsLanguage,
    usePromotionArticlePath,
    usePromotionImagePath,
} from '../../services/promotion';
import PromotionParticipantsBadge from './participants-badge/PromotionParticipantsBadge';
import PromotionTimeRemainingBadge from './time-remaining-badge/PromotionTimeRemainingBadge';
import UiNavLink from '../ui/nav-link/UiNavLink';
import UiTextTruncate from '../ui/text-truncate/UiTextTruncate';
import { Campaign } from '../../microservices/bonuses';
import { useStore } from '../../hooks/useStore';

interface Props {
    promotion: Campaign;
    $isMobileLandingPage?: boolean;
    showDescription?: boolean;
    showTimeRemainingBadge?: boolean;
    blockClickAction?: boolean;
    openInNewWindow?: boolean;
    onLinkClick?: (event: React.SyntheticEvent) => void;
}

export default function Promotion(props: Props) {
    const {
        $isMobileLandingPage,
        promotion,
        showDescription,
        showTimeRemainingBadge,
        onLinkClick,
        blockClickAction,
        openInNewWindow,
    } = props;
    const [language] = useStore(stores.language);
    const translationLanguage = getMexicanMarketPromotionsLanguage(language);
    const path = usePromotionArticlePath(promotion.article?.slug);

    return (
        <Wrapper $isMobileLandingPage={$isMobileLandingPage}>
            {showTimeRemainingBadge && <PromotionTimeRemainingBadge endTime={promotion.end_time} />}
            <UiNavLink
                onClick={(event) => {
                    if (blockClickAction) {
                        event.preventDefault();
                    } else if (onLinkClick) {
                        onLinkClick(event);
                    }
                }}
                $openNewTab={openInNewWindow}
                to={path}
            >
                <div className="promo-card">
                    <img
                        src={usePromotionImagePath(promotion.images, translationLanguage)}
                        alt={promotion.name_external}
                    />
                    {showDescription && (
                        <div className="promo-description">
                            <UiTextTruncate text={promotion.description[translationLanguage]} />
                            <PromotionParticipantsBadge participants={promotion.participants} />
                        </div>
                    )}
                </div>
                {promotion.terms_and_conditions && promotion.terms_and_conditions[translationLanguage] && (
                    <div
                        className="promo-terms"
                        dangerouslySetInnerHTML={{
                            __html: promotion.terms_and_conditions[translationLanguage],
                        }}
                    />
                )}
            </UiNavLink>
        </Wrapper>
    );
}
