import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { logger } from '../services/logger';
import { storageGet } from '../services/storage';
import { isProd } from '../services/environment';
import { RaceResults } from '@staycool/lvdc-types/race-results';
import { BetHistoryFilter, GetBetsHistoryResponse } from '@staycool/lvdc-types/bet-history';
import { BetPlacementResponse, BetType, TicketReceipt } from '@staycool/lvdc-types/bets';
import { TodaysTrack, TrackRace, TrackRaceListItem } from '@staycool/lvdc-types/track';
import { RaceCarryover } from '@staycool/lvdc-types/race';
import { getCurrentDateString } from '../services/lvdc/lvdc';
import { retail } from '../stores/retail/retail';
import { getStoreValue } from '../stores/store/utils';
import { environment } from '../stores/environment/environment';

const getUrl = (url) => getServiceUrl('lvdc-web', url);

export interface RaceEntry {
    saddle_cloth: string;
    post_position: number;
    betting_interest: number;
    name: string;
    is_entry?: any;
    is_scratched: boolean;
    morning_line: string;
    trainer_name: string;
    medication?: any;
    equipment?: any;
    apprentice_code?: number;
    claim_amount: number;
    status_update_time?: any;
    jockey_name: string;
    short_jockey_name: string;
    jockey_weight: number;
    jockey_overweight_amount?: any;
    odds: string;
    probable_pay: number;
    odds_type: string;
    is_replacement_jockey: boolean;
}

export interface RaceBetType {
    id: number;
    code: BetType;
    description: string;
    num_runners: number;
    races: number[];
    is_betting_enabled: boolean;
    is_multiple_of_fiftycent: boolean;
    is_multiple_of_min: boolean;
    is_pennies_allowed: boolean;
    is_min_amount_only: boolean;
    is_min_on_bet_cost: boolean;
    special_max_amount: number;
    special_max_combos: number;
    special_min_amount: number;
    special_min_combos: number;
    standard_max_amount: number;
    standard_min_amount: number;
    sub_types: BetType[];
    parent_bet_type_id?: number;
}

export interface RaceState {
    isPastRace: boolean;
    isUpcomingRace: boolean;
    isCurrentRace: boolean;
    isFutureRace: boolean;
}

export interface TrackRaceDetails {
    trackRace: TrackRace;
    entries: RaceEntry[];
    betTypes: RaceBetType[];
    state: RaceState | null;
    raceList: TrackRaceListItem[];
}

export type BetPoolType =
    | 'P08'
    | 'P10'
    | 'P07'
    | 'PLC'
    | 'WIN'
    | 'ROU'
    | 'OMN'
    | 'P05'
    | 'DD'
    | 'QU'
    | 'SPR'
    | 'P03'
    | 'EX'
    | 'P09'
    | 'E05'
    | 'TRI'
    | 'P04'
    | 'P06'
    | 'SHW'
    | 'GSL';

interface GetTrackRaceReplayFilter {
    raceId: number;
    date: string;
    nevadaTrackId: number;
}

interface ReplayInfo {
    filename: string;
    flash: number;
    mobile: number;
    http: number;
}

interface GetTrackRaceReplayResponse {
    race: number;
    hdpan?: ReplayInfo;
    sdpan?: ReplayInfo;
    hdho?: ReplayInfo;
    sdho?: ReplayInfo;
}

export interface RacePoolTotal {
    poolType: string;
    amount: number;
}

export interface LivestreamDetails {
    code: string | null;
    is_stream_available: boolean;
}

export async function loadTodaysTracks() {
    try {
        const url = getUrl(`track/${getCurrentDateString()}/all/`);
        return await httpGet<TodaysTrack[]>(url);
    } catch (error) {
        logger.error('LvdcWebMicroservice', 'loadTodaysTracks', error);
        return [];
    }
}

export async function loadTrackRaceDetails(nevadaTrackId: number, raceId: number) {
    try {
        const url = getUrl(`track/details/${getCurrentDateString()}/${nevadaTrackId}/${raceId}`);
        const trackDetails = await httpGet<TrackRaceDetails>(url);
        if (!isProd() && storageGet('development.horse-racing-dev-mode')) {
            return {
                ...trackDetails,
                betTypes: trackDetails.betTypes.map((betType) => ({ ...betType, is_betting_enabled: true })),
                state: {
                    ...trackDetails.state,
                    isPastRace: false,
                },
            } as TrackRaceDetails;
        }
        return trackDetails;
    } catch (error) {
        logger.error('LvdcWebMicroservice', 'loadTrackRaceDetails', error);
        return {} as TrackRaceDetails;
        // TODO: meaningful error that load failed
    }
}

export async function getBetHistory(filter: BetHistoryFilter) {
    try {
        const url = getUrl(`bets-history`);
        return await httpPost<GetBetsHistoryResponse>(url, filter);
    } catch (error) {
        logger.error('LvdcWebMicroservice', 'getBetHistory', error);
        return null;
    }
}

export async function getFollowingRaces(nevadaTrackId: number, raceIds: number[]) {
    try {
        const url = getUrl('track/following-races');
        return await httpPost<TrackRaceDetails[]>(url, { date: getCurrentDateString(), nevadaTrackId, raceIds });
    } catch (error) {
        logger.error('LvdcWebMicroservice', 'getFollowingRaces', error);
        return [];
    }
}

export async function placeHorseRacingBet(betPlacingData) {
    const url = getUrl(`bets`);
    const shopId = getStoreValue(retail.shopId);

    return httpPost<BetPlacementResponse>(url, {
        ...betPlacingData,
        ...(shopId && { extra_info: { shopId } }),
    });
}

export async function getTicketReceipt(ticketId: number) {
    const url = getUrl(`ticket/receipt/${ticketId}`);
    return httpGet<TicketReceipt>(url);
}

export async function loadCarryovers() {
    try {
        const url = getUrl(`race/${getCurrentDateString()}/carryovers/`);
        return await httpGet<RaceCarryover[]>(url);
    } catch (error) {
        logger.error('LvdcWebMicroservice', 'loadCarryovers', error);
        return [];
    }
}

export async function getResults(nevadaTrackId: number, raceId: number, date?: string) {
    try {
        const url = getUrl(`race-results/${date ?? getCurrentDateString()}/${nevadaTrackId}/${raceId || 1}/`);
        return await httpGet<RaceResults>(url);
    } catch (error) {
        logger.error('LvdcWebMicroservice', 'getResults', error);
    }
}

export async function getTrackRaceReplayUrl(filter: GetTrackRaceReplayFilter) {
    try {
        const { RCN_REFERER } = getStoreValue(environment);
        const url = getUrl(`replay/fetch-url`);
        return await httpPost<GetTrackRaceReplayResponse>(url, { ...filter, RCN_REFERER });
    } catch (error) {
        logger.error('LvdcWebMicroservice', 'getTrackRaceReplayUrl', error);
        return null;
    }
}

export async function getRacePoolTotals(nevadaTrackId: number, raceId: number) {
    try {
        const url = getUrl(`/race-betting-pool/total/${getCurrentDateString()}/${nevadaTrackId}/${raceId}`);
        return await httpGet<RacePoolTotal[]>(url);
    } catch (error) {
        logger.error('LvdcWebMicroservice', 'getRacePoolTotals', error);
        return [];
    }
}

export async function getLivestreamDetails(nevadaTrackId: number, raceId: number) {
    try {
        const url = getUrl(`track/livestream-info/${getCurrentDateString()}/${nevadaTrackId}/${raceId}`);
        const { livestream } = await httpGet<{ livestream: LivestreamDetails }>(url);
        return livestream;
    } catch (error) {
        logger.error('LvdcWebMicroservice', 'getLivestreamDetails', error);
        return { code: null, is_stream_available: false };
    }
}
