import styled, { css } from 'styled-components';

type StyledProps = {
    size?: number;
    isDigitalBoard?: boolean;
};

export default styled.div<StyledProps>`
    ${({ size, isDigitalBoard }) =>
        css`
            img {
                display: block;
                height: ${size}${isDigitalBoard ? 'rem' : 'px'};
            }
        `}
`;
