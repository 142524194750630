import { useEffect } from 'react';
import { getBonusOffers } from '../../../microservices/bonuses';
import { linkInvitation } from '../../../microservices/refer-a-friend';
import { getDeviceType } from '../../../services/device';
import { FEATURE, isFeatureAvailable } from '../../../services/feature';
import { storageGet, storageRemove } from '../../../services/storage';
import { getUserFavorites } from '../../../services/user-favorites';
import { stores } from '../../../stores';
import { getStoreValue } from '../../../stores/store/utils';
import { useStore } from '../../../hooks/useStore';

export default function SystemUser() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const rafCode = storageGet('raf_code');

    useEffect(() => {
        stores.deviceType.set(getDeviceType());
    }, []);

    useEffect(() => {
        const user = getStoreValue(stores.user);

        if (isAuthenticated && user) {
            if (!user.email || !user.alias || !user.phoneNumber) {
                stores.modals.isEssentialUserInformationInquiryModalOpen.set(true);
            } else {
                stores.modals.isTermsAndConditionsAcceptanceModalOpen.set(!user.acceptTermsAndConditions);
            }

            if (rafCode) {
                linkInvitation(user.id, rafCode).then(() => storageRemove('raf_code'));
            }
        }

        isFeatureAvailable(FEATURE.USER_FAVORITES) && getUserFavorites();
        isFeatureAvailable(FEATURE.CAMPAIGNS) && getBonusOffers({ reload: true });
    }, [isAuthenticated]);

    return null;
}
