import React, { useEffect, useState } from 'react';
import { CasinoGame, getGameImageUrl } from '../../../../../services/casino/games';
import { translate } from '../../../../../services/translate';
import UiFlag from '../../../../ui/flag/UiFlag';
import CasinoThumbnail from '../../../../casino/thumbnail/CasinoThumbnail';
import Wrapper from './styles';
import { getLicence, getLicenceName, LICENCE_FLAG_COUNTRY } from '../../../../../services/licence';

interface Props {
    className?: string;
    isDisplayingFlag?: boolean;
    game: CasinoGame;
    showRtpInfo?: boolean;
    gameRtp?: number;
    nameLabel: string;
    gameInfo: {
        spins: number;
        minutes: number;
    };
}

export default function RtpCalculatorGameSelectionComparisonInfo({
    game,
    className,
    isDisplayingFlag = true,
    gameRtp,
    nameLabel,
    showRtpInfo = false,
    gameInfo,
}: Props) {
    const [gameImageUrl, setGameImageUrl] = useState<string>('');
    const licence = getLicence();

    useEffect(() => {
        if (game) {
            setGameImageUrl(getGameImageUrl(game.imageName));
        }
    }, [game]);

    return (
        <Wrapper>
            <div className={className}>
                <div className="rtp-calculator-game-selection-comparison-label">
                    <div className="rtp-calculator-game-selection-comparison-label-text">
                        <div>{nameLabel}</div>
                        <div>
                            {!LICENCE_FLAG_COUNTRY[licence] &&
                                isDisplayingFlag &&
                                ` (${translate(`${getLicenceName()}`, 'casino.rtp-calculator')})`}
                        </div>
                    </div>
                    <div className="rtp-calculator-game-selection-comparison-label-flag">
                        {isDisplayingFlag && LICENCE_FLAG_COUNTRY[licence] && (
                            <UiFlag flag={LICENCE_FLAG_COUNTRY[licence]} />
                        )}
                    </div>
                </div>
                <div className="rtp-calculator-game-selection-comparison-label-percent">{gameRtp}%</div>
                <div className="rtp-calculator-game-selection-comparison-image-wrapper">
                    {showRtpInfo ? (
                        <div className="rtp-calculator-game-selection-comparison-image">
                            <CasinoThumbnail game={game} isRTPCalculator />
                        </div>
                    ) : (
                        <div
                            className="rtp-calculator-game-selection-comparison-image"
                            style={{ backgroundImage: `url(${gameImageUrl})` }}
                        />
                    )}
                </div>
                <div className="rtp-calculator-game-selection-comparison-label-info">
                    <div className="rtp-calculator-game-selection-comparison-label-spins">
                        {gameInfo.spins} {translate('spin', 'casino.rtp-calculator')}
                    </div>
                    <div className="rtp-calculator-game-selection-comparison-label-minutes">
                        {gameInfo.minutes} {translate('min', 'casino.rtp-calculator')}
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
