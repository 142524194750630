import React, { MutableRefObject, useRef } from 'react';
import { stores } from '../../../../../stores';
import UiFormInput from '../../../../ui/form/input/UiFormInput';
import Wrapper from './styles';
import { translate } from '../../../../../services/translate';
import { FEATURE, isFeatureAvailable } from '../../../../../services/feature';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    handleStakeChange: (stake?: string) => void;
}

export default function HorseRacingRaceStakeNormal({ handleStakeChange }: Props) {
    const inputRef = useRef();
    const elementRef = useRef<HTMLDivElement>();

    const [betslip] = useStore(stores.horseRacing.betslip);
    const { betStake, betType } = betslip;
    const [stakeOptions] = useStore(stores.horseRacing.predefinedBetStakes);
    const [minMaxStakes] = useStore(stores.horseRacing.minMaxStakes);

    const betStakeInputId = `betStakeInputId-normal-${betType?.code}`;

    return (
        <Wrapper>
            <div ref={elementRef as MutableRefObject<HTMLDivElement>}>
                <UiFormInput
                    id={betStakeInputId}
                    ref={inputRef as any}
                    label={translate('Wager', 'ui.racebook')}
                    value={betStake || ''}
                    placeholder={`Min. $${minMaxStakes.minStake}` || ''}
                    type="number"
                    onBlur={() => stores.sports.isBottomNavigationVisible.set(true)}
                    onValueChange={(value) => handleStakeChange(value)}
                    name="betStake"
                    className="race-stake-input"
                    select
                    displayDollarSign={isFeatureAvailable(FEATURE.DISPLAY_DOLLAR_SIGN)}
                >
                    {stakeOptions.map((stake) => (
                        <option key={stake} value={stake}>
                            {stake}
                        </option>
                    ))}
                </UiFormInput>
            </div>
        </Wrapper>
    );
}
