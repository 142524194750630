import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import UiGroup from '../../../components/ui/group/UiGroup';
import Transactions from '../../../components/transactions/Transactions';
import Loader from '../../../components/loader/Loader';
import { translate } from '../../../services/translate';
import UiAlert from '../../../components/ui/alert/UiAlert';
import moment from 'moment';
import { toast } from 'react-toastify';
import UiPageSize from '../../../components/ui/page-size/UiPageSize';
import { logger } from '../../../services/logger';
import { TRANSACTION_TYPE, getTransactionsByProduct } from '../../../services/wallet/wallet';
import UiButton from '../../../components/ui/button/UiButton';
import { Transaction, TransactionProduct } from '../../../services/wallet/types';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

export default function SportTransactionsPage() {
    const orderBy = '-created';
    const [{ isPhone }] = useStore(media);

    const productFilters = [
        {
            label: translate('Sportsbook', 'ui.common'),
            value: TransactionProduct.SPORTSBOOK,
        },
    ];

    const dateFilters = [
        {
            label: translate('All', 'ui.common'),
        },
        {
            label: translate('Today', 'ui.common'),
            value: {
                from: nowUtcStartOfDay(),
                to: nowUtc(),
            },
        },
        {
            label: translate('This Week', 'ui.common'),
            value: {
                from: nowUtcStartOfDay().subtract(7, 'days'),
                to: nowUtc(),
            },
        },
        {
            label: translate('This Month', 'ui.common'),
            value: {
                from: nowUtcStartOfDay().subtract(1, 'months'),
                to: nowUtc(),
            },
        },
    ];

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
    const [activeProduct, setActiveProduct] = useState<TransactionProduct>(TransactionProduct.SPORTSBOOK);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [activeDateFilter, setActiveDateFilter] = useState(dateFilters[0]);
    const [{ transactions, hasNextPage }, setTransactionsResponse] = useState(getInitialTransactionsResponse());
    const [isTransactionsGrouped, setIsTransactionsGrouped] = useState(true);

    const isTransactionGroupingAvailable = activeProduct === undefined || activeProduct === TransactionProduct.CASINO;

    useEffect(() => {
        loadTransactions();
    }, [activeProduct, pageSize, orderBy, activeDateFilter, isTransactionsGrouped]);

    function nowUtc() {
        return moment().utc();
    }

    function nowUtcStartOfDay() {
        return nowUtc().startOf('day');
    }

    function getDefaultQueryParams() {
        return {
            order_by: orderBy,
            page_size: pageSize,
            product: activeProduct,
            created__ge: activeDateFilter.value && activeDateFilter.value.from.format(),
            created__lt: activeDateFilter.value && activeDateFilter.value.to.format(),
            type__ni: activeProduct === TransactionProduct.WITHDRAWAL ? TRANSACTION_TYPE.CANCELLATION : '',
            isGrouped: isTransactionGroupingAvailable ? isTransactionsGrouped : undefined,
        };
    }

    async function loadTransactions() {
        setIsLoading(true);

        try {
            setTransactionsResponse(getInitialTransactionsResponse());
            setPage(1);
            const result = await getTransactionsByProduct({
                ...getDefaultQueryParams(),
                page: 1,
            });
            setTransactionsResponse(result);
        } catch (error) {
            logger.error('SportTransactionsPage', 'loadTransactions', error);

            toast.error(
                `${translate('Could not load transactions.', 'ui.account')} ${translate(
                    'Please try again later.',
                    'ui.common',
                )}`,
            );
        }

        setIsLoading(false);
    }

    async function loadNextPage() {
        const nextPage = page + 1;

        setPage(nextPage);
        setIsLoadingNextPage(true);

        try {
            const walletTransactions = await getTransactionsByProduct({
                ...getDefaultQueryParams(),
                page: nextPage,
            });

            setTransactionsResponse({
                transactions: [...transactions, ...walletTransactions.transactions],
                hasNextPage: walletTransactions.hasNextPage,
            });
        } catch (error) {
            logger.error('SportTransactionsPage', 'loadNextPage', error);

            toast.error(
                `${translate('Could not load transactions.', 'ui.account')} ${translate(
                    'Please try again later.',
                    'ui.common',
                )}`,
            );
        }

        setIsLoadingNextPage(false);
    }

    function getInitialTransactionsResponse(): {
        transactions: Transaction[];
        hasNextPage: boolean;
    } {
        return {
            transactions: [],
            hasNextPage: false,
        };
    }
    return (
        <Wrapper>
            <h2>{translate('Transactions', 'ui.account')}</h2>

            <div className="transactions-header">
                <UiGroup shouldOverflow>
                    {productFilters.map(({ label, value }) => (
                        <UiButton
                            selected={value === activeProduct}
                            onClick={() => setActiveProduct(value)}
                            key={label}
                        >
                            {label}
                        </UiButton>
                    ))}
                </UiGroup>
            </div>

            <div className="minor-filters-container">
                <UiGroup className="time-filter">
                    {dateFilters.map((dateFilter) => (
                        <UiButton
                            size="small"
                            onClick={() => setActiveDateFilter(dateFilter)}
                            selected={dateFilter.label === activeDateFilter.label}
                            key={dateFilter.label}
                        >
                            {dateFilter.label}
                        </UiButton>
                    ))}
                </UiGroup>

                {isTransactionGroupingAvailable && (
                    <UiButton
                        size="small"
                        onClick={() => setIsTransactionsGrouped(!isTransactionsGrouped)}
                        selected={isTransactionsGrouped}
                        className="group-button"
                    >
                        {translate('Group', 'ui.transactions')}
                    </UiButton>
                )}
            </div>

            {!isPhone && <UiPageSize onChange={setPageSize} />}

            <Transactions
                activeProduct={activeProduct}
                transactions={transactions}
                onReloadTransactions={loadTransactions}
                hasNextPage={hasNextPage}
                onNextPageRequested={loadNextPage}
                isLoadingNextPage={isLoadingNextPage}
            />

            {!isLoading && !transactions.length && <UiAlert warning>{translate('ui.transactions.notfound')}</UiAlert>}

            {isLoading && <Loader />}
        </Wrapper>
    );
}
