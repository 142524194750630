import styled, { css } from 'styled-components';
import { lighten, desaturate, darken } from 'polished';
import { mediaQuery } from '../../../../../styles/utils';

const isUserAnswerCorrect = ({ isUserAnswerCorrect }: StyledProps) =>
    isUserAnswerCorrect &&
    css`
        border-color: ${({ theme }) => theme.colors.success};
        color: ${({ theme }) => theme.colors.success};
    `;

const isUserAnswerNotCorrect = ({ isUserAnswerNotCorrect }: StyledProps) =>
    isUserAnswerNotCorrect &&
    css`
        border-color: ${({ theme }) => theme.colors.red};
        color: ${({ theme }) => theme.colors.red};
    `;

type StyledProps = {
    selected?: boolean;
    isUserAnswerCorrect?: boolean;
    isUserAnswerNotCorrect?: boolean;
};
export default styled.div<StyledProps>`
    background-color: ${(props) =>
        props.selected
            ? `${desaturate(0.8, darken(0.35, props.theme.colors.secondary))}!important`
            : lighten(0.15, props.theme.box.background)};
    border: 2px solid
        ${(props) =>
            props.selected
                ? `${({ theme }) => theme.colors.secondary}!important`
                : lighten(0.2, props.theme.box.background)};
    padding: ${({ theme }) => theme.layout.gutter} 1.6rem;
    margin: 0 0 ${({ theme }) => theme.layout.gutterHalf} 0;
    border-radius: var(--ui-radius);
    font-size: 1rem;
    color: ${(props) => (props.selected ? props.theme.colors.secondary : props.theme.typography.fontColorSecondary)};
    cursor: pointer;
    transition: all 200ms linear;
    display: flex;
    align-items: center;
    box-shadow: 0 6px 5px -5px #000;

    @media ${mediaQuery.isPhone} {
        min-height: 55px;
        font-size: 1.1rem;
    }
    ${isUserAnswerCorrect}
    ${isUserAnswerNotCorrect}
    &:hover {
        background-color: ${({ theme }) => lighten(0.15, theme.box.background)};
        border-color: ${({ theme }) => lighten(0.2, theme.box.background)};
    }
    ${[1, 2, 3, 4, 5].map(
        (i) => css`
            &:nth-child(${i}) .answer-text {
                animation-delay: ${i * 150}ms;
            }
        `,
    )}

    .answer-text {
        flex: 1;
    }
    .answer-icon {
        width: 18px;
        height: 18px;
        margin-left: ${({ theme }) => theme.layout.gutter};
    }
    .correct {
        color: ${({ theme }) => theme.colors.secondary};
    }
    .incorrect {
        color: ${({ theme }) => theme.colors.error};
    }
`;
