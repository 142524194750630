import React, { useEffect, useState } from 'react';
import SportMatchList from '../../sport/match/list/SportMatchList';
import { getMatchesByFilter } from '../../../microservices/sbgate';
import UiDotsLoader from '../../ui/dots-loader/UiDotsLoader';
import { logger } from '../../../services/logger';
import { useSportsUserSettings } from '../../../services/sports/user-settings';
import Wrapper from './styles';
import { stores } from '../../../stores';
import isEmpty from 'lodash/isEmpty';
import { FoCategoryMatch } from '@staycool/sbgate-types';
import { useStore } from '../../../hooks/useStore';

interface Props {
    matchids?: string;
    categoryids?: string;
    isopen?: boolean;
    markettypeids?: string;
}

export default function CmsSportMatchList({ matchids, categoryids, markettypeids, isopen = false }: Props) {
    const [matches, setMatches] = useState<FoCategoryMatch[]>([]);
    const { layout } = useSportsUserSettings();

    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const isBetslipEmpty = isEmpty(betSlipMarketIdToOutcomeId);

    useEffect(() => {
        if (matchids && !markettypeids) {
            const matchIds = JSON.parse(matchids);
            loadMatches(matchIds);
        }

        if (matchids && markettypeids) {
            const matchIds = JSON.parse(matchids);
            const marketTypeIds = JSON.parse(markettypeids);
            loadMatches(matchIds, marketTypeIds);
        }

        if (categoryids) {
            const categoryIds = JSON.parse(categoryids);
            loadMatchesByCategory(categoryIds);
        }
    }, [layout]);

    async function loadMatches(matchIds, marketTypeIds = undefined) {
        try {
            setMatches(await getMatchesByFilter({ matchIds, marketTypeIds }));
        } catch (error) {
            logger.error('CmsSportMatchList', 'loadMatches', error);
        }
    }

    async function loadMatchesByCategory(categoryIds) {
        try {
            setMatches(await getMatchesByFilter({ categoryIds }));
        } catch (error) {
            logger.error('CmsSportMatchList', 'loadMatchesByCategory', error);
        }
    }
    return (
        <Wrapper isBetslipOpen={!isBetslipEmpty}>
            {!matches && <UiDotsLoader />}
            {matches && <SportMatchList matches={matches} isOpen={isopen} />}
        </Wrapper>
    );
}
