import { isMobile } from '../browser';
import { getStoreValue } from '../../stores/store/utils';
import { retail } from '../../stores/retail/retail';

interface Params {
    targetEl: Element;
    anchorEl?: Element;
    callback: (value: string) => void;
    onEnter?: () => void;
    layout: string;
    offsetCenterX?: number;
    offsetCenterY?: number;
    ignoreLossFocus?: boolean;
    beforeShow?: () => void;
    showWithDelay?: number;
    value?: string;
    shouldTakeAnchorWidth?: boolean;
}

let beforeShowAction: Params['beforeShow'];

function beforeShow(isVisible: boolean, params: Params) {
    if (isVisible && beforeShowAction) {
        beforeShowAction();
    }
    beforeShowAction = params.beforeShow;
}

function show(params: Params) {
    const {
        targetEl,
        anchorEl = targetEl,
        callback,
        onEnter,
        layout,
        ignoreLossFocus = true,
        value,
        shouldTakeAnchorWidth = false,
    } = params;
    let { offsetCenterX = 0, offsetCenterY = 0 } = params;
    const anchorElCoord = anchorEl.getBoundingClientRect();
    const targetElCoord = targetEl.getBoundingClientRect();
    const keyboardWidth = shouldTakeAnchorWidth
        ? anchorElCoord.width
        : Math.max(layout === 'betslip' ? 398 : 950, anchorElCoord.width);

    const targetCenterX = targetElCoord.x + keyboardWidth / 2;
    offsetCenterX += anchorElCoord.x + anchorElCoord.width / 2 - targetCenterX;
    offsetCenterY += anchorElCoord.height - targetElCoord.height - (targetElCoord.y - anchorElCoord.y);
    retail.retailKeyboard.value.unsubscribeAll();
    retail.retailKeyboard.value.subscribe(callback);
    retail.retailKeyboard.position.set({
        x: targetElCoord.x + offsetCenterX,
        y: targetElCoord.y + offsetCenterY,
        height: targetElCoord.height,
        width: keyboardWidth,
    });
    retail.retailKeyboard.isVisible.set(true);
    retail.retailKeyboard.isIgnoreLossFocus.set(ignoreLossFocus);
    retail.retailKeyboard.layout.set(layout);
    retail.retailKeyboard.value.reset();
    if (value) {
        retail.retailKeyboard.value.set(value);
    }
    retail.retailKeyboard.lastKeyPress.reset();
    retail.retailKeyboard.lastKeyPress.unsubscribeAll();
    retail.retailKeyboard.lastKeyPress.subscribe((button: string) => {
        if (button === '{enter}' && onEnter) {
            onEnter();
        }
    });
}

export function showVirtualKeyboard(params: Params) {
    const { targetEl, showWithDelay = 0 } = params;
    if (targetEl && !isMobile()) {
        const isVisible = getStoreValue(retail.retailKeyboard.isVisible);
        beforeShow(isVisible, params);
        if (showWithDelay) {
            setTimeout(() => show(params), showWithDelay);
        } else {
            show(params);
        }
    }
}

export function hideVirtualKeyboard() {
    retail.retailKeyboard.isVisible.set(false);
}
