import { GetLimitConfiguration } from '@staycool/bets-types/limit-configuration';
import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { getActiveCurrency } from '../services/currency';
import { logger } from '../services/logger';
import { createBonusBetRequestObject } from '../services/sports/bonus-bets';
import { CASHOUT_NOT_ELIGIBLE_ERROR } from '../services/sports/constants';
import { Ticket } from '../services/sports/types';
import { convertArrayToObject } from '../services/util';

const getUrl = (url) => getServiceUrl('bets', url);

export async function placeBetRequest(data) {
    const url = getUrl('bets');
    return httpPost<string>(url, data);
}

export async function getLatestTicketId(timestampFrom) {
    const currentFoTime = new Date().getTime();
    const url = getUrl('tickets/latest');
    return httpGet<string>(url, { timestampFrom, currentFoTime });
}

export async function placeFreeBetRequest(marketId, isManualAcceptance = false, isForceDuplicate = false) {
    const url = getUrl('bets/free');
    const betPlacingData = createBonusBetRequestObject(marketId, isManualAcceptance, isForceDuplicate);
    return httpPost<string>(url, betPlacingData);
}

export async function placeFreeBetV2Request(marketId, isManualAcceptance = false, isForceDuplicate = false) {
    const url = getUrl('bets/free-v2');
    const betPlacingData = createBonusBetRequestObject(marketId, isManualAcceptance, isForceDuplicate);
    return httpPost<string>(url, betPlacingData);
}

export async function placeRiskFreeBetRequest(marketId, isManualAcceptance = false, isForceDuplicate = false) {
    const url = getUrl('bets/risk-free');
    const betPlacingData = createBonusBetRequestObject(marketId, isManualAcceptance, isForceDuplicate);
    return httpPost<string>(url, betPlacingData);
}

export async function placeMultiplierBetRequest(marketId, isManualAcceptance = false, isForceDuplicate = false) {
    const url = getUrl('bets/multiplier');
    const betPlacingData = createBonusBetRequestObject(marketId, isManualAcceptance, isForceDuplicate);
    return httpPost<string>(url, betPlacingData);
}

export async function getMatchTurnover(marketId, currency = getActiveCurrency()) {
    const turnoverStats = await httpGet<
        { id: number; market_id: number; stake: number; bets: number; share: number; sum: number }[]
    >(getUrl(`stats/market`), {
        id: marketId,
        currency,
    });
    return convertArrayToObject(turnoverStats, 'id');
}

export function getMarketsStats(marketsIds: number[], currency: string) {
    const url = getUrl('stats/bulk-markets');
    return httpPost<MarketStatsByMarketId>(url, { marketsIds, currency });
}

export function doCashout(ticketId, cashoutAmount, acceptAnyOddsChanges, acceptLimitedOddsChanges) {
    return httpPost<{ amount: number; amount_uc: number; id: string }[]>(getUrl(`cashout/${ticketId}`), {
        cashoutAmount,
        acceptAnyOddsChanges,
        acceptLimitedOddsChanges,
    });
}

export async function isBetslipCashoutEligible(ticketType, marketIds) {
    if (!marketIds || !marketIds.length) {
        return true;
    }
    try {
        await httpGet(getUrl('cashout/is-betslip-eligible/'), { marketIds, ticket_type: ticketType });
        return true;
    } catch (e: any) {
        if ([CASHOUT_NOT_ELIGIBLE_ERROR, 1152, 1154, 1155].includes(e.code)) {
            return false;
        }
        return true;
    }
}

export type IsSameMatchComboEligiblePayload = {
    matchId: number;
    totalsMarketId: number;
    handicapMarketId?: number;
}[];

export async function isSameMatchComboEligible(payload: IsSameMatchComboEligiblePayload) {
    if (!payload.length) {
        return {};
    }
    try {
        return await httpPost<Record<number, boolean>>(getUrl('validation/is-same-match-combo-eligible'), payload);
    } catch (e) {
        logger.error('BetsMicroservice', 'isSameMatchComboEligible', e);
        return {};
    }
}

export async function getLimitsByCurrency(currency: string) {
    try {
        const url = getUrl(`limit-configuration/${currency}`);
        const response = await httpGet<GetLimitConfiguration | null>(url);
        if (!response) {
            throw new Error(`Limits for ${currency} not found`);
        }
        return response;
    } catch (err) {
        logger.error('BetsMicroservice', 'getLimitsByCurrency', err);
        return {} as GetLimitConfiguration;
    }
}

export function fetchCashouts(offsetDate) {
    return httpGet<{ hasMore: boolean; tickets: Ticket[] }>(getUrl('cashout/eligible-tickets'), { offsetDate });
}

interface OutcomeStats {
    id: number;
    market_id: number;
    stake: number;
    bets: number;
    share: number;
    sum: number;
}

export type MarketStatsByMarketId = Record<number, OutcomeStats[]>;
