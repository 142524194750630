import React, { useEffect, useMemo } from 'react';
import Wrapper from './styles';
import { useTurnoverMarket } from '../../../../../services/sports/top-markets';
import SportMatchTurnover from '../../turnover/SportMatchTurnover';
import SportMatchComments from '../../comments/SportMatchComments';
import SportMatchMarketsTopMarkets from '../../markets/top-markets/SportMatchMarketsTopMarkets';
import SportMatchInfo from '../../info/SportMatchInfo';
import { MATCH_STATUS, MATCH_TYPE } from '../../../../../services/sports/constants';
import SportMatchName from '../../name/SportMatchName';
import SportMatchScore from '../../score/SportMatchScore';
import { useSportsMatchContext } from '../../../../../services/sports/match-helpers';
import { stores, useStoreWithSelector } from '../../../../../stores';
import UiCloseButton from '../../../../ui/close-button/UiCloseButton';
import { getRoute, useRouter } from '../../../../../services/router';
import MatchPromotion from '../../../../marketing/match-promotion/MarketingMatchPromotion';
import { SportMatchLayoutComponentParams as Props } from '../../../../../services/sports/types';
import SportBadge from '../../../badge/SportBadge';
import SportMatchExtra from '../../extra/SportMatchExtra';
import { FEATURE, isFeatureAvailable } from '../../../../../services/feature';
import { translate } from '../../../../../services/translate';
import UiButton from '../../../../ui/button/UiButton';
import { useSportsUserSettings } from '../../../../../services/sports/user-settings';
import UiPlaceholder from '../../../../ui/placeholder/UiPlaceholder';
import { useGlobalModal } from '../../../../../hooks/useGlobalModal';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';

export default function SportMatchLayoutCompact({ match }: Props) {
    const turnoverMarket = useTurnoverMarket(match);
    const { turnoverVisible, isOpen, setContext: setMatchContext } = useSportsMatchContext();
    const [promotion] = useStoreWithSelector(stores.marketing.matchPromotionsByMatchIds, (s) => s[match.id], [
        match.id,
    ]);
    const isLiveNow = match.status === MATCH_STATUS.LIVE;
    const isNormalMatch = match.match_type !== MATCH_TYPE.OUTRIGHT;
    const [{ isPhone }] = useStore(media);
    const { isAmericanLayout } = useSportsUserSettings({ sportCategoryId: match.sport_category });

    const matchRoute = isLiveNow ? `${getRoute('sport.live')}/${match.id}` : `${getRoute('sport.match')}/${match.id}`;
    const isMatchPage = window.location.pathname.includes(matchRoute);
    const isLivebetMatch = !isLiveNow && match.inplay;
    const shouldHaveGeniusStream = match.has_genius_stream;
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);
    const { navigateTo } = useRouter();
    const { showModal, hideModal } = useGlobalModal();

    const leagueCategoryId = match ? match.category_id : -1;
    const [topMarketTypesByCategoryId] = useStore(stores.sports.topMarketTypesByCategory);
    const topMarketTypes = useMemo(() => {
        const topMarketTypes = topMarketTypesByCategoryId[leagueCategoryId];

        const marketTypeIds = match?.markets?.map((market) => market?.market_type_id).filter((id) => !!id);
        return (
            topMarketTypes &&
            topMarketTypes.map((topType) =>
                !marketTypeIds || marketTypeIds.includes(topType.id) ? topType : { ...topType, name: ' ' },
            )
        );
    }, [leagueCategoryId, match, topMarketTypesByCategoryId && topMarketTypesByCategoryId[leagueCategoryId]]);

    useEffect(() => {
        if (turnoverVisible) {
            showModal(
                SportMatchTurnover,
                {
                    matchId: match.id,
                    mainMarket: turnoverMarket,
                    isModal: true,
                },
                () => {},
                () => {
                    setMatchContext?.({ turnoverVisible: false });
                    hideModal();
                },
            );
        }
    }, [turnoverVisible]);

    function openSidebets() {
        isLayoutB ? !isOpen && navigateTo(matchRoute) : setMatchContext?.({ isOpen: !isOpen });
    }

    return (
        <Wrapper
            isOutright={!isNormalMatch}
            isAmericanLayout={isAmericanLayout}
            isOpen={isOpen}
            isMatchPage={isMatchPage}
        >
            {isPhone && isAmericanLayout && match && match.match_type !== MATCH_TYPE.OUTRIGHT && (
                <div className="match-market-types-header">
                    <div className="match-name-container" />
                    <div className="category-market-types">
                        {(topMarketTypes || [0, -1, -2]).map((marketType) => (
                            <div key={marketType.id} className="market-type">
                                <div>{marketType.shortName || marketType.name || <UiPlaceholder />}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <div className="compact-match-container">
                <div className="match-name-container">
                    <SportMatchName match={match} isCompact />

                    {isLiveNow && (
                        <div className="match-scores">
                            <SportMatchScore
                                isLivebetOur={match.livebet_our}
                                matchId={match.id}
                                sportId={match.sport}
                                sportCategoryId={match.sport_category}
                                isCompact
                            />
                        </div>
                    )}
                </div>
                {isNormalMatch && (
                    <SportMatchMarketsTopMarkets match={match} marketsToShow={isAmericanLayout || !isPhone ? 3 : 1} />
                )}
                {!isNormalMatch && !isOpen && (
                    <UiButton
                        block={isPhone}
                        size="small"
                        className="show-more-odds"
                        onClick={openSidebets}
                        color="outline"
                    >
                        {translate('Show odds', 'ui.sportsbook')}
                    </UiButton>
                )}
            </div>

            {!isNormalMatch && <SportMatchMarketsTopMarkets match={match} />}

            <div className="match-compact-info">
                <div className="match-compact-info-main">
                    <SportMatchInfo match={match} showTime />
                    <div className="match-extra">
                        {isLivebetMatch && !(match.stream_info || shouldHaveGeniusStream) && !isLayoutB && (
                            <SportBadge type={MATCH_TYPE.LIVEBET} />
                        )}
                        <SportMatchExtra match={match} />
                    </div>
                </div>
                {!isOpen && (
                    <div className="match-comments">
                        <SportMatchComments match={match} />
                    </div>
                )}
            </div>

            {isOpen && !isMatchPage && (
                <UiCloseButton
                    onClick={() => setMatchContext?.({ isOpen: false })}
                    size={24}
                    className="close-button"
                />
            )}

            {!isOpen && promotion && <MatchPromotion promotion={promotion} />}
        </Wrapper>
    );
}
