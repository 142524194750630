import React from 'react';
import { getOS, OPERATING_SYSTEM } from '../../../../services/device';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import Snippet from '../../../snippet/Snippet';
import UiAlert from '../../../ui/alert/UiAlert';
import UiButton from '../../../ui/button/UiButton';
import UiGroup from '../../../ui/group/UiGroup';
import Wrapper from './styles';
import Svg from '../../../svg/Svg';
import { environment } from '../../../../stores/environment/environment';
import { useStore } from '../../../../hooks/useStore';

export default function GeocomplyDesktopInstallers() {
    const [user] = useStore(stores.user);
    const [geocomplyClient] = useStore(stores.geocomply.client);
    const [{ GEOCOMPLY }] = useStore(environment);

    if (!GEOCOMPLY) {
        return null;
    }

    const getInstallerUrl = (os: 'win' | 'mac') => {
        const intallerKey = GEOCOMPLY.DESKTOP_INSTALLER_KEY;
        const version = geocomplyClient.libraryVersion as string;
        const userId = user?.id as string;

        const params = `os=${os}&id=${intallerKey}&version=${version}&user_id=${userId}`;
        return `${GEOCOMPLY.DESKTOP_INSTALLER_BASE_URL}?${params}`;
    };

    const openLink = (link: string) => window.open(link, '_blank');
    const os = getOS();

    return (
        <Wrapper>
            <UiGroup vertical>
                <Snippet snippetKey="geocomply.pc-app-introduction" />
                <UiGroup>
                    <UiButton
                        color={os === OPERATING_SYSTEM.APPLE_DESKTOP ? 'primary' : 'default'}
                        icon={<Svg icon="apple" />}
                        iconPosition="left"
                        onClick={() => openLink(getInstallerUrl('mac'))}
                    >
                        {translate('Download for Mac OS', 'ui.geocomply')}
                    </UiButton>

                    <UiButton
                        color={os === OPERATING_SYSTEM.WINDOWS_DESKTOP ? 'primary' : 'default'}
                        icon={<Svg icon="windows" />}
                        iconPosition="left"
                        onClick={() => openLink(getInstallerUrl('win'))}
                    >
                        {translate('Download for Windows', 'ui.geocomply')}
                    </UiButton>
                </UiGroup>

                {![OPERATING_SYSTEM.APPLE_DESKTOP, OPERATING_SYSTEM.WINDOWS_DESKTOP].includes(os) && (
                    <UiAlert warning>
                        {translate('You are using an unsupported os - {{ os }}', 'ui.geocomply', { os })}
                    </UiAlert>
                )}
            </UiGroup>
        </Wrapper>
    );
}
