import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { stores } from '../../../stores';
import {
    getLeaderboard,
    PokerLeaderboard,
    PokerLeaderboardParticipant,
} from '../../../microservices/poker-playtech-gameslink';
import UiDotsLoader from '../../ui/dots-loader/UiDotsLoader';
import CmsPokerLeaderboardParticipant from './participant/CmsPokerLeaderboardParticipant';
import { translate } from '../../../services/translate';
import CmsPokerLeaderboardSearch from './search/CmsPokerLeaderboardSearch';
import { useStore } from '../../../hooks/useStore';

interface Props {
    'leaderboard-id': string;
    'show-event-count': boolean;
}

export default function CmsPokerLeaderboard({
    'leaderboard-id': leaderboardId,
    'show-event-count': showEventCount = false,
}: Props) {
    const [language] = useStore(stores.language);
    const [leaderboard, setLeaderboard] = useState<PokerLeaderboard>();
    const [filteredParticipants, setFilteredParticipants] = useState<PokerLeaderboardParticipant[]>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadLeaderboard().finally(() => {
            setIsLoading(false);
        });
    }, []);

    async function loadLeaderboard(): Promise<void> {
        const data = await getLeaderboard(leaderboardId, language);
        setLeaderboard(data);
        setFilteredParticipants(data.participants);
    }

    function onSearch(participants: PokerLeaderboardParticipant[]): void {
        setFilteredParticipants(participants);
    }

    return (
        <Wrapper>
            {isLoading && <UiDotsLoader />}
            {leaderboard && (
                <>
                    <div>
                        <h1>{leaderboard.displayName}</h1>
                        <span>
                            {translate('Registration closes at', 'poker.leaderboard')}{' '}
                            {new Date(leaderboard.registrationEndTime).toString()}
                        </span>
                    </div>
                    {leaderboard.participants.length > 50 && (
                        <CmsPokerLeaderboardSearch participants={leaderboard.participants} onSearch={onSearch} />
                    )}
                    <div className="participants-header">
                        <div className="position">{translate('Rank', 'poker.leaderboard')}</div>
                        <div className="alias">{translate('Alias', 'poker.leaderboard')}</div>
                        <div className="score">{translate('Score', 'poker.leaderboard')}</div>
                        {showEventCount && <div className="eventCount">{translate('Events', 'poker.leaderboard')}</div>}
                        <div className="prize">{translate('Prize', 'poker.leaderboard')}</div>
                    </div>
                    <div className="participants-container">
                        {filteredParticipants?.map((participant) => (
                            <CmsPokerLeaderboardParticipant
                                key={participant.alias}
                                showEventCount={showEventCount}
                                minEvents={leaderboard.minEvents}
                                {...participant}
                            />
                        ))}
                    </div>
                </>
            )}
        </Wrapper>
    );
}
