import React, { useEffect, useState } from 'react';
import { translate } from '../../../../../../services/translate';
import UiFormInput from '../../../../../ui/form/input/UiFormInput';
import { validators } from '../../../../../../services/validators';
import { useForm } from '../../../../../../services/form';
import UiAlert from '../../../../../ui/alert/UiAlert';
import { updateProfile } from '../../../../../../microservices/users-cb';
import { stores } from '../../../../../../stores';
import UiButton from '../../../../../ui/button/UiButton';
import { useStore } from '../../../../../../hooks/useStore';

interface Props {
    isModal?: boolean;
    onAfterSubmit: () => void;
    onExit: () => void;
}

export default function AuthProfileValidatorLicenceRequirementSwedenPersonalId({
    isModal = false,
    onAfterSubmit,
    onExit,
}: Props) {
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isPersonalIdAsked, setIsPersonalIdAsked] = useState(false);
    const [user] = useStore(stores.user);

    const form = useForm({
        personalId: undefined,
    });

    useEffect(() => {
        const askPersonalId = user?.askPersonalId || false;
        setIsPersonalIdAsked(Boolean(askPersonalId));
        if (askPersonalId && isModal) {
            stores.modals.isRequiredProfileInformationInquiryModalOpen.set(true);
        }
        if (!askPersonalId) {
            onExit();
        }
    }, [user]);

    async function onSubmit() {
        setIsLoading(true);
        try {
            const personalId = form.getValues().personalId;
            const error = validators.personalIdSwedish(personalId);
            if (error && error.error) {
                setError(error.error);
                return;
            }
            await updateProfile({ personal_id: personalId });
            onAfterSubmit();
        } catch (error) {
            setError(translate('Something went wrong', 'ui.common'));
        }
        setIsLoading(false);
    }

    if (!isPersonalIdAsked) {
        return null;
    }

    return (
        <>
            <UiFormInput
                name="sweden-set-personal-id"
                value={form.getValues().personalId}
                onValueChange={(value) => {
                    form.setInputValue({ personalId: value });
                }}
                minLength={12}
                maxLength={12}
                placeholder={translate('ID code', 'ui.account')}
                label={translate('ID code', 'ui.account')}
            />
            <UiButton block color="primary" isLoading={isLoading} onClick={onSubmit}>
                {translate('Confirm', 'ui.common')}
            </UiButton>
            {error && <UiAlert failure>{error}</UiAlert>}
        </>
    );
}
