import React, { useState } from 'react';
import { GeoComplyMobileAppRequiredLocationPermissionHint } from '../../../../../../services/geocomply/mobile';
import { translate } from '../../../../../../services/translate';
import UiButton from '../../../../../ui/button/UiButton';
import UiGroup from '../../../../../ui/group/UiGroup';
import GeocomplyRetryGeolocationButton from '../../../../retry-geolocation-button/GeocomplyRetryGeolocationButton';

interface Props {
    hint: GeoComplyMobileAppRequiredLocationPermissionHint;
}

export default function GeocomplyMobileHintHandlerAppRequiredLocationPermission({ hint }: Props) {
    const [isRetryAllowed, setIsRetryAllowed] = useState(false);

    function allowLocationAccess() {
        window.location.assign(hint.wakeupUrl);
        setIsRetryAllowed(true);
    }

    return (
        <UiGroup vertical>
            <UiButton onClick={allowLocationAccess} color="primary">
                {translate('Allow location access', 'ui.geocomply')}
            </UiButton>

            {isRetryAllowed && <GeocomplyRetryGeolocationButton />}
        </UiGroup>
    );
}
