import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    @media ${mediaQuery.isPhone} {
        padding: var(--spacing-8) 0;
    }
    .more-odds-buttons {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: var(--spacing-4);
        > *:first-child {
            flex: 1;
        }
    }
`;
