import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    position: relative;
    background-color: #2f2f38;
    width: 100%;

    .rtp-calculator-game-selection-comparison {
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            display: block;
        }
        margin: auto;
    }

    .rtp-calculator-game-selection-play-options {
        display: flex;
        .rtp-calculator-game-selection-element:first-child {
            margin-right: 20px;
        }
    }

    .rtp-calculator-game-selection-label {
        &-text {
            font-size: 1.5rem;
            font-weight: 500;
            letter-spacing: 1px;
            padding-bottom: 15px;
            color: var(--color-highlight);
            transition: color 0.2s;
            @media ${mediaQuery.isPhone} {
                font-size: 0.875rem;
            }
        }
    }
    .rtp-calculator-game-selection-wrapper {
        box-shadow: 0 0 50px #16161b;
        display: flex;
        @media ${mediaQuery.isPhone} {
            display: block;
        }
    }

    .rtp-calculator-game-selection-content,
    .rtp-calculator-game-selection-label {
        max-width: 768px;
        padding: 1.25em;
        margin: auto;
    }

    .rtp-calculator-game-selection-info {
        margin: auto;
        text-align: center;
        margin-top: 10px;
        font-size: 0.6rem;
    }

    .rtp-calculator-game-selection-select {
        width: 100%;
        .select-container:after {
            width: 1.7rem;
        }
    }

    .rtp-calculator-game-selection-element {
        display: inline-block;
        align-items: center;
        padding-top: 10px;
        vertical-align: middle;
        width: 100%;
        border-radius: 0;
        @media ${mediaQuery.isPhone} {
            width: 100%;
            font-size: 0.85rem;
        }

        &-label {
            display: block;
            cursor: default;
            width: 190px;
            font-size: 0.75rem;
            color: #cdcdcc;
            margin: 0 0 0.5rem 0;
            padding: 0 0.4rem;
            white-space: nowrap;
            @media ${mediaQuery.isPhone} {
                width: 100%;
                font-size: 0.85rem;
            }
        }
    }
`;
