import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { getDeviceUuid } from '../services/fingerprint';
import { GeocomplyGeoverificationStatus, GeocomplyLicense } from '../services/geocomply/geocomply';

const getUrl = (url) => getServiceUrl('geocomply', url);

export function postGeopacketForProcessing(encryptedGeopacket: string): Promise<GeocomplyGeoverificationStatus> {
    const deviceUuid = getDeviceUuid();

    const deviceUuidHeaders = {
        headers: {
            device_uuid: deviceUuid,
        },
    };

    return httpPost(
        getUrl(`geopacket/`),
        { geopacket: encryptedGeopacket },
        deviceUuid ? deviceUuidHeaders : undefined,
    );
}

export function getGeoVerificationStatus(): Promise<GeocomplyGeoverificationStatus> {
    const deviceUuid = getDeviceUuid();

    const deviceUuidHeaders = {
        headers: {
            device_uuid: deviceUuid,
        },
    };

    return httpGet(getUrl(`status/`), null, deviceUuid ? deviceUuidHeaders : undefined);
}

export function getGeocomplyLicense(isLoadByError = false): Promise<GeocomplyLicense> {
    return httpGet(getUrl(`license/`), { isLoadByError });
}
