import classNames from 'classnames';
import round from 'lodash/round';
import React, { useEffect, useState } from 'react';
import { convertOdds } from '../../../../services/odds-format';
import { useRouter } from '../../../../services/router';
import { calculateTotalOdds, hasBetSlipChangedFromOdds } from '../../../../services/sports/betslip';
import { getFixedStake } from '../../../../services/sports/betslip-formatting';
import { getErrorCode } from '../../../../services/sports/betslip-errors';
import { BET_TYPE, BonusType, COMBO_MARKET_ID, MA_ENABLED_ERROR } from '../../../../services/sports/constants';
import { hasBetslipManualAcceptanceError } from '../../../../services/sports/manual-acceptance-helpers';
import { useOddsFormat } from '../../../../services/sports/user-settings';
import { translate } from '../../../../services/translate';
import { stores, useStoreWithSelector } from '../../../../stores';
import SportBetslipWageToWin from '../wage-to-win/SportBetslipWageToWin';
import SportBetslipStakeInputExtraWin from './extra-win/SportBetslipStakeInputExtraWin';
import UiAnimate from '../../../ui/animate/UiAnimate';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    marketId: string | null;
}

export default function SportBetslipStakeInput({ marketId }: Props) {
    const [userState, setUserState] = useStore(stores.sports.betSlipUserState);
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [teaserSelectedPoint] = useStore(stores.sports.teaserSelectedPoint);
    const [teaserPayouts] = useStore(stores.sports.teaserPayouts);
    const [genericErrors] = useStoreWithSelector(
        stores.sports.betSlipErrorByMarketId,
        (state) => state[String(COMBO_MARKET_ID)],
    );
    const [betSlipPlacingState] = useStore(stores.sports.betSlipPlacingState);
    const [oddsIds] = useStoreWithSelector(stores.sports.oddsByOutcomeId, hasBetSlipChangedFromOdds);
    const [bonusBetsSelection] = useStore(stores.sports.bonusBetsSelection);
    const { stake = 0 } = useRouter().queryParams as any;
    const [maxWin, setMaxWin] = useState(0);
    const [totalOdds, setTotalOdds] = useState(0);
    const [isStakeChanging, setIsStakeChanging] = useState(false);
    const { stakeByMarketId, MAStakeByMarketId, disableInputs, betType } = userState;
    const stakeValue = getStakeValue();
    const [oddsFormat] = useOddsFormat();

    useEffect(calculateMaxWin, [
        stakeValue,
        oddsIds,
        betSlipMarketIdToOutcomeId,
        oddsFormat,
        betType,
        teaserSelectedPoint,
        teaserPayouts,
    ]);

    useEffect(() => {
        if (stakeValue) {
            stakeChange(stakeValue);
        }
    }, []);

    function stakeChange(stakeValue) {
        setUserState((state) => ({
            ...state,
            stakeByMarketId: { ...stakeByMarketId, [String(marketId)]: getFixedStake(stakeValue) },
        }));
    }

    function getStakeValue(): number {
        const marketStake = stakeByMarketId[String(marketId)] as any;

        if (marketStake === undefined) {
            return stake;
        }

        return marketStake;
    }

    function MAStakeChange(stakeValue) {
        setUserState((state) => ({
            ...state,
            MAStakeByMarketId: { ...MAStakeByMarketId, [String(marketId)]: getFixedStake(stakeValue) },
        }));
    }

    function calculateMaxWin() {
        const totalOdds = calculateTotalOdds(marketId);
        setTotalOdds(totalOdds);
        if (!stakeValue) {
            if (betType === BET_TYPE.TEASER) {
                setMaxWin(round(0, 2));
            }
            return;
        }
        setMaxWin(round(totalOdds * stakeValue, 2));
    }

    function applyStakeForAllSingles(stake: number) {
        setUserState((state) => ({
            ...state,
            stakeByMarketId: Object.keys(betSlipMarketIdToOutcomeId).reduce((stakeByMarketId, marketId) => {
                stakeByMarketId[marketId] = stake;
                return stakeByMarketId;
            }, {}),
        }));
    }

    function handleStakeChange(value) {
        stakeChange(value);
        setIsStakeChanging(true);
        const timeout = setTimeout(() => setIsStakeChanging(false), 500);
        return () => clearTimeout(timeout);
    }

    const loading = betSlipPlacingState.isLoading || betSlipPlacingState.needsConfirm;
    const hasManualAcceptanceError = hasBetslipManualAcceptanceError();
    const isManualAcceptanceStakeAllowed = (genericErrors || []).map(getErrorCode).includes(MA_ENABLED_ERROR);
    const isMainInputDisabled = loading || disableInputs || hasManualAcceptanceError;
    const isFreeBetApplied = bonusBetsSelection?.free_bet || bonusBetsSelection?.free_bet_v2;
    const displayMaxWin = stakeValue > 0 && maxWin;
    const isFreeBetDeduction = isFeatureAvailable(FEATURE.FREE_BET_DEDUCTION) && isFreeBetApplied;

    return (
        <>
            <SportBetslipWageToWin
                name={`yourStake${marketId || 'Combo'}`}
                marketId={marketId}
                isDisabled={isMainInputDisabled}
                value={stakeValue}
                disableToWin={false}
                totalOdds={totalOdds}
                onChange={handleStakeChange}
                hideQuickStakes={hasManualAcceptanceError}
                disableQuickStakes={isFreeBetApplied}
            />

            {Object.keys(betSlipMarketIdToOutcomeId).length > 1 &&
                stakeValue > 0 &&
                [BET_TYPE.SINGLE, BET_TYPE.BETBUILDER].includes(betType) && (
                    <a className="apply-to-all" onClick={() => applyStakeForAllSingles(stakeValue)}>
                        <span>{translate('Apply to all ?', 'ui.sportsbook')}</span>
                    </a>
                )}
            {hasManualAcceptanceError && isManualAcceptanceStakeAllowed && (
                <UiAnimate animationIn="bounceIn" animationOut="bounceOut" isVisible={true}>
                    <SportBetslipWageToWin
                        name={`extraStake${marketId || 'Combo'}`}
                        label={translate('Apply extra stake', 'ui.sportsbook')}
                        isDisabled={loading}
                        value={(MAStakeByMarketId[String(marketId)] as any) || 0}
                        onChange={MAStakeChange}
                        hideQuickStakes
                    />
                </UiAnimate>
            )}
            {[BET_TYPE.COMBO, BET_TYPE.TEASER].includes(betType) && (
                <div className="total-odds">
                    <span className="stake-input-label">{translate('Total odds', 'ui.betslip')} →</span>
                    <span className="stake-input-value">{convertOdds(totalOdds)}</span>
                </div>
            )}
            <div className="potential-return">
                <span className="stake-input-label">{translate('Potential return', 'ui.betslip')} →</span>
                <span className={classNames('stake-input-value', { 'animated-value': isStakeChanging })}>
                    {displayMaxWin
                        ? formattedAmountWithCurrency(isFreeBetDeduction ? round(maxWin - stakeValue, 2) : maxWin)
                        : null}
                </span>
            </div>
            {bonusBetsSelection[BonusType.Multiplier] && (
                <SportBetslipStakeInputExtraWin maxWin={maxWin} stake={stakeValue} />
            )}
        </>
    );
}
