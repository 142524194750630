import styled from 'styled-components';

const badgeHeight = 20;
const iconWidth = 80;

export default styled.div`
    display: flex;
    flex-direction: ${(props) => (props.isCompactView ? 'column' : 'row')};
    align-items: center;
    position: relative;
    background: var(--surface-level-2-bg);
    border: var(--surface-level-2-border);
    border-radius: var(--ui-radius);
    margin-bottom: var(--spacing-20);
    width: 100%;
    max-width: 35rem;
    padding: var(--spacing-16) 0;

    .badge-container {
        position: absolute;
        left: 0;
        right: 0;
        top: -${badgeHeight / 2}px;
        height: ${badgeHeight}px;
        display: flex;
        align-items: center;
        justify-content: center;
        .badge {
            padding: 0.2rem 0.5rem;
            background-color: var(--color-highlight);
            color: var(--color-on-highlight);
            font-size: 0.75rem;
            text-transform: capitalize;
            border-radius: var(--ui-radius);
        }
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto 0;
        padding: ${({ theme }) => theme.layout.gutter};
        margin-left: ${(props) => (props.isCompactView ? '0' : props.theme.layout.gutter)};

        .icon {
            width: ${iconWidth}px;
            margin-bottom: ${({ theme }) => theme.layout.gutter};
            color: var(--font-color-subtle);
        }

        .description {
            width: ${iconWidth * 2}px;
            text-align: center;
            font-size: 0.8rem;
        }
    }

    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        padding: var(--spacing-12);
        margin-right: ${(props) =>
            props.isCompactView || !props.isHeaderVisible ? 'auto' : props.theme.layout.gutter};
        .title {
            color: var(--font-color-primary);
            margin-bottom: var(--spacing-12);
            text-align: center;
            display: block;
            width: 100%;
            font-size: 1.125rem;
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering}
        }
    }
`;
