import { Licence, Market } from '@staycool/location';
import { COUNTRY } from './country';
import { Language } from './language';

export enum SportsLayout {
    EUROPEAN = 'EUROPEAN',
    AMERICAN = 'AMERICAN',
}

export enum Province {
    CANADA_ONTARIO = 'Ontario',
    USA_NEVADA = 'Nevada',
}

export type User = {
    acceptTermsAndConditions: boolean;
    address: string;
    alias: string;
    askPersonalId: boolean;
    birthDate: string;
    birthPlace: string;
    casinoClosed: boolean;
    casinoClosedReason?: string;
    city: string;
    closed: boolean;
    country: COUNTRY;
    created: Date;
    email: string;
    eVerified: 'failed' | 'not_checked' | 'recommended' | 'requested' | 'verified';
    firstName: string;
    gender?: 'M' | 'F';
    hasAcceptedAgeAndPlayingOwnBehalf: boolean;
    industryId: number;
    isTest: boolean;
    jobTitle: string;
    language: Language;
    lastName: string;
    licence: Licence;
    market: Market;
    nationality: string;
    personalId: string;
    phoneNumber: string;
    phonePrefix: string;
    pokerClosed: boolean;
    pokerClosedReason?: string;
    province?: string;
    readonly id: string;
    registrationSource?: string;
    showFullAliasInLeaderboard?: boolean;
    showInLeaderboard: boolean;
    sportsbookClosed: boolean;
    sportsbookClosedReason?: string;
    subscribeDirectMail: boolean;
    subscribeMailList: boolean;
    subscribePhoneCalls: boolean;
    subscribeSms: boolean;
    tempAccount?: any;
    verified: boolean;
    virtualSportsClosed: boolean;
    virtualSportsClosedReason?: string;
    zip: string;
    isProfileReviewRequired: boolean;
    horseRacingClosed: boolean;
    horseRacingClosedReason: string;
};

export type UserQueryParameters = {
    personalId?: boolean;
    extra?: boolean;
};

export type UserSC = {
    accept_terms_and_conditions: boolean;
    address: string;
    alias: string;
    ask_personal_id: boolean;
    birth_date: string;
    birth_place: string;
    casino_closed: boolean;
    city: string;
    closed: boolean;
    country: COUNTRY;
    created: Date;
    email: string;
    e_verified: 'failed' | 'not_checked' | 'recommended' | 'requested' | 'verified';
    first_name: string;
    gender?: 'M' | 'F';
    has_accepted_age_and_playing_own_behalf: boolean;
    industry_id: number;
    is_test: boolean;
    job_title: string;
    language: Language;
    last_name: string;
    licence: Licence;
    nationality: string;
    personal_id: string;
    phone_number: string;
    phone_prefix: string;
    poker_closed: boolean;
    province?: string;
    readonly id: string;
    registration_source?: string;
    show_full_alias_in_leaderboard?: boolean;
    show_in_leaderboard: boolean;
    sportsbook_closed: boolean;
    subscribe_direct_mail: boolean;
    subscribe_mail_list: boolean;
    subscribe_phone_calls: boolean;
    subscribe_sms: boolean;
    verified: boolean;
    virtual_sports_closed: boolean;
    zip: string;
    source_of_funds: string;

    // use "kyc/user-settings" and "store.kyc.settings" instead
    // kyc_address_decline_reason?: string;
    // kyc_id_decline_reason?: string;
    // kyc_income_decline_reason?: string;
    // kyc_payment_decline_reason?: string;
    // kyc_status: 'approved' | 'waiting' | string;
    // proof_of_address: 'not_checked' | string;
    // proof_of_id: 'approved' | string;
    // proof_of_id_expiry_date: Date;
    // proof_of_payment_status: 'not_checked' | string;
    // source_of_income_status: 'declined' | 'not_checked' | 'approved' | 'requested' | 'pending';
};
