import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { getProvinceOntarioOrNull, getUserCountry } from '../services/user';
import { getSportsLayout } from '../services/sports/user-settings';
import { FoMatch } from '@staycool/sports-types';
import { WithLanguageLayout } from '@staycool/sbgate-types';
import { setSingleCategoryMarketTypesToStore } from '../services/sports/sport-category';
import { SportCategoryWithMatches } from '../services/sports/types';
import { Currency } from '../services/wallet/types';

const getUrl = (url) => getServiceUrl('sb-campaigns', url);

export function getCampaigns(params: { locale: string; market: Market }) {
    const url = getUrl('campaign');
    return httpGet<Campaign[]>(url, params);
}

export function postCampaignBet(campaignBetRequest) {
    return httpPost<string>(getUrl('bets'), campaignBetRequest);
}

export function getLeaderboard(campaignId, day) {
    const url = day ? `leaderboard/${campaignId}/total/${day}` : `leaderboard/${campaignId}/daily`;
    return httpGet<LeaderBoard[]>(getUrl(url));
}

export function getOddsCompetitionLeaderboard(campaignId) {
    const url = getUrl(`leaderboard/${campaignId}/oddsCompetition`);
    return httpGet<{ leaderBoard: LeaderBoard[]; campaign: Campaign[] }>(url);
}

export function getLeaderboardCounter(campaignId) {
    const url = getUrl(`leaderboard/${campaignId}/counter`);
    return httpGet<number>(url);
}
type GetCampaignMatchesExtraParams = Pick<FoMatch.FoMatchListFilteredCampaigns, 'product' | 'offset' | 'limit'>;
type CampaignsRequestMatchList = Pick<
    WithLanguageLayout<FoMatch.FoMatchListFilteredCampaigns>,
    'offset' | 'limit' | 'country' | 'locale' | 'layout' | 'language' | 'province'
>;

export async function getCampaignMatches(campaignId: number, params: GetCampaignMatchesExtraParams) {
    const language = getStoreValue(stores.language);
    const country = getUserCountry();
    const province = getProvinceOntarioOrNull();
    const layout = getSportsLayout();
    const query: CampaignsRequestMatchList = {
        ...params,
        language,
        locale: language,
        country,
        province: province || undefined,
        layout,
    };
    const categoryWithMatches = await httpGet<SportCategoryWithMatches>(
        getUrl(`campaign/matches/${campaignId}`),
        query,
    );
    setSingleCategoryMarketTypesToStore(categoryWithMatches);
    return categoryWithMatches?.matches;
}

export type Campaign = {
    id: number;
    name: string;
    active: boolean;
    deleted: boolean;
    bet_min_odds: number;
    bet_amounts: BetAmounts;
    sport_requirements: SportRequirements;
    start_date: Date | null;
    end_date: Date | null;
    recurrence: string | null;
    repeat_requirement: number | null;
    start_day: number;
    daily_start_time: string;
    daily_end_time: string;
    length_days: null | number;
    recurring_amount_field: 'max_win' | 'stake';
    related_campaign_id: null | number;
    created_at: Date;
    updated_at: Date;
    segment_id: string;
    bo_user_id: number;
    is_first_day: boolean;
    previous_day_bet: CampaignBet;
    info_link: InfoLink;
    public_availability: PublicAvailability;
    bet_period: 'day' | 'week' | 'minute' | 'hour' | 'month';
    require_previous_bet: boolean;
    limits_override: boolean;
    article_id: string;
    min_odd_per_selection: number | null;
    max_selection_lost: number | null;
    max_bet_per_period: number | null;
    duration_time?: any;
    country_daily_start_time?: {
        default: string;
        [country: string]: string;
    };
};

interface CampaignBet {
    bet_stats: BetStats;
    bo_user_id: number;
    campaign_id: number;
    currency: string;
    id: number;
    initial_bet_stake: string | null;
    match_ids: number[] | string[];
    odds: string;
    stake: number | null;
    ticket_id: string;
    created_at: Date | string;
    updated_at: Date | string | undefined;
    user_id: string;
    valid: boolean;
    won: boolean | null | undefined;
    won_amount: number | null;
}

interface BetStats {
    id: string;
    bets: Bet[];
    totalOdds: number;
    created_at: Date;
    display_id: number;
    marketsById: Record<string, Market>;
    matchesById: Record<string, Match>;
    num_bets_ma: number;
    systemsMeta: any;
    ticket_type: string;
    total_stake: number;
    num_bets_won: number;
    outcomesById: Record<string, Outcome>;
    num_bets_lost: number;
    num_bets_total: number;
    selectionsMeta: SectionMeta[];
    num_bets_pending: number;
    potential_return: number;
    banker_outcome_ids: any;
    ma_stake_requested: any;
}

interface Market {
    id: number;
    line: number;
    name: string;
    status: string;
    in_play: boolean;
    highRisk: boolean;
    match_id: number;
    view_type: string;
    betting_end: any;
    singles_only: boolean;
    parlayEnabled: boolean;
    market_type_id: number;
    teasersEnabled: boolean;
    excludedCountries: string[];
    paperParlayEnabled: boolean;
    sameMatchComboAllowed: boolean;
}
interface Outcome {
    id: number;
    name: string;
    status: string;
    team_id: any;
    limitUser: number;
    market_id: number;
    limitTotal: number;
    result_key: string;
    excludedCountries: never[];
}
interface SectionMeta {
    odds: number;
    outcome_id: number;
}
interface Match {
    id: number;
    name: string;
    status: string;
    matchType: any;
    bettingEnd: string;
    livebetOur: boolean;
    matchStart: string;
    awayTeamName: string;
    bettingStart: string;
    homeTeamName: string;
    preferHalfLines: boolean;
    sportCategoryId: number;
    leagueCategoryId: number;
    regionCategoryId: number;
    excludedCountries: string[];
    expectedResultTime: string;
    livebetGracePeriod: number;
    matchLivebetGracePeriod: any;
    categoryLivebetGracePeriod: number;
}
interface Bet {
    id: string;
    odds: number;
    stake: number;
    status: string;
    selections: Section[];
}
interface Section {
    odds: number;
    status: string;
    product: string;
    outcome_id: number;
}

export type LeaderBoard = {
    created_at: string;
    currency: Currency;
    start_bet_amount: number;
    user_alias: string;
    won_amount: number;
    ranking: number;
    total_odds: number;
    bets_by_period: any;
};

export type BetAmount = {
    min: number;
    max: number;
    default: number;
};

type BetAmounts = {
    [currency: string]: BetAmount;
};

type SportRequirements = {
    product: Product;
    bet_type: string;
    category_ids: number[];
    allow_outrights: boolean;
    market_type_ids: number[];
    sport_category_ids: number[];
    next_day_match_result_time: number;
    match_ids?: number[];
    selections?: any;
    override_next_day_match_result_time?: boolean;
};

enum Product {
    PREMATCH = 'PREMATCH',
    LIVEBET = 'LIVEBET',
    BOTH = 'BOTH',
}

type InfoLink = {
    [language: string]: string;
};

type PublicAvailability = {
    [language: string]: boolean;
};
