import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import DocumentCaptureProvider, {
    DocumentCaptureContext,
} from '../../../../contexts/document-capture/DocumentCaptureContext';
import { withProvider } from '../../../../higher-order-components/context/provider/ContextProvider';
import { getBadgeProps, KycDocumentType, ProofOfIdStatus, useDocumentUpload } from '../../../../services/kyc';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import AccountVerificationBox from '../box/AccountVerificationBox';
import AccountVerificationDocumentCaptureIdScan from '../document-capture/id-scan/AccountVerificationDocumentCaptureIdScan';
import UiButton from '../../../ui/button/UiButton';
import { useStore } from '../../../../hooks/useStore';

function AccountVerificationDocumentCapture() {
    const isMobileFlow = new URLSearchParams(useLocation().search).get('isMobileFlow') === 'true';
    const { isModalVisible, setIsModalVisible, isDesktop, setIsQrOpen } = useContext(DocumentCaptureContext);
    const [kycSettings] = useStore(stores.kyc.settings);
    const verificationStatus = kycSettings.proofOfId;

    useEffect(() => {
        if (isMobileFlow && !isDesktop) {
            openModal();
        }
    }, []);

    function openModal() {
        setIsQrOpen(false);
        setIsModalVisible(true);
    }

    const documentUpload = useDocumentUpload({
        documentType: KycDocumentType.ID,
        status: verificationStatus as ProofOfIdStatus,
        declineReason:
            kycSettings.kycIdDeclineReason ||
            translate(
                'Your document did not pass the automatic ID check. Please try to upload a different document or contact our customer support team for further information about the decline reason.',
                'ui.kyc',
            ),
        approvedMessage: translate('Your ID document has been verified.', 'ui.kyc'),
        pendingMessage: translate(
            'Your ID document did not pass the automatic ID check and has been sent to manual review. We’ll update the status here and also shoot you an email once the manual review has been completed.',
            'ui.kyc',
        ),
    });

    if (!kycSettings.isKycCaptureEnabled) {
        return null;
    }

    return (
        <>
            <AccountVerificationBox
                data-test="proof-of-id"
                title={translate('proof-of-id', 'ui.account')}
                {...getBadgeProps(verificationStatus)}
            >
                {documentUpload.statusAlert}
                {verificationStatus &&
                    [ProofOfIdStatus.REQUESTED, ProofOfIdStatus.DECLINED].includes(
                        verificationStatus as ProofOfIdStatus,
                    ) && <UiButton onClick={openModal}>{translate('Verify identity', 'ui.kyc')}</UiButton>}
            </AccountVerificationBox>
            {isModalVisible && <AccountVerificationDocumentCaptureIdScan />}
        </>
    );
}

export default withProvider(DocumentCaptureProvider, AccountVerificationDocumentCapture);
