import React, { useEffect, useState } from 'react';
import { getRoute, isActiveRoute } from '../../../../services/router';
import { stores } from '../../../../stores';
import Wrapper from './styles';
import { translate } from '../../../../services/translate';
import { MATCH_STATUS } from '../../../../services/sports/constants';
import SportMatchMarkets from '../markets/SportMatchMarkets';
import {
    MarketTypeGroupIdAll,
    MarketTypeGroupIdBetbuilder,
    MarketTypeGroupIdPopular,
    useFoGroupFilters,
    useMatchSidebets,
} from '../../../../services/sports/sidebets';
import { CategoryMatch } from '../../../../services/sports/types';
import UiButton from '../../../ui/button/UiButton';
import { useSportsMatchContext } from '../../../../services/sports/match-helpers';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    match: CategoryMatch;
    matchStatus: string;
    stickyTop?: number;
}

export default function SportMatchOpen({ match, matchStatus, stickyTop }: Props) {
    const isLiveNow = matchStatus === MATCH_STATUS.LIVE;
    const [searchSelected] = useStore(stores.sports.searchMatchSelected);
    const { isBetbuilderActive } = useSportsMatchContext();

    const isBetbuilderEnabled = isFeatureAvailable(FEATURE.BETBUILDER) && !isLiveNow && match?.betbuilder_id;

    function getMarketTypeGroupToDisplay() {
        if (searchSelected?.id === match.id && searchSelected.market_type_group_ids?.length === 1) {
            return searchSelected.market_type_group_ids[0];
        }
        return match?.match_type
            ? MarketTypeGroupIdAll
            : isBetbuilderEnabled &&
              (isActiveRoute(getRoute('sport.betbuilder'), false) ||
                  isBetbuilderActive ||
                  new URLSearchParams(window.location.search).get('betbuilder'))
            ? MarketTypeGroupIdBetbuilder
            : MarketTypeGroupIdPopular;
    }

    const [currentMarketTypeGroup, setCurrentMarketTypeGroup] = useState(getMarketTypeGroupToDisplay());
    const { foGroups, markets } = useMatchSidebets(match.id, currentMarketTypeGroup, matchStatus) || {};
    const [isBetbuilderShown, setIsBetbuilderShown] = useState(false);

    const matchRoute = isLiveNow ? `${getRoute('sport.live')}/${match.id}` : `${getRoute('sport.match')}/${match.id}`;
    const isMatchPage = window.location.pathname.includes(matchRoute);
    const { setContext: setMatchContext } = useSportsMatchContext();

    const allMarketTypeGroupFilters = useFoGroupFilters(
        setCurrentMarketTypeGroup,
        foGroups,
        currentMarketTypeGroup,
        match.id,
        Boolean(isBetbuilderEnabled),
    );

    useEffect(() => {
        if (isMatchPage) {
            setMatchContext?.({ isOpen: true });
        }

        setIsBetbuilderShown(currentMarketTypeGroup === MarketTypeGroupIdBetbuilder);
    }, [currentMarketTypeGroup]);

    return (
        <Wrapper>
            <SportMatchMarkets
                marketsByMarketType={markets}
                match={match}
                allMarketTypeGroupFilters={allMarketTypeGroupFilters}
                setCurrentMarketTypeGroup={setCurrentMarketTypeGroup}
                currentMarketTypeGroup={currentMarketTypeGroup}
                stickyTop={stickyTop}
                isBetbuilderShown={isBetbuilderShown}
            />

            {!isMatchPage && !isBetbuilderShown && (
                <div className="more-odds-buttons">
                    <UiButton block url={matchRoute} size="small">
                        {translate('More odds', 'ui.sportsbook')}
                    </UiButton>
                </div>
            )}
        </Wrapper>
    );
}
