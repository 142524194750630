import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import CasinoSeo from '../../components/casino-seo/CasinoSeo';
import { loadCasinoGameForSeoById } from '../../microservices/casino';
import { loadCasino } from '../../services/casino/init';
import { useRouter } from '../../services/router';
import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import MainLayout from '../main/MainLayout';
import Wrapper from './styles';
import { useStore } from '../../hooks/useStore';
interface Props {
    children: JSX.Element;
}

export default function CasinoGameLayout({ children }: Props) {
    const [shouldReload] = useStore(stores.casino.shouldReload);
    const [isCasinoInitialized] = useStore<boolean>(stores.casino.isInitialized);
    const [isRgPanelVisible] = useStore(stores.responsibleGaming.isResponsibleGamingPanelVisible);
    const [isMaltaPanelVisible] = useStore(stores.responsibleGaming.isMaltaPanelVisible);
    const [isFullScreen] = useStore(stores.casino.isFullScreen);
    const [seo, setSeo] = useState<any>();
    const { gameSlug, providerId, gameType } = useRouter().params;

    useEffect(() => {
        if (shouldReload) {
            loadCasino();
        }
    }, [shouldReload]);

    useEffect(() => {
        if (isCasinoInitialized) {
            const fetchMetaData = async () => {
                const gameId = gameSlug?.split('-').pop();
                if (gameId) {
                    const gameInfo =
                        getStoreValue(stores.casino.gamesById)[gameId] || (await loadCasinoGameForSeoById(gameId));

                    const seoMetaData = CasinoSeo(gameInfo);
                    setSeo(seoMetaData);
                }
            };

            fetchMetaData();
        }
    }, [gameSlug, isCasinoInitialized, providerId, gameType]);

    return (
        <Wrapper
            isMaltaPanelVisible={isMaltaPanelVisible}
            className={classNames({ 'casino-game-layout-semi-full-screen': isFullScreen && isRgPanelVisible })}
        >
            {seo}
            <MainLayout>{children}</MainLayout>
        </Wrapper>
    );
}
