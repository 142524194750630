import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { stores } from '../../../../stores';
import { COUNTRY } from '../../../../services/country';
import classNames from 'classnames';
import { getUserCountry } from '../../../../services/user';
import { getLicence } from '../../../../services/licence';
import { getClient } from '../../../../services/environment';
import { Licence } from '@staycool/location';
import { ClientName } from '../../../../services/utils/types';
import { environment } from '../../../../stores/environment/environment';
import { useStore } from '../../../../hooks/useStore';

interface FooterPaymentProvider {
    name: string;
    externalLink: string;
    allowedClients?: typeof ClientName[keyof typeof ClientName][];
    allowedCountries?: COUNTRY[];
    allowedLicences: Licence[];
}

export default function CoolbetFooterPaymentPartners() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [{ IMAGES_HOST }] = useStore(environment);
    const client = getClient();
    const [filteredPaymentProviders, setFilteredPaymentProviders] = useState<FooterPaymentProvider[]>([]);
    const imagePath = `${IMAGES_HOST}${client.split('-', 1)[0]}/payments/providers/`;
    const paymentProviders: FooterPaymentProvider[] = [
        {
            name: 'MasterCard',
            externalLink: 'https://www.mastercard.us/',
            allowedLicences: [Licence.MALTA, Licence.ESTONIA, Licence.SWEDEN],
        },
        {
            name: 'MasterCard',
            externalLink: 'https://www.mastercard.com.mx/',
            allowedLicences: [Licence.MEXICO],
        },
        {
            name: 'VISA',
            externalLink: 'https://www.visaeurope.com/',
            allowedLicences: [Licence.MALTA, Licence.ESTONIA, Licence.SWEDEN],
        },
        {
            name: 'VISA',
            externalLink: 'https://www.visa.com.mx/',
            allowedLicences: [Licence.MEXICO],
        },
        {
            name: 'SPEI',
            externalLink: 'https://www.banxico.org.mx/',
            allowedLicences: [Licence.MEXICO],
        },
        {
            name: 'Codi',
            externalLink: 'https://www.banxico.org.mx/',
            allowedLicences: [Licence.MEXICO],
        },
        {
            name: 'Euteller',
            externalLink: 'https://www.euteller.com/',
            allowedCountries: [COUNTRY.FINLAND],
            allowedLicences: [Licence.MALTA],
        },
        {
            name: 'EcoPayz',
            externalLink: 'https://www.ecopayz.com/',
            allowedCountries: [COUNTRY.CANADA],
            allowedLicences: [Licence.MALTA],
        },
        {
            name: 'Neosurf',
            externalLink: 'https://www.neosurf.com/',
            allowedCountries: [COUNTRY.CANADA, COUNTRY.NORWAY],
            allowedLicences: [Licence.MALTA],
        },
        {
            name: 'Neteller',
            externalLink: 'https://www.neteller.com/',
            allowedCountries: [
                COUNTRY.CHILE,
                COUNTRY.ESTONIA,
                COUNTRY.FINLAND,
                COUNTRY.ICELAND,
                COUNTRY.PERU,
                COUNTRY.SWEDEN,
            ],
            allowedLicences: [Licence.MALTA, Licence.ESTONIA, Licence.SWEDEN],
        },
        {
            name: 'Skrill',
            externalLink: 'https://www.skrill.com/',
            allowedCountries: [
                COUNTRY.CHILE,
                COUNTRY.ESTONIA,
                COUNTRY.FINLAND,
                COUNTRY.ICELAND,
                COUNTRY.PERU,
                COUNTRY.SWEDEN,
            ],
            allowedLicences: [Licence.ESTONIA, Licence.MALTA, Licence.SWEDEN],
        },
        {
            name: 'Trustly',
            externalLink: 'https://trustly.com/',
            allowedCountries: [COUNTRY.ESTONIA, COUNTRY.FINLAND, COUNTRY.SWEDEN, COUNTRY.USA],
            allowedLicences: [Licence.ESTONIA, Licence.MALTA, Licence.SWEDEN, Licence.USA_NEVADA],
            allowedClients: [ClientName.COOLBET, ClientName.WYNNBET_NEVADA],
        },
        {
            name: 'Oxxo',
            externalLink: 'https://www.oxxo.com/',
            allowedLicences: [Licence.MEXICO],
        },
        {
            name: 'Paysafecard',
            externalLink: 'https://www.paysafecard.com',
            allowedCountries: [COUNTRY.FINLAND, COUNTRY.ICELAND, COUNTRY.PERU],
            allowedLicences: [Licence.MALTA],
        },
        {
            name: 'LHV',
            externalLink: 'https://www.lhv.ee/',
            allowedCountries: [COUNTRY.ESTONIA],
            allowedLicences: [Licence.ESTONIA],
        },
        {
            name: 'SEB',
            externalLink: 'https://www.seb.ee/',
            allowedCountries: [COUNTRY.ESTONIA],
            allowedLicences: [Licence.ESTONIA],
        },
        {
            name: 'Swedbank',
            externalLink: 'https://www.swedbank.ee/',
            allowedCountries: [COUNTRY.ESTONIA],
            allowedLicences: [Licence.ESTONIA],
        },
        {
            name: 'Interac',
            externalLink: 'https://www.interac.ca/en/interac-online-consumer.html',
            allowedCountries: [COUNTRY.CANADA],
            allowedLicences: [Licence.MALTA],
        },
        {
            name: '7Eleven',
            externalLink: 'https://7-eleven.com.mx/',
            allowedLicences: [Licence.MEXICO],
        },
        {
            name: 'AstroPay',
            externalLink: 'https://www.astropay.com/',
            allowedCountries: [COUNTRY.CHILE, COUNTRY.ECUADOR, COUNTRY.PERU, COUNTRY.NORWAY],
            allowedLicences: [Licence.MALTA],
        },
        {
            name: 'AstroPay',
            externalLink: 'https://astropay.com/es/home-latam/',
            allowedLicences: [Licence.MEXICO],
        },
        {
            name: 'Sightline',
            externalLink: 'https://sightlinepayments.com/',
            allowedCountries: [COUNTRY.USA],
            allowedLicences: [Licence.USA_NEVADA],
            allowedClients: [ClientName.STATION],
        },
        {
            name: 'PayNearMe',
            externalLink: 'https://home.paynearme.com/',
            allowedCountries: [COUNTRY.USA],
            allowedLicences: [Licence.USA_NEVADA],
            allowedClients: [ClientName.STATION],
        },
        {
            name: 'PavilionPay',
            externalLink: 'https://pavilionpayments.com/',
            allowedCountries: [COUNTRY.USA],
            allowedLicences: [Licence.USA_NEVADA],
            allowedClients: [ClientName.STATION],
        },
    ];

    useEffect(() => {
        setFilteredPaymentProviders(filterProvidersByCountry(paymentProviders));
    }, [isAuthenticated]);

    function filterProvidersByCountry(providers: FooterPaymentProvider[]) {
        return providers.filter(
            (provider) =>
                (!provider.allowedClients || provider.allowedClients.includes(client)) &&
                (!provider.allowedCountries || provider.allowedCountries.includes(getUserCountry())) &&
                provider.allowedLicences.includes(getLicence()),
        );
    }

    if (!filteredPaymentProviders.length) {
        return null;
    }

    return (
        <Wrapper className="footer-partners-payments">
            <div className="coolbet-footer-payment-partners-container">
                {filteredPaymentProviders.map((provider) => (
                    <a
                        className="payment-partner-link"
                        key={provider.name}
                        href={provider.externalLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            loading="lazy"
                            className={classNames('payment-partner-image', provider.name)}
                            srcSet={`${imagePath}${provider.name.toLowerCase()}.png?auto=compress&dpr=1 1x,
              ${imagePath}${provider.name.toLowerCase()}.png?auto=compress&dpr=2 2x,
              ${imagePath}${provider.name.toLowerCase()}.png?auto=compress&dpr=3 3x`}
                            src={`${imagePath}${provider.name.toLowerCase()}.png?auto=compress`}
                            alt={provider.name}
                        />
                    </a>
                ))}
            </div>
        </Wrapper>
    );
}
