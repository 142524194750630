import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

type StyledProps = {
    isBetslipOpen?: boolean;
};
export default styled.div<StyledProps>`
    font-family: ${({ theme }) => theme.typography.fontFamily};
    line-height: 1;

    ${({ isBetslipOpen }) =>
        isBetslipOpen &&
        css`
            @media ${mediaQuery.isLaptop} {
                padding-right: 230px;
            }
        `};
`;
