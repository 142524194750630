import React, { useEffect, useState } from 'react';
import { getRoute, isActiveRoute, isActiveRouteBySlug } from '../../../../../../services/router';
import { stores } from '../../../../../../stores';
import Svg from '../../../../../svg/Svg';
import UiPlaceholder from '../../../../../ui/placeholder/UiPlaceholder';
import { toggleMatchOrCategoryInFavorites } from '../../../../../../services/user-favorites';
import { translate } from '../../../../../../services/translate';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../../../../services/analytics';
import { PreMatchTreeChildren } from '../../../../../../microservices/sbgate';
import UiNavLink from '../../../../../ui/nav-link/UiNavLink';
import classNames from 'classnames';
import SportCategoryIcon from '../../../../category-icon/SportCategoryIcon';
import { useStore } from '../../../../../../hooks/useStore';

interface Props {
    category: PreMatchTreeChildren;
}

function SportTreePrematchAdvancedItem({ category }: Props) {
    const [isOpen, setIsOpen] = useState(Boolean(isActiveRouteBySlug(category.slug, category)));
    const [userFavorites] = useStore(stores.sports.userFavorites);
    const [isCategoryInFavorites, setIsCategoryInFavorites] = useState(false);

    useEffect(() => {
        if (isActiveRouteBySlug(category.slug, category)) {
            setIsOpen(true);
        }
    }, []);

    useEffect(() => {
        setIsCategoryInFavorites((userFavorites.categories || []).includes(category.id));
    }, [userFavorites]);

    function toggleCategories(category) {
        toggleMatchOrCategoryInFavorites({ category: category.id }, null);
    }

    return (
        <>
            <div
                className={classNames('sport-menu-item', {
                    expanded: isOpen,
                    active: isActiveRoute(`${getRoute('sport')}/${category.fullSlug}`, true),
                })}
            >
                <UiNavLink
                    exact
                    to={`${getRoute('sport')}/${category.fullSlug}`}
                    onClick={(event) => {
                        if (category.is_sports_wrapper) {
                            event.preventDefault();
                        }
                        setIsOpen(!isOpen);
                        stores.sports.isSportSideMenuOpen.set(false);
                        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.SPORT_MENU_ADVANCED_CLICKED);
                    }}
                    className="sport-menu-item-link"
                >
                    <span className="item-icon">
                        <SportCategoryIcon
                            small
                            icon={category.icon}
                            isRegion={category.depth === 3}
                            shouldForceRenderFlag={category.depth === 3}
                        />
                    </span>
                    <span className="item-text">{category.name || <UiPlaceholder />}</span>
                </UiNavLink>

                {(category.depth || 0) < 4 && (
                    <span
                        className={classNames('sport-menu-expand', { rotate: isOpen })}
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <Svg icon="arrow-round" />
                    </span>
                )}

                {category.depth >= 4 && (
                    <div className={classNames('favorite-icon', { selected: isCategoryInFavorites })}>
                        <Svg
                            icon="star"
                            size={1.1}
                            title={translate('Add to favorites', 'ui.sportsbook')}
                            onClick={() => toggleCategories(category)}
                        />
                    </div>
                )}
            </div>

            {isOpen && category.children && category.children.length > 0 && (
                <div className="sport-menu-expanded">
                    {category.children.map((child) => (
                        <SportTreePrematchAdvancedItem category={child} key={child.id} />
                    ))}
                </div>
            )}
        </>
    );
}
export default SportTreePrematchAdvancedItem;
