import React from 'react';
import { SPORT_ID } from '../../../../../services/sports/constants';
import { translate } from '../../../../../services/translate';
import { stores, useStoreWithSelector } from '../../../../../stores';
import Wrapper from './styles';
import classNames from 'classnames';
import SportMatchTimeLive from '../../time/live/SportMatchTimeLive';
import { FoCategoryMatch } from '@staycool/sbgate-types';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    match: FoCategoryMatch;
}

export default function SportMatchScoreboardInhouse({ match }: Props) {
    const [{ isPhone }] = useStore(media);
    const [inplayData] = useStoreWithSelector(stores.sports.inplayMatchData, (state) => state[match.id], [match.id]);
    const {
        home_team_points,
        away_team_points,
        custom,
        scoreboard: scoreboardObject,
        current_phase,
    } = inplayData || {};
    const scoreboard: any = // TODO: type
        Boolean(scoreboardObject && Object.values(scoreboardObject).length) && Object.values(scoreboardObject);
    const { players, messages, penaltyScoreboard = {} } = custom || {};
    const penaltyScores: any = // TODO: type
        Boolean(penaltyScoreboard.scores && Object.values(penaltyScoreboard.scores).length) &&
        Object.values(penaltyScoreboard.scores);

    const isOutright = match.match_type === 'OUTRIGHT';
    const homeTeam = isOutright ? '' : match.home_team_name;
    const awayTeam = isOutright ? '' : match.away_team_name;
    const backgroundUrl = `/assets/images/scoreboard-backgrounds/inhouse/liveconsole-${match.sport_category}.jpg`;
    if (!inplayData) {
        return null;
    }
    return (
        <Wrapper backgroundUrl={backgroundUrl}>
            <div className="background">
                {scoreboard && (
                    <div className="scoreboard">
                        <div className="box-label">{translate('Score center', 'ui.sportsbook')}</div>
                        {!isPhone && (
                            <div className="status">
                                <SportMatchTimeLive
                                    matchId={match.id}
                                    sportId={match.sport}
                                    isOurLivebet={match.livebet_our}
                                />
                            </div>
                        )}
                        <div className="scoreboard-box">
                            <div className="round">
                                <div className="round-name">
                                    {translate(`round-name-${match.sport}`, 'ui.sportsbook.lb')}
                                </div>
                                {scoreboard.map((round, index: number) => (
                                    <div
                                        className={classNames('round-number', {
                                            active: parseInt(current_phase) === index,
                                        })}
                                        key={index}
                                    >
                                        {round.name || index + 1}
                                    </div>
                                ))}
                                {match.sport !== SPORT_ID.SNOOKER && <div className="round-total">Total</div>}
                            </div>
                            <div className="team">
                                <div className="team-name">{homeTeam}</div>
                                {scoreboard.map((round, index) => (
                                    <div className="set-points" key={index}>
                                        {round.home || 0}
                                    </div>
                                ))}
                                {match.sport !== SPORT_ID.SNOOKER && (
                                    <div className="set-points points-total">{home_team_points}</div>
                                )}
                            </div>
                            <div className="team">
                                <div className="team-name">{awayTeam}</div>
                                {scoreboard.map((round, index) => (
                                    <div className="set-points" key={index}>
                                        {round.away || 0}
                                    </div>
                                ))}
                                {match.sport !== SPORT_ID.SNOOKER && (
                                    <div className="set-points points-total">{away_team_points}</div>
                                )}
                            </div>
                            {penaltyScores && (
                                <div className="penalty-scoreboard">
                                    <div className="team">
                                        <div className="team-name round">Penalty Shootout</div>
                                        <div className="team-name">{homeTeam}</div>
                                        <div className="team-name">{awayTeam}</div>
                                    </div>
                                    {penaltyScores.map((score) => (
                                        <div className="team scores" key={score.name}>
                                            <div className="set-points">{score.name}</div>
                                            <div className="set-points">{score.home}</div>
                                            <div className="set-points">{score.away}</div>
                                        </div>
                                    ))}
                                    <div className="team scores">
                                        <div className="round-total">Total</div>
                                        <div className="set-points points-total">
                                            {penaltyScoreboard.totalScores.homeTeam}
                                        </div>
                                        <div className="set-points points-total">
                                            {penaltyScoreboard.totalScores.awayTeam}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div className="messages-and-winners">
                    {players && players.length > 0 && (
                        <div className="leaderboard">
                            <div className="box-label">{translate('Winners feed', 'ui.sportsbook')}</div>
                            {players.map((player, index: number) => (
                                <div key={index} className="leaderboard-position">
                                    <span className="position-number">{index + 1}</span>
                                    <span className="player-name"> {player.name}</span>
                                </div>
                            ))}
                        </div>
                    )}

                    <div className="messages">
                        <div className="box-label">{translate('Messages', 'ui.sportsbook')}</div>
                        <div className="messages-list">
                            {messages &&
                                messages.map((message) => (
                                    <div className="message" key={message.id}>
                                        {message.message}
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
