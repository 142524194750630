import React, { useEffect, useMemo, useRef, useState } from 'react';
import Wrapper from './styles';
import { getRoute, useRouter } from '../../../services/router';
import UiNavLink from '../../ui/nav-link/UiNavLink';
import CasinoThumbnailLabels from './labels/CasinoThumbnailLabels';
import { stores } from '../../../stores';
import CasinoThumbnailDisabled from './disabled/CasinoThumbnailDisabled';
import { useIsTestUser } from '../../../services/user';
import {
    CasinoGame,
    CasinoGameFull,
    CasinoGameGameType,
    CasinoGameType,
    GameLogoType,
    getCasinoGameBackgroundUrl,
    getCasinoGameSlug,
    getGameImageUrl,
    hasCasinoGamePlayForFun,
    INFINITE_SEATS_FEATURE,
    isCasinoGameDisabled,
    LAST_WINNINGS_MAX_SIZE,
    navigateToCasinoGamePage,
} from '../../../services/casino/games';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../services/analytics';
import CasinoGameInfoModal from '../game/info/modal/CasinoGameInfoModal';
import CasinoThumbnailLabelsRtp from './labels/rtp/CasinoThumbnailLabelsRtp';
import { getCasinoGameFull } from '../../../microservices/casino';
import { gameHasRtpVariant } from '../../../services/rtp-calculator';
import Svg from '../../svg/Svg';
import classNames from 'classnames';
import { CasinoCategory, CasinoCategoryCode } from '../../../services/casino/categories';
import UiRatioBox from '../../ui/ratio-box/UiRatioBox';
import { FEATURE, isFeatureAvailable } from '../../../services/feature';
import CasinoThumbnailSeats from './seats/CasinoThumbnailSeats';
import CasinoThumbnailLatestWinnings from './latest-winnings/CasinoThumbnailLatestWinnings';
import { translate } from '../../../services/translate';
import { GameLaunchPosition, GameLaunchSource } from '../../../services/casino/types';
import isEmpty from 'lodash/isEmpty';
import CasinoThumbnailLabelsVolatility from './labels/volatility/CasinoThumbnailLabelsVolatility';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';
import { useInViewport } from 'react-in-viewport';

interface Props {
    game: CasinoGame;
    hiddenGameLabels?: number[];
    isFeatured?: boolean;
    categoryCode?: CasinoCategoryCode;
    onGameClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, game: CasinoGame) => void;
    handleSearchFocus?: () => void;
    isSearchResult?: boolean;
    isContentFeatured?: boolean;
    isPaybackBoosterExpired?: boolean;
    isRTPCalculator?: boolean;
    isCategoryView?: boolean;
    isFooterSearch?: boolean;
    thumbnailLocation?: GameLaunchSource;
    position?: number;
    size?: number;
    isFooterVisible?: boolean;
    isHeaderVisible?: boolean;
}

export default function CasinoThumbnail({
    game,
    hiddenGameLabels,
    isFeatured,
    categoryCode,
    onGameClick,
    handleSearchFocus,
    isSearchResult,
    isContentFeatured,
    isPaybackBoosterExpired,
    isRTPCalculator,
    isCategoryView,
    isFooterSearch,
    thumbnailLocation,
    position,
    size,
    isFooterVisible = true,
    isHeaderVisible = true,
}: Props) {
    const loadMoreRef = useRef(null);
    const { inViewport } = useInViewport(loadMoreRef);
    const [{ isDesktop, isLaptop, isPhone, isTablet }] = useStore(media);
    const fullImageUrl = getGameImageUrl(game?.imageName, getImageSize());
    const backgroundImageUrl = game?.backgroundImageName && getCasinoGameBackgroundUrl(game.backgroundImageName);
    const [isGameInfoModalOpen, setIsGameInfoModalOpen] = useState(false);
    const [fullGame, setFullGame] = useState<CasinoGameFull>();
    const [liveImageUrl, setLiveImageUrl] = useState('');
    const [lobbySettings] = useStore(stores.casino.lobbySettings);
    const [gamesPlayedCountsByGameId] = useStore(stores.casino.gamesPlayedCountsByGameId);
    const [blockedProviders] = useStore(stores.casino.maintenance);
    const [hasActiveBonus] = useStore(stores.casino.hasActiveBonus);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [features] = useStore(stores.casino.features);
    const [isInfiniteSeatsGame, setIsInfiniteSeatsGame] = useState(false);
    const isTestUser = useIsTestUser();
    const [closedGames] = useStore(stores.casino.closedGames);
    const { navigateTo } = useRouter();
    const [programGames] = useStore(stores.paybackBooster.programGames);
    const [playForReal] = useStore(stores.casino.playForReal);
    const [activeGame] = useStore(stores.casino.activeGame);
    const [isGamePageDrawerOpen, setIsGamePageDrawerOpen] = useStore(stores.casino.isGamePageDrawerOpen);
    const isDisabled = useMemo(
        () => isCasinoGameDisabled(game) || closedGames[game.id],
        [game, closedGames, blockedProviders, hasActiveBonus],
    );
    const [gamesMetaDataById] = useStore(stores.casino.gamesMetaDataById);
    const [dealerName, setDealerName] = useState(gamesMetaDataById[game.id]?.dealerName || '');
    const [latestWinnings, setLatestWinnings] = useState<string[]>([]);
    const isLiveCasinoGame = game.gameType === CasinoGameGameType.LIVE;
    const isNumberOfPlayersShown = !isLiveCasinoGame || game.type === CasinoGameType.GAME_SHOW;
    const isPaybackBooster = categoryCode === CasinoCategoryCode.PAYBACK_BOOSTER;
    const isRecentlyPlayed = categoryCode === CasinoCategoryCode.RECENTLY_PLAYED;
    const extraPayback = programGames[0]?.cashbackSettings?.extraPayback;
    const [categories] = useStore<CasinoCategory[]>(stores.casino.categories);
    const [shouldShowBetBehind, setShouldShowBetBehind] = useState(false);
    const shouldDisplayThumbnailGameFooter =
        isFooterVisible && !isRecentlyPlayed && !isRTPCalculator && (!isPaybackBooster || isPaybackBoosterExpired);
    const providerLogo = game.gameLogos?.find((gameLogo) => gameLogo.type === GameLogoType.PROVIDER);
    const promotionLogo = game.gameLogos?.find((gameLogo) => gameLogo.type === GameLogoType.PROMOTION);
    const providerLogoSrc = providerLogo && getGameImageUrl(providerLogo.imageName);
    const promotionLogoSrc = promotionLogo && getGameImageUrl(promotionLogo.imageName);

    useEffect(() => {
        setLatestWinnings(getLatestWinnings(game));
    }, [game, gamesMetaDataById]);

    useEffect(() => {
        if (gamesMetaDataById[game.id]) {
            setDealerName(gamesMetaDataById[game.id].dealerName || '');
        }
    }, [game.id, gamesMetaDataById]);

    useEffect(() => {
        const infiniteFeature = features.find((feature) => feature.name === INFINITE_SEATS_FEATURE);
        setIsInfiniteSeatsGame(Boolean(infiniteFeature && game?.properties.includes(infiniteFeature.id)));
    }, [game, features]);

    useEffect(() => {
        const gameMetaData = gamesMetaDataById[game.id];
        if (game.gameType !== CasinoGameGameType.LIVE) {
            return;
        }

        if (
            isLiveCasinoGame &&
            game.type === CasinoGameType.BLACKJACK &&
            gameMetaData?.seatsTaken?.length &&
            gameMetaData?.seatedPlayers !== undefined &&
            gameMetaData?.availableSeats !== undefined
        ) {
            const totalSeats = gameMetaData.seatedPlayers + gameMetaData.availableSeats;
            if (totalSeats) {
                const isAllSeatsTaken = gameMetaData.seatsTaken.length >= totalSeats;
                setShouldShowBetBehind(gameMetaData.betBehind && isAllSeatsTaken);
            }
        }
        if (gameMetaData?.imageUrl) {
            setLiveImageUrl(gameMetaData.imageUrl);
        }
    }, [game, gamesMetaDataById[game.id]]);

    function getImageSize() {
        if (size) {
            return size;
        }
        if (isRecentlyPlayed) {
            return 95;
        }
        if (isSearchResult || isPhone) {
            return 130;
        }
        if (isFeatured && !isPhone) {
            return 460;
        }
        return 190;
    }

    function loadFullGame() {
        if (!fullGame && game) {
            getCasinoGameFull(game.id).then((fullGame) => setFullGame(fullGame));
        }
    }

    function setGameLaunchPosition() {
        let source: GameLaunchSource = categoryCode ?? 'UNDEFINED';
        let categoryLobbyPosition: number | undefined;

        if (isFeatured) {
            source = 'BIG_TILE';
            position = undefined;
        }
        if (isSearchResult) {
            source = `${isFooterSearch ? 'INGAME' : 'LOBBY'}_SEARCH` as const;
        }
        if (isRTPCalculator) {
            source = 'RTP_CALCULATOR';
        }
        if (thumbnailLocation) {
            source = thumbnailLocation;
        }
        if (categoryCode && !isContentFeatured && !isRecentlyPlayed && !isCategoryView) {
            const categoryType = categories.find((category) => category.code === categoryCode)?.type;
            categoryLobbyPosition =
                categories
                    .filter((category) => category.type === categoryType)
                    .findIndex((category) => category.code === categoryCode) + 1;
        }
        if ((position || position === 0) && !isContentFeatured) {
            position++;
        }
        const gameLaunchPosition: GameLaunchPosition = {
            launchSource: source,
            categoryLobbyPosition,
            gameRowPosition: position,
        };
        stores.casino.latestGameLaunchPosition.set(gameLaunchPosition);
    }

    function openGame(event) {
        if (handleSearchFocus) {
            handleSearchFocus();
        }

        if (isGamePageDrawerOpen) {
            setIsGamePageDrawerOpen(false);
        }

        setGameLaunchPosition();
        if (isDisabled && !isTestUser) {
            event.preventDefault();
            return;
        }

        if (isPhone && isAuthenticated) {
            stores.casino.isGamePageDrawerOpen.set(false);
        }

        if (isFeatured) {
            trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.CASINO_FEATURED_GAME_CLICKED);
        }
        if (isAuthenticated) {
            event.preventDefault();
            navigateToCasinoGamePage(navigateTo, game);
            return;
        }

        if (game && !hasCasinoGamePlayForFun(game) && !isAuthenticated) {
            event.preventDefault();
            stores.modals.isLoginModalOpen.set(true);
            return;
        }

        if (isPhone && !isAuthenticated && !playForReal && !activeGame) {
            event.preventDefault();
            setIsGameInfoModalOpen(true);
            return;
        }

        if ((isDesktop || isLaptop) && onGameClick) {
            onGameClick(event, game);
            return;
        }

        event.preventDefault();
        navigateToCasinoGamePage(navigateTo, game);
    }

    const getTakenSeats = (game: CasinoGame) => {
        return gamesMetaDataById[game.id]?.seatsTaken;
    };
    const getLatestWinnings = (game: CasinoGame): string[] => {
        if (
            game.type &&
            !([CasinoGameType.ROULETTE, CasinoGameType.BACCARAT, CasinoGameType.GAME_SHOW] as string[]).includes(
                game.type,
            )
        ) {
            return [];
        }
        return (gamesMetaDataById?.[game?.id]?.results ?? []).slice(0, LAST_WINNINGS_MAX_SIZE);
    };

    return (
        <Wrapper
            ref={loadMoreRef}
            className={classNames('casino-game-thumbnail', {
                disabled: isCasinoGameDisabled(game),
                isTestUser,
            })}
            isMobileDevice={isPhone}
            isFeatured={isFeatured}
            isPaybackBooster={isPaybackBooster}
            isPaybackBoosterExpired={isPaybackBoosterExpired}
        >
            <UiNavLink to={`${getRoute('casino-game')}/${getCasinoGameSlug(game.name, game.id)}`} onClick={openGame}>
                <div
                    style={{
                        width: isHeaderVisible && !isFeatured && !isCategoryView ? `${getImageSize()}px` : '100%',
                    }}
                    className={classNames(
                        'casino-thumbnail-game-container',
                        isFeatured && 'featured',
                        isRecentlyPlayed && 'small-game-container',
                    )}
                >
                    {!isRecentlyPlayed && !isRTPCalculator && isHeaderVisible && (
                        <div className="casino-thumbnail-game-title">{game.name}</div>
                    )}

                    <div className="casino-thumbnail-game-body">
                        <UiRatioBox
                            key={game.id}
                            boxHorizontalSide={1}
                            boxVerticalSide={1}
                            deactivate={!isCategoryView}
                        >
                            <div
                                className="casino-thumbnail-game-image-container"
                                style={{
                                    backgroundImage:
                                        isFeatured && isPhone ? `url(${backgroundImageUrl}&w=400&dpr=1)` : 'none',
                                    backgroundSize: 'cover',
                                }}
                            >
                                <div
                                    className={classNames('casino-thumbnail-game-image', { disabled: isDisabled })}
                                    style={{
                                        backgroundImage: inViewport ? `url(${fullImageUrl})` : 'none',
                                        width:
                                            (!isCategoryView && !isContentFeatured && !isRTPCalculator) ||
                                            (isPhone && isFeatured)
                                                ? getImageSize()
                                                : '100%',
                                        height:
                                            (!isCategoryView && !isContentFeatured && !isRTPCalculator) ||
                                            (isPhone && isFeatured)
                                                ? getImageSize()
                                                : '100%',
                                        margin: isPhone && isFeatured ? 'var(--spacing-12) 0' : '0',
                                    }}
                                />
                                {!isEmpty(liveImageUrl) && (
                                    <div
                                        className="casino-thumbnail-live-game-image"
                                        style={{
                                            backgroundImage: `url(${liveImageUrl})`,
                                        }}
                                    />
                                )}
                                {isDisabled && (
                                    <CasinoThumbnailDisabled gameLauncher={game} isRecentlyPlayed={isRecentlyPlayed} />
                                )}
                                {promotionLogo && !isRecentlyPlayed && (
                                    <img
                                        className={classNames('casino-thumbnail-game-image-logo-promotion', {
                                            disabled: isDisabled,
                                        })}
                                        src={promotionLogoSrc}
                                        alt=""
                                    />
                                )}
                                {providerLogo && !isRecentlyPlayed && (
                                    <img
                                        className="casino-thumbnail-game-image-logo-provider"
                                        src={providerLogoSrc}
                                        alt=""
                                    />
                                )}

                                {!isFeatured && (
                                    <>
                                        <CasinoThumbnailLatestWinnings
                                            latestWinnings={latestWinnings}
                                            gameType={game.type}
                                            gameId={game.id}
                                            gameProperties={game.properties}
                                        />
                                        {game?.type === CasinoGameType.BLACKJACK && !isInfiniteSeatsGame && (
                                            <CasinoThumbnailSeats
                                                seatsTaken={getTakenSeats(game)}
                                                seatedPlayers={gamesMetaDataById[game.id]?.seatedPlayers}
                                                availableSeats={gamesMetaDataById[game.id]?.availableSeats}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </UiRatioBox>
                        {shouldDisplayThumbnailGameFooter && (
                            <div className="casino-thumbnail-game-footer">
                                {!shouldShowBetBehind && (
                                    <>
                                        {!lobbySettings.volatility && (
                                            <div
                                                className={classNames(
                                                    'casino-thumbnail-game-users-played',
                                                    !gamesPlayedCountsByGameId[game.id]?.playCount && 'hide',
                                                )}
                                            >
                                                {isNumberOfPlayersShown && (
                                                    <>
                                                        <Svg icon="profile-icon" size={0.875} />
                                                        {gamesPlayedCountsByGameId[game.id]?.playCount}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                        <div>
                                            {!isLiveCasinoGame &&
                                                lobbySettings.volatility &&
                                                game.volatility &&
                                                isFeatureAvailable(FEATURE.VOLATILITY_THUMBNAIL) && (
                                                    <CasinoThumbnailLabelsVolatility volatility={game.volatility} />
                                                )}
                                        </div>
                                    </>
                                )}
                                {!isSearchResult && !isPhone && !isTablet && (
                                    <div
                                        className="casino-thumbnail-game-info-icon"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            setIsGameInfoModalOpen(true);
                                        }}
                                    >
                                        {isFeatureAvailable(FEATURE.GAME_INFORMATION) && (
                                            <Svg icon="info-2" size={1.25} />
                                        )}
                                    </div>
                                )}

                                {!isRecentlyPlayed &&
                                    game.gameType !== CasinoGameGameType.LIVE &&
                                    isFeatureAvailable(FEATURE.RTP_THUMBNAIL) && (
                                        <CasinoThumbnailLabelsRtp
                                            game={game}
                                            loadFullGame={loadFullGame}
                                            fullGame={fullGame}
                                            hasRtpVariant={gameHasRtpVariant(game)}
                                            paybackEnabled={Boolean(lobbySettings.payback && game.rtp)}
                                            isSearchResult={isSearchResult}
                                        />
                                    )}

                                {shouldShowBetBehind && (
                                    <div className="casino-thumbnail-bet-behind">
                                        {translate(['Bet Behind', 'BET BEHIND'], 'casino.live-lobby')}
                                    </div>
                                )}

                                {!isRecentlyPlayed && game.gameType === CasinoGameGameType.LIVE && (
                                    <div className="casino-thumbnail-game-dealer">{dealerName || ''}</div>
                                )}
                            </div>
                        )}

                        {isPaybackBooster && !isPaybackBoosterExpired && extraPayback && game.serverGameId && (
                            <div className="casino-thumbnail-game-footer booster">
                                {translate('+%1% / spin', 'casino.payback-booster', [
                                    extraPayback[game.serverGameId]?.toFixed(2) || 0,
                                ])}
                            </div>
                        )}
                        {!isDisabled && (
                            <CasinoThumbnailLabels
                                hiddenGameLabels={hiddenGameLabels}
                                game={game}
                                hideAllLabels={isRecentlyPlayed}
                                isSearchResult={isSearchResult}
                            />
                        )}
                    </div>
                </div>
            </UiNavLink>
            {isFeatureAvailable(FEATURE.GAME_INFORMATION) && (
                <CasinoGameInfoModal
                    isOpen={isGameInfoModalOpen}
                    game={game}
                    onClose={() => setIsGameInfoModalOpen(false)}
                    onGameClick={(event, game) => {
                        stores.casino.latestGameLaunchPosition.set({ launchSource: 'GAME_INFO_MODAL' });
                        if (onGameClick) {
                            onGameClick(event, game);
                        }
                    }}
                />
            )}
        </Wrapper>
    );
}
