import React, { useEffect } from 'react';
import { UiKeyboard } from '../../ui/keyboard/UiKeyboard';
import Wrapper from './styles';
import { clearActiveElementFocus } from '../../../services/util';
import { useRouter } from '../../../services/router';
import { useComponentFocused } from '../../../services/hooks';
import { retail } from '../../../stores/retail/retail';
import { useStore } from '../../../hooks/useStore';

type RetailKeyboardLayout = 'loyalty' | 'search' | 'betslip' | 'numpad' | 'modal';

interface Props {
    layout?: RetailKeyboardLayout;
    isVisible?: boolean;
    style?: React.CSSProperties;
    setValue?: CallableFunction;
}

export default function RetailKeyboard({
    isVisible: keyboardIsVisible,
    layout: keyboardLayout,
    style,
    setValue: handleInput,
}: Props) {
    const { location } = useRouter();
    const [elementRef, isElementFocused, setIsElementFocused] = (useComponentFocused as any)(false, 'touchstart');
    const [, setValue] = useStore(retail.retailKeyboard.value);
    const [, setLastKeyPress] = useStore(retail.retailKeyboard.lastKeyPress);
    const [layout, setLayout] = useStore(retail.retailKeyboard.layout);
    const [isVisible, setIsVisible] = useStore(retail.retailKeyboard.isVisible);
    const [position] = useStore(retail.retailKeyboard.position);
    const [isIgnoreLossFocus] = useStore(retail.retailKeyboard.isIgnoreLossFocus);
    const defaultLayouts = {
        default: {
            default: [
                '1 2 3 4 5 6 7 8 9 0',
                'Q W E R T Y U I O P',
                'A S D F G H J K L',
                'Z X C V B N M {bksp}',
                '{space} {enter}',
            ],
        },
        numeric: {
            default: ['1 2 3', '4 5 6', '7 8 9', '0 {bksp}', '{enter}'],
        },
        numpad: {
            default: ['1 2 3', '4 5 6', '7 8 9', '. 0 {bksp}', '{enter}'],
        },
        modal: {
            default: ['1 2 3', '4 5 6', '7 8 9', '0 {bksp}'],
        },
    };
    const layouts = {
        loyalty: defaultLayouts.default,
        search: defaultLayouts.default,
        betslip: defaultLayouts.numeric,
        numpad: defaultLayouts.numpad,
        modal: defaultLayouts.modal,
    };
    const display = {
        '{bksp}': layout === 'numpad' ? '⌫' : '⌫ delete',
        '{enter}': 'enter ↵',
        '{space}': 'space',
    };

    useEffect(() => {
        if (isVisible) {
            setIsVisible(false);
        }
        if (keyboardLayout) {
            setLayout(keyboardLayout);
        }
    }, [location]);

    useEffect(() => {
        const onScroll = () => handleBlur();
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [position]);

    useEffect(() => {
        if (!isElementFocused) {
            handleBlur();
        }
    }, [isElementFocused]);

    useEffect(() => {
        setIsElementFocused(isVisible);
    }, [isVisible]);

    if (!isVisible && !keyboardIsVisible) {
        return <></>;
    }

    function handleBlur() {
        if (isIgnoreLossFocus) {
            return;
        }
        clearActiveElementFocus();
        setIsVisible(false);
    }

    return (
        <Wrapper $position={layout !== 'modal' ? position : undefined}>
            <div ref={elementRef} onBlur={handleBlur} className={`layout-${layout}`} style={style}>
                <UiKeyboard
                    setValue={handleInput || setValue}
                    width="100%"
                    layout={layouts[layout]}
                    display={display}
                    onEnter={handleBlur}
                    onKeyPress={setLastKeyPress}
                />
            </div>
        </Wrapper>
    );
}
