import { useState, useEffect } from 'react';

interface Props {
    channelName: string;
}

interface Data {
    channel: string;
    message: string;
    time: number;
}

interface State {
    lastMessage: string | undefined;
    messages: string[];
}

const initialState = {
    lastMessage: undefined,
    messages: [],
};

export function useBrowserCommunication({ channelName }: Props) {
    const [channelState, setChannelState] = useState<State>(initialState);

    useEffect(() => {
        window.addEventListener('message', onMessageReceived, false);
        return () => {
            window.removeEventListener('message', onMessageReceived, false);
        };
    }, [channelName]);

    function onMessageReceived(event: MessageEvent) {
        if (
            event.data &&
            event.data.message &&
            event.data.time &&
            event.data.channel &&
            event.data.channel === channelName
        ) {
            const decodedMessage: Data = event.data;
            updateState(decodedMessage);
        }
    }

    function postMessage(message: any) {
        if (message) {
            const msg = {
                channel: channelName,
                message,
                time: Date.now(),
            };
            window.postMessage(msg, '*');
        }
    }

    function updateState(data: Data) {
        setChannelState((prevState: State) => {
            return {
                lastMessage: data.message,
                messages: prevState.messages.concat(data.message),
            };
        });
    }

    return { channelState, postMessage };
}
