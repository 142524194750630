import { DependencyList, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { validateUserCountry } from '../microservices/sbgate';
import { stores } from '../stores';
import { useStore } from '../hooks/useStore';

export function useClock(clockIntervalInSeconds = 1) {
    const [secondsSinceClockStart, setSecondsSinceClockStart] = useState(0);
    const [isClockRunning, setIsClockRunning] = useState(true);

    const intervalMilliseconds = isClockRunning ? clockIntervalInSeconds * 1000 : 0;

    useInterval(
        () => {
            setSecondsSinceClockStart((secondsSinceClockStart) => secondsSinceClockStart + clockIntervalInSeconds);
        },
        intervalMilliseconds,
        [intervalMilliseconds],
    );

    return [
        secondsSinceClockStart,
        {
            pauseClock: () => setIsClockRunning(false),
            resumeClock: () => setIsClockRunning(true),
            resetClock: () => setSecondsSinceClockStart(0),
            isClockRunning,
        },
    ] as [number, { pauseClock: () => void; resumeClock: () => void; resetClock: () => void; isClockRunning: boolean }];
}

export function useInterval(callback: () => void, intervalInMilliseconds: number, watchProperties: any[] = []) {
    const callbackReference = useRef(callback);
    callbackReference.current = callback;

    useEffect(() => {
        if (!intervalInMilliseconds) {
            return;
        }

        const reference = setInterval(() => callbackReference.current(), intervalInMilliseconds);

        return () => clearInterval(reference);
    }, watchProperties);
}

export function useCountdown(
    callback: () => void,
    onCountdownFinished: () => void,
    intervalInMilliseconds: number,
    countdownTimes: number,
    watchProperties: any[] = [],
    conditionToStart: () => boolean = () => true,
) {
    const callbackReference = useRef(callback);
    callbackReference.current = callback;
    const intervalReference = useRef<NodeJS.Timeout>();
    const [countdown, setCountdown] = useState(countdownTimes);

    useEffect(() => {
        if (!intervalInMilliseconds || !conditionToStart()) {
            return;
        }

        intervalReference.current = setInterval(() => {
            setCountdown((prevValue) => prevValue - 1);
            callbackReference.current();
        }, intervalInMilliseconds);

        return () => {
            if (intervalReference) {
                clearInterval(intervalReference.current as NodeJS.Timeout);
            }
        };
    }, watchProperties);

    useEffect(() => {
        if (countdown === 0) {
            clearInterval(intervalReference.current as NodeJS.Timeout);
            onCountdownFinished();
        }
    }, [countdown]);
}

export function useTimeout(callback: () => void, timeoutInMilliseconds?: number, watchProperties: any[] = []) {
    const callbackReference = useRef(callback);
    callbackReference.current = callback;

    useEffect(() => {
        if (!timeoutInMilliseconds) {
            return;
        }

        const timeoutReference = setTimeout(() => callbackReference.current(), timeoutInMilliseconds);

        return () => {
            if (timeoutReference) {
                clearTimeout(timeoutReference);
            }
        };
    }, watchProperties);
}

export function useImmediate(callback: () => void, watchProperties: any[] = []) {
    useEffect(() => {
        const immediateReference = setImmediate(callback);

        return () => {
            if (immediateReference) {
                clearImmediate(immediateReference);
            }
        };
    }, watchProperties);
}

// eslint-disable-next-line import/no-unused-modules
export function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

export function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

function useDebouncedEvent(target, options, callback) {
    const { eventName, delayInMs = 100, isTrailing = true } = options;

    useEffect(() => {
        if (!target) {
            return;
        }

        let isActiveTimeout;

        const eventHandler = () => {
            clearTimeout(isActiveTimeout);

            if (!isActiveTimeout && !isTrailing) {
                callback();
            }

            isActiveTimeout = setTimeout(() => {
                if (isTrailing) {
                    callback();
                }

                isActiveTimeout = undefined;
            }, delayInMs);
        };

        target.addEventListener(eventName, eventHandler);

        return () => {
            clearTimeout(isActiveTimeout);
            target.removeEventListener(eventName, eventHandler);
        };
    }, [target]);
}

// eslint-disable-next-line import/no-unused-modules
export function useScrollState() {
    const [isScrolling, setIsScrolling] = useState(false);

    useDebouncedEvent(
        window,
        {
            eventName: 'scroll',
            isTrailing: false,
        },
        () => {
            // Triggers only once when scrolling is started
            setIsScrolling(true);
        },
    );

    useDebouncedEvent(
        window,
        {
            eventName: 'scroll',
            isTrailing: true,
        },
        () => {
            // Triggers only once when scrolling is ended
            setIsScrolling(false);
        },
    );

    return [isScrolling];
}

// eslint-disable-next-line import/no-unused-modules
export function useHoverState() {
    const [isHoverAllowed, setIsHoverAllowed] = useState(true);
    const [isScrolling] = useScrollState();

    useEffect(() => {
        const handleMouseMove = () => {
            if (!isScrolling && !isHoverAllowed) {
                setIsHoverAllowed(true);
            }
        };
        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [isScrolling]);

    useEffect(() => {
        if (isScrolling) {
            setIsHoverAllowed(false);
        }
    }, [isScrolling]);

    return [isHoverAllowed];
}

export function useHover(isDisabled = false, mouseLeaveDelayMS = 1000) {
    const [isHovering, setIsHovering] = useState(false);
    let mouseOutTimer;
    return {
        isHovering,
        hoverProps: {
            onMouseEnter: () => {
                if (!isDisabled) {
                    clearTimeout(mouseOutTimer);
                    setIsHovering(true);
                }
            },
            onMouseLeave: () => {
                if (!isDisabled) {
                    mouseOutTimer = setTimeout(() => setIsHovering(false), mouseLeaveDelayMS);
                }
            },
        },
    };
}

export function useComponentFocused(initialIsFocused) {
    const [isComponentFocused, setIsComponentFocused] = useState(initialIsFocused);
    // figure out what to put here instead of any
    const ref = useRef<any>(null);
    const filterElementRef = useRef(null);

    const handleClickOutside = (event) => {
        const isFiltered = filterElementRef.current && (filterElementRef.current as any).contains(event.target);
        if (ref.current && !ref.current.contains(event.target) && !isFiltered) {
            setIsComponentFocused(false);
        } else {
            setIsComponentFocused(true);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, []);

    return [ref, isComponentFocused, setIsComponentFocused, filterElementRef];
}

export function useHeaderElementsByContent(domElements) {
    useEffect(() => {
        domElements.forEach((element) => {
            document.head.appendChild(element);
        });
        return () =>
            domElements.forEach((element) => {
                document.head.removeChild(element);
            });
    }, [domElements]);
}

export function useSecondaryEffect<D extends DependencyList>(
    callback: () => any,
    dependencies: D,
    guardFunction: (...deps: D) => boolean = () => true,
) {
    const initialRender = useRef(true);
    useEffect(() => {
        if (initialRender.current && guardFunction(...dependencies)) {
            initialRender.current = false;
            return;
        }

        return callback();
    }, dependencies);
}

export default function useLockedBody(initialLocked = false): [boolean, (locked: boolean) => void] {
    const [locked, setLocked] = useState(initialLocked);

    useLayoutEffect(() => {
        if (!locked) {
            return;
        }

        const originalOverflow = document.body.style.overflow;
        const originalPaddingRight = document.body.style.paddingRight;

        document.body.style.overflow = 'hidden';

        const root = document.getElementById('___gatsby');
        const scrollBarWidth = root ? root.offsetWidth - root.scrollWidth : 0;

        if (scrollBarWidth) {
            document.body.style.paddingRight = `${scrollBarWidth}px`;
        }

        return () => {
            document.body.style.overflow = originalOverflow;

            if (scrollBarWidth) {
                document.body.style.paddingRight = originalPaddingRight;
            }
        };
    }, [locked]);

    useEffect(() => {
        if (locked !== initialLocked) {
            setLocked(initialLocked);
        }
    }, [initialLocked]);

    return [locked, setLocked];
}

export function useSportsbookCountryBlock() {
    const [ipCountry] = useStore(stores.ipCountry);
    const [isCountryBlocked, setIsCountryBlocked] = useState(false);
    useEffect(() => {
        validateUserCountry(ipCountry).then(setIsCountryBlocked);
    }, [ipCountry]);
    return isCountryBlocked;
}
