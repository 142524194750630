import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import Svg from '../../../svg/Svg';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import UiSparkles from '../../../ui/sparkles/UiSparkles';
import soundEffect from '../../../crm/message/classic-bonus-points-fx.mp3';
import UiAlert from '../../../ui/alert/UiAlert';
import { optInToHappyHourCrmCheck, optOutFromHappyHourCrm } from '../../../../microservices/happy-hour';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../../services/analytics';
import UiButton from '../../../ui/button/UiButton';
import { closeCrmMessageAndUpdate } from '../../../../services/crm';
import UiCloseButton from '../../../ui/close-button/UiCloseButton';
import * as classnames from 'classnames';
import { OnsiteMessageWithCampaign } from '../../../../microservices/crm';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    message: OnsiteMessageWithCampaign;
    isPreview: boolean;
}

export default function CasinoHappyHourCrmMessage({ message, isPreview }: Props) {
    const [isStoreMessageOpen] = useStore(stores.crm.isMessageOpen);
    const [language] = useStore(stores.language);
    const [storeOptIn] = useStore(stores.happyHour.optIn);
    const [isOptedIn, setIsOptedIn] = useState(true);
    const [isOptOutMessageVisible, setIsOptOutMessageVisible] = useState(false);
    const isMessageOpen = isPreview || isStoreMessageOpen;
    const optIn = isPreview || storeOptIn;
    const OnsiteMessageDesignTag = {
        WINNER: 'happy-hour-winner',
        START: 'happy-hour-start',
        END: 'happy-hour-end',
    };

    useEffect(() => {
        if (!isPreview) {
            optInToHappyHourCrmCheck();
            trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.CASINO_HAPPY_HOUR_CRM_SHOWN);
        }
    }, []);

    useEffect(() => {
        setIsOptedIn(Boolean(optIn));
    }, [optIn]);

    function optOutFromHappyHour() {
        if (isPreview) {
            return;
        }
        optOutFromHappyHourCrm();
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.CASINO_HAPPY_HOUR_OPT_OUT);
        setIsOptOutMessageVisible(true);
    }

    function trackOnCtaClick() {
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.CRM_CTA_CLICKED, {
            on_site_message_name: message.campaignName,
            on_site_message_id: message.campaignId,
        });
    }

    if (!message) {
        return null;
    }

    const freeSpinsAmount = message.designTag?.includes(OnsiteMessageDesignTag.WINNER) ? status.split('-').pop() : '';

    return (
        <Wrapper isPreview={isPreview}>
            <div className={classnames(['crm-message', isMessageOpen ? 'visible' : 'hidden'])}>
                <UiCloseButton
                    className="close-button"
                    onClick={() => !isPreview && closeCrmMessageAndUpdate()}
                    data-test="close-button"
                />

                <UiSparkles block>
                    <div className="header">
                        <div className="header-text">
                            {status?.includes(OnsiteMessageDesignTag.WINNER) && (
                                <img src={`/assets/images/casino//happy-hour/you-won-${language}.png`} alt="" />
                            )}
                            {!status?.includes(OnsiteMessageDesignTag.WINNER) && (
                                <img src={`/assets/images/casino//happy-hour/hh-logo-en.png`} alt="" />
                            )}
                        </div>
                        {status?.includes(OnsiteMessageDesignTag.WINNER) && (
                            <div className="winners-label">
                                <div className="winners-count">{freeSpinsAmount}</div>
                                <Svg icon="casino-lobby" size={21} />
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: translate('free<br/>spins', 'casino-happy-hour'),
                                    }}
                                />
                            </div>
                        )}
                        {status?.includes(OnsiteMessageDesignTag.START) && (
                            <div className="happy-hour-status">{translate('Started', 'casino-happy-hour')}</div>
                        )}
                        {status?.includes(OnsiteMessageDesignTag.END) && (
                            <div className="happy-hour-status">{translate('Ended', 'casino-happy-hour')}</div>
                        )}
                    </div>
                </UiSparkles>

                {status?.includes(OnsiteMessageDesignTag.WINNER) && (
                    <audio autoPlay>
                        <source src={soundEffect} type="audio/mpeg" />
                    </audio>
                )}

                <div className="crm-message-content">
                    {message.description && (
                        <div className="crm-message-text" dangerouslySetInnerHTML={{ __html: message.description }} />
                    )}
                    {message.ctaLink && (
                        <div className="crm-message-cta" onClick={trackOnCtaClick}>
                            <a href={message.ctaLink}>
                                <UiButton color="primary" block size="large">
                                    {message.ctaTitle}
                                </UiButton>
                            </a>
                        </div>
                    )}
                </div>

                {isOptedIn && !isOptOutMessageVisible && (
                    <a onClick={() => optOutFromHappyHour()} className="opt-out-link">
                        {translate('Not interested?', 'casino-happy-hour')}
                    </a>
                )}

                {isOptOutMessageVisible && (
                    <UiAlert success className="opt-out-success">
                        {translate('You have opted out from Casino Happy hour messages', 'casino-happy-hour')}
                    </UiAlert>
                )}
            </div>

            {isMessageOpen && <div className="message-blur" />}
        </Wrapper>
    );
}
