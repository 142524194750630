export const PRODUCT = {
    ALL: 'all',
    CASINO: 'casino',
    CASINO_RACE: 'casino-race',
    DEPOSIT: 'deposit',
    MOBILE_LANDING: 'mobile-landing',
    RACEBOOK: 'racebook',
    SPORTSBOOK: 'sportsbook',
    POKER: 'poker',
    TOURNAMENTS: 'tournaments',
    VIRTUAL_SPORTS: 'virtual_sports',
    VS_HIGHLIGHT_GAMES: 'VS_HIGHLIGHT_GAMES',
} as const;
