import React, { useState } from 'react';
import Wrapper from './styles';
import { translate } from '../../../../services/translate';
import UiAlert from '../../../ui/alert/UiAlert';
import UiGroup from '../../../ui/group/UiGroup';
import UiFormInput from '../../../ui/form/input/UiFormInput';
import { stores } from '../../../../stores';
import { useForm } from '../../../../services/form';
import UiForm from '../../../ui/form/UiForm';
import { validators } from '../../../../services/validators';
import UiButton from '../../../ui/button/UiButton';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    phonePrefix?: string;
    phoneNumber?: string;
    onPhoneChanged?: (values: any) => void;
}

export default function AuthVerificationChangePhoneNumber({
    phonePrefix,
    phoneNumber,
    onPhoneChanged = () => {},
}: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isRegistrationPhoneVerification] = useStore(stores.verification.isRegistrationPhoneVerification);

    const form = useForm({
        phonePrefix,
        phoneNumber,
    });

    function tryChangePhoneNumber(isFormValid) {
        if (isFormValid) {
            setIsLoading(true);
            onPhoneChanged(form.getValues());
            setIsLoading(false);
        }
    }

    return (
        <Wrapper>
            {!isRegistrationPhoneVerification && (
                <UiAlert info>
                    {translate('Insert your phone number!', 'ui.registration')}{' '}
                    {translate('Insert your number to get a verification SMS', 'ui.registration')}
                </UiAlert>
            )}

            {isRegistrationPhoneVerification && (
                <UiAlert info>
                    {translate('Change number!', 'ui.registration')}{' '}
                    {translate('Update your number to get a new verification SMS', 'ui.registration')}
                </UiAlert>
            )}

            <UiForm onSubmit={tryChangePhoneNumber} fullStoryBlock>
                <UiGroup expand>
                    <UiFormInput value={phonePrefix} disabled className="select-prefix" />

                    <UiFormInput
                        {...form.phoneNumber}
                        validator={(value) => validators.required(value)}
                        type="tel"
                        placeholder={translate('Mobile number', 'ui.account')}
                        className="input-phone"
                        maxLength={16}
                        typeNumeric
                    />
                </UiGroup>

                <UiButton color="primary" block isFormSubmitButton isLoading={isLoading}>
                    {translate('Send SMS', 'ui.registration')}
                </UiButton>
            </UiForm>
        </Wrapper>
    );
}
