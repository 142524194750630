import React from 'react';
import FourOFour from '../four-o-four/FourOFour';
import PageBetHistory from './bet-history/PageBetHistory';
import PageTransactions from './transactions/PageTransactions';

interface Props {
    page: string;
}

export default function Page({ page }: Props) {
    const pages = {
        'bet-history': <PageBetHistory />,
        transactions: <PageTransactions />,
        '404': <FourOFour />,
    };

    return <>{pages[page] || pages[404]}</>;
}
