import styled from 'styled-components';

export default styled.div`
        display: flex;
        flex-shrink;
        justify-content: center;
        align-items: flex-start;
        margin: 0 -1.5em;
    
    .job-title {
        cursor: pointer;
    }
`;
