import React from 'react';
import Wrapper from './styles';
import { translate } from '../../../../services/translate';
import Svg from '../../../svg/Svg';
import { startPokerAndroidAppDownload } from '../../../../services/poker';
import UiAlert from '../../../ui/alert/UiAlert';
import { getUserCountry } from '../../../../services/user';
import { COUNTRY } from '../../../../services/country';
import UiButton from '../../../ui/button/UiButton';

export default function PokerPlayAndroidInstructions() {
    return (
        <Wrapper>
            <div className="poker-android-instructions-title">{translate('How to Install', 'poker.app')}</div>
            <div className="poker-android-instructions-google-play">{translate('google-play', 'poker.app')}</div>

            <div className="poker-android-instructions-step-container">
                <Svg icon="settings" className="poker-android-instructions-step-icon" />
                <div
                    className="poker-android-instructions-step-text"
                    dangerouslySetInnerHTML={{ __html: `1. ${translate('Go to Settings', 'poker.app')}` }}
                />
            </div>

            <div className="poker-android-instructions-step-container">
                <Svg icon="lock" className="poker-android-instructions-step-icon" />
                <div
                    className="poker-android-instructions-step-text"
                    dangerouslySetInnerHTML={{ __html: `2. ${translate('Choose Security', 'poker.app')}` }}
                />
            </div>

            <div className="poker-android-instructions-step-container">
                <Svg icon="check" className="poker-android-instructions-step-icon" />
                <div
                    className="poker-android-instructions-step-text"
                    dangerouslySetInnerHTML={{ __html: `3. ${translate('Tick the Box', 'poker.app')}` }}
                />
            </div>

            <div className="poker-android-instructions-step-container">
                <Svg icon="download" className="poker-android-instructions-step-icon" />
                <div
                    className="poker-android-instructions-step-text"
                    dangerouslySetInnerHTML={{ __html: `4. ${translate('Download the app', 'poker.app')}` }}
                />
            </div>

            <UiButton color="primary" block onClick={startPokerAndroidAppDownload}>
                {translate('Download', 'poker.app')}
            </UiButton>

            {getUserCountry() === COUNTRY.SWEDEN && (
                <div className="bank-id-warning">
                    <UiAlert failure={true}>
                        <p>{translate('warning-1', 'poker.download')}</p>
                        <p>{translate('warning-2', 'poker.download')}</p>
                    </UiAlert>
                </div>
            )}

            <div
                className="poker-android-instructions-after-install"
                dangerouslySetInnerHTML={{ __html: translate('after-install', 'poker.app') }}
            />
        </Wrapper>
    );
}
