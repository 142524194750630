import React, { ReactNode } from 'react';
import Wrapper from './styles';
import MainLayout from '../main/MainLayout';
import { getRoute, isActiveRoute } from '../../services/router';

interface Props {
    children: ReactNode;
}

export default function PokerLayout({ children }: Props) {
    const isPokerLobbyPage = isActiveRoute(getRoute('poker'));

    return (
        <Wrapper $isPokerLobbyPage={isPokerLobbyPage}>
            <MainLayout>{children}</MainLayout>
        </Wrapper>
    );
}
