import { translate } from './translate';
import { Quiz } from '../microservices/quiz';
import { Language } from './language';

export const QUIZ_STATUS = {
    ACTIVE: 'active',
    DRAFT: 'draft',
    RESULTED: 'resulted',
    RESULTING: 'resulting',
    UNRESOLVED: 'unresolved',
} as const;

export const SPORT_TYPE = {
    FOOTBALL: 'football',
    BASKETBALL: 'basketball',
    HOCKEY: 'hockey',
};

export const QUIZ_TITLE_TYPE = {
    VS: 'vs',
    CUSTOM: 'custom',
};

export function checkForPlaceholders(answer: string, quiz: Quiz, language: Language) {
    if (!answer || !quiz.variables?.home || !quiz.variables?.away) {
        return '';
    }

    const replaceArray = ['home', 'away'];
    const replaceWith = [quiz.variables.home[language], quiz.variables.away[language]];
    const mapping = {};
    replaceArray.forEach((variable, replaceIndex) => {
        mapping[`[${variable}]`] = replaceWith[replaceIndex];
        return variable;
    });
    return answer.replace(/\[\w+\]/gi, (n) => mapping[n]);
}

export function getQuizError(errorCode) {
    const QUIZ_ERRORS = {
        2001: translate('Could not submit user answers', 'ui.quiz'),
        2002: translate('You are not eligible for this quiz', 'ui.quiz'),
    };
    return QUIZ_ERRORS[errorCode];
}

export function translateQuizStatus(status) {
    const translationMap = {
        [QUIZ_STATUS.ACTIVE]: translate('ACTIVE', 'ui.quiz'),
        [QUIZ_STATUS.DRAFT]: translate('DRAFT', 'ui.quiz'),
        [QUIZ_STATUS.RESULTED]: translate('RESULTED', 'ui.quiz'),
        [QUIZ_STATUS.RESULTING]: translate('RESULTING', 'ui.quiz'),
        [QUIZ_STATUS.UNRESOLVED]: translate('UNRESOLVED', 'ui.quiz'),
    };

    if (translationMap[status]) {
        return translationMap[status];
    }
    return status;
}
