import styled from 'styled-components';

type StyledProps = {
    isGameTopBar?: boolean;
    isCompact?: boolean;
};

export default styled.div<StyledProps>`
    .clocks-group {
        display: flex;
        flex-direction: ${(props) => (props.isGameTopBar ? 'row' : 'column')};
        vertical-align: middle;
        align-items: center;
        justify-content: center;

        overflow: visible; // To not clip the clock visuals during the shake animation
        margin-left: ${(props) => (props.isGameTopBar ? '0' : '0.8rem')};

        color: var(--font-color-secondary);
        line-height: ${(props) => (props.isCompact ? '0.5rem' : '0.8rem')};
        font-size: 8px;
        font-weight: var(--font-weight-bold);

        .session-expiry-countdown {
            border-radius: var(--ui-radius);
            color: #00bfff;

            &.five-minutes-left {
                color: ${({ theme }) => theme.colors.red};
                border: 1px solid ${({ theme }) => theme.colors.red};
                background-color: transparent;
                animation: shakeClock 1s; // TODO: Fix. Animation not triggering after removing ten-minutes-left and adding five-minutes-left class
            }

            &.ten-minutes-left {
                color: #fff;
                border: 1px solid ${({ theme }) => theme.colors.red};
                background-color: ${({ theme }) => theme.colors.red};
                animation: shakeClock 1s;
            }
        }

        .session-duration {
            color: ${({ theme }) => theme.colors.secondary};
        }

        .current-time,
        .session-expiry-countdown,
        .session-duration {
            padding: 0.1rem 0.2rem;
            line-height: 1;
        }
    }

    @keyframes shakeClock {
        0% {
            transform: translate(1px, 1px) rotate(0deg);
        }
        10% {
            transform: translate(-1px, -2px) rotate(-1deg);
        }
        20% {
            transform: translate(-3px, 0px) rotate(1deg);
        }
        30% {
            transform: translate(3px, 2px) rotate(0deg);
        }
        40% {
            transform: translate(1px, -1px) rotate(1deg);
        }
        50% {
            transform: translate(-1px, 2px) rotate(-1deg);
        }
        60% {
            transform: translate(-3px, 1px) rotate(0deg);
        }
        70% {
            transform: translate(3px, 1px) rotate(-1deg);
        }
        80% {
            transform: translate(-1px, -1px) rotate(1deg);
        }
        90% {
            transform: translate(1px, 2px) rotate(0deg);
        }
        100% {
            transform: translate(1px, -2px) rotate(-1deg);
        }
    }
`;
