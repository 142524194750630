import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';
import { PaybackBooster } from '../../../../services/casino/payback-booster';

type StyledProps = {
    isMobileDevice?: boolean;
    paybackBooster?: PaybackBooster;
    isCompact?: boolean;
};

export default styled.div<StyledProps>`
    position: relative;
    width: 100%;
    border-radius: 10px;
    background-position: center;
    background-size: cover;
    margin-bottom: ${({ theme }) => theme.layout.gutter};
    ${({ paybackBooster, isCompact }) =>
        paybackBooster &&
        isCompact &&
        css`
            margin-bottom: ${({ theme }) => theme.layout.gutterDouble};
        `}
    .booster-label {
        border-radius: 100px;
        padding: 0.2rem 0.8rem;
        background-color: var(--color-body-bg);
        color: var(--font-color-secondary);
        text-transform: uppercase;
        font-size: 0.6rem;
        font-weight: 700;
        letter-spacing: 1px;
        position: absolute;
        z-index: 3;
        left: 100px;
        transform: translate(-50%, 0);
        top: -9px;
        border: 2px solid ${({ theme }) => theme.paybackBooster.primaryColor};
        text-align: center;

        ${({ isCompact }) =>
            !isCompact &&
            css`
                display: none;
            `}

        @media ${mediaQuery.isTablet}, ${mediaQuery.isPhone} {
            top: -10px;
            right: 50%;
            left: auto;
            transform: translate(50%, 0);
            animation-duration: 300ms;
            animation-iteration-count: 1;
        }
    }

    .booster-alert > div {
        margin-top: ${({ theme }) => theme.layout.gutter};
        ${({ isCompact }) =>
            isCompact &&
            css`
                margin-top: ${({ theme }) => theme.layout.gutterDouble};
            `}
    }
`;
