import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { stores } from '../../../../../stores';
import { initialBetSlipPlacingState } from '../../../../../services/sports/constants';
import { isMobile } from '../../../../../services/browser';
import { getTicketDetailsByTickedId } from '../../../../../microservices/sbgate';
import { logger } from '../../../../../services/logger';
import { TicketDetails } from '../../../../../services/sports/types';
import { translate } from '../../../../../services/translate';
import UiAlert from '../../../../ui/alert/UiAlert';
import SportTicketDetails from '../../../ticket/details/SportTicketDetails';
import { getTicketsToPrint } from '../../../../../microservices/retail-middleware';
import { isRetail } from '../../../../../services/environment';
import { DATE_YEAR_TIME_FORMAT, getFormattedDate } from '../../../../../services/date';
import { retail } from '../../../../../stores/retail/retail';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    ticketId: string;
}

export default function SportParlayCardBetslipReceipt({ ticketId }: Props) {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [hasRetailSession] = useStore(retail.hasRetailSession);
    const [ticketResponse, setTicketResponse] = useState<TicketDetails>();
    const isRetailLayout = isRetail();

    useEffect(() => {
        if (ticketId) {
            isRetailLayout ? loadRetailTicketsDetails(ticketId) : loadTicketDetails(ticketId);
        }
    }, [ticketId]);

    useEffect(() => {
        if (isAuthenticated || hasRetailSession) {
            return;
        }
        removeReceipt();
    }, [isAuthenticated]);

    async function loadTicketDetails(ticketId: string) {
        try {
            const response = await getTicketDetailsByTickedId(ticketId);
            setTicketResponse(response);
        } catch (error) {
            logger.error('SportParlayCardBetslipReceipt', 'loadTicketDetails', error);
        }
    }

    async function loadRetailTicketsDetails(ticketId: string) {
        try {
            const tickets = await getTicketsToPrint();
            if (tickets.length) {
                const ticket = tickets.find((ticket) => ticket.betsTicket?.ticket.id === ticketId);
                const betsTicket = ticket?.betsTicket;
                if (betsTicket) {
                    setTicketResponse(betsTicket as any);
                }
                stores.sports.ticketsToPrint.set(tickets);
            }
        } catch (error) {
            logger.error('SportParlayCardBetslipReceipt', 'loadRetailTicketsDetails', error);
        }
    }

    function removeReceipt() {
        stores.sports.parlayCard.betSlipPlacingState.set(initialBetSlipPlacingState);
        if (isMobile()) {
            stores.isParlayBetslipOpen.set(false);
        }
    }

    if (!ticketResponse) {
        return null;
    }

    return (
        <Wrapper>
            <p>
                {translate('Bet ID:', 'ui.betslip')} {ticketResponse.ticket.display_id} /{' '}
                <span>
                    {getFormattedDate({
                        date: ticketResponse.ticket.created_at,
                        format: DATE_YEAR_TIME_FORMAT,
                        useDots: true,
                    })}
                </span>
            </p>

            <UiAlert center success>
                {translate('Your bet is placed. Good luck!', 'ui.betslip')}
            </UiAlert>

            <SportTicketDetails ticket={ticketResponse} isCompactView onTicketClosed={removeReceipt} />
        </Wrapper>
    );
}
