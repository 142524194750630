import { getServiceUrl, httpGet, httpPost, httpPut } from '../services/api';
import { logger } from '../services/logger';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';

export type TrackingType = 'public' | 'private';

interface TrackingIDs {
    adformId?: string;
    googleAnalyticsId?: string;
}

interface TicketTrackingData {
    ticket_id: string;
    context: string;
}

export interface DelayedEvent {
    id: number;
    user_id: string;
    event: string;
    reference_id: string;
    variables: Record<string, string>;
    reported_at: Date | null;
    created_at: Date;
    locked_until: Date | null;
}

const getUrl = (url) => getServiceUrl('analytics', url);

export async function setTrackingIDs(type: TrackingType, body: TrackingIDs): Promise<void> {
    const url = getUrl(`tracking/${type}`);
    try {
        await httpPost<{ message: string }>(url, body);
    } catch (error) {
        logger.error('AnalyticsMicroservice', 'setTrackingIDs', error);
    }
}

export async function postBettingContext(data: TicketTrackingData) {
    try {
        return httpPost<{ message: string }>(getUrl('ticket-tracking'), data);
    } catch (e) {
        logger.error('AnalyticsMicroservice', 'postBettingContext', e);
    }
}

export async function getDelayedEventsByAuthenticatedUser() {
    const url = getUrl(`delayed-events`);
    try {
        return await httpGet<any>(url);
    } catch (error) {
        logger.error('AnalyticsMicroservice', 'getDelayedEventsByAuthenticatedUser', error);
    }
}

export async function reportDelayedEvents(delayedEventsIds: number[]) {
    try {
        return await httpPut<any>(getUrl('delayed-events'), { ids: delayedEventsIds });
    } catch (error) {
        logger.error('AnalyticsMicroservice', 'reportDelayedEvents', error);
    }
}

export async function reportAffiliateBtag(user_id: string, btag: string) {
    try {
        return httpPost<boolean>(getUrl('income-access-affiliate'), {
            btag,
            user_id,
        });
    } catch (e) {
        const user = getStoreValue(stores.user);
        logger.error('AnalyticsMicroservice', 'reportAffiliateBtag', {
            extra: `userId: ${user?.id} with btag: ${btag}`,
            e,
        });
    }
}
