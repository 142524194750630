import styled, { css } from 'styled-components';

type StyledProps = {
    mode: 'default' | 'drawer';
    maxWidth?: number;
    minWidth?: number;
    iOS?: boolean;
};

export default styled.div<StyledProps>`
    color: var(--font-color-primary);
    font-size: var(--font-14);
    .new-close-button {
        display: none;
    }
    ${({ mode }) =>
        mode !== 'drawer' &&
        css<StyledProps>`
            max-width: ${({ maxWidth = 270 }) => (Number.isInteger(maxWidth) ? `${maxWidth}px` : maxWidth)};
            outline: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-width: ${({ minWidth = 325 }) => (Number.isInteger(minWidth) ? `${minWidth}px` : minWidth)};
            &.new-modal {
                min-width: ${({ minWidth = '' }) => (Number.isInteger(minWidth) ? `${minWidth}px` : minWidth)};
                max-width: ${({ maxWidth = '' }) => (Number.isInteger(maxWidth) ? `${maxWidth}px` : maxWidth)};
                max-height: 90vh;
                overflow: auto;

                background-color: var(--surface-level-2-bg);
                border: var(--surface-level-2-border);
                border-radius: var(--ui-radius);
                box-shadow: var(--ui-shadow);
                color: var(--font-color-primary);
                padding: var(--spacing-24);
                .ui-modal-title {
                    text-align: center;
                    font-size: 1.25rem;
                    font-weight: var(--font-weight-bold);
                    ${({ theme }) => theme.optimizeFontRendering};
                    margin: var(--spacing-12) auto var(--spacing-24) auto;
                }
                .new-close-button {
                    display: block;
                    top: var(--spacing-12);
                    right: var(--spacing-12);
                }
            }
        `};
    &.new-modal {
        min-width: ${({ minWidth = '' }) => (Number.isInteger(minWidth) ? `${minWidth}px` : minWidth)};
        max-width: ${({ maxWidth = '' }) => (Number.isInteger(maxWidth) ? `${maxWidth}px` : maxWidth)};
        width: 100%;
        margin: 0 auto;
        padding: var(--spacing-24);
        background-color: var(--surface-level-2-bg);

        .ui-modal-title {
            text-align: center;
            font-size: 1.25rem;
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};
            margin: var(--spacing-12) auto var(--spacing-24) auto;
        }
        .new-close-button {
            display: block;
            top: var(--spacing-12);
            right: var(--spacing-12);
        }

        .cutout {
            background-color: var(--surface-level-2-bg);
        }
    }

    .ui-modal-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: var(--spacing-12) 0;
    }

    .betslip-box,
    .tickets-box {
        background: var(--betslip-bg);
        padding: 0 var(--spacing-12);
        color: var(--font-color-primary);
        border-top-left-radius: var(--ui-radius);
        border-top-right-radius: var(--ui-radius);
        user-select: none;
        overflow: auto;
    }

    .tickets-box {
        min-height: 100vh;
        max-height: 100vh;
    }

    .betslip-box {
        max-height: 100vh;
    }

    .game-launcher-content {
        background: var(--betslip-bg);
        padding: 0 var(--spacing-16) var(--spacing-32) var(--spacing-16);
        min-height: 100vh;
        max-height: 100vh;
        overflow: auto;
    }

    p {
        color: var(--font-color-secondary);
        font-size: var(--font-14);
    }
    .ui-modal-content {
        text-align: center;
    }
    .cutout {
        background-color: var(--betslip-bg);
        padding: var(--spacing-8);
        display: flex;
        justify-content: center;
        z-index: 11;
    }
    .holder {
        width: 45px;
        height: 8px;
        background-color: var(--surface-level-3-bg);
        border: var(--surface-level-3-border);
        border-radius: 100px;
    }
    a {
        color: var(--font-link-color);
    }
`;
