import { useEffect, useMemo } from 'react';
import { calculateTotalOdds, isBetSlipLoadingMarkets } from '../../../../services/sports/betslip';
import { addBetslipError, removeAllBetslipErrorsOfCode } from '../../../../services/sports/betslip-errors';
import { getActiveEligibleBonus } from '../../../../services/sports/bonus-bets';
import {
    BET_TYPE as BET_TYPES,
    BONUS_BET_CANNOT_COMBINE_RISK_FREE_BET_AND_NORMAL_ERROR,
    BONUS_BET_MIN_ODDS_ERROR,
    BONUS_BET_MIN_STAKE_ERROR,
    COMBO_MARKET_ID,
    RISK_FREE_BET_STAKE_LIMIT_EXCEEDED_ERROR,
} from '../../../../services/sports/constants';
import { useOddsByOutcomeIds } from '../../../../services/sports/hooks';
import { stores } from '../../../../stores';
import { useOddsFormat } from '../../../../services/sports/user-settings';
import { BONUS_TYPE } from '../../../../services/bonus';
import { useStore } from '../../../../hooks/useStore';

export default function SportBonusBetBetslipValidations() {
    const selectedBonus = getActiveEligibleBonus();
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [betSlipUserState] = useStore(stores.sports.betSlipUserState);
    const [marketInfoById] = useStore(stores.sports.marketInfoById);
    const outcomeIds = useMemo(() => Object.values(betSlipMarketIdToOutcomeId), [betSlipMarketIdToOutcomeId]);
    const [oddsByOutcomeId] = useOddsByOutcomeIds(outcomeIds, [outcomeIds.join()]);
    const [teaserPoint] = useStore(stores.sports.teaserSelectedPoint);
    const [teaserPayouts] = useStore(stores.sports.teaserPayouts);
    const [oddsFormat] = useOddsFormat();
    const { stakeByMarketId, betType } = betSlipUserState;
    const marketId = betType === BET_TYPES.COMBO ? String(COMBO_MARKET_ID) : Object.keys(betSlipMarketIdToOutcomeId)[0];
    const currentStake: any = stakeByMarketId[marketId];

    useEffect(() => {
        removeAllBetslipErrorsOfCode([
            BONUS_BET_MIN_STAKE_ERROR,
            BONUS_BET_CANNOT_COMBINE_RISK_FREE_BET_AND_NORMAL_ERROR,
            BONUS_BET_MIN_ODDS_ERROR,
            RISK_FREE_BET_STAKE_LIMIT_EXCEEDED_ERROR,
        ]);

        if (!selectedBonus || isBetSlipLoadingMarkets()) {
            return;
        }
        if (currentStake < selectedBonus.minAmount) {
            addBetslipError(COMBO_MARKET_ID, BONUS_BET_MIN_STAKE_ERROR);
        }
        if (calculateTotalOdds(marketId) < selectedBonus.odds_value) {
            addBetslipError(COMBO_MARKET_ID, BONUS_BET_MIN_ODDS_ERROR);
        }

        if (selectedBonus.bonus_type === BONUS_TYPE.RISK_FREE_BET && selectedBonus.amount < currentStake) {
            addBetslipError(COMBO_MARKET_ID, RISK_FREE_BET_STAKE_LIMIT_EXCEEDED_ERROR);
        }

        const numSelectionsCurrent = Object.keys(betSlipMarketIdToOutcomeId).length;
        if ((betType === BET_TYPES.SINGLE && numSelectionsCurrent !== 1) || betType === BET_TYPES.SYSTEM) {
            addBetslipError(COMBO_MARKET_ID, BONUS_BET_CANNOT_COMBINE_RISK_FREE_BET_AND_NORMAL_ERROR);
        }
    }, [
        selectedBonus,
        betSlipMarketIdToOutcomeId,
        currentStake,
        betType,
        oddsByOutcomeId,
        marketInfoById,
        teaserPoint,
        teaserPayouts,
        oddsFormat,
    ]);
    return null;
}
