import React from 'react';
import { UseSportsUserSettings } from '../../../microservices/sbgate';
import { useMatchStatusUpdateSubscribe } from '../../../services/sports/hooks';
import { CategoryMatch } from '../../../services/sports/types';
import SportMatchLayout from './layout/SportMatchLayout';

interface Props {
    match: CategoryMatch;
    isOpen?: boolean;
    layout?: UseSportsUserSettings;
    isInitiallyOpen?: boolean;
    isMarketTitleVisible?: boolean;
    isCompactView?: boolean;
}

function SportMatch({ match }: Props) {
    const matchStatus = useMatchStatusUpdateSubscribe(match);

    return <SportMatchLayout match={match} matchStatus={matchStatus} />;
}

export default React.memo(SportMatch, (prevProps, nextProps) => {
    return prevProps.match.id === nextProps.match.id && prevProps.layout === nextProps.layout;
});
