import React from 'react';
import Wrapper from './styles';
import { MATCH_STATUS, SPORT_ID } from '../../../../services/sports/constants';
import { translate } from '../../../../services/translate';
import { useMatchStatusUpdate } from '../../../../services/sports/hooks';

interface Props {
    match: {
        id: number;
        sport_category: number;
        sport: number;
        external_comment: string | null;
        pitcherHome?: string | null;
        pitcherAway?: string | null;
        status?: string;
    };
    // TODO SportSearchResultMatch should use SportMatchName to display pitchers
    forceShowListedPitchers?: boolean;
}

export default function SportMatchComments({ match }: Props) {
    const matchStatus = useMatchStatusUpdate(match);
    const mapAdvantageMessage = getMapAdvantage(match);
    const offTheBoard = getOffTheBoard();

    const message = offTheBoard || mapAdvantageMessage || match.external_comment;

    function getOffTheBoard() {
        return matchStatus === MATCH_STATUS.OFFTHEBOARD
            ? translate(
                  ['match-comment-offtheboard', 'Match offline. Betting will resume in few minutes.'],
                  'ui.sportsbook',
              )
            : false;
    }

    function getMapAdvantage({ external_comment: externalComment, sport }) {
        const validMapAdvantageKeys = ['esports-home-team-map-advantage', 'esports-away-team-map-advantage'];
        const isMapAdvantage =
            sport === SPORT_ID.ESPORTS && externalComment && validMapAdvantageKeys.includes(externalComment);
        return isMapAdvantage ? translate(externalComment, 'ui.sportsbook') : '';
    }

    return <Wrapper>{message && <div className="match-comment">{message}</div>}</Wrapper>;
}
