import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { mediaQuery } from '../../../../styles/utils';

const baseColor = '#ff64fa';

type StyledProps = {
    isCompact?: boolean;
    isOptedIn?: boolean;
};
export default styled.div<StyledProps>`
    position: relative;
    z-index: 110;
    margin-left: ${(props) => (props.isCompact ? '' : '0.4rem')};
    display: flex;
    flex-direction: column;

    border-radius: var(--ui-radius);
    background-color: var(--surface-level-1-bg);

    cursor: pointer;
    user-select: none;

    @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
        height: 50px;
        margin: 0 0 0.8rem 0;
        z-index: 11;
    }

    .happy-hour-widget {
        display: flex;
        align-items: stretch;

        flex-direction: ${(props) => (props.isCompact ? 'column' : 'row')};

        height: 5.5rem;
        min-height: 50px;
        width: ${(props) => (props.isCompact ? '66px' : '')};

        z-index: 2;
    }
    .happy-hour-logo {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        color: #fff;
        text-transform: uppercase;
        animation: purplePulse 3s infinite alternate;
        transition: ease-in-out;

        padding: ${(props) => (props.isCompact ? '0.4rem 0.2rem' : '0.4rem 1.2rem')};
        background-color: #b02ae1;
        border-radius: 8px;
        border: 2px solid;
        text-shadow: 1px 1px 1px #711792;
        opacity: ${(props) => (props.isOptedIn ? '1' : '0.5')};
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            min-width: 150px;
        }
        .hour {
            font-family: 'Roboto', sans-serif;
            font-weight: 800;
            letter-spacing: 1px;
            font-size: 0.875rem;
        }
        .happy,
        .active {
            letter-spacing: 2px;
            font-size: 0.65rem;
        }
    }

    .happy-our-countdown {
        padding: ${(props) => (props.isCompact ? '0.8rem 0.2rem' : '0 1.2rem')};
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex: 1;

        font-size: 0.8rem;
        letter-spacing: 1px;
        color: ${baseColor};

        .countdown-clock {
            opacity: ${(props) => (props.isOptedIn ? '1' : '0.5')};
            font-size: var(--font-14);
            font-weight: var(--button-font-weight);
        }

        .opted-out {
            font-size: 0.6rem;
            color: var(--font-color-secondary);
            text-transform: uppercase;
            ${({ isCompact }) =>
                isCompact &&
                css`
                    text-align: center;
                    margin-top: 0.5rem;
                `};
        }
    }

    .happy-our-time {
        display: flex;
        flex: 1;

        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: ${(props) => (props.isCompact ? '0.4rem 0.2rem' : '0 1rem')};
        text-transform: uppercase;

        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            flex: 1;
        }

        .day {
            letter-spacing: 2px;
            font-size: 0.65rem;
            margin-bottom: 3px;
        }
        .time-time {
            display: flex;
            justify-content: center;

            flex-direction: ${(props) => (props.isCompact ? 'column' : 'row')};
            align-items: ${(props) => (props.isCompact ? 'center' : '')};

            font-family: 'Roboto', sans-serif;
            font-weight: 800;
            letter-spacing: 1px;
            font-size: 0.8rem;
            margin: ${(props) => (props.isCompact ? '0.2rem 0' : '')};
            .time {
                display: flex;
            }
            .minutes {
                font-size: 0.5rem;
                font-weight: 300;

                margin-left: 3px;
                margin-top: ${(props) => (props.isCompact ? '1px' : '2px')};
            }
            .time-separator {
                margin: 0 5px;
            }
            @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
                font-size: 1rem;
            }
        }
    }
    .happy-hour-info {
        cursor: pointer;
        margin-right: 1rem;
        align-items: center;
        display: flex;
        color: var(--font-color-secondary);
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            width: 50px;
            justify-content: center;
        }
    }

    .happy-hour-description {
        position: absolute;
        top: 0;
        z-index: 1;

        padding: 70px var(--spacing-24) var(--spacing-24) var(--spacing-24);
        width: 100%;

        border-radius: 8px;
        border: 2px solid ${baseColor};
        background-color: var(--surface-level-1-bg);
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.35), inset 1px 1px 4px -2px rgba(255, 255, 255, 0.22);
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            padding: 60px var(--spacing-24) 0 var(--spacing-24);
        }
        .text {
            font-size: 1rem;
            color: var(--font-color-secondary);
            line-height: 150%;
            @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
                font-size: 1.2rem;
            }
        }
        .read-more {
            font-size: 0.875rem;
            text-align: center;

            display: block;

            @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
                padding: 1rem 0;
                font-size: 1.2rem;
            }
        }
    }

    @keyframes purplePulse {
        0% {
            box-shadow: 2px 2px 16px -2px ${baseColor}, inset -4px -4px 16px #ff64fa;
            border-color: ${lighten(0.05, baseColor)};
        }
        25% {
            box-shadow: -2px 2px 16px -2px ${baseColor}, inset 4px -4px 16px #ff64fa;
            border-color: ${lighten(0.05, baseColor)};
        }
        50% {
            box-shadow: -2px -2px 16px -2px ${baseColor}, inset 4px 4px 16px #ff64fa;
            border-color: ${lighten(0.05, baseColor)};
        }
        100% {
            box-shadow: 2px -2px 16px -2px ${baseColor}, inset -4px 4px 16px #ff64fa;
            border-color: ${baseColor};
        }
    }
`;
