import React from 'react';
import Wrapper from './styles';
import RtpCalculator from '../../rtp-calculator/RtpCalculator';

interface Props {
    preselectedgameids?: string;
}

export default function CmsCasinoRtpCalculator({ preselectedgameids }: Props) {
    return (
        <Wrapper>
            <RtpCalculator preSelectedGameIds={preselectedgameids?.split(',')} />
        </Wrapper>
    );
}
