import styled from 'styled-components';
import UiGroup from '../../../ui/group/UiGroup';

export default styled(UiGroup)`
    display: flex;
    color: var(--font-color-subtle);
    font-size: 12px;
    //margin: 1rem 0;
    em {
        color: var(--color-highlight);
        font-style: normal;
    }
`;
