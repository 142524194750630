import { parse, stringify } from 'query-string';
import { storageGet, storageSet } from './storage';
import { COUNTRY } from './country';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import mapValues from 'lodash/mapValues';
import { setLanguage } from './translate';
import { getRoute, isActiveRoute, translateUrlToLanguage } from './router';
import { updateProfile } from '../microservices/users-cb';
import { logger } from './logger';
import { getUserCountry, isTestUser } from './user';
import { LOGIN_METHOD } from './auth';
import { NativeMessageEventType, sendNativeEvent } from './mobile-app';
import { betbuilderCultureByLanguage } from './sports/constants';
import { ClientName } from './utils/types';
import memoize from 'lodash/memoize';
import { getClient, isRetail } from './environment';
import { environment } from '../stores/environment/environment';

export interface LanguageSelection {
    label: string;
    value: Language;
    locale: LOCALE;
    experimental?: typeof ClientName[keyof typeof ClientName][];
    clients: typeof ClientName[keyof typeof ClientName][];
}

enum FANTASY_LANGUAGE {
    CANADIAN = 'ca',
    CHILEAN = 'cl',
    PERUVIAN = 'pe',
    ECUADORIAN = 'ec',
    MEXICAN = 'mx',
    US = 'us',
    EUROPEAN = 'eu',
}

enum REAL_LANGUAGE {
    ENGLISH = 'en',
    ESTONIAN = 'et',
    FINNISH = 'fi',
    ICELANDIC = 'is',
    NORWEGIAN = 'no',
    RUSSIAN = 'ru',
    SPANISH = 'es',
    SWEDISH = 'sv',
    DEFAULT_LATAM = 'cl',
    EUROPEAN = 'eu',
}

export const LANGUAGE = { ...FANTASY_LANGUAGE, ...REAL_LANGUAGE };
export type Language = FANTASY_LANGUAGE | REAL_LANGUAGE;

const FANTASY_TO_REAL_LANGUAGE: { [key in FANTASY_LANGUAGE]: REAL_LANGUAGE } = {
    [LANGUAGE.CHILEAN]: LANGUAGE.SPANISH,
    [LANGUAGE.PERUVIAN]: LANGUAGE.SPANISH,
    [LANGUAGE.CANADIAN]: LANGUAGE.ENGLISH,
    [LANGUAGE.ECUADORIAN]: LANGUAGE.SPANISH,
    [LANGUAGE.MEXICAN]: LANGUAGE.SPANISH,
    [LANGUAGE.US]: LANGUAGE.ENGLISH,
    [LANGUAGE.EUROPEAN]: LANGUAGE.ENGLISH,
};

const STORAGE_LANGUAGE_KEY = 'language';

enum LOCALE {
    EN = 'en_GB',
    NO = 'nb_NO',
    SE = 'sv_SE',
    FI = 'fi_FI',
    EE = 'et_EE',
    RU = 'ru_RU',
    DE = 'de_DE',
    ES = 'es_ES',
    IS = 'is_IS',
    CL = 'es_CL',
    PE = 'es_PE',
    DK = 'da_DK',
    CA = 'en_CA',
    ON = 'en_CA',
    EC = 'es_EC',
    PY = 'es_PY',
    MX = 'es_MX',
    US = 'en_US',
    EU = 'en_EU',
}

export const localeOfLanguage = {
    [LANGUAGE.CANADIAN]: LOCALE.CA,
    [LANGUAGE.CHILEAN]: LOCALE.CL,
    [LANGUAGE.ENGLISH]: LOCALE.EN,
    [LANGUAGE.ESTONIAN]: LOCALE.EE,
    [LANGUAGE.FINNISH]: LOCALE.FI,
    [LANGUAGE.ICELANDIC]: LOCALE.IS,
    [LANGUAGE.EUROPEAN]: LOCALE.EU,
    [LANGUAGE.PERUVIAN]: LOCALE.PE,
    [LANGUAGE.RUSSIAN]: LOCALE.RU,
    [LANGUAGE.SPANISH]: LOCALE.ES,
    [LANGUAGE.SWEDISH]: LOCALE.SE,
    [LANGUAGE.ECUADORIAN]: LOCALE.EC,
    [LANGUAGE.MEXICAN]: LOCALE.MX,
    [LANGUAGE.US]: LOCALE.US,
};

export const languageByCountry = {
    [COUNTRY.CANADA]: LANGUAGE.CANADIAN,
    [COUNTRY.CHILE]: LANGUAGE.CHILEAN,
    [COUNTRY.ESTONIA]: LANGUAGE.ESTONIAN,
    [COUNTRY.FINLAND]: LANGUAGE.FINNISH,
    [COUNTRY.ICELAND]: LANGUAGE.ICELANDIC,
    [COUNTRY.PERU]: LANGUAGE.PERUVIAN,
    [COUNTRY.NORWAY]: LANGUAGE.EUROPEAN,
    [COUNTRY.SWEDEN]: LANGUAGE.SWEDISH,
    [COUNTRY.ECUADOR]: LANGUAGE.ECUADORIAN,
    [COUNTRY.MEXICO]: LANGUAGE.MEXICAN,
    [COUNTRY.USA]: LANGUAGE.US,
};

// MomentJS uses locales for i18n. Norwegian locale starts with "nb_NO". To update MomentJS locale translations
// you reference it with the first part of locale (in Norwegian case "nb"). That's why we need this dictionary.
export const momentJsLanguageByApplicationLanguage = mapValues(localeOfLanguage, (locale) => locale.split('_')[0]);

const languages: LanguageSelection[] = [
    {
        label: 'English',
        value: LANGUAGE.ENGLISH,
        locale: LOCALE.EN,
        clients: [ClientName.COOLBET],
    },
    {
        label: 'Canadian English',
        value: LANGUAGE.CANADIAN,
        locale: LOCALE.CA,
        clients: [ClientName.COOLBET],
    },
    {
        label: 'Svenska',
        value: LANGUAGE.SWEDISH,
        locale: LOCALE.SE,
        clients: [ClientName.COOLBET],
    },
    {
        label: 'Suomi',
        value: LANGUAGE.FINNISH,
        locale: LOCALE.FI,
        clients: [ClientName.COOLBET],
    },
    {
        label: 'Eesti',
        value: LANGUAGE.ESTONIAN,
        locale: LOCALE.EE,
        clients: [ClientName.COOLBET],
    },
    {
        label: 'Русский',
        value: LANGUAGE.RUSSIAN,
        locale: LOCALE.RU,
        clients: [ClientName.COOLBET],
    },
    {
        label: 'Íslenska',
        value: LANGUAGE.ICELANDIC,
        locale: LOCALE.IS,
        clients: [ClientName.COOLBET],
    },
    {
        label: 'Chilean Spanish',
        value: LANGUAGE.CHILEAN,
        locale: LOCALE.CL,
        clients: [ClientName.COOLBET],
    },
    {
        label: 'Peruvian Spanish',
        value: LANGUAGE.PERUVIAN,
        locale: LOCALE.PE,
        clients: [ClientName.COOLBET],
    },
    {
        label: 'Ecuadorian Spanish',
        value: LANGUAGE.ECUADORIAN,
        locale: LOCALE.EC,
        clients: [ClientName.COOLBET],
    },
    {
        label: 'Mexican Spanish',
        value: LANGUAGE.MEXICAN,
        locale: LOCALE.MX,
        clients: [ClientName.COOLBET],
    },
    {
        label: 'English US',
        value: LANGUAGE.US,
        locale: LOCALE.US,
        clients: [ClientName.DEMO, ClientName.IVC, ClientName.WYNNBET, ClientName.WYNNBET_NEVADA, ClientName.STATION],
    },
    {
        label: 'Español',
        value: LANGUAGE.SPANISH,
        locale: LOCALE.ES,
        experimental: [ClientName.COOLBET],
        clients: [ClientName.COOLBET, ClientName.STATION, ClientName.WYNNBET_NEVADA],
    },
    {
        label: 'European En',
        value: LANGUAGE.EUROPEAN,
        locale: LOCALE.EU,
        clients: [ClientName.COOLBET],
    },
    {
        label: 'Enorca',
        value: LANGUAGE.ENGLISH,
        locale: LOCALE.EN,
        clients: [ClientName.ORCA],
    },
];

export const languagesByKey = languages.reduce((languagesByKey, language) => {
    // eslint-disable-next-line no-param-reassign
    languagesByKey[language.value] = language;
    return languagesByKey;
}, {});

function getRetailLanguage() {
    return languages.find(({ locale }) => locale === LOCALE.US) as LanguageSelection;
}

function overrideLanguageForNorway() {
    const userLanguage = getStoreValue(stores.language);
    if (userLanguage === LANGUAGE.NORWEGIAN) {
        stores.language.set(LANGUAGE.EUROPEAN);
    }
    if (getUserCountry() === COUNTRY.NORWAY) {
        switch (userLanguage) {
            case LANGUAGE.ENGLISH:
                stores.language.set(LANGUAGE.EUROPEAN);
                break;
            case LANGUAGE.NORWEGIAN:
                stores.language.set(LANGUAGE.EUROPEAN);
                break;
        }
    }
}

export async function initLanguage(history) {
    overrideLanguageForNorway();

    if (window.coolb2b?.language) {
        await setLanguage(window.coolb2b?.language);
        return;
    }
    const client = getClient();
    const isRetailLayout = isRetail();
    let language = getStoreValue(stores.language);
    const isOfficeIP = getStoreValue(stores.isOfficeIp);

    const isPokerSubPage = !isRetailLayout
        ? isActiveRoute(getRoute('poker', LANGUAGE.ENGLISH), false) &&
          !isActiveRoute(getRoute('poker', LANGUAGE.ENGLISH))
        : null;

    const foundLanguage = languages.find((languagesLanguage) => languagesLanguage.value === language);
    if (
        !foundLanguage ||
        (foundLanguage?.experimental?.includes(client) && !(isTestUser() || isOfficeIP || isPokerSubPage))
    ) {
        window.location.href = `${window.location.origin}/en`;
    }

    await setLanguage(language);

    const languageFromQueryString = getLanguageFromQueryString();
    const user = !isRetailLayout ? getStoreValue(stores.user) : null;

    if (languageFromQueryString) {
        if (isPokerSubPage && user && FANTASY_TO_REAL_LANGUAGE[user.language] === language) {
            // prefer user language, poker client does not support some languages
            // eslint-disable-next-line prefer-destructuring
            language = user.language;
            stores.language.set(language);
            await setLanguage(language);
        }

        const params = parse(window.location.search);
        delete params.lang;

        const translatedPath = await translateUrlToLanguage(window.location.pathname, language);

        history.replace(`${translatedPath}?${stringify(params)}`);
        return;
    } else if (!isRetailLayout && window.location.pathname.startsWith('/--/')) {
        // Try to translate to previously used language with English fallback.
        // Useful when FO language is unknown.
        const params = parse(window.location.search);
        const pathnameInEnglish = window.location.pathname.replace('/--/', `/${LANGUAGE.ENGLISH}/`);
        const translatedPath = await translateUrlToLanguage(pathnameInEnglish, language);
        history.replace(`${translatedPath}?${stringify(params)}`);
        return;
    }

    if (!isRetailLayout && user && user.language !== language && !isPokerSubPage) {
        updateUserLanguage(language);
    }

    sendNativeEvent({ type: NativeMessageEventType.PAGE_LOADED, language });

    const [languageFromUrl] = decodeURI(window.location.pathname).split('/').slice(1);
    if (language === FANTASY_LANGUAGE.EUROPEAN && languageFromUrl !== FANTASY_LANGUAGE.EUROPEAN) {
        const params = parse(window.location.search);
        const translatedPath = await translateUrlToLanguage(window.location.pathname, language);
        history.replace(`${translatedPath}?${stringify(params)}`);
    }
}

async function updateUserLanguage(language) {
    const isBackOfficeLogin = Boolean(storageGet(LOGIN_METHOD.BO_LOGIN));
    if (isBackOfficeLogin) {
        return;
    }

    try {
        await updateProfile({ language });
    } catch (error) {
        logger.error('LanguageService', 'updateUserLanguage', error);
    }
}

export function findLanguage() {
    const clientNameFromEnv = getStoreValue(environment).CLIENT_NAME;
    const isOfficeIP = getStoreValue(stores.isOfficeIp);
    const languageSelection = getInitialLanguage();
    const isLanguageAllowed = languageSelection.clients.includes(clientNameFromEnv) || isOfficeIP;

    stores.language.set(isLanguageAllowed ? languageSelection.value : LANGUAGE.ENGLISH);
}

function getInitialLanguage(): LanguageSelection {
    return !isRetail()
        ? getLanguageFromConfig() ||
              getLanguageFromUrl() ||
              getLastUsedLanguage() ||
              getIpCountryLanguage() ||
              getNavigatorLanguage() ||
              getDefaultLanguage()
        : getRetailLanguage();
}

function getLastUsedLanguage() {
    const activeLanguageValue = storageGet(STORAGE_LANGUAGE_KEY);
    return getAvailableLanguagesMemoized().find((language) => language.value === activeLanguageValue);
}

function getIpCountryLanguage() {
    const ipCountry = getStoreValue(stores.ipCountry);
    return getAvailableLanguagesMemoized().find((language) => language.value === languageByCountry[ipCountry]);
}

function getNavigatorLanguage() {
    const locale = tryGetLocaleFromString(navigator.language);
    return getAvailableLanguagesMemoized().find((language) => language.locale === locale);
}

function tryGetLocaleFromString(languageString: string) {
    for (const language of Object.keys(localeOfLanguage)) {
        if (languageString.includes(language)) {
            return localeOfLanguage[language] as LOCALE;
        }
    }
    return null;
}

function getDefaultLanguage() {
    return getAvailableLanguagesMemoized()[0];
}

function getLanguageFromConfig() {
    if ((window as any).coolb2b?.language) {
        const lang = (window as any).coolb2b.language;
        return languages.find((language) => language.value === lang);
    }
    return false;
}

function getLanguageFromUrl() {
    return getLanguageFromQueryString() || getLanguageFromUrlPath();
}

function getLanguageFromQueryString() {
    const queryParams = parse(window.location.search) as { lang: string };
    return languages.find(({ value }) => value === (queryParams.lang && queryParams.lang.toLowerCase()));
}

export function getLanguageFromUrlPath() {
    const language = window.location.pathname.split('/')[1];
    return languages.find(({ value }) => value === language);
}

export function saveLanguageToStorage(language) {
    storageSet(STORAGE_LANGUAGE_KEY, language);
}

export function getActiveLocale() {
    return localeOfLanguage[getStoreValue(stores.language)];
}

export function getBetbuilderCulture(language: Language) {
    return betbuilderCultureByLanguage[language] || betbuilderCultureByLanguage['en'];
}

export const getAvailableLanguagesMemoized = memoize(getAvailableLanguages);

function getAvailableLanguages() {
    const userCountry = getUserCountry();
    const client = getClient();
    const isOfficeIP = getStoreValue(stores.isOfficeIp);

    return languages.filter((language) => {
        if (language.experimental?.includes(client) && !(isTestUser() || isOfficeIP)) {
            return false;
        } else if (!language.clients.includes(client)) {
            return false;
        } else if (userCountry !== COUNTRY.NORWAY && language.value === LANGUAGE.EUROPEAN) {
            return false;
        } else if (userCountry === COUNTRY.NORWAY && language.value === LANGUAGE.ENGLISH) {
            return false;
        }
        return true;
    });
}
