import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { useInterval } from '../../../services/hooks';
import { filterStyleProps } from '../../../styles/utils';

interface Props {
    percentage?: number;
    fakeProgressDuration?: number;
    label?: string;
    isPercentageVisible?: boolean;
    showAfterPercentage?: number;
    className?: string;
}

export default function UiProgressBar({
    percentage = 0,
    fakeProgressDuration,
    label,
    isPercentageVisible = true,
    showAfterPercentage = 0,
    ...rest
}: Props) {
    const [localPercentage, setLocalPercentage] = useState(0);
    const [updateInterval, setUpdateInterval] = useState(0);

    useEffect(() => {
        if (fakeProgressDuration) {
            setUpdateInterval(fakeProgressDuration / 100);
            setLocalPercentage(8);
        }
    }, [percentage, fakeProgressDuration]);

    useInterval(
        () => {
            setLocalPercentage((localPercentage) => (localPercentage < 99 ? localPercentage + 1 : 99));
        },
        updateInterval,
        [updateInterval],
    );

    useEffect(() => {
        if (localPercentage >= 99) {
            setUpdateInterval(0);
        }
    }, [localPercentage]);

    if (showAfterPercentage && showAfterPercentage > (localPercentage || percentage)) {
        return null;
    }

    return (
        <Wrapper percentage={localPercentage || percentage} {...filterStyleProps({ ...rest })}>
            <div className="progress-bar">
                <span className="stripes">
                    <span className="animated" />
                </span>
            </div>
            {(label || isPercentageVisible) && (
                <div className="progress-text">
                    {label && <>{label}&nbsp;</>}
                    {isPercentageVisible && <>{localPercentage || percentage}%</>}
                </div>
            )}
        </Wrapper>
    );
}
