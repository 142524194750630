import React from 'react';
import { getRoute } from '../../../../services/router';
import { translate } from '../../../../services/translate';
import CoolbetHeaderAuthenticatedMenu from './menu/CoolbetHeaderAuthenticatedMenu';
import CoolbetHeaderAuthenticatedClock from './clock/CoolbetHeaderAuthenticatedClock';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import UiButton from '../../../ui/button/UiButton';
import Svg from '../../../svg/Svg';
import { stores } from '../../../../stores';
import CrmInboxIcon from '../../../crm/inbox/icon/CrmInboxIcon';
import Wrapper from './styles';
import { isB2B } from '../../../../services/environment';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

export default function CoolbetHeaderAuthenticated() {
    const [{ isPhone }] = useStore(media);
    const [dynamicPromotionsCount] = useStore(stores.dynamicPromotionsCount);

    return (
        <Wrapper>
            {!isB2B() && <CoolbetHeaderAuthenticatedClock />}

            {!isB2B() && (
                <UiButton
                    size="small"
                    url={getRoute('responsible-gaming.set-limits')}
                    icon={<Svg icon="anchor" className="icon" size={1.125} />}
                />
            )}

            {isFeatureAvailable(FEATURE.INBOX) && !isPhone && <CrmInboxIcon />}

            {!isB2B() && (
                <UiButton
                    size="small"
                    isOnSurface
                    url={getRoute('promotions.list')}
                    badge={dynamicPromotionsCount}
                    icon={<Svg icon="promotions-ico" className="icon" size={1.125} />}
                />
            )}

            <CoolbetHeaderAuthenticatedMenu />

            {isFeatureAvailable(FEATURE.DEPOSIT) && (
                <UiButton
                    color="primary"
                    size="small"
                    selected={false}
                    url={getRoute('deposit')}
                    icon={<Svg icon="plus-sign" className="icon" size={1.125} />}
                >
                    {!isPhone && translate('Deposit', 'ui.account')}
                </UiButton>
            )}
        </Wrapper>
    );
}
