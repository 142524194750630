import React from 'react';
import Wrapper from './styles';
import { stores } from '../../../../../../stores';
import CoolbetHeaderAnonymous from '../../../anonymous/CoolbetHeaderAnonymous';
import { getHomeRoute, updateSiteLanguage } from '../../../../../../services/router';
import Logo from '../../../../../logo/Logo';
import UiNavLink from '../../../../../ui/nav-link/UiNavLink';
import { isB2B } from '../../../../../../services/environment';
import { FEATURE, isFeatureAvailable } from '../../../../../../services/feature';
import UiLanguageSelectMini from '../../../../../ui/language-select/mini/UiLanguageSelectMini';
import CoolbetHeaderAuthenticated from '../../../authenticated/CoolbetHeaderAuthenticated';
import { useStore } from '../../../../../../hooks/useStore';

export default function CoolbetHeaderMobileLayoutDefault() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [language] = useStore(stores.language);

    return (
        <Wrapper>
            <UiNavLink to={getHomeRoute()}>
                <Logo size={isB2B() ? 35 : 50} compact />
            </UiNavLink>
            <div className="mobile-profile-wrapper">
                {isFeatureAvailable(FEATURE.LANGUAGE_SELECTION) && !isAuthenticated && (
                    <UiLanguageSelectMini
                        currentLanguage={language}
                        shift="center"
                        onValueChange={updateSiteLanguage}
                    />
                )}
                {isAuthenticated ? <CoolbetHeaderAuthenticated /> : <CoolbetHeaderAnonymous />}
            </div>
        </Wrapper>
    );
}
