import React from 'react';
import Wrapper from './styles';
import { filterStyleProps } from '../../../styles/utils';

interface Props extends React.PropsWithChildren, React.HTMLAttributes<HTMLButtonElement> {
    onClick: () => void;
    dataTest: string;
}

export default function AuthButton({ onClick, children, dataTest, ...rest }: Props) {
    return (
        <Wrapper data-test={dataTest} onClick={onClick} {...filterStyleProps(rest)}>
            {children}
        </Wrapper>
    );
}
