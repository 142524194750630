import { getServiceUrl, httpGet } from '../services/api';
import { LiveGameMetaData } from '../services/casino/games';
import { logger } from '../services/logger';

const getUrl = (url) => getServiceUrl('casino-live-lobby', url);

export async function getLiveLobbyMetaData(): Promise<LiveGameMetaData[]> {
    try {
        const url = getUrl(`fo/metadata`);
        return await httpGet<LiveGameMetaData[]>(url);
    } catch (error) {
        logger.error('CasinoLiveLobbyMicroservice', 'getLiveLobbyMetaData', error);
        return [];
    }
}
