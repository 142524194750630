import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    z-index: 10;
    position: relative;
    background-color: var(--surface-level-2-bg);
    border-radius: var(--ui-radius);
    transition: background-color 0.2s, border-radius 0.2s;
    height: 45px;

    &:focus-within {
        .search-container {
            background-color: var(--surface-level-2-bg);
            border-radius: var(--ui-radius) var(--ui-radius) 0 0;
        }

        .search-icon {
            color: var(--color-highlight);
        }

        .search-input {
            color: var(--font-color-primary);
        }
    }

    .search-icon {
        height: 45px;
        width: 45px;
        top: 0;
        left: 0;
        padding: 10px;
        margin-left: 5px;
        color: var(--font-color-subtle);
        transition: color 0.2s;
    }

    .search-cross-container {
        position: relative;
        width: 0;
        flex: 0 0 0;
        height: 30px;
    }

    .search-cross {
        position: relative;
        height: 30px;
        width: 30px;
        cursor: pointer;
        padding: 7px;
        margin-right: 5px;
        right: 30px;
        opacity: 0;
        transition: opacity 0.2s;
        color: var(--font-color-subtle);
    }

    .search-input {
        flex: 1 1 250px;
        width: 250px;
        min-width: 0;
        background-color: transparent;
        border: unset;
        outline: unset;
        color: var(--font-color-subtle);
        height: 100%;
        caret-color: var(--color-highlight);
        transition: color 0.2s;
        font-size: 1rem;

        &::placeholder {
            color: var(--font-color-subtle);
            font-weight: 700;
            opacity: 1;
        }

        &::-ms-clear {
            display: none;
        }
    }
`;
