import React, { useEffect, useState } from 'react';
import { getLimitsByType, saveSwedishLicenseFields } from '../../../../../../microservices/responsible-gaming';
import { getFilteredTransactions } from '../../../../../../microservices/wallet';

import {
    LIMIT_PERIOD,
    LIMIT_TYPE,
    RG_ERROR_CODE,
    getLimitTooHighErrorMessage,
    getMaximumLimit,
    getOverRecommendedLimitPeriod,
    validateDepositLimitsMinimumAmount,
} from '../../../../../../services/responsible-gaming';
import { translate } from '../../../../../../services/translate';
import { TRANSACTION_TYPE } from '../../../../../../services/wallet/wallet';
import { stores } from '../../../../../../stores';
import ResponsibleGamingLimitOverRecommendedPrompt from '../../../../../responsible-gaming/limit/over-recommended-prompt/ResponsibleGamingLimitOverRecommendedPrompt';
import Snippet from '../../../../../snippet/Snippet';
import UiAlert from '../../../../../ui/alert/UiAlert';
import UiButton from '../../../../../ui/button/UiButton';
import UiPrompt from '../../../../../ui/prompt/UiPrompt';
import Wrapper from '../../../limits/styles';
import UiBox from '../../../../../ui/box/UiBox';
import Ui2FormTextInput from '../../../../../ui-2/form/text-input/Ui2FormTextInput';
import UiFormGroup from '../../../../../ui/form/group/UiFormGroup';
import Ui2Form from '../../../../../ui-2/form/Ui2Form';
import Ui2FormRadio from '../../../../../ui-2/form/radio/Ui2FormRadio';
import { useForm } from 'react-hook-form';
import UiGroup from '../../../../../ui/group/UiGroup';
import { updateProfile } from '../../../../../../microservices/users-cb';
import { useStore } from '../../../../../../hooks/useStore';

interface Props {
    isModal?: boolean;
    onAfterSubmit: () => void;
}

export default function AuthProfileValidatorLicenceRequirementSwedenDepositLimits({
    isModal = false,
    onAfterSubmit,
}: Props) {
    const [askDepositLimit] = useStore(stores.responsibleGaming.askDepositLimit);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLowerDepositLimitConfirmationPromptOpen, setIsLowerDepositLimitConfirmationPromptOpen] = useState(false);
    const [overRecommendedLimitPeriod, setOverRecommendedLimitPeriod] = useState<LIMIT_PERIOD>();
    const [isPayAndPlayFlow, setIsPayAndPlayFlow] = useState(false);
    const [user] = useStore(stores.user);
    const [isExtraField, setIsExtraField] = useState(false);
    const [isOverRecommendedLimitConfirmed, setIsOverRecommendedLimitConfirmed] = useState(false);
    const [isLowerDepositLimitConfirmed, setIsLowerDepositLimitConfirmed] = useState(false);

    const useFormMethods = useForm({
        defaultValues: {
            depositLimitAmountDay: undefined,
            depositLimitAmountMonth: undefined,
            depositLimitAmountWeek: undefined,
            sourceOfFunds: undefined,
            sourceOfFundsExtra: '',
        },
    });

    const { formState } = useFormMethods;

    function onRadioChange(event) {
        setIsExtraField(false);
        const value = event.target.value;
        if (value === 'other') {
            setIsExtraField(true);
        }
    }

    useEffect(() => {
        loadDepositLimits();
    }, []);

    useEffect(() => {
        if (user) {
            setIsPayAndPlayFlow(Boolean(user.registrationSource === 'Pay N Play'));
        }
    }, [user]);

    useEffect(() => {
        if (isModal) {
            stores.modals.isRequiredProfileInformationInquiryModalOpen.set(Boolean(askDepositLimit));
        }
    }, [askDepositLimit]);

    async function loadDepositLimits() {
        const depositLimit = await getLimitsByType(LIMIT_TYPE.DEPOSIT);

        if (
            depositLimit[LIMIT_PERIOD.DAY] &&
            depositLimit[LIMIT_PERIOD.DAY].current_limit &&
            getMaximumLimit(LIMIT_PERIOD.DAY, LIMIT_TYPE.DEPOSIT) >= depositLimit[LIMIT_PERIOD.DAY].current_limit.amount
        ) {
            useFormMethods.setValue('depositLimitAmountDay', depositLimit[LIMIT_PERIOD.DAY].current_limit.amount);
        }
        if (
            depositLimit[LIMIT_PERIOD.WEEK] &&
            depositLimit[LIMIT_PERIOD.WEEK].current_limit &&
            getMaximumLimit(LIMIT_PERIOD.WEEK, LIMIT_TYPE.DEPOSIT) >=
                depositLimit[LIMIT_PERIOD.WEEK].current_limit.amount
        ) {
            useFormMethods.setValue('depositLimitAmountWeek', depositLimit[LIMIT_PERIOD.WEEK].current_limit.amount);
        }
        if (
            depositLimit[LIMIT_PERIOD.MONTH] &&
            depositLimit[LIMIT_PERIOD.MONTH].current_limit &&
            getMaximumLimit(LIMIT_PERIOD.MONTH, LIMIT_TYPE.DEPOSIT) >=
                depositLimit[LIMIT_PERIOD.MONTH].current_limit.amount
        ) {
            useFormMethods.setValue('depositLimitAmountMonth', depositLimit[LIMIT_PERIOD.MONTH].current_limit.amount);
        }
    }

    async function isDepositLimitLowerThanPayAndPlayDeposit(
        depositLimitAmountDay: number,
        depositLimitAmountWeek: number,
        depositLimitAmountMonth: number,
    ) {
        const transactions = await getFilteredTransactions({
            created__gt: user?.created,
            type: TRANSACTION_TYPE.TRUSTLY_DEPOSIT,
        });

        if (transactions.data && transactions.data.length) {
            const depositedAmount = transactions.data.reduce((sum: number, deposit) => sum + deposit.amount_uc, 0);

            if (
                (depositLimitAmountDay && depositLimitAmountDay < depositedAmount) ||
                (depositLimitAmountWeek && depositLimitAmountWeek < depositedAmount) ||
                (depositLimitAmountMonth && depositLimitAmountMonth < depositedAmount)
            ) {
                return true;
            }
        }
        return false;
    }

    async function onSubmit() {
        setIsLoading(true);
        setError('');

        const {
            depositLimitAmountDay: depositLimitAmountDayString,
            depositLimitAmountMonth: depositLimitAmountMonthString,
            depositLimitAmountWeek: depositLimitAmountWeekString,
            sourceOfFunds,
            sourceOfFundsExtra,
        } = useFormMethods.getValues();

        const depositLimitAmountDay = Number(depositLimitAmountDayString);
        const depositLimitAmountMonth = Number(depositLimitAmountMonthString);
        const depositLimitAmountWeek = Number(depositLimitAmountWeekString);

        if (!(depositLimitAmountDay && depositLimitAmountWeek && depositLimitAmountMonth)) {
            setError(translate('You have to choose all the deposit limit periods', 'ui.registration'));
            setIsLoading(false);
            return;
        }

        const error = validateDepositLimitsMinimumAmount([
            depositLimitAmountDay,
            depositLimitAmountWeek,
            depositLimitAmountMonth,
        ]);

        if (error) {
            setError(error);
            setIsLoading(false);
            return;
        }

        const period =
            !isOverRecommendedLimitConfirmed &&
            getOverRecommendedLimitPeriod({
                [LIMIT_PERIOD.DAY]: depositLimitAmountDay,
                [LIMIT_PERIOD.WEEK]: depositLimitAmountWeek,
                [LIMIT_PERIOD.MONTH]: depositLimitAmountMonth,
            });
        if (period) {
            setOverRecommendedLimitPeriod(period);
            setIsLoading(false);
            return;
        }

        if (
            isPayAndPlayFlow &&
            !isLowerDepositLimitConfirmed &&
            (await isDepositLimitLowerThanPayAndPlayDeposit(
                depositLimitAmountDay,
                depositLimitAmountWeek,
                depositLimitAmountMonth,
            ))
        ) {
            setIsLowerDepositLimitConfirmationPromptOpen(true);
            setIsLoading(false);
            return;
        }

        try {
            await updateProfile({ source_of_funds: sourceOfFunds === 'other' ? sourceOfFundsExtra : sourceOfFunds });

            await saveSwedishLicenseFields({
                depositLimitAmountDay,
                depositLimitAmountMonth,
                depositLimitAmountWeek,
                isOverRecommendedLimitConfirmed,
            });

            onAfterSubmit();
        } catch (error: any) {
            if (error.code === RG_ERROR_CODE.LIMIT_AMOUNT_TOO_HIGH) {
                setError(getLimitTooHighErrorMessage(error));
            } else {
                setError(translate('Failed to update fields', 'ui.common'));
            }
        }

        setIsLoading(false);
    }

    if (!askDepositLimit) {
        return null;
    }

    return (
        <Wrapper>
            <div className="responsible-gaming-header">
                <div>
                    <h2>{translate('Deposit limits', 'ui.account')}</h2>
                    <Snippet snippetKey={'account.rg.deposit-limit-text-registration-swe'} />
                </div>
            </div>

            <UiFormGroup>
                <Ui2Form onSubmit={onSubmit} useFormMethods={useFormMethods} className="deposit-limit-form">
                    <div>
                        <h3>{translate('How do you finance your gambling?', 'ui.account')}</h3>
                        <Ui2FormRadio
                            name="sourceOfFunds"
                            options={[
                                { value: 'salary', label: 'Salary' },
                                { value: 'savings', label: 'Savings' },
                                { value: 'other', label: 'Other' },
                            ]}
                            radioNowrap
                            onClick={onRadioChange}
                            error={formState.errors.sourceOfFunds}
                            required
                        />
                        {isExtraField && <Ui2FormTextInput name="sourceOfFundsExtra" />}
                    </div>
                    <UiBox className="input-container">
                        <h3>{translate('Set deposit limits', 'ui.account')}</h3>
                        <h3>{translate('Period / Amount', 'ui.account')}</h3>
                        <UiGroup>
                            <Ui2FormTextInput
                                label={translate('24h', 'ui.account')}
                                placeholder={translate('Amount', 'ui.account')}
                                data-test="limit-amount-day"
                                name="depositLimitAmountDay"
                                type="number"
                                min={0}
                                max={getMaximumLimit(
                                    LIMIT_PERIOD.DAY,
                                    LIMIT_TYPE.DEPOSIT,
                                    useFormMethods.getValues().depositLimitAmountDay,
                                )}
                            />
                            <Ui2FormTextInput
                                label={translate('1 Week', 'ui.account')}
                                placeholder={translate('Amount', 'ui.account')}
                                data-test="limit-amount-week"
                                name="depositLimitAmountWeek"
                                type="number"
                                min={0}
                                max={getMaximumLimit(
                                    LIMIT_PERIOD.WEEK,
                                    LIMIT_TYPE.DEPOSIT,
                                    useFormMethods.getValues().depositLimitAmountWeek,
                                )}
                            />

                            <Ui2FormTextInput
                                label={translate('1 Month', 'ui.account')}
                                placeholder={translate('Amount', 'ui.account')}
                                data-test="limit-amount-month"
                                name="depositLimitAmountMonth"
                                type="number"
                                min={0}
                                max={getMaximumLimit(
                                    LIMIT_PERIOD.MONTH,
                                    LIMIT_TYPE.DEPOSIT,
                                    useFormMethods.getValues().depositLimitAmountMonth,
                                )}
                            />
                        </UiGroup>
                    </UiBox>
                    <UiButton color="primary" isFormSubmitButton isLoading={isLoading}>
                        {translate('Confirm', 'ui.account')}
                    </UiButton>
                </Ui2Form>
            </UiFormGroup>
            {error && <UiAlert failure>{error}</UiAlert>}
            <UiPrompt
                open={isLowerDepositLimitConfirmationPromptOpen}
                message={translate(
                    'Are you sure you want to set deposit limit lower than previosuly deposited amount?',
                    'ui.pay-and-play',
                )}
                onAccept={() => {
                    setIsLowerDepositLimitConfirmed(true);
                    onSubmit();
                }}
                onDismiss={() => {
                    setIsLowerDepositLimitConfirmationPromptOpen(false);
                }}
                isCoolbearVisible={false}
            />
            <ResponsibleGamingLimitOverRecommendedPrompt
                period={overRecommendedLimitPeriod}
                onAccept={() => {
                    setOverRecommendedLimitPeriod(undefined);
                }}
                onDismiss={() => {
                    setOverRecommendedLimitPeriod(undefined);
                    setIsOverRecommendedLimitConfirmed(true);
                    onSubmit();
                }}
            />
        </Wrapper>
    );
}
