import styled from 'styled-components';

export default styled.div`
    .bonus-offers {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 1rem 0;
        > * {
            flex: 1;
        }
        p {
            width: 120px;
            margin: 0;
        }
    }

    .subscription-options {
        align-items: flex-end;
    }
    .create-account-button {
        margin-top: ${({ theme }) => theme.layout.gutter};
    }
`;
