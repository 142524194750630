import styled from 'styled-components';

import { lighten } from 'polished';

export default styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: ${({ theme }) => lighten(0.05, theme.box.background)};
    border-radius: var(--ui-radius);
    box-shadow: var(--ui-shadow), inset 1px 1px 4px -2px ${({ theme }) => lighten(0.2, theme.box.background)};
    padding: ${({ theme }) => theme.layout.gutter};
    width: 100%;
    z-index: -1;
    .submit-timer {
        display: flex;
        align-items: center;
        .time {
            display: flex;
            flex-direction: column;
            align-items: center;
            &:not(:last-of-type) {
                margin-right: 0.4rem;
            }
        }
        .time-number {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 40px;
            font-weight: bold;
            color: ${({ theme }) => theme.colors.secondary};
            border-radius: 5px;
            font-size: 0.85rem;
            letter-spacing: 1px;
        }
        .time-label {
            font-size: 0.5rem;
            letter-spacing: 1px;
            text-transform: uppercase;
            text-align: center;
            color: var(--font-color-secondary);
            background: none;
            margin-bottom: ${({ theme }) => theme.layout.gutterHalf};
            min-height: inherit;
        }
    }
`;
