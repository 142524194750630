import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

type StyledProps = {
    $isRetailLayout?: boolean;
    size: 'default' | 'large' | 'small' | 'tiny';
    block?: boolean;
    selected?: boolean;
};

export default styled.button<StyledProps>`
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: var(--font-family);
    font-size: var(--button-font-size);
    font-weight: var(--button-font-weight);
    line-height: 1;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    user-select: none;

    border-radius: var(--ui-radius);
    padding: 0 0.8rem;

    transition: background-color 0.2s, color 0.2s, border-color 0.2s;

    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;

    .badge {
        position: absolute;
        top: -0.5rem;
        right: -0.5rem;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 17px;
        height: 17px;

        background-color: var(--color-info);
        border-radius: 50%;

        font-size: 0.65rem;
        color: #fff;
        text-align: center;
        font-weight: var(--font-weight-medium);
        line-height: 1;

        transform: translate(0, 0);
        transition: transform 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s;
    }
    > *:not(:last-child) {
        margin-right: var(--spacing-8);
    }
    @media ${mediaQuery.isPhone} {
        font-weight: var(--button-font-weight-mobile);
        ${({ theme }) => theme.optimizeFontRendering};
    }

    ${({ color }) => color && colors[color].css};
    ${({ size }) => size && sizes[size].css};

    ${({ block }) =>
        block &&
        css`
            width: 100%;
        `};

    ${({ selected }) => {
        if (selected) {
            return states.active;
        }
        if (selected === undefined) {
            return css`
                .active > & {
                    ${states.active}
                }
            `;
        }
    }};

    &[disabled] {
        pointer-events: none;
        cursor: not-allowed;
        filter: contrast(0.8);
        opacity: 0.6;
        box-shadow: none;
    }
`;

const colors = {
    default: {
        css: css<StyledProps>`
            background-color: var(--button-bg);
            color: var(--button-text-color);
            border: var(--button-border);
            box-shadow: var(--button-shadow);
            ${({ $isRetailLayout }) => {
                return $isRetailLayout
                    ? ''
                    : `&:hover {
                background-color: var(--button-bg-hover);
                color: var(--button-text-color-hover);
            }`;
            }};
        `,
    },
    primary: {
        css: css<StyledProps>`
            background: var(--button-primary-bg);
            color: var(--button-primary-text-color);
            border: var(--button-primary-border);
            box-shadow: var(--button-primary-shadow);
            ${({ $isRetailLayout }) => {
                return $isRetailLayout
                    ? ''
                    : `&:hover {
                background-color: var(--button-primary-bg-hover);
                color: var(--button-primary-text-color-hover);
            }`;
            }};
        `,
    },
    ghost: {
        css: css<StyledProps>`
            background-color: transparent;
            color: var(--button-ghost-text-color);
            border: none;
            box-shadow: none;
            ${({ $isRetailLayout }) => {
                return $isRetailLayout
                    ? ''
                    : `&:hover {
                background-color: var(--button-ghost-bg-hover);
            }`;
            }};
        `,
    },

    outline: {
        css: css`
            background-color: transparent;
            color: var(--button-outline-text-color);
            border: var(--button-outline-border);
            box-shadow: none;
        `,
    },

    warning: {
        css: css<StyledProps>`
            background-color: var(--color-lost);
            color: #fff;
            border: 1px solid var(--color-lost);
            box-shadow: none !important;
            ${({ $isRetailLayout }) => {
                return $isRetailLayout
                    ? ''
                    : `&:hover {
                background-color: var(--color-lost);
                color: #fff;
            }`;
            }};
        `,
    },
};

const sizes = {
    default: {
        css: css`
            padding: var(--button-size-default);
            @media ${mediaQuery.isPhone} {
                font-size: 0.85rem;
                min-height: 50px;
            }
            .button-icon svg {
                width: 1.1rem;
                height: 1.1rem;
            }
        `,
    },
    tiny: {
        css: css`
            padding: var(--button-size-tiny);
            font-size: var(--button-font-size-tiny);
            letter-spacing: 0.01em;
            @media ${mediaQuery.isPhone} {
                font-size: 0.55rem;
            }
            .button-icon svg {
                width: 0.5rem;
                height: 0.5rem;
            }
        `,
    },
    small: {
        css: css`
            padding: var(--button-size-small);
            font-size: var(--button-font-size-small);
            letter-spacing: 0.03em;
            @media ${mediaQuery.isPhone} {
                font-size: 0.675rem;
            }
            .button-icon svg {
                width: 0.75rem;
                height: 0.75rem;
            }
        `,
    },
    large: {
        css: css`
            padding: var(--button-size-large);
            font-size: var(--button-font-size-large);
            @media ${mediaQuery.isPhone} {
                font-size: 0.95rem;
            }
            .button-icon svg {
                width: 2.25rem;
                height: 2.25rem;
            }
        `,
    },
};

const states = {
    active: css<{ $isRetailLayout?: boolean }>`
        background-color: var(--button-active-bg);
        border: var(--button-active-border);
        box-shadow: var(--button-active-shadow);
        color: var(--button-active-text-color);
        ${({ $isRetailLayout }) => {
            return $isRetailLayout
                ? ''
                : `&:hover {
        background-color: var(--button-active-bg-hover);
        color: var(--button-active-text-color-hover);
    }`;
        }};
    `,
};
