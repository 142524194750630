import { getGeneratedUserData } from '../../microservices/users';
import { REGISTRATION_FLOW } from '../auth';

export async function generateUserData(flow, country) {
    const generatedData = await getGeneratedUserData(country);

    if (flow === REGISTRATION_FLOW.PAY_AND_PLAY) {
        return {
            email: generatedData.email,
            alias: generatedData.alias,
            phoneNumber: generatedData.phoneNumber,
            phonePrefix: generatedData.phonePrefix,
            password: generatedData.password,
        };
    }

    if (flow === REGISTRATION_FLOW.EMAIL) {
        return generatedData;
    }
}
