import React from 'react';
import Wrapper from './styles';
import { translate } from '../../../services/translate';
import { isRetail } from '../../../services/environment';
interface Props {
    type: string;
    extraInfo?: string;
}

export default function SportBadge({ type, extraInfo }: Props) {
    if (!type) {
        return null;
    }

    function formatType() {
        if (type === 'betbuilder' && isRetail()) {
            return 'SGP';
        }
        return translate(type, 'sport.badge');
    }

    return (
        <Wrapper type={type} className="sport-badge">
            {`${formatType()} ${extraInfo ? `- ${extraInfo}` : ''}`}
        </Wrapper>
    );
}
