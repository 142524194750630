import styled, { css } from 'styled-components';

const itemsGutter = ({ layoutGutterInRem }) => layoutGutterInRem || 'var(--spacing-4)';
const flexConfiguration = ({ expand }) => (expand ? '1 1 auto' : '');
const flexFlowRow = ({ reversed }) => (reversed ? 'row-reverse nowrap' : 'row nowrap');
const flexFlowColumn = ({ reversed }) => (reversed ? 'column-reverse nowrap' : 'column nowrap');

const horizontal = ({ vertical, shouldOverflow }) =>
    !vertical &&
    !shouldOverflow &&
    css`
        flex-flow: ${flexFlowRow};

        > * {
            flex: ${flexConfiguration};

            &:not(:last-child) {
                margin-right: ${itemsGutter};
            }
        }
    `;

const horizontalOverflow = ({ vertical, shouldOverflow }) =>
    !vertical &&
    shouldOverflow &&
    css`
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        .UiGroup-items {
            display: flex;
            flex-flow: ${flexFlowRow};

            > * {
                flex: ${flexConfiguration};

                &:not(:last-child) {
                    margin-right: ${itemsGutter};
                }
            }
        }
    `;

const vertical = ({ vertical, shouldOverflow }) =>
    vertical &&
    !shouldOverflow &&
    css`
        flex-flow: ${flexFlowColumn};

        > * {
            flex: ${flexConfiguration};

            &:not(:last-child) {
                margin-bottom: ${itemsGutter};
            }
        }
    `;

const verticalOverflow = ({ vertical, shouldOverflow }) =>
    vertical &&
    shouldOverflow &&
    css`
        overflow: auto;

        .UiGroup-items {
            display: flex;
            flex-flow: ${flexFlowColumn};

            * {
                flex: ${flexConfiguration};

                &:not(:last-child) {
                    margin-bottom: ${itemsGutter};
                }
            }
        }
    `;

const verticallyCenteredColumn = (props) =>
    props.verticallyCentered &&
    props.vertical &&
    css`
        justify-content: center;
    `;

const verticallyCenteredRow = (props) =>
    props.verticallyCentered &&
    !props.vertical &&
    css`
        align-items: center;
    `;

const horizontallyCenteredColumn = (props) =>
    props.horizontallyCentered &&
    props.vertical &&
    css`
        align-items: center;
    `;

const horizontallyCenteredRow = (props) =>
    props.horizontallyCentered &&
    !props.vertical &&
    css`
        justify-content: center;
    `;

export default styled.div`
    display: flex;

    &::-webkit-scrollbar {
        display: none;
    }

    ${horizontal}
    ${horizontalOverflow}
    ${vertical}
    ${verticalOverflow}
    
    ${verticallyCenteredColumn};
    ${verticallyCenteredRow};
    ${horizontallyCenteredColumn};
    ${horizontallyCenteredRow};
`;
