import React, { useState } from 'react';
import { GeoComplyMobileAppRequiredNotificationPermissionHint } from '../../../../../../services/geocomply/mobile';
import { translate } from '../../../../../../services/translate';
import UiButton from '../../../../../ui/button/UiButton';
import UiGroup from '../../../../../ui/group/UiGroup';
import GeocomplyRetryGeolocationButton from '../../../../retry-geolocation-button/GeocomplyRetryGeolocationButton';

interface Props {
    hint: GeoComplyMobileAppRequiredNotificationPermissionHint;
}

export default function GeocomplyMobileHintHandlerAppRequiredNotificationPermission({ hint }: Props) {
    const [isRetryAllowed, setIsRetryAllowed] = useState(false);

    function openApp() {
        window.location.assign(hint.androidNotificationSettingsIntentUri);
        setIsRetryAllowed(true);
    }

    function openSettings() {
        window.location.assign(hint.androidNotificationSettingUrl);
        setIsRetryAllowed(true);
    }

    return (
        <UiGroup vertical>
            <UiGroup>
                <UiButton onClick={openApp} color="primary">
                    {translate('Open app', 'ui.geocomply')}
                </UiButton>

                <UiButton onClick={openSettings} color="primary">
                    {translate('Open settings', 'ui.geocomply')}
                </UiButton>

                {isRetryAllowed && <GeocomplyRetryGeolocationButton />}
            </UiGroup>
        </UiGroup>
    );
}
