import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { stores } from '../../../../stores';
import { translate } from '../../../../services/translate';
import { getRoute, isActiveRoute } from '../../../../services/router';
import Svg from '../../../svg/Svg';
import SportTreeRetailMenuButton from './menu-button/SportTreeRetailMenuButton';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import SportCategoryIcon from '../../category-icon/SportCategoryIcon';
import { useStore } from '../../../../hooks/useStore';

interface Link {
    icon: string;
    label: string;
    route: string;
    isSportIcon?: boolean;
}

export default function SportTreeRetail() {
    const initialVisibleCategoryCount = isFeatureAvailable(FEATURE.HORSE_RACING) ? 5 : 6;
    const fakeParentCategories = [];
    const [tree] = useStore(stores.sports.prematchTree);
    const [isDisplayingAll, setIsDisplayingAll] = useState(false);
    const [links, setLinks] = useState<Link[]>([]);
    const [parlayCards] = useStore(stores.sports.parlayCards);
    const [isRacingAvailable] = useStore(stores.isRacingAvailable);

    useEffect(() => {
        getLinks();
    }, []);

    function getLinks() {
        const links: Link[] = [
            {
                label: translate('sports.navigation.sports-lobby'),
                route: getRoute('sport.recommendations'),
                icon: 'top-sport',
            },
            {
                label: 'Live',
                route: getRoute('sport.live'),
                icon: 'live-stream',
            },
        ];
        if (isFeatureAvailable(FEATURE.HORSE_RACING) && isRacingAvailable) {
            links.push({
                label: translate('Horse Racing', 'ui.header'),
                route: getRoute(`horse-racing`),
                icon: 'horse-racing',
                isSportIcon: true,
            });
        }
        if (isFeatureAvailable(FEATURE.PARLAY_CARD) && parlayCards.length > 0) {
            links.push({
                label: translate('Parlay Cards', 'ui.sportsbook'),
                route: getRoute('sport.parlay-cards'),
                icon: 'parley-card',
            });
        }
        if (isFeatureAvailable(FEATURE.CONTESTS)) {
            links.push({
                label: translate('Contests', 'ui.sportsbook'),
                route: getRoute('sport.contests'),
                icon: 'casino-race',
            });
        }
        setLinks(links);
    }

    return (
        <Wrapper>
            <div className="main-sports-menu">
                {links.map((product) => (
                    <SportTreeRetailMenuButton
                        key={product.route}
                        url={product.route}
                        icon={<Svg icon={product.icon} />}
                        selected={isActiveRoute(product.route, false)}
                    >
                        <span
                            dangerouslySetInnerHTML={{
                                __html: product.label,
                            }}
                        />
                    </SportTreeRetailMenuButton>
                ))}

                {(tree?.children || fakeParentCategories).slice(0, initialVisibleCategoryCount).map((sport) => (
                    <SportTreeRetailMenuButton
                        key={sport.slug}
                        url={`${getRoute('sport')}/${sport.slug}`}
                        icon={<SportCategoryIcon icon={sport.icon} size={2.5} />}
                        selected={isActiveRoute(`${getRoute('sport')}/${sport.slug}`, false)}
                    >
                        <span
                            dangerouslySetInnerHTML={{
                                __html: sport.name,
                            }}
                        />
                    </SportTreeRetailMenuButton>
                ))}

                <SportTreeRetailMenuButton
                    onClick={() => setIsDisplayingAll(!isDisplayingAll)}
                    icon={isDisplayingAll ? <Svg icon="crossmark" /> : <Svg icon="menu-dots-horizontal" />}
                >
                    {translate('show all', 'ui.sportsbook')}
                </SportTreeRetailMenuButton>
            </div>
            {isDisplayingAll && (
                <div className="expanded-sports-menu">
                    {(tree?.children || fakeParentCategories).slice(initialVisibleCategoryCount).map((sport) => (
                        <SportTreeRetailMenuButton
                            key={sport.slug}
                            onClick={() => setIsDisplayingAll(!isDisplayingAll)}
                            url={`${getRoute('sport')}/${sport.slug}`}
                            icon={<SportCategoryIcon icon={sport.icon} size={2.5} />}
                            selected={isActiveRoute(`${getRoute('sport')}/${sport.slug}`, false)}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: sport.name,
                                }}
                            />
                        </SportTreeRetailMenuButton>
                    ))}
                </div>
            )}
        </Wrapper>
    );
}
