import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import BlogPreview from '../../preview/BlogPreview';
import Loader from '../../../loader/Loader';
import { loadArticlesByParams } from '../../../../microservices/l10n';
import { BlogPost } from '../../../../services/cms-blog';

interface Props {
    forArticle?: BlogPost;
}

export default function BlogArticleSideArticles({ forArticle }: Props) {
    const [articles, setArticles] = useState<BlogPost[]>([]);

    useEffect(() => {
        if (forArticle?.category) {
            loadArticlesByParams({ category: forArticle.category, limit: 5, excludeArticleId: forArticle.id }).then(
                setArticles,
            );
        }
    }, [forArticle]);

    return (
        <Wrapper>
            {!articles && <Loader />}
            {articles.map((article) => (
                <div className="side-component-content" key={article.id}>
                    <BlogPreview key={article.id} article={article} />
                </div>
            ))}
        </Wrapper>
    );
}
