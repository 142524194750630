import React, { useEffect, useState } from 'react';
import { getBonusCodeCounter } from '../../../microservices/bonuses';

interface Props {
    'bonus-id': number;
}

export default function CmsBonusCodeCounter({ 'bonus-id': bonusId }: Props) {
    const [bonusCodeCount, setBonusCodeCount] = useState(0);

    useEffect(() => {
        fetchLoaderboardCount();
    }, []);

    async function fetchLoaderboardCount() {
        setBonusCodeCount(await getBonusCodeCounter(bonusId));
    }

    return <span>{bonusCodeCount}</span>;
}
