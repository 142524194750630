import React from 'react';
import Wrapper from './styles';
import { translate } from '../../../../../services/translate';
import { stores } from '../../../../../stores';
import classNames from 'classnames';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    attributes: number[];
    hiddenGameLabels?: number[];
}

export default function CasinoThumbnailLabelsAttributes({ attributes, hiddenGameLabels = [] }: Props) {
    const [gameLabels] = useStore(stores.casino.gameLabels);

    const labelTypes = {
        NEW: 'new',
        HOT: 'hot',
        EXCLUSIVE: 'exclusive',
    };

    return (
        <Wrapper>
            {gameLabels.map(
                (gameLabel) =>
                    attributes.includes(gameLabel.id) &&
                    !hiddenGameLabels.includes(gameLabel.id) && (
                        <div
                            key={gameLabel.id}
                            className={classNames('casino-thumbnail-labels-attribute', labelTypes[gameLabel.name])}
                        >
                            {translate(`ui.casino.labels.${gameLabel.id}`)}
                        </div>
                    ),
            )}
        </Wrapper>
    );
}
