import { createTheme } from '@mui/material/styles';
import merge from 'lodash/merge';
import { darkTheme } from '../theme/darkTheme';

const themeBaseValues = {
    shape: {
        borderRadius: 2,
    },
};

export const ThemeList = {
    dark: createTheme(addBaseValuesToTheme(darkTheme)),
};

function addBaseValuesToTheme(theme) {
    return merge({}, theme, themeBaseValues);
}
