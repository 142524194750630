import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

export default styled.div`
    .stake-input {
        position: relative;

        width: 100%;
        margin-top: 1.2rem;

        &.input-to-win input {
            background-color: var(--betslip-stake-towin-bg);
            border: var(--betslip-stake-towin-border);
        }

        input {
            margin: 0;
            padding: 0.2rem;
            height: 3.5rem;
            width: 100%;
            -webkit-appearance: none;
            -moz-appearance: textfield;
            outline: none;

            background-color: transparent;

            border: var(--betslip-stake-border);
            border-radius: var(--ui-radius);

            color: var(--font-color-primary);
            font-size: 1.4rem;
            font-weight: var(--font-weight-bold);
            font-family: var(--font-family);
            ${({ theme }) => theme.optimizeFontRendering};
            text-align: center;

            @media ${mediaQuery.isPhone} {
                height: 70px;
                font-size: 1.4rem;
            }

            &:focus {
                background-color: var(--betslip-stake-bg-focused);
            }
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            &[disabled] {
                opacity: 0.4;
                pointer-events: none;
            }
        }
        .stake-input-label {
            position: absolute;
            top: -7px;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: 1;
            padding: 0.2rem 0.4rem;
            background: var(--surface-level-2-bg);

            color: var(--betslip-stake-label-color);
            font-size: 0.625rem;
            white-space: nowrap;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};
            &.label-to-win {
                color: var(--betslip-stake-towin-label-color);
            }
            @media ${mediaQuery.isPhone} {
                font-size: 0.625rem;
            }
        }
        .available-balance {
            position: absolute;
            bottom: 0.4rem;
            left: 0.4rem;

            display: flex;
            align-items: center;

            color: var(--font-color-secondary);
            font-size: 0.75rem;

            svg {
                width: 12px;
                margin-right: 0.4rem;
            }
        }
    }
    .quick-stake-buttons {
        display: flex;
        align-items: center;
        width: 100%;

        margin: 0.4rem 0;

        > * {
            flex: 1;
            animation: zoomIn 200ms ease-in;
            animation-fill-mode: both;
        }

        > *:not(:last-child) {
            margin-right: 0.1rem;
        }
    }

     {
        ${[1, 2, 3, 4, 5].map(
            (i) => css`
                .quick-stake-buttons > *:nth-child(${i}) {
                    animation-delay: ${i * 50}ms;
                }
            `,
        )}
    }

    .remove-stake-button {
        position: absolute;
        z-index: 5;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        padding: var(--spacing-12);

        display: flex;
        align-items: center;
        justify-content: center;

        color: var(--font-color-subtle);
        cursor: pointer;
        svg {
            width: 1.25rem;
        }
    }

    .retail-stake-input {
        pointer-events: none;
        caret-color: transparent;
    }
`;
