import styled from 'styled-components';

export default styled.div`
    .user-email {
        align-items: center;
        color: ${({ theme }) => theme.colors.success};
    }

    .icon-wrapper,
    svg {
        height: 32px;
        width: 32px;
        fill: ${({ theme }) => theme.colors.success};
    }
`;
