import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { stores } from '../stores';
import { logger } from '../services/logger';

const getUrl = (url) => getServiceUrl('happy-hour', url);

export async function loadActiveHappyHour() {
    try {
        const url = getUrl('user/active');

        const activeHappyHour = await httpGet<any>(url);

        stores.happyHour.active.set(activeHappyHour[0]);
    } catch (error) {
        logger.error('HappyHourMicroservice', 'loadActiveHappyHour', error);
    }
}

export async function loadAvailableHappyHour() {
    try {
        const url = getUrl('user');

        const availableHappyHours = await httpGet<any>(url);

        stores.happyHour.available.set(availableHappyHours);
    } catch (error) {
        logger.error('HappyHourMicroservice', 'loadAvailableHappyHour', error);
    }
}

export async function optOutFromHappyHourCrm() {
    try {
        const url = getUrl('opt-out');
        await httpPost(url);
    } catch (error) {
        logger.error('HappyHourMicroservice', 'optOutFromHappyHourCrm', error);
    }
}

export async function optInToHappyHourCrmCheck() {
    try {
        const url = getUrl('opt-out');
        const isHappyHourOptOut = await httpGet(url);
        stores.happyHour.optIn.set(!isHappyHourOptOut);
    } catch (error) {
        logger.error('HappyHourMicroservice', 'optInToHappyHourCrmCheck', error);
    }
}

export async function optInToHappyHourCrm() {
    try {
        const url = getUrl('opt-out');
        await httpPost(url, {
            isOptIn: true,
        });
        stores.happyHour.optIn.set(true);
    } catch (error) {
        logger.error('HappyHourMicroservice', 'optInToHappyHourCrm', error);
    }
}
