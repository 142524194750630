import React from 'react';
import { getActiveCurrency } from '../../../../services/currency';
import {
    LIMIT_PERIOD,
    RECOMMENDED_DEPOSIT_LIMITS,
    getLimitPeriodTranslation,
} from '../../../../services/responsible-gaming';
import { translate } from '../../../../services/translate';
import { media } from '../../../../stores/media/media';
import UiPrompt from '../../../ui/prompt/UiPrompt';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    onAccept: () => void;
    onDismiss: () => void;
    period?: LIMIT_PERIOD;
}

export default function ResponsibleGamingLimitOverRecommendedPrompt({ onAccept, onDismiss, period }: Props) {
    const [{ isPhoneMedium, isPhoneMin }] = useStore(media);
    if (!period) {
        return null;
    }

    return (
        <UiPrompt
            open
            dismissPhrase={translate('I can afford this limit', 'ui.responsible-gaming')}
            acceptPhrase={translate('Review the limit', 'ui.responsible-gaming')}
            onAccept={onAccept}
            onDismiss={(_, source) => source === 'dismissButtonClick' && onDismiss()}
            message={translate(
                'It seems you are setting a high limit which is above the recommended amount of {{ limit }} {{ currency }} per {{ period }}. ' +
                    'Due to the risks involved in gambling with high limit, ' +
                    'we kindly ask you: Is this limit amount affordable to you?',
                'ui.responsible-gaming',
                {
                    limit: RECOMMENDED_DEPOSIT_LIMITS[period],
                    currency: getActiveCurrency(),
                    period: getLimitPeriodTranslation(period),
                },
            )}
            isCoolbearVisible={false}
            maxWidth={800}
            buttonsVertical={isPhoneMedium || isPhoneMin}
        />
    );
}
