import React, { useEffect, useState } from 'react';
import { logger } from '../../../../services/logger';
import { getMyBetradarTickets } from '../../../../microservices/betradar-vs';
import { getMyHighlightGamesTickets } from '../../../../microservices/highlight-games';
import { Ticket } from '../../../../services/sports/types';
import VirtualSportsTicket from '../VirtualSportsTicket';
import UiDotsLoader from '../../../ui/dots-loader/UiDotsLoader';
import UiAlert from '../../../ui/alert/UiAlert';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import { VIRTUAL_SPORTS_PROVIDERS } from '../../../../services/virtual-sports/constants';
import SportTotals from '../../../sport/totals/SportTotals';
import { TIME_FILTER } from '../../../../services/bet-history';
import { isFeatureAvailable, FEATURE } from '../../../../services/feature';
import orderBy from 'lodash/orderBy';
import UiButton from '../../../ui/button/UiButton';
import { getStoreValue } from '../../../../stores/store/utils';
import { PRODUCT } from '../../../../types/common';

interface Props {
    isCompactView?: boolean;
}

export default function VirtualSportsTicketList({ isCompactView }: Props) {
    const compactPageSize = 5;
    const limit = isCompactView ? compactPageSize : getStoreValue(stores.betHistory.pageSize);
    const [tickets, setTickets] = useState<Ticket[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [hasMoreBetradarTickets, setHasMoreBetradarTickets] = useState(true);
    const [hasMoreHighlightGamesTickets, setHasMoreHighlightGamesTickets] = useState(true);
    const [betradarTicketsOffset, setBetradarTicketsOffset] = useState(0);
    const [highlightGamesTicketsOffset, setHighlightGamesTicketsOffset] = useState(0);

    useEffect(() => {
        loadTickets();
    }, []);

    async function loadTickets() {
        setIsLoading(true);
        setIsError(false);
        try {
            const betradarTicketsPromise = hasMoreBetradarTickets
                ? getMyBetradarTickets(limit, betradarTicketsOffset)
                : { tickets: [], count: 0 };
            const highlightGamesTicketsPromise =
                isFeatureAvailable(FEATURE.VS_HIGHLIGHT_GAMES) && hasMoreHighlightGamesTickets
                    ? getMyHighlightGamesTickets(limit, highlightGamesTicketsOffset)
                    : { tickets: [], count: 0 };
            const [betradarTickets, highlightGamesTickets] = await Promise.all([
                betradarTicketsPromise,
                highlightGamesTicketsPromise,
            ]);

            const mergedTickets = orderBy(
                [
                    ...betradarTickets.tickets.map((ticket) => ({
                        ...ticket,
                        provider: VIRTUAL_SPORTS_PROVIDERS.BETRADAR,
                    })),

                    ...highlightGamesTickets.tickets.map((ticket) => ({
                        ...ticket,
                        provider: VIRTUAL_SPORTS_PROVIDERS.HIGHLIGHT_GAMES,
                    })),
                ],
                'created_at',
                'desc',
            );
            const slicedTickets = mergedTickets.slice(0, limit);
            const disclosedTicketsCountByProvider = slicedTickets.reduce(
                (counts, ticket) => {
                    counts[ticket.provider] += 1;
                    return counts;
                },
                {
                    [VIRTUAL_SPORTS_PROVIDERS.BETRADAR]: betradarTicketsOffset,
                    [VIRTUAL_SPORTS_PROVIDERS.HIGHLIGHT_GAMES]: highlightGamesTicketsOffset,
                },
            );
            setTickets([...tickets, ...slicedTickets]);
            setHasMoreBetradarTickets(
                betradarTickets.count > disclosedTicketsCountByProvider[VIRTUAL_SPORTS_PROVIDERS.BETRADAR],
            );

            setHasMoreHighlightGamesTickets(
                highlightGamesTickets.count > disclosedTicketsCountByProvider[VIRTUAL_SPORTS_PROVIDERS.HIGHLIGHT_GAMES],
            );
            setBetradarTicketsOffset(disclosedTicketsCountByProvider[VIRTUAL_SPORTS_PROVIDERS.BETRADAR]);
            setHighlightGamesTicketsOffset(disclosedTicketsCountByProvider[VIRTUAL_SPORTS_PROVIDERS.HIGHLIGHT_GAMES]);
        } catch (error) {
            logger.error('VirtualSportsTicketList', 'loadTickets', error);
            setIsError(true);
        }
        setIsLoading(false);
    }

    return (
        <>
            {!isCompactView && <SportTotals product={PRODUCT.VIRTUAL_SPORTS} selectedDateFilter={TIME_FILTER.ALL} />}
            {tickets.map((ticket) => (
                <VirtualSportsTicket key={ticket.id} ticket={ticket} isCompactView={isCompactView} />
            ))}
            {isLoading && !isError && <UiDotsLoader />}
            {isError && <UiAlert failure>{translate('Could not load tickets', 'ui.account')}</UiAlert>}
            {(hasMoreBetradarTickets || hasMoreHighlightGamesTickets) && !isLoading && !isError && !isCompactView && (
                <UiButton onClick={loadTickets} block>
                    {translate('Load more', 'ui.common')}
                </UiButton>
            )}
        </>
    );
}
