import React from 'react';
import Wrapper from './styles';
import Svg from '../../../../svg/Svg';
import { stores } from '../../../../../stores';
import UiButton from '../../../../ui/button/UiButton';
import { translate } from '../../../../../services/translate';
import moment from 'moment';
import CmsQuizCollectionQuizUserScore from '../user-score/CmsQuizCollectionQuizUserScore';
import { QUIZ_STATUS } from '../../../../../services/quiz';
import UiAnimate from '../../../../ui/animate/UiAnimate';
import { Quiz } from '../../../../../microservices/quiz';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    onViewingAnswers: () => void;
    quiz: Quiz;
}

export default function CmsQuizCollectionQuizSubmission({ quiz, onViewingAnswers = () => {} }: Props) {
    const [language] = useStore(stores.language);
    const isSubmissionTimeEnded = moment(quiz.submission_end_time) < moment();

    return (
        <Wrapper>
            <UiAnimate animationIn="fadeIn" animationOut="fadeOut">
                <div className="submitted-answers-container">
                    {isSubmissionTimeEnded && quiz.status === QUIZ_STATUS.RESULTED ? (
                        <CmsQuizCollectionQuizUserScore quiz={quiz} />
                    ) : (
                        <>
                            {quiz.status === QUIZ_STATUS.UNRESOLVED ? (
                                <div className="quiz-resolving-message">
                                    {translate('Quiz is being resolved', 'ui.quiz')}
                                </div>
                            ) : (
                                <>
                                    <div className="submitted-answers-title">
                                        {translate('Great! We saved your answers.', 'ui.quiz')}
                                    </div>
                                    <div>
                                        <Svg icon="checkmark" className="submitted-icon animated bounceIn" />
                                    </div>
                                </>
                            )}
                        </>
                    )}

                    <div dangerouslySetInnerHTML={{ __html: quiz.submitted_text[language] }} />
                    <UiButton color="primary" block className="view-answers-button" onClick={onViewingAnswers}>
                        {translate('View your answers', 'ui.quiz')}
                    </UiButton>
                </div>
            </UiAnimate>
        </Wrapper>
    );
}
