import every from 'lodash/every';
import {
    placeFreeBetRequest,
    placeFreeBetV2Request,
    placeMultiplierBetRequest,
    placeRiskFreeBetRequest,
} from '../../microservices/bets';
import { getOfferedBonuses } from '../../microservices/bonuses';
import { stores, useStoreWithSelector } from '../../stores';
import { translate } from '../translate';
import { createBetPlaceDataObjectFromStoreByMarketId, getErrorMessage, placeBetWrap } from './betslip';
import { BET_TYPE, BonusType, MATCH_STATUS } from './constants';
import { convertOdds } from '../odds-format';
import { logger } from '../logger';
import moment from 'moment';
import { getActiveCurrency } from '../currency';
import { getStoreValue } from '../../stores/store/utils';

export const bonusLabelByType = {
    [BonusType.FreeBet]: 'Free bet',
    [BonusType.FreeBetV2]: 'Free bet',
    [BonusType.RiskFreeBet]: 'Risk free bet',
};

export function createBonusBetRequestObject(marketId: string, isManualAcceptance = false, isForceDuplicate = false) {
    const betPlacingData = createBetPlaceDataObjectFromStoreByMarketId(marketId, isManualAcceptance, isForceDuplicate);
    const { code, userBonusId } = getActiveEligibleBonus();
    // TODO: maybe we should unify the backend requests for bonus-bets
    return {
        ...betPlacingData,
        bets: betPlacingData.bets?.length ? betPlacingData.bets.map((bet) => ({ ...bet, code, userBonusId })) : [],
        code,
        userBonusId,
    };
}

export function getBonusBetErrorMessage(error) {
    const activeBonus = getActiveEligibleBonus() || {};
    const activeBonusReplaced = { ...activeBonus, odds_value: convertOdds(activeBonus?.odds_value) };
    return getErrorMessage(error) || translate(error.message || error, 'ui.bonus', activeBonusReplaced);
}

export async function placeFreeBet(isManualAcceptance: boolean, allowErrorForMarket = false, isForceDuplicate = false) {
    const isError = await placeBetWrap(placeFreeBetRequest)(isManualAcceptance, allowErrorForMarket, isForceDuplicate);
    if (!isError) {
        try {
            await loadOfferedBetTypeBonuses(BonusType.FreeBet);
        } catch (e) {
            logger.info('SportsBonusBetsService', 'placeFreeBet', e);
        }
    }
    return isError;
}

export async function loadOfferedBonuses() {
    const response = await getOfferedBonuses({ currency: getActiveCurrency() });
    stores.sports.bonusBets.set({
        free_bet: response.free_bet || [],
        free_bet_v2: response.free_bet_v2 || [],
        risk_free_bet: response.risk_free_bet || [],
        multiplier: response.multiplier || [],
    });
}

async function loadOfferedBetTypeBonuses(bonusType: BonusType) {
    const currency = getActiveCurrency();
    const response = await getOfferedBonuses({ bonusType, currency });
    stores.sports.bonusBets.set((state) => ({ ...state, [bonusType]: response }));
}

export async function placeFreeBetV2(
    isManualAcceptance: boolean,
    allowErrorForMarket = false,
    isForceDuplicate = false,
) {
    const isError = await placeBetWrap(placeFreeBetV2Request)(
        isManualAcceptance,
        allowErrorForMarket,
        isForceDuplicate,
    );
    if (!isError) {
        try {
            await loadOfferedBetTypeBonuses(BonusType.FreeBetV2);
        } catch (e) {
            logger.info('SportsBonusBetsService', 'placeFreeBetV2', e);
        }
    }
    return isError;
}

export async function placeRiskFreeBet(
    isManualAcceptance: boolean,
    allowErrorForMarket = false,
    isForceDuplicate = false,
) {
    const isError = await placeBetWrap(placeRiskFreeBetRequest)(
        isManualAcceptance,
        allowErrorForMarket,
        isForceDuplicate,
    );
    if (!isError) {
        try {
            await loadOfferedBetTypeBonuses(BonusType.RiskFreeBet);
        } catch (e) {
            logger.info('SportsBonusBetsService', 'placeRiskFreeBet', e);
        }
    }
    return isError;
}

export async function placeMultiplierBet(
    isManualAcceptance: boolean,
    allowErrorForMarket = false,
    isForceDuplicate = false,
) {
    const isError = await placeBetWrap(placeMultiplierBetRequest)(
        isManualAcceptance,
        allowErrorForMarket,
        isForceDuplicate,
    );
    if (!isError) {
        try {
            await loadOfferedBetTypeBonuses(BonusType.Multiplier);
        } catch (e) {
            logger.info('SportsBonusBetsService', 'placeMultiplierBet', e);
        }
    }
    return isError;
}

function isMarketAllowedForBonus(bonus, market) {
    const { betType } = getStoreValue(stores.sports.betSlipUserState);
    if (!market) {
        return false;
    }
    if (bonus.bet_type && bonus.bet_type.toLowerCase() !== betType.toLowerCase()) {
        return false;
    }
    if (betType === BET_TYPE.SYSTEM) {
        return false;
    }
    if (bonus.sb_product === 'LIVE' && market.match_status !== MATCH_STATUS.LIVE) {
        return false;
    }
    if (bonus.sb_product === 'PREMATCH' && market.match_status === MATCH_STATUS.LIVE) {
        return false;
    }
    if (bonus.markets && bonus.markets.find((x) => x.id === market.id)) {
        return true;
    }
    if (bonus.markets && bonus.markets.map((market2) => market2.match_id).includes(market.match_id)) {
        return false;
    }
    if (bonus.categories && bonus.categories.length && !bonus.categories.includes(market.category_id)) {
        return false;
    }
    if (bonus.matches && bonus.matches.length && !bonus.matches.includes(market.match_id)) {
        return false;
    }
    return true;
}

function isBonusEligibleForBetslip(bonus) {
    const marketIds = Object.keys(getStoreValue(stores.sports.betSlipMarketIdToOutcomeId));
    const marketInfoById = getStoreValue(stores.sports.marketInfoById);
    return every(marketIds, (marketId) => isMarketAllowedForBonus(bonus, marketInfoById[marketId]));
}

export function getBetslipEligibleBonusesForBonusType(bonusType: BonusType) {
    const bonuses = getStoreValue(stores.sports.bonusBets)[bonusType] || [];
    return bonuses.filter(isBonusEligibleForBetslip).map((bonus) => {
        return {
            ...bonus,
            bonusType,
        };
    });
}

export function getActiveEligibleBonus() {
    const bonusBets = getStoreValue(stores.sports.bonusBets);
    const bonusBetsSelection = getStoreValue(stores.sports.bonusBetsSelection);
    const eligibleBonuses = Object.keys(bonusBets)
        .filter((bonusType) => bonusBetsSelection[bonusType])
        .flatMap((bonusType) => bonusBets[bonusType])
        .filter(isBonusEligibleForBetslip);

    const activeBonusId = getStoreValue(stores.sports.selectedBonusId);
    const activeUserBonusId = getStoreValue(stores.sports.selectedUserBonusId);

    if (activeUserBonusId) {
        return eligibleBonuses.find((bonus) => bonus.userBonusId === activeUserBonusId);
    }
    return eligibleBonuses.find((bonus) => bonus.id === activeBonusId);
}

export function useBonus(bonusId: string, bonusType: BonusType) {
    const [bonusBets] = useStoreWithSelector(stores.sports.bonusBets, (state) => state[bonusType]);
    return bonusBets.find((bonus) => bonus.id === bonusId);
}

export function isBonusEligibleAndSelected(bonus) {
    const bonusBetsSelection = getStoreValue(stores.sports.bonusBetsSelection);
    const activeBonusId = getStoreValue(stores.sports.selectedBonusId);
    const activeUserBonusId = getStoreValue(stores.sports.selectedUserBonusId);

    const isTypeActive = bonusBetsSelection[bonus.bonusType];

    const isBonusSelected = activeUserBonusId ? bonus.userBonusId === activeUserBonusId : activeBonusId === bonus.id;

    return isBonusSelected && isTypeActive;
}

export function getRemainingTimeText(expiryDate: Date) {
    const remainingTime = moment.duration(moment(expiryDate).diff(moment()));
    const days = Math.floor(remainingTime.asDays());
    if (days >= 1) {
        return translate(['expires-in-days', `Expires in ${days} days`], 'ui.bonus', { days });
    } else {
        const minutes = moment.duration(remainingTime).minutes();
        const hours = moment.duration(remainingTime).hours();
        return translate(['expires-in-hours', `Expires in ${hours} hours ${minutes} minutes`], 'ui.bonus', {
            hours,
            minutes,
        });
    }
}
