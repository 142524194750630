import React from 'react';
import Wrapper from './styles';
import ComboCardsItemDetails from './details/ComboCardsItemDetails';
import ComboCardsItemHeader from './header/ComboCardsItemHeader';
import ComboCardsItemFooter from './footer/ComboCardsItemFooter';
import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';

interface Props {
    card: FoComboCardWithOdds;
}

export default function ComboCardsItem({ card }: Props) {
    const isSingleMatchCard = card.matches.length === 1;

    return (
        <Wrapper>
            <ComboCardsItemHeader card={card} />
            <div className="combo-card-content">
                <ComboCardsItemDetails card={card} isSingleMatchCard={isSingleMatchCard} />
                <ComboCardsItemFooter card={card} />
            </div>
        </Wrapper>
    );
}
