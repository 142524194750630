import React from 'react';
import Wrapper from './styles';
import { useStoreWithSelector, stores } from '../../../../../stores';
import { translate } from '../../../../../services/translate';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { isTestUser } from '../../../../../services/user';
import SportMatchTimeLive from '../../time/live/SportMatchTimeLive';
import { FoCategoryMatch } from '@staycool/sbgate-types';

interface Props {
    match: FoCategoryMatch;
}

export default function SportMatchScoreboardBgAmericanFootball({ match }: Props) {
    const [inplayData] = useStoreWithSelector(stores.sports.inplayMatchData, (state) => state[match.id], [match.id]);
    const americanFootballIconPath = '/assets/images/sport-icons/amfootball-ico.png';
    const {
        homeTeamPoints,
        awayTeamPoints,
        currentPossession = '',
        timeoutsLeft = {},
        currentQuarter = null,
        quartersArray = [],
        scoreboard = {},
        isDetailedScoreboard = false,
        isTimeoutsLeftVisible = false,
        isQuartersScoreboardVisible = false,
    }: any = getScoreboardData();

    function getScoreboardData() {
        const {
            home_team_points: homeTeamPoints,
            away_team_points: awayTeamPoints,
            custom,
            scoreboard = {},
        } = inplayData || {};
        if (isTestUser() && !isEmpty(custom)) {
            return getDetailedScoreboardData(custom, scoreboard);
        }
        return { homeTeamPoints, awayTeamPoints };
    }

    function getDetailedScoreboardData(custom, scoreboard) {
        const { home_team_points, away_team_points, current_quarter, current_possession, timeouts_left } = custom || {};
        const currentQuarter = parseInt(current_quarter) || current_quarter;
        const overtimePeriods = Object.keys(scoreboard)
            .filter((period) => `${period}`.startsWith('OT'))
            .sort();
        const quartersArray = [1, 2, 3, 4, ...overtimePeriods];
        let hasMissingData = false;
        quartersArray.forEach((quarter) => {
            if ((quarter < currentQuarter || overtimePeriods.includes(currentQuarter)) && !has(scoreboard, quarter)) {
                hasMissingData = true;
            }
        });

        return {
            homeTeamPoints: home_team_points,
            awayTeamPoints: away_team_points,
            currentPossession: current_possession,
            timeoutsLeft: timeouts_left,
            currentQuarter,
            quartersArray,
            scoreboard,
            isDetailedScoreboard: true,
            isTimeoutsLeftVisible: has(timeouts_left, 'home') && has(timeouts_left, 'away'),
            isQuartersScoreboardVisible: currentQuarter !== 1 && !hasMissingData,
        };
    }

    return (
        <Wrapper className="animated fadeIn">
            <div className={`scores ${!isDetailedScoreboard ? 'less-margin' : ''}`}>
                <div className="score">
                    <h1 className="score-title">{match.home_team_name}</h1>
                    <span className="score-number"> {homeTeamPoints || 0}</span>
                    {isTimeoutsLeftVisible && (
                        <span className="timeouts-left">
                            {translate('Timeouts left', 'ui.scoreboard.am')}: {timeoutsLeft.home}
                        </span>
                    )}
                    {currentPossession === 'Home' && (
                        <img className="current-possession" src={americanFootballIconPath} alt="american_football" />
                    )}
                </div>

                <div className="timer">
                    <SportMatchTimeLive matchId={match.id} sportId={match.sport} isOurLivebet={match.livebet_our} />
                </div>

                <div className="score">
                    <h1 className="score-title">{match.away_team_name}</h1>
                    <span className="score-number"> {awayTeamPoints || 0}</span>
                    {isTimeoutsLeftVisible && (
                        <span className="timeouts-left">
                            {translate('Timeouts left', 'ui.scoreboard.am')}: {timeoutsLeft.away}
                        </span>
                    )}
                    {currentPossession === 'Away' && (
                        <img className="current-possession" src={americanFootballIconPath} alt="american_football" />
                    )}
                </div>
            </div>
            {isQuartersScoreboardVisible && (
                <div className="quarter-scores-container">
                    <div className="quarter-scores">
                        <div className="team-name">{match.home_team_name}</div>
                        {quartersArray.map((quarter) => (
                            <span
                                className={`quarter-score ${quarter === currentQuarter ? 'active' : ''}`}
                                key={quarter}
                            >
                                <div className="quarter-label">
                                    {`${quarter}`.startsWith('OT') ? '' : 'Q'}
                                    {quarter}
                                </div>
                                {has(scoreboard, `${quarter}.home`) ? scoreboard[quarter].home : '-'}
                            </span>
                        ))}
                    </div>
                    <div className="quarter-scores">
                        <div className="team-name">{match.away_team_name}</div>
                        {quartersArray.map((quarter) => (
                            <span
                                className={`quarter-score ${quarter === currentQuarter ? 'active' : ''}`}
                                key={quarter}
                            >
                                {has(scoreboard, `${quarter}.away`) ? scoreboard[quarter].away : '-'}
                            </span>
                        ))}
                    </div>
                </div>
            )}
        </Wrapper>
    );
}
