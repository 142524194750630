import React from 'react';
import {
    ProofOfAddressVerificationContextConsumer,
    ProofOfAddressVerificationContextProvider,
} from '../../../../contexts/proof-of-address-verification/ProofOfAddressVerificationContext';
import { getBadgeProps, ProofOfAddressStatus } from '../../../../services/kyc';
import { translate } from '../../../../services/translate';
import AccountVerificationMultiStepBox from '../multi-step/box/AccountVerificationMultiStepBox';
import AccountVerificationProofOfAddressSteps from './steps/AccountVerificationProofOfAddressSteps';

interface Props {
    isCompactView?: boolean;
}

export default function AccountVerificationProofOfAddress(props: Props) {
    return (
        <ProofOfAddressVerificationContextProvider>
            <ProofOfAddressVerificationContextConsumer>
                {({ activeStep, documentUpload, setActiveStep, stepCompletionState, formContainerRef }) => {
                    const badgeProps =
                        activeStep > 0
                            ? { badgeText: translate('In progress', 'ui.common'), badgeColor: 'info' }
                            : getBadgeProps(
                                  documentUpload?.status === ProofOfAddressStatus.FAILED
                                      ? ProofOfAddressStatus.NOT_CHECKED
                                      : documentUpload?.status,
                              );
                    const progressIndicatorSteps = [
                        {
                            description: translate('type', 'ui.account.kyc'),
                            isCompleted: stepCompletionState[1],
                        },
                        {
                            description: translate('documents', 'ui.account.kyc'),
                            isCompleted: stepCompletionState[2],
                        },
                        {
                            description: translate('review', 'ui.account.kyc'),
                            isCompleted: stepCompletionState[3],
                        },
                        {
                            description: translate('submit', 'ui.account.kyc'),
                            isCompleted: stepCompletionState[4],
                        },
                    ];
                    return (
                        <AccountVerificationMultiStepBox
                            Steps={AccountVerificationProofOfAddressSteps}
                            activeStep={activeStep}
                            progressIndicatorSteps={progressIndicatorSteps}
                            ref={formContainerRef}
                            setActiveStep={setActiveStep}
                            title={translate('proof-of-address', 'ui.account')}
                            data-test="proof-of-address"
                            {...badgeProps}
                            {...props}
                        />
                    );
                }}
            </ProofOfAddressVerificationContextConsumer>
        </ProofOfAddressVerificationContextProvider>
    );
}
