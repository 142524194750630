import React from 'react';
import { OnsiteMessage } from '../../microservices/crm';
import CrmMessage from '../crm/message/CrmMessage';
import Loader from '../../components/loader/Loader';
import Wrapper from './styles';

interface Props {
    onsiteMessage?: OnsiteMessage;
}

export default function OnsiteMessagePreview({ onsiteMessage }: Props) {
    return (
        <Wrapper>
            <div className="custom-frame">
                {!onsiteMessage && <Loader />}
                {onsiteMessage && <CrmMessage previewMessage={onsiteMessage} />}
            </div>
        </Wrapper>
    );
}
