import React, { useState } from 'react';
import Wrapper from './styles';
import { Tooltip } from '@mui/material';
import { translate } from '../../../../../services/translate';
import { CasinoGame, CasinoGameFull } from '../../../../../services/casino/games';
import Svg from '../../../../svg/Svg';
import classNames from 'classnames';
import { getCuracaoNameLabel, getRtpByLicenseCountryCode } from '../../../../../services/rtp-calculator';
import UiFlag from '../../../../ui/flag/UiFlag';
import { getLicence, getLicenceName, LICENCE_FLAG_COUNTRY } from '../../../../../services/licence';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    game: CasinoGame;
    fullGame?: CasinoGameFull;
    paybackEnabled?: boolean;
    hasRtpVariant?: boolean;
    loadFullGame?: () => void;
    isSearchResult?: boolean;
}

export default function CasinoThumbnailLabelsRtp({
    game,
    fullGame,
    loadFullGame,
    paybackEnabled,
    hasRtpVariant,
    isSearchResult,
}: Props) {
    const licence = getLicence();
    const [{ isPhone, isTablet }] = useStore(media);
    const [checkForDrag, setCheckForDrag] = useState({ xAxis: 0, yAxis: 0 });
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    function getRtpDescription() {
        if (game.properties.includes(2)) {
            if (fullGame) {
                return fullGame.rtpComment || translate('RTP', 'casino.thumbnail');
            }
            return translate('Loading...', 'ui.common');
        }
        return translate('RTP', 'casino.thumbnail');
    }
    function rtpInformation() {
        return (
            <div>
                {paybackEnabled && <div style={{ marginBottom: '5px' }}>{getRtpDescription()}</div>}
                {hasRtpVariant && (
                    <>
                        {translate('If you play this game', 'casino.rtp-calculator')}
                        <ul>
                            <li>
                                <div style={{ display: 'flex' }}>
                                    Coolbet RTP:{' '}
                                    {!LICENCE_FLAG_COUNTRY[licence] &&
                                        ` (${translate(getLicenceName(), 'casino.rtp-calculator')}) `}
                                    {LICENCE_FLAG_COUNTRY[licence] && (
                                        <div style={{ padding: '0px 5px' }}>
                                            <UiFlag flag={LICENCE_FLAG_COUNTRY[licence]} />
                                        </div>
                                    )}
                                    {game.rtp}%
                                </div>
                            </li>
                            <li>
                                <div style={{ display: 'flex' }}>
                                    {translate('Other casinos', 'casino.rtp-calculator')}:{' '}
                                    {!LICENCE_FLAG_COUNTRY[licence] &&
                                        ` (${translate(getLicenceName(), 'casino.rtp-calculator')}) `}
                                    {LICENCE_FLAG_COUNTRY[licence] && (
                                        <div style={{ padding: '0px 5px' }}>
                                            <UiFlag flag={LICENCE_FLAG_COUNTRY[licence]} />
                                        </div>
                                    )}
                                    {getRtpByLicenseCountryCode(game)}%
                                </div>
                            </li>
                            <li>
                                {getCuracaoNameLabel()}: {game.minRtpCur}%
                            </li>
                        </ul>
                    </>
                )}
            </div>
        );
    }

    if (!game.rtp) {
        return null;
    }

    if (!hasRtpVariant && !paybackEnabled) {
        return null;
    }

    function mouseDownCoords(event) {
        setCheckForDrag({ xAxis: event.clientX, yAxis: event.clientY });
    }

    function clickOrDrag(event) {
        if (
            event.clientX > checkForDrag.xAxis + 7 ||
            event.clientX < checkForDrag.xAxis - 7 ||
            event.clientY > checkForDrag.yAxis + 7 ||
            event.clientY < checkForDrag.yAxis - 7
        ) {
            setIsTooltipOpen(false);
        } else {
            setIsTooltipOpen((oldValue) => !oldValue);
        }
    }

    return (
        <Wrapper>
            <Tooltip
                title={<div style={{ whiteSpace: 'pre-line', fontSize: '11px' }}>{rtpInformation()}</div>}
                enterDelay={200}
                enterTouchDelay={0}
                disableHoverListener={isPhone}
                open={isTooltipOpen}
            >
                <div
                    onMouseEnter={() => {
                        loadFullGame && loadFullGame();
                        if (!isPhone) {
                            setIsTooltipOpen(true);
                        }
                    }}
                    onTouchStart={(event) => {
                        if (loadFullGame) {
                            loadFullGame();
                        }
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                    onMouseDown={mouseDownCoords}
                    onMouseUp={clickOrDrag}
                    onMouseLeave={() => {
                        if (!isPhone) {
                            setIsTooltipOpen(false);
                        }
                    }}
                    onClick={(event) => {
                        if (isPhone) {
                            event.preventDefault();
                            event.stopPropagation();
                        }
                    }}
                    className={classNames('casino-thumbnail-labels-rtp', {
                        'casino-thumbnail-labels-rtp-variant': hasRtpVariant,
                        'casino-thumbnail-labels-rtp-payback': paybackEnabled,
                        'casino-thumbnail-labels-rtp-variant-extra':
                            game.properties.includes(2) && hasRtpVariant && paybackEnabled,
                    })}
                >
                    {hasRtpVariant && !isSearchResult && !isPhone && !isTablet && (
                        <Svg icon="checkmark" className="casino-thumbnail-labels-rtp-boosted" size={0.875} />
                    )}
                    {paybackEnabled && `${parseFloat(game.rtp).toFixed(2)}%${game.properties.includes(2) ? '*' : ''}`}
                </div>
            </Tooltip>
        </Wrapper>
    );
}
