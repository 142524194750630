import styled from 'styled-components';
import { lighten, transparentize } from 'polished';
import { mediaQuery } from '../../../../../styles/utils';

export default styled.div`
    background: url(${(props) => props.backgroundUrl || ''});
    background-size: cover;
    background-position: center;
    @media ${mediaQuery.isPhone} {
        margin: 0;
        padding: 1rem 0;
        background-position: left;
    }

    .score-board {
        align-items: center;
        background: ${({ theme }) => transparentize(0.2, lighten(0.06, theme.box.background))};
        background-position: center;
        background-size: cover;
        border-radius: var(--ui-radius);
        box-shadow: var(--ui-shadow);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0.8rem auto;
        max-width: 700px;
        padding: 1rem;
        @media ${mediaQuery.isPhone} {
            max-width: 360px;
        }
    }

    .scores {
        align-items: stretch;
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        &.less-margin {
            margin-bottom: 10px;
        }
        .score {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            width: 30%;

            .current-batter {
                font-size: 0.7rem;
                color: var(--font-color-subtle);
                position: absolute;
                bottom: -25px;
                left: 33px;
                width: 20px;
                height: 20px;
            }
        }
        .score-title {
            font-size: 1rem;
            letter-spacing: 1px;
            text-shadow: 1px 1px #000;
            text-align: center;
        }
        .score-number {
            align-items: center;
            background-color: var(--surface-level-1-bg);
            border-radius: 10px;
            display: flex;
            font-size: 1.5rem;
            font-weight: bold;
            height: 50px;
            justify-content: center;
            width: 40px;
        }
        .timeouts-left {
            color: var(--font-color-secondary);
            margin-top: 3px;
        }
    }
    .inning-scores-container {
        background-color: var(--surface-level-1-bg);
        border-radius: var(--ui-radius);
        box-shadow: var(--ui-shadow), inset 1px 1px 4px -2px ${({ theme }) => lighten(0.4, theme.box.background)};
        letter-spacing: 1px;
        padding: 1.5rem;
        text-shadow: 1px 1px #000;
        @media ${mediaQuery.isPhone} {
            max-width: 360px;
        }
        .batting-scores {
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 1rem 0 2rem;
            .batting-score {
                display: flex;
                justify-content: center;
                background-color: var(--surface-level-2-bg);
                border-radius: 10px;
                margin: 0.5rem 3rem;
                min-width: 33px;
                padding: 5px;
                position: relative;
                .batting-label {
                    bottom: 34px;
                    color: var(--font-color-secondary);
                    font-size: 0.8rem;
                    position: absolute;
                }
            }
        }
        .inning-scores {
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 0.2rem;
            .inning-score {
                display: flex;
                justify-content: center;
                background-color: var(--surface-level-2-bg);
                border-radius: 10px;
                margin-right: 5px;
                min-width: 28px;
                padding: 5px;
                position: relative;
                text-align: center;
                .inning-label {
                    bottom: 34px;
                    color: var(--font-color-secondary);
                    font-size: 0.7rem;
                    position: absolute;
                }
                &.active {
                    color: ${({ theme }) => theme.colors.primary};
                    .inning-label {
                        color: ${({ theme }) => theme.colors.primary};
                    }
                }
                &.custom {
                    margin: 0 0.5rem;
                }
            }
            .team-name {
                align-items: center;
                display: flex;
                font-size: 0.8rem;
                margin: 0 1rem 0 0;
                max-width: 140px;
                min-width: 110px;
                padding: 0.5rem 0;
                text-align: left;
                @media ${mediaQuery.isPhone} {
                    max-width: 110px;
                    min-width: 110px;
                }
            }
            &.custom {
                padding-left: 15px;
                .set-score {
                    margin: 0 0.3rem;
                }
            }
        }
    }

    .timer {
        align-items: center;
        display: flex;
        justify-content: center;
        min-width: 100px;
        padding-top: 50px;
    }
`;
