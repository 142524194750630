import React, { useState } from 'react';
import Wrapper from './styles';
import { translate } from '../../../../../services/translate';
import { PaybackBooster } from '../../../../../services/casino/payback-booster';
import CasinoPaybackBoosterThumbnailPromoDescription from './description/CasinoPaybackBoosterThumbnailPromoDescription';
import CasinoPaybackBoosterTermsAndConditions from '../../terms-and-conditions/CasinoPaybackBoosterTermsAndConditions';
import UiCloseButton from '../../../../ui/close-button/UiCloseButton';

interface Props {
    paybackBooster?: PaybackBooster;
    forceFullView?: boolean;
    isCompact: boolean;
}

export default function CasinoPaybackBoosterThumbnailPromo(props: Props) {
    const [isTermsAndConditionsVisible, setIsTermsAndConditionsVisible] = useState(false);
    const { isCompact } = props;
    const [isInstructionsVisible, setIsInstructionsVisible] = useState(false);

    return (
        <Wrapper isCompact={isCompact}>
            <div className="promo-container">
                <div className="promo-read-more" onClick={() => setIsInstructionsVisible(true)}>
                    {translate('How it works', 'ui.account')}?
                </div>

                {isInstructionsVisible && (
                    <>
                        <UiCloseButton className="close-btn" onClick={() => setIsInstructionsVisible(false)} />
                        <div className="promo-description">
                            <CasinoPaybackBoosterThumbnailPromoDescription {...props} />
                        </div>
                    </>
                )}

                <div className="booster-logo" />
                {!isCompact && (
                    <p className="promo-terms-and-conditions" onClick={() => setIsTermsAndConditionsVisible(true)}>
                        {translate('Terms & Conditions', 'ui.account')}
                    </p>
                )}
            </div>
            <CasinoPaybackBoosterTermsAndConditions
                isOpen={isTermsAndConditionsVisible}
                onClose={() => setIsTermsAndConditionsVisible(false)}
            />
        </Wrapper>
    );
}
