import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    height: 100%;

    .casino-thumbnail-labels-top-container {
        display: flex;
        position: absolute;
        top: 6px;
        left: 0;
        width: 100%;
        place-content: center;
    }

    .casino-thumbnail-labels {
        display: flex;
        width: 95%;
        justify-content: space-around;
    }

    .casino-thumbnail-labels-jackpot {
        font-size: 0.75rem;
        line-height: 12px;
        font-weight: 600;
        height: auto;
        text-transform: uppercase;
        font-family: Lato, Roboto, sans-serif;
        border-radius: calc(2 * var(--ui-radius));
        padding: 3px 8px;
        color: ${({ theme }) => theme.colors.black};
        border: 1px solid ${({ theme }) => theme.colors.yellow};
        background-color: ${({ theme }) => theme.colors.yellow};
    }

    .casino-thumbnail-labels-stakes {
        background-color: var(--surface-level-1-bg);
        font-size: 12px;
        font-weight: 900;
        border-radius: 10px;
        padding: 0 5px;
        border: 2px solid #494c54;
        color: var(--font-color-primary);
        height: 18px;
        line-height: 14px;
        font-family: 'Roboto', sans-serif;
    }

    .casino-thumbnail-labels-bottom-container {
        position: absolute;
        bottom: 65px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
    }

    .casino-thumbnail-labels-stakes {
        display: none;
    }

    .casino-thumbnail-label {
        position: relative;
        z-index: 3;
    }

    &:hover {
        .stakes {
            .casino-thumbnail-labels-stakes {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .casino-thumbnail-hidden {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 40px;
        color: gray;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 5px 15px 5px 5px;
        opacity: 0.9;
        border-radius: 5px;
        background-color: var(--surface-level-1-bg);

        .casino-thumbnail-hidden-icon {
            width: 30px;
            height: 30px;
            margin: 0 5px;
        }
    }

    .hidden {
        .casino-thumbnail-labels-jackpot,
        .casino-thumbnail-labels-stakes {
            display: none;
        }
    }

    .stakes {
        .stake {
            display: none;
            margin-bottom: 3px;
        }
    }

    .casino-thumbnail-labels-top {
        display: flex;
        flex-wrap: nowrap;
    }

    .casino-thumbnail-top-container {
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
    }

    &:hover {
        @media (min-width: 1200px) {
            .casino-thumbnail-top-container {
                opacity: 1;
            }
            .casino-thumbnail-labels-top-container {
                opacity: 0;
            }
        }
    }
`;
