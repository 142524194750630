import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import filter from 'lodash/filter';
import tail from 'lodash/tail';
import { getRoute, isActiveRoute } from './router';

export type Toast = {
    id?: number;
    title?: string;
    text: string;
    size?: 'size-small' | 'size-medium';
    iconName?: string;
    type?: string;
    closeOnLogout?: boolean;
    lifespan?: number;
    iconSubText?: string;
    isImportant?: boolean;
    actions?: { name: string; default?: boolean; action?: any }[];
};

let toastIdCounter = 0;

export function showToast(toast: Toast): void {
    if (isActiveRoute(`${getRoute('poker')}/:rest`, false)) {
        return;
    }
    toast.id = toastIdCounter++;
    const toasts = getStoreValue(stores.toasts);

    if (toasts.length < 2 || toast.isImportant) {
        stores.toasts.set([...toasts, toast]);
    }
}

export function closeToast(toast: Toast): void {
    const toasts = getStoreValue(stores.toasts);
    stores.toasts.set(filter(toasts, (currentToast) => currentToast.id !== toast.id));
}

export function closeToastsOnLogout(): void {
    const toasts = getStoreValue(stores.toasts);
    if (toasts.length > 1) {
        stores.toasts.set([toasts[0], ...filter(tail(toasts), { closeOnLogout: false })]);
    }
}
