import React from 'react';
import { useProofOfIdVerificationContext } from '../../../../../contexts/proof-of-id-verification/ProofOfIdVerificationContext';
import AccountVerificationMultiStepFooter from '../../multi-step/footer/AccountVerificationMultiStepFooter';
import AccountVerificationProofOfIdSteps from '../steps/AccountVerificationProofOfIdSteps';

interface Props {
    isNextButtonDisabled?: boolean;
    nextButtonText?: string;
}

export default function AccountVerificationProofOfIdFooter(props: Props) {
    const { activeStep, setActiveStep, stepCompletionState } = useProofOfIdVerificationContext();

    return (
        <AccountVerificationMultiStepFooter
            {...props}
            nextButtonText={props.nextButtonText}
            isNextButtonDisabled={!stepCompletionState[activeStep]}
            isPreviousButtonVisible={activeStep > 1}
            onPreviousButtonClick={() => setActiveStep(Math.max(activeStep - 1, 0))}
            onNextButtonClick={() =>
                setActiveStep(Math.min(activeStep + 1, AccountVerificationProofOfIdSteps.length - 1))
            }
        />
    );
}
