import React, { ReactNode } from 'react';
import Wrapper from './styles';

interface Props {
    children: ReactNode;
}

export default function FullstoryBlock({ children }: Props) {
    return <Wrapper className="fs-block">{children}</Wrapper>;
}
