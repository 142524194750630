import React, { useEffect, useMemo } from 'react';
import { getNewPostsAmount } from '../../../microservices/l10n';
import { FEATURE, isFeatureAvailable } from '../../../services/feature';
import { getHomeRoute, getRoute, isActiveRoute } from '../../../services/router';
import { translate } from '../../../services/translate';
import { stores } from '../../../stores';
import { media } from '../../../stores/media/media';
import Logo from '../../logo/Logo';
import Svg from '../../svg/Svg';
import UiButton from '../../ui/button/UiButton';
import UiNavLink from '../../ui/nav-link/UiNavLink';
import CoolbetHeaderAnonymous from './anonymous/CoolbetHeaderAnonymous';
import CoolbetHeaderAuthenticated from './authenticated/CoolbetHeaderAuthenticated';
import CoolbetHeaderLanguages from './languages/CoolbetHeaderLanguages';
import Wrapper from './styles';
import CoolbetHeaderWarning from './warning/CoolbetHeaderWarning';
import { useStore } from '../../../hooks/useStore';

export default function CoolbetHeader() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [dynamicPromotionsCount] = useStore(stores.dynamicPromotionsCount);
    const [newBlogPostsCount] = useStore(stores.blog.newBlogPostsCount);
    const [blogVisitDate] = useStore(stores.blog.blogVisitDate);
    const [{ isDesktop, isDesktopSmall }] = useStore(media);
    const MAX_DESKTOP_MENU_ITEMS = 7;
    const MAX_SMALL_DESKTOP_MENU_ITEMS = 5;
    const MAX_MENU_ITEMS = 4;
    const isTwoToneTheme = !isFeatureAvailable(FEATURE.THEME_SINGLE_TONE);

    useEffect(() => {
        if (blogVisitDate) {
            getNewPostsAmount();
        }
    }, [blogVisitDate]);

    const links: {
        active?: boolean;
        badge?: number;
        feature: FEATURE;
        icon: string;
        isSupportedScreen?: boolean;
        label: string;
        route: string;
    }[] = [
        {
            label: translate('Sports', 'ui.header'),
            route: getRoute('sport'),
            icon: 'sports-ico',
            feature: FEATURE.SPORTSBOOK,
        },
        {
            label: translate('Casino', 'ui.header'),
            route: getRoute('casino.slots'),
            icon: 'casino-lobby',
            active: isActiveRoute(getRoute('casino.slots'), false) || isActiveRoute(getRoute('casino.race'), false),
            feature: FEATURE.CASINO,
        },
        {
            label: translate('Live Casino', 'ui.header'),
            route: getRoute('casino.live'),
            icon: 'casino-live',
            active: isActiveRoute(getRoute('casino.live'), false),
            feature: FEATURE.CASINO,
            isSupportedScreen: isDesktop && !isDesktopSmall,
        },
        {
            label: translate('Horse Racing', 'ui.header'),
            route: getRoute('horse-racing'),
            icon: 'horse-racing-ico',
            feature: FEATURE.HORSE_RACING,
        },
        {
            label: translate('Poker', 'ui.header'),
            route: getRoute('poker'),
            icon: 'poker-ico',
            feature: FEATURE.POKER,
        },
        {
            label: translate('Virtual Sports', 'ui.common'),
            route: getRoute('virtual-sports'),
            icon: 'virtual-sports',
            feature: FEATURE.VIRTUAL_SPORTS,
            isSupportedScreen: isDesktop,
        },
        {
            label: translate('Promotions', 'ui.header'),
            route: getRoute('promotions'),
            badge: dynamicPromotionsCount,
            icon: 'promotions-ico',
            feature: FEATURE.PROMOTIONS,
        },
        {
            label: translate('Blog', 'ui.header'),
            route: getRoute('blog'),
            badge: newBlogPostsCount,
            icon: 'blog-ico',
            feature: FEATURE.BLOG,
            isSupportedScreen: isDesktop && !isDesktopSmall,
        },
    ];

    const navLinks = useMemo(() => {
        let newLinks = [...links];
        newLinks = newLinks.filter((link) => isFeatureAvailable(link.feature));

        const desktopItemsCount = isDesktopSmall ? MAX_SMALL_DESKTOP_MENU_ITEMS : MAX_DESKTOP_MENU_ITEMS;
        const maxMenuItemsCount = isDesktop ? desktopItemsCount : MAX_MENU_ITEMS;

        let linksLen = newLinks.length;
        if (linksLen > maxMenuItemsCount) {
            newLinks = newLinks.filter((link) => {
                const isLinksCountGreaterThanMaxItems = linksLen > maxMenuItemsCount;
                const isSupportedScreen = !link.hasOwnProperty('isSupportedScreen') || link?.isSupportedScreen;

                const shouldRemoveLink = isLinksCountGreaterThanMaxItems && !isSupportedScreen;

                if (shouldRemoveLink) {
                    linksLen--;
                    return false;
                }

                return true;
            });
        }

        return newLinks;
    }, [isDesktop, isDesktopSmall]);

    return (
        <Wrapper>
            <CoolbetHeaderWarning />

            <div className="header-container">
                <UiNavLink to={getHomeRoute()} className="header-logo">
                    <Logo size={35} />
                </UiNavLink>

                <div className="header-products-nav">
                    {navLinks.map((link) => (
                        <UiButton
                            type="tab"
                            key={link.route}
                            url={link.route}
                            size="default"
                            selected={isActiveRoute(link.route, false)}
                            icon={<Svg icon={link.icon} />}
                            badge={link.badge}
                            isTwoToneTheme={isTwoToneTheme}
                        >
                            {link.label}
                        </UiButton>
                    ))}
                </div>

                <div className="header-user-controls">
                    {isFeatureAvailable(FEATURE.LANGUAGE_SELECTION) && <CoolbetHeaderLanguages />}
                    {isAuthenticated ? <CoolbetHeaderAuthenticated /> : <CoolbetHeaderAnonymous />}
                </div>
            </div>
        </Wrapper>
    );
}
