import styled from 'styled-components';

export default styled.span`
    width: 100%;
    height: 100%;
    color: #f3f3f3;
    text-shadow: 1px 1px 1px #316309;
    display: flex;
    justify-content: center;
    align-items: center;

    &.red {
        background-color: #ff4d4d;
    }

    &.green {
        background-color: #44870d;
    }

    &.yellow {
        background-color: #c7b110;
    }

    &.black {
        background-color: #000000;
    }

    &.black-bordered {
        background-color: #000000;
        border: 1px solid #ffffff;
    }
`;
