import styled from 'styled-components';
import { darken } from 'polished';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    margin: 0 auto 5px;
    max-height: inherit;
    overflow: hidden;
    position: relative;
    background-color: ${({ theme }) => theme.pokerLeaderboard.primaryBackground};
    border-radius: 5px;
    display: flex;
    justify-content: stretch;
    align-items: center;
    border: 1px solid transparent;
    z-index: 1;
    height: 41px;
    text-align: left;
    color: var(--font-color-primary);

    @media ${mediaQuery.isPhone} {
        justify-content: center;
    }

    div {
        margin: 0 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        letter-spacing: 1px;
        color: var(--font-color-secondary);
        font-weight: bold;
        font-size: 13px;
        overflow: hidden;
        text-align: right;
    }

    .position {
        display: block;
        text-align: center;
        width: 50px;
        background-color: ${({ theme }) => darken(0.1, theme.pokerLeaderboard.primaryBackground)};
        line-height: 30px;
        border-radius: 5px;
        font-weight: 700;
        text-align: center;
    }

    .alias {
        width: 200px;

        @media ${mediaQuery.isPhone} {
            flex: 1;
        }
    }

    .score {
        text-align: center;
        width: 100px;

        @media ${mediaQuery.isPhone} {
            justify-content: flex-end;
            padding-right: 5px;
        }
    }

    .eventCount {
        width: 80px;

        @media ${mediaQuery.isPhone} {
            display: none;
        }
    }

    .prize {
        flex: 1;
        justify-content: center;
        text-transform: uppercase;
        color: var(--color-highlight);

        @media ${mediaQuery.isPhone} {
            display: none;
        }
    }
`;
