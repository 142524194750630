export enum ResponsibleGamingProduct {
    CASINO = 'casino',
    HORSE_RACING = 'horse_racing',
    POKER = 'poker',
    SPORTSBOOK = 'sportsbook',
}

export type SelfExclusionUntilFurtherNotice = {
    notice_date: Date;
};

export type ExclusionPeriod = {
    label: string;
    periodInDays: number;
    isUntilFurtherNotice?: boolean;
};
