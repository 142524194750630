import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { CasinoGame } from '../../../../services/casino/games';
import RtpCalculatorGameComparisonInfo from './info/RtpCalculatorGameSelectionComparisonInfo';
import UiDotsLoader from '../../../ui/dots-loader/UiDotsLoader';
import { translate } from '../../../../services/translate';
import { getCuracaoNameLabel, getRtpByLicenseCountryCode } from '../../../../services/rtp-calculator';

interface Props {
    gameId: number;
    betSize: number;
    gamesWithRtpVariant: CasinoGame[];
    startingBalance: number;
    spinsPerMinute: number;
}

type comparisonGameType = 'Coolbet' | 'Other Casinos' | 'Curacao Casinos';

export default function RtpCalculatorGameSelectionComparison({
    gameId,
    gamesWithRtpVariant,
    betSize,
    startingBalance,
    spinsPerMinute,
}: Props) {
    const [selectedGame, setSelectedGame] = useState<CasinoGame>({} as CasinoGame);

    useEffect(() => {
        if (gamesWithRtpVariant.length) {
            const foundGame = gamesWithRtpVariant.find((game) => game.id === gameId);
            if (foundGame) {
                setSelectedGame(foundGame);
            }
        }
    }, [gamesWithRtpVariant, gameId]);

    function getClassName(type: comparisonGameType) {
        const rtp = getRtpBasedOnLicenseAndType(type);
        const compareToType = type === 'Other Casinos' ? 'Curacao Casinos' : 'Other Casinos';
        const compareRtp = getRtpBasedOnLicenseAndType(compareToType);

        return rtp >= compareRtp ? 'secondary' : 'tertiary';
    }

    function getPlayInfoBasedOnRtp(type: comparisonGameType) {
        const rtp = getRtpBasedOnLicenseAndType(type);

        const gameMarginPercent = 100 - rtp;
        const burnRate = spinsPerMinute * betSize * (gameMarginPercent / 100);
        const totalPlayTime = startingBalance / burnRate;
        const minSpinsMade = totalPlayTime * spinsPerMinute;
        return { spins: Math.floor(minSpinsMade), minutes: Math.floor(totalPlayTime) };
    }

    function getRtpBasedOnLicenseAndType(comparisonGameType: comparisonGameType) {
        if (comparisonGameType === 'Coolbet') {
            return selectedGame.rtp ? parseFloat(selectedGame.rtp) : 0;
        }
        if (comparisonGameType === 'Other Casinos') {
            return getRtpByLicenseCountryCode(selectedGame);
        }
        return selectedGame.minRtpCur ? parseFloat(selectedGame.minRtpCur) : 0;
    }

    if (!Object.keys(selectedGame).length) {
        return <UiDotsLoader />;
    }

    return (
        <Wrapper>
            <div className="rtp-calculator-game-selection-comparison">
                <div className="rtp-calculator-game-selection-comparison-wrapper">
                    <div className="rtp-calculator-game-selection-comparison-content">
                        <div className="rtp-calculator-game-selection-comparison-content-info">
                            <RtpCalculatorGameComparisonInfo
                                className="rtp-calculator-game-selection-comparison-info-primary"
                                game={selectedGame}
                                showRtpInfo={true}
                                gameRtp={getRtpBasedOnLicenseAndType('Coolbet')}
                                gameInfo={getPlayInfoBasedOnRtp('Coolbet')}
                                nameLabel="Coolbet"
                            />
                        </div>
                        <div className="rtp-calculator-game-selection-comparison-content-info">
                            <RtpCalculatorGameComparisonInfo
                                className={`rtp-calculator-game-selection-comparison-info-${getClassName(
                                    'Other Casinos',
                                )}`}
                                game={selectedGame}
                                gameInfo={getPlayInfoBasedOnRtp('Other Casinos')}
                                gameRtp={getRtpBasedOnLicenseAndType('Other Casinos')}
                                nameLabel={translate('Other casinos', 'casino.rtp-calculator')}
                            />
                        </div>
                        <div className="rtp-calculator-game-selection-comparison-content-info">
                            <RtpCalculatorGameComparisonInfo
                                className={`rtp-calculator-game-selection-comparison-info-${getClassName(
                                    'Curacao Casinos',
                                )}`}
                                isDisplayingFlag={false}
                                game={selectedGame}
                                gameInfo={getPlayInfoBasedOnRtp('Curacao Casinos')}
                                gameRtp={getRtpBasedOnLicenseAndType('Curacao Casinos')}
                                nameLabel={getCuracaoNameLabel()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
