import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

const inputStatus = ({ success, failure, warning, theme }) => {
    if (success) {
        return theme.alerts.successColor;
    }
    if (failure) {
        return theme.alerts.failureColor;
    }
    if (warning) {
        return theme.alerts.warningColor;
    }
    return '';
};

export default styled.div`
    position: relative;
    .input-wrapper {
        display: flex;
        overflow: hidden;
        input {
            border: none;
            box-shadow: none;
            height: 100%;
            margin: 0;
        }
        input:focus,
        input:active {
            border: none;
        }
        .input-adornment {
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    input:focus,
    input:active,
    .input-wrapper:active,
    .input-wrapper:focus-within {
        border: 1px solid var(--surface-level-3-line-color);
    }

    input {
        padding: 0 var(--spacing-12);
        outline: 0;
    }

    input:disabled,
    .input-wrapper.disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .input-wrapper,
    input {
        width: 100%;
        height: 2.5rem;

        color: var(--form-input-text);
        margin: 0 0 var(--spacing-12) 0;
        font-size: 0.875rem;
        font-family: var(--font-family);

        border: var(--form-input-border);
        border-radius: var(--ui-radius);
        background-color: var(--form-input-bg);
        border-bottom-color: ${inputStatus};

        @media ${mediaQuery.isPhone} {
            height: 3rem;
        }
    }

    input::-webkit-input-placeholder {
        color: var(--font-color-subtle);
    }
    input:-moz-placeholder {
        color: var(--font-color-subtle);
    }

    input[type='number'] {
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
            margin: 0;
        }
    }

    ul.autocomplete-options {
        background-color: var(--form-input-bg);
        color: var(--font-color-primary);
        list-style-type: none;
        margin-top: -0.8rem;
        padding: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
        li {
            cursor: pointer;
            padding: 0.5rem;

            &:hover {
                filter: brightness(1.1);
            }
        }
    }
`;
