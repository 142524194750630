import UiForm from '../../../../ui/form/UiForm';
import UiGroup from '../../../../ui/group/UiGroup';
import UiFormInput from '../../../../ui/form/input/UiFormInput';
import { validators } from '../../../../../services/validators';
import { translate } from '../../../../../services/translate';
import React from 'react';
import { useForm } from '../../../../../services/form';
import { getActiveCurrency } from '../../../../../services/currency';
import UiButton from '../../../../ui/button/UiButton';

interface Props {
    formFields: { [key: string]: any };
    isLoading: boolean;
    handleOnSubmit: (isFormValid?: boolean) => void;
}

export default function AuthProfileValidatorSourceOfWealthForm({ formFields, isLoading, handleOnSubmit }: Props) {
    const form = useForm(
        Object.assign({}, ...formFields.map((field) => ({ [field.fieldId]: field.value || '' })), {
            hasUserConfirmedFormValidity: false,
        }),
    );
    const currency = getActiveCurrency();

    function isMonetaryField(fieldId) {
        const MONTHLY_GAMBLING_SPENDING_INTENTIONS_FIELD_ID = 4;
        const MONTHLY_NET_INCOME_FIELD_ID = 3;
        return [MONTHLY_GAMBLING_SPENDING_INTENTIONS_FIELD_ID, MONTHLY_NET_INCOME_FIELD_ID].includes(fieldId);
    }

    return (
        <UiForm onSubmit={handleOnSubmit} isLoading={isLoading}>
            {formFields.map((field) => (
                <UiGroup key={field.fieldId}>
                    <UiFormInput
                        {...form[field.fieldId]}
                        label={translate(field.label, 'ui.account')}
                        className="sow-field"
                        maxLength={120}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            field.value = e.target.value;
                        }}
                        validator={validators.required}
                        endAdornment={
                            isMonetaryField(field.fieldId) && <span className="currency-badge">{currency}</span>
                        }
                    />
                </UiGroup>
            ))}

            <UiFormInput
                {...form.hasUserConfirmedFormValidity}
                checkbox
                validator={validators.required}
                label={translate('I confirm that the above information is correct and true', 'ui.account')}
            />

            <UiButton color="primary" isFormSubmitButton isLoading={isLoading}>
                {translate('Submit', 'ui.account')}
            </UiButton>
        </UiForm>
    );
}
