import React, { useEffect } from 'react';
import { stores } from '../../../stores';
import { useSocketSubscribeUnsubscribe, useSocketTopicEvents } from '../../../microservices/pusher';
import { getBannerMessages } from '../../../microservices/cms';
import { getStoreValue } from '../../../stores/store/utils';
import { useStore } from '../../../hooks/useStore';

enum ChangeType {
    add = 'add',
    remove = 'remove',
    update = 'update',
}

export default function SystemBannerMessage() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [user] = useStore(stores.user);
    const [, setBannerMessages] = useStore(stores.cms.bannerMessages);
    const [, setBannerMessageLastRequest] = useStore(stores.cms.bannerMessageLastRequest);

    useEffect(() => {
        if (isAuthenticated && user) {
            getBannerMessages(user.market);
        } else {
            setBannerMessages([]);
            setBannerMessageLastRequest('');
        }
    }, [isAuthenticated]);

    function onBannerMessageUpdate({ bannerMessage, changeType }) {
        const bannerMessages = getStoreValue(stores.cms.bannerMessages);
        const user = getStoreValue(stores.user);
        if (bannerMessage.market !== user?.market) {
            return;
        }
        let messages = [...bannerMessages];
        if (changeType === ChangeType.remove) {
            messages = bannerMessages.filter((message) => message.id !== bannerMessage.id);
        } else if (changeType === ChangeType.update) {
            messages = bannerMessages.map((message) => {
                if (message.id === bannerMessage.id) {
                    return bannerMessage;
                }
                return message;
            });
        } else if (changeType === ChangeType.add) {
            messages.push(bannerMessage);
        }
        stores.cms.bannerMessages.set(messages);
    }
    useSocketSubscribeUnsubscribe('public', {
        params: {
            service: 'cms',
            channel: 'banner-message',
        },
        guardFunction: () => isAuthenticated,
        watchParams: [isAuthenticated],
    });

    useSocketTopicEvents('message-update', onBannerMessageUpdate, []);
    return <></>;
}
