import React, { useEffect } from 'react';
import classnames from 'classnames';
import OddMatchPromotion from './odd/MarketingMatchPromotionOdd';
import CTAMatchPromotion from './cta/MarketingMatchPromotionCta';
import Wrapper from './styles';
import { translate } from '../../../services/translate';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../services/analytics';
import { getPromotionAnalyticsInfo } from '../../../microservices/cms';
import { stores, useStoreWithSelector } from '../../../stores';
import { useSportsVisualSettings } from '../../../services/sports/sport-match-visual-settings';
import { MatchPromotionComponentProps as Props } from '../../../services/cms-promotion';

export default function MarketingMatchPromotion({ promotion }: Props) {
    const { isShowSportCategoryIcon } = useSportsVisualSettings();
    const { id, type, use_highlight_text: useHighlightText, use_content_text: useContentText } = promotion;
    const [odds] = useStoreWithSelector(
        stores.sports.oddsByOutcomeId,
        (state) => promotion.type === 'odd' && state[promotion.outcome_id],
        [promotion.type === 'odd' && promotion.outcome_id],
        () => promotion.type === 'odd',
    );

    useEffect(() => {
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.MARKETING_PROMOTION_PRINT, getPromotionAnalyticsInfo(promotion));
    }, []);

    function getTranslatedHighlightText() {
        if (!useHighlightText) {
            return '';
        } else if (translate(`ui.promotion.highlight.${id}`) !== `ui.promotion.highlight.${id}`) {
            return translate(`ui.promotion.highlight.${id}`);
        } else if (type === 'odd') {
            return translate('ui.promotion.highlight-odd');
        } else if (type === 'cta') {
            return translate('ui.promotion.highlight-cta');
        }

        return '';
    }

    if (promotion.type === 'odd' && !odds) {
        return null;
    }

    return (
        <Wrapper className={classnames('promotion-container', { 'marged-promotion': isShowSportCategoryIcon })}>
            <div className="promotion-text-wrapper">
                <span className="promotion-highlighted-text">{`${getTranslatedHighlightText()} `}</span>
                {useContentText && translate(`ui.promotion.content.${id}`)}
                {type === 'cta' && <CTAMatchPromotion key={promotion.id} promotion={promotion} />}
            </div>
            {type === 'odd' && <OddMatchPromotion key={promotion.id} promotion={promotion} />}
        </Wrapper>
    );
}
