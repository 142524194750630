import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../../../../styles/utils';

type StyledProps = {
    mobile?: boolean;
};
export default styled.div<StyledProps>`
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    padding: ${({ theme }) => theme.layout.gutterHalf} ${({ theme }) => theme.layout.gutterHalf}
        ${({ theme }) => theme.layout.gutter} ${({ theme }) => theme.layout.gutterHalf};
    width: 100%;
    @media ${mediaQuery.isPhone} {
        padding: ${({ theme }) => theme.layout.gutterHalf};
    }

    .casino-payback-booster-games-modal-view-results-container {
        width: 100%;
    }

    .casino-payback-booster-games-modal-view-results-body {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        min-width: 0;
        justify-content: space-around;
        gap: var(--spacing-4);
    }

    .casino-payback-booster-games-modal-view-results-header {
        display: flex;
        justify-content: flex-start;
        padding-bottom: 5px;
    }

    .casino-payback-booster-games-modal-view-results-games-found-amount {
        font-size: 0.8rem;
        color: var(--font-color-subtle);
        font-weight: 700;
        margin: 0 10px;
    }

    @media ${mediaQuery.isSmallerThanLaptop} {
        width: 100vw;
        .casino-payback-booster-games-modal-view-results-container {
            padding: var(--spacing-12);
        }
        .casino-payback-booster-games-modal-view-game-thumbnail {
            display: inline-flex;
        }
        .casino-payback-booster-games-modal-view-game-thumbnail {
            padding: ${({ theme }) => theme.layout.gutter} ${({ theme }) => theme.layout.gutterHalf};
        }
        .casino-payback-booster-games-modal-view-game-thumbnail,
        .casino-payback-booster-games-modal-view-game-background {
            width: 100%;
            height: 100%;
            vertical-align: top;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .casino-payback-booster-games-modal-view-results-body {
            display: grid;
            grid-template-columns: repeat(5, 1fr);

            @media ${mediaQuery.isTabletMediumSmall} {
                grid-template-columns: repeat(4, 1fr);
            }

            @media ${mediaQuery.isPhone} {
                grid-template-columns: repeat(4, 1fr);
            }

            @media ${mediaQuery.isPhoneMedium} {
                grid-template-columns: repeat(3, 1fr);
            }

            @media (max-width: 450px) {
                grid-template-columns: repeat(2, 1fr);
            }
            gap: 1rem;
        }

        .casino-payback-booster-games-modal-view-results-container {
            padding: var(--spacing-12);
        }
    }

    ${({ mobile }) =>
        mobile &&
        css`
            justify-content: space-between;
        `};
`;
