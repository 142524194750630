import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    position: relative;

    display: flex;
    align-items: center;
    padding: 1.5rem;
    background-color: var(--surface-level-1-bg);

    transition: background-color 100ms ease-in-out;
    cursor: pointer;
    border-radius: 10px;
    margin: 0 0 1.2rem 0;
    &:last-of-type {
        margin-bottom: 0;
    }
    &:hover {
        background-color: var(--surface-level-2-bg);
    }
    .crm-status {
        position: absolute;
        top: -10px;
        right: 10px;

        display: flex;
        align-items: center;
        text-align: center;

        border-radius: 100px;
        font-size: 0.6rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 2px 4px;

        background-color: #00bfff;
        color: #000;

        &.expired {
            background-color: var(--color-highlight);
        }
    }
    .message-time {
        font-size: 0.7rem;
        color: var(--font-color-subtle);
        margin-bottom: 0.5rem;
        text-align: right;
    }
    .crm-inbox-message-text {
        padding: 0 0.5rem;
        > h1 {
            font-size: 1.2rem;
            color: var(--font-color-primary);
            margin: 0 0 0.8rem 0;
            padding: 0;
            font-weight: normal;
        }
        > p {
            font-size: 0.875rem;
            color: var(--font-color-subtle);
            margin: 0;
            padding: 0;
            line-height: 1.2rem;
            @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
                font-size: 1.2rem;
                line-height: 1.4rem;
            }
        }
        ol,
        ul {
            padding: 0 0 0 1.6rem;
        }
    }
`;
