import { FoEvent, FoMarket, FoOutcome, FoTicket } from '@staycool/highlight-games-types';

export interface VirtualSportEvent extends FoEvent {
    id: number;
    homeTeamName?: string;
    awayTeamName?: string;
    markets: Market[];
    match_day?: number;
    season_display_id?: string;
    home_team_id: string;
    away_team_id: string;
    season_id: string;
}

export const BETSLIP_MODE = {
    HISTORY: 'HISTORY',
    BETSLIP: 'BETSLIP',
};

export interface Market extends FoMarket {
    gameWeek: number;
    id: number;
    outcomes: Outcome[];
    status: BetradarMarketStatus;
}

type OutcomeResult = 'won' | 'lost' | 'pending';

export interface Outcome extends FoOutcome {
    id: number;
    result: OutcomeResult;
}

export interface Ticket extends FoTicket {
    provider: string;
}
