import React, { useEffect, useRef, useState } from 'react';
import Wrapper from './styles';
import UiButton from '../../../ui/button/UiButton';
import UiModal from '../../../ui/modal/UiModal';
import UiBox from '../../../ui/box/UiBox';
import UiHeading from '../../../ui/heading/UiHeading';
import UiFormInput from '../../../ui/form/input/UiFormInput';
import { translate } from '../../../../services/translate';
import UiTable from '../../../ui/table/UiTable';
import { Campaign, getOddsCompetitionLeaderboard, LeaderBoard } from '../../../../microservices/sb-campaigns';
import moment from 'moment';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import UiDotsLoader from '../../../ui/dots-loader/UiDotsLoader';
import UiCloseButton from '../../../ui/close-button/UiCloseButton';
import classNames from 'classnames';

interface Props {
    campaignId: number;
    'campaign-id': number;
    linkText: string;
    'link-text': string;
    headerText: string;
    'header-text': string;
}

export default function CmsLeaderboardOddsPerPeriod({
    'campaign-id': campaignId,
    'link-text': linkText,
    'header-text': headerText,
}: Props) {
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [campaign, setCampaign] = useState<Campaign>();
    const [leaderboard, setLeaderboard] = useState<LeaderBoard[]>([]);
    const [periods, setPeriods] = useState<string[]>([]);
    const [userBetsByPeriod, setUserBetsByPeriod] = useState({});

    const [searchInput, setSearchInput] = useState('');
    const [firstMatchingPosition, setFirstMatchingPosition] = useState<LeaderBoard | undefined>(undefined);

    const leaderboardReference = useRef<HTMLDivElement>(null);

    useEffect(() => {
        initialize();
    }, []);

    useEffect(() => {
        if (!searchInput) {
            setFirstMatchingPosition(undefined);
            return;
        }

        const firstMatchingPosition = leaderboard.find((position) => position.user_alias.startsWith(searchInput));
        setFirstMatchingPosition(firstMatchingPosition);
    }, [searchInput]);

    useEffect(() => {
        if (!firstMatchingPosition || !leaderboardReference?.current) {
            return;
        }
        const leaderboardPositionHeight = 46.8;
        const activeElementPosition = leaderboard.indexOf(firstMatchingPosition);
        leaderboardReference.current.scroll({
            top: activeElementPosition * leaderboardPositionHeight,
            left: 0,
            behavior: 'smooth',
        });
    }, [firstMatchingPosition]);

    async function initialize() {
        setIsLoading(true);

        const { campaign, leaderBoard: leaderboard } = await getOddsCompetitionLeaderboard(campaignId);
        const currentCampaign = campaign[0];

        setCampaign(currentCampaign);
        setLeaderboard(leaderboard);

        const betPeriodStartOf = currentCampaign.bet_period === 'week' ? 'isoWeek' : currentCampaign.bet_period;
        const start = moment(currentCampaign.start_date).startOf(betPeriodStartOf);
        const end = moment(currentCampaign.end_date).startOf(betPeriodStartOf);

        const periods = [start.format('YYYY-MM-DDTHH:mm:ss+00:00')];
        const betPeriod = currentCampaign.bet_period;
        while (start.isBefore(end)) {
            start.add(1, betPeriod);
            periods.push(start.format('YYYY-MM-DDTHH:mm:ss+00:00'));
        }
        setPeriods(periods);

        setUserBetsByPeriod(
            mapValues(keyBy(leaderboard, 'user_alias'), (userBets) => keyBy(userBets.bets_by_period, 'bet_period')),
        );

        setIsLoading(false);
    }

    return (
        <>
            <UiButton onClick={() => setIsModalOpen(true)}>{linkText && <span>{linkText}</span>}</UiButton>

            <UiModal open={isModalOpen} onClose={() => setIsModalOpen(false)} maxWidth="100%">
                <Wrapper>
                    <UiBox className="leaderboard-modal-box">
                        <div className="leaderboard-header">
                            <UiCloseButton onClick={() => setIsModalOpen(false)} className="close-button" />

                            {headerText && <UiHeading title={headerText} />}

                            <UiFormInput
                                name="search-input"
                                placeholder={translate('Search for your user alias', 'ui.sports.campaigns.leaderboard')}
                                value={searchInput}
                                onValueChange={setSearchInput}
                                className="leaderboard-search-input"
                            />
                        </div>

                        <div className="leaderboard-positions" ref={leaderboardReference}>
                            {isLoading && <UiDotsLoader />}

                            {!isLoading && campaign && (
                                <UiTable>
                                    <div className="table-row heading">
                                        <div className="table-cell">
                                            {translate('RANK', 'ui.sports.campaigns.leaderboard.odds_per_period')}
                                        </div>
                                        <div className="table-cell">
                                            {translate('ALIAS', 'ui.sports.campaigns.leaderboard.daily')}
                                        </div>
                                        {periods.map((period, index) => (
                                            <div className="table-cell" key={index}>
                                                {translate(
                                                    `POINTS ${campaign.bet_period}`,
                                                    'ui.sports.campaigns.leaderboard.odds_per_period',
                                                )}{' '}
                                                {index + 1}
                                            </div>
                                        ))}
                                        <div className="table-cell">
                                            {translate(
                                                'TOTAL POINTS',
                                                'ui.sports.campaigns.leaderboard.odds_per_period',
                                            )}
                                        </div>
                                    </div>

                                    {leaderboard.map((position, index) => (
                                        <div
                                            className={classNames('table-row', {
                                                active: position === firstMatchingPosition,
                                            })}
                                            key={index}
                                        >
                                            <div className="table-cell">{position.ranking}</div>
                                            <div className="table-cell">{position.user_alias}</div>
                                            {periods.map((period, index) => (
                                                <div className="table-cell" key={index}>
                                                    {(userBetsByPeriod[position.user_alias][period] &&
                                                        userBetsByPeriod[position.user_alias][period].odds) ||
                                                        0}
                                                </div>
                                            ))}
                                            <div className="table-cell">{position.total_odds}</div>
                                        </div>
                                    ))}
                                </UiTable>
                            )}
                        </div>
                    </UiBox>
                </Wrapper>
            </UiModal>
        </>
    );
}
