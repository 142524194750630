import styled, { css } from 'styled-components';

export default styled.div`
    &.match-container {
        display: flex;
        flex: 1;
        min-width: 0;
        ${({ isOpen }) =>
            isOpen &&
            css`
                padding: 0.8rem 0 0 0;
            `};
    }
    .match-name {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .match-time {
        display: flex;
        flex-direction: column;
        justify-content: center;

        color: var(--match-secondary-color);
        text-align: center;
        font-size: 0.6rem;

        width: 55px;
        min-width: 55px;
        margin: 0 var(--spacing-4);
    }

    .match-live {
        .live-period {
            white-space: normal;
        }
        > *:not(:last-child) {
            margin-bottom: 0.8rem;
        }
    }
    .match-main-content {
        flex: 1;
        min-width: 0;
    }
    .match-markets {
        margin-bottom: 0.4rem;
    }
    .match-info {
        display: flex;
        justify-content: space-between;
        > *:not(:last-child) {
            margin-right: 0.4rem;
        }
    }
    .match-extra {
        width: 3.5rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > * {
            padding: 0.6rem 0;
        }
    }
`;
