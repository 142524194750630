import { useEffect } from 'react';
import { stores } from '../../stores';
import { parse } from 'query-string';
import { storageSet } from '../../services/storage';
import { AppType } from '../../services/mobile-app';
import { useStore } from '../../hooks/useStore';

export default function MobileApp() {
    const [, setAppType] = useStore(stores.appType);

    useEffect(() => {
        const queryParams = parse(window.location.search);
        if (queryParams.appType) {
            const appType = AppType[queryParams.appType?.toString()?.toUpperCase()] || AppType.WEB;
            storageSet('appType', appType);
            setAppType(appType);
        }
    }, []);

    return null;
}
