import React from 'react';
import Svg from '../../../../svg/Svg';
import Wrapper from './styles';
import { useTurnoverMarket } from '../../../../../services/sports/top-markets';
import { CategoryMatch } from '../../../../../services/sports/types';
import { isRetail } from '../../../../../services/environment';

interface Props {
    onTurnoverClick: (isTurnoverVisible: boolean) => void;
    isTurnoverVisible: boolean;
    match: CategoryMatch;
}

export default function SportMatchTurnoverButton({ onTurnoverClick, isTurnoverVisible, match }: Props) {
    const turnoverMarket = useTurnoverMarket(match);
    const isRetailLayout = isRetail();

    if (!turnoverMarket) {
        return null;
    }
    return (
        <Wrapper
            onClick={() => onTurnoverClick(!isTurnoverVisible)}
            className={isTurnoverVisible ? 'turnover active' : 'turnover'}
        >
            <Svg
                icon={isTurnoverVisible ? 'turnover-ico-filled' : 'turnover-ico'}
                size={isRetailLayout ? 1.75 : 1.25}
            />
        </Wrapper>
    );
}
