import React, { useEffect } from 'react';
import Wrapper from './styles';
import Svg from '../../../svg/Svg';
import { isStreamAllowed } from '../../../../services/stream';
import { MATCH_STATUS, MATCH_TYPE } from '../../../../services/sports/constants';
import { CategoryMatch } from '../../../../services/sports/types';
import classNames from 'classnames';
import { useSportsMatchContext } from '../../../../services/sports/match-helpers';
import SportMatchTurnoverButton from '../turnover/button/SportMatchTurnoverButton';
import SportFavoriteStar from '../../favorite/star/SportFavoriteStar';
import { FEATURE, isFeatureAvailable, isGeniusStreamAvailable } from '../../../../services/feature';
import SportMatchSidebetsCount from '../sidebets-count/SportMatchSidebetsCount';
import SportMatchStatistics from '../statistics/SportMatchStatistics';
import { stores } from '../../../../stores';
import { translate } from '../../../../services/translate';
import { getRoute } from '../../../../services/router';
import { getScoreboardType } from '../../../../services/sports/inplay-helpers';
import { isScoreboardEnabled } from '../../../../services/utils/b2b';
import { isRetail } from '../../../../services/environment';
import SportMatchBetbuilderButton from '../betbuilder/button/SportMatchBetbuilderButton';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    match: CategoryMatch;
    isVertical?: boolean;
    isPrimary?: boolean;
    isSecondary?: boolean;
}
export default function SportMatchExtra({ match, isVertical, isPrimary, isSecondary }: Props) {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const isLiveNow = match.status === MATCH_STATUS.LIVE;
    const shouldHaveGeniusStream = match.has_genius_stream;
    const isBetbuilderMatch = match.betbuilder_id;
    const isRegularStream = match?.stream_info && isStreamAllowed(match.sport_category);
    const isRetailLayout = isRetail();
    const matchRoute = isLiveNow ? `${getRoute('sport.live')}/${match.id}` : `${getRoute('sport.match')}/${match.id}`;
    const isMatchPage = window.location.pathname.includes(matchRoute);
    const data = useSportsMatchContext();
    const {
        turnoverVisible,
        currentVisualization,
        isCurrentVisualizationIconClicked,
        isOpen,
        setContext: setMatchContext,
    } = data;
    const [{ isPhone }] = useStore(media);
    const [currentStreamMatchId, setCurrentStreamMatchId] = useStore(stores.sports.liveNow.currentStreamMatchId);
    const [, setIsLoginModalOpen] = useStore(stores.modals.isLoginModalOpen);
    const isNormalMatch = match.match_type !== MATCH_TYPE.OUTRIGHT;

    const isBetBuilderVisible =
        isFeatureAvailable(FEATURE.BETBUILDER) && !isLiveNow && isBetbuilderMatch && (isPhone || isPrimary);

    useEffect(() => {
        if (currentStreamMatchId !== match.id) {
            setMatchContext?.({ currentVisualization: '' });
        } else {
            setMatchContext?.({ currentVisualization: 'stream' });
        }
    }, [currentStreamMatchId]);

    useEffect(() => {
        if (!isAuthenticated) {
            setCurrentStreamMatchId(undefined as unknown as number);
        }
    }, [isAuthenticated]);

    function toggleMatchVisualisation(type: string) {
        setMatchContext?.({
            currentVisualization: currentVisualization !== type ? type : '',
            isCurrentVisualizationIconClicked: !isCurrentVisualizationIconClicked,
        });
    }

    function handleStreamClick() {
        if (!isAuthenticated) {
            setIsLoginModalOpen(true);
        }
        setCurrentStreamMatchId(match.id === currentStreamMatchId ? (undefined as unknown as number) : match.id);
    }

    return (
        <Wrapper $isVertical={isVertical}>
            {(isPrimary || isPhone) && (
                <>
                    {(isRegularStream || (shouldHaveGeniusStream && isGeniusStreamAvailable())) &&
                        !isRetailLayout &&
                        isLiveNow && (
                            <div
                                className={classNames('stream-icon', {
                                    active:
                                        currentVisualization === 'stream' &&
                                        currentStreamMatchId === match.id &&
                                        isAuthenticated,
                                })}
                            >
                                <Svg
                                    icon="sport-stream-ico"
                                    size={isRetailLayout ? 1.5 : 1}
                                    onClick={handleStreamClick}
                                />
                                {!isOpen && (
                                    <div className="stream-tooltip">
                                        {translate('Live stream available', 'ui.sportsbook')}
                                    </div>
                                )}
                            </div>
                        )}
                    {isLiveNow && !match.stream_info && isScoreboardEnabled(getScoreboardType(match)) && (
                        <div className={classNames({ active: isCurrentVisualizationIconClicked })}>
                            <Svg
                                icon={currentVisualization ? 'stats-ico-filled' : 'stats-ico'}
                                size={isRetailLayout ? 1.5 : 1}
                                onClick={() => toggleMatchVisualisation('scoreboard')}
                            />
                        </div>
                    )}
                </>
            )}
            {isPrimary && !isPhone && !isLiveNow && !isRetailLayout && (
                <SportMatchStatistics externalId={match.external_id} />
            )}

            {(isPhone || isSecondary) && isNormalMatch && <SportFavoriteStar matchId={match.id} />}

            {isFeatureAvailable(FEATURE.MATCH_TURNOVER) && (isPhone || isSecondary || isRetailLayout) && (
                <SportMatchTurnoverButton
                    match={match}
                    isTurnoverVisible={turnoverVisible}
                    onTurnoverClick={() => setMatchContext?.({ turnoverVisible: !turnoverVisible })}
                />
            )}
            {isBetBuilderVisible && <SportMatchBetbuilderButton match={match} />}

            {(isPhone || isPrimary) && !isMatchPage && <SportMatchSidebetsCount match={match} />}
        </Wrapper>
    );
}
