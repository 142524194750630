import React, { ReactNode } from 'react';
import Wrapper from './styles';

interface Props {
    className?: string;
    children: ReactNode;
}

export default function UiSubHeading({ children }: Props) {
    return <Wrapper>{children}</Wrapper>;
}
