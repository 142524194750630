import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../analytics';
import { storageSet } from '../storage';
import { CasinoGame, isCasinoGameDisabled, navigateToCasinoGamePage } from './games';
import uniqBy from 'lodash/uniqBy';

export interface RecentlyPlayed {
    userId: string;
    gameGroupId: number;
    gameVariantId: number;
    createdAt?: Date | string;
    updatedAt?: Date | string;
}

export function storeRecentlyPlayedGames(recentlyPlayed: RecentlyPlayed[]) {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);

    if (!recentlyPlayed?.length) {
        return;
    }

    if (isAuthenticated) {
        storageSet('recentlyPlayed', recentlyPlayed);
    }

    const gamesByGroupId = getStoreValue<{ [key: number]: CasinoGame }>(stores.casino.gamesByGroupId);
    const recentlyPlayedGames = uniqBy(recentlyPlayed, 'gameGroupId')
        .filter((recentlyPlayed) => gamesByGroupId[recentlyPlayed.gameGroupId]?.isPublic)
        .map((recentlyPlayed) => {
            return gamesByGroupId[recentlyPlayed.gameGroupId];
        });

    stores.casino.recentlyPlayedGames.set(recentlyPlayedGames);
}

export function openGame(
    event: React.MouseEvent<HTMLElement>,
    game: CasinoGame | null,
    navigateTo: (path: string, state?: string | undefined) => void,
): void {
    event.preventDefault();
    if (!game || isCasinoGameDisabled(game)) {
        return;
    }
    stores.casino.latestGameLaunchPosition.set({ launchSource: 'MY_CASINO' });

    trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.CASINO_MYCASINO_GAME_OPENED);
    navigateToCasinoGamePage(navigateTo, game);
}

export interface GamePlayedCount {
    gameGroupId: number;
    playCount: number;
}

export interface UserStatistics {
    biggestWin?: {
        gameGroupId: number;
        multiplier: number;
        betsUc: number;
        winsUc: number;
    };
    highestRtp?: {
        gameGroupId: number;
        rtp: number;
    };
    mostPlayedGameGroupId?: number;
    totalPlayedGames: number;
    totalSpins: number;
    latestUpdate: Date | null;
}

export interface UserActivity {
    rtp: UserActivitySegment;
    spins: UserActivitySegment;
    hitRate: UserActivitySegment;
    betsWins: {
        bets: number;
        wins: number;
        trend: {
            day: string;
            bets: number;
            wins: number;
        }[];
        games: GameTotalBetsWins[];
    };
    latestUpdate: Date | null;
}
interface GameTotal {
    gameGroupId: number;
    total: number;
}
interface GameTotalBetsWins {
    gameGroupId: number;
    bets: number;
    wins: number;
}
interface UserActivitySegment {
    total: number;
    trend: { day: string; total: number }[];
    games: GameTotal[];
}

export interface CasinoDateFilter {
    period: Period;
    startDate: string | null | Date;
    endDate: string | null | Date;
    createdAt?: Date | string;
    updatedAt?: Date | string;
}

export interface UserHighestWin {
    betsUc: number;
    createdAt?: Date | string;
    updatedAt?: Date | string;
    gameRoundId: number;
    gameGroupId: number;
    multiplier: number;
    winsUc: number;
    image?: string;
    gameName?: string;
}

export interface HighestDailyWins {
    id: number;
    gameGroupId: number;
    city: string;
    country: string;
    winsUc: number;
    multiplier: number;
}

export enum Period {
    LAST_7_DAYS = 'last_7_days',
    LAST_30_DAYS = 'last_30_days',
    CURRENT_YEAR = 'current_year',
    CUSTOM = 'custom',
}
