import React, { useEffect, useState } from 'react';
import { animateScroll } from 'react-scroll';
import { UserCrmPreferences, updateUserSubscriptionPreferences } from '../../../../microservices/crm';
import { acceptTermsAndConditions } from '../../../../microservices/users';
import { updateProfile } from '../../../../microservices/users-cb';
import { REGISTRATION_FLOW, changePassword, getPayAndPlayMessage, loadHasPassword } from '../../../../services/auth';
import { useForm } from '../../../../services/form';
import { logger } from '../../../../services/logger';
import { loadAskLimit } from '../../../../services/responsible-gaming';
import { translate } from '../../../../services/translate';
import { UserSC } from '../../../../services/types';
import { getUpdateUserErrors, loadProfile } from '../../../../services/user';
import { validators } from '../../../../services/validators';
import { stores } from '../../../../stores';
import RegistrationFieldsAcceptTermsAndConditions from '../../../registration/fields/accept-terms-and-conditions/RegistrationFieldsAcceptTermsAndConditions';
import RegistrationFieldsAlias from '../../../registration/fields/alias/RegistrationFieldsAlias';
import RegistrationFieldsEmailAndPassword from '../../../registration/fields/email-and-password/RegistrationFieldsEmailAndPassword';
import RegistrationFieldsHasAcceptedAgeAndPlayingOwnBehalf from '../../../registration/fields/has-accepted-age-and-playing-own-behalf/RegistrationFieldsHasAcceptedAgeAndPlayingOwnBehalf';
import RegistrationFieldsPhoneNumber from '../../../registration/fields/phone-number/RegistrationFieldsPhoneNumber';
import RegistrationFieldsSubscriptions from '../../../registration/fields/subscriptions/RegistrationFieldsSubscriptions';
import UiAlert from '../../../ui/alert/UiAlert';
import UiBox from '../../../ui/box/UiBox';
import UiButton from '../../../ui/button/UiButton';
import UiForm from '../../../ui/form/UiForm';
import UiFormInput from '../../../ui/form/input/UiFormInput';
import UiGroup from '../../../ui/group/UiGroup';
import UiSubHeading from '../../../ui/sub-heading/UiSubHeading';
import AuthRegisterAutoFill from '../../register/auto-fill/AuthRegisterAutoFill';
import Wrapper from './styles';
import { useStore } from '../../../../hooks/useStore';

export default function AuthProfileValidatorEssentialRequirement() {
    const [isEssentialUserInformationInquiryModalOpen, setIsEssentialUserInformationInquiryModalOpen] = useStore(
        stores.modals.isEssentialUserInformationInquiryModalOpen,
    );
    const [backendErrors, setBackendErrors] = useStore(stores.registration.errors);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [language] = useStore(stores.language);
    const [hasPassword] = useStore(stores.hasPassword);
    const [payAndPlayAuthType, setPayAndPlayAuthType] = useStore(stores.payAndPlayAuthType);
    const [payAndPlaySuccessMessageType, setPayAndPlaySuccessMessageType] = useStore(
        stores.payAndPlaySuccessMessageType,
    );
    const [headingContent, setHeadingContent] = useState();
    const [message, setMessage] = useState();
    const [user] = useStore(stores.user);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [type, setType] = useState<any>();

    const form = useForm(stores.registration.form);

    const isEmailEntryRequired = !user?.email;

    useEffect(() => {
        if (isEssentialUserInformationInquiryModalOpen && isAuthenticated && isEmailEntryRequired) {
            loadExistsPassword();
        }
    }, [isEssentialUserInformationInquiryModalOpen, isAuthenticated, user]);

    useEffect(() => {
        if (backendErrors) {
            form.setInputValidationMessage(backendErrors);
        }
    }, [backendErrors]);

    useEffect(() => {
        if (payAndPlayAuthType && payAndPlaySuccessMessageType) {
            setHeadingContent(getPayAndPlayMessage('HEADING'));
            setMessage(getPayAndPlayMessage(payAndPlaySuccessMessageType));
            setType(getMessageType(payAndPlaySuccessMessageType));
        }
    }, [payAndPlayAuthType, payAndPlaySuccessMessageType]);

    if (!isAuthenticated || !isEssentialUserInformationInquiryModalOpen) {
        return null;
    }

    lockBodyScroll();
    const isAliasEntryRequired = !user?.alias;
    const isPhoneNumberEntryRequired = !user?.phoneNumber;
    const isSubscriptionOptionRequired =
        !user?.subscribeMailList && !user?.subscribeDirectMail && !user?.subscribePhoneCalls && !user?.subscribeSms;
    const isTermsAgreedRequired = !user?.acceptTermsAndConditions;
    const isAgeRestrictionAgreedRequired = !user?.acceptTermsAndConditions && !user?.hasAcceptedAgeAndPlayingOwnBehalf;
    const isAddressEntryRequired = Boolean(validators.address(user?.address as string));

    async function loadExistsPassword() {
        setIsLoading(true);
        await loadHasPassword();
        setIsLoading(false);
    }

    function getMessageType(payAndPlaySuccessMessageType) {
        if (payAndPlaySuccessMessageType === 'FAIL_BONUS') {
            return { failure: true };
        }
        return { success: true };
    }

    function handleModalDismissal() {
        setPayAndPlayAuthType(undefined);
        setPayAndPlaySuccessMessageType(undefined);
        setIsEssentialUserInformationInquiryModalOpen(false);
        unlockBodyScroll();
    }

    function getFieldsToSave() {
        const {
            address,
            email,
            alias,
            phoneNumber,
            subscribeSms,
            subscribeDirectMail,
            subscribeMailList,
            subscribePhoneCalls,
            hasAcceptedAgeAndPlayingOwnBehalf,
        } = form.getValues();
        const fieldsToSave: Partial<UserSC> = {};

        if (isAgeRestrictionAgreedRequired) {
            fieldsToSave.has_accepted_age_and_playing_own_behalf = hasAcceptedAgeAndPlayingOwnBehalf;
        }
        if (isEmailEntryRequired) {
            fieldsToSave.email = email;
        }
        if (isAliasEntryRequired) {
            fieldsToSave.alias = alias;
        }
        if (isPhoneNumberEntryRequired) {
            fieldsToSave.phone_number = phoneNumber;
        }
        if (isAddressEntryRequired) {
            fieldsToSave.address = address;
        }
        if (isSubscriptionOptionRequired) {
            fieldsToSave.subscribe_sms = subscribeSms;
            fieldsToSave.subscribe_direct_mail = subscribeDirectMail;
            fieldsToSave.subscribe_mail_list = subscribeMailList;
            fieldsToSave.subscribe_phone_calls = subscribePhoneCalls;
        }
        if (!user?.language) {
            fieldsToSave.language = language;
        }
        return fieldsToSave;
    }

    async function saveProfile(isFormValid) {
        setError('');
        if (!isFormValid) {
            return;
        }

        setIsLoading(true);
        try {
            const fieldsToSave = getFieldsToSave();
            const userPreferencesPayload: Omit<UserCrmPreferences, 'user_id'> = {
                subscribeDirectMail: Boolean(fieldsToSave?.subscribe_direct_mail),
                subscribeMailList: Boolean(fieldsToSave?.subscribe_mail_list),
                subscribeSms: Boolean(fieldsToSave?.subscribe_sms),
                subscribePhoneCalls: Boolean(fieldsToSave?.subscribe_phone_calls),
            };
            await updateUserSubscriptionPreferences(userPreferencesPayload);
            await updateProfile({
                address: fieldsToSave.address,
                email: fieldsToSave.email,
                alias: fieldsToSave.alias,
                phone_number: fieldsToSave.phone_number,
                language: fieldsToSave.language,
                has_accepted_age_and_playing_own_behalf: fieldsToSave.has_accepted_age_and_playing_own_behalf,
            });
            if (!hasPassword && form.password.value) {
                await changePassword(form.password.value);
            }
            if (isTermsAgreedRequired && form.acceptTermsAndConditions.value) {
                await acceptTermsAndConditions();
            }
            await Promise.all([loadProfile({ isThrowingOnError: true }), loadAskLimit()]);
            handleModalDismissal();
            stores.crm.userPreferences.set(userPreferencesPayload);
            stores.modals.isRequiredProfileInformationInquiryModalOpen.set(true);
        } catch (error: any) {
            if (error.errors) {
                setBackendErrors(getUpdateUserErrors(error.errors));
            } else {
                logger.error('AuthProfileValidatorEssentialRequirement', 'fieldsToSave', error.message);
                setError(translate('Failed to update fields', 'ui.common'));
            }
        }

        setIsLoading(false);
    }

    function scrollDown() {
        animateScroll.scrollToBottom({
            containerId: 'info-required-form',
        });
    }

    function lockBodyScroll() {
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
    }

    function unlockBodyScroll() {
        document.body.style.position = 'initial';
        document.body.style.width = 'initial';
    }

    return (
        <Wrapper open={isEssentialUserInformationInquiryModalOpen && isAuthenticated}>
            <UiBox id="info-required-form" className="info-required-form">
                {message && <UiAlert {...type}>{message}</UiAlert>}
                <UiSubHeading>
                    {headingContent || translate('Please complete the form below to start playing', 'ui.pay-and-play')}
                </UiSubHeading>
                <br />
                <AuthRegisterAutoFill flow={REGISTRATION_FLOW.PAY_AND_PLAY} />
                <UiGroup vertical>
                    <UiForm onSubmit={saveProfile} isLoading={isLoading}>
                        {isEmailEntryRequired && (
                            <RegistrationFieldsEmailAndPassword
                                emailForm={form.email}
                                passwordForm={form.password}
                                isPasswordRequired={!hasPassword}
                            />
                        )}
                        {isAliasEntryRequired && <RegistrationFieldsAlias form={form.alias} />}
                        {isPhoneNumberEntryRequired && (
                            <RegistrationFieldsPhoneNumber isUseValidPhoneTextVisible={false} form={form} />
                        )}
                        {isAddressEntryRequired && (
                            <UiFormInput
                                {...form.address}
                                placeholder={translate('Street address and home number', 'ui.account')}
                                validator={validators.address}
                                label={translate('Address', 'ui.registration')}
                                maxLength={64}
                                data-test="address"
                            />
                        )}
                        <div onClick={scrollDown}>
                            {isSubscriptionOptionRequired && <RegistrationFieldsSubscriptions />}
                            {isAgeRestrictionAgreedRequired && <RegistrationFieldsHasAcceptedAgeAndPlayingOwnBehalf />}
                            {isTermsAgreedRequired && <RegistrationFieldsAcceptTermsAndConditions />}
                        </div>

                        <div className="essential-info-confirm">
                            <UiButton isFormSubmitButton isLoading={isLoading} color="primary" block>
                                {translate('Confirm', 'ui.common')}
                            </UiButton>
                        </div>
                    </UiForm>
                </UiGroup>

                {error && <UiAlert failure>{error}</UiAlert>}
            </UiBox>
        </Wrapper>
    );
}
