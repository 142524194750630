import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useProofOfAddressVerificationContext } from '../../../../../../contexts/proof-of-address-verification/ProofOfAddressVerificationContext';
import { updateProfile } from '../../../../../../microservices/users-cb';
import { translate } from '../../../../../../services/translate';
import { loadProfile } from '../../../../../../services/user';
import { stores } from '../../../../../../stores';
import UiButton from '../../../../../ui/button/UiButton';
import UiAlert from '../../../../../ui/alert/UiAlert';
import UiFormGroup from '../../../../../ui/form/group/UiFormGroup';
import UiFormInput from '../../../../../ui/form/input/UiFormInput';
import UiFormLabel from '../../../../../ui/form/label/UiFormLabel';
import AccountVerificationDocumentPreview from '../../../document/preview/AccountVerificationDocumentPreview';
import AccountVerificationProofOfAddressFooter from '../../footer/AccountVerificationProofOfAddressFooter';
import AccountVerificationProofOfAddressHeader from '../../header/AccountVerificationProofOfAddressHeader';
import { validators } from '../../../../../../services/validators';
import Wrapper from './styles';
import { useStore } from '../../../../../../hooks/useStore';

export default function AccountVerificationProofOfAddressSteps3() {
    const { submissionState, updateSubmissionState, documentUpload } = useProofOfAddressVerificationContext();
    const removeAddressAlertTimeoutRef = useRef<NodeJS.Timeout>();
    const [user, setUser] = useStore(stores.user);
    const [address, setAddress] = useState(user?.address || '');
    const [city, setCity] = useState(user?.city || '');
    const [zip, setZip] = useState(user?.zip || '');
    const [addressAlert, setAddressAlert] = useState<ReactNode>();
    const { isNameVisible, isAddressVisible, isAddressEqualToAccountAddress, isDocumentNotOlderThanThreeMonths } =
        submissionState;

    useEffect(() => clearRemoveAddressAlertTimeout, []);

    async function handleUpdatingAddress() {
        if (!user) {
            return;
        }
        clearRemoveAddressAlertTimeout();
        setAddressAlert(undefined);
        setUser({ ...user, address, city, zip });
        try {
            await updateProfile({ address, city, zip });
            setAddressAlert(<UiAlert success>{translate('Account info updated', 'ui.account')}</UiAlert>);
        } catch (error: any) {
            const errorMessage = error.errors?.[0]?.[0] || 'Oops!';
            setAddressAlert(<UiAlert failure>{translate(errorMessage, 'ui.account')}</UiAlert>);
        } finally {
            removeAddressAlertTimeoutRef.current = setTimeout(() => setAddressAlert(undefined), 5000);
            await loadProfile();
        }
    }

    function clearRemoveAddressAlertTimeout() {
        if (removeAddressAlertTimeoutRef.current) {
            clearTimeout(removeAddressAlertTimeoutRef.current);
            removeAddressAlertTimeoutRef.current = undefined;
        }
    }

    return (
        <>
            <AccountVerificationProofOfAddressHeader>
                <span>{translate('Review your document', 'ui.account.kyc')}</span>
            </AccountVerificationProofOfAddressHeader>
            <AccountVerificationDocumentPreview documents={documentUpload.documents} itemHeight={512} isColumnLayout />
            <Wrapper>
                <UiFormInput
                    label={translate('Name is visible', 'ui.account.kyc')}
                    checkbox
                    value={isNameVisible}
                    onValueChange={(isNameVisible: boolean) => updateSubmissionState({ isNameVisible })}
                />
                <UiFormInput
                    label={translate('Address is visible', 'ui.account.kyc')}
                    checkbox
                    value={isAddressVisible}
                    onValueChange={(isAddressVisible: boolean) => updateSubmissionState({ isAddressVisible })}
                />
                <UiFormInput
                    label={translate('Document is not older than three months', 'ui.account.kyc')}
                    checkbox
                    value={isDocumentNotOlderThanThreeMonths}
                    onValueChange={(isDocumentNotOlderThanThreeMonths: boolean) =>
                        updateSubmissionState({ isDocumentNotOlderThanThreeMonths })
                    }
                />
                {user && (
                    <>
                        <UiFormInput
                            label={translate('Address is same as the one on my account', 'ui.account.kyc')}
                            checkbox
                            value={isAddressEqualToAccountAddress}
                            onValueChange={(isAddressEqualToAccountAddress: boolean) =>
                                updateSubmissionState({ isAddressEqualToAccountAddress })
                            }
                        />
                        <div className="address-input-container">
                            <UiFormLabel className="address-input-label">
                                {translate('Address', 'ui.account')}
                            </UiFormLabel>
                            <UiFormGroup row>
                                <UiFormInput value={user.country} disabled />
                                <UiFormInput
                                    value={city}
                                    onValueChange={setCity}
                                    maxLength={26}
                                    placeholder={translate('City', 'ui.account')}
                                />
                                <UiFormInput
                                    value={zip}
                                    onValueChange={setZip}
                                    maxLength={16}
                                    placeholder={translate('Zip Code', 'ui.common')}
                                />
                            </UiFormGroup>
                            <UiFormGroup row>
                                <UiFormInput
                                    value={address}
                                    onValueChange={setAddress}
                                    maxLength={64}
                                    placeholder={translate('Street address and home number', 'ui.account')}
                                    validator={validators.address}
                                />
                                <UiButton
                                    disabled={
                                        !(address && city && zip) ||
                                        (address === user.address && city === user.city && zip === user.zip)
                                    }
                                    onClick={handleUpdatingAddress}
                                >
                                    {translate('Update', 'ui.account')}
                                </UiButton>
                            </UiFormGroup>
                        </div>
                        {addressAlert}
                    </>
                )}
            </Wrapper>
            <AccountVerificationProofOfAddressFooter nextButtonText={translate('Submit', 'ui.account')} />
        </>
    );
}
