import React from 'react';
import {
    ProofOfIdVerificationContextConsumer,
    ProofOfIdVerificationContextProvider,
} from '../../../../contexts/proof-of-id-verification/ProofOfIdVerificationContext';
import { getBadgeProps } from '../../../../services/kyc';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import AccountVerificationDocumentCapture from '../document-capture/AccountVerificationDocumentCapture';
import AccountVerificationMultiStepBox from '../multi-step/box/AccountVerificationMultiStepBox';
import AccountVerificationProofOfIdSteps from './steps/AccountVerificationProofOfIdSteps';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    isCompactView?: boolean;
}

export default function AccountVerificationProofOfId(props: Props) {
    const [kycSettings] = useStore(stores.kyc.settings);

    if (kycSettings && kycSettings.isKycCaptureEnabled) {
        return <AccountVerificationDocumentCapture />;
    }

    if (kycSettings && !kycSettings.isProofOfIdEnabled) {
        return null;
    }

    return (
        <ProofOfIdVerificationContextProvider>
            <ProofOfIdVerificationContextConsumer>
                {({ activeStep, documentUpload, setActiveStep, stepCompletionState, formContainerRef }) => {
                    const badgeProps =
                        activeStep > 0
                            ? { badgeText: translate('In progress', 'ui.common'), badgeColor: 'info' }
                            : getBadgeProps(documentUpload.status);
                    const progressIndicatorSteps = [
                        {
                            description: translate('type', 'ui.account.kyc'),
                            isCompleted: stepCompletionState[1],
                        },
                        {
                            description: translate('documents', 'ui.account.kyc'),
                            isCompleted: stepCompletionState[2],
                        },
                        {
                            description: translate('review', 'ui.account.kyc'),
                            isCompleted: stepCompletionState[3],
                        },
                        {
                            description: translate('submit', 'ui.account.kyc'),
                            isCompleted: stepCompletionState[4],
                        },
                    ];
                    return (
                        <AccountVerificationMultiStepBox
                            Steps={AccountVerificationProofOfIdSteps}
                            activeStep={activeStep}
                            progressIndicatorSteps={progressIndicatorSteps}
                            ref={formContainerRef}
                            setActiveStep={setActiveStep}
                            title={translate('proof-of-id', 'ui.account')}
                            dataTest="proof-of-id"
                            {...badgeProps}
                            {...props}
                        />
                    );
                }}
            </ProofOfIdVerificationContextConsumer>
        </ProofOfIdVerificationContextProvider>
    );
}
