import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { loadFilteredCasinoGames, mapCasinoGames, setInitialLiveGameMetaData } from './games';
import { mapCasinoCategories } from './categories';
import {
    loadCasinoActiveBonus,
    loadCasinoCategories,
    loadCasinoFeatures,
    loadCasinoGames,
    loadCasinoProviders,
    loadCasinoGameLabels,
    loadCasinoGameOperatingLanguages,
    loadCasinoLobbySettings,
    loadSubproviderPositions,
    loadCasinoStudios,
    loadCasinoGamesThemes,
    loadCasinoProviderLiveLobbyTypes,
} from '../../microservices/casino';
import { setCasinoJackpots } from './jackpots';
import { updateCasinoMaintenance } from './maintenance';
import { initializeCasinoRace } from './casino-race';
import { mapCasinoProviders } from './providers';
import { loadRecentlyPlayedGames } from '../../microservices/casino-statistics';
import { loadPaybackBoosterProgramGames } from './payback-booster';
import { FEATURE, isFeatureAvailable } from '../feature';

let casinoInitializationPromise: Promise<void>;

export function initCasino(): Promise<void> {
    return casinoInitializationPromise || loadCasino();
}

export function loadCasino(): Promise<void> {
    stores.casino.playForReal.set(getStoreValue(stores.isAuthenticated));
    stores.casino.shouldReload.set(false);

    casinoInitializationPromise = Promise.all([
        loadCasinoGames(),
        loadCasinoGamesThemes(),
        loadCasinoCategories(),
        loadCasinoProviders(),
        loadSubproviderPositions(),
        loadCasinoStudios(),
        loadCasinoProviderLiveLobbyTypes(),
    ])
        .then(() => {
            if (isFeatureAvailable(FEATURE.RECENTLY_PLAYED_GAMES)) {
                return loadRecentlyPlayedGames();
            }
        })
        .then(mapCasinoCategories)
        .then(mapCasinoGames)
        .then(mapCasinoProviders)
        .then(loadFilteredCasinoGames)
        .then(() => {
            stores.casino.isInitialized.set(true);
        })
        .then(loadSecondaryData);

    checkCasinoActiveBonus(); // TODO Should be updated with pusher, when user gets bonus or when lock is removed.
    setCasinoJackpots();
    updateCasinoMaintenance(); // TODO Should be implemented with pusher.
    initializeCasinoRace();

    return casinoInitializationPromise;
}

async function loadSecondaryData() {
    return Promise.all([
        loadCasinoFeatures(),
        loadCasinoGameLabels(),
        loadCasinoGameOperatingLanguages(),
        loadCasinoLobbySettings(),
        loadPaybackBoosterProgramGames(),
        setInitialLiveGameMetaData(),
    ])
        .then(mapCasinoCategories)
        .then(mapCasinoGames)
        .then(() => stores.casino.isSecondaryDataInitialized.set(true));
}

export async function checkCasinoActiveBonus() {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);

    if (!isAuthenticated) {
        stores.casino.hasActiveBonus.set(false);
        return;
    }

    await loadCasinoActiveBonus();
}
