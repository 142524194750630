import React, { useEffect, useMemo, useState } from 'react';
import Wrapper from './styles';
import { HotCardType, HotEvent } from '../../../../microservices/cms';
import RetailHotEventsItemMarketOutcomes from './market-outcomes/RetailHotEventsItemMarketOutcomes';
import { findBestLine } from '../../../../services/sports/top-markets';
import { stores, useStoreWithSelector } from '../../../../stores';
import pick from 'lodash/pick';
import { CategoryMatchMarket } from '../../../../services/sports/types';

interface Props {
    event: HotEvent;
}

export default function RetailHotEventsItem({ event }: Props) {
    const [market, setMarket] = useState<CategoryMatchMarket>();

    const outcomeIds = useMemo(
        () =>
            event.matchMarkets?.markets?.map((lineMarket) => lineMarket.outcomes.map((outcome) => outcome.id)).flat() ||
            [],
        [event.matchMarkets],
    );

    const [marketOddsByOutcomeId] = useStoreWithSelector(
        stores.sports.oddsByOutcomeId,
        (state) => pick(state, outcomeIds),
        [outcomeIds],
    );

    useEffect(selectBestMarket, [marketOddsByOutcomeId]);

    function selectBestMarket() {
        const markets = event?.matchMarkets?.markets;
        if (!markets || !markets.length) {
            return;
        }
        const bestMarket = findBestLine(
            markets,
            markets[0].view_type,
            marketOddsByOutcomeId,
            Boolean(event.matchMarkets?.prefer_half_lines),
        );
        setMarket(bestMarket);
    }

    return event.event.hotCardType !== HotCardType.EmptyHotCard ? (
        <Wrapper $background={event.image}>
            <div className="hot-event-description">
                <div
                    className="description-title"
                    dangerouslySetInnerHTML={{
                        __html: event.title,
                    }}
                />
                {market && market.outcomes?.length && (
                    <RetailHotEventsItemMarketOutcomes market={market} event={event} />
                )}
                <div className="description-text">{event.content}</div>
            </div>
        </Wrapper>
    ) : (
        <Wrapper>
            <div className="coming-soon">Coming soon...</div>
        </Wrapper>
    );
}
