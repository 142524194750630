import styled from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

export default styled.div`
    padding: 0.4rem 1.2rem;

    display: flex;
    display: -webkit-box;
    flex-wrap: nowrap;
    cursor: pointer;
    transition: background-color 0.2s;
    position: relative;
    @media ${mediaQuery.isPhone} {
        padding: 0.4rem;
    }
    &:not(.rtp-calculator-game-selection-search-input-selected):hover {
        background-color: var(--surface-level-2-bg);

        .rtp-calculator-game-selection-search-game-title {
            color: var(--font-color-secondary);
        }
    }

    &:first-child {
        padding-top: 7px;
        @media ${mediaQuery.isPhone} {
            padding-top: 0.4rem;
        }
    }

    &:last-child {
        border-radius: 0 0 var(--ui-radius) var(--ui-radius);
        padding-bottom: 10px;
    }

    .rtp-calculator-game-selection-search-game-image-container {
        background-color: var(--surface-level-1-bg);
        border-radius: 10px;
        position: relative;

        .rtp-calculator-game-selection-search-game-image {
            width: 50px;
            height: 50px;
            flex: 0 0 50px;
            background-position: center;
            background-size: cover;
            border-radius: 10px;
            box-shadow: 0 3px 4px rgba(0, 0, 0, 0.7), inset 1px 1px 2px rgba(255, 255, 255, 0.15);

            @media ${mediaQuery.isPhone} {
                width: 25px;
                height: 25px;
                flex: 0 0 25px;
            }
        }

        .rtp-calculator-game-selection-search-game-image-selected {
            width: 25px;
            height: 25px;
            flex: 0 0 40px;
            background-position: center;
            background-size: cover;
            border-radius: 10px;
            box-shadow: 0 3px 4px rgba(0, 0, 0, 0.7), inset 1px 1px 2px rgba(255, 255, 255, 0.15);
        }
    }

    .rtp-calculator-game-selection-search-game-body {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: nowrap;
        padding-left: 0.4rem;
    }

    .rtp-calculator-game-selection-search-game-title {
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 15px;
        padding: 5px;
        color: var(--font-color-subtle);
        transition: color 0.2s;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media ${mediaQuery.isPhone} {
            font-size: 0.8rem;
            width: 140px;
        }
    }
`;
