import React from 'react';
import { FEATURE, isFeatureAvailable } from '../../../../../services/feature';
import { translate } from '../../../../../services/translate';
import UiButton from '../../../../ui/button/UiButton';

interface Props {
    onClick: () => void;
}

export default function AuthLoginFormRegistrationLink({ onClick }: Props) {
    const isRegistrationFeatureAvailable = isFeatureAvailable(FEATURE.REGISTRATION);
    const isRegistrationB2BFeatureAvailable = isFeatureAvailable(FEATURE.REGISTRATION_B2B);

    if (!isRegistrationFeatureAvailable && !isRegistrationB2BFeatureAvailable) {
        return null;
    }

    return (
        <UiButton
            size="small"
            block
            route={isRegistrationB2BFeatureAvailable ? 'registration-btob' : 'registration'}
            onClick={onClick}
        >
            {translate('Register', 'ui.common')}
        </UiButton>
    );
}
