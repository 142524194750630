import React from 'react';
import Wrapper from './styles';

import { translate } from '../../services/translate';
import Seo from '../seo/Seo';
import UiBox from '../ui/box/UiBox';
import UiNavLink from '../ui/nav-link/UiNavLink';
import { getRoute } from '../../services/router';
import Logo from '../logo/Logo';
import UiAnimate from '../ui/animate/UiAnimate';
import { isB2B } from '../../services/environment';

interface Props {
    translatedCustomMessage?: string;
}

export default function FourOFour({ translatedCustomMessage = '' }: Props) {
    const b2bNotFoundMsg = "We couldn't find this page. Just go to our ";
    return (
        <Wrapper>
            <Seo title="Page not found" preRenderDoNotIndex />
            <UiBox>
                <div className="logo-icon">
                    <Logo size={35} />
                </div>
                <div>
                    <UiAnimate animationIn="bounceIn" animationOut="fadeOut" isVisible={true}>
                        <h1 dangerouslySetInnerHTML={{ __html: translate('ui.notfound.title') }} />
                    </UiAnimate>
                    <UiAnimate animationIn="fadeIn" animationInDelay={500} animationOut="fadeOut" isVisible={true}>
                        {isB2B() ? (
                            <p>
                                {b2bNotFoundMsg}
                                <UiNavLink to={getRoute('sport')}>homepage</UiNavLink>
                            </p>
                        ) : translatedCustomMessage ? (
                            <p>{translatedCustomMessage}</p>
                        ) : (
                            <p dangerouslySetInnerHTML={{ __html: translate('ui.notfound.desc') }} />
                        )}
                    </UiAnimate>
                </div>
            </UiBox>
        </Wrapper>
    );
}
