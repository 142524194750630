import React, { useEffect } from 'react';
import SportMatchScoreboard from '../../scoreboard/SportMatchScoreboard';
import SportMatchStream from '../../stream/SportMatchStream';
import { getScoreboardType, useInplayScores } from '../../../../../services/sports/inplay-helpers';
import { isStreamAllowed } from '../../../../../services/stream';
import Wrapper from './styles';
import { MATCH_STATUS, MATCH_TYPE } from '../../../../../services/sports/constants';
import SportMatchGeniusPlayer from '../../genius-player/SportMatchGeniusPlayer';
import { isGeniusStreamAvailable } from '../../../../../services/feature';
import { stores } from '../../../../../stores';
import { useSportsMatchContext } from '../../../../../services/sports/match-helpers';
import { FoCategoryMatch } from '@staycool/sbgate-types';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    match: FoCategoryMatch;
}

export default function SportMatchOpenTopVisualization({ match }: Props) {
    const matchId = match.id;
    const isRegularStream = match?.stream_info && isStreamAllowed(match.sport_category);
    const shouldHaveGeniusStream =
        match.has_genius_stream && match.match_type !== MATCH_TYPE.OUTRIGHT && isGeniusStreamAvailable();
    const [currentStreamMatchId] = useStore(stores.sports.liveNow.currentStreamMatchId);
    const [isAuthenticated] = useStore(stores.isAuthenticated);

    const inplayData = useInplayScores(matchId);
    const scoreboardType = getScoreboardType(match);
    const {
        isOpen,
        currentVisualization,
        isCurrentVisualizationIconClicked,
        setContext: setMatchContext,
    } = useSportsMatchContext();

    const notLive = match.status !== MATCH_STATUS.LIVE;

    const noDataScoreBoard = scoreboardType === 'betgenius' && !inplayData;
    const scoreBoardAllowed = !notLive && scoreboardType && (inplayData || noDataScoreBoard);

    useEffect(() => {
        setMatchContext?.({ isCurrentVisualizationIconClicked: isOpen });
    }, [isOpen]);

    return (
        <Wrapper>
            {(isOpen || currentVisualization === 'scoreboard') &&
                isCurrentVisualizationIconClicked &&
                scoreBoardAllowed && (
                    <div className="scoreboards">
                        <SportMatchScoreboard match={match} />
                    </div>
                )}

            {currentVisualization === 'stream' &&
                currentStreamMatchId === match.id &&
                isAuthenticated &&
                ((isRegularStream && match.stream_info && (
                    <SportMatchStream categoryId={match.category_id} streamInfo={match.stream_info} />
                )) ||
                    (shouldHaveGeniusStream && !isRegularStream && (
                        <SportMatchGeniusPlayer match={match} betgeniusId={match.betgenius_id} />
                    )))}
        </Wrapper>
    );
}
