import React from 'react';
import { translate } from '../../../../../services/translate';
import UiFormGroup from '../../../../ui/form/group/UiFormGroup';
import UiFormInput from '../../../../ui/form/input/UiFormInput';
import classNames from 'classnames';
import { stores } from '../../../../../stores';
import { storageSet } from '../../../../../services/storage';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    key: number;
}

export default function SportSettingsMenuOptionCompactView({ key }: Props) {
    const [isMobileCompactView, setIsMobileCompactView] = useStore(stores.sports.isMobileCompactView);

    function toggleMobileCompactView() {
        storageSet('isCompactView', !isMobileCompactView);
        setIsMobileCompactView(!isMobileCompactView);
    }

    return (
        <div className="setting-item item-row" key={key}>
            <div className="item-label">
                <div className="label-text">{translate('Compact view', 'sport.match')}</div>
            </div>
            <div>
                <UiFormGroup className="toggle">
                    <UiFormInput
                        className={classNames({
                            'toggle-enabled': isMobileCompactView,
                        })}
                        toggle
                        value={isMobileCompactView}
                        name="compact-view"
                        onChange={toggleMobileCompactView}
                        nowrap
                    />
                </UiFormGroup>
            </div>
        </div>
    );
}
