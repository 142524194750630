import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { stores } from '../../../../../stores';
import { checkForPlaceholders, QUIZ_STATUS } from '../../../../../services/quiz';
import Svg from '../../../../svg/Svg';
import moment from 'moment';
import { Question, QuestionAnswer, Quiz } from '../../../../../microservices/quiz';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    answer: QuestionAnswer;
    quiz: Quiz;
    question: Question;
}

export default function CmsQuizCollectionQuizAnswer({ answer, quiz, question }: Props) {
    const [language] = useStore(stores.language);
    const [activeQuestionNumber, setActiveQuestionNumber] = useStore(stores.cms.activeQuestionNumber);
    const [userAnswers, setUserAnswers] = useStore(stores.cms.userAnswers);
    const [isSelectedAnswer, setIsSelectedAnswer] = useState(false);
    const [isSelectedAnswerWrong, setIsSelectedAnswerWrong] = useState(false);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const isUserAnswerCorrect = Boolean(answer.is_correct) && isAuthenticated;
    const isUserAnswerNotCorrect = isSelectedAnswerWrong && isAuthenticated;
    const isQuizResulted = quiz.status === QUIZ_STATUS.RESULTED && isAuthenticated;

    useEffect(() => {
        if (quiz?.id && userAnswers[quiz.id]) {
            const selectedUserAnswer = userAnswers[quiz.id].find((userAnswer) => userAnswer.answer_id === answer.id);
            const isSelectedAnswerInCorrect = selectedUserAnswer && !selectedUserAnswer.was_correct;

            if (quiz.status === QUIZ_STATUS.RESULTED) {
                setIsSelectedAnswer(false);
                setIsSelectedAnswerWrong(Boolean(isSelectedAnswerInCorrect));
                return;
            }
            setIsSelectedAnswer(Boolean(selectedUserAnswer));
        }
    }, [userAnswers]);

    async function handleSelectAnswer() {
        if (moment(quiz.go_live_time) > moment()) {
            return;
        }

        if (quiz?.id && quiz.status === QUIZ_STATUS.ACTIVE) {
            if (userAnswers[quiz.id]) {
                const selectedUserAnswerForQuestionIndex = userAnswers[quiz.id].findIndex(
                    (userAnswer) => userAnswer.question_id === question.id,
                );
                if (selectedUserAnswerForQuestionIndex !== -1) {
                    userAnswers[quiz.id].splice(selectedUserAnswerForQuestionIndex, 1);
                }
            } else {
                userAnswers[quiz.id] = [];
            }
            userAnswers[quiz.id].push({
                question_id: question.id,
                answer_id: answer.id,
            });
            setUserAnswers({ ...userAnswers });
            await new Promise((resolve) => setTimeout(resolve, 300));
            setActiveQuestionNumber(Math.min(quiz.questions.length, activeQuestionNumber + 1));
        }
    }

    return (
        <Wrapper
            selected={isSelectedAnswer}
            isUserAnswerCorrect={isUserAnswerCorrect}
            isUserAnswerNotCorrect={isUserAnswerNotCorrect}
            onClick={handleSelectAnswer}
        >
            <div className="answer-text animated fadeIn">
                {checkForPlaceholders(answer.text[language || quiz.default_language], quiz, language)}
            </div>
            {isQuizResulted && (
                <div className="answer-icon">
                    {answer.is_correct && <Svg icon="checkmark" className="correct" />}
                    {!answer.is_correct && answer.is_selected && <Svg icon="crossmark" className="incorrect" />}
                </div>
            )}
        </Wrapper>
    );
}
