import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    $isCompact?: boolean;
    $isRetailLayout?: boolean;
    $isAmericanCompactLayout?: boolean;
    $isAmericanLayout?: boolean;
};

export default styled.div<StyledProps>`
    display: flex;
    flex-direction: ${({ $isCompact }) => ($isCompact ? 'column' : 'row')};
    justify-content: flex-start;

    width: 100%;
    min-width: 0;

    color: var(--match-primary-color);
    font-size: ${({ $isRetailLayout }) => ($isRetailLayout ? '0.9rem' : 'var(--match-name-size)')};
    font-weight: var(--match-name-weight);
    ${({ theme }) => theme.optimizeFontRendering};
    line-height: 1.8rem;
    cursor: pointer;
    text-align: left;

    @media ${mediaQuery.isLaptop}, (max-width: 1600px) {
        font-size: ${({ $isRetailLayout }) => ($isRetailLayout ? '0.9rem' : 'var(--match-name-size-laptop)')};
    }
    @media ${mediaQuery.isPhone} {
        font-size: ${({ $isCompact }) =>
            $isCompact ? 'var(--match-name-size-mobile-compact)' : 'var(--match-name-size-mobile)'};
    }

    .match-teams {
        position: relative;

        display: ${({ $isCompact }) => ($isCompact ? '' : 'flex')};
        flex-wrap: ${({ $isCompact }) => ($isCompact ? '' : 'wrap')};
        align-items: ${({ $isCompact }) => ($isCompact ? '' : 'center')};

        display: flex;
        flex-direction: column;

        overflow: ${({ $isAmericanCompactLayout }) => ($isAmericanCompactLayout ? '' : 'hidden')};
        white-space: nowrap;
        text-overflow: ellipsis;

        span {
            color: var(--match-secondary-color);
            margin: 0 var(--spacing-4);
            display: inline-block;
            vertical-align: middle;
        }
        .pitcher-name {
            font-size: 80%;
            font-weight: normal;
            @media ${mediaQuery.isLaptop}, ${mediaQuery.isPhone} {
                display: block;
                margin: 0;
                line-height: 1;
            }
        }

        > *:not(:first-child) {
            margin-top: ${({ $isAmericanCompactLayout }) => ($isAmericanCompactLayout ? 'var(--spacing-8)' : '')};
        }

        .team-home,
        .team-away {
            position: relative;
            display: flex;
            align-items: center;
            overflow: ${({ $isAmericanCompactLayout }) => ($isAmericanCompactLayout ? '' : 'hidden')};
            white-space: nowrap;
            text-overflow: ellipsis;
            ${({ $isAmericanCompactLayout }) =>
                $isAmericanCompactLayout &&
                `display: flex;
                height: var(--odds-american-height-mobile);
                padding: 0.4rem 0;
                line-height: 1.2;
                white-space: pre-wrap;`};
            height: ${({ $isRetailLayout, $isAmericanLayout }) =>
                $isRetailLayout && $isAmericanLayout ? 'var(--odds-retail-height)' : ''};
            &.small-size {
                @media ${mediaQuery.isPhone} {
                    font-size: ${({ $isAmericanCompactLayout }) => ($isAmericanCompactLayout ? '90%' : '')};
                }
            }
            .team-name {
                overflow: hidden;
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                @media ${mediaQuery.isPhone} {
                    overflow: auto;
                    white-space: pre-wrap;
                }
            }
            .name {
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        .team-home {
            position: relative;
            align-items: center;
        }
        .team-away {
            align-items: center;
        }
        .american-layout-divider {
            padding: 0 0.6rem;
        }
    }

    .match-outright-name {
        color: var(--match-primary-color);
        font-size: var(--match-name-size);
        line-height: 130%;
        white-space: normal;
        padding: var(--spacing-8) 0;
        @media ${mediaQuery.isPhone} {
            font-size: var(--match-name-size-mobile-compact);
            width: 100%;
        }
    }
`;
