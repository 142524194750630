import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    $isRetailLayout?: boolean;
};

export default styled.div<StyledProps>`
    max-width: ${({ $isRetailLayout }) => ($isRetailLayout ? '' : '340px')};
    margin: 0 auto;
    padding-top: var(--spacing-12);
    @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
        padding-bottom: 80px;
    }
    .selections {
        border: var(--surface-level-2-border);
        border-radius: var(--ui-radius);
        background: var(--surface-level-2-bg);
        box-shadow: var(--ui-shadow);
        padding: 0.8rem;
        margin-bottom: var(--spacing-8);
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            margin-top: var(--spacing-12);
        }
    }
    .ui-alert {
        margin: 0;
    }

    .warning-alert {
        margin: 0.25rem 0;
        word-break: break-word;
    }

    .alert-message {
        margin-top: var(--spacing-8);
    }

    .error-alert {
        margin: var(--spacing-8) 0;
    }

    .alerts-container {
        margin: var(--spacing-8) 0;
    }

    .selection-count {
        display: flex;
        justify-content: center;
        margin: var(--spacing-16) 0;
    }

    .potential-return,
    .total-odds {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 0.4rem;
        margin: 0;

        font-size: 0.55rem;
        color: var(--betslip-label-general-color);
        text-align: left;
        text-transform: uppercase;
        .stake-input-value {
            min-width: 90px;

            font-size: 1.4rem;
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};
            text-align: right;
            @media ${mediaQuery.isPhone} {
                width: 100px;
            }
        }
    }
    .total-odds {
        border-bottom: 1px solid var(--surface-level-1-line-color);
    }

    .stake-input-label {
        letter-spacing: 1px;
        color: var(--font-color-subtle);
    }

    .total-odds .stake-input-value {
        color: var(--betslip-label-odds-color);
    }

    .potential-return .stake-input-value {
        color: var(--betslip-return-label-color);
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering};
        transition: text-shadow 300ms ease-in-out;
        &.animated-value {
            text-shadow: 0 0 20px var(--betslip-return-label-color);
        }
    }

    .pick-bets {
        position: relative;

        font-size: 0.8rem;
        color: var(--font-color-secondary);
        text-align: center;

        margin: 0.8rem 0;
        .pick-bets-ico {
            margin-bottom: -19px;
        }
        .pick-bets-text {
            position: relative;
            padding: 0.8rem;
            background-color: var(--betslip-bg);
            z-index: 2;
        }
    }
    .pick-bets-arrows {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.8rem 0;
        > * {
            animation: fadeInRight 500ms ease-in;
            animation-fill-mode: both;
        }

        > *:not(:last-child) {
            margin-right: 0.8rem;
        }
    }

     {
        ${[1, 2, 3].map(
            (i) => css`
                .pick-bets-arrows > *:nth-child(${i}) {
                    animation-delay: ${i * 200}ms;
                }
            `,
        )}
    }

    .divider {
        height: 1rem;
        overflow: hidden;

        &::after {
            content: '--------------------------------------------------------------';
            color: #757575;
            letter-spacing: 5px;
        }
    }

    .qr-code-nav {
        display: flex;
        gap: 5px;
        color: var(--font-color-primary);
        width: 100%;
        justify-content: space-between;
        .drawer-icon {
            cursor: pointer;
            width: 2em;
        }
    }

    .pagination-counter {
        padding: 1rem 0rem;
        text-align: center;
        font-weight: bold;
    }

    .action-btn {
        margin-top: 1rem;
    }
`;
