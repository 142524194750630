import styled from 'styled-components';

export default styled.div`
    .casino-thumbnail-seats-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding: var(--spacing-8) var(--spacing-4);
        gap: var(--spacing-4);
        position: absolute;
        left: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.45);
        border-radius: 0 0 var(--ui-radius) var(--ui-radius);
        flex: none;
        order: 3;
        flex-grow: 0;
        z-index: 3;
    }
    .casino-thumbnail-seats-seat {
        color: #62b117;
    }
    .casino-thumbnail-seats-seat-taken {
        color: #ff4d4d;
    }
`;
