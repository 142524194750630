import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';
import backgroundImage from './hh-message-background.jpg';

type StyledProps = {
    isPreview: boolean;
};
export default styled.div<StyledProps>`
    .crm-message {
        width: 320px;
        max-width: 320px;
        border-radius: 10px;
        background-color: #3c1245;
        border: 2px solid #b923e3;
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.35), inset 1px 1px 4px -2px rgba(255, 255, 255, 0.22);

        position: fixed;
        left: 50%;
        margin-left: -175px;
        text-align: center;
        z-index: 1000;
        bottom: 1rem;

        visibility: hidden;
        opacity: 0;

        transition: all 500ms cubic-bezier(0.22, 0.61, 0.36, 1);
        transform: translate(0, 100%);

        ${(props) => props.isPreview && `position: relative;`}

        @media ${mediaQuery.isDesktop} {
            bottom: 100px;
        }

        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            margin: 0 0 1rem 0;
            ${(props) =>
                !props.isPreview &&
                `bottom: ${({ theme }) => theme.mobileTabs.height};
                transform: translate(-50%, 100%);`}
        }

        &.visible {
            visibility: visible;
            opacity: 1;
            transform: translate(0, 0);

            @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
                margin: 0 0 1rem 0;
                transform: translate(-50%, 0);
            }
        }

        .close-button {
            position: absolute;
            top: -10px;
            right: -10px;
            width: 40px;
            height: 40px;
            border-radius: 100px;
            z-index: 2;
            @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
                width: 40px;
                height: 40px;
            }
        }
    }

    .crm-message-content {
        color: var(--font-color-primary);
        border-radius: 5px;
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            font-size: 1rem;
        }

        .crm-message-text {
            padding: 1.4rem;
            font-size: 1rem;
            line-height: 1.3rem;
            border-radius: 5px;
            ol,
            ul {
                padding: 0 0 0 1.6rem;
            }
            b {
                color: var(--color-highlight);
            }

            @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
                font-size: 1rem;
                line-height: 1.2rem;
            }
            @media ${mediaQuery.isPhone} {
                max-height: 400px;
                overflow: auto;
            }
            a {
                border-bottom: 1px dotted;
            }
        }

        .crm-message-cta {
            margin: 0 0.8rem 0.85rem 0.8rem;
            display: flex;
            align-items: center;
            > a {
                width: 100%;
            }
            button {
                flex: 1;
                height: 55px;
                background-color: #bc23e4;
                box-shadow: inset 0 0 30px -5px #f664ff;
                color: white;
            }
        }
    }
    .header {
        background: url(${backgroundImage});
        background-size: cover;
        width: 100%;
        min-height: 150px;
        padding: 1rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
    }

    .header-text {
        img {
            height: 65px;
        }
    }
    .winners-label {
        display: flex;
        align-items: center;

        background-color: #5a1474;
        border: 1px solid #ffee34;
        border-radius: 10px;
        padding: 0.4rem 1rem;
        margin: 0.5rem 0;

        font-family: 'Roboto', sans-serif;
        font-weight: 800;
        font-size: 0.75rem;
        text-transform: uppercase;
        text-align: left;
        color: #ffee34;
        svg {
            margin-right: 0.4rem;
        }
    }
    .winners-count {
        font-family: 'Roboto', sans-serif;
        font-size: 2rem;
        font-weight: 800;

        margin-right: 0.8rem;
    }
    .happy-hour-status {
        text-align: center;
        color: #ffee34;
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .opt-out-link {
        text-align: center;
        color: var(--font-color-subtle);
        cursor: pointer;
        font-size: 0.85rem;

        margin: 1rem 0;
        display: block;
    }
    .opt-out-success {
        margin: 1rem;
    }
`;
