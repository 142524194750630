import React, { useState } from 'react';
import Wrapper from './styles';
import UiFormLabel from '../../../ui/form/label/UiFormLabel';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { translate } from '../../../../services/translate';

interface Props extends React.HTMLProps<HTMLInputElement> {
    name: string;
    error?: string;
    label?: string;
    justify?: 'spaced' | 'center';
    dataTest?: string;
}

export default function Ui2FormToggle({
    name,
    error,
    label,
    defaultValue,
    justify,
    dataTest,
    required = false,
    ...args
}: Props) {
    const { register } = useFormContext();
    const [inputValue, setInputValue] = useState(defaultValue);

    const options: RegisterOptions = {
        required: { value: required, message: translate('Oops! You forgot this field', 'ui.registration') },
        onChange: (e) => {
            setInputValue(e.target.checked);
        },
    };

    return (
        <Wrapper justify={justify}>
            {label && <UiFormLabel>{label}</UiFormLabel>}
            <label className="pseudo-toggle-container">
                <input
                    {...register(name, options)}
                    {...args}
                    type="checkbox"
                    checked={!!inputValue}
                    data-test={dataTest}
                />
                <span className="pseudo-toggle" />
            </label>
        </Wrapper>
    );
}
