import styled, { css } from 'styled-components';

type StyledProps = {
    isMobileMenu?: boolean;
    isCompact?: boolean;
};
export default styled.div<StyledProps>`
    .amount {
        font-size: 1.25rem;
        sup {
            top: 0;
        }
        ${({ isMobileMenu }) =>
            isMobileMenu &&
            css`
                color: ${({ theme }) => theme.goldColor};
                font-size: var(--font-10);
                font-weight: var(--font-weight-bold);
                margin-left: 0;
                sup {
                    font-size: 0.625rem;
                }
            `};
    }
    button {
        font-size: var(--font-16);
        border-radius: 100px;
        ${({ isCompact }) =>
            isCompact &&
            css`
                height: 50px;
            `};
        &.can-add-to-balance {
            border: 2px solid rgb(188 89 253);
            background: rgba(190, 88, 255, 0.2);
            box-shadow: 0px 2px 2px rgba(32, 32, 39, 0.3);
            filter: drop-shadow(0px 2px 2px rgba(32, 32, 39, 0.3));
            color: #fff;
        }
    }
    .balance-change {
        background: ${({ theme }) => theme.goldColor};
        border-radius: var(--ui-radius);
        color: #000;
        font-weight: var(--font-weight-bold);
        padding: 1px;
        position: absolute;
        right: 32px;
        top: -5px;
        animation-duration: 2s;
        animation-fill-mode: both;
        &.fadeOutDown {
            animation-name: fadeOutDown;
        }
        &.fadeOutUp {
            animation-name: fadeOutUp;
        }
        sup {
            font-size: 0.3rem;
        }
    }
    .balance-text {
        align-items: center;
        display: flex;
        gap: 0.5rem;
        font-size: var(--font-12);
    }
    .currency {
        font-size: var(--font-10);
        margin-left: 0.25rem;
        ${({ isMobileMenu }) =>
            isMobileMenu &&
            css`
                color: ${({ theme }) => theme.goldColor};
                margin-left: 0;
                color: var(--font-color-subtle);
                font-weight: var(--font-weight-bold);
                margin-left: 0;
            `};
    }
    .icon {
        width: 18px;
        height: 18px;
    }
    @keyframes fadeOutDown {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: translate3d(0, 100%, 0);
        }
    }
    @keyframes fadeOutUp {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
        }
    }
`;
