/* eslint-disable react/no-children-prop */
import { createElement, ReactNode, useMemo, useState } from 'react';
import AccountVerificationDeclineReason from '../components/account/verification/decline-reason/AccountVerificationDeclineReason';
import UiAlert from '../components/ui/alert/UiAlert';
import { loadKycUserSettings, uploadFiles } from '../microservices/kyc';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { storageSet } from './storage';
import { translate } from './translate';
import { loadProfile } from './user';
import { useStore } from '../hooks/useStore';

export enum KycDocumentType {
    ADDRESS = 'address',
    ID = 'id',
    PAYMENT = 'payment',
    INCOME = 'income',
}

export enum EVerifiedStatus {
    NOT_CHECKED = 'not_checked',
    REQUESTED = 'requested',
    FAILED = 'failed',
    VERIFIED = 'verified',
    PENDING = 'pending',
}

enum KycStatus {
    WAITING = 'waiting',
    APPROVED = 'approved',
    NONE = 'none',
    SECURITY = 'security',
}

export enum SourceOfIncomeStatus {
    NOT_CHECKED = 'not_checked',
    DECLINED = 'declined',
    APPROVED = 'approved',
    REQUESTED = 'requested',
    PENDING = 'pending',
}

export enum ProofOfPaymentStatus {
    NOT_CHECKED = 'not_checked',
    DECLINED = 'declined',
    APPROVED = 'approved',
    PENDING = 'pending',
    REQUESTED = 'requested',
}

export enum ProofOfIdStatus {
    NOT_CHECKED = 'not_checked',
    APPROVED = 'approved',
    DECLINED = 'declined',
    PENDING = 'pending',
    REQUESTED = 'requested',
}

export enum ProofOfAddressStatus {
    NOT_CHECKED = 'not_checked',
    DECLINED = 'declined',
    APPROVED = 'approved',
    PENDING = 'pending',
    FAILED = 'failed',
    REQUESTED = 'requested',
}

export function getBadgeProps(documentStatus?: string) {
    const badgeColorByDocumentStatus = {
        approved: 'success',
        declined: 'error',
        failed: 'error',
        pending: 'warning',
        recommended: 'success',
        requested: 'warning',
        verified: 'success',
    };
    if (!documentStatus || !badgeColorByDocumentStatus[documentStatus]) {
        return;
    }
    return {
        badgeText: translate(documentStatus, 'ui.account.kyc'),
        badgeColor: badgeColorByDocumentStatus[documentStatus],
    };
}

export function isUserVerified() {
    const kycSettings = getStoreValue(stores.kyc.settings);
    const user = getStoreValue(stores.user);
    return (
        kycSettings.kycStatus === KycStatus.APPROVED ||
        kycSettings.proofOfId === ProofOfIdStatus.APPROVED ||
        user?.eVerified === EVerifiedStatus.VERIFIED
    );
}

export function useDocumentUpload({
    documentType,
    uploadSuccessMessage = translate(
        "We have received your document copies. The average processing time is 12 hours. We'll email you once they have been processed.",
        'ui.account',
    ),
    documentLimit: documentLimitOverride,
    status,
    declineReason,
    approvedMessage,
    pendingMessage,
}: {
    declineReason?: string;
    documentLimit?: number;
    documentType: KycDocumentType;
    status?: ProofOfAddressStatus | ProofOfIdStatus | ProofOfPaymentStatus | SourceOfIncomeStatus;
    uploadSuccessMessage?: string;
    approvedMessage?: string;
    pendingMessage?: string;
}) {
    const [documents, setDocuments] = useState<KycFile[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadResultAlert, setUploadResultAlert] = useState<ReactNode>();
    const [kycSettings] = useStore(stores.kyc.settings);
    const documentLimit = documentLimitOverride ?? kycSettings.limits[documentType];
    const isLimitReached = kycSettings.hasReachedLimits[documentType];

    const statusAlert = useMemo(() => {
        if (uploadResultAlert) {
            return null;
        }
        if (status === 'approved') {
            return createElement(UiAlert, {
                center: true,
                children: approvedMessage || translate('This document type is already verified.', 'ui.account'),
            });
        }
        if (status === 'pending' && pendingMessage) {
            return createElement(UiAlert, {
                center: true,
                children: pendingMessage,
            });
        }
        if (status === 'declined') {
            return createElement(UiAlert, {
                center: true,
                failure: true,
                children: createElement(AccountVerificationDeclineReason, { declineReason }),
            });
        }
        if (status === 'not_checked') {
            return createElement(UiAlert, {
                success: true,
                children: translate('This document type does not need to be verified at the moment.', 'ui.account'),
            });
        }
        if (isLimitReached) {
            return createElement(UiAlert, {
                center: true,
                children: translate(
                    'Your documents are being reviewed. We will let you know if we need anything more from you.',
                    'ui.account',
                ),
            });
        }
        return null;
    }, [status, declineReason, isLimitReached, uploadResultAlert]);

    function addDocuments(documentsToAdd: KycFile[]) {
        setDocuments([...documents, ...documentsToAdd.slice(0, documentLimit - documents.length)]);
    }

    function removeDocument(documentToRemove: KycFile) {
        setDocuments(documents.filter((document) => document !== documentToRemove));
    }

    async function uploadDocuments(extraData: Record<string, string | Blob> = {}) {
        setIsUploading(true);
        try {
            await uploadFiles(documents, documentType, extraData);
            await Promise.all([getStoreValue(stores.user) && loadProfile(), loadKycUserSettings()]);
            setUploadResultAlert(
                createElement(UiAlert, {
                    success: true,
                    children: uploadSuccessMessage,
                }),
            );
        } catch (error: any) {
            const errorMessage = error.error || 'Upload failed';
            setUploadResultAlert(
                createElement(UiAlert, { failure: true, children: translate(errorMessage, 'ui.account.kyc') }),
            );
            throw error;
        } finally {
            setIsUploading(false);
        }
    }

    function reset() {
        setDocuments([]);
        setIsUploading(false);
        setUploadResultAlert(undefined);
    }

    return {
        addDocuments,
        documentLimit,
        documents,
        isDocumentSelected: !!documents.length,
        isEnabled: status && !['approved', 'not_checked'].includes(status) && !isLimitReached,
        isSelectingDocumentsDisabled: documents.length >= documentLimit,
        isUploading,
        uploadResultAlert,
        removeDocument,
        reset,
        selectButtonText: translate(`select-up-to-amount-files`, `ui.account`, { amount: documentLimit }),
        setDocuments,
        setUploadResultAlert,
        status,
        statusAlert,
        uploadDocuments,
    };
}

export function openKycModal(kycToken: string, kycTokenExpiry?: string) {
    storageSet('kycToken', kycToken);
    stores.kyc.kycToken.set(kycToken);
    storageSet('kycTokenExpiry', kycTokenExpiry);
    stores.kyc.kycTokenExpiry.set(kycTokenExpiry);
}

interface KycFile extends File {
    side?: 'front' | 'back';
}
