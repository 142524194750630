import React from 'react';
import { getClient, isProd } from '../../../../../services/environment';
import Wrapper from './styles';
import { ClientName } from '../../../../../services/utils/types';
import { FoCategoryMatch } from '@staycool/sbgate-types';

interface Props {
    match: FoCategoryMatch;
}

export default function SportMatchScoreboardBetgenius({ match }: Props) {
    const sportName = match.sport_name.toLowerCase();
    const scorecenterVersion = sportName === 'tennis' ? '2' : '';

    const coolbetConfig = {
        stream: !isProd() ? 'coolbet-uat' : 'coolbet',
        page: '',
        scoreboardPath: '/multisportscoreboard/coolbetmultisportscoreboard/html',
        scorecenterPath: `/${sportName}scorecentre${scorecenterVersion}/coolbet${sportName}scorecentre/html`,
    };

    const config = {
        [ClientName.COOLBET]: coolbetConfig,
        [ClientName.STATION]: coolbetConfig,
        [ClientName.IVC]: {
            stream: 'ivcgan',
            page: '/page/ivcgan',
            scoreboardPath: '/multisportscoreboard',
            scorecenterPath: `/${sportName}scorecentre`,
        },
        [ClientName.WYNNBET]: {
            stream: 'wynnbet',
            page: '/page/wynnbet',
            scoreboardPath: '/multisportscoreboard',
            scorecenterPath: `/${sportName}scorecentre`,
        },
    };
    const client = getClient();
    if (!config[client]) {
        return null;
    }
    const { stream, page, scorecenterPath, scoreboardPath } = config[client];

    let scoreBoardUrl = '';
    if (match.scoreboard_url) {
        const valuesByPlaceholders = {
            '{fixtureId}': match.betgenius_id,
        };
        let url = match.scoreboard_url;
        Object.entries(valuesByPlaceholders).forEach(([placeholder, value]) => {
            url = url.replace(placeholder, String(value));
        });
        scoreBoardUrl = url;
    } else if (match.scorecenter_available && match.betgenius_id) {
        scoreBoardUrl = `https://${stream}.betstream.betgenius.com/betstream-view${page}${scorecenterPath}?eventId=${match.betgenius_id}`;
    } else if (match.scoreboard_available && match.betgenius_id) {
        scoreBoardUrl = `https://${stream}.betstream.betgenius.com/betstream-view${page}${scoreboardPath}?eventId=${match.betgenius_id}`;
    }

    return (
        <Wrapper small={match.scoreboard_available && !match.scorecenter_available && !match.scoreboard_url}>
            {!!scoreBoardUrl && (
                <div className="animated fadeIn">
                    <iframe title="bg-scoreboard" src={scoreBoardUrl} />
                </div>
            )}
        </Wrapper>
    );
}
