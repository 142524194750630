import styled from 'styled-components';

export default styled.div`
    .leaderboard {
        text-align: center;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 70vh;
    }
    .leaderboard-results {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        color: var(--font-color-secondary);
    }
    .leaderboard-header {
        text-align: center;
        display: flex;
        margin: 0.6rem 0;
        color: var(--font-color-primary);
    }
    .leaderboard-cell {
        width: 8rem;
        margin: 0.3rem 0;
    }
    .user-alias {
        font-weight: bold;
        text-align: left;
    }
    .date {
        font-weight: 300;
        font-size: small;
        text-align: left;
    }
    .winning-bet-amount,
    .start-bet-amount {
        text-align: right;
        margin: auto;
    }
`;
