import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../../../../styles/utils';

type StyledProps = {
    isExpired?: boolean;
    isCompact?: boolean;
};
export default styled.div<StyledProps>`
    align-items: center;
    border-radius: var(--ui-radius);
    display: flex;
    padding: 1rem 0;
    min-width: 400px;
    justify-content: center;

    @media ${mediaQuery.isTablet}, ${mediaQuery.isPhone} {
        ${({ isCompact }) =>
            !isCompact &&
            css`
                padding: 1rem 0 1rem 0;
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            `};

        min-width: 100%;
    }

    .footer-container {
        display: flex;
        flex: 1;
    }

    .expired-content {
        font-size: var(--font-12);
        align-self: center;
        margin: 0 10px;
        flex: 1;
        text-align: center;
    }

    @media (max-width: 350px) {
        flex-direction: column-reverse;
    }

    .gold-text {
        color: ${({ theme }) => theme.paybackBooster.primaryColor};
    }

    .countdown {
        margin-right: ${({ theme }) => theme.layout.gutterHalf};
        min-width: 193px;
        max-width: 193px;
        flex: 1;
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            margin-right: 0;
            min-width: 145px;
            max-width: unset;
            text-align: right;
        }
    }

    .balance {
        flex: 1;
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            padding-right: var(--spacing-12);
        }
    }

    @media (max-width: 450px) {
        ${({ isCompact }) =>
            isCompact &&
            css`
                .countdown {
                    & > * {
                        font-size: 20px;
                    }
                }

                .balance {
                    text-align: center;
                    text-align: -webkit-center;
                    .amount {
                        font-size: var(--font-12);
                        margin: unset;
                    }
                    .balance-text-title {
                        font-size: var(--font-10);
                    }
                }
            `};
    }
`;
