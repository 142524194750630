import React, { useState } from 'react';
import Wrapper from './styles';
import UiButton from '../../../../ui/button/UiButton';
import { logger } from '../../../../../services/logger';
import { collectPaybackBoosterBalance } from '../../../../../microservices/casino-payback-booster';
import { translate } from '../../../../../services/translate';
import Svg from '../../../../svg/Svg';

interface Props {
    onConfirmed: (number?) => void;
}

export default function CasinoPaybackBoosterBalanceTransfer({ onConfirmed }: Props) {
    const [isLoading, setIsLoading] = useState(false);

    async function transfer() {
        setIsLoading(true);
        try {
            const response = await collectPaybackBoosterBalance();
            onConfirmed(response.balance);
        } catch (error) {
            logger.error('CasinoPaybackBoosterBalanceTransfer', 'transfer', error);
        }
        setIsLoading(false);
    }

    return (
        <Wrapper>
            <Svg icon="payback-booster" size={1.75} />
            <span
                className="transfer-message"
                dangerouslySetInnerHTML={{
                    __html: translate('Transfer to<br>main balance?', 'casino.payback-booster'),
                }}
            />
            <UiButton className="option-button" color="primary" disabled={isLoading} onClick={transfer}>
                {translate('Yes', 'ui.common')}
            </UiButton>
            <UiButton className="option-button" disabled={isLoading} onClick={onConfirmed}>
                {translate('No', 'ui.common')}
            </UiButton>
        </Wrapper>
    );
}
