import React from 'react';
import Wrapper from './styles';
import { stores } from '../../../../stores';
import { getMarketInfoByOutcomeId } from '../../../../microservices/sbgate';
import { BET_TYPE, initialBetSlipPlacingState } from '../../../../services/sports/constants';
import UiButton from '../../../ui/button/UiButton';
import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';
import { convertOdds } from '../../../../services/odds-format';
import { BetslipMode, clearBetslipSelection } from '../../../../services/sports/betslip';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    card: FoComboCardWithOdds;
}

export default function ComboCardsItemFooter({ card }: Props) {
    const [cardsInBetslip, setCardsInBetslip] = useStore(stores.sports.comboCard.cardsInBetslip);
    const [, setBetSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [, setBetSlipUserState] = useStore(stores.sports.betSlipUserState);
    const [, setBetSlipPlacingState] = useStore(stores.sports.betSlipPlacingState);
    const cardAlreadyInBetslip = Boolean(cardsInBetslip.find(({ id }) => id === card.id));

    async function handleComboCardSelection() {
        if (!cardsInBetslip.length) {
            clearBetslipSelection(BetslipMode.Betslip, false, true);
        }
        setBetSlipPlacingState(initialBetSlipPlacingState);
        if (!cardAlreadyInBetslip) {
            setCardsInBetslip((cards) => [...cards, card]);

            const markets = card.matches.flatMap(({ markets }) =>
                markets.map(({ id, outcome_id }) => ({ [id]: outcome_id })),
            );
            const marketIdToOutcomeId = Object.assign({}, ...markets);
            const outcomeIds = Object.values(marketIdToOutcomeId);
            await Promise.all(outcomeIds.map(getMarketInfoByOutcomeId));

            setBetSlipMarketIdToOutcomeId((prev) => ({ ...prev, ...marketIdToOutcomeId }));
            setBetSlipUserState((prev) => ({
                ...prev,
                userBetTypeSelection: BET_TYPE.COMBO_CARD,
                betType: BET_TYPE.COMBO_CARD,
            }));
        }
    }

    return (
        <Wrapper>
            <UiButton
                className={cardAlreadyInBetslip ? 'selection-btn-active' : 'selection-btn'}
                color="primary"
                block
                onClick={handleComboCardSelection}
            >
                <div className="btn-text">
                    {card.odds_boost === 1 ? (
                        <span className="boosted-odds">{convertOdds(card.boosted_odds)}</span>
                    ) : (
                        <>
                            <span className="original-odds">{convertOdds(card.original_odds)}</span>
                            <span className="arrow">{' » '}</span>
                            <span className="boosted-odds">{convertOdds(card.boosted_odds)}</span>
                        </>
                    )}
                </div>
            </UiButton>
            <span className="cards-left-text">{card.bets_left} cards left</span>
        </Wrapper>
    );
}
