import React, { useRef, useState } from 'react';
import {
    GeoComplyMobileAppNotOpenedHint,
    GeoComplyMobileClient,
    GeoComplyMobileLibrary,
} from '../../../../../../services/geocomply/mobile';
import { translate } from '../../../../../../services/translate';
import UiButton from '../../../../../ui/button/UiButton';
import UiGroup from '../../../../../ui/group/UiGroup';
import GeocomplyRetryGeolocationButton from '../../../../retry-geolocation-button/GeocomplyRetryGeolocationButton';
import GeocomplyMobileAppPinger from '../../../app-pinger/GeocomplyMobileAppPinger';

interface Props {
    hint: GeoComplyMobileAppNotOpenedHint;
}

export default function GeocomplyMobileHintHandlerAppNotOpened({ hint }: Props) {
    const appPingerRef = useRef(null);
    const [isAppOpened, setIsAppOpened] = useState(false);
    const [isAppPingerRunning, setIsAppPingerRunning] = useState(false);
    const [hasAttemptedToOpenApp, setHasAttemptedToOpenApp] = useState(false);

    function openApp() {
        const GeoComplyMobileLibrary = (window as any).CoolbetGeoComplyMobileLibrary as GeoComplyMobileLibrary;
        const GeoComplyMobileClient = (window as any).CoolbetGeoComplyMobileClient as GeoComplyMobileClient;

        const url = GeoComplyMobileLibrary.utils.browser.is.android
            ? GeoComplyMobileClient.enablePlayStorePopUp
                ? hint.androidWakeupIntent
                : hint.androidWakeupUrl
            : hint.iosWakeupUrl;

        window.open(url, '_blank');

        const isAppPingerRequired = hint.message === 'Please open the GeoGuard Location Validator to geolocate';

        if (isAppPingerRequired) {
            setIsAppPingerRunning(true);
            setHasAttemptedToOpenApp(true);
            (appPingerRef.current as any)?.startPinger();
        } else {
            setIsAppOpened(true);
        }
    }

    return (
        <UiGroup vertical>
            <GeocomplyMobileAppPinger
                ref={appPingerRef}
                onAppOpenSuccess={() => {
                    setIsAppOpened(true);
                    setIsAppPingerRunning(false);
                }}
                onAppOpenFailure={() => {
                    setIsAppOpened(false);
                    setIsAppPingerRunning(false);
                }}
            />

            {!isAppOpened && !isAppPingerRunning && (
                <UiButton onClick={openApp}>
                    {hasAttemptedToOpenApp
                        ? translate('Retry opening the App', 'ui.geocomply')
                        : translate('Open the App', 'ui.geocomply')}
                </UiButton>
            )}

            {isAppOpened && <GeocomplyRetryGeolocationButton />}
        </UiGroup>
    );
}
