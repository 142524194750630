import React, { useContext, useEffect, useRef, useState } from 'react';
import { DocumentCaptureContext } from '../../../../../contexts/document-capture/DocumentCaptureContext';
import { GbgParams, loadGbgParams } from '../../../../../microservices/kyc';
import { defaultSdkParams } from '../../../../../services/gbg-wsdk/defaultSdkParams';
import { getTranslationsDictionary } from '../../../../../services/gbg-wsdk/translationsDictionary';
import { translate } from '../../../../../services/translate';
import UiAlert from '../../../../ui/alert/UiAlert';

export default function AccountVerificationDocumentCaptureSdk() {
    const { setIsLoading, isScriptLoaded, onClose } = useContext(DocumentCaptureContext);
    const [selectedFilenameBySide, setSelectedFilenameBySide] = useState<Partial<Record<'front' | 'back', 'string'>>>(
        {},
    );
    const [gbgParams, setGbgParams] = useState<GbgParams>();
    const [currentEvent, setCurrentEvent] = useState<string>();
    const { front, back } = selectedFilenameBySide;
    const isSameImageSelected = !!(front && back) && front === back;
    const containerRef = useRef<HTMLDivElement>(null);
    const journeyContainer = useRef<any>();
    const textBySide = {
        front: 'Select an image of the <strong>photo</strong> side',
        back: 'Select an image of the <strong>back</strong> side',
    };

    useEffect(() => () => journeyContainer.current?.terminate(), []);

    useEffect(() => {
        loadGbgParams().then(setGbgParams);
    }, []);

    useEffect(() => {
        if (currentEvent === 'DISPLAY:PROVIDER') {
            setContinueButtonDisabled(isSameImageSelected);
        }
    }, [isSameImageSelected, currentEvent]);

    useEffect(() => {
        if (!isScriptLoaded || !gbgParams) {
            return;
        }
        journeyContainer.current = new (window as any).GBG.Idscan.JourneyContainer({
            ...defaultSdkParams,
            ...gbgParams,
            container: containerRef.current,
            onJourneyEvent,
            dictionary: getTranslationsDictionary(),
        });
        journeyContainer.current.initialize().then(() => setIsLoading(false));
    }, [gbgParams, isScriptLoaded]);

    function onJourneyEvent(event, meta, state) {
        setCurrentEvent(event);
        if (meta.step === 'result') {
            return onClose();
        }
        switch (event) {
            case 'DISPLAY:INFO': {
                const side = getSide(state.action);
                setSelectedFilenameBySide((selectedFileBySide) => ({
                    ...selectedFileBySide,
                    [side]: meta.fileName,
                }));
                break;
            }
            case 'DISPLAY:STEP': {
                if (meta.step === 'gateway' && state.action.startsWith('IDENTITY')) {
                    const side = getSide(state.action);
                    setSelectedFilenameBySide((selectedFileBySide) => ({
                        ...selectedFileBySide,
                        [side]: undefined,
                    }));
                }
                break;
            }
            case 'DISPLAY:PROVIDER': {
                if (meta.provider === 'filesystem' && state.action.startsWith('IDENTITY')) {
                    const side = getSide(state.action);
                    setSelectFileButtonText(translate(textBySide[side], 'ui.account.kyc'));
                }
                break;
            }
        }
    }

    function getSide(action: string) {
        return action.split(':')[1].toLowerCase();
    }

    function setSelectFileButtonText(text: string) {
        const selectFileButton = containerRef.current?.querySelector('#select-file-button') as HTMLButtonElement;
        if (!selectFileButton) {
            return;
        }
        selectFileButton.innerHTML = `<span style="margin: 0;">${text}</span>`;
    }

    function setContinueButtonDisabled(isDisabled: boolean) {
        const continueButton = containerRef.current?.querySelector('#continue-button') as HTMLButtonElement;
        if (!continueButton) {
            return;
        }
        continueButton.disabled = isDisabled;
    }

    return (
        <>
            {isSameImageSelected && (
                <UiAlert warning>
                    {translate('Please make sure you have selected the correct image', 'ui.account')}
                </UiAlert>
            )}
            <div ref={containerRef} />
        </>
    );
}
