import React, { useEffect, useState } from 'react';
import { translate } from '../../../../services/translate';
import UiFormGroup from '../../../ui/form/group/UiFormGroup';
import UiFormInput from '../../../ui/form/input/UiFormInput';
import { validators } from '../../../../services/validators';
import UiGroup from '../../../ui/group/UiGroup';

import UiAlert from '../../../ui/alert/UiAlert';
import { useForm } from '../../../../services/form';
import { stores } from '../../../../stores';
import Wrapper from './styles';
import UiAnimate from '../../../ui/animate/UiAnimate';

export default function RegistrationFieldsSubscriptions() {
    const [subscriptionRadio, setSubscriptionRadio] = useState('');

    const form = useForm(stores.registration.form);
    const { subscribeSms, subscribeDirectMail, subscribeMailList, subscribePhoneCalls } = form.getValues();
    const isOptInButtonActive = subscribeSms || subscribeDirectMail || subscribeMailList || subscribePhoneCalls;
    const isOptInSettingsChanged = isOptInButtonActive !== undefined;
    const isOptOutButtonActive = isOptInSettingsChanged && isOptInButtonActive === false;

    useEffect(() => {
        if (subscriptionRadio === 'yes') {
            optIn();
        } else if (subscriptionRadio === 'no') {
            optOut();
        }
    }, [subscriptionRadio]);

    function optIn() {
        form.setInputValue({
            subscribeSms: true,
            subscribeMailList: true,
            subscribeDirectMail: true,
            subscribePhoneCalls: true,
        });
    }

    function optOut() {
        form.setInputValue({
            subscribeSms: false,
            subscribeMailList: false,
            subscribeDirectMail: false,
            subscribePhoneCalls: false,
        });
    }

    return (
        <Wrapper>
            <div className="bonus-offers">
                <p>{translate('signup.gdpr.consent')}</p>

                <UiFormGroup expand>
                    <UiFormInput
                        nowrap
                        radio
                        name="subscriptionStatus"
                        validator={validators.required}
                        radioOptions={[
                            { label: translate('Yes', 'ui.common'), value: 'yes' },
                            { label: translate('No', 'ui.common'), value: 'no' },
                        ]}
                        value={subscriptionRadio}
                        onValueChange={setSubscriptionRadio}
                        data-test="subscriptionStatus"
                    />
                </UiFormGroup>
            </div>
            {(isOptInButtonActive || isOptOutButtonActive) && (
                <UiGroup expand className="subscription-options">
                    <UiFormInput {...form.subscribeSms} toggle label={translate('signup.gdpr.field.sms')} exclude />

                    <UiFormInput
                        {...form.subscribeDirectMail}
                        toggle
                        label={translate('signup.gdpr.field.email')}
                        exclude
                    />

                    <UiFormInput
                        {...form.subscribeMailList}
                        toggle
                        label={translate('signup.gdpr.field.mail')}
                        exclude
                    />

                    <UiFormInput
                        {...form.subscribePhoneCalls}
                        toggle
                        label={translate('signup.gdpr.field.phone')}
                        exclude
                    />
                </UiGroup>
            )}
            {isOptOutButtonActive && (
                <UiAnimate animationIn="flipInX" animationOut="fadeOut">
                    <UiAlert success>{translate('signup.gdpr.advice')}</UiAlert>
                </UiAnimate>
            )}
        </Wrapper>
    );
}
