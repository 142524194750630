import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { Transaction } from '@staycool/retail-types/transaction';
import { BetSlipUserState } from '../services/sports/types';
import { ParlayCardBetslipMarketIdToOutcomeIds, ParlayUserState } from '../services/parlay-card/parlay-card';
import { BetslipMode } from '../services/sports/betslip';

const getUrl = (url) => getServiceUrl('retail-proxy', url);

export interface RecentTransactions extends Transaction {
    voucher_id?: string;
    ticket_id?: string;
    loyalty_id?: string;
}

export function getBringYourDeviceByCode(code: string) {
    const url = getUrl(`bring-your-device/${code}`);
    return httpGet(url);
}

export function saveBringYourDevice({ code, device }: BringYourOwnDeviceData) {
    const url = getUrl(`bring-your-device`);
    return httpPost(url, { code, device });
}

export interface BringYourOwnDeviceData {
    code: string;
    device: {
        betslip?: Record<number, number> | ParlayCardBetslipMarketIdToOutcomeIds;
        userState?: ParlayUserState | BetSlipUserState;
        betslipMode?: BetslipMode;
    };
}
