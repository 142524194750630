import moment from 'moment';
import {
    getRound,
    getRoundPrediction,
    getTournament,
    getTournamentList,
    postPrediction,
} from '../microservices/tournaments';
import sortBy from 'lodash/sortBy';

export const SHOW_SECTION = {
    ROUND_PREDICTIONS: 'ROUND_PREDICTIONS',
    LEADERBOARDS: 'LEADERBOARDS',
};

export const SHOW_ROUND_INFO = {
    ROUND: 'ROUND',
    TOURNAMENT: 'TOURNAMENT',
};

export const ROUND_STATUS = {
    ACTIVE: 'ACTIVE',
    FINISHED: 'FINISHED',
};

export async function getActiveTournaments() {
    const tournaments = await getTournamentList();
    return tournaments.filter((tournament) => tournament.status === ROUND_STATUS.ACTIVE);
}

export async function getTournamentById(tournamentId) {
    const tournament = await getTournament(tournamentId);
    if (!tournament) {
        return null;
    }
    tournament.rounds = sortBy(tournament.rounds, 'endBet');
    return tournament;
}

export async function getRoundById(roundId) {
    const round = await getRound(roundId);
    if (!round) {
        return null;
    }
    round.matches = sortBy(round.matches, 'matchStart');
    round.submissionHasEnded = hasTimePassed(round.endBet);
    return round;
}

export async function getPrediction(roundId: number, userAlias?: string) {
    const roundPrediction = await getRoundPrediction(roundId, userAlias);
    return formatRoundPredictionByMatchId(roundPrediction);
}

export function submitRoundPrediction(roundId, predictionsByMatchId) {
    return postPrediction(roundId, predictionsByMatchId);
}

function hasTimePassed(timestamp) {
    const currentTime = moment().unix();
    const submissionDeadline = moment(timestamp).unix();
    return currentTime > submissionDeadline;
}

function formatRoundPredictionByMatchId(roundPrediction): Record<string, any> {
    const predictionByMatchId = {};
    roundPrediction?.matchPredictions?.forEach((prediction) => {
        predictionByMatchId[prediction.matchId] = prediction;
    });
    return predictionByMatchId;
}
