import moment from 'moment';
import React, { useMemo } from 'react';
import { getPromotionAnalyticsInfo } from '../../../microservices/cms';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../services/analytics';
import { convertOdds } from '../../../services/odds-format';
import { useSportBettingContext } from '../../../services/sports/bet-context';
import { removeAllNonFrontendErrors } from '../../../services/sports/betslip-errors';
import { ODDS_STATUS } from '../../../services/sports/constants';
import { useBetslipOddsByOutcomeId } from '../../../services/sports/hooks';
import { SportOddsProps as Props } from '../../../services/sports/odds';
import { useOddsFormat } from '../../../services/sports/user-settings';
import { stores, useStoreWithSelector } from '../../../stores';
import Svg from '../../svg/Svg';
import UiPlaceholder from '../../ui/placeholder/UiPlaceholder';
import Wrapper from './styles';
import { getLine } from '../../../services/sports/market';
import { removeSelections } from '../../../services/sports/betslip';
import { isRetail } from '../../../services/environment';
import { MarketInfo } from '../../../services/sports/types';
import { BetSlipMinimalMarket } from '@staycool/sbgate-types';
import { resetComboCardBetSlip } from '../../../services/sports/combo-cards';
import { getStoreValue } from '../../../stores/store/utils';
import { filterStyleProps } from '../../../styles/utils';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

function SportOdds({
    children,
    isAmericanLayout,
    isAmericanLayoutTopMarkets,
    isBetslip,
    isDisabled,
    isOutcomeNameVisible,
    light,
    market,
    outcomeId,
    small,
    promotion,
}: Props) {
    const [cardsInBetslip] = useStore(stores.sports.comboCard.cardsInBetslip);
    const [isMobileCompactView] = useStore(stores.sports.isMobileCompactView);
    const [{ isPhone, isTablet }] = useStore(media);
    const bettingContext = useSportBettingContext();
    const isRetailLayout = isRetail();

    const isLineMarket = market.view_type === 'line';
    useOddsFormat();

    const [betslipMarketOutcomeId] = useStoreWithSelector(
        stores.sports.betSlipMarketIdToOutcomeId,
        (state) => state[market.id],
        [market.id],
    );

    const { currentOdds, isOddsValueIncreased, isOddsAvailable, isOddsValueDecreased, currentOddsValue } =
        useBetslipOddsByOutcomeId(outcomeId, (market as MarketInfo).in_play, market.provider, !!isBetslip);

    const outcome = useMemo(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        () => market.outcomes.find((outcome) => outcome.id === Number(outcomeId)),
        [market, outcomeId],
    );

    const outcomeDisabled = outcome && outcome.status && outcome.status !== ODDS_STATUS.OPEN;
    const marketDisabled =
        market?.status !== ODDS_STATUS.OPEN ||
        ((market as MarketInfo)?.betting_end &&
            !(market as MarketInfo)?.in_play &&
            moment((market as MarketInfo).betting_end).isBefore());
    const isOddsDisabled = isDisabled || !isOddsAvailable || marketDisabled || outcomeDisabled;
    const isOddsTooBig = currentOddsValue
        ? parseFloat(currentOddsValue as unknown as string).toFixed(2).length > 4
        : false;

    if (!currentOdds || !outcome) {
        return (
            <Wrapper $isCompactViewEnabled={isPhone}>
                {isOutcomeNameVisible && (
                    <div className="odds-outcome">
                        <span className="outcome-name">
                            <UiPlaceholder />
                        </span>
                    </div>
                )}
                <button className="odds-button">
                    <span className="value" />
                </button>
            </Wrapper>
        );
    }

    function addRemoveBetSlip() {
        const { isLoading, needsConfirm } = getStoreValue(stores.sports.betSlipPlacingState);

        if (isLoading || needsConfirm) {
            return;
        }

        if (betslipMarketOutcomeId === outcomeId && !isBetslip) {
            stores.sports.betSlipMarketIdToOutcomeId.set((state) => {
                delete state[market.id];
            });
            return;
        }

        removeSelections(true);
        resetComboCardBetSlip(Boolean(cardsInBetslip.length));
        stores.sports.betSlipMarketIdToOutcomeId.set((state) => {
            state[market.id] = outcomeId;
        });
        stores.sports.bettingContextByOutcomeId.set((state) => {
            if (bettingContext) {
                state[outcomeId] = bettingContext;
            } else {
                delete state[outcomeId];
            }
        });
        removeAllNonFrontendErrors();
    }

    function toggleOddsSelection() {
        if (isOddsDisabled) {
            return;
        }
        if (promotion) {
            trackGoogleTagManagerEvent(
                TAG_MANAGER_EVENT.MARKETING_PROMOTION_CLICK,
                getPromotionAnalyticsInfo(promotion),
            );
        }
        addRemoveBetSlip();
    }

    const styleProps = {
        $isActive: betslipMarketOutcomeId === outcomeId,
        $isDisabled: isOddsDisabled,
        $oddsDecreased: isOddsValueDecreased,
        $oddsIncreased: isOddsValueIncreased,
        $oddsTooBig: isOddsTooBig,
        $lineTooBig: market.view_type === 'line' && market.raw_line > 99,
        light,
        small,
        $isCompactViewEnabled: isPhone || isTablet,
        $isMobileCompactView: isMobileCompactView,
        $isAmericanLayoutTopMarkets: isAmericanLayoutTopMarkets,
        $isPromotion: !!promotion,
    };

    return (
        <Wrapper {...filterStyleProps(styleProps)} $isRetailLayout={isRetailLayout}>
            {isOutcomeNameVisible && !promotion && (
                <div className="odds-outcome">
                    <span className="outcome-name">{outcome.name}</span>
                    {!isAmericanLayout && (
                        <span className="outcome-line">
                            <span className="raw-line">
                                {market.view_type === 'line' &&
                                getLine(market as BetSlipMinimalMarket, outcome) === undefined &&
                                outcome.result_key !== 'Draw'
                                    ? market.raw_line
                                    : ''}
                                {getLine(market as BetSlipMinimalMarket, outcome)}
                            </span>
                        </span>
                    )}
                </div>
            )}
            <button
                className="odds-button"
                data-test={isOddsDisabled ? 'button-odds-disabled' : `button-odds-${market.id}`}
                onClick={toggleOddsSelection}
            >
                {isOddsDisabled && <Svg icon="lock" size={0.5} className="odds-locked" />}
                <span className="odds-value">
                    <>{children}</>
                    {isLineMarket && isAmericanLayout && (
                        <span className="outcome-line">{getLine(market as BetSlipMinimalMarket, outcome)}</span>
                    )}
                    <span
                        className="outcome-value"
                        data-test={betslipMarketOutcomeId === outcomeId ? 'selected-outcome-value' : ''}
                    >
                        {convertOdds(currentOddsValue)}
                    </span>
                </span>
            </button>
        </Wrapper>
    );
}

export default React.memo(
    SportOdds,
    (prevProps, nextProps) =>
        prevProps.outcomeId === nextProps.outcomeId &&
        prevProps.market === nextProps.market &&
        prevProps.market.status === nextProps.market.status &&
        prevProps.isAmericanLayout === nextProps.isAmericanLayout,
);
