import React from 'react';
import Wrapper from './styles';
import { stores, useStoreWithSelector } from '../../../../../stores';
import has from 'lodash/has';
import SportMatchTimeLive from '../../time/live/SportMatchTimeLive';
import { FoCategoryMatch } from '@staycool/sbgate-types';

interface Props {
    match: FoCategoryMatch;
}

export default function SportMatchScoreboardBgDarts({ match }: Props) {
    const [inplayData] = useStoreWithSelector(stores.sports.inplayMatchData, (state) => state[match.id], [match.id]);
    const { home_team_points, away_team_points, scoreboard } = inplayData || {};

    const isOutright = match.match_type === 'OUTRIGHT';
    const homeTeam = isOutright ? '' : match.home_team_name;
    const awayTeam = isOutright ? '' : match.away_team_name;
    const backgroundUrl = `/assets/images/scoreboard-backgrounds/scoreboard-${match.sport_slug}.jpg`;

    if (!inplayData) {
        return null;
    }

    const setNumbers = Object.keys(scoreboard);
    const latestSet = setNumbers[setNumbers.length - 1];

    return (
        <Wrapper backgroundUrl={backgroundUrl}>
            <div className="score-board">
                <div className="scores">
                    <div className="score">
                        <h1 className="score-title">{homeTeam}</h1>
                        <span className="score-number"> {home_team_points || 0}</span>
                    </div>
                    <div className="timer">
                        <SportMatchTimeLive matchId={match.id} sportId={match.sport} isOurLivebet={match.livebet_our} />
                    </div>
                    <div className="score">
                        <h1 className="score-title">{awayTeam}</h1>
                        <span className="score-number"> {away_team_points || 0}</span>
                    </div>
                </div>
                {Boolean(Object.values(scoreboard).length) && (
                    <div className="set-scores-container">
                        <div className="set-scores">
                            <div className="team-name">{match.home_team_name}</div>
                            {setNumbers.map((set) => (
                                <span className={`set-score ${set === latestSet ? 'active' : ''}`} key={set}>
                                    <div className="set-label">{`#${set}`}</div>
                                    {has(scoreboard, `${set}.home`) ? scoreboard[set].home : '-'}
                                </span>
                            ))}
                        </div>
                        <div className="set-scores">
                            <div className="team-name">{match.away_team_name}</div>
                            {setNumbers.map((set) => (
                                <span className={`set-score ${set === latestSet ? 'active' : ''}`} key={set}>
                                    {has(scoreboard, `${set}.away`) ? scoreboard[set].away : '-'}
                                </span>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </Wrapper>
    );
}
