import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import UiAlert from '../ui/alert/UiAlert';
import { translate } from '../../services/translate';
import { stores } from '../../stores';
import { LOGIN_METHOD } from '../../services/auth';
import { storageGet, storageSet } from '../../services/storage';
import { getRoute, isActiveRoute } from '../../services/router';
import { CmsBannerMessage, BannerMessageSeverityLevel, BannerMessageDisplayPage } from '../../microservices/cms';
import UiCloseButton from '../ui/close-button/UiCloseButton';
import { useStore } from '../../hooks/useStore';

export default function BannerMessage() {
    const [user] = useStore(stores.user);
    const [storeBannerMessages, setStoreBannerMessages] = useStore(stores.cms.bannerMessages);
    const [kycSettings] = useStore(stores.kyc.settings);
    const [bannerMessages, setBannerMessages] = useState<CmsBannerMessage[]>([]);
    const isBackOfficeLogin = Boolean(storageGet(LOGIN_METHOD.BO_LOGIN));
    const isCasinoView = isActiveRoute(getRoute('casino-game'), false);
    useEffect(() => {
        if (user) {
            init();
        }
    }, [user, kycSettings, isCasinoView, storeBannerMessages]);

    function init() {
        const messages: CmsBannerMessage[] = [];
        if (!isCasinoView) {
            const loadedMessages = loadBannerMessages();
            messages.push(...loadedMessages);
            if (kycSettings.isKycBannerEnabled && Object.values(kycSettings.hasReachedLimits).includes(false)) {
                messages.push({
                    id: 'kyc-status-waiting',
                    translationKey: translate('kyc-status-waiting', 'ui.account'),
                    severityLevel: BannerMessageSeverityLevel.info,
                } as any);
            }
        }

        if (isBackOfficeLogin) {
            messages.push({
                id: 'bo-login',
                translationKey: translate('Warning. Your are logged into FO. Do not make any changes', 'ui.account'),
                severityLevel: BannerMessageSeverityLevel.failure,
            } as any);
        }

        setBannerMessages(messages);
    }

    function loadBannerMessages() {
        return storeBannerMessages.filter(
            (message) =>
                message.displayPage === BannerMessageDisplayPage.header && new Date(message.endDate) > new Date(),
        );
    }

    function closeBannerMessage(bannerMessage: CmsBannerMessage) {
        storageSet(`banner-${bannerMessage.id.toString()}`, new Date(bannerMessage.endDate).getTime());
        setStoreBannerMessages([
            ...storeBannerMessages.filter((message) => message.id !== bannerMessage.id),
            { ...bannerMessage, isHidden: true },
        ]);
    }

    if (!bannerMessages) {
        return null;
    }

    return (
        <Wrapper className="banner-message">
            {bannerMessages
                .filter((message) => !message.isHidden)
                .map((bannerMessage) => (
                    <UiAlert
                        className="alert"
                        key={bannerMessage.id}
                        info={bannerMessage.severityLevel === BannerMessageSeverityLevel.info}
                        failure={bannerMessage.severityLevel === BannerMessageSeverityLevel.failure}
                        warning={bannerMessage.severityLevel === BannerMessageSeverityLevel.warning}
                        center
                    >
                        <div
                            className="text"
                            dangerouslySetInnerHTML={{
                                __html: bannerMessage.id
                                    ? translate(bannerMessage.translationKey)
                                    : bannerMessage.translationKey,
                            }}
                        />
                        {bannerMessage.endDate && (
                            <UiCloseButton className="close-button" onClick={() => closeBannerMessage(bannerMessage)} />
                        )}
                    </UiAlert>
                ))}
        </Wrapper>
    );
}
