import styled from 'styled-components';
import { mediaQuery } from '../../styles/utils';

export default styled.div`
    .time-filter {
        margin-bottom: ${({ theme }) => theme.layout.gutter};

        button {
            font-size: 0.7rem;
            min-height: 30px;
        }
    }

    @media ${mediaQuery.isPhone} {
        .time-filter {
            white-space: nowrap;
            overflow: auto;
        }
    }
`;
