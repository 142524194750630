import React from 'react';
import Svg from '../../../../../../svg/Svg';
import { translate } from '../../../../../../../services/translate';
import { useHiddenMarketsCountWithDevice } from '../../../../../../../services/sports/top-markets';
import { CategoryMatch } from '../../../../../../../services/sports/types';
import { useSportsMatchContext } from '../../../../../../../services/sports/match-helpers';
import { FEATURE, isFeatureAvailable } from '../../../../../../../services/feature';
import { getRoute, useRouter } from '../../../../../../../services/router';
import { MATCH_STATUS } from '../../../../../../../services/sports/constants';
import { media } from '../../../../../../../stores/media/media';
import { useStore } from '../../../../../../../hooks/useStore';

interface Props {
    match: CategoryMatch;
}

function SportMatchMarketsTopMarketsMarketNoOdds({ match }: Props) {
    const { hiddenMarketsCount } = useHiddenMarketsCountWithDevice(match);
    const [{ isPhone }] = useStore(media);
    const { setContext: setMatchContext } = useSportsMatchContext();
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);
    const { navigateTo } = useRouter();
    const isLiveNow = match.status === MATCH_STATUS.LIVE;
    const matchRoute = isLiveNow ? `${getRoute('sport.live')}/${match.id}` : `${getRoute('sport.match')}/${match.id}`;

    function openSidebets() {
        isLayoutB ? navigateTo(matchRoute) : setMatchContext?.({ isOpen: hiddenMarketsCount > 0 });
    }
    return (
        <div className="no-odds-container" onClick={() => openSidebets()}>
            <div className="no-odds-available animated fadeIn">
                {hiddenMarketsCount > 0 ? (
                    isPhone ? (
                        <Svg icon="menu-dots-horizontal" size={0.75} />
                    ) : (
                        <span>{translate('Check more odds', 'sport.market')} →</span>
                    )
                ) : isPhone ? (
                    <Svg icon="deny-sign" size={1} />
                ) : (
                    <span>{translate('No odds for this market', 'sport.market')}</span>
                )}
            </div>
        </div>
    );
}

export default React.memo(SportMatchMarketsTopMarketsMarketNoOdds);
