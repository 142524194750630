import React, { useEffect, useState } from 'react';
import { renewToken } from '../../../microservices/auth';
import {
    sendRegistrationVerificationPinToEmail,
    sendRegistrationVerificationPinToSms,
    verifyEmailPin,
    verifySmsPin,
    sendRegistrationCodeToEmail,
} from '../../../microservices/sms';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../services/analytics';
import { logout, setTokenAndAuthenticate } from '../../../services/auth';
import { COUNTRY } from '../../../services/country';
import { useForm } from '../../../services/form';
import { NativeMessageEventType, checkSourceType, sendNativeEvent } from '../../../services/mobile-app';
import { getRoute, useRouter } from '../../../services/router';
import { storageGet } from '../../../services/storage';
import { translate } from '../../../services/translate';
import { isProduction } from '../../../services/util';
import { validators } from '../../../services/validators';
import { stores } from '../../../stores';
import UiButton from '../../ui/button/UiButton';
import UiAlert from '../../ui/alert/UiAlert';
import UiCloseButton from '../../ui/close-button/UiCloseButton';
import UiFormInput from '../../ui/form/input/UiFormInput';
import UiForm from '../../ui/form/UiForm';
import AuthProfileValidatorLicenceRequirement from '../profile-validator/licence-requirement/AuthProfileValidatorLicenceRequirement';
import AuthVerificationChangePhoneNumber from './change-phone-number/AuthVerificationChangePhoneNumber';
import Wrapper from './styles';
import { isDefaultEmailRegistrationCountry } from '../../../services/auth';
import UiCountdown from '../../ui/countdown/UiCountdown';
import { getToken } from '../../../services/token';
import UiBox from '../../ui/box/UiBox';
import { getStoreValue } from '../../../stores/store/utils';
import { useStore } from '../../../hooks/useStore';

export default function AuthVerification() {
    const [
        { email, phoneNumber, phonePrefix, country = getStoreValue(stores.language), temporaryToken, userId },
        setVerificationData,
    ] = useStore(stores.verification.data);

    const { navigateTo } = useRouter();
    const [isRegistrationPhoneVerification] = useStore(stores.verification.isRegistrationPhoneVerification);
    const [isVerifying, setIsVerifying] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [isSmsSent, setIsSmsSent] = useState(false);
    const [isSmsResent, setIsSmsResent] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [pinId, setPinId] = useState('');
    const [error, setError] = useState('');
    const [isSendingCodeToEmail, setIsSendingCodeToEmail] = useState(false);
    const [isSendingCodeToPhone, setIsSendingCodeToPhone] = useState(false);
    const [isChangePhoneNumberFormVisible, setIsChangePhoneNumberFormVisible] = useState(
        Boolean(!phoneNumber && !phonePrefix),
    );
    const [timeoutEndTime, setTimeoutEndTime] = useState<number>();
    const [isEmailResent, setIsEmailResent] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isChangePhoneNumber, setIsChangePhoneNumber] = useState(false);
    const SMS_ERROR = {
        'Could not verify': translate('Could not verify', 'ui.registration'),
        'Email is missing': translate('Email is missing', 'ui.registration'),
        'Email is not correct': translate('Email is not correct', 'ui.registration'),
        'email-send-failed': translate('email-send-failed', 'ui.registration'),
        'Mobile number is mandatory': translate('Mobile number is mandatory', 'ui.registration'),
        'Mobile number is missing': translate('Mobile number is missing', 'ui.registration'),
        'Mobile number is not correct': translate('Mobile number is not correct', 'ui.registration'),
        'No pin id': translate('No pin id', 'ui.registration'),
        'No pin or pin_id!': translate('No pin or pin_id!', 'ui.registration'),
        'Too many requests! Try again later.': translate('Too many requests! Try again later.', 'ui.registration'),
        'Too many requests from same phone number! Try again in 1h': translate(
            'Too many requests from same phone number! Try again in 1h',
            'ui.registration',
        ),
        'too-many-email-retries': translate('too-many-email-retries', 'ui.registration'),
    };

    const form = useForm({
        pin: '',
    });

    useEffect(() => {
        if (!isChangePhoneNumber && isDefaultEmailRegistrationCountry(country)) {
            sendCodeToEmail();
        } else {
            initializeSmsVerification();
        }
    }, [phoneNumber, phonePrefix]);

    async function initializeSmsVerification() {
        setError('');
        setIsSuccess(false);
        setIsEmailSent(false);
        setIsSendingCodeToPhone(true);
        setIsEmailResent(false);

        try {
            const { pinId } = await sendRegistrationVerificationPinToSms({
                email,
                phoneNumber,
                phonePrefix,
                country,
                userId,
            });

            setIsSmsSent(true);
            setPinId(pinId);
            setIsSuccess(true);
        } catch (error: any) {
            setError(SMS_ERROR[error.error] || translate('Something went wrong', 'ui.common'));
            setIsSmsSent(false);
        }
        addTimeoutForSubmit();
        setIsSendingCodeToPhone(false);
    }

    async function sendCodeToEmail() {
        setError('');
        setIsSuccess(false);
        setIsSendingCodeToEmail(true);

        try {
            if (isDefaultEmailRegistrationCountry(country)) {
                const { emailPinId } = await sendRegistrationCodeToEmail({
                    email,
                    phoneNumber,
                    phonePrefix,
                    country,
                    userId,
                    pin_id: pinId,
                });
                setPinId(emailPinId);
            } else {
                await sendRegistrationVerificationPinToEmail({ pin_id: pinId });
            }
            setIsEmailSent(true);
            setIsSmsSent(false);
            setIsSuccess(true);
        } catch (error: any) {
            setError(SMS_ERROR[error.error] || translate('Something went wrong', 'ui.common'));
            setIsSmsResent(false);
        }
        addTimeoutForSubmit();
        setIsSendingCodeToEmail(false);
    }

    function resendCodeToEmail() {
        setIsEmailSent(false);
        setIsEmailResent(true);
        sendCodeToEmail();
        addTimeoutForSubmit();
    }

    async function tryVerifyPin(isFormValid) {
        setError('');
        setIsSuccess(false);
        if (!isFormValid) {
            return;
        }

        if (!pinId) {
            setError(translate('sms-error-resend', 'ui.account'));
            return;
        }

        setIsVerifying(true);

        if (isEmailSent || isEmailResent) {
            await verifyPinFromEmail();
        } else {
            await verifyPinFromSms();
        }

        setIsVerifying(false);
    }

    async function verifyPinFromEmail() {
        const { pin } = form.getValues();
        setError('');
        setIsSuccess(false);

        try {
            await verifyEmailPin({ pin, pinId });
            await handleVerified();
        } catch (error: any) {
            setError(SMS_ERROR[error.error] || translate('Something went wrong', 'ui.common'));
        }
    }

    async function verifyPinFromSms() {
        const { pin } = form.getValues();
        setError('');
        setIsSuccess(false);

        try {
            await verifySmsPin({ pin, pinId });
            await handleVerified();
        } catch (error) {
            setError(translate('sms-verification-failed', 'ui.account'));
        }
    }

    async function autoVerify() {
        try {
            await handleVerified();
            goToLandingPage();
        } catch (error) {
            setError(translate('sms-verification-failed', 'ui.account'));
        }
    }

    async function handleVerified() {
        await setTokenAndAuthenticate(temporaryToken);

        const user = getStoreValue(stores.user);

        if (user?.closed) {
            await logout();
        } else {
            await renewToken();
        }

        const isRegistrationPhoneVerification = getStoreValue(stores.verification.isRegistrationPhoneVerification);
        if (isRegistrationPhoneVerification) {
            trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.SIGNUP_VERIFIED);
        }

        stores.verification.isRegistrationPhoneVerification.set(false);
        stores.isRegistrationComplete.set(true);
        stores.verification.isVerificationActive.set(false);
        stores.verification.data.set({
            temporaryToken: undefined,
            email: undefined,
            phoneNumber: undefined,
            phonePrefix: undefined,
            country: undefined,
            userId: undefined,
        });
        stores.modals.isLoginModalOpen.set(false);

        setIsVerified(true);
        sendNativeEvent({
            type: NativeMessageEventType.REGISTRATION_COMPLETED,
            id: user?.id,
            email: user?.email,
            verified: user?.verified,
            closed: user?.closed,
            token: getToken(),
        });
    }

    function goToLandingPage() {
        storageGet('leaguePromoEnabled') ? navigateTo(getRoute('league')) : navigateTo(getRoute('welcome.bonus'));
    }

    function isSendingCodeToEmailAllowedByCountryCode(countryCode) {
        const countries = [COUNTRY.CHILE, COUNTRY.ECUADOR, COUNTRY.PERU, COUNTRY.MEXICO];
        return countries.includes(countryCode);
    }

    function addTimeoutForSubmit() {
        const resendTimeoutInSeconds = 30;
        setTimeoutEndTime(new Date().setSeconds(new Date().getSeconds() + resendTimeoutInSeconds));
        setTimeout(() => {
            setTimeoutEndTime(undefined);
        }, resendTimeoutInSeconds * 1000);
    }

    return (
        <Wrapper>
            <UiBox>
                {isVerified && <AuthProfileValidatorLicenceRequirement onExit={goToLandingPage} />}
                {!isVerified && (
                    <>
                        <h1 dangerouslySetInnerHTML={{ __html: translate('step3.header', 'ui.registration') }} />
                        {error && <UiAlert failure>{error}</UiAlert>}

                        {!isChangePhoneNumberFormVisible && (
                            <div>
                                {isSmsSent && isSuccess && (
                                    <>
                                        <UiAlert success className="top-0">
                                            <strong>{translate('Verification SMS sent!', 'ui.registration')}</strong>
                                            <br />
                                            {translate(
                                                'check-your-phone--we-have-sent-sms-to-this-number',
                                                'ui.registration',
                                            )}
                                            :
                                            <em>
                                                {phonePrefix} {phoneNumber}
                                            </em>
                                        </UiAlert>
                                    </>
                                )}

                                {isEmailSent && isSuccess && (
                                    <UiAlert success>
                                        <strong>{translate('Verification Email sent', 'ui.common')}</strong>
                                    </UiAlert>
                                )}

                                <UiForm onSubmit={tryVerifyPin} isLoading={isVerifying}>
                                    <UiFormInput
                                        {...form.pin}
                                        validator={
                                            isEmailSent || isEmailResent ? validators.emailPin : validators.smsPin
                                        }
                                        type="tel"
                                        placeholder={translate('Enter PIN here', 'ui.gdpr')}
                                        maxLength={6}
                                        label={translate('Enter PIN here', 'ui.gdpr')}
                                    />

                                    <UiButton
                                        block
                                        size="large"
                                        color="primary"
                                        isFormSubmitButton
                                        isLoading={isVerifying}
                                    >
                                        {checkSourceType() !== 'IOS'
                                            ? translate('Verify & Login', 'ui.registration')
                                            : translate('Verify SMS', 'ui.registration')}
                                    </UiButton>
                                </UiForm>

                                {!isProduction() && (
                                    <UiButton block onClick={autoVerify} data-test="loginWithoutVerifying">
                                        {translate('Login without verifying', 'ui.registration')}
                                    </UiButton>
                                )}

                                <br />

                                {isSmsSent && (
                                    <UiButton
                                        block
                                        onClick={() => {
                                            initializeSmsVerification();
                                            setIsSmsResent(true);
                                        }}
                                        isLoading={isSendingCodeToPhone}
                                        disabled={Boolean(timeoutEndTime)}
                                    >
                                        {translate('Resend SMS', 'ui.registration')} {}
                                        {timeoutEndTime && (
                                            <UiCountdown endDate={timeoutEndTime} className="submit-timer">
                                                {({ seconds }) => <>({seconds})</>}
                                            </UiCountdown>
                                        )}
                                    </UiButton>
                                )}

                                {!isEmailSent && isSmsResent && isSendingCodeToEmailAllowedByCountryCode(country) && (
                                    <UiButton
                                        block
                                        onClick={sendCodeToEmail}
                                        isLoading={isSendingCodeToEmail}
                                        disabled={Boolean(timeoutEndTime)}
                                    >
                                        {translate('Send code to Email', 'ui.common')} {}
                                        {timeoutEndTime && (
                                            <UiCountdown endDate={timeoutEndTime} className="submit-timer">
                                                {({ seconds }) => <>({seconds})</>}
                                            </UiCountdown>
                                        )}
                                    </UiButton>
                                )}

                                {isEmailSent && (
                                    <UiButton
                                        block
                                        onClick={resendCodeToEmail}
                                        isLoading={isSendingCodeToEmail}
                                        disabled={Boolean(timeoutEndTime)}
                                    >
                                        {translate('Resend Email', 'ui.common')} {}
                                        {timeoutEndTime && (
                                            <UiCountdown endDate={timeoutEndTime} className="submit-timer">
                                                {({ seconds }) => <>({seconds})</>}
                                            </UiCountdown>
                                        )}
                                    </UiButton>
                                )}

                                {!isSmsSent && isEmailResent && isDefaultEmailRegistrationCountry(country) && (
                                    <UiButton
                                        block
                                        onClick={initializeSmsVerification}
                                        isLoading={isSendingCodeToEmail}
                                        disabled={Boolean(timeoutEndTime)}
                                    >
                                        {translate('Send SMS', 'ui.common')} {}
                                        {timeoutEndTime && (
                                            <UiCountdown endDate={timeoutEndTime} className="submit-timer">
                                                {({ seconds }) => <>({seconds})</>}
                                            </UiCountdown>
                                        )}
                                    </UiButton>
                                )}

                                {isRegistrationPhoneVerification && (
                                    <UiButton
                                        block
                                        onClick={() => {
                                            setIsChangePhoneNumberFormVisible(true);
                                            setError('');
                                            setIsSuccess(false);
                                        }}
                                    >
                                        {translate('-change-', 'ui.registration')}
                                    </UiButton>
                                )}
                            </div>
                        )}

                        {isChangePhoneNumberFormVisible && (
                            <div className="change-phone-form">
                                {isRegistrationPhoneVerification && (
                                    <UiCloseButton
                                        className="close-button"
                                        onClick={() => {
                                            setIsChangePhoneNumberFormVisible(false);
                                        }}
                                    />
                                )}

                                <AuthVerificationChangePhoneNumber
                                    phonePrefix={phonePrefix}
                                    phoneNumber={phoneNumber}
                                    onPhoneChanged={({ phonePrefix, phoneNumber }) => {
                                        setVerificationData((verificationData) => {
                                            verificationData.phonePrefix = phonePrefix;
                                            verificationData.phoneNumber = phoneNumber;
                                        });
                                        setIsChangePhoneNumber(true);
                                        setIsChangePhoneNumberFormVisible(false);
                                    }}
                                />
                            </div>
                        )}
                    </>
                )}
            </UiBox>
        </Wrapper>
    );
}
