import React, { useState } from 'react';
import { translate } from '../../../../services/translate';
import UiButton from '../../../ui/button/UiButton';
import Ui2Form from '../../../ui-2/form/Ui2Form';
import { getFieldValidators } from '../../../../services/validators';
import Ui2FormTextInput from '../../../ui-2/form/text-input/Ui2FormTextInput';
import { renewPassword } from '../../../../services/auth';
import UiAlert from '../../../ui/alert/UiAlert';
import { useForm } from 'react-hook-form';

type Props = {
    email: string;
    currentPassword: string;
    onSuccess?: ({ email, newPassword }: { email: string; newPassword: string }) => void;
};

export default function AuthLoginRenewPassword({ email, currentPassword, onSuccess = () => {} }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const useFormMethods = useForm({
        defaultValues: {
            newPassword: '',
            passwordConfirm: '',
        },
    });

    async function startRenewPassword() {
        const { newPassword } = useFormMethods.getValues();
        setIsLoading(true);

        try {
            await renewPassword({ newPassword, oldPassword: currentPassword, email });
            onSuccess({ email, newPassword });
        } catch (error: any) {
            setErrorMessage(error.message as string);
            setIsLoading(false);
        }
    }

    return (
        <>
            {errorMessage && (
                <UiAlert failure>
                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                </UiAlert>
            )}
            <Ui2Form onSubmit={startRenewPassword} useFormMethods={useFormMethods} className="email-login">
                <Ui2FormTextInput
                    name="newPassword"
                    type="password"
                    placeholder={translate('New password', 'ui.account')}
                    dataTest="new-password"
                    required
                    minLength={8}
                    validator={getFieldValidators(['password', 'passwordDifferentThanEmail'])}
                    error={useFormMethods.formState.errors.newPassword}
                />
                <Ui2FormTextInput
                    name="passwordConfirm"
                    type="password"
                    placeholder={translate('Confirm password', 'ui.account')}
                    required
                    validator={getFieldValidators(['passwordConfirm'])}
                    error={useFormMethods.formState.errors.passwordConfirm}
                    dataTest="new-password-confirm"
                />
                <UiButton
                    color="primary"
                    size="large"
                    block
                    isLoading={isLoading}
                    className="login-button"
                    data-test="button-renew-password-submit"
                    isFormSubmitButton
                >
                    {translate('Set password', 'ui.account')}
                </UiButton>
            </Ui2Form>
        </>
    );
}
