import { EventEmitter } from 'events';
import { stores } from '../../stores';
import { environment } from '../../stores/environment/environment';
import { getStoreValue } from '../../stores/store/utils';
import { getDecodedToken } from '../token';
import {
    attemptWithRetry,
    GEOCOMPLY_REASON,
    geocomplyClientErrorHandler,
    handleEncryptedGeopacket,
    logMessage,
    startDurationTimer,
} from './geocomply';

export async function geoComplyMobilePlatformGeoVerification(reason: GEOCOMPLY_REASON): Promise<void> {
    const geocomplyMobileSettings = getGeocomplyMobileSettings(reason);
    const GeoComplyMobileLibrary = (window as any).GCOobee as GeoComplyMobileLibrary;
    const GeoComplyMobileClient = GeoComplyMobileLibrary.createClient();

    (window as any).CoolbetGeoComplyMobileLibrary = GeoComplyMobileLibrary;
    (window as any).CoolbetGeoComplyMobileClient = GeoComplyMobileClient;

    const knownErrorCodes = Object.values(GeoComplyMobileClient.ErrorCodes);

    stores.geocomply.client.set((geocomplyClient) => {
        geocomplyClient.libraryVersion = GeoComplyMobileClient.getVersion();
        geocomplyClient.clientError = null;
        geocomplyClient.hint = null;
    });

    function setupInformationalEventLoggers(geoComplyMobileClient: GeoComplyMobileClient) {
        const EVENTS = geoComplyMobileClient.EVENTS;

        GeoComplyMobileClient.events.on(EVENTS.LOG, (...args: any[]) => {
            logMessage(`(${EVENTS.LOG}) ${JSON.stringify(args, null, 4)}`, 'INFO');
        });

        GeoComplyMobileClient.events.on(EVENTS.BEFORE, () => {
            logMessage(`(${EVENTS.BEFORE})`, 'INFO');
        });

        GeoComplyMobileClient.events.on(EVENTS.REVISE_FAILED, (errorCode: number, errorMessage: string) => {
            logMessage(`(${EVENTS.REVISE_FAILED}) | errorCode: ${errorCode} | errorMessage: ${errorMessage}`, 'ERROR');
        });

        GeoComplyMobileClient.events.on(EVENTS.INIT_FAILED, (errorCode: number, errorMessage: string) => {
            logMessage(`(${EVENTS.INIT_FAILED}) | errorCode: ${errorCode} | errorMessage: ${errorMessage}`, 'ERROR');
        });

        GeoComplyMobileClient.events.on(EVENTS.INIT_SUCCESS, () => {
            logMessage(`(${EVENTS.INIT_SUCCESS})`, 'SUCCESS');
        });

        GeoComplyMobileClient.events.on(EVENTS.USERDEVICE_BEFORE, () => {
            logMessage(`(${EVENTS.USERDEVICE_BEFORE})`, 'INFO');
        });

        GeoComplyMobileClient.events.on(EVENTS.USERDEVICE_FAILED, () => {
            logMessage(`(${EVENTS.USERDEVICE_FAILED})`, 'ERROR');
        });

        GeoComplyMobileClient.events.on(EVENTS.USERDEVICE_REGISTERED, () => {
            logMessage(`(${EVENTS.USERDEVICE_REGISTERED})`, 'SUCCESS');
        });

        GeoComplyMobileClient.events.on(EVENTS.USERDEVICE_UNREGISTERED, () => {
            logMessage(`(${EVENTS.USERDEVICE_UNREGISTERED})`, 'SUCCESS');
        });

        GeoComplyMobileClient.events.on(EVENTS.REGISTER_BEFORE, () => {
            logMessage(`(${EVENTS.REGISTER_BEFORE})`, 'INFO');
        });

        GeoComplyMobileClient.events.on(EVENTS.REGISTER_FAILED, (errorCode: number, errorMessage: string) => {
            logMessage(
                `(${EVENTS.REGISTER_FAILED}) | errorCode: ${errorCode} | errorMessage: ${errorMessage}`,
                'ERROR',
            );
        });

        GeoComplyMobileClient.events.on(EVENTS.REGISTER_SUCCESS, (deviceUid: string, pairCode: string) => {
            logMessage(`(${EVENTS.REGISTER_SUCCESS}) | deviceUid: ${deviceUid} | pairCode: ${pairCode}`, 'SUCCESS');
        });

        GeoComplyMobileClient.events.on(EVENTS.DEREGISTER_BEFORE, () => {
            logMessage(`(${EVENTS.DEREGISTER_BEFORE})`, 'INFO');
        });

        GeoComplyMobileClient.events.on(EVENTS.DEREGISTER_FAILED, () => {
            logMessage(`(${EVENTS.DEREGISTER_FAILED})`, 'ERROR');
        });

        GeoComplyMobileClient.events.on(EVENTS.DEREGISTER_SUCCESS, () => {
            logMessage(`(${EVENTS.DEREGISTER_SUCCESS})`, 'SUCCESS');
        });

        GeoComplyMobileClient.events.on(EVENTS.CONNECT_BEFORE, () => {
            logMessage(`(${EVENTS.CONNECT_BEFORE})`, 'INFO');
        });

        GeoComplyMobileClient.events.on(EVENTS.CONNECT_SUCCESS, () => {
            logMessage(`(${EVENTS.CONNECT_SUCCESS})`, 'SUCCESS');
        });

        GeoComplyMobileClient.events.on(EVENTS.CONNECT_FAILED, () => {
            logMessage(`(${EVENTS.CONNECT_FAILED})`, 'ERROR');
        });

        GeoComplyMobileClient.events.on(EVENTS.GEOLOCATION_BEFORE, () => {
            logMessage(`(${EVENTS.GEOLOCATION_BEFORE})`, 'INFO');
        });

        GeoComplyMobileClient.events.on(EVENTS.GEOLOCATION_PENDING, () => {
            logMessage(`(${EVENTS.GEOLOCATION_PENDING})`, 'INFO');
        });

        GeoComplyMobileClient.events.on(EVENTS.GEOLOCATION_FAILED, (errorCode: number, errorMessage: string) => {
            logMessage(
                `(${EVENTS.GEOLOCATION_FAILED}) | errorCode: ${errorCode} | errorMessage: ${errorMessage}`,
                'ERROR',
            );
        });

        GeoComplyMobileClient.events.on(
            EVENTS.GIF_STOP_UPDATING,
            ({ code, message }: { code: number; message: string }) => {
                logMessage(`(${EVENTS.GIF_STOP_UPDATING}) | code: ${code} | message: ${message}`, 'ERROR');
            },
        );

        GeoComplyMobileClient.events.on(EVENTS.GIF_BLUETOOTH_OFF, (message: string) => {
            logMessage(`(${EVENTS.GIF_BLUETOOTH_OFF}) | message: ${message}`, 'ERROR');
        });
    }

    const attemptConnection = (attemptIndex: number, totalAttempts: number) =>
        new Promise<void>((resolve, reject) => {
            if (GeoComplyMobileLibrary.utils.browser.is.ios && GeoComplyMobileClient.hasGeolocatedRecently()) {
                logMessage(`iOS GeoComply client has geolocated recently - skipping connection attempt`, 'INFO');
                resolve();
                return;
            }

            logMessage(`Connection attempt ${attemptIndex} out of ${totalAttempts}`, 'INFO');

            const getDuration = startDurationTimer();
            const EVENTS = GeoComplyMobileClient.EVENTS;

            setupInformationalEventLoggers(GeoComplyMobileClient);

            GeoComplyMobileClient.events.on(EVENTS.HINT, (hint: GeoComplyMobileHint) => {
                logMessage(`(${EVENTS.HINT}) | hint: ${JSON.stringify(hint, null, 4)}`, 'WARNING');

                stores.geocomply.client.set((geocomplyClient) => {
                    geocomplyClient.hint = hint;
                });
            });

            GeoComplyMobileClient.events.on('*.failed', (errorCode: number, errorMessage: string) => {
                logMessage(`Connecting failed after ${getDuration().humanized}`, 'INFO');

                geocomplyClientErrorHandler(errorCode, errorMessage);
                reject({ code: errorCode, message: errorMessage });
            });

            GeoComplyMobileClient.connect(
                {
                    timeout: 15000,
                    userAction: true,
                },
                () => {
                    logMessage(`Connecting succeeded after ${getDuration().humanized}`, 'INFO');
                    logMessage('GeoComply client connected', 'SUCCESS');

                    stores.geocomply.client.set((geocomplyClient) => {
                        geocomplyClient.clientError = null;
                        geocomplyClient.clientVersion = GeoComplyMobileClient.getVersion();
                        geocomplyClient.isConnected = true;
                        geocomplyClient.isConnecting = false;
                        geocomplyClient.isGeolocating = false;
                    });

                    resolve();
                },
                () => {
                    logMessage(`Connecting failed after ${getDuration().humanized}`, 'INFO');
                    logMessage('GeoComply client connection failed', 'ERROR');

                    stores.geocomply.client.set((geocomplyClient) => {
                        geocomplyClient.clientError = null;
                        geocomplyClient.clientVersion = null;
                        geocomplyClient.isConnected = false;
                        geocomplyClient.isConnecting = false;
                        geocomplyClient.isGeolocating = false;
                    });

                    reject();
                },
            );

            stores.geocomply.client.set((geocomplyClient) => {
                geocomplyClient.isConnecting = true;
            });
        }).finally(() => {
            GeoComplyMobileClient.events.removeAllListeners();
        });

    const attemptConnectionWithRetry = () =>
        attemptWithRetry(
            [
                GeoComplyMobileClient.ErrorCodes.ERROR_INTERNAL as number,
                GeoComplyMobileClient.ErrorCodes.UNEXPECTED as number,
                GeoComplyMobileClient.ErrorCodes.ERROR_UNEXPECTED as number,
            ],
            knownErrorCodes,
            (attemptIndex: number, totalAttempts: number) => attemptConnection(attemptIndex, totalAttempts),
        );

    const attemptGeoLocation = (attemptIndex: number, totalAttempts: number) =>
        new Promise<string>((resolve, reject) => {
            logMessage(`Geolocation attempt ${attemptIndex} out of ${totalAttempts}`, 'INFO');

            const getDuration = startDurationTimer();
            const EVENTS = GeoComplyMobileClient.EVENTS;

            setupInformationalEventLoggers(GeoComplyMobileClient);

            GeoComplyMobileClient.events.on(EVENTS.GEOLOCATION_SUCCESS, (encryptedGeopacket: string) => {
                logMessage(`GeoLocating succeeded after ${getDuration().humanized}`, 'INFO');

                logMessage(`GeoPacket created: ${encryptedGeopacket}`, 'SUCCESS');

                stores.geocomply.client.set((geocomplyClient) => {
                    geocomplyClient.clientError = null;
                    geocomplyClient.clientVersion = GeoComplyMobileClient.getVersion();
                    geocomplyClient.isConnected = true;
                    geocomplyClient.isConnecting = false;
                    geocomplyClient.isGeolocating = false;
                });

                resolve(encryptedGeopacket);
            });

            GeoComplyMobileClient.events.on(EVENTS.HINT, (hint: GeoComplyMobileHint) => {
                logMessage(`(${EVENTS.HINT}) | hint: ${JSON.stringify(hint, null, 4)}`, 'WARNING');

                stores.geocomply.client.set((geocomplyClient) => {
                    geocomplyClient.hint = hint;
                });
            });

            GeoComplyMobileClient.events.on('*.failed', (errorCode: number, errorMessage: string) => {
                logMessage(`GeoLocating failed after ${getDuration().humanized}`, 'INFO');

                geocomplyClientErrorHandler(errorCode, errorMessage);
                reject({ code: errorCode, message: errorMessage });
            });

            GeoComplyMobileClient.request();

            stores.geocomply.client.set((geocomplyClient) => {
                geocomplyClient.isGeolocating = true;
            });
        }).finally(() => {
            GeoComplyMobileClient.events.removeAllListeners();
        });

    const attemptGeolocationWithRetry = () =>
        attemptWithRetry(
            [
                GeoComplyMobileClient.ErrorCodes.ERROR_INTERNAL as number,
                GeoComplyMobileClient.ErrorCodes.UNEXPECTED as number,
                GeoComplyMobileClient.ErrorCodes.ERROR_UNEXPECTED as number,
            ],
            knownErrorCodes,
            (attemptIndex: number, totalAttempts: number) => attemptGeoLocation(attemptIndex, totalAttempts),
        );

    try {
        try {
            GeoComplyMobileClient.configure({
                serviceUrl: geocomplyMobileSettings.serviceUrl,
                oobeeUrl: geocomplyMobileSettings.oobeeUrl,
                license: geocomplyMobileSettings.license,
                userId: geocomplyMobileSettings.userId,
                reason: geocomplyMobileSettings.reason,
                customFields: {
                    sessionKey: geocomplyMobileSettings.customFields.sessionKey as string,
                },
            });
        } catch (error: any) {
            logMessage(`GeoComply client configuration failed | ${error.message}`, 'ERROR');
            throw error;
        }

        stores.geocomply.client.set((geocomplyClient) => {
            geocomplyClient.isAttemptingConnection = true;
        });

        await attemptConnectionWithRetry();

        stores.geocomply.client.set((geocomplyClient) => {
            geocomplyClient.isAttemptingConnection = false;
            geocomplyClient.isAttemptingGeolocation = true;
        });

        const encryptedGeopacket = await attemptGeolocationWithRetry();
        await handleEncryptedGeopacket(encryptedGeopacket, reason);
    } finally {
        stores.geocomply.client.set((geocomplyClient) => {
            geocomplyClient.clientVersion = null;
            geocomplyClient.isConnected = false;
            geocomplyClient.isConnecting = false;
            geocomplyClient.isAttemptingConnection = false;
            geocomplyClient.isGeolocating = false;
            geocomplyClient.isAttemptingGeolocation = false;
        });
    }
}

export function getGeocomplyMobileSettings(reason: GEOCOMPLY_REASON): GeocomplyMobileSettings {
    const user = getStoreValue(stores.user);
    const token = getStoreValue(stores.token);
    const license = getStoreValue(stores.geocomply.license);

    return {
        serviceUrl: getStoreValue(environment)?.GEOCOMPLY?.MOBILE_OOBEE_SERVICE_URL ?? '',
        oobeeUrl: getStoreValue(environment)?.GEOCOMPLY?.MOBILE_OOBEE_URL ?? '',
        license: license.license ?? '',
        userId: user?.id ?? '',
        reason,
        customFields: {
            sessionKey: token ? getDecodedToken(token).login_session_id : '',
        },
    };
}

interface GeocomplyMobileSettings {
    serviceUrl: string;
    oobeeUrl: string;
    enablePlayStorePopUp?: boolean; // If true and app not installed in addition to CLNT_ERROR_APP_NOT_INSTALLED event it will also open PlayStore page for the app
    geoTimeout?: number;
    enableAndroidSimplified?: boolean;
    license: string;
    userId: string;
    reason: GEOCOMPLY_REASON;
    session?: string;
    customFields: Record<string, string | number>; // Optional
    customUrl?: string;
    androidAppName?: string;
    androidAppBundleId?: string;
    androidHttpServerPorts?: Array<number>;
    iOSAppName?: string;
    iOSAppBundleId?: string;
    iOSHttpServerPorts?: Array<number>;
    appName?: string;
    appBundleId?: string;
    httpServerPorts?: Array<number>;
    branchIOCallBackURL?: string;
    useTrueLocationBrowser?: boolean;
    trueLocationBrowserUseGIF?: boolean;
    trueLocationBrowserReturn611?: boolean;
    sdkVersion?: string;
}

export interface GeoComplyMobileLibrary {
    createClient: (options?: GeocomplyMobileSettings) => GeoComplyMobileClient;
    utils: {
        browser: {
            is: {
                android: boolean;
                ios: boolean;
            };
        };
    };
}

interface ConnectOptions {
    timeout: number;
    userAction: true; // Don't know in which cases it should be false. Forcing true everywhere.
}

interface RequestOptions {
    connect?: ConnectOptions;
    geoTimeout?: GeocomplyMobileSettings['geoTimeout'];
}

export interface GeoComplyMobileClient {
    configure: (options?: GeocomplyMobileSettings) => void; // Not specified in OobeeJS documentation pages 33 and 34
    request: (options?: RequestOptions) => void;
    getVersion: () => string;
    connect: (options: ConnectOptions, successCallback: () => void, errorCallback: () => void) => void;
    getLastRegisterDate: () => string;
    getLastGeolocateDate: () => string;
    hasRegisteredRecently: () => boolean;
    hasGeolocatedRecently: () => boolean;
    ping: (successCallback: () => void, errorCallback: () => void) => void;
    isTrueLocationBrowser: () => boolean;
    stopUpdating: () => void; // To stop the indoor geolocation flow immediately. The event geolocation.stop_updating will be triggered right after that.
    enablePlayStorePopUp: boolean;
    setServiceUrl: (serviceUrl: GeocomplyMobileSettings['serviceUrl']) => void;
    setOobeeUrl: (oobeeUrl: GeocomplyMobileSettings['oobeeUrl']) => void;
    setEnablePlayStorePopUp: (enablePlayStorePopUp: GeocomplyMobileSettings['enablePlayStorePopUp']) => void;
    setGeoTimeout: (geoTimeout: GeocomplyMobileSettings['geoTimeout']) => void;
    setEnableAndroidSimplified: (enableAndroidSimplified: GeocomplyMobileSettings['enableAndroidSimplified']) => void;
    setLicense: (license: GeocomplyMobileSettings['license']) => void;
    setUserId: (userId: GeocomplyMobileSettings['userId']) => void;
    setReason: (reason: GeocomplyMobileSettings['reason']) => void;
    setSession: (session: GeocomplyMobileSettings['session']) => void;
    setCustomFields: (customFields: GeocomplyMobileSettings['customFields']) => void;
    setCustomURL: (customUrl: GeocomplyMobileSettings['customUrl']) => void;
    setAndroidAppName: (androidAppName: GeocomplyMobileSettings['androidAppName']) => void;
    setAndroidAppBundleId: (androidAppBundleId: GeocomplyMobileSettings['androidAppBundleId']) => void;
    setAndroidHttpServerPorts: (androidHttpServerPorts: GeocomplyMobileSettings['androidHttpServerPorts']) => void;
    setIOSAppName: (iOSAppName: GeocomplyMobileSettings['iOSAppName']) => void;
    setIOSAppBundleId: (iOSAppBundleId: GeocomplyMobileSettings['iOSAppBundleId']) => void;
    setIOSHttpServerPorts: (iOSHttpServerPorts: GeocomplyMobileSettings['iOSHttpServerPorts']) => void;
    setAppName: (appName: GeocomplyMobileSettings['appName']) => void;
    setAppBundleId: (appBundleId: GeocomplyMobileSettings['appBundleId']) => void;
    setHttpServerPorts: (httpServerPorts: GeocomplyMobileSettings['httpServerPorts']) => void;
    setBranchIOCallBackURL(branchIOCallBackURL: GeocomplyMobileSettings['branchIOCallBackURL'], callback: () => void);
    setUseTrueLocationBrowser: (useTrueLocationBrowser: GeocomplyMobileSettings['useTrueLocationBrowser']) => void;
    setTrueLocationBrowserUseGIF: (
        trueLocationBrowserUseGIF: GeocomplyMobileSettings['trueLocationBrowserUseGIF'],
    ) => void;
    setTrueLocationBrowserReturn611: (
        trueLocationBrowserReturn611: GeocomplyMobileSettings['trueLocationBrowserReturn611'],
    ) => void;
    setSdkVersion: (sdkVersion: GeocomplyMobileSettings['sdkVersion']) => void;
    ErrorCodes: {
        AJAX: 1001;
        APN_MESSAGE_ERROR: 57;
        APN_SERVER_ERROR: 56;
        APP_NOT_INSTALLED: 103;
        APP_REQUIRED: 61;
        CANNOT_CONNECT_ANDROID_TLB_SERVICE: 700;
        CANNOT_FIND_CALLBACK_URL: 312;
        CLIENT_AKEY_OCCUPIED: 36;
        CLIENT_LOCKED: 38;
        CLIENT_NOT_EXISTS: 35;
        CLIENT_SUSPENDED: 102;
        CLIENT_SUSPENDED_SERVER: 604;
        CLIENT_UNAUTHORIZED: 101;
        CLIENT_USER_DUPLICATED: 239;
        DEVICE_ACCESS_DENIED: 9;
        DEVICE_ALREADY_CANCELED: 132;
        DEVICE_ALREADY_REGISTERED: 212;
        DEVICE_ALREADY_UNPAIRED: 17;
        DEVICE_CALLBACK_NOT_FOUND: 617;
        DEVICE_DUPLICATED: 133;
        DEVICE_NOT_EXISTS: 14;
        DEVICE_NOT_INITIALIZED: 131;
        DEVICE_NOT_PAIRED: 13;
        DEVICE_PROXIMITY_NOT_REQUIRED: 16;
        DEVICE_PROXIMITY_REQUIRED: 15;
        DEVICE_UID_NOT_EXIST: 214;
        DISABLED_SOLUTION: 605;
        DUPLICATE_APN: 305;
        ERROR_INTERNAL: 99;
        ERROR_NETWORK_CONNECTION: 602;
        ERROR_UNEXPECTED: 600;
        ES_INVALID_REQUEST_DATA: 291;
        GEOLOCATION_IN_PROGRESS: 614;
        GEOLOCATION_TIMEOUT: 301;
        GOOGLE_PLAY_SERVICE_NOT_FOUND: 616;
        INVALID_ALGORITHM: 125;
        INVALID_ARGUMENT_ERROR: 303;
        INVALID_BRANCH_IO_CALLBACK_URL: 641;
        INVALID_CALLBACK: 123;
        INVALID_CUSTOM_FIELDS: 609;
        INVALID_DEVICE_UID: 120;
        INVALID_HMAC: 640;
        INVALID_LICENSE: 306;
        INVALID_PHONE_NUMBER: 121;
        INVALID_REQUEST_DATA: 91;
        INVALID_REQUEST_FORMAT: 90;
        INVALID_REQUEST_SOURCE: 92;
        INVALID_RESPONSE_DATA: 172;
        INVALID_RETURN_FROM_SERVER: 311;
        INVALID_SDK_VERSION: 93;
        INVALID_TRANSACTION: 124;
        INVALID_USERNAME: 122;
        INVALID_USER_INPUT: 635;
        LICENSE_EXPIRED: 106;
        LICENSE_EXPIRED_SERVER: 608;
        LICENSE_FORMAT: 107;
        LICENSE_FORMAT_SERVER: 606;
        LICENSE_REQUIRED: 108;
        LICENSE_UNAUTHORIZED: 105;
        LICENSE_UNAUTHORIZED_SERVER: 607;
        MERCHANT_ID_NOT_FOUND: 308;
        NOTIFICATION_NOT_ALLOWED: 322;
        NOTIFICATION_SERVICE_DISABLED: 60;
        NO_NETWORK: 173;
        NULL_ARGUMENT_ERROR: 302;
        OK: 0;
        PAYLOAD_APN: 143;
        PAYLOAD_GCM: 142;
        PAYLOAD_INVALID_ID: 21;
        PAYLOAD_PROXIMITY_EXPIRED: 140;
        PAYLOAD_RESPONSE: 141;
        PERMISSION_NOT_GRANTED: 615;
        PRECISE_LOCATION_OFF: 637;
        PROXIMITY_IS_NOT_REQUIRED: 309;
        PROXIMITY_NOT_REQUIRED: 216;
        PROXIMITY_TIMEOUT: 310;
        REGISTER_SMS: 150;
        REQUEST_CANCELED: 611;
        SERVER_COMMUNICATION: 603;
        SERVICE_LOCKED: 112;
        SERVICE_NOT_CONFIGURED: 110;
        SERVICE_NO_RESPONSE: 113;
        SERVICE_UNAUTHORIZED: 111;
        SOCKET: 1002;
        SOCKET_URL_ERROR: 307;
        TOKEN_EXPIRED: 219;
        TOKEN_INVALID: 220;
        TOKEN_VERIFICATION_FAILED: 218;
        TRUE_LOCATION_BROWSER_REQUIRED: 134;
        UNEXPECTED: 100;
        USERID_REQUIRED: 126;
        WRONG_APN_FORMAT: 304;
        WRONG_REQUEST_URL: 171;
    };
    events: EventEmitter;
    EVENTS: {
        '**': '**';
        BEFORE: 'before';
        LOG: 'log';
        HINT: 'hint';
        // ABORT: 'abort'; // Not found in actual object although specified in documentation
        REVISE_FAILED: 'revise.failed';
        INIT_FAILED: 'init.failed';
        INIT_SUCCESS: 'init.success';
        REGISTER_BEFORE: 'register.before';
        REGISTER_FAILED: 'register.failed';
        REGISTER_SUCCESS: 'register.success';
        GEOLOCATION_BEFORE: 'geolocation.before';
        GEOLOCATION_PENDING: 'geolocation.pending';
        GEOLOCATION_FAILED: 'geolocation.failed';
        GEOLOCATION_SUCCESS: 'geolocation.success';
        GIF_STOP_UPDATING: 'geolocation.stop_updating';
        GIF_BLUETOOTH_OFF: 'geolocation.bluetooth_off';
        USERDEVICE_BEFORE: 'userDevice.before';
        USERDEVICE_FAILED: 'userDevice.failed';
        USERDEVICE_REGISTERED: 'userDevice.registered';
        USERDEVICE_UNREGISTERED: 'userDevice.unregistered';
        DEREGISTER_BEFORE: 'deregister.before';
        DEREGISTER_FAILED: 'deregister.failed';
        DEREGISTER_SUCCESS: 'deregister.success';
        CONNECT_BEFORE: 'connect.before';
        CONNECT_FAILED: 'connect.failed';
        CONNECT_SUCCESS: 'connect.success';
    };
    HINTS: {
        // If the JS SDK can not connect to the application and it requires users to install or to open the app. Or if the operator web app tries to call connect() without the user action and userAction is set by “false”.
        // If user does not allow the app to access Location Precise Permission, the hint event below will be returned along with the error 637
        // If user does not allow the app to access Notification Permission, the hint event below will be returned along with the error 322
        APP_NOT_OPENED: {
            reason: 1;
            message: string;
            retry: boolean;
            iosWakeupUrl: string;
            androidWakeupUrl?: string;
            androidWakeupIntent?: string;
        };
        // If “useTrueLocationBrowser” is “true” and users are using another browser instead of TrueLocation Browser.
        TRUE_LOCATION_BROWSER: { reason: 2; message: string; link: string };
        // If the app was installed but never opened. Android App is required to be opened at least
        // once to initialize before geolocating.
        APP_REQUIRED: {
            reason: 3;
            message: string;
            retry: boolean;
            wakeupAppIntentURI: string;
        };
        // Hint user to open the Android App and grant location permission to the app when JS SDK receives the error 615 from the app.
        APP_REQUIRED_LOCATION_PERMISSION: { reason: 4; message: string; wakeupUrl: string };
        // Hint user to open the Notification Settings and turn on the settings for the Android App when JS SDK receives the error 60 from the Android App.
        APP_REQUIRED_NOTIFICATION_PERMISSION: {
            reason: 6;
            message: string;
            androidNotificationSettingUrl: string;
            androidNotificationSettingsIntentUri: string;
        };
    };
}

export type GeoComplyMobileAppNotOpenedHint = GeoComplyMobileClient['HINTS']['APP_NOT_OPENED'];
export type GeoComplyMobileTrueLocationBrowserHint = GeoComplyMobileClient['HINTS']['TRUE_LOCATION_BROWSER'];
export type GeoComplyMobileAppRequiredHint = GeoComplyMobileClient['HINTS']['APP_REQUIRED'];
export type GeoComplyMobileAppRequiredLocationPermissionHint =
    GeoComplyMobileClient['HINTS']['APP_REQUIRED_LOCATION_PERMISSION'];
export type GeoComplyMobileAppRequiredNotificationPermissionHint =
    GeoComplyMobileClient['HINTS']['APP_REQUIRED_NOTIFICATION_PERMISSION'];

export type GeoComplyMobileHint =
    | GeoComplyMobileAppNotOpenedHint
    | GeoComplyMobileTrueLocationBrowserHint
    | GeoComplyMobileAppRequiredHint
    | GeoComplyMobileAppRequiredLocationPermissionHint
    | GeoComplyMobileAppRequiredNotificationPermissionHint;
