import styled from 'styled-components';

export default styled.div`
    width: 100%;
    height: 100%;
    opacity: 0;

    &.loaded {
        transition: opacity 0.2s;
        opacity: 1;
    }

    &.pre-loaded {
        opacity: 1;
    }
`;
