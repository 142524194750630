import React from 'react';
import Svg from '../../../../svg/Svg';
import { MATCH_STATUS } from '../../../../../services/sports/constants';
import { getRoute, useRouter } from '../../../../../services/router';
import { useSportsMatchContext } from '../../../../../services/sports/match-helpers';
import classNames from 'classnames';
import { CategoryMatch } from '../../../../../services/sports/types';
import { isRetail } from '../../../../../services/environment';

interface Props {
    match: CategoryMatch;
}
export default function SportMatchBetbuilderButton({ match }: Props) {
    const { navigateTo } = useRouter();
    const matchRoute =
        match.status === MATCH_STATUS.LIVE
            ? `${getRoute('sport.live')}/${match.id}`
            : `${getRoute('sport.match')}/${match.id}`;
    const { isBetbuilderActive, isOpen, setContext: setMatchContext } = useSportsMatchContext();
    const isRetailLayout = isRetail();

    function toggleBetBuilder() {
        navigateTo(`${matchRoute}?betbuilder=true`);
        setMatchContext?.({
            isBetbuilderActive: !isBetbuilderActive,
            isBetbuilderIconClicked: true,
            isOpen: true,
        });
    }

    return (
        <div
            className={classNames('betbuilder-ico', { active: isOpen && isBetbuilderActive })}
            title="Bet builder"
            onClick={toggleBetBuilder}
        >
            <Svg icon="betbuilder" width={isRetailLayout ? 2.2 : 2} />
        </div>
    );
}
