import { getStoreValue } from '../stores/store/utils';
import { LocalStorage, storageGet, storageRemove, storageSet } from './storage';
import { ClientName } from './utils/types';
import { Environment } from '../stores/environment/types';
import { environment } from '../stores/environment/environment';

window.tempEnvironmentVariables;

export const ENVIRONMENT_PARAMS = {
    IS_BYOD: 'IS_BYOD',
    IS_RETAIL: 'IS_RETAIL',
    CLIENT_NAME: 'CLIENT_NAME',
};

const overwriteClientName = {
    'ivc-ms': ClientName.IVC,
};

async function fetchEnvironment(): Promise<Environment> {
    return fetch(`${window.coolb2b?.baseUrl || ''}/environment.json`).then((response) => response.json());
}

export async function loadEnvironment() {
    let environmentVariables;

    if (window.tempEnvironmentVariables) {
        environmentVariables = window.tempEnvironmentVariables;
        delete window.tempEnvironmentVariables;
    } else {
        environmentVariables = await fetchEnvironment();
    }

    environmentVariables.CLIENT_NAME =
        overwriteClientName[environmentVariables.CLIENT_NAME] || environmentVariables.CLIENT_NAME;
    for (const environmentParam of Object.keys(ENVIRONMENT_PARAMS)) {
        if (storageGet(environmentParam)) {
            environmentVariables[environmentParam] = String(storageGet(environmentParam));
        }
    }

    environment.set(environmentVariables);
}

export function resetEnvironment() {
    for (const environmentParam of Object.keys(ENVIRONMENT_PARAMS)) {
        if (storageGet(environmentParam)) {
            storageRemove(environmentParam);
        }
    }
}

export function saveAvailableLicenes() {
    const storedLicense = storageGet<string>(LocalStorage.AVAILABLE_LICENCES);

    if (storedLicense) {
        return;
    }

    const environmentLicense = getStoreValue(environment).AVAILABLE_LICENSES;
    if (environmentLicense && localStorage) {
        storageSet(LocalStorage.AVAILABLE_LICENCES, environmentLicense.join(','));
    }
}

export function isProd(): boolean {
    // 'prod' when deploying to prod, 'production' when running locally against prod
    return ['prod', 'production'].includes(getStoreValue(environment).ENVIRONMENT);
}

export function isStage(): boolean {
    return getStoreValue(environment).ENVIRONMENT === 'stage';
}

export function isDevelopment() {
    return getStoreValue(environment).ENVIRONMENT === 'dev';
}

export function isLocalDevelopment() {
    return (
        window.location.hostname === 'localhost' ||
        window.location.hostname.startsWith('10.') ||
        window.location.hostname.startsWith('192.168.') ||
        window.location.hostname.startsWith('172.16.')
    );
}

export function isBYOD(): boolean {
    return getStoreValue(environment).IS_BYOD === 'true' || getStoreValue(environment).isBYOD === 'true';
}

export function isRetail(): boolean {
    return getStoreValue(environment).IS_RETAIL === 'true' || getStoreValue(environment).isRetail === 'true';
}

export function getClient(): typeof ClientName[keyof typeof ClientName] {
    return getClientWithoutFallback() || ClientName.COOLBET;
}

export function getClientWithoutFallback(): typeof ClientName[keyof typeof ClientName] {
    return getStoreValue(environment).CLIENT_NAME;
}

export function isB2B(): boolean {
    return getClient() !== ClientName.COOLBET;
}
