import React, { useState } from 'react';
import { stores } from '../../../../stores';
import UiAlert from '../../../ui/alert/UiAlert';
import { translate } from '../../../../services/translate';
import UiFormInput from '../../../ui/form/input/UiFormInput';
import { getRoute, useRouter } from '../../../../services/router';
import { finishLoginWithToken, getErrorMessage } from '../../../../services/two-factor';
import { sendTwoFactorEmailPin, sendTwoFactorCode } from '../../../../microservices/auth';
import { verifyTwoFactorPin } from '../../../../microservices/auth';
import { useForm } from '../../../../services/form';
import UiForm from '../../../ui/form/UiForm';
import { validators } from '../../../../services/validators';
import UiButton from '../../../ui/button/UiButton';
import { isDefaultEmailRegistrationCountry } from '../../../../services/auth';
import Wrapper from '../../verification/styles';
import UiCountdown from '../../../ui/countdown/UiCountdown';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    onSuccess?: () => void;
}

export default function AuthTwoFactorEmail({ onSuccess }: Props) {
    const { navigateTo } = useRouter();
    if (!onSuccess) {
        onSuccess = () => {
            navigateTo(getRoute('sport.recommendations'));
        };
    }

    const [error, setError] = useState('');
    const [isVerifying, setIsVerifying] = useState(false);
    const [isResending, setIsResending] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(true);

    const [isSendCodeToSmsButtonVisible, setIsSendCodeToSmsButtonVisible] = useState(false);
    const [email] = useStore(stores.twoFactor.email);
    const [country] = useStore(stores.twoFactor.country);
    const [reason] = useStore(stores.twoFactor.reason);
    const [smsResendFailedReason] = useStore(stores.twoFactor.smsResendFailedReason);
    const form = useForm({ receivedEmailPin: '' });
    const [timeoutEndTime, setTimeoutEndTime] = useState<number>();
    const [isSuccess, setIsSuccess] = useState(true);

    async function tryVerifyTwoFactorEmailPin(isFormValid) {
        setError('');
        setIsSuccess(false);

        if (!isFormValid) {
            return;
        }

        setIsVerifying(true);

        try {
            const { receivedEmailPin } = form.getValues();
            const { token } = await verifyTwoFactorPin({ pin: String(receivedEmailPin), email });
            finishLoginWithToken(token);
            setIsVerifying(false);
            stores.twoFactor.isTwoFactorEmailActive.set(false);
            stores.modals.isLoginModalOpen.set(false);
            onSuccess && onSuccess();
        } catch (error: any) {
            error.message = error.code;
            setError(getErrorMessage(error));
            setIsVerifying(false);
        }
    }

    async function tryResendTwoFactorEmailPin() {
        setIsResending(true);
        setError('');
        setIsSuccess(false);

        try {
            await sendTwoFactorEmailPin(email);
            setIsEmailSent(true);
            setIsSuccess(true);
        } catch (error: any) {
            setIsEmailSent(false);
            error.message = error.code;
            setError(getErrorMessage(error));
        }

        form.setInputValue({ receivedEmailPin: '' });
        setIsResending(false);
        setIsSendCodeToSmsButtonVisible(true);
        addTimeoutForSubmit();
    }

    async function startSmsVerification() {
        setIsResending(true);
        setError('');
        setIsSuccess(false);
        try {
            email && (await sendTwoFactorCode(email));
            setIsResending(false);
            setIsSuccess(true);
        } catch (error) {
            setError(getErrorMessage(error));
            setIsResending(false);
        }
        addTimeoutForSubmit();
    }

    function addTimeoutForSubmit() {
        const resendTimeoutInSeconds = 30;
        setTimeoutEndTime(new Date().setSeconds(new Date().getSeconds() + resendTimeoutInSeconds));
        setTimeout(() => {
            setTimeoutEndTime(undefined);
        }, resendTimeoutInSeconds * 1000);
    }

    return (
        <Wrapper>
            <div>
                {smsResendFailedReason && (
                    <UiAlert warning>{translate(smsResendFailedReason, 'ui.registration')}</UiAlert>
                )}

                {isEmailSent && isSuccess && (
                    <UiAlert success>
                        <strong>{translate('Verification Email sent', 'ui.common')}</strong>{' '}
                        {reason && translate(reason, 'ui.registration')}
                    </UiAlert>
                )}

                {error && <UiAlert failure>{error}</UiAlert>}

                <UiForm onSubmit={tryVerifyTwoFactorEmailPin} isLoading={isVerifying}>
                    <UiFormInput
                        {...form.receivedEmailPin}
                        validator={validators.emailPin}
                        type="tel"
                        placeholder={translate('Enter PIN here', 'ui.gdpr')}
                        maxLength={6}
                    />

                    <UiButton color="primary" block isFormSubmitButton isLoading={isVerifying}>
                        {translate('Verify & Login', 'ui.registration')}
                    </UiButton>
                </UiForm>

                {isEmailSent && (
                    <UiButton
                        block
                        onClick={tryResendTwoFactorEmailPin}
                        isLoading={isResending}
                        disabled={Boolean(timeoutEndTime)}
                    >
                        {translate('Resend Email', 'ui.common')} {}
                        {timeoutEndTime && (
                            <UiCountdown endDate={timeoutEndTime} className="submit-timer">
                                {({ seconds }) => <>({seconds})</>}
                            </UiCountdown>
                        )}
                    </UiButton>
                )}

                {isSendCodeToSmsButtonVisible && isDefaultEmailRegistrationCountry(country) && (
                    <UiButton
                        block
                        onClick={startSmsVerification}
                        isLoading={isResending}
                        disabled={Boolean(timeoutEndTime)}
                    >
                        {translate('Send SMS', 'ui.common')} {}
                        {timeoutEndTime && (
                            <UiCountdown endDate={timeoutEndTime} className="submit-timer">
                                {({ seconds }) => <>({seconds})</>}
                            </UiCountdown>
                        )}
                    </UiButton>
                )}
            </div>
        </Wrapper>
    );
}
