import React from 'react';
import AccountMenu from '../menu/AccountMenu';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

interface Props {
    open: boolean;
    onClose: () => void;
    onOpen: () => void;
    position: 'left' | 'right';
}

export default function AccountMobileSidebar({ open, onClose, onOpen, position }: Props) {
    return (
        <>
            <SwipeableDrawer
                disableEnforceFocus
                open={open}
                onOpen={onOpen}
                onClose={onClose}
                anchor={position}
                disableDiscovery={true}
            >
                <AccountMenu />
            </SwipeableDrawer>
        </>
    );
}
