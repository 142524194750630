import { getServiceUrl, httpGet } from '../services/api';
import { COUNTRY } from '../services/country';
import { Province } from '../services/types';

const getUrl = (url) => getServiceUrl('ip', url);

export function fetchIpDetails() {
    return httpGet<IpDetails>(getUrl('details'));
}

type IpDetails = {
    as: string;
    city: string;
    country: string;
    countryCode: COUNTRY;
    isOfficeIP: boolean;
    isp: string;
    lat: number;
    lon: number;
    org: string;
    query: string;
    region: string;
    regionName: Province;
    status: string;
    timezone: string;
    zip: string;
};
