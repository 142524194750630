import styled, { css } from 'styled-components';

type StyledProps = {
    $paddingTop: number;
};
export default styled.div<StyledProps>`
    ${({ $paddingTop }) =>
        css`
            position: relative;
            height: 0;
            width: 100%;

            padding-top: ${$paddingTop}%;

            .ratio-box-content {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        `}
`;
