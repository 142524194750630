import styled, { css } from 'styled-components';

const transformStyles = ({ duration, delay, display }) =>
    css`
        transition-duration: ${duration}ms;
        transition-delay: ${delay}ms;
        transform: translate(0, ${display}em);
    `;

export default styled.div`
    ${transformStyles}

    .number {
        height: 1em;
        color: var(--kiosk-header-color-primary);
        text-align: center;
    }
`;
