import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { ComboCardMatch } from '@staycool/sports-types/combo-card';
import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';

interface Props {
    card: FoComboCardWithOdds;
    isSingleMatchCard?: boolean;
}

export default function ComboCardsItemDetails({ card, isSingleMatchCard = false }: Props) {
    const maximumNumberOfMarkets = 3;
    const numberOfTotalMarkets = card.matches.flatMap(({ markets }) => markets).length;
    const [matchesToDisplay, setMatchesToDisplay] = useState<ComboCardMatch[]>([]);
    const [isShowingAllMarkets, setIsShowingAllMarkets] = useState<boolean>(false);
    const toggleIsShowingAllMarkets = () => setIsShowingAllMarkets(!isShowingAllMarkets);

    useEffect(() => {
        const { matches } = card;
        if (isSingleMatchCard && matches[0].markets.length <= maximumNumberOfMarkets) {
            setMatchesToDisplay(matches);
            return;
        }
        const updatedMatches: ComboCardMatch[] = [];
        matches.forEach((match) => {
            const numberOfTotalMarkets = updatedMatches.flatMap((match) => match.markets).length;
            if (numberOfTotalMarkets >= maximumNumberOfMarkets) {
                return;
            }
            if (match.markets.length <= maximumNumberOfMarkets) {
                updatedMatches.push({
                    ...match,
                    markets: match.markets.slice(0, maximumNumberOfMarkets - numberOfTotalMarkets),
                });
                return;
            }
        });
        setMatchesToDisplay(updatedMatches);
    }, [card]);

    return (
        <Wrapper>
            {(isShowingAllMarkets ? card.matches : matchesToDisplay).map(({ id, markets }) => (
                <div className="combo-selection" key={id}>
                    {markets.map(({ id, outcome_name }) => (
                        <div className="selection-market" key={id}>
                            {outcome_name}
                        </div>
                    ))}
                </div>
            ))}
            {numberOfTotalMarkets > maximumNumberOfMarkets && (
                <span onClick={toggleIsShowingAllMarkets} className="show-all-text">
                    {isShowingAllMarkets
                        ? 'Show less'
                        : `Show all ... [ ${numberOfTotalMarkets - maximumNumberOfMarkets} ]`}
                </span>
            )}
        </Wrapper>
    );
}
