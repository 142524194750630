import React, { useEffect } from 'react';
import Wrapper from './styles';
import CasinoPaybackBoosterThumbnail from '../../casino/payback-booster/thumbnail/CasinoPaybackBoosterThumbnail';
import { stores } from '../../../stores';
import { useStore } from '../../../hooks/useStore';

export default function CmsCasinoPaybackBooster() {
    const [, setIsPaybackBoosterCmsView] = useStore(stores.casino.isPaybackBoosterCmsView);

    useEffect(() => {
        setIsPaybackBoosterCmsView(true);
    }, []);

    return (
        <Wrapper>
            <CasinoPaybackBoosterThumbnail />
        </Wrapper>
    );
}
