import styled from 'styled-components';

export default styled.div`
    background-color: var(--surface-level-1-bg);
    border-radius: 10px;
    padding: 0 5px;
    border: 1px solid #494c54;
    color: var(--font-color-primary);
    height: 16px;
    top: 0px;
    right: 5px;
    margin-left: auto;
    .dot-container {
        display: flex;
        height: 14px;
        align-items: center;
        justify-content: center;
        gap: 3px;

        &.low span:nth-child(1) {
            background-color: ${({ theme }) => theme.colors.green};
        }

        &.medium span:nth-child(-n + 2) {
            background-color: var(--color-info);
        }

        &.high span:nth-child(-n + 3) {
            background-color: #b42fcc;
        }
    }

    .dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--surface-level-3-bg);
    }
`;
