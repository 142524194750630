import { orange } from '@mui/material/colors';

export const darkTheme = {
    palette: {
        mode: 'dark',
        primary: orange,
        background: {
            default: 'var(--surface-level-1-bg)',
            paper: 'var(--surface-level-1-bg);',
        },
    },
    component: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    root: {
                        backgroundColor: '#000000',
                    },
                },
                popper: {
                    root: {
                        opacity: 1,
                    },
                },
            },
        },
    },
};
