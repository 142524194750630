import React, { useEffect, useState } from 'react';
import UiAlert from '../../../ui/alert/UiAlert';
import Wrapper from './styles';
import { stores, useStoreWithSelector } from '../../../../stores';
import { translate } from '../../../../services/translate';
import SportTicketDetails from '../../ticket/details/SportTicketDetails';
import { initialBetSlipPlacingState } from '../../../../services/sports/constants';
import { getTicketDetailsByTickedId } from '../../../../microservices/sbgate';
import { logger } from '../../../../services/logger';
import { isMobile } from '../../../../services/browser';
import SportBetslipRecommendation from '../recommendation/SportBetslipRecommendation';
import { postBettingContext } from '../../../../microservices/analytics';
import { getTicketsToPrint } from '../../../../microservices/retail-middleware';
import UiButton from '../../../ui/button/UiButton';
import { isRetail } from '../../../../services/environment';
import { TicketDetails } from '../../../../services/sports/types';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import { DATE_YEAR_TIME_FORMAT, getFormattedDate } from '../../../../services/date';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    marketId: string;
}

function SportBetslipReceipt({ marketId }: Props) {
    const BETTING_CONTEXTS = {
        UPSELL: 'upsell',
    };
    const [ticketId] = useStoreWithSelector(stores.sports.betSlipPlacingState, (state) => state.receiptById[marketId]);
    const [ticketResponse, setTicketResponse] = useState<TicketDetails>();
    const [upsellRecommendation] = useStore(stores.marketing.upsellRecommendation);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const isRetailLayout = isRetail();

    useEffect(() => {
        if (ticketId) {
            isRetailLayout ? loadRetailTicketsDetails(ticketId) : loadTicketDetails(ticketId);
        }
    }, [ticketId]);

    useEffect(() => {
        if (isAuthenticated || isRetail()) {
            return;
        }
        removeReceipt();
    }, [isAuthenticated]);

    async function loadTicketDetails(ticketId: string) {
        try {
            const response = await getTicketDetailsByTickedId(ticketId);
            sendRecommendedTicketTracking(response);
            setTicketResponse(response);
        } catch (error) {
            logger.error('SportBetslipReceipt', 'loadTicketDetails', error);
        }
    }

    async function loadRetailTicketsDetails(ticketId: string) {
        try {
            const tickets = await getTicketsToPrint();
            if (tickets.length) {
                const ticket = tickets.find((ticket) => ticket.betsTicket?.ticket.id === ticketId);
                const betsTicket = ticket?.betsTicket;
                if (betsTicket) {
                    sendRecommendedTicketTracking(betsTicket as any);
                    setTicketResponse(betsTicket as any);
                }
                stores.sports.ticketsToPrint.set(tickets);
            }
        } catch (error) {
            logger.error('SportBetslipReceipt', 'loadRetailTicketsDetails', error);
        }
    }

    function sendRecommendedTicketTracking(ticketDetails: TicketDetails) {
        const { marketId, matchId } = upsellRecommendation;
        ticketDetails.uniqueSelections.forEach((selection) => {
            if (selection.market_id === marketId && selection.match_id === matchId) {
                isFeatureAvailable(FEATURE.TICKET_TRACKING) &&
                    postBettingContext({ ticket_id: ticketDetails.ticket.id, context: BETTING_CONTEXTS.UPSELL });
                stores.marketing.upsellRecommendation.set((state) => ({
                    ...state,
                    marketId: undefined,
                    matchId: undefined,
                }));
            }
        });
    }

    function removeReceipt() {
        stores.sports.betSlipPlacingState.set(initialBetSlipPlacingState);
        if (isMobile()) {
            stores.isBetslipOpen.set(false);
        }
    }

    if (!ticketResponse) {
        return null;
    }

    const isManualAcceptance = ticketResponse.ticket.ma_status;
    return (
        <Wrapper>
            <UiButton block onClick={removeReceipt} className="remove-receipt">
                {translate('Close', 'ui.common')}
            </UiButton>

            {isManualAcceptance && (
                <UiAlert warning>
                    {translate('Your bet request is sent to Manual Acceptance!', 'ui.betslip')}{' '}
                    {translate('Stake', 'ui.betslip')}{' '}
                    {formattedAmountWithCurrency(ticketResponse.ticket.ma_stake_requested)}.
                </UiAlert>
            )}

            <p>
                {translate('Bet ID:', 'ui.betslip')} {ticketResponse.ticket.display_id} /{' '}
                <span>
                    {getFormattedDate({
                        date: ticketResponse.ticket.created_at,
                        format: DATE_YEAR_TIME_FORMAT,
                        useDots: true,
                    })}
                </span>
            </p>

            <>
                {Boolean(ticketResponse.ticket.total_stake) && (
                    <UiAlert center success>
                        {translate('Your bet is placed. Good luck!', 'ui.betslip')}
                    </UiAlert>
                )}

                <div className="betslip-receipt">
                    <SportTicketDetails ticket={ticketResponse} isCompactView onTicketClosed={removeReceipt} />
                </div>

                {!isNaN(+marketId) && <SportBetslipRecommendation marketId={marketId as number | 'null'} />}
            </>
        </Wrapper>
    );
}

export default SportBetslipReceipt;
