import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { requestResetPassword } from '../../../../microservices/auth';
import { translate } from '../../../../services/translate';
import Svg from '../../../svg/Svg';
import UiGroup from '../../../ui/group/UiGroup';
import Wrapper from './styles';
import UiSubHeading from '../../../ui/sub-heading/UiSubHeading';
import { logger } from '../../../../services/logger';
import UiButton from '../../../ui/button/UiButton';
import Ui2Form from '../../../ui-2/form/Ui2Form';
import { getFieldValidators } from '../../../../services/validators';
import UiFormGroup from '../../../ui/form/group/UiFormGroup';
import Ui2FormTextInput from '../../../ui-2/form/text-input/Ui2FormTextInput';

interface Props {
    onClose: () => void;
    useFormMethods: any;
}

export default function AuthLoginForgotPassword({ onClose, useFormMethods }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isResetPasswordSuccess, setIsResetPasswordSuccess] = useState(false);
    const { formState } = useFormMethods;

    async function resetPassword() {
        setIsLoading(true);
        const { email } = useFormMethods.getValues();
        try {
            await requestResetPassword(email);
            setIsResetPasswordSuccess(true);
            toast.success(translate('Password reset email sent!', 'ui.account'));
        } catch (error) {
            logger.error('AuthLoginForgotPassword', 'resetPassword', error);
            toast.error(translate('Failed to reset password!', 'ui.account'));
        }

        setIsLoading(false);
    }

    return (
        <Wrapper>
            {!isResetPasswordSuccess && (
                <Ui2Form onSubmit={resetPassword} useFormMethods={useFormMethods}>
                    <UiFormGroup>
                        <Ui2FormTextInput
                            dataTest="input-email"
                            disabled={isLoading}
                            error={formState.errors.email}
                            name="email"
                            placeholder={translate('Email', 'ui.account')}
                            type="email"
                            validator={getFieldValidators(['email'])}
                        />
                    </UiFormGroup>

                    <UiGroup vertical>
                        <UiButton color="primary" block isFormSubmitButton isLoading={isLoading}>
                            {translate('Reset password', 'ui.common')}
                        </UiButton>

                        <UiButton onClick={onClose} block>
                            {translate('Back', 'ui.registration')}
                        </UiButton>
                    </UiGroup>
                </Ui2Form>
            )}

            {isResetPasswordSuccess && (
                <div>
                    <UiSubHeading>{translate('Password reset email successfully sent to', 'ui.account')}</UiSubHeading>

                    <UiGroup className="user-email">
                        <Svg icon="checkmark" className="icon-wrapper" />
                        <span>{useFormMethods.getValues().email}</span>
                    </UiGroup>

                    <p>{translate('Click the link in the email to reset your password', 'ui.common')}</p>

                    <UiButton color="primary" block onClick={onClose}>
                        {translate('OK', 'ui.common')}
                    </UiButton>
                </div>
            )}
        </Wrapper>
    );
}
