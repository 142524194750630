import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    isCasinoLocked: boolean;
    isSmallWidth: boolean;
};
export default styled.div<StyledProps>`
    > div {
        display: flex;
        justify-content: ${({ isSmallWidth }) => (isSmallWidth ? 'center' : 'right')};
    }
    color: ${({ isCasinoLocked }) => !isCasinoLocked && `${({ theme }) => theme.paybackBooster.primaryColor}`};
    font-size: var(--font-12);
    font-weight: var(--font-weight-medium);

    letter-spacing: 1px;
    padding: 0.2rem ${({ theme }) => theme.layout.gutter};
    justify-content: center;

    .booster-time-unit {
        margin: 0 3px;
        font-size: var(--font-12);
    }

    @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
        font-size: 1rem;
        padding: 0.45rem ${({ theme }) => theme.layout.gutter};

        .booster-time-left-title {
            font-size: var(--font-12);
        }
    }
`;
