import styled, { css } from 'styled-components';

export default styled.div`
    position: relative;

    a,
    img {
        user-select: none;
        -webkit-user-select: none;
        -webkit-user-drag: none;
        -webkit-app-region: no-drag;
    }

    .hot-events-container {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        overflow: auto;
        min-height: 500px;

        &::-webkit-scrollbar {
            display: none;
        }
        > * {
            margin-right: 0.8rem;
        }

        > * {
            animation: fadeInLeft 800ms cubic-bezier(0.86, 0, 0.07, 1);
            animation-fill-mode: both;
            padding-bottom: 3px;
        }
        ${[1, 2, 3, 4, 5].map(
            (i) => css`
                > *:nth-child(${i}) {
                    animation-delay: ${i * 100}ms;
                }
            `,
        )}
    }
`;
