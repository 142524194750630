import { getServiceUrl, httpGet } from '../services/api';

const getUrl = (url) => getServiceUrl('warehouse-api', url);

export async function getMyStats(params = {}): Promise<MyStats> {
    try {
        const url = getUrl('my-stats');
        return await httpGet(url, params);
    } catch (error) {
        return {
            betsUc: 0,
            depositsCount: 0,
            depositsUc: 0,
            casinoBetsUc: 0,
            casinoWinsUc: 0,
            pokerGgrEur: 0,
            pokerPlayerRakeUc: 0,
            sportsbookBetsUc: 0,
            sportsbookPendingBetsUc: 0,
            sportsbookWinsUc: 0,
            virtualSportsBetsUc: 0,
            virtualSportsWinsUc: 0,
            winsUc: 0,
            withdrawalsCount: 0,
            withdrawalsUc: 0,
        };
    }
}

export type MyStats = {
    betsUc: number;
    depositsCount: number;
    depositsUc: number;
    casinoBetsUc: number;
    casinoWinsUc: number;
    pokerGgrEur: number;
    pokerPlayerRakeUc: number;
    sportsbookBetsUc: number;
    sportsbookPendingBetsUc: number;
    sportsbookWinsUc: number;
    virtualSportsBetsUc: number;
    virtualSportsWinsUc: number;
    winsUc: number;
    withdrawalsCount: number;
    withdrawalsUc: number;
};
