import React from 'react';
import UiModal from '../../ui/modal/UiModal';
import UiBox from '../../ui/box/UiBox';
import UiCloseButton from '../../ui/close-button/UiCloseButton';

import Wrapper from './styles';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

interface Props {
    positionId: string;
    positionTitle: string;
    applicationURL: string;
    isOpen: boolean;
    onClose: () => unknown;
}

export default function CareerApply({ positionId, positionTitle, applicationURL, isOpen, onClose = () => {} }: Props) {
    const [{ deviceType }] = useStore(media);

    const minWidthByDevice = {
        isPhone: '400px',
        isTablet: '800px',
        isLaptop: '1000px',
        isDesktop: '1000px',
    };

    const maxWidthByDevice = {
        isPhone: '760px',
        isTablet: '1000px',
        isLaptop: '1000px',
        isDesktop: '1000px',
    };

    return (
        <UiModal
            open={isOpen}
            maxWidth={maxWidthByDevice[deviceType]}
            minWidth={minWidthByDevice[deviceType]}
            onClose={() => onClose()}
        >
            <Wrapper>
                <UiBox className="container" isCompactView>
                    <UiCloseButton onClick={() => onClose()} className="close-button" />
                    <iframe
                        id={`position-${positionId}`}
                        title={positionTitle}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        src={applicationURL}
                    />
                </UiBox>
            </Wrapper>
        </UiModal>
    );
}
