import React, { useRef, useState } from 'react';
import { GeoComplyMobileAppRequiredHint } from '../../../../../../services/geocomply/mobile';
import { translate } from '../../../../../../services/translate';
import UiButton from '../../../../../ui/button/UiButton';
import UiGroup from '../../../../../ui/group/UiGroup';
import GeocomplyRetryGeolocationButton from '../../../../retry-geolocation-button/GeocomplyRetryGeolocationButton';
import GeocomplyMobileAppPinger from '../../../app-pinger/GeocomplyMobileAppPinger';

interface Props {
    hint: GeoComplyMobileAppRequiredHint;
}

export default function GeocomplyMobileHintHandlerAppRequired({ hint }: Props) {
    const appPingerRef = useRef(null);
    const [isAppOpened, setIsAppOpened] = useState(false);
    const [isAppPingerRunning, setIsAppPingerRunning] = useState(false);
    const [hasAttemptedToOpenApp, setHasAttemptedToOpenApp] = useState(false);

    function openApp() {
        const url = hint.wakeupAppIntentURI;
        window.open(url, '_blank');

        setTimeout(() => {
            setIsAppPingerRunning(true);
            setHasAttemptedToOpenApp(true);
            (appPingerRef.current as any)?.startPinger();
        }, 2000);
    }

    return (
        <UiGroup vertical>
            {isAppOpened && <GeocomplyRetryGeolocationButton />}

            {!isAppOpened && !isAppPingerRunning && (
                <UiButton onClick={openApp}>
                    {hasAttemptedToOpenApp
                        ? translate('Retry opening the App', 'ui.geocomply')
                        : translate('Open the App', 'ui.geocomply')}
                </UiButton>
            )}

            <GeocomplyMobileAppPinger
                ref={appPingerRef}
                onAppOpenSuccess={() => {
                    setIsAppOpened(true);
                    setIsAppPingerRunning(false);
                }}
                onAppOpenFailure={() => {
                    setIsAppOpened(false);
                    setIsAppPingerRunning(false);
                }}
            />
        </UiGroup>
    );
}
