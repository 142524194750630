import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

type StyledProps = {
    simplifyHeader?: boolean;
    isTwoToneTheme?: boolean;
    isRulesPage?: boolean;
};

export default styled.div<StyledProps>`
    position: ${({ simplifyHeader, isRulesPage }) => (!simplifyHeader || isRulesPage ? 'sticky' : undefined)};
    top: 0;
    z-index: 6;
    color: var(--kiosk-header-color-primary);

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: var(--kiosk-header-bg);
    border-bottom: 2px solid var(--kiosk-header-border-color);

    height: ${({ simplifyHeader }) => (simplifyHeader ? '60px' : '150px')};

    padding: 1.6rem 0.8rem 1.6rem 1.6rem;
    @media ${mediaQuery.isPhone} {
        padding: var(--spacing-12);
    }

    a,
    img {
        user-select: none;
        -webkit-user-select: none;
        -webkit-user-drag: none;
        -webkit-app-region: no-drag;
    }

    @media ${mediaQuery.isTablet} {
        padding: 2rem;
    }

    .secondary-button {
        background-color: ${({ isTwoToneTheme }) => (isTwoToneTheme ? 'var(--kiosk-header-button-bg)' : '')};
        border: ${({ isTwoToneTheme }) => (isTwoToneTheme ? 'var(--kiosk-header-button-border)' : '')};
    }

    .header-info {
        display: flex;
        align-items: center;
        > *:not(:last-child) {
            margin-right: 1rem;
        }
    }

    .header-controls {
        display: flex;
        align-items: center;

        margin-left: auto;

        > *:not(:last-child) {
            margin-right: 2rem;
        }
    }
    .header-logo {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: auto;
        .kiosk-id {
            position: absolute;
            top: 100%;
            margin-top: 0.2rem;
            font-size: 0.6rem;
            color: var(--font-color-subtle);
            text-align: right;
            text-transform: uppercase;
            letter-spacing: 0.5px;
        }
    }
    .header-loyalty {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        min-width: 100px;
        .loyalty-icon {
            display: flex;
            align-items: flex-end;
            > *:not(:last-of-type) {
                margin-right: 0.4rem;
            }
        }
        .loyalty-label {
            margin-bottom: 5px;
            position: absolute;
            bottom: 100%;
            font-size: 0.6rem;
            text-align: left;
            text-transform: uppercase;
            letter-spacing: 0.5px;
        }
        .loyalty-amount {
            position: relative;
            top: 2px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            width: 100%;

            font-size: 2rem;
            color: var(--kiosk-header-loyalty-color);
            font-weight: var(--font-weight-heavy);
            ${({ theme }) => theme.optimizeFontRendering};
        }
        svg {
            margin-bottom: 0.5rem;
        }
    }
    .balance {
        min-width: 200px;
        display: flex;
        align-items: center;
        .toggle-balance {
            display: flex;
            align-items: center;
            flex-direction: column;
            font-size: 12px;
            font-weight: var(--button-font-weight);
            text-transform: uppercase;
            ${({ theme }) => theme.optimizeFontRendering};
        }

        .header-balance {
            position: relative;
            margin: 0 1rem;

            .balance-amount {
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                font-size: 2rem;
                color: var(--kiosk-header-color-primary);
                font-weight: var(--font-weight-heavy);
                ${({ theme }) => theme.optimizeFontRendering};

                animation: neon 0.08s ease-in-out 10;
                > *:not(:last-of-type) {
                    margin-right: 0.4rem;
                }

                .amount {
                    font-size: 2.25rem;
                }
            }
            .balance-label {
                position: absolute;
                bottom: 100%;
                font-size: 0.6rem;
                text-align: left;
                text-transform: uppercase;
                white-space: nowrap;
                letter-spacing: 0.5px;
                margin-bottom: var(--spacing-4);
            }

            .balance-update-bubble {
                display: none;
                opacity: 0;

                position: absolute;
                top: 0;
                left: 100%;
                margin-left: 0.4rem;

                animation: animateBubble 900ms linear infinite;

                font-weight: var(--font-weight-heavy);
                ${({ theme }) => theme.optimizeFontRendering};
                white-space: nowrap;
                &.active {
                    display: block;
                }
                &.positive {
                    color: var(--betslip-stake-label-color);
                }
            }
        }
    }

    .header-print {
        display: flex;
        align-items: center;
        > *:not(:last-of-type) {
            margin-right: 0.4rem;
        }
    }

    .header-tickets-link {
        position: relative;
        background: var(--kiosk-header-bg);
        border-radius: 100px;
        padding: 1rem;
        a {
            color: var(--kiosk-header-color-primary);
        }
        svg {
            width: 2rem;
            height: 2rem;
        }
        &::after {
            opacity: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            height: 100%;
            width: 100%;
            border: 12px solid var(--mobile-nav-tab-badge-bg);
            border-radius: 100%;
            animation-name: ripple;
            animation-duration: 3s;
            animation-delay: 0s;
            animation-iteration-count: infinite;
            animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
            z-index: -1;
        }

        &::before {
            opacity: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            height: 100%;
            width: 100%;
            border: 8px solid var(--mobile-nav-tab-badge-bg);
            border-radius: 100%;
            animation-name: ripple;
            animation-duration: 3s;
            animation-delay: 0.5s;
            animation-iteration-count: infinite;
            animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
            z-index: -1;
        }

        .badge {
            display: flex;
            align-items: center;
            justify-content: center;

            position: absolute;
            top: -1rem;

            transform: translate(50%, 0);
            height: 2rem;
            width: 2rem;

            background-color: var(--mobile-nav-tab-badge-bg);
            border-radius: 100px;

            color: var(--mobile-nav-tab-badge-text-color);
            font-size: 1rem;
        }
    }

    .kiosk-id {
        white-space: nowrap;
    }

    @keyframes ripple {
        from {
            opacity: 1;
            transform: scale3d(0.75, 0.75, 1);
        }

        to {
            opacity: 0;
            transform: scale3d(1.5, 1.5, 1);
        }
    }

    @keyframes animateBubble {
        0% {
            transform: translate(0, 0);
            opacity: 1;
        }
        100% {
            transform: translate(0, -250%);
            opacity: 0;
        }
    }
`;
