import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import classNames from 'classnames';
import { CasinoGameType, CRAZY_TIME_FEATURE } from '../../../../services/casino/games';
import { stores } from '../../../../stores';
import CasinoThumbnailLatestWinningsCrazyTimeResults from './crazy-time-results/CasinoThumbnailLatestWinningsCrazyTimeResults';
import CasinoThumbnailLatestWinningsResults from './results/CasinoThumbnailLatestWinningsResults';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    latestWinnings: string[];
    gameType?: typeof CasinoGameType[keyof typeof CasinoGameType];
    gameId: number;
    gameProperties: number[];
}

export default function CasinoThumbnailLatestWinnings({ latestWinnings, gameType, gameId, gameProperties }: Props) {
    const isDoubleBallRoulette = latestWinnings.length ? latestWinnings[0].includes('|') : false;
    const [features] = useStore(stores.casino.features);
    const [isCrazyTime, setIsCrazyTime] = useState(false);

    useEffect(() => {
        const crazyTimeFeature = features.find((feature) => feature.name === CRAZY_TIME_FEATURE);
        if (crazyTimeFeature && gameType === CasinoGameType.GAME_SHOW && gameProperties.includes(crazyTimeFeature.id)) {
            setIsCrazyTime(true);
        } else {
            setIsCrazyTime(false);
        }
    }, [gameId, features]);

    return (
        <Wrapper>
            {latestWinnings.length > 0 && (
                <div
                    className={classNames('container', {
                        'double-ball-roulette': isDoubleBallRoulette,
                    })}
                >
                    {latestWinnings.map((item, index) => {
                        return (
                            <div
                                className={classNames('item', {
                                    'item-double-ball-roulette': isDoubleBallRoulette,
                                })}
                                key={index}
                            >
                                {isCrazyTime ? (
                                    <CasinoThumbnailLatestWinningsCrazyTimeResults item={item} />
                                ) : (
                                    <CasinoThumbnailLatestWinningsResults
                                        item={item}
                                        latestWinnings={latestWinnings}
                                        gameType={gameType}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            )}
        </Wrapper>
    );
}
