import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    height: 40px;
    position: relative;
    z-index: 100;
    margin-bottom: 0.8rem;

    &:focus-within {
        .rtp-calculator-game-selection-search-input {
            color: var(--font-color-primary);
            padding-left: 55px;
        }
    }

    &.focused,
    &.game-selected {
        .rtp-calculator-game-selection-search-container,
        .rtp-calculator-game-selection-search-cross-wrapper,
        .rtp-calculator-game-selection-search-cross-container {
            opacity: 1;
            z-index: 3;
        }
    }

    &.game-selected {
        .rtp-calculator-game-selection-search-input-container {
            opacity: 0;
            height: 0px;
        }
    }
    .rtp-calculator-game-selection-search-container {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        z-index: 10;
        position: relative;
        transition: background-color 0.2s, border-radius 0.2s;
        height: 40px;
        border: 1px solid #43444e;
        background-color: #3c3d46;
        box-shadow: -3px 6px 6px -3px rgba(0, 0, 0, 0.35);
        border-radius: var(--ui-radius);

        &-wrapper {
            width: 100%;
            z-index: 2;
        }

        .rtp-calculator-game-selection-search-cross-wrapper {
            position: relative;
            width: 0;
            flex: 0 0 0;
            height: 30px;

            .rtp-calculator-game-selection-search-cross-container {
                height: 30px;
                width: 30px;
                cursor: pointer;
                padding: 7px;
                margin-right: 5px;
                position: absolute;
                right: 0;
                z-index: 1;
                opacity: 0;
                transition: opacity 0.2s;
                color: var(--font-color-subtle);
            }
        }
    }

    .rtp-calculator-game-selection-search-icon {
        height: 40px;
        width: 40px;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        padding: 10px;
        margin-left: 5px;
        color: var(--color-highlight);
        transition: color 0.2s;
    }

    .rtp-calculator-game-selection-search-input-selected,
    .rtp-calculator-game-selection-search-input {
        width: 100%;
        height: 100%;
        padding-right: 40px;
    }

    .rtp-calculator-game-selection-search-input-selected {
        padding-left: 15px;
    }

    .rtp-calculator-game-selection-search-input {
        flex: 1 1 250px;
        min-width: 0;
        background-color: transparent;
        border: unset;
        outline: unset;
        color: var(--font-color-secondary);
        transition: color 0.2s, padding-left 0.15s;
        z-index: 2;
        padding-left: 45px;

        &::placeholder {
            color: var(--font-color-secondary);
            font-weight: 700;
            opacity: 1;
        }

        &::-ms-clear {
            display: none;
        }
    }

    .rtp-calculator-game-selection-search-result-wrapper {
        position: absolute;
        z-index: 5;
        top: 100%;
        left: 0;
        width: 100%;
        margin-top: var(--ui-radius);
        border-radius: 0 0 var(--ui-radius) var(--ui-radius);

        .rtp-calculator-game-selection-search-result-container {
            padding-top: var(--ui-radius);
            background-color: var(--surface-level-1-bg);
            border-radius: 0 0 var(--ui-radius) var(--ui-radius);
            max-height: calc(400px + var(--ui-radius));

            &-no-games {
                width: 100%;
                height: 310px;

                display: flex;
                justify-content: center;
                align-items: center;

                @media ${mediaQuery.isPhone} {
                    height: 100px;
                }

                &-text {
                    width: 200px;
                    text-align: center;
                    font-size: 0.875rem;
                    color: var(--font-color-subtle);
                    font-weight: 700;
                }
            }

            &-games {
                width: 100%;
                max-height: 400px;
                overflow-y: scroll;
                display: flex;
                flex-wrap: nowrap;
                flex-direction: column;
            }
        }
    }
`;
