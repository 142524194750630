import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    $isCompactViewEnabled: boolean;
    $isScreenShotPage: boolean;
};

export default styled.div<StyledProps>`
    position: relative;

    background-color: var(--ticket-bg);
    border: var(--ticket-border);
    border-radius: var(--ui-radius);
    .ticket-details {
        position: relative;

        display: flex;
        align-items: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'flex-start' : 'center')};
        flex-direction: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'column' : 'row')};

        padding: var(--spacing-20) var(--spacing-12);
        border-top: 1px dashed var(--ticket-selection-separator);
        @media ${mediaQuery.isPhone} {
            flex-direction: column;
            align-items: flex-start;
        }

        .banker-bet {
            margin: 0 0.8rem 0 0;

            text-align: center;
            font-size: 0.75rem;
            color: var(--ticket-text-color-secondary);
            svg {
                height: 1.4rem;
                width: 1.4rem;
            }
        }

        .selection-sport {
            display: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'none' : 'flex')};
            justify-content: center;

            margin-bottom: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0.2rem' : '')};
            min-width: 50px;
        }

        .selection-match {
            flex: 1;
            margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0 0 1.2rem 0' : '')};
            width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '100%' : '')};

            .match-info {
                display: flex;
                align-items: center;

                font-size: 0.75rem;
                color: var(--ticket-text-color-secondary);

                margin-bottom: var(--spacing-8);

                ${({ $isScreenShotPage }) =>
                    $isScreenShotPage &&
                    css`
                        font-size: 0.85rem;
                    `};

                > * {
                    margin-right: 0.4rem;
                }
                .match-league {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .match-sport {
                    display: flex;
                    align-items: center;
                    flex-shrink: 0;
                    @media ${mediaQuery.isPhone} {
                        position: relative;
                        right: 0;
                    }
                    > * {
                        margin-right: 0.4rem;
                    }
                }
                .match-dates {
                    flex-shrink: 0;
                }
                .sport-icon {
                    width: 1.125rem;
                }
            }

            .match-container {
                display: flex;
                align-items: center;
                flex-wrap: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'wrap' : '')};

                margin: 0 0.2rem 0 0;
                ${({ $isScreenShotPage }) =>
                    $isScreenShotPage &&
                    css`
                        font-size: 1.2rem;
                    `};

                @media ${mediaQuery.isPhone} {
                    font-size: 1rem;
                }
                > *:not(:last-child) {
                    margin-right: 0.4rem;
                }
            }

            .match-name {
                font-size: 0.875rem;
                color: var(--ticket-text-color-primary);
                font-weight: var(--match-name-weight);
                ${({ theme }) => theme.optimizeFontRendering};
            }

            .selected-market {
                font-size: 0.75rem;
                color: var(--ticket-text-color-secondary);
                margin-top: var(--spacing-8);
                ${({ $isScreenShotPage }) =>
                    $isScreenShotPage &&
                    css`
                        font-size: 0.85rem;
                    `};

                > *:not(:last-child) {
                    margin-right: 0.4rem;
                }
                .market-name {
                    margin-bottom: var(--spacing-8);
                }
                .market-outcome {
                    display: flex;
                    align-items: center;
                    gap: var(--spacing-8);
                    white-space: initial;
                    text-align: left;
                    font-size: 0.75rem;
                    color: var(--color-odds);
                    font-weight: bold;
                    ${({ theme }) => theme.optimizeFontRendering};
                }
            }
        }
    }

    .ticket-status {
        margin-left: auto;
        margin-right: 0.8rem;
    }

    .odds-and-type {
        display: flex;
        flex-direction: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'row' : 'column')};
        align-items: center;

        width: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '100%' : '')};
        > *:not(:last-child) {
            margin-right: 0.4rem;
        }
        .coolbet-ticket-logo {
            margin-left: auto;
            display: none;
            @media ${mediaQuery.isPhone} {
                display: block;
            }
        }
    }
    .bet-sharing {
        display: flex;
        align-items: center;
        flex-direction: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? 'column' : '')};
        margin-bottom: var(--spacing-4);
        > * {
            width: 100%;
        }
    }
    .copy-ticket {
        animation: fadeIn 200ms linear;
        margin-right: 0;
    }
    .betbuilder-market {
        margin: 10px 0px;

        .outcome {
            color: var(--color-odds);
        }
    }
    .match-score {
        padding-right: 0.4rem;
    }
    .match-live-score {
        display: flex;
        align-items: center;
        margin: ${({ $isCompactViewEnabled }) => ($isCompactViewEnabled ? '0 0 var(--spacing-12) 0' : '')};
    }
    .system-groups {
        margin-bottom: var(--spacing-4);
    }
`;
