import { translate } from './translate';
import { BonusBet, cancelPokerPendingBonus, getUserTakenBonuses } from '../microservices/bonuses';
import { cancelBonusLock } from '../microservices/locks';
import orderBy from 'lodash/orderBy';
import isPlainObject from 'lodash/isPlainObject';
import { parse } from 'query-string';
import { stores } from '../stores';
import { LANGUAGE } from './language';
import { getUserCountry } from './user';
import { COUNTRY } from './country';
import { convertOdds } from './odds-format';
import { DATE_YEAR_TIME_FORMAT, getFormattedDate } from './date';
import { getStoreValue } from '../stores/store/utils';

export const BONUS_STATUS = {
    ELIGIBLE: 'ELIGIBLE',
    BONUS_SUBMITTED: 'BONUS_SUBMITTED',
    CODE_REGISTERED: 'CODE_REGISTERED',
    NEED_DEPOSIT: 'NEED_DEPOSIT',
    BONUS_GIVEN: 'BONUS_GIVEN',
    ALREADY_GOT_THIS_BONUS: 'ALREADY_GOT_THIS_BONUS',
    ALREADY_ENTERED: 'ALREADY_ENTERED',
    NO_BONUS_FOUND: 'NO_BONUS_FOUND',
    SUBMIT_ERROR: 'SUBMIT_ERROR',
    ALREADY_HAS_ACTIVE_BONUS_CASINO: 'ALREADY_HAS_ACTIVE_BONUS_CASINO',
    ALREADY_HAS_ACTIVE_BONUS_SB: 'ALREADY_HAS_ACTIVE_BONUS_SB',
    SECURITY_MATCH_FOUND: 'SECURITY_MATCH_FOUND',
    MOBILE_APP_ONLY: 'MOBILE_APP_ONLY',
    VALIDATION_FAILED: 'VALIDATION_FAILED',
    SUBMISSION_FAILED: 'SUBMISSION_FAILED',
    BONUS_NOT_ELIGIBLE_FOR_DEPOSIT_WITH_THIS_PROVIDER: 'BONUS_NOT_ELIGIBLE_FOR_DEPOSIT_WITH_THIS_PROVIDER',
};

const BONUS_ERRORS = [
    BONUS_STATUS.ALREADY_GOT_THIS_BONUS,
    BONUS_STATUS.ALREADY_ENTERED,
    BONUS_STATUS.NO_BONUS_FOUND,
    BONUS_STATUS.SUBMIT_ERROR,
    BONUS_STATUS.ALREADY_HAS_ACTIVE_BONUS_CASINO,
    BONUS_STATUS.ALREADY_HAS_ACTIVE_BONUS_SB,
    BONUS_STATUS.SECURITY_MATCH_FOUND,
    BONUS_STATUS.MOBILE_APP_ONLY,
    BONUS_STATUS.VALIDATION_FAILED,
    BONUS_STATUS.SUBMISSION_FAILED,
    BONUS_STATUS.BONUS_NOT_ELIGIBLE_FOR_DEPOSIT_WITH_THIS_PROVIDER,
];

export const BONUS_PRODUCT = {
    CASINO: 'CASINO',
    SPORTSBOOK: 'SPORTSBOOK',
    POKER: 'POKER',
};

export const BONUS_LOCK_STATUS = {
    ACTIVE: 'active',
    CANCELLED: 'cancelled',
};

export const BONUS_TYPE = {
    DEPOSIT: 'deposit',
    PENDING: 'pending',
    CAMPAIGN: 'campaign',
    FREE_MONEY: 'free_money',
    RISK_FREE_BET: 'risk_free_bet',
};

const PROMOTION_DESCRIPTION_HIDDEN_COUNTRIES = [COUNTRY.FINLAND];

export function getUnifiedBonusResponse(bonusResponse, isExtendedMessageVisible) {
    const bonusStatus = isPlainObject(bonusResponse) ? bonusResponse.status : bonusResponse;
    const isError = BONUS_ERRORS.includes(bonusStatus);
    const language = getStoreValue(stores.language);

    return {
        bonusValidationMessage:
            bonusResponse.validation_message &&
            (bonusResponse.validation_message[language] || bonusResponse.validation_message[LANGUAGE.ENGLISH]),
        status: bonusStatus,
        isSuccess: !isError,
        isError,
        message: getMessageForBonusResponse(bonusStatus, isExtendedMessageVisible),
        rawResponse: bonusResponse,
    };
}

function getMessageForBonusResponse(bonusStatus, isExtendedMessageVisible = true) {
    const successMesssage = translate('Success!', 'ui.bonus');
    const failedMessage = translate('Oops!', 'ui.bonus');

    const failureErrorMessage = {
        emphasizedMessage: failedMessage,
        message: translate(bonusStatus, 'ui.bonus'),
    };

    const errorMessageByBonusStatus =
        {
            [BONUS_STATUS.NEED_DEPOSIT]: {
                emphasizedMessage: successMesssage,
                message: translate('Please proceed with depositing to fulfill bonus terms', 'ui.bonus'),
            },
            [BONUS_STATUS.CODE_REGISTERED]: {
                emphasizedMessage: successMesssage,
                message: translate('Bonus code registered', 'ui.bonus'),
            },
            [BONUS_STATUS.BONUS_GIVEN]: {
                emphasizedMessage: successMesssage,
                message: translate('Bonus given', 'ui.bonus'),
            },
            [BONUS_STATUS.SUBMISSION_FAILED]: {
                emphasizedMessage: failedMessage,
                message: translate('Submitting bonus failed!', 'profile.wallet'),
            },
            [BONUS_STATUS.VALIDATION_FAILED]: {
                emphasizedMessage: failedMessage,
                message: translate('Validating bonus failed!', 'profile.wallet'),
            },
            [BONUS_STATUS.ELIGIBLE]: {
                emphasizedMessage: translate('Bonus code is valid!', 'ui.bonus'),
                message: translate('Please submit to get your bonus', 'ui.bonus'),
            },
        }[bonusStatus] || failureErrorMessage;

    const { emphasizedMessage, message } = errorMessageByBonusStatus;

    return !isExtendedMessageVisible && bonusStatus === BONUS_STATUS.ELIGIBLE
        ? `<strong>${emphasizedMessage}</strong>`
        : `<strong>${emphasizedMessage}</strong> ${message}`;
}

export async function cancelBonus(bonus) {
    if (isPokerPendingBonus(bonus)) {
        return cancelPokerPendingBonus(bonus);
    }
    return cancelBonusLock(bonus);
}

export async function getUserBonuses() {
    const language = getStoreValue(stores.language);
    const userBonuses = await getUserTakenBonuses(language);
    return orderBy(userBonuses, ['created'], ['desc']);
}

function bonusHasIncrementalSettings(bonus) {
    return Boolean(bonus.lock && bonus.lock.incremental_settings);
}

export function getBonusBlocksRemaining(bonus) {
    if (!isPokerPendingBonus(bonus) && !bonusHasIncrementalSettings(bonus)) {
        return;
    }
    return isPokerPendingBonus(bonus)
        ? bonus.poker_details.blocks_remaining
        : bonus.lock.incremental_settings.blocks_remaining;
}

export function getBonusLockAmount(bonus) {
    return isPokerPendingBonus(bonus) ? 0 : Number(bonus.lock.amount);
}

export function getBonusRequirementAmount(bonus) {
    return isPokerPendingBonus(bonus) ? bonus.poker_details.rake_requirement : bonus.lock.wager_requirement;
}

export function getBonusRemainingAmount(bonus) {
    return isPokerPendingBonus(bonus) ? bonus.poker_details.rakeRemaining : bonus.lock.wager_remaining;
}

export function getBonusExpiryTime(bonus) {
    if (isPokerPendingBonus(bonus)) {
        return (
            bonus.poker_details &&
            bonus.poker_details.expires &&
            getFormattedDate({ date: bonus.poker_details.expires, format: DATE_YEAR_TIME_FORMAT, useDots: true })
        );
    }

    return (
        bonus.lock &&
        bonus.lock.expires &&
        getFormattedDate({ date: bonus.lock.expires, format: DATE_YEAR_TIME_FORMAT, useDots: true })
    );
}

export function getBonusCreatedTime({ created }) {
    return getFormattedDate({ date: created, format: DATE_YEAR_TIME_FORMAT, useDots: true });
}

export function isBonusActive(bonus) {
    if (isPokerPendingBonus(bonus)) {
        return Boolean(bonus.status && bonus.status.active);
    }

    return Boolean(bonus.lock && bonus.lock.status === BONUS_LOCK_STATUS.ACTIVE);
}

export function getBonusStatus(bonus) {
    if (bonus.lock && bonus.lock.status) {
        return translate(bonus.lock.status, 'ui.lock-status');
    }
    if (bonus.status) {
        return translate(Object.keys(bonus.status).find((key) => bonus.status[key]) as any, 'ui.lock-status');
    }
}

function isPokerPendingBonus(bonus) {
    return Boolean(bonus.product === BONUS_PRODUCT.POKER && bonus.bonus_type === BONUS_TYPE.PENDING);
}

export function getBonusCodeFromUrl() {
    let { bonus } = parse(window.location.search);
    bonus = Array.isArray(bonus) ? bonus[0] : bonus;
    const prefilledBonusCode = getStoreValue(stores.bonuses.prefilledBonusCode);

    return bonus || prefilledBonusCode || '';
}

export function filterBonusesThatAreNotMatchOfTheDay(bonusList: BonusBet[]) {
    return bonusList.filter((bonus) => !isMatchOfTheDayBonusCode(bonus.code));
}

export function isMatchOfTheDayBonusCode(bonusCode: string) {
    return Boolean(bonusCode.match('motd_[0-9]{2}_[0-9]{2}_[0-9]{4}_[0-9]{2}_[0-9]{2}'));
}

export function isPromotionDescriptionShownForUser() {
    const userCountry = getUserCountry();
    return !PROMOTION_DESCRIPTION_HIDDEN_COUNTRIES.includes(userCountry);
}

export function getMinimumOdd(bonus) {
    return bonus.wagering_min_odds ? convertOdds(bonus.wagering_min_odds) : '';
}

export function getWageringRatio(bonus) {
    return bonus.wagering_ratio ? bonus.wagering_ratio : '';
}
