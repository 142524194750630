import {
    BetSlipPlacingState,
    BetSlipReplayState,
    CategoryMatch,
    MarketTypeCategory,
    Odds,
    OddsByOutcomeIdStore,
    SportCategoryWithMatches,
} from '../services/sports/types';
import {
    BetslipMode,
    getOrResetBetSlipSettings,
    MultiBetslipState,
    MultiParlayState,
} from '../services/sports/betslip';
import { initialBetSlipPlacingState, initialBetSlipUserState, MENU_TYPE } from '../services/sports/constants';
import { PreMatchTree, PreMatchTreeChildren } from '../microservices/sbgate';
import { storageGet } from '../services/storage';
import { Campaign } from '../microservices/sb-campaigns';
import { BonusBetsResponse } from '../microservices/bonuses';
import { LocalSportsUserSettings } from '../services/sports/user-settings';
import { ParlayCard, ParlayCardMarket, ParlayCardWithMarket, SportEventTypeObject } from '../microservices/sports';
import { createStores } from './store/utils';
import { PayoutOddsFO } from '@staycool/sports-types';
import { ParlayCardBetslipMarketIdToOutcomeIds } from '../services/parlay-card/parlay-card';
import { TicketToPrintResponse } from '@staycool/retail-types/ticket';
import { GetLimitConfiguration } from '@staycool/bets-types/limit-configuration';
import { BetSlipMinimalMarket, FoHotEvent, SportSearchMatch } from '@staycool/sbgate-types';
import { LiveTreeCategoryFo } from '@staycool/sbgate-types/dist/categoryTree/types';
import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';
import { initialMediaStore } from './media/media';

export const sports = createStores({
    isHotCardWithOdds: false,
    topMarketTypesByCategory: {} as Record<number, MarketTypeCategory[]>,
    filterCategoryIds: [] as number[],
    matchesSoon: null as unknown as CategoryMatch[] | null,
    marketTypeGroupById: null,
    matchesLiveBet: null as unknown as SportCategoryWithMatches[],
    sportsById: {},
    category: null as unknown as SportCategoryWithMatches[] | null,
    oddsByOutcomeId: {} as OddsByOutcomeIdStore,
    betSlipMarketIdToOutcomeId: getOrResetBetSlipSettings() as { [marketId: number]: number }, // marketId: outcomeId
    betSlipUserState: initialBetSlipUserState,
    betSlipPlacingState: initialBetSlipPlacingState as BetSlipPlacingState,
    betslipCollection: [] as MultiBetslipState[],
    isByodOrReplay: false,
    betSlipErrorByMarketId: {},
    ticketsToPrint: [] as TicketToPrintResponse[],
    marketInfoById: {} as Record<number, BetSlipMinimalMarket>, // used in betslip only market & outcome related info - match_name, market_name
    prematchTree: {} as PreMatchTree,
    prematchSelectedMenu: storageGet('prematchSelectedMenu') || MENU_TYPE.ADVANCED,
    prematchSelectedCategory: {} as PreMatchTree,
    prematchBreadcrumbs: [] as PreMatchTree[],
    flatCategories: null as unknown as PreMatchTreeChildren[],
    liveMatchesCount: 0,
    parlayCards: [] as ParlayCard[],
    liveTree: null as unknown as LiveTreeCategoryFo[],
    liveTreeStreams: {},
    liveTreeSelectedCategoryId: null as unknown as number | undefined,
    liveNow: createStores({
        currentStreamMatchId: undefined as unknown as number,
        isShowingMatchesWithStreamsOnly: false,
        filterIds: [] as number[],
        hasStream: false,
    }),
    hotEvents: null as FoHotEvent[] | null,
    inplayMatchData: {},
    campaigns: {} as { selected?: Campaign; list: Campaign[] },
    bonusBets: {
        free_bet: [],
        free_bet_v2: [],
        risk_free_bet: [],
        multiplier: [],
    } as BonusBetsResponse,
    bonusBetsSelection: {} as Record<string, boolean>,
    selectedBonusId: '',
    selectedUserBonusId: '',
    bookmakerMessages: {},
    userFavorites: { categories: [], matches: [] } as { categories: number[]; matches: number[] },
    selectedFavoriteCategory: null as number | null,
    multiViewCategories: [] as PreMatchTreeChildren[],
    isLivebet: false,
    isNavigationHidden: false,
    isStartingSoonPage: null,
    isBoostedOddsPage: null,
    isSportCampaignPage: false,
    cashoutsDoneTicketIds: [] as string[],
    isSportSideMenuOpen: false,
    isMobileCompactView: initialMediaStore().isPhone && storageGet<boolean>('isCompactView', true), // TODO: check this
    currentlyOpenMatchIds: [] as number[],
    keptBetslipSelections: null as null | { [marketId: number]: number },
    totalStake: [],
    isBottomNavigationVisible: true,
    bettingContextByOutcomeId: {} as { [outcomeId: number]: string },
    marketTypeSelectedByCategoryId: {} as { [categoryId: number]: number },
    heartbeat: {} as Record<string, boolean>,
    userSettings: null as LocalSportsUserSettings | null,
    searchMatchSelected: null as SportSearchMatch | null,
    isSidebetsOpen: false as boolean,
    isTeasersAvailable: false as boolean,
    teaserMainLineErrorMarketIds: [] as number[],
    teaserInvalidMainLineErrorMarketIds: [] as number[],
    teaserStatusDisabledMarketIds: [] as number[],
    teaserPayouts: {} as PayoutOddsFO,
    teaserSelectedPoint: undefined as number | undefined,
    parlayCard: createStores({
        parlayCard: {} as ParlayCardWithMarket,
        marketInfo: {} as Record<number, ParlayCardMarket>,
        betSlipMarketIdToOutcomeIds: {} as ParlayCardBetslipMarketIdToOutcomeIds,
        stake: 0,
        betSlipPlacingState: initialBetSlipPlacingState as BetSlipPlacingState,
        betSlipErrors: [] as any[],
        oddsByOutcomeId: {} as { [outcomeId: number]: Odds },
        manualAcceptanceStake: 0,
        parlayBetslipCollection: [] as MultiParlayState[],
        betslipPosition: 0,
    }),
    comboCard: createStores({
        cardsInBetslip: [] as FoComboCardWithOdds[],
        stakeByCardId: {} as Record<number, number>,
        isLoading: false,
        isComboCardBetslipOpen: false,
    }),
    betSlipPosition: 0,
    qrCode: '',
    betslipMode: BetslipMode.Betslip,
    betbuilderBetslipIdByMarketId: {} as Record<number, string>,
    betSlipReplayState: {} as BetSlipReplayState,
    sportEventTypes: {} as SportEventTypeObject,
    americanSportCategoryIds: undefined as number[] | undefined,
    limits: {
        min_stake: Number.MAX_SAFE_INTEGER,
        system_ticket_min_stake: Number.MAX_SAFE_INTEGER,
        ticket_max_win: 0,
        retail_min_stake: Number.MAX_SAFE_INTEGER,
        retail_system_ticket_min_stake: Number.MAX_SAFE_INTEGER,
        retail_ticket_max_win: 0,
        retail_loyalty_ticket_max_win: 0,
        daily_max_win: 0,
        weekly_max_win: 0,
    } as GetLimitConfiguration,
});
