import React from 'react';
import SportHotEvents from '../../hot-events/SportHotEvents';
import SportSidebarSoonCampaigns from './campaigns/SportSidebarSoonCampaigns';
import Wrapper from './styles';
import SportTreeMenu from '../../tree/menu/SportTreeMenu';
import SportSettingsMenuButton from '../../settings-menu/button/SportSettingsMenuButton';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import SportBonusBetList from '../../bonus-bet/list/SportBonusBetList';

export default function SportSidebarSoon() {
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);

    return (
        <Wrapper>
            {!isLayoutB && (
                <>
                    <SportSettingsMenuButton />
                    <SportBonusBetList />
                    <SportSidebarSoonCampaigns />
                </>
            )}
            <SportHotEvents wrap type="menu" />
            <SportTreeMenu />
        </Wrapper>
    );
}
