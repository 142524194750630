import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import UiQr from '../../ui/qr/UiQr';
import { stores } from '../../../stores';
import { BringYourOwnDeviceData, saveBringYourDevice } from '../../../microservices/retail-proxy';
import { BetslipMode } from '../../../services/sports/betslip';
import { ParlayUserState } from '../../../services/parlay-card/parlay-card';
import isEqual from 'lodash/isEqual';
import { useStore } from '../../../hooks/useStore';

interface Props {
    betslipMode: BetslipMode;
}

export default function RetailBringYourDevice({ betslipMode }: Props) {
    const [counter, setCounter] = useState(0);
    const [code, setCode] = useStore(stores.sports.qrCode);
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [userState] = useStore(stores.sports.betSlipUserState);
    const [parlayCard] = useStore(stores.sports.parlayCard.parlayCard);
    const [stake] = useStore(stores.sports.parlayCard.stake);
    const [betSlipErrors] = useStore(stores.sports.parlayCard.betSlipErrors);
    const [oddsByOutcomeId] = useStore(stores.sports.parlayCard.oddsByOutcomeId);
    const [manualAcceptanceStake] = useStore(stores.sports.parlayCard.manualAcceptanceStake);
    const [teaserSelectedPoint] = useStore(stores.sports.teaserSelectedPoint);
    const [parlayBetSlipMarketIdToOutcomeIds] = useStore(stores.sports.parlayCard.betSlipMarketIdToOutcomeIds);
    const [parlayUserState, setParlayUserState] = useState<ParlayUserState>({});
    const [savedData, setSavedData] = useState<BringYourOwnDeviceData>();

    const qrValue = `betSlip:${code}`;

    useEffect(() => {
        setCode(Math.random().toString().substring(2, 8));
    }, []);

    useEffect(() => {
        if (betslipMode === BetslipMode.ParlayCard) {
            setParlayUserState({
                parlayCard,
                stake,
                betSlipErrors,
                oddsByOutcomeId,
                manualAcceptanceStake,
            });
        }
    }, [parlayCard, stake, betSlipErrors, oddsByOutcomeId, manualAcceptanceStake]);

    useEffect(() => {
        if (code) {
            const data =
                betslipMode === BetslipMode.ParlayCard
                    ? { betslip: parlayBetSlipMarketIdToOutcomeIds, userState: parlayUserState, betslipMode }
                    : { betSlip: betSlipMarketIdToOutcomeId, userState, teaserSelectedPoint };
            const dataToSave: BringYourOwnDeviceData = { code, device: data };
            if (isEqual(dataToSave, savedData)) {
                return;
            }
            saveBringYourDevice(dataToSave);
            setSavedData(dataToSave);
        }
    }, [
        code,
        betSlipMarketIdToOutcomeId,
        userState,
        parlayUserState,
        parlayBetSlipMarketIdToOutcomeIds,
        teaserSelectedPoint,
    ]);

    return (
        <Wrapper>
            <div className="qr-code-container">
                <div className="qr-code-text">
                    Your bet is ready. Now just scan this QR code on a kiosk to place the bet.
                </div>
                <UiQr value={qrValue} size={180} />
                <div className="qr-code-title" onClick={() => setCounter(counter + 1)}>
                    Scan me on sportsbook kiosk
                </div>
                {counter > 7 && <div>{qrValue}</div>}
            </div>
        </Wrapper>
    );
}
