import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';
import { stores } from '../../stores';
import { BetslipMode, clearBetslipSelection } from './betslip';

export function resetComboCardBetSlip(clearBetslipSelections = true) {
    if (clearBetslipSelections) {
        clearBetslipSelection(BetslipMode.Betslip);
    }
    stores.sports.comboCard.isComboCardBetslipOpen.set(false);
    stores.sports.comboCard.cardsInBetslip.set([]);
    stores.sports.comboCard.stakeByCardId.set({});
}

export function getMarketIdsFromComboCardMatches(matches: FoComboCardWithOdds['matches']) {
    return matches.flatMap((match) => match.markets.map((market) => market.id));
}
