import styled, { css } from 'styled-components';

export default styled.div`
    max-width: 340px;
    margin: 0 auto;
    .betslip-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: var(--spacing-16) 0;
        margin-bottom: var(--spacing-16);
        border-bottom: 1px solid var(--surface-level-2-line-color);
    }

    .header-title {
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: var(--font-weight-bold);

        ${({ theme }) => theme.optimizeFontRendering}
        > *:first-of-type {
            margin-right: var(--spacing-8);
        }

        .bets-count {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 1.625rem;
            height: 1.625rem;

            border-radius: 100px;
            border: 2px solid var(--odds-text-color);

            font-size: var(--font-14);
            color: var(--odds-text-color);
        }
    }

    .clear-all {
        font-size: 0.75rem;
        color: var(--font-color-secondary);
        cursor: pointer;
        margin-left: auto;
    }

    .betslip-buttons {
        display: flex;
        flex-direction: column;

        > *:not(:last-of-type) {
            margin-bottom: var(--spacing-8);
        }
    }

    .ticket-cost {
        display: flex;
        flex-direction: column;
        margin: var(--spacing-12) 0;
        > *:not(:last-of-type) {
            border-bottom: 1px solid var(--surface-level-1-line-color);
        }
    }

    .cost {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: var(--spacing-8);

        .cost-label {
            color: var(--font-color-secondary);
            font-size: 0.55rem;
            text-transform: uppercase;
            white-space: nowrap;
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};
        }

        .cost-amount {
            min-width: 90px;
            font-size: 1.2rem;
            color: var(--ticket-stake-text);
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};
            text-align: right;
        }
    }

    .pick-bets {
        position: relative;

        font-size: 0.8rem;
        color: var(--font-color-secondary);
        text-align: center;

        margin: var(--spacing-24) 0 var(--spacing-12) 0;

        .pick-bets-ico {
            margin-bottom: -19px;
        }

        .pick-bets-text {
            position: relative;
            padding: 0.8rem;
            background-color: var(--betslip-bg);
            z-index: 2;
        }
    }

    .pick-bets-arrows {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 var(--spacing-24) 0;

        > * {
            animation: fadeInRight 500ms ease-in;
            animation-fill-mode: both;
        }

        > *:not(:last-child) {
            margin-right: 0.8rem;
        }
    }
     {
        ${[1, 2, 3].map(
            (i) => css`
                .pick-bets-arrows > *:nth-child(${i}) {
                    animation-delay: ${i * 200}ms;
                }
            `,
        )}
    }
`;
