import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    svg {
        width: 1.125rem;
        @media ${mediaQuery.isPhone} {
            width: 1.25rem;
        }
    }
    .selected svg {
        color: var(--color-highlight);
    }
`;
