import styled from 'styled-components';

function setPosition(position: { x: number; y: number; height: number; width: number }) {
    const { x, y, height, width } = position;
    return `
        top: ${y + height}px;
        left: ${x}px;
        width: ${width}px;
    `;
}

type StyledProps = {
    $position?: { x: number; y: number; height: number; width: number };
};

export default styled.div<StyledProps>`
    > div {
        ${({ $position }) => $position && setPosition($position)}
        position: fixed;
        z-index: 10000;

        background: var(--surface-level-1-bg);
        border: 1px solid var(--surface-level-1-line-color);
        border-radius: 0 0 10px 10px;
        padding: 1rem;

        animation: fadeIn 100ms linear;
        .hg-theme-default {
            background-color: var(--surface-level-1-bg);
            display: flex;
            justify-content: center;
            margin: 0 auto;
        }

        .hg-button {
            background-color: var(--button-bg);
            border: var(--button-border);
            box-shadow: var(--button-shadow);
            border-radius: 12px;
            height: 80px;
            flex-grow: initial;

            color: var(--button-text-color);
            font-size: 1.2rem;
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};
            &.hg-standardBtn {
                width: 80px;
            }
            &.hg-button-space {
                flex-grow: 1;
            }
            &.hg-button-shift {
                width: 80px;
            }
            &.hg-button-bksp {
                width: 170px;
            }
            &.hg-button-enter {
                border: none;
                box-shadow: none;
                width: 170px;
            }
        }

        &.layout-search,
        &.layout-loyalty {
            .hg-theme-default {
                width: 900px;
                .hg-rows {
                    .hg-row:nth-child(3) {
                        margin-left: 25px;
                    }
                    .hg-row:nth-child(4) {
                        margin-left: 50px;
                    }
                }
            }
        }

        &.layout-betslip,
        &.layout-loyalty {
            .hg-button {
                &.hg-button-enter {
                    border: none;
                    box-shadow: none;
                    background-color: var(--button-primary-bg);
                    color: var(--button-primary-text-color);
                }
            }
        }

        &.layout-search {
            .hg-button {
                &.hg-button-enter {
                    display: none;
                }
            }
        }

        &.layout-betslip {
            .hg-button {
                width: 70px;
                height: 70px;
            }
            .hg-theme-default {
                width: 250px;
                display: flex;
                justify-content: center;
            }
            .hg-button {
                &.hg-button-bksp {
                    width: 150px;
                }
                &.hg-button-enter {
                    border: none;
                    box-shadow: none;
                    width: 230px;
                }
            }
        }
        &.layout-numpad {
            .hg-button {
                &.hg-button-bksp {
                    width: 80px;
                }
                &.hg-button-enter {
                    border: none;
                    box-shadow: none;
                    width: 260px;
                }
            }
        }

        .hg-theme-default .hg-row .hg-button:not(:last-child) {
            margin-right: 0.5rem;
        }

        .hg-theme-default .hg-row:not(:last-child) {
            margin-bottom: 0.5rem;
        }

        &.layout-default,
        &.layout-search,
        &.layout-loyalty {
            .hg-theme-default .hg-row:first-child {
                margin-bottom: 35px;
            }
        }

        .hg-activeButton {
            background-color: var(--button-bg-hover) !important;
            transform: translate(0, 2px);
        }
    }
`;
