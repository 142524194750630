import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { isMobile } from './browser';
import { AppType } from './mobile-app';
import { Platform } from '../microservices/casino-integrations';
import { UAParser } from 'ua-parser-js';
import { storageGet } from './storage';

const uaParser = new UAParser();

export const OPERATING_SYSTEM = {
    ANDROID: 'Android',
    APPLE_DESKTOP: 'Mac OS',
    APPLE_MOBILE: 'iOS',
    LINUX: 'Linux',
    WINDOWS_DESKTOP: 'Windows',
    WINDOWS_MOBILE: 'Windows Phone',
};

export function getOS() {
    return uaParser.getOS().name;
}

export function getDeviceType() {
    return (uaParser.getDevice().type || 'DESKTOP').toUpperCase();
}

export function getDeviceCategory(): Platform {
    const appType = getStoreValue(stores.appType);
    if (appType !== AppType.WEB) {
        return appType === AppType.ANDROID ? Platform.MOBILE : Platform.IOS;
    }
    return isMobile() ? Platform.MOBILE : Platform.DESKTOP;
}

export function isNativeApp() {
    return storageGet('isNativeApp');
}
