import React from 'react';
import Wrapper from './styles';
import { getFilteredPromotions } from '../../../services/promotion';
import Promotion from '../Promotion';
import { stores } from '../../../stores';
import { filterStyleProps } from '../../../styles/utils';
import { useStore } from '../../../hooks/useStore';

interface Props {
    sliderSettings?: any;
    product: string;
    isMobileLandingPage?: boolean;
}

export default function PromotionSlider({ sliderSettings, product, isMobileLandingPage = false }: Props) {
    const [promotions] = useStore(stores.promotions);
    const filteredPromotions = getFilteredPromotions(product, promotions);

    const defaultSliderSettings = {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        dots: false,
        easing: 'cubic-bezier(0.5, 0, 0.1, 1)',
        lazyLoad: 'progressive',
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
    };

    const newSliderSettings = { ...defaultSliderSettings, ...sliderSettings };

    return (
        <Wrapper {...filterStyleProps(newSliderSettings)}>
            {promotions.length > 0 &&
                filteredPromotions.map((filteredPromotion) => (
                    <Promotion
                        $isMobileLandingPage={isMobileLandingPage}
                        key={filteredPromotion.id}
                        promotion={filteredPromotion}
                    />
                ))}
        </Wrapper>
    );
}
