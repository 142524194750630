import styled from 'styled-components';

export default styled.div`
    position: sticky;
    top: 10px;
    height: 100vh;

    background: var(--sport-menu-bg);
    border: var(--sport-menu-border);
    border-radius: var(--ui-radius);

    padding: var(--spacing-12);
    overflow: auto;
`;
