import React, { useEffect, useState } from 'react';
import UiAlert from '../../../ui/alert/UiAlert';
import Wrapper from './styles';
import { stores, useStoreWithSelector } from '../../../../stores';
import { translate } from '../../../../services/translate';
import { getTicketDetailsByTickedId } from '../../../../microservices/sbgate';
import { logger } from '../../../../services/logger';
import { TicketDetails } from '../../../../services/sports/types';
import SportTicketTotals from '../../../sport/ticket/totals/SportTicketTotals';
import groupBy from 'lodash/groupBy';
import Loader from '../../../loader/Loader';
import { DATE_YEAR_TIME_FORMAT, getFormattedDate } from '../../../../services/date';
import { retail } from '../../../../stores/retail/retail';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    cardId: string;
    removeReceipt: () => void;
}

function ComboCardsBetslipReceipt({ cardId, removeReceipt }: Props) {
    const [ticketId] = useStoreWithSelector(stores.sports.betSlipPlacingState, (state) => state.receiptById[cardId]);
    const [isLoadingTicketReceipt, setIsLoadingTicketReceipt] = useState(false);
    const [ticketResponse, setTicketResponse] = useState<TicketDetails>();
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [hasRetailSession] = useStore(retail.hasRetailSession);

    useEffect(() => {
        if (ticketId) {
            loadTicketReceipt(ticketId);
        }
    }, [ticketId]);

    useEffect(() => {
        if (isAuthenticated || hasRetailSession) {
            return;
        }
        removeReceipt();
    }, [isAuthenticated]);

    async function loadTicketReceipt(ticketId: string) {
        try {
            setIsLoadingTicketReceipt(true);
            await loadTicketDetails(ticketId);
        } catch (error) {
            logger.error('ComboCardsBetslipReceipt', 'loadTicketReceipt', error);
        } finally {
            setIsLoadingTicketReceipt(false);
        }
    }

    async function loadTicketDetails(ticketId: string) {
        const response = await getTicketDetailsByTickedId(ticketId);
        setTicketResponse(response);
    }

    if (isLoadingTicketReceipt) {
        return <Loader />;
    }
    if (!ticketResponse) {
        return null;
    }

    return (
        <Wrapper>
            <p>
                {translate('Bet ID:', 'ui.betslip')} {ticketResponse.ticket.display_id} /{' '}
                <span>
                    {getFormattedDate({
                        date: ticketResponse.ticket.created_at,
                        format: DATE_YEAR_TIME_FORMAT,
                        useDots: true,
                    })}
                </span>
            </p>

            {Boolean(ticketResponse.ticket.total_stake) && (
                <UiAlert center success>
                    {translate('Your bet is placed. Good luck!', 'ui.betslip')}
                </UiAlert>
            )}

            <div className="betslip-receipt">
                <SportTicketTotals ticket={ticketResponse.ticket} isPublic={false} isCompactView extraInfo />
                {Object.values(groupBy(ticketResponse.uniqueSelections, 'match_id')).map((groupedOutcomes) => {
                    const { match_id, match_name } = groupedOutcomes[0];
                    return (
                        <div className="match-details-container" key={match_id}>
                            <div className="match-name">{match_name}</div>
                            {groupedOutcomes.map(({ outcome_name, outcome_id }) => (
                                <div className="outcome-row" key={outcome_id}>
                                    <span className="outcome-name">{outcome_name}</span>
                                </div>
                            ))}
                        </div>
                    );
                })}
            </div>
        </Wrapper>
    );
}

export default ComboCardsBetslipReceipt;
