import styled from 'styled-components';

export default styled.div`
    .parlay-card-header {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 1rem;
        padding: var(--spacing-16);
        border-bottom: 1px solid var(--surface-level-2-line-color);
        margin-bottom: 1rem;

        .parlay-card-title {
            font-size: 1.125rem;
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering}
        }

        .clear-all {
            font-size: var(--font-14);
            color: var(--font-color-secondary);
            cursor: pointer;
            white-space: nowrap;
        }
    }
`;
