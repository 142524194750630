import React from 'react';
import { CLOSURE_REASON } from '../../../../services/responsible-gaming';
import { translate } from '../../../../services/translate';
import UiNavLink from '../../../ui/nav-link/UiNavLink';
import { getRoute } from '../../../../services/router';
import UiAlert from '../../../ui/alert/UiAlert';
import { stores } from '../../../../stores';
import { getUserProductBlockReason } from '../../../../services/responsible-gaming';
import { PRODUCT } from '../../../../types/common';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    product: typeof PRODUCT[keyof typeof PRODUCT];
}

export default function ResponsibleGamingProductBlockingAlert({ product }: Props) {
    const [isPanicTimeoutActive] = useStore(stores.responsibleGaming.isPanicTimeoutActive);

    if (product === PRODUCT.DEPOSIT) {
        return <UiAlert warning>{translate('all-product-block', 'ui.deposit')}</UiAlert>;
    }

    const closureReason = getUserProductBlockReason(product);

    if (!closureReason) {
        return null;
    }

    const translationKeyPrefix = {
        [PRODUCT.SPORTSBOOK]: 'sb-',
        [PRODUCT.CASINO]: '',
        [PRODUCT.POKER]: 'poker-',
        [PRODUCT.VIRTUAL_SPORTS]: 'vs-',
        [PRODUCT.RACEBOOK]: 'hr-',
    }[product];

    return (
        <UiAlert warning>
            {isPanicTimeoutActive && translate('self-exclusion-from-all-products-for-24h', 'ui.account')}

            {!isPanicTimeoutActive && (
                <>
                    {closureReason === CLOSURE_REASON.CLOSED && translate('your-account-is-closed-', 'ui.account')}
                    {closureReason === CLOSURE_REASON.HAMPI &&
                        translate(`ui.account.${translationKeyPrefix}closed-hampi`)}
                    {closureReason === CLOSURE_REASON.LIMIT &&
                        translate(`ui.account.${translationKeyPrefix}closed-limit`)}
                    {closureReason === CLOSURE_REASON.COUNTRY &&
                        translate(`ui.account.${translationKeyPrefix}closed-country`)}

                    {closureReason === CLOSURE_REASON.UNDERAGE &&
                        translate(`ui.account.${translationKeyPrefix}closed-underage`)}

                    {closureReason === CLOSURE_REASON.MAINTENANCE &&
                        translate(`ui.account.${translationKeyPrefix}closed-maintenance`)}

                    {closureReason === CLOSURE_REASON.SPELPAUS &&
                        translate(`ui.account.${translationKeyPrefix}closed-spelpaus`)}

                    {closureReason === CLOSURE_REASON.BALANCE &&
                        translate(`ui.account.${translationKeyPrefix}closed-balance-exceeded`)}

                    {closureReason === CLOSURE_REASON.PID_MISSING && translate('closed-pid-missing', 'ui.account')}

                    {closureReason === CLOSURE_REASON.TIMEOUT && (
                        <>
                            {translate(`ui.account.${translationKeyPrefix}closed-timeout1`)}
                            &nbsp;
                            <UiNavLink to={getRoute('responsible-gaming.set-limits')}>
                                {translate('closed-timeout2', 'ui.account')}
                            </UiNavLink>
                        </>
                    )}

                    {closureReason === CLOSURE_REASON.SUPPORT && (
                        <>
                            {translate(`ui.account.${translationKeyPrefix}closed-support1`)}
                            &nbsp;
                            <UiNavLink to={getRoute('support')}>{translate('closed-support2', 'ui.account')}</UiNavLink>
                            &nbsp;
                            {translate('closed-support3', 'ui.account')}
                        </>
                    )}
                </>
            )}
        </UiAlert>
    );
}
