import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { getMatchTurnover } from '../../../../microservices/bets';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../../services/analytics';
import { getActiveCurrency } from '../../../../services/currency';
import { isB2B } from '../../../../services/environment';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import { logger } from '../../../../services/logger';
import { SHARE_TYPE } from '../../../../services/share';
import { useSportsMatchContext } from '../../../../services/sports/match-helpers';
import { CategoryMatchMarket, Turnover } from '../../../../services/sports/types';
import { translate } from '../../../../services/translate';
import { isExistingUser } from '../../../../services/user';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import { convertArrayToObject } from '../../../../services/util';
import { Currency } from '../../../../services/wallet/types';
import { stores } from '../../../../stores';
import AuthLoginButton from '../../../auth/login/button/AuthLoginButton';
import AuthRegisterButton from '../../../auth/register/button/AuthRegisterButton';
import Logo from '../../../logo/Logo';
import Share from '../../../share/Share';
import UiAlert from '../../../ui/alert/UiAlert';
import UiCloseButton from '../../../ui/close-button/UiCloseButton';
import UiDotsLoader from '../../../ui/dots-loader/UiDotsLoader';
import UiGroup from '../../../ui/group/UiGroup';
import SportMatchTurnoverOutcome from './outcome/SportMatchTurnoverOutcome';
import Wrapper from './styles';
import { filterStyleProps } from '../../../../styles/utils';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    mainMarket?: CategoryMatchMarket & { betting_end: Date; in_play: boolean };
    isScreenShotPage?: boolean;
    currency?: Currency;
    matchId: number;
    isModal?: boolean;
}

export default function SportMatchTurnover({
    mainMarket,
    isScreenShotPage = false,
    currency = getActiveCurrency(),
    matchId,
    isModal,
    ...rest
}: Props) {
    const [turnover, setTurnover] = useState<Turnover>();
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const shareableTitle = translate('name', 'share.facebook.betstats', [
        mainMarket?.outcomes?.[0]?.name,
        mainMarket?.outcomes?.[mainMarket.outcomes.length - 1]?.name,
    ]);
    const shareableDescription = translate('description', 'share.facebook.betstats');
    const { turnoverVisible, setContext } = useSportsMatchContext();
    const [{ isPhone }] = useStore(media);
    const isUserExists = isExistingUser();

    useEffect(() => {
        if (!isModal && !turnoverVisible) {
            return;
        }
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.MATCH_TURNOVER_OPENED);
        if (isAuthenticated || isScreenShotPage || isB2B()) {
            getMatchTurnover(mainMarket?.id, currency)
                .then(setTurnover)
                .catch((e) => {
                    logger.error('SportMatchTurnover', 'SportMatchTurnover', e);
                });
        } else {
            setFakeData();
        }
    }, [isAuthenticated, isScreenShotPage, turnoverVisible]);

    function setFakeData() {
        const dummyData: any[] = [];
        let totalAmount = 0;
        mainMarket?.outcomes.forEach((outcome) => {
            const stake = Math.floor(Math.random() * (7000 - 1 + 1) + 1);
            totalAmount += stake;
            dummyData.push({
                bets: Math.floor(Math.random() * (330 - 1 + 1) + 1),
                id: outcome.id,
                share: 0,
                stake,
            });
        });

        dummyData.forEach((item) => {
            item.share = (item.stake / totalAmount) * 100;
            item.sum = totalAmount;
        });

        setTurnover(convertArrayToObject(dummyData, 'id'));
    }

    function getTotalSum() {
        if (turnover && !isEmpty(turnover)) {
            const firstOutcome = Object.keys(turnover)[0];
            return turnover[firstOutcome].sum.toFixed() as string;
        }
        return '0';
    }

    if (!isModal && !turnoverVisible) {
        return null;
    }

    return (
        <Wrapper {...filterStyleProps({ ...rest })} className="animated fadeIn">
            {!isModal && !isScreenShotPage && (
                <UiCloseButton className="close-button" onClick={() => setContext?.({ turnoverVisible: false })} />
            )}

            {!turnover && (
                <div className="show-loader">
                    <UiDotsLoader className="loader" />
                </div>
            )}
            {turnover && (
                <>
                    {!isB2B() && (
                        <div className="turnover-market-name">
                            <>
                                {isB2B() && mainMarket?.name} {translate('Total turnover', 'ui.sportsbook')}:
                            </>
                            <span className="total-sum">{formattedAmountWithCurrency(Number(getTotalSum()))}</span>
                        </div>
                    )}
                    <div className="turnover-outcomes">
                        {mainMarket?.outcomes.slice(0, 3).map((outcome) => (
                            <SportMatchTurnoverOutcome
                                key={outcome.id}
                                outcome={outcome}
                                market={mainMarket}
                                turnover={turnover}
                                currency={currency}
                            />
                        ))}
                    </div>
                    {!isAuthenticated && !isScreenShotPage && !isB2B() && (
                        <div className="turnover-footer">
                            <Logo size={20} />
                            <UiAlert info>{translate('stats-not-real', 'ui.sportsbook')}</UiAlert>
                            <UiGroup expand className="footer-buttons">
                                {!isUserExists && <AuthRegisterButton block={isPhone} />}
                                {<AuthLoginButton block={isPhone} />}
                            </UiGroup>
                        </div>
                    )}
                    {!isScreenShotPage &&
                        isAuthenticated &&
                        !isB2B() &&
                        isFeatureAvailable(FEATURE.SPORT_TICKET_SHARING) && (
                            <Share
                                type={SHARE_TYPE.BET_STATS}
                                matchId={matchId}
                                title={shareableTitle}
                                description={shareableDescription}
                            />
                        )}
                </>
            )}
        </Wrapper>
    );
}
