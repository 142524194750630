import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import React, { useEffect, useMemo, useState } from 'react';
import Wrapper from './styles';
import {
    useCampaignOrNormalBetSlipFunctions,
    getBetslipStakeComboOrSingles,
    isBetslipButtonDisabled,
    isWarning,
} from '../../../../services/sports/betslip';
import {
    addBetslipError,
    getErrorCode,
    removeAllBetslipErrorsOfCode,
} from '../../../../services/sports/betslip-errors';
import {
    BET_BUILDER_NOT_ALLOWED_ERROR,
    BET_TYPE,
    COMBO_MARKET_ID,
    INSUFFICIENT_FUNDS_ERROR,
    MA_ENABLED_ERROR,
    ODDS_CHANGED_ERROR,
    TICKET_LIMIT_ERROR,
} from '../../../../services/sports/constants';
import { hasBetslipManualAcceptanceError } from '../../../../services/sports/manual-acceptance-helpers';
import { calculateSystemUserOnlyStake, getSystemCombinations } from '../../../../services/sports/system-bet-helpers';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import UiAlert from '../../../ui/alert/UiAlert';
import UiProgressBar from '../../../ui/progress-bar/UiProgressBar';
import SportBetslipButtonAndErrorsConfirm from './confirm/SportBetslipButtonAndErrorsConfirm';
import SportBetslipButtonAndErrorsManualAcceptance from './manual-acceptance/SportBetslipButtonAndErrorsManualAcceptance';
import UiButton from '../../../ui/button/UiButton';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import { isBYOD, isRetail } from '../../../../services/environment';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    clearSelection: () => void;
}

export default function SportBetslipButtonAndErrors({ clearSelection }: Props) {
    const [userState] = useStore(stores.sports.betSlipUserState);
    const [betSlipErrorByMarketId] = useStore(stores.sports.betSlipErrorByMarketId);
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [isGeoComplyModalOpen] = useStore(stores.modals.isGeoComplyModalOpen);
    const [marketInfoById] = useStore(stores.sports.marketInfoById);
    const { betType, stakeByMarketId, bankers, acceptAnyOddsChanges } = userState;
    const [betSlipPlacingState, setBetSlipPlacingState] = useStore(stores.sports.betSlipPlacingState);
    const { isLoading, receiptById, needsConfirm, needsConfirmDuplicate } = betSlipPlacingState;
    const systemBets = useMemo(() => {
        if (betType === BET_TYPE.SYSTEM) {
            return getSystemCombinations(betSlipMarketIdToOutcomeId, bankers);
        }
    }, [bankers, betSlipErrorByMarketId]);
    const [isBetBuilderAllowed, setIsBetBuilderAllowed] = useState(true);
    const isRetailLayout = isRetail();
    const { placeBet, placeBetTextPrefix, getErrorMessageByError } = useCampaignOrNormalBetSlipFunctions();
    const [isOddsChangeConfirmRequired, setIsOddsChangeConfirmRequired] = useState(false);

    useEffect(() => {
        if (!isGeoComplyModalOpen) {
            setBetSlipPlacingState({
                ...betSlipPlacingState,
                isConfirmed: false,
                isLoading: false,
            });
        }
    }, [isGeoComplyModalOpen]);

    useEffect(() => {
        if (userState) {
            if (userState.betType === 'betbuilder') {
                const isBetBuilderFeatureAvailable = isFeatureAvailable(FEATURE.BETBUILDER);
                setIsBetBuilderAllowed(Boolean(isBetBuilderFeatureAvailable));
                const betBuilderMarket = Object.values(marketInfoById).filter(
                    (market) => market.view_type === 'bet_builder',
                );
                if (betBuilderMarket.length && !isBetBuilderFeatureAvailable) {
                    for (const { id } of betBuilderMarket) {
                        addBetslipError(id, BET_BUILDER_NOT_ALLOWED_ERROR);
                    }
                }
                return;
            }
            setIsBetBuilderAllowed(true);
        }
    }, [userState]);

    useEffect(() => {
        if (acceptAnyOddsChanges) {
            setIsOddsChangeConfirmRequired(false);
            return;
        }

        const hasOddsChanged = Object.values(betSlipErrorByMarketId).some((errors: any) => {
            return errors.some((errorObjectOrCode) => getErrorCode(errorObjectOrCode) === ODDS_CHANGED_ERROR);
        });
        setIsOddsChangeConfirmRequired(Boolean(hasOddsChanged));
    }, [betSlipErrorByMarketId, acceptAnyOddsChanges]);

    if (!isEmpty(receiptById) && isEmpty(betSlipErrorByMarketId)) {
        return null;
    }
    const totalStake =
        betType === BET_TYPE.SYSTEM
            ? calculateSystemUserOnlyStake(systemBets)
            : getBetslipStakeComboOrSingles(betType, stakeByMarketId, betSlipMarketIdToOutcomeId);
    const genericErrors = betSlipErrorByMarketId[String(COMBO_MARKET_ID)] || [];

    const isManualAcceptanceError = betType !== BET_TYPE.BETBUILDER && hasBetslipManualAcceptanceError();
    const normalStakeAllowed = totalStake > 0 && isManualAcceptanceError;
    const manualAcceptanceAllowed = some(genericErrors, (error) => getErrorCode(error) === MA_ENABLED_ERROR);
    const keepValidBecauseMA =
        manualAcceptanceAllowed &&
        normalStakeAllowed &&
        !some(genericErrors, (error) => [TICKET_LIMIT_ERROR, INSUFFICIENT_FUNDS_ERROR].includes(getErrorCode(error)));

    function handleAcceptOddsChanges() {
        setIsOddsChangeConfirmRequired(false);
        if (isRetailLayout) {
            removeAllBetslipErrorsOfCode([ODDS_CHANGED_ERROR]);
        }
    }

    if (!isRetailLayout && isBYOD()) {
        return null;
    }

    if (!isManualAcceptanceError && (needsConfirm || needsConfirmDuplicate)) {
        return (
            <Wrapper>
                {needsConfirmDuplicate && isLoading && (
                    <UiProgressBar className="progress" fakeProgressDuration={8500} showAfterPercentage={20} />
                )}
                <SportBetslipButtonAndErrorsConfirm
                    onPlaceBet={placeBet}
                    isForceDuplicate={needsConfirmDuplicate}
                    clearSelection={clearSelection}
                    betSlipPlacingState={betSlipPlacingState}
                    setBetSlipPlacingState={setBetSlipPlacingState}
                />
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            {genericErrors.map((genericError) => (
                <UiAlert
                    info={isWarning(genericError)}
                    failure={!isWarning(genericError)}
                    key={getErrorCode(genericError)}
                >
                    {getErrorMessageByError(genericError)}
                </UiAlert>
            ))}

            {manualAcceptanceAllowed && (
                <SportBetslipButtonAndErrorsManualAcceptance
                    onPlaceBet={placeBet}
                    systemBets={systemBets}
                    totalStake={totalStake}
                />
            )}
            {isLoading && <UiProgressBar className="progress" fakeProgressDuration={8500} showAfterPercentage={20} />}

            {isOddsChangeConfirmRequired && (
                <UiButton color="primary" size="large" block isLoading={isLoading} onClick={handleAcceptOddsChanges}>
                    {translate('Accept odds changes', 'ui.sportsbook')}
                </UiButton>
            )}

            {!isOddsChangeConfirmRequired && (!genericErrors.length || !isManualAcceptanceError || normalStakeAllowed) && (
                <>
                    {normalStakeAllowed && manualAcceptanceAllowed && (
                        <div className="or">{translate('OR', 'ui.betslip')}</div>
                    )}
                    <UiButton
                        color="primary"
                        size="large"
                        block
                        isLoading={isLoading}
                        onClick={() => placeBet(false, normalStakeAllowed && manualAcceptanceAllowed)}
                        disabled={
                            isLoading || (isBetslipButtonDisabled() && !keepValidBecauseMA) || !isBetBuilderAllowed
                        }
                        data-test="button-place-bet"
                    >
                        <div>{translate(`Place ${placeBetTextPrefix}bet`, 'ui.sportsbook')}</div>
                        {normalStakeAllowed && (
                            <div className="extra-text">
                                {translate('Place the bet of %1 and disregard the remaining amount.', 'ui.sportsbook', [
                                    `${formattedAmountWithCurrency(totalStake)}`,
                                ])}
                            </div>
                        )}
                    </UiButton>
                </>
            )}
        </Wrapper>
    );
}
