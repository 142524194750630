import React, { useEffect, useState } from 'react';
import SportCampaignsBetslipCheckboxes from '../../campaigns/betslip-checkboxes/SportCampaignsBetslipCheckboxes';
import SportBonusBetFreeBets from '../../bonus-bet/free-bets/SportBonusBetFreeBets';
import { stores } from '../../../../stores';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import { getActiveEligibleBonus, loadOfferedBonuses } from '../../../../services/sports/bonus-bets';
import { isMatchOfTheDayBonusCode } from '../../../../services/bonus';
import { BonusType } from '../../../../services/sports/constants';
import SportBonusBetMultiplier from '../../bonus-bet/multiplier/SportBonusBetMultiplier';
import { loadCampaigns } from '../../../../services/sports/campaigns';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    betSlipMarketIds: string[];
}

export default function SportBetslipBonuses({ betSlipMarketIds }: Props) {
    const [hasMatchOfTheDayBonusBeenSelected, setHasMatchOfTheDayBonusBeenSelected] = useState(false);
    const [bonusBetsSelection, setBonusBetsSelection] = useStore(stores.sports.bonusBetsSelection);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [campaigns] = useStore(stores.sports.campaigns);
    const [, setActiveBonusId] = useStore(stores.sports.selectedBonusId);
    const [, setActiveUserBonusId] = useStore(stores.sports.selectedUserBonusId);
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [bonusBets] = useStore(stores.sports.bonusBets);
    const [isMatchOfTheDayMultiplier, setIsMatchOfTheDayMultiplier] = useState(false);
    const [marketInfoById] = useStore(stores.sports.marketInfoById);

    useEffect(() => {
        if (!isAuthenticated) {
            setBonusBetsSelection({});
            setActiveBonusId('');
            setActiveUserBonusId('');
        } else {
            isFeatureAvailable(FEATURE.BONUSES) && loadOfferedBonuses();

            if (isFeatureAvailable(FEATURE.CAMPAIGNS) && (!campaigns || isEmpty(campaigns.list))) {
                loadCampaigns();
            }
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (isEmpty(betSlipMarketIdToOutcomeId)) {
            setActiveBonusId('');
            setActiveUserBonusId('');
            return;
        }
        const bonus = getActiveEligibleBonus();
        if (!bonus) {
            setActiveBonusId('');
            setActiveUserBonusId('');
        } else {
            setActiveBonusId(bonus.id);
            setActiveUserBonusId(bonus.userBonusId);
            setIsMatchOfTheDayMultiplier(isMatchOfTheDayBonusCode(bonus.code));
        }
    }, [betSlipMarketIdToOutcomeId, bonusBets, bonusBetsSelection, marketInfoById]);

    useEffect(() => {
        setHasMatchOfTheDayBonusBeenSelected(isMatchOfTheDayMultiplier && bonusBetsSelection[BonusType.Multiplier]);
    }, [bonusBetsSelection, isMatchOfTheDayMultiplier]);

    useEffect(() => {
        if (campaigns.selected && some(bonusBetsSelection)) {
            setBonusBetsSelection({});
        }
    }, [campaigns.selected]);

    if (betSlipMarketIds && betSlipMarketIds.length < 1) {
        return null;
    }

    return (
        <>
            {betSlipMarketIds.length > 0 && <SportBonusBetMultiplier />}
            {!hasMatchOfTheDayBonusBeenSelected && (
                <>
                    <SportCampaignsBetslipCheckboxes />
                    <SportBonusBetFreeBets />
                </>
            )}
        </>
    );
}
