import { getServiceUrl, httpGet, httpPost, httpPut } from '../services/api';
import { OnsiteMessageStatus } from '../services/crm';
import { logger } from '../services/logger';

const getUrl = (url) => getServiceUrl('crm', url);

export async function loadCrmMessages() {
    const url = getUrl('onsite-message/list');
    return httpGet<OnsiteMessageWithCampaign[]>(url);
}
export async function loadCrmMessageById(messageId) {
    try {
        const url = getUrl(`onsite-message/${messageId}`);
        const message = await httpGet<OnsiteMessageWithCampaign>(url);
        return message;
    } catch (error) {
        logger.error('CrmMicroservice', 'loadCrmMessageById', error);
    }
}

export async function confirmMessageDelivery(messageId) {
    try {
        const url = getUrl(`onsite-message/${messageId}/confirm`);
        await httpGet<void>(url);
    } catch (error) {
        logger.error('CrmMicroservice', 'confirmMessageDelivery', error);
    }
}

export async function getUserSubscriptionPreferences(): Promise<UserCrmPreferences | undefined> {
    const url = getUrl(`user-preferences/my`);
    return httpGet<UserCrmPreferences | undefined>(url);
}

export async function insertUserSubscriptionPreferences(
    body: Omit<UserCrmPreferences, 'user_id'>,
): Promise<UserCrmPreferences | undefined> {
    const payload = {
        subscribe_direct_mail: body.subscribeDirectMail,
        subscribe_phone_calls: body.subscribePhoneCalls,
        subscribe_sms: body.subscribeSms,
        subscribe_mail_list: body.subscribeMailList,
    };
    const url = getUrl(`user-preferences`);
    return httpPost<UserCrmPreferences | undefined>(url, payload);
}

export async function updateUserSubscriptionPreferences(
    body: Omit<UserCrmPreferences, 'user_id'>,
): Promise<UserCrmPreferences | undefined> {
    const payload = {
        subscribe_direct_mail: body.subscribeDirectMail,
        subscribe_phone_calls: body.subscribePhoneCalls,
        subscribe_sms: body.subscribeSms,
        subscribe_mail_list: body.subscribeMailList,
    };
    const url = getUrl(`user-preferences`);
    return httpPut<UserCrmPreferences | undefined>(url, payload);
}

export async function trackOnSiteMessage(userMessageId: number, status: OnsiteMessageStatus) {
    try {
        const url = getUrl(`v2/onsite-message/status`);
        await httpPost<void>(url, { userMessageId, status });
    } catch (error) {
        logger.error('CrmMicroservice', 'trackOnSiteMessage', error);
    }
}

export type UserCrmPreferences = {
    user_id: string;
    subscribeDirectMail: boolean;
    subscribePhoneCalls: boolean;
    subscribeSms: boolean;
    subscribeMailList: boolean;
    created_at?: Date;
    updated_at?: Date;
};

export type OnsiteMessage = {
    id: number;
    userId: string;
    createdAt: string;
    expiresAt: string;
    deletedAt?: string;
    deleteAfterSeen: boolean;
    seenAt?: string | Date;
    type: string;
    mascotMessage: string;
    mascotAvatar: string;
    title: string;
    image: string;
    description: string;
    ctaTitle: string;
    ctaLink: string;
    designTag?: string;
    status: string;
};

export type OnsiteMessageWithCampaign = OnsiteMessage & {
    campaignName?: string;
    campaignId?: number;
};
