import { logger } from '../services/logger';
import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { CasinoRace } from '../services/casino/casino-race';

export enum RaceHistorySortingKey {
    LAST_10 = 'last_10',
    ONLY_MINE = 'only_mine',
}

const getUrl = (url) => getServiceUrl('casino-race', url);

export function getMyCasinoRaces() {
    const url = getUrl('fo/race/active/me');
    return httpGet<CasinoRace>(url);
}

export function getPublicCasinoRaces() {
    const url = getUrl('fo/race/public');
    return httpGet<CasinoRace>(url);
}

export async function joinRace(raceId: number) {
    try {
        return await httpPost(getUrl(`api/registration/join-race/${raceId}`));
    } catch (error) {
        logger.error('CasinoRaceMicroservice', 'joinRace', error); // Failed to join the race
        return error;
    }
}

export async function getCasinoRaceHistory(sortingKey: RaceHistorySortingKey) {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const historyUrl = 'fo/race/history/last-10';
    const url = {
        last_10: isAuthenticated ? `${historyUrl}/me` : `${historyUrl}/public`,
        only_mine: `fo/race/history/onlymine`,
    };
    return httpGet<{ races: any[]; total?: number }>(getUrl(url[sortingKey]));
}

export async function disableMinBetReminder() {
    try {
        return await httpPost(getUrl('api/game/disable-min-bet-reminder/'), {});
    } catch (error) {
        logger.error('CasinoRaceMicroservice', 'disableMinBetReminder', error);
    }
}

export async function resetPoints() {
    try {
        return await httpPost<any>(getUrl('api/game/reset-points'));
    } catch (error) {
        logger.error('CasinoRaceMicroservice', 'resetPoints', error);
        return error;
    }
}
