import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { cancelWithdrawalRequest, lockWithdrawalRequest } from '../../../microservices/payments';
import { DATE_YEAR_TIME_FORMAT, getFormattedDate } from '../../../services/date';
import { logger } from '../../../services/logger';
import { translate } from '../../../services/translate';
import { formattedAmountWithCurrency } from '../../../services/currency';
import { Transaction, TransactionProduct, TransactionState } from '../../../services/wallet/types';
import { TRANSACTION_DESCRIPTION, TRANSACTION_TYPE } from '../../../services/wallet/wallet';
import Svg from '../../svg/Svg';
import UiButton from '../../ui/button/UiButton';
import UiDotsLoader from '../../ui/dots-loader/UiDotsLoader';
import UiPrompt from '../../ui/prompt/UiPrompt';
import Wrapper from './styles';
import { PRODUCT } from '../../../types/common';

interface Props {
    transaction: Transaction;
    isBalanceVisible: boolean;
    onReloadTransactions: () => void;
}

export default function TransactionsRow({ transaction, isBalanceVisible, onReloadTransactions }: Props) {
    const {
        external_transaction_id,
        created,
        type,
        description,
        product,
        amount_uc,
        balance_uc,
        state,
        isLockedWithdrawal,
        isCancellable = false,
    } = transaction;
    const [isCancellationPromptVisible, setIsCancellationPromptVisible] = useState(false);
    const [isLockingPromptVisible, setIsLockingPromptVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isPayoutCancelled, setIsPayoutCancelled] = useState(false);
    const [hasPendingWithdrawalActions, setHasPendingWithdrawalActions] = useState(false);
    const [hasCancelWithdrawalLock, setHasCancelWithdrawalLock] = useState(
        isLockedWithdrawal && state === TransactionState.PENDING,
    );

    const translatableDescriptions = [
        TRANSACTION_DESCRIPTION.WITHDRAWAL_DECLINED,
        TRANSACTION_DESCRIPTION.BANK,
        TRANSACTION_DESCRIPTION.CASH,
        TRANSACTION_DESCRIPTION.WEBPAY,
    ];

    useEffect(() => {
        if (product === TransactionProduct.WITHDRAWAL && state === TransactionState.PENDING) {
            setHasPendingWithdrawalActions(!isPayoutCancelled && !hasCancelWithdrawalLock && isCancellable);
        }
    }, [isPayoutCancelled, hasCancelWithdrawalLock]);

    const amountClassName =
        (product === TransactionProduct.WITHDRAWAL && 'info') ||
        (amount_uc > 0 && 'success') ||
        (amount_uc < 0 && 'error');

    async function cancelPayout() {
        setIsCancellationPromptVisible(false);
        setIsLoading(true);
        try {
            await cancelWithdrawalRequest(external_transaction_id);

            onReloadTransactions();
            setIsPayoutCancelled(true);
        } catch (error) {
            logger.error('TransactionsRow', 'cancelPayout', error);
            toast.error(translate('Could not cancel', 'ui.account'));
        }

        setIsLoading(false);
    }

    async function lockPayout() {
        setIsLockingPromptVisible(false);
        setIsLoading(true);

        try {
            await lockWithdrawalRequest(external_transaction_id);
            setHasCancelWithdrawalLock(true);
        } catch (error) {
            logger.error('TransactionsRow', 'lockPayout', error);
            toast.error(translate('Something went wrong', 'ui.common'));
        }

        setIsLoading(false);
    }

    function getDescription() {
        if (isDescriptionTranslatable()) {
            if (transaction.product.toLowerCase() === PRODUCT.VIRTUAL_SPORTS) {
                return translate(description, 'ui.account.vs');
            }
            return translate(description, 'ui.account');
        }
        if (transaction.gameName) {
            return transaction.gameName;
        }
        if (type === TRANSACTION_TYPE.TRUSTLY_DEPOSIT) {
            return description.replace(/^(FBB|PNP|BBP) /, '');
        }
        if (transaction.reason === 'Bonus cancelled') {
            if (description.endsWith('cancelled by Coolbet')) {
                const bonusName = description.replace('cancelled by Coolbet', '').trimEnd();
                return translate('cancelled by admin', 'ui.bonuses', { bonusName: bonusName });
            }
            if (description.endsWith('cancelled by you')) {
                const bonusName = description.replace('cancelled by you', '').trimEnd();
                return translate('cancelled by you', 'ui.bonuses', { bonusName: bonusName });
            }
        }
        return description;
    }

    function isDescriptionTranslatable() {
        return (
            translatableDescriptions.includes(description) ||
            transaction.product.toLowerCase() === PRODUCT.VIRTUAL_SPORTS
        );
    }

    return (
        <Wrapper className="table-row" data-test="transaction">
            <div className="table-cell">
                <div>
                    <span>
                        {getFormattedDate({
                            date: created,
                            format: DATE_YEAR_TIME_FORMAT,
                            useDots: true,
                        })}
                    </span>
                </div>
                <div className="secondary-text">{translate(type, 'ui.account')}</div>
            </div>

            <div className="table-cell">
                <div>{getDescription()}</div>
                <div className="secondary-text">{translate(product, 'ui.common')}</div>
            </div>

            <div className="table-cell table-cell-actions">
                <div>
                    <div className={classNames('amount', amountClassName)}>
                        {formattedAmountWithCurrency(amount_uc)}
                    </div>
                    <div className="secondary-text">{isBalanceVisible && formattedAmountWithCurrency(balance_uc)}</div>
                    <div className="secondary-text">
                        {product === TransactionProduct.WITHDRAWAL && <>{state && translate(state, 'ui.account')} </>}
                    </div>
                </div>
                {hasPendingWithdrawalActions && !isLoading && (
                    <div className="action-buttons">
                        <UiButton
                            className="lock-payout-button"
                            size="small"
                            onClick={() => setIsLockingPromptVisible(true)}
                        >
                            <Svg icon="lock-icon" className="lock-icon svg-icon" />
                            <span className="text">{translate('Lock', 'ui.account')}</span>
                        </UiButton>
                        <UiButton
                            className="cancel-payout-button"
                            size="small"
                            onClick={() => setIsCancellationPromptVisible(true)}
                        >
                            <Svg icon="cross" className="cross-icon svg-icon" />
                            <span className="text">{translate('Cancel payout', 'ui.account')}</span>
                        </UiButton>
                    </div>
                )}
                {hasPendingWithdrawalActions && isLoading && <UiDotsLoader />}
                <UiPrompt
                    open={isCancellationPromptVisible}
                    message={translate('Are you sure you want to cancel this payout?', 'ui.account')}
                    onAccept={cancelPayout}
                    onDismiss={() => setIsCancellationPromptVisible(false)}
                />
                <UiPrompt
                    open={isLockingPromptVisible}
                    message={`${translate(
                        'Are you sure you want to lock this payout? You will not be able to cancel it yourself, once you have locked it. It may still be cancelled for other operational reasons.',
                        'ui.account',
                    )}`}
                    onAccept={lockPayout}
                    onDismiss={() => setIsLockingPromptVisible(false)}
                />
            </div>
        </Wrapper>
    );
}
