import { fetchIpDetails } from '../microservices/ip';
import { logger } from './logger';
import { stores } from '../stores';
import { COUNTRY } from './country';
import { FEATURE, isFeatureAvailable } from './feature';

export async function loadIpDetails() {
    if (!isFeatureAvailable(FEATURE.IP_CHECK)) {
        stores.ipCountry.set(COUNTRY.USA);
        return;
    }
    try {
        const { countryCode, query, isOfficeIP, regionName, lat, lon } = await fetchIpDetails();
        stores.ipCountry.set(countryCode || COUNTRY.ENGLAND);
        stores.ipProvince.set(regionName || '');
        stores.ip.set(query);
        stores.ipLocation.set({ latitude: lat, longitude: lon });
        stores.isOfficeIp.set(Boolean(isOfficeIP));
    } catch (error) {
        logger.error('IpService', 'loadIpDetails', error);
    }
}
