import React from 'react';
import Wrapper from './styles';
import parse from 'html-react-parser';

interface Props {
    mascot: string;
    type: string;
    avatar?: string;
    className: string;
}

export default function CrmMascot({ mascot, type, avatar, className }: Props) {
    return (
        <Wrapper className={className} type={type}>
            <div className="crm-mascot">
                <img src={avatar || '/assets/images/general/mascot-bear.png'} alt="" />
            </div>
            <div className="crm-text">{parse(mascot)}</div>
        </Wrapper>
    );
}
