import styled from 'styled-components';

export default styled.div`
    width: 100%;
    margin: auto;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    line-height: 1.2;
    color: #f1f1f1;
`;
