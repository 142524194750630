import React, { ButtonHTMLAttributes, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import Wrapper from './styles';
import { impersonateWithGoogle, linkWithGoogle } from '../../../../../microservices/auth';
import { authenticateWithGoogle, getBackofficeLoginToken } from '../../../../../services/auth';
import { translate } from '../../../../../services/translate';
import { logger } from '../../../../../services/logger';
import { LOGIN_METHOD } from '../../../../../services/auth';
import { useRouter } from '../../../../../services/router';
import { filterStyleProps } from '../../../../../styles/utils';
import { environment } from '../../../../../stores/environment/environment';
import { useStore } from '../../../../../hooks/useStore';

type ClientConfigError = google.accounts.oauth2.ClientConfigError;
type TokenResponse = google.accounts.oauth2.TokenResponse;
type TokenClient = google.accounts.oauth2.TokenClient;

interface Props extends PropsWithChildren, ButtonHTMLAttributes<HTMLButtonElement> {
    isLinking?: boolean;
    onAuthenticationStart?: () => void;
    onAuthenticationSuccess?: () => void;
    onErrorHandler?: (message: string) => void;
    buttonComponent: (props: any) => ReactElement<any, any>;
}

export default function AuthLoginButtonGoogle({
    children,
    buttonComponent: ButtonComponent,
    isLinking = false,
    onAuthenticationStart = () => {},
    onAuthenticationSuccess = () => {},
    onErrorHandler = () => {},
    ...rest
}: Props) {
    const { navigateTo } = useRouter();
    const [{ GOOGLE_CLIENT_ID }] = useStore(environment);
    const [isLoading, setIsLoading] = useState(false);
    const [tokenClient, setTokenClient] = useState<TokenClient>();

    useEffect(() => {
        loadGoogleScript();

        return () => removeGoogleScript();
    }, []);

    function loadGoogleScript() {
        const scriptElement = document.getElementsByTagName('script')[0];
        const fjs = scriptElement;
        let js = scriptElement;
        js = document.createElement('script');
        js.src = 'https://accounts.google.com/gsi/client';
        js.id = 'google-oauth-client';
        if (fjs && fjs.parentNode) {
            fjs.parentNode.insertBefore(js, fjs);
        } else {
            document.head.appendChild(js);
        }
        js.onload = initToken;
    }

    function removeGoogleScript() {
        const scriptElement = document.getElementById('google-oauth-client');

        scriptElement?.remove();
    }

    function initToken() {
        const token = window.google?.accounts?.oauth2?.initTokenClient({
            client_id: GOOGLE_CLIENT_ID,
            scope: 'profile email',
            callback: onSuccess,
            error_callback: onFailure,
        });

        setTokenClient(token);
    }

    async function onSuccess({ access_token }: TokenResponse) {
        try {
            let response;
            const impersonationToken = getBackofficeLoginToken();
            if (impersonationToken) {
                response = await impersonateWithGoogle(access_token, impersonationToken);
            } else if (isLinking) {
                await linkWithGoogle(access_token);
            } else {
                response = await authenticateWithGoogle(access_token);
            }
            setIsLoading(false);
            onAuthenticationSuccess();

            if (!isLinking && response && response.navigateTo && window.location.pathname !== response.navigateTo) {
                navigateTo(response.navigateTo);
            }
        } catch (error: any) {
            setIsLoading(false);
            onErrorHandler(translate(error.message, 'ui.account'));
        }
    }

    function onFailure({ message, type }: ClientConfigError) {
        logger.error('AuthLoginButtonGoogle', 'onFailure', message);

        setIsLoading(false);

        if (type === 'popup_closed') {
            onErrorHandler(
                translate(`Authentication with {{ loginMethod }} cancelled!`, 'auth.login', {
                    loginMethod: LOGIN_METHOD.GOOGLE,
                }),
            );

            return;
        }

        const errorMessage = translate(`Failed to authenticate with {{ loginMethod }}`, 'auth.login', {
            loginMethod: LOGIN_METHOD.GOOGLE,
        });

        if (message === 'popup_failed_to_open') {
            onErrorHandler(errorMessage);
            return;
        }

        const errorMessageWithErrorCode = `${errorMessage} "${message}"`;

        onErrorHandler(errorMessageWithErrorCode);
    }

    return (
        <Wrapper>
            {tokenClient && (
                <ButtonComponent
                    onClick={() => {
                        onAuthenticationStart();
                        setIsLoading(true);
                        tokenClient.requestAccessToken();
                    }}
                    isLoading={isLoading}
                    {...filterStyleProps(rest)}
                >
                    {children || 'Google'}
                </ButtonComponent>
            )}
        </Wrapper>
    );
}
