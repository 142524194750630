import styled from 'styled-components';
import { lighten } from 'polished';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${({ theme }) => theme.layout.gutterDouble};
    background-color: ${({ theme }) => lighten(0.05, theme.box.background)};
    border-radius: var(--ui-radius);
    box-shadow: var(--ui-shadow), inset 1px 1px 4px -2px ${({ theme }) => lighten(0.2, theme.box.background)};
    padding: ${({ theme }) => theme.layout.gutterDouble};
    @media ${mediaQuery.isPhone} {
        max-height: initial;
    }
    .quiz-history-item-container {
        max-height: 170px;
        overflow: auto;
    }
    .no-participated-quizzes-message {
        text-transform: uppercase;
    }
    .quiz-history-item {
        background-color: var(--surface-level-1-bg);
        border: 2px solid ${({ theme }) => lighten(0.2, theme.box.background)};
        border-radius: var(--ui-radius);
        padding: ${({ theme }) => theme.layout.gutter};
        color: var(--font-color-secondary);
        text-align: center;
        text-transform: uppercase;
        font-size: 0.75rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: ${({ theme }) => theme.layout.gutterHalf};
        position: relative;

        &:hover {
            background-color: ${({ theme }) => lighten(0.15, theme.box.background)};
            border-color: ${({ theme }) => lighten(0.3, theme.box.background)};
            color: var(--font-color-primary);
        }

        &.selected {
            background-color: ${({ theme }) => lighten(0.15, theme.box.background)};
            border-color: ${({ theme }) => lighten(0.3, theme.box.background)};
            color: var(--font-color-primary);
        }
        &:not(:last-of-type) {
            margin-bottom: ${({ theme }) => theme.layout.gutterHalf};
        }
        .history-item-date {
            margin-right: ${({ theme }) => theme.layout.gutter};
        }
        .history-item-title {
            letter-spacing: 1px;
            flex: 1;
        }
    }
    .filter-buttons {
        display: flex;
        margin-bottom: ${({ theme }) => theme.layout.gutter};

        button {
            flex: 1;
            &:not(:last-of-type) {
                margin-right: 0.4rem;
            }
        }
    }
    .participated-icon {
        position: absolute;
        right: 0;
        top: 0;
        width: 20px;
        height: 20px;
        color: green;
    }
`;
