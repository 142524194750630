import React from 'react';
import Wrapper from './styles';
import { MATCH_STATUS, MATCH_TYPE } from '../../../../services/sports/constants';
import { stores } from '../../../../stores';
import classNames from 'classnames';
import { useSportsMatchContext } from '../../../../services/sports/match-helpers';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import { getRoute, useRouter } from '../../../../services/router';
import { useSportsUserSettings } from '../../../../services/sports/user-settings';
import { isRetail } from '../../../../services/environment';
import TeamLogo from '../../../team-logo/TeamLogo';
import UiPlaceholder from '../../../ui/placeholder/UiPlaceholder';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    isCompact?: boolean;
    match: {
        inplay: boolean;
        name: string;
        home_team_name?: string;
        home_team_short_name?: string;
        away_team_name?: string;
        away_team_short_name?: string;
        categoryName: string;
        sport_category: number;
        external_comment: string | null;
        sport: number;
        id: number;
        status: string;
        match_type: string | null;
        pitcherHome?: string | null;
        pitcherAway?: string | null;
        away_team_logo_name?: string | undefined;
        home_team_logo_name?: string | undefined;
    };
}

export default function SportMatchName({ match, isCompact }: Props) {
    const isNormalMatch = match.match_type !== MATCH_TYPE.OUTRIGHT;
    const { isAmericanLayout, isPreferringShortNames } = useSportsUserSettings({
        sportCategoryId: match.sport_category,
    });
    const [isMobileCompactView] = useStore(stores.sports.isMobileCompactView);
    const isAmericanCompactLayout = isAmericanLayout && isMobileCompactView && isCompact;
    const { setContext: setMatchContext, isOpen } = useSportsMatchContext();
    const [{ isTablet }] = useStore(media);
    const isRetailLayout = isRetail();
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);
    const isLiveNow = match.status === MATCH_STATUS.LIVE;
    const matchRoute = isLiveNow ? `${getRoute('sport.live')}/${match.id}` : `${getRoute('sport.match')}/${match.id}`;
    const { navigateTo } = useRouter();

    function isNameTooLong(name) {
        return name?.length > 20;
    }

    const awayTeamName = isPreferringShortNames
        ? match.away_team_short_name || match.away_team_name
        : match.away_team_name;
    const homeTeamName = isPreferringShortNames
        ? match.home_team_short_name || match.home_team_name
        : match.home_team_name;

    function openSidebets() {
        if (!isOpen) {
            !isRetailLayout && (isLayoutB ? navigateTo(matchRoute) : setMatchContext?.({ isOpen: !isOpen }));
        }
    }
    return (
        <Wrapper
            $isCompact={isCompact || isTablet}
            onClick={() => openSidebets()}
            $isAmericanCompactLayout={isAmericanCompactLayout}
            $isRetailLayout={isRetailLayout}
            $isAmericanLayout={isAmericanLayout}
        >
            {!isAmericanLayout && isNormalMatch && (
                <div className="match-teams">
                    <div className="team-home">
                        <div className="name">{homeTeamName || <UiPlaceholder />} </div>
                        {match.pitcherHome && <span className="pitcher-name">{match.pitcherHome}</span>}
                    </div>
                    {!isCompact && <span className="divider">-</span>}
                    <div className="team-away">
                        <div className="name">{awayTeamName || <UiPlaceholder />}</div>{' '}
                        {match.pitcherAway && <span className="pitcher-name">{match.pitcherAway}</span>}
                    </div>
                </div>
            )}

            {isAmericanLayout && isNormalMatch && (
                <div className="match-teams">
                    {
                        <div className={classNames('team-away', { 'small-size': isNameTooLong(awayTeamName) })}>
                            {match.away_team_logo_name && <TeamLogo teamName={match.away_team_logo_name} />}
                            <div className="team-name">
                                {awayTeamName || <UiPlaceholder />}
                                {match.pitcherAway && <span className="pitcher-name">{match.pitcherAway}</span>}
                            </div>
                        </div>
                    }
                    <div className={classNames('team-home', { 'small-size': isNameTooLong(homeTeamName) })}>
                        {match.home_team_logo_name && <TeamLogo teamName={match.home_team_logo_name} />}
                        <div className="team-name">
                            {homeTeamName || <UiPlaceholder />}
                            {match.pitcherHome && <span className="pitcher-name">{match.pitcherHome}</span>}
                        </div>
                    </div>
                </div>
            )}

            {!isNormalMatch && <div className="match-outright-name">{match.name}</div>}
        </Wrapper>
    );
}
