import { OnsiteMessageWithCampaign, UserCrmPreferences } from '../microservices/crm';
import { createStores } from './store/utils';

export const crm = createStores({
    push: undefined,
    onsite: undefined as OnsiteMessageWithCampaign | undefined,
    allMessages: [] as OnsiteMessageWithCampaign[],
    unreadMessagesTotal: 0,
    inbox: undefined,
    isInboxOpen: false,
    isMessageOpen: false,
    openedBy: 'system' as 'user' | 'system',
    userPreferences: undefined as UserCrmPreferences | undefined | Omit<UserCrmPreferences, 'user_id'>,
    showSubscriptionModalForm: false as boolean,
});
