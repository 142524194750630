import { KycSettings } from '../microservices/kyc';
import { KycDocumentType } from '../services/kyc';
import { storageGet } from '../services/storage';
import { createStores } from './store/utils';

export const kyc = createStores({
    kycToken: storageGet<string | undefined>('kycToken'),
    kycTokenExpiry: storageGet<string | undefined>('kycTokenExpiry'),
    settings: {
        hasReachedLimits: {
            [KycDocumentType.ADDRESS]: false,
            [KycDocumentType.ID]: false,
            [KycDocumentType.INCOME]: false,
            [KycDocumentType.PAYMENT]: false,
        },
        isEVerificationEnabled: false,
        isKycBannerEnabled: false,
        isKycCaptureEnabled: false,
        isKycDocsEnabled: false,
        isProofOfIdEnabled: false,
        kycAddressDeclineReason: '',
        kycIdDeclineReason: '',
        kycIncomeDeclineReason: '',
        kycPaymentDeclineReason: '',
        kycStatus: '',
        isClosed: false,
        limits: {
            [KycDocumentType.ADDRESS]: 0,
            [KycDocumentType.ID]: 0,
            [KycDocumentType.INCOME]: 0,
            [KycDocumentType.PAYMENT]: 0,
        },
        proofOfAddress: '',
        proofOfId: '',
        proofOfPaymentStatus: '',
        sourceOfIncomeStatus: '',
    } as KycSettings,
});
