import React, { useState } from 'react';
import Wrapper from './styles';
import CasinoPaybackBoosterCountdown from '../../../countdown/CasinoPaybackBoosterCountdown';
import CasinoPaybackBoosterBalance from '../../../balance/CasinoPaybackBoosterBalance';
import { PaybackBooster } from '../../../../../../services/casino/payback-booster';
import { translate } from '../../../../../../services/translate';
import { getActiveCurrency } from '../../../../../../services/currency';
import { stores } from '../../../../../../stores';

interface Props {
    paybackBooster: PaybackBooster;
    onExpired: () => void;
    isCompact: boolean;
}

export default function CasinoPaybackBoosterThumbnailPaybackBoosterActive({
    paybackBooster,
    onExpired,
    isCompact,
}: Props) {
    const currency = getActiveCurrency();
    const [isExpired, setIsExpired] = useState(Boolean(new Date(paybackBooster.expiryDate) < new Date()));
    const [isTransferConfirming, setIsTransferConfirming] = useState(false);

    function onCountdownExpired() {
        setIsExpired(true);
        stores.paybackBooster.isExpired.set(true);
        onExpired();
    }

    return (
        <Wrapper isExpired={isExpired}>
            {!isTransferConfirming && !isExpired && (
                <div className="countdown">
                    {!isExpired && (
                        <CasinoPaybackBoosterCountdown
                            endDate={paybackBooster.expiryDate}
                            onExpired={onCountdownExpired}
                            isCasinoLocked={paybackBooster.casinoLocked}
                        />
                    )}
                </div>
            )}
            <div className="footer-container">
                {isExpired && !isTransferConfirming && (
                    <div className="expired-content">
                        <span className="gold-text">{translate('Time is over', 'casino.payback-booster')}</span>
                        <p>
                            {translate('You earned extra %1', 'casino.payback-booster', [
                                `${paybackBooster.amountTotal} ${currency}`,
                            ])}
                        </p>
                    </div>
                )}
                <div className="balance">
                    <CasinoPaybackBoosterBalance
                        isCompact={isCompact}
                        isTransferConfirming={() => setIsTransferConfirming(!isTransferConfirming)}
                    />
                </div>
            </div>
        </Wrapper>
    );
}
