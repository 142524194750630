import { LoyaltyResponseKiosk, PrintEvent, RetailEvent, RetailTerminalConfig, Terminal } from './types';
import { createStores } from '../store/utils';

export const retail = createStores({
    retailInputValueUpdateTimeout: {} as ReturnType<typeof setTimeout>,
    retailExpirationTimeout: {} as ReturnType<typeof setTimeout>,
    loyaltyClearanceTimeout: {} as ReturnType<typeof setTimeout>,
    retailTerminal: {} as Terminal,
    hasRetailSession: false,
    retailKeyboard: createStores({
        layout: 'default',
        value: '',
        lastKeyPress: '',
        position: undefined as { x: number; y: number; height: number; width: number } | undefined,
        isVisible: false,
        isIgnoreLossFocus: false,
    }),
    retailSettings: {
        retailExpirationTime: 45_000,
        retailKioskIdleTime: 30_000,
        retailAuthorizationIdleTime: 30_000,
    },
    retailModals: createStores({
        isRetailModalDisabled: false,
        isRetailModalExpires: false,
        isRetailModalMaintenance: false,
        isRetailModalLoyalty: false,
        retailModalEvent: undefined as RetailEvent | undefined,
        retailModalBringYourDevice: undefined as RetailEvent | undefined,
        isNoBalanceModalVisible: false,
        isRetailModalDeposit: false,
        retailPrintingModal: { isPrinting: false } as { isPrinting: boolean; data?: Record<string, any> },
    }),
    retailPrinterEvent: undefined as PrintEvent | undefined,
    retailExpirationRefresh: false,
    retailSessionClosed: false,
    retailTerminalConfig: {
        timezone: '',
        networkSettings: { ip: '', dns: '', mask: '', gateway: '', isDhcp: false },
    } as RetailTerminalConfig,
    isRetailBalanceVisible: true,
    loyalty: {} as LoyaltyResponseKiosk,
    shopId: null as unknown as number,
});
