import React, { useEffect, useState } from 'react';
import { stores } from '../../../stores';
import { getCashbackInfo } from '../../../microservices/poker-playtech-gameslink';
import { translate } from '../../../services/translate';
import moment from 'moment';
import UiTable from '../../ui/table/UiTable';
import UiAlert from '../../ui/alert/UiAlert';
import { useInterval } from '../../../services/hooks';
import { DATE_YEAR_TIME_FORMAT, getFormattedDate } from '../../../services/date';
import { getActiveCurrency } from '../../../services/currency';
import { useStore } from '../../../hooks/useStore';

export default function PokerCashback() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [cashback, setCashback] = useState<any>({});
    const [error, setError] = useState();
    const currency = getActiveCurrency();

    useEffect(() => {
        loadCashback();
    }, []);

    async function loadCashback() {
        setError(undefined);
        try {
            const cashback = await getCashbackInfo();
            setCashback(cashback);
        } catch (error: any) {
            setError(error && error.message ? error.message : JSON.stringify(error));
        }
    }

    useInterval(loadCashback, 1000 * 60);

    if (!isAuthenticated) {
        return null;
    }

    function getFormattedPeriod(start, end) {
        return (
            <>
                <span>
                    {getFormattedDate({
                        date: start,
                        format: DATE_YEAR_TIME_FORMAT,
                        useMonthLetters: true,
                    })}
                </span>
                {' - '}
                <span>
                    {getFormattedDate({
                        date: moment(end).subtract(1, 'minutes').toString(),
                        format: DATE_YEAR_TIME_FORMAT,
                        useMonthLetters: true,
                    })}
                </span>
            </>
        );
    }

    return (
        <UiTable className="poker-cashback">
            {error && <UiAlert failure>{error}</UiAlert>}
            <div className="table-row heading">
                <div className="table-cell">{translate('previous-period', 'ui.poker')}</div>
                <div className="table-cell">{translate('current-period', 'ui.poker')}</div>
            </div>
            <div className="table-row">
                <div className="table-cell">
                    {getFormattedPeriod(cashback.previousWeekStart, cashback.previousWeekEnd)}
                </div>
                <div className="table-cell">
                    {getFormattedPeriod(cashback.currentWeekStart, cashback.currentWeekEnd)}
                </div>
            </div>
            <div className="table-row">
                <div className="table-cell">
                    {translate('rake', 'ui.poker')}: {cashback.previousRake} {currency}
                </div>
                <div className="table-cell">
                    {translate('rake', 'ui.poker')}: {cashback.currentRake} {currency}
                </div>
            </div>
            <div className="table-row">
                <div className="table-cell">
                    {translate('previous-cashback', 'ui.poker')}: {cashback.previousCashback} {currency}
                </div>
                <div className="table-cell">
                    {translate('potential-cashback', 'ui.poker')}: {cashback.potentialCashback} {currency}
                </div>
            </div>
        </UiTable>
    );
}
