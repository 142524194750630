import {
    getUserSubscriptionPreferences,
    insertUserSubscriptionPreferences,
    loadCrmMessageById,
    loadCrmMessages,
    OnsiteMessage,
    OnsiteMessageWithCampaign,
    trackOnSiteMessage,
    updateUserSubscriptionPreferences,
    UserCrmPreferences,
} from '../microservices/crm';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { storageGet } from './storage';
import moment from 'moment';
import { translate } from './translate';
import { logger } from './logger';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from './analytics';

export type OnsiteMessageStatus = 'pending' | 'received' | 'seen' | 'cta_clicked' | 'failed' | 'expired';

export async function getAllCrmMessages() {
    let messages: OnsiteMessage[] = [];
    try {
        messages = await loadCrmMessages();
    } catch (error) {
        logger.error('CrmService', 'getAllCrmMessages', error);
        return;
    }
    stores.crm.allMessages.set(messages);
    showUnreadMessages(messages);
}

async function showUnreadMessages(messages: OnsiteMessage[]) {
    const unreadMessages = messages.filter((message) => !message.seenAt && !moment().isAfter(message.expiresAt));
    if (!unreadMessages.length) {
        return;
    }

    stores.crm.unreadMessagesTotal.set(unreadMessages.length);
    await trackPendingMessagesAsReceived(unreadMessages);

    if (unreadMessages.length > 1) {
        openMultipleMessagesNotice();
        return;
    }

    displayCrmMessage(unreadMessages[0]);
}

async function trackPendingMessagesAsReceived(messages: OnsiteMessage[]) {
    const pendingMessages = messages.filter((message) => message.status === 'pending');
    for (const message of pendingMessages) {
        await trackOnSiteMessage(message.id, 'received');
    }
}

function updateUnreadMessagesCount(messages) {
    const unreadMessages = messages.filter((message) => !message.seenAt);
    stores.crm.unreadMessagesTotal.set(unreadMessages.length);
}

function updateInboxMessages(message) {
    const allMessages = getStoreValue(stores.crm.allMessages);
    allMessages.unshift(message);
    stores.crm.allMessages.set(allMessages);
    updateUnreadMessagesCount(allMessages);
}

function displayCrmMessage(unreadMessage: OnsiteMessage) {
    const timeNow = new Date();
    const lastMessageSeenTime = new Date(storageGet('lastCrmMessageSeenAt'));
    const hoursPassed = moment(timeNow, 'hours').diff(lastMessageSeenTime, 'hours');
    if (hoursPassed < 8) {
        return;
    }
    trackOnSiteMessage(unreadMessage.id, 'seen');
    stores.crm.onsite.set(unreadMessage);
    stores.crm.isMessageOpen.set(true);
}

export function closeCrmMessageAndUpdate() {
    const onsiteMessage = getStoreValue(stores.crm.onsite);

    const payload = {
        on_site_message_name: onsiteMessage?.campaignName,
        on_site_message_id: onsiteMessage?.campaignId,
    };
    trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.CRM_CLOSE_BUTTON_CLICKED, payload);

    if (onsiteMessage) {
        if (!onsiteMessage.seenAt && !(onsiteMessage as any).multiMessage) {
            updateMessage(onsiteMessage.id);
        }
        if ((onsiteMessage as any).multiMessage) {
            stores.crm.isInboxOpen.set(true);
        }
    }

    stores.crm.isMessageOpen.set(false);
    stores.crm.onsite.set(undefined);
}

function openMessage(message: OnsiteMessageWithCampaign, openedBy: 'system' | 'user') {
    if (message.status !== 'seen') {
        trackOnSiteMessage(message.id, 'seen');
    }
    stores.crm.isInboxOpen.set(false);
    stores.crm.isMessageOpen.set(true);
    stores.crm.onsite.set(message);
    stores.crm.openedBy.set(openedBy);
}

export async function openMessageBySystem(messageId: string) {
    const message = await loadCrmMessageById(messageId);
    if (!message) {
        return;
    }
    updateInboxMessages(message);
    openMessage(message, 'system');
}

export function openMessageByUser(message: OnsiteMessage) {
    openMessage(message, 'user');
}

function openMultipleMessagesNotice() {
    stores.crm.onsite.set({
        mascotMessage: getRandomMessage(),
        type: 'MASCOT_ONLY',
        multiMessage: true,
    } as any);
    stores.crm.isMessageOpen.set(true);
}

function updateMessage(messageId) {
    const allMessages = getStoreValue(stores.crm.allMessages);

    allMessages.map((message) => {
        if (message.id === messageId) {
            message.seenAt = new Date();
            message.status = 'seen';
        }
        return message;
    });

    const unreadMessages = allMessages.filter((message) => !message.seenAt);
    stores.crm.unreadMessagesTotal.set(unreadMessages.length);
}

function getRandomMessage() {
    const MULTIPLE_CRM_MESSAGES_NOTICE = [
        translate('crm.multiple-message-notice-one'),
        translate('crm.multiple-message-notice-two'),
        translate('crm.multiple-message-notice-three'),
    ];
    const totalAmountOfMessage = MULTIPLE_CRM_MESSAGES_NOTICE.length;
    const randomized = Math.floor(Math.random() * Math.floor(totalAmountOfMessage));
    return MULTIPLE_CRM_MESSAGES_NOTICE[randomized];
}

export async function loadUserCrmPreferences() {
    try {
        const preferences = await getUserSubscriptionPreferences();
        if (preferences) {
            stores.crm.userPreferences.set(preferences);
        }
    } catch (error) {
        logger.error('CrmService', 'loadUserCrmPreferences', error);
    }
}

export async function insertUserCrmPreferences(body: Omit<UserCrmPreferences, 'user_id'>) {
    try {
        await insertUserSubscriptionPreferences(body);
        stores.crm.userPreferences.set(body);
    } catch (error) {
        logger.error('CrmService', 'insertUserCrmPreferences', error);
    }
}

export async function updateUserCrmPreferences(body: Omit<UserCrmPreferences, 'user_id'>) {
    try {
        await updateUserSubscriptionPreferences(body);
        stores.crm.userPreferences.set(body);
    } catch (error) {
        logger.error('CrmService', 'updateUserCrmPreferences', error);
    }
}
