import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { LeaderBoardTicket } from '../../../microservices/sbgate';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../services/analytics';
import { copyTicketToBetslipByOutcomeIds } from '../../../services/sports/betslip';
import { useCashoutDoneRefreshTicket } from '../../../services/sports/cashout';
import { Ticket } from '../../../services/sports/types';
import { useOddsFormat } from '../../../services/sports/user-settings';
import { translate } from '../../../services/translate';
import { stores } from '../../../stores';
import UiButton from '../../ui/button/UiButton';
import UiDotsLoader from '../../ui/dots-loader/UiDotsLoader';
import UiFlag from '../../ui/flag/UiFlag';
import SportBadgeTicketPlatform from '../badge/ticket-platform/SportBadgeTicketPlatform';
import SportBadgeTicketStatus from '../badge/ticket-status/SportBadgeTicketStatus';
import SportTicketDetails from './details/SportTicketDetails';
import SportTicketMatchInfo from './match-info/SportTicketMatchInfo';
import Wrapper from './styles';
import SportTicketTotals from './totals/SportTicketTotals';
import SportCategoryIcon from '../category-icon/SportCategoryIcon';
import { DATE_YEAR_TIME_FORMAT, getFormattedDate } from '../../../services/date';
import { filterStyleProps } from '../../../styles/utils';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

interface Props {
    ticket: Ticket | LeaderBoardTicket;
    isCompactView?: boolean;
    isPublic?: boolean;
    isLeaderboard?: boolean;
    isBetHistory?: boolean;
}

export default function SportTicket({
    ticket: propsTicket,
    isPublic,
    isCompactView,
    isLeaderboard,
    isBetHistory = false,
    ...rest
}: Props) {
    const [isTicketDetailsVisible, setIsTicketDetailsVisible] = useState(false);
    const [{ isPhone, isDesktop }] = useStore(media);
    const [isCopyingTicket, setIsCopyingTicket] = useState(false);
    const localTicket = useCashoutDoneRefreshTicket(propsTicket);
    const isCompactViewEnabled = isCompactView || isPhone;
    const [isTicketLoading, setIsTicketLoading] = useState(false);
    const [isBetslipOpen, setIsBetslipOpen] = useStore(stores.isBetslipOpen);

    useOddsFormat();

    useEffect(() => {
        setIsTicketDetailsVisible(false);
    }, [propsTicket]);

    if (!propsTicket) {
        return null;
    }
    const ticket: any = localTicket || propsTicket;
    const firstMatch = ticket.first_match;

    async function copyTicketToBetSlip() {
        setIsCopyingTicket(true);
        await copyTicketToBetslipByOutcomeIds(ticket.outcomeIds, ticket.id);
        if (isPhone) {
            setIsBetslipOpen(!isBetslipOpen);
        }
        setIsCopyingTicket(false);
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.INCOMING_BETS_COPIED);
    }

    return (
        <Wrapper
            {...filterStyleProps({ ...rest })}
            $isCompactViewEnabled={isCompactViewEnabled}
            $isPublic={isPublic}
            $isTicketDetailsVisible={isTicketLoading}
        >
            {!isTicketLoading && (
                <div className={classNames('bet-ticket', { loaded: isTicketDetailsVisible })}>
                    <div className="bet-match" onClick={() => setIsTicketDetailsVisible(true)}>
                        {firstMatch && !isCompactView && (
                            <div className="bet-sport" onClick={() => setIsTicketDetailsVisible(true)}>
                                <SportCategoryIcon small icon={firstMatch.sport_icon} />
                            </div>
                        )}
                        <SportTicketMatchInfo
                            ticket={ticket}
                            isCompactView={isCompactView}
                            isLeaderboard={isLeaderboard}
                            isPublic={isPublic}
                        />
                    </div>

                    {
                        <SportTicketTotals
                            ticket={ticket}
                            isLeaderboard={isLeaderboard}
                            isPublic={Boolean(isPublic)}
                            isCopyingTicket={isCopyingTicket}
                            isCompactView={isCompactView}
                        />
                    }

                    {isPublic && (
                        <>
                            <div className="bet-location">
                                <UiFlag flag={ticket.country} />
                                <div className="location-city">{ticket.city}</div>
                            </div>

                            <div className="copy-ticket-button">
                                <UiButton color="primary" onClick={copyTicketToBetSlip} isLoading={isCopyingTicket}>
                                    {isDesktop && translate('ui.sportsbook.betsfeed.copy-ticket')}
                                    <span style={{ fontFamily: 'monospace' }}>
                                        {!isDesktop && translate('+', 'ui.sportsbook')}
                                    </span>
                                </UiButton>
                            </div>
                        </>
                    )}

                    {!isPublic && !isCompactViewEnabled && (
                        <div className="bet-info" onClick={() => setIsTicketDetailsVisible(true)}>
                            <SportBadgeTicketPlatform ticket={ticket} />
                            <SportBadgeTicketStatus ticket={ticket} className="ticket-status" />
                            {!isLeaderboard && (
                                <div className="bet-dates">
                                    {getFormattedDate({
                                        date: ticket.created_at,
                                        format: DATE_YEAR_TIME_FORMAT,
                                        useMonthLetters: true,
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}

            {isTicketDetailsVisible && !isTicketLoading && !isLeaderboard && <UiDotsLoader />}

            {isTicketDetailsVisible && (
                <SportTicketDetails
                    ticket={ticket}
                    onTicketClosed={() => {
                        setIsTicketDetailsVisible(false);
                        setIsTicketLoading(false);
                    }}
                    onLoadingAccomplished={() => setIsTicketLoading(true)}
                    isPublic={isPublic}
                    isCompactView={isCompactView}
                    isCopyingTicket={isCopyingTicket}
                    isLeaderboard={isLeaderboard}
                    onTicketCopy={copyTicketToBetSlip}
                    isBetHistory={isBetHistory}
                />
            )}
        </Wrapper>
    );
}
