import React, { useEffect, useState } from 'react';
import { getLeaderboardCounter } from '../../../../microservices/sb-campaigns';

interface Props {
    'campaign-id': number;
}

export default function CmsLeaderboardCounter({ 'campaign-id': campaignId }: Props) {
    const [leaderboardCount, setLeaderboardCount] = useState(0);

    useEffect(() => {
        fetchLoaderboardCount();
    }, []);

    async function fetchLoaderboardCount() {
        setLeaderboardCount(await getLeaderboardCounter(campaignId));
    }

    return <span>{leaderboardCount}</span>;
}
