import React from 'react';
import Wrapper from './styles';
import SportMatchName from '../../../name/SportMatchName';
import SportMatchComments from '../../../comments/SportMatchComments';
import { MATCH_STATUS } from '../../../../../../services/sports/constants';
import SportMatchScore from '../../../score/SportMatchScore';
import { SportMatchLayoutComponentParams as Props } from '../../../../../../services/sports/types';
import SportMatchExtra from '../../../extra/SportMatchExtra';
import { isRetail } from '../../../../../../services/environment';

export default function SportMatchLayoutAmericanHead({ match }: Props) {
    const isLiveNow = match.status === MATCH_STATUS.LIVE;
    const isRetailLayout = isRetail();

    return (
        <Wrapper>
            <div className="match-main-info">
                <div className="match-name">
                    <SportMatchName match={match} isCompact />
                    {isLiveNow && (
                        <SportMatchScore
                            isLivebetOur={match.livebet_our}
                            sportId={match.sport}
                            sportCategoryId={match.sport_category}
                            matchId={match.id}
                            isCompact
                        />
                    )}
                </div>
                <SportMatchComments match={match} />
            </div>

            {!isRetailLayout && (
                <>
                    <div className="match-extra">
                        <SportMatchExtra match={match} isVertical isSecondary />
                    </div>
                </>
            )}
        </Wrapper>
    );
}
