import React from 'react';
import SportMatchInfo from '../../../info/SportMatchInfo';
import SportMatchName from '../../../name/SportMatchName';
import { useTurnoverMarket } from '../../../../../../services/sports/top-markets';
import SportMatchTurnover from '../../../turnover/SportMatchTurnover';
import Wrapper from './styles';
import { MATCH_STATUS, MATCH_TYPE } from '../../../../../../services/sports/constants';
import SportMatchComments from '../../../comments/SportMatchComments';
import SportMatchMarketsTopMarkets from '../../../markets/top-markets/SportMatchMarketsTopMarkets';
import SportMatchTime from '../../../time/SportMatchTime';
import SportMatchTimeLive from '../../../time/live/SportMatchTimeLive';
import SportMatchScore from '../../../score/SportMatchScore';
import { useSportsMatchContext } from '../../../../../../services/sports/match-helpers';
import UiCloseButton from '../../../../../ui/close-button/UiCloseButton';
import { getRoute } from '../../../../../../services/router';
import { SportMatchLayoutComponentParams as Props } from '../../../../../../services/sports/types';
import SportBadge from '../../../../badge/SportBadge';
import SportMatchExtra from '../../../extra/SportMatchExtra';
import { translate } from '../../../../../../services/translate';
import UiButton from '../../../../../ui/button/UiButton';

export default function SportMatchLayoutAmericanPhone({ match }: Props) {
    const turnoverMarket = useTurnoverMarket(match);
    const isLiveNow = match.status === MATCH_STATUS.LIVE;
    const isPrematch = match.status === MATCH_STATUS.OPEN && match.match_start;
    const { turnoverVisible, isOpen, setContext: setMatchContext } = useSportsMatchContext();
    const matchRoute = isLiveNow ? `${getRoute('sport.live')}/${match.id}` : `${getRoute('sport.match')}/${match.id}`;
    const isMatchPage = window.location.pathname.includes(matchRoute);
    const isLivebetMatch = !isLiveNow && match.inplay;
    const shouldHaveGeniusStream = match.has_genius_stream;
    const isNormalMatch = match.match_type !== MATCH_TYPE.OUTRIGHT;

    return (
        <>
            <Wrapper className="match-container" isOpen={isOpen}>
                {isLiveNow && (
                    <div className="match-time">
                        <SportMatchTimeLive
                            matchId={match.id}
                            sportId={match.sport}
                            isOurLivebet={match.livebet_our}
                            isCompact
                        />
                    </div>
                )}
                {isPrematch && (
                    <div className="match-time">
                        <SportMatchTime
                            time={match.match_type === MATCH_TYPE.OUTRIGHT ? match.betting_end : match.match_start}
                            wrap
                            vertical
                        />
                    </div>
                )}
                <div className="match-main-content">
                    <div className="match-name">
                        <SportMatchName match={match} isCompact />
                        {isLiveNow && (
                            <div className="match-live">
                                <div className="match-score">
                                    <SportMatchScore
                                        isLivebetOur={match.livebet_our}
                                        matchId={match.id}
                                        sportId={match.sport}
                                        sportCategoryId={match.sport_category}
                                        isCompact
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <SportMatchMarketsTopMarkets match={match} marketsToShow={3} />
                    <div className="match-info">
                        <SportMatchInfo match={match} />
                        {(isLivebetMatch && !(match.stream_info || shouldHaveGeniusStream)) || isLiveNow ? (
                            isLiveNow ? (
                                <SportBadge type={MATCH_STATUS.LIVE} />
                            ) : (
                                <SportBadge type={MATCH_TYPE.LIVEBET} />
                            )
                        ) : null}
                    </div>
                    <SportMatchComments match={match} />
                    {!isNormalMatch && !isOpen && (
                        <UiButton
                            block
                            size="small"
                            className="show-more-odds"
                            color="outline"
                            onClick={() => setMatchContext?.({ isOpen: true })}
                        >
                            {translate('Show odds', 'ui.sportsbook')}
                        </UiButton>
                    )}
                </div>

                {isOpen && !isMatchPage && (
                    <UiCloseButton
                        onClick={() => setMatchContext?.({ isOpen: false })}
                        size={24}
                        className="close-button"
                    />
                )}

                <div className="match-extra">
                    <SportMatchExtra match={match} isVertical />
                </div>
            </Wrapper>
            {turnoverVisible && <SportMatchTurnover matchId={match.id} mainMarket={turnoverMarket} />}
        </>
    );
}
