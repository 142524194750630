import styled from 'styled-components';

export default styled.footer`
    display: flex;
    flex-direction: column;
    margin-top: var(--spacing-16);
    &.hidden {
        display: none;
    }
`;
