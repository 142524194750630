import styled from 'styled-components';

export default styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    .option-button {
        margin-left: 0.5rem;
    }

    .transfer-message {
        color: var(--font-color-secondary);
        font-size: var(--font-12);
        font-weight: 600;
        margin-left: 0.5rem;
        text-transform: uppercase;
    }
`;
