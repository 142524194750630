import React, { ReactNode } from 'react';
import { Modal, SwipeableDrawer } from '@mui/material';
import Wrapper from './styles';
import UiCloseButton from '../close-button/UiCloseButton';
import { filterStyleProps } from '../../../styles/utils';

interface Props {
    children?: any;
    disableEnforceFocus?: boolean;
    $disableBackdropClick?: boolean;
    shouldDisplayCloseButton?: boolean;
    maxHeight?: number | string;
    maxWidth?: number | string;
    minWidth?: number | string;
    onClose?: () => void;
    onOpen?: () => void;
    open?: boolean;
    className?: any;
    mode?: 'default' | 'drawer';
    title?: string;
    icon?: ReactNode;
    paperProps?: any;
    centered?: boolean;
}

export default function UiModal(props: Props) {
    const {
        open = true,
        children,
        onClose = () => {},
        onOpen = () => {},
        mode = 'default',
        icon,
        shouldDisplayCloseButton = true,
        title,
        disableEnforceFocus = false,
        $disableBackdropClick: disableBackdropClick = false,
    } = props;
    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

    if (mode === 'drawer') {
        return (
            <SwipeableDrawer
                open={open}
                onClose={onClose}
                anchor="bottom"
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                disableSwipeToOpen={true}
                onOpen={onOpen}
                PaperProps={{
                    sx: {
                        backgroundColor: 'var(--surface-level-2-bg)',
                        backgroundImage: 'none',
                        ...props.paperProps,
                    },
                }}
            >
                <Wrapper {...filterStyleProps(props)} mode={mode} iOS={iOS}>
                    <div className="cutout">
                        <div className="holder" />
                    </div>
                    {onClose && shouldDisplayCloseButton && (
                        <UiCloseButton onClick={onClose} className="new-close-button" />
                    )}
                    {icon && <div className="ui-modal-icon">{icon}</div>}
                    {title && <div className="ui-modal-title">{title}</div>}
                    {open && children}
                </Wrapper>
            </SwipeableDrawer>
        );
    } else {
        return (
            <Modal
                open={open}
                onClose={(_, reason) => {
                    if (disableBackdropClick && reason === 'backdropClick') {
                        return;
                    }
                    onClose();
                }}
                disableEnforceFocus={disableEnforceFocus}
            >
                <Wrapper {...filterStyleProps(props)} mode={mode}>
                    {onClose && shouldDisplayCloseButton && (
                        <UiCloseButton onClick={onClose} className="new-close-button" />
                    )}
                    {icon && <div className="ui-modal-icon">{icon}</div>}
                    {title && <div className="ui-modal-title">{title}</div>}
                    {children && <div className="ui-modal-content">{children}</div>}
                </Wrapper>
            </Modal>
        );
    }
}
