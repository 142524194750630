import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    $isRetailLayout?: boolean;
};

export default styled.div<StyledProps>`
    position: relative;
    > * {
        font-size: 0.8rem;
        width: 100%;
        height: ${({ $isRetailLayout }) => ($isRetailLayout ? '50px' : '32px')};
        @media ${mediaQuery.isPhone} {
            font-size: 0.875rem;
            height: 32px;
        }
    }
    .quick-stake {
        padding: 0.5rem;
    }

    .quick-stake-bubble {
        display: none;
        opacity: 0;
        position: absolute;
        top: 0;
        animation: animateBubble 700ms linear infinite;
        color: var(--betslip-stake-label-color);

        &.active {
            display: block;
        }
    }

    @keyframes animateBubble {
        0% {
            transform: translate(0, 0);
            opacity: 1;
        }
        100% {
            transform: translate(0, -250%);
            opacity: 0;
        }
    }
`;
