import React from 'react';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import UiDotsLoader from '../../../ui/dots-loader/UiDotsLoader';
import Wrapper from './styles';
import UiBox from '../../../ui/box/UiBox';
import { useStore } from '../../../../hooks/useStore';

export default function GeocomplyFeedbackFull() {
    const [geocomplyClient] = useStore(stores.geocomply.client);
    const [serviceError] = useStore(stores.geocomply.serviceError);
    const [unexpectedError] = useStore(stores.geocomply.unexpectedError);

    const isProcessing = geocomplyClient.isAttemptingConnection || geocomplyClient.isAttemptingGeolocation;
    const hasErrors = geocomplyClient.clientError || geocomplyClient.hint || serviceError || unexpectedError;

    return (
        <Wrapper>
            <UiBox>
                {geocomplyClient.isAttemptingConnection && (
                    <div className="status connecting">
                        <span className="title">GeoComply</span>
                        <span>{translate('Establishing connection', 'ui.geocomply')}</span>
                        <span className="loader">
                            <UiDotsLoader />
                        </span>
                    </div>
                )}

                {geocomplyClient.isAttemptingGeolocation && (
                    <div className="status geoverifying">
                        <span className="title">GeoComply</span>
                        <span>{translate('Geolocating', 'ui.geocomply')}</span>
                        <span className="loader">
                            <UiDotsLoader />
                        </span>
                    </div>
                )}

                {!isProcessing && !hasErrors && (
                    <div className="status geoverified">
                        <span className="title">GeoComply</span>
                        <span>{translate('Successfully geoverified', 'ui.geocomply')}</span>
                    </div>
                )}
            </UiBox>
        </Wrapper>
    );
}
