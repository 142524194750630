import { parse } from 'query-string';
import { useState, useContext } from 'react';
import cmsArticleRoutes from '../cms-article-routes.json';
import routes from '../routes.json';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { storageGet, storageSet } from './storage';
import { fetchPrematchTree } from '../microservices/sbgate';
import { getFlatCategories } from './sports/sport-category';
import { matchPath } from 'react-router-dom';
import { __RouterContext } from 'react-router';
import { logger } from './logger';
import { isB2B } from './environment';
import { FEATURE, isFeatureAvailable } from './feature';
import { PRODUCT } from '../types/common';
import { media } from '../stores/media/media';
import { useStore } from '../hooks/useStore';

export function useRouter() {
    const router = useContext<Router>(__RouterContext);

    return {
        location: router.location,
        history: router.history,
        navigateTo: router.history.push,
        params: (router.match && router.match.params) || {},
        queryParams: parse(router.history.location.search),
        goBack: router.history.goBack,
        pathname: router.location.pathname,
        fullUrl: window.location.href,
    };
}

export function getArticlePath(articleSlug) {
    return `${getRoute('cms')}/${articleSlug}`;
}

export function getArticleRoute(articleRouteKey: string, baseRouteKey?: string) {
    if (!cmsArticleRoutes[articleRouteKey]) {
        throw new Error(`Article route ${articleRouteKey} not found in cms-article-routes.json`);
    }

    const translatedArticleRoute = translateArticleKey(articleRouteKey);
    return baseRouteKey ? `${getRoute(baseRouteKey)}/${translatedArticleRoute}` : withLanguage(translatedArticleRoute);
}

export function getRoute(key: string, language?: string) {
    if (!routes[key]) {
        throw new Error(`Route ${key} not found in routes.json`);
    }

    const routeParts = key.split('.');

    let translatedRoute = translateKey(routeParts[0], language);
    let currentTranslation = routeParts[0];
    routeParts.shift();

    routeParts.forEach((part) => {
        currentTranslation += `.${part}`;
        translatedRoute += `/${translateKey(currentTranslation, language)}`;
    });
    return withLanguage(translatedRoute, language);
}

export function translateArticleKey(articleKey) {
    const language = getStoreValue(stores.language);
    return cmsArticleRoutes[articleKey][language];
}

function translateKey(key, languageOverride) {
    const language = languageOverride || getStoreValue(stores.language);
    return routes[key][language];
}

function withLanguage(translatedRoute: string, languageOverride?: string) {
    const language = languageOverride || getStoreValue(stores.language);
    return `/${language}/${translatedRoute}`;
}

export async function updateSiteLanguage(language) {
    const { pathname } = window.location;
    const urlParams = window.location.search || '';
    const translatedUrl = await translateUrlToLanguage(pathname, language);

    const applicationType = getStoreValue(stores.applicationType);
    if (applicationType !== 'poker-download') {
        window.location.href = translatedUrl + urlParams;
    }
}

export async function translateUrlToLanguage(pathname, targetLanguage, keepUntranslatedUrl = false) {
    const [currentLanguage, ...urlSegments] = decodeURI(pathname).split('/').slice(1);

    let routeKeyPrefix = '';
    let routeKey;
    for (const urlSegment of urlSegments) {
        routeKey = findUrlSegmentKeyAndTranslatedValue({
            urlSegment,
            currentLanguage,
            routeKeyPrefix,
        });
        if (!routeKey) {
            break;
        }
        routeKeyPrefix = routeKey;
    }
    routeKey = routeKey || routeKeyPrefix;

    if (routeKey === 'sport') {
        let flatCategories = getStoreValue(stores.sports.flatCategories);
        if (!flatCategories) {
            return getRoute('sport', targetLanguage);
        }
        let category = flatCategories.filter((category) => category.fullSlug === urlSegments.slice(1).join('/'))[0];
        if (category) {
            const categoryId = category.id;
            try {
                flatCategories = getFlatCategories(await fetchPrematchTree(targetLanguage));
                category = flatCategories.filter((category) => category.id === categoryId)[0];
                return `${getRoute('sport', targetLanguage)}/${category.fullSlug}`;
            } catch (e) {
                logger.error('RouterService', 'translateUrlToLanguage', e);
            }
        }
    }

    if (routeKey === 'casino.slots' || routeKey === 'casino.live') {
        const categorySlug = urlSegments.slice(routeKey.split('.').length).join('/');
        const categoriesBySlug = getStoreValue(stores.casino.categoriesBySlug);

        const currentCategory = categoriesBySlug[categorySlug];
        return `${getRoute(routeKey, targetLanguage)}/${currentCategory?.id || categorySlug}`;
    }

    const currentArticleSlug = getStoreValue(stores.cms.currentArticleSlugByLanguage); // TODO: doesn't work during initial page load
    if (currentArticleSlug) {
        const translatedSlug = currentArticleSlug[targetLanguage] || currentArticleSlug[currentLanguage];
        if (routeKey) {
            return `${getRoute(routeKey, targetLanguage)}/${translatedSlug}`;
        }
        return `/${targetLanguage}/${translatedSlug}`;
    }

    const activeArticle: any = getStoreValue(stores.blog.activeArticle); // TODO: doesn't work during initial page load
    const activeArticleSlug = activeArticle && activeArticle.slug;
    if (activeArticleSlug) {
        const translatedSlug = activeArticleSlug[targetLanguage] || activeArticleSlug[currentLanguage];
        if (routeKey) {
            return `${getRoute(routeKey, targetLanguage)}/${translatedSlug}`;
        }
        return `/${targetLanguage}/${getRoute('blog', targetLanguage)}/${translatedSlug}`;
    }

    const untranslatedUrlSegments = urlSegments.slice(routeKey.split('.').length).join('/');

    if (routeKey) {
        const basePath = getRoute(routeKey, targetLanguage);
        return untranslatedUrlSegments ? `${basePath}/${untranslatedUrlSegments}` : basePath;
    }

    if (keepUntranslatedUrl) {
        return `/${targetLanguage}/${urlSegments?.join('/')}`;
    }

    return getLastActiveProductRoute(targetLanguage);
}

function findUrlSegmentKeyAndTranslatedValue({ urlSegment, currentLanguage, routeKeyPrefix }) {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const depth = routeKeyPrefix ? routeKeyPrefix.split('.').length + 1 : 1;
    return Object.keys(routes)
        .filter((key) => key.split('.').length === depth)
        .filter((key) => key.startsWith(routeKeyPrefix || ''))
        .find((routeKey) => routes[routeKey][currentLanguage] === urlSegment);
}

export function isActiveRoute(route, exact = true) {
    const path = `${window.coolb2b?.basePath || ''}${route}`;
    return Boolean(
        matchPath(window.location.pathname, {
            path,
            exact,
        }),
    );
}

export function isActiveRouteBySlug(slug, child?) {
    if (slug === 'winter-sports' && child) {
        return child.children.find((child) => isActiveRouteBySlug(child.slug));
    }
    return window.location && window.location.pathname && window.location.pathname.split('/').includes(slug);
}

export function useRoutes(allRoutes) {
    const [routes] = useState(allRoutes.filter(Boolean));

    return routes;
}

export function setLastActiveProduct(product) {
    storageSet('lastActiveProduct', product);
}

export function getLastActiveProductRoute(language?: string) {
    const lastActiveProduct = storageGet<string>('lastActiveProduct');

    const routeByProduct = {
        [PRODUCT.CASINO]: 'casino',
        [PRODUCT.DEPOSIT]: 'deposit',
        [PRODUCT.MOBILE_LANDING]: 'mobile-landing',
        [PRODUCT.POKER]: 'poker',
        [PRODUCT.SPORTSBOOK]: 'sport',
        [PRODUCT.VIRTUAL_SPORTS]: 'virtual-sports',
    };

    const route =
        routeByProduct[lastActiveProduct] || (isFeatureAvailable(FEATURE.WELCOME_PAGE_ROUTE) ? 'welcome' : 'sport');
    return getRoute(route, language);
}

export function getHomeRoute() {
    const [{ isPhone, isTablet }] = useStore(media);
    return (isPhone || isTablet) && !isB2B() ? getRoute('mobile-landing') : getLastActiveProductRoute();
}

type Router = {
    history: {
        action: string;
        block: (prompt) => void;
        createHref: (location) => void;
        go: (n) => void;
        goBack: () => void;
        goForward: () => void;
        length: number;
        listen: (listener) => void;
        location: RouterLocation;
        push: (path: string, state?: string) => void;
        replace: (path: string, state?: string) => void;
    };
    location: RouterLocation;
    match: {
        path: string;
        url: string;
        params: Record<string, any>;
        isExact: boolean;
    };
    staticContext?: unknown;
};
type RouterLocation = {
    pathname: string;
    search: string;
    hash: string;
    state: null;
    key: string;
};
