import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import BlogPreview from './preview/BlogPreview';
import { withErrorBoundary } from '../../higher-order-components/error-boundary';
import { loadArticlesByParams } from '../../microservices/l10n';
import Loader from '../loader/Loader';
import { stores } from '../../stores';
import { translate } from '../../services/translate';
import { useRouter } from '../../services/router';
import UiButton from '../ui/button/UiButton';
import { getUserMarketCode } from '../../services/user';
import { getStoreValue } from '../../stores/store/utils';
import { media } from '../../stores/media/media';
import { useStore } from '../../hooks/useStore';

function Blog() {
    const { blogCategory } = useRouter().params;
    const [isLoading, setIsLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [offset, setOffset] = useState(0);
    const [articles] = useStore(stores.blog.activeCategoryArticles);
    const [{ isDesktop }] = useStore(media);

    const articlesPerPage = isDesktop ? 8 : 9;

    useEffect(() => {
        loadArticles();
    }, [offset, blogCategory]);

    useEffect(() => {
        setOffset(0);
        stores.blog.activeCategoryArticles.set([]);
        setHasMore(false);
    }, [blogCategory]);

    async function loadArticles() {
        setIsLoading(true);

        const limit = offset === 0 ? articlesPerPage + 2 : articlesPerPage + 1;

        const newArticles = await loadArticlesByParams({
            category: blogCategory,
            offset,
            limit,
            market: getUserMarketCode(),
        });

        const hasMoreArticles = newArticles.length === limit;

        setHasMore(Boolean(hasMoreArticles));

        if (hasMoreArticles) {
            newArticles.pop();
        }

        const articles = getStoreValue(stores.blog.activeCategoryArticles);
        stores.blog.activeCategoryArticles.set([...articles, ...newArticles]);

        setIsLoading(false);
    }

    function handleLoadMoreClick() {
        if (isLoading) {
            return;
        }

        setOffset(offset === 0 ? articlesPerPage + 1 : offset + articlesPerPage);
    }

    return (
        <Wrapper>
            {isLoading && <Loader />}
            {!isLoading && !articles && <div>{translate('No articles', 'ui.blog')}</div>}

            {articles && articles.length > 0 && (
                <div className="blog-highlighted">
                    <div className="blog-highlighted-preview" key={articles[0].id}>
                        <BlogPreview
                            key={articles[0].id}
                            article={articles[0]}
                            isHighlightedArticle
                            excerptLength={150}
                        />
                    </div>
                </div>
            )}

            <div className="blog-articles">
                {articles.slice(1).map((article) => (
                    <div className="blog-articles-preview" key={article.id}>
                        <BlogPreview key={article.id} article={article} />
                    </div>
                ))}
            </div>

            {articles.length > 0 && hasMore && (
                <UiButton
                    className="load-more-button"
                    color="primary"
                    isLoading={isLoading}
                    disabled={isLoading}
                    onClick={handleLoadMoreClick}
                >
                    {translate('Load more', 'ui.common')}
                </UiButton>
            )}
        </Wrapper>
    );
}

export default withErrorBoundary(Blog);
