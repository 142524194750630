import React from 'react';
import { useProofOfPaymentVerificationContext } from '../../../../../contexts/proof-of-payment-verification/ProofOfPaymentVerificationContext';
import AccountVerificationMultiStepFooter from '../../multi-step/footer/AccountVerificationMultiStepFooter';
import AccountVerificationProofOfPaymentSteps from '../steps/AccountVerificationProofOfPaymentSteps';

interface Props {
    nextButtonText?: string;
}

export default function AccountVerificationProofOfPaymentFooter(props: Props) {
    const { activeStep, setActiveStep, stepCompletionState, isSecondStepSkipped } =
        useProofOfPaymentVerificationContext();
    return (
        <AccountVerificationMultiStepFooter
            {...props}
            isNextButtonDisabled={!stepCompletionState[activeStep]}
            isPreviousButtonVisible={activeStep > 0}
            onPreviousButtonClick={() =>
                setActiveStep(isSecondStepSkipped && activeStep === 2 ? 0 : Math.max(activeStep - 1, 0))
            }
            onNextButtonClick={() =>
                setActiveStep(Math.min(activeStep + 1, AccountVerificationProofOfPaymentSteps.length - 1))
            }
        />
    );
}
