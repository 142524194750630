import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    $background?: string;
};

export default styled.div<StyledProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 500px;
    min-width: 320px;
    max-width: 320px;
    background-image: url(${({ $background }) => $background && $background});
    background-size: cover;
    background-color: var(--surface-level-1-bg);
    border: var(--hotevents-border);
    border-radius: var(--welcome-ui-radius);
    color: var(--hotevents-color-secondary);
    overflow: hidden;
    box-shadow: var(--ui-shadow);
    @media ${mediaQuery.isTablet} {
        height: 480px;
        min-width: 300px;
        max-width: 300px;
    }

    .hot-event-category {
        position: absolute;
        top: 1rem;
        right: 1rem;
        display: flex;
        align-items: center;
        color: var(--hotevents-color-secondary);
        background: var(--color-body-bg);
        box-shadow: inset -1px -1px 2px var(--color-primary);
        border-radius: 100px;
        padding: 0.5rem 1rem;
        @media ${mediaQuery.isTablet} {
            top: 1rem;
            right: 1rem;
            font-size: 0.8rem;
            padding: 0.2rem 0.5rem;
        }
        > *:not(:last-of-type) {
            margin-right: 0.4rem;
        }
    }
    .hot-event-description {
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering};
        padding: 2rem;
        margin-top: auto;
        .description-title {
            font-size: 1.4rem;
            margin-bottom: 1rem;
            font-weight: 900;
            color: var(--hotevents-color-primary);
            @media ${mediaQuery.isTablet} {
                font-size: 1.2rem;
            }
            em {
                font-weight: 300;
                font-style: italic;
            }
        }
        .description-text {
            font-size: 1rem;
            line-height: 1.3;
            color: var(--hotevents-color-secondary);
            @media ${mediaQuery.isTablet} {
                font-size: 0.9rem;
            }
        }
    }
    .coming-soon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.5rem;
        color: var(--font-color-secondary);
        text-align: center;
        line-height: 1;
        z-index: -1;
    }
`;
