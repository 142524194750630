import React from 'react';

const defaultValue = {
    isMarketTitleVisible: true,
    isShowSportCategoryIcon: true,
};

export const SportsVisualSettingsContext = React.createContext(defaultValue);

export const useSportsVisualSettings = () => React.useContext(SportsVisualSettingsContext);
