import React from 'react';
import { withErrorBoundary } from '../../../../higher-order-components/error-boundary';
import Wrapper from './styles';
import SportMatchScoreboardBetgenius from './betgenius/SportMatchScoreboardBetgenius';
import SportMatchScoreboardInhouse from './inhouse/SportMatchScoreboardInhouse';
import SportMatchScoreboardBgRounds from './bg-rounds/SportMatchScoreboardBgRounds';
import SportMatchScoreboardBgIcehockey from './bg-icehockey/SportMatchScoreboardBgIcehockey';
import SportMatchScoreboardBgAmericanFootball from './bg-american-football/SportMatchScoreboardBgAmericanFootball';
import SportMatchScoreboardBgFootball from './bg-football/SportMatchScoreboardBgFootball';
import SportMatchScoreboardBgBaseball from './bg-baseball/SportMatchScoreboardBgBaseball';
import SportMatchScoreboardBgDarts from './bg-darts/SportMatchScoreboardBgDarts';
import SportMatchScoreboardBgTennis from './bg-tennis/SportMatchScoreboardBgTennis';
import SportMatchScoreboardBgBasketball from './bg-basketball/SportMatchScoreboardBgBasketball';
import { ScoreboardType } from '../../../../services/sports/types';
import { getScoreboardType } from '../../../../services/sports/inplay-helpers';
import { isScoreboardEnabled } from '../../../../services/utils/b2b';
import { FoCategoryMatch } from '@staycool/sbgate-types';

interface Props {
    match: FoCategoryMatch;
}

function SportMatchScoreboard({ match }: Props) {
    const scoreboardType = getScoreboardType(match);

    return (
        <Wrapper scoreboardType={scoreboardType} sportId={match.sport} sportSlug={match.sport_slug}>
            {isScoreboardEnabled(scoreboardType, ScoreboardType.bgRounds) && (
                <SportMatchScoreboardBgRounds match={match} />
            )}
            {isScoreboardEnabled(scoreboardType, ScoreboardType.bgIcehockey) && (
                <SportMatchScoreboardBgIcehockey match={match} />
            )}
            {isScoreboardEnabled(scoreboardType, ScoreboardType.bgAmericanFootball) && (
                <SportMatchScoreboardBgAmericanFootball match={match} />
            )}
            {isScoreboardEnabled(scoreboardType, ScoreboardType.betgenius) && (
                <SportMatchScoreboardBetgenius match={match} />
            )}
            {isScoreboardEnabled(scoreboardType, ScoreboardType.inhouse) && (
                <SportMatchScoreboardInhouse match={match} />
            )}
            {isScoreboardEnabled(scoreboardType, ScoreboardType.bgFootball) && (
                <SportMatchScoreboardBgFootball match={match} />
            )}
            {isScoreboardEnabled(scoreboardType, ScoreboardType.bgDarts) && (
                <SportMatchScoreboardBgDarts match={match} />
            )}
            {isScoreboardEnabled(scoreboardType, ScoreboardType.bgBaseball) && (
                <SportMatchScoreboardBgBaseball match={match} />
            )}
            {isScoreboardEnabled(scoreboardType, ScoreboardType.bgTennis) && (
                <SportMatchScoreboardBgTennis match={match} />
            )}
            {isScoreboardEnabled(scoreboardType, ScoreboardType.bgBasketball) && (
                <SportMatchScoreboardBgBasketball match={match} />
            )}
        </Wrapper>
    );
}

export default withErrorBoundary(SportMatchScoreboard);
