import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, ReactNode } from 'react';
import Wrapper from './styles';
import { useInterval } from '../../../../services/hooks';

interface Props {
    children: ReactNode;
    reserveFromBottom: number;
    onLoadMore: () => void;
}

function UiLazyLoaderInterval({ children, reserveFromBottom = 0, onLoadMore }: Props, ref) {
    const [isLoading, setIsLoading] = useState(false);
    const containerDomElement = useRef();

    useInterval(
        () => {
            if (typeof onLoadMore === 'function' && !isLoading && containerDomElement && containerDomElement.current) {
                const rect = (containerDomElement.current as any).getBoundingClientRect();
                const viewportHeight = window.innerHeight;

                if (rect.bottom - reserveFromBottom < viewportHeight) {
                    setIsLoading(true);
                }
            }
        },
        200,
        [],
    );

    useEffect(() => {
        if (isLoading) {
            onLoadMore();
        }
    }, [isLoading]);

    useImperativeHandle(ref, () => ({
        loadingFinished() {
            setIsLoading(false);
        },
    }));

    return (
        <Wrapper ref={containerDomElement} className="lazy-loader-scrolling-box">
            {children}
        </Wrapper>
    );
}

export default forwardRef(UiLazyLoaderInterval);
