import React from 'react';
import Wrapper from './styles';

interface Props {
    rank: number;
    alias: string;
    eventCount?: number;
    score?: number;
    minEvents?: number;
    prize?: string;
    showEventCount: boolean;
}

export default function CmsPokerLeaderboardParticipant({
    rank,
    alias,
    eventCount,
    score,
    minEvents,
    prize,
    showEventCount,
}: Props) {
    const formattedScore = score ? score / 100 : 0;

    return (
        <Wrapper>
            <div className="position">{rank}</div>
            <div className="alias">{alias}</div>
            <div className="score">{formattedScore}</div>
            {showEventCount && (
                <div className="eventCount">
                    {eventCount} / {minEvents}
                </div>
            )}
            <div className="prize">{prize}</div>
        </Wrapper>
    );
}
