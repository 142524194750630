import React, { useState } from 'react';
import Wrapper from './styles';
import { PaybackBooster } from '../../../../../../services/casino/payback-booster';
import CasinoPaybackBoosterActivateBoosterButton from '../../../activate-booster-button/CasinoPaybackBoosterActivateBoosterButton';
import CasinoPaybackBoosterCountdown from '../../../countdown/CasinoPaybackBoosterCountdown';
import { Tooltip } from '@mui/material';
import { translate } from '../../../../../../services/translate';

interface Props {
    paybackBooster: PaybackBooster;
    onExpired: () => void;
}

export default function CasinoPaybackBoosterThumbnailPaybackBoosterAvailable({ paybackBooster, onExpired }: Props) {
    const [isExpired, setIsExpired] = useState(Boolean(new Date(paybackBooster.expiryDate) < new Date()));
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    return (
        <Wrapper
            isCasinoLocked={paybackBooster.casinoLocked}
            onMouseLeave={() => {
                setIsTooltipOpen(false);
            }}
            onMouseEnter={() => {
                setIsTooltipOpen(Boolean(paybackBooster.casinoLocked));
            }}
        >
            <div className="countdown">
                {!paybackBooster.paybackBoosterSettings.endProgramImmediately && !isExpired && (
                    <CasinoPaybackBoosterCountdown
                        endDate={paybackBooster.expiryDate}
                        minutesWithExtraBoost={paybackBooster.paybackBoosterSettings.minutesWithExtraRtp}
                        isCasinoLocked={paybackBooster.casinoLocked}
                    />
                )}
                {paybackBooster.paybackBoosterSettings.endProgramImmediately && !isExpired && (
                    <CasinoPaybackBoosterCountdown
                        endDate={paybackBooster.expiryDate}
                        onExpired={() => {
                            setIsExpired(true);
                            onExpired();
                        }}
                        isCasinoLocked={paybackBooster.casinoLocked}
                    />
                )}
                {isExpired && <CasinoPaybackBoosterCountdown isCasinoLocked={paybackBooster.casinoLocked} />}
            </div>

            <Tooltip
                title={translate(
                    'You can not activate Payback Booster while having an active Casino Bonus',
                    'casino.payback-booster',
                )}
                enterDelay={200}
                enterTouchDelay={0}
                open={isTooltipOpen}
            >
                <div>
                    {!isExpired && (
                        <div className="activate-button">
                            <CasinoPaybackBoosterActivateBoosterButton paybackBooster={paybackBooster} />
                        </div>
                    )}
                </div>
            </Tooltip>
        </Wrapper>
    );
}
