import styled from 'styled-components';
import { transparentize, lighten } from 'polished';
import { mediaQuery } from '../../../../../styles/utils';

export default styled.div`
    max-width: 550px;
    margin: 0.8rem auto;
    padding: 2rem;
    border-radius: var(--ui-radius);
    background: ${(props) =>
            props.isPhone
                ? `url(/assets/images/scoreboard-backgrounds/scoreboard-${props.sportSlug}.jpg)`
                : transparentize(0.2, lighten(0.06, props.theme.box.background))}
        center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: var(--ui-shadow);
    @media ${mediaQuery.isPhone} {
        margin: 0;
    }
    .scores {
        display: flex;
        align-items: stretch;
        justify-content: center;
        .score {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        .score-title {
            font-size: 1rem;
            letter-spacing: 1px;
            text-shadow: 1px 1px #000;
        }
        .score-team {
            font-size: 0.8rem;
            color: var(--font-color-primary);
            font-weight: normal;
            text-align: center;
        }
        .score-number {
            font-size: 1.5rem;
            font-weight: bold;
            width: 40px;
            height: 50px;
            background-color: var(--surface-level-1-bg);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
        }
    }
    .timer {
        min-width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media ${mediaQuery.isPhone} {
            > div {
                background-color: ${({ theme }) => transparentize(0.2, lighten(0.06, theme.box.background))};
                padding: 6px;
                border-radius: var(--ui-radius);
            }
        }
    }
    .stats {
        display: flex;
        align-items: stretch;
        text-align: center;
        width: 100%;
        &:not(:last-of-type) {
            margin-bottom: 0.5rem;
        }
        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 1px;
            background: ${({ theme }) => transparentize(0.5, theme.colors.bodyBackground)};
            border-radius: var(--ui-radius);
            flex: 1;
        }
        .stats-divider {
            max-width: 10px;
            background: transparent;
        }
    }
    .stats-title {
        flex: 1;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 0.7rem;
        color: var(--font-color-secondary);
        padding: 0.3rem;
        font-weight: normal;
        margin: 0;
        background: var(--color-body-bg);
        border-radius: var(--ui-radius);
    }
    .stats-number {
        padding: 0.5rem;
        font-size: 0.7rem;

        &.red-card {
            color: ${({ theme }) => theme.colors.red};
        }
        &.yellow-card {
            color: ${({ theme }) => theme.colors.gold};
        }
    }

    .stats-chart {
        margin-bottom: 0.5rem;
        @media ${mediaQuery.isPhone} {
            display: none;
        }
    }
`;
