import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Svg from '../../../../../../svg/Svg';
import classnames from 'classnames';
import SportMatchMarketsTopMarketsMarketLineSelector from '../line-selector/SportMatchMarketsTopMarketsMarketLineSelector';
import { useComponentFocused } from '../../../../../../../services/hooks';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../../../../../services/analytics';
import { CategoryMatchMarket } from '../../../../../../../services/sports/types';
import { MarketsWithOutcomesMinimal } from '../../../../../../../microservices/sbgate';

interface Props {
    markets: (CategoryMatchMarket | MarketsWithOutcomesMinimal)[] | undefined;
    market: CategoryMatchMarket | MarketsWithOutcomesMinimal;
    setMarket: Dispatch<SetStateAction<CategoryMatchMarket | MarketsWithOutcomesMinimal | undefined>>;
    isAmericanLayout: boolean;
    disabled?: boolean;
    bestMarketLineId: number;
}

function SportMatchMarketsTopMarketsMarketLineBtn({
    setMarket,
    market,
    markets,
    isAmericanLayout,
    disabled,
    bestMarketLineId,
}: Props) {
    const [linesRef, isLinesFocused, , filteredRef] = useComponentFocused(false);
    const [isAllLinesVisible, setIsAllLinesVisible] = useState(false);

    useEffect(() => {
        if (!isLinesFocused) {
            setIsAllLinesVisible(false);
        }
    }, [isLinesFocused]);

    function toggleAllLinesSelect() {
        if (disabled) {
            return;
        }
        if (!isAllLinesVisible) {
            trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.SPORT_LINE_MARKET_SELECT_OPENED);
        }
        setIsAllLinesVisible(!isAllLinesVisible);
    }

    return (
        <>
            <a
                ref={filteredRef}
                className={classnames('market-line-select', {
                    selected: isAllLinesVisible,
                    disabled,
                })}
                onClick={toggleAllLinesSelect}
            >
                <Svg icon="drawer" size={0.5} rotate={-90} />
            </a>
            {isAllLinesVisible && (
                <SportMatchMarketsTopMarketsMarketLineSelector
                    onLineClick={setMarket}
                    markets={markets}
                    selectedMarket={market}
                    linesRef={linesRef}
                    isAmericanLayout={isAmericanLayout}
                    onClose={() => setIsAllLinesVisible(!isAllLinesVisible)}
                    bestMarketLineId={bestMarketLineId}
                />
            )}
        </>
    );
}
export default React.memo(SportMatchMarketsTopMarketsMarketLineBtn);
