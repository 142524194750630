import React, { useState } from 'react';
import { stores } from '../../../../stores';
import AuthPayAndPlay from '../AuthPayAndPlay';
import { translate } from '../../../../services/translate';
import UiModal from '../../../ui/modal/UiModal';
import { Country } from '@staycool/location';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

export default function AuthPayAndPlayModal() {
    const [payAndPlaySettings] = useStore(stores.payAndPlaySettings);
    const [isPayAndPlayModalOpen, setIsPayAndPlayModalOpen] = useStore(stores.modals.isPayAndPlayModalOpen);
    const [{ isPhone }] = useStore(media);
    const [shouldDisableBackdropClick, setShouldDisableBackdropClick] = useState(false);

    function onClose() {
        setIsPayAndPlayModalOpen(false);
        setShouldDisableBackdropClick(false);
    }

    return (
        <UiModal
            onClose={onClose}
            mode={isPhone ? 'drawer' : undefined}
            open={isPayAndPlayModalOpen}
            className="new-modal"
            $disableBackdropClick={shouldDisableBackdropClick}
            title={translate(`Deposit your amount in 3 easy steps`, 'ui.pay-and-play')}
        >
            <ol className="steps">
                <li>{translate('Enter deposit amount', 'ui.pay-and-play')}</li>
                <li>
                    {translate('Choose your bank', 'ui.pay-and-play')}
                    {payAndPlaySettings?.country === Country.ESTONIA && (
                        <span>
                            :<br />- {translate('SEB, Swedbank or LHV', 'ui.pay-and-play')}
                        </span>
                    )}
                </li>
                <li>{translate('Start playing', 'ui.pay-and-play')}</li>
            </ol>

            <AuthPayAndPlay onClose={onClose} setShouldDisableBackdropClick={setShouldDisableBackdropClick} />
        </UiModal>
    );
}
