import React from 'react';
import Wrapper from './styles';
import styled from 'styled-components';
import UiSparklesSparkle from './sparkle/UiSparklesSparkle';
import { filterStyleProps } from '../../../styles/utils';

interface Props {
    color?: string;
    block?: boolean;
    children?: React.ReactNode;
}

export default function UiSparkles({ color = '#ffee34', children, block, ...rest }: Props) {
    const ChildWrapper = styled.span`
        position: relative;
        z-index: 1;
        font-weight: bold;
    `;

    const range = (start, end?, step = 1) => {
        const output: number[] = [];
        if (typeof end === 'undefined') {
            end = start;
            start = 0;
        }
        for (let i = start; i < end; i += step) {
            output.push(i);
        }
        return output;
    };

    const random = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;

    const generateSparkle = function (color) {
        const sparkle = {
            id: String(random(10000, 99999)),
            createdAt: Date.now(),
            color,
            size: random(10, 40),
            style: {
                top: `${random(0, 100)}%`,
                left: `${random(0, 100)}%`,
            },
        };
        return sparkle;
    };

    const useRandomInterval = function (callback, minDelay, maxDelay) {
        const timeoutId = React.useRef<number | null>(null);
        const savedCallback = React.useRef(callback);
        React.useEffect(() => {
            savedCallback.current = callback;
        });
        React.useEffect(() => {
            const isEnabled = typeof minDelay === 'number' && typeof maxDelay === 'number';
            if (isEnabled) {
                const handleTick = function () {
                    const nextTickAt = random(minDelay, maxDelay);
                    timeoutId.current = window.setTimeout(() => {
                        savedCallback.current();
                        handleTick();
                    }, nextTickAt);
                };
                handleTick();
            }
            return () => window.clearTimeout(timeoutId.current as number);
        }, [minDelay, maxDelay]);
        const cancel = React.useCallback(function () {
            window.clearTimeout(timeoutId.current as number);
        }, []);
        return cancel;
    };

    const [sparkles, setSparkles] = React.useState(() => {
        return range(3).map(() => generateSparkle(color));
    });

    useRandomInterval(
        () => {
            const sparkle = generateSparkle(color);
            const now = Date.now();
            const nextSparkles = sparkles.filter((sp) => {
                const delta = now - sp.createdAt;
                return delta < 750;
            });
            nextSparkles.push(sparkle);
            setSparkles(nextSparkles);
        },
        50,
        450,
    );

    return (
        <Wrapper {...filterStyleProps({ ...rest })} block={block}>
            {sparkles.map((sparkle) => (
                <UiSparklesSparkle key={sparkle.id} color={sparkle.color} size={sparkle.size} style={sparkle.style} />
            ))}
            <ChildWrapper>{children}</ChildWrapper>
        </Wrapper>
    );
}
