import camelCase from 'lodash/camelCase';
import forOwn from 'lodash/forOwn';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
    getLimitsByType,
    getProductsTimeoutStatuses,
    getUserAskResponsibleGaming,
    loadUnapprovedReminders,
    TimeoutItemSource,
} from '../microservices/responsible-gaming';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { logout } from './auth';
import { COUNTRY } from './country';
import { getActiveCurrency } from './currency';
import { isB2B } from './environment';
import { FEATURE, isFeatureAvailable } from './feature';
import { openKycModal } from './kyc';
import { logger } from './logger';
import { NativeMessageEventType, isMobileApp, sendNativeEvent } from './mobile-app';
import { storageSet } from './storage';
import { translate } from './translate';
import { getUserCountry, isUserFromCountry } from './user';
import { ResponsibleGamingProduct } from './responsible-gaming/types';
import { Currency } from './wallet/types';
import { PRODUCT } from '../types/common';

export const MGA_LICENCE_LINK =
    'https://authorisation.mga.org.mt/verification.aspx?lang=EN&company=7e4405b1-02fe-48d4-971c-97efe4133de9&details=1';

export const SHOW_ANNUAL_REPORT = 'show_annual_report';
export const CLOSURE_REASON = {
    CLOSED: 'CLOSED',
    COUNTRY: 'COUNTRY',
    SUPPORT: 'SUPPORT',
    TIMEOUT: 'TIMEOUT',
    HAMPI: 'HAMPI',
    UNDERAGE: 'UNDERAGE',
    MAINTENANCE: 'MAINTENANCE',
    LIMIT: 'LIMIT',
    SPELPAUS: 'SPELPAUS',
    BALANCE: 'BALANCE',
    PID_MISSING: 'PID_MISSING',
};

const MINIMUM_DEPOSIT_LIMITS = {
    [Currency.EUR]: 10,
    [Currency.PEN]: 10,
    [Currency.USD]: 10,
    [Currency.CAD]: 10,
    [Currency.SEK]: 100,
    [Currency.NOK]: 100,
    [Currency.CLP]: 2500,
};

export enum LIMIT_TYPE {
    DEPOSIT = 'deposit',
    LOSS = 'loss',
    LOGIN_DURATION = 'login_duration',
    WAGERING = 'wager',
}

const TIME_LIMITS = [LIMIT_TYPE.LOGIN_DURATION];

const SPENDING_LIMITS = [LIMIT_TYPE.DEPOSIT, LIMIT_TYPE.LOSS, LIMIT_TYPE.WAGERING];

export enum LIMIT_PERIOD {
    NO_LIMIT = 'no_limit',
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
}

const MAXIMUM_LIMITS = {
    [COUNTRY.SWEDEN]: {
        [LIMIT_TYPE.DEPOSIT]: {
            [LIMIT_PERIOD.DAY]: 125000,
            [LIMIT_PERIOD.WEEK]: 250000,
            [LIMIT_PERIOD.MONTH]: 500000,
        },
        [LIMIT_TYPE.LOSS]: {
            [LIMIT_PERIOD.DAY]: 125000,
            [LIMIT_PERIOD.WEEK]: 250000,
            [LIMIT_PERIOD.MONTH]: 500000,
        },
    },
    [COUNTRY.CHILE]: {
        [LIMIT_TYPE.DEPOSIT]: {
            [LIMIT_PERIOD.DAY]: 100000000,
            [LIMIT_PERIOD.WEEK]: 100000000,
            [LIMIT_PERIOD.MONTH]: 100000000,
        },
        [LIMIT_TYPE.LOSS]: {
            [LIMIT_PERIOD.DAY]: 100000000,
            [LIMIT_PERIOD.WEEK]: 100000000,
            [LIMIT_PERIOD.MONTH]: 100000000,
        },
    },
};

export const RECOMMENDED_DEPOSIT_LIMITS = {
    [LIMIT_PERIOD.DAY]: 333,
    [LIMIT_PERIOD.WEEK]: 2500,
    [LIMIT_PERIOD.MONTH]: 10000,
};

export function getOverRecommendedLimitPeriod(limits: Partial<Record<LIMIT_PERIOD, number>>) {
    return [LIMIT_PERIOD.MONTH, LIMIT_PERIOD.WEEK, LIMIT_PERIOD.DAY].find((period) => {
        const limit = limits[period];
        return limit && limit >= RECOMMENDED_DEPOSIT_LIMITS[period];
    });
}

const DEFAULT_MAX_LIMIT_AMOUNT = 10_000_000;
const DEFAULT_B2B_MAX_LIMIT_AMOUNT = 100_000_000;

export const LIMIT_DURATION = {
    FIFTEEN_MINUTES: 15,
    THIRTY_MINUTES: 30,
    ONE_HOUR: 60,
    TWO_HOURS: 60 * 2,
    FOUR_HOURS: 60 * 4,
    SIX_HOURS: 60 * 6,
    EIGHT_HOURS: 60 * 8,
    TWELVE_HOURS: 60 * 12,
    SIXTEEN_HOURS: 60 * 16,
    ONE_DAY: 60 * 24,
    TWO_DAYS: 60 * 24 * 2,
    THREE_DAYS: 60 * 24 * 3,
    FOUR_DAYS: 60 * 24 * 4,
    FIVE_DAYS: 60 * 24 * 5,
    ONE_WEEK: 60 * 24 * 7,
    TWO_WEEKS: 60 * 24 * 7 * 2,
    THREE_WEEKS: 60 * 24 * 7 * 3,
    ONE_MONTH: 60 * 24 * 30,
};

export function validateDepositLimitsMinimumAmount(limits: number[]) {
    const currency = getActiveCurrency();
    const minimumDepositLimit = MINIMUM_DEPOSIT_LIMITS[currency];

    if (limits.some((limit) => limit < minimumDepositLimit)) {
        return translate('Minimum deposit limit amount is %1 %2', 'ui.account', [minimumDepositLimit, currency]);
    }

    return '';
}

export async function initializeResponsibleGamingTimeoutStatuses() {
    const [{ casino, sportsbook, horse_racing, poker, source, firstEndDate, selfExclusionUntilFurtherNotice }] =
        await Promise.all([getProductsTimeoutStatuses(), loadAskLimit()]);
    stores.responsibleGaming.timeout.set({
        [ResponsibleGamingProduct.CASINO]: casino?.end_date ? new Date(casino.end_date) : undefined,
        [ResponsibleGamingProduct.HORSE_RACING]: horse_racing?.end_date ? new Date(horse_racing.end_date) : undefined,
        [ResponsibleGamingProduct.POKER]: poker?.end_date ? new Date(poker.end_date) : undefined,
        [ResponsibleGamingProduct.SPORTSBOOK]: sportsbook?.end_date ? new Date(sportsbook.end_date) : undefined,
    });

    if (source === TimeoutItemSource.PANIC_BUTTON) {
        stores.responsibleGaming.isPanicTimeoutActive.set(true);
        stores.responsibleGaming.panicTimeoutEndDate.set(new Date(firstEndDate));
    } else if (source === TimeoutItemSource.SELF_EXCLUSION) {
        stores.responsibleGaming.isSelfExclusionTimeoutActive.set(true);
        stores.responsibleGaming.selfExclusionTimeoutEndDate.set(new Date(firstEndDate));
        stores.responsibleGaming.selfExclusionUntilFurtherNotice.set(selfExclusionUntilFurtherNotice);
    }
}

export async function loadAskLimit() {
    const { askDepositLimit, askLoginDurationLimit, askLossLimit } = await getUserAskResponsibleGaming();
    stores.responsibleGaming.askDepositLimit.set(askDepositLimit);
    stores.responsibleGaming.askLoginDurationLimit.set(askLoginDurationLimit);
    stores.responsibleGaming.askLossLimit.set(askLossLimit);
}

export function resetResponsibleGamingTimeoutStatuses() {
    stores.responsibleGaming.timeout.set({
        [ResponsibleGamingProduct.CASINO]: undefined,
        [ResponsibleGamingProduct.SPORTSBOOK]: undefined,
        [ResponsibleGamingProduct.POKER]: undefined,
        [ResponsibleGamingProduct.HORSE_RACING]: undefined,
    });

    stores.responsibleGaming.isPanicTimeoutActive.set(false);
    stores.responsibleGaming.isSelfExclusionTimeoutActive.set(false);
    stores.responsibleGaming.panicTimeoutEndDate.set(new Date());
    stores.responsibleGaming.selfExclusionTimeoutEndDate.set(new Date());
    stores.responsibleGaming.selfExclusionUntilFurtherNotice.set(undefined);
    stores.responsibleGaming.unApprovedSessionReminder.set(null);
    stores.responsibleGaming.isUnapprovedSessionReminderLoaded.set(false);
    stores.modals.isUnapprovedSessionReminderOpen.set(false);
    stores.responsibleGaming.annualReport.set(null);
    stores.responsibleGaming.isAnnualReportLoaded.set(null);
    stores.modals.isResponsibleGamingAnnualReportOpen.set(false);
    storageSet(SHOW_ANNUAL_REPORT, true);
}

export async function initializeResponsibleGaming() {
    try {
        await initializeResponsibleGamingTimeoutStatuses();
        await loadUnapprovedReminders();
    } catch (error) {
        logger.error('ResponsibleGamingService', 'initializeResponsibleGaming', error);
    }
}

export function processReminder(responsibleGamingReminder) {
    if (isMobileApp() && ['open', 'close'].includes(responsibleGamingReminder.action)) {
        sendNativeEvent({
            type: NativeMessageEventType.SESSION_REMINDER,
            currency: getActiveCurrency(),
            ...getUnApprovedSessionReminder(responsibleGamingReminder),
        });
    } else if (responsibleGamingReminder.action === 'open') {
        stores.modals.isUnapprovedSessionReminderOpen.set(!isEmpty(responsibleGamingReminder));
        stores.responsibleGaming.unApprovedSessionReminder.set(responsibleGamingReminder);
    } else if (responsibleGamingReminder.action === 'close') {
        stores.responsibleGaming.unApprovedSessionReminder.set(null);
    }

    if (responsibleGamingReminder.action === 'block-user-login-duration') {
        stores.responsibleGaming.loginDurationLimitReminder.set(responsibleGamingReminder);
    }

    if (responsibleGamingReminder.action === 'master-session-logout') {
        logout(responsibleGamingReminder.reason);
        stores.responsibleGaming.unApprovedSessionReminder.set(null);
        if (responsibleGamingReminder.kycToken) {
            openKycModal(responsibleGamingReminder.kycToken, responsibleGamingReminder.kycTokenExpiry);
        }

        if (responsibleGamingReminder.reason) {
            openMultipleSessionModal();
        }
    }

    stores.responsibleGaming.isUnapprovedSessionReminderLoaded.set(true);
}

function getUnApprovedSessionReminder(responsibleGamingReminder) {
    const initialProductTransactions = {
        sportsbook: 0,
        casino: 0,
        poker: 0,
        virtual_sports: 0,
    };

    const currentProductTransactions = responsibleGamingReminder?.wallet_data.reduce((result, transaction) => {
        const { product, amount_uc } = transaction;
        result[product.toLowerCase()] = amount_uc;
        return result;
    }, {});

    const walletTransactionsByProduct = { ...initialProductTransactions, ...currentProductTransactions };
    return { ...responsibleGamingReminder, wallet_data: walletTransactionsByProduct };
}

function getListFromLimitsDictionary(limitsDictionary) {
    const normalizedLimits: { activeLimit: Limit; upcomingLimit: Limit }[] = [];

    forOwn(limitsDictionary, (limit, limitPeriod) => {
        if (isEmpty(limit)) {
            return;
        }

        const normalizedLimit: any = {};

        if (limit && limit.current_limit) {
            normalizedLimit.activeLimit = { period: limitPeriod, ...limit.current_limit };
        }

        if (limit && limit.next_limit) {
            normalizedLimit.upcomingLimit = { period: limitPeriod, ...limit.next_limit };
        }

        normalizedLimits.push(normalizedLimit);
    });

    return normalizedLimits;
}

export function isDepositPageBlocked() {
    return Boolean(hasAllProductTimeout() || userHasGamblingRestriction());
}

export function isUserValidForAnnualReport() {
    return isUserFromCountry(COUNTRY.SWEDEN);
}

export function hasAllProductTimeout() {
    const timeout = getStoreValue(stores.responsibleGaming.timeout);
    if (isFeatureAvailable(FEATURE.CASINO) && !Boolean(timeout[ResponsibleGamingProduct.CASINO])) {
        return false;
    }
    if (isFeatureAvailable(FEATURE.HORSE_RACING) && !Boolean(timeout[ResponsibleGamingProduct.HORSE_RACING])) {
        return false;
    }
    if (isFeatureAvailable(FEATURE.POKER) && !Boolean(timeout[ResponsibleGamingProduct.POKER])) {
        return false;
    }
    if (isFeatureAvailable(FEATURE.SPORTSBOOK) && !Boolean(timeout[ResponsibleGamingProduct.SPORTSBOOK])) {
        return false;
    }
    return true;
}

export function isTimeLimitByType(type) {
    return TIME_LIMITS.includes(type);
}

export function isSpendingLimitByType(type) {
    return SPENDING_LIMITS.includes(type);
}

export function getPeriodTranslationForLimitPeriod(limitPeriod: LIMIT_PERIOD) {
    return {
        [LIMIT_PERIOD.NO_LIMIT]: '',
        [LIMIT_PERIOD.DAY]: translate('daily', 'ui.common'),
        [LIMIT_PERIOD.WEEK]: translate('weekly', 'ui.common'),
        [LIMIT_PERIOD.MONTH]: translate('monthly', 'ui.common'),
    }[limitPeriod];
}

export function getLimitPeriodTranslation(period: LIMIT_PERIOD) {
    return {
        [LIMIT_PERIOD.DAY]: translate('24 hours', 'ui.account'),
        [LIMIT_PERIOD.WEEK]: translate('week', 'ui.account'),
        [LIMIT_PERIOD.MONTH]: translate('month', 'ui.account'),
    }[period];
}

export async function getLimits(type): Promise<{ activeLimit: Limit; upcomingLimit: Limit }[]> {
    const limitsByPeriod = await getLimitsByType(type);
    return getListFromLimitsDictionary(limitsByPeriod);
}

export function getMaximumLimit(period: LIMIT_PERIOD, limitType: LIMIT_TYPE, activeLimit: number = 0) {
    if (isB2B()) {
        return DEFAULT_B2B_MAX_LIMIT_AMOUNT;
    }
    const maximumLimit = get(MAXIMUM_LIMITS, [getUserCountry(), limitType, period]);
    return maximumLimit
        ? parseFloat(String(Math.max(maximumLimit, activeLimit) || maximumLimit))
        : DEFAULT_MAX_LIMIT_AMOUNT;
}

export function getLimitTooHighErrorMessage(error) {
    return translate(`Maximum {{ period }} limit is {{ amount }} {{ currency }}`, 'ui.account', {
        period: getPeriodTranslationForLimitPeriod(error.details.providedLimitPeriod),
        amount: error.details.maximumLimitAmount,
        currency: getActiveCurrency(),
    });
}

export function getTimeoutPeriods() {
    return [
        {
            label: translate('24 hours', 'ui.account'),
            periodInDays: 1,
        },
        {
            label: `1 ${translate('week', 'ui.account')}`,
            periodInDays: 7,
        },
        {
            label: `30 ${translate('days', 'ui.account')}`,
            periodInDays: 30,
        },
        {
            label: `90 ${translate('days', 'ui.account')}`,
            periodInDays: 90,
        },
        {
            label: `180 ${translate('days', 'ui.account')}`,
            periodInDays: 180,
        },
    ];
}

export const RG_ERROR_CODE = {
    LIMIT_AMOUNT_TOO_HIGH: 1013,
    LIMIT_INCREASE_REMOVAL_LOCKED: 2006,
};

export async function logoutByStopSession() {
    return logout('stop session');
}

export function hasToLowerDepositLimit() {
    const user: any = getStoreValue(stores.user);
    return Boolean(user.should_lower_deposit_limits);
}

export function userHasGamblingRestriction() {
    const user = getStoreValue(stores.user);
    const gamblingRestrictions = [CLOSURE_REASON.SPELPAUS, CLOSURE_REASON.HAMPI, CLOSURE_REASON.PID_MISSING];
    const products = [PRODUCT.CASINO, PRODUCT.POKER, PRODUCT.SPORTSBOOK, PRODUCT.VIRTUAL_SPORTS].map(camelCase);

    if (!user) {
        return false;
    }

    const reasons = products.filter((product) =>
        Boolean(user[`${product}Closed`] && gamblingRestrictions.includes(user[`${product}ClosedReason`])),
    );

    return Boolean(reasons.length === products.length);
}

export function getUserProductBlockReason(product: typeof PRODUCT[keyof typeof PRODUCT]) {
    const user = getStoreValue(stores.user);
    if (product === PRODUCT.CASINO && user?.casinoClosed) {
        return user?.casinoClosedReason || CLOSURE_REASON.SUPPORT;
    }
    if (product === PRODUCT.POKER && user?.pokerClosed) {
        return user?.pokerClosedReason || CLOSURE_REASON.SUPPORT;
    }
    if (product === PRODUCT.SPORTSBOOK && user?.sportsbookClosed) {
        return user?.sportsbookClosedReason || CLOSURE_REASON.SUPPORT;
    }
    if (product === PRODUCT.VIRTUAL_SPORTS && user?.virtualSportsClosed) {
        return user?.virtualSportsClosedReason || CLOSURE_REASON.SUPPORT;
    }
    const userProductToResponsibleGamingProduct = {
        [PRODUCT.CASINO]: ResponsibleGamingProduct.CASINO,
        [PRODUCT.RACEBOOK]: ResponsibleGamingProduct.HORSE_RACING,
        [PRODUCT.POKER]: ResponsibleGamingProduct.POKER,
        [PRODUCT.SPORTSBOOK]: ResponsibleGamingProduct.SPORTSBOOK,
        [PRODUCT.VIRTUAL_SPORTS]: ResponsibleGamingProduct.CASINO,
    };
    const timeout = getStoreValue(stores.responsibleGaming.timeout);
    if (timeout[userProductToResponsibleGamingProduct[product]]) {
        return CLOSURE_REASON.TIMEOUT;
    }
}

export type AuthProfileValidatorLimitsProps = {
    heading: string;
    isModal?: boolean;
    isOfferOnly?: boolean; // Ideally this prop should come from backend
    limitType: LIMIT_TYPE;
    currentLimits?: {
        activeLimit: Limit;
        upcomingLimit: Limit;
    }[];
    onAfterSubmit: () => Promise<void> | void;
    onExit: () => void;
    onSkip: (limitType: LIMIT_TYPE) => Promise<void> | void;
    snippetKey: string;
};

export type Limit = {
    amount: number;
    start_date: Date;
    end_date: Date;
    type: LIMIT_TYPE;
    period: LIMIT_PERIOD;
    remaining_amount: number;
    current_period_end_date: Date;
    consumption_unit: string;
};

function openMultipleSessionModal() {
    stores.modals.isMultipleSessionModalOpen?.set(true);
}
