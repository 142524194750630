import React, { useEffect, useState } from 'react';
import { getRoute, useRouter, useRoutes } from '../../services/router';
import { stores } from '../../stores';
import { getLoyalty } from '../../microservices/retail-middleware';
import { proceedExpiration, resetExpirationPopup } from '../../services/retail-session';
import { logger } from '../../services/logger';
import RetailHeader from './header/RetailHeader';
import RetailKeyboard from './keyboard/RetailKeyboard';
import { getRootRoutes } from '../../pages';
import Wrapper from './styles';
import { getIsDummyLoyalty } from '../../services/retail';
import { isLocalDevelopment } from '../../services/environment';
import Development from '../development/Development';
import DevelopmentSettings from '../development/settings/DevelopmentSettings';
import { GlobalStyles } from '../../theme/global';
import Page from '../../components/page/Page';
import Navigator from '../../components/navigator/Navigator';
import { initRetailMiddlewareSocket } from '../../services/retail-middleware-socket';
import { ToastContainer } from 'react-toastify';
import { TerminalStatus } from '@staycool/retail-types/terminal';
import { retail } from '../../stores/retail/retail';
import { useStore } from '../../hooks/useStore';
import asyncComponent from '../async-component/AsyncComponent';
const RetailModal = asyncComponent(() => import('../../components/retail/modal/RetailModal'));

export default function Retail() {
    const { navigateTo, pathname } = useRouter();
    const [wallet] = useStore(stores.wallet);
    const [retailModalBringYourDevice] = useStore(retail.retailModals.retailModalBringYourDevice);
    const [retailModalEvent] = useStore(retail.retailModals.retailModalEvent);
    const [retailExpirationRefresh] = useStore(retail.retailExpirationRefresh);
    const routes = useRoutes(getRootRoutes(true));
    const [printerEvent] = useStore(retail.retailPrinterEvent);
    const [ticketsToPrint] = useStore(stores.sports.ticketsToPrint);
    const [loyalty, setLoyalty] = useStore(retail.loyalty);
    const [{ status: terminalStatus }] = useStore(retail.retailTerminal);
    const [retailPrintingModal] = useStore(retail.retailModals.retailPrintingModal);
    const [retailSettings] = useStore(retail.retailSettings);
    const [loyaltyClearanceTimeout, setLoyaltyClearanceTimeout] = useStore(retail.loyaltyClearanceTimeout);
    const [hasRetailSession, setHasRetailSession] = useStore(retail.hasRetailSession);
    const hasLoyalty = Boolean(loyalty.loyalty_id);
    const [retailSessionClosed] = useStore(retail.retailSessionClosed);
    const [isRetailModalExpires] = useStore(retail.retailModals.isRetailModalExpires);
    const isRetailDiagnostic = pathname.includes(getRoute('retail.maintenance.diagnostic'));
    const isRetailMaintenance = pathname.includes(getRoute('retail.maintenance'));
    const isTerminalActive =
        [TerminalStatus.Enabled, TerminalStatus.PreBlocked].includes(terminalStatus) || isLocalDevelopment();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        try {
            initRetailMiddlewareSocket();
        } catch (error) {
            logger.error('Retail', 'Retail', error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (ticketsToPrint.length && hasLoyalty && getIsDummyLoyalty()) {
            updateLoyalty(loyalty.loyalty_id);
        }
    }, [ticketsToPrint]);

    useEffect(() => {
        if (hasLoyalty && !hasRetailSession) {
            setLoyaltyClearanceTimeout(setTimeout(resetExpirationPopup, retailSettings.retailAuthorizationIdleTime));
        }
        return () => clearLoyaltyResetTimeout();
    }, [hasLoyalty, retailExpirationRefresh]);

    useEffect(() => {
        if (hasRetailSession) {
            clearLoyaltyResetTimeout();
        }
    }, [hasRetailSession]);

    function clearLoyaltyResetTimeout() {
        if (loyaltyClearanceTimeout) {
            clearTimeout(loyaltyClearanceTimeout);
        }
    }

    useEffect(() => {
        if (!isTerminalActive && !isRetailMaintenance) {
            navigateTo(getRoute('retail.maintenance'));
        } else if (isTerminalActive && isRetailMaintenance && !isRetailDiagnostic) {
            navigateTo(getRoute('retail.welcome'));
        }
    }, [terminalStatus]);

    useEffect(() => {
        if (!isRetailMaintenance) {
            navigateTo(getRoute('retail.welcome'));
        }
    }, [retailSessionClosed]);

    async function updateLoyalty(loyaltyId: string) {
        const loyalty = await getLoyalty(loyaltyId);

        if (loyalty) {
            setLoyalty(loyalty);
        }
    }

    useEffect(() => {
        setHasRetailSession((wallet && wallet.balance_uc > 0) || ticketsToPrint.length > 0);
    }, [wallet?.balance_uc, ticketsToPrint.length]);

    useEffect(() => {
        proceedExpiration();
    }, [
        retailModalBringYourDevice,
        retailModalEvent,
        retailExpirationRefresh,
        retailPrintingModal.isPrinting,
        printerEvent,
        hasLoyalty,
        hasRetailSession,
    ]);

    const page = (window as any).coolb2b?.page;

    function onRetailClick() {
        if (!isRetailModalExpires) {
            proceedExpiration();
        }
    }

    if (isLoading) {
        return <></>;
    }

    return (
        <Wrapper onTouchStart={onRetailClick} onClick={onRetailClick}>
            {process.env.NODE_ENV === 'development' && <Development />}
            {process.env.NODE_ENV === 'development' && <DevelopmentSettings />}
            {isTerminalActive && !isRetailDiagnostic && (
                <>
                    <RetailModal />
                    <RetailHeader />
                    <RetailKeyboard />
                </>
            )}
            <GlobalStyles isRetailLayout />
            {page ? <Page page={page} /> : <Navigator routes={routes} root />}
            <ToastContainer />
        </Wrapper>
    );
}
