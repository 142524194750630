import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { translate } from '../../../../services/translate';
import UiGroup from '../../../ui/group/UiGroup';
import { getFieldValidators } from '../../../../services/validators';
import UiDotsLoader from '../../../ui/dots-loader/UiDotsLoader';
import { storageSet } from '../../../../services/storage';
import { LOGIN_METHOD } from '../../../../services/auth';
import UiButton from '../../../ui/button/UiButton';
import { SMART_ID_FLOW, useSmartIdAuth } from '../../../../services/smart-id';
import { useForm } from 'react-hook-form';
import Ui2Form from '../../../ui-2/form/Ui2Form';
import Ui2FormTextInput from '../../../ui-2/form/text-input/Ui2FormTextInput';
import { useRouter } from '../../../../services/router';

interface Props {
    onAuthenticationSuccess: () => void;
}

export default function AuthLoginSmartId({ onAuthenticationSuccess }: Props) {
    const useFormMethods = useForm({ defaultValues: { personalId: '' } });
    const { navigateTo } = useRouter();
    const [isLoginStarted, setIsLoginStarted] = useState(false);
    const { startAuthentication, verificationCode, errorMessage, isAuthenticationSuccessful, redirectUrl } =
        useSmartIdAuth(SMART_ID_FLOW.LOGIN);

    useEffect(() => {
        if (errorMessage) {
            setIsLoginStarted(false);
            useFormMethods.setError('personalId', { message: errorMessage });
            useFormMethods.setFocus('personalId');
        }
    }, [errorMessage]);

    useEffect(() => {
        if (isAuthenticationSuccessful) {
            onAuthenticationSuccess();
            redirectUrl && navigateTo(redirectUrl);
        }
    }, [isAuthenticationSuccessful]);

    function login({ personalId }: { personalId: string }) {
        useFormMethods.clearErrors();
        setIsLoginStarted(true);
        startAuthentication(personalId);
        storageSet('preferLogin', LOGIN_METHOD.SMART_ID);
    }

    return (
        <Wrapper>
            {isLoginStarted && !errorMessage ? (
                <>
                    <p>{translate('Verification process started, please check your Smart ID app', 'ui.account')}</p>
                    <div className="verification-code">{verificationCode ?? '_ _ _ _'}</div>
                    <UiDotsLoader />
                </>
            ) : (
                <Ui2Form onSubmit={login} useFormMethods={useFormMethods}>
                    <Ui2FormTextInput
                        type="tel"
                        name="personalId"
                        validator={getFieldValidators(['personalIdEstonian'])}
                        error={useFormMethods.formState.errors.personalId}
                        placeholder={translate('Personal identification', 'ui.account')}
                    />

                    <UiGroup vertical>
                        <UiButton color="primary" className="smart-id-next-button" isFormSubmitButton>
                            {translate('Continue', 'ui.account')}
                        </UiButton>
                    </UiGroup>
                </Ui2Form>
            )}
        </Wrapper>
    );
}
