import styled from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

export default styled.div`
    background-color: ${({ theme }) => theme.paybackBooster.primaryColor};
    color: #000000;
    height: 100%;
    padding: 1rem 2rem;
    margin-left: 10px;

    display: flex;
    align-items: center;

    @media ${mediaQuery.isPhone} {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 0;
        padding: 2rem 1rem;
    }

    .booster-description {
        max-width: 300px;
        margin: 0 1rem 0 0;
        line-height: 1.4;
        display: flex;
        align-items: center;
        @media ${mediaQuery.isPhone} {
            margin: 0 0 1rem 0;
        }
    }
    .booster-step {
        color: #000;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 30px;
        height: 30px;
        border-radius: 100px;
        border: 2px solid;
        margin: 0 10px 0 0;
    }
`;
