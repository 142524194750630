import React from 'react';
import Wrapper from './styles';
import uniq from 'lodash/uniq';
import { PaybackBooster } from '../../../../../services/casino/payback-booster';
import { translate } from '../../../../../services/translate';
import CasinoPaybackBoosterGames from '../CasinoPaybackBoosterGames';
import { stores } from '../../../../../stores';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    paybackBoosterSettings: PaybackBooster['paybackBoosterSettings'];
    isExpired?: boolean;
    isShowStepNeeded?: boolean;
}

export default function CasinoPaybackBoosterGamesBooster({
    paybackBoosterSettings,
    isExpired = false,
    isShowStepNeeded = true,
}: Props) {
    const [{ isPhone }] = useStore(media);
    const [isPaybackBoosterCmsView] = useStore(stores.casino.isPaybackBoosterCmsView);
    const maxGames = isPhone || isPaybackBoosterCmsView ? 2 : 5;
    const serverGameIds = uniq([
        ...(paybackBoosterSettings?.promotedGames || []),
        ...Object.keys(paybackBoosterSettings?.extraPayback || {}),
    ]);

    return (
        <Wrapper>
            {!isExpired && (
                <div className="booster-description">
                    {isShowStepNeeded && <div className="booster-step">2</div>}
                    {translate(
                        'Play the eligible game(s) and earn extra cash on each spin (no matter if you win or lose)',
                        'casino.payback-booster',
                    )}
                </div>
            )}
            <CasinoPaybackBoosterGames
                serverGameIds={serverGameIds}
                extraPayback={paybackBoosterSettings?.extraPayback}
                maxGames={maxGames}
            />
        </Wrapper>
    );
}
