import React from 'react';
import WrapperButton from './styles-button';
import WrapperTab from './styles-tab';
import WrapperCard from './styles-card';
import Svg from '../../svg/Svg';
import { getRoute, isActiveRoute } from '../../../services/router';
import { translate } from '../../../services/translate';
import UiDotsLoader from '../../ui/dots-loader/UiDotsLoader';
import UiNavLink from '../../ui/nav-link/UiNavLink';
import omit from 'lodash/omit';
import { isRetail } from '../../../services/environment';
import { filterStyleProps } from '../../../styles/utils';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

type buttonTypes = 'button' | 'menu' | 'tab' | 'card';

interface Props {
    type?: buttonTypes;
    color?: 'primary' | 'default' | 'warning' | 'ghost' | 'outline';
    size?: 'tiny' | 'small' | 'large' | 'default';
    iconSize?: number;
    iconPosition?: 'left' | 'right';
    icon?: any;
    badge?: number;
    route?: string;
    url?: string;
    isOnSurface?: boolean;
    isLoading?: boolean;
    selected?: boolean;
    disabled?: boolean;
    block?: boolean;
    isFormSubmitButton?: boolean;
    onClick?: (event?) => void;
    onMouseOver?: (event?) => void;
    onMouseOut?: (event?) => void;
    className?: any;
    dataTest?: string;
    children?: any;
    newFeature?: boolean;
    updated?: boolean;
    flag?: any;
    lowercase?: boolean;
    isTwoToneTheme?: boolean;
    $openNewTab?: boolean;
}

export default function UiButton({
    isLoading,
    icon,
    children,
    route,
    url,
    isOnSurface,
    disabled,
    block,
    isFormSubmitButton,
    onClick,
    onMouseOver,
    onMouseOut,
    badge,
    newFeature,
    dataTest,
    updated,
    lowercase,
    isTwoToneTheme,
    iconSize = 18,
    type = 'button',
    iconPosition = 'right',
    color = 'default',
    size = 'default',
    ...rest
}: Props) {
    const buttonType = isFormSubmitButton ? 'submit' : 'button';
    const isDisabled = isLoading || disabled;
    const onClickHandler = onClick && !isDisabled ? onClick : () => {};
    const onMouseOverHandler = onMouseOver && !isDisabled ? onMouseOver : () => {};
    const onMouseOutHandler = onMouseOut && !isDisabled ? onMouseOut : () => {};
    const [{ isPhone }] = useStore(media);
    const isRetailLayout = isRetail();
    const dataTestValue = `${dataTest ? dataTest + '-' : ''}`;
    const dataTestChildrenValue = `${typeof children == 'string' ? children.replace(' ', '-').toLowerCase() : ''}`;
    const showBadge = badge && badge > 0 ? badge : false;

    function getButton(type: buttonTypes) {
        if (type === 'button') {
            return (
                <WrapperButton
                    {...filterStyleProps({ ...rest })}
                    data-test={dataTestValue + dataTestChildrenValue}
                    type={buttonType}
                    color={color}
                    disabled={isDisabled && !isLoading}
                    block={block}
                    size={size}
                    $isRetailLayout={isRetailLayout}
                    onClick={onClickHandler}
                    onMouseOver={onMouseOverHandler}
                    onMouseOut={onMouseOutHandler}
                >
                    {isLoading && <UiDotsLoader />}

                    {showBadge && <span className="badge">{badge}</span>}

                    {icon && !isLoading && iconPosition === 'left' && <span className="button-icon">{icon}</span>}

                    {children && !isLoading && <span className="button-text">{children}</span>}

                    {icon && !isLoading && iconPosition === 'right' && <span className="button-icon">{icon}</span>}
                </WrapperButton>
            );
        }

        if (type === 'card') {
            return (
                <WrapperCard
                    {...filterStyleProps({ ...rest })}
                    data-test={dataTestValue + dataTestChildrenValue}
                    type={buttonType}
                    size={size}
                    block={block}
                    onClick={onClickHandler}
                    isOnSurface={isOnSurface}
                    selected={isActiveRoute(url, false)}
                    badge={badge}
                >
                    {isLoading && <UiDotsLoader />}
                    {icon && !showBadge && !isLoading && <span className="button-icon">{icon}</span>}
                    {showBadge && !isLoading && <span className="button-badge">{badge}</span>}
                    {children && !isLoading && <span className="button-text">{children}</span>}
                </WrapperCard>
            );
        }

        if (type === 'tab' || 'menu') {
            return (
                <WrapperTab
                    {...filterStyleProps({ ...rest })}
                    onClick={onClickHandler}
                    $isPhone={isPhone}
                    size={size}
                    type={type}
                    lowercase={lowercase}
                    $isTwoToneTheme={isTwoToneTheme}
                >
                    {isLoading && <UiDotsLoader />}

                    {icon && <span className="icon">{icon}</span>}

                    {!!badge && badge > 0 && <span className="badge">{badge}</span>}
                    {newFeature && <span className="new">{translate('New', 'ui.common')}</span>}
                    {updated && <span className="updated">{translate('Updated', 'ui.common')}</span>}

                    {children && !isLoading && (
                        <span className="text">
                            <span className="content">{children}</span>
                        </span>
                    )}
                </WrapperTab>
            );
        }

        return (
            <WrapperButton
                {...filterStyleProps({ ...rest })}
                type={buttonType}
                $isRetailLayout={isRetailLayout}
                size={size}
                color={color}
                block={block}
                onClick={onClickHandler}
            >
                {isLoading && <UiDotsLoader />}

                {children && !isLoading && <span className="button-text">{children}</span>}

                {icon && !isLoading && (
                    <span className="button-icon">
                        <Svg icon={icon} size={iconSize} />
                    </span>
                )}
            </WrapperButton>
        );
    }

    if ((route || url) && !isDisabled) {
        return (
            <UiNavLink
                {...filterStyleProps(omit({ ...rest }, ['block', 'newFeature', 'iconSize']))}
                exact
                badge={badge}
                to={route ? getRoute(route) : url}
                style={{ display: block ? 'block' : 'inline-block' }}
            >
                {getButton(type)}
            </UiNavLink>
        );
    }

    return getButton(type);
}
