import { createStores } from './store/utils';

export const poker = createStores({
    isClosePopup: false,
    loginInProgress: false,
    isLoggedIn: false,
    isPokerBlocked: false,
    isLoadingPokerBlocked: false,
    isFirstRenderLoading: true,
    hasDownloadClientAuthBeenActivated: false,
});
