import React from 'react';
import { FEATURE, isFeatureAvailable } from '../../../services/feature';
import { stores } from '../../../stores';
import AuthProfileValidatorEssentialRequirement from './essential-requirement/AuthProfileValidatorEssentialRequirement';
import AuthProfileValidatorLicenceRequirement from './licence-requirement/AuthProfileValidatorLicenceRequirement';
import AuthProfileValidatorSourceOfWealthFiles from './source-of-wealth-files/AuthProfileValidatorSourceOfWealthFiles';
import AuthProfileValidatorSourceOfWealth from './source-of-wealth/AuthProfileValidatorSourceOfWealth';
import AuthProfileValidatorTermsAndConditionsAcceptanceModal from './terms-and-conditions-acceptance-modal/AuthProfileValidatorTermsAndConditionsAcceptanceModal';
import { useStore } from '../../../hooks/useStore';

export default function AuthProfileValidator() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);

    if (!isFeatureAvailable(FEATURE.AUTH_PROFILE_VALIDATOR) || !isAuthenticated) {
        return null;
    }

    return (
        <>
            <AuthProfileValidatorEssentialRequirement />
            <AuthProfileValidatorLicenceRequirement isModal />
            <AuthProfileValidatorSourceOfWealth />
            <AuthProfileValidatorSourceOfWealthFiles />
            <AuthProfileValidatorTermsAndConditionsAcceptanceModal />
        </>
    );
}
