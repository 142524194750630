import React, { useEffect, useState } from 'react';
import {
    Industry,
    UserCountry,
    getCountries,
    getIndustries,
    getMe,
    saveLoqateAddress,
} from '../../../../microservices/users';
import { updateProfile } from '../../../../microservices/users-cb';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import { useForm } from '../../../../services/form';
import { getFormattedLicenceName } from '../../../../services/licence';
import { logger } from '../../../../services/logger';
import { translate } from '../../../../services/translate';
import { isFieldVisible, loadProfile } from '../../../../services/user';
import { validators } from '../../../../services/validators';
import { stores } from '../../../../stores';
import Snippet from '../../../snippet/Snippet';
import UiButton from '../../../ui/button/UiButton';
import UiForm from '../../../ui/form/UiForm';
import UiFormInput from '../../../ui/form/input/UiFormInput';
import UiGroup from '../../../ui/group/UiGroup';
import Wrapper from './styles';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    isModal?: boolean;
    onAfterSubmit: () => void;
    onExit?: () => void;
}

export default function AuthProfileValidatorOccupation({
    isModal = false,
    onAfterSubmit = () => {},
    onExit = () => {},
}: Props) {
    if (!isFeatureAvailable(FEATURE.PROFILE_OCCUPATION_VALIDATION)) {
        return null;
    }

    const [industries, setIndustries] = useState<Industry[]>([]);
    const [countries, setCountries] = useState<UserCountry[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isOccupationAsked, setIsOccupationAsked] = useState(false);
    const [isNationalityAsked, setIsNationalityAsked] = useState(false);
    const [isBirthPlaceAsked, setIsBirthPlaceAsked] = useState(false);
    const [isProfileReviewRequired, setIsProfileReviewRequired] = useState(false);
    const industryTitlesWithoutJobTitle = ['Student'];
    const industryIdsWithoutJobTitle = [1002];
    const industryTitlesWithPreviousJobTitle = ['Retired', 'Unemployed'];
    const [user] = useStore(stores.user);
    const form = useForm({
        birthPlace: undefined,
        industry: undefined,
        politicallyExposedPersonRelationship: undefined,
        isPoliticallyExposedPerson: undefined,
        isThirdPartyDeterminationConfirmed: undefined,
        jobTitle: undefined,
        nationality: undefined,
        politicallyExposedPersonText: undefined,
        city: undefined,
        address: undefined,
        country: undefined,
        zip: undefined,
        province: undefined,
        loqateLookupAddressId: undefined,
        fullName: undefined,
    });

    useEffect(() => {
        init();
    }, [user]);

    async function init() {
        if (!user) {
            return;
        }

        let userWithAdditionalData;
        try {
            userWithAdditionalData = await getMe({ extra: true });
        } catch (e) {
            logger.error('AuthProfileValidatorOccupation', 'init', e);
        }

        if (!userWithAdditionalData) {
            return;
        }

        const isProfileReviewRequired = userWithAdditionalData.isProfileReviewRequired;
        const isOccupationAsked = !userWithAdditionalData.industryId || isProfileReviewRequired;
        const isNationalityAsked =
            isFieldVisible('nationality') && (!userWithAdditionalData.nationality || isProfileReviewRequired);
        const isBirthPlaceAsked =
            isFieldVisible('birthPlace') && (!userWithAdditionalData.birthPlace || isProfileReviewRequired);

        const profileReviewNotRequired =
            !isProfileReviewRequired && !isOccupationAsked && !isNationalityAsked && !isBirthPlaceAsked;

        if (profileReviewNotRequired) {
            return onExit();
        }

        try {
            const [industries, countries] = await Promise.all([
                isOccupationAsked ? getIndustries() : [],
                isNationalityAsked || isBirthPlaceAsked ? getCountries() : [],
            ]);
            const translatedIndustries = industries.map((industry) => ({
                title: translate(industry.title, 'ui.account'),
                id: industry.id,
            }));
            setIndustries(translatedIndustries);
            setCountries(countries);
        } catch (error) {
            logger.error('AuthProfileValidatorOccupation', 'init', error);
        }

        setIsOccupationAsked(Boolean(isOccupationAsked));
        setIsNationalityAsked(Boolean(isNationalityAsked));
        setIsBirthPlaceAsked(Boolean(isBirthPlaceAsked));
        setIsProfileReviewRequired(Boolean(isProfileReviewRequired));

        const userIndustry = industries.find((industry) => industry.id === userWithAdditionalData.industryId);

        form.setInputValue({
            industry: userIndustry?.title,
            jobTitle: userWithAdditionalData.jobTitle,
            city: user.city,
            address: user.address,
            country: user.country,
            zip: user.zip,
            province: user.province,
            fullName: `${user.firstName} ${user.lastName}`,
        });

        if (isModal) {
            stores.modals.isRequiredProfileInformationInquiryModalOpen.set(true);
        }
    }

    async function save(isFormValid: boolean) {
        if (!isFormValid) {
            return;
        }

        setIsLoading(true);

        try {
            const {
                birthPlace: birthPlaceTitle,
                industry,
                jobTitle,
                nationality: nationalityTitle,
                loqateLookupAddressId,
            } = form.getValues();
            const birthPlace = getCountryIso2ByTitle(birthPlaceTitle);
            const nationality = getCountryIso2ByTitle(nationalityTitle);
            const industryId = getIndustryIdByTitle(industry);
            await Promise.all([
                updateProfile({
                    ...(isOccupationAsked &&
                        industryId &&
                        (industryIdsWithoutJobTitle.includes(industryId) || jobTitle) && {
                            industry_id: industryId,
                            job_title: jobTitle,
                        }),
                    ...(isBirthPlaceAsked && { birth_place: birthPlace }),
                    ...(isNationalityAsked && { nationality }),
                }),
                loqateLookupAddressId && saveLoqateAddress(loqateLookupAddressId),
            ]);
            await loadProfile();
            onAfterSubmit();
        } catch (error) {
            logger.error('AuthProfileValidatorOccupation', 'save', error);
        }

        setIsLoading(false);
    }

    function getIndustryIdByTitle(title: string) {
        return industries.find((x) => x.title === title)?.id;
    }

    function getCountryIso2ByTitle(title: string) {
        return countries.find((country) => country.title === title)?.iso2;
    }

    function isJobTitleDisplayed() {
        return isOccupationAsked && !industryTitlesWithoutJobTitle.includes(form.getInputValue('industry'));
    }

    function industryValidator(industry: string) {
        if (!getIndustryIdByTitle(industry)) {
            return { error: translate('Invalid industry', 'ui.account') };
        }
    }

    function nationalityValidator(nationality: string) {
        if (!getCountryIso2ByTitle(nationality)) {
            return { error: translate('Invalid nationality', 'ui.account') };
        }
    }

    function birthPlaceValidator(birthPlace: string) {
        if (!getCountryIso2ByTitle(birthPlace)) {
            return { error: translate('Invalid birth place', 'ui.account') };
        }
    }

    if (!isOccupationAsked && !isNationalityAsked && !isBirthPlaceAsked && !isProfileReviewRequired) {
        return null;
    }

    return (
        <Wrapper>
            <UiForm onSubmit={save} isLoading={isLoading} fullStoryBlock>
                {isProfileReviewRequired ? (
                    <>
                        <Snippet snippetKey={`auth.${getFormattedLicenceName()}-occupation-review-header`} />
                        <UiGroup expand>
                            <UiFormInput
                                {...form.fullName}
                                data-test="user-fullname"
                                disabled
                                label={translate('Full name (please contact support to change)', 'ui.account')}
                            />
                        </UiGroup>
                    </>
                ) : (
                    <Snippet snippetKey={`auth.${getFormattedLicenceName()}-occupation-header`} />
                )}
                {isOccupationAsked && (
                    <UiGroup expand>
                        <UiFormInput
                            {...form.industry}
                            autoCompleteOptions={industries}
                            data-test="industry"
                            autocompleteSelect
                            label={translate('Industry', 'ui.account')}
                            placeholder={translate('Industry', 'ui.account')}
                            validator={industryValidator}
                        />
                    </UiGroup>
                )}

                {isJobTitleDisplayed() && (
                    <UiGroup expand>
                        <UiFormInput
                            {...form.jobTitle}
                            data-test="job-title"
                            minLength={3}
                            label={
                                industryTitlesWithPreviousJobTitle.includes(form.industry.value)
                                    ? translate('Previous job title', 'ui.account')
                                    : translate('Job title', 'ui.account')
                            }
                            placeholder={
                                industryTitlesWithPreviousJobTitle.includes(form.industry.value)
                                    ? translate('Previous job title', 'ui.account')
                                    : translate('Job title', 'ui.account')
                            }
                            validator={validators.chain(validators.required, validators.jobTitle)}
                        />
                    </UiGroup>
                )}
                {isNationalityAsked && (
                    <UiGroup expand>
                        <UiFormInput
                            {...form.nationality}
                            autocompleteSelect
                            autoCompleteOptions={countries}
                            data-test="nationality"
                            label={translate('Nationality', 'ui.account')}
                            placeholder={translate('Nationality', 'ui.account')}
                            validator={validators.chain(validators.required, nationalityValidator)}
                        />
                    </UiGroup>
                )}
                {isBirthPlaceAsked && (
                    <UiGroup expand>
                        <UiFormInput
                            {...form.birthPlace}
                            autocompleteSelect
                            autoCompleteOptions={countries}
                            data-test="birthPlace"
                            label={translate('Birth place', 'ui.account')}
                            placeholder={translate('Birth place', 'ui.account')}
                            validator={validators.chain(validators.required, birthPlaceValidator)}
                        />
                    </UiGroup>
                )}
                <UiButton data-test="set-occupation" isFormSubmitButton block color="primary" isLoading={isLoading}>
                    {translate('Continue', 'ui.account')}
                </UiButton>
            </UiForm>
        </Wrapper>
    );
}
