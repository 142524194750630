import React, { useEffect, useRef, useState } from 'react';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import ceil from 'lodash/ceil';
import round from 'lodash/round';
import {
    ADJUSTED_TOWIN_CALC,
    BETSLIP_MAX_LENGTH,
    BET_TYPE,
    COMBO_MARKET_ID,
} from '../../../../services/sports/constants';
import { addBetslipError, removeBetslipError } from '../../../../services/sports/betslip-errors';
import Svg from '../../../svg/Svg';
import SportBetslipQuickStake from '../quick-stake/SportBetslipQuickStake';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../../services/analytics';
import { useSportsUserSettings } from '../../../../services/sports/user-settings';
import UiGroup from '../../../ui/group/UiGroup';
import classNames from 'classnames';
import SportBetslipStakeKeyboard from '../stake-keyboard/SportBetslipStakeKeyboard';
import { showVirtualKeyboard } from '../../../../services/virtual-keyboard/virtual-keyboard';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import { proccessRetailInput } from '../../../../services/util';
import { getBetslipQuickStakes } from '../../../../services/sports/betslip';
import { FEATURE, isFeatureAvailable } from '../../../../services/feature';
import { isB2B, isRetail } from '../../../../services/environment';
import { retail } from '../../../../stores/retail/retail';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    onChange: (value) => void;
    isDisabled: boolean;
    hideQuickStakes?: boolean;
    disableQuickStakes?: boolean;
    value: number;
    name: string;
    totalOdds?: number;
    disableToWin?: boolean;
    marketId?: string | null;
    label?: string;
}

export default function SportBetslipWageToWin({
    onChange,
    isDisabled,
    value,
    name,
    totalOdds,
    disableToWin = true,
    label,
    marketId,
    hideQuickStakes,
    disableQuickStakes,
}: Props) {
    const { isAmericanBetslipInputFormat } = useSportsUserSettings();
    const [betSlipUserState] = useStore(stores.sports.betSlipUserState);
    const { betType } = betSlipUserState;
    const [betSlipPlacingState] = useStore(stores.sports.betSlipPlacingState);
    const { isLoading } = betSlipPlacingState;
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [betSlipErrorByMarketId] = useStore(stores.sports.betSlipErrorByMarketId);
    const [toWinValue, setToWinValue] = useState<string>();
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [isToWinFocused, setIsToWinFocused] = useState(false);
    const [isKeyboardOpened, setIsKeyboardOpened] = useState(false);
    const [{ isPhone }] = useStore(media);
    const [wallet] = useStore(stores.wallet) as any;
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const inputRef = useRef<HTMLInputElement>(null);
    const isRetailLayout = isRetail();
    const [isRetailKeyboardVisible] = useStore(retail.retailKeyboard.isVisible);
    const marketIdsToTake =
        betType !== BET_TYPE.SINGLE
            ? [COMBO_MARKET_ID]
            : marketId
            ? [marketId]
            : Object.keys(betSlipMarketIdToOutcomeId);

    const elementRef = useRef<HTMLDivElement>(null);
    const betStakeInputId = `betStakeInputId-${name}`;

    useEffect(() => {
        if (!disableToWin && totalOdds && isAmericanBetslipInputFormat && !isToWinFocused) {
            const newToWinValue = round(totalOdds * value - value, 2);
            setToWinValue(newToWinValue ? newToWinValue.toString() : '');
        }
    }, [value, isAmericanBetslipInputFormat, totalOdds]);

    useEffect(() => {
        Object.keys(betSlipErrorByMarketId).forEach((marketId) => {
            removeBetslipError(marketId, ADJUSTED_TOWIN_CALC);
        });
    }, [isAmericanBetslipInputFormat, betType]);

    useEffect(() => {
        if (isInputFocused) {
            setIsKeyboardOpened(true);
            if (!isRetailLayout) {
                clearStake();
            }

            if (isPhone) {
                const stakeElement = elementRef.current;
                if (stakeElement) {
                    stakeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }
        }
    }, [isInputFocused]);

    useEffect(() => {
        if (isKeyboardOpened && isLoading) {
            setIsKeyboardOpened(false);
        }
    }, [isLoading]);

    function handleInputFocus(e) {
        setIsInputFocused(true);
        setIsToWinFocused(false);
        stores.sports.isBottomNavigationVisible.set(false);
        if (isRetailLayout) {
            showVirtualKeyboard({
                targetEl: e.target,
                anchorEl: e.target?.parentElement?.parentElement as HTMLElement,
                callback: (value) => onChange(value),
                value: value.toString(),
                layout: 'betslip',
                offsetCenterY: 6,
                ignoreLossFocus: false,
                showWithDelay: 1,
            });
        }
    }

    function handleToWinFocus() {
        setIsToWinFocused(true);
        setIsInputFocused(false);
        stores.sports.isBottomNavigationVisible.set(false);
    }

    function handleInputBlur() {
        setIsInputFocused(false);
        stores.sports.isBottomNavigationVisible.set(true);
    }

    function handleToWinBlur() {
        setIsToWinFocused(false);
        stores.sports.isBottomNavigationVisible.set(true);

        if (totalOdds) {
            const newToWinValue = round(totalOdds * value - value, 2).toString();

            if (newToWinValue !== toWinValue) {
                marketIdsToTake.forEach((marketId) => {
                    addBetslipError(marketId, ADJUSTED_TOWIN_CALC);
                });

                setToWinValue(newToWinValue);
            }
        }
    }

    function handleToWinChange(newToWinValue) {
        setToWinValue(newToWinValue);
        if (totalOdds && newToWinValue) {
            const roundValue = round(newToWinValue / (totalOdds - 1), 2);
            const ceilValue = ceil(newToWinValue / (totalOdds - 1), 2);
            const newValue =
                round(totalOdds * roundValue - roundValue, 2).toString() === newToWinValue ? roundValue : ceilValue;
            onChange(newValue);
        }

        marketIdsToTake.forEach((marketId) => {
            removeBetslipError(marketId, ADJUSTED_TOWIN_CALC);
        });
    }

    function handleStakeInput(value) {
        if (isRetailLayout) {
            proccessRetailInput(onChange, value);
        } else {
            onChange(value);
        }
    }

    const inputProps = {
        name,
        type: 'text',
        min: 0,
        onFocus: handleInputFocus,
        onBlur: handleInputBlur,
        onChange: (e) => handleStakeInput(e.target.value),
        autoComplete: 'off',
        autoCorrect: 'off',
        autoCapitalize: 'off',
        spellCheck: 'false',
        'data-gramm_editor': 'false',
        'data-gramm': 'false',
    } as const;

    const limitAmountOfOption = isPhone ? 5 : 4;
    const quickStakes = getBetslipQuickStakes(limitAmountOfOption);

    function updateStake(value, newValue: number) {
        const isQuickStakeIncremental = isFeatureAvailable(FEATURE.QUICKSTAKE_INCREMENTAL);
        const currentStake = parseInt(value || 0);

        const updatedValue = isQuickStakeIncremental ? currentStake + newValue : newValue;
        onChange(updatedValue);
    }

    function handleManualInputFocus() {
        if (isRetailLayout && inputRef?.current) {
            inputRef.current?.focus();
        }
    }

    function clearStake() {
        onChange('');
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.BETSLIP_QUICK_STAKE_REMOVE_CLICKED);
    }

    return (
        <>
            <UiGroup horizontallyCentered>
                <div className="stake-input" ref={elementRef}>
                    {isPhone && isAuthenticated && !isAmericanBetslipInputFormat && (
                        <div className="available-balance">
                            <Svg icon="user-icon" />
                            <div>{formattedAmountWithCurrency(wallet?.balance_uc)}</div>
                        </div>
                    )}
                    {isB2B() && <div className="stake-currency">$</div>}
                    <label
                        htmlFor={betStakeInputId}
                        className={classNames('stake-input-label', {
                            'single-label': betType === BET_TYPE.SINGLE || betType === BET_TYPE.BETBUILDER,
                        })}
                    >
                        {translate(label || (!isAmericanBetslipInputFormat ? 'Your stake' : 'Wager'), 'ui.sportsbook')}
                    </label>

                    <div data-test="retailStakeInputWrapper" onClick={handleManualInputFocus}>
                        <input
                            id={betStakeInputId}
                            value={isRetailLayout && !Number(value) ? '' : value}
                            ref={inputRef}
                            maxLength={BETSLIP_MAX_LENGTH}
                            readOnly={isPhone || isRetailLayout}
                            disabled={isDisabled}
                            className={isRetailLayout ? 'retail-stake-input' : ''}
                            {...inputProps}
                        />
                    </div>

                    {value > 0 &&
                        !isDisabled &&
                        ((!isKeyboardOpened && !isRetailLayout) || (!isRetailKeyboardVisible && isRetailLayout)) && (
                            <div
                                className={classNames('remove-stake-button', {
                                    'remove-button-small': isAmericanBetslipInputFormat,
                                })}
                                onClick={() => clearStake()}
                            >
                                <Svg icon="backspace" />
                            </div>
                        )}
                </div>
                {!isRetailLayout && isAmericanBetslipInputFormat && !disableToWin && Number(totalOdds) > 0 && (
                    <>
                        <div className="stake-input input-to-win">
                            {isB2B() && <div className="stake-currency">$</div>}
                            <label
                                htmlFor="betStakeInput"
                                className={classNames('stake-input-label label-to-win', {
                                    'single-label': betType === BET_TYPE.SINGLE || betType === BET_TYPE.BETBUILDER,
                                })}
                            >
                                {translate('To win', 'ui.sportsbook')}
                            </label>

                            <input
                                id="betStake"
                                value={toWinValue}
                                {...inputProps}
                                onChange={(e) => handleToWinChange(e.target.value)}
                                onBlur={handleToWinBlur}
                                onFocus={handleToWinFocus}
                                disabled={isDisabled || isRetailLayout}
                                readOnly={isRetailLayout || isPhone}
                            />
                        </div>
                    </>
                )}
            </UiGroup>
            {isKeyboardOpened && !isRetailLayout ? (
                <SportBetslipStakeKeyboard
                    inputRef={inputRef}
                    value={value}
                    onChange={onChange}
                    onClose={() => setIsKeyboardOpened(false)}
                />
            ) : (
                <>
                    {!hideQuickStakes && (
                        <div className="quick-stake-buttons">
                            {quickStakes.map((stake) => {
                                return (
                                    <SportBetslipQuickStake
                                        key={stake}
                                        stake={stake}
                                        onStakeUpdate={() => updateStake(value, stake)}
                                        disabled={isRetailKeyboardVisible || disableQuickStakes}
                                    />
                                );
                            })}
                        </div>
                    )}
                </>
            )}
        </>
    );
}
