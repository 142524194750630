import React from 'react';
import { getClient, isRetail } from '../../services/environment';
import Wrapper from './styles';
import { environment } from '../../stores/environment/environment';

import { useStore } from '../../hooks/useStore';

interface Props {
    teamName?: string;
    width?: string;
}

export default function TeamLogo({ teamName, width }: Props) {
    const [{ IMAGES_HOST }] = useStore(environment);
    const client = getClient();
    const imagePath = `${IMAGES_HOST}${client}/sportsbook/team-logos/${teamName}.png`;
    const imageWidth = isRetail() ? 32 : 28;

    if (!IMAGES_HOST || !client || !teamName) {
        return null;
    }

    return (
        <Wrapper>
            <img
                srcSet={`${imagePath}?w=${imageWidth}&dpr=1 1x,
          ${imagePath}?w=${imageWidth}&dpr=2 2x,
          ${imagePath}?w=${imageWidth}&dpr=3 3x,
          ${imagePath}?w=${imageWidth}&dpr=4 4x`}
                src={`${imagePath}?w=${imageWidth}&auto=compress`}
                width={width || imageWidth}
                alt={teamName}
            />
        </Wrapper>
    );
}
