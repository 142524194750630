import React, { useState } from 'react';
import { convertOdds } from '../../../../services/odds-format';
import { SystemBet, TicketDetails, TicketType } from '../../../../services/sports/types';
import { useOddsFormat } from '../../../../services/sports/user-settings';
import { translate } from '../../../../services/translate';
import Svg from '../../../svg/Svg';
import UiButton from '../../../ui/button/UiButton';
import SportBadgeTicketPlatform from '../../badge/ticket-platform/SportBadgeTicketPlatform';
import SportBadgeTicketStatus from '../../badge/ticket-status/SportBadgeTicketStatus';
import SportTicketMatchScore from '../match-score/SportTicketMatchScore';
import SportTicketOdds from '../odds/SportTicketOdds';
import SportTicketTotals from '../totals/SportTicketTotals';
import Wrapper from './styles';
import SportCategoryIcon from '../../category-icon/SportCategoryIcon';
import { DATE_TIME_FORMAT, getFormattedDate } from '../../../../services/date';
import { formattedAmountWithCurrency } from '../../../../services/currency';

interface Props {
    systemInfo: SystemBet;
    ticketFromResponse: TicketDetails;
    isPublic: boolean;
    isCompactViewEnabled: boolean;
}

export default function SportTicketSelections({
    systemInfo,
    isPublic,
    isCompactViewEnabled,
    ticketFromResponse,
}: Props) {
    const [isTicketDetailsVisible, setIsTicketDetailsVisible] = useState(false);

    useOddsFormat();

    if (!systemInfo.bets) {
        return null;
    }

    const { selectionByOutcomeId, ticket } = ticketFromResponse;

    return (
        <Wrapper className="system-groups">
            <UiButton
                block
                onClick={() => {
                    setIsTicketDetailsVisible(!isTicketDetailsVisible);
                }}
                selected={isTicketDetailsVisible}
            >
                {systemInfo.bets.length}&nbsp;&times;&nbsp;{translate(systemInfo.systemType, 'ui.betslip')} (
                {formattedAmountWithCurrency(systemInfo.totalStake)})
            </UiButton>

            {isTicketDetailsVisible &&
                selectionByOutcomeId &&
                systemInfo.bets.map((bet) => (
                    <div key={bet.id} className="system-item">
                        <SportTicketTotals
                            extraInfo
                            ticket={bet}
                            isPublic={isPublic}
                            isCompactView={isCompactViewEnabled}
                            w2gEventStatus={ticket.w2g_events?.find((e) => e.bet_id === bet.id)?.status}
                        />

                        {bet.outcome_ids &&
                            bet.outcome_ids.map((outcomeId, index) => (
                                <div className="ticket-details" key={index}>
                                    <div className="selection-sport">
                                        <SportCategoryIcon
                                            className="sport-icon"
                                            small
                                            icon={selectionByOutcomeId[outcomeId].sport_icon}
                                        />
                                    </div>

                                    <div className="selection-match">
                                        <div className="match-info">
                                            <div className="match-dates">
                                                <span>
                                                    {getFormattedDate({
                                                        date: `${bet.created_at}`,
                                                        format: DATE_TIME_FORMAT,
                                                        useMonthLetters: true,
                                                    })}
                                                </span>
                                            </div>
                                            <span> / </span>
                                            <span className="match-league">
                                                {selectionByOutcomeId[outcomeId].leagueName ||
                                                    selectionByOutcomeId[outcomeId].league_name}
                                            </span>
                                            {isCompactViewEnabled && (
                                                <span className="match-sport">
                                                    <SportCategoryIcon
                                                        className="sport-icon"
                                                        tiny
                                                        icon={selectionByOutcomeId[outcomeId].sport_icon}
                                                    />
                                                </span>
                                            )}
                                        </div>

                                        <div className="match-container">
                                            <div className="match-name">
                                                {selectionByOutcomeId[outcomeId] &&
                                                    selectionByOutcomeId[outcomeId].match_name}
                                            </div>
                                            {!isCompactViewEnabled && !isPublic && (
                                                <div className="ticket-status">
                                                    <SportBadgeTicketPlatform
                                                        ticket={selectionByOutcomeId[outcomeId]}
                                                    />
                                                    <SportBadgeTicketStatus ticket={selectionByOutcomeId[outcomeId]} />
                                                </div>
                                            )}
                                        </div>

                                        <div className="selected-market">
                                            <div className="market-name">
                                                {selectionByOutcomeId[outcomeId].market_name}
                                            </div>
                                            <span className="market-outcome">
                                                {ticket.ticket_type !== TicketType.TEASER && (
                                                    <SportTicketOdds compact>
                                                        {convertOdds(selectionByOutcomeId[outcomeId].odds)}
                                                    </SportTicketOdds>
                                                )}
                                                <span>»</span>
                                                {selectionByOutcomeId[outcomeId].outcome_name}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="odds-and-type">
                                        {isCompactViewEnabled && (
                                            <>
                                                {selectionByOutcomeId[outcomeId].score_home !== null && (
                                                    <SportTicketMatchScore
                                                        matchInfo={selectionByOutcomeId[outcomeId]}
                                                    />
                                                )}
                                                <div className="selection-status">
                                                    <SportBadgeTicketPlatform
                                                        ticket={selectionByOutcomeId[outcomeId]}
                                                    />
                                                    <SportBadgeTicketStatus ticket={selectionByOutcomeId[outcomeId]} />
                                                </div>
                                            </>
                                        )}

                                        {ticket.banker_outcome_ids && ticket.banker_outcome_ids.includes(outcomeId) && (
                                            <div className="banker-bet">
                                                <Svg icon="bank" />
                                                <span>{translate('banker', 'ui.sportsbook')}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                    </div>
                ))}
        </Wrapper>
    );
}
