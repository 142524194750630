import React from 'react';
import SportMatch from '../SportMatch';
import { CategoryMatch } from '../../../../services/sports/types';
import { useSportsUserSettings } from '../../../../services/sports/user-settings';
import { ChangableContextProvider } from '../../../../higher-order-components/context/provider/ContextProvider';
import { SportsMatchContext } from '../../../../services/sports/match-helpers';

interface Props {
    matches: CategoryMatch[];
    isOpen?: boolean;
}

export default function SportMatchList({ matches }: Props) {
    const layout = useSportsUserSettings();

    return (
        <>
            {matches?.map((match) => (
                <ChangableContextProvider Context={SportsMatchContext} key={match.id}>
                    <SportMatch key={match.id} match={match} layout={layout} />
                </ChangableContextProvider>
            ))}
        </>
    );
}
