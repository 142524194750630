import { httpGet, httpPost, httpPut } from '../services/api';
import {
    retailMiddlewareUrl,
    TerminalMachineIdResponse,
    TerminalConfig,
    TerminalIntegrity,
    TerminalNotes,
    FullCashboxReport,
} from '../services/retail-middleware';
import { RecentTransactions } from './retail-proxy';
import { BetShopSettings } from '@staycool/retail-types/bet-shop-settings';
import { TicketResponse, TicketToPrintResponse } from '@staycool/retail-types/ticket';
import { WebTransferResponse } from '@staycool/retail-types/web-transfer';
import { getStoreValue } from '../stores/store/utils';
import { Loyalty } from '@staycool/retail-types/loyalty';
import { BetPlacementResponse } from '@staycool/lvdc-types/bets';
import { retail } from '../stores/retail/retail';
import { LoyaltyResponseKiosk, Terminal } from '../stores/retail/types';

export const getUrl = (url: string) => `${retailMiddlewareUrl}/${url}`;

export async function getTerminalMachineId() {
    return httpGet<TerminalMachineIdResponse>(getUrl(`init/machine-id`));
}

export async function getTerminalConfig() {
    return httpGet<TerminalConfig>(getUrl(`config`));
}

export async function initTerminal() {
    return httpPost<void>(getUrl(`init`));
}

export async function resetTerminal() {
    return httpPost<void>(getUrl(`init/reset`));
}

export async function setTerminalConfig(config: TerminalConfig) {
    return httpPost<TerminalConfig>(getUrl(`config`), config);
}

export function getDiagnosticVersion() {
    return httpGet<string>(getUrl(`diagnostic/version`));
}

export function getDiagnosticIntegrity() {
    return httpGet<TerminalIntegrity>(getUrl(`diagnostic/integrity`));
}

export function printTicketAndVoucher() {
    return httpPost<void>(getUrl(`print/kiosk`));
}

export function depositTicket(id: string) {
    return httpPost<void>(getUrl(`ticket/kiosk/${id}/deposit`));
}

export function createVoucherFromWinningTicket(id: string) {
    return httpPost<void>(getUrl(`ticket/kiosk/${id}/voucher`));
}

export function getTicketsToPrint() {
    const url = getUrl('ticket/kiosk/print');
    return httpGet<TicketToPrintResponse[]>(url);
}

export function getBalance() {
    const url = getUrl('session/kiosk/balance');
    return httpGet<number>(url);
}

export function createLoyaltyById(loyaltyId: string) {
    return httpPost<LoyaltyResponseKiosk>(getUrl(`loyalty/kiosk/manually-entered`), { loyaltyId });
}

export async function clearMiddlewareLoyalty() {
    const url = getUrl(`loyalty/clear`);
    return httpPost(url);
}

export function placeRetailTicket(data: any) {
    const shopId = getStoreValue(retail.shopId);
    const url = getUrl(`ticket/place`);
    return httpPost<string>(url, {
        data,
        shopId,
    });
}

export function placeRetailHorseRacingTicket(betPlacingData: any) {
    const url = getUrl(`ticket/horse-racing-place`);
    return httpPost<BetPlacementResponse>(url, {
        betPlacingData: {
            ...betPlacingData,
            extra_info: {
                shopId: getStoreValue(retail.shopId),
            },
        },
    });
}

export async function getLoyalty(loyaltyId: string) {
    const url = getUrl(`loyalty/kiosk/${loyaltyId}`);
    return httpGet<Loyalty | undefined>(url);
}

export function getTerminal() {
    return httpGet<Terminal>(getUrl('terminal'));
}

export async function getTerminalNotes() {
    const url = getUrl('notes');
    return httpGet<TerminalNotes>(url);
}

export async function getLastCashboxReport() {
    const url = getUrl('cashbox-report');
    return httpGet<FullCashboxReport>(url);
}

export async function collectCashAndCreateReport() {
    const url = getUrl('cashbox-report');
    return httpPost<FullCashboxReport>(url);
}

export async function printLastCashboxReport() {
    const url = getUrl('cashbox-report/print');
    return httpPost<void>(url);
}

export async function expireSession() {
    const url = getUrl(`session/kiosk/expire`);
    return httpPut(url, {});
}

export function getLatestTransactions() {
    const url = getUrl(`transaction/kiosk/recent`);
    return httpGet<RecentTransactions[]>(url);
}

export function getTerminalStatistics() {
    const url = getUrl(`statistics/kiosk`);
    return httpGet<{ data: Record<string, number> }>(url);
}

export async function getBetShopSettings() {
    const url = getUrl('bet-shop/settings');
    return httpGet<BetShopSettings>(url);
}

export function restartMachine() {
    return httpPost(getUrl(`restart`));
}

export async function getTicket(id: string) {
    return httpGet<TicketResponse>(getUrl(`ticket/kiosk/${id}`));
}

export function createWebAccountDeposit(loyaltyId: string, amount: number) {
    const url = getUrl('web-transfer/deposit');
    return httpPost<WebTransferResponse>(url, { loyaltyId, amount });
}
