import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { QUIZ_STATUS, QUIZ_TITLE_TYPE, SPORT_TYPE } from '../services/quiz';

const getUrl = (url) => getServiceUrl('quiz', url);

export async function getQuiz(quizId: number) {
    const url = getUrl(`quiz/${quizId}`);
    return httpGet<Quiz>(url);
}

export async function getCollectionQuizzes(collectionId) {
    const url = getUrl(`collection-quizzes/${collectionId}`);
    return httpGet<Quiz[]>(url);
}

export async function submitAnswers(answers) {
    const url = getUrl('user-answers');
    return httpPost<UserAnswers>(url, answers);
}

export async function getUserAnswers(quizId) {
    const url = getUrl(`user-answers/${quizId}`);
    return httpGet<UserAnswers>(url);
}
export async function getUserParticipatedQuizzes(collectionId) {
    const url = getUrl(`user-quizzes/${collectionId}`);
    return httpGet<Quiz[]>(url);
}
export async function checkIsUserInSegment(quizId) {
    const url = getUrl(`user-segment-check/${quizId}`);
    return httpGet<{ isUserInQuizSegment: boolean }>(url);
}

export type Quiz = {
    id: number;
    collection_id: number;
    created?: Date;
    updated?: Date;
    status: typeof QUIZ_STATUS[keyof typeof QUIZ_STATUS];
    bo_title: string;
    title: Record<string, string>;
    title_type: typeof QUIZ_TITLE_TYPE[keyof typeof QUIZ_TITLE_TYPE];
    submission_start_time: Date | string;
    submission_end_time: Date | string;
    go_live_time: Date | string;
    image: string;
    segment_id: string;
    winner_segment_id?: string;
    loser_segment_id?: string;
    min_correct_answers: number;
    submitted_text: Record<string, string>;
    is_template: boolean;
    default_language: string;
    sport_type?: typeof SPORT_TYPE[keyof typeof SPORT_TYPE];
    variables?: QuizVariables;
    questions: Question[];
    user_answers_count?: number;
};

interface QuizVariables {
    home: Record<string, string>;
    away: Record<string, string>;
}

export type Question = {
    id?: number;
    quiz_id: number;
    question_number: number;
    title: any;
    answers: QuestionAnswer[];
};

export type QuestionAnswer = {
    id: string;
    is_correct: boolean;
    is_selected: boolean;
    text: any;
    user_selection?: boolean;
};

export type UserAnswers = {
    id?: number;
    quiz_id: number;
    user_id: string;
    answers: Answer[];
    score?: number;
    created?: string;
    updated?: string;
};

type Answer = {
    question_id: number;
    answer_id: number;
    was_correct?: boolean;
};
