import React, { ReactNode } from 'react';
import Wrapper from './styles';
import { filterStyleProps } from '../../../../styles/utils';

interface Props {
    children: ReactNode;
    noWrap?: boolean;
    className?: string;
}

export default function UiFormLabel({ children, ...rest }: Props) {
    return <Wrapper {...filterStyleProps({ ...rest })}>{children}</Wrapper>;
}
