import React from 'react';
import Wrapper from './styles';
import Svg from '../../svg/Svg';
import { isRetail } from '../../../services/environment';
import { filterStyleProps } from '../../../styles/utils';

interface Props {
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
    size?: number;
}

export default function UiCloseButton(props: Props) {
    const isRetailLayout = isRetail();

    return (
        <Wrapper {...filterStyleProps(props)} $isRetailLayout={isRetailLayout}>
            <Svg icon="crossmark" />
        </Wrapper>
    );
}
