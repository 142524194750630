import styled from 'styled-components';

export default styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 10000;
    box-shadow: var(--ui-shadow);
    border: 1px solid var(--surface-level-2-line-color);
    border-radius: var(--ui-radius);
    min-height: 18.375rem;
    width: 14rem;

    color: var(--font-color-primary);
    user-select: none;

    .combo-card-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;
        background-color: var(--surface-level-1-bg);
        padding: var(--general-ui-spacing-radius-spacing-12, 0.75rem)
            var(--general-ui-spacing-radius-spacing-12, 0.75rem) 0.75rem
            var(--general-ui-spacing-radius-spacing-12, 0.75rem);
        border-radius: 0 0 var(--ui-radius) var(--ui-radius);
        gap: 0.5rem;
        align-self: stretch;
    }
`;
