import React from 'react';
import { stores, useStoreWithSelector } from '../../../../../stores';
import { amountOfMarketsByDevice, useTurnoverMarket } from '../../../../../services/sports/top-markets';
import Wrapper from './styles';
import SportMatchTurnover from '../../turnover/SportMatchTurnover';
import SportMatchMarketsTopMarkets from '../../markets/top-markets/SportMatchMarketsTopMarkets';
import SportMatchLayoutAmericanHead from './head/SportMatchLayoutAmericanHead';
import { useSportsMatchContext } from '../../../../../services/sports/match-helpers';
import UiCloseButton from '../../../../ui/close-button/UiCloseButton';
import { MATCH_STATUS, MATCH_TYPE } from '../../../../../services/sports/constants';
import { getRoute } from '../../../../../services/router';
import MatchPromotion from '../../../../marketing/match-promotion/MarketingMatchPromotion';
import { SportMatchLayoutComponentParams as Props } from '../../../../../services/sports/types';
import SportMatchExtra from '../../extra/SportMatchExtra';
import { isRetail } from '../../../../../services/environment';
import SportMatchInfo from '../../info/SportMatchInfo';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';

export default function SportMatchLayoutAmerican({ match }: Props) {
    const isRetailLayout = isRetail();
    const [{ deviceType }] = useStore(media);
    const numMarketsToShow = isRetailLayout ? 3 : amountOfMarketsByDevice[deviceType];
    const [promotion] = useStoreWithSelector(stores.marketing.matchPromotionsByMatchIds, (s) => s[match.id], [
        match.id,
    ]);
    const turnoverMarket = useTurnoverMarket(match);
    const { turnoverVisible, isOpen, setContext: setMatchContext } = useSportsMatchContext();
    const isLiveNow = match.status === MATCH_STATUS.LIVE;
    const matchRoute = isLiveNow ? `${getRoute('sport.live')}/${match.id}` : `${getRoute('sport.match')}/${match.id}`;
    const isMatchPage = window.location.pathname.includes(matchRoute);
    const isNormalMatch = match.match_type !== MATCH_TYPE.OUTRIGHT;

    return (
        <>
            <Wrapper
                className="match-container"
                $isOpen={isOpen}
                $isRetailLayout={isRetailLayout}
                $isOutright={!isNormalMatch}
            >
                <div className="match-header">
                    <SportMatchLayoutAmericanHead match={match} />
                </div>

                <SportMatchMarketsTopMarkets match={match} marketsToShow={numMarketsToShow} />

                <div className="match-extra">
                    {isOpen && !isMatchPage && (
                        <UiCloseButton
                            onClick={() => setMatchContext?.({ isOpen: false, currentVisualization: '' })}
                            className="close-button"
                        />
                    )}
                    <SportMatchExtra match={match} isVertical isPrimary />
                </div>
            </Wrapper>
            {isLiveNow && (
                <div className="live-info">
                    <SportMatchInfo match={match} showTime />
                </div>
            )}
            {!isLiveNow && <SportMatchInfo match={match} showTime />}

            {!isOpen && promotion && <MatchPromotion promotion={promotion} />}
            {turnoverVisible && <SportMatchTurnover matchId={match.id} mainMarket={turnoverMarket} />}
        </>
    );
}
