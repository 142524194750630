import React, { ReactNode, createContext } from 'react';

export const GlobalModalContext = createContext<Context>({
    showModal: () => {},
    hideModal: () => {},
    store: {},
});

interface Context {
    showModal: (component: any, { children }: any, onOpen?: () => void, onClose?: () => void) => void;
    hideModal: () => void;
    store: any;
}

interface Props {
    children: ReactNode;
    value: Context;
}

export function GlobalModalContextProvider({ children, value }: Props) {
    return <GlobalModalContext.Provider value={value}>{children}</GlobalModalContext.Provider>;
}
