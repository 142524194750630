import { getServiceUrl, httpPost } from '../services/api';

const getUrl = (url) => getServiceUrl('betgenius-streams-web', url);

export async function getStreamDRM(data: StreamDataParams) {
    const url = getUrl('fixture-stream');
    return await httpPost(url, data);
}

interface StreamDataParams {
    deliveryType: string;
    streamId: string;
    deliveryId: string;
    geniusSportsFixtureId: number;
    endUserSessionId: string;
    region: string;
    device: string;
}
