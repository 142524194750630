import styled from 'styled-components';

export default styled.div`
    .prompt-title {
        margin-top: 0;
    }

    .prompt-message {
        color: var(--font-color-primary);
    }
`;
