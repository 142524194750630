import styled from 'styled-components';

type StyledProps = {
    $isVertical?: boolean;
};

export default styled.div<StyledProps>`
    display: flex;
    align-items: center;
    justify-content: ${({ $isVertical }) => ($isVertical ? 'center' : '')};
    margin-left: ${({ $isVertical }) => ($isVertical ? '' : 'auto')};
    flex-direction: ${({ $isVertical }) => ($isVertical ? 'column' : 'row')};
    color: var(--match-secondary-color);
    gap: var(--spacing-16);
    padding: var(--spacing-8) 0;

    .stream-icon {
        position: relative;

        .stream-tooltip {
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translate(-50%, 0);
            margin-bottom: var(--spacing-12);
            background: rgba(255, 255, 255, 0.9);
            border-radius: var(--ui-radius);
            padding: var(--spacing-8);
            font-size: var(--font-12);
            color: black;
            display: none;
            animation: fadeIn 200ms linear;
            z-index: 4;

            &:after {
                content: '';
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: white transparent transparent transparent;
            }
        }
    }

    svg {
        cursor: pointer;
    }

    .active svg {
        color: var(--color-highlight);
    }
`;
